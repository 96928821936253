import React, { PropsWithChildren } from "react";
import { Modal, ModalHeader } from "reactstrap";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useCustomLanguageClient } from "../../../api/grpc/workplaces/useCustomLanguageClient";

import { CenteredBox } from "../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { Icon } from "../../shared/Icon/Icon";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import CustomLanguageModalForm from "./CustomLanguageModalForm";
import { WayfinderCustomLanguageModalForm } from "../WayfinderCustomLanguageModalForm/WayfinderCustomLanguageModalForm";
import {
  RetrieveCustomerGlobalCustomLanguagesRequest,
  RetrieveCustomerGlobalCustomLanguagesResponse,
} from "../../../api/grpc/workplaces/workplaces";

import "./CustomLanguageModal.scss";

interface Props {
  toggleModal: () => void;
  workspaceId?: string;
  langObj?: Uint8Array;
  langName: string;
  langId: string;
  refetch: () => void;
  refetchLanguages: () => void;
}

const components: {
  [key in "room" | "wayfinder"]: React.FunctionComponent<Props>;
} = {
  room: CustomLanguageModalForm,
  wayfinder: WayfinderCustomLanguageModalForm,
};

export const CustomLanguageModal = ({
  isOpen,
  toggleModal,
  workspaceId,
  type,
  customLanguageId,
  refetchLanguages,
}: PropsWithChildren<{
  isOpen: boolean;
  toggleModal: () => void;
  workspaceId?: string;
  type: "room" | "wayfinder";
  customLanguageId: string;
  refetchLanguages: () => void;
}>) => {
  const Component = components[type];

  const { getCustomLanguageById } = useCustomLanguageClient();

  const { loading, error, data, refetch } = useGrpcQuery<
    Omit<RetrieveCustomerGlobalCustomLanguagesRequest, "customerId">,
    RetrieveCustomerGlobalCustomLanguagesResponse
  >({
    method: getCustomLanguageById,
    variables: {
      id: customLanguageId,
    },
  });

  const componentProps: Props = {
    toggleModal,
    workspaceId,
    langObj: data?.languageData,
    langName: data?.name || "",
    langId: customLanguageId,
    refetch,
    refetchLanguages,
  };

  return (
    <Modal isOpen={isOpen} className="CustomLanguageModal">
      <ModalHeader>
        <span onClick={toggleModal} className="btn-close">
          <Icon icon="close-icon" />
        </span>
        Customize language
      </ModalHeader>

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<LoadingBox minHeight={45} count={10} />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={
            <CenteredBox>
              {error?.message ??
                "Error fetching custom language. Please try to refresh page or contanct us."}
            </CenteredBox>
          }
        >
          <Component {...componentProps} />
        </HandleRequestState>
      </HandleLoadingState>
    </Modal>
  );
};
