import React, { PropsWithChildren } from "react";
import { SharedPieChart } from "../../../../shared/Charts/PieChart/SharedPieChart/SharedPieChart";

export const UtilizationOccupancyTotal = ({
  value,
}: PropsWithChildren<{ value: number }>) => {
  const data = [
    {
      id: "% of occupancy",
      label: "occupancy",
      value: value,
      color: "#00EC97",
    },
    {
      id: "% of total",
      label: "% of total",
      value: 100 - value,
      color: "#ADAEB9",
    },
  ];

  return <SharedPieChart data={data} value={value} />;
};
