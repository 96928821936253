import React from "react";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";

import { Input } from "../../shared/Input/Input";
import { REGISTER_STRINGS as t } from "../../../lib/utils/constants";
import { CompleteSignupFormik } from "../lib/useCompleteSignupFormik";
import { PasswordInput } from "../../shared/PasswordInput/PasswordInput";
import { PrivacyTerms } from "../../../views/CompleteSignup/PrivacyTerms/PrivacyTerms";

interface Props {
  loading: boolean;
  formik: CompleteSignupFormik;
}

export const CompleteSignupForm: React.FunctionComponent<Props> = ({
  formik,
  loading,
}: Props) => {
  return (
    <Form className="w-100" onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="firstName">{t.firstName}</Label>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              isTouched={formik.touched.firstName}
              errorMessage={formik.errors.firstName}
              invalid={!!formik.errors.firstName && formik.touched.firstName}
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="lastName">{t.lastName}</Label>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              data-cy="lastName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              isTouched={formik.touched.lastName}
              errorMessage={formik.errors.lastName}
              invalid={!!formik.errors.lastName && formik.touched.lastName}
            />
          </FormGroup>
        </Col>

        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="workEmail">Work Email</Label>
            <Input
              readOnly
              type="email"
              id="workEmail"
              name="workEmail"
              value={formik.values.workEmail}
            />
          </FormGroup>
        </Col>

        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="accountPassword">Password</Label>
            <PasswordInput
              id="accountPassword"
              name="accountPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.accountPassword}
              invalid={
                !!formik.errors.accountPassword &&
                formik.touched.accountPassword
              }
              isTouched={formik.touched.accountPassword}
              errorMessage={formik.errors.accountPassword}
            />
          </FormGroup>
        </Col>

        <PrivacyTerms formik={formik} className="col-12" />

        <Col xs="12">
          <div className="AuthenticationPage__buttons">
            <Button
              size="large"
              type="submit"
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="mr-3">Creating your account...</span>
                  <Spinner size="sm" color="light" />
                </>
              ) : (
                "Sign Up"
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
