import React from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useConfirmation } from "../../../lib/context/Confirmation/ConfirmationContext";

import { trimText } from "../../../lib/helpers/trimText";
import {
  getCalendarIcon,
  getCalendarTypeName,
  getCalendarTypeSlug,
} from "../lib/helpers";

import {
  StructureTable,
  StructureTableColumn,
} from "../../shared/StructureTable/StructureTable";
import { DeleteButton } from "../../shared/DeleteButton/DeleteButton";
import { GGCalendarProvider } from "../../../api/grpc/calendarprovider/calendarprovider";
import { Icon } from "../../shared/Icon/Icon";

import "./CalendarsList.scss";

interface CalendarsListProps {
  items: GGCalendarProvider[];
}

export const CalendarsList = (props: CalendarsListProps) => {
  const { items } = props;
  const { user } = useAuthContext();
  const confirmation = useConfirmation();
  const { deleteProvider: deleteFromAPI } = useCalendarRequests();
  const { deleteProvider: deleteFromContext } = useCalendarContext();

  const columns: StructureTableColumn[] = [
    {
      key: "name",
      header: "Name",
    },
    {
      key: "type",
      header: "Type",
    },
    // {
    //   key: "sync_status",
    //   header: "Sync Status",
    // },
    {
      key: "actions",
      header: "",
    },
  ];

  // TO DO: Restructure the function below to follow only try-catch statement
  const handleCalendarDelete = async (id: string) => {
    confirmation
      .confirm({
        title: "Are you sure that you want to delete this calendar?",
      })
      .then(async () => {
        if (!user) {
          return;
        }

        try {
          await deleteFromAPI(id);

          deleteFromContext(id);

          toast.success("Calendar removed successfully!");
        } catch (e) {
          console.error("Failed deleting Calendar", e);
          toast.error("Failed deleting Calendar!");
        }
      })
      .catch(() => {});
  };

  const rows = items?.map((calendar) => {
    const calendarSlug = getCalendarTypeSlug(calendar.calType);
    const calendarName = getCalendarTypeName(calendar.calType);

    return {
      name: (
        <div className="CalendarsList__name">
          <NavLink
            to={`/manage/calendars/${calendarSlug}/${calendar.iD}`}
            title={calendar.name}
            onClick={() => {
              if (localStorage.getItem("listCalendars")) {
                localStorage.removeItem("listCalendars");
              }
            }}
          >
            {trimText(calendar.name)}
          </NavLink>
        </div>
      ),
      type: (
        <div className="flex-a-center">
          <Icon icon={getCalendarIcon(calendar?.iD)} />
          <h4 className="CalendarsList__type" title={calendarName}>
            {trimText(calendarName)}
          </h4>
        </div>
      ),
      // sync_status: (
      //   <div className="CalendarsList__sync">
      //     <span className="full"></span>
      //     <span className="full"></span>
      //   </div>
      // ),
      actions: (
        <div className="ml-auto">
          <DeleteButton onClick={() => handleCalendarDelete(calendar.iD)} />
        </div>
      ),
    };
  });

  return (
    <div className="CalendarsList my-4">
      <StructureTable rows={rows} columns={columns} />
    </div>
  );
};
