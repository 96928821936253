import React, { useState } from "react";
import { toast } from "react-toastify";
import { Form, FormGroup, Label } from "reactstrap";
import { MultiFactorError } from "firebase/auth";

import { useAccountFormik } from "./lib/useAccountFormik";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useAccountRequests } from "../../api/grpc/account/useAccountRequests";
import { useAccountDetails } from "./lib/useAccountDetails";
import { useTimeZoneContext } from "../../lib/context/TimeZone/TimeZoneContext";
import { useToggleModal } from "../../lib/hooks/useToggleModal";
import { capitalizeFirstLetter } from "../../lib/helpers/capitalizeFirstLetter";

import { Input } from "../shared/Input/Input";
import { tooltips } from "../../lib/utils/tooltips";
import { getUpdatedFields } from "./lib/getUpdatedFields";
import { CancelAccount } from "./CancelAccount/CancelAccount";
import { FormSection } from "../shared/FormSection/FormSection";
import { UploadImage } from "../shared/UploadImage/UploadImage";
import { PasswordInput } from "../shared/PasswordInput/PasswordInput";
import { FormSubSection } from "../shared/FormSubSection/FormSubSection";
import { EditSettings } from "../Workplaces/EditWorkplace/EditSettings/EditSettings";
import { LabelWithInformationBox } from "../shared/LabelWithInformationBox/LabelWithInformationBox";
import { EnableMFAOption } from "./EnableMFA/EnableMFAOption/EnableMFAOption";
import { FormItem } from "../shared/FormItem/FormItem";
import { MfaReAuthCreds } from "./EnableMFA/Modal/ModalMFASteps/MfaReAuthCreds";
import { MFAType, RolePermissions } from "../../api/grpc/account/account";
import { PermissionWrapper } from "../shared/PermissionWrapper/PermissionWrapper";

export const AccountForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const { updateAccount, requestEmailChange } = useAccountRequests();
  const { user, updateUser, verifyPassword, updatePassword, signOut } =
    useAuthContext();
  const customer = useAccountDetails();
  const { timeZone } = useTimeZoneContext();
  const { isOpen, toggleModal } = useToggleModal();
  const [errorMfa, setErrorMfa] = useState<MultiFactorError | undefined>(
    undefined
  );

  const userRole = user?.claims.role;

  let allowAccountInfoPart = ["admin", "owner", "support"].includes(userRole);

  const formik = useAccountFormik({
    initialValues: {
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      email: !!user?.email ? user.email.toLowerCase() : "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      displayName: user?.displayName || "",
    },
    onSubmit: async (values) => {
      try {
        if (!user) {
          return;
        }
        let passwordChanged = false;
        setSubmitting(true);

        if (user?.email !== values.email) {
          if (!allowAccountInfoPart) {
            //we add this case if users go to inspect element and manually delete disable value of email field if they have member role
            //if this is the case we return a toast message and stop the execute
            return toast.error(
              "Member accounts are not able to change their address!"
            );
          } else {
            await requestEmailChange(values.email.toLowerCase());
          }
        }

        if (values.currentPassword && values.newPassword) {
          if (user?.googleAccountConnected || user?.o365AccountConnected) {
            toast.error(
              "Username & password sign in not allowed for your account."
            );

            return;
          } else {
            //if MFA is enabled, the verification of password is done in MfaReAuthCreds component
            //with TOTP code, if this is the case we skip verification here
            if (!isOpen) {
              await verifyPassword(values.currentPassword);
            }

            await updatePassword(values.newPassword);

            toast.success(
              "Password changed successfully. Please re-login using your new password."
            );
            passwordChanged = true;
          }
        }

        const updatedFields = getUpdatedFields(
          formik.initialValues,
          values,
          passwordChanged
        );

        const { response } = await updateAccount(
          {
            displayName: values.displayName,
            email: values.email.toLowerCase(),
            firstName: values.firstName,
            lastName: values.lastName,
            newPassword: values.newPassword || "",
            phoneNumber: "",
            photoUrl: user.picture,
            googleAccountConnected: user.googleAccountConnected,
            o365AccountConnected: user.o365AccountConnected,
            googleAccountLinkedEmail: user.googleAccountLinkedEmail,
            o365AccountLinkedEmail: user.o365AccountLinkedEmail,
            timeZone,
            mfaType: user.mfaType,
            recoveryEmail: user.recoveryEmail || "",
          },
          updatedFields
        );

        updateUser({
          email: response.email.toLowerCase(),
          displayName: response.displayName,
          lastName: response.lastName,
          firstName: response.firstName,
          name: response.displayName,
          picture: response.photoUrl,
        });

        if (user?.email !== values.email) {
          toast.warn(
            "Please check the verification email sent to your new address."
          );
        } else {
          toast.success("Account updated successfully!");
        }

        formik.resetForm({
          values: {
            ...values,
            newPassword: "",
            currentPassword: "",
            confirmPassword: "",
          },
        });

        if (passwordChanged) {
          setTimeout(async () => {
            await signOut();
          }, 3000);
        }
      } catch (error: any) {
        if (error?.code === "auth/multi-factor-auth-required") {
          setErrorMfa(error);
          toggleModal();
          return;
        }
        toast.error(error?.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <EditSettings
      splitSections
      flexibleSections
      submitting={submitting}
      onSubmit={formik.submitForm}
      // hasChanges={formik.isValid && formik.dirty}
      className="position-relative"
    >
      {isOpen && (
        <MfaReAuthCreds
          handleCancel={toggleModal}
          errorMfa={errorMfa}
          handleSubmit={() => formik.submitForm()}
          toggleModal={toggleModal}
        />
      )}
      <div className="UserInfoMeta">
        <span className="SubscriptionStatus SubscriptionStatus--active">
          {capitalizeFirstLetter(userRole === "contact" ? "Member" : userRole)}
        </span>
        {customer.data?.companyName && (
          <span className="UserInfoMeta__company">
            {customer.data.companyName}
          </span>
        )}
      </div>
      <Form className="AccountForm flexible-form">
        <FormSection>
          <FormItem
            title="Personal profile"
            icon="profile-icon"
            className="pb-3"
          >
            <>
              <FormGroup>
                <LabelWithInformationBox for="firstName" title="First name" />
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  isTouched={formik.touched.firstName}
                  errorMessage={formik.errors.firstName}
                  invalid={
                    !!formik.errors.firstName && formik.touched.firstName
                  }
                />
              </FormGroup>
              <FormGroup>
                <LabelWithInformationBox for="lastName" title="Last name" />
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  isTouched={formik.touched.lastName}
                  errorMessage={formik.errors.lastName}
                  invalid={!!formik.errors.lastName && formik.touched.lastName}
                />
              </FormGroup>
              <FormGroup>
                <LabelWithInformationBox
                  for="displayName"
                  title="Display name"
                />
                <Input
                  type="text"
                  id="displayName"
                  name="displayName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.displayName}
                  isTouched={formik.touched.displayName}
                  errorMessage={formik.errors.displayName}
                  invalid={
                    !!formik.errors.displayName && formik.touched.displayName
                  }
                />
              </FormGroup>
              <FormGroup>
                <LabelWithInformationBox
                  for="email"
                  title="Account email"
                  message={tooltips.account.email}
                />
                <Input
                  id="email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isTouched={formik.touched.email}
                  errorMessage={formik.errors.email}
                  invalid={!!formik.errors.email && formik.touched.email}
                  disabled={!allowAccountInfoPart}
                />
              </FormGroup>
            </>
          </FormItem>

          <FormItem
            title="Change password"
            icon="password-lock"
            className="pb-3"
            message={tooltips.account.changePassword}
          >
            <FormSubSection column defaultContent>
              <FormGroup>
                <Label for="currentPassword">Current password</Label>
                <PasswordInput
                  id="currentPassword"
                  name="currentPassword"
                  autoComplete="new-password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  invalid={
                    !!formik.errors.currentPassword &&
                    (formik.touched.currentPassword ||
                      formik.touched.newPassword)
                  }
                  errorMessage={formik.errors.currentPassword}
                  isTouched={
                    formik.touched.currentPassword || formik.touched.newPassword
                  }
                />
              </FormGroup>
              <FormGroup className="flex">
                <Label for="newPassword">New password</Label>
                <PasswordInput
                  id="newPassword"
                  name="newPassword"
                  autoComplete="new-password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  isTouched={formik.touched.newPassword}
                  errorMessage={formik.errors.newPassword}
                  invalid={
                    !!formik.errors.newPassword && formik.touched.newPassword
                  }
                />
              </FormGroup>
              <FormGroup className="flex">
                <Label for="confirmPassword">Confirm password</Label>
                <PasswordInput
                  id="confirmPassword"
                  name="confirmPassword"
                  autoComplete="new-password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  errorMessage={formik.errors.confirmPassword}
                  invalid={
                    !!formik.errors.confirmPassword &&
                    (formik.touched.confirmPassword ||
                      formik.touched.newPassword)
                  }
                  isTouched={
                    formik.touched.confirmPassword || formik.touched.newPassword
                  }
                />
              </FormGroup>
            </FormSubSection>
          </FormItem>

          {user && (
            <PermissionWrapper
              permission={RolePermissions.PROFILE_TWO_FACTOR_AUTH_ADD}
            >
              <EnableMFAOption
                userEnrolled={user.mfaType !== MFAType.MFA_TYPE_UNKNOWN}
                ssoUser={
                  user?.o365AccountConnected || user?.googleAccountConnected
                }
              />
            </PermissionWrapper>
          )}

          <div className="d-flex justify-content-between">
            <PermissionWrapper
              permission={RolePermissions.PROFILE_PICTURE_ADD_EDIT}
            >
              <div>
                <FormItem
                  title="Profile picture"
                  message={tooltips.account.profilePicture}
                >
                  <UploadImage
                    image={user?.picture}
                    title="Upload your picture"
                    onUpload={(image) => updateUser({ picture: image })}
                    hasImage={!!user?.picture}
                  />
                </FormItem>
              </div>
            </PermissionWrapper>

            {allowAccountInfoPart && (
              <div className="d-flex flex-column align-items-center justify-content-around">
                <LabelWithInformationBox
                  for="accountCancellation"
                  title="Account cancellation"
                />
                <CancelAccount />
              </div>
            )}
          </div>
        </FormSection>
      </Form>
    </EditSettings>
  );
};
