import React from "react";
import { Icon, IconDefinition } from "../../../shared/Icon/Icon";

import "./WorkplacePrefixIcon.scss";

interface Props {
  icon: IconDefinition;
}

export const WorkplacePrefixIcon = (props: Props) => {
  return (
    <div className="WorkplacePrefixIcon">
      <Icon icon={props.icon} />
    </div>
  );
};
