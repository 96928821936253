import React from "react";
import { EWSFormik } from "./lib/useEWSFormik";
import { EWSServiceForm } from "./CalendarForms/EWSServiceForm";
import { CalendarsWizardFooter } from "./CalendarsWizardForm/CalendarsWizardFooter/CalendarsWizardFooter";
import { O365ConnectButton } from "./CalendarForms/O365ConnectButton/O365ConnectButton";
import { GSuiteConnectButton } from "./CalendarForms/GSuiteConnectButton/GSuiteConnectButton";
import { CalendarsWizardHeader } from "./CalendarsWizardForm/CalendarsWizardHeader/CalendarsWizardHeader";

interface Props {
  formik: EWSFormik;
  activeStep: number;
  activeCalendar: string;
  toggleModal?: () => void;
  handlePrevClick: () => void;
  handleSubmitClick: () => void;
  type?: string;
}

export const CalendarServiceAccount = (props: Props) => {
  return (
    <div className="CalendarServiceAccount">
      <CalendarsWizardHeader
        activeStep={props.activeStep}
        handleClick={(n) => console.info(n)}
        type={props.type}
      />

      {props.activeCalendar === "ews" && (
        <EWSServiceForm formik={props.formik} type={props.type} />
      )}

      {props.activeCalendar === "o365" && (
        <O365ConnectButton type={props.type || ""} />
      )}

      {props.activeCalendar === "gsuite" && (
        <GSuiteConnectButton
          type={props.type}
          toggleModal={props.toggleModal}
        />
      )}

      <CalendarsWizardFooter
        createButton
        disabledNext={
          !props.activeCalendar ||
          props.formik.isSubmitting ||
          !props.formik.dirty
        }
        activeStep={props.activeStep}
        activeCalendar={props.activeCalendar}
        handlePrevClick={props.handlePrevClick}
        handleSubmitClick={() => props.handleSubmitClick()}
      />
    </div>
  );
};
