import React, { useState } from "react";
import { toast } from "react-toastify";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useContacts } from "../../../api/grpc/contacts/useContacts";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { CompleteSignupFormik } from "../../../components/CompleteSignup/lib/useCompleteSignupFormik";
import { PrivacyTerms } from "../PrivacyTerms/PrivacyTerms";
import { ProviderButton } from "../../../components/Insights/shared/ProviderButtons/ProviderButton";

interface Props {
  formik: CompleteSignupFormik;
}

export const CompleteWithGoogle = ({ formik }: Props) => {
  const { signUpWithProvider } = useAuthContext();
  const { completeSocialRegistration } = useContacts();
  const { query } = useRouter();
  const { history } = useRouter();
  const [customError, setCustomError] = useState(false);

  const handleGoogleSignUp = async () => {
    if (!formik.values.privacyTerms) {
      return setCustomError(true);
    } else {
      setCustomError(false);
    }
    try {
      if (!query.customerID) {
        toast.error("TenantID not found to complete the sign up!");
        return;
      }
      const res = await signUpWithProvider(query.customerID, "google");

      await completeSocialRegistration(
        res?.user?.uid || "",
        res?.user?.email || "",
        query.customerID
      );

      // await signOutFromProvider();
      history.push("/account-confirmed");
      return;
    } catch (error: any) {
      toast.error(error?.message ?? "User not found!");
      console.error("error", error);
    }
  };

  return (
    <>
      <ProviderButton type="Google" handleClick={handleGoogleSignUp} />

      <PrivacyTerms
        formik={formik}
        className="mt-3"
        customError={customError}
      />
    </>
  );
};
