import React from "react";
import ContentLoader from "react-content-loader";

export const EditWorkplaceLoader = () => (
  <ContentLoader
    speed={2}
    width={1300}
    height={500}
    viewBox="0 0 1300 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="250" height="30" />
    <rect x="0" y="50" rx="25" ry="25" width="20" height="20" />
    <rect x="30" y="50" rx="25" ry="25" width="20" height="20" />
    <rect x="0" y="150" rx="0" ry="0" width="1200" height="3" />

    <rect x="0" y="120" rx="0" ry="0" width="80" height="15" />
    <rect x="100" y="120" rx="0" ry="0" width="80" height="15" />
    <rect x="200" y="120" rx="0" ry="0" width="80" height="15" />

    <rect x="1065" y="100" rx="20" ry="20" width="137" height="35" />

    <rect x="400" y="224" rx="0" ry="0" width="414" height="30" />
    <rect x="447" y="271" rx="0" ry="0" width="325" height="28" />
    <rect x="530" y="318" rx="20" ry="20" width="172" height="35" />
  </ContentLoader>
);
