import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import {
  OnLevelSearchRequest,
  OnLevelSearchResponse,
} from "../../../api/grpc/workplaces/workplaces";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";

export const useListWorkspaceChildren = ({
  id,
  licenseStatus,
  nameFilter,
  locationFilter,
  resourceFilter,
  tagsFilter,
}: Omit<OnLevelSearchRequest, "customerId">) => {
  const { searchWorkplaceItems } = useWorkplacesClient();

  const { loading, error, data, refetch } = useGrpcQuery<
    Omit<OnLevelSearchRequest, "customerId">,
    OnLevelSearchResponse
  >({
    method: searchWorkplaceItems,
    variables: {
      id,
      licenseStatus,
      nameFilter,
      locationFilter: locationFilter,
      resourceFilter: resourceFilter,
      tagsFilter: tagsFilter,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
