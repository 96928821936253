import React from "react";
import { NavLink } from "react-router-dom";
import { CenteredBox } from "../../components/shared/CenteredBox/CenteredBox";
import { GENERAL_STRINGS as t } from "../../lib/utils/constants";

export const Logout = () => {
  return (
    <>
      <CenteredBox constant={t.slogan}>
        <h3 className="text-center mb-2">Signed Out</h3>
        <h5 className="text-center mb-4 mt-2">
          You have been successfully logged out.
        </h5>
        <NavLink
          to="/login"
          className="btn btn-primary shadow-sm p-2 mb-2 text-uppercase w-100"
        >
          Sign Back In
        </NavLink>
        <NavLink
          to="/"
          className="p-2 text-uppercase text-center small-font-size w-100 text-dark"
        >
          Return to Home Page
        </NavLink>
      </CenteredBox>
    </>
  );
};
