// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auth.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Auth } from "./auth";
import type { ValidateCredentialsResponse } from "./auth";
import type { ValidateCredentialsRequest } from "./auth";
import type { DisableMFAAccountResponse } from "./auth";
import type { DisableMFAAccountRequest } from "./auth";
import type { CheckBasicAuthMethodRequest } from "./auth";
import type { SetAccountClaimsRequest } from "./auth";
import type { ExchangeSocialAuthTokenResponse } from "./auth";
import type { ExchangeSocialAuthTokenRequest } from "./auth";
import type { UpdatePrimaryEmailRequest } from "./auth";
import type { AuthDeviceByIDResponse } from "./auth";
import type { AuthDeviceByIDRequest } from "./auth";
import type { VerifyDeviceTokenRequest } from "./auth";
import type { DeleteAuthAccountRequest } from "./auth";
import type { AuthVerifyEmailRequest } from "./auth";
import type { ResetPasswordResponse } from "./auth";
import type { ResetPasswordLinkRequest } from "./auth";
import type { VerifyEmailLinkResponse } from "./auth";
import type { VerifyEmailLinkRequest } from "./auth";
import type { UpdateAuthAccountRequest } from "./auth";
import type { GetAccountByIdRequest } from "./auth";
import type { GetAccountByEmailRequest } from "./auth";
import type { Token } from "./auth";
import type { CustomTokenRequest } from "./auth";
import type { VerifyTokenResponse } from "./auth";
import type { VerifyTokenRequest } from "./auth";
import type { CreateSubscriptionClaimsRequest } from "./auth";
import type { CreateContactAccountRequest } from "./auth";
import type { CreateAdminAccountRequest } from "./auth";
import type { AuthAccount } from "./auth";
import type { AuthCreateAccountRequest } from "./auth";
import type { Empty } from "./google/protobuf/empty";
import type { DeleteTenantRequest } from "./auth";
import type { GetTenantRequest } from "./auth";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Tenant } from "./auth";
import type { CreateTenantRequest } from "./auth";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Auth
 */
export interface IAuthClient {
    /**
     * @generated from protobuf rpc: CreateTenant(CreateTenantRequest) returns (Tenant);
     */
    createTenant(input: CreateTenantRequest, options?: RpcOptions): UnaryCall<CreateTenantRequest, Tenant>;
    /**
     * @generated from protobuf rpc: GetTenant(GetTenantRequest) returns (Tenant);
     */
    getTenant(input: GetTenantRequest, options?: RpcOptions): UnaryCall<GetTenantRequest, Tenant>;
    /**
     * @generated from protobuf rpc: DeleteTenant(DeleteTenantRequest) returns (google.protobuf.Empty);
     */
    deleteTenant(input: DeleteTenantRequest, options?: RpcOptions): UnaryCall<DeleteTenantRequest, Empty>;
    /**
     * @generated from protobuf rpc: CreateAccount(AuthCreateAccountRequest) returns (AuthAccount);
     */
    createAccount(input: AuthCreateAccountRequest, options?: RpcOptions): UnaryCall<AuthCreateAccountRequest, AuthAccount>;
    /**
     * @generated from protobuf rpc: CreateAdminAccount(CreateAdminAccountRequest) returns (AuthAccount);
     */
    createAdminAccount(input: CreateAdminAccountRequest, options?: RpcOptions): UnaryCall<CreateAdminAccountRequest, AuthAccount>;
    /**
     * @generated from protobuf rpc: CreateContactAccount(CreateContactAccountRequest) returns (AuthAccount);
     */
    createContactAccount(input: CreateContactAccountRequest, options?: RpcOptions): UnaryCall<CreateContactAccountRequest, AuthAccount>;
    /**
     * @generated from protobuf rpc: CreateSubscriptionClaims(CreateSubscriptionClaimsRequest) returns (google.protobuf.Empty);
     */
    createSubscriptionClaims(input: CreateSubscriptionClaimsRequest, options?: RpcOptions): UnaryCall<CreateSubscriptionClaimsRequest, Empty>;
    /**
     * @generated from protobuf rpc: VerifyToken(VerifyTokenRequest) returns (VerifyTokenResponse);
     */
    verifyToken(input: VerifyTokenRequest, options?: RpcOptions): UnaryCall<VerifyTokenRequest, VerifyTokenResponse>;
    /**
     * @generated from protobuf rpc: GenerateCustomToken(CustomTokenRequest) returns (Token);
     */
    generateCustomToken(input: CustomTokenRequest, options?: RpcOptions): UnaryCall<CustomTokenRequest, Token>;
    /**
     * @generated from protobuf rpc: GetAccountByEmail(GetAccountByEmailRequest) returns (AuthAccount);
     */
    getAccountByEmail(input: GetAccountByEmailRequest, options?: RpcOptions): UnaryCall<GetAccountByEmailRequest, AuthAccount>;
    /**
     * @generated from protobuf rpc: GetAccountById(GetAccountByIdRequest) returns (AuthAccount);
     */
    getAccountById(input: GetAccountByIdRequest, options?: RpcOptions): UnaryCall<GetAccountByIdRequest, AuthAccount>;
    /**
     * @generated from protobuf rpc: UpdateAuthAccount(UpdateAuthAccountRequest) returns (AuthAccount);
     */
    updateAuthAccount(input: UpdateAuthAccountRequest, options?: RpcOptions): UnaryCall<UpdateAuthAccountRequest, AuthAccount>;
    /**
     * @generated from protobuf rpc: GenerateVerifyEmailLink(VerifyEmailLinkRequest) returns (VerifyEmailLinkResponse);
     */
    generateVerifyEmailLink(input: VerifyEmailLinkRequest, options?: RpcOptions): UnaryCall<VerifyEmailLinkRequest, VerifyEmailLinkResponse>;
    /**
     * @generated from protobuf rpc: GenerateResetPasswordLink(ResetPasswordLinkRequest) returns (ResetPasswordResponse);
     */
    generateResetPasswordLink(input: ResetPasswordLinkRequest, options?: RpcOptions): UnaryCall<ResetPasswordLinkRequest, ResetPasswordResponse>;
    /**
     * @generated from protobuf rpc: VerifyEmail(AuthVerifyEmailRequest) returns (google.protobuf.Empty);
     */
    verifyEmail(input: AuthVerifyEmailRequest, options?: RpcOptions): UnaryCall<AuthVerifyEmailRequest, Empty>;
    /**
     * @generated from protobuf rpc: DeleteAccount(DeleteAuthAccountRequest) returns (google.protobuf.Empty);
     */
    deleteAccount(input: DeleteAuthAccountRequest, options?: RpcOptions): UnaryCall<DeleteAuthAccountRequest, Empty>;
    /**
     * @generated from protobuf rpc: VerifyDeviceToken(VerifyDeviceTokenRequest) returns (VerifyTokenResponse);
     */
    verifyDeviceToken(input: VerifyDeviceTokenRequest, options?: RpcOptions): UnaryCall<VerifyDeviceTokenRequest, VerifyTokenResponse>;
    /**
     * @generated from protobuf rpc: AuthDeviceByID(AuthDeviceByIDRequest) returns (AuthDeviceByIDResponse);
     */
    authDeviceByID(input: AuthDeviceByIDRequest, options?: RpcOptions): UnaryCall<AuthDeviceByIDRequest, AuthDeviceByIDResponse>;
    /**
     * UpdatePrimaryEmail update primary email for account.
     * NOTE: this method set emails as verified. We need handle new email verification on our end
     *
     * @generated from protobuf rpc: UpdatePrimaryEmail(UpdatePrimaryEmailRequest) returns (AuthAccount);
     */
    updatePrimaryEmail(input: UpdatePrimaryEmailRequest, options?: RpcOptions): UnaryCall<UpdatePrimaryEmailRequest, AuthAccount>;
    /**
     * @generated from protobuf rpc: ExchangeSocialAuthToken(ExchangeSocialAuthTokenRequest) returns (ExchangeSocialAuthTokenResponse);
     */
    exchangeSocialAuthToken(input: ExchangeSocialAuthTokenRequest, options?: RpcOptions): UnaryCall<ExchangeSocialAuthTokenRequest, ExchangeSocialAuthTokenResponse>;
    /**
     * @generated from protobuf rpc: SetAccountClaims(SetAccountClaimsRequest) returns (google.protobuf.Empty);
     */
    setAccountClaims(input: SetAccountClaimsRequest, options?: RpcOptions): UnaryCall<SetAccountClaimsRequest, Empty>;
    /**
     * @generated from protobuf rpc: CheckBasicAuthMethod(CheckBasicAuthMethodRequest) returns (google.protobuf.Empty);
     */
    checkBasicAuthMethod(input: CheckBasicAuthMethodRequest, options?: RpcOptions): UnaryCall<CheckBasicAuthMethodRequest, Empty>;
    /**
     * @generated from protobuf rpc: DisableMFAForAccount(DisableMFAAccountRequest) returns (DisableMFAAccountResponse);
     */
    disableMFAForAccount(input: DisableMFAAccountRequest, options?: RpcOptions): UnaryCall<DisableMFAAccountRequest, DisableMFAAccountResponse>;
    /**
     * ValidateCredentials check if email and password is valid for tenant user.
     *
     * @generated from protobuf rpc: ValidateCredentials(ValidateCredentialsRequest) returns (ValidateCredentialsResponse);
     */
    validateCredentials(input: ValidateCredentialsRequest, options?: RpcOptions): UnaryCall<ValidateCredentialsRequest, ValidateCredentialsResponse>;
}
/**
 * @generated from protobuf service Auth
 */
export class AuthClient implements IAuthClient, ServiceInfo {
    typeName = Auth.typeName;
    methods = Auth.methods;
    options = Auth.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateTenant(CreateTenantRequest) returns (Tenant);
     */
    createTenant(input: CreateTenantRequest, options?: RpcOptions): UnaryCall<CreateTenantRequest, Tenant> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateTenantRequest, Tenant>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTenant(GetTenantRequest) returns (Tenant);
     */
    getTenant(input: GetTenantRequest, options?: RpcOptions): UnaryCall<GetTenantRequest, Tenant> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTenantRequest, Tenant>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTenant(DeleteTenantRequest) returns (google.protobuf.Empty);
     */
    deleteTenant(input: DeleteTenantRequest, options?: RpcOptions): UnaryCall<DeleteTenantRequest, Empty> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteTenantRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateAccount(AuthCreateAccountRequest) returns (AuthAccount);
     */
    createAccount(input: AuthCreateAccountRequest, options?: RpcOptions): UnaryCall<AuthCreateAccountRequest, AuthAccount> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthCreateAccountRequest, AuthAccount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateAdminAccount(CreateAdminAccountRequest) returns (AuthAccount);
     */
    createAdminAccount(input: CreateAdminAccountRequest, options?: RpcOptions): UnaryCall<CreateAdminAccountRequest, AuthAccount> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateAdminAccountRequest, AuthAccount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateContactAccount(CreateContactAccountRequest) returns (AuthAccount);
     */
    createContactAccount(input: CreateContactAccountRequest, options?: RpcOptions): UnaryCall<CreateContactAccountRequest, AuthAccount> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateContactAccountRequest, AuthAccount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSubscriptionClaims(CreateSubscriptionClaimsRequest) returns (google.protobuf.Empty);
     */
    createSubscriptionClaims(input: CreateSubscriptionClaimsRequest, options?: RpcOptions): UnaryCall<CreateSubscriptionClaimsRequest, Empty> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateSubscriptionClaimsRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: VerifyToken(VerifyTokenRequest) returns (VerifyTokenResponse);
     */
    verifyToken(input: VerifyTokenRequest, options?: RpcOptions): UnaryCall<VerifyTokenRequest, VerifyTokenResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerifyTokenRequest, VerifyTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GenerateCustomToken(CustomTokenRequest) returns (Token);
     */
    generateCustomToken(input: CustomTokenRequest, options?: RpcOptions): UnaryCall<CustomTokenRequest, Token> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomTokenRequest, Token>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccountByEmail(GetAccountByEmailRequest) returns (AuthAccount);
     */
    getAccountByEmail(input: GetAccountByEmailRequest, options?: RpcOptions): UnaryCall<GetAccountByEmailRequest, AuthAccount> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAccountByEmailRequest, AuthAccount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAccountById(GetAccountByIdRequest) returns (AuthAccount);
     */
    getAccountById(input: GetAccountByIdRequest, options?: RpcOptions): UnaryCall<GetAccountByIdRequest, AuthAccount> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAccountByIdRequest, AuthAccount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateAuthAccount(UpdateAuthAccountRequest) returns (AuthAccount);
     */
    updateAuthAccount(input: UpdateAuthAccountRequest, options?: RpcOptions): UnaryCall<UpdateAuthAccountRequest, AuthAccount> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateAuthAccountRequest, AuthAccount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GenerateVerifyEmailLink(VerifyEmailLinkRequest) returns (VerifyEmailLinkResponse);
     */
    generateVerifyEmailLink(input: VerifyEmailLinkRequest, options?: RpcOptions): UnaryCall<VerifyEmailLinkRequest, VerifyEmailLinkResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerifyEmailLinkRequest, VerifyEmailLinkResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GenerateResetPasswordLink(ResetPasswordLinkRequest) returns (ResetPasswordResponse);
     */
    generateResetPasswordLink(input: ResetPasswordLinkRequest, options?: RpcOptions): UnaryCall<ResetPasswordLinkRequest, ResetPasswordResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResetPasswordLinkRequest, ResetPasswordResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: VerifyEmail(AuthVerifyEmailRequest) returns (google.protobuf.Empty);
     */
    verifyEmail(input: AuthVerifyEmailRequest, options?: RpcOptions): UnaryCall<AuthVerifyEmailRequest, Empty> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthVerifyEmailRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAccount(DeleteAuthAccountRequest) returns (google.protobuf.Empty);
     */
    deleteAccount(input: DeleteAuthAccountRequest, options?: RpcOptions): UnaryCall<DeleteAuthAccountRequest, Empty> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteAuthAccountRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: VerifyDeviceToken(VerifyDeviceTokenRequest) returns (VerifyTokenResponse);
     */
    verifyDeviceToken(input: VerifyDeviceTokenRequest, options?: RpcOptions): UnaryCall<VerifyDeviceTokenRequest, VerifyTokenResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<VerifyDeviceTokenRequest, VerifyTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuthDeviceByID(AuthDeviceByIDRequest) returns (AuthDeviceByIDResponse);
     */
    authDeviceByID(input: AuthDeviceByIDRequest, options?: RpcOptions): UnaryCall<AuthDeviceByIDRequest, AuthDeviceByIDResponse> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthDeviceByIDRequest, AuthDeviceByIDResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdatePrimaryEmail update primary email for account.
     * NOTE: this method set emails as verified. We need handle new email verification on our end
     *
     * @generated from protobuf rpc: UpdatePrimaryEmail(UpdatePrimaryEmailRequest) returns (AuthAccount);
     */
    updatePrimaryEmail(input: UpdatePrimaryEmailRequest, options?: RpcOptions): UnaryCall<UpdatePrimaryEmailRequest, AuthAccount> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePrimaryEmailRequest, AuthAccount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ExchangeSocialAuthToken(ExchangeSocialAuthTokenRequest) returns (ExchangeSocialAuthTokenResponse);
     */
    exchangeSocialAuthToken(input: ExchangeSocialAuthTokenRequest, options?: RpcOptions): UnaryCall<ExchangeSocialAuthTokenRequest, ExchangeSocialAuthTokenResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<ExchangeSocialAuthTokenRequest, ExchangeSocialAuthTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetAccountClaims(SetAccountClaimsRequest) returns (google.protobuf.Empty);
     */
    setAccountClaims(input: SetAccountClaimsRequest, options?: RpcOptions): UnaryCall<SetAccountClaimsRequest, Empty> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetAccountClaimsRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckBasicAuthMethod(CheckBasicAuthMethodRequest) returns (google.protobuf.Empty);
     */
    checkBasicAuthMethod(input: CheckBasicAuthMethodRequest, options?: RpcOptions): UnaryCall<CheckBasicAuthMethodRequest, Empty> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckBasicAuthMethodRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DisableMFAForAccount(DisableMFAAccountRequest) returns (DisableMFAAccountResponse);
     */
    disableMFAForAccount(input: DisableMFAAccountRequest, options?: RpcOptions): UnaryCall<DisableMFAAccountRequest, DisableMFAAccountResponse> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<DisableMFAAccountRequest, DisableMFAAccountResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ValidateCredentials check if email and password is valid for tenant user.
     *
     * @generated from protobuf rpc: ValidateCredentials(ValidateCredentialsRequest) returns (ValidateCredentialsResponse);
     */
    validateCredentials(input: ValidateCredentialsRequest, options?: RpcOptions): UnaryCall<ValidateCredentialsRequest, ValidateCredentialsResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValidateCredentialsRequest, ValidateCredentialsResponse>("unary", this._transport, method, opt, input);
    }
}
