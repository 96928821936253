import React, { PropsWithChildren } from "react";
import { MainResources } from "../../../../lib/types/main.types";
import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import { ActionList } from "../ActionList/ActionList";

export const ProfileItem = ({
  id,
  name,
  icon,
  profileType,
  description,
  handleEditTemplate,
  handleDeleteTemplate,
}: PropsWithChildren<{
  id: string;
  name: string;
  icon: IconDefinition;
  description: string;
  profileType: MainResources;
  handleEditTemplate: () => void;
  handleDeleteTemplate: () => Promise<void>;
}>) => {
  return (
    <div className="ProfileTable__item">
      <Icon className="mb-1" icon={icon} />
      <h6 className="mb-1">{name}</h6>
      <span>{description}</span>
      <ActionList
        id={id}
        name={name}
        profileType={profileType}
        handleDeleteTemplate={handleDeleteTemplate}
        handleEditTemplate={handleEditTemplate}
      />
    </div>
  );
};
