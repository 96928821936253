import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";
import { FormikCustomWayfinderLanguageFields } from "../../WayFinderForm/types/types";

const handleCustomValidation = (value: any, validator: string) => {
  if (value.startsWith(validator)) {
    return value.includes(`${validator} `);
  }

  if (value.endsWith(validator)) {
    return value.includes(` ${validator}`);
  }

  return value.includes(` ${validator} `);
};

const CustomWayfinderLanguageSchema = Yup.object({
  name: Yup.string().required(f.required),
  common: Yup.object().shape({
    app: Yup.object().shape({
      version: Yup.string()
        .required(f.required)
        .test(
          "includes-version",
          "Version must include {version}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return handleCustomValidation(value, "{version}");
          }
        ),
      device_id: Yup.string()
        .required(f.required)
        .test(
          "includes-device-id",
          "Version must include {device_id}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return handleCustomValidation(value, "{device_id}");
          }
        ),
    }),
    actions: Yup.object().shape({
      submit: Yup.string().required(f.required),
      done: Yup.string().required(f.required),
      open: Yup.string().required(f.required),
      cancel: Yup.string().required(f.required),
      start: Yup.string().required(f.required),
      unpair: Yup.string().required(f.required),
    }),
    footer: Yup.object().shape({
      powered_by: Yup.string().required(f.required),
    }),
    errors: Yup.object().shape({
      unpair_error: Yup.string().required(f.required),
      unable_get_data: Yup.string()
        .required(f.required)
        .test(
          "includes-entity",
          "The field must include {entity}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return handleCustomValidation(value, "{entity}");
          }
        ),
      internet_connection: Yup.string().required(f.required),
    }),
    time: Yup.object().shape({
      all_day: Yup.string().required(f.required),
      time: Yup.string().required(f.required),
      free_all_day: Yup.string().required(f.required),
      free_until: Yup.string()
        .required(f.required)
        .test(
          "includes-time",
          "The field must include {time}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return handleCustomValidation(value, "{time}");
          }
        ),
      busy_all_day: Yup.string().required(f.required),
      busy_until: Yup.string()
        .required(f.required)
        .test(
          "includes-busy",
          "The field must include {time}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return handleCustomValidation(value, "{time}");
          }
        ),
      time_left: Yup.string()
        .required(f.required)
        .test(
          "includes-left",
          "The field must include {time}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return handleCustomValidation(value, "{time}");
          }
        ),
      now: Yup.string().required(f.required),
      from: Yup.string().required(f.required),
      until: Yup.string().required(f.required),
      duration: Yup.string().required(f.required),
    }),
    equipment: Yup.object().shape({
      accessibility: Yup.string().required(f.required),
      audio: Yup.string().required(f.required),
      video_conferencing: Yup.string().required(f.required),
      climate: Yup.string().required(f.required),
      desk_sit_stand: Yup.string().required(f.required),
      presentation_tech: Yup.string().required(f.required),
      charging_port: Yup.string().required(f.required),
      casting: Yup.string().required(f.required),
    }),
    entities: Yup.object().shape({
      desk: Yup.string().required(f.required),
      room: Yup.string().required(f.required),
    }),
  }),
  screens: Yup.object().shape({
    settings: Yup.object().shape({
      screen_title: Yup.string().required(f.required),
      general_tab_name: Yup.string().required(f.required),
      network_tab_name: Yup.string().required(f.required),
      datetime_tab_name: Yup.string().required(f.required),
      locale_tab_name: Yup.string().required(f.required),
      language_select_title: Yup.string().required(f.required),
      language_select_subtitle: Yup.string().required(f.required),
      orientation_title: Yup.string().required(f.required),
      orientation_subtitle: Yup.string().required(f.required),
      updates_title: Yup.string().required(f.required),
      updates_checking: Yup.string().required(f.required),
      no_updates_required: Yup.string().required(f.required),
      update_available: Yup.string()
        .required(f.required)
        .test(
          "includes-update-version",
          "The field must include {version}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return handleCustomValidation(value, "{version}");
          }
        ),
      update_to: Yup.string()
        .required(f.required)
        .test(
          "includes-update-to-version",
          "The field must include {version}.",
          (value) => {
            if (!value) {
              return;
            }

            return value.includes("{version}");
          }
        ),
      updates_info_error: Yup.string().required(f.required),
      update_failed: Yup.string().required(f.required),
      unassign_title: Yup.string().required(f.required),
      unassign_subtitle: Yup.string().required(f.required),
      unpair_title: Yup.string().required(f.required),
      unpair_subtitle: Yup.string().required(f.required),
      wifi_settings_title: Yup.string().required(f.required),
      wifi_settings_subtitle: Yup.string().required(f.required),
      lan_settings_title: Yup.string().required(f.required),
      lan_settings_subtitle: Yup.string().required(f.required),
      datetime_settings_title: Yup.string().required(f.required),
      datetime_settings_subtitle: Yup.string().required(f.required),
      localization_settings_title: Yup.string().required(f.required),
      localization_settings_subtitle: Yup.string().required(f.required),
    }),
    pairing: Yup.object().shape({
      pairing_code_title: Yup.string().required(f.required),
      pairing_code_description: Yup.string().required(f.required),
      device_paired_title: Yup.string().required(f.required),
      device_paired_subtitle: Yup.string().required(f.required),
      device_paired_label: Yup.string().required(f.required),
      new_code_btn_text: Yup.string().required(f.required),
      connection_error_title: Yup.string().required(f.required),
      connection_error_description: Yup.string().required(f.required),
    }),
    main_board: Yup.object().shape({
      events_title: Yup.string().required(f.required),
      events_list_empty: Yup.string().required(f.required),
      event_status_ongoing: Yup.string().required(f.required),
      event_status_later_today: Yup.string().required(f.required),
      rooms_title: Yup.string().required(f.required),
      rooms_list_empty: Yup.string().required(f.required),
      room_reserve_description: Yup.string().required(f.required),
      map_title: Yup.string().required(f.required),
      occupancy_desks: Yup.string().required(f.required),
      occupancy_rooms: Yup.string().required(f.required),
      occupancy_general: Yup.string().required(f.required),
      occupancy_general_calm: Yup.string().required(f.required),
      occupancy_general_crowded: Yup.string().required(f.required),
      occupancy_general_full: Yup.string().required(f.required),
    }),
  }),
  modals: Yup.object().shape({
    pin_code: Yup.object().shape({
      title: Yup.string().required(f.required),
      subtitle: Yup.string().required(f.required),
      wrong_pin: Yup.string().required(f.required),
    }),
    room_booking: Yup.object().shape({
      user_name_field: Yup.string().required(f.required),
      subject_field: Yup.string().required(f.required),
      people_count_field: Yup.string().required(f.required),
      online_meeting_field: Yup.string().required(f.required),
      book_now: Yup.string().required(f.required),
      book_success: Yup.string().required(f.required),
      qr_code_description: Yup.string().required(f.required),
      no_internet_error: Yup.string().required(f.required),
    }),
    desk_booking: Yup.object().shape({
      qr_code_description: Yup.string().required(f.required),
    }),
  }),
});

interface UseCustomLanguageSchemaOptions {
  enableReinitialize?: boolean;
  onSubmit: (
    values: FormikCustomWayfinderLanguageFields,
    formikHelpers: FormikHelpers<FormikCustomWayfinderLanguageFields>
  ) => Promise<any>;
}

export type WayfinderDisplaySettingsFormik = ReturnType<
  typeof useCustomWayfinderLanguageFormik
>;

export const useCustomWayfinderLanguageFormik = (
  data: FormikCustomWayfinderLanguageFields | null | undefined,
  opts: UseCustomLanguageSchemaOptions
) => {
  return useFormik<FormikCustomWayfinderLanguageFields>({
    initialValues: {
      name: data?.name || "",
      common: {
        app: {
          version: data?.common.app.version || "",
          device_id: data?.common.app.device_id || "",
        },
        actions: {
          submit: data?.common.actions.submit || "",
          done: data?.common.actions.done || "",
          open: data?.common.actions.open || "",
          cancel: data?.common.actions.cancel || "",
          start: data?.common.actions.start || "",
          unpair: data?.common.actions.unpair || "",
          close: data?.common.actions.close || "",
        },
        footer: {
          powered_by: data?.common.footer.powered_by || "",
        },
        errors: {
          unpair_error: data?.common.errors.unpair_error || "",
          unable_get_data: data?.common.errors.unable_get_data || "",
          internet_connection: data?.common.errors.internet_connection || "",
        },
        time: {
          all_day: data?.common.time.all_day || "",
          time: data?.common.time.time || "",
          free_all_day: data?.common.time.free_all_day || "",
          free_until: data?.common.time.free_until || "",
          busy_all_day: data?.common.time.busy_all_day || "",
          busy_until: data?.common.time.busy_until || "",
          time_left: data?.common.time.time_left || "",
          now: data?.common.time.now || "",
          from: data?.common.time.from || "",
          until: data?.common.time.until || "",
          duration: data?.common.time.duration || "",
        },
        equipment: {
          accessibility: data?.common.equipment.accessibility || "",
          audio: data?.common.equipment.audio || "",
          video_conferencing: data?.common.equipment.video_conferencing || "",
          climate: data?.common.equipment.climate || "",
          desk_sit_stand: data?.common.equipment.desk_sit_stand || "",
          presentation_tech: data?.common.equipment.presentation_tech || "",
          charging_port: data?.common.equipment.charging_port || "",
          casting: data?.common.equipment.casting || "",
        },
        entities: {
          desk: data?.common.entities.desk || "",
          room: data?.common.entities.room || "",
        },
      },
      screens: {
        settings: {
          screen_title: data?.screens.settings.screen_title || "",
          general_tab_name: data?.screens.settings.general_tab_name || "",
          network_tab_name: data?.screens.settings.network_tab_name || "",
          datetime_tab_name: data?.screens.settings.datetime_tab_name || "",
          locale_tab_name: data?.screens.settings.locale_tab_name || "",
          language_select_title:
            data?.screens.settings.language_select_title || "",
          language_select_subtitle:
            data?.screens.settings.language_select_subtitle || "",
          updates_title: data?.screens.settings.updates_title || "",
          updates_checking: data?.screens.settings.updates_checking || "",
          no_updates_required: data?.screens.settings.no_updates_required || "",
          update_available: data?.screens.settings.update_available || "",
          update_to: data?.screens.settings.update_to || "",
          updates_info_error: data?.screens.settings.updates_info_error || "",
          update_failed: data?.screens.settings.update_failed || "",
          unassign_title: data?.screens.settings.unassign_title || "",
          unassign_subtitle: data?.screens.settings.unassign_subtitle || "",
          unpair_title: data?.screens.settings.unpair_title || "",
          unpair_subtitle: data?.screens.settings.unpair_subtitle || "",
          wifi_settings_title: data?.screens.settings.wifi_settings_title || "",
          wifi_settings_subtitle:
            data?.screens.settings.wifi_settings_subtitle || "",
          lan_settings_title: data?.screens.settings.lan_settings_title || "",
          lan_settings_subtitle:
            data?.screens.settings.lan_settings_subtitle || "",
          datetime_settings_title:
            data?.screens.settings.datetime_settings_title || "",
          datetime_settings_subtitle:
            data?.screens.settings.datetime_settings_subtitle || "",
          localization_settings_title:
            data?.screens.settings.localization_settings_title || "",
          localization_settings_subtitle:
            data?.screens.settings.localization_settings_subtitle || "",
          orientation_subtitle:
            data?.screens.settings.orientation_subtitle || "",
          orientation_title: data?.screens.settings.orientation_title || "",
        },
        pairing: {
          pairing_code_title: data?.screens.pairing.pairing_code_title || "",
          pairing_code_description:
            data?.screens.pairing.pairing_code_description || "",
          device_paired_title: data?.screens.pairing.device_paired_title || "",
          device_paired_subtitle:
            data?.screens.pairing.device_paired_subtitle || "",
          device_paired_label: data?.screens.pairing.device_paired_label || "",
          new_code_btn_text: data?.screens.pairing.new_code_btn_text || "",
          connection_error_title:
            data?.screens.pairing.connection_error_title || "",
          connection_error_description:
            data?.screens.pairing.connection_error_description || "",
        },
        main_board: {
          events_title: data?.screens.main_board.events_title || "",
          events_list_empty: data?.screens.main_board.events_list_empty || "",
          event_status_ongoing:
            data?.screens.main_board.event_status_ongoing || "",
          event_status_later_today:
            data?.screens.main_board.event_status_later_today || "",
          rooms_title: data?.screens.main_board.rooms_title || "",
          rooms_list_empty: data?.screens.main_board.rooms_list_empty || "",
          room_reserve_description:
            data?.screens.main_board.room_reserve_description || "",
          map_title: data?.screens.main_board.map_title || "",
          occupancy_desks: data?.screens.main_board.occupancy_desks || "",
          occupancy_rooms: data?.screens.main_board.occupancy_rooms || "",
          occupancy_general: data?.screens.main_board.occupancy_general || "",
          occupancy_general_calm:
            data?.screens.main_board.occupancy_general_calm || "",
          occupancy_general_crowded:
            data?.screens.main_board.occupancy_general_crowded || "",
          occupancy_general_full:
            data?.screens.main_board.occupancy_general_full || "",
        },
      },
      modals: {
        pin_code: {
          title: data?.modals.pin_code.title || "",
          subtitle: data?.modals.pin_code.subtitle || "",
          wrong_pin: data?.modals.pin_code.wrong_pin || "",
        },
        room_booking: {
          user_name_field: data?.modals.room_booking.user_name_field || "",
          subject_field: data?.modals.room_booking.subject_field || "",
          people_count_field:
            data?.modals.room_booking.people_count_field || "",
          online_meeting_field:
            data?.modals.room_booking.online_meeting_field || "",
          book_now: data?.modals.room_booking.book_now || "",
          book_success: data?.modals.room_booking.book_success || "",
          qr_code_description:
            data?.modals.room_booking.qr_code_description || "",
          no_internet_error: data?.modals.room_booking.no_internet_error || "",
          in_use: data?.modals.room_booking.in_use || "",
          in_use_description:
            data?.modals.room_booking.in_use_description || "",
        },
        desk_booking: {
          qr_code_description:
            data?.modals.desk_booking.qr_code_description || "",
        },
      },
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: CustomWayfinderLanguageSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
