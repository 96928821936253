import React, { useRef } from "react";
import { useToggleModal } from "../../../lib/hooks/useToggleModal";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import cs from "classnames";
import { Icon } from "../Icon/Icon";

import "./FilterBox.scss";

interface Props {
  title?: string;
  children: JSX.Element;
  numberOfFilters?: number;
  disabledFilters?: boolean;
}

export const FilterBox = ({
  title,
  children,
  numberOfFilters,
  disabledFilters,
}: Props) => {
  const { toggleModal, isOpen } = useToggleModal();

  const ref = useRef(null);

  const classes = cs("FilterBox", {
    FilterBox__active: !!numberOfFilters || isOpen,
    disabled: disabledFilters,
  });

  useOutsideClickDetection(
    ref,
    () => {
      if (isOpen) {
        return toggleModal();
      }
    },
    isOpen
  );

  return (
    <div className={classes} ref={ref}>
      <div
        className="FilterBox__header"
        onClick={() => {
          if (disabledFilters) {
            return;
          }

          return toggleModal();
        }}
      >
        <Icon icon="filter-icon" />
        <h4>
          {title || "Filter"} {numberOfFilters && `(${numberOfFilters})`}
        </h4>
      </div>

      {isOpen && children}
    </div>
  );
};
