import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";

import { Icon } from "../../../shared/Icon/Icon";
import { BookingsMapReserve } from "./BookingsMapReserve";
import { BookingsMapDeleteModal } from "./BookingsMapDeleteModal/BookingsMapDeleteModal";
import { BusyTimeSlots } from "../../../../api/grpc/desk/ggevent/ggevent";
import { MainResources } from "../../../../lib/types/main.types";
import { RolePermissions } from "../../../../api/grpc/account/account";
import { UserViewType } from "../../../shared/UserViewType/UserViewType";
import { BookingsReserveLoader } from "./shared/BookingsReserveLoader";

import "./BookingsButtonsModal.scss";

interface Props {
  id: string;
  type: string;
  typeOfWorkplace: MainResources;
  isOpen: boolean;
  toggleModal: () => void;
  busySlots: BusyTimeSlots[];
  refetch: () => void;
}

export const BookingsButtonsModal = ({
  id,
  type,
  typeOfWorkplace,
  isOpen,
  toggleModal,
  busySlots,
  refetch,
}: Props) => {
  const { user } = useAuthContext();
  const { loading } = useCustomerContext();

  return (
    <Modal isOpen={isOpen} className="BookingsModal BookingsButtonsModal">
      {!user?.permissions?.includes(
        RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
      ) && <UserViewType />}

      <ModalHeader className="pt-3 pr-3 pb-0">
        <div onClick={toggleModal}>
          <Icon icon="close-icon" />
        </div>
      </ModalHeader>
      {type === "add" ? (
        <>
          {loading ? (
            <BookingsReserveLoader />
          ) : (
            <BookingsMapReserve
              id={id}
              typeOfWorkplace={typeOfWorkplace}
              refetch={refetch}
            />
          )}
        </>
      ) : (
        <BookingsMapDeleteModal
          type={typeOfWorkplace}
          id={id}
          busySlots={busySlots}
          toggleModal={toggleModal}
          refetch={refetch}
        />
      )}
    </Modal>
  );
};
