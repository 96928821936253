import React from "react";
import { FormGroup } from "reactstrap";

import { useRoomFormik } from "../../../../shared/Forms/RoomForm/lib/useRoomFormik";
import { useDisplaySettingsFormik } from "../../../../shared/Forms/DisplaySettingsForm/lib/useDisplaySettingsForm";
import { useTemplateFormik } from "../../../lib/useTemplateFormik";

import { Icon } from "../../../../shared/Icon/Icon";
import { Input } from "../../../../shared/Input/Input";
import { LabelWithInformationBox } from "../../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { RoomForm } from "../../../../shared/Forms/RoomForm/RoomForm";
import { DefaultTemplate } from "../../../../shared/DefaultTemplate/DefaultTemplate";
import { MainSettings } from "../../../../shared/Forms/DisplaySettingsForm/Sections/MainSettings";
import { DisplaySettings } from "../../../../shared/Forms/DisplaySettingsForm/Sections/DisplaySettings";
import { ReservationSettings } from "../../../../shared/Forms/DisplaySettingsForm/Sections/ReservationSettings";
import { CustomerSettings } from "../../../../../api/grpc/customer/customer";
import { CustomerSettingsVariables } from "../../../../Organization/OrganizationSettings/InsightsCard/Insights/Insights";
import { tooltips } from "../../../../../lib/utils/tooltips";

interface Props {
  templateFormik: ReturnType<typeof useTemplateFormik>;
  formik: ReturnType<typeof useRoomFormik>;
  displaySettingsFormik: ReturnType<typeof useDisplaySettingsFormik>;
  isBussinesOrPro?: boolean;
  loading: boolean;
  error: any;
  workingHoursData?: CustomerSettings;
  handleWorkingHours: ({
    errorReportingEmail,
  }: CustomerSettingsVariables) => void;
  refetch: () => void;
}

export const RoomProfileForm = ({
  templateFormik,
  formik,
  displaySettingsFormik,
  isBussinesOrPro,
  loading,
  error,
  workingHoursData,
  handleWorkingHours,
  refetch,
}: Props) => {
  const handleFormikChange = (
    field: string,
    value: string | string[] | boolean | number | number[] | Date | null
  ) => {
    displaySettingsFormik.setFieldValue(field, value);
    displaySettingsFormik.setFieldTouched(field);
  };

  return (
    <div className="RoomProfileForm">
      <>
        <div className="ProfilesForm__type TemplateManage__subTitle flex-a-center mb-2 mt-1">
          <Icon className="TemplateManage__room-profile" icon="room-profile" />

          <h6 className="pl-1">Template</h6>
        </div>
        <DefaultTemplate>
          <>
            <FormGroup>
              <LabelWithInformationBox
                for="profileName"
                title="Profile name"
                message={tooltips.templates.profileName}
                direction="right"
              />
              <Input
                id="profileName"
                name="profileName"
                type="text"
                value={templateFormik.values.profileName}
                onBlur={templateFormik.handleBlur}
                onChange={templateFormik.handleChange}
                isTouched={templateFormik.touched.profileName}
                errorMessage={templateFormik.errors.profileName}
                invalid={
                  !!templateFormik.errors.profileName &&
                  templateFormik.touched.profileName
                }
              />
            </FormGroup>

            <FormGroup>
              <LabelWithInformationBox
                for="profileDescription"
                title="Profile description"
                message={tooltips.templates.profileDescription}
                direction="right"
              />
              <Input
                type="text"
                id="profileDescription"
                name="profileDescription"
                onBlur={templateFormik.handleBlur}
                value={templateFormik.values.profileDescription}
                onChange={templateFormik.handleChange}
              />
            </FormGroup>
          </>
        </DefaultTemplate>

        <div className="ProfilesForm__type TemplateManage__subTitle flex-a-center mb-3">
          <Icon icon="rooms-icon" />

          <h6 className="pl-1">Room settings</h6>
        </div>

        <DefaultTemplate>
          <RoomForm
            formik={formik}
            loading={loading}
            error={error}
            workingHoursData={workingHoursData}
            handleWorkingHours={handleWorkingHours}
            isProfile
            isBussinesOrPro={isBussinesOrPro}
            className="ProfilesForm"
          />
        </DefaultTemplate>

        <div className="ProfilesForm__type TemplateManage__subTitle flex-a-center mb-3">
          <Icon icon="device_icon_48x48" />

          <h6 className="pl-1">Display settings</h6>
        </div>

        <DefaultTemplate className="mb-4">
          <MainSettings
            formik={displaySettingsFormik}
            onChange={(key, value) => handleFormikChange(key, value)}
            isBussinesOrPro={isBussinesOrPro}
            isProfile
            // roomId={roomId}
            // currentCustomLanguageId={currentCustomLanguageId}
            className="ProfilesForm"
          />
        </DefaultTemplate>
        <DefaultTemplate className="mb-4">
          <DisplaySettings
            formik={displaySettingsFormik}
            onChange={(key, value) => handleFormikChange(key, value)}
            isBussinesOrPro={isBussinesOrPro}
            className="ProfilesForm"
          />
        </DefaultTemplate>
        <DefaultTemplate className="mb-4">
          <ReservationSettings
            formik={displaySettingsFormik}
            onChange={(key, value) => handleFormikChange(key, value)}
            isBussinesOrPro={isBussinesOrPro}
            displayEditWorkHours
            isProfile
            className="ProfilesForm"
            loading={loading}
            error={error}
            workingHoursData={workingHoursData}
            refetch={refetch}
          />
        </DefaultTemplate>
      </>
    </div>
  );
};
