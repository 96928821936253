import React from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { NavLink } from "react-router-dom";

import { Input } from "../../shared/Input/Input";
import { LoginFormik } from "../../../views/Login/lib/useLoginFormik";
import { PasswordInput } from "../../shared/PasswordInput/PasswordInput";

import { GENERAL_STRINGS as t } from "../../../lib/utils/constants";
import { SubmitButton } from "../../shared/SubmitButton/SubmitButton";

interface Props {
  loading: boolean;
  formik: LoginFormik;
}

export const LoginForm = (props: Props) => {
  const { formik, loading } = props;

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className="d-flex flex-column w-100 flex"
    >
      <FormGroup>
        <Label for="username">Username</Label>
        <Input
          type="email"
          id="username"
          name="username"
          onBlur={formik.handleBlur}
          value={formik.values.username}
          onChange={formik.handleChange}
          isTouched={formik.touched.username}
          errorMessage={formik.errors.username}
          invalid={!!formik.errors.username && formik.touched.username}
        />
      </FormGroup>
      <FormGroup className="mb-1">
        <Label for="password">Password</Label>
        <PasswordInput
          id="password"
          name="password"
          onBlur={formik.handleBlur}
          value={formik.values.password}
          onChange={formik.handleChange}
          isTouched={formik.touched.password}
          errorMessage={formik.errors.password}
          invalid={!!formik.errors.password && formik.touched.password}
        />
      </FormGroup>
      <NavLink className="mt-2 mb-4 d-inline-block" to="/reset-password">
        Forgot your password?
      </NavLink>
      <div className="AuthenticationPage__buttons">
        <SubmitButton
          size="large"
          title={t.logIn}
          loading={loading}
          loadingTitle="Logging in"
        />

        <NavLink to="/register" className="btn btn-large btn-outline-primary">
          Sign Up
        </NavLink>
      </div>
    </Form>
  );
};
