import React from "react";
import cs from "classnames";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { MeetingsAveragePercentage } from "./MeetingsAveragePercentage";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import { Icon } from "../../../shared/Icon/Icon";
import { LabelDirectionType } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";

interface Props {
  title?: string;
  value: number | string;
  percentage?: number;
  description: string;
  className?: string;
  message?: string;
  titleDescription?: string;
  cardDescription?: string;
  cardIcon?: IconDefinition;
  positiveCardValue?: boolean;
  direction?: LabelDirectionType;
}

export const MeetingsAverageRow = ({
  title,
  value,
  percentage,
  description,
  className,
  message,
  titleDescription,
  cardDescription,
  cardIcon,
  positiveCardValue,
  direction,
}: Props) => {
  const classes = cs(className, "MeetingsAverageRow");
  return (
    <div className={classes}>
      <div className="flex-a-center">
        <h6 className="pr-1">{title}</h6>
        {message && <InformationBox message={message} direction={direction} />}
      </div>
      {titleDescription && (
        <span className="MeetingsAverageRow__description">
          {titleDescription}
        </span>
      )}
      <div className="MeetingsAverageRow__value">
        <h6 className="pt-3">{value}</h6>
        <span className="MeetingsAverageRow__description pb-2">
          {description}
        </span>

        {(!!percentage || percentage === 0) && (
          <MeetingsAveragePercentage percentage={Math.round(percentage)} />
        )}

        {cardDescription && (
          <div
            className={`mt-2 MeetingsAverageRow__card--description
            MeetingsAverageRow__card--description--${
              positiveCardValue ? "active" : "inactive"
            }
            flex-a-center`}
          >
            <Icon icon={cardIcon} />
            <span className="pl-2">{cardDescription}</span>
          </div>
        )}
      </div>
    </div>
  );
};
