import React from "react";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
// import { CloudDeployment } from "./CloudDeployment/CloudDeployment";

import { redirectToO365 } from "./o365-popup";

import "./O365ConnectButton.scss";

interface Props {
  type: string;
}

export const O365ConnectButton = ({ type }: Props) => {
  const { openEnvironment } = useCalendarContext();

  return (
    <div className="O365ConnectButton">
      <div className="O365ConnectButton__button mt-3">
        <button
          onClick={(e) => {
            e.preventDefault();
            redirectToO365(type, openEnvironment);
          }}
        ></button>
      </div>
      {/* <CloudDeployment /> */}
    </div>
  );
};
