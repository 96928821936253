import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { useMap } from "react-leaflet";
import { ConsumerAction, Resource } from "@gogetcorp/floor-map-bridge";
// types
import { MessageEvent } from "../types/message.types";

type MessageListenerProps = {
  zoom: {
    initial: number;
    max: number;
    min: number;
  };
  onOverlayUrlChange: (url: ConsumerAction.OverlaySet["payload"]) => void;
  onMarkersChange: Dispatch<
    SetStateAction<ConsumerAction.MarkersSet["payload"]>
  >;
  onMarkerFocusChange: (markerId: string | null) => void;
  onBgColorChange: (bgColor: ConsumerAction.SetBgColor["payload"]) => void;
  onMarkerReservationFocusSet: (markerReservationId: string) => void;
  onMarkerReservationFocusClear: (markerReservationId: string) => void;
  onMapConfigChange: (config: Resource.MapConfig) => void;
  onMapCenter: () => void;
};

const MessageListener: FC<MessageListenerProps> = ({
  onOverlayUrlChange,
  onMarkersChange,
  onMarkerFocusChange,
  onBgColorChange,
  onMapConfigChange,
  onMarkerReservationFocusSet,
  onMarkerReservationFocusClear,
  onMapCenter,
}) => {
  // hooks
  const map = useMap();

  const zoomSetAction = useCallback(
    ({ payload }: ConsumerAction.ZoomSet) => {
      if (payload === "zoomIn") {
        map.zoomIn();
      }

      if (payload === "zoomOut") {
        map.zoomOut();
      }
    },
    [map]
  );

  const overlaySetAction = useCallback(
    ({ payload }: ConsumerAction.OverlaySet) => {
      onOverlayUrlChange(payload);
    },
    [onOverlayUrlChange]
  );

  const markersSetAction = useCallback(
    ({ payload }: ConsumerAction.MarkersSet) => {
      onMarkersChange((prev) => ({ prev, ...payload }));
    },
    [onMarkersChange]
  );

  const bgColorSetAction = useCallback(
    ({ payload }: ConsumerAction.SetBgColor) => {
      onBgColorChange(payload);
    },
    [onBgColorChange]
  );

  const onMessage = useCallback(
    ({ data }: MessageEvent) => {
      try {
        const parcedData = JSON.parse(`${data}`) as unknown;

        if (ConsumerAction.isConsumerAction(parcedData)) {
          if (parcedData.type === "zoom-set") {
            // todo: upgrade ts version and remove `as`
            zoomSetAction(parcedData as ConsumerAction.ZoomSet);
          }

          if (parcedData.type === "overlay-set") {
            overlaySetAction(parcedData as ConsumerAction.OverlaySet);
          }

          if (parcedData.type === "markers-set") {
            markersSetAction(parcedData as ConsumerAction.MarkersSet);
          }

          if (parcedData.type === "focus-marker") {
            onMarkerFocusChange(
              (parcedData as ConsumerAction.MarkerFocus).payload
            );
          }

          if (parcedData.type === "focus-clear") {
            onMarkerFocusChange(null);
          }

          if (parcedData.type === "focus-reservation-marker") {
            onMarkerReservationFocusSet(
              (parcedData as ConsumerAction.ReservationMarkerFocus).payload
            );
          }

          if (parcedData.type === "focus-reservation-marker-clear") {
            onMarkerReservationFocusClear(
              (parcedData as ConsumerAction.ReservationMarkerFocus).payload
            );
          }

          if (parcedData.type === "set-bg-color") {
            bgColorSetAction(parcedData as ConsumerAction.SetBgColor);
          }

          if (parcedData.type === "set-map-config") {
            onMapConfigChange(parcedData.payload);
          }

          if (parcedData.type === "center-map") {
            onMapCenter();
          }
        }
      } catch {}
    },
    [
      zoomSetAction,
      overlaySetAction,
      markersSetAction,
      bgColorSetAction,
      onMarkerFocusChange,
      onMarkerReservationFocusSet,
      onMarkerReservationFocusClear,
      onMapConfigChange,
      onMapCenter,
    ]
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);
    // @ts-ignore
    document.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
      // @ts-ignore
      document.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  return null;
};

export { MessageListener };
