import { useMemo } from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { transport } from "../grpcTransport";
import { HistoryEventsRequest, TimeRangeEventsRequest } from "./insights";
import { InsightsClient } from "./insights.client";

export type TimeRangeRequest = Omit<TimeRangeEventsRequest, "customerID">;

export const useDeskInsightsRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const insightsClient = useMemo(() => new InsightsClient(transport), []);

  const getDesksOverview = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.deskInsightsOverview(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDeskReservationTimeRange = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.deskReservationsTimeRange(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getGeneralDeskStats = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.generalStatsByDesks(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDeskHeatMapResevations = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.deskEventsHeatMap(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDeskTotalOccupancy = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.desksTotalOccupancy(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const exportDeskInsights = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.desksInsightsExport(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDesksMeetingBehaviour = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.desksMeetingBehavior(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDesksSankeyChart = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
  }: TimeRangeRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.desksSankeyChart(
      {
        customerID: user.customerid,
        locationID: locationID,
        startDate: startDate,
        endDate: endDate,
        timeZone,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDeskReservationsHistory = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
    limit,
    offset,
    organizers,
  }: Omit<HistoryEventsRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.deskHistoryEvents(
      {
        customerID: user.customerid,
        locationID,
        startDate,
        endDate,
        timeZone,
        limit,
        offset,
        organizers,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const exportDeskHistoryInsights = async ({
    locationID,
    startDate,
    endDate,
    timeZone,
    limit,
    offset,
    organizers,
  }: Omit<HistoryEventsRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return insightsClient.deskHistoryEventsExport(
      {
        customerID: user.customerid,
        locationID,
        startDate,
        endDate,
        timeZone,
        limit,
        offset,
        organizers,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    getGeneralDeskStats,
    getDesksOverview,
    getDeskReservationTimeRange,
    getDeskHeatMapResevations,
    getDeskTotalOccupancy,
    exportDeskInsights,
    getDesksMeetingBehaviour,
    getDesksSankeyChart,
    getDeskReservationsHistory,
    exportDeskHistoryInsights,
  };
};
