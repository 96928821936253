import { useEffect, useMemo, useState } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";
import { LicenseResponse } from "./subscription";
import { SubscriptionServiceClient } from "./subscription.client";
import { LicenseUpdateRequest } from "../../graphql/useWorkplaceRequests";

export const useLicenses = () => {
  const { user, tokenInterceptor } = useAuthContext();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LicenseResponse | undefined>();

  const subscriptionClient = useMemo(
    () => new SubscriptionServiceClient(transport),
    []
  );

  useEffect(() => {
    handleRequest();
  }, []);

  const handleRequest = async () => {
    if (data !== undefined) {
      return;
    }

    try {
      setLoading(true);
      const { response } = await subscriptionClient.licencesList(
        {
          customerId: user?.customerid || "",
          subscriptionId: user?.claims.subscriptionId,
        },
        {
          interceptors: [tokenInterceptor],
        }
      );

      setData(response.response[0]);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateChargeBeeLicense = async (variables: LicenseUpdateRequest) => {
    if (!user) {
      throw new Error("No user found when updating the license!");
    }

    const request = {
      entityId: variables.id,
      entityType: variables.type,
      customerId: user.customerid,
      subscriptionId: user.claims.subscriptionId,
    };

    if (variables.value) {
      return await subscriptionClient.licenceEntity(request, {
        interceptors: [tokenInterceptor],
      });
    }

    return await subscriptionClient.unlicenceEntity(request, {
      interceptors: [tokenInterceptor],
    });
  };

  return {
    updateChargeBeeLicense,
    loading,
    error,
    data,
  };
};
