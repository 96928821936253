import React, { useEffect, useState } from "react";

import { useRouter } from "../../lib/hooks/useRouter";
import { useAccountRequests } from "../../api/grpc/account/useAccountRequests";

import { CenteredLoader } from "../../components/shared/CenteredLoader/CenteredLoader";
import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";
import { HandleRequestState } from "../../components/shared/HandleRequestState/HandleRequestState";
import { AccountMessage } from "../../components/shared/AuthenticationPage/AccountMessage/AccountMessage";

export const VerifyAccount = () => {
  const { query } = useRouter();
  const [loading, setLoading] = useState(true);
  const { verifyAccount } = useAccountRequests();
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    handleRequest();
  }, []);

  const handleRequest = async () => {
    if (!query.token) {
      return;
    }

    try {
      await verifyAccount({
        apiKey: query.apiKey,
        customerId: query.tenantId,
        token: query.token,
      });
    } catch (error: any) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthenticationPage fullWidth>
      <HandleRequestState state={loading} placeholder={<CenteredLoader />}>
        <HandleRequestState
          state={!!error}
          placeholder={
            <AccountMessage
              title="Try verifying your email again"
              message="Your request to verify your email has expired or the link has already been used"
            />
          }
        >
          <AccountMessage
            title="Your account has been verified"
            message="You can now sign in with your account"
          />
        </HandleRequestState>
      </HandleRequestState>
    </AuthenticationPage>
  );
};
