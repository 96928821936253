import { useMemo } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";
import {
  ApplyDeskTemplateRequest,
  ApplyRoomTemplateRequest,
  CreateDeskTemplateRequest,
  CreateRoomTemplateRequest,
  UpdateDeskTemplateRequest,
  UpdateRoomTemplateRequest,
} from "./workplaces";
import { WorkplacesClient } from "./workplaces.client";

export const useTemplatesClient = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const workplacesClient = useMemo(() => new WorkplacesClient(transport), []);

  const listDeskTemplates = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.listDeskTemplates(
      {
        customerId: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createDeskTemplate = async ({
    name,
    description,
    deskSettings,
  }: Omit<CreateDeskTemplateRequest, "tenantId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.createDeskTemplate(
      {
        tenantId: user.customerid,
        name,
        description,
        deskSettings,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateDeskTemplate = async ({
    id,
    name,
    description,
    deskSettings,
  }: UpdateDeskTemplateRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.updateDeskTemplate(
      {
        tenantId: user.customerid,
        id: id,
        name,
        description,
        deskSettings,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteDeskTemplate = async (id: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.deleteDeskTemplate(
      {
        customerId: user.customerid,
        id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listRoomTemplates = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.listRoomTemplates(
      {
        customerId: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createRoomTemplate = async ({
    name,
    description,
    displaySettings,
    roomSettings,
  }: Omit<CreateRoomTemplateRequest, "tenantId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.createRoomTemplate(
      {
        tenantId: user.customerid,
        name,
        description,
        displaySettings,
        roomSettings,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateRoomTemplate = async ({
    id,
    name,
    description,
    displaySettings,
    roomSettings,
  }: UpdateRoomTemplateRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.updateRoomTemplate(
      {
        tenantId: user.customerid,
        id: id,
        name,
        description,
        displaySettings,
        roomSettings,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteRoomTemplate = async (id: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.deleteRoomTemplate(
      {
        customerId: user.customerid,
        id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const applyRoomTemplate = async ({
    roomsId,
    displaySettingsFields,
    roomSettingsFields,
    templateId,
  }: Omit<ApplyRoomTemplateRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.applyRoomTemplate(
      {
        customerId: user.customerid,
        roomsId,
        displaySettingsFields,
        roomSettingsFields,
        templateId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const applyDeskTemplate = async ({
    deskSettingsFields,
    desksId,
    templateId,
  }: Omit<ApplyDeskTemplateRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.applyDeskTemplate(
      {
        customerId: user.customerid,
        deskSettingsFields,
        desksId,
        templateId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    listDeskTemplates,
    createDeskTemplate,
    updateDeskTemplate,
    deleteDeskTemplate,
    listRoomTemplates,
    createRoomTemplate,
    updateRoomTemplate,
    deleteRoomTemplate,
    applyDeskTemplate,
    applyRoomTemplate,
  };
};
