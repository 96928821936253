import React, { PropsWithChildren, useEffect } from "react";
import { toast } from "react-toastify";
import { useDirectoryIntegrationsRequests } from "../../../api/grpc/calendarprovider/useDirectoryIntegrationsRequests";
import { useRouter } from "../../../lib/hooks/useRouter";

import {
  CalendarType,
  GsuiteConnection,
  ListDirectoryProviderResponse,
  O365Connection,
} from "../../../api/grpc/calendarprovider/calendarprovider";
import { WorkplacesTreeLoader } from "../../../views/Manage/Workplaces/WorkplacesTree/WorkplacesTreeLoader/WorkplacesTreeLoader";
import { AddCalendarConnections } from "../../Calendars/AddCalendarConnections/AddCalendarConnections";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { PageSidebar } from "../../shared/PageSidebar/PageSidebar";
import { PageSidebarHeader } from "../../shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { DirectoryIntegrationsList } from "../DirectoryIntegrations/DirectoryIntegrationsList/DirectoryIntegrationsList";
import { DIRECTORY_ROOT_PATH } from "../../../lib/routes/routes";
import { tooltips } from "../../../lib/utils/tooltips";
import { Icon } from "../../shared/Icon/Icon";

export const DirectoryIntegrationPicker = ({
  loading,
  error,
  data,
  refetch,
}: PropsWithChildren<{
  loading: boolean;
  error?: Error;
  data?: ListDirectoryProviderResponse | undefined;
  refetch: () => void;
}>) => {
  const { pathname, location, history } = useRouter();
  const { createDirectoryIntegration } = useDirectoryIntegrationsRequests();
  const { reAuthenticateDirectoryIntegration } =
    useDirectoryIntegrationsRequests();
  const isDirectoryPage = pathname === DIRECTORY_ROOT_PATH;

  useEffect(() => {
    finishDirectoryIntegration();
  }, []);

  const finishDirectoryIntegration = async () => {
    // microsoft returns "#" instead of "?", so we can't simply access code query
    // instead we need an extra step which is to get the hash value from the entire url:
    const searchParams = new URLSearchParams(location.search);
    let definedCode;
    const isDirectoryReAuth = !!localStorage.getItem("directoryReAuth")?.length;

    const type = searchParams.get("type");

    if (type && type === "google") {
      definedCode = searchParams.get("code");
    } else {
      const hash = window.location.hash;

      const codeFromHash = new URLSearchParams(hash.slice(1)).get("code");

      definedCode = codeFromHash;
    }

    if (!definedCode) {
      return;
    }

    try {
      if (isDirectoryReAuth) {
        const providerID = localStorage.getItem("directoryReAuth") as string;
        const isO365 = providerID.toLowerCase().startsWith("o365");

        let cType:
          | { oneofKind: "gsuite"; gsuite: GsuiteConnection }
          | { oneofKind: "o365"; o365: O365Connection }
          | { oneofKind: undefined } = {
          oneofKind: undefined,
        };

        if (isO365) {
          cType = {
            oneofKind: "o365",
            o365: {
              calendarType: CalendarType.O365_SYNC,
              oAuthToken: definedCode,
            },
          };
        } else {
          cType = {
            oneofKind: "gsuite",
            gsuite: {
              calendarType: CalendarType.GSUITE_SYNC,
              oAuthToken: definedCode,
            },
          };
        }

        await reAuthenticateDirectoryIntegration({
          providerID,
          cType,
        });

        toast.success(
          <div className="SuccessToast">
            <div className="flex-a-center">
              <Icon icon="check-icon" />
              <span className="ml-2">
                Re-authentication finished successfully!
              </span>
            </div>
          </div>,
          {
            position: toast.POSITION.TOP_LEFT,
          }
        );
      } else {
        toast.info("Finishing adding integration...");

        await createDirectoryIntegration({
          oAuthToken: definedCode,
          providerType:
            type === "google"
              ? CalendarType.GSUITE_SYNC
              : CalendarType.O365_SYNC,
        });

        toast.success(`Integration added successfully!`);
      }

      refetch();
    } catch (error: any) {
      console.error(error.message);
      toast.error(
        error?.message ?? "Integration couldn't be added or reauthenticated!"
      );
    } finally {
      if (isDirectoryReAuth) {
        localStorage.removeItem("directoryReAuth");
      }
      setTimeout(() => {
        history.push("/users/sync");
      }, 2000);
    }
  };

  return (
    <PageSidebar
      className="PageSidebar--default PageSidebar--Custom"
      showOnInitialization={isDirectoryPage}
      hideToggler={isDirectoryPage}
      alwaysShowToggler={true}
    >
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<WorkplacesTreeLoader />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={<p>Data couldn't be loaded.</p>}
        >
          <PageSidebarHeader
            title="Directory Integrations"
            message={tooltips.directorySync.integrations}
            direction="left"
            onTitleClick={() => {}}
            hasZIndex
          />

          <DirectoryIntegrationsList data={data?.integrations} />
          <AddCalendarConnections isDirectoryUser={true} />
        </HandleRequestState>
      </HandleLoadingState>
    </PageSidebar>
  );
};
