import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useZoneFormik } from "../../../shared/Forms/ZoneForm/lib/useZoneFormik";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";

import { SubRouteProps } from "../../../../lib/routes/routes";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import { tooltips } from "../../../../lib/utils/tooltips";
import { EditSettings } from "../EditSettings/EditSettings";
import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import {
  ZoneRequestVariables,
  ZONE_FIELDS_FRAGMENT,
} from "../../../../api/graphql/zones/zones";
import { CompanyForm } from "../../../shared/Forms/CompanyForm/CompanyForm";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";
import { EditViewsTabWorkspaces } from "../EditViewsTabWorkspaces/EditViewsTabWorkspaces";

export const EditZone = (props: EditWorkplaceProps) => {
  const { id } = props;
  const { match } = useRouter();
  const client = useApolloClient();
  const { customer } = useCustomerContext();
  const { updateZone } = useWorkplaceRequests();
  const [zoneData, setZoneData] = useState<WorkplaceFields | null>(null);

  // TO DO: Move on how the data are loaded to a hook useWorkplaceData
  const updateData = useCallback(() => {
    setZoneData(
      client.readFragment({
        id: `Zone:${id}`,
        fragmentName: "ZoneFields",
        fragment: ZONE_FIELDS_FRAGMENT,
      })
    );
  }, [id]);

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useZoneFormik({
    initialValues: {
      name: zoneData?.name || "",
      tags: zoneData?.tags || [],
      description: zoneData?.description || "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const removedTags =
          zoneData?.tags.filter((item) => !values.tags.includes(item)) || [];

        const variables: ZoneRequestVariables = {
          id: props.id,
          name: values.name,
          tags: values.tags,
          description: values.description,
          tenantId: customer.tenantId,
          remove: {
            tags: removedTags,
          },
        };

        await updateZone(variables);

        toast.success("Zone updated successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Zone couldn't be updated, please reload and try again!"
        );
        console.error(error?.message);
      }
    },
  });

  const rows: WorkplaceListData[] = [
    {
      data: zoneData?.rooms,
      type: "Room",
    },
    {
      data: zoneData?.desks,
      type: "Desk",
    },
    {
      data: zoneData?.wayfinders,
      type: "Wayfinder",
    },
  ];

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "View",
      to: match.url,
      id: "view-page-zone",
      render: () => <EditViewsTabWorkspaces type="Zone" id={id} rows={rows} />,
    },
    {
      exact: true,
      label: "Settings",
      id: "settings-page-zone",
      to: `${match.url}/settings`,
      render: () => (
        <EditSettings
          title="General settings"
          // hasChanges={formik.dirty}
          onSubmit={formik.submitForm}
        >
          <CompanyForm formik={formik} />
        </EditSettings>
      ),
    },
  ];

  return (
    <EditWorkplaceWrapper
      type="Zone"
      id={props.id}
      routes={routes}
      data={zoneData || undefined}
      message={tooltips.workplaces.zone}
    />
  );
};
