import React, { useState } from "react";
import cs from "classnames";
import { FormFeedback, Input, InputProps } from "reactstrap";

import { Icon } from "../Icon/Icon";

import "./PasswordInput.scss";

interface Props extends InputProps {
  isTouched?: boolean;
  errorMessage?: string;
}

export const PasswordInput = (props: Props) => {
  const { errorMessage, isTouched, ...inputProps } = props;

  const [showPassword, setShowPassword] = useState(false);

  const classes = cs("PasswordInput", {
    "PasswordInput--invalid": props.invalid,
  });

  return (
    <>
      <div className={classes}>
        <Input {...inputProps} type={showPassword ? "text" : "password"} />
        <div
          className="PasswordInput__icon"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {showPassword ? (
            <Icon icon="eye-slash-regular" />
          ) : (
            <Icon icon="eye-regular" />
          )}
        </div>
      </div>
      {isTouched && (
        <FormFeedback className={props.invalid ? "invalid-feedback" : ""}>
          {errorMessage}
        </FormFeedback>
      )}
    </>
  );
};
