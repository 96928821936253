import React from "react";
import { format } from "date-fns";

import {
  TimeRangeRequest,
  useInsightsRequests,
} from "../../../../../api/grpc/insights/useInsightsRequests";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { useInsightsContext } from "../../InsightsContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import {
  UtilizationInsights,
  UtilizationInsightsContext,
} from "./UtilizationContext";
import {
  HeatMapResponse,
  TotalOccupancyResponse,
} from "../../../../../api/grpc/insights/insights";

interface Props {
  children: React.ReactNode;
}

export const UtilizationContextProvider = ({ children }: Props) => {
  const { getTotalOccupancy, getRoomHeatMapEvents } = useInsightsRequests();
  const { timeZone } = useTimeZoneContext();

  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const { loading, error, data } = useGrpcQuery<
    TimeRangeRequest,
    TotalOccupancyResponse
  >({
    method: getTotalOccupancy,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingDensity,
    error: errorDensity,
    data: roomsDensity,
  } = useGrpcQuery<TimeRangeRequest, HeatMapResponse>({
    method: getRoomHeatMapEvents,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const context: UtilizationInsights = {
    loading,
    error,
    totalOccupancy: data,
    loadingDensity,
    errorDensity,
    roomsDensity,
  };

  return (
    <UtilizationInsightsContext.Provider value={context}>
      {children}
    </UtilizationInsightsContext.Provider>
  );
};
