import { createContext, useContext } from "react";
import { DeskTemplate } from "../../../../api/grpc/workplaces/workplaces";

export interface IDeskTemplateContext {
  deskTemplates: DeskTemplate[];
  errorDeskTemplates?: string;
  loadingDeskTemplate: boolean;
  refetchDeskTemplates: () => Promise<void>;
}

export const DeskTemplateContext = createContext<IDeskTemplateContext>({
  deskTemplates: [],
  errorDeskTemplates: undefined,
  loadingDeskTemplate: false,
  refetchDeskTemplates: async () => {},
});

export const useDeskTemplateContext = () => useContext(DeskTemplateContext);

export const { Provider } = DeskTemplateContext;
