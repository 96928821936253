import React, { useState } from "react";
import { toast } from "react-toastify";
import { utcToZonedTime } from "date-fns-tz";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";

import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";

export const CheckInDeskModal = ({
  type,
  // workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  disableEventActions,
}: ExtendedProps) => {
  const { checkInDesk } = useDeskBookingRequest();
  const { timeZone } = useTimeZoneContext();
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  console.log(event);
  const handleCheckInDesk = async () => {
    try {
      setLoading(true);
      await checkInDesk(event?.metaData?.DESK_EVENT_ID);

      toast.success("Check-in finished successfully");

      if (refetchDesk) {
        refetchDesk();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(
        error?.message || "Check-in failed, please reaload and try again!"
      );
    }
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title={`${
        user?.email === event.organizer?.email
          ? "Check in?"
          : "Check in for another user?"
      }`}
      icon="check-in-reservation"
      workspaceType={type}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={dateToHoursAndMinutes(new Date(endTime), timeZone)}
      userName={userName}
      locationPath={locationPath}
      type="check-in"
      buttonClick={handleCheckInDesk}
      buttonTitle="Confirm"
      subject={event?.title}
      handleModalClose={toggleModal}
      classNames="modal-footer-row"
      disableEventActions={disableEventActions}
    />
  );
};
