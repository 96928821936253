import React, { PropsWithChildren, useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { MultiFactorError } from "firebase/auth";

import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useTotpInputChange } from "../../../../../lib/hooks/useTotpInputChange";

import { TotpCodeInput } from "../../../../shared/Forms/TotpCodeInput/TotpCodeInput";
import { ModalMFA } from "../ModalMFA";

export const MfaReAuthCreds = ({
  handleCancel,
  handleSubmit,
  errorMfa,
  toggleModal,
}: PropsWithChildren<{
  handleCancel: () => void;
  handleSubmit: () => Promise<any>;
  errorMfa?: MultiFactorError;
  toggleModal: () => void;
}>) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { handleTotpInputChange, totpCode } = useTotpInputChange();
  const { user, loginWithTotp } = useAuthContext();

  const finishAuthenticate = async () => {
    try {
      if (!user || !errorMfa) {
        return;
      }

      setLoading(true);

      await loginWithTotp(errorMfa, totpCode.join(""));

      await handleSubmit();

      toggleModal();
    } catch (error: any) {
      if (error?.code === "auth/invalid-verification-code") {
        setError("Wrong code");
        return;
      }

      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalMFA
      title="Activate two-factor authentication"
      keyPoints={[
        "Two-factor authentication is enabled - please verify password change by using your authenticator app.",
      ]}
      closeButton="Cancel"
      nextButton="Authenticate"
      handleBackButton={handleCancel}
      handleNextButton={finishAuthenticate}
      disabledBack={loading}
      disabledNext={totpCode.join("").length < 6 || loading}
    >
      <div className="pt-3 pl-4 pb-5">
        <FormGroup>
          <Label className="d-block mt-4">
            Enter the 6-digit code generated by your authenticator app.
          </Label>
          <TotpCodeInput
            totpCode={totpCode}
            handleTotpInputChange={handleTotpInputChange}
          />
          {error && (
            <FormFeedback className="invalid-feedback">{error}</FormFeedback>
          )}
        </FormGroup>
      </div>
    </ModalMFA>
  );
};
