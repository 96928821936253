import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

const SetPasswordSchema = Yup.object().shape({
  newResetPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long!")
    .required("Password field is required!"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("newResetPassword")],
    "Passwords don't match!"
  ),
});

export interface SetPasswordFields {
  emailPassword: string;
  confirmPassword: string;
  newResetPassword: string;
}

interface UseSetPasswordFormOptions {
  initialValues: SetPasswordFields;
  onSubmit: (
    values: SetPasswordFields,
    formikHelpers: FormikHelpers<SetPasswordFields>
  ) => Promise<any>;
}

export type SetPasswordFormik = ReturnType<typeof useSetPasswordFormik>;

export const useSetPasswordFormik = (opts: UseSetPasswordFormOptions) => {
  return useFormik({
    ...opts,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: SetPasswordSchema,
  });
};
