import React from "react";
import {
  DropdownList,
  DropdownListOptions,
} from "../../../shared/DropdownList/DropdownList";

import { WorkplaceTypes } from "../AddWorkplaceButton/AddWorkplaceButton";
import { getPossibleTypes } from "../helpers/getPossibleTypes";

interface Props {
  type?: WorkplaceTypes;
  onClick: (type: WorkplaceTypes) => void;
}

export const AddWorkplaceDropdown = (props: Props) => {
  const { type, onClick } = props;

  const possibleTypes: DropdownListOptions[] = getPossibleTypes(type);

  return (
    <DropdownList
      options={possibleTypes}
      onClick={(value) => onClick(value as WorkplaceTypes)}
    />
  );
};
