import React from "react";
import { UtilizationDensity } from "./UtilizationDensity/UtilizationDensity";
import { UtilizationOccupancy } from "./UtilizationOccupancy/UtilizationOccupancy";

export const RoomUtilization = () => {
  return (
    <div className="RoomValueInsights WorkspaceValueInsights">
      <UtilizationOccupancy />

      <UtilizationDensity />
    </div>
  );
};
