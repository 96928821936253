import React, { ReactNode, useState } from "react";
import { toast } from "react-toastify";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useCalendarContext } from "../Calendar/CalendarContext";
import { useAuthContext } from "../Auth/AuthContext";

import { redirectToO365 } from "../../../components/Calendars/CalendarForms/O365ConnectButton/o365-popup";
import { config } from "../../../components/Calendars/CalendarForms/GSuiteConnectButton/GSuiteConnectButton";
import {
  CalendarCredentialsStatus,
  CalendarType,
} from "../../../api/grpc/calendarprovider/calendarprovider";
import { ReAuthModal } from "../../../components/Modals/ReAuthModal/ReAuthModal";
import { Icon } from "../../../components/shared/Icon/Icon";
import {
  ValidateTokenContext,
  IValidateTokenContext,
} from "./ValidateTokenContext";
import { Modal } from "reactstrap";
import { ValidateEWS } from "./ValidateEWS";

interface Props {
  children: ReactNode;
}
export const ValidateTokenProvider = (props: Props) => {
  const [openReAuthModal, setOpenReAuthModal] = useState(false);
  const [openEWSForm, setOpenEWSForm] = useState(false);
  const [visiblePersonalBanner, setVisiblePersonalBanner] = useState(true);

  const { user } = useAuthContext();
  const [statusOfToken, setStatusOfToken] = useState<
    CalendarCredentialsStatus | undefined
  >(undefined);
  const { reAuthenticateIntegration } = useCalendarRequests();
  const { personalCalendar, refetchPersonalCalendar } = useCalendarContext();

  const handleGoogleReAuth = async (token: string) => {
    if (!user) {
      return;
    }

    try {
      await reAuthenticateIntegration(personalCalendar[0].iD, {
        oneofKind: "gsuite",
        gsuite: {
          oAuthToken: token,
          calendarType: CalendarType.GSUITE,
        },
      });

      await refetchPersonalCalendar();

      //we need to update state of status for google and EWS re-auth updates
      //because of the custom requirements to only send request when users logins
      // for o365 the path will redirect to bookings pages, so we don't need to update state
      setStatusOfToken(
        CalendarCredentialsStatus.CalendarCredentialsStatusValid
      );

      if (openReAuthModal) {
        setOpenReAuthModal(false);
      }

      toast.success(
        <div className="SuccessToast">
          <div className="flex-a-center">
            <Icon icon="check-icon" />
            <span className="ml-2">
              Re-authentication finished successfully!
            </span>
          </div>
        </div>,
        {
          position: toast.POSITION.TOP_LEFT,
        }
      );

      return;
    } catch (error: any) {
      toast.error(error?.message ?? "Calendar couldn't be added!");
    }
  };

  const handleFixNowButton = () => {
    if (personalCalendar[0].iD.toLowerCase().includes("o365")) {
      return redirectToO365("reAuth", false);
    }

    if (personalCalendar[0].iD.toLowerCase().includes("gsuite")) {
      return window.google.accounts.oauth2
        .initCodeClient({
          client_id: config.clientId,
          scope: config.scope,
          callback: async (tokenResponse: any) => {
            if (tokenResponse && tokenResponse.code) {
              await handleGoogleReAuth(tokenResponse.code);
            }
          },
        })
        .requestCode();
    }

    return setOpenEWSForm(true);
  };

  const context: IValidateTokenContext = {
    openReAuthModal,
    setOpenReAuthModal,
    statusOfToken,
    setStatusOfToken,
    reAuthButton: handleFixNowButton,
    openEWSForm,
    setOpenEWSForm,
    visiblePersonalBanner,
    setVisiblePersonalBanner,
  };

  return (
    <ValidateTokenContext.Provider value={context}>
      {props.children}

      <Modal
        isOpen={openEWSForm}
        className="ReAuthEWS"
        toggle={() => setOpenEWSForm((prev) => !prev)}
      >
        <ValidateEWS />
      </Modal>

      {openReAuthModal && (
        <ReAuthModal
          closeModal={() => setOpenReAuthModal(false)}
          handleReAuthButton={handleFixNowButton}
          type="User"
          isEws={personalCalendar[0]?.iD.toLowerCase().startsWith("ews")}
        />
      )}
    </ValidateTokenContext.Provider>
  );
};
