import React from "react";
import { Nav } from "reactstrap";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { UserNavHeader } from "../UserNavHeader/UserNavHeader";
import { LogInButton } from "../../shared/Buttons/LogInButton/LogInButton";
import { RegisterButton } from "../../shared/Buttons/RegisterButton/RegisterButton";

import "./HeaderNavigation.scss";

export const HeaderNavigation = () => {
  const { isAuthenticated } = useAuthContext();

  return (
    <Nav className="d-flex align-items-center main_navigation ml-auto" navbar>
      {isAuthenticated ? (
        <>
          <UserNavHeader />
        </>
      ) : (
        <>
          <RegisterButton />
          <LogInButton />
        </>
      )}
    </Nav>
  );
};
