import React from "react";
import { Input } from "../../../shared/Input/Input";
import { BillingInnerCard } from "../../shared/BillingInnerCard/BillingInnerCard";
import { ManagedPartnerInfo } from "./ManagedPartnerInfo";
import { ChannelPartnerFormik } from "../lib/useChannelPartnerFormik";

import "./ManagedPartnerForm.scss";

interface Props {
  formik: ChannelPartnerFormik;
}
export const ManagedPartnerForm = ({ formik }: Props) => {
  return (
    <div className="ManagedPartnerForm">
      <BillingInnerCard
        title="Partner email ID"
        content={
          <>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Add partner email ID"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              isTouched={formik.touched.email}
              errorMessage={formik.errors.email}
              invalid={!!formik.errors.email && formik.touched.email}
            />
            <ManagedPartnerInfo />
          </>
        }
      />
    </div>
  );
};
