import React, { PropsWithChildren } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import cs from "classnames";

import { selectStyles } from "../../../../lib/utils/selectStyles";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import {
  LoginMethodObject,
  useSelectLoginMethod,
} from "./lib/useSelectLoginMethod";
import { tooltips } from "../../../../lib/utils/tooltips";

export const IntegrationsUsersSelector = ({
  className,
  isBasicOrBussines,
  isGoogleIntegration,
  loginMethod,
  setLoginMethod,
  disabled,
}: PropsWithChildren<{
  className?: string;
  isBasicOrBussines?: boolean;
  isGoogleIntegration: boolean;
  loginMethod: LoginMethodObject;
  setLoginMethod: React.Dispatch<React.SetStateAction<LoginMethodObject>>;
  disabled?: boolean;
}>) => {
  const classes = cs(className);
  const { basicOptions, googleMethod, microsoftMethod } =
    useSelectLoginMethod();

  const options: Array<LoginMethodObject> = [
    basicOptions,
    isGoogleIntegration ? googleMethod : microsoftMethod,
  ];

  return (
    <FormGroup className={classes}>
      <LabelWithInformationBox
        title="Sign in method for new users"
        htmlFor="signInMethod"
        message={tooltips.directorySync.syncSelectLoginMethod}
      />
      <Select
        name="signInMethod"
        className="w-75"
        options={isBasicOrBussines ? [basicOptions] : options}
        menuPlacement="top"
        value={isBasicOrBussines ? basicOptions : loginMethod}
        theme={selectStyles}
        isDisabled={disabled}
        onChange={(e) => {
          if (!e) {
            return;
          }

          return setLoginMethod(e);
        }}
      />
    </FormGroup>
  );
};
