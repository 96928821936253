import { useEffect, useState } from "react";

import { useRouter } from "../../../lib/hooks/useRouter";
import { useCalendarRequests } from "./useCalendarRequests";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";

import { Calendar } from "./calendarprovider";

export const useCalendarProvider = (
  providerId: string,
  shouldRefetchAllCalendars?: boolean
) => {
  const { pathname } = useRouter();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { listCalendars } = useCalendarRequests();
  const [data, setData] = useState<Calendar[]>([]);
  const { updateProvidersList, refetchCalendars } = useCalendarContext();

  useEffect(() => {
    loadCalendars();
  }, [pathname, providerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCalendars = async () => {
    setError(false);

    try {
      setLoading(true);
      const res = await listCalendars(providerId);

      updateProvidersList(providerId, res.response.calendars);

      setLoading(false);
      setData(res.response.calendars);
    } catch (error: any) {
      setError(true);
      if (
        localStorage.getItem("listCalendars") !== "refetch" &&
        shouldRefetchAllCalendars
      ) {
        refetchCalendars();

        localStorage.setItem("listCalendars", "refetch");
      }

      // console.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const refetch = () => {
    loadCalendars();
  };

  return {
    loading,
    error,
    data,
    refetch,
  };
};
