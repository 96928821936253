// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "analytics.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Analytics } from "./analytics";
import type { AnalyticsSubscriptionInfoResponse } from "./analytics";
import type { InsightsResponse } from "./analytics";
import type { DevicesResponse } from "./analytics";
import type { CalendarsResponse } from "./analytics";
import type { WorkplacesResponse } from "./analytics";
import type { CustomerRequest } from "./analytics";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Empty } from "./google/protobuf/empty";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Analytics
 */
export interface IAnalyticsClient {
    /**
     * @generated from protobuf rpc: Echo(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    echo(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * Workplaces return analytics about workplaces
     *
     * @generated from protobuf rpc: Workplaces(CustomerRequest) returns (WorkplacesResponse);
     */
    workplaces(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, WorkplacesResponse>;
    /**
     * Calendars return analytics about calendars
     *
     * @generated from protobuf rpc: Calendars(CustomerRequest) returns (CalendarsResponse);
     */
    calendars(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, CalendarsResponse>;
    /**
     * Devices return analytics about calendars
     *
     * @generated from protobuf rpc: Devices(CustomerRequest) returns (DevicesResponse);
     */
    devices(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, DevicesResponse>;
    /**
     * Insights return analytics about event insights adjusted to required timezone
     *
     * @generated from protobuf rpc: Insights(CustomerRequest) returns (InsightsResponse);
     */
    insights(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, InsightsResponse>;
    /**
     * Subscription return analytics about subscription
     *
     * @generated from protobuf rpc: Subscription(CustomerRequest) returns (AnalyticsSubscriptionInfoResponse);
     */
    subscription(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, AnalyticsSubscriptionInfoResponse>;
}
/**
 * @generated from protobuf service Analytics
 */
export class AnalyticsClient implements IAnalyticsClient, ServiceInfo {
    typeName = Analytics.typeName;
    methods = Analytics.methods;
    options = Analytics.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Echo(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    echo(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Workplaces return analytics about workplaces
     *
     * @generated from protobuf rpc: Workplaces(CustomerRequest) returns (WorkplacesResponse);
     */
    workplaces(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, WorkplacesResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerRequest, WorkplacesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Calendars return analytics about calendars
     *
     * @generated from protobuf rpc: Calendars(CustomerRequest) returns (CalendarsResponse);
     */
    calendars(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, CalendarsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerRequest, CalendarsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Devices return analytics about calendars
     *
     * @generated from protobuf rpc: Devices(CustomerRequest) returns (DevicesResponse);
     */
    devices(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, DevicesResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerRequest, DevicesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Insights return analytics about event insights adjusted to required timezone
     *
     * @generated from protobuf rpc: Insights(CustomerRequest) returns (InsightsResponse);
     */
    insights(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, InsightsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerRequest, InsightsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Subscription return analytics about subscription
     *
     * @generated from protobuf rpc: Subscription(CustomerRequest) returns (AnalyticsSubscriptionInfoResponse);
     */
    subscription(input: CustomerRequest, options?: RpcOptions): UnaryCall<CustomerRequest, AnalyticsSubscriptionInfoResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<CustomerRequest, AnalyticsSubscriptionInfoResponse>("unary", this._transport, method, opt, input);
    }
}
