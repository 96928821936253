import React from "react";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";

export const UserModalLoading = () => {
  return (
    <>
      <LoadingBox className="UserModalLoading__rounded" minHeight={100} />
      <LoadingBox className="UserModalLoading" count={3} minHeight={35} />
    </>
  );
};
