import { useMemo } from "react";
import { transport } from "../grpcTransport";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { SubscriptionServiceClient } from "./subscription.client";

export const useChannelPartnerRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const subscriptionClient = useMemo(
    () => new SubscriptionServiceClient(transport),
    []
  );

  const getChannelPartner = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await subscriptionClient.getChannelPartner(
      {
        customerId: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createChannelPartner = async (partnerEmail: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await subscriptionClient.createChannelPartner(
      {
        customerId: user.customerid,
        partnerEmail: partnerEmail,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteChannelPartner = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await subscriptionClient.delinkChannelPartner(
      {
        customerId: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    getChannelPartner,
    createChannelPartner,
    deleteChannelPartner,
  };
};
