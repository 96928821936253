import React from "react";
import { NavLink } from "react-router-dom";

import "./RegisterConfirmation.scss";

interface Props {
  email: string;
}

export const RegisterConfirmation = (props: Props) => {
  const { email } = props;

  return (
    <div className="RegisterConfirmation">
      <h2>Thank you!</h2>
      <p>An activation link was sent to email: {email}</p>
      <p>
        Open the email to confirm your account and complete the registration
        process
      </p>
      <NavLink to="/login" className="btn btn-primary btn-large btn-rounded">
        Go to login
      </NavLink>
    </div>
  );
};
