import { useApolloClient } from "@apollo/client";
import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../../../api/graphql/workplaces";
import { SearchDesk } from "../../../api/grpc/workplaces/workplaces";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
} from "../helpers/getWorkplaceFragment";

export const useRootBulkDesks = () => {
  const client = useApolloClient();

  const updateRootCache = (id: string, desks: SearchDesk[]) => {
    const previousQuery = client.cache.readQuery<
      GetWorkplacesResponse,
      WorkplaceRequestVariables
    >({
      query: GET_ROOT_WORKPLACES,
      variables: {
        tenantId: id,
      },
    });

    const tenantResults = previousQuery?.queryTenant[0];

    client.cache.writeQuery({
      query: GET_ROOT_WORKPLACES,
      variables: {
        tenantId: id,
      },
      data: {
        queryTenant: [
          {
            ...tenantResults,
            desks: [...(tenantResults?.desks || []), ...desks],
          },
        ],
      },
    });
  };

  const updateWorkplacesEntity = (
    parentType: string | "",
    parentId: string | "",
    desks: SearchDesk[]
  ) => {
    const previousData = client.readFragment({
      id: `${parentType}:${parentId}`,
      fragmentName: getWorkplaceFragmentName(parentType),
      fragment: getWorkplaceFragment(parentType),
    });

    client.writeFragment({
      id: `${parentType}:${parentId}`,
      fragmentName: getWorkplaceFragmentName(parentType),
      fragment: getWorkplaceFragment(parentType),
      data: {
        ...previousData,
        desks: [...previousData.desks, ...desks],
      },
    });
  };

  return { updateRootCache, updateWorkplacesEntity };
};
