import { useMemo } from "react";

import { transport } from "../grpcTransport";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import {
  EWSProvider,
  EwsConnection,
  O365Connection,
  GsuiteConnection,
  MicrosoftEnvironment,
} from "./calendarprovider";
import { CalendarType, CalendarProviderDeviceType } from "./calendarprovider";
import { CalendarProviderClient } from "./calendarprovider.client";

export const useCalendarRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const calendarClient = useMemo(
    () => new CalendarProviderClient(transport),
    []
  );

  const createEWSProvider = async (name: string, data: EwsConnection) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.createProvider(
      {
        name,
        deviceType: CalendarProviderDeviceType.WEB,
        cType: {
          ews: data,
          oneofKind: "ews",
        },
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateEWSProvider = async (variables: EWSProvider) => {
    return await calendarClient.updateEWSProvider(variables, {
      interceptors: [tokenInterceptor],
    });
  };

  const retrieveEWSProviderConnection = async (id: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.retrieveEWSProviderConnection(
      {
        customerID: user.customerid,
        iD: id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createGsuiteProvider = async (name: string, data: GsuiteConnection) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.createProvider(
      {
        name,
        deviceType: CalendarProviderDeviceType.WEB,
        cType: {
          gsuite: data,
          oneofKind: "gsuite",
        },
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const validateEWSCalendar = async (variables: {
    calendarID: string;
    calendarProviderID: string;
  }) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.validateEWSCalendar(
      {
        customerID: user.customerid,
        calendarID: variables.calendarID,
        calendarProviderID: variables.calendarProviderID,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createO365Provider = async (
    name: string,
    data: O365Connection,
    environment?: MicrosoftEnvironment
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.createProvider(
      {
        name,
        deviceType: CalendarProviderDeviceType.WEB,
        cType: {
          o365: data,
          oneofKind: "o365",
        },
        customerID: user.customerid,
        environment,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteProvider = async (id: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.deleteProvider(
      {
        iD: id,
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const retrieveProviderList = async () => {
    return await calendarClient.retrieveProviderList(
      {
        calType: CalendarType.ALL,
        customerID: user?.customerid || "",
        limit: 50,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listCalendars = async (id: string) => {
    return await calendarClient.listCalendars(
      {
        calendarProviderID: id,
        customerID: user?.customerid || "",
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const linkPersonalGsuiteCalendar = async (
    name: string,
    data: GsuiteConnection
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.linkPersonalCalendar(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
        calendarType: {
          oneofKind: "gsuite",
          gsuite: data,
        },
        name: name,
        deviceType: CalendarProviderDeviceType.WEB,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const linkPersonal0365Calendar = async (
    name: string,
    data: O365Connection
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.linkPersonalCalendar(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
        calendarType: {
          oneofKind: "o365",
          o365: data,
        },
        name: name,
        deviceType: CalendarProviderDeviceType.WEB,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const linkPersonalEWSCalendar = async (name: string, data: EwsConnection) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.linkPersonalCalendar(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
        calendarType: {
          ews: data,
          oneofKind: "ews",
        },
        name: name,
        deviceType: CalendarProviderDeviceType.WEB,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listPersonalCalendars = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.listPersonalCalendars(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const validateCalendarIntegrationCredentials = async (
    calendarProviderID: string
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.validateCalendarIntegrationCredentials(
      {
        customerID: user.customerid,
        calendarProviderID: calendarProviderID,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const reAuthenticateIntegration = async (
    calendarProviderID: string,
    cType:
      | {
          oneofKind: "ews";
          ews: EwsConnection;
        }
      | {
          oneofKind: "gsuite";

          gsuite: GsuiteConnection;
        }
      | {
          oneofKind: "o365";

          o365: O365Connection;
        }
      | {
          oneofKind: undefined;
        }
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await calendarClient.updateCalendarProviderCredentials(
      {
        calendarProviderID: calendarProviderID,
        customerID: user.customerid,
        cType: cType,
        deviceType: CalendarProviderDeviceType.WEB,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    listCalendars,

    deleteProvider,

    createEWSProvider,
    updateEWSProvider,
    validateEWSCalendar,
    retrieveEWSProviderConnection,

    createO365Provider,
    listPersonalCalendars,
    createGsuiteProvider,
    retrieveProviderList,
    linkPersonalGsuiteCalendar,
    linkPersonal0365Calendar,
    linkPersonalEWSCalendar,
    validateCalendarIntegrationCredentials,
    reAuthenticateIntegration,
  };
};
