import React from "react";
import { NavLink } from "react-router-dom";

import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";

import { CalendarEmail } from "./CalendarEmail/CalendarEmail";
import { CalendarProvider } from "./CalendarProvider/CalendarProvider";
import { RoomFormik } from "../../shared/Forms/RoomForm/lib/useRoomFormik";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { LabelDirectionType } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { CALENDARS_ROOT_PATH } from "../../../lib/routes/routes";

interface Props {
  formik: RoomFormik;
  direction?: LabelDirectionType;
  roomId?: string;
}

export const CalendarProviderSelect = (props: Props) => {
  const { formik, direction, roomId } = props;
  const { loading, providers } = useCalendarContext();

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<p>Loading calendars...</p>}
    >
      {providers.length > 0 ? (
        <>
          <CalendarProvider
            providers={providers}
            value={formik.values.calendarProviderId}
            error={formik.errors.calendarProviderId}
            direction={direction}
            onChange={async (value, type) => {
              await formik.setFieldValue("calendarProviderId", value, true);

              await formik.setFieldValue("calendarId", "", true);
              await formik.setFieldValue("calendarType", type, true);
            }}
            onRemove={async () => {
              await formik.setFieldValue("calendarProviderId", "", true);

              await formik.setFieldValue("calendarId", "", true);
              await formik.setFieldValue("calendarType", "", true);
            }}
          />
          {formik.values.calendarProviderId !== "" && (
            <CalendarEmail
              formik={formik}
              providers={providers}
              roomId={roomId}
            />
          )}
        </>
      ) : (
        <NavLink
          to={CALENDARS_ROOT_PATH}
          className="btn btn-outline btn-small mt-2"
        >
          Add Calendar Integration
        </NavLink>
      )}
    </HandleLoadingState>
  );
};
