import React from "react";

import { FormGroup } from "reactstrap";
import { useDeskFormik } from "../../../../shared/Forms/DeskForm/lib/useDeskFormik";
import { useTemplateFormik } from "../../../lib/useTemplateFormik";
import { useGetContacts } from "../../../../People/lib/useGetContacts";

import { DeskForm } from "../../../../shared/Forms/DeskForm/DeskForm";
import { Icon } from "../../../../shared/Icon/Icon";
import { Input } from "../../../../shared/Input/Input";
import { LabelWithInformationBox } from "../../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { DefaultTemplate } from "../../../../shared/DefaultTemplate/DefaultTemplate";
import { tooltips } from "../../../../../lib/utils/tooltips";

interface Props {
  templateFormik: ReturnType<typeof useTemplateFormik>;
  formik: ReturnType<typeof useDeskFormik>;
}

export const DeskProfileForm = ({ templateFormik, formik }: Props) => {
  const {
    loading,
    error,
    data,
    // refetch: refetchContacts,
  } = useGetContacts({
    page: 1,
    pageSize: 0,
  });

  return (
    <div className="DeskProfileForm">
      <div className="ProfilesForm__type TemplateManage__subTitle flex-a-center mb-2 mt-1">
        <Icon className="TemplateManage__desk-profile" icon="desk-profile" />

        <h6 className="pl-1">Template</h6>
      </div>
      <DefaultTemplate>
        <FormGroup>
          <LabelWithInformationBox
            for="profileName"
            title="Profile name"
            message={tooltips.templates.profileName}
            direction="right"
          />
          <Input
            id="profileName"
            name="profileName"
            type="text"
            value={templateFormik.values.profileName}
            onBlur={templateFormik.handleBlur}
            onChange={templateFormik.handleChange}
            isTouched={templateFormik.touched.profileName}
            errorMessage={templateFormik.errors.profileName}
            invalid={
              !!templateFormik.errors.profileName &&
              templateFormik.touched.profileName
            }
          />
        </FormGroup>

        <FormGroup>
          <LabelWithInformationBox
            for="profileDescription"
            title="Profile description"
            message={tooltips.templates.profileDescription}
            direction="right"
          />
          <Input
            type="text"
            id="profileDescription"
            name="profileDescription"
            onBlur={templateFormik.handleBlur}
            value={templateFormik.values.profileDescription}
            onChange={templateFormik.handleChange}
          />
        </FormGroup>
      </DefaultTemplate>

      <div className="ProfilesForm__type TemplateManage__subTitle flex-a-center mb-3">
        <Icon icon="desk_icon_48x48" />

        <h6 className="pl-1">Desk settings</h6>
      </div>

      <DefaultTemplate className="mb-4">
        <DeskForm
          formik={formik}
          loading={loading}
          error={error}
          data={data}
          isProfile={true}
          displayEditWorkHours
          className="ProfilesForm"
        />
      </DefaultTemplate>
    </div>
  );
};
