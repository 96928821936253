import React from "react";
import { RouteComponentProps } from "react-router";
import { BookingsDesksSearch } from "./BookingsDesksSearch/BookingsDesksSearch";
import { BookingsRoomsSearch } from "./BookingsRoomsSearch/BookingsRoomsSearch";

export type BookingTypes = "room" | "desk";

interface Props {
  type: string;
}

const components: {
  [key in BookingTypes]: React.FunctionComponent<any>;
} = {
  room: BookingsRoomsSearch,
  desk: BookingsDesksSearch,
};

export const BookingsSearch = ({
  match: {
    params: { type },
  },
}: RouteComponentProps<Props>) => {
  const Component = components[type as BookingTypes];

  return <Component type={type} />;
};
