import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { WorkplaceTypes } from "../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { BUILDING_FIELDS_FRAGMENT } from "../buildings/buildings";
import { COMPANY_FIELDS_FRAGMENT } from "../companies/companies";
import { FLOOR_FIELDS_FRAGMENT } from "../floors/floors";
import { SITE_FIELDS_FRAGMENT } from "../sites/sites";
import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../workplaces";
import { ZONE_FIELDS_FRAGMENT } from "../zones/zones";
import {
  AddRoomDirectionToWayfinderResponse,
  AddRoomDirectionToWayfinderVariables,
  AddWayfinderResponse,
  ADD_MARKER_TO_WAYFINDER,
  ADD_ROOM_DIRECTION_TO_WAYFINDER,
  ADD_WAY_FINDER_TO_BUILDING,
  ADD_WAY_FINDER_TO_COMPANY,
  ADD_WAY_FINDER_TO_FLOOR,
  ADD_WAY_FINDER_TO_SITE,
  ADD_WAY_FINDER_TO_TENANT,
  ADD_WAY_FINDER_TO_ZONE,
  defaultDisplaySettings,
  UpdateWayfinderDisplaySettingsVariables,
  UpdateWayfinderDistanceUnitVariables,
  UpdateWayfinderLicenseVariables,
  UpdateWayfinderMarkerVariables,
  UpdateWayfinderResponse,
  UPDATE_WAYFINDER_DISTANCE_UNIT,
  UPDATE_WAY_FINDER,
  UPDATE_WAY_FINDER_DISPLAY_SETTINGS,
  UPDATE_WAY_FINDER_LICENSE,
  WayfinderRequestVariables,
  WAY_FINDER_DISPLAY_SETTINGS_FRAGMENT,
  WAY_FINDER_FIELDS_FRAGMENT,
} from "./wayfinder";

export const useWayfinderRequests = () => {
  const client = useApolloClient();

  const updateWayfinderMutation = useMutation<
    UpdateWayfinderResponse,
    WayfinderRequestVariables
  >(UPDATE_WAY_FINDER);

  const addWayfinderToTenant = async (variables: WayfinderRequestVariables) => {
    return await client.mutate<AddWayfinderResponse, WayfinderRequestVariables>(
      {
        mutation: ADD_WAY_FINDER_TO_TENANT,
        variables,
        update: (cache, newData) => {
          const previousQuery = cache.readQuery<
            GetWorkplacesResponse,
            WorkplaceRequestVariables
          >({
            query: GET_ROOT_WORKPLACES,
            variables: {
              tenantId: variables.tenantId,
            },
          });

          const tenantResults = previousQuery?.queryTenant[0];
          const newWayfinder = newData.data?.addWayfinder.wayfinder[0];

          cache.writeQuery({
            query: GET_ROOT_WORKPLACES,
            variables: {
              tenantId: variables.tenantId,
            },
            data: {
              queryTenant: [
                {
                  ...tenantResults,
                  wayfinders: [
                    ...(tenantResults?.wayfinders || []),
                    newWayfinder,
                  ],
                },
              ],
            },
          });
        },
      }
    );
  };

  const addWayfinderToCompany = async (
    variables: WayfinderRequestVariables
  ) => {
    return await client.mutate<AddWayfinderResponse, WayfinderRequestVariables>(
      {
        mutation: ADD_WAY_FINDER_TO_COMPANY,
        variables,
        update: (cache, newData) => {
          const previousData = client.readFragment({
            id: `Company:${variables.id}`,
            fragmentName: "CompanyFields",
            fragment: COMPANY_FIELDS_FRAGMENT,
          });

          client.writeFragment({
            id: `Company:${variables.id}`,
            fragmentName: "CompanyFields",
            fragment: COMPANY_FIELDS_FRAGMENT,
            data: {
              ...previousData,
              wayfinders: [
                ...previousData.wayfinders,
                newData.data?.addWayfinder.wayfinder[0],
              ],
            },
          });
        },
      }
    );
  };

  const addWayfinderToSite = async (variables: WayfinderRequestVariables) => {
    return await client.mutate<AddWayfinderResponse, WayfinderRequestVariables>(
      {
        mutation: ADD_WAY_FINDER_TO_SITE,
        variables,
        update: (cache, newData) => {
          const previousData = client.readFragment({
            id: `Site:${variables.id}`,
            fragmentName: "SiteFields",
            fragment: SITE_FIELDS_FRAGMENT,
          });

          client.writeFragment({
            id: `Site:${variables.id}`,
            fragmentName: "SiteFields",
            fragment: SITE_FIELDS_FRAGMENT,
            data: {
              ...previousData,
              wayfinders: [
                ...previousData.wayfinders,
                newData.data?.addWayfinder.wayfinder[0],
              ],
            },
          });
        },
      }
    );
  };

  const addWayfinderToBuilding = async (
    variables: WayfinderRequestVariables
  ) => {
    return await client.mutate<AddWayfinderResponse, WayfinderRequestVariables>(
      {
        mutation: ADD_WAY_FINDER_TO_BUILDING,
        variables,
        update: (cache, newData) => {
          const previousData = client.readFragment({
            id: `Building:${variables.id}`,
            fragmentName: "BuildingFields",
            fragment: BUILDING_FIELDS_FRAGMENT,
          });

          client.writeFragment({
            id: `Building:${variables.id}`,
            fragmentName: "BuildingFields",
            fragment: BUILDING_FIELDS_FRAGMENT,
            data: {
              ...previousData,
              wayfinders: [
                ...previousData.wayfinders,
                newData.data?.addWayfinder.wayfinder[0],
              ],
            },
          });
        },
      }
    );
  };

  const addWayfinderToFloor = async (variables: WayfinderRequestVariables) => {
    return await client.mutate<AddWayfinderResponse, WayfinderRequestVariables>(
      {
        mutation: ADD_WAY_FINDER_TO_FLOOR,
        variables,
        update: (cache, newData) => {
          const previousData = client.readFragment({
            id: `Floor:${variables.id}`,
            fragmentName: "FloorFields",
            fragment: FLOOR_FIELDS_FRAGMENT,
          });

          client.writeFragment({
            id: `Floor:${variables.id}`,
            fragmentName: "FloorFields",
            fragment: FLOOR_FIELDS_FRAGMENT,
            data: {
              ...previousData,
              wayfinders: [
                ...previousData.wayfinders,
                newData.data?.addWayfinder.wayfinder[0],
              ],
            },
          });
        },
      }
    );
  };

  const addWayfinderToZone = async (variables: WayfinderRequestVariables) => {
    return await client.mutate<AddWayfinderResponse, WayfinderRequestVariables>(
      {
        mutation: ADD_WAY_FINDER_TO_ZONE,
        variables,
        update: (cache, newData) => {
          const previousData = client.readFragment({
            id: `Zone:${variables.id}`,
            fragmentName: "ZoneFields",
            fragment: ZONE_FIELDS_FRAGMENT,
          });

          client.writeFragment({
            id: `Zone:${variables.id}`,
            fragmentName: "ZoneFields",
            fragment: ZONE_FIELDS_FRAGMENT,
            data: {
              ...previousData,
              wayfinders: [
                ...previousData.wayfinders,
                newData.data?.addWayfinder.wayfinder[0],
              ],
            },
          });
        },
      }
    );
  };

  const updateWayfinder = async (variables: WayfinderRequestVariables) => {
    const [request] = updateWayfinderMutation;
    return await request({
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateWayfinder.wayfinder[0],
          },
        });

        toast.success("Wayfinder updated successfully!");
      },
      onError: (error: any) => {
        toast.error(error.networkError.result.message);
      },
    });
  };

  const updateWayfinderDisplay = async (
    variables: UpdateWayfinderDisplaySettingsVariables
  ) => {
    return await client.mutate<
      UpdateWayfinderResponse,
      UpdateWayfinderDisplaySettingsVariables
    >({
      mutation: UPDATE_WAY_FINDER_DISPLAY_SETTINGS,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderDisplaySettings",
          fragment: WAY_FINDER_DISPLAY_SETTINGS_FRAGMENT,
          data: {
            displaySettings: {
              ...newData.data?.updateWayfinder.wayfinder[0].displaySettings,
            },
          },
        });
      },
    });
  };

  const updateWayfinderDistanceUnit = async (
    variables: UpdateWayfinderDistanceUnitVariables
  ) => {
    return await client.mutate<
      UpdateWayfinderResponse,
      UpdateWayfinderDistanceUnitVariables
    >({
      mutation: UPDATE_WAYFINDER_DISTANCE_UNIT,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            distanceUnit:
              newData.data?.updateWayfinder.wayfinder[0].distanceUnit,
          },
        });
      },
    });
  };

  const updateWayfinderLicense = async (
    variables: UpdateWayfinderLicenseVariables
  ) => {
    return await client.mutate<
      UpdateWayfinderResponse,
      UpdateWayfinderLicenseVariables
    >({
      mutation: UPDATE_WAY_FINDER_LICENSE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            licensed: newData.data?.updateWayfinder.wayfinder[0].licensed,
          },
        });
      },
    });
  };

  const addRoomDirectionToWayfinder = async (
    id: string,
    variables: AddRoomDirectionToWayfinderVariables
  ) => {
    return await client.mutate<
      AddRoomDirectionToWayfinderResponse,
      AddRoomDirectionToWayfinderVariables
    >({
      mutation: ADD_ROOM_DIRECTION_TO_WAYFINDER,
      variables,
    });
  };

  const updateWayfinderMarker = async (
    variables: UpdateWayfinderMarkerVariables
  ) => {
    return await client.mutate<
      UpdateWayfinderResponse,
      UpdateWayfinderMarkerVariables
    >({
      mutation: ADD_MARKER_TO_WAYFINDER,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Wayfinder:${variables.id}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            marker: {
              latitude:
                newData?.data?.updateWayfinder.wayfinder[0].marker.latitude,
              longitude:
                newData?.data?.updateWayfinder.wayfinder[0].marker.longitude,
            },
          },
        });
      },
    });
  };

  const addWayfinder = (
    params: WayfinderRequestVariables,
    parentType?: WorkplaceTypes
  ) => {
    const variables: WayfinderRequestVariables = {
      ...params,
      displaySettings: {
        ...defaultDisplaySettings,
        workingWeekDays: params.workingWeekDays,
        customerId: params.customerId,
      },
    };

    if (parentType === "Company") {
      return addWayfinderToCompany(variables);
    }

    if (parentType === "Site") {
      return addWayfinderToSite(variables);
    }

    if (parentType === "Building") {
      return addWayfinderToBuilding(variables);
    }

    if (parentType === "Floor") {
      return addWayfinderToFloor(variables);
    }

    if (parentType === "Zone") {
      return addWayfinderToZone(variables);
    }

    return addWayfinderToTenant(variables);
  };

  return {
    addWayfinder,
    updateWayfinder,
    updateWayfinderDisplay,
    updateWayfinderDistanceUnit,
    updateWayfinderLicense,
    addRoomDirectionToWayfinder,
    updateWayfinderMarker,
  };
};
