import React, { PropsWithChildren } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { DebugInfoItem } from "../../../Organization/OrganizationSettings/DebugInfoCard/DebugInfo/DebugInfoItem";
import { InputDescription } from "../../InputDescription/InputDescription";
import { SUCCESS_STRINGS } from "../../../../lib/utils/constants";

export const DebugDeviceInfo = ({
  deviceId,
  workspaceId,
}: PropsWithChildren<{
  deviceId: string;
  workspaceId?: string;
}>) => {
  const { user } = useAuthContext();

  const debugItems = [
    { title: "customerID", value: user?.customerid },
    { title: "accountID", value: user?.claims?.user_id },
    { title: "email", value: user?.email },
    { title: "subscriptionID", value: user?.claims?.subscriptionId },
    { title: "deviceID", value: deviceId },
    { title: "workspaceId", value: workspaceId },
  ];

  let clipBoardText = `
  customerID: ${user?.customerid}
  accountID: ${user?.claims?.user_id}
  email: ${user?.email}
  subscriptionID: ${user?.claims?.subscriptionId}
  deviceID: ${deviceId}
  workspaceId: ${workspaceId}
  `;

  const copyToClipboard = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.writeText(clipBoardText);
    toast.success(SUCCESS_STRINGS.copiedText);
  };

  return (
    <div className="DebugInfo">
      <h4>For Goget support:</h4>
      <ul>
        {debugItems.map((item) => {
          return (
            <DebugInfoItem
              title={item.title}
              value={item.value || ""}
              key={item.title}
            />
          );
        })}
      </ul>
      <div className="center">
        <button onClick={(e) => copyToClipboard(e)}>Copy to clipboard</button>
      </div>

      <div className="flex-a-center mt-2">
        <InputDescription description="Use" />
        <a
          href="https://support.gogetcorp.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="DebugInfo__outerLink pl-1 pr-1"
        >
          https://support.gogetcorp.com/
        </a>
        <InputDescription description=" to contact us" />
      </div>
    </div>
  );
};
