import { createContext, useContext } from "react";
import { ApolloError } from "@apollo/client";

import { LicenseResponse } from "../../../api/grpc/subscription/subscription";
import { LicenseUpdateRequest } from "../../../api/graphql/useWorkplaceRequests";

export interface CustomerContext {
  customer: any;
  loading: boolean;
  error: ApolloError | undefined;
  licenses: {
    loading: boolean;
    error: string;
    data: LicenseResponse | undefined;
  };
  retrieveTenant: () => Promise<{ id: string; tenantId: string }>;
  refetch: () => Promise<any>;
}

export interface LicensesContext {
  disabled: boolean;
  spinner: boolean;
  handleLicenseUpdate: (variables: LicenseUpdateRequest) => Promise<boolean>;
}

export const CustomerContext = createContext<CustomerContext>({
  loading: false,
  error: undefined,
  customer: undefined,
  licenses: {
    loading: false,
    error: "",
    data: undefined,
  },
  retrieveTenant: async () => ({ id: "", tenantId: "" }),
  refetch: async () => {},
});

export const LicensesContext = createContext<LicensesContext>({
  disabled: false,
  spinner: false,
  handleLicenseUpdate: async () => false,
});

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error("useCustomerContext must be used within CustomerContext");
  }

  return context;
};

export const useLicensesContext = () => {
  const context = useContext(LicensesContext);

  if (!context) {
    throw new Error("useLicensesContext must be used within LicensesContext");
  }

  return context;
};
