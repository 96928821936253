import React from "react";
import { Button } from "../../../shared/Button/Button";
import cs from "classnames";

import "./BillingInnerCard.scss";

interface Props {
  title?: string;
  content?: string | JSX.Element;
  imageSrc?: string | JSX.Element;
  hasButton?: boolean;
  buttonTitle?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  secondDescription?: string;
  outterLink?: string;
  outerLinkName?: string;
}

export const BillingInnerCard = ({
  title,
  content,
  imageSrc,
  secondDescription,
  hasButton,
  buttonTitle,
  onClick,
  disabled,
  className,
  outterLink,
  outerLinkName,
}: Props) => {
  const classes = cs("BillingInnerCard", className);

  return (
    <div className={classes}>
      {title && <h5>{title}</h5>}
      <div className="BillingInnerCard__content">
        {imageSrc && imageSrc}
        <div>
          <span className="BillingInnerCard__content--text">
            {content}{" "}
            {outterLink && (
              <a href={outterLink} target="_blank" rel="noopener noreferrer">
                {outerLinkName}
              </a>
            )}
          </span>
          {secondDescription && (
            <span className="BillingInnerCard__content--text d-block">
              {secondDescription}
            </span>
          )}
        </div>
        {hasButton && (
          <Button
            title={buttonTitle}
            disabled={disabled}
            size="small"
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};
