import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useWorkplaceRequests } from "../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useRouter } from "../../lib/hooks/useRouter";
import { useBookingsContext } from "../../components/Bookings/Context/BookingsContext";
import { useSidebarContext } from "../../lib/context/Sidebar/SidebarContext";
import { useCalendarRequests } from "../../api/grpc/calendarprovider/useCalendarRequests";
import { useCalendarContext } from "../../lib/context/Calendar/CalendarContext";
import { useValidPersonalToken } from "../../lib/context/ValidateToken/ValidateTokenContext";

import { FilteredWorkplacesTree } from "../Manage/Workplaces/FilteredWorkplaces/FilteredWorkplacesTree";
import { BookingsRoutes } from "../../components/Bookings/BookingsRoutes";
import { RESERVATIONS_MAP_ROOT_PATH } from "../../lib/routes/routes";

import "./Bookings.scss";

export const Bookings = () => {
  const { user } = useAuthContext();
  const { getRootWorkplaces } = useWorkplaceRequests();
  const { pathname } = useRouter();
  const [request, { loading, error, data }] = getRootWorkplaces;
  const { togglePageSidebar } = useSidebarContext();
  const { handleLocationId, emptyActiveTags } = useBookingsContext();
  const { validateCalendarIntegrationCredentials } = useCalendarRequests();
  const { personalCalendar } = useCalendarContext();
  const { setStatusOfToken } = useValidPersonalToken();

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  useEffect(() => {
    if (personalCalendar.length <= 0) {
      //no need to check token's validation if the user doens't have a personal calendar added
      return;
    }

    const checkPersonalToken = async () => {
      try {
        const {
          response: { status },
        } = await validateCalendarIntegrationCredentials(
          personalCalendar[0].iD
        );

        setStatusOfToken(status);
      } catch (error: any) {
        toast.error(error?.message);
        return;
      }
    };

    checkPersonalToken();

    if (localStorage.getItem("initiateLogin") === "1") {
      localStorage.removeItem("initiateLogin");
    }
  }, [personalCalendar]);

  return (
    <div className="Bookings default-page">
      <FilteredWorkplacesTree
        className="PageSidebar--Custom"
        showOnDesktop={false}
        loading={loading}
        error={error}
        rootWorkplaces={rootWorkplaces}
        showOnInitialization={pathname === RESERVATIONS_MAP_ROOT_PATH}
        hideToggler={pathname === RESERVATIONS_MAP_ROOT_PATH}
        isPickerOnly={!pathname.includes(RESERVATIONS_MAP_ROOT_PATH)}
        onTitleClick={() => {
          togglePageSidebar();
          handleLocationId("root", "All locations");
          emptyActiveTags();
          return;
        }}
        pathTo={
          pathname.toLowerCase().includes("search")
            ? "#"
            : RESERVATIONS_MAP_ROOT_PATH
        }
      />

      <BookingsRoutes />
    </div>
  );
};
