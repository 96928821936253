import React, { useEffect } from "react";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useInsightsContext } from "../../Context/InsightsContext";
import { useSidebarContext } from "../../../../lib/context/Sidebar/SidebarContext";
import { trimText } from "../../../../lib/helpers/trimText";
import { Button } from "../../../shared/Button/Button";
import { InsightsWorkplacesTree } from "../InsightsWorkplacesTree/InsightsWorkplacesTree";

export const LocationInsightsButton = () => {
  const { getRootWorkplaces } = useWorkplaceRequests();
  const { user } = useAuthContext();
  const { togglePageSidebar } = useSidebarContext();
  const {
    insightsLocation: { locationName },
    setInsightsLocation,
  } = useInsightsContext();

  const [request, { loading, error, data }] = getRootWorkplaces;

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  return (
    <>
      <Button
        title={
          !!locationName.length ? trimText(locationName, 20) : "All locations"
        }
        icon="location-reserve"
        color="primary"
        size="medium"
        className="InsightsHeaderButtons__location"
        onClick={togglePageSidebar}
      />

      <InsightsWorkplacesTree
        loading={loading}
        error={error}
        rootWorkplaces={rootWorkplaces}
        onItemClick={setInsightsLocation}
        toggleSidebar={togglePageSidebar}
      />
    </>
  );
};
