import React, { PropsWithChildren } from "react";
import { useDirectoryContext } from "../../Context/DirectoryContext";
import { IntegrationGroupMember } from "../../../../api/grpc/contacts/contacts";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";

import { IntegrationsHeader } from "./IntegrationsHeader";
import { Button } from "../../../shared/Button/Button";
import { DirectoryIntegrationsTable } from "./DirectoryIntegrationsTable";

export const DirectoryIntegrationsContent = ({
  loading,
  error,
  members,
  showLoadMoreButton,
  handleListOfDirectory,
}: PropsWithChildren<{
  loading: boolean;
  error?: Error;
  members?: IntegrationGroupMember[];
  showLoadMoreButton: boolean;
  handleListOfDirectory: () => Promise<void>;
}>) => {
  const { setSelectedUsers } = useDirectoryContext();

  const handleSelectUsers = (isSelect: boolean) => {
    if (!members) {
      return;
    }

    if (isSelect) {
      return setSelectedUsers([]);
    }

    return setSelectedUsers(members);
  };

  return (
    <>
      <IntegrationsHeader
        handleSelectAll={handleSelectUsers}
        numberOfAllUsers={members?.length || 0}
        disableFilters={!!error || loading}
      />
      <HandleLoadingState
        loading={loading && !members?.length}
        loadingPlaceholder={<LoadingBox count={5} minHeight={60} />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={
            <CenteredBox>
              <span className="w-100 word-break">
                {error?.message ?? "Error fetching users"}
              </span>
            </CenteredBox>
          }
        >
          {!!members?.length || showLoadMoreButton ? (
            <>
              <DirectoryIntegrationsTable data={members || []} />

              {/* loader for new requests */}
              {loading && <LoadingBox count={5} minHeight={60} />}

              {showLoadMoreButton && (
                <div className="text-center mt-4 mb-4">
                  <Button
                    title="More users"
                    size="small"
                    color="primary"
                    onClick={() => handleListOfDirectory()}
                    disabled={loading}
                  />
                </div>
              )}
            </>
          ) : (
            <CenteredBox>No users found that match the filters</CenteredBox>
          )}
        </HandleRequestState>
      </HandleLoadingState>
    </>
  );
};
