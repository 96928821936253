import React, { useState } from "react";
import { addDays, addMonths, addWeeks } from "date-fns";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { dateFormat } from "../../../Bookings/helpers/displayTimeFormat";
import { useCheckUserPermissions } from "../../../../lib/hooks/useCheckUserPermissions";

import {
  DAY_LABELS,
  forReservationDate,
} from "../../../Bookings/lib/datePickerHelper";

import DatePicker from "react-datepicker";
import { Button } from "../../../shared/Button/Button";
import { useAllReservationContext } from "../context/AllReservationContext/AllReservationContext";
import { RolePermissions } from "../../../../api/grpc/account/account";

const dayOptions = [DAY_LABELS.today, DAY_LABELS.tomorrow];

export const ReservationsDateRange = () => {
  const { timeZone } = useTimeZoneContext();
  const { checkUserPermission } = useCheckUserPermissions();
  const { isOpen, toggleModal } = useToggleModal();
  const { startDate, setStartDate } = useAllReservationContext();

  const [innerStartDate, setInnerStartDate] = useState(
    startDate ? startDate : new Date()
  );

  return (
    <div>
      <Button
        title={forReservationDate(innerStartDate, timeZone)}
        icon="calendar_icon_18x18"
        color="primary"
        size="medium"
        className="InsightsHeaderButtons__date"
        onClick={toggleModal}
      />

      {isOpen && (
        <div className="DateRangeButton__content">
          {dayOptions.map((day) => (
            <span
              key={day}
              className={`d-block label__main cursor-pointer DateRangeButton__content--option text-capitalize ${
                forReservationDate(innerStartDate, timeZone).startsWith(day)
                  ? "DateRangeButton__active"
                  : ""
              }`}
              onClick={() => {
                if (day === DAY_LABELS.today) {
                  return setInnerStartDate(new Date());
                }

                return setInnerStartDate(addDays(new Date(), 1));
              }}
            >
              {day}
            </span>
          ))}

          <div className="DateRangeButton__content--picker">
            <label className="label__main pt-3">Custom date</label>
            <DatePicker
              selected={innerStartDate}
              onChange={(date) => {
                if (date) {
                  setInnerStartDate(date);
                }
              }}
              startDate={innerStartDate}
              dateFormat={dateFormat}
              minDate={new Date()}
              maxDate={
                checkUserPermission(
                  RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
                )
                  ? addWeeks(new Date(), 1)
                  : addMonths(new Date(), 1)
              }
            />
          </div>

          <div className="DateRangeButton__dialog flex-a-center">
            <Button
              onClick={() => {
                // setInnerDayOption(undefined);

                if (startDate) {
                  setInnerStartDate(startDate);
                }

                toggleModal();
              }}
              title="Cancel"
              color="outline-secondary"
            />
            <Button
              onClick={() => {
                setStartDate(innerStartDate);

                toggleModal();
              }}
              title="Apply"
              color="primary"
            />
          </div>
        </div>
      )}
    </div>
  );
};
