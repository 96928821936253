import React from "react";

export interface IPaginationContext {
  key: string;
  page: number;
  total: number;
  pageSize: number;
  setToNextPage: () => void;
  setToPreviousPage: () => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
}

const values: IPaginationContext = {
  key: "",
  page: 1,
  total: 1,
  pageSize: 10,
  setPage: () => {},
  setPageSize: () => {},
  setToNextPage: () => {},
  setToPreviousPage: () => {},
};

export const PaginationContext = React.createContext(values);

export const usePaginationContext = () => {
  const context = React.useContext(PaginationContext);

  if (!context) {
    throw new Error(
      "usePaginationContext must be used inside of PaginationContext"
    );
  }

  return context;
};
