import React from "react";
import { Button } from "../../Button/Button";
import { InputDescription } from "../../InputDescription/InputDescription";
import { NameAndValueRow } from "../../NameAndValueRow/NameAndValueRow";

interface Props {
  shouldUpdate: boolean;
  appVersion: string;
  handleUpdateDevice: () => Promise<void>;
  isUpdating: boolean;
}

export const UpdateDeviceVersion = ({
  shouldUpdate,
  appVersion,
  handleUpdateDevice,
  isUpdating,
}: Props) => {
  return (
    <div className="main-flex">
      <div>
        <NameAndValueRow name="App version" value={appVersion} />
        <InputDescription
          title={
            shouldUpdate
              ? "A newer version is available"
              : "Running the latest version"
          }
          color={shouldUpdate ? "danger" : "theme"}
          className="mt-0"
        />
      </div>

      <Button
        title={isUpdating ? "Updating..." : "UPDATE"}
        color="primary"
        disabled={!shouldUpdate || isUpdating}
        className="Button__extra-small"
        onClick={handleUpdateDevice}
      />
    </div>
  );
};
