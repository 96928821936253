import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouter } from "../../lib/hooks/useRouter";

import { SubRouteProps } from "../../lib/routes/routes";
import { Subscriptions } from "../Billings/Subscriptions/Subscriptions";
import { BillingInfo } from "./BillingInfo/BillingInfo";
import { ManagedPartnerMain } from "./ManagedPartner/ManagedPartnerMain";
import { PaymentMethod } from "./PaymentMethod/PaymentMethod";
import { ResourceNavigation } from "../shared/ResourceNavigation/ResourceNavigation";

import "./BillingsMain.scss";

export const BillingsMain = () => {
  const router = useRouter();

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Subscriptions",
      to: `${router.match.url}/subscriptions`,
      id: "subscriptions",
      render: () => <Subscriptions />,
    },
    {
      exact: true,
      label: "Billing Info",
      id: "billing-info",
      to: `${router.match.url}/billing-info`,
      render: () => <BillingInfo />,
    },
    {
      exact: true,
      label: "Payment Method",
      id: "payment-method",
      to: `${router.match.url}/payment-method`,
      render: () => <PaymentMethod />,
    },
    {
      exact: true,
      label: "Channel Partner",
      id: "channel-partner",
      to: `${router.match.url}/channel-partner`,
      render: () => <ManagedPartnerMain />,
    },
  ];

  return (
    <div className="BillingsMain">
      <ResourceNavigation routes={routes} />
      <Switch>
        {routes.map((item) => (
          <Route
            key={item.id}
            path={item.to}
            render={item.render}
            exact={item.exact ? true : undefined}
          />
        ))}
        <Redirect to={routes[0].to} />
      </Switch>
    </div>
  );
};
