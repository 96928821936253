import React, { useEffect } from "react";

import { WorkplacesTree } from "./WorkplacesTree/WorkplacesTree";
import { WorkplacesContent } from "./WorkplacesContent/WorkplacesContent";
import { useWorkplaceRequests } from "../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import "./Workplaces.scss";

export const Workplaces = () => {
  const { user } = useAuthContext();
  const { getRootWorkplaces } = useWorkplaceRequests();

  const [request, { loading, error, data }] = getRootWorkplaces;

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  return (
    <div className="Workplaces d-flex">
      <WorkplacesTree
        showOnDesktop={true}
        loading={loading}
        error={error}
        rootWorkplaces={rootWorkplaces}
      />
      <WorkplacesContent />
    </div>
  );
};
