import React, { Dispatch, SetStateAction } from "react";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { WorkplacesTreeLoader } from "../../../../views/Manage/Workplaces/WorkplacesTree/WorkplacesTreeLoader/WorkplacesTreeLoader";
import { FloorChildrenType } from "../../../Floors/FloorChildrenList/FloorChildrenExpandedList";
import { ApolloErrorGuard } from "../../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { PageSidebar } from "../../../shared/PageSidebar/PageSidebar";
import { PageSidebarHeader } from "../../../shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { InsightsLocation } from "../../Context/InsightsContext";
import { InsightsWorkplacesTreeLevel } from "./InsightsWorkplacesTreeLevel";

interface Props {
  loading: boolean;
  error: any;
  rootWorkplaces?: (string | WorkplaceFields[] | undefined)[];
  onItemClick: Dispatch<SetStateAction<InsightsLocation>>;
  toggleSidebar: () => void;
}

export const InsightsWorkplacesTree = ({
  loading,
  error,
  rootWorkplaces,
  onItemClick,
  toggleSidebar,
}: Props) => {
  const { pathname } = useRouter();

  // if page is room we have to filter Desk, if page is desk we have
  // to filter Room, thats why we give opposite values
  const filterWorkplace: FloorChildrenType = pathname
    .toLowerCase()
    .includes("room")
    ? "Desk"
    : "Room";

  return (
    <PageSidebar
      className={`PageSidebar--default PageSidebar--Custom`}
      showOnInitialization={false}
      showOnDesktop={false}
      hideToggler
    >
      <PageSidebarHeader
        title="All locations"
        message="Select location for Insights"
        direction="left"
        onTitleClick={() => {
          //we set locationId empty this means
          //it will query in root level
          onItemClick({
            locationId: "",
            locationName: "",
            locationType: "",
          });
          toggleSidebar();
        }}
      />

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<WorkplacesTreeLoader />}
      >
        <ApolloErrorGuard
          error={error}
          errorComponent={<p>Data couldn't be loaded.</p>}
        >
          <div className="default-scroll-y">
            {rootWorkplaces &&
              rootWorkplaces.map((item: any) => {
                if (typeof item !== "object") {
                  return;
                }

                //in the filter, if page is room insights we hide Desks items
                // and vice-versa
                return item
                  .filter(
                    (i: WorkplaceFields) =>
                      i.__typename !== filterWorkplace &&
                      i.__typename !== "Wayfinder"
                  )
                  .map((workplace: WorkplaceFields) => {
                    return (
                      <InsightsWorkplacesTreeLevel
                        item={workplace}
                        toggleParent={() => {}}
                        key={`${workplace.id}-tree-root`}
                        filterWorkplace={filterWorkplace}
                        onItemClick={onItemClick}
                        toggleSidebar={toggleSidebar}
                      />
                    );
                  });
              })}
          </div>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </PageSidebar>
  );
};
