import React from "react";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { CalendarModalWizard } from "../../../Calendars/CalendarModalWizard/CalendarModalWizard";
import { FormSection } from "../../../shared/FormSection/FormSection";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { PersonalCalendarInfo } from "./PersonalCalendarInfo";

export const PersonalCalendarForm = () => {
  const { isOpen, toggleModal } = useToggleModal();
  const { loadingPersonalCalendar, errorPersonalCalendar } =
    useCalendarContext();

  const { pathname } = useRouter();

  return (
    <FormSection
      title="Link personal calendar"
      toggleable
      isActive={pathname.includes("personal")}
    >
      {loadingPersonalCalendar ? (
        <LoadingBox minHeight={50} />
      ) : errorPersonalCalendar ? (
        <div>Could'nt fetch calendar. Pleas reload and try again</div>
      ) : (
        <PersonalCalendarInfo toggleModal={toggleModal} />
      )}

      <CalendarModalWizard
        isOpen={isOpen}
        toggle={toggleModal}
        type="personal"
      />
    </FormSection>
  );
};
