import React, { useEffect, useRef, useState } from "react";
import cs from "classnames";
import { Button } from "reactstrap";

import { Icon } from "../../../shared/Icon/Icon";
import { AddWorkplaceModal } from "../AddWorkplaceModal/AddWorkplaceModal";
import { AddWorkplaceDropdown } from "../AddWorkplaceDropdown/AddWorkplaceDropdown";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../../lib/hooks/useRouter";

import "./AddWorkplaceButton.scss";

export type WorkplaceTypes =
  | "Company"
  | "Site"
  | "Building"
  | "Floor"
  | "Zone"
  | "Room"
  | "Desk"
  | "Device"
  | "Wayfinder";

interface Props {
  title?: string;
  small?: boolean;
  parentId?: string;
  className?: string;
  disabled?: boolean;
  adjustable?: boolean;
  type?: WorkplaceTypes;
}

export const AddWorkplaceButton = (props: Props) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [type, setType] = useState<WorkplaceTypes | null>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { pathname } = useRouter();

  const classes = cs(
    "AddWorkplaceButton",
    {
      "AddWorkplaceButton--small": props.small,
    },
    { "AddWorkplaceButton--open": showDropdown },
    {
      "adjustable-button": props.adjustable,
    },
    {
      "position-relative": showDropdown || pathname !== "/manage/organization",
    },
    props.className
  );
  useEffect(() => {
    if (type !== null) {
      setIsOpen(true);
      setShowDropdown(false);
    }
  }, [type]);

  useEffect(() => {
    if (isOpen) {
      setShowDropdown(false);
    }
  }, [isOpen]);

  const toggleModal = () => {
    setType(null);
    setIsOpen((prev) => !prev);
  };

  const handleDropdownClick = () => {
    if (props.disabled) {
      return;
    }

    setShowDropdown((prev) => !prev);
  };

  useOutsideClickDetection(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div ref={ref} className={classes}>
      {props.small ? (
        <Button color="primary" onClick={handleDropdownClick}>
          {showDropdown ? (
            <Icon icon="close-button-solid" />
          ) : (
            <Icon icon="add-button-solid" />
          )}
        </Button>
      ) : (
        <Button size="medium" color="primary" onClick={handleDropdownClick}>
          {props.title || "Add new"}
        </Button>
      )}

      {showDropdown && (
        <AddWorkplaceDropdown
          type={props.type}
          onClick={(value) => setType(value)}
        />
      )}

      <AddWorkplaceModal
        type={type}
        isOpen={isOpen}
        toggle={toggleModal}
        parentId={props.parentId}
        parentType={props.type}
      />
    </div>
  );
};
