import React, { useEffect, useState } from "react";
import { differenceInMinutes } from "date-fns";
import { useApolloClient } from "@apollo/client";
import { useBookingsMapContext } from "../Context/BookingsMapContext";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

import { RouteComponentProps } from "react-router-dom";
import { FLOOR_FIELDS_FRAGMENT } from "../../../../api/graphql/floors/floors";
import { FloorMapResponse } from "../../../../api/grpc/workplaces/workplaces";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { BookingsFloorMap } from "../BookingsFloorMap/BookingsFloorMap";
import { BookingsMapHeader } from "./BookingsMapHeader";

import "./BookingsMapSingle.scss";

interface Props {
  id: string;
}

export const BookingsMapSingle = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<Props>) => {
  const client = useApolloClient();
  const { getFloorMap } = useWorkplacesClient();
  const { timeZone } = useTimeZoneContext();
  const [data, setData] = useState<FloorMapResponse | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { dateAndTime } = useBookingsMapContext();

  const floorData = client.readFragment({
    id: `Floor:${id}`,
    fragmentName: "FloorFields",
    fragment: FLOOR_FIELDS_FRAGMENT,
  });

  const fetchFloorMap = async (dateTime: string) => {
    try {
      setLoading(true);
      const res = await getFloorMap({
        customerId: "",
        floorId: id,
        dateTime: dateTime,
        timeZone: timeZone,
      });

      return setData(res.response);
    } catch (error: any) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFloorMap(
      differenceInMinutes(new Date(), new Date(dateAndTime)) <= 0
        ? dateAndTime
        : new Date().toISOString()
    );
  }, [dateAndTime, id]);

  return (
    <div className="BookingsMapSingle">
      <BookingsMapHeader title={floorData?.name} data={data} />

      {loading ? (
        <LoadingBox />
      ) : error ? (
        <div>Error fetching markers, please try again.</div>
      ) : (
        <BookingsFloorMap
          data={floorData}
          floorDataMarker={data}
          refetch={() =>
            fetchFloorMap(
              differenceInMinutes(new Date(), new Date(dateAndTime)) <= 0
                ? dateAndTime
                : new Date().toISOString()
            )
          }
        />
      )}
    </div>
  );
};
