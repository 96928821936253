import React from "react";
import { PageHeader } from "../../components/shared/PageHeader/PageHeader";
import { UpgradePlanButton } from "../../components/shared/UpgradePlan/UpgradePlanButton";

interface Props {
  title: string;
}

export const RestrictedPage = ({ title }: Props) => {
  return (
    <div className="default-page">
      <PageHeader title={title ? title : "Restricted Page"} />

      <div className="center-content">
        <h4>You need to upgrade your plan to access this feature</h4>
        <UpgradePlanButton />
      </div>
    </div>
  );
};
