import { useEffect } from "react";
import { useCustomerRequests } from "../../../api/grpc/customer/useCustomerRequests";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { CustomerPermissionsResponse } from "../../../api/grpc/customer/customer";

export const useCustomerPermissions = () => {
  const { getCustomerPermissions } = useCustomerRequests();

  const { data, error, loading, refetch } = useGrpcQuery<
    {},
    CustomerPermissionsResponse
  >({
    method: getCustomerPermissions,
    variables: {},
  });

  useEffect(() => {
    const intervalId = setInterval(async () => {
      refetch();
    }, 180000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  let isBussinesOrPro =
    data?.id?.toLowerCase()?.includes("business") ||
    data?.id?.toLowerCase()?.includes("pro");

  let isBasicOrBussines =
    data?.id?.toLowerCase()?.includes("business") ||
    data?.id?.toLowerCase()?.includes("basic");

  return { data, error, loading, refetch, isBussinesOrPro, isBasicOrBussines };
};
