import React from "react";

import notificationsIcon from "../../assets/images/notifications-icon.svg";

import "./Notifications.scss";

export const Notifications = () => {
  return (
    <div className="Notifications">
      <div className="Notifications__icon" data-count="3">
        <img src={notificationsIcon} alt="Notifications" />
      </div>
    </div>
  );
};
