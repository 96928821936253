import React from "react";

import { CalendarsWizardFooter } from "../../Calendars/CalendarsWizardForm/CalendarsWizardFooter/CalendarsWizardFooter";
import { CalendarsWizardHeader } from "../../Calendars/CalendarsWizardForm/CalendarsWizardHeader/CalendarsWizardHeader";
import { IntegrationsContent } from "../../Integrations/IntegrationsContent";
import { IconDefinition } from "../../shared/Icon/Icon";

interface Props {
  activeStep: number;
  activeCalendar: string;
  handlePrevClick: () => void;
  handleNextClick: (n: number) => void;
  onClick: (id: string) => void;
}

export const DirectorySyncSystem = ({
  activeStep,
  activeCalendar,
  handleNextClick,
  handlePrevClick,
  onClick,
}: Props) => {
  const items: {
    id: string;
    label: string;
    description: string;
    icon: IconDefinition;
  }[] = [
    {
      id: "o365",
      icon: "microsoft-365",
      label: "Microsoft 365",
      description:
        "Applies to: Microsoft 365 (formerly Office 365/Exchange Online)",
    },
    {
      id: "gsuite",
      icon: "google_wp",
      label: "Google Workspace",
      description: "Applies to: Google Workspace",
    },
  ];

  return (
    <div className="CalendarSystem">
      <CalendarsWizardHeader
        activeStep={activeStep}
        handleClick={(n) => console.info(n)}
        isDirectoryUser={true}
      />

      <div className="CalendarSystem__list">
        {items.map((item) => (
          <div key={item.id} className="CalendarSystemItem">
            <div
              className={`CalendarSystem__item px-2 py-2 ${
                activeCalendar === item.id
                  ? "CalendarSystem__item--active bg-light"
                  : ""
              }`}
              title={`${item.label} System`}
              onClick={() => onClick(item.id)}
            >
              <IntegrationsContent
                id={item.id}
                icon={item.icon}
                description={item.description}
                label={item.label}
                badge="User directory"
              />
            </div>
          </div>
        ))}
      </div>

      <CalendarsWizardFooter
        activeStep={activeStep}
        handlePrevClick={handlePrevClick}
        handleNextClick={() => {
          return handleNextClick(activeStep + 1);
        }}
      />
    </div>
  );
};
