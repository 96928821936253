import React from "react";

import { SubRouteProps } from "../../../lib/routes/routes";
import { ResourceNavigation } from "../../shared/ResourceNavigation/ResourceNavigation";
import { InvitePeopleButton } from "../InvitePeople/InvitePeopleButton/InvitePeopleButton";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { tooltips } from "../../../lib/utils/tooltips";

interface Props {
  routes: SubRouteProps[];
}

export const PeopleHeader = ({ routes }: Props) => {
  return (
    <div className="PeopleHeader position-relative">
      <PageHeader title="People" hideBorder message={tooltips.people.heading} />

      <ResourceNavigation routes={routes}>
        <InvitePeopleButton adjustable />
      </ResourceNavigation>
    </div>
  );
};
