import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import { useSidebarContext } from "../../../../lib/context/Sidebar/SidebarContext";

import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import { getFirstRouteFromURL } from "../../../../lib/context/Sidebar/helpers/getFirstRouteFromURL";

import "./SidebarMainMenuItem.scss";

interface Props {
  id: string;
  to: string;
  label: string;
  hasMenu: boolean;
  disabled: boolean;
  icon?: IconDefinition;
}

export const SidebarMainMenuItem = (props: Props) => {
  const sidebarCtx = useSidebarContext();
  const classes = classNames("SidebarMainMenuItem", {
    "SidebarMainMenuItem--disabled": props.disabled,
  });

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.hasMenu) {
      e.preventDefault();
    }

    if (props.disabled || !props.id) {
      return;
    }

    sidebarCtx.handleSidebarClick(props.id, !!props.hasMenu);
  };

  return (
    <li className={classes}>
      <MenuLink {...props} onClick={handleClick} />
    </li>
  );
};

interface LinkProps extends Props {
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

// TO DO: Refactor the structuring of the below component
const MenuLink = ({ id, to, icon, label, disabled, onClick }: LinkProps) => {
  const sidebarCtx = useSidebarContext();

  if (disabled) {
    return (
      <>
        <div className="SidebarMainMenuItem__icon">
          <Icon icon={icon} />
        </div>
        <h5 className="SidebarMainMenuItem__title px-2 mb-0">{label}</h5>
      </>
    );
  }

  return (
    <NavLink
      isActive={(match, location) => {
        if (sidebarCtx.activeMenuItem !== id) {
          return false;
        }

        if (sidebarCtx.activeMenuItem === id) {
          return true;
        }

        if (location.pathname === "/") {
          return to === "/";
        }

        const firstRoute = getFirstRouteFromURL(location.pathname);

        return to ? to.startsWith(firstRoute) : false;
      }}
      to={to}
      onClick={onClick}
      className="d-flex flex-column align-items-center"
    >
      <div className="SidebarMainMenuItem__icon">
        <Icon icon={icon} />
      </div>
      <h5 className="SidebarMainMenuItem__title px-2 mb-0">{label}</h5>
    </NavLink>
  );
};
