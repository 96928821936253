import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import { usePaginationHelper } from "../../../lib/hooks/usePaginationHelper";
import { trimText } from "../../../lib/helpers/trimText";

import { Calendar } from "../../../api/grpc/calendarprovider/calendarprovider";
import { PaginationContext } from "../../../lib/context/Pagination/PaginationContext";
import { CalendarTypeParam } from "../../../views/Manage/Calendars/CalendarSingle/CalendarSingle";
import { Pagination } from "../../shared/Pagination/Pagination";
import {
  StructureTable,
  StructureTableColumn,
} from "../../shared/StructureTable/StructureTable";
import { CenteredBox } from "../../shared/CenteredBox/CenteredBox";
import { ResourceFilter } from "../../shared/ResourceFilter/ResourceFilter";
import { CalendarsFilters } from "./CalendarsFilters";

interface Props {
  data: Calendar[];
  calendarType: CalendarTypeParam;
}
export const ListCalendars = ({ data, calendarType }: Props) => {
  const { page, pageSize, pagination } = usePaginationHelper(data.length);

  const [searchValue, setSearchValue] = useState("");
  const [hasRoom, setHasRoom] = useState<boolean | undefined>(undefined);
  const debouncedValue = useDebounce<string>(searchValue, 500);

  const indexOfLastItem = page * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentCalendarsData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const columns: StructureTableColumn[] = [
    {
      key: "name",
      header: "Name",
    },
    {
      key: "email",
      header: "Email",
      hidden: calendarType.toLowerCase() === "gsuite",
    },
    {
      key: "room",
      header: "Room",
    },
  ];

  let calendarsData = useMemo(() => {
    if (!data) {
      return [];
    }

    if (!!!debouncedValue.length && hasRoom === undefined) {
      return currentCalendarsData;
    }

    if (hasRoom !== undefined) {
      return data.filter((calendar) =>
        hasRoom === true
          ? calendar.hasOwnProperty("room")
          : !calendar.hasOwnProperty("room") &&
            calendar.name.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    }

    return data.filter((calendar) =>
      calendar.name.toLowerCase().includes(debouncedValue.toLowerCase())
    );
  }, [debouncedValue, hasRoom, data, page, pageSize]);

  const rows = calendarsData.map((item) => ({
    name: (
      <h4 className="CalendarAccountsList__name" title={item.name}>
        {trimText(item.name)}
      </h4>
    ),
    email: (
      <h4 className="CalendarAccountsList__email" title={item.email}>
        {trimText(item.email, 25)}
      </h4>
    ),
    room: item?.room ? (
      <NavLink
        to={`/manage/organization/room/${item.room?.id}`}
        className="CalendarAccountsList__status"
      >
        {trimText(item.room?.name || "-")}
      </NavLink>
    ) : (
      <h4 className="CalendarAccountsList__status">-</h4>
    ),
  }));

  return (
    <PaginationContext.Provider value={pagination}>
      <ResourceFilter
        searchValue={searchValue}
        onChangeSearch={(e) => setSearchValue(e.target.value)}
        clearSearchValue={() => setSearchValue("")}
        numberOfFilters={hasRoom !== undefined ? 1 : undefined}
      >
        <CalendarsFilters
          assignedFilter={hasRoom}
          handleAssignedChange={(e) => {
            setHasRoom(e);
          }}
        />
      </ResourceFilter>

      <StructureTable
        rows={rows}
        columns={columns}
        noResultsPlaceholder={<CenteredBox>No calendar found</CenteredBox>}
      />
      {!!!debouncedValue.length && hasRoom === undefined && <Pagination />}
    </PaginationContext.Provider>
  );
};
