import React from "react";

import { tooltips } from "../../lib/utils/tooltips";

import { AccountForm } from "../../components/Account/AccountForm";
import { PageHeader } from "../../components/shared/PageHeader/PageHeader";
import { MfaContextProvider } from "../../lib/context/MFA/MfaContextProvider";

export const Account = () => {
  return (
    <MfaContextProvider>
      <div className="Account default-page">
        <PageHeader
          title="Account profile"
          message={tooltips.account.heading}
          className="pb-4"
        />

        <AccountForm />
      </div>
    </MfaContextProvider>
  );
};
