import { addMinutes, format } from "date-fns";
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { SearchWorkplaceFields } from "../../../views/Bookings/types";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
// import { datePickOptions } from "../form/SearchWorkplaceForm";

const SearchWorkplaceSchema = Yup.object().shape({
  day: Yup.object()
    .shape({
      value: Yup.string().required("Date is required"),
    })
    .required(),
  from: Yup.string().required("From time is required"),
  until: Yup.string().required("Until time is required"),
});

interface UseSearchWorkplaceFormOptions {
  initialValues?: SearchWorkplaceFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: SearchWorkplaceFields,
    formikHelpers: FormikHelpers<SearchWorkplaceFields>
  ) => Promise<any>;
}

export type SearchWorkplaceFormik = ReturnType<typeof useSearchWorkplaceFormik>;

export const useSearchWorkplaceFormik = (
  opts: UseSearchWorkplaceFormOptions
) => {
  const { timeZone } = useTimeZoneContext();

  return useFormik<SearchWorkplaceFields>({
    initialValues: {
      day: {
        label: `Today, ${format(
          utcToZonedTime(new Date(), timeZone),
          "MMM dd"
        )}`,
        value: format(utcToZonedTime(new Date(), timeZone), "yyyy-MM-dd"),
      },
      from: utcToZonedTime(new Date(), timeZone), //new Date(),
      until: utcToZonedTime(addMinutes(new Date(), 15), timeZone),
      tags: [],
      attendees: [],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SearchWorkplaceSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
