import React, { Fragment, useState } from "react";
import { FormFeedback } from "reactstrap";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";
import { Icon, IconDefinition } from "../../../../shared/Icon/Icon";
import { ModalMFA } from "../ModalMFA";

enum MfaCategory {
  TOTP = 1,
  SMS = 2,
}

const options = [
  {
    title: "Use Google Authenticator App",
    description: "Verification codes generated by an authenticator app.",
    icon: "qr-code-option" as IconDefinition,
    value: MfaCategory.TOTP,
  },
  {
    title: "Use SMS",
    description: "Verification codes sent to your mobile phone.",
    icon: "text-icon" as IconDefinition,
    value: MfaCategory.SMS,
    disabled: true,
  },
];

export const MfaTypePicker = () => {
  const [type, setType] = useState(MfaCategory.TOTP);
  const { setMfaModal } = useMfaContext();
  const { generateTotpSecret } = useAuthContext();

  const handleCodeGenerator = async () => {
    try {
      await generateTotpSecret();
      setMfaModal("QRCode");
    } catch (error: any) {
      if (
        ["auth/requires-recent-login", "auth/user-token-expired"].includes(
          error.code
        )
      ) {
        setMfaModal("authentication");
        return;
      }
      setMfaModal("error");
    }
  };

  return (
    <ModalMFA
      title="Activate two-factor authentication"
      keyPoints={["How do you want to receive your security codes?"]}
      nextButton="Next"
      closeButton="Close"
      handleBackButton={() => setMfaModal(undefined)}
      handleNextButton={handleCodeGenerator}
      className="MfaTypePicker"
    >
      {options.map((option, index) => (
        <Fragment key={`${option.title}-${index}`}>
          <div
            tabIndex={option.value === MfaCategory.TOTP ? 0 : -1}
            className={`MfaTypePicker__option ${
              option.value === MfaCategory.SMS
                ? "disabled"
                : "MfaTypePicker__option--totp"
            }`}
            key={option.value}
            onClick={() => {
              if (option.value === MfaCategory.SMS || type === option.value) {
                return;
              }

              setType(option.value);
            }}
          >
            <input
              key="key"
              type="radio"
              name="name"
              checked={type === option.value}
              onChange={() => {
                if (type === option.value) {
                  return;
                }

                setType(option.value);
              }}
            />
            <div>
              <h6>{option.title}</h6>
              <span>{option.description}</span>
            </div>
            <Icon icon={option.icon} />
          </div>
          {option.disabled && (
            <FormFeedback className="form-info">
              This option is currently not available
            </FormFeedback>
          )}
        </Fragment>
      ))}
    </ModalMFA>
  );
};
