import { RoomSelectFields } from "../../../../api/graphql/rooms/rooms";

export const structureOptions = (
  data: RoomSelectFields[] | undefined,
  currentRoom: string
) => {
  if (data === undefined) {
    return [];
  }

  const filteredData = data.filter((item) => item.id !== currentRoom);

  return filteredData.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};
