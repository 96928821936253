// @generated by protobuf-ts 2.9.4 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "ggevent/ggevent.proto" (package "ggevent", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Event
 *
 * @generated from protobuf message ggevent.Event
 */
export interface Event {
    /**
     * @generated from protobuf field: string Title = 1 [json_name = "Title"];
     */
    title: string;
    /**
     * @generated from protobuf field: string StartTime = 2 [json_name = "StartTime"];
     */
    startTime: string;
    /**
     * @generated from protobuf field: string EndTime = 3 [json_name = "EndTime"];
     */
    endTime: string;
    /**
     * @generated from protobuf field: string Description = 4 [json_name = "Description"];
     */
    description: string;
    /**
     * @generated from protobuf field: repeated ggevent.Location Locations = 5 [json_name = "Locations"];
     */
    locations: Location[];
    /**
     * @generated from protobuf field: repeated ggevent.Attendee Attendees = 6 [json_name = "Attendees"];
     */
    attendees: Attendee[];
    /**
     * @generated from protobuf field: ggevent.Attendee Organizer = 7 [json_name = "Organizer"];
     */
    organizer?: Attendee;
    /**
     * @generated from protobuf field: bool AllDay = 8 [json_name = "AllDay"];
     */
    allDay: boolean;
    /**
     * @generated from protobuf field: bool IsPrivate = 9 [json_name = "IsPrivate"];
     */
    isPrivate: boolean;
    /**
     * Extended properties only support basic key/value mapping and doesn't support nested values.
     *
     * @generated from protobuf field: map<string, string> ExtendedProperties = 10 [json_name = "ExtendedProperties"];
     */
    extendedProperties: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, string> MetaData = 11 [json_name = "MetaData"];
     */
    metaData: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string CreationTime = 12 [json_name = "CreationTime"];
     */
    creationTime: string;
    /**
     * @generated from protobuf field: string UpdateTime = 13 [json_name = "UpdateTime"];
     */
    updateTime: string;
    /**
     * @generated from protobuf field: repeated string tags = 14;
     */
    tags: string[];
    /**
     * @generated from protobuf field: string workplaceID = 15;
     */
    workplaceID: string; // The Dgraph ID of the room, usually in the format 0x123
    /**
     * @generated from protobuf field: ggevent.CheckInRequiredStatus checkInRequiredStatus = 16;
     */
    checkInRequiredStatus: CheckInRequiredStatus;
    /**
     * @generated from protobuf field: ggevent.EventCheckInStatus eventCheckInStatus = 17;
     */
    eventCheckInStatus: EventCheckInStatus;
    /**
     * @generated from protobuf field: repeated ggevent.Location workplaceLocationPath = 18;
     */
    workplaceLocationPath: Location[];
    /**
     * @generated from protobuf field: bool IsCheckOut = 19 [json_name = "IsCheckOut"];
     */
    isCheckOut: boolean;
    /**
     * @generated from protobuf field: bool IsOnlineMeeting = 20 [json_name = "IsOnlineMeeting"];
     */
    isOnlineMeeting: boolean;
    /**
     * @generated from protobuf field: string OnlineMeetingUrl = 21 [json_name = "OnlineMeetingUrl"];
     */
    onlineMeetingUrl: string;
    /**
     * @generated from protobuf field: int32 headcount = 22;
     */
    headcount: number;
    /**
     * @generated from protobuf field: string lockPin = 23;
     */
    lockPin: string;
    /**
     * @generated from protobuf field: string eventTimeZone = 24;
     */
    eventTimeZone: string; // Name of timezone when event was booked. Only used for create/update operation
    /**
     * @generated from protobuf field: bool checkInReminderSend = 25;
     */
    checkInReminderSend: boolean; // Flag for indicate if check in reminder notification is send to organizer
    /**
     * @generated from protobuf field: string providerType = 26;
     */
    providerType: string;
    /**
     * @generated from protobuf field: bool notifyAssignee = 27;
     */
    notifyAssignee: boolean;
    /**
     * @generated from protobuf field: bool mode2Organizer = 28;
     */
    mode2Organizer: boolean;
}
/**
 * Location
 *
 * @generated from protobuf message ggevent.Location
 */
export interface Location {
    /**
     * @generated from protobuf field: string displayName = 1;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: string status = 4;
     */
    status: string;
}
/**
 * Attendee
 *
 * @generated from protobuf message ggevent.Attendee
 */
export interface Attendee {
    /**
     * @generated from protobuf field: string firstName = 1;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string surName = 2;
     */
    surName: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: string status = 4;
     */
    status: string;
    /**
     * @generated from protobuf field: ggevent.Avatar avatar = 5;
     */
    avatar?: Avatar;
    /**
     * @generated from protobuf field: string displayName = 6;
     */
    displayName: string;
    /**
     * @generated from protobuf field: bool isHuman = 7;
     */
    isHuman: boolean;
    /**
     * @generated from protobuf field: string id = 8;
     */
    id: string;
    /**
     * @generated from protobuf field: bool allowToFind = 9;
     */
    allowToFind: boolean;
    /**
     * @generated from protobuf field: bool mode1Organizer = 10;
     */
    mode1Organizer: boolean;
}
/**
 * @generated from protobuf message ggevent.Avatar
 */
export interface Avatar {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: bytes base64 = 2;
     */
    base64: Uint8Array;
    /**
     * @generated from protobuf field: bytes bytesArray = 3;
     */
    bytesArray: Uint8Array;
}
/**
 * @generated from protobuf message ggevent.BusyTimeSlots
 */
export interface BusyTimeSlots {
    /**
     * The start time the entity is busy and unavailable for booking. UTC time format.
     *
     * @generated from protobuf field: string startTime = 1;
     */
    startTime: string;
    /**
     * The end time the entity is busy and unavailable for booking. UTC time format.
     *
     * @generated from protobuf field: string endTime = 2;
     */
    endTime: string;
    /**
     * event organizer info
     *
     * @generated from protobuf field: ggevent.Attendee organizer = 3;
     */
    organizer?: Attendee;
    /**
     * is entity assigned to person
     *
     * @generated from protobuf field: bool isAssigned = 4;
     */
    isAssigned: boolean;
    /**
     * @generated from protobuf field: ggevent.EventCheckInStatus checkInStatus = 5;
     */
    checkInStatus: EventCheckInStatus;
    /**
     * @generated from protobuf field: string eventId = 6;
     */
    eventId: string;
    /**
     * @generated from protobuf field: bool isAllDay = 7;
     */
    isAllDay: boolean;
}
/**
 * @generated from protobuf message ggevent.BatchMeetingsRequest
 */
export interface BatchMeetingsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 2;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: repeated string calendarsID = 3;
     */
    calendarsID: string[];
    /**
     * @generated from protobuf field: string startDate = 5;
     */
    startDate: string;
    /**
     * @generated from protobuf field: string endDate = 6;
     */
    endDate: string;
    /**
     * @generated from protobuf field: repeated string fields = 7;
     */
    fields: string[];
    /**
     * @generated from protobuf field: string timeZone = 8;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message ggevent.BatchMeetingsResponse
 */
export interface BatchMeetingsResponse {
    /**
     * @generated from protobuf field: repeated ggevent.GGEventBatch data = 1;
     */
    data: GGEventBatch[];
}
/**
 * @generated from protobuf message ggevent.GGEventBatch
 */
export interface GGEventBatch {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 2;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: string calendarID = 3;
     */
    calendarID: string;
    /**
     * @generated from protobuf field: repeated ggevent.Event events = 4;
     */
    events: Event[];
}
/**
 * @generated from protobuf message ggevent.EventParams
 */
export interface EventParams {
    /**
     * @generated from protobuf field: string itemID = 1;
     */
    itemID: string;
    /**
     * @generated from protobuf field: string calendarID = 2;
     */
    calendarID: string;
    /**
     * @generated from protobuf field: string changeKey = 3;
     */
    changeKey: string;
}
/**
 * @generated from protobuf message ggevent.GetEventsByIdRequest
 */
export interface GetEventsByIdRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 2;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: repeated ggevent.EventParams eventsID = 3;
     */
    eventsID: EventParams[];
}
/**
 * @generated from protobuf message ggevent.GetEventsByIdResponse
 */
export interface GetEventsByIdResponse {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 2;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: repeated ggevent.Event events = 4;
     */
    events: Event[];
}
/**
 * @generated from protobuf enum ggevent.CheckInRequiredStatus
 */
export enum CheckInRequiredStatus {
    /**
     * @generated from protobuf enum value: CHECK_IN_NOT_REQUIRED = 0;
     */
    CHECK_IN_NOT_REQUIRED = 0,
    /**
     * @generated from protobuf enum value: CHECK_IN_REQUIRED = 1;
     */
    CHECK_IN_REQUIRED = 1
}
/**
 * @generated from protobuf enum ggevent.EventCheckInStatus
 */
export enum EventCheckInStatus {
    /**
     * @generated from protobuf enum value: CHECK_IN_NONE = 0;
     */
    CHECK_IN_NONE = 0,
    /**
     * @generated from protobuf enum value: CHECK_IN_NOT_OPEN = 1;
     */
    CHECK_IN_NOT_OPEN = 1,
    /**
     * @generated from protobuf enum value: CHECK_IN_OPEN = 2;
     */
    CHECK_IN_OPEN = 2,
    /**
     * @generated from protobuf enum value: CHECK_IN_CLOSED = 3;
     */
    CHECK_IN_CLOSED = 3,
    /**
     * @generated from protobuf enum value: CHECK_IN_ACTIVATED = 4;
     */
    CHECK_IN_ACTIVATED = 4,
    /**
     * @generated from protobuf enum value: CHECK_IN_OPEN_REMINDER = 5;
     */
    CHECK_IN_OPEN_REMINDER = 5,
    /**
     * @generated from protobuf enum value: CHECK_IN_DECLINED = 6;
     */
    CHECK_IN_DECLINED = 6
}
/**
 * @generated from protobuf enum ggevent.QRCodeRequiredStatus
 */
export enum QRCodeRequiredStatus {
    /**
     * @generated from protobuf enum value: QR_STATUS_NONE = 0;
     */
    QR_STATUS_NONE = 0,
    /**
     * @generated from protobuf enum value: QR_STATUS_REQUIRED = 1;
     */
    QR_STATUS_REQUIRED = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Event$Type extends MessageType<Event> {
    constructor() {
        super("ggevent.Event", [
            { no: 1, name: "Title", kind: "scalar", jsonName: "Title", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "StartTime", kind: "scalar", jsonName: "StartTime", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "EndTime", kind: "scalar", jsonName: "EndTime", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "Description", kind: "scalar", jsonName: "Description", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "Locations", kind: "message", jsonName: "Locations", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 6, name: "Attendees", kind: "message", jsonName: "Attendees", repeat: 1 /*RepeatType.PACKED*/, T: () => Attendee },
            { no: 7, name: "Organizer", kind: "message", jsonName: "Organizer", T: () => Attendee },
            { no: 8, name: "AllDay", kind: "scalar", jsonName: "AllDay", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "IsPrivate", kind: "scalar", jsonName: "IsPrivate", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "ExtendedProperties", kind: "map", jsonName: "ExtendedProperties", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 11, name: "MetaData", kind: "map", jsonName: "MetaData", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 12, name: "CreationTime", kind: "scalar", jsonName: "CreationTime", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "UpdateTime", kind: "scalar", jsonName: "UpdateTime", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "workplaceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "checkInRequiredStatus", kind: "enum", T: () => ["ggevent.CheckInRequiredStatus", CheckInRequiredStatus] },
            { no: 17, name: "eventCheckInStatus", kind: "enum", T: () => ["ggevent.EventCheckInStatus", EventCheckInStatus] },
            { no: 18, name: "workplaceLocationPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 19, name: "IsCheckOut", kind: "scalar", jsonName: "IsCheckOut", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "IsOnlineMeeting", kind: "scalar", jsonName: "IsOnlineMeeting", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "OnlineMeetingUrl", kind: "scalar", jsonName: "OnlineMeetingUrl", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "headcount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "lockPin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "eventTimeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "checkInReminderSend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "providerType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "notifyAssignee", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 28, name: "mode2Organizer", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Event>): Event {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.startTime = "";
        message.endTime = "";
        message.description = "";
        message.locations = [];
        message.attendees = [];
        message.allDay = false;
        message.isPrivate = false;
        message.extendedProperties = {};
        message.metaData = {};
        message.creationTime = "";
        message.updateTime = "";
        message.tags = [];
        message.workplaceID = "";
        message.checkInRequiredStatus = 0;
        message.eventCheckInStatus = 0;
        message.workplaceLocationPath = [];
        message.isCheckOut = false;
        message.isOnlineMeeting = false;
        message.onlineMeetingUrl = "";
        message.headcount = 0;
        message.lockPin = "";
        message.eventTimeZone = "";
        message.checkInReminderSend = false;
        message.providerType = "";
        message.notifyAssignee = false;
        message.mode2Organizer = false;
        if (value !== undefined)
            reflectionMergePartial<Event>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Event): Event {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Title = 1 [json_name = "Title"];*/ 1:
                    message.title = reader.string();
                    break;
                case /* string StartTime = 2 [json_name = "StartTime"];*/ 2:
                    message.startTime = reader.string();
                    break;
                case /* string EndTime = 3 [json_name = "EndTime"];*/ 3:
                    message.endTime = reader.string();
                    break;
                case /* string Description = 4 [json_name = "Description"];*/ 4:
                    message.description = reader.string();
                    break;
                case /* repeated ggevent.Location Locations = 5 [json_name = "Locations"];*/ 5:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ggevent.Attendee Attendees = 6 [json_name = "Attendees"];*/ 6:
                    message.attendees.push(Attendee.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* ggevent.Attendee Organizer = 7 [json_name = "Organizer"];*/ 7:
                    message.organizer = Attendee.internalBinaryRead(reader, reader.uint32(), options, message.organizer);
                    break;
                case /* bool AllDay = 8 [json_name = "AllDay"];*/ 8:
                    message.allDay = reader.bool();
                    break;
                case /* bool IsPrivate = 9 [json_name = "IsPrivate"];*/ 9:
                    message.isPrivate = reader.bool();
                    break;
                case /* map<string, string> ExtendedProperties = 10 [json_name = "ExtendedProperties"];*/ 10:
                    this.binaryReadMap10(message.extendedProperties, reader, options);
                    break;
                case /* map<string, string> MetaData = 11 [json_name = "MetaData"];*/ 11:
                    this.binaryReadMap11(message.metaData, reader, options);
                    break;
                case /* string CreationTime = 12 [json_name = "CreationTime"];*/ 12:
                    message.creationTime = reader.string();
                    break;
                case /* string UpdateTime = 13 [json_name = "UpdateTime"];*/ 13:
                    message.updateTime = reader.string();
                    break;
                case /* repeated string tags */ 14:
                    message.tags.push(reader.string());
                    break;
                case /* string workplaceID */ 15:
                    message.workplaceID = reader.string();
                    break;
                case /* ggevent.CheckInRequiredStatus checkInRequiredStatus */ 16:
                    message.checkInRequiredStatus = reader.int32();
                    break;
                case /* ggevent.EventCheckInStatus eventCheckInStatus */ 17:
                    message.eventCheckInStatus = reader.int32();
                    break;
                case /* repeated ggevent.Location workplaceLocationPath */ 18:
                    message.workplaceLocationPath.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool IsCheckOut = 19 [json_name = "IsCheckOut"];*/ 19:
                    message.isCheckOut = reader.bool();
                    break;
                case /* bool IsOnlineMeeting = 20 [json_name = "IsOnlineMeeting"];*/ 20:
                    message.isOnlineMeeting = reader.bool();
                    break;
                case /* string OnlineMeetingUrl = 21 [json_name = "OnlineMeetingUrl"];*/ 21:
                    message.onlineMeetingUrl = reader.string();
                    break;
                case /* int32 headcount */ 22:
                    message.headcount = reader.int32();
                    break;
                case /* string lockPin */ 23:
                    message.lockPin = reader.string();
                    break;
                case /* string eventTimeZone */ 24:
                    message.eventTimeZone = reader.string();
                    break;
                case /* bool checkInReminderSend */ 25:
                    message.checkInReminderSend = reader.bool();
                    break;
                case /* string providerType */ 26:
                    message.providerType = reader.string();
                    break;
                case /* bool notifyAssignee */ 27:
                    message.notifyAssignee = reader.bool();
                    break;
                case /* bool mode2Organizer */ 28:
                    message.mode2Organizer = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap10(map: Event["extendedProperties"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Event["extendedProperties"] | undefined, val: Event["extendedProperties"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ggevent.Event.ExtendedProperties");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap11(map: Event["metaData"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Event["metaData"] | undefined, val: Event["metaData"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ggevent.Event.MetaData");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Event, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Title = 1 [json_name = "Title"]; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string StartTime = 2 [json_name = "StartTime"]; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string EndTime = 3 [json_name = "EndTime"]; */
        if (message.endTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endTime);
        /* string Description = 4 [json_name = "Description"]; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* repeated ggevent.Location Locations = 5 [json_name = "Locations"]; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated ggevent.Attendee Attendees = 6 [json_name = "Attendees"]; */
        for (let i = 0; i < message.attendees.length; i++)
            Attendee.internalBinaryWrite(message.attendees[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* ggevent.Attendee Organizer = 7 [json_name = "Organizer"]; */
        if (message.organizer)
            Attendee.internalBinaryWrite(message.organizer, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool AllDay = 8 [json_name = "AllDay"]; */
        if (message.allDay !== false)
            writer.tag(8, WireType.Varint).bool(message.allDay);
        /* bool IsPrivate = 9 [json_name = "IsPrivate"]; */
        if (message.isPrivate !== false)
            writer.tag(9, WireType.Varint).bool(message.isPrivate);
        /* map<string, string> ExtendedProperties = 10 [json_name = "ExtendedProperties"]; */
        for (let k of globalThis.Object.keys(message.extendedProperties))
            writer.tag(10, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.extendedProperties[k]).join();
        /* map<string, string> MetaData = 11 [json_name = "MetaData"]; */
        for (let k of globalThis.Object.keys(message.metaData))
            writer.tag(11, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metaData[k]).join();
        /* string CreationTime = 12 [json_name = "CreationTime"]; */
        if (message.creationTime !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.creationTime);
        /* string UpdateTime = 13 [json_name = "UpdateTime"]; */
        if (message.updateTime !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.updateTime);
        /* repeated string tags = 14; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.tags[i]);
        /* string workplaceID = 15; */
        if (message.workplaceID !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.workplaceID);
        /* ggevent.CheckInRequiredStatus checkInRequiredStatus = 16; */
        if (message.checkInRequiredStatus !== 0)
            writer.tag(16, WireType.Varint).int32(message.checkInRequiredStatus);
        /* ggevent.EventCheckInStatus eventCheckInStatus = 17; */
        if (message.eventCheckInStatus !== 0)
            writer.tag(17, WireType.Varint).int32(message.eventCheckInStatus);
        /* repeated ggevent.Location workplaceLocationPath = 18; */
        for (let i = 0; i < message.workplaceLocationPath.length; i++)
            Location.internalBinaryWrite(message.workplaceLocationPath[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* bool IsCheckOut = 19 [json_name = "IsCheckOut"]; */
        if (message.isCheckOut !== false)
            writer.tag(19, WireType.Varint).bool(message.isCheckOut);
        /* bool IsOnlineMeeting = 20 [json_name = "IsOnlineMeeting"]; */
        if (message.isOnlineMeeting !== false)
            writer.tag(20, WireType.Varint).bool(message.isOnlineMeeting);
        /* string OnlineMeetingUrl = 21 [json_name = "OnlineMeetingUrl"]; */
        if (message.onlineMeetingUrl !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.onlineMeetingUrl);
        /* int32 headcount = 22; */
        if (message.headcount !== 0)
            writer.tag(22, WireType.Varint).int32(message.headcount);
        /* string lockPin = 23; */
        if (message.lockPin !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.lockPin);
        /* string eventTimeZone = 24; */
        if (message.eventTimeZone !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.eventTimeZone);
        /* bool checkInReminderSend = 25; */
        if (message.checkInReminderSend !== false)
            writer.tag(25, WireType.Varint).bool(message.checkInReminderSend);
        /* string providerType = 26; */
        if (message.providerType !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.providerType);
        /* bool notifyAssignee = 27; */
        if (message.notifyAssignee !== false)
            writer.tag(27, WireType.Varint).bool(message.notifyAssignee);
        /* bool mode2Organizer = 28; */
        if (message.mode2Organizer !== false)
            writer.tag(28, WireType.Varint).bool(message.mode2Organizer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.Event
 */
export const Event = new Event$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("ggevent.Location", [
            { no: 1, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.displayName = "";
        message.id = "";
        message.type = "";
        message.status = "";
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string displayName */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string displayName = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Attendee$Type extends MessageType<Attendee> {
    constructor() {
        super("ggevent.Attendee", [
            { no: 1, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "surName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "avatar", kind: "message", T: () => Avatar },
            { no: 6, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "isHuman", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "allowToFind", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "mode1Organizer", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Attendee>): Attendee {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.firstName = "";
        message.surName = "";
        message.email = "";
        message.status = "";
        message.displayName = "";
        message.isHuman = false;
        message.id = "";
        message.allowToFind = false;
        message.mode1Organizer = false;
        if (value !== undefined)
            reflectionMergePartial<Attendee>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Attendee): Attendee {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string firstName */ 1:
                    message.firstName = reader.string();
                    break;
                case /* string surName */ 2:
                    message.surName = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                case /* ggevent.Avatar avatar */ 5:
                    message.avatar = Avatar.internalBinaryRead(reader, reader.uint32(), options, message.avatar);
                    break;
                case /* string displayName */ 6:
                    message.displayName = reader.string();
                    break;
                case /* bool isHuman */ 7:
                    message.isHuman = reader.bool();
                    break;
                case /* string id */ 8:
                    message.id = reader.string();
                    break;
                case /* bool allowToFind */ 9:
                    message.allowToFind = reader.bool();
                    break;
                case /* bool mode1Organizer */ 10:
                    message.mode1Organizer = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Attendee, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string firstName = 1; */
        if (message.firstName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.firstName);
        /* string surName = 2; */
        if (message.surName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.surName);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        /* ggevent.Avatar avatar = 5; */
        if (message.avatar)
            Avatar.internalBinaryWrite(message.avatar, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string displayName = 6; */
        if (message.displayName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.displayName);
        /* bool isHuman = 7; */
        if (message.isHuman !== false)
            writer.tag(7, WireType.Varint).bool(message.isHuman);
        /* string id = 8; */
        if (message.id !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.id);
        /* bool allowToFind = 9; */
        if (message.allowToFind !== false)
            writer.tag(9, WireType.Varint).bool(message.allowToFind);
        /* bool mode1Organizer = 10; */
        if (message.mode1Organizer !== false)
            writer.tag(10, WireType.Varint).bool(message.mode1Organizer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.Attendee
 */
export const Attendee = new Attendee$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Avatar$Type extends MessageType<Avatar> {
    constructor() {
        super("ggevent.Avatar", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "base64", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "bytesArray", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<Avatar>): Avatar {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.url = "";
        message.base64 = new Uint8Array(0);
        message.bytesArray = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<Avatar>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Avatar): Avatar {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* bytes base64 */ 2:
                    message.base64 = reader.bytes();
                    break;
                case /* bytes bytesArray */ 3:
                    message.bytesArray = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Avatar, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* bytes base64 = 2; */
        if (message.base64.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.base64);
        /* bytes bytesArray = 3; */
        if (message.bytesArray.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.bytesArray);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.Avatar
 */
export const Avatar = new Avatar$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BusyTimeSlots$Type extends MessageType<BusyTimeSlots> {
    constructor() {
        super("ggevent.BusyTimeSlots", [
            { no: 1, name: "startTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "endTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "organizer", kind: "message", T: () => Attendee },
            { no: 4, name: "isAssigned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "checkInStatus", kind: "enum", T: () => ["ggevent.EventCheckInStatus", EventCheckInStatus] },
            { no: 6, name: "eventId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "isAllDay", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BusyTimeSlots>): BusyTimeSlots {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startTime = "";
        message.endTime = "";
        message.isAssigned = false;
        message.checkInStatus = 0;
        message.eventId = "";
        message.isAllDay = false;
        if (value !== undefined)
            reflectionMergePartial<BusyTimeSlots>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BusyTimeSlots): BusyTimeSlots {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string startTime */ 1:
                    message.startTime = reader.string();
                    break;
                case /* string endTime */ 2:
                    message.endTime = reader.string();
                    break;
                case /* ggevent.Attendee organizer */ 3:
                    message.organizer = Attendee.internalBinaryRead(reader, reader.uint32(), options, message.organizer);
                    break;
                case /* bool isAssigned */ 4:
                    message.isAssigned = reader.bool();
                    break;
                case /* ggevent.EventCheckInStatus checkInStatus */ 5:
                    message.checkInStatus = reader.int32();
                    break;
                case /* string eventId */ 6:
                    message.eventId = reader.string();
                    break;
                case /* bool isAllDay */ 7:
                    message.isAllDay = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BusyTimeSlots, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string startTime = 1; */
        if (message.startTime !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startTime);
        /* string endTime = 2; */
        if (message.endTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endTime);
        /* ggevent.Attendee organizer = 3; */
        if (message.organizer)
            Attendee.internalBinaryWrite(message.organizer, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool isAssigned = 4; */
        if (message.isAssigned !== false)
            writer.tag(4, WireType.Varint).bool(message.isAssigned);
        /* ggevent.EventCheckInStatus checkInStatus = 5; */
        if (message.checkInStatus !== 0)
            writer.tag(5, WireType.Varint).int32(message.checkInStatus);
        /* string eventId = 6; */
        if (message.eventId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.eventId);
        /* bool isAllDay = 7; */
        if (message.isAllDay !== false)
            writer.tag(7, WireType.Varint).bool(message.isAllDay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.BusyTimeSlots
 */
export const BusyTimeSlots = new BusyTimeSlots$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchMeetingsRequest$Type extends MessageType<BatchMeetingsRequest> {
    constructor() {
        super("ggevent.BatchMeetingsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendarsID", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "fields", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchMeetingsRequest>): BatchMeetingsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        message.calendarsID = [];
        message.startDate = "";
        message.endDate = "";
        message.fields = [];
        message.timeZone = "";
        if (value !== undefined)
            reflectionMergePartial<BatchMeetingsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchMeetingsRequest): BatchMeetingsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 2:
                    message.calendarProviderID = reader.string();
                    break;
                case /* repeated string calendarsID */ 3:
                    message.calendarsID.push(reader.string());
                    break;
                case /* string startDate */ 5:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 6:
                    message.endDate = reader.string();
                    break;
                case /* repeated string fields */ 7:
                    message.fields.push(reader.string());
                    break;
                case /* string timeZone */ 8:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchMeetingsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 2; */
        if (message.calendarProviderID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderID);
        /* repeated string calendarsID = 3; */
        for (let i = 0; i < message.calendarsID.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.calendarsID[i]);
        /* string startDate = 5; */
        if (message.startDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 6; */
        if (message.endDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.endDate);
        /* repeated string fields = 7; */
        for (let i = 0; i < message.fields.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fields[i]);
        /* string timeZone = 8; */
        if (message.timeZone !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.BatchMeetingsRequest
 */
export const BatchMeetingsRequest = new BatchMeetingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchMeetingsResponse$Type extends MessageType<BatchMeetingsResponse> {
    constructor() {
        super("ggevent.BatchMeetingsResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GGEventBatch }
        ]);
    }
    create(value?: PartialMessage<BatchMeetingsResponse>): BatchMeetingsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = [];
        if (value !== undefined)
            reflectionMergePartial<BatchMeetingsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchMeetingsResponse): BatchMeetingsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ggevent.GGEventBatch data */ 1:
                    message.data.push(GGEventBatch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchMeetingsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ggevent.GGEventBatch data = 1; */
        for (let i = 0; i < message.data.length; i++)
            GGEventBatch.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.BatchMeetingsResponse
 */
export const BatchMeetingsResponse = new BatchMeetingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GGEventBatch$Type extends MessageType<GGEventBatch> {
    constructor() {
        super("ggevent.GGEventBatch", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GGEventBatch>): GGEventBatch {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        message.calendarID = "";
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GGEventBatch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GGEventBatch): GGEventBatch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 2:
                    message.calendarProviderID = reader.string();
                    break;
                case /* string calendarID */ 3:
                    message.calendarID = reader.string();
                    break;
                case /* repeated ggevent.Event events */ 4:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GGEventBatch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 2; */
        if (message.calendarProviderID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderID);
        /* string calendarID = 3; */
        if (message.calendarID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarID);
        /* repeated ggevent.Event events = 4; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.GGEventBatch
 */
export const GGEventBatch = new GGEventBatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventParams$Type extends MessageType<EventParams> {
    constructor() {
        super("ggevent.EventParams", [
            { no: 1, name: "itemID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "changeKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventParams>): EventParams {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.itemID = "";
        message.calendarID = "";
        message.changeKey = "";
        if (value !== undefined)
            reflectionMergePartial<EventParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventParams): EventParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string itemID */ 1:
                    message.itemID = reader.string();
                    break;
                case /* string calendarID */ 2:
                    message.calendarID = reader.string();
                    break;
                case /* string changeKey */ 3:
                    message.changeKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string itemID = 1; */
        if (message.itemID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.itemID);
        /* string calendarID = 2; */
        if (message.calendarID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarID);
        /* string changeKey = 3; */
        if (message.changeKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.changeKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.EventParams
 */
export const EventParams = new EventParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsByIdRequest$Type extends MessageType<GetEventsByIdRequest> {
    constructor() {
        super("ggevent.GetEventsByIdRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "eventsID", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventParams }
        ]);
    }
    create(value?: PartialMessage<GetEventsByIdRequest>): GetEventsByIdRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        message.eventsID = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsByIdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsByIdRequest): GetEventsByIdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 2:
                    message.calendarProviderID = reader.string();
                    break;
                case /* repeated ggevent.EventParams eventsID */ 3:
                    message.eventsID.push(EventParams.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsByIdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 2; */
        if (message.calendarProviderID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderID);
        /* repeated ggevent.EventParams eventsID = 3; */
        for (let i = 0; i < message.eventsID.length; i++)
            EventParams.internalBinaryWrite(message.eventsID[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.GetEventsByIdRequest
 */
export const GetEventsByIdRequest = new GetEventsByIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsByIdResponse$Type extends MessageType<GetEventsByIdResponse> {
    constructor() {
        super("ggevent.GetEventsByIdResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GetEventsByIdResponse>): GetEventsByIdResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsByIdResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsByIdResponse): GetEventsByIdResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 2:
                    message.calendarProviderID = reader.string();
                    break;
                case /* repeated ggevent.Event events */ 4:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsByIdResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 2; */
        if (message.calendarProviderID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderID);
        /* repeated ggevent.Event events = 4; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ggevent.GetEventsByIdResponse
 */
export const GetEventsByIdResponse = new GetEventsByIdResponse$Type();
