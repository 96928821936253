import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { HistoricalEventCheckin } from "../../../../api/grpc/insights/insights";

const checkInStatus: { [key in HistoricalEventCheckin]: string } = {
  [HistoricalEventCheckin.HistoricalEventCheckin_CONFIRMED]: "Yes",
  [HistoricalEventCheckin.HistoricalEventCheckin_NOT_CONFIRMED]: "No",
  [HistoricalEventCheckin.HistoricalEventCheckin_NONE]: "N/A",
};

export const HistoryCheckInStatus = ({
  checkedIn,
}: PropsWithChildren<{
  checkedIn: HistoricalEventCheckin;
}>) => {
  const classes = cs("MainStatus", {
    "MainStatus--active":
      checkedIn === HistoricalEventCheckin.HistoricalEventCheckin_CONFIRMED,
    "MainStatus--inactive":
      checkedIn === HistoricalEventCheckin.HistoricalEventCheckin_NOT_CONFIRMED,
  });

  return <span className={classes}>{checkInStatus[checkedIn]}</span>;
};
