import L from "leaflet";

type RoomIconParams = {
  isBooked: boolean;
  isFocused: boolean;
  size: number;
};

const RoomIcon = ({ isBooked, isFocused, size }: RoomIconParams) =>
  new L.DivIcon({
    html: `
    <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5_7)">
    <rect x="10" y="10" width="24" height="24" rx="3" fill="${
      isFocused ? "#00555E" : "white"
    }"/>
    <rect x="12" y="12" width="20" height="20" rx="2" fill="${
      isBooked ? "#FF0000" : "#01EC96"
    }"/>
    <path d="M21.1441 22.1027C21.415 22.1027 21.6441 21.811 21.6441 21.436C21.6441 21.0819 21.415 20.7694 21.1441 20.7694C20.8525 20.7694 20.6441 21.0819 20.6441 21.436C20.6441 21.811 20.8525 22.1027 21.1441 22.1027ZM28.1441 25.7694H25.8108V18.4777C25.8108 17.9152 25.3525 17.436 24.8108 17.436H23.1441V16.811C23.1441 16.4152 22.8316 16.1027 22.4775 16.1027C22.4149 16.1027 22.3525 16.1235 22.3108 16.1444L18.3108 17.1652C17.9983 17.2485 17.8108 17.5194 17.8108 17.8319V25.7694H15.4775C15.29 25.7694 15.1441 25.9361 15.1441 26.1027V26.4361C15.1441 26.6235 15.29 26.7694 15.4775 26.7694H28.1441C28.3108 26.7694 28.4775 26.6235 28.4775 26.4361V26.1027C28.4775 25.9361 28.3108 25.7694 28.1441 25.7694ZM22.1441 25.7694H18.8108V18.0819L22.1441 17.2069V25.7694ZM24.8108 25.7694H23.1441V18.436H24.8108V25.7694Z" fill="black" fill-opacity="0.5"/>
    </g>
    <defs>
    <filter id="filter0_d_5_7" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_7"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_7" result="shape"/>
    </filter>
    </defs>
    </svg>
  `,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    className: "",
  });

export { RoomIcon };
