import React from "react";
import {
  MeetingBehaviorResponse,
  SankeyChartResponse,
} from "../../../../../api/grpc/insights/insights";

export interface BehaviourInsights {
  loading: boolean;
  error?: Error;
  data?: MeetingBehaviorResponse;
  loadingSankey: boolean;
  errorSankey?: Error;
  dataSankey?: SankeyChartResponse;
}

const values: BehaviourInsights = {
  loading: false,
  error: undefined,
  data: undefined,
  loadingSankey: false,
  errorSankey: undefined,
  dataSankey: undefined,
};

export const BehaviourInsightsContext = React.createContext(values);

export const useBehaviourInsightsContext = () => {
  const context = React.useContext(BehaviourInsightsContext);

  if (!context) {
    throw new Error(
      "useBehaviourInsightsContext must be used inside of BehaviourInsightsContextInsightsContext"
    );
  }

  return context;
};
