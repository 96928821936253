import React, { PropsWithChildren } from "react";
import Select from "react-select";
import { Subscription } from "../../../../../../api/grpc/subscription/subscription";
import { selectStyles } from "../../../../../../lib/utils/selectStyles";
import { useCalulactorProContext } from "../../Context/CalculatorProContext";

export const SelectSub = ({
  subscriptions,
}: PropsWithChildren<{
  subscriptions: Subscription[];
}>) => {
  const { setSubscription } = useCalulactorProContext();

  const { subscription } = useCalulactorProContext();
  return (
    <div>
      <Select
        isSearchable
        theme={selectStyles}
        value={
          !!subscription
            ? {
                label: subscription?.company + " " + `(${subscription?.email})`,
                value: subscription?.email,
                sub: subscription,
              }
            : undefined
        }
        options={subscriptions.map((sub) => {
          return {
            label: sub.company + " " + `(${sub.email})`,
            value: sub.email,
            sub: sub,
          };
        })}
        onChange={(e) => {
          if (!e) {
            return;
          }

          setSubscription(e.sub);
        }}
      />
    </div>
  );
};
