import React from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useConfirmation } from "../../../../lib/context/Confirmation/ConfirmationContext";
import { useCalendarRequests } from "../../../../api/grpc/calendarprovider/useCalendarRequests";
import { getCalendarIcon } from "../../../../components/Calendars/lib/helpers";

import { CalendarEWSSingle } from "./CalendarEWSSingle";
import { tooltips } from "../../../../lib/utils/tooltips";
import { CalendarDefaultSingle } from "./CalendarDefaultSingle";
import { CALENDARS_ROOT_PATH } from "../../../../lib/routes/routes";
import { ResourceActions } from "../../../../components/shared/ResourceActions/ResourceActions";
import { GGCalendarProviderStatus } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { CalendarError } from "../../../../components/Calendars/CalendarError/CalendarError";
import { Icon } from "../../../../components/shared/Icon/Icon";

import "./CalendarSingle.scss";

export type CalendarTypeParam = "gsuite" | "ews" | "o365";

export interface CalendarSingleParams {
  id: string;
  calendarType: CalendarTypeParam;
}

const templates: {
  [key: string]: React.FunctionComponent<{
    type: string;
    shouldRefetchAllCalendars?: boolean;
  }>;
} = {
  ews: CalendarEWSSingle,
  default: CalendarDefaultSingle,
};

const calendarMessage: { [key in CalendarTypeParam]: string } = {
  ews: tooltips.calendars.ews.heading,
  gsuite: tooltips.calendars.heading,
  o365: tooltips.calendars.heading,
};

export const CalendarSingle = () => {
  const { history } = useRouter();
  const confirmation = useConfirmation();
  const params = useParams<CalendarSingleParams>();
  const { deleteProvider: deleteFromAPI } = useCalendarRequests();
  const { deleteProvider: deleteFromContext, getProviderByID } =
    useCalendarContext();

  const calendar = getProviderByID(params.id);

  const handleCalendarDelete = async () => {
    await confirmation.confirm({
      title: "Are you sure that you want to delete this calendar?",
    });

    try {
      await deleteFromAPI(params.id);

      deleteFromContext(params.id);

      history.replace(CALENDARS_ROOT_PATH);

      toast.success("Calendar removed successfully!");
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || "Failed deleting Calendar!");
    }
  };

  const Component =
    templates[params.calendarType as string] || templates.default;

  return (
    <div className="CalendarSingle">
      <div className="CalendarSingle__header">
        <div className="flex-a-center CalendarSingle__header--title">
          <Icon icon={getCalendarIcon(calendar?.iD || "")} />
          <h1>{calendar?.name}</h1>
        </div>
        <ResourceActions
          onDelete={handleCalendarDelete}
          message={
            calendarMessage[
              params.calendarType.toLowerCase() as CalendarTypeParam
            ]
          }
        />
      </div>

      {calendar?.status === GGCalendarProviderStatus.FAILED && (
        <CalendarError calendar={calendar} message={calendar.apiError} />
      )}

      <Component
        type={params.calendarType}
        shouldRefetchAllCalendars={
          calendar?.status !== GGCalendarProviderStatus.FAILED
        }
      />
    </div>
  );
};
