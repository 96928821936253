import { CalendarType } from "../../../../../api/grpc/calendarprovider/calendarprovider";
import { Providers } from "../../../../../lib/context/Calendar/CalendarContextProvider";

interface Props {
  calendarId?: string;
  providerId?: string;
  providers: Providers[];
}

export const retrieveCalendarName = (props: Props) => {
  const { calendarId, providerId, providers } = props;

  const calendarProvider = providers.find(
    (provider) => provider.iD === providerId
  );

  if (!providerId || calendarProvider === undefined) {
    return;
  }

  if (calendarProvider.calType === CalendarType.EWS && calendarId) {
    return calendarId;
  }

  return calendarProvider.name;
};
