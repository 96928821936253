import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useDebounce } from "../../../../lib/hooks/useDebounce";
import { useWorkplacesFilters } from "../../lib/useWorkplacesFilters";
import { useAllTags } from "../../lib/useAllTags";
import useLocalStorageState from "use-local-storage-state";

import {
  GET_ROOT_WORKPLACES,
  GetWorkplacesResponse,
  WorkplaceRequestVariables,
} from "../../../../api/graphql/workplaces";
import { WorkplaceList, WorkplaceListData } from "../WorkplaceList";
import { ResourceFilter } from "../../../shared/ResourceFilter/ResourceFilter";
import { WorkplacesFilters } from "./WorkplacesFilters";
import { SearchedWorkplaceChildren } from "../../EditWorkplace/SearchedWorkplaceChildren/SearchedWorkplaceChildren";
import { numberOfFilters } from "../../lib/numberOfActiveFilters";

export const WorkplacesDefaultList = () => {
  const { customer } = useCustomerContext();
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce<string>(searchValue, 500);
  const [planChange, setPlanChange] = useLocalStorageState(`plan-change-event`);

  const { workplacesFilters, setWorkplacesFilters, activeFilters } =
    useWorkplacesFilters();

  const { activeTagsFilter, setActiveTagsFilter } = useAllTags();

  const { loading, error, data, refetch } = useQuery<
    GetWorkplacesResponse,
    WorkplaceRequestVariables
  >(GET_ROOT_WORKPLACES, {
    variables: {
      tenantId: customer.tenantId,
    },
  });

  useEffect(() => {
    if (planChange === true) {
      refetch();
      setPlanChange(false);
    }

    return;
  }, []);

  const tenantData = data?.queryTenant[0];

  const rowsData: WorkplaceListData[] = [
    {
      data: tenantData?.companies,
      type: "Company",
    },
    {
      data: tenantData?.sites,
      type: "Site",
    },
    {
      data: tenantData?.buildings,
      type: "Building",
    },
    {
      data: tenantData?.floors,
      type: "Floor",
    },
    {
      data: tenantData?.zones,
      type: "Zone",
    },
    {
      data: tenantData?.rooms,
      type: "Room",
    },
    {
      data: tenantData?.desks,
      type: "Desk",
    },
    {
      data: tenantData?.wayfinders,
      type: "Wayfinder",
    },
  ];

  return (
    <>
      {!!tenantData?.id.length && (
        <ResourceFilter
          onChangeSearch={(e) => setSearchValue(e.target.value)}
          searchValue={searchValue}
          numberOfFilters={numberOfFilters(activeFilters, activeTagsFilter)}
          clearSearchValue={() => setSearchValue("")}
        >
          <WorkplacesFilters
            workplacesFilters={workplacesFilters}
            setWorkplacesFilters={setWorkplacesFilters}
            disableClearButton={
              activeFilters === 0 && !!!activeTagsFilter.length
            }
            activeTagsFilter={activeTagsFilter}
            setActiveTagsFilter={setActiveTagsFilter}
            id={tenantData.id}
          />
        </ResourceFilter>
      )}

      {!!debouncedValue.length ||
      activeFilters > 0 ||
      !!activeTagsFilter.length ? (
        <SearchedWorkplaceChildren
          debouncedValue={debouncedValue}
          id={data?.queryTenant[0].id || ""}
          licenseFilter={workplacesFilters.license}
          location={workplacesFilters.location}
          resource={workplacesFilters.resources}
          tags={activeTagsFilter}
        />
      ) : (
        <WorkplaceList loading={loading} error={error} rows={rowsData} />
      )}
    </>
  );
};
