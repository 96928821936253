import { useFormik, FormikHelpers } from "formik";
import { rest } from "lodash";
import * as Yup from "yup";

export interface ContactSalesFields {
  email: string;
  name: string;
  message: string;
}
const ContactSalesSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email field is required!"),
  name: Yup.string()
    .min(2, "Please add a valid name")
    .required("Name field is required!"),
  message: Yup.string().required("Message is required"),
});

interface UseContactSalesFormOptions {
  initialValues?: ContactSalesFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: ContactSalesFields,
    formikHelpers: FormikHelpers<ContactSalesFields>
  ) => Promise<any>;
}

export type ContactSalesFormik = ReturnType<typeof useContactSalesFormik>;

export const useContactSalesFormik = (opts: UseContactSalesFormOptions) => {
  return useFormik<ContactSalesFields>({
    initialValues: {
      email: "",
      name: "",
      message: "",
    },
    ...rest,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ContactSalesSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
