import React from "react";
import { tooltips } from "../../../../lib/utils/tooltips";
import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { ChannelPartnerFormik } from "../lib/useChannelPartnerFormik";
import { ManagedPartnerForm } from "./ManagedPartnerForm";

interface Props {
  connectPartner: () => void;
  formik: ChannelPartnerFormik;
}

export const AddManagedPartner = ({ connectPartner, formik }: Props) => {
  return (
    <>
      <InfoCard
        title="Channel Partner"
        hasButton
        buttonTitle="Connect"
        onClick={() => connectPartner()}
        message={tooltips.organizationSettings.channelPartner}
      >
        <ManagedPartnerForm formik={formik} />
      </InfoCard>
    </>
  );
};
