import React, { PropsWithChildren, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";

import { useAuthContext } from "../Auth/AuthContext";
import { useLicenses } from "../../../api/grpc/subscription/useLicenses";

import {
  useWorkplaceRequests,
  LicenseUpdateRequest,
} from "../../../api/graphql/useWorkplaceRequests";

import { CustomerContext, LicensesContext } from "./CustomerContext";
import {
  GET_ROOT_WORKPLACES,
  GetWorkplacesResponse,
  WorkplaceRequestVariables,
} from "../../../api/graphql/workplaces";
import { LicenseStatus } from "../../../api/grpc/subscription/subscription";
import { EntityType } from "../../../api/grpc/account/subscription/subscription";

export const CustomerContextProvider = (props: PropsWithChildren<{}>) => {
  const { user } = useAuthContext();
  const [disabled, setDisabled] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { updateChargeBeeLicense, ...licenses } = useLicenses();
  const { updateGraphQLLicense, addTenant } = useWorkplaceRequests();

  const { loading, error, data, refetch } = useQuery<
    GetWorkplacesResponse,
    WorkplaceRequestVariables
  >(GET_ROOT_WORKPLACES, {
    variables: {
      tenantId: user?.customerid || "",
    },
  });

  const retrieveTenant = async () => {
    const customer = data?.queryTenant[0];

    if (!customer) {
      const { data } = await addTenant();

      const newTenant = data?.addTenant.tenant[0];

      return {
        id: newTenant?.id || "",
        tenantId: newTenant?.tenantId || "",
      };
    }

    return {
      id: customer.id,
      tenantId: customer.tenantId,
    };
  };

  const handleLicenseUpdate = async (variables: LicenseUpdateRequest) => {
    try {
      setDisabled(true);
      setSpinner(true);
      const { response } = await updateChargeBeeLicense(variables);

      if (response.status === LicenseStatus.FAIL) {
        toast.error(response.message);
        setSpinner(false);

        return false;
      }

      if (variables.type !== EntityType.USER) {
        await updateGraphQLLicense(variables);
      }

      toast.success("License updated successfully!");
      setSpinner(false);

      return true;
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
      setSpinner(false);

      return false;
    } finally {
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
    }
  };

  const context: CustomerContext = {
    customer: data?.queryTenant[0],
    error,
    loading,
    licenses,
    retrieveTenant,
    refetch,
  };

  const licensesContext: LicensesContext = {
    disabled,
    spinner,
    handleLicenseUpdate,
  };

  return (
    <CustomerContext.Provider value={context}>
      <LicensesContext.Provider value={licensesContext}>
        {props.children}
      </LicensesContext.Provider>
    </CustomerContext.Provider>
  );
};
