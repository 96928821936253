import React, { useMemo } from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BookingsMapModalTypes } from "../BookingsMapHeader";
import { useBookingsMapContext } from "../../Context/BookingsMapContext";

import { SelectTimeModal } from "../ModalTypes/SelectTimeModal";
import { Button } from "../../../../shared/Button/Button";
import { Icon } from "../../../../shared/Icon/Icon";
import { Person } from "../../../../../api/grpc/contacts/contacts";
import { FindPeopleModal } from "./FindPeopleModal";
import { FloorMapResponse } from "../../../../../api/grpc/workplaces/workplaces";
import { NoMatchFound } from "../../BookingsMapModal/shared/NoMatchFound";
import "./BookingsMapModal.scss";
import "../../../form/SearchWorkplaceForm.scss";

interface Props {
  isOpen: boolean;
  type: BookingsMapModalTypes;
  toggleModal: () => void;

  data?: FloorMapResponse;
}

export const BookingsMapModal = ({
  isOpen,
  type,
  toggleModal,

  data,
}: Props) => {
  const { searchByDate, handleSearchedAttendees, searchedAttendees } =
    useBookingsMapContext();

  const handleSearch = () => () => {
    if (type === "time") {
      searchByDate();
      toggleModal();
      return;
    }

    toggleModal();
  };

  const checkUserReservation = () => {
    if (!data || type === "time" || searchedAttendees.length <= 0) {
      return [];
    }

    return searchedAttendees.map((attendee) => {
      let obj = [...data.desks, ...data.rooms].filter(
        (item) => item.organizer?.id === attendee.accountID
      );

      if (obj.length) {
        return 1;
      }
    });
  };

  const hasReservation = useMemo(() => {
    return checkUserReservation();
  }, [data, checkUserReservation]);

  return (
    <Modal isOpen={isOpen} className="BookingsMapModal SearchWorkplaceForm">
      <ModalHeader>
        <span onClick={toggleModal} className="btn-close">
          <Icon icon="close-icon" />
        </span>
      </ModalHeader>

      <ModalBody>
        {type === "time" ? (
          <SelectTimeModal />
        ) : (
          <>
            <FindPeopleModal
              onChange={(values: Person[]) => handleSearchedAttendees(values)}
              attendees={searchedAttendees}
            />
            {!!hasReservation.length && !hasReservation.includes(1) && (
              <NoMatchFound />
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter className="flex-center">
        <Button
          color="third"
          title="Cancel"
          size="small"
          onClick={toggleModal}
        />
        <Button
          color="primary"
          title="Apply"
          size="small"
          onClick={handleSearch()}
        />
      </ModalFooter>
    </Modal>
  );
};
