import { useQuery } from "@apollo/client";
import React, { PropsWithChildren } from "react";
import {
  GET_WORKSPACE_NAME,
  WorkspaceNameResponse,
} from "../../../../api/graphql/bookings/bookings";
import { trimText } from "../../../../lib/helpers/trimText";
import { Button } from "../../../shared/Button/Button";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";

export const BookingsSearchButton = ({
  id,
  togglePageSidebar,
  className,
  isSmall,
}: PropsWithChildren<{
  id: string;
  togglePageSidebar: () => void;
  className?: string;
  isSmall?: boolean;
}>) => {
  const getWorkplaceName = useQuery<WorkspaceNameResponse, { id: string }>(
    GET_WORKSPACE_NAME,
    {
      fetchPolicy: "network-only",
      variables: {
        id: id,
      },
    }
  );

  const handleClick = () => {
    if (!!getWorkplaceName.data?.getWorkplace) {
      return togglePageSidebar();
    }

    return;
  };

  return (
    <HandleLoadingState
      loading={getWorkplaceName.loading}
      loadingPlaceholder={<LoadingBox minHeight={50} />}
    >
      <HandleRequestState
        state={!!!getWorkplaceName.data?.getWorkplace}
        placeholder={<div>Could't fetch location name</div>}
      >
        <Button
          title={trimText(getWorkplaceName.data?.getWorkplace?.name ?? "", 25)}
          color="primary"
          size={isSmall ? "small" : undefined}
          icon="location-reserve"
          onClick={handleClick}
          className={className}
        />
      </HandleRequestState>
    </HandleLoadingState>
  );
};
