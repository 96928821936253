import React from "react";

import { useCustomerDetails } from "../../lib/useCustomerDetails";
import { usePartnerData } from "../../lib/usePartnerData";
import { useSubscriptionRequests } from "../../../../api/grpc/subscription/useSubscriptionRequests";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useOpenBillingInfo } from "../../lib/useOpenBillingInfo";
import useLocalStorageState from "use-local-storage-state";

import { openCustomerPortal } from "../../../Subscription/lib/openCustomerPortal";
import { toast } from "react-toastify";
import { tooltips } from "../../../../lib/utils/tooltips";
import { Subscription as SubscriptionResponse } from "../../../../api/grpc/subscription/subscription";

import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { SubscriptionItems } from "../SubscriptionsRows/SubscriptionItems";
import { SubscriptionPlan } from "../SubscriptionsRows/SubscriptionPlan";
import { AddInfoModal } from "../../shared/AddInfoModal";

export const SubscriptionsInfo = () => {
  const { data: partnerData } = usePartnerData();
  const {
    loading: customerLoading,
    error: customerError,
    hasRequiredFieldsAdded,
    refetch: refetchCustomer,
  } = useCustomerDetails();
  const [planChange, setPlanChange] = useLocalStorageState(
    `plan-change-event`,
    {
      defaultValue: false,
    }
  );

  const { getCustomerSubscription } = useSubscriptionRequests();
  const { loading, error, data, refetch } = useGrpcQuery<
    {},
    SubscriptionResponse
  >({
    method: getCustomerSubscription,
    variables: {},
  });

  const { isOpen, toggleModal } = useToggleModal();

  const { user, tokenInterceptor, chargebeeProperties } = useAuthContext();
  const { handleBillingModal } = useOpenBillingInfo(() => {
    refetchCustomer();
    toggleModal();
  });

  const handleOpenWidget = async () => {
    if (!hasRequiredFieldsAdded) {
      return toggleModal();
    }
    try {
      const customerPortal = await openCustomerPortal(
        user?.customerid || "",
        tokenInterceptor
      );

      return customerPortal?.cbPortal.open(
        {
          close: () => {
            refetch();
            setPlanChange(true);
          },
        },
        chargebeeProperties("EDIT_SUBSCRIPTION")
      );
    } catch (error: any) {
      toast.error(
        "Failed to fetch your data to open widget, please reload and try again!"
      );
      console.log(error);
    }
  };

  return (
    <HandleRequestState
      state={loading || customerLoading}
      placeholder={
        <>
          <LoadingBox minHeight={235} />
        </>
      }
    >
      <HandleRequestState
        state={!!error || !!customerError}
        placeholder={<p>Error loading subscription information</p>}
      >
        <InfoCard
          title="Subscription Information"
          hasButton
          disabled={!!partnerData?.email}
          onClick={handleOpenWidget}
          message={tooltips.organizationSettings.subscription.info}
        >
          <SubscriptionPlan
            data={data}
            hasManagedPartner={!!partnerData?.email}
          />
          <SubscriptionItems subscription={data} partnerData={partnerData} />

          {isOpen && (
            <AddInfoModal
              handleBillingModal={handleBillingModal}
              toggleModal={toggleModal}
            />
          )}
        </InfoCard>
      </HandleRequestState>
    </HandleRequestState>
  );
};
