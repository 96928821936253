import React, { PropsWithChildren, useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { defineLeastMostPaddingChart } from "../../../../shared/lib/defineLeastMostPaddingChart";
import { trimText } from "../../../../../../lib/helpers/trimText";

export const ResponsiveBarChart = ({
  data,
  dataLength,
}: PropsWithChildren<{
  data: { id: string; name: string; value: number; color: string }[];
  dataLength: number;
}>) => {
  const defineLengthOfName = useMemo(() => {
    return data.some((item) => {
      return item.name.split("-innerGoGetIDSplitter-")[0].length >= 14;
    });
  }, [data]);

  return (
    <>
      <ResponsiveBar
        data={data.slice().reverse()}
        colors={{ datum: "data.color" }}
        indexBy="name"
        layout="horizontal"
        margin={{
          top: 0,
          right: 5,
          bottom: 50,
          left: defineLengthOfName ? 140 : 100,
        }}
        padding={defineLeastMostPaddingChart(dataLength)}
        maxValue={100}
        animate={true}
        borderRadius={5}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        tooltip={({ color, data }) => {
          return (
            <div className="BarChart flex-a-center">
              <div
                className="BarChart__square"
                style={{ backgroundColor: color }}
              ></div>
              <span className="BarChart__name">
                {data.name.split("-innerGoGetIDSplitter-")[0]}:
              </span>
              <span className="BarChart__value">{data.value}%</span>
            </div>
          );
        }}
        axisLeft={{
          format: (v) => {
            return (
              <tspan>
                {trimText(v.split("-innerGoGetIDSplitter-")[0], 15)}
                <title>{v.split("-innerGoGetIDSplitter-")[0]}</title>
              </tspan>
            );
          },
          tickSize: 0,
          tickPadding: 15,
          legendPosition: "middle",
        }}
        isInteractive={true}
        theme={{
          fontFamily: "Sofia Pro",
          fontSize: 13,
          axis: {
            legend: {
              text: {
                color: "green",
              },
            },
            ticks: {
              text: {
                color: "green",
              },
            },
          },
          grid: {
            line: {
              stroke: "#F1F5F5",
              strokeWidth: 9,
            },
          },
        }}
        role="application"
      />

      <div
        className={`UtilizationOccupancyMostLeast__values UtilizationOccupancyMostLeast__values--${
          data.length >= 6 ? 6 : data.length
        }`}
      >
        {data.map((item) => (
          <div key={item.id}>{Math.round(item.value)}%</div>
        ))}
      </div>
    </>
  );
};
