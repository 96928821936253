import {
  addDays,
  differenceInDays,
  differenceInMinutes,
  startOfDay,
} from "date-fns";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";

export const DAY_LABELS = {
  today: "Today",
  tomorrow: "Tomorrow",
};

export const getDaysRangeArray = (startDate: Date, daysCount: number) => {
  const res = [startDate];

  for (let i = 0; i < daysCount - 1; i++) {
    res.push(addDays(startDate, i + 1));
  }

  return res;
};

export const useFormatReservationDate = (date: Date | number) => {
  const { timeZone } = useTimeZoneContext();

  const dayDifference = differenceInDays(
    startOfDay(date),
    startOfDay(utcToZonedTime(new Date(), timeZone))
  );

  if (dayDifference === 0) {
    return `${DAY_LABELS.today}, ${format(date, "MMM dd")}`;
  }

  if (dayDifference === 1) {
    return `${DAY_LABELS.tomorrow}, ${format(date, "MMM dd")}`;
  }

  return format(date, "EEEE, MMM dd");
};

export const forReservationDate = (date: Date | number, timeZone: string) => {
  const dayDifference = differenceInDays(
    startOfDay(date),
    startOfDay(utcToZonedTime(new Date(), timeZone))
  );

  if (dayDifference === 0) {
    return `Today, ${format(date, "MMM dd")}`;
  }

  if (dayDifference === 1) {
    return `Tomorrow, ${format(date, "MMM dd")}`;
  }

  return format(date, "EEEE, MMM dd");
};

export const getDuration = (from: Date, until: Date) => {
  const allMinutes = differenceInMinutes(until, from);

  const days = Math.floor(allMinutes / (60 * 24));
  const hours = Math.floor((allMinutes % (60 * 24)) / 60);
  const minutes = allMinutes % 60;

  return { days, hours, minutes };
};
