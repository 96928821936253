export const getFirstRouteFromURL = (pathname: string) => {
  const firstRouteIndex = pathname.indexOf("/", 1);

  if (pathname === "/") {
    return "/";
  }

  if (firstRouteIndex === -1) {
    return pathname;
  }

  return pathname.substr(0, firstRouteIndex);
};
