import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useValidPersonalToken } from "../../../lib/context/ValidateToken/ValidateTokenContext";

import { tooltips } from "../../../lib/utils/tooltips";
import { Form } from "reactstrap";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { GeneralSettings } from "./Forms/GeneralSettings";
import { LocationSettings } from "./Forms/LocationSettings";
import { PersonalCalendarForm } from "./Forms/PersonalCalendarForm";

import "./BookingsSettings.scss";

export const BookingsSettings = () => {
  const { personalCalendar } = useCalendarContext();
  const { validateCalendarIntegrationCredentials } = useCalendarRequests();
  const { setStatusOfToken } = useValidPersonalToken();

  useEffect(() => {
    if (personalCalendar.length <= 0) {
      //no need to check token's validation if the user doens't have a personal calendar added
      return;
    }

    const checkPersonalToken = async () => {
      try {
        const {
          response: { status },
        } = await validateCalendarIntegrationCredentials(
          personalCalendar[0].iD
        );

        setStatusOfToken(status);
      } catch (error: any) {
        toast.error(error?.message);
        return;
      }
    };

    checkPersonalToken();

    if (localStorage.getItem("initiateLogin") === "1") {
      localStorage.removeItem("initiateLogin");
    }
  }, [personalCalendar]);

  return (
    <div className="BookingsSettings default-page">
      <PageHeader title="Settings" message={tooltips.bookings.bookingsMain} />
      <Form className="BookingsSettings__form">
        <PersonalCalendarForm />
        <GeneralSettings />
        <LocationSettings />
      </Form>
    </div>
  );
};
