import React, { useState } from "react";
import cs from "classnames";

import { Icon } from "../../shared/Icon/Icon";
import { CalendarModalWizard } from "../../Calendars/CalendarModalWizard/CalendarModalWizard";
import { Button } from "../../shared/Button/Button";

import "./AddCalendarConnections.scss";

interface Props {
  largeIcon?: boolean;
  largeView?: boolean;
  isDirectoryUser?: boolean;
}

export const AddCalendarConnections = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = cs("AddCalendarConnections", {
    "AddCalendarConnections--small": !props.largeIcon,
    "AddCalendarConnections--large": props.largeIcon,
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes}>
      {props.largeView ? (
        <>
          <h4 className="heading-empty">
            It seems that there isn’t any calendar integration added!
          </h4>
          <Button
            title="Add integration"
            onClick={handleToggle}
            color="primary"
            size="medium"
          />
        </>
      ) : (
        <div className="AddCalendarConnections__toggler" onClick={handleToggle}>
          {props.largeIcon && (
            <div className="AddCalendarConnections__large-icon mb-3">
              <Icon icon="large-calendar-icon" />
            </div>
          )}
          <div className="AddCalendarConnections__content">
            <Icon icon="add-button-solid" />
            <span>Add integration</span>
          </div>
        </div>
      )}

      <CalendarModalWizard
        isOpen={isOpen}
        toggle={handleToggle}
        isDirectoryUser={props.isDirectoryUser}
      />
    </div>
  );
};
