import React, { Dispatch, SetStateAction } from "react";
import { FormGroup, Label } from "reactstrap";
import { Icon } from "../../../../shared/Icon/Icon";
import { Input } from "../../../../shared/Input/Input";

interface Props {
  selectedDays: number[];
  setSelectedDays: Dispatch<SetStateAction<number[]>>;
}

const days: { id: string; name: string }[] = [
  {
    id: "0",
    name: "Sun",
  },
  {
    id: "1",
    name: "Mon",
  },
  {
    id: "2",
    name: "Tue",
  },
  {
    id: "3",
    name: "Wed",
  },
  {
    id: "4",
    name: "Thu",
  },
  {
    id: "5",
    name: "Fri",
  },
  {
    id: "6",
    name: "Sat",
  },
];

const SelectWorkingDays = ({ selectedDays, setSelectedDays }: Props) => {
  const handleChange = (selectedDayId: string) => {
    const index = days.findIndex((day) => day.id === selectedDayId);

    const selectedDays: number[] = [];

    for (let i = index; i < index + 5; i++) {
      const day = days[i % days.length];
      selectedDays.push(+day.id);
    }

    setSelectedDays(selectedDays);
  };

  return (
    <FormGroup className="SelectWorkingDays pb-2">
      <span className="WorkHours__form--label d-block">
        Define work week by selecting first day of the week:
      </span>

      {/* <div className="flex-a-center gap-1 mt-1">
        {workOptions.map((option) => (
          <RadioButton
            name="workweek"
            key={option.id}
            title={option.value + "x "}
            value={option.value}
            checked={option.value === selectedWorkWeek}
            onChange={() => setSelectedWorkWeek(option.value)}
            className="WorkHours__workweek"
          />
        ))}
      </div> */}

      <div className="mt-2 mb-2 flex-a-center flex-wrap gap-1">
        {days.map(({ id, name }) => (
          <div key={id} className="position-relative">
            <Input
              type="checkbox"
              id={id}
              for={id}
              name={id}
              value={id}
              checked={selectedDays.includes(+id)}
              onChange={() => handleChange(id)}
              disabled={selectedDays.length === 7}
            />

            <Label for={id} className="checkbox_label">
              <span
                className={`WorkHours__form--label ${
                  selectedDays.length === 7 ? "disabled" : ""
                }`}
              >
                {name}
              </span>

              <span
                className={`checkbox_box ${
                  selectedDays.length === 7 ? "disabled" : ""
                }`}
              ></span>
            </Label>

            {selectedDays.length < 7 && selectedDays[0] === +id && (
              <Icon className="position-absolute StartIcon" icon="start-icon" />
            )}
          </div>
        ))}
        <Input
          type="checkbox"
          id="fullWeek"
          for="fullWeek"
          name="fullWeek"
          value="fullWeek"
          checked={selectedDays.length === 7}
          onChange={() => {
            if (selectedDays.length === 7) {
              return setSelectedDays([1, 2, 3, 4, 5]);
            }

            return setSelectedDays([0, 1, 2, 3, 4, 5, 6]);
          }}
        />

        <Label for="fullWeek" className="checkbox_label">
          <span className="WorkHours__form--label">Full week</span>

          <span className="checkbox_box"></span>
        </Label>
      </div>
    </FormGroup>
  );
};

export default SelectWorkingDays;
