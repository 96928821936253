import { DeskLocationItem } from "../../../api/grpc/desk/desk";

export const defineLocationPath = (path: DeskLocationItem[]) => {
  if (!path.length) {
    return [
      {
        id: "1",
        name: "All locations",
        type: "Root",
        index: 0,
      },
    ];
  }

  return path;
};
