import React, { PropsWithChildren } from "react";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { Redirect, Route, Switch } from "react-router";

import { DirectoryIntegration } from "../DirectoryIntegration/DirectoryIntegration";
import { DirectoryMain } from "../DirectoryMain/DirectoryMain";
import { DirectorySyncIntegration } from "../../../../api/grpc/calendarprovider/calendarprovider";

export const DirectoryRotues = ({
  refetch,
  getDirectoryById,
}: PropsWithChildren<{
  refetch: () => void;
  getDirectoryById: (id: string) => DirectorySyncIntegration | undefined;
}>) => {
  const { match } = useRouter();

  const directoryRoutes = [
    {
      to: match.url,
      exact: true,
      label: "DirectoryMain",
      component: DirectoryMain,
      id: "directory-main-tab",
    },
    {
      to: `${match.url}/:name/:directoryType/:id`,
      label: "DirectoryIntegration",
      component: DirectoryIntegration,
      id: "directory-integration",
    },
  ];

  return (
    <Switch>
      {directoryRoutes.map((item) => (
        <Route
          key={item.id}
          path={item.to}
          render={(props) => (
            <item.component
              {...props}
              refetch={refetch}
              getDirectoryById={getDirectoryById}
            />
          )}
          exact={item.exact ? true : undefined}
        />
      ))}
      <Redirect to={directoryRoutes[0].to} />
    </Switch>
  );
};
