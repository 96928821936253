import React, { useContext } from "react";
import { ConfirmationModalOptions } from "./ConfirmationContextProvider";

export interface ConfirmationContextType {
  isOpen: boolean;
  confirm: (options: ConfirmationModalOptions) => Promise<any>;
}

const defaultContext = {
  isOpen: false,
  confirm: async () => {},
};

export const ConfirmationContext =
  React.createContext<ConfirmationContextType>(defaultContext);

export function useConfirmation() {
  return useContext(ConfirmationContext);
}
