import React, { PropsWithChildren, useRef, useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import _, { isString } from "lodash";

import { useTemplatesClient } from "../../../../../api/grpc/workplaces/useTemplatesClient";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useRoomTemplateContext } from "../../../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";
import { useDisplaySettingsFormik } from "../../../../shared/Forms/DisplaySettingsForm/lib/useDisplaySettingsForm";
import { useRoomFormik } from "../../../../shared/Forms/RoomForm/lib/useRoomFormik";
import { useWorkingHoursData } from "../../../../Account/helpers/useWorkingHoursData";
import { useCustomerPermissions } from "../../../../Billings/lib/useCustomerPermissions";
import { useTemplateFormik } from "../../../lib/useTemplateFormik";
import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";
import { useOutsideClickDetection } from "../../../../../lib/hooks/useOutsideClickDetection";
import { useTemplateContext } from "../../../../../lib/context/Templates/Templates/TemplatesContext";
import { useRouter } from "../../../../../lib/hooks/useRouter";
import { editProfileButtonOptions } from "../../../lib/editProfileOptions";

import {
  CustomerLanguages,
  RoomTemplate,
} from "../../../../../api/grpc/workplaces/workplaces";
import { Amenity } from "../../../../../api/graphql/rooms/rooms";
import { dateHelper } from "../../../../shared/Forms/DisplaySettingsForm/lib/displayDateHelper";
import { roomAmenetiesNames } from "../../../../shared/Forms/RoomForm/RoomForm";

import { RoomProfileForm } from "./RoomProfileForm";
import { DoubleButton } from "../../../../shared/DoubleButton/DoubleButton";
import { DropdownList } from "../../../../shared/DropdownList/DropdownList";
import { TEMPLATE_ROOT_PATH } from "../../../../../lib/routes/routes";
import { Icon } from "../../../../shared/Icon/Icon";
import { CancelProfileButton } from "../../../shared/CancelProfileButton";

export const EditRoomProfileForm = ({
  roomData,
}: PropsWithChildren<{
  roomData: RoomTemplate;
}>) => {
  const ref = useRef(null);
  const { isOpen, toggleModal } = useToggleModal();
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  const { refetchRoomTemplates } = useRoomTemplateContext();
  const { updateRoomTemplate, deleteRoomTemplate } = useTemplatesClient();
  const { setActiveProfile } = useTemplateContext();
  const { history } = useRouter();

  const {
    loading: workingHoursLoading,
    error,
    customerSettings,
    handleWorkingHours,
    refetch,
  } = useWorkingHoursData();

  const {
    data: customerPermissions,
    error: customerError,
    isBussinesOrPro,
  } = useCustomerPermissions();

  const templateFormik = useTemplateFormik({
    initialValues: {
      profileName: roomData?.name || "",
      profileDescription: roomData?.description || "",
    },
    onSubmit: async () => {},
  });

  const formik = useRoomFormik({
    initialValues: {
      customerId: user?.customerid || "",
      name: roomData?.name || "",
      description: roomData?.description || "",
      tags: roomData?.roomSettings?.tags || [],
      roomAmenities: _.keys(
        _.pickBy(roomData?.roomSettings?.amenities, (item) => {
          return item === true;
        })
      ) as Amenity[],
      brokenAmenitiesReporting:
        roomData?.roomSettings?.brokenAmenitiesReporting || false,
      licensed: false,
      numberOfSeats: roomData?.roomSettings?.numberOfSeats || 1,
      calendarProviderActive: false,
      isBlocked: roomData?.roomSettings?.isBlocked || false,
      marker: {
        latitude: 0,
        longitude: 0,
      },
      circleShape: {
        latitude: 0,
        longitude: 0,
        radius: 0,
      },
      polygonal: [],
      roomDirection: [
        {
          direction: 0,
          distance: 0,
        },
      ],
      showCheckInTime: false,
      checkInTime: 1,
      calendarId: "",
      calendarProviderId: "",
      reportingEmail: "",
      resourceEmail: "",
    },
    onSubmit: async () => {},
  });

  useOutsideClickDetection(
    ref,
    () => {
      if (!isOpen) {
        return;
      }
      toggleModal();
    },
    isOpen
  );

  const displaySettingsFormik = useDisplaySettingsFormik(
    roomData?.displaySettings,
    {
      onSubmit: async () => {},
    }
  );

  const handleEditRoomProfile = async () => {
    try {
      if (
        Object.keys(displaySettingsFormik.errors).length ||
        Object.keys(templateFormik.errors).length ||
        templateFormik.values.profileName.length <= 0
      ) {
        toast.error(
          "Field validation failed. Make sure you write data as validation rules require."
        );
        return;
      }
      if (!!error || !!customerError) {
        toast.error(
          "Failed to fetch working hours, please contact your administrator."
        );
        return;
      }

      setLoading(true);

      const {
        description,
        tags,
        roomAmenities,
        numberOfSeats,
        isBlocked,
        brokenAmenitiesReporting,
      } = formik.values;

      const { main, display, reservation } = displaySettingsFormik.values;

      const amenities = roomAmenetiesNames.reduce((acc: any, cur) => {
        acc[cur] = roomAmenities.includes(cur);

        return acc;
      }, {});

      await updateRoomTemplate({
        id: roomData.id,
        name: templateFormik.values.profileName,
        description: templateFormik.values.profileDescription,
        tenantId: user?.customerid || "",
        roomSettings: {
          description,
          numberOfSeats,
          brokenAmenitiesReporting,
          tags: tags || [],
          amenities: amenities,
          isBlocked,
        },
        displaySettings: {
          adminPin: main.adminPin,
          backgroundImageUrl: main.backgroundImageUrl,
          logoImageUrl: main.logoImageUrl,
          showOrganizer: display.showOrganizer,
          showMeetingTitle: display.showMeetingTitle,
          showAttendees: display.showAttendees,
          showRoomCapacity: display.showRoomCapacity,
          showRoomAmeneties: display.showRoomAmeneties,
          showCheckinRequired: !!display.showCheckinRequired,
          pendingTimeBeforeMeeting: display.pendingTimeBeforeMeeting,
          pendingTimeAfterMeeting: display.pendingTimeAfterMeeting,
          soonFree: display.soonFree,
          enableScreenLock: display.enableScreenLock,
          screenLock: display.screenLock,
          energySaveMode: display.energySaveMode,
          oneTimePinProtection: reservation.onScreenReservation
            ? reservation.onScreenReservation.oneTimePinProtection || false
            : false,
          onScreenReservation: !!reservation.onScreenReservation,
          showMeetingTitleInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.showMeetingTitleInput || false
            : false,
          requireMeetingTitleInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.requireMeetingTitleInput || false
            : false,
          showYourNameInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.showYourNameInput || false
            : false,
          requireYourNameInput: reservation.onScreenReservation
            ? reservation.onScreenReservation.requireYourNameInput || false
            : false,
          workingHrsActivated: reservation.onScreenReservation
            ? reservation.onScreenReservation.workingHrsActivated || false
            : false,
          requireHeadcount: reservation.onScreenReservation
            ? reservation.onScreenReservation.requireHeadcount || false
            : false,
          energySaveModeStart: format(
            dateHelper(
              reservation.onScreenReservation?.workingHrsEnd || "",
              "endTime",
              customerSettings
            ),
            "HH:mm:ss"
          ),
          energySaveModeEnd: format(
            dateHelper(
              reservation.onScreenReservation?.workingHrsStart || "",
              "startTime",
              customerSettings
            ),
            "HH:mm:ss"
          ),
          qrCodeRequired: display.qrCodeRequired,
          checkinReminder: display.checkinReminder,
          contentBoardActivated: display.contentBoardActivated,
          contentBoardURL: display.contentBoardURL,
          customLanguageId: display?.customLanguage?.id || "",
          customLanguage: !!display.customLanguage
            ? ({
                id: display.customLanguage?.id || "",
                value: display.customLanguage?.value || "",
                name: display.customLanguage?.name || "",
                isGlobal: display.customLanguage?.isGlobal || false,
              } as CustomerLanguages)
            : undefined,
          bwFilter: display.bwFilter,
          workingHrsStart: !!reservation.onScreenReservation?.workingHrsStart
            ? isString(reservation.onScreenReservation.workingHrsStart) &&
              reservation.onScreenReservation.workingHrsStart.length <= 9
              ? reservation.onScreenReservation.workingHrsStart
              : format(
                  new Date(
                    reservation.onScreenReservation.workingHrsStart || ""
                  ),
                  "HH:mm:ss"
                )
            : "",
          workingHrsEnd: !!reservation.onScreenReservation?.workingHrsEnd
            ? isString(reservation.onScreenReservation.workingHrsEnd) &&
              reservation.onScreenReservation.workingHrsEnd.length <= 9
              ? reservation.onScreenReservation.workingHrsEnd
              : format(
                  new Date(reservation.onScreenReservation.workingHrsEnd || ""),
                  "HH:mm:ss"
                )
            : "",
          deleteMeeting: reservation.deleteMeeting,
          endMeetingEarly: reservation.endMeetingEarly,
          extendMeeting: reservation.extendMeeting,
          nearbyRooms: reservation.nearbyRooms,
          nearbyEnabled: customerPermissions?.nearbyRooms || false,
          mapView: reservation.mapView,
          floorId: reservation?.floor?.id || "",
          floor: !reservation.floor?.id
            ? undefined
            : {
                id: reservation.floor.id,
                name: "",
              },
        },
      });

      toast.success("Room template updated successfully.");

      await refetchRoomTemplates();
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRoomProfile = async () => {
    try {
      toggleModal();

      await deleteRoomTemplate(roomData.id);

      await refetchRoomTemplates();

      toast.success("Room template deleted successfully.");

      history.push(TEMPLATE_ROOT_PATH);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handeOptionClick = (type: string) => {
    if (type === "transfer") {
      setActiveProfile({
        label: roomData.name,
        value: roomData.id,
        type: "room",
        redirect: true,
      });
      history.push(`${TEMPLATE_ROOT_PATH}/transfer`);
      return;
    }

    if (type === "delete") {
      return handleDeleteRoomProfile();
    }
  };

  return (
    <>
      <div className="main-flex mb-3 TemplateManage__head">
        <div className="TemplateManage__info">
          <Icon icon="red-error-icon" />
          <span>
            Note that any updates to the template after it has been transferred
            to rooms will require a manual re-transfer to affected rooms.
          </span>
        </div>

        <div className="flex-a-center gap-1 TemplateManage__head--buttons">
          <CancelProfileButton className="TemplateManage__head--cancel" />
          <div ref={ref} className="TemplateManage__buttons relative">
            <DoubleButton
              onClick={handleEditRoomProfile}
              secondButtonClick={toggleModal}
              disabled={loading}
            />

            {isOpen && (
              <DropdownList
                onClick={(e) => handeOptionClick(e)}
                options={editProfileButtonOptions}
              />
            )}
          </div>
        </div>
      </div>

      <RoomProfileForm
        formik={formik}
        displaySettingsFormik={displaySettingsFormik}
        templateFormik={templateFormik}
        isBussinesOrPro={isBussinesOrPro}
        error={error}
        handleWorkingHours={handleWorkingHours}
        loading={workingHoursLoading}
        workingHoursData={customerSettings}
        refetch={refetch}
      />
    </>
  );
};
