import { useApolloClient } from "@apollo/client";

import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../workplaces";

import { SITE_FIELDS_FRAGMENT } from "../sites/sites";
import { FLOOR_FIELDS_FRAGMENT } from "../floors/floors";
import { COMPANY_FIELDS_FRAGMENT } from "../companies/companies";
import { BUILDING_FIELDS_FRAGMENT } from "../buildings/buildings";

import {
  AddDeskResponse,
  AddDeskVariables,
  ADD_DESK_TO_TENANT,
  UpdateDeskResponse,
  DeskRequestVariables,
  UPDATE_DESK,
  ADD_DESK_TO_COMPANY,
  ADD_DESK_TO_SITE,
  ADD_DESK_TO_BUILDING,
  ADD_DESK_TO_FLOOR,
  DESK_FIELDS_FRAGMENT,
  UPDATE_DESK_LICENSE,
  UpdateDeskLicenseVariables,
  ADD_DESK_TO_ZONE,
  ADD_MARKER_TO_DESK,
  UpdateDeskMarkerVariables,
  DELETE_USER_FROM_DESK,
  RemoveUserVariables,
  UpdateUserDeskVariables,
  ADD_USER_TO_DESK,
} from "./desks";
import { WorkplaceTypes } from "../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { ZONE_FIELDS_FRAGMENT } from "../zones/zones";

export const useDesksRequests = () => {
  const client = useApolloClient();

  const addDeskToTenant = async (variables: DeskRequestVariables) => {
    return await client.mutate<AddDeskResponse, AddDeskVariables>({
      mutation: ADD_DESK_TO_TENANT,
      variables,
      update: (cache, newData) => {
        const previousQuery = cache.readQuery<
          GetWorkplacesResponse,
          WorkplaceRequestVariables
        >({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
        });

        const tenantResults = previousQuery?.queryTenant[0];
        const newDesk = newData.data?.addDesk.desk[0];

        cache.writeQuery({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
          data: {
            queryTenant: [
              {
                ...tenantResults,
                desks: [...(tenantResults?.desks || []), newDesk],
              },
            ],
          },
        });
      },
    });
  };

  const addDeskToCompany = async (variables: DeskRequestVariables) => {
    return await client.mutate<AddDeskResponse, DeskRequestVariables>({
      mutation: ADD_DESK_TO_COMPANY,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            desks: [...previousData.desks, newData.data?.addDesk.desk[0]],
          },
        });
      },
    });
  };

  const addDeskToSite = async (variables: DeskRequestVariables) => {
    return await client.mutate<AddDeskResponse, DeskRequestVariables>({
      mutation: ADD_DESK_TO_SITE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            desks: [...previousData.desks, newData.data?.addDesk.desk[0]],
          },
        });
      },
    });
  };

  const addDeskToBuilding = async (variables: DeskRequestVariables) => {
    return await client.mutate<AddDeskResponse, DeskRequestVariables>({
      mutation: ADD_DESK_TO_BUILDING,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            desks: [...previousData.desks, newData.data?.addDesk.desk[0]],
          },
        });
      },
    });
  };

  const addDeskToFloor = async (variables: DeskRequestVariables) => {
    return await client.mutate<AddDeskResponse, DeskRequestVariables>({
      mutation: ADD_DESK_TO_FLOOR,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            desks: [...previousData.desks, newData.data?.addDesk.desk[0]],
          },
        });
      },
    });
  };

  const addDeskToZone = async (variables: DeskRequestVariables) => {
    return await client.mutate<AddDeskResponse, DeskRequestVariables>({
      mutation: ADD_DESK_TO_ZONE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Zone:${variables.id}`,
          fragmentName: "ZoneFields",
          fragment: ZONE_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Zone:${variables.id}`,
          fragmentName: "ZoneFields",
          fragment: ZONE_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            desks: [...previousData.desks, newData.data?.addDesk.desk[0]],
          },
        });
      },
    });
  };

  const addDesk = async (
    variables: DeskRequestVariables,
    parentType?: WorkplaceTypes
  ) => {
    let deskVariables: DeskRequestVariables = {
      ...variables,
    };

    if (parentType === "Company") {
      return await addDeskToCompany(deskVariables);
    }

    if (parentType === "Site") {
      return await addDeskToSite(deskVariables);
    }

    if (parentType === "Building") {
      return await addDeskToBuilding(deskVariables);
    }

    if (parentType === "Floor") {
      return await addDeskToFloor(deskVariables);
    }

    if (parentType === "Zone") {
      return await addDeskToZone(deskVariables);
    }

    return await addDeskToTenant(deskVariables);
  };

  const updateDesk = async (variables: DeskRequestVariables) => {
    return await client.mutate<UpdateDeskResponse, DeskRequestVariables>({
      mutation: UPDATE_DESK,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateDesk.desk[0],
          },
        });
      },
    });
  };

  const updateAttachedUserOfDesk = async (
    variables: UpdateUserDeskVariables
  ) => {
    return await client.mutate<UpdateDeskResponse, UpdateUserDeskVariables>({
      mutation: ADD_USER_TO_DESK,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            account: {
              accountID: variables.accountID,
              displayName: variables.displayName,
              email: variables.email,
              tenantID: variables.tenantId,
            },
          },
        });
      },
    });
  };

  const updateDeskMarker = async (variables: UpdateDeskMarkerVariables) => {
    return await client.mutate<UpdateDeskResponse, UpdateDeskMarkerVariables>({
      mutation: ADD_MARKER_TO_DESK,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            marker: {
              latitude: newData?.data?.updateDesk.desk[0].marker.latitude,
              longitude: newData?.data?.updateDesk.desk[0].marker.longitude,
            },
          },
        });
      },
    });
  };

  const updateDeskLicense = async (variables: UpdateDeskLicenseVariables) => {
    return await client.mutate<UpdateDeskResponse, UpdateDeskLicenseVariables>({
      mutation: UPDATE_DESK_LICENSE,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateDesk.desk[0],
          },
        });
      },
    });
  };

  const removeUserFromDesk = async (variables: RemoveUserVariables) => {
    return await client.mutate<UpdateDeskResponse, RemoveUserVariables>({
      mutation: DELETE_USER_FROM_DESK,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Desk:${variables.id}`,
          fragmentName: "DeskFields",
          fragment: DESK_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            account: null,
          },
        });
      },
    });
  };

  return {
    addDesk,
    updateDesk,
    updateDeskLicense,
    updateDeskMarker,
    removeUserFromDesk,
    updateAttachedUserOfDesk,
  };
};
