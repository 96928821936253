import React from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useConfirmation } from "../../../../lib/context/Confirmation/ConfirmationContext";

import { trimText } from "../../../../lib/helpers/trimText";
import { getWorkplaceName } from "../../helpers/getWorkplaceInformation";

import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";
import { DisconnectDeviceVariables } from "../../../../api/graphql/devices/devices";
import { WorkplacePrefixIcon } from "../WorkplacePrefixIcon/WorkplacePrefixIcon";
import { UnLinkButton } from "../../../shared/UnLinkButton/UnLinkButton";
import { DeviceApp } from "../../../Devices/DeviceApp/DeviceApp";
import { Icon } from "../../../shared/Icon/Icon";
import { DEVICES_ROOT_PATH } from "../../../../lib/routes/routes";

interface Props {
  parentId?: string;
  onClose: () => void;
  data: WorkplaceFields[];
}

export const WorkplaceExpandedDevicesRfx = (props: Props) => {
  const { parentId, data, onClose } = props;
  const { confirm } = useConfirmation();
  const { disconnectDeviceWayfinder } = useWorkplaceRequests();

  const workplaceName = getWorkplaceName("Device", data.length);

  const handleUnlink = async (deviceId: string) => {
    if (!parentId) {
      toast.error("Couldn't disconnect device, please reload and try again!");
      console.error("No room id was provided to disconnect the device!");
      return;
    }

    try {
      await confirm({
        title: "Are you sure you want to disconnect the device?",
      });

      const variables: DisconnectDeviceVariables = {
        id: deviceId,
        wayFinderId: parentId,
        unassignedSince: Math.floor(Date.now() / 1000),
        warnedUnassigned: true,
      };

      await disconnectDeviceWayfinder(variables);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const columns: StructureTableColumn[] = [
    {
      key: "workplace_icon_device",
      header: workplaceName,
      onClick: () => onClose(),
    },
    {
      key: "workplace_name",
      header: "Name",
    },
    {
      key: "device_connection",
      header: "Status",
    },
    {
      key: "device_app",
      header: "App",
    },
    {
      key: "workplace_app_version",
      header: "App version",
    },
    {
      key: "workplace_hardware",
      header: "Hardware",
    },
    {
      key: "workplace_description",
      header: "Description",
    },
    // {
    //   key: "workplace_tags",
    //   header: "Tags",
    // },
    {
      key: "workplace_delete",
      header: "",
    },
  ];

  const rows: StructureTableRows[] = data.map((item) => {
    return {
      workplace_icon_device: (
        <WorkplacePrefixIcon icon="wayFinder_icon_48x48" />
      ),
      workplace_name: (
        <NavLink title={item.name} to={`${DEVICES_ROOT_PATH}`}>
          {trimText(item.name, 15)}
        </NavLink>
      ),
      device_connection: (
        <div className="d-flex justify-content-center align-items-center">
          {item.isOnline || item.isOnline === null ? (
            <Icon icon="approve-icon" />
          ) : (
            <div className="offline-device">
              <Icon icon="alert-triangle" />
              <span>Offline</span>
            </div>
          )}
        </div>
      ),
      device_app: <DeviceApp typeOfWorkspace="wayFinder" name="Wayfinder X" />,
      workplace_app_version: !!item.softwareInfo ? (
        <span>{trimText(item.softwareInfo.version, 15)}</span>
      ) : (
        "-"
      ),
      workplace_hardware: !!item.hardwareInfo ? (
        <span>{trimText(item.hardwareInfo.model, 15)}</span>
      ) : (
        "-"
      ),
      // workplace_tags: (
      //   <h6 className="mb-0">
      //     {item.tags.length > 0 ? trimText(item.tags[0], 25) : "-"}
      //   </h6>
      // ),
      workplace_description: (
        <span>{trimText(item.description || "-", 30)}</span>
      ),
      workplace_delete: (
        <div className="ml-auto">
          <UnLinkButton onClick={() => handleUnlink(item.id)} />
        </div>
      ),
    };
  });

  return (
    <StructureTable rows={rows} columns={columns} noResultsPlaceholder={"--"} />
  );
};
