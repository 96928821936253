import React from "react";
import { useCustomerPermissions } from "../../components/Billings/lib/useCustomerPermissions";
import { useRouter } from "../../lib/hooks/useRouter";

import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage";
import { NestedRoute } from "../../components/shared/NestedRoute/NestedRoute";
import { RestrictedPage } from "../RestrictedPage/RestrictedPage";

export const Users = () => {
  const { loading, error, isBussinesOrPro } = useCustomerPermissions();
  const { pathname } = useRouter();

  if (loading || isBussinesOrPro) {
    return <NestedRoute baseRoute="users-sidebar" />;
  }

  if (error) {
    return (
      <ErrorMessage message="Error fetching user plan, please try reloading the page!" />
    );
  }

  return (
    <RestrictedPage
      title={
        pathname.includes("people")
          ? "Manage users and teams"
          : "Add and sync users from directories"
      }
    />
  );
};
