import React, { FC } from "react";

import { useWorkingHoursData } from "../../../Account/helpers/useWorkingHoursData";
import { tooltips } from "../../../../lib/utils/tooltips";

import { EnableSSO } from "./EnableSSO/EnableSSO";
import { InfoCard } from "../../../shared/InfoCard/InfoCard";

type Props = {
  workingHoursDataQuery: ReturnType<typeof useWorkingHoursData>;
};

export const SSOCard: FC<Props> = ({ workingHoursDataQuery }) => {
  return (
    <InfoCard
      title="Single Sign-On (SSO)"
      message={tooltips.account.singleSignOn}
      direction="left"
      adjustInfoLeft={true}
    >
      <div className="OrganizationSettigs__cardContent">
        <EnableSSO workingHoursDataQuery={workingHoursDataQuery} />
      </div>
    </InfoCard>
  );
};
