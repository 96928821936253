import React from "react";
import { ManageProfiles } from "./ManageProfiles/ManageProfiles";
import { ProfilesList } from "./ProfilesList/ProfilesList";

export const SettingsProfilesContent = () => {
  return (
    <div className="d-flex flex-column gap-1 mt-5 SettingsProfilesContent">
      <ProfilesList />

      <ManageProfiles />
    </div>
  );
};
