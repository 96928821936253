import {
  RoomTemplateSettings,
  RoomTemplateDisplaySettings,
} from "../../../api/grpc/workplaces/workplaces";

//fix children?: string[]; type from string to keys from RoomTemplateSettings and RoomTemplateDisplaySettings

export type SettingOption = {
  name: string;
  value: string;
  children?: string[];
};

interface SettingsOptions {
  name: string;
  options: SettingOption[];
}

type RoomTemplate = Omit<RoomTemplateSettings, "brokenAmenitiesReporting">;

const roomCoreSettings: Record<
  keyof RoomTemplate,
  { label: string; children?: string[] }
> = {
  description: { label: "Description" },
  numberOfSeats: { label: "Number of seats" },
  tags: { label: "Tags" },
  isBlocked: { label: "Block room" },
  amenities: {
    label: "Room equipment",
    children: ["brokenAmenitiesReporting"],
  },
};

const roomMainSettings: Partial<
  Record<
    keyof RoomTemplateDisplaySettings,
    { label: string; children?: string[] }
  >
> = {
  adminPin: { label: "Admin PIN (4 digits 0-9)" },
  backgroundImageUrl: {
    label: "Background image",
    children: ["bwFilter"],
  },
  logoImageUrl: { label: "Logo image" },
  customLanguage: { label: "Language" },
};

const roomDisplaySettings: Partial<
  Record<
    keyof RoomTemplateDisplaySettings,
    { label: string; children?: string[] }
  >
> = {
  showOrganizer: { label: "Show meeting organizer" },
  showMeetingTitle: { label: "Show meeting title" },
  showAttendees: { label: "Show attendees" },
  showRoomCapacity: { label: "Show capacity" },
  showRoomAmeneties: { label: "Show room amenities" },
  showCheckinRequired: {
    label: "Check-in required",
    children: [
      "pendingTimeBeforeMeeting",
      "pendingTimeAfterMeeting",
      "checkinReminder",
    ],
  },
  soonFree: { label: "Soon free" },
  energySaveMode: { label: "Energy save mode" },
  qrCodeRequired: { label: "Contactless QR booking" },
  contentBoardActivated: {
    label: "Content board",
    children: ["contentBoardURL"],
  },
  enableScreenLock: {
    label: "Enable screen lock",
    children: ["screenLock"],
  },
};

const roomReservationSettings: Partial<
  Record<
    keyof RoomTemplateDisplaySettings,
    { label: string; children?: string[] }
  >
> = {
  onScreenReservation: {
    label: "On screen reservation",
    children: [
      "showMeetingTitleInput",
      "requireMeetingTitleInput",
      "showYourNameInput",
      "requireYourNameInput",
      "workingHrsActivated",
      "workingHrsStart",
      "workingHrsEnd",
      "requireHeadcount",
      "oneTimePinProtection",
    ],
  },
  extendMeeting: {
    label: "Edit meeting settings",
    children: ["deleteMeeting", "endMeetingEarly"],
  },
  mapView: {
    label: "Map view",
    children: ["floor"],
  },
};

export const roomCoreSettingsOptions = (): SettingsOptions => {
  const options = Object.entries(roomCoreSettings).map(
    ([key, { label, children }]) => ({
      name: label,
      value: key,
      children: children || [],
    })
  );
  return {
    name: "Core settings",
    options,
  };
};

export const roomDisplaySettingsOptions = (): {
  mainOptions: SettingsOptions;
  displayOptions: SettingsOptions;
  reservationOptions: SettingsOptions;
} => {
  const mainOptions = {
    name: "Main settings",
    options: Object.entries(roomMainSettings).map(
      ([key, { label, children }]) => ({
        name: label,
        value: key,
        children: children || [],
      })
    ),
  };
  const displayOptions = {
    name: "Display settings",
    options: Object.entries(roomDisplaySettings).map(
      ([key, { label, children }]) => ({
        name: label,
        value: key,
        children: children || [],
      })
    ),
  };
  const reservationOptions = {
    name: "Reservation settings",
    options: Object.entries(roomReservationSettings).map(
      ([key, { label, children }]) => ({
        name: label,
        value: key,
        children: children || [],
      })
    ),
  };
  return { mainOptions, displayOptions, reservationOptions };
};
