import React, { useState } from "react";
import { Icon } from "../../../../shared/Icon/Icon";
import { BookingsSettingsModal } from "./BookingsSettingsModal";
import { CustomerSettingsVariables } from "../../InsightsCard/Insights/Insights";

interface Props {
  handleDeskSimultaneous: ({
    disableDeskSimultaneous,
  }: CustomerSettingsVariables) => void;
  loading: boolean;
  disableDeskSimultaneous?: boolean;
  error?: Error;
}

export const BookingsSettings = ({
  loading,
  error,
  handleDeskSimultaneous,
  disableDeskSimultaneous,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="flex-a-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Icon icon="bookings-icon" />
        <h3>Bookings settings</h3>
      </div>

      {isOpen && (
        <BookingsSettingsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          loading={loading}
          error={error}
          handleDeskSimultaneous={handleDeskSimultaneous}
          disableDeskSimultaneous={disableDeskSimultaneous}
        />
      )}
    </>
  );
};
