export const trimText = (text: string, limit: number = 30) => {
  if (!text) {
    return "";
  }

  if (text.length < limit) {
    return text;
  }

  return text.substring(0, limit) + "...";
};
