import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  DisplaySettingsFields,
  FormikDisplaySettingsFields,
} from "../../../../../api/graphql/rooms/rooms";

const DisplaySchema = Yup.object().shape({
  main: Yup.object().shape({
    adminPin: Yup.string()
      .matches(/^[0-9]+$/, "Pin number must be only digits!")
      .min(4, "Pin number must be exactly 4 digits long!")
      .max(4, "Pin number must be exactly 4 digits long!"),
    backgroundImageUrl: Yup.string()
      .matches(/^https:\/\/(.*)$/, {
        message:
          "Please make sure that the link is from a secure source, starting with an https://",
        excludeEmptyString: true,
      })
      .matches(/^(.*).(png|jpg|jpeg)$/, {
        message:
          "Please make sure that image is from a supported format(.jpg, .jpeg or .png)",
        excludeEmptyString: true,
      }),
    logoImageUrl: Yup.string()
      .matches(/^https:\/\/(.*)$/, {
        message:
          "Please make sure that the link is from a secure source, starting with an https://",
        excludeEmptyString: true,
      })
      .matches(/^(.*).(png|jpg|jpeg)$/, {
        message:
          "Please make sure that image is from a supported format(.jpg, .jpeg or .png)",
        excludeEmptyString: true,
      }),
  }),
  display: Yup.object().shape({
    contentBoardURL: Yup.string().matches(/^https:\/\/(.*)$/, {
      message:
        "Please make sure that the link is from a secure source, starting with an https://",
      excludeEmptyString: true,
    }),
    screenLock: Yup.string()
      .matches(/^[0-9]+$/, "Screen lock number must be only digits!")
      .min(4, "Screen lock must be exactly 4 digits long!")
      .max(4, "Screen lock must be exactly 4 digits long!"),
  }),
});

interface UseDisplaySettingsFormOptions {
  enableReinitialize?: boolean;
  onSubmit: (
    values: FormikDisplaySettingsFields,
    formikHelpers: FormikHelpers<FormikDisplaySettingsFields>
  ) => Promise<any>;
}

export type DisplaySettingsFormik = ReturnType<typeof useDisplaySettingsFormik>;

export const useDisplaySettingsFormik = (
  data: DisplaySettingsFields | null | undefined,
  opts: UseDisplaySettingsFormOptions
) => {
  return useFormik<FormikDisplaySettingsFields>({
    initialValues: {
      main: {
        adminPin: data?.adminPin ? data.adminPin.toString() : "",
        backgroundImageUrl: data?.backgroundImageUrl || "",
        logoImageUrl: data?.logoImageUrl || "",
      },
      display: {
        customerId: data?.customerId || "",
        showOrganizer: data?.showOrganizer || false,
        showMeetingTitle: data?.showMeetingTitle || false,
        showAttendees: data?.showAttendees || false,
        showRoomCapacity: data?.showRoomCapacity || false,
        showRoomAmeneties: data?.showRoomAmeneties || false,
        showCheckinRequired: !!data?.showCheckinRequired,
        checkinReminder: data?.checkinReminder || false,
        soonFree: data?.soonFree || false,
        energySaveMode: data?.energySaveMode || false,
        energySaveModeStart: data?.energySaveModeStart || "",
        energySaveModeEnd: data?.workingHrsStart || "",
        qrCodeRequired: data?.qrCodeRequired || false,
        textScaling: data?.textScaling || "normal",
        contentBoardActivated: data?.contentBoardActivated || false,
        contentBoardURL: data?.contentBoardURL || "",
        enableScreenLock: data?.enableScreenLock || false,
        screenLock: data?.screenLock || "",
        customLanguage: data?.customLanguage || undefined,
        bwFilter: data?.bwFilter || false,
        workingWeekDays: data?.workingWeekDays || [1, 2, 3, 4, 5],
        pendingTimeBeforeMeeting:
          data?.pendingTimeBeforeMeeting !== undefined
            ? data.pendingTimeBeforeMeeting
            : 15,
        pendingTimeAfterMeeting:
          data?.pendingTimeAfterMeeting !== undefined
            ? data.pendingTimeAfterMeeting
            : 15,
      },
      reservation: {
        onScreenReservation: data?.onScreenReservation
          ? {
              requireMeetingTitleInput: data?.requireMeetingTitleInput,
              enforceWorkingHours: data?.enforceWorkingHours,
              showYourNameInput: data?.showYourNameInput,
              requireYourNameInput: data?.requireYourNameInput,
              showMeetingTitleInput: data?.showMeetingTitleInput,
              workingHrsActivated: data?.workingHrsActivated,
              requireHeadcount: data?.requireHeadcount,
              oneTimePinProtection: data?.oneTimePinProtection,
              workingHrsStart: data?.workingHrsStart,
              workingHrsEnd: data?.workingHrsEnd,
            }
          : null,
        deleteMeeting: data?.deleteMeeting || false,
        endMeetingEarly: data?.endMeetingEarly || false,
        extendMeeting: data?.extendMeeting || false,
        nearbyEnabled: data?.nearbyEnabled || false,
        nearbyRooms: data?.nearbyRooms || [],
        mapView: data?.mapView || false,
        floor: data?.floor || null,
      },
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: DisplaySchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
