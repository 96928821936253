import { createContext, useContext } from "react";
import { Area } from "react-easy-crop/types";

export type CropInformation =
  | { croppedArea: Area; croppedAreaPixels: Area }
  | undefined;

export interface ImageEditor {
  open: (file: any, instructions: any) => void;
  onconfirm: (output: any) => void;
  oncancel: () => void;
  onclose: () => void;
}

export interface IUploadImageContext {
  zoom: number;
  editor: ImageEditor;
  onSubmit: () => void;
  editImage: string | undefined;
  setZoom: (value: number) => void;
  cropInformation: React.MutableRefObject<CropInformation>;
}

export const UploadImageContext = createContext<IUploadImageContext>({
  zoom: 1,
  editor: {
    open: () => {},
    onconfirm: () => {},
    oncancel: () => {},
    onclose: () => {},
  },
  setZoom: () => {},
  onSubmit: () => {},
  editImage: undefined,
  cropInformation: {
    current: undefined,
  },
});

export const useUploadImageContext = () => useContext(UploadImageContext);
