import React from "react";
import { Icon } from "../Icon/Icon";

import "./UnLinkButton.scss";

interface Props {
  onClick: () => void;
}

export const UnLinkButton = (props: Props) => {
  return (
    <div className="UnLinkButton" onClick={() => props.onClick()}>
      <Icon icon="unlink-solid" />
    </div>
  );
};
