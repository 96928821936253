import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { useAuthContext } from "./context/Auth/AuthContext";
import { useCheckUserPermissions } from "./hooks/useCheckUserPermissions";

import { RouteType } from "./routes/routes";
import { NoPermission } from "../components/shared/NoPermission/NoPermission";
import { RolePermissions } from "../api/grpc/account/account";

interface Props extends RouteProps {
  routeType: RouteType;
  permissions?: RolePermissions[];
}

export const AppRoute = (props: Props) => {
  const { routeType, ...otherProps } = props;
  const { isAuthenticated, userAccess } = useAuthContext();
  const { checkUserPermission } = useCheckUserPermissions();

  if (routeType === RouteType.PUBLIC) {
    return <Route {...otherProps} />;
  }

  if (
    (userAccess === "no-permission" || isAuthenticated) &&
    routeType === RouteType.RESTRICTED
  ) {
    return <Redirect to="/" />;
  }

  if (isAuthenticated && props.permissions) {
    if (!checkUserPermission(props.permissions)) {
      //bokings is accessible to all users
      return <Redirect to="/bookings" />;
    }
  }

  if (userAccess === "no-permission") {
    return <NoPermission />;
  }

  if (!isAuthenticated && routeType === RouteType.RESTRICTED) {
    return <Route {...otherProps} />;
  }

  if (!isAuthenticated && routeType === RouteType.PRIVATE) {
    return <Redirect to="/login" />;
  }

  return <Route {...otherProps} />;
};
