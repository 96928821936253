import React, { Dispatch, SetStateAction, useMemo } from "react";

import { useDeskOverviewInsightsContext } from "../../Context/Desk/Overview/OverviewContext";
import { GeneralStatsByDesksResponse } from "../../../../api/grpc/insights/insights";

import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { InsightsExpandedDesks } from "./InsightsExpandedDesks";
import { SearchBox } from "../../../shared/SearchBox/SearchBox";

interface Props {
  searchValue: string;
  debouncedValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

export const InsightsAllDesks = ({
  searchValue,
  debouncedValue,
  setSearchValue,
}: Props) => {
  const {
    loadingGeneral: loading,
    errorGeneral: error,
    dataGeneral: data,
  } = useDeskOverviewInsightsContext();

  const desksData: GeneralStatsByDesksResponse | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    if (!!!debouncedValue.length) {
      return data;
    }

    let desks = data.data.filter((desk) =>
      desk.name.toLowerCase().includes(debouncedValue.toLowerCase())
    );

    return {
      customerID: data.customerID,
      data: desks,
      endDate: data.endDate,
      startDate: data.startDate,
    };
  }, [debouncedValue, data]);

  return (
    <HandleLoadingState loading={loading} loadingPlaceholder={<LoadingBox />}>
      <HandleRequestState
        state={!!error}
        placeholder={<CenteredBox>{error?.message}</CenteredBox>}
      >
        {desksData !== undefined && (
          <>
            <SearchBox
              searchValue={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              clearSearchValue={() => setSearchValue("")}
            />
            {!!debouncedValue.length && !!!desksData.data.length ? (
              <CenteredBox>No desk found</CenteredBox>
            ) : (
              <InsightsExpandedDesks
                data={desksData}
                showPagination={!!!debouncedValue.length}
              />
            )}
          </>
        )}
      </HandleRequestState>
    </HandleLoadingState>
  );
};
