import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useRouter } from "../../lib/hooks/useRouter";
import { useContacts } from "../../api/grpc/contacts/useContacts";

import { CenteredLoader } from "../../components/shared/CenteredLoader/CenteredLoader";
import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";
import { HandleRequestState } from "../../components/shared/HandleRequestState/HandleRequestState";
import { AccountActivated } from "../../components/CompleteSignup/AccountActivated/AccountActivated";
import { AccountMessage } from "../../components/shared/AuthenticationPage/AccountMessage/AccountMessage";
import { CompleteSignupForm } from "../../components/CompleteSignup/CompleteSignupForm/CompleteSignupForm";
import { LoginMethod } from "../../api/grpc/account/account";
import { CompleteWithGoogle } from "./SignUpMethodTypes/CompleteWithGoogle";
import { CompleteWithO365 } from "./SignUpMethodTypes/CompleteWithO365";
import {
  CompleteSignupFormik,
  useCompleteSignupFormik,
} from "../../components/CompleteSignup/lib/useCompleteSignupFormik";

interface Props {
  loading: boolean;
  formik: CompleteSignupFormik;
}

const components: { [key in LoginMethod]: React.FunctionComponent<Props> } = {
  "0": CompleteSignupForm,
  "1": CompleteSignupForm,
  "2": CompleteWithGoogle,
  "3": CompleteWithO365,
};

export const SignupMethod = () => {
  const { query } = useRouter();
  const { validateRegisterToken, registerContact } = useContacts();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(1);
  const [email, setEmail] = useState("");

  useEffect(() => {
    handleVerifyCode();
  }, []);

  const handleVerifyCode = async () => {
    if (!query.token || !query.customerID || !query.email) {
      setLoading(false);
      setShowError(true);
      return;
    }

    // we use .replace(/ /g,"+") in case if empty space exists we simply add + sign
    let validatedEmail: string = query.email.replace(/ /g, "+").toLowerCase();

    try {
      const { response } = await validateRegisterToken({
        email: validatedEmail,
        token: query.token,
      });

      setLoginMethod(response.loginMethod);
      setEmail(validatedEmail);
    } catch (error: any) {
      setShowError(true);
      console.error(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const formik = useCompleteSignupFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      workEmail: email,
      accountPassword: "",
      privacyTerms: false,
    },
    onSubmit: async (values) => {
      try {
        await registerContact({
          customerID: query.customerID,
          email: values.workEmail.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.accountPassword,
          phoneNumber: "",
          validationToken: query.token,
        });

        setProcessed(true);
      } catch (error: any) {
        setShowError(true);
        console.error(error.toString());
        toast.error(
          "Registration couldn't be completed, please reload and try again!"
        );
      }
    },
  });

  const Component = components[loginMethod];

  let componentProps: Props = {
    loading: loading,
    formik: formik,
  };

  return (
    <AuthenticationPage
      fullWidth
      isDefault
      formTitle={`${
        processed ? "Account activated!" : "Complete account registration"
      }`}
      information={`${
        !processed && !showError
          ? "Account problems? Please contact your administrator"
          : ""
      }`}
    >
      <HandleRequestState state={loading} placeholder={<CenteredLoader />}>
        <HandleRequestState
          state={showError}
          placeholder={
            <AccountMessage message="Your request to complete your account registration has expired or the link has already been used!" />
          }
        >
          {processed ? <AccountActivated /> : <Component {...componentProps} />}
        </HandleRequestState>
      </HandleRequestState>
    </AuthenticationPage>
  );
};
