import React from "react";
import { DeskReleaseBehaviour } from "./DeskReleaseBehaviour/DeskReleaseBehaviour";
import { DeskReleaseReservationFlow } from "./DeskReleaseReservationFlow/DeskReleaseReservationFlow";

export const DeskRelease = () => {
  return (
    <div className="RoomValueInsights WorkspaceValueInsights">
      <DeskReleaseBehaviour />

      <DeskReleaseReservationFlow />
    </div>
  );
};
