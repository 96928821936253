import React from "react";
import { Icon } from "../../shared/Icon/Icon";

import { TeamItemRow } from "./TeamItemRow/TeamItemRow";
import "./TeamItem.scss";

interface Team {
  name: string;
  member: string;
  location: string;
}

interface Props {
  team: Team;
  onClick: () => void;
}

export const TeamItem = ({ team, onClick }: Props) => {
  return (
    <div className="TeamItem">
      <div className="TeamItem__header d-flex align-items-center mb-3">
        <Icon icon="teams-icon" />
        <h4 className="pl-3" onClick={onClick}>
          {team.name}
        </h4>
      </div>
      <TeamItemRow icon="people-icon" name={team.member} />
      <TeamItemRow icon="location-icon" name={team.location} />
    </div>
  );
};
