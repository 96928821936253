import React from "react";
import { Icon } from "../../../../shared/Icon/Icon";
import { IconDefinition } from "../../../../shared/Icon/Icon.generated";

export interface WorkspaceCostData {
  step: string;
  stepMark?: string | IconDefinition;
  value: string;
  definition: string;
}

export const WorkspaceCostInfoRow = ({
  step,
  stepMark,
  value,
  definition,
}: WorkspaceCostData) => {
  return (
    <div className="WorkspaceCostInfoRow">
      <span>
        {stepMark && stepMark.includes(".") ? (
          <span className="pr-2">{stepMark}</span>
        ) : (
          <Icon icon={stepMark as IconDefinition} />
        )}
        {step}
      </span>

      <span>{value}</span>

      <span>{definition}</span>
    </div>
  );
};
