import React from "react";
import { DeskFormik } from "../lib/useDeskFormik";
import { FormGroup } from "reactstrap";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../../../LabelWithInformationBox/LabelWithInformationBox";
import { Input } from "../../../Input/Input";
import { Icon } from "../../../Icon/Icon";
import { tooltips } from "../../../../../lib/utils/tooltips";

import "./BulkDesksForm.scss";

interface Props {
  formik: DeskFormik;
  informationBoxDirection?: LabelDirectionType;
  onClick: () => void;
  isBulkDesksOpen: boolean;
}

export const BulkDesksForm = ({
  formik,
  informationBoxDirection,
  onClick,
  isBulkDesksOpen,
}: Props) => {
  const handleOpenBulkDesks = () => () => {
    return onClick();
  };

  return (
    <div className="BulkDesksForm">
      <div
        className="BulkDesksForm__button flex-a-center"
        onClick={handleOpenBulkDesks()}
      >
        {isBulkDesksOpen ? (
          <Icon icon="delete-item" />
        ) : (
          <Icon icon="add-button-solid" />
        )}
        <span>Add Multiple</span>
      </div>
      {isBulkDesksOpen && (
        <div className="BulkDesksForm__inner">
          <FormGroup>
            <LabelWithInformationBox
              for="desksNumber"
              title="Number of desks"
              message={tooltips.desks.bulkDeskNumber}
              direction={informationBoxDirection}
            />
            <Input
              type="number"
              id="desksNumber"
              name="desksNumber"
              onBlur={formik.handleBlur}
              value={formik.values.desksNumber}
              onChange={formik.handleChange}
            />
          </FormGroup>
          <div className="BulkDesksForm__creds flex-a-center">
            <FormGroup>
              <LabelWithInformationBox
                for="name"
                title="Name prefix"
                message={tooltips.desks.bulkDeskName}
                direction={informationBoxDirection}
              />
              <Input
                type="text"
                id="name"
                name="name"
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormGroup>

            <FormGroup>
              <LabelWithInformationBox
                for="startIndex"
                title="Index"
                message={tooltips.desks.bulkDeskNumberIndex}
                direction={informationBoxDirection}
              />
              <Input
                type="number"
                id="startIndex"
                name="startIndex"
                onBlur={formik.handleBlur}
                value={formik.values.startIndex}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </div>
        </div>
      )}
    </div>
  );
};
