import React, { memo } from "react";
import { MapConsumer } from "react-leaflet";
import { ConsumerAction } from "@gogetcorp/floor-map-bridge";
// const
import { RESERVATION_MARKER_WIDTH_PX } from "../components/ReservationsIcon";

type PanToControllerProps = {
  markers: ConsumerAction.MarkersSet["payload"];
  markerIdToFocus: string | null;
};

const PanToController = memo<PanToControllerProps>(
  ({ markers, markerIdToFocus }) => {
    return (
      <MapConsumer>
        {(map) => {
          if (markerIdToFocus) {
            const markerToFocus = [...markers.desks, ...markers.rooms].find(
              (marker) => marker.id === markerIdToFocus
            );

            if (markerToFocus) {
              map.panTo([markerToFocus.lat, markerToFocus.lng], {
                animate: true,
              });
              return null;
            }

            const reservationMarkerToFocus = [
              ...markers.desks,
              ...markers.rooms,
            ].find((marker) => `${marker.id}-reservation` === markerIdToFocus);

            if (reservationMarkerToFocus) {
              const latlng = map.unproject(
                map
                  .project([
                    reservationMarkerToFocus.lat,
                    reservationMarkerToFocus.lng,
                  ])
                  .add([RESERVATION_MARKER_WIDTH_PX / 2, 0])
              );
              map.panTo(latlng, {
                animate: true,
              });

              return null;
            }
          }
          return null;
        }}
      </MapConsumer>
    );
  }
);

export { PanToController };
