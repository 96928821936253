import React, { useMemo } from "react";
import Select from "react-select";

import { useRoomsQuery } from "../../../lib/hooks/useRoomsQuery";

import { structureOptions } from "./lib/structureOptions";
import { selectStyles } from "../../../lib/utils/selectStyles";
import { structureInitialValues } from "./lib/structureInitialValues";

import { ApolloErrorGuard } from "../ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../HandleLoadingState/HandleLoadingState";

interface Props {
  currentRoom: string;
  initialValues: string[];
  onChange: (values: string[]) => void;
  isDisabled: boolean;
}

export const NearbyRoomsSelect = (props: Props) => {
  const { currentRoom, initialValues, onChange, isDisabled } = props;
  const { loading, error, data } = useRoomsQuery();

  const values = structureInitialValues(data, initialValues);
  const options = useMemo(() => structureOptions(data, currentRoom), [data]);

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<h5>Loading...</h5>}
    >
      <ApolloErrorGuard
        error={error}
        errorComponent={<h4>Error fetching room resources...</h4>}
      >
        <Select
          isMulti
          name="color"
          isClearable
          value={values}
          options={options}
          menuPlacement="top"
          isSearchable={true}
          theme={selectStyles}
          isDisabled={isDisabled}
          onChange={(e) => {
            if (!e) {
              return;
            }

            const values = e.map((item) => item.value);

            onChange(values);
          }}
        />
      </ApolloErrorGuard>
    </HandleLoadingState>
  );
};
