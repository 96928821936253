import React from "react";
import cs from "classnames";
import { Spinner } from "reactstrap";

import { useRouter } from "../../../lib/hooks/useRouter";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";

import { CALENDARS_ROOT_PATH } from "../../../lib/routes/routes";

import { tooltips } from "../../../lib/utils/tooltips";
import { Integrations } from "./Integrations/Integrations";
import { PageSidebar } from "../../shared/PageSidebar/PageSidebar";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { AddCalendarConnections } from "../AddCalendarConnections/AddCalendarConnections";
import { PageSidebarHeader } from "../../shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";

import "./CalendarSidebar.scss";

export const CalendarSidebar = () => {
  const { pathname, history } = useRouter();
  const { loading } = useCalendarContext();

  const containerClasses = cs("CalendarSidebar__container", {
    "CalendarSidebar__container--loading d-flex mt-5 justify-content-center":
      loading,
  });

  return (
    <div className="CalendarSidebar">
      <PageSidebar showOnDesktop={true}>
        <PageSidebarHeader
          title="Calendar integrations"
          message={tooltips.calendars.connections}
          onTitleClick={() => {
            if (pathname === CALENDARS_ROOT_PATH) {
              return;
            }

            history.push(CALENDARS_ROOT_PATH);
          }}
        />
        <div className={containerClasses}>
          <HandleRequestState state={loading} placeholder={<Spinner />}>
            <Integrations />
            <AddCalendarConnections />
          </HandleRequestState>
        </div>
      </PageSidebar>
    </div>
  );
};
