import React, { useEffect, useState } from "react";
import { FormGroup, Input, FormFeedback } from "reactstrap";

import { tooltips } from "../../../lib/utils/tooltips";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../LabelWithInformationBox/LabelWithInformationBox";

import "./TagInput.scss";

interface TagInputProps {
  value: string[];
  hasError?: boolean;
  errorMessage?: string;
  onChange: (tags: string[]) => void;
  direction?: LabelDirectionType;
}

const tagExist = (tags: string[], tagItem: string) => {
  return tags.filter((tag: string) => {
    return tag === tagItem;
  });
};

export const TagInput = (props: TagInputProps) => {
  const { value, hasError, errorMessage, onChange, direction } = props;
  const [inputState, setInputState] = useState("");
  const [tagsState, setTagsState] = useState<string[]>(value);

  useEffect(() => {
    setTagsState(value);
  }, [value]);

  useEffect(() => {
    if (value !== tagsState) {
      onChange(tagsState);
    }
  }, [tagsState]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (tagsState.length === 0 && inputState === "") {
      return;
    }

    if (e.key === "Enter" || e.key === "Tab") {
      addTag(inputState);
    }

    if (e.key === "Backspace" && inputState === "") {
      removeTag(tagsState.length - 1);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setInputState(e.target.value);
  };

  const removeTag = (tagIndex: number) => () => {
    setTagsState((tags) =>
      tags.filter((tag: string, index: number) => index !== tagIndex)
    );
  };

  const addTag = (tag: string) => {
    if (tag.length === 0) {
      return;
    }

    setTagsState((prev) => {
      if (tagExist(tagsState, tag).length === 0) {
        return [...prev, tag];
      }
      return prev;
    });
    setInputState("");
  };

  return (
    <FormGroup>
      <LabelWithInformationBox
        for="tags"
        title="Tags"
        message={tooltips.workplaces.tags}
        direction={direction}
      />
      <div className="tags">
        {tagsState.map((tag: string, index: number) => {
          return (
            <span className="tags__item" key={`${tag}-${index}`}>
              {tag}
              <span
                data-role="remove"
                role="button"
                onClick={removeTag(index)}
              ></span>
            </span>
          );
        })}
        <Input
          id="tags"
          name="tags"
          type="text"
          autoComplete="off"
          value={inputState}
          invalid={hasError}
          className="tags__input"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={(e) => {
            if (e) {
              addTag(e.target.value);
            }
          }}
          placeholder={tagsState.length === 0 ? "Add new tag" : ""}
        />
      </div>
      <FormFeedback>{errorMessage}</FormFeedback>
    </FormGroup>
  );
};
