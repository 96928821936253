import React from "react";
import cs from "classnames";

import { Status as StatusType } from "../../../api/grpc/contacts/account/account";

import "./Status.scss";

interface Props {
  status: StatusType;
}

const statusText: { [key in StatusType]: string } = {
  [StatusType.ACTIVE]: "Active",
  [StatusType.INACTIVE]: "Inactive",
  [StatusType.PENDING]: "Pending",
  [StatusType.UNKNOWN_STATUS]: "Unknown",
};

export const Status = ({ status }: Props) => {
  const classes = cs("Status", {
    "Status--active": status === StatusType.ACTIVE,
    "Status--inactive": status === StatusType.INACTIVE,
  });

  return <span className={classes}>{statusText[status]}</span>;
};
