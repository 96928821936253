import React from "react";
import { NavLink } from "react-router-dom";
import { GENERAL_STRINGS as t } from "../../../../lib/utils/constants";

export const RegisterButton = () => {
  return (
    <NavLink to={"/register"} className="mr-3 btn btn-primary">
      {t.register}
    </NavLink>
  );
};
