import React from "react";

import "../../../../../src/components/Login/ProvidersLogin/ConnectProviders.scss";

interface Props {
  className?: string;
  handleClick: () => void;
  type: "Google" | "Office 365" | "Microsoft";
  disabled?: boolean;
}

export const ProviderButton = ({
  className,
  handleClick,
  type,
  disabled,
}: Props) => {
  return (
    <div className={`ConnectProviders ${className}`}>
      <button
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
        disabled={disabled}
      >
        <span
          className={`ConnectProviders__${
            type === "Google" ? "google" : "office"
          }`}
        ></span>
        <span>Sign in with {type}</span>
      </button>
    </div>
  );
};
