import React, { useEffect, useState } from "react";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useTemplateContext } from "../../../../lib/context/Templates/Templates/TemplatesContext";

import { CalendarsWizardHeaderItem } from "../../../Calendars/CalendarsWizardForm/CalendarsWizardHeader/CalendarsWizardHeaderItem/CalendarsWizardHeaderItem";
import { Button } from "../../../shared/Button/Button";
import { DefaultTemplate } from "../../../shared/DefaultTemplate/DefaultTemplate";
import { SelectItemsStep } from "./Steps/SelectItemsStep";
import { SelectProfileStep } from "./Steps/SelectProfileStep";
import { SelectSettingsStep } from "./Steps/SelectSettingsStep";
import { TEMPLATE_ROOT_PATH } from "../../../../lib/routes/routes";
import { StepProps } from "../../../Calendars/CalendarsWizardForm/CalendarsWizardForm";

import "./ApplyProfilesSteps.scss";

enum StepsEnum {
  TEMPLATE = 1,
  WORKSPACE = 2,
  SETTINGS = 3,
}

export const ApplyProfilesSteps = () => {
  const [activeStep, setActiveStep] = useState<StepsEnum>(StepsEnum.TEMPLATE);
  const { history } = useRouter();
  const {
    activeProfile,
    setActiveProfile,
    pickedWorkspaces,
    setPickedWorkspaces,
  } = useTemplateContext();

  const steps: StepProps[] = [
    {
      id: "calendar-wizard-1",
      title: "Select template",
    },
    pickedWorkspaces.redirect
      ? null
      : {
          id: "calendar-wizard-2",
          title: "Select workspaces",
        },
    {
      id: "calendar-wizard-3",
      title: "Select settings",
    },
  ].filter((step): step is StepProps => step !== null);

  const handlePrevClick = () => {
    if (activeStep === StepsEnum.TEMPLATE) {
      if (activeProfile) {
        setActiveProfile(undefined);
      }
      if (pickedWorkspaces.data.length) {
        setPickedWorkspaces({ data: [], redirect: undefined });
      }

      history.push(TEMPLATE_ROOT_PATH);

      return;
    }

    if (activeStep === StepsEnum.SETTINGS && pickedWorkspaces.redirect) {
      setActiveStep(StepsEnum.TEMPLATE);
      return;
    }

    setActiveStep((prevValue) => prevValue - 1);
  };

  const handleStepUpdate = (n: number) => {
    if (n === 4) return;

    if (
      n === StepsEnum.WORKSPACE &&
      pickedWorkspaces.data.length &&
      pickedWorkspaces.redirect
    ) {
      return setActiveStep(StepsEnum.SETTINGS);
    }

    setActiveStep(n);
  };

  const defineDisableButton = (): boolean => {
    if (activeStep === StepsEnum.TEMPLATE) {
      return activeProfile === undefined;
    }

    if (activeStep === StepsEnum.WORKSPACE) {
      return pickedWorkspaces.data.length <= 0;
    }

    return false;
  };

  useEffect(() => {
    //if activeProfile is filled from edit profile page, we directly go to step 2
    if (activeStep === StepsEnum.TEMPLATE && activeProfile?.redirect === true) {
      setActiveStep(StepsEnum.WORKSPACE);
    }
  }, []);

  return (
    <div className="ApplyProfilesSteps mt-5">
      <DefaultTemplate>
        <div className="CalendarsWizardForm">
          <div className="CalendarsWizardHeader CalendarsWizardHeader__three">
            {steps.map((item, key: number) => {
              const itemIndex = ++key;
              let isActive = activeStep === itemIndex;

              if (
                pickedWorkspaces.redirect &&
                activeStep === StepsEnum.SETTINGS
              ) {
                isActive = activeStep === itemIndex + 1;
              }

              return (
                <CalendarsWizardHeaderItem
                  item={item}
                  number={itemIndex}
                  key={`${item.id}-header`}
                  // isActive={activeStep === itemIndex}
                  isActive={isActive}
                  isCompleted={itemIndex < activeStep}
                  onClick={() => {}}
                  //   onClick={() => props.handleClick(itemIndex)}
                />
              );
            })}
          </div>

          <div className="CalendarsWizardForm__profiles">
            {activeStep === StepsEnum.TEMPLATE && <SelectProfileStep />}
            {activeStep === StepsEnum.WORKSPACE &&
              pickedWorkspaces.redirect !== true && <SelectItemsStep />}
            {activeStep === StepsEnum.SETTINGS && <SelectSettingsStep />}
          </div>

          <div className="CalendarsWizardForm__profiles--footer d-flex justify-content-between border-top pt-4 mt-4">
            <Button
              size="small"
              color="secondary"
              title="Cancel"
              onClick={() => {
                if (activeProfile) {
                  setActiveProfile(undefined);
                }
                if (pickedWorkspaces.data.length) {
                  setPickedWorkspaces({ data: [], redirect: undefined });
                }
                history.push(TEMPLATE_ROOT_PATH);
                return;
              }}
            />

            <div className="CalendarsWizardForm__profiles--footer--group">
              <Button
                size="small"
                color="outline-secondary"
                title="Back"
                onClick={handlePrevClick}
              />
              <Button
                size="small"
                color="primary"
                title="Next"
                className={`ml-3 ${
                  activeStep === StepsEnum.SETTINGS ? "invisible" : ""
                }`}
                disabled={defineDisableButton()}
                onClick={() => {
                  handleStepUpdate(activeStep + 1);
                }}
              />
            </div>
          </div>
        </div>
      </DefaultTemplate>
    </div>
  );
};
