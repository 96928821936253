import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { IconDefinition } from "../shared/Icon/Icon.generated";
import { Icon } from "../shared/Icon/Icon";

export const IntegrationsContent = ({
  className,
  contentClassName,
  icon,
  description,
  label,
  id,
  badge,
}: PropsWithChildren<{
  className?: string;
  contentClassName?: string;
  icon: IconDefinition;
  description: string;
  label: string;
  badge?: string;
  id?: string;
}>) => {
  const classes = cs(
    "CalendarSystem__item__icon d-flex align-items-center justify-content-center",
    className
  );

  const contentClasses = cs("CalendarSystem__item__content", contentClassName);

  return (
    <>
      <div className={classes}>
        <Icon icon={icon} />
      </div>
      <div className={contentClasses}>
        <div className="d-flex align-items-center">
          <h4
            className={`CalendarSystem__item__title ${
              id ? `CalendarSystem__item__title--${id}` : ""
            } `}
          >
            {label}
          </h4>
          {!!badge && (
            <div className="CalendarSystem__item__badge">{badge}</div>
          )}
        </div>
        <p className="CalendarSystem__item__desc">{description}</p>
      </div>
    </>
  );
};
