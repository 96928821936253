import {
  AnalyticsSubscriptionInfoResponse,
  CustomerRequest,
} from "../../api/grpc/analytics/analytics";

import useGrpcQuery from "./useGrpcQuery";
import { useAnalytics } from "../../api/grpc/analytics/useAnalytics";
import { useTimeZoneContext } from "../context/TimeZone/TimeZoneContext";

export const useSubscription = () => {
  const { getSubscription } = useAnalytics();
  const { timeZone } = useTimeZoneContext();

  const { loading, error, data } = useGrpcQuery<
    Omit<CustomerRequest, "customerID">,
    AnalyticsSubscriptionInfoResponse
  >({
    method: getSubscription,
    variables: {
      timeZone,
    },
  });

  return { loading, error, data };
};
