// @generated by protobuf-ts 2.9.4 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "subscription/subscription.proto" (syntax proto3)
// tslint:disable
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FieldMask } from "../google/protobuf/field_mask";
/**
 * @generated from protobuf message DeleteCBCustomerRequest
 */
export interface DeleteCBCustomerRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CreateSubscriptionRequest
 */
export interface CreateSubscriptionRequest {
    /**
     * Required. The subscription to create.
     *
     * @generated from protobuf field: Subscription subscription = 1;
     */
    subscription?: Subscription;
    /**
     * Chargebee customerId
     *
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
    /**
     * BillingId in Platform 2.0 database
     *
     * @generated from protobuf field: int32 billing_id = 3;
     */
    billingId: number;
}
/**
 * @generated from protobuf message GetSubscriptionRequest
 */
export interface GetSubscriptionRequest {
    /**
     * Chargebee customerId
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * Chargebee subscriptionId
     *
     * @generated from protobuf field: string subscription_id = 2;
     */
    subscriptionId: string;
}
/**
 * @generated from protobuf message GetCustomerSubscriptionRequest
 */
export interface GetCustomerSubscriptionRequest {
    /**
     * Chargebee customerId
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ListSubscriptionsRequest
 */
export interface ListSubscriptionsRequest {
    /**
     * The maximum number of subscriptions to return. The service may return fewer
     * than this value. If unspecified, at most 50 subscriptions will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `ListSubscriptions` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListSubscriptions` must
     * match the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
    /**
     * Chargebee customerId
     *
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ListSubscriptionsResponse
 */
export interface ListSubscriptionsResponse {
    /**
     * List of Chargebee Subscriptions
     *
     * @generated from protobuf field: repeated Subscription subscriptions = 1;
     */
    subscriptions: Subscription[];
    /**
     * A token, which can be sent as `page_token` to retrieve the next page.
     * If this field is omitted, there are no subsequent pages.
     *
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message UpdateSubscriptionRequest
 */
export interface UpdateSubscriptionRequest {
    /**
     * The subscription to update.
     *
     * The subscription's `id` field is used to identify the subscription to be
     * updated.
     *
     * @generated from protobuf field: Subscription subscription = 1;
     */
    subscription?: Subscription;
    /**
     * The list of fields to be updated.
     *
     * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
     */
    updateMask?: FieldMask;
    /**
     * Chargebee customerId
     *
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DeleteSubscriptionRequest
 */
export interface DeleteSubscriptionRequest {
    /**
     * Chargebee customerId
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * Chargebee subscriptionId
     *
     * @generated from protobuf field: string subscription_id = 2;
     */
    subscriptionId: string;
}
/**
 * @generated from protobuf message TrialRequest
 */
export interface TrialRequest {
    /**
     * CustomerId of the Google Identity Platform tenant. ex: cus-test-123
     *
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
    /**
     * Customer details.
     *
     * @generated from protobuf field: CBCustomer customer = 2;
     */
    customer?: CBCustomer;
    /**
     * Billing details.
     *
     * @generated from protobuf field: BillingAddress billingAddress = 3;
     */
    billingAddress?: BillingAddress;
}
/**
 * @generated from protobuf message CheckSubscriptionRequest
 */
export interface CheckSubscriptionRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message InvoiceRequest
 */
export interface InvoiceRequest {
    /**
     * @generated from protobuf field: string invoiceId = 1;
     */
    invoiceId: string;
}
/**
 * @generated from protobuf message InvoicePDFResponse
 */
export interface InvoicePDFResponse {
    /**
     * @generated from protobuf field: string invoiceUrl = 1;
     */
    invoiceUrl: string;
}
/**
 * @generated from protobuf message CheckSubscriptionResponse
 */
export interface CheckSubscriptionResponse {
    /**
     * @generated from protobuf field: bool exists = 1;
     */
    exists: boolean;
}
/**
 * @generated from protobuf message PortalSessionRequest
 */
export interface PortalSessionRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message PortalSession
 */
export interface PortalSession {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
    /**
     * @generated from protobuf field: string access_url = 3;
     */
    accessUrl: string;
    /**
     * @generated from protobuf field: string status = 4;
     */
    status: string;
    /**
     * @generated from protobuf field: int64 created_at = 5;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 expires_at = 6;
     */
    expiresAt: number;
    /**
     * @generated from protobuf field: string object = 7;
     */
    object: string;
    /**
     * @generated from protobuf field: string customer_id = 8;
     */
    customerId: string;
}
/**
 * @generated from protobuf message Addon
 */
export interface Addon {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string item_price_id = 4;
     */
    itemPriceId: string;
    /**
     * @generated from protobuf field: EntityType entityType = 5;
     */
    entityType: EntityType;
}
/**
 * @generated from protobuf message BillingStatus
 */
export interface BillingStatus {
    /**
     * @generated from protobuf field: string upcoming_billing_at = 1;
     */
    upcomingBillingAt: string;
    /**
     * @generated from protobuf field: int32 price = 2;
     */
    price: number;
    /**
     * @generated from protobuf field: string currency = 3;
     */
    currency: string;
}
/**
 * @generated from protobuf message Subscription
 */
export interface Subscription {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string plan_id = 3;
     */
    planId: string;
    /**
     * @generated from protobuf field: string status = 4;
     */
    status: string;
    /**
     * @generated from protobuf field: string createdAt = 5;
     */
    createdAt: string;
    /**
     * @generated from protobuf field: string startedAt = 6;
     */
    startedAt: string;
    /**
     * @generated from protobuf field: string activatedAt = 7;
     */
    activatedAt: string;
    /**
     * @generated from protobuf field: string customer_id = 8;
     */
    customerId: string;
    /**
     * @generated from protobuf field: repeated Addon addons = 9;
     */
    addons: Addon[];
    /**
     * @generated from protobuf field: string pay_period = 10;
     */
    payPeriod: string;
    /**
     * @generated from protobuf field: BillingStatus billing_status = 11;
     */
    billingStatus?: BillingStatus;
    /**
     * @generated from protobuf field: string cancel_date = 12;
     */
    cancelDate: string;
    /**
     * @generated from protobuf field: string next_renewal = 13;
     */
    nextRenewal: string;
    /**
     * @generated from protobuf field: string email = 14;
     */
    email: string;
    /**
     * @generated from protobuf field: string company = 15;
     */
    company: string;
}
/**
 * @generated from protobuf message CBCustomer
 */
export interface CBCustomer {
    /**
     * @generated from protobuf field: string firstName = 1;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string lastName = 2;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: string locale = 4;
     */
    locale: string;
    /**
     * @generated from protobuf field: string phone = 5;
     */
    phone: string;
    /**
     * @generated from protobuf field: string companyName = 6;
     */
    companyName: string;
    /**
     * @generated from protobuf field: BillingAddress billingInfo = 7;
     */
    billingInfo?: BillingAddress;
}
/**
 * @generated from protobuf message BillingAddress
 */
export interface BillingAddress {
    /**
     * @generated from protobuf field: string firstName = 1;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string lastName = 2;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string line1 = 3;
     */
    line1: string;
    /**
     * @generated from protobuf field: string line2 = 4;
     */
    line2: string;
    /**
     * @generated from protobuf field: string line3 = 5;
     */
    line3: string;
    /**
     * @generated from protobuf field: string city = 6;
     */
    city: string;
    /**
     * @generated from protobuf field: string state = 7;
     */
    state: string;
    /**
     * @generated from protobuf field: string zip = 8;
     */
    zip: string;
    /**
     * 2-letter, ISO 3166 alpha-2 country code.
     *
     * @generated from protobuf field: string country = 9;
     */
    country: string;
    /**
     * @generated from protobuf field: string vat = 10;
     */
    vat: string;
    /**
     * @generated from protobuf field: string currency = 11;
     */
    currency: string;
}
/**
 * @generated from protobuf message EntityLicenceRequest
 */
export interface EntityLicenceRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string subscriptionId = 2;
     */
    subscriptionId: string;
    /**
     * @generated from protobuf field: string entityId = 3;
     */
    entityId: string;
    /**
     * @generated from protobuf field: EntityType entityType = 4;
     */
    entityType: EntityType;
}
/**
 * @generated from protobuf message EntityLicenceResponse
 */
export interface EntityLicenceResponse {
    /**
     * @generated from protobuf field: LicenseStatus status = 1;
     */
    status: LicenseStatus;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * @generated from protobuf field: string entityID = 3;
     */
    entityID: string;
    /**
     * @generated from protobuf field: bool isLicensed = 4;
     */
    isLicensed: boolean;
}
/**
 * @generated from protobuf message ListLicenseRequest
 */
export interface ListLicenseRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string subscriptionId = 2;
     */
    subscriptionId: string;
}
/**
 * @generated from protobuf message SubscriptionCustomerRequest
 */
export interface SubscriptionCustomerRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message LicenseResponse
 */
export interface LicenseResponse {
    /**
     * @generated from protobuf field: int64 totalQuantity = 1;
     */
    totalQuantity: number;
    /**
     * @generated from protobuf field: int64 totalLicenced = 2;
     */
    totalLicenced: number;
    /**
     * @generated from protobuf field: EntityType entityType = 3;
     */
    entityType: EntityType;
}
/**
 * @generated from protobuf message ListLicenseResponse
 */
export interface ListLicenseResponse {
    /**
     * @generated from protobuf field: repeated LicenseResponse response = 1;
     */
    response: LicenseResponse[];
}
/**
 * @generated from protobuf message Invoices
 */
export interface Invoices {
    /**
     * @generated from protobuf field: string invoiceID = 1;
     */
    invoiceID: string;
    /**
     * @generated from protobuf field: string planName = 2;
     */
    planName: string;
    /**
     * @generated from protobuf field: string chargeAmount = 3;
     */
    chargeAmount: string;
    /**
     * @generated from protobuf field: string currency = 4;
     */
    currency: string;
    /**
     * @generated from protobuf field: string date = 5;
     */
    date: string;
}
/**
 * @generated from protobuf message BillingStat
 */
export interface BillingStat {
    /**
     * @generated from protobuf field: string upcomingCharge = 1;
     */
    upcomingCharge: string;
    /**
     * @generated from protobuf field: string chargeAmount = 2;
     */
    chargeAmount: string;
    /**
     * @generated from protobuf field: string currency = 3;
     */
    currency: string;
    /**
     * @generated from protobuf field: repeated Invoices invoices = 4;
     */
    invoices: Invoices[];
}
/**
 * @generated from protobuf message SubscriptionStats
 */
export interface SubscriptionStats {
    /**
     * @generated from protobuf field: string planName = 1;
     */
    planName: string;
    /**
     * @generated from protobuf field: string planPrice = 2;
     */
    planPrice: string;
    /**
     * @generated from protobuf field: string planCurrency = 3;
     */
    planCurrency: string;
    /**
     * @generated from protobuf field: int32 roomNumber = 4;
     */
    roomNumber: number;
    /**
     * @generated from protobuf field: int32 deskNumber = 5;
     */
    deskNumber: number;
    /**
     * @generated from protobuf field: int32 licensedRoomNumber = 6;
     */
    licensedRoomNumber: number;
    /**
     * @generated from protobuf field: int32 licensedDeskNumber = 7;
     */
    licensedDeskNumber: number;
    /**
     * @generated from protobuf field: string status = 8;
     */
    status: string;
    /**
     * @generated from protobuf field: int32 userNumber = 9;
     */
    userNumber: number;
    /**
     * @generated from protobuf field: int32 licensedUserNumber = 10;
     */
    licensedUserNumber: number;
    /**
     * @generated from protobuf field: int32 wayfinderNumber = 11;
     */
    wayfinderNumber: number;
    /**
     * @generated from protobuf field: int32 licensedWayfinderNumber = 12;
     */
    licensedWayfinderNumber: number;
}
/**
 * @generated from protobuf message SubscriptionInfoResponse
 */
export interface SubscriptionInfoResponse {
    /**
     * @generated from protobuf field: SubscriptionStats subscription = 1;
     */
    subscription?: SubscriptionStats;
    /**
     * @generated from protobuf field: BillingStat billing = 2;
     */
    billing?: BillingStat;
}
/**
 * @generated from protobuf message LicenseFreeSlotsRequest
 */
export interface LicenseFreeSlotsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string subscriptionID = 2;
     */
    subscriptionID: string;
    /**
     * @generated from protobuf field: EntityType type = 3;
     */
    type: EntityType;
}
/**
 * @generated from protobuf message LicenseFreeSlotsResponse
 */
export interface LicenseFreeSlotsResponse {
    /**
     * @generated from protobuf field: int32 quantity = 1;
     */
    quantity: number;
}
/**
 * @generated from protobuf message UpdateChargebeeCustomerRequest
 */
export interface UpdateChargebeeCustomerRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CreatePaymentCardRequest
 */
export interface CreatePaymentCardRequest {
    /**
     * @generated from protobuf field: string number = 1;
     */
    number: string;
    /**
     * @generated from protobuf field: string cvv = 2;
     */
    cvv: string;
    /**
     * @generated from protobuf field: int32 expiry_year = 3;
     */
    expiryYear: number;
    /**
     * @generated from protobuf field: int32 expiry_month = 4;
     */
    expiryMonth: number;
    /**
     * @generated from protobuf field: string customer_id = 5;
     */
    customerId: string;
}
/**
 * @generated from protobuf message UpdatePaymentCardRequest
 */
export interface UpdatePaymentCardRequest {
    /**
     * @generated from protobuf field: string number = 1;
     */
    number: string;
    /**
     * @generated from protobuf field: string cvv = 2;
     */
    cvv: string;
    /**
     * @generated from protobuf field: int32 expiry_year = 3;
     */
    expiryYear: number;
    /**
     * @generated from protobuf field: int32 expiry_month = 4;
     */
    expiryMonth: number;
    /**
     * @generated from protobuf field: string customer_id = 5;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string id = 6;
     */
    id: string;
}
/**
 * @generated from protobuf message GetPaymentCardRequest
 */
export interface GetPaymentCardRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message PaymentCardResponse
 */
export interface PaymentCardResponse {
    /**
     * @generated from protobuf field: string masked_number = 1;
     */
    maskedNumber: string;
    /**
     * @generated from protobuf field: int32 expiry_year = 2;
     */
    expiryYear: number;
    /**
     * @generated from protobuf field: int32 expiry_month = 3;
     */
    expiryMonth: number;
    /**
     * @generated from protobuf field: string customer_id = 4;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string brand = 5;
     */
    brand: string;
}
/**
 * @generated from protobuf message CreateChannelPartnerRequest
 */
export interface CreateChannelPartnerRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string partner_email = 2;
     */
    partnerEmail: string;
}
/**
 * @generated from protobuf message GetChannelPartnerRequest
 */
export interface GetChannelPartnerRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DelinkChannelPartnerRequest
 */
export interface DelinkChannelPartnerRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message GetSubscriptionCustomerRequest
 */
export interface GetSubscriptionCustomerRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message CreateHostedPageRequest
 */
export interface CreateHostedPageRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string itemPriceID = 2;
     */
    itemPriceID: string;
    /**
     * @generated from protobuf field: string redirectURL = 3;
     */
    redirectURL: string;
}
/**
 * @generated from protobuf message ListCustomerSubscriptionRequest
 */
export interface ListCustomerSubscriptionRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message ListCustomerSubscriptionResponse
 */
export interface ListCustomerSubscriptionResponse {
    /**
     * @generated from protobuf field: repeated Subscription subscriptions = 1;
     */
    subscriptions: Subscription[];
}
/**
 * @generated from protobuf message HostedPage
 */
export interface HostedPage {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
    /**
     * @generated from protobuf field: string state = 4;
     */
    state: string;
    /**
     * @generated from protobuf field: string failureReason = 5;
     */
    failureReason: string;
    /**
     * @generated from protobuf field: string passThruContent = 6;
     */
    passThruContent: string;
    /**
     * @generated from protobuf field: bool embed = 7;
     */
    embed: boolean;
    /**
     * @generated from protobuf field: int64 createdAt = 8;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 expiresAt = 9;
     */
    expiresAt: number;
    /**
     * @generated from protobuf field: bytes content = 10;
     */
    content: Uint8Array;
    /**
     * @generated from protobuf field: int64 updatedAt = 11;
     */
    updatedAt: number;
    /**
     * @generated from protobuf field: int64 resourceVersion = 12;
     */
    resourceVersion: number;
    /**
     * @generated from protobuf field: bytes checkoutInfo = 13;
     */
    checkoutInfo: Uint8Array;
    /**
     * @generated from protobuf field: string object = 14;
     */
    object: string;
}
/**
 * @generated from protobuf message BulkLicensingRequest
 */
export interface BulkLicensingRequest {
    /**
     * @generated from protobuf field: repeated EntityLicenceRequest entities = 1;
     */
    entities: EntityLicenceRequest[];
}
/**
 * @generated from protobuf message BulkLicensingResponse
 */
export interface BulkLicensingResponse {
    /**
     * @generated from protobuf field: repeated EntityLicenceResponse status = 1;
     */
    status: EntityLicenceResponse[];
}
/**
 * @generated from protobuf enum EntityType
 */
export enum EntityType {
    /**
     * @generated from protobuf enum value: ROOM = 0;
     */
    ROOM = 0,
    /**
     * @generated from protobuf enum value: DESK = 1;
     */
    DESK = 1,
    /**
     * @generated from protobuf enum value: USER = 2;
     */
    USER = 2,
    /**
     * @generated from protobuf enum value: WAYFINDER = 3;
     */
    WAYFINDER = 3
}
/**
 * Room licensing status
 *
 * @generated from protobuf enum LicenseStatus
 */
export enum LicenseStatus {
    /**
     * @generated from protobuf enum value: SUCCESS = 0;
     */
    SUCCESS = 0,
    /**
     * @generated from protobuf enum value: FAIL = 1;
     */
    FAIL = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCBCustomerRequest$Type extends MessageType<DeleteCBCustomerRequest> {
    constructor() {
        super("DeleteCBCustomerRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteCBCustomerRequest>): DeleteCBCustomerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteCBCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCBCustomerRequest): DeleteCBCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCBCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteCBCustomerRequest
 */
export const DeleteCBCustomerRequest = new DeleteCBCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSubscriptionRequest$Type extends MessageType<CreateSubscriptionRequest> {
    constructor() {
        super("CreateSubscriptionRequest", [
            { no: 1, name: "subscription", kind: "message", T: () => Subscription, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "billing_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CreateSubscriptionRequest>): CreateSubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.billingId = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSubscriptionRequest): CreateSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Subscription subscription */ 1:
                    message.subscription = Subscription.internalBinaryRead(reader, reader.uint32(), options, message.subscription);
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* int32 billing_id */ 3:
                    message.billingId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Subscription subscription = 1; */
        if (message.subscription)
            Subscription.internalBinaryWrite(message.subscription, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* int32 billing_id = 3; */
        if (message.billingId !== 0)
            writer.tag(3, WireType.Varint).int32(message.billingId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateSubscriptionRequest
 */
export const CreateSubscriptionRequest = new CreateSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSubscriptionRequest$Type extends MessageType<GetSubscriptionRequest> {
    constructor() {
        super("GetSubscriptionRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subscription_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSubscriptionRequest>): GetSubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.subscriptionId = "";
        if (value !== undefined)
            reflectionMergePartial<GetSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSubscriptionRequest): GetSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string subscription_id */ 2:
                    message.subscriptionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string subscription_id = 2; */
        if (message.subscriptionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subscriptionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetSubscriptionRequest
 */
export const GetSubscriptionRequest = new GetSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomerSubscriptionRequest$Type extends MessageType<GetCustomerSubscriptionRequest> {
    constructor() {
        super("GetCustomerSubscriptionRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomerSubscriptionRequest>): GetCustomerSubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<GetCustomerSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomerSubscriptionRequest): GetCustomerSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomerSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCustomerSubscriptionRequest
 */
export const GetCustomerSubscriptionRequest = new GetCustomerSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubscriptionsRequest$Type extends MessageType<ListSubscriptionsRequest> {
    constructor() {
        super("ListSubscriptionsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListSubscriptionsRequest>): ListSubscriptionsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pageSize = 0;
        message.pageToken = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<ListSubscriptionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSubscriptionsRequest): ListSubscriptionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSubscriptionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListSubscriptionsRequest
 */
export const ListSubscriptionsRequest = new ListSubscriptionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubscriptionsResponse$Type extends MessageType<ListSubscriptionsResponse> {
    constructor() {
        super("ListSubscriptionsResponse", [
            { no: 1, name: "subscriptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Subscription },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListSubscriptionsResponse>): ListSubscriptionsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subscriptions = [];
        message.nextPageToken = "";
        if (value !== undefined)
            reflectionMergePartial<ListSubscriptionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSubscriptionsResponse): ListSubscriptionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Subscription subscriptions */ 1:
                    message.subscriptions.push(Subscription.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSubscriptionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Subscription subscriptions = 1; */
        for (let i = 0; i < message.subscriptions.length; i++)
            Subscription.internalBinaryWrite(message.subscriptions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListSubscriptionsResponse
 */
export const ListSubscriptionsResponse = new ListSubscriptionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSubscriptionRequest$Type extends MessageType<UpdateSubscriptionRequest> {
    constructor() {
        super("UpdateSubscriptionRequest", [
            { no: 1, name: "subscription", kind: "message", T: () => Subscription, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "update_mask", kind: "message", T: () => FieldMask },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateSubscriptionRequest>): UpdateSubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSubscriptionRequest): UpdateSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Subscription subscription */ 1:
                    message.subscription = Subscription.internalBinaryRead(reader, reader.uint32(), options, message.subscription);
                    break;
                case /* google.protobuf.FieldMask update_mask */ 2:
                    message.updateMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.updateMask);
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* Subscription subscription = 1; */
        if (message.subscription)
            Subscription.internalBinaryWrite(message.subscription, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.FieldMask update_mask = 2; */
        if (message.updateMask)
            FieldMask.internalBinaryWrite(message.updateMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateSubscriptionRequest
 */
export const UpdateSubscriptionRequest = new UpdateSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteSubscriptionRequest$Type extends MessageType<DeleteSubscriptionRequest> {
    constructor() {
        super("DeleteSubscriptionRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subscription_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteSubscriptionRequest>): DeleteSubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.subscriptionId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteSubscriptionRequest): DeleteSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string subscription_id */ 2:
                    message.subscriptionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string subscription_id = 2; */
        if (message.subscriptionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subscriptionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteSubscriptionRequest
 */
export const DeleteSubscriptionRequest = new DeleteSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrialRequest$Type extends MessageType<TrialRequest> {
    constructor() {
        super("TrialRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer", kind: "message", T: () => CBCustomer },
            { no: 3, name: "billingAddress", kind: "message", T: () => BillingAddress }
        ]);
    }
    create(value?: PartialMessage<TrialRequest>): TrialRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<TrialRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TrialRequest): TrialRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                case /* CBCustomer customer */ 2:
                    message.customer = CBCustomer.internalBinaryRead(reader, reader.uint32(), options, message.customer);
                    break;
                case /* BillingAddress billingAddress */ 3:
                    message.billingAddress = BillingAddress.internalBinaryRead(reader, reader.uint32(), options, message.billingAddress);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TrialRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* CBCustomer customer = 2; */
        if (message.customer)
            CBCustomer.internalBinaryWrite(message.customer, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BillingAddress billingAddress = 3; */
        if (message.billingAddress)
            BillingAddress.internalBinaryWrite(message.billingAddress, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TrialRequest
 */
export const TrialRequest = new TrialRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckSubscriptionRequest$Type extends MessageType<CheckSubscriptionRequest> {
    constructor() {
        super("CheckSubscriptionRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckSubscriptionRequest>): CheckSubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<CheckSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckSubscriptionRequest): CheckSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckSubscriptionRequest
 */
export const CheckSubscriptionRequest = new CheckSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceRequest$Type extends MessageType<InvoiceRequest> {
    constructor() {
        super("InvoiceRequest", [
            { no: 1, name: "invoiceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InvoiceRequest>): InvoiceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.invoiceId = "";
        if (value !== undefined)
            reflectionMergePartial<InvoiceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvoiceRequest): InvoiceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string invoiceId */ 1:
                    message.invoiceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvoiceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string invoiceId = 1; */
        if (message.invoiceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.invoiceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoiceRequest
 */
export const InvoiceRequest = new InvoiceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoicePDFResponse$Type extends MessageType<InvoicePDFResponse> {
    constructor() {
        super("InvoicePDFResponse", [
            { no: 1, name: "invoiceUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InvoicePDFResponse>): InvoicePDFResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.invoiceUrl = "";
        if (value !== undefined)
            reflectionMergePartial<InvoicePDFResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvoicePDFResponse): InvoicePDFResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string invoiceUrl */ 1:
                    message.invoiceUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvoicePDFResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string invoiceUrl = 1; */
        if (message.invoiceUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.invoiceUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoicePDFResponse
 */
export const InvoicePDFResponse = new InvoicePDFResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckSubscriptionResponse$Type extends MessageType<CheckSubscriptionResponse> {
    constructor() {
        super("CheckSubscriptionResponse", [
            { no: 1, name: "exists", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckSubscriptionResponse>): CheckSubscriptionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.exists = false;
        if (value !== undefined)
            reflectionMergePartial<CheckSubscriptionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckSubscriptionResponse): CheckSubscriptionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool exists */ 1:
                    message.exists = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckSubscriptionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool exists = 1; */
        if (message.exists !== false)
            writer.tag(1, WireType.Varint).bool(message.exists);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckSubscriptionResponse
 */
export const CheckSubscriptionResponse = new CheckSubscriptionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PortalSessionRequest$Type extends MessageType<PortalSessionRequest> {
    constructor() {
        super("PortalSessionRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PortalSessionRequest>): PortalSessionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<PortalSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PortalSessionRequest): PortalSessionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PortalSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PortalSessionRequest
 */
export const PortalSessionRequest = new PortalSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PortalSession$Type extends MessageType<PortalSession> {
    constructor() {
        super("PortalSession", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "access_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "expires_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "object", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PortalSession>): PortalSession {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.token = "";
        message.accessUrl = "";
        message.status = "";
        message.createdAt = 0;
        message.expiresAt = 0;
        message.object = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<PortalSession>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PortalSession): PortalSession {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                case /* string access_url */ 3:
                    message.accessUrl = reader.string();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                case /* int64 created_at */ 5:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 expires_at */ 6:
                    message.expiresAt = reader.int64().toNumber();
                    break;
                case /* string object */ 7:
                    message.object = reader.string();
                    break;
                case /* string customer_id */ 8:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PortalSession, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        /* string access_url = 3; */
        if (message.accessUrl !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accessUrl);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        /* int64 created_at = 5; */
        if (message.createdAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.createdAt);
        /* int64 expires_at = 6; */
        if (message.expiresAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.expiresAt);
        /* string object = 7; */
        if (message.object !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.object);
        /* string customer_id = 8; */
        if (message.customerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PortalSession
 */
export const PortalSession = new PortalSession$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Addon$Type extends MessageType<Addon> {
    constructor() {
        super("Addon", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "item_price_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "entityType", kind: "enum", T: () => ["EntityType", EntityType] }
        ]);
    }
    create(value?: PartialMessage<Addon>): Addon {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.quantity = 0;
        message.name = "";
        message.itemPriceId = "";
        message.entityType = 0;
        if (value !== undefined)
            reflectionMergePartial<Addon>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Addon): Addon {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string item_price_id */ 4:
                    message.itemPriceId = reader.string();
                    break;
                case /* EntityType entityType */ 5:
                    message.entityType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Addon, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string item_price_id = 4; */
        if (message.itemPriceId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.itemPriceId);
        /* EntityType entityType = 5; */
        if (message.entityType !== 0)
            writer.tag(5, WireType.Varint).int32(message.entityType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Addon
 */
export const Addon = new Addon$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingStatus$Type extends MessageType<BillingStatus> {
    constructor() {
        super("BillingStatus", [
            { no: 1, name: "upcoming_billing_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "price", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BillingStatus>): BillingStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.upcomingBillingAt = "";
        message.price = 0;
        message.currency = "";
        if (value !== undefined)
            reflectionMergePartial<BillingStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillingStatus): BillingStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string upcoming_billing_at */ 1:
                    message.upcomingBillingAt = reader.string();
                    break;
                case /* int32 price */ 2:
                    message.price = reader.int32();
                    break;
                case /* string currency */ 3:
                    message.currency = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillingStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string upcoming_billing_at = 1; */
        if (message.upcomingBillingAt !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.upcomingBillingAt);
        /* int32 price = 2; */
        if (message.price !== 0)
            writer.tag(2, WireType.Varint).int32(message.price);
        /* string currency = 3; */
        if (message.currency !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.currency);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingStatus
 */
export const BillingStatus = new BillingStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Subscription$Type extends MessageType<Subscription> {
    constructor() {
        super("Subscription", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "createdAt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "startedAt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "activatedAt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "addons", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Addon },
            { no: 10, name: "pay_period", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "billing_status", kind: "message", T: () => BillingStatus },
            { no: 12, name: "cancel_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "next_renewal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "company", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Subscription>): Subscription {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.planId = "";
        message.status = "";
        message.createdAt = "";
        message.startedAt = "";
        message.activatedAt = "";
        message.customerId = "";
        message.addons = [];
        message.payPeriod = "";
        message.cancelDate = "";
        message.nextRenewal = "";
        message.email = "";
        message.company = "";
        if (value !== undefined)
            reflectionMergePartial<Subscription>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Subscription): Subscription {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string plan_id */ 3:
                    message.planId = reader.string();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                case /* string createdAt */ 5:
                    message.createdAt = reader.string();
                    break;
                case /* string startedAt */ 6:
                    message.startedAt = reader.string();
                    break;
                case /* string activatedAt */ 7:
                    message.activatedAt = reader.string();
                    break;
                case /* string customer_id */ 8:
                    message.customerId = reader.string();
                    break;
                case /* repeated Addon addons */ 9:
                    message.addons.push(Addon.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string pay_period */ 10:
                    message.payPeriod = reader.string();
                    break;
                case /* BillingStatus billing_status */ 11:
                    message.billingStatus = BillingStatus.internalBinaryRead(reader, reader.uint32(), options, message.billingStatus);
                    break;
                case /* string cancel_date */ 12:
                    message.cancelDate = reader.string();
                    break;
                case /* string next_renewal */ 13:
                    message.nextRenewal = reader.string();
                    break;
                case /* string email */ 14:
                    message.email = reader.string();
                    break;
                case /* string company */ 15:
                    message.company = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Subscription, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string plan_id = 3; */
        if (message.planId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.planId);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        /* string createdAt = 5; */
        if (message.createdAt !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdAt);
        /* string startedAt = 6; */
        if (message.startedAt !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.startedAt);
        /* string activatedAt = 7; */
        if (message.activatedAt !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.activatedAt);
        /* string customer_id = 8; */
        if (message.customerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerId);
        /* repeated Addon addons = 9; */
        for (let i = 0; i < message.addons.length; i++)
            Addon.internalBinaryWrite(message.addons[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string pay_period = 10; */
        if (message.payPeriod !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.payPeriod);
        /* BillingStatus billing_status = 11; */
        if (message.billingStatus)
            BillingStatus.internalBinaryWrite(message.billingStatus, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string cancel_date = 12; */
        if (message.cancelDate !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.cancelDate);
        /* string next_renewal = 13; */
        if (message.nextRenewal !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.nextRenewal);
        /* string email = 14; */
        if (message.email !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.email);
        /* string company = 15; */
        if (message.company !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.company);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Subscription
 */
export const Subscription = new Subscription$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CBCustomer$Type extends MessageType<CBCustomer> {
    constructor() {
        super("CBCustomer", [
            { no: 1, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "lastName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "companyName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "billingInfo", kind: "message", T: () => BillingAddress }
        ]);
    }
    create(value?: PartialMessage<CBCustomer>): CBCustomer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.firstName = "";
        message.lastName = "";
        message.email = "";
        message.locale = "";
        message.phone = "";
        message.companyName = "";
        if (value !== undefined)
            reflectionMergePartial<CBCustomer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CBCustomer): CBCustomer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string firstName */ 1:
                    message.firstName = reader.string();
                    break;
                case /* string lastName */ 2:
                    message.lastName = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string locale */ 4:
                    message.locale = reader.string();
                    break;
                case /* string phone */ 5:
                    message.phone = reader.string();
                    break;
                case /* string companyName */ 6:
                    message.companyName = reader.string();
                    break;
                case /* BillingAddress billingInfo */ 7:
                    message.billingInfo = BillingAddress.internalBinaryRead(reader, reader.uint32(), options, message.billingInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CBCustomer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string firstName = 1; */
        if (message.firstName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.firstName);
        /* string lastName = 2; */
        if (message.lastName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.lastName);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string locale = 4; */
        if (message.locale !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.locale);
        /* string phone = 5; */
        if (message.phone !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.phone);
        /* string companyName = 6; */
        if (message.companyName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.companyName);
        /* BillingAddress billingInfo = 7; */
        if (message.billingInfo)
            BillingAddress.internalBinaryWrite(message.billingInfo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CBCustomer
 */
export const CBCustomer = new CBCustomer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingAddress$Type extends MessageType<BillingAddress> {
    constructor() {
        super("BillingAddress", [
            { no: 1, name: "firstName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "lastName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "line1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "line2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "line3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "country", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "vat", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BillingAddress>): BillingAddress {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.firstName = "";
        message.lastName = "";
        message.line1 = "";
        message.line2 = "";
        message.line3 = "";
        message.city = "";
        message.state = "";
        message.zip = "";
        message.country = "";
        message.vat = "";
        message.currency = "";
        if (value !== undefined)
            reflectionMergePartial<BillingAddress>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillingAddress): BillingAddress {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string firstName */ 1:
                    message.firstName = reader.string();
                    break;
                case /* string lastName */ 2:
                    message.lastName = reader.string();
                    break;
                case /* string line1 */ 3:
                    message.line1 = reader.string();
                    break;
                case /* string line2 */ 4:
                    message.line2 = reader.string();
                    break;
                case /* string line3 */ 5:
                    message.line3 = reader.string();
                    break;
                case /* string city */ 6:
                    message.city = reader.string();
                    break;
                case /* string state */ 7:
                    message.state = reader.string();
                    break;
                case /* string zip */ 8:
                    message.zip = reader.string();
                    break;
                case /* string country */ 9:
                    message.country = reader.string();
                    break;
                case /* string vat */ 10:
                    message.vat = reader.string();
                    break;
                case /* string currency */ 11:
                    message.currency = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillingAddress, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string firstName = 1; */
        if (message.firstName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.firstName);
        /* string lastName = 2; */
        if (message.lastName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.lastName);
        /* string line1 = 3; */
        if (message.line1 !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.line1);
        /* string line2 = 4; */
        if (message.line2 !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.line2);
        /* string line3 = 5; */
        if (message.line3 !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.line3);
        /* string city = 6; */
        if (message.city !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.city);
        /* string state = 7; */
        if (message.state !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.state);
        /* string zip = 8; */
        if (message.zip !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.zip);
        /* string country = 9; */
        if (message.country !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.country);
        /* string vat = 10; */
        if (message.vat !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.vat);
        /* string currency = 11; */
        if (message.currency !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.currency);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingAddress
 */
export const BillingAddress = new BillingAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntityLicenceRequest$Type extends MessageType<EntityLicenceRequest> {
    constructor() {
        super("EntityLicenceRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subscriptionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "entityId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "entityType", kind: "enum", T: () => ["EntityType", EntityType] }
        ]);
    }
    create(value?: PartialMessage<EntityLicenceRequest>): EntityLicenceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.subscriptionId = "";
        message.entityId = "";
        message.entityType = 0;
        if (value !== undefined)
            reflectionMergePartial<EntityLicenceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntityLicenceRequest): EntityLicenceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string subscriptionId */ 2:
                    message.subscriptionId = reader.string();
                    break;
                case /* string entityId */ 3:
                    message.entityId = reader.string();
                    break;
                case /* EntityType entityType */ 4:
                    message.entityType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntityLicenceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string subscriptionId = 2; */
        if (message.subscriptionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subscriptionId);
        /* string entityId = 3; */
        if (message.entityId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.entityId);
        /* EntityType entityType = 4; */
        if (message.entityType !== 0)
            writer.tag(4, WireType.Varint).int32(message.entityType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EntityLicenceRequest
 */
export const EntityLicenceRequest = new EntityLicenceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntityLicenceResponse$Type extends MessageType<EntityLicenceResponse> {
    constructor() {
        super("EntityLicenceResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["LicenseStatus", LicenseStatus] },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "entityID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "isLicensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<EntityLicenceResponse>): EntityLicenceResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.message = "";
        message.entityID = "";
        message.isLicensed = false;
        if (value !== undefined)
            reflectionMergePartial<EntityLicenceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntityLicenceResponse): EntityLicenceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* LicenseStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                case /* string entityID */ 3:
                    message.entityID = reader.string();
                    break;
                case /* bool isLicensed */ 4:
                    message.isLicensed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntityLicenceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* LicenseStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* string entityID = 3; */
        if (message.entityID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.entityID);
        /* bool isLicensed = 4; */
        if (message.isLicensed !== false)
            writer.tag(4, WireType.Varint).bool(message.isLicensed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EntityLicenceResponse
 */
export const EntityLicenceResponse = new EntityLicenceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLicenseRequest$Type extends MessageType<ListLicenseRequest> {
    constructor() {
        super("ListLicenseRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subscriptionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListLicenseRequest>): ListLicenseRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.subscriptionId = "";
        if (value !== undefined)
            reflectionMergePartial<ListLicenseRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLicenseRequest): ListLicenseRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string subscriptionId */ 2:
                    message.subscriptionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListLicenseRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string subscriptionId = 2; */
        if (message.subscriptionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subscriptionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListLicenseRequest
 */
export const ListLicenseRequest = new ListLicenseRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionCustomerRequest$Type extends MessageType<SubscriptionCustomerRequest> {
    constructor() {
        super("SubscriptionCustomerRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SubscriptionCustomerRequest>): SubscriptionCustomerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<SubscriptionCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscriptionCustomerRequest): SubscriptionCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscriptionCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscriptionCustomerRequest
 */
export const SubscriptionCustomerRequest = new SubscriptionCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LicenseResponse$Type extends MessageType<LicenseResponse> {
    constructor() {
        super("LicenseResponse", [
            { no: 1, name: "totalQuantity", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "totalLicenced", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "entityType", kind: "enum", T: () => ["EntityType", EntityType] }
        ]);
    }
    create(value?: PartialMessage<LicenseResponse>): LicenseResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalQuantity = 0;
        message.totalLicenced = 0;
        message.entityType = 0;
        if (value !== undefined)
            reflectionMergePartial<LicenseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LicenseResponse): LicenseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalQuantity */ 1:
                    message.totalQuantity = reader.int64().toNumber();
                    break;
                case /* int64 totalLicenced */ 2:
                    message.totalLicenced = reader.int64().toNumber();
                    break;
                case /* EntityType entityType */ 3:
                    message.entityType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LicenseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalQuantity = 1; */
        if (message.totalQuantity !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalQuantity);
        /* int64 totalLicenced = 2; */
        if (message.totalLicenced !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalLicenced);
        /* EntityType entityType = 3; */
        if (message.entityType !== 0)
            writer.tag(3, WireType.Varint).int32(message.entityType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LicenseResponse
 */
export const LicenseResponse = new LicenseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLicenseResponse$Type extends MessageType<ListLicenseResponse> {
    constructor() {
        super("ListLicenseResponse", [
            { no: 1, name: "response", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LicenseResponse }
        ]);
    }
    create(value?: PartialMessage<ListLicenseResponse>): ListLicenseResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.response = [];
        if (value !== undefined)
            reflectionMergePartial<ListLicenseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLicenseResponse): ListLicenseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LicenseResponse response */ 1:
                    message.response.push(LicenseResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListLicenseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated LicenseResponse response = 1; */
        for (let i = 0; i < message.response.length; i++)
            LicenseResponse.internalBinaryWrite(message.response[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListLicenseResponse
 */
export const ListLicenseResponse = new ListLicenseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Invoices$Type extends MessageType<Invoices> {
    constructor() {
        super("Invoices", [
            { no: 1, name: "invoiceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "planName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chargeAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Invoices>): Invoices {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.invoiceID = "";
        message.planName = "";
        message.chargeAmount = "";
        message.currency = "";
        message.date = "";
        if (value !== undefined)
            reflectionMergePartial<Invoices>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Invoices): Invoices {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string invoiceID */ 1:
                    message.invoiceID = reader.string();
                    break;
                case /* string planName */ 2:
                    message.planName = reader.string();
                    break;
                case /* string chargeAmount */ 3:
                    message.chargeAmount = reader.string();
                    break;
                case /* string currency */ 4:
                    message.currency = reader.string();
                    break;
                case /* string date */ 5:
                    message.date = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Invoices, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string invoiceID = 1; */
        if (message.invoiceID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.invoiceID);
        /* string planName = 2; */
        if (message.planName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.planName);
        /* string chargeAmount = 3; */
        if (message.chargeAmount !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.chargeAmount);
        /* string currency = 4; */
        if (message.currency !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.currency);
        /* string date = 5; */
        if (message.date !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.date);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Invoices
 */
export const Invoices = new Invoices$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingStat$Type extends MessageType<BillingStat> {
    constructor() {
        super("BillingStat", [
            { no: 1, name: "upcomingCharge", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "chargeAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "invoices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Invoices }
        ]);
    }
    create(value?: PartialMessage<BillingStat>): BillingStat {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.upcomingCharge = "";
        message.chargeAmount = "";
        message.currency = "";
        message.invoices = [];
        if (value !== undefined)
            reflectionMergePartial<BillingStat>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillingStat): BillingStat {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string upcomingCharge */ 1:
                    message.upcomingCharge = reader.string();
                    break;
                case /* string chargeAmount */ 2:
                    message.chargeAmount = reader.string();
                    break;
                case /* string currency */ 3:
                    message.currency = reader.string();
                    break;
                case /* repeated Invoices invoices */ 4:
                    message.invoices.push(Invoices.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillingStat, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string upcomingCharge = 1; */
        if (message.upcomingCharge !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.upcomingCharge);
        /* string chargeAmount = 2; */
        if (message.chargeAmount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.chargeAmount);
        /* string currency = 3; */
        if (message.currency !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.currency);
        /* repeated Invoices invoices = 4; */
        for (let i = 0; i < message.invoices.length; i++)
            Invoices.internalBinaryWrite(message.invoices[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillingStat
 */
export const BillingStat = new BillingStat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionStats$Type extends MessageType<SubscriptionStats> {
    constructor() {
        super("SubscriptionStats", [
            { no: 1, name: "planName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "planPrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "planCurrency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "roomNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "deskNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "licensedRoomNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "licensedDeskNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "userNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "licensedUserNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "wayfinderNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "licensedWayfinderNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SubscriptionStats>): SubscriptionStats {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.planName = "";
        message.planPrice = "";
        message.planCurrency = "";
        message.roomNumber = 0;
        message.deskNumber = 0;
        message.licensedRoomNumber = 0;
        message.licensedDeskNumber = 0;
        message.status = "";
        message.userNumber = 0;
        message.licensedUserNumber = 0;
        message.wayfinderNumber = 0;
        message.licensedWayfinderNumber = 0;
        if (value !== undefined)
            reflectionMergePartial<SubscriptionStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscriptionStats): SubscriptionStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string planName */ 1:
                    message.planName = reader.string();
                    break;
                case /* string planPrice */ 2:
                    message.planPrice = reader.string();
                    break;
                case /* string planCurrency */ 3:
                    message.planCurrency = reader.string();
                    break;
                case /* int32 roomNumber */ 4:
                    message.roomNumber = reader.int32();
                    break;
                case /* int32 deskNumber */ 5:
                    message.deskNumber = reader.int32();
                    break;
                case /* int32 licensedRoomNumber */ 6:
                    message.licensedRoomNumber = reader.int32();
                    break;
                case /* int32 licensedDeskNumber */ 7:
                    message.licensedDeskNumber = reader.int32();
                    break;
                case /* string status */ 8:
                    message.status = reader.string();
                    break;
                case /* int32 userNumber */ 9:
                    message.userNumber = reader.int32();
                    break;
                case /* int32 licensedUserNumber */ 10:
                    message.licensedUserNumber = reader.int32();
                    break;
                case /* int32 wayfinderNumber */ 11:
                    message.wayfinderNumber = reader.int32();
                    break;
                case /* int32 licensedWayfinderNumber */ 12:
                    message.licensedWayfinderNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscriptionStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string planName = 1; */
        if (message.planName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.planName);
        /* string planPrice = 2; */
        if (message.planPrice !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.planPrice);
        /* string planCurrency = 3; */
        if (message.planCurrency !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.planCurrency);
        /* int32 roomNumber = 4; */
        if (message.roomNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.roomNumber);
        /* int32 deskNumber = 5; */
        if (message.deskNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.deskNumber);
        /* int32 licensedRoomNumber = 6; */
        if (message.licensedRoomNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.licensedRoomNumber);
        /* int32 licensedDeskNumber = 7; */
        if (message.licensedDeskNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.licensedDeskNumber);
        /* string status = 8; */
        if (message.status !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.status);
        /* int32 userNumber = 9; */
        if (message.userNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.userNumber);
        /* int32 licensedUserNumber = 10; */
        if (message.licensedUserNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.licensedUserNumber);
        /* int32 wayfinderNumber = 11; */
        if (message.wayfinderNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.wayfinderNumber);
        /* int32 licensedWayfinderNumber = 12; */
        if (message.licensedWayfinderNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.licensedWayfinderNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscriptionStats
 */
export const SubscriptionStats = new SubscriptionStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionInfoResponse$Type extends MessageType<SubscriptionInfoResponse> {
    constructor() {
        super("SubscriptionInfoResponse", [
            { no: 1, name: "subscription", kind: "message", T: () => SubscriptionStats },
            { no: 2, name: "billing", kind: "message", T: () => BillingStat }
        ]);
    }
    create(value?: PartialMessage<SubscriptionInfoResponse>): SubscriptionInfoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SubscriptionInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscriptionInfoResponse): SubscriptionInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SubscriptionStats subscription */ 1:
                    message.subscription = SubscriptionStats.internalBinaryRead(reader, reader.uint32(), options, message.subscription);
                    break;
                case /* BillingStat billing */ 2:
                    message.billing = BillingStat.internalBinaryRead(reader, reader.uint32(), options, message.billing);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscriptionInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* SubscriptionStats subscription = 1; */
        if (message.subscription)
            SubscriptionStats.internalBinaryWrite(message.subscription, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BillingStat billing = 2; */
        if (message.billing)
            BillingStat.internalBinaryWrite(message.billing, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscriptionInfoResponse
 */
export const SubscriptionInfoResponse = new SubscriptionInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LicenseFreeSlotsRequest$Type extends MessageType<LicenseFreeSlotsRequest> {
    constructor() {
        super("LicenseFreeSlotsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subscriptionID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["EntityType", EntityType] }
        ]);
    }
    create(value?: PartialMessage<LicenseFreeSlotsRequest>): LicenseFreeSlotsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.subscriptionID = "";
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<LicenseFreeSlotsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LicenseFreeSlotsRequest): LicenseFreeSlotsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string subscriptionID */ 2:
                    message.subscriptionID = reader.string();
                    break;
                case /* EntityType type */ 3:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LicenseFreeSlotsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string subscriptionID = 2; */
        if (message.subscriptionID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subscriptionID);
        /* EntityType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LicenseFreeSlotsRequest
 */
export const LicenseFreeSlotsRequest = new LicenseFreeSlotsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LicenseFreeSlotsResponse$Type extends MessageType<LicenseFreeSlotsResponse> {
    constructor() {
        super("LicenseFreeSlotsResponse", [
            { no: 1, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LicenseFreeSlotsResponse>): LicenseFreeSlotsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.quantity = 0;
        if (value !== undefined)
            reflectionMergePartial<LicenseFreeSlotsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LicenseFreeSlotsResponse): LicenseFreeSlotsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 quantity */ 1:
                    message.quantity = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LicenseFreeSlotsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 quantity = 1; */
        if (message.quantity !== 0)
            writer.tag(1, WireType.Varint).int32(message.quantity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LicenseFreeSlotsResponse
 */
export const LicenseFreeSlotsResponse = new LicenseFreeSlotsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateChargebeeCustomerRequest$Type extends MessageType<UpdateChargebeeCustomerRequest> {
    constructor() {
        super("UpdateChargebeeCustomerRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateChargebeeCustomerRequest>): UpdateChargebeeCustomerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateChargebeeCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateChargebeeCustomerRequest): UpdateChargebeeCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateChargebeeCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateChargebeeCustomerRequest
 */
export const UpdateChargebeeCustomerRequest = new UpdateChargebeeCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePaymentCardRequest$Type extends MessageType<CreatePaymentCardRequest> {
    constructor() {
        super("CreatePaymentCardRequest", [
            { no: 1, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cvv", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "expiry_year", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "expiry_month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreatePaymentCardRequest>): CreatePaymentCardRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = "";
        message.cvv = "";
        message.expiryYear = 0;
        message.expiryMonth = 0;
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<CreatePaymentCardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePaymentCardRequest): CreatePaymentCardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string number */ 1:
                    message.number = reader.string();
                    break;
                case /* string cvv */ 2:
                    message.cvv = reader.string();
                    break;
                case /* int32 expiry_year */ 3:
                    message.expiryYear = reader.int32();
                    break;
                case /* int32 expiry_month */ 4:
                    message.expiryMonth = reader.int32();
                    break;
                case /* string customer_id */ 5:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePaymentCardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string number = 1; */
        if (message.number !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.number);
        /* string cvv = 2; */
        if (message.cvv !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cvv);
        /* int32 expiry_year = 3; */
        if (message.expiryYear !== 0)
            writer.tag(3, WireType.Varint).int32(message.expiryYear);
        /* int32 expiry_month = 4; */
        if (message.expiryMonth !== 0)
            writer.tag(4, WireType.Varint).int32(message.expiryMonth);
        /* string customer_id = 5; */
        if (message.customerId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreatePaymentCardRequest
 */
export const CreatePaymentCardRequest = new CreatePaymentCardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePaymentCardRequest$Type extends MessageType<UpdatePaymentCardRequest> {
    constructor() {
        super("UpdatePaymentCardRequest", [
            { no: 1, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cvv", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "expiry_year", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "expiry_month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdatePaymentCardRequest>): UpdatePaymentCardRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = "";
        message.cvv = "";
        message.expiryYear = 0;
        message.expiryMonth = 0;
        message.customerId = "";
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<UpdatePaymentCardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePaymentCardRequest): UpdatePaymentCardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string number */ 1:
                    message.number = reader.string();
                    break;
                case /* string cvv */ 2:
                    message.cvv = reader.string();
                    break;
                case /* int32 expiry_year */ 3:
                    message.expiryYear = reader.int32();
                    break;
                case /* int32 expiry_month */ 4:
                    message.expiryMonth = reader.int32();
                    break;
                case /* string customer_id */ 5:
                    message.customerId = reader.string();
                    break;
                case /* string id */ 6:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePaymentCardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string number = 1; */
        if (message.number !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.number);
        /* string cvv = 2; */
        if (message.cvv !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cvv);
        /* int32 expiry_year = 3; */
        if (message.expiryYear !== 0)
            writer.tag(3, WireType.Varint).int32(message.expiryYear);
        /* int32 expiry_month = 4; */
        if (message.expiryMonth !== 0)
            writer.tag(4, WireType.Varint).int32(message.expiryMonth);
        /* string customer_id = 5; */
        if (message.customerId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerId);
        /* string id = 6; */
        if (message.id !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdatePaymentCardRequest
 */
export const UpdatePaymentCardRequest = new UpdatePaymentCardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaymentCardRequest$Type extends MessageType<GetPaymentCardRequest> {
    constructor() {
        super("GetPaymentCardRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPaymentCardRequest>): GetPaymentCardRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<GetPaymentCardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaymentCardRequest): GetPaymentCardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaymentCardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetPaymentCardRequest
 */
export const GetPaymentCardRequest = new GetPaymentCardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaymentCardResponse$Type extends MessageType<PaymentCardResponse> {
    constructor() {
        super("PaymentCardResponse", [
            { no: 1, name: "masked_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expiry_year", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "expiry_month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PaymentCardResponse>): PaymentCardResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.maskedNumber = "";
        message.expiryYear = 0;
        message.expiryMonth = 0;
        message.customerId = "";
        message.brand = "";
        if (value !== undefined)
            reflectionMergePartial<PaymentCardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaymentCardResponse): PaymentCardResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string masked_number */ 1:
                    message.maskedNumber = reader.string();
                    break;
                case /* int32 expiry_year */ 2:
                    message.expiryYear = reader.int32();
                    break;
                case /* int32 expiry_month */ 3:
                    message.expiryMonth = reader.int32();
                    break;
                case /* string customer_id */ 4:
                    message.customerId = reader.string();
                    break;
                case /* string brand */ 5:
                    message.brand = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaymentCardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string masked_number = 1; */
        if (message.maskedNumber !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.maskedNumber);
        /* int32 expiry_year = 2; */
        if (message.expiryYear !== 0)
            writer.tag(2, WireType.Varint).int32(message.expiryYear);
        /* int32 expiry_month = 3; */
        if (message.expiryMonth !== 0)
            writer.tag(3, WireType.Varint).int32(message.expiryMonth);
        /* string customer_id = 4; */
        if (message.customerId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerId);
        /* string brand = 5; */
        if (message.brand !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.brand);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PaymentCardResponse
 */
export const PaymentCardResponse = new PaymentCardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateChannelPartnerRequest$Type extends MessageType<CreateChannelPartnerRequest> {
    constructor() {
        super("CreateChannelPartnerRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateChannelPartnerRequest>): CreateChannelPartnerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.partnerEmail = "";
        if (value !== undefined)
            reflectionMergePartial<CreateChannelPartnerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateChannelPartnerRequest): CreateChannelPartnerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string partner_email */ 2:
                    message.partnerEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateChannelPartnerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string partner_email = 2; */
        if (message.partnerEmail !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateChannelPartnerRequest
 */
export const CreateChannelPartnerRequest = new CreateChannelPartnerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChannelPartnerRequest$Type extends MessageType<GetChannelPartnerRequest> {
    constructor() {
        super("GetChannelPartnerRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetChannelPartnerRequest>): GetChannelPartnerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<GetChannelPartnerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChannelPartnerRequest): GetChannelPartnerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChannelPartnerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetChannelPartnerRequest
 */
export const GetChannelPartnerRequest = new GetChannelPartnerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelinkChannelPartnerRequest$Type extends MessageType<DelinkChannelPartnerRequest> {
    constructor() {
        super("DelinkChannelPartnerRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DelinkChannelPartnerRequest>): DelinkChannelPartnerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<DelinkChannelPartnerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelinkChannelPartnerRequest): DelinkChannelPartnerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelinkChannelPartnerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DelinkChannelPartnerRequest
 */
export const DelinkChannelPartnerRequest = new DelinkChannelPartnerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSubscriptionCustomerRequest$Type extends MessageType<GetSubscriptionCustomerRequest> {
    constructor() {
        super("GetSubscriptionCustomerRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSubscriptionCustomerRequest>): GetSubscriptionCustomerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<GetSubscriptionCustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSubscriptionCustomerRequest): GetSubscriptionCustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSubscriptionCustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetSubscriptionCustomerRequest
 */
export const GetSubscriptionCustomerRequest = new GetSubscriptionCustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateHostedPageRequest$Type extends MessageType<CreateHostedPageRequest> {
    constructor() {
        super("CreateHostedPageRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "itemPriceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "redirectURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateHostedPageRequest>): CreateHostedPageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.itemPriceID = "";
        message.redirectURL = "";
        if (value !== undefined)
            reflectionMergePartial<CreateHostedPageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateHostedPageRequest): CreateHostedPageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string itemPriceID */ 2:
                    message.itemPriceID = reader.string();
                    break;
                case /* string redirectURL */ 3:
                    message.redirectURL = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateHostedPageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string itemPriceID = 2; */
        if (message.itemPriceID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.itemPriceID);
        /* string redirectURL = 3; */
        if (message.redirectURL !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.redirectURL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateHostedPageRequest
 */
export const CreateHostedPageRequest = new CreateHostedPageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomerSubscriptionRequest$Type extends MessageType<ListCustomerSubscriptionRequest> {
    constructor() {
        super("ListCustomerSubscriptionRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListCustomerSubscriptionRequest>): ListCustomerSubscriptionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<ListCustomerSubscriptionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomerSubscriptionRequest): ListCustomerSubscriptionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomerSubscriptionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListCustomerSubscriptionRequest
 */
export const ListCustomerSubscriptionRequest = new ListCustomerSubscriptionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomerSubscriptionResponse$Type extends MessageType<ListCustomerSubscriptionResponse> {
    constructor() {
        super("ListCustomerSubscriptionResponse", [
            { no: 1, name: "subscriptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Subscription }
        ]);
    }
    create(value?: PartialMessage<ListCustomerSubscriptionResponse>): ListCustomerSubscriptionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subscriptions = [];
        if (value !== undefined)
            reflectionMergePartial<ListCustomerSubscriptionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomerSubscriptionResponse): ListCustomerSubscriptionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Subscription subscriptions */ 1:
                    message.subscriptions.push(Subscription.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomerSubscriptionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Subscription subscriptions = 1; */
        for (let i = 0; i < message.subscriptions.length; i++)
            Subscription.internalBinaryWrite(message.subscriptions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListCustomerSubscriptionResponse
 */
export const ListCustomerSubscriptionResponse = new ListCustomerSubscriptionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HostedPage$Type extends MessageType<HostedPage> {
    constructor() {
        super("HostedPage", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "failureReason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "passThruContent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "embed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "expiresAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "content", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 11, name: "updatedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "resourceVersion", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "checkoutInfo", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "object", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HostedPage>): HostedPage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.type = "";
        message.url = "";
        message.state = "";
        message.failureReason = "";
        message.passThruContent = "";
        message.embed = false;
        message.createdAt = 0;
        message.expiresAt = 0;
        message.content = new Uint8Array(0);
        message.updatedAt = 0;
        message.resourceVersion = 0;
        message.checkoutInfo = new Uint8Array(0);
        message.object = "";
        if (value !== undefined)
            reflectionMergePartial<HostedPage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HostedPage): HostedPage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* string state */ 4:
                    message.state = reader.string();
                    break;
                case /* string failureReason */ 5:
                    message.failureReason = reader.string();
                    break;
                case /* string passThruContent */ 6:
                    message.passThruContent = reader.string();
                    break;
                case /* bool embed */ 7:
                    message.embed = reader.bool();
                    break;
                case /* int64 createdAt */ 8:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 expiresAt */ 9:
                    message.expiresAt = reader.int64().toNumber();
                    break;
                case /* bytes content */ 10:
                    message.content = reader.bytes();
                    break;
                case /* int64 updatedAt */ 11:
                    message.updatedAt = reader.int64().toNumber();
                    break;
                case /* int64 resourceVersion */ 12:
                    message.resourceVersion = reader.int64().toNumber();
                    break;
                case /* bytes checkoutInfo */ 13:
                    message.checkoutInfo = reader.bytes();
                    break;
                case /* string object */ 14:
                    message.object = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HostedPage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* string state = 4; */
        if (message.state !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.state);
        /* string failureReason = 5; */
        if (message.failureReason !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.failureReason);
        /* string passThruContent = 6; */
        if (message.passThruContent !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.passThruContent);
        /* bool embed = 7; */
        if (message.embed !== false)
            writer.tag(7, WireType.Varint).bool(message.embed);
        /* int64 createdAt = 8; */
        if (message.createdAt !== 0)
            writer.tag(8, WireType.Varint).int64(message.createdAt);
        /* int64 expiresAt = 9; */
        if (message.expiresAt !== 0)
            writer.tag(9, WireType.Varint).int64(message.expiresAt);
        /* bytes content = 10; */
        if (message.content.length)
            writer.tag(10, WireType.LengthDelimited).bytes(message.content);
        /* int64 updatedAt = 11; */
        if (message.updatedAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.updatedAt);
        /* int64 resourceVersion = 12; */
        if (message.resourceVersion !== 0)
            writer.tag(12, WireType.Varint).int64(message.resourceVersion);
        /* bytes checkoutInfo = 13; */
        if (message.checkoutInfo.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.checkoutInfo);
        /* string object = 14; */
        if (message.object !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.object);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message HostedPage
 */
export const HostedPage = new HostedPage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkLicensingRequest$Type extends MessageType<BulkLicensingRequest> {
    constructor() {
        super("BulkLicensingRequest", [
            { no: 1, name: "entities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EntityLicenceRequest }
        ]);
    }
    create(value?: PartialMessage<BulkLicensingRequest>): BulkLicensingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entities = [];
        if (value !== undefined)
            reflectionMergePartial<BulkLicensingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkLicensingRequest): BulkLicensingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EntityLicenceRequest entities */ 1:
                    message.entities.push(EntityLicenceRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkLicensingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated EntityLicenceRequest entities = 1; */
        for (let i = 0; i < message.entities.length; i++)
            EntityLicenceRequest.internalBinaryWrite(message.entities[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BulkLicensingRequest
 */
export const BulkLicensingRequest = new BulkLicensingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BulkLicensingResponse$Type extends MessageType<BulkLicensingResponse> {
    constructor() {
        super("BulkLicensingResponse", [
            { no: 1, name: "status", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EntityLicenceResponse }
        ]);
    }
    create(value?: PartialMessage<BulkLicensingResponse>): BulkLicensingResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = [];
        if (value !== undefined)
            reflectionMergePartial<BulkLicensingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BulkLicensingResponse): BulkLicensingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated EntityLicenceResponse status */ 1:
                    message.status.push(EntityLicenceResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BulkLicensingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated EntityLicenceResponse status = 1; */
        for (let i = 0; i < message.status.length; i++)
            EntityLicenceResponse.internalBinaryWrite(message.status[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BulkLicensingResponse
 */
export const BulkLicensingResponse = new BulkLicensingResponse$Type();
/**
 * @generated ServiceType for protobuf service SubscriptionService
 */
export const SubscriptionService = new ServiceType("SubscriptionService", [
    { name: "CreateSubscription", options: { "google.api.http": { post: "/v1/customers/{customerId}/subscriptions", body: "subscription" }, "google.api.method_signature": ["customer_id"] }, I: CreateSubscriptionRequest, O: Subscription },
    { name: "GetSubscription", options: { "google.api.http": { get: "/v1/customers/{customerId}/subscriptions/{subscriptionId}" }, "google.api.method_signature": ["customer_id,subscription_id"] }, I: GetSubscriptionRequest, O: Subscription },
    { name: "GetChargebeeSubscription", options: { "google.api.http": { get: "/v1/customers/{customerId}/subscriptions/{subscriptionId}/chargebee" }, "google.api.method_signature": ["customer_id,subscription_id"] }, I: GetSubscriptionRequest, O: Subscription },
    { name: "GetCustomerSubscription", options: { "google.api.http": { get: "/v1/customers/{customerId}/subscription" }, "google.api.method_signature": ["customer_id"] }, I: GetCustomerSubscriptionRequest, O: Subscription },
    { name: "UpdateChargebeeCustomer", options: { "google.api.http": { put: "/v1/customers/{customerId}/update" }, "google.api.method_signature": ["customer_id"] }, I: UpdateChargebeeCustomerRequest, O: CBCustomer },
    { name: "ListSubscriptions", options: { "google.api.http": { get: "/v1/customers/{customerId}/subscriptions" }, "google.api.method_signature": ["customer_id"] }, I: ListSubscriptionsRequest, O: ListSubscriptionsResponse },
    { name: "UpdateSubscription", options: { "google.api.http": { patch: "/v1/customers/{customerId}/subscriptions", body: "*" }, "google.api.method_signature": ["customer_id,subscription,update_mask"] }, I: UpdateSubscriptionRequest, O: Subscription },
    { name: "DeleteSubscription", options: { "google.api.http": { delete: "/v1/customers/{customerId}/subscriptions/{subscriptionId}" } }, I: DeleteSubscriptionRequest, O: Empty },
    { name: "DeleteCustomer", options: {}, I: DeleteCBCustomerRequest, O: Empty },
    { name: "CreateTrial", options: { "google.api.http": { post: "/v1/subscriptions/trial", body: "*" } }, I: TrialRequest, O: Subscription },
    { name: "CheckSubscriptionExists", options: { "google.api.http": { get: "/v1/subscriptions" } }, I: CheckSubscriptionRequest, O: CheckSubscriptionResponse },
    { name: "LicenceEntity", options: { "google.api.http": { post: "/v1/subscriptions/entities" } }, I: EntityLicenceRequest, O: EntityLicenceResponse },
    { name: "UnlicenceEntity", options: { "google.api.http": { patch: "/v1/subscriptions/entities" } }, I: EntityLicenceRequest, O: EntityLicenceResponse },
    { name: "LicencesList", options: { "google.api.http": { get: "/v1/subscriptions/licence" } }, I: ListLicenseRequest, O: ListLicenseResponse },
    { name: "GetSubscriptionStats", options: { "google.api.http": { get: "/v1/subscriptions/stats" } }, I: CheckSubscriptionRequest, O: SubscriptionInfoResponse },
    { name: "GetInvoicePDF", options: { "google.api.http": { get: "/v1/subscriptions/invoice" } }, I: InvoiceRequest, O: InvoicePDFResponse },
    { name: "LicenseFreeSlots", options: { "google.api.http": { get: "/v1/subscriptions/slots" } }, I: LicenseFreeSlotsRequest, O: LicenseFreeSlotsResponse },
    { name: "BulkLicensing", options: { "google.api.http": { post: "/v1/subscriptions/licensing", body: "*" } }, I: BulkLicensingRequest, O: BulkLicensingResponse },
    { name: "CreatePaymentCard", options: { "google.api.http": { post: "/v1/subscriptions/payment" } }, I: CreatePaymentCardRequest, O: PaymentCardResponse },
    { name: "UpdatePaymentCard", options: { "google.api.http": { patch: "/v1/subscriptions/payment" } }, I: CreatePaymentCardRequest, O: PaymentCardResponse },
    { name: "GetPaymentCard", options: { "google.api.http": { get: "/v1/subscriptions/customer/payment" } }, I: GetPaymentCardRequest, O: PaymentCardResponse },
    { name: "CreateChannelPartner", options: { "google.api.http": { post: "/v1/subscriptions/customer/partner" } }, I: CreateChannelPartnerRequest, O: CBCustomer },
    { name: "GetChannelPartner", options: { "google.api.http": { get: "/v1/subscriptions/customer/partner" } }, I: GetChannelPartnerRequest, O: CBCustomer },
    { name: "DelinkChannelPartner", options: { "google.api.http": { delete: "/v1/subscriptions/customer/partner" } }, I: DelinkChannelPartnerRequest, O: Empty },
    { name: "GetSubscriptionCustomer", options: { "google.api.http": { get: "/v1/subscriptions/customer/{customerID}" }, "google.api.method_signature": ["customerID"] }, I: GetSubscriptionCustomerRequest, O: CBCustomer },
    { name: "ListCustomerSubscription", options: { "google.api.http": { get: "/v1/subscriptions/customer/{customerID}/subscriptions" }, "google.api.method_signature": ["customerID"] }, I: ListCustomerSubscriptionRequest, O: ListCustomerSubscriptionResponse },
    { name: "SyncChargebeeSubscriptions", options: {}, I: Empty, O: Empty }
]);
/**
 * @generated ServiceType for protobuf service ChargeBeeHostedService
 */
export const ChargeBeeHostedService = new ServiceType("ChargeBeeHostedService", [
    { name: "CreatePortalSession", options: { "google.api.http": { post: "/v1/subscriptions/portal_sessions", body: "*" } }, I: PortalSessionRequest, O: PortalSession },
    { name: "CreateHostedPage", options: { "google.api.http": { post: "/v1/subscriptions/hosted_page", body: "*" } }, I: CreateHostedPageRequest, O: HostedPage }
]);
