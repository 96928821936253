import React from "react";

import { useDeskUtilizationInsightsContext } from "../../../Context/Desk/Utilization/UtilizationContext";

import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { ChartCard } from "../../../shared/ChartCard/ChartCard";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { UtilizationDensityHeatMap } from "../../../Room/Utilization/UtilizationDensity/UtilizationDensityHeatMap/UtilizationDensityHeatMap";
import { tooltips } from "../../../../../lib/utils/tooltips";

export const DeskUtilizationDensity = () => {
  const {
    loadingHeatmap: loading,
    errorHeatmap: error,
    dataHeatmap: data,
  } = useDeskUtilizationInsightsContext();

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<LoadingBox minHeight={400} className="mt-4" />}
    >
      <ChartCard
        title="Desk Reservation Density by Time/Day"
        className="mt-4 UtilizationDensity"
        message={tooltips.insights.desks.deskReservationDensity}
        direction="right"
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>{error?.message}</CenteredBox>}
        >
          {!!data?.data.length ? (
            <UtilizationDensityHeatMap data={data.data} />
          ) : (
            <CenteredBox>Empty data</CenteredBox>
          )}
        </HandleRequestState>
      </ChartCard>
    </HandleLoadingState>
  );
};
