import React from "react";
import { WorkingHoursForm } from "./WorkingHoursForm";
import { CustomerSettings } from "../../../../../api/grpc/customer/customer";
import { CustomerSettingsUpdate } from "../../../../../api/grpc/customer/useCustomerRequests";

import "./WorkingHours.scss";

interface Props {
  handleStartHours: (value: string | Date) => void;
  handleEndHours: (value: string | Date) => void;
  startHours: string | Date;
  endHours: string | Date;
  onChange?: () => void;
  isGlobal?: boolean;
  customerSettings?: CustomerSettings;
  updateCustomerSettings: ({
    startTime,
    endTime,
    errorReportingEmail,
    meetingSpaceCostPerHr,
    currency,
    workingWeekDays,
    collectInsights,
    ssoEnable,
    disableDeskSimultaneous,
    showEventOrganizer,
  }: CustomerSettingsUpdate) => Promise<any>;
  loading: boolean;
  refetch: () => void;
  id?: string;
  disabledWorkingHours?: boolean;
  isProfile?: boolean;
}

export const WorkingHours = ({
  handleEndHours,
  handleStartHours,
  startHours,
  endHours,
  onChange,
  isGlobal,
  customerSettings,
  updateCustomerSettings,
  loading,
  refetch,
  id,
  disabledWorkingHours,
  isProfile,
}: Props) => {
  return (
    <div className="WorkingHours">
      <WorkingHoursForm
        handleEndHours={handleEndHours}
        handleStartHours={handleStartHours}
        startHours={startHours}
        endHours={endHours}
        onChange={onChange}
        isGlobal={isGlobal}
        customerSettings={customerSettings}
        updateCustomerSettings={updateCustomerSettings}
        loading={loading}
        refetch={refetch}
        id={id}
        disabledWorkingHours={disabledWorkingHours}
        isProfile={isProfile}
      />
    </div>
  );
};
