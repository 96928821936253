import React from "react";
import { InvitePeopleButton } from "../../InvitePeople/InvitePeopleButton/InvitePeopleButton";

import "./EmptyContacts.scss";

export const EmptyContacts = () => {
  return (
    <div className="EmptyContacts">
      <h4>It seems that there isn’t any user invited!</h4>
      <InvitePeopleButton noMargin />
    </div>
  );
};
