export type Currencies = "USD" | "EUR" | "SEK" | "GBP";

export type CurrencySigns = "$" | "€" | "kr" | "£";

export const CurrenciesSymbols: { [key in Currencies]: CurrencySigns } = {
  USD: "$",
  EUR: "€",
  SEK: "kr",
  GBP: "£",
};
