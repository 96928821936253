import { useState } from "react";
import { LoginMethod } from "../../../../../api/grpc/account/account";
import { CalendarType } from "../../../../../api/grpc/calendarprovider/calendarprovider";
import { useRouter } from "../../../../../lib/hooks/useRouter";
import { getCalendarTypeSlug } from "../../../../Calendars/lib/helpers";

export type LoginMethodObject = {
  value: LoginMethod;
  label: string;
};

export const definedLoginValue: { [key in LoginMethod]: string } = {
  "0": "All",
  "1": "Email + Password",
  "2": "SSO - Google",
  "3": "SSO - Microsoft 365",
};

const basicOptions: LoginMethodObject = {
  value: LoginMethod.EMAIL,
  label: definedLoginValue[LoginMethod.EMAIL],
};

const googleMethod: LoginMethodObject = {
  value: LoginMethod.GSUITE,
  label: definedLoginValue[LoginMethod.GSUITE],
};

const microsoftMethod: LoginMethodObject = {
  value: LoginMethod.O365,
  label: definedLoginValue[LoginMethod.O365],
};

export const useSelectLoginMethod = () => {
  const { query } = useRouter();
  const isGoogleIntegration =
    query.directoryType === getCalendarTypeSlug(CalendarType.GSUITE_SYNC);

  const [loginMethod, setLoginMethod] = useState<LoginMethodObject>(() =>
    isGoogleIntegration ? googleMethod : microsoftMethod
  );

  return {
    basicOptions,
    googleMethod,
    microsoftMethod,
    loginMethod,
    setLoginMethod,
  };
};
