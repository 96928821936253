import {
  ProducerAction,
  ProducerActionService,
} from "@gogetcorp/floor-map-bridge";

export const sendMessageToRn = (message: ProducerAction.Any) => {
  const producerActionService = new ProducerActionService();

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      producerActionService.create(message)
    );
  }
};
