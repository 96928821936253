import React from "react";
import {
  OnLevelSearchRequestLicenseStatus,
  WorkplaceLocationType,
  WorkplaceResourceType,
} from "../../../../api/grpc/workplaces/workplaces";
import {
  PossibleWorkplaceFilters,
  WorkplaceLocation,
} from "../../../../lib/types/main.types";
import { Button } from "../../../shared/Button/Button";
import { CheckboxButton } from "../../../shared/CheckboxButton/CheckboxButton";
import { ResourceFilterHeader } from "../../../shared/ResourceFilter/ResourceFilterHeader";
import { TagPicker } from "../../../shared/TagPicker/TagPicker";
import { definedWorkplaceFilters } from "../../lib/definedWorkplacesFilters";

export interface IWorkplacesFilters {
  license: OnLevelSearchRequestLicenseStatus;
  location: WorkplaceLocationType[];
  resources: WorkplaceResourceType[];
}

interface Props {
  id: string;
  workplacesFilters: IWorkplacesFilters;
  setWorkplacesFilters: React.Dispatch<
    React.SetStateAction<IWorkplacesFilters>
  >;
  activeTagsFilter: string[];
  setActiveTagsFilter: React.Dispatch<React.SetStateAction<string[]>>;
  type?: WorkplaceLocation;
  disableClearButton: boolean;
}

const definedName: {
  [key in PossibleWorkplaceFilters]: string;
} = {
  license: "License",
  location: "Location",
  resources: "Resources",
};

export const WorkplacesFilters = ({
  id,
  workplacesFilters,
  activeTagsFilter,
  setActiveTagsFilter,
  setWorkplacesFilters,
  type,
  disableClearButton,
}: Props) => {
  let allWorkplaceFilters = definedWorkplaceFilters(type);

  const handleFilterChange = (
    filterName: PossibleWorkplaceFilters,
    selectedValue: number
  ) => {
    setWorkplacesFilters((prevFilters) => {
      let updatedFilters: IWorkplacesFilters = { ...prevFilters };

      if (filterName === "license") {
        updatedFilters[filterName] =
          updatedFilters[filterName] === selectedValue
            ? OnLevelSearchRequestLicenseStatus.OnLevelSearchRequestLicenseStatusNone
            : selectedValue;
      } else if (filterName === "location") {
        const locationItem = selectedValue as WorkplaceLocationType;
        const index = updatedFilters.location?.indexOf(locationItem);

        if (index === -1) {
          updatedFilters.location = [
            ...(updatedFilters.location || []),
            locationItem,
          ];
        } else {
          updatedFilters.location = updatedFilters.location?.filter(
            (item) => item !== locationItem
          );
        }
      } else if (filterName === "resources") {
        const resourceItem = selectedValue as WorkplaceResourceType;
        const index = updatedFilters.resources?.indexOf(resourceItem);

        if (index === -1) {
          updatedFilters.resources = [
            ...(updatedFilters.resources || []),
            resourceItem,
          ];
        } else {
          updatedFilters.resources = updatedFilters.resources?.filter(
            (item) => item !== resourceItem
          );
        }
      }

      return updatedFilters;
    });
  };

  const defineCheckedValues = (
    name: PossibleWorkplaceFilters,
    value: number
  ): boolean => {
    if (name === "license") {
      return workplacesFilters[name] === value;
    }

    if (name === "location") {
      return (
        workplacesFilters.location?.includes(value as WorkplaceLocationType) ??
        false
      );
    }

    if (name === "resources") {
      return (
        workplacesFilters.resources?.includes(value as WorkplaceResourceType) ??
        false
      );
    }

    return false;
  };

  return (
    <div className="child-element">
      <div className="main-flex mb-2 FilterHeader">
        <ResourceFilterHeader />

        <div className="flex-a-center gap-1">
          <TagPicker
            id={id}
            activeTagsFilter={activeTagsFilter}
            setActiveTagsFilter={setActiveTagsFilter}
          />

          <Button
            title="Clear all"
            color="outline-secondary"
            className="child-element--clear"
            disabled={disableClearButton}
            onClick={() => {
              setWorkplacesFilters({
                license:
                  OnLevelSearchRequestLicenseStatus.OnLevelSearchRequestLicenseStatusNone,
                location: [],
                resources: [],
              });

              setActiveTagsFilter([]);

              return;
            }}
          />
        </div>
      </div>

      <div className="d-flex gap-3 FilterContent">
        {allWorkplaceFilters.map((filterItem, index) => {
          return (
            <div key={index}>
              <span className="child-element--head text-nowrap flex">
                {definedName[filterItem.name as PossibleWorkplaceFilters]}
              </span>

              {filterItem.options.map((item, index) => (
                <CheckboxButton
                  key={index}
                  id={item.name}
                  name={item.title}
                  checked={defineCheckedValues(filterItem.name, item.value)}
                  onChange={() =>
                    handleFilterChange(filterItem.name, item.value)
                  }
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
