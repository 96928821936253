import { useMemo } from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";
import {
  CreateDeskBookingRequest,
  DeleteDeskBookingRequest,
  ListDesksBookingRequest,
  MatchingDeskRequest,
  PersonBookingRequest,
  UpdateDeskBookingRequest,
} from "./desk";
import { DeskBookingClient } from "./desk.client";

export type ListAllDesksBookingRequest = Omit<
  ListDesksBookingRequest,
  "customerID"
>;

export const useDeskBookingRequest = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const deskClient = useMemo(() => new DeskBookingClient(transport), []);

  const searchMatchedDesks = async ({
    startTime,
    endTime,
    locationID,
    accountID,
    tagsFilter,
    timeZone,
    page,
  }: MatchingDeskRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await deskClient.searchMatchedDesks(
      {
        startTime: startTime,
        endTime: endTime,
        locationID: locationID,
        customerID: user.customerid,
        accountID: accountID,
        tagsFilter: tagsFilter,
        timeZone: timeZone,
        page: page,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const reserveDesk = async (variables: CreateDeskBookingRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    const { event, accountID, deskID } = variables;

    return await deskClient.createDeskBooking(
      {
        event: event,
        accountID: accountID,
        deskID: deskID,
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getUserReserveBookingInfo = async ({
    startTime,
    endTime,
    accountID,
    tagsFilter,
  }: PersonBookingRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await deskClient.searchPersonBooking(
      {
        customerID: user?.customerid,
        accountID: accountID,
        startTime: startTime,
        endTime: endTime,
        tagsFilter: tagsFilter,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const findNearbyDesks = async ({
    startTime,
    endTime,
    locationID,
    tagsFilter,
    accountID,
    timeZone,
    page,
  }: MatchingDeskRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await deskClient.nearbyDesks(
      {
        customerID: user?.customerid,
        accountID: accountID,
        startTime: startTime,
        endTime: endTime,
        tagsFilter: tagsFilter,
        locationID: locationID,
        timeZone: timeZone,
        page: page,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listDeskBooking = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await deskClient.listDeskBooking(
      {
        customerID: user?.customerid,
        accountID: user?.claims.user_id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteDeskBooking = async ({
    deskID,
    accountID,
    eventID,
  }: DeleteDeskBookingRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await deskClient.deleteDeskBooking(
      {
        customerID: user.customerid,
        accountID: accountID || user.claims.user_id,
        deskID: deskID,
        eventID: eventID,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateDeskBooking = async ({
    deskID,
    eventID,
    event,
  }: UpdateDeskBookingRequest) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await deskClient.updateDeskBooking(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
        deskID: deskID,
        eventID: eventID,
        event: event,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const checkInDesk = async (eventId: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await deskClient.deskCheckIn(
      {
        customerID: user.customerid,
        accountID: user.claims.user_id,
        eventID: eventId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const checkOutDesk = async (eventId: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return deskClient.deskCheckOut(
      {
        customerID: user.customerid,
        eventID: eventId,
        isSystemCheckOut: false,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listAllDesksBooking = async ({
    startTime,
    endTime,
    desksId,
  }: Omit<ListDesksBookingRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return deskClient.listDesksBooking(
      {
        customerID: user.customerid,
        startTime,
        endTime,
        desksId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    searchMatchedDesks,
    reserveDesk,
    getUserReserveBookingInfo,
    findNearbyDesks,
    listDeskBooking,
    deleteDeskBooking,
    updateDeskBooking,
    checkInDesk,
    checkOutDesk,
    listAllDesksBooking,
  };
};
