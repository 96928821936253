import React, { useState } from "react";
import { useDebounce } from "../../../../lib/hooks/useDebounce";
import { useWorkplacesFilters } from "../../lib/useWorkplacesFilters";
import { useAllTags } from "../../lib/useAllTags";

import { OnLevelSearchResponse } from "../../../../api/grpc/workplaces/workplaces";
import { ResourceFilter } from "../../../shared/ResourceFilter/ResourceFilter";
import { WorkplaceTypes } from "../../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import { WorkplacesFilters } from "../../WorkplaceList/WorkplacesDefaultList/WorkplacesFilters";
import { EditViewsTab } from "../EditViewsTab/EditViewsTab";
import { SearchedWorkplaceChildren } from "../SearchedWorkplaceChildren/SearchedWorkplaceChildren";
import { WorkplaceLocation } from "../../../../lib/types/main.types";
import { numberOfFilters } from "../../lib/numberOfActiveFilters";

interface Props {
  type: WorkplaceTypes;
  data?: OnLevelSearchResponse;
  rows: WorkplaceListData[];
  id: string;
  refetch?: () => void;
}

export const EditViewsTabWorkspaces = ({ id, type, rows }: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce<string>(searchValue, 500);
  const { activeTagsFilter, setActiveTagsFilter } = useAllTags();

  const { workplacesFilters, setWorkplacesFilters, activeFilters } =
    useWorkplacesFilters();

  const hasData = rows.some((item: any) => item.data && item.data.length > 0);

  return (
    <>
      {hasData && (
        <ResourceFilter
          onChangeSearch={(e) => setSearchValue(e.target.value)}
          searchValue={searchValue}
          numberOfFilters={numberOfFilters(activeFilters, activeTagsFilter)}
          clearSearchValue={() => setSearchValue("")}
        >
          <WorkplacesFilters
            workplacesFilters={workplacesFilters}
            setWorkplacesFilters={setWorkplacesFilters}
            activeTagsFilter={activeTagsFilter}
            setActiveTagsFilter={setActiveTagsFilter}
            type={type as WorkplaceLocation}
            disableClearButton={
              activeFilters === 0 && !!!activeTagsFilter.length
            }
            id={id}
          />
        </ResourceFilter>
      )}

      {!!debouncedValue.length ||
      activeFilters > 0 ||
      !!activeTagsFilter.length ? (
        <SearchedWorkplaceChildren
          debouncedValue={debouncedValue}
          id={id}
          licenseFilter={workplacesFilters.license}
          location={workplacesFilters.location}
          resource={workplacesFilters.resources}
          tags={activeTagsFilter}
        />
      ) : (
        <EditViewsTab id={id} type={type} rows={rows} hasData={hasData} />
      )}
    </>
  );
};
