import React, { useRef, useState } from "react";
import cs from "classnames";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useLocationDefaultInfo } from "../../helpers/useLocationId";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import {
  ListTagsEntity,
  ListTagsRequest,
  Tags,
} from "../../../../api/grpc/workplaces/workplaces";
import { Button } from "../../../shared/Button/Button";
import { TagFilter } from "../../shared/TagFilter/TagFilter";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";

interface Props {
  onClick: (e: string) => void;
  hasActiveTags?: boolean;
  type: BookingTypes;
}

const entityTypes: { [key in BookingTypes]: ListTagsEntity } = {
  desk: ListTagsEntity.TYPE_DESK,
  room: ListTagsEntity.TYPE_ROOM,
};

export const TagsButton = ({ onClick, hasActiveTags, type }: Props) => {
  const { listTags } = useWorkplacesClient();
  const ref = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const { _locationId } = useLocationDefaultInfo();

  const { data, loading, error } = useGrpcQuery<ListTagsRequest, Tags>({
    method: listTags,
    variables: {
      locationId: _locationId || "",
      limit: 30,
      entityType: entityTypes[type],
      customerId: "",
    },
  });

  const classes = cs(
    "SearchWorkplaceForm__button SearchWorkplaceForm__button--tags",
    {
      active: hasActiveTags,
    }
  );

  useOutsideClickDetection(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div ref={ref}>
      <Button
        title="Tags"
        icon="tag-reserve"
        className={classes}
        onClick={() => setShowDropdown((prev) => !prev)}
      />

      {showDropdown && (
        <TagFilter
          onClick={(e: string) => onClick(e)}
          data={data}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};
