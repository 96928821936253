import React from "react";
import { SearchBox } from "../../../../shared/SearchBox/SearchBox";
import { WorkplaceExpandedDevicesMain } from "../../../WorkplaceExpandedList/WorkplaceExpandedDevices/WorkplaceExpandedDevicesMain";

import { WorkplaceListData } from "../../../WorkplaceList/WorkplaceList";

import { EditViewsTab } from "../../EditViewsTab/EditViewsTab";

interface Props {
  id: string;
  rows: WorkplaceListData[];
  searchValue: string;
  debouncedValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
}

export const EditRoomViews = (props: Props) => {
  const { id, rows, searchValue, debouncedValue, onChange, clearSearchValue } =
    props;

  const hasData = rows.some((item: any) => item.data && item.data.length > 0);

  return (
    <>
      {(hasData || !!debouncedValue.length) && (
        <SearchBox
          searchValue={searchValue}
          onChange={onChange}
          clearSearchValue={clearSearchValue}
          className="ResourceFilter"
        />
      )}
      {!!debouncedValue.length && !!rows[0].data?.length ? (
        <div className="WorkplaceList">
          <WorkplaceExpandedDevicesMain
            parentId={id}
            data={rows[0].data}
            onClose={() => {}}
          />
        </div>
      ) : (
        <EditViewsTab
          id={id}
          type="Room"
          rows={rows}
          deviceType="main"
          hasData={hasData}
        />
      )}
    </>
  );
};
