import React, { PropsWithChildren } from "react";

import { ResponsiveHeatMap } from "@nivo/heatmap";

export const UtilizationDensityHeatMap = ({
  data,
}: PropsWithChildren<{
  data: {
    id: string;
    data: { x: string; y: number }[];
  }[];
}>) => {
  const densityData = data.map((density) => {
    return {
      id: density.id.slice(0, 3),
      data: density.data,
    };
  });

  return (
    <ResponsiveHeatMap
      data={densityData}
      margin={{ top: 20, right: 10, bottom: 70, left: 60 }}
      axisBottom={{
        tickPadding: 10,
        tickSize: 0,
        tickRotation: -90,
        // format: (value) => value.split(":")[0],
      }}
      // label={(d) => `${d.formattedValue}%`}
      axisRight={null}
      axisTop={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 15,
      }}
      colors={{
        type: "quantize",

        colors: [
          "#01EC96",
          "#02E695",
          "#03DF93",
          "#04D992",
          "#05D290",
          "#06CC8F",
          "#07C68D",
          "#08BF8C",
          "#09B98A",
          "#0AB289",
          "#0CAC88",
          "#0DA686",
          "#0E9F85",
          "#0F9983",
          "#109282",
          "#118C80",
          "#12867F",
          "#137F7D",
          "#14797C",
          "#15727A",
          "#166C79",
        ],
        minValue: 0,
        maxValue: 100,
      }}
      emptyColor="#555555"
      theme={{
        fontFamily: "Sofia Pro",
        fontSize: 13,
      }}
    />
  );
};
