import React, { PropsWithChildren, useMemo, useState } from "react";
import { People } from "../../../../api/grpc/contacts/contacts";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import Select from "react-select";
import { Button } from "../../../shared/Button/Button";
import { OrganizerFilter } from "../../Context/Room/Overview/RoomInsightsContextProvider";

export const HistoryPeopleSelect = ({
  loading,
  error,
  data,
  organizers,
  setOrganizers,
  handleClose,
}: PropsWithChildren<{
  loading: boolean;
  error: any;
  data?: People;
  organizers?: OrganizerFilter[];
  setOrganizers: React.Dispatch<
    React.SetStateAction<OrganizerFilter[] | undefined>
  >;
  handleClose: (() => void) | undefined;
}>) => {
  const [selectedValues, setSelectedValues] = useState<
    { label: string; value: string }[] | null
  >(() => (organizers ? organizers : null));

  const options = useMemo(() => {
    return data?.people.map((item) => ({
      value: item.accountID,
      label: item.displayName,
    }));
  }, [data]);

  return (
    <>
      {loading ? (
        <LoadingBox minHeight={50} />
      ) : error ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <>
          <Select
            isMulti
            className="SearchWorkplaceForm__createableOptions"
            onChange={(e) => {
              if (e === null) {
                return;
              }

              const values = e.map((item) => ({
                label: item.label,
                value: item.value,
              }));

              setSelectedValues(values);
            }}
            value={selectedValues}
            options={options}
            isClearable
            //   classNamePrefix={selectedValues.length >= 2 ? "MultipleVal" : ""}
          />

          <div className="d-flex justify-content-between">
            <Button
              title="Done"
              size="small"
              color="outline-primary"
              className="Button__extra-small mt-3"
              onClick={() => {
                if (handleClose) {
                  handleClose();
                }

                if (!selectedValues && organizers?.length) {
                  setOrganizers(undefined);
                  return;
                }

                if (!selectedValues) {
                  return;
                }

                setOrganizers(selectedValues);
              }}
            />

            {!!organizers?.length && (
              <Button
                title="Clear"
                size="small"
                color="outline-secondary"
                className="Button__extra-small mt-3"
                onClick={() => {
                  setSelectedValues(null);
                  setOrganizers(undefined);
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
