import React, { useState } from "react";
import { toast } from "react-toastify";

import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../../lib/context/Customer/CustomerContext";
import { useCompanyFormik } from "../../../../shared/Forms/CompanyForm/lib/useCompanyFormik";

import { WorkplaceModalProps } from "../AddWorkplaceModal";

import { AddCompanyVariables } from "../../../../../api/graphql/companies/companies";
import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";

import { CompanyForm } from "../../../../shared/Forms/CompanyForm/CompanyForm";

export const AddCompanyModal = (props: WorkplaceModalProps) => {
  const { isOpen, toggle } = props;
  const { addCompany } = useWorkplaceRequests();
  const [loading, setLoading] = useState(false);
  const { retrieveTenant } = useCustomerContext();

  const formik = useCompanyFormik({
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const { id, tenantId } = await retrieveTenant();

        const variables: AddCompanyVariables = {
          id,
          tenantId,
          name: values.name,
          tags: values.tags,
          description: values.description,
        };

        await addCompany(variables);

        toggle();
        toast.success("Company added successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Company couldn't be added, please reload and try again!"
        );
        setLoading(false);
        console.error(error.message);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Company"
      onSubmit={formik.handleSubmit}
    >
      <CompanyForm formik={formik} informationBoxDirection="left" />
    </ModalSidebar>
  );
};
