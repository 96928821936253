import React from "react";
import { NavLink, useHistory } from "react-router-dom";

import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";

import { trimText } from "../../../../lib/helpers/trimText";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { WORKPLACES_ROOT_PATH } from "../../../../lib/routes/routes";
import { retrieveCalendarName } from "./helpers/retrieveCalendarName";
import { getWorkplaceName } from "../../helpers/getWorkplaceInformation";

import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";
import { LicenseSwitch } from "../../LicenseSwitch/LicenseSwitch";
import { DeleteButton } from "../../../shared/DeleteButton/DeleteButton";
import { EntityType } from "../../../../api/grpc/subscription/subscription";
import { WorkplacePrefixIcon } from "../WorkplacePrefixIcon/WorkplacePrefixIcon";
import { SetCalendarButton } from "../../AddWorkplace/SetCalendarButton/SetCalendarButton";
import { AddDeviceButton } from "../../AddWorkplace/AddWorkplaceButton/AddDeviceButton/AddDeviceButton";

import "./WorkplaceExpandedRooms.scss";

interface Props {
  onClose: () => void;
  data: WorkplaceFields[];
}

export const WorkplaceExpandedRooms = (props: Props) => {
  const history = useHistory();
  const { data, onClose } = props;
  const { deleteWorkplace } = useWorkplaceRequests();

  const { providers } = useCalendarContext();

  const workplaceName = getWorkplaceName("Room", data.length);

  const columns: StructureTableColumn[] = [
    {
      key: "workplace_icon",
      header: workplaceName,
      onClick: () => onClose(),
    },
    {
      key: "workplace_name",
      header: "Name",
    },
    {
      key: "workplace_description",
      header: "Description",
    },
    {
      key: "workplace_calendar",
      header: "Calendar",
    },
    {
      key: "workplace_device",
      header: "Device",
    },
    {
      key: "workplace_license",
      header: "License",
    },
    {
      key: "workplace_delete",
      header: "",
    },
  ];

  const rows: StructureTableRows[] = data.map((item) => {
    const devices =
      item.devices && item.devices.length > 0
        ? item.devices.map((item) => item.name)
        : [];

    const calendarName = retrieveCalendarName({
      providers,
      calendarId: item.calendarId,
      providerId: item.calendarProviderId,
    });

    return {
      workplace_icon: <WorkplacePrefixIcon icon="rooms-icon" />,
      workplace_name: (
        <NavLink to={`${WORKPLACES_ROOT_PATH}/room/${item.id}`}>
          <h6 className="mb-0" title={item.name}>
            {item.name}
          </h6>
        </NavLink>
      ),
      workplace_description: (
        <h6 className="mb-0" title={item.description || "-"}>
          {trimText(item.description || "-")}
        </h6>
      ),
      workplace_calendar: calendarName ? (
        <span className="mb-0" title={calendarName}>
          {trimText(calendarName, 25)}
        </span>
      ) : (
        <SetCalendarButton
          outline
          size="small"
          lineHeight="low"
          title="Set Calendar"
          onClick={() => {
            history.push(
              `${WORKPLACES_ROOT_PATH}/room/${item.id}/settings#add-calendar`
            );
          }}
        />
      ),
      workplace_device:
        devices.length > 0 ? (
          <>
            {devices.map((item, index) => {
              return <span key={`${item}-${index}`}>{trimText(item)}</span>;
            })}
          </>
        ) : (
          <AddDeviceButton
            outline
            size="small"
            lineHeight="low"
            color="secondary"
            title="Set Device"
            parentId={item.id}
            parentType="Room"
            numberOfDeviceAdded={item?.devices?.length}
          />
        ),
      workplace_license: (
        <>
          <LicenseSwitch
            id={item.id}
            type={EntityType.ROOM}
            value={!!item.licensed}
          />
        </>
      ),
      workplace_delete: (
        <DeleteButton
          onClick={() => deleteWorkplace(item.id, item.__typename)}
        />
      ),
    };
  });

  return (
    <StructureTable
      rows={rows}
      columns={columns}
      noResultsPlaceholder={"--"}
      className="StructureTable--rooms"
    />
  );
};
