import React from "react";
import Select from "react-select";

import { selectStyles } from "../../../lib/utils/selectStyles";

interface Props {
  value: string;
  fieldName: string;
  onChange: (field: string, value: string[]) => void;
}

const minutes = [
  { value: "member-1", label: "Member 1" },
  { value: "member-2", label: "Member 2" },
];

export const MemberSelect = ({ fieldName, onChange }: Props) => {
  return (
    <Select
      isMulti
      isClearable
      theme={selectStyles}
      name={fieldName}
      options={minutes}
      onChange={(e) => {
        if (!e) {
          return;
        }

        const values = e.map((item) => item.value);
        onChange(fieldName, values);
      }}
    />
  );
};
