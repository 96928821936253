import { useMemo } from "react";

import { transport } from "../grpcTransport";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { DirectoryProviderClient } from "./calendarprovider.client";
import {
  CreateDirectoryProviderRequest,
  GenerateAuthLinkRequest,
  UpdateDirectoryProviderCredentialsRequest,
} from "./calendarprovider";

export const useDirectoryIntegrationsRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const integrationClient = useMemo(
    () => new DirectoryProviderClient(transport),
    []
  );

  const listOfDirectoryIntegration = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await integrationClient.listDirectoryProvider(
      {
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const generateDirectoryIntegrationAuthLink = async ({
    integrationType,
    redirectURL,
  }: Omit<GenerateAuthLinkRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await integrationClient.generateAuthLink(
      {
        customerID: user.customerid,
        integrationType,
        redirectURL,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createDirectoryIntegration = async ({
    oAuthToken,
    providerType,
  }: Omit<CreateDirectoryProviderRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await integrationClient.createDirectoryProvider(
      {
        customerID: user.customerid,
        oAuthToken,
        providerType,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteDirectoryIntegration = async (id: string) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await integrationClient.deleteDirectoryProvider(
      {
        customerID: user.customerid,
        iD: id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const reAuthenticateDirectoryIntegration = async ({
    cType,
    providerID,
  }: Omit<UpdateDirectoryProviderCredentialsRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await integrationClient.updateDirectoryProviderCredentials(
      {
        customerID: user.customerid,
        cType,
        providerID,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    listOfDirectoryIntegration,
    generateDirectoryIntegrationAuthLink,
    createDirectoryIntegration,
    deleteDirectoryIntegration,
    reAuthenticateDirectoryIntegration,
  };
};
