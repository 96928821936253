import React, { PropsWithChildren } from "react";
import { usePeopleContext } from "../../../views/Users/People/lib/PeopleContext";

import { Role, Status } from "../../../api/grpc/account/account";
import { NullableBoolean } from "../../../api/grpc/contacts/contacts";
import { Button } from "../../shared/Button/Button";
import { CheckboxButton } from "../../shared/CheckboxButton/CheckboxButton";
import {
  contactFilters,
  PossibleFilters,
} from "./helpers/definedContactsFilters";
import { ResourceFilterHeader } from "../../shared/ResourceFilter/ResourceFilterHeader";

export interface IContactFilters {
  licensed: NullableBoolean;
  role: Role[];
  status: Status[];
  desks: NullableBoolean;
}

const definedName: {
  [key in PossibleFilters]: string;
} = {
  licensed: "License",
  role: "Role",
  status: "Status",
  desks: "Assigned desk",
};

export const ContactsFilters = ({
  disableClearButton,
}: PropsWithChildren<{ disableClearButton: boolean }>) => {
  const { contactsFilters, setContactsFilters } = usePeopleContext();

  const handleFilterChange = (filterName: string, selectedValue: number) => {
    setContactsFilters((prevFilters) => {
      let updatedFilters: IContactFilters = { ...prevFilters };

      if (filterName === "licensed" || filterName === "desks") {
        updatedFilters[filterName] =
          updatedFilters[filterName] === selectedValue
            ? NullableBoolean.NULL
            : selectedValue;
      } else if (filterName === "role") {
        const role = selectedValue as Role;
        const index = updatedFilters.role?.indexOf(role);

        if (index === -1) {
          updatedFilters.role = [...(updatedFilters.role || []), role];
        } else {
          updatedFilters.role = updatedFilters.role?.filter(
            (item) => item !== role
          );
        }
      } else if (filterName === "status") {
        const status = selectedValue as Status;
        const index = updatedFilters.status?.indexOf(status);

        if (index === -1) {
          updatedFilters.status = [...(updatedFilters.status || []), status];
        } else {
          updatedFilters.status = updatedFilters.status?.filter(
            (item) => item !== status
          );
        }
      }

      return updatedFilters;
    });
  };

  const defineCheckedValues = (
    name: PossibleFilters,
    value: string | number
  ): boolean => {
    if (name === "licensed" || name === "desks") {
      return contactsFilters[name] === value;
    }

    if (name === "role") {
      return contactsFilters.role?.includes(value as Role) ?? false;
    }

    if (name === "status") {
      return contactsFilters.status?.includes(value as Status) ?? false;
    }

    return false;
  };

  return (
    <div className="child-element">
      <div className="main-flex mb-2">
        <ResourceFilterHeader />

        <Button
          title="Clear all"
          color="outline-secondary"
          className="child-element--clear"
          disabled={disableClearButton}
          onClick={() => {
            setContactsFilters({
              desks: NullableBoolean.NULL,
              licensed: NullableBoolean.NULL,
              role: [],
              status: [],
            });
          }}
        />
      </div>

      <div className="d-flex gap-3 FilterContent">
        {contactFilters.map((filterItem, index) => {
          return (
            <div key={index}>
              <span className="child-element--head text-nowrap flex">
                {definedName[filterItem.name as PossibleFilters]}
              </span>

              {filterItem.options.map((item) => {
                return (
                  <CheckboxButton
                    key={item.name}
                    id={item.name}
                    name={item.title}
                    checked={defineCheckedValues(filterItem.name, item.value)}
                    onChange={() =>
                      handleFilterChange(filterItem.name, item.value)
                    }
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
