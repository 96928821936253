import { MultiFactorError } from "firebase/auth";
import React, { useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { useTotpInputChange } from "../../../../../lib/hooks/useTotpInputChange";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";

import { MFAType } from "../../../../../api/grpc/account/account";
import { TotpCodeInput } from "../../../../shared/Forms/TotpCodeInput/TotpCodeInput";
import { PasswordInput } from "../../../../shared/PasswordInput/PasswordInput";
import { ModalMFA } from "../ModalMFA";

export const MfaReAuthUser = () => {
  const { user, verifyPassword, loginWithTotp } = useAuthContext();
  const { setMfaModal } = useMfaContext();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [requireTotp, setRequireTotp] = useState(false);
  const [errorObj, setErrorObj] = useState<{
    errorMfa?: MultiFactorError;
    errorMessage?: string;
  }>({
    errorMessage: undefined,
    errorMfa: undefined,
  });
  const { handleTotpInputChange, totpCode } = useTotpInputChange();

  const handleReAuth = async () => {
    try {
      setLoading(true);
      if (requireTotp) {
        if (errorObj.errorMfa === undefined) {
          return setMfaModal("error");
        }

        await loginWithTotp(errorObj.errorMfa, totpCode.join(""));
      } else {
        await verifyPassword(password);
      }

      if (user?.mfaType !== MFAType.MFA_TYPE_UNKNOWN) {
        return setMfaModal("confirm");
      }

      setMfaModal("mfaPicker");
    } catch (error: any) {
      if (["auth/user-mismatch", "auth/wrong-password"].includes(error?.code)) {
        setErrorObj({ errorMessage: "Wrong password" });
        return;
      }
      if (error?.code === "auth/multi-factor-auth-required") {
        setErrorObj({ errorMfa: error });
        setRequireTotp(true);
        return;
      }

      if (error?.code === "auth/invalid-verification-code") {
        setErrorObj({ ...errorObj, errorMessage: "Wrong code" });
        return;
      }

      setMfaModal("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalMFA
      title={`${
        user?.mfaType !== MFAType.MFA_TYPE_UNKNOWN ? "Disable" : "Set up"
      } two-factor authentication`}
      keyPoints={[
        "For security purposes, please re-enter your owner/admin account password below",
      ]}
      closeButton="Close"
      handleBackButton={() => setMfaModal(undefined)}
      handleNextButton={handleReAuth}
      disabledNext={loading || password.length < 8}
      nextButton="Next"
      className="MfaReAuthUser"
    >
      {requireTotp ? (
        <FormGroup>
          <Label className="d-block mt-4">
            Enter the 6-digit code generated by your authenticator app.
          </Label>
          <TotpCodeInput
            totpCode={totpCode}
            handleTotpInputChange={handleTotpInputChange}
          />
          {errorObj.errorMessage && (
            <FormFeedback className="invalid-feedback">
              {errorObj.errorMessage}
            </FormFeedback>
          )}
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>Re-enter password for {user?.email}</Label>
          <PasswordInput
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            errorMessage={errorObj.errorMessage}
            isTouched={true}
          />
        </FormGroup>
      )}
    </ModalMFA>
  );
};
