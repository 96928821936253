import React, { useMemo } from "react";

import { usePeopleContext } from "../../../views/Users/People/lib/PeopleContext";

import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";

import { PeopleTable } from "../PeopleTable/PeopleTable";
import { EmptyContacts } from "./EmptyContacts/EmptyContacts";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { PaginationContext } from "../../../lib/context/Pagination/PaginationContext";
import { CenteredBox } from "../../shared/CenteredBox/CenteredBox";
import { ResourceFilter } from "../../shared/ResourceFilter/ResourceFilter";
import { ContactsFilters } from "../ContactsFilters/ContactsFilters";

import "./Contacts.scss";

export const Contacts = () => {
  const {
    loading,
    error,
    data,
    refetchUsers,
    pagination,
    searchValue,
    setSearchValue,
    debouncedValue,
    contactsFilters,
    deleteLoader,
  } = usePeopleContext();

  const activeFilters = useMemo(() => {
    let counter = 0;
    if (contactsFilters.desks !== 0) {
      counter++;
    }

    if (contactsFilters.licensed !== 0) {
      counter++;
    }

    if (contactsFilters.role.length) {
      counter++;
    }

    if (contactsFilters.status.length) {
      counter++;
    }

    return counter;
  }, [contactsFilters]);

  return (
    <div className="Contacts">
      <PaginationContext.Provider value={pagination}>
        <ResourceFilter
          searchValue={searchValue}
          onChangeSearch={(e) => setSearchValue(e.target.value)}
          numberOfFilters={activeFilters !== 0 ? activeFilters : undefined}
          disabledFilters={
            !loading &&
            !!!searchValue.length &&
            !!!data?.length &&
            activeFilters === 0
          }
          clearSearchValue={() => setSearchValue("")}
          className="mt-3"
        >
          <ContactsFilters disableClearButton={activeFilters === 0} />
        </ResourceFilter>

        <HandleRequestState
          state={loading || deleteLoader}
          placeholder={
            <div className="Contacts__loading">
              <LoadingBox count={10} />
            </div>
          }
        >
          <HandleRequestState
            state={!!error}
            placeholder={
              <ErrorMessage message="Contacts could not be loaded, please try again later" />
            }
          >
            {data && data.length > 0 ? (
              <PeopleTable contacts={data} refetch={refetchUsers} />
            ) : !!debouncedValue.length || activeFilters > 0 ? (
              <CenteredBox>No contact found</CenteredBox>
            ) : (
              <EmptyContacts />
            )}
          </HandleRequestState>
        </HandleRequestState>
      </PaginationContext.Provider>
    </div>
  );
};
