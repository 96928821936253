import { useRoomsRequests } from "../../../../api/graphql/rooms/useRoomsRequests";

export const useManageShapes = (tenantId: string) => {
  const { updateRoomCircle, updateRoomPolygonal } = useRoomsRequests();

  const handleRoomCircleDelete = (id: string) => async () => {
    return await updateRoomCircle({
      id: id,
      circleShape: {
        longitude: 0,
        latitude: 0,
        radius: 0,
      },
      tenantId,
    });
  };

  const handleRoomPolygonalDelete =
    (id: string, polygonalToDelete: { id: string }[]) => async () => {
      const polygonalToDeleteIds = polygonalToDelete.map((item) => ({
        id: item.id,
      }));

      return await updateRoomPolygonal({
        id: id,
        polygonal: [],
        polygonalToDelete: polygonalToDeleteIds,
        tenantId,
      });
    };

  return {
    handleRoomCircleDelete,
    handleRoomPolygonalDelete,
  };
};
