import React from "react";

import { Form, FormGroup } from "reactstrap";

// import { tooltips } from "../../../../lib/utils/tooltips";
import { MemberSelect } from "../../../Teams/MemberSelect/MemberSelect";

import { Input } from "../../Input/Input";
import { LabelWithInformationBox } from "../../LabelWithInformationBox/LabelWithInformationBox";

interface Props {
  formik: any;
}

export const EditTeamForm = ({ formik }: Props) => {
  return (
    <Form>
      <FormGroup>
        <LabelWithInformationBox for="name" title="Name" direction="left" />
        <Input
          id="name"
          type="text"
          name="name"
          value={formik.values.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.name}
          errorMessage={formik.errors.name}
          invalid={!!formik.errors.name && formik.touched.name}
        />
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="member"
          title="Members"
          direction="left"
        />
        <MemberSelect
          fieldName="member"
          value=""
          onChange={() => console.log("test")}
        />
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="location"
          title="Choose location"
          direction="left"
        />
        <Input
          type="text"
          id="location"
          name="location"
          placeholder="Company A"
          className="mb-3"
          value={formik.values.location}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.location}
          errorMessage={formik.errors.location}
          invalid={!!formik.errors.location && formik.touched.location}
        />
        <Input
          type="text"
          id="location"
          name="location"
          placeholder="All locations"
          value={formik.values.company}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.company}
          errorMessage={formik.errors.company}
          invalid={!!formik.errors.company && formik.touched.company}
        />
      </FormGroup>
    </Form>
  );
};
