import { useMemo } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";
import { ValidateCredentialsRequest } from "./auth";
import { AuthClient } from "./auth.client";

export const useAuth = () => {
  const { tokenInterceptor } = useAuthContext();

  const authsClient = useMemo(() => new AuthClient(transport), []);

  const exchangeSocialAuthToken = async (token: string) => {
    return await authsClient.exchangeSocialAuthToken(
      {
        token: token,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const validateCredentials = async ({
    email,
    password,
  }: ValidateCredentialsRequest) => {
    return await authsClient.validateCredentials({
      email,
      password,
    });
  };

  return {
    exchangeSocialAuthToken,
    validateCredentials,
  };
};
