import React from "react";
import { InformationBox } from "../../InformationBox/InformationBox";
import { LabelDirectionType } from "../../LabelWithInformationBox/LabelWithInformationBox";

import "./PageSidebarHeader.scss";

interface Props {
  title: string;
  message: string;
  onTitleClick?: () => void;
  children?: React.ReactNode;
  direction?: LabelDirectionType;
  hasZIndex?: boolean;
  adjustInfoLeft?: boolean;
  adjustInfoRight?: boolean;
}

export const PageSidebarHeader = (props: Props) => {
  const {
    title,
    message,
    children,
    onTitleClick,
    direction,
    hasZIndex,
    adjustInfoLeft,
    adjustInfoRight,
  } = props;

  const handleTitleClick = () => {
    if (onTitleClick) {
      onTitleClick();
    }
  };

  return (
    <div className="PageSidebarHeader">
      <h3
        onClick={handleTitleClick}
        className={`${onTitleClick ? "cursor-pointer" : ""}`}
      >
        {title}
      </h3>
      <InformationBox
        message={message}
        direction={direction}
        className="InformationBox__top"
        hasZIndex={hasZIndex}
        adjustInfoLeft={adjustInfoLeft}
        adjustInfoRight={adjustInfoRight}
      />
      {children}
    </div>
  );
};
