import { useApolloClient } from "@apollo/client";

import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../workplaces";
import { COMPANY_FIELDS_FRAGMENT } from "../companies/companies";

import {
  AddSiteResponse,
  AddSiteVariables,
  ADD_SITE_TO_TENANT,
  UpdateSiteResponse,
  SiteRequestVariables,
  UPDATE_SITE,
  ADD_SITE_TO_COMPANY,
  SITE_FIELDS_FRAGMENT,
} from "./sites";
import { WorkplaceTypes } from "../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

export const useSitesRequests = () => {
  const client = useApolloClient();

  const addSiteToTenant = async (variables: SiteRequestVariables) => {
    return await client.mutate<AddSiteResponse, AddSiteVariables>({
      mutation: ADD_SITE_TO_TENANT,
      variables,
      update: (cache, newData) => {
        const previousQuery = cache.readQuery<
          GetWorkplacesResponse,
          WorkplaceRequestVariables
        >({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
        });

        const tenantResults = previousQuery?.queryTenant[0];
        const newSite = newData.data?.addSite.site[0];

        cache.writeQuery({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
          data: {
            queryTenant: [
              {
                ...tenantResults,
                sites: [...(tenantResults?.sites || []), newSite],
              },
            ],
          },
        });
      },
    });
  };

  const addSiteToCompany = async (variables: SiteRequestVariables) => {
    return await client.mutate<AddSiteResponse, AddSiteVariables>({
      mutation: ADD_SITE_TO_COMPANY,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            sites: [...previousData.sites, newData.data?.addSite.site[0]],
          },
        });
      },
    });
  };

  const addSite = async (
    variables: SiteRequestVariables,
    parentType?: WorkplaceTypes
  ) => {
    if (parentType === "Company") {
      return await addSiteToCompany(variables);
    }

    return await addSiteToTenant(variables);
  };

  const updateSite = async (variables: SiteRequestVariables) => {
    return await client.mutate<UpdateSiteResponse, SiteRequestVariables>({
      mutation: UPDATE_SITE,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateSite.site[0],
          },
        });
      },
    });
  };

  return { addSite, updateSite };
};
