import React, { PropsWithChildren } from "react";
import { Icon } from "../../../shared/Icon/Icon";

export const MeetingsAveragePercentage = ({
  percentage,
}: PropsWithChildren<{
  percentage: number;
}>) => {
  return (
    <div className="flex-a-center MeetingsAverageRow__value--percentage pt-2">
      <Icon icon={percentage >= 0 ? "polygon-green" : "polygon-red"} />
      <span
        className={`pl-2 MeetingsAverageRow__${
          percentage >= 0 ? "positive" : "negative"
        }`}
      >
        {percentage >= 0 && "+"}
        {percentage}%
      </span>
      <span className="MeetingsAverageRow__description">
        vs previous period
      </span>
    </div>
  );
};
