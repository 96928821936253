import { Marker } from "../../../api/graphql/rooms/rooms";
import { useDesksRequests } from "../../../api/graphql/desks/useDesksRequests";
import { useRoomsRequests } from "../../../api/graphql/rooms/useRoomsRequests";
import { useWayfinderRequests } from "../../../api/graphql/wayfinder/useWayfinderRequests";
import { TypeOfFloorChildrenMethod } from "../FloorChildrenList/FloorChildrenDefaultList";
import { randomLatLngGenerator } from "./randomLatLngGenerator";

type BookingTypesMethod = "desk" | "room" | "wayfinder";

export const useTypesHelper = () => {
  const { updateRoomMarker } = useRoomsRequests();
  const { updateDeskMarker } = useDesksRequests();
  const { updateWayfinderMarker } = useWayfinderRequests();

  const methodTypes: {
    [key in BookingTypesMethod]: any;
  } = {
    desk: updateDeskMarker,
    room: updateRoomMarker,
    wayfinder: updateWayfinderMarker,
  };

  const markerDataTypes: {
    [key in TypeOfFloorChildrenMethod]: Marker;
  } = {
    add: {
      latitude: randomLatLngGenerator(10, 90),
      longitude: randomLatLngGenerator(10, 90),
    },
    delete: {
      latitude: 0,
      longitude: 0,
    },
  };

  return { methodTypes, markerDataTypes };
};
