import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { ContactSalesFormik } from "../Billings/lib/useContactSalesFormik";

interface Props {
  formik: ContactSalesFormik;
  bodyLabelText: string;
}

export const ContactSalesForm = ({ formik, bodyLabelText }: Props) => {
  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Label for="name">Your name</Label>
        <Input
          type="text"
          id="name"
          name="name"
          onBlur={formik.handleBlur}
          value={formik.values.name}
          onChange={formik.handleChange}
          isTouched={formik.touched.name}
          errorMessage={formik.errors.name}
          invalid={!!formik.errors.name && formik.touched.name}
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          id="email"
          name="email"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
          isTouched={formik.touched.email}
          errorMessage={formik.errors.email}
          invalid={!!formik.errors.email && formik.touched.email}
        />
      </FormGroup>
      <FormGroup>
        <Label for="message">{bodyLabelText}</Label>
        <Input
          type="textarea"
          id="message"
          name="message"
          onBlur={formik.handleBlur}
          value={formik.values.message}
          onChange={formik.handleChange}
          isTouched={formik.touched.message}
          errorMessage={formik.errors.message}
          invalid={!!formik.errors.message && formik.touched.message}
        />
      </FormGroup>
    </Form>
  );
};
