import React, { useMemo } from "react";
import { format } from "date-fns";
import {
  ListAllDesksBookingRequest,
  useDeskBookingRequest,
} from "../../../../api/grpc/desk/useDeskBookingRequest";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useAllReservationContext } from "../../shared/context/AllReservationContext/AllReservationContext";
import { defineLocationPath } from "../../../Bookings/helpers/defineLocationPath";

import { ListDesksBookingResponse } from "../../../../api/grpc/desk/desk";
import { ReservationCard } from "../shared/ReservationCard";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { QRCodeRequiredStatus } from "../../../../api/grpc/booking/ggevent/ggevent";
import { Event } from "../../../../api/grpc/ggevent/ggevent";
import { FavoriteWorkplace } from "../../../../api/grpc/account/account";

interface Props {
  pickedDesks: FavoriteWorkplace[];
}

export const DesksReservations = ({ pickedDesks }: Props) => {
  const { listAllDesksBooking } = useDeskBookingRequest();
  const { startDate } = useAllReservationContext();

  const {
    loading,
    error,
    data,
    refetch: refetchDesks,
  } = useGrpcQuery<ListAllDesksBookingRequest, ListDesksBookingResponse>({
    method: listAllDesksBooking,
    variables: {
      startTime: format(startDate || new Date(), "yyyy-MM-dd"),
      endTime: format(startDate || new Date(), "yyyy-MM-dd"),
      desksId: pickedDesks.map((workspace) => workspace.id),
    },
  });

  const desks = useMemo(() => {
    if (!data) {
      return [];
    }

    // we need this memo to define events because events from desk have different types from backend respone
    return data.data.map((desk) => ({
      deskId: desk.deskId,
      deskName: desk.deskName,
      deskTags: desk.deskTags,
      deskLocationPath: desk.deskLocationPath,
      qrCodeRequired:
        desk.events[0]?.qrCodeRequired ===
        QRCodeRequiredStatus.QR_STATUS_REQUIRED,
      checkInTime: desk.checkInTime,
      events: desk.events
        .map((deskBookingEvent) => deskBookingEvent.event)
        .filter((event): event is Event => !!event),
    }));
  }, [data]);

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<LoadingBox maxWidth={300} />}
    >
      <HandleRequestState
        state={!!error}
        placeholder={<div>{error?.message}</div>}
      >
        {desks.map((item) => (
          <ReservationCard
            key={item.deskId}
            type="desk"
            name={item.deskName}
            events={item.events}
            tags={item.deskTags.join(", ")}
            locationPath={defineLocationPath(item.deskLocationPath)}
            workplaceID={item.deskId}
            refetch={refetchDesks}
            qrCodeRequired={item.qrCodeRequired}
            checkInTime={item.checkInTime}
          />
        ))}
      </HandleRequestState>
    </HandleLoadingState>
  );
};
