import React from "react";
import { useLoginContext } from "../../../../lib/context/LoginContext/LoginContext";

import { Button } from "../../../shared/Button/Button";

export const RecoverMfaOptions = () => {
  const { setMfaStep } = useLoginContext();

  return (
    <div className="VerifyMfa">
      <div className="VerifyMfa__info">
        <span>
          Having problems? Try one of the following alternative options.
        </span>
      </div>

      <div className="VerifyMfa__options">
        <Button
          color="link"
          size="small"
          title="Send recovery link to secondary email"
          onClick={() => setMfaStep("secondaryEmail")}
          className="d-block"
        />

        <Button
          color="link"
          size="small"
          title="Use an authenticator app"
          onClick={() => setMfaStep("verifyCode")}
          className="d-block"
        />

        <Button
          color="link"
          size="small"
          title="I can't use any of these options"
          onClick={() => setMfaStep("requestDisable")}
          className="d-block"
        />
      </div>
    </div>
  );
};
