import React, { useEffect, useState } from "react";
import cs from "classnames";

import "./StructureTable.scss";

export type StructureTableColumn = {
  header: string | JSX.Element;
  key: string;
  classes?: string;
  hidden?: boolean;
  sortable?: boolean;
  onClick?: () => void;
};

export type StructureTableRows = {
  [key: string]: React.ReactNode;
};

interface StructureTableProps {
  small?: boolean;
  loading?: boolean;
  className?: string;
  rows: StructureTableRows[];
  columns: StructureTableColumn[];
  onRowClick?: (index: number) => void;
  noResultsPlaceholder?: string | React.ReactNode;
  isMobile?: boolean;
}

export const StructureTable = (props: StructureTableProps) => {
  const [rows, setRows] = useState<StructureTableRows[]>(() => props.rows);
  const [columns, setColumns] = useState<StructureTableColumn[]>(() =>
    props.columns.filter((item) => !item.hidden)
  );
  const [clickedRow, setClickedRow] = useState<number | null>(null);

  useEffect(() => {
    setRows(props.rows);
    setColumns(props.columns.filter((item) => !item.hidden));
  }, [props.rows, props.columns]);

  if (rows && rows.length === 0) {
    return typeof props.noResultsPlaceholder === "string" ? (
      <h4>{props.noResultsPlaceholder}</h4>
    ) : (
      <>{props.noResultsPlaceholder}</>
    );
  }

  const handleRowClick = (index: number) => {
    if (props.onRowClick) {
      props.onRowClick(index);
    }

    // setClickedRow((prevClickedRow) =>
    //   prevClickedRow === index ? null : index
    // );
    setClickedRow(index);
  };

  const classes = cs(
    "StructureTable",
    {
      "StructureTable--small": props.small,
    },
    props.className
  );

  return (
    <div className={classes}>
      <div className="StructureTable__head mb-1 mt-2">
        {columns.map((col: StructureTableColumn) => (
          <h5
            key={col.key}
            className={`StructureTable__head__item ${col.key} ${
              col.classes ? col.classes : ""
            }`}
            onClick={(e) => {
              e.preventDefault();

              if (col.onClick) {
                col.onClick();
              }
            }}
          >
            <span>{col.header}</span>
          </h5>
        ))}
      </div>
      <div className="StructureTable__content">
        {rows.map((row, i) => (
          <div
            key={`row-${i}`}
            // className="StructureTable__item list-row"
            className={`StructureTable__item list-row ${
              props.isMobile && clickedRow === i ? "clicked-row" : ""
            }`}
            // onClick={() => (props.onRowClick ? props.onRowClick(i) : undefined)}
            onClick={() => handleRowClick(i)}
          >
            {columns.map((rowColumn) => {
              return (
                <div
                  key={rowColumn.key}
                  className={cs(
                    "StructureTable__item__column",
                    rowColumn["key"]
                  )}
                >
                  {row[rowColumn["key"]]}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
