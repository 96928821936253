import { MultiFactorError } from "firebase/auth";
import React, { PropsWithChildren, useState } from "react";
import {
  LoginContext,
  LoginContextValues,
  VerifyMfaKeys,
} from "./LoginContext";

export const LoginContextProvider = ({
  children,
}: PropsWithChildren<{
  children: React.ReactNode;
}>) => {
  const [mfaStep, setMfaStep] = useState<VerifyMfaKeys | undefined>(undefined);
  const [errorMfa, setErrorMfa] = useState<MultiFactorError | undefined>(
    undefined
  );
  const context: LoginContextValues = {
    mfaStep,
    setMfaStep,
    errorMfa,
    setErrorMfa,
  };

  return (
    <LoginContext.Provider value={context}>{children}</LoginContext.Provider>
  );
};
