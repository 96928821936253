import React, { useRef, useState } from "react";
import { Button } from "../../../shared/Button/Button";
import cs from "classnames";
import { Seatings } from "./Seatings/Seatings";
import { Equipment } from "./Equipment/Equipment";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useBookingsContext } from "../../Context/BookingsContext";

import "./Capabilities.scss";

export const Capabilities = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { equipment, seats } = useBookingsContext();
  const ref = useRef(null);

  const classes = cs(
    "SearchWorkplaceForm__button SearchWorkplaceForm__button--capabilities",
    {
      active:
        seats !== "Any" ||
        Object.values(equipment).some((value) => value === true),
    }
  );

  useOutsideClickDetection(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div ref={ref}>
      <Button
        title="Capabilities"
        icon="capabilities-icon"
        className={classes}
        size="small"
        onClick={() => setShowDropdown((prev) => !prev)}
      />

      {showDropdown && (
        <div className="Capabilities">
          <Equipment />
          <Seatings />
        </div>
      )}
    </div>
  );
};
