import React from "react";
import { LabelWithInformationBox } from "../LabelWithInformationBox/LabelWithInformationBox";

import "./RadioButton.scss";

interface Props {
  title: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  className?: string;
}

export const RadioButton = ({
  title,
  checked,
  onChange,
  value,
  name,
  className,
}: Props) => {
  return (
    <div className={`RadioButton flex-a-center ${className}`}>
      <LabelWithInformationBox title={title} className="pr-2" />

      <input
        id={value}
        name={name}
        type="radio"
        checked={checked}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
