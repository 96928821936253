import { toast } from "react-toastify";
import { useBookingsRequests } from "../../../api/graphql/bookings/useBookingsRequests";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

export const useDefaultLocationHelpers = () => {
  const { user } = useAuthContext();
  const {
    addDefaultLocationMutation,
    defaultLocation,
    deleteDefaultLocation,
    updateDefaultLocation,
  } = useBookingsRequests();

  const addDefaultLocation = async (referenceId: string, name: string) => {
    try {
      await addDefaultLocationMutation({
        accountId: user?.claims.user_id || "",
        referenceId: referenceId,
        tenantId: user?.customerid || "",
        name: name,
      });

      toast.success("Default location created successfully");
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const handleUpdateDefaultLocation = async (
    referenceId: string,
    name: string
  ) => {
    try {
      await updateDefaultLocation({
        accountId: user?.claims.user_id || "",
        referenceId: referenceId,
        tenantId: user?.customerid || "",
        name: name,
      });

      toast.success("Default location updated successfully");
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
    }
  };

  const handleDeleteDefaultLocation = async () => {
    try {
      await deleteDefaultLocation({
        accountId: user?.claims.user_id || "",
      });

      toast.success("Default location deleted successfully");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return {
    defaultLocation,
    addDefaultLocation,
    handleDeleteDefaultLocation,
    handleUpdateDefaultLocation,
  };
};
