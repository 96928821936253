import React from "react";

import { useRouter } from "../../../../lib/hooks/useRouter";

import { tooltips } from "../../../../lib/utils/tooltips";
import { RESERVATIONS_MAP_ROOT_PATH } from "../../../../lib/routes/routes";
import { PageSidebar } from "../../../../components/shared/PageSidebar/PageSidebar";
import { ApolloErrorGuard } from "../../../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../../../components/shared/HandleLoadingState/HandleLoadingState";
import { PageSidebarHeader } from "../../../../components/shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { WorkplacesTreeLoader } from "../WorkplacesTree/WorkplacesTreeLoader/WorkplacesTreeLoader";
import { WorkplacesTreeLevel } from "../WorkplacesTree/WorkplacesTreeLevel/WorkplacesTreeLevel";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { Icon } from "../../../../components/shared/Icon/Icon";
import { Button } from "../../../../components/shared/Button/Button";

interface Props {
  showOnDesktop?: boolean;
  loading: boolean;
  error: any;
  rootWorkplaces?: (string | WorkplaceFields[] | undefined)[];
  showOnInitialization?: boolean;
  isPickerOnly?: boolean;
  className?: string;
  isLocationDefault?: boolean;
  defaultLocation?: any;
  isWayfinder?: boolean;
  onTitleClick: () => void;
  onItemClick?: (item: WorkplaceFields) => void;
  hideToggler?: boolean;
  hasCloseButton?: boolean;
  pathTo: string;
  handleClearFloorMap?: () => void;
}

export const FilteredWorkplacesTree = ({
  showOnDesktop,
  loading,
  error,
  rootWorkplaces,
  showOnInitialization,
  className,
  isPickerOnly,
  isLocationDefault,
  defaultLocation,
  isWayfinder,
  onTitleClick,
  onItemClick,
  hideToggler,
  hasCloseButton,
  pathTo,
  handleClearFloorMap,
}: Props) => {
  const { pathname } = useRouter();

  return (
    <PageSidebar
      className={`PageSidebar--default ${className}`}
      showOnInitialization={showOnInitialization}
      showOnDesktop={showOnDesktop}
      hideToggler={
        pathname === "/bookings/reserve" || isWayfinder || hideToggler
      }
    >
      <>
        <PageSidebarHeader
          title="Workspaces"
          message={
            isLocationDefault
              ? tooltips.defaultLocation.locationPicker
              : tooltips.floorPicker
          }
          direction="left"
          onTitleClick={() => {
            onTitleClick();
          }}
        />

        {hasCloseButton && (
          <span onClick={onTitleClick} className="btn-close">
            <Icon icon="close-icon" />
          </span>
        )}
      </>

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<WorkplacesTreeLoader />}
      >
        <ApolloErrorGuard
          error={error}
          errorComponent={<p>Data couldn't be loaded.</p>}
        >
          <div className="overflow-y-scroll workspace__items">
            {rootWorkplaces &&
              rootWorkplaces.map((item: any) => {
                if (typeof item !== "object") {
                  return;
                }

                return item
                  .filter(
                    (i: WorkplaceFields) =>
                      i.__typename === "Company" ||
                      i.__typename === "Site" ||
                      i.__typename === "Building" ||
                      i.__typename === "Floor"
                  )
                  .map((workplace: WorkplaceFields) => {
                    return (
                      <WorkplacesTreeLevel
                        item={workplace}
                        toggleParent={() => {}}
                        key={`${workplace.id}-tree-root`}
                        filterRoot={true}
                        isPickerOnly={isPickerOnly}
                        isLocationDefault={isLocationDefault}
                        defaultLocation={defaultLocation}
                        isWayfinder={isWayfinder}
                        pathTo={pathTo}
                        onItemClick={onItemClick}
                        typeOfPicker={
                          pathname.includes(RESERVATIONS_MAP_ROOT_PATH)
                            ? "booking"
                            : "floor"
                        }
                      />
                    );
                  });
              })}
          </div>

          {isWayfinder && (
            <div className="flex-a-center gap-min FloorMapPicker">
              <Button
                title="Clear"
                size="small"
                color="outline-secondary"
                onClick={handleClearFloorMap}
              />
              <Button
                title="Ok"
                size="small"
                color="primary"
                onClick={onTitleClick}
              />
            </div>
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </PageSidebar>
  );
};
