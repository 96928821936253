import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Error404 } from "../components/Error404/Error404";

import { useAuthContext } from "./context/Auth/AuthContext";

export type UserRoles =
  | "owner"
  | "admin"
  | "contact"
  | "member"
  | "coordinator";

interface Props extends RouteProps {
  role: UserRoles | undefined;
}

export const UserRoute = (props: Props) => {
  const { role } = props;
  const { user } = useAuthContext();

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (!role || user.claims.role === "admin" || role === user.claims.role) {
    return <Route {...props} />;
  }

  return <Error404 />;
};
