import React, { useState } from "react";
import { toast } from "react-toastify";

import { DEVICES_ROOT_PATH } from "../../../../../lib/routes/routes";

import { useRouter } from "../../../../../lib/hooks/useRouter";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../../lib/context/Customer/CustomerContext";
import { useDeviceFormik } from "../../../../shared/Forms/DeviceForm/lib/useDeviceFormik";

import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";
import { DeviceForm } from "../../../../shared/Forms/DeviceForm/DeviceForm";
import { AddDeviceVariables } from "../../../../../api/graphql/devices/devices";
import { WorkplaceTypes } from "../../AddWorkplaceButton/AddWorkplaceButton";
import { PairingDeviceType } from "../../../../../api/grpc/pairing/pairing";

interface Props {
  parentId?: string;
  isOpen: boolean;
  parentType?: WorkplaceTypes;
  toggle: () => void;
  numberOfDeviceAdded?: number;
  refetch?: () => void;
}

export const AddDeviceModal = (props: Props) => {
  const { pathname } = useRouter();
  const { isOpen, parentId, parentType, toggle, numberOfDeviceAdded } = props;
  const [loading, setLoading] = useState(false);
  const { retrieveTenant } = useCustomerContext();
  const { pairDevice, addDevice, updateDeviceRoom, updateDeviceWayfinder } =
    useWorkplaceRequests();

  const isDevicesPage = pathname === DEVICES_ROOT_PATH;

  const formik = useDeviceFormik({
    newDevice: isDevicesPage,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { tenantId, id } = await retrieveTenant();

        const isWayfinderDevice = values.typeOfWorkspace === "wayFinder";

        if (values.type === "existing") {
          if (isWayfinderDevice) {
            await updateDeviceWayfinder({
              tenantId,
              wayFinderId: parentId || "",
              id: values.existingDeviceId || "",
              unassignedSince: 0,
              warnedUnassigned: false,
            });
            if (props.refetch) {
              props.refetch();
            }
          } else {
            if (numberOfDeviceAdded && numberOfDeviceAdded >= 3) {
              return toast.error(
                "You have hit the maximum nr of assigned devices to this room (3). Disconnect a device and then try again."
              );
            }
            await updateDeviceRoom({
              tenantId,
              roomId: parentId || "",
              id: values.existingDeviceId || "",
              unassignedSince: 0,
              warnedUnassigned: false,
            });
            if (props.refetch) {
              props.refetch();
            }
          }
        }

        if (values.type === "new") {
          if (
            numberOfDeviceAdded &&
            numberOfDeviceAdded >= 3 &&
            !isWayfinderDevice
          ) {
            return toast.error(
              "You have hit the maximum nr of assigned devices to this room (3). Disconnect a device and then try again."
            );
          }

          const {
            response: { deviceId },
          } = await pairDevice(
            values.code || "",
            tenantId,
            isWayfinderDevice
              ? PairingDeviceType.PairingDeviceType_RFX
              : PairingDeviceType.PairingDeviceType_RDX
          );

          let variables: AddDeviceVariables = {
            id: id,
            tenantId,
            name: values.name,
            deviceId: deviceId,
            description: values.description,
            typeOfWorkspace: values.typeOfWorkspace,
            isOnline: values.isOnline,
            unassignedSince: parentId ? 0 : Math.floor(Date.now() / 1000),
            warnedUnassigned: parentId ? false : true,
            autoUpdate: true,
          };

          if (parentId) {
            variables = {
              ...variables,
              [isWayfinderDevice ? "wayFinder" : "room"]: {
                id: parentId,
              },
            };
          }

          await addDevice(variables);
          if (props.refetch) {
            props.refetch();
          }
        }

        toggle();
        toast.success("Device added successfully!");
        formik.resetForm();
      } catch (error: any) {
        if (error?.message?.includes("doesn't match device type")) {
          if (values.typeOfWorkspace === "wayFinder") {
            toast.error("Make sure you're pairing a Wayfinder device.", {
              autoClose: 10000,
            });
          } else {
            toast.error(
              "Make sure you're pairing a Room Display with version 1.0.85 or newer. Update from the app settings on your device.",
              {
                autoClose: 10000,
              }
            );
          }
        }

        //main error
        toast.error(
          error?.message ||
            "Device couldn't be added, please reload and try again!"
        );

        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Device"
      onSubmit={formik.handleSubmit}
    >
      <DeviceForm
        parentType={parentType}
        formik={formik}
        addNewDevice={isDevicesPage}
      />
    </ModalSidebar>
  );
};
