import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useDeleteLocation } from "../../../components/Bookings/helpers/useDeleteDefaultLocation";
import { useUpdateDefaultLocation } from "../../../components/Bookings/helpers/useUpdateDefaultLocation";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import {
  AddDefaultLocationPathResponse,
  AddDefaultLocationPathVariables,
  ADD_DEFAULT_LOCATION_PATH_GQL_MUTATION,
  DeleteDefaultLocationPathVariables,
  GetDefaultLocationPathRequestVariables,
  GetDefaultLocationPathResponse,
  GET_DEFAULT_LOCATION_PATH,
  UpdateDefaultLocationPathVariables,
} from "./bookings";

export const useBookingsRequests = () => {
  const { user } = useAuthContext();

  const addDefaultLocation = useMutation<
    AddDefaultLocationPathResponse,
    AddDefaultLocationPathVariables
  >(ADD_DEFAULT_LOCATION_PATH_GQL_MUTATION);

  const [request] = useDeleteLocation();
  const [updateRequest] = useUpdateDefaultLocation();

  const defaultLocation = useQuery<
    GetDefaultLocationPathResponse,
    GetDefaultLocationPathRequestVariables
  >(GET_DEFAULT_LOCATION_PATH, {
    variables: {
      accountId: user?.claims.user_id || "",
    },
  });

  const addDefaultLocationMutation = async (
    variables: AddDefaultLocationPathVariables
  ) => {
    const [request] = addDefaultLocation;

    return await request({
      variables,
      update: () => {
        defaultLocation.refetch();
      },
      onError: (error: any) => {
        toast.error(error.networkError.result.message);
      },
    });
  };

  const deleteDefaultLocation = async (
    variables: DeleteDefaultLocationPathVariables
  ) => {
    return await request({
      variables,
      update: () => {
        defaultLocation.refetch();
      },
      onError: (error: any) => {
        toast.error(error.networkError.result.message);
      },
    });
  };

  const updateDefaultLocation = async (
    variables: UpdateDefaultLocationPathVariables
  ) => {
    return await updateRequest({
      variables,
      update: () => {
        defaultLocation.refetch();
      },
      onError: (error: any) => {
        toast.error(error.networkError.result.message);
      },
    });
  };

  return {
    addDefaultLocationMutation,
    defaultLocation,
    deleteDefaultLocation,
    updateDefaultLocation,
  };
};
