import React from "react";
import { format } from "date-fns";

import {
  MeetingBehaviorResponse,
  SankeyChartResponse,
} from "../../../../../api/grpc/insights/insights";
import {
  TimeRangeRequest,
  useInsightsRequests,
} from "../../../../../api/grpc/insights/useInsightsRequests";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { useInsightsContext } from "../../InsightsContext";
import {
  BehaviourInsights,
  BehaviourInsightsContext,
} from "./BehaviourContext";

interface Props {
  children: React.ReactNode;
}
export const BehaviourContextProvider = ({ children }: Props) => {
  const { getRoomBehavior, getRoomReservationFlor } = useInsightsRequests();

  const { timeZone } = useTimeZoneContext();

  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const { loading, error, data } = useGrpcQuery<
    TimeRangeRequest,
    MeetingBehaviorResponse
  >({
    method: getRoomBehavior,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingSankey,
    error: errorSankey,
    data: dataSankey,
  } = useGrpcQuery<TimeRangeRequest, SankeyChartResponse>({
    method: getRoomReservationFlor,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const context: BehaviourInsights = {
    loading,
    error,
    data,
    loadingSankey,
    errorSankey,
    dataSankey,
  };

  return (
    <BehaviourInsightsContext.Provider value={context}>
      {children}
    </BehaviourInsightsContext.Provider>
  );
};
