import React, { FC, useMemo } from "react";

import { Icon } from "../../../shared/Icon/Icon";

import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

import styles from "./TimeZoneLabel.module.scss";
import { useTimezones } from "../../../../lib/hooks/useTimezones";

export const TimeZoneLabel: FC = () => {
  const { options } = useTimezones({ displayValue: "UTC" });
  const { openTimeZoneSettings, timeZone } = useTimeZoneContext();
  // memo
  const timeZoneData = useMemo(
    () => options.find((option) => option && option.value === timeZone),
    [options, timeZone]
  );

  return (
    <div className={styles.root} onClick={openTimeZoneSettings}>
      <Icon icon="earth" />
      <span>Displayed in {timeZoneData?.label || timeZone}</span>
    </div>
  );
};
