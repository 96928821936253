import React, { FC, useCallback, useState } from "react";
import { toast } from "react-toastify";

import { useAuthContext } from "../Auth/AuthContext";
import { useAccountRequests } from "../../../api/grpc/account/useAccountRequests";
import { useTimezones } from "../../hooks/useTimezones";
import { useCheckUserPermissions } from "../../hooks/useCheckUserPermissions";

import { TimeZoneContext, TimeZoneContextValues } from "./TimeZoneContext";

import { TimeZoneSettingsModal } from "../../../components/Organization/OrganizationSettings/TimeZoneSettingsModal/TimeZoneSettingsModal";
import { RolePermissions } from "../../../api/grpc/account/account";

export const TimeZoneContextProvider: FC = ({ children }) => {
  const { userTimezone, parseTimezone } = useTimezones({
    displayValue: "UTC",
  });
  const { checkUserPermission } = useCheckUserPermissions();
  // context
  const authContext = useAuthContext();
  // queries
  const { updateUserTimeZone } = useAccountRequests();
  // state
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  // context value
  const value: TimeZoneContextValues = {
    timeZone: userTimezone,
    timeZoneFormatted: parseTimezone(userTimezone)?.label ?? userTimezone,
    openTimeZoneSettings: () => {
      if (
        checkUserPermission(RolePermissions.MODALS_TIMEZONE_SELECTOR_VIEW_EDIT)
      ) {
        setIsSettingsOpen(true);
      }
    },
  };

  const handleTimeZoneChange = useCallback(
    async (timeZone: string) => {
      const currentTimeZone = authContext.user?.timeZone;

      // optimistic update
      authContext.updateUser({
        timeZone,
      });

      try {
        await updateUserTimeZone(timeZone);
      } catch (err: any) {
        toast.error(err?.message);
        // reset initial timeZone if update request failed
        authContext.updateUser({
          timeZone: currentTimeZone,
        });
      }
    },
    [authContext, updateUserTimeZone]
  );

  return (
    <TimeZoneContext.Provider value={value}>
      {children}
      <TimeZoneSettingsModal
        timeZone={value.timeZone}
        onSave={handleTimeZoneChange}
        isOpen={isSettingsOpen}
        setIsOpen={setIsSettingsOpen}
      />
    </TimeZoneContext.Provider>
  );
};
