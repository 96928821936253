import React from "react";
import { MatchingDesk } from "../../../../api/grpc/desk/desk";
import {
  BookingsSearchHeader,
  SearchHeaderInfo,
} from "../BookingsSearchHeader/BookingsSearchHeader";
import { BookingsDesksTable } from "../BookingsSearchTable/BookingsDesksTable";
import { SearchDesk } from "../../../../api/grpc/workplaces/workplaces";

interface Props {
  desks: MatchingDesk[] | null;
  info: SearchHeaderInfo;
  assignedDesk?: SearchDesk;
}

export const BookingsDesksResult = ({ desks, info, assignedDesk }: Props) => {
  return (
    <>
      {desks && desks?.length > 0 ? (
        <div>
          <BookingsSearchHeader type="desk" info={info} />
          <BookingsDesksTable desks={desks} assignedDesk={assignedDesk} />
        </div>
      ) : (
        <div>No results...</div>
      )}
    </>
  );
};
