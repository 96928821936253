import React from "react";
import cs from "classnames";
import { Form, Modal } from "reactstrap";
import { ModalHeaderContent } from "../../../Modals/ModalHeaderContent/ModalHeaderContent";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { ModalFooterContent } from "../../../Modals/ModalFooterContent/ModalFooterContent";

interface Props {
  title: string;
  formTitle?: string;
  children: React.ReactNode;
  secondDescription?: React.JSX.Element;
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  loading: boolean;
  handleSave: () => void;
  toggleModal: () => void;
  error?: Error;
  disabled?: boolean;
  formClassName?: string;
  modalClassName?: string;
}

export const OrganizationSettingsModal = ({
  title,
  children,
  secondDescription,
  isOpen,
  setIsOpen,
  loading,
  handleSave,
  toggleModal,
  formTitle,
  error,
  disabled,
  formClassName,
  modalClassName,
}: Props) => {
  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onToggle={() => setIsOpen((prev) => !prev)}
      className={cs("WorkHours", modalClassName)}
    >
      <ModalHeaderContent title={title} handleToggle={() => setIsOpen(false)} />

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <CenteredBox>
            <LoadingBox minHeight={80} maxWidth={300} />
          </CenteredBox>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>Error fetching data</CenteredBox>}
        >
          {secondDescription}

          <span className="WorkHours__info pb-4">{formTitle}</span>

          <div className="WorkingHoursForm WorkHours__info">
            <Form
              className={
                formClassName
                  ? formClassName
                  : "flex-a-center WorkingHoursForm__selectors pt-2"
              }
            >
              {children}
            </Form>
          </div>

          <ModalFooterContent
            disabled={disabled}
            toggleModal={() => {
              toggleModal();
            }}
            handleSave={() => {
              handleSave();
            }}
          />
        </HandleRequestState>
      </HandleLoadingState>
    </Modal>
  );
};
