import React from "react";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";

export const BookingsReserveLoader = () => {
  return (
    <div className="w-90 mt-3">
      <LoadingBox count={2} minHeight={40} className="mb-3" />

      <div className="BookingsReserveLoader__group">
        <LoadingBox count={2} minHeight={40} />
      </div>

      <LoadingBox minHeight={40} className="mt-3" />
    </div>
  );
};
