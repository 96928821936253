import React, { useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import { useBookingsContext } from "../../Context/BookingsContext";

import { People } from "../../../../api/grpc/contacts/contacts";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { Button } from "../../../shared/Button/Button";

interface Props {
  loading: boolean;
  data?: People;
  error: any;
  handleDoneButton?: (e: { label: string; value: string }[]) => void;
}

export const CreatablePeopleList = ({
  loading,
  error,
  data,
  handleDoneButton,
}: Props) => {
  const { attendees, handleAttendees } = useBookingsContext();

  const options = useMemo(() => {
    return data?.people.map((item) => ({
      value: item.email,
      label: item.displayName,
    }));
  }, [data]);

  const selectedValues = useMemo(() => {
    return attendees.map((attendee) => ({
      label: attendee,
      value: attendee,
    }));
  }, [attendees]);

  return (
    <>
      {loading ? (
        <LoadingBox minHeight={50} />
      ) : error ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <CreatableSelect
          isMulti
          className="SearchWorkplaceForm__createableOptions"
          onChange={(e) => {
            if (e === null) {
              return;
            }
            const values = e.map((item) => item.value);

            handleAttendees(values);
          }}
          value={selectedValues}
          options={options}
          placeholder="Add attendees"
          classNamePrefix={selectedValues.length >= 2 ? "MultipleVal" : ""}
        />
      )}

      <span className="d-block mb-3 text-14 text-secondary font-weight-light">
        Type email when adding new one.
      </span>

      {handleDoneButton && (
        <Button
          title="Done"
          size="small"
          color="outline-primary"
          className="Button__extra-small"
          onClick={() => {
            handleDoneButton(selectedValues);
          }}
        />
      )}
    </>
  );
};
