import React from "react";
import { LoginContextProvider } from "../../lib/context/LoginContext/LoginContextProvider";
import { Login } from "./Login";

export const LoginPage = () => {
  return (
    <LoginContextProvider>
      <Login />
    </LoginContextProvider>
  );
};
