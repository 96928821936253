import React, { useState } from "react";
import { WorkplaceFields } from "../../../api/graphql/workplaces";

import {
  getWorkplaceName,
  getWorkplaceIcon,
} from "../helpers/getWorkplaceInformation";

import { Icon } from "../../shared/Icon/Icon";
import { WorkplaceTypes } from "../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { WorkplaceExpandedList } from "../WorkplaceExpandedList/WorkplaceExpandedList";
import { DeviceType } from "../WorkplaceExpandedList/WorkplaceExpandedDevices/WorkplaceExpandedDevices";

import "./WorkplaceRow.scss";

interface Props {
  parentId?: string;
  onClick: () => void;
  type: WorkplaceTypes;
  data: WorkplaceFields[];
  deviceType?: DeviceType;
}

export const WorkplaceRow = (props: Props) => {
  const { parentId, data, type, deviceType } = props;
  const rowIcon = getWorkplaceIcon(type, true);
  const rowName = getWorkplaceName(type, data.length);

  const [isToggled, setIsToggled] = useState<boolean>(false);

  const handleToggle = () => {
    setIsToggled((prev) => !prev);
  };

  return (
    <>
      {isToggled ? (
        <WorkplaceExpandedList
          type={type}
          data={data}
          parentId={parentId}
          onClose={handleToggle}
          deviceType={deviceType}
        />
      ) : (
        <div onClick={handleToggle} className="WorkplaceRow list-row">
          <div className="WorkplaceRow__toggle">
            <Icon icon="toggle-list-icon" />
          </div>
          <Icon icon={rowIcon} className="WorkplaceRow__icon" />
          <h4 className="WorkplaceRow__title">{rowName}</h4>
          <h4 className="WorkplaceRow__count">{`${data.length} ${
            data.length === 1 ? "item" : "items"
          }`}</h4>
        </div>
      )}
    </>
  );
};
