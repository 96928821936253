import React from "react";
import { BillingInnerCard } from "../../shared/BillingInnerCard/BillingInnerCard";
import { BillingInfoCustomerAddress } from "./BillingInfoCustomerAddress";
import { CBCustomer } from "../../../../api/grpc/subscription/subscription";

interface BillingDetails {
  name: string;
  content?: string | JSX.Element;
}

interface Props {
  data?: CBCustomer;
}

export const BillingInfoDetails = ({ data }: Props) => {
  const items: BillingDetails[] = [
    {
      name: "Company name",
      content: data?.companyName,
    },
    {
      name: "Address",
      content: (
        <BillingInfoCustomerAddress
          address={data?.billingInfo?.line1}
          city={data?.billingInfo?.city}
          zip={data?.billingInfo?.zip}
          country={data?.billingInfo?.country}
        />
      ),
    },
    {
      name: "VAT ID",
      content: data?.billingInfo?.vat,
    },
  ];

  return (
    <div>
      {items &&
        items.map((item: BillingDetails) => (
          <BillingInnerCard
            key={item.name}
            title={item.name}
            content={item.content}
          />
        ))}
    </div>
  );
};
