import React from "react";
import { useTemplateContext } from "../../../../../lib/context/Templates/Templates/TemplatesContext";
import { DeskSettingsStep } from "./DeskStep/DeskSettingsStep";
import { RoomSettingsStep } from "./RoomStep/RoomSettingsStep";

export const SelectSettingsStep = () => {
  const { pickedWorkspaces } = useTemplateContext();

  return (
    <div className="SelectSettingsStep">
      {pickedWorkspaces.data[0].__typename === "Desk" ? (
        <DeskSettingsStep />
      ) : (
        <RoomSettingsStep />
      )}
    </div>
  );
};
