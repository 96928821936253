import React, { useState } from "react";
import { Icon } from "../../../../shared/Icon/Icon";

import { CustomerSettingsVariables } from "../../InsightsCard/Insights/Insights";
import { ServiceEmailModal } from "./ServiceEmailModal";

interface Props {
  handleErrorReportingEmail: ({
    errorReportingEmail,
  }: CustomerSettingsVariables) => void;
  errorReportingEmail?: string;
  loading: boolean;
  error?: Error;
}

export const ServiceEmail = ({
  handleErrorReportingEmail,
  errorReportingEmail,
  loading,
  error,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="flex-a-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Icon icon="service-email" />
        <h3>Service emails</h3>
      </div>

      {isOpen && (
        <ServiceEmailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          email={errorReportingEmail}
          handleErrorReportingEmail={handleErrorReportingEmail}
          loading={loading}
          error={error}
        />
      )}
    </>
  );
};
