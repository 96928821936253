import React from "react";
import { AccountActivated } from "../../components/CompleteSignup/AccountActivated/AccountActivated";
import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";

export const ConfirmAccountRegistration = () => {
  return (
    <AuthenticationPage fullWidth isDefault formTitle={`Account activated!`}>
      <AccountActivated />
    </AuthenticationPage>
  );
};
