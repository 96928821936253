import React, { useState } from "react";
import { toast } from "react-toastify";

import { useFloorFormik } from "../../../../shared/Forms/FloorForm/lib/useFloorFormik";
import { useCustomerContext } from "../../../../../lib/context/Customer/CustomerContext";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";

import { WorkplaceModalProps } from "../AddWorkplaceModal";

import { FloorForm } from "../../../../shared/Forms/FloorForm/FloorForm";
import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";
import { AddFloorVariables } from "../../../../../api/graphql/floors/floors";

export const AddFloorModal = (props: WorkplaceModalProps) => {
  const { isOpen, toggle, parentId, parentType } = props;
  const { addFloor } = useWorkplaceRequests();
  const { retrieveTenant } = useCustomerContext();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFloorFormik({
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const { id, tenantId } = await retrieveTenant();

        const variables: AddFloorVariables = {
          id: parentId || id,
          tenantId,
          name: values.name,
          tags: values.tags,
          map: values.map,
          description: values.description,
        };

        await addFloor(variables, parentType);

        toggle();
        toast.success("Floor added successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Floor couldn't be added, please reload and try again!"
        );
        setLoading(false);
        console.error(error.message);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Floor"
      onSubmit={formik.handleSubmit}
    >
      <FloorForm formik={formik} informationBoxDirection="left" />
    </ModalSidebar>
  );
};
