import { useState } from "react";

export const useTotpInputChange = (initialLength: number = 6) => {
  const [totpCode, setTotpCode] = useState<string[]>(
    Array(initialLength).fill("")
  );

  const handleTotpInputChange = (index: number, value: string) => {
    setTotpCode((prevTotpCode) => {
      const newTotpCode = [...prevTotpCode];
      newTotpCode[index] = value;
      return newTotpCode;
    });
  };

  return { totpCode, handleTotpInputChange };
};
