// @generated by protobuf-ts 2.9.4 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "calendarprovider.proto" (package "calendarprovider", syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * @generated from protobuf message calendarprovider.ListPersonalCalendarsRequest
 */
export interface ListPersonalCalendarsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string accountID = 2;
     */
    accountID: string;
}
/**
 * @generated from protobuf message calendarprovider.PersonalCalendars
 */
export interface PersonalCalendars {
    /**
     * @generated from protobuf field: repeated calendarprovider.PersonalCalendar calendars = 1;
     */
    calendars: PersonalCalendar[];
}
/**
 * @generated from protobuf message calendarprovider.ListCalendarsRequest
 */
export interface ListCalendarsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 2;
     */
    calendarProviderID: string;
}
/**
 * @generated from protobuf message calendarprovider.ListCustomerCalendarsRequest
 */
export interface ListCustomerCalendarsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
}
/**
 * @generated from protobuf message calendarprovider.ListCalendarsResponse
 */
export interface ListCalendarsResponse {
    /**
     * @generated from protobuf field: repeated calendarprovider.Calendar calendars = 1;
     */
    calendars: Calendar[];
}
/**
 * @generated from protobuf message calendarprovider.Room
 */
export interface Room {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message calendarprovider.Calendar
 */
export interface Calendar {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    iD: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: calendarprovider.Room room = 4;
     */
    room?: Room;
}
/**
 * @generated from protobuf message calendarprovider.GGCalendar
 */
export interface GGCalendar {
    /**
     * @generated from protobuf field: calendarprovider.CalendarType CalType = 1 [json_name = "CalType"];
     */
    calType: CalendarType;
    /**
     * @generated from protobuf field: string OfficeHoursStart = 2 [json_name = "OfficeHoursStart"];
     */
    officeHoursStart: string;
    /**
     * @generated from protobuf field: string OfficeHoursEnd = 3 [json_name = "OfficeHoursEnd"];
     */
    officeHoursEnd: string;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
}
/**
 * @generated from protobuf message calendarprovider.EwsConnection
 */
export interface EwsConnection {
    /**
     * @generated from protobuf field: string svc_account = 1;
     */
    svcAccount: string; // Service account email
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string; // Service account password
    /**
     * @generated from protobuf field: string domain = 3;
     */
    domain: string; // Domain of the EWS server
    /**
     * @generated from protobuf field: bool impersonation_enabled = 4;
     */
    impersonationEnabled: boolean; // Activate impersonation request
}
/**
 * @generated from protobuf message calendarprovider.GsuiteConnection
 */
export interface GsuiteConnection {
    /**
     * @generated from protobuf field: string oAuthToken = 1;
     */
    oAuthToken: string; // An oAuth 2.0 refresh token
    /**
     * @generated from protobuf field: calendarprovider.CalendarType calendarType = 2;
     */
    calendarType: CalendarType;
}
/**
 * @generated from protobuf message calendarprovider.O365Connection
 */
export interface O365Connection {
    /**
     * @generated from protobuf field: string oAuthToken = 1;
     */
    oAuthToken: string; // An oAuth 2.0 refresh token
    /**
     * @generated from protobuf field: calendarprovider.CalendarType calendarType = 2;
     */
    calendarType: CalendarType;
}
/**
 * GGCalendarProvider
 *
 * @generated from protobuf message calendarprovider.GGCalendarProvider
 */
export interface GGCalendarProvider {
    /**
     * Internal ID
     *
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    iD: string;
    /**
     * Human friendly name
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Calendar provider type
     *
     * @generated from protobuf field: calendarprovider.CalendarType CalType = 3 [json_name = "CalType"];
     */
    calType: CalendarType;
    /**
     * Internal token generated - maybe this is an Auth token that we can create and pass instead of passwords.
     *
     * @generated from protobuf field: string token = 4;
     */
    token: string;
    /**
     * Email of the provider account
     *
     * @generated from protobuf field: string email = 5;
     */
    email: string;
    /**
     * Timestamp when this provider was created
     *
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 6;
     */
    createdAt?: Timestamp;
    /**
     * Timestamp when this provider was last updated
     *
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 7;
     */
    updatedAt?: Timestamp;
    /**
     * deviceType describe witch device using for creating connection
     *
     * @generated from protobuf field: calendarprovider.CalendarProviderDeviceType deviceType = 8;
     */
    deviceType: CalendarProviderDeviceType;
    /**
     * environment describe which doman should we use for graph and azure
     *
     * @generated from protobuf field: calendarprovider.MicrosoftEnvironment environment = 9;
     */
    environment?: MicrosoftEnvironment;
    /**
     * status describe if provider is active or not
     *
     * @generated from protobuf field: calendarprovider.GGCalendarProviderStatus status = 10;
     */
    status: GGCalendarProviderStatus;
    /**
     * api_error describe error message from provider
     *
     * @generated from protobuf field: string api_error = 11;
     */
    apiError: string;
    /**
     * custom connection host
     *
     * @generated from protobuf field: string host_url = 12;
     */
    hostUrl: string;
}
/**
 * @generated from protobuf message calendarprovider.MicrosoftEnvironment
 */
export interface MicrosoftEnvironment {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string graph_url = 2;
     */
    graphUrl: string;
    /**
     * @generated from protobuf field: string azure_url = 3;
     */
    azureUrl: string;
}
/**
 * @generated from protobuf message calendarprovider.LinkPersonalCalendarRequest
 */
export interface LinkPersonalCalendarRequest {
    /**
     * @generated from protobuf oneof: CalendarType
     */
    calendarType: {
        oneofKind: "ews";
        /**
         * @generated from protobuf field: calendarprovider.EwsConnection ews = 1;
         */
        ews: EwsConnection;
    } | {
        oneofKind: "gsuite";
        /**
         * @generated from protobuf field: calendarprovider.GsuiteConnection gsuite = 2;
         */
        gsuite: GsuiteConnection;
    } | {
        oneofKind: "o365";
        /**
         * @generated from protobuf field: calendarprovider.O365Connection o365 = 3;
         */
        o365: O365Connection;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string; // Custom name for the calendar provider connection
    /**
     * @generated from protobuf field: string customerID = 5;
     */
    customerID: string; // CustomerID to save this connection under
    /**
     * @generated from protobuf field: string accountID = 6;
     */
    accountID: string;
    /**
     * @generated from protobuf field: calendarprovider.CalendarProviderDeviceType deviceType = 7;
     */
    deviceType: CalendarProviderDeviceType; // deviceType describe witch device using for creating connection
}
/**
 * @generated from protobuf message calendarprovider.PersonalCalendar
 */
export interface PersonalCalendar {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    iD: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * @generated from protobuf field: string host_url = 4;
     */
    hostUrl: string;
}
/**
 * CreateProviderRequest
 *
 * @generated from protobuf message calendarprovider.CreateProviderRequest
 */
export interface CreateProviderRequest {
    /**
     * @generated from protobuf oneof: CType
     */
    cType: {
        oneofKind: "ews";
        /**
         * @generated from protobuf field: calendarprovider.EwsConnection ews = 1;
         */
        ews: EwsConnection;
    } | {
        oneofKind: "gsuite";
        /**
         * @generated from protobuf field: calendarprovider.GsuiteConnection gsuite = 2;
         */
        gsuite: GsuiteConnection;
    } | {
        oneofKind: "o365";
        /**
         * @generated from protobuf field: calendarprovider.O365Connection o365 = 3;
         */
        o365: O365Connection;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string; // Custom name for the calendar provider connection
    /**
     * @generated from protobuf field: string customerID = 5;
     */
    customerID: string; // CustomerID to save this connection under
    /**
     * @generated from protobuf field: calendarprovider.CalendarProviderDeviceType deviceType = 6;
     */
    deviceType: CalendarProviderDeviceType; // deviceType describe witch device using for creating connection
    /**
     * @generated from protobuf field: calendarprovider.MicrosoftEnvironment environment = 7;
     */
    environment?: MicrosoftEnvironment; //  environment describe which domain should we use for graph and azure
}
/**
 * Provider
 *
 * @generated from protobuf message calendarprovider.Provider
 */
export interface Provider {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    iD: string; // The ID created in our system
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // Human friendly name from our system
    /**
     * @generated from protobuf field: string tokenName = 3;
     */
    tokenName: string; // Name of Google account from idToken. Only used for Google.
    /**
     * @generated from protobuf field: string tokenEmail = 4;
     */
    tokenEmail: string; // Email of Google account from idToken. Only used for Google.
}
/**
 * RetrieveListRequest
 *
 * @generated from protobuf message calendarprovider.RetrieveProviderListRequest
 */
export interface RetrieveProviderListRequest {
    /**
     * @generated from protobuf field: calendarprovider.CalendarType CalType = 1 [json_name = "CalType"];
     */
    calType: CalendarType;
    /**
     * @generated from protobuf field: int32 limit = 2;
     */
    limit: number;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
}
/**
 * RetrieveListResponse
 *
 * @generated from protobuf message calendarprovider.RetrieveProviderListResponse
 */
export interface RetrieveProviderListResponse {
    /**
     * List of providers
     *
     * @generated from protobuf field: repeated calendarprovider.GGCalendarProvider providers = 1;
     */
    providers: GGCalendarProvider[];
}
/**
 * RetrieveListRequest
 *
 * @generated from protobuf message calendarprovider.RetrieveProviderRequest
 */
export interface RetrieveProviderRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string ID = 2 [json_name = "ID"];
     */
    iD: string;
}
/**
 * RetrieveResponse
 *
 * @generated from protobuf message calendarprovider.RetrieveProviderResponse
 */
export interface RetrieveProviderResponse {
    /**
     * The calendar provider
     *
     * @generated from protobuf field: calendarprovider.GGCalendarProvider provider = 1;
     */
    provider?: GGCalendarProvider;
}
/**
 * DeleteRequest
 *
 * @generated from protobuf message calendarprovider.DeleteProviderRequest
 */
export interface DeleteProviderRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string ID = 2 [json_name = "ID"];
     */
    iD: string;
}
/**
 * DeleteResponse
 *
 * @generated from protobuf message calendarprovider.DeleteProviderResponse
 */
export interface DeleteProviderResponse {
}
/**
 * DeleteRequest
 *
 * @generated from protobuf message calendarprovider.RetrieveEWSProviderRequest
 */
export interface RetrieveEWSProviderRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string ID = 2 [json_name = "ID"];
     */
    iD: string;
}
/**
 * Update EWS Provider connection details
 *
 * @generated from protobuf message calendarprovider.EWSProvider
 */
export interface EWSProvider {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string svc_account = 2;
     */
    svcAccount: string; // Service account email
    /**
     * @generated from protobuf field: string password = 3;
     */
    password: string; // Service account password
    /**
     * @generated from protobuf field: string domain = 4;
     */
    domain: string; // Domain of the EWS server
    /**
     * @generated from protobuf field: string customerID = 5;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string ID = 6 [json_name = "ID"];
     */
    iD: string; // Our own ID
    /**
     * @generated from protobuf field: bool impersonation_enabled = 7;
     */
    impersonationEnabled: boolean; // flag for impersonation request
}
/**
 * @generated from protobuf message calendarprovider.AssignRequest
 */
export interface AssignRequest {
    /**
     * The organization ID
     *
     * @generated from protobuf field: string organization = 1;
     */
    organization: string;
    /**
     * A calendar type
     *
     * @generated from protobuf field: calendarprovider.CalendarType type = 2;
     */
    type: CalendarType;
    /**
     * The calendarProvider ID
     *
     * @generated from protobuf field: string ID = 3 [json_name = "ID"];
     */
    iD: string;
    /**
     * The roomID to assign the calendar to
     *
     * @generated from protobuf field: string roomID = 4;
     */
    roomID: string;
}
/**
 * @generated from protobuf message calendarprovider.AssignResponse
 */
export interface AssignResponse {
}
/**
 * @generated from protobuf message calendarprovider.UnassignRequest
 */
export interface UnassignRequest {
    /**
     * The organization ID
     *
     * @generated from protobuf field: string organization = 1;
     */
    organization: string;
    /**
     * A calendar type
     *
     * @generated from protobuf field: calendarprovider.CalendarType type = 2;
     */
    type: CalendarType;
    /**
     * The calendarProvider ID
     *
     * @generated from protobuf field: string ID = 3 [json_name = "ID"];
     */
    iD: string;
}
/**
 * @generated from protobuf message calendarprovider.UnassignResponse
 */
export interface UnassignResponse {
}
/**
 * GetSupportedIntegrationsRequest
 *
 * @generated from protobuf message calendarprovider.GetSupportedIntegrationsRequest
 */
export interface GetSupportedIntegrationsRequest {
}
/**
 * GetSupportedIntegrationsResponse
 *
 * @generated from protobuf message calendarprovider.GetSupportedIntegrationsResponse
 */
export interface GetSupportedIntegrationsResponse {
    /**
     * A list of all the integrations we support
     *
     * @generated from protobuf field: repeated string integrations = 1;
     */
    integrations: string[];
}
/**
 * @generated from protobuf message calendarprovider.ValidateEWSRoomRequest
 */
export interface ValidateEWSRoomRequest {
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 4;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: string calendarID = 5;
     */
    calendarID: string;
}
/**
 * @generated from protobuf message calendarprovider.ValidateEWSRoomResponse
 */
export interface ValidateEWSRoomResponse {
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
}
/**
 * @generated from protobuf message calendarprovider.UpdateCalendarProviderCredentialsRequest
 */
export interface UpdateCalendarProviderCredentialsRequest {
    /**
     * @generated from protobuf oneof: CType
     */
    cType: {
        oneofKind: "ews";
        /**
         * @generated from protobuf field: calendarprovider.EwsConnection ews = 1;
         */
        ews: EwsConnection;
    } | {
        oneofKind: "gsuite";
        /**
         * @generated from protobuf field: calendarprovider.GsuiteConnection gsuite = 2;
         */
        gsuite: GsuiteConnection;
    } | {
        oneofKind: "o365";
        /**
         * @generated from protobuf field: calendarprovider.O365Connection o365 = 3;
         */
        o365: O365Connection;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string customerID = 4;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 5;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: calendarprovider.CalendarProviderDeviceType deviceType = 6;
     */
    deviceType: CalendarProviderDeviceType;
}
/**
 * @generated from protobuf message calendarprovider.CreateDirectoryProviderRequest
 */
export interface CreateDirectoryProviderRequest {
    /**
     * @generated from protobuf field: string oAuthToken = 1;
     */
    oAuthToken: string;
    /**
     * @generated from protobuf field: calendarprovider.CalendarType providerType = 2;
     */
    providerType: CalendarType;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
}
/**
 * @generated from protobuf message calendarprovider.GenerateAuthLinkRequest
 */
export interface GenerateAuthLinkRequest {
    /**
     * @generated from protobuf field: string redirectURL = 1;
     */
    redirectURL: string;
    /**
     * @generated from protobuf field: calendarprovider.CalendarType integrationType = 2;
     */
    integrationType: CalendarType;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
}
/**
 * @generated from protobuf message calendarprovider.GenerateAuthLinkResponse
 */
export interface GenerateAuthLinkResponse {
    /**
     * @generated from protobuf field: string authLink = 1;
     */
    authLink: string;
    /**
     * @generated from protobuf field: calendarprovider.CalendarType integrationType = 2;
     */
    integrationType: CalendarType;
}
/**
 * @generated from protobuf message calendarprovider.ListDirectoryProviderRequest
 */
export interface ListDirectoryProviderRequest {
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
}
/**
 * @generated from protobuf message calendarprovider.DirectorySyncIntegration
 */
export interface DirectorySyncIntegration {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    iD: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Calendar provider type
     *
     * @generated from protobuf field: calendarprovider.CalendarType integrationType = 3;
     */
    integrationType: CalendarType;
    /**
     * Internal token generated - maybe this is an Auth token that we can create and pass instead of passwords.
     *
     * @generated from protobuf field: string token = 4;
     */
    token: string;
    /**
     * Timestamp when this provider was created
     *
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * Timestamp when this provider was last updated
     *
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 6;
     */
    updatedAt?: Timestamp;
    /**
     * status describe if provider is active or not
     *
     * @generated from protobuf field: calendarprovider.GGCalendarProviderStatus status = 7;
     */
    status: GGCalendarProviderStatus;
    /**
     * integration auth api error
     *
     * @generated from protobuf field: string api_error = 8;
     */
    apiError: string;
}
/**
 * @generated from protobuf message calendarprovider.ListDirectoryProviderResponse
 */
export interface ListDirectoryProviderResponse {
    /**
     * @generated from protobuf field: repeated calendarprovider.DirectorySyncIntegration integrations = 1;
     */
    integrations: DirectorySyncIntegration[];
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message calendarprovider.RetrieveDirectoryProviderRequest
 */
export interface RetrieveDirectoryProviderRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string ID = 2 [json_name = "ID"];
     */
    iD: string;
}
/**
 * @generated from protobuf message calendarprovider.RetrieveDirectoryProviderResponse
 */
export interface RetrieveDirectoryProviderResponse {
    /**
     * @generated from protobuf field: calendarprovider.DirectorySyncIntegration integration = 1;
     */
    integration?: DirectorySyncIntegration;
}
/**
 * @generated from protobuf message calendarprovider.DeleteDirectoryProviderRequest
 */
export interface DeleteDirectoryProviderRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string ID = 2 [json_name = "ID"];
     */
    iD: string;
}
/**
 * @generated from protobuf message calendarprovider.ListMatchedCalendarIntegrationsRequest
 */
export interface ListMatchedCalendarIntegrationsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 2;
     */
    calendarProviderID: string;
}
/**
 * @generated from protobuf message calendarprovider.ListMatchedCalendarIntegrationsResponse
 */
export interface ListMatchedCalendarIntegrationsResponse {
    /**
     * @generated from protobuf field: repeated calendarprovider.PersonalCalendar integrations = 1;
     */
    integrations: PersonalCalendar[];
}
/**
 * @generated from protobuf message calendarprovider.ValidateCalendarIntegrationCredentialsRequest
 */
export interface ValidateCalendarIntegrationCredentialsRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 2;
     */
    calendarProviderID: string;
}
/**
 * @generated from protobuf message calendarprovider.ValidateCalendarIntegrationCredentialsResponse
 */
export interface ValidateCalendarIntegrationCredentialsResponse {
    /**
     * @generated from protobuf field: calendarprovider.CalendarCredentialsStatus status = 1;
     */
    status: CalendarCredentialsStatus;
}
/**
 * @generated from protobuf message calendarprovider.UpdateCalendarIntegrationStatusRequest
 */
export interface UpdateCalendarIntegrationStatusRequest {
    /**
     * @generated from protobuf field: string CalendarProviderID = 1 [json_name = "CalendarProviderID"];
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: calendarprovider.GGCalendarProviderStatus status = 3;
     */
    status: GGCalendarProviderStatus;
    /**
     * @generated from protobuf field: string api_error = 4;
     */
    apiError: string;
}
/**
 * @generated from protobuf message calendarprovider.UpdateCalendarIntegrationStatusResponse
 */
export interface UpdateCalendarIntegrationStatusResponse {
    /**
     * @generated from protobuf field: calendarprovider.GGCalendarProvider provider = 1;
     */
    provider?: GGCalendarProvider;
}
/**
 * @generated from protobuf message calendarprovider.UpdateDirectoryIntegrationStatusRequest
 */
export interface UpdateDirectoryIntegrationStatusRequest {
    /**
     * @generated from protobuf field: string integrationID = 1;
     */
    integrationID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: calendarprovider.GGCalendarProviderStatus status = 3;
     */
    status: GGCalendarProviderStatus;
    /**
     * @generated from protobuf field: string api_error = 4;
     */
    apiError: string;
}
/**
 * @generated from protobuf message calendarprovider.UpdateDirectoryIntegrationStatusResponse
 */
export interface UpdateDirectoryIntegrationStatusResponse {
    /**
     * @generated from protobuf field: calendarprovider.DirectorySyncIntegration integration = 1;
     */
    integration?: DirectorySyncIntegration;
}
/**
 * @generated from protobuf message calendarprovider.UpdateDirectoryProviderCredentialsRequest
 */
export interface UpdateDirectoryProviderCredentialsRequest {
    /**
     * @generated from protobuf oneof: CType
     */
    cType: {
        oneofKind: "gsuite";
        /**
         * @generated from protobuf field: calendarprovider.GsuiteConnection gsuite = 2;
         */
        gsuite: GsuiteConnection;
    } | {
        oneofKind: "o365";
        /**
         * @generated from protobuf field: calendarprovider.O365Connection o365 = 3;
         */
        o365: O365Connection;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string customerID = 4;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string providerID = 5;
     */
    providerID: string;
}
/**
 * @generated from protobuf enum calendarprovider.CalendarType
 */
export enum CalendarType {
    /**
     * @generated from protobuf enum value: ALL = 0;
     */
    ALL = 0,
    /**
     * @generated from protobuf enum value: EWS = 1;
     */
    EWS = 1,
    /**
     * @generated from protobuf enum value: GSUITE = 2;
     */
    GSUITE = 2,
    /**
     * @generated from protobuf enum value: O365 = 3;
     */
    O365 = 3,
    /**
     * @generated from protobuf enum value: O365_MOBILE = 4;
     */
    O365_MOBILE = 4,
    /**
     * @generated from protobuf enum value: GSUITE_MOBILE = 5;
     */
    GSUITE_MOBILE = 5,
    /**
     * @generated from protobuf enum value: O365_WEB = 6;
     */
    O365_WEB = 6,
    /**
     * @generated from protobuf enum value: GSUITE_WEB = 7;
     */
    GSUITE_WEB = 7,
    /**
     * @generated from protobuf enum value: GSUITE_SYNC = 8;
     */
    GSUITE_SYNC = 8,
    /**
     * @generated from protobuf enum value: O365_SYNC = 9;
     */
    O365_SYNC = 9
}
/**
 * @generated from protobuf enum calendarprovider.CalendarProviderDeviceType
 */
export enum CalendarProviderDeviceType {
    /**
     * @generated from protobuf enum value: WEB = 0;
     */
    WEB = 0,
    /**
     * @generated from protobuf enum value: IOS = 1;
     */
    IOS = 1,
    /**
     * @generated from protobuf enum value: ANDROID = 2;
     */
    ANDROID = 2,
    /**
     * @generated from protobuf enum value: WEB_SYNC = 3;
     */
    WEB_SYNC = 3
}
/**
 * @generated from protobuf enum calendarprovider.GGCalendarProviderStatus
 */
export enum GGCalendarProviderStatus {
    /**
     * @generated from protobuf enum value: UNKNOWN_STATUS = 0;
     */
    UNKNOWN_STATUS = 0,
    /**
     * @generated from protobuf enum value: FAILED = 1;
     */
    FAILED = 1,
    /**
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2
}
/**
 * @generated from protobuf enum calendarprovider.CalendarCredentialsStatus
 */
export enum CalendarCredentialsStatus {
    /**
     * @generated from protobuf enum value: CalendarCredentialsStatusUnknown = 0;
     */
    CalendarCredentialsStatusUnknown = 0,
    /**
     * @generated from protobuf enum value: CalendarCredentialsStatusValid = 1;
     */
    CalendarCredentialsStatusValid = 1,
    /**
     * @generated from protobuf enum value: CalendarCredentialsStatusInvalid = 2;
     */
    CalendarCredentialsStatusInvalid = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class ListPersonalCalendarsRequest$Type extends MessageType<ListPersonalCalendarsRequest> {
    constructor() {
        super("calendarprovider.ListPersonalCalendarsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListPersonalCalendarsRequest>): ListPersonalCalendarsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.accountID = "";
        if (value !== undefined)
            reflectionMergePartial<ListPersonalCalendarsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPersonalCalendarsRequest): ListPersonalCalendarsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 2:
                    message.accountID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPersonalCalendarsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 2; */
        if (message.accountID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListPersonalCalendarsRequest
 */
export const ListPersonalCalendarsRequest = new ListPersonalCalendarsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalCalendars$Type extends MessageType<PersonalCalendars> {
    constructor() {
        super("calendarprovider.PersonalCalendars", [
            { no: 1, name: "calendars", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PersonalCalendar }
        ]);
    }
    create(value?: PartialMessage<PersonalCalendars>): PersonalCalendars {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.calendars = [];
        if (value !== undefined)
            reflectionMergePartial<PersonalCalendars>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalCalendars): PersonalCalendars {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated calendarprovider.PersonalCalendar calendars */ 1:
                    message.calendars.push(PersonalCalendar.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalCalendars, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated calendarprovider.PersonalCalendar calendars = 1; */
        for (let i = 0; i < message.calendars.length; i++)
            PersonalCalendar.internalBinaryWrite(message.calendars[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.PersonalCalendars
 */
export const PersonalCalendars = new PersonalCalendars$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCalendarsRequest$Type extends MessageType<ListCalendarsRequest> {
    constructor() {
        super("calendarprovider.ListCalendarsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListCalendarsRequest>): ListCalendarsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        if (value !== undefined)
            reflectionMergePartial<ListCalendarsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCalendarsRequest): ListCalendarsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 2:
                    message.calendarProviderID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCalendarsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 2; */
        if (message.calendarProviderID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListCalendarsRequest
 */
export const ListCalendarsRequest = new ListCalendarsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomerCalendarsRequest$Type extends MessageType<ListCustomerCalendarsRequest> {
    constructor() {
        super("calendarprovider.ListCustomerCalendarsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListCustomerCalendarsRequest>): ListCustomerCalendarsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<ListCustomerCalendarsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomerCalendarsRequest): ListCustomerCalendarsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomerCalendarsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListCustomerCalendarsRequest
 */
export const ListCustomerCalendarsRequest = new ListCustomerCalendarsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCalendarsResponse$Type extends MessageType<ListCalendarsResponse> {
    constructor() {
        super("calendarprovider.ListCalendarsResponse", [
            { no: 1, name: "calendars", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Calendar }
        ]);
    }
    create(value?: PartialMessage<ListCalendarsResponse>): ListCalendarsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.calendars = [];
        if (value !== undefined)
            reflectionMergePartial<ListCalendarsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCalendarsResponse): ListCalendarsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated calendarprovider.Calendar calendars */ 1:
                    message.calendars.push(Calendar.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCalendarsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated calendarprovider.Calendar calendars = 1; */
        for (let i = 0; i < message.calendars.length; i++)
            Calendar.internalBinaryWrite(message.calendars[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListCalendarsResponse
 */
export const ListCalendarsResponse = new ListCalendarsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Room$Type extends MessageType<Room> {
    constructor() {
        super("calendarprovider.Room", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Room>): Room {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<Room>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Room): Room {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Room, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.Room
 */
export const Room = new Room$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Calendar$Type extends MessageType<Calendar> {
    constructor() {
        super("calendarprovider.Calendar", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "room", kind: "message", T: () => Room }
        ]);
    }
    create(value?: PartialMessage<Calendar>): Calendar {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.iD = "";
        message.name = "";
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<Calendar>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Calendar): Calendar {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* calendarprovider.Room room */ 4:
                    message.room = Room.internalBinaryRead(reader, reader.uint32(), options, message.room);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Calendar, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.iD);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* calendarprovider.Room room = 4; */
        if (message.room)
            Room.internalBinaryWrite(message.room, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.Calendar
 */
export const Calendar = new Calendar$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GGCalendar$Type extends MessageType<GGCalendar> {
    constructor() {
        super("calendarprovider.GGCalendar", [
            { no: 1, name: "CalType", kind: "enum", jsonName: "CalType", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 2, name: "OfficeHoursStart", kind: "scalar", jsonName: "OfficeHoursStart", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "OfficeHoursEnd", kind: "scalar", jsonName: "OfficeHoursEnd", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GGCalendar>): GGCalendar {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.calType = 0;
        message.officeHoursStart = "";
        message.officeHoursEnd = "";
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<GGCalendar>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GGCalendar): GGCalendar {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.CalendarType CalType = 1 [json_name = "CalType"];*/ 1:
                    message.calType = reader.int32();
                    break;
                case /* string OfficeHoursStart = 2 [json_name = "OfficeHoursStart"];*/ 2:
                    message.officeHoursStart = reader.string();
                    break;
                case /* string OfficeHoursEnd = 3 [json_name = "OfficeHoursEnd"];*/ 3:
                    message.officeHoursEnd = reader.string();
                    break;
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GGCalendar, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.CalendarType CalType = 1 [json_name = "CalType"]; */
        if (message.calType !== 0)
            writer.tag(1, WireType.Varint).int32(message.calType);
        /* string OfficeHoursStart = 2 [json_name = "OfficeHoursStart"]; */
        if (message.officeHoursStart !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.officeHoursStart);
        /* string OfficeHoursEnd = 3 [json_name = "OfficeHoursEnd"]; */
        if (message.officeHoursEnd !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.officeHoursEnd);
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.GGCalendar
 */
export const GGCalendar = new GGCalendar$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EwsConnection$Type extends MessageType<EwsConnection> {
    constructor() {
        super("calendarprovider.EwsConnection", [
            { no: 1, name: "svc_account", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "impersonation_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<EwsConnection>): EwsConnection {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.svcAccount = "";
        message.password = "";
        message.domain = "";
        message.impersonationEnabled = false;
        if (value !== undefined)
            reflectionMergePartial<EwsConnection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EwsConnection): EwsConnection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string svc_account */ 1:
                    message.svcAccount = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                case /* string domain */ 3:
                    message.domain = reader.string();
                    break;
                case /* bool impersonation_enabled */ 4:
                    message.impersonationEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EwsConnection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string svc_account = 1; */
        if (message.svcAccount !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.svcAccount);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        /* string domain = 3; */
        if (message.domain !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.domain);
        /* bool impersonation_enabled = 4; */
        if (message.impersonationEnabled !== false)
            writer.tag(4, WireType.Varint).bool(message.impersonationEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.EwsConnection
 */
export const EwsConnection = new EwsConnection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GsuiteConnection$Type extends MessageType<GsuiteConnection> {
    constructor() {
        super("calendarprovider.GsuiteConnection", [
            { no: 1, name: "oAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarType", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] }
        ]);
    }
    create(value?: PartialMessage<GsuiteConnection>): GsuiteConnection {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oAuthToken = "";
        message.calendarType = 0;
        if (value !== undefined)
            reflectionMergePartial<GsuiteConnection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GsuiteConnection): GsuiteConnection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string oAuthToken */ 1:
                    message.oAuthToken = reader.string();
                    break;
                case /* calendarprovider.CalendarType calendarType */ 2:
                    message.calendarType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GsuiteConnection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string oAuthToken = 1; */
        if (message.oAuthToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.oAuthToken);
        /* calendarprovider.CalendarType calendarType = 2; */
        if (message.calendarType !== 0)
            writer.tag(2, WireType.Varint).int32(message.calendarType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.GsuiteConnection
 */
export const GsuiteConnection = new GsuiteConnection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class O365Connection$Type extends MessageType<O365Connection> {
    constructor() {
        super("calendarprovider.O365Connection", [
            { no: 1, name: "oAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarType", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] }
        ]);
    }
    create(value?: PartialMessage<O365Connection>): O365Connection {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oAuthToken = "";
        message.calendarType = 0;
        if (value !== undefined)
            reflectionMergePartial<O365Connection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: O365Connection): O365Connection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string oAuthToken */ 1:
                    message.oAuthToken = reader.string();
                    break;
                case /* calendarprovider.CalendarType calendarType */ 2:
                    message.calendarType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: O365Connection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string oAuthToken = 1; */
        if (message.oAuthToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.oAuthToken);
        /* calendarprovider.CalendarType calendarType = 2; */
        if (message.calendarType !== 0)
            writer.tag(2, WireType.Varint).int32(message.calendarType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.O365Connection
 */
export const O365Connection = new O365Connection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GGCalendarProvider$Type extends MessageType<GGCalendarProvider> {
    constructor() {
        super("calendarprovider.GGCalendarProvider", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "CalType", kind: "enum", jsonName: "CalType", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 4, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "deviceType", kind: "enum", T: () => ["calendarprovider.CalendarProviderDeviceType", CalendarProviderDeviceType] },
            { no: 9, name: "environment", kind: "message", T: () => MicrosoftEnvironment },
            { no: 10, name: "status", kind: "enum", T: () => ["calendarprovider.GGCalendarProviderStatus", GGCalendarProviderStatus] },
            { no: 11, name: "api_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "host_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GGCalendarProvider>): GGCalendarProvider {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.iD = "";
        message.name = "";
        message.calType = 0;
        message.token = "";
        message.email = "";
        message.deviceType = 0;
        message.status = 0;
        message.apiError = "";
        message.hostUrl = "";
        if (value !== undefined)
            reflectionMergePartial<GGCalendarProvider>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GGCalendarProvider): GGCalendarProvider {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* calendarprovider.CalendarType CalType = 3 [json_name = "CalType"];*/ 3:
                    message.calType = reader.int32();
                    break;
                case /* string token */ 4:
                    message.token = reader.string();
                    break;
                case /* string email */ 5:
                    message.email = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 6:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 7:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* calendarprovider.CalendarProviderDeviceType deviceType */ 8:
                    message.deviceType = reader.int32();
                    break;
                case /* calendarprovider.MicrosoftEnvironment environment */ 9:
                    message.environment = MicrosoftEnvironment.internalBinaryRead(reader, reader.uint32(), options, message.environment);
                    break;
                case /* calendarprovider.GGCalendarProviderStatus status */ 10:
                    message.status = reader.int32();
                    break;
                case /* string api_error */ 11:
                    message.apiError = reader.string();
                    break;
                case /* string host_url */ 12:
                    message.hostUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GGCalendarProvider, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.iD);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* calendarprovider.CalendarType CalType = 3 [json_name = "CalType"]; */
        if (message.calType !== 0)
            writer.tag(3, WireType.Varint).int32(message.calType);
        /* string token = 4; */
        if (message.token !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.token);
        /* string email = 5; */
        if (message.email !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.email);
        /* google.protobuf.Timestamp created_at = 6; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 7; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.CalendarProviderDeviceType deviceType = 8; */
        if (message.deviceType !== 0)
            writer.tag(8, WireType.Varint).int32(message.deviceType);
        /* calendarprovider.MicrosoftEnvironment environment = 9; */
        if (message.environment)
            MicrosoftEnvironment.internalBinaryWrite(message.environment, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.GGCalendarProviderStatus status = 10; */
        if (message.status !== 0)
            writer.tag(10, WireType.Varint).int32(message.status);
        /* string api_error = 11; */
        if (message.apiError !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.apiError);
        /* string host_url = 12; */
        if (message.hostUrl !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.hostUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.GGCalendarProvider
 */
export const GGCalendarProvider = new GGCalendarProvider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MicrosoftEnvironment$Type extends MessageType<MicrosoftEnvironment> {
    constructor() {
        super("calendarprovider.MicrosoftEnvironment", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "graph_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "azure_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MicrosoftEnvironment>): MicrosoftEnvironment {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.graphUrl = "";
        message.azureUrl = "";
        if (value !== undefined)
            reflectionMergePartial<MicrosoftEnvironment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MicrosoftEnvironment): MicrosoftEnvironment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string graph_url */ 2:
                    message.graphUrl = reader.string();
                    break;
                case /* string azure_url */ 3:
                    message.azureUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MicrosoftEnvironment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string graph_url = 2; */
        if (message.graphUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.graphUrl);
        /* string azure_url = 3; */
        if (message.azureUrl !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.azureUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.MicrosoftEnvironment
 */
export const MicrosoftEnvironment = new MicrosoftEnvironment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkPersonalCalendarRequest$Type extends MessageType<LinkPersonalCalendarRequest> {
    constructor() {
        super("calendarprovider.LinkPersonalCalendarRequest", [
            { no: 1, name: "ews", kind: "message", oneof: "calendarType", T: () => EwsConnection },
            { no: 2, name: "gsuite", kind: "message", oneof: "calendarType", T: () => GsuiteConnection },
            { no: 3, name: "o365", kind: "message", oneof: "calendarType", T: () => O365Connection },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "deviceType", kind: "enum", T: () => ["calendarprovider.CalendarProviderDeviceType", CalendarProviderDeviceType] }
        ]);
    }
    create(value?: PartialMessage<LinkPersonalCalendarRequest>): LinkPersonalCalendarRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.calendarType = { oneofKind: undefined };
        message.name = "";
        message.customerID = "";
        message.accountID = "";
        message.deviceType = 0;
        if (value !== undefined)
            reflectionMergePartial<LinkPersonalCalendarRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkPersonalCalendarRequest): LinkPersonalCalendarRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.EwsConnection ews */ 1:
                    message.calendarType = {
                        oneofKind: "ews",
                        ews: EwsConnection.internalBinaryRead(reader, reader.uint32(), options, (message.calendarType as any).ews)
                    };
                    break;
                case /* calendarprovider.GsuiteConnection gsuite */ 2:
                    message.calendarType = {
                        oneofKind: "gsuite",
                        gsuite: GsuiteConnection.internalBinaryRead(reader, reader.uint32(), options, (message.calendarType as any).gsuite)
                    };
                    break;
                case /* calendarprovider.O365Connection o365 */ 3:
                    message.calendarType = {
                        oneofKind: "o365",
                        o365: O365Connection.internalBinaryRead(reader, reader.uint32(), options, (message.calendarType as any).o365)
                    };
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string customerID */ 5:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 6:
                    message.accountID = reader.string();
                    break;
                case /* calendarprovider.CalendarProviderDeviceType deviceType */ 7:
                    message.deviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LinkPersonalCalendarRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.EwsConnection ews = 1; */
        if (message.calendarType.oneofKind === "ews")
            EwsConnection.internalBinaryWrite(message.calendarType.ews, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.GsuiteConnection gsuite = 2; */
        if (message.calendarType.oneofKind === "gsuite")
            GsuiteConnection.internalBinaryWrite(message.calendarType.gsuite, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.O365Connection o365 = 3; */
        if (message.calendarType.oneofKind === "o365")
            O365Connection.internalBinaryWrite(message.calendarType.o365, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string customerID = 5; */
        if (message.customerID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 6; */
        if (message.accountID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.accountID);
        /* calendarprovider.CalendarProviderDeviceType deviceType = 7; */
        if (message.deviceType !== 0)
            writer.tag(7, WireType.Varint).int32(message.deviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.LinkPersonalCalendarRequest
 */
export const LinkPersonalCalendarRequest = new LinkPersonalCalendarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalCalendar$Type extends MessageType<PersonalCalendar> {
    constructor() {
        super("calendarprovider.PersonalCalendar", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "host_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalCalendar>): PersonalCalendar {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.iD = "";
        message.name = "";
        message.email = "";
        message.hostUrl = "";
        if (value !== undefined)
            reflectionMergePartial<PersonalCalendar>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalCalendar): PersonalCalendar {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string host_url */ 4:
                    message.hostUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalCalendar, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.iD);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string host_url = 4; */
        if (message.hostUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.hostUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.PersonalCalendar
 */
export const PersonalCalendar = new PersonalCalendar$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateProviderRequest$Type extends MessageType<CreateProviderRequest> {
    constructor() {
        super("calendarprovider.CreateProviderRequest", [
            { no: 1, name: "ews", kind: "message", oneof: "cType", T: () => EwsConnection },
            { no: 2, name: "gsuite", kind: "message", oneof: "cType", T: () => GsuiteConnection },
            { no: 3, name: "o365", kind: "message", oneof: "cType", T: () => O365Connection },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "deviceType", kind: "enum", T: () => ["calendarprovider.CalendarProviderDeviceType", CalendarProviderDeviceType] },
            { no: 7, name: "environment", kind: "message", T: () => MicrosoftEnvironment }
        ]);
    }
    create(value?: PartialMessage<CreateProviderRequest>): CreateProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cType = { oneofKind: undefined };
        message.name = "";
        message.customerID = "";
        message.deviceType = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateProviderRequest): CreateProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.EwsConnection ews */ 1:
                    message.cType = {
                        oneofKind: "ews",
                        ews: EwsConnection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).ews)
                    };
                    break;
                case /* calendarprovider.GsuiteConnection gsuite */ 2:
                    message.cType = {
                        oneofKind: "gsuite",
                        gsuite: GsuiteConnection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).gsuite)
                    };
                    break;
                case /* calendarprovider.O365Connection o365 */ 3:
                    message.cType = {
                        oneofKind: "o365",
                        o365: O365Connection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).o365)
                    };
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string customerID */ 5:
                    message.customerID = reader.string();
                    break;
                case /* calendarprovider.CalendarProviderDeviceType deviceType */ 6:
                    message.deviceType = reader.int32();
                    break;
                case /* calendarprovider.MicrosoftEnvironment environment */ 7:
                    message.environment = MicrosoftEnvironment.internalBinaryRead(reader, reader.uint32(), options, message.environment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.EwsConnection ews = 1; */
        if (message.cType.oneofKind === "ews")
            EwsConnection.internalBinaryWrite(message.cType.ews, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.GsuiteConnection gsuite = 2; */
        if (message.cType.oneofKind === "gsuite")
            GsuiteConnection.internalBinaryWrite(message.cType.gsuite, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.O365Connection o365 = 3; */
        if (message.cType.oneofKind === "o365")
            O365Connection.internalBinaryWrite(message.cType.o365, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string customerID = 5; */
        if (message.customerID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerID);
        /* calendarprovider.CalendarProviderDeviceType deviceType = 6; */
        if (message.deviceType !== 0)
            writer.tag(6, WireType.Varint).int32(message.deviceType);
        /* calendarprovider.MicrosoftEnvironment environment = 7; */
        if (message.environment)
            MicrosoftEnvironment.internalBinaryWrite(message.environment, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.CreateProviderRequest
 */
export const CreateProviderRequest = new CreateProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Provider$Type extends MessageType<Provider> {
    constructor() {
        super("calendarprovider.Provider", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tokenName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tokenEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Provider>): Provider {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.iD = "";
        message.name = "";
        message.tokenName = "";
        message.tokenEmail = "";
        if (value !== undefined)
            reflectionMergePartial<Provider>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Provider): Provider {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string tokenName */ 3:
                    message.tokenName = reader.string();
                    break;
                case /* string tokenEmail */ 4:
                    message.tokenEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Provider, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.iD);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string tokenName = 3; */
        if (message.tokenName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tokenName);
        /* string tokenEmail = 4; */
        if (message.tokenEmail !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tokenEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.Provider
 */
export const Provider = new Provider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveProviderListRequest$Type extends MessageType<RetrieveProviderListRequest> {
    constructor() {
        super("calendarprovider.RetrieveProviderListRequest", [
            { no: 1, name: "CalType", kind: "enum", jsonName: "CalType", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 2, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveProviderListRequest>): RetrieveProviderListRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.calType = 0;
        message.limit = 0;
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<RetrieveProviderListRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveProviderListRequest): RetrieveProviderListRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.CalendarType CalType = 1 [json_name = "CalType"];*/ 1:
                    message.calType = reader.int32();
                    break;
                case /* int32 limit */ 2:
                    message.limit = reader.int32();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveProviderListRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.CalendarType CalType = 1 [json_name = "CalType"]; */
        if (message.calType !== 0)
            writer.tag(1, WireType.Varint).int32(message.calType);
        /* int32 limit = 2; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int32(message.limit);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.RetrieveProviderListRequest
 */
export const RetrieveProviderListRequest = new RetrieveProviderListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveProviderListResponse$Type extends MessageType<RetrieveProviderListResponse> {
    constructor() {
        super("calendarprovider.RetrieveProviderListResponse", [
            { no: 1, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GGCalendarProvider }
        ]);
    }
    create(value?: PartialMessage<RetrieveProviderListResponse>): RetrieveProviderListResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providers = [];
        if (value !== undefined)
            reflectionMergePartial<RetrieveProviderListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveProviderListResponse): RetrieveProviderListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated calendarprovider.GGCalendarProvider providers */ 1:
                    message.providers.push(GGCalendarProvider.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveProviderListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated calendarprovider.GGCalendarProvider providers = 1; */
        for (let i = 0; i < message.providers.length; i++)
            GGCalendarProvider.internalBinaryWrite(message.providers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.RetrieveProviderListResponse
 */
export const RetrieveProviderListResponse = new RetrieveProviderListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveProviderRequest$Type extends MessageType<RetrieveProviderRequest> {
    constructor() {
        super("calendarprovider.RetrieveProviderRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveProviderRequest>): RetrieveProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.iD = "";
        if (value !== undefined)
            reflectionMergePartial<RetrieveProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveProviderRequest): RetrieveProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string ID = 2 [json_name = "ID"];*/ 2:
                    message.iD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string ID = 2 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.iD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.RetrieveProviderRequest
 */
export const RetrieveProviderRequest = new RetrieveProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveProviderResponse$Type extends MessageType<RetrieveProviderResponse> {
    constructor() {
        super("calendarprovider.RetrieveProviderResponse", [
            { no: 1, name: "provider", kind: "message", T: () => GGCalendarProvider }
        ]);
    }
    create(value?: PartialMessage<RetrieveProviderResponse>): RetrieveProviderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RetrieveProviderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveProviderResponse): RetrieveProviderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.GGCalendarProvider provider */ 1:
                    message.provider = GGCalendarProvider.internalBinaryRead(reader, reader.uint32(), options, message.provider);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveProviderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.GGCalendarProvider provider = 1; */
        if (message.provider)
            GGCalendarProvider.internalBinaryWrite(message.provider, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.RetrieveProviderResponse
 */
export const RetrieveProviderResponse = new RetrieveProviderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProviderRequest$Type extends MessageType<DeleteProviderRequest> {
    constructor() {
        super("calendarprovider.DeleteProviderRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteProviderRequest>): DeleteProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.iD = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProviderRequest): DeleteProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string ID = 2 [json_name = "ID"];*/ 2:
                    message.iD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string ID = 2 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.iD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.DeleteProviderRequest
 */
export const DeleteProviderRequest = new DeleteProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProviderResponse$Type extends MessageType<DeleteProviderResponse> {
    constructor() {
        super("calendarprovider.DeleteProviderResponse", []);
    }
    create(value?: PartialMessage<DeleteProviderResponse>): DeleteProviderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteProviderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProviderResponse): DeleteProviderResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteProviderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.DeleteProviderResponse
 */
export const DeleteProviderResponse = new DeleteProviderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveEWSProviderRequest$Type extends MessageType<RetrieveEWSProviderRequest> {
    constructor() {
        super("calendarprovider.RetrieveEWSProviderRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveEWSProviderRequest>): RetrieveEWSProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.iD = "";
        if (value !== undefined)
            reflectionMergePartial<RetrieveEWSProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveEWSProviderRequest): RetrieveEWSProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string ID = 2 [json_name = "ID"];*/ 2:
                    message.iD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveEWSProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string ID = 2 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.iD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.RetrieveEWSProviderRequest
 */
export const RetrieveEWSProviderRequest = new RetrieveEWSProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EWSProvider$Type extends MessageType<EWSProvider> {
    constructor() {
        super("calendarprovider.EWSProvider", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "svc_account", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "impersonation_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<EWSProvider>): EWSProvider {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.svcAccount = "";
        message.password = "";
        message.domain = "";
        message.customerID = "";
        message.iD = "";
        message.impersonationEnabled = false;
        if (value !== undefined)
            reflectionMergePartial<EWSProvider>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EWSProvider): EWSProvider {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string svc_account */ 2:
                    message.svcAccount = reader.string();
                    break;
                case /* string password */ 3:
                    message.password = reader.string();
                    break;
                case /* string domain */ 4:
                    message.domain = reader.string();
                    break;
                case /* string customerID */ 5:
                    message.customerID = reader.string();
                    break;
                case /* string ID = 6 [json_name = "ID"];*/ 6:
                    message.iD = reader.string();
                    break;
                case /* bool impersonation_enabled */ 7:
                    message.impersonationEnabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EWSProvider, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string svc_account = 2; */
        if (message.svcAccount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.svcAccount);
        /* string password = 3; */
        if (message.password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.password);
        /* string domain = 4; */
        if (message.domain !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.domain);
        /* string customerID = 5; */
        if (message.customerID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerID);
        /* string ID = 6 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.iD);
        /* bool impersonation_enabled = 7; */
        if (message.impersonationEnabled !== false)
            writer.tag(7, WireType.Varint).bool(message.impersonationEnabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.EWSProvider
 */
export const EWSProvider = new EWSProvider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignRequest$Type extends MessageType<AssignRequest> {
    constructor() {
        super("calendarprovider.AssignRequest", [
            { no: 1, name: "organization", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 3, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AssignRequest>): AssignRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.organization = "";
        message.type = 0;
        message.iD = "";
        message.roomID = "";
        if (value !== undefined)
            reflectionMergePartial<AssignRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssignRequest): AssignRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization */ 1:
                    message.organization = reader.string();
                    break;
                case /* calendarprovider.CalendarType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* string ID = 3 [json_name = "ID"];*/ 3:
                    message.iD = reader.string();
                    break;
                case /* string roomID */ 4:
                    message.roomID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssignRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization = 1; */
        if (message.organization !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organization);
        /* calendarprovider.CalendarType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* string ID = 3 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.iD);
        /* string roomID = 4; */
        if (message.roomID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.roomID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.AssignRequest
 */
export const AssignRequest = new AssignRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignResponse$Type extends MessageType<AssignResponse> {
    constructor() {
        super("calendarprovider.AssignResponse", []);
    }
    create(value?: PartialMessage<AssignResponse>): AssignResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AssignResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssignResponse): AssignResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AssignResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.AssignResponse
 */
export const AssignResponse = new AssignResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnassignRequest$Type extends MessageType<UnassignRequest> {
    constructor() {
        super("calendarprovider.UnassignRequest", [
            { no: 1, name: "organization", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 3, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UnassignRequest>): UnassignRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.organization = "";
        message.type = 0;
        message.iD = "";
        if (value !== undefined)
            reflectionMergePartial<UnassignRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnassignRequest): UnassignRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization */ 1:
                    message.organization = reader.string();
                    break;
                case /* calendarprovider.CalendarType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* string ID = 3 [json_name = "ID"];*/ 3:
                    message.iD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnassignRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization = 1; */
        if (message.organization !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organization);
        /* calendarprovider.CalendarType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* string ID = 3 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.iD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UnassignRequest
 */
export const UnassignRequest = new UnassignRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnassignResponse$Type extends MessageType<UnassignResponse> {
    constructor() {
        super("calendarprovider.UnassignResponse", []);
    }
    create(value?: PartialMessage<UnassignResponse>): UnassignResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UnassignResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnassignResponse): UnassignResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UnassignResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UnassignResponse
 */
export const UnassignResponse = new UnassignResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSupportedIntegrationsRequest$Type extends MessageType<GetSupportedIntegrationsRequest> {
    constructor() {
        super("calendarprovider.GetSupportedIntegrationsRequest", []);
    }
    create(value?: PartialMessage<GetSupportedIntegrationsRequest>): GetSupportedIntegrationsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetSupportedIntegrationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSupportedIntegrationsRequest): GetSupportedIntegrationsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetSupportedIntegrationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.GetSupportedIntegrationsRequest
 */
export const GetSupportedIntegrationsRequest = new GetSupportedIntegrationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSupportedIntegrationsResponse$Type extends MessageType<GetSupportedIntegrationsResponse> {
    constructor() {
        super("calendarprovider.GetSupportedIntegrationsResponse", [
            { no: 1, name: "integrations", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSupportedIntegrationsResponse>): GetSupportedIntegrationsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.integrations = [];
        if (value !== undefined)
            reflectionMergePartial<GetSupportedIntegrationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSupportedIntegrationsResponse): GetSupportedIntegrationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string integrations */ 1:
                    message.integrations.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSupportedIntegrationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string integrations = 1; */
        for (let i = 0; i < message.integrations.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.integrations[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.GetSupportedIntegrationsResponse
 */
export const GetSupportedIntegrationsResponse = new GetSupportedIntegrationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateEWSRoomRequest$Type extends MessageType<ValidateEWSRoomRequest> {
    constructor() {
        super("calendarprovider.ValidateEWSRoomRequest", [
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "calendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateEWSRoomRequest>): ValidateEWSRoomRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        message.calendarID = "";
        if (value !== undefined)
            reflectionMergePartial<ValidateEWSRoomRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateEWSRoomRequest): ValidateEWSRoomRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 4:
                    message.calendarProviderID = reader.string();
                    break;
                case /* string calendarID */ 5:
                    message.calendarID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateEWSRoomRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 4; */
        if (message.calendarProviderID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.calendarProviderID);
        /* string calendarID = 5; */
        if (message.calendarID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.calendarID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ValidateEWSRoomRequest
 */
export const ValidateEWSRoomRequest = new ValidateEWSRoomRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateEWSRoomResponse$Type extends MessageType<ValidateEWSRoomResponse> {
    constructor() {
        super("calendarprovider.ValidateEWSRoomResponse", [
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateEWSRoomResponse>): ValidateEWSRoomResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<ValidateEWSRoomResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateEWSRoomResponse): ValidateEWSRoomResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateEWSRoomResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ValidateEWSRoomResponse
 */
export const ValidateEWSRoomResponse = new ValidateEWSRoomResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCalendarProviderCredentialsRequest$Type extends MessageType<UpdateCalendarProviderCredentialsRequest> {
    constructor() {
        super("calendarprovider.UpdateCalendarProviderCredentialsRequest", [
            { no: 1, name: "ews", kind: "message", oneof: "cType", T: () => EwsConnection },
            { no: 2, name: "gsuite", kind: "message", oneof: "cType", T: () => GsuiteConnection },
            { no: 3, name: "o365", kind: "message", oneof: "cType", T: () => O365Connection },
            { no: 4, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "deviceType", kind: "enum", T: () => ["calendarprovider.CalendarProviderDeviceType", CalendarProviderDeviceType] }
        ]);
    }
    create(value?: PartialMessage<UpdateCalendarProviderCredentialsRequest>): UpdateCalendarProviderCredentialsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cType = { oneofKind: undefined };
        message.customerID = "";
        message.calendarProviderID = "";
        message.deviceType = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateCalendarProviderCredentialsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCalendarProviderCredentialsRequest): UpdateCalendarProviderCredentialsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.EwsConnection ews */ 1:
                    message.cType = {
                        oneofKind: "ews",
                        ews: EwsConnection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).ews)
                    };
                    break;
                case /* calendarprovider.GsuiteConnection gsuite */ 2:
                    message.cType = {
                        oneofKind: "gsuite",
                        gsuite: GsuiteConnection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).gsuite)
                    };
                    break;
                case /* calendarprovider.O365Connection o365 */ 3:
                    message.cType = {
                        oneofKind: "o365",
                        o365: O365Connection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).o365)
                    };
                    break;
                case /* string customerID */ 4:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 5:
                    message.calendarProviderID = reader.string();
                    break;
                case /* calendarprovider.CalendarProviderDeviceType deviceType */ 6:
                    message.deviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCalendarProviderCredentialsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.EwsConnection ews = 1; */
        if (message.cType.oneofKind === "ews")
            EwsConnection.internalBinaryWrite(message.cType.ews, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.GsuiteConnection gsuite = 2; */
        if (message.cType.oneofKind === "gsuite")
            GsuiteConnection.internalBinaryWrite(message.cType.gsuite, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.O365Connection o365 = 3; */
        if (message.cType.oneofKind === "o365")
            O365Connection.internalBinaryWrite(message.cType.o365, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 4; */
        if (message.customerID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 5; */
        if (message.calendarProviderID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.calendarProviderID);
        /* calendarprovider.CalendarProviderDeviceType deviceType = 6; */
        if (message.deviceType !== 0)
            writer.tag(6, WireType.Varint).int32(message.deviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UpdateCalendarProviderCredentialsRequest
 */
export const UpdateCalendarProviderCredentialsRequest = new UpdateCalendarProviderCredentialsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateDirectoryProviderRequest$Type extends MessageType<CreateDirectoryProviderRequest> {
    constructor() {
        super("calendarprovider.CreateDirectoryProviderRequest", [
            { no: 1, name: "oAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "providerType", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateDirectoryProviderRequest>): CreateDirectoryProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oAuthToken = "";
        message.providerType = 0;
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<CreateDirectoryProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateDirectoryProviderRequest): CreateDirectoryProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string oAuthToken */ 1:
                    message.oAuthToken = reader.string();
                    break;
                case /* calendarprovider.CalendarType providerType */ 2:
                    message.providerType = reader.int32();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateDirectoryProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string oAuthToken = 1; */
        if (message.oAuthToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.oAuthToken);
        /* calendarprovider.CalendarType providerType = 2; */
        if (message.providerType !== 0)
            writer.tag(2, WireType.Varint).int32(message.providerType);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.CreateDirectoryProviderRequest
 */
export const CreateDirectoryProviderRequest = new CreateDirectoryProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateAuthLinkRequest$Type extends MessageType<GenerateAuthLinkRequest> {
    constructor() {
        super("calendarprovider.GenerateAuthLinkRequest", [
            { no: 1, name: "redirectURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "integrationType", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateAuthLinkRequest>): GenerateAuthLinkRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.redirectURL = "";
        message.integrationType = 0;
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<GenerateAuthLinkRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateAuthLinkRequest): GenerateAuthLinkRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redirectURL */ 1:
                    message.redirectURL = reader.string();
                    break;
                case /* calendarprovider.CalendarType integrationType */ 2:
                    message.integrationType = reader.int32();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateAuthLinkRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redirectURL = 1; */
        if (message.redirectURL !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redirectURL);
        /* calendarprovider.CalendarType integrationType = 2; */
        if (message.integrationType !== 0)
            writer.tag(2, WireType.Varint).int32(message.integrationType);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.GenerateAuthLinkRequest
 */
export const GenerateAuthLinkRequest = new GenerateAuthLinkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateAuthLinkResponse$Type extends MessageType<GenerateAuthLinkResponse> {
    constructor() {
        super("calendarprovider.GenerateAuthLinkResponse", [
            { no: 1, name: "authLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "integrationType", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] }
        ]);
    }
    create(value?: PartialMessage<GenerateAuthLinkResponse>): GenerateAuthLinkResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.authLink = "";
        message.integrationType = 0;
        if (value !== undefined)
            reflectionMergePartial<GenerateAuthLinkResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateAuthLinkResponse): GenerateAuthLinkResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string authLink */ 1:
                    message.authLink = reader.string();
                    break;
                case /* calendarprovider.CalendarType integrationType */ 2:
                    message.integrationType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateAuthLinkResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string authLink = 1; */
        if (message.authLink !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.authLink);
        /* calendarprovider.CalendarType integrationType = 2; */
        if (message.integrationType !== 0)
            writer.tag(2, WireType.Varint).int32(message.integrationType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.GenerateAuthLinkResponse
 */
export const GenerateAuthLinkResponse = new GenerateAuthLinkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDirectoryProviderRequest$Type extends MessageType<ListDirectoryProviderRequest> {
    constructor() {
        super("calendarprovider.ListDirectoryProviderRequest", [
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListDirectoryProviderRequest>): ListDirectoryProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<ListDirectoryProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDirectoryProviderRequest): ListDirectoryProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDirectoryProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListDirectoryProviderRequest
 */
export const ListDirectoryProviderRequest = new ListDirectoryProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirectorySyncIntegration$Type extends MessageType<DirectorySyncIntegration> {
    constructor() {
        super("calendarprovider.DirectorySyncIntegration", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "integrationType", kind: "enum", T: () => ["calendarprovider.CalendarType", CalendarType] },
            { no: 4, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "status", kind: "enum", T: () => ["calendarprovider.GGCalendarProviderStatus", GGCalendarProviderStatus] },
            { no: 8, name: "api_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DirectorySyncIntegration>): DirectorySyncIntegration {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.iD = "";
        message.name = "";
        message.integrationType = 0;
        message.token = "";
        message.status = 0;
        message.apiError = "";
        if (value !== undefined)
            reflectionMergePartial<DirectorySyncIntegration>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirectorySyncIntegration): DirectorySyncIntegration {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* calendarprovider.CalendarType integrationType */ 3:
                    message.integrationType = reader.int32();
                    break;
                case /* string token */ 4:
                    message.token = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 6:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* calendarprovider.GGCalendarProviderStatus status */ 7:
                    message.status = reader.int32();
                    break;
                case /* string api_error */ 8:
                    message.apiError = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirectorySyncIntegration, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.iD);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* calendarprovider.CalendarType integrationType = 3; */
        if (message.integrationType !== 0)
            writer.tag(3, WireType.Varint).int32(message.integrationType);
        /* string token = 4; */
        if (message.token !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.token);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 6; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.GGCalendarProviderStatus status = 7; */
        if (message.status !== 0)
            writer.tag(7, WireType.Varint).int32(message.status);
        /* string api_error = 8; */
        if (message.apiError !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.apiError);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.DirectorySyncIntegration
 */
export const DirectorySyncIntegration = new DirectorySyncIntegration$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDirectoryProviderResponse$Type extends MessageType<ListDirectoryProviderResponse> {
    constructor() {
        super("calendarprovider.ListDirectoryProviderResponse", [
            { no: 1, name: "integrations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DirectorySyncIntegration },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListDirectoryProviderResponse>): ListDirectoryProviderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.integrations = [];
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<ListDirectoryProviderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDirectoryProviderResponse): ListDirectoryProviderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated calendarprovider.DirectorySyncIntegration integrations */ 1:
                    message.integrations.push(DirectorySyncIntegration.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDirectoryProviderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated calendarprovider.DirectorySyncIntegration integrations = 1; */
        for (let i = 0; i < message.integrations.length; i++)
            DirectorySyncIntegration.internalBinaryWrite(message.integrations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListDirectoryProviderResponse
 */
export const ListDirectoryProviderResponse = new ListDirectoryProviderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveDirectoryProviderRequest$Type extends MessageType<RetrieveDirectoryProviderRequest> {
    constructor() {
        super("calendarprovider.RetrieveDirectoryProviderRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveDirectoryProviderRequest>): RetrieveDirectoryProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.iD = "";
        if (value !== undefined)
            reflectionMergePartial<RetrieveDirectoryProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveDirectoryProviderRequest): RetrieveDirectoryProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string ID = 2 [json_name = "ID"];*/ 2:
                    message.iD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveDirectoryProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string ID = 2 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.iD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.RetrieveDirectoryProviderRequest
 */
export const RetrieveDirectoryProviderRequest = new RetrieveDirectoryProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveDirectoryProviderResponse$Type extends MessageType<RetrieveDirectoryProviderResponse> {
    constructor() {
        super("calendarprovider.RetrieveDirectoryProviderResponse", [
            { no: 1, name: "integration", kind: "message", T: () => DirectorySyncIntegration }
        ]);
    }
    create(value?: PartialMessage<RetrieveDirectoryProviderResponse>): RetrieveDirectoryProviderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RetrieveDirectoryProviderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveDirectoryProviderResponse): RetrieveDirectoryProviderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.DirectorySyncIntegration integration */ 1:
                    message.integration = DirectorySyncIntegration.internalBinaryRead(reader, reader.uint32(), options, message.integration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveDirectoryProviderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.DirectorySyncIntegration integration = 1; */
        if (message.integration)
            DirectorySyncIntegration.internalBinaryWrite(message.integration, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.RetrieveDirectoryProviderResponse
 */
export const RetrieveDirectoryProviderResponse = new RetrieveDirectoryProviderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteDirectoryProviderRequest$Type extends MessageType<DeleteDirectoryProviderRequest> {
    constructor() {
        super("calendarprovider.DeleteDirectoryProviderRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ID", kind: "scalar", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteDirectoryProviderRequest>): DeleteDirectoryProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.iD = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteDirectoryProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteDirectoryProviderRequest): DeleteDirectoryProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string ID = 2 [json_name = "ID"];*/ 2:
                    message.iD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteDirectoryProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string ID = 2 [json_name = "ID"]; */
        if (message.iD !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.iD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.DeleteDirectoryProviderRequest
 */
export const DeleteDirectoryProviderRequest = new DeleteDirectoryProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchedCalendarIntegrationsRequest$Type extends MessageType<ListMatchedCalendarIntegrationsRequest> {
    constructor() {
        super("calendarprovider.ListMatchedCalendarIntegrationsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchedCalendarIntegrationsRequest>): ListMatchedCalendarIntegrationsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        if (value !== undefined)
            reflectionMergePartial<ListMatchedCalendarIntegrationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchedCalendarIntegrationsRequest): ListMatchedCalendarIntegrationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 2:
                    message.calendarProviderID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchedCalendarIntegrationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 2; */
        if (message.calendarProviderID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListMatchedCalendarIntegrationsRequest
 */
export const ListMatchedCalendarIntegrationsRequest = new ListMatchedCalendarIntegrationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchedCalendarIntegrationsResponse$Type extends MessageType<ListMatchedCalendarIntegrationsResponse> {
    constructor() {
        super("calendarprovider.ListMatchedCalendarIntegrationsResponse", [
            { no: 1, name: "integrations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PersonalCalendar }
        ]);
    }
    create(value?: PartialMessage<ListMatchedCalendarIntegrationsResponse>): ListMatchedCalendarIntegrationsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.integrations = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchedCalendarIntegrationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchedCalendarIntegrationsResponse): ListMatchedCalendarIntegrationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated calendarprovider.PersonalCalendar integrations */ 1:
                    message.integrations.push(PersonalCalendar.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchedCalendarIntegrationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated calendarprovider.PersonalCalendar integrations = 1; */
        for (let i = 0; i < message.integrations.length; i++)
            PersonalCalendar.internalBinaryWrite(message.integrations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ListMatchedCalendarIntegrationsResponse
 */
export const ListMatchedCalendarIntegrationsResponse = new ListMatchedCalendarIntegrationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateCalendarIntegrationCredentialsRequest$Type extends MessageType<ValidateCalendarIntegrationCredentialsRequest> {
    constructor() {
        super("calendarprovider.ValidateCalendarIntegrationCredentialsRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateCalendarIntegrationCredentialsRequest>): ValidateCalendarIntegrationCredentialsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerID = "";
        message.calendarProviderID = "";
        if (value !== undefined)
            reflectionMergePartial<ValidateCalendarIntegrationCredentialsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateCalendarIntegrationCredentialsRequest): ValidateCalendarIntegrationCredentialsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string calendarProviderID */ 2:
                    message.calendarProviderID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateCalendarIntegrationCredentialsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string calendarProviderID = 2; */
        if (message.calendarProviderID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ValidateCalendarIntegrationCredentialsRequest
 */
export const ValidateCalendarIntegrationCredentialsRequest = new ValidateCalendarIntegrationCredentialsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateCalendarIntegrationCredentialsResponse$Type extends MessageType<ValidateCalendarIntegrationCredentialsResponse> {
    constructor() {
        super("calendarprovider.ValidateCalendarIntegrationCredentialsResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["calendarprovider.CalendarCredentialsStatus", CalendarCredentialsStatus] }
        ]);
    }
    create(value?: PartialMessage<ValidateCalendarIntegrationCredentialsResponse>): ValidateCalendarIntegrationCredentialsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<ValidateCalendarIntegrationCredentialsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateCalendarIntegrationCredentialsResponse): ValidateCalendarIntegrationCredentialsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.CalendarCredentialsStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateCalendarIntegrationCredentialsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.CalendarCredentialsStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.ValidateCalendarIntegrationCredentialsResponse
 */
export const ValidateCalendarIntegrationCredentialsResponse = new ValidateCalendarIntegrationCredentialsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCalendarIntegrationStatusRequest$Type extends MessageType<UpdateCalendarIntegrationStatusRequest> {
    constructor() {
        super("calendarprovider.UpdateCalendarIntegrationStatusRequest", [
            { no: 1, name: "CalendarProviderID", kind: "scalar", jsonName: "CalendarProviderID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["calendarprovider.GGCalendarProviderStatus", GGCalendarProviderStatus] },
            { no: 4, name: "api_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCalendarIntegrationStatusRequest>): UpdateCalendarIntegrationStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.calendarProviderID = "";
        message.customerID = "";
        message.status = 0;
        message.apiError = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateCalendarIntegrationStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCalendarIntegrationStatusRequest): UpdateCalendarIntegrationStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string CalendarProviderID = 1 [json_name = "CalendarProviderID"];*/ 1:
                    message.calendarProviderID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* calendarprovider.GGCalendarProviderStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* string api_error */ 4:
                    message.apiError = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCalendarIntegrationStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string CalendarProviderID = 1 [json_name = "CalendarProviderID"]; */
        if (message.calendarProviderID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.calendarProviderID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* calendarprovider.GGCalendarProviderStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* string api_error = 4; */
        if (message.apiError !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.apiError);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UpdateCalendarIntegrationStatusRequest
 */
export const UpdateCalendarIntegrationStatusRequest = new UpdateCalendarIntegrationStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCalendarIntegrationStatusResponse$Type extends MessageType<UpdateCalendarIntegrationStatusResponse> {
    constructor() {
        super("calendarprovider.UpdateCalendarIntegrationStatusResponse", [
            { no: 1, name: "provider", kind: "message", T: () => GGCalendarProvider }
        ]);
    }
    create(value?: PartialMessage<UpdateCalendarIntegrationStatusResponse>): UpdateCalendarIntegrationStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateCalendarIntegrationStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCalendarIntegrationStatusResponse): UpdateCalendarIntegrationStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.GGCalendarProvider provider */ 1:
                    message.provider = GGCalendarProvider.internalBinaryRead(reader, reader.uint32(), options, message.provider);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCalendarIntegrationStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.GGCalendarProvider provider = 1; */
        if (message.provider)
            GGCalendarProvider.internalBinaryWrite(message.provider, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UpdateCalendarIntegrationStatusResponse
 */
export const UpdateCalendarIntegrationStatusResponse = new UpdateCalendarIntegrationStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateDirectoryIntegrationStatusRequest$Type extends MessageType<UpdateDirectoryIntegrationStatusRequest> {
    constructor() {
        super("calendarprovider.UpdateDirectoryIntegrationStatusRequest", [
            { no: 1, name: "integrationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["calendarprovider.GGCalendarProviderStatus", GGCalendarProviderStatus] },
            { no: 4, name: "api_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateDirectoryIntegrationStatusRequest>): UpdateDirectoryIntegrationStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.integrationID = "";
        message.customerID = "";
        message.status = 0;
        message.apiError = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateDirectoryIntegrationStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateDirectoryIntegrationStatusRequest): UpdateDirectoryIntegrationStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string integrationID */ 1:
                    message.integrationID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* calendarprovider.GGCalendarProviderStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* string api_error */ 4:
                    message.apiError = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateDirectoryIntegrationStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string integrationID = 1; */
        if (message.integrationID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.integrationID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* calendarprovider.GGCalendarProviderStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* string api_error = 4; */
        if (message.apiError !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.apiError);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UpdateDirectoryIntegrationStatusRequest
 */
export const UpdateDirectoryIntegrationStatusRequest = new UpdateDirectoryIntegrationStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateDirectoryIntegrationStatusResponse$Type extends MessageType<UpdateDirectoryIntegrationStatusResponse> {
    constructor() {
        super("calendarprovider.UpdateDirectoryIntegrationStatusResponse", [
            { no: 1, name: "integration", kind: "message", T: () => DirectorySyncIntegration }
        ]);
    }
    create(value?: PartialMessage<UpdateDirectoryIntegrationStatusResponse>): UpdateDirectoryIntegrationStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateDirectoryIntegrationStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateDirectoryIntegrationStatusResponse): UpdateDirectoryIntegrationStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.DirectorySyncIntegration integration */ 1:
                    message.integration = DirectorySyncIntegration.internalBinaryRead(reader, reader.uint32(), options, message.integration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateDirectoryIntegrationStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.DirectorySyncIntegration integration = 1; */
        if (message.integration)
            DirectorySyncIntegration.internalBinaryWrite(message.integration, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UpdateDirectoryIntegrationStatusResponse
 */
export const UpdateDirectoryIntegrationStatusResponse = new UpdateDirectoryIntegrationStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateDirectoryProviderCredentialsRequest$Type extends MessageType<UpdateDirectoryProviderCredentialsRequest> {
    constructor() {
        super("calendarprovider.UpdateDirectoryProviderCredentialsRequest", [
            { no: 2, name: "gsuite", kind: "message", oneof: "cType", T: () => GsuiteConnection },
            { no: 3, name: "o365", kind: "message", oneof: "cType", T: () => O365Connection },
            { no: 4, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "providerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateDirectoryProviderCredentialsRequest>): UpdateDirectoryProviderCredentialsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cType = { oneofKind: undefined };
        message.customerID = "";
        message.providerID = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateDirectoryProviderCredentialsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateDirectoryProviderCredentialsRequest): UpdateDirectoryProviderCredentialsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* calendarprovider.GsuiteConnection gsuite */ 2:
                    message.cType = {
                        oneofKind: "gsuite",
                        gsuite: GsuiteConnection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).gsuite)
                    };
                    break;
                case /* calendarprovider.O365Connection o365 */ 3:
                    message.cType = {
                        oneofKind: "o365",
                        o365: O365Connection.internalBinaryRead(reader, reader.uint32(), options, (message.cType as any).o365)
                    };
                    break;
                case /* string customerID */ 4:
                    message.customerID = reader.string();
                    break;
                case /* string providerID */ 5:
                    message.providerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateDirectoryProviderCredentialsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* calendarprovider.GsuiteConnection gsuite = 2; */
        if (message.cType.oneofKind === "gsuite")
            GsuiteConnection.internalBinaryWrite(message.cType.gsuite, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* calendarprovider.O365Connection o365 = 3; */
        if (message.cType.oneofKind === "o365")
            O365Connection.internalBinaryWrite(message.cType.o365, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 4; */
        if (message.customerID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.customerID);
        /* string providerID = 5; */
        if (message.providerID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.providerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message calendarprovider.UpdateDirectoryProviderCredentialsRequest
 */
export const UpdateDirectoryProviderCredentialsRequest = new UpdateDirectoryProviderCredentialsRequest$Type();
/**
 * @generated ServiceType for protobuf service calendarprovider.CalendarProvider
 */
export const CalendarProvider = new ServiceType("calendarprovider.CalendarProvider", [
    { name: "CreateProvider", options: { "google.api.http": { post: "/v1/calendarprovider/{customerID}", body: "*" } }, I: CreateProviderRequest, O: Provider },
    { name: "LinkPersonalCalendar", options: { "google.api.http": { post: "/v1/calendarprovider/personalCalendar", body: "*" } }, I: LinkPersonalCalendarRequest, O: PersonalCalendar },
    { name: "ListPersonalCalendars", options: { "google.api.http": { get: "/v1/calendarprovider/personalCalendars" } }, I: ListPersonalCalendarsRequest, O: PersonalCalendars },
    { name: "ListCalendars", options: { "google.api.http": { get: "/v1/calendarprovider/{customerID}/{calendarProviderID}/calendars" } }, I: ListCalendarsRequest, O: ListCalendarsResponse },
    { name: "ListCustomerCalendars", options: { "google.api.http": { get: "/v1/calendarprovider/{customerID}/calendars" } }, I: ListCustomerCalendarsRequest, O: ListCalendarsResponse },
    { name: "RetrieveProviderList", options: { "google.api.http": { get: "/v1/calendarprovider/{customerID}" } }, I: RetrieveProviderListRequest, O: RetrieveProviderListResponse },
    { name: "RetrieveProvider", options: { "google.api.http": { get: "/v1/calendarprovider/{customerID}/{ID}" } }, I: RetrieveProviderRequest, O: RetrieveProviderResponse },
    { name: "DeleteProvider", options: { "google.api.http": { delete: "/v1/calendarprovider/{customerID}/{ID}" } }, I: DeleteProviderRequest, O: DeleteProviderResponse },
    { name: "RetrieveEWSProviderConnection", options: { "google.api.http": { get: "/v1/calendarprovider/ews/{customerID}/{ID}" } }, I: RetrieveEWSProviderRequest, O: EWSProvider },
    { name: "UpdateEWSProvider", options: { "google.api.http": { put: "/v1/calendarprovider/ews/{customerID}/{ID}", body: "*" } }, I: EWSProvider, O: Provider },
    { name: "ValidateEWSCalendar", options: { "google.api.http": { put: "/v1/calendarprovider/ews/{customerID}/calendar/{calendarProviderID}", body: "*" } }, I: ValidateEWSRoomRequest, O: ValidateEWSRoomResponse },
    { name: "UpdateCalendarProviderCredentials", options: { "google.api.http": { patch: "/v1/calendarprovider/{customerID}/calendar/{calendarProviderID}", body: "*" } }, I: UpdateCalendarProviderCredentialsRequest, O: Provider },
    { name: "GetSupportedIntegrations", options: { "google.api.http": { get: "/v1/calendarprovider/integrations" } }, I: GetSupportedIntegrationsRequest, O: GetSupportedIntegrationsResponse },
    { name: "ListMatchedCalendarIntegrations", options: { "google.api.http": { get: "/v1/calendarprovider/matchedIntegrations/list" } }, I: ListMatchedCalendarIntegrationsRequest, O: ListMatchedCalendarIntegrationsResponse },
    { name: "ValidateCalendarIntegrationCredentials", options: { "google.api.http": { get: "/v1/calendarprovider/validate-creds" } }, I: ValidateCalendarIntegrationCredentialsRequest, O: ValidateCalendarIntegrationCredentialsResponse },
    { name: "BuildIntegrationCalendarsCache", options: {}, I: Empty, O: Empty },
    { name: "UpdateCalendarIntegrationStatus", options: { "google.api.http": { patch: "/v1/calendarprovider/status/update", body: "*" } }, I: UpdateCalendarIntegrationStatusRequest, O: UpdateCalendarIntegrationStatusResponse }
]);
/**
 * @generated ServiceType for protobuf service calendarprovider.DirectoryProvider
 */
export const DirectoryProvider = new ServiceType("calendarprovider.DirectoryProvider", [
    { name: "GenerateAuthLink", options: { "google.api.http": { post: "/v1/syncdirectory/auth", body: "*" } }, I: GenerateAuthLinkRequest, O: GenerateAuthLinkResponse },
    { name: "CreateDirectoryProvider", options: { "google.api.http": { post: "/v1/syncdirectory/{customerID}", body: "*" } }, I: CreateDirectoryProviderRequest, O: Provider },
    { name: "ListDirectoryProvider", options: { "google.api.http": { get: "/v1/syncdirectory/{customerID}" } }, I: ListDirectoryProviderRequest, O: ListDirectoryProviderResponse },
    { name: "RetrieveDirectoryProvider", options: { "google.api.http": { get: "/v1/syncdirectory/{customerID}/{ID}" } }, I: RetrieveDirectoryProviderRequest, O: RetrieveDirectoryProviderResponse },
    { name: "DeleteDirectoryProvider", options: { "google.api.http": { delete: "/v1/syncdirectory/{customerID}/{ID}" } }, I: DeleteDirectoryProviderRequest, O: Empty },
    { name: "UpdateDirectoryProviderStatus", options: { "google.api.http": { patch: "/v1/syncdirectory/status/update", body: "*" } }, I: UpdateDirectoryIntegrationStatusRequest, O: UpdateDirectoryIntegrationStatusResponse },
    { name: "UpdateDirectoryProviderCredentials", options: { "google.api.http": { patch: "/v1/syncdirectory/{customerID}/integration/{providerID}", body: "*" } }, I: UpdateDirectoryProviderCredentialsRequest, O: Provider }
]);
/**
 * @generated ServiceType for protobuf service calendarprovider.Sync
 */
export const Sync = new ServiceType("calendarprovider.Sync", [
    { name: "Sync", options: {}, I: Empty, O: Empty },
    { name: "RenewIntegrationsToken", options: {}, I: Empty, O: Empty }
]);
