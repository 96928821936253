import { differenceInMinutes, format } from "date-fns";
import { useFormatReservationDate as formatReservationDate } from "../../lib/datePickerHelper";
import { utcToZonedTime } from "date-fns-tz";

export const formatTitleDate = (date: string, timeZone: string) => {
  const _date = utcToZonedTime(new Date(date), timeZone);
  const _now = utcToZonedTime(new Date(), timeZone);

  if (differenceInMinutes(_date, _now) <= 0) {
    return "Now";
  }

  return `${formatReservationDate(_date)}, ${format(_date, "HH:mm")}`;
};
