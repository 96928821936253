import React from "react";
import { Button, Spinner } from "reactstrap";

import { ThemeButtonProps } from "../Button/Button";

interface Props extends ThemeButtonProps {
  title: string;
  loading: boolean;
  loadingTitle: string;
}

export const SubmitButton = ({ loading, loadingTitle, size, title }: Props) => {
  return (
    <Button
      size={size}
      type="submit"
      color="primary"
      disabled={loading}
      className="submit-button"
    >
      {loading && <Spinner />}
      {loading ? loadingTitle : title}
    </Button>
  );
};
