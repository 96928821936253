import React, { PropsWithChildren } from "react";
import { MainResources } from "../../../../lib/types/main.types";
import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";

import "./BookingsSearchHeader.scss";

export interface SearchHeaderInfo {
  day: string;
  time: string;
  location: string;
}

export const BookingsSearchHeader = ({
  type,
  info: { day, location, time },
}: PropsWithChildren<{
  type: MainResources;
  info: SearchHeaderInfo;
}>) => {
  return (
    <div className="BookingsSearchHeader flex-a-center flex-wrap justify-content-between mb-3">
      <div className="flex-a-center BookingsSearchHeader__header">
        <div className="flex-a-center">
          <Icon icon="bookings-search" />
          <h1>Results matching {type}s</h1>
        </div>

        <Button
          title="Back to top"
          icon="arrow-up-simple"
          color="outline-primary"
          size="small"
          className="BookingsSearchHeader__back"
          onClick={() => {
            return window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        />
      </div>

      <div className="flex-a-center BookingsSearchHeader__subHead">
        <Icon icon="search-right-arrow" />

        <div className="BookingsSearchHeader__inputs">
          <div className="flex-a-center">
            <Icon icon="calendar-simple" />
            <span>{day}</span>
          </div>

          <div className="flex-a-center">
            <Icon icon="clock-icon" />
            <span>{time}</span>
          </div>

          <div className="flex-a-center BookingsSearchHeader__inputs--location">
            <Icon icon="location-reserve" />
            <span>{location}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
