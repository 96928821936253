import React, { PropsWithChildren } from "react";
import { Icon } from "../../../../Icon/Icon";

export const SelectedFloor = ({
  name,
}: PropsWithChildren<{ name: string }>) => {
  return (
    <div className="SelectedFloor flex-a-center pt-2">
      <Icon icon="floors-icon" />
      <span className="label__main pl-1">{name}</span>
    </div>
  );
};
