import gql from "graphql-tag";

import { Marker } from "../rooms/rooms";

import { WorkplaceTenantFields, WorkplaceFields } from "../workplaces";

export interface User {
  id?: string;
  displayName: string;
  email?: string;
  tenantID?: string;
  accountID: string;
  desks?: any;
}

export interface RemoveUserVariables {
  id: string;
  userId: string;
  email: string;
}

export interface DeskSelectFields {
  id: string;
  name: string;
  account: [
    {
      accountID: string;
      email: string;
      displayName: string;
    }
  ];
}

export interface GetDesksSelectResponse {
  queryDesk: DeskSelectFields[];
}

export interface UpdateDeskResponse {
  updateDesk: {
    desk: [
      {
        id: string;
        name: string;
        tags: string[];
        description: string;
        licensed: boolean;
        showCheckInTime: boolean;
        checkInTime: number;
        qrCodeRequired: boolean;
        isBooked?: boolean;
        isBlocked?: boolean;
        marker: Marker;
        rooms: WorkplaceFields[];
        account?: User;
        workingHrsActivated: boolean;
        workingHrsStart?: string;
        workingHrsEnd?: string;
        workingWeekDays?: number[];
      }
    ];
  };
}

export interface DeskRequestVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  showCheckInTime: boolean;
  checkInTime: number;
  qrCodeRequired: boolean;
  isBooked?: boolean;
  isBlocked?: boolean;
  marker: Marker;
  account?: User | null;
  workingHrsActivated: boolean;
  workingHrsStart?: string;
  workingHrsEnd?: string;
  workingWeekDays?: number[];
  remove?: {
    tags: string[];
  };
}

export interface UpdateDeskLicenseVariables extends WorkplaceTenantFields {
  licensed: boolean;
}

export interface AddDeskVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  showCheckInTime: boolean;
  qrCodeRequired: boolean;
  checkInTime: number;
  isBooked?: boolean;
  isBlocked?: boolean;
  marker: Marker;
  description: string;
  account?: User | null;
  workingHrsActivated: boolean;
  workingHrsStart?: string;
  workingHrsEnd?: string;
  workingWeekDays?: number[];
}

export interface AddDeskResponse {
  addDesk: {
    desk: WorkplaceFields[];
  };
}

export interface UpdateDeskMarkerVariables extends WorkplaceTenantFields {
  marker: Marker;
}

export interface UpdateUserDeskVariables extends WorkplaceTenantFields {
  id: string;
  displayName: string;
  email?: string;
  accountID: string;
  desks?: any;
}

// workingHrsStart?: string;
// workingHrsEnd?: string;

export type CheckInTime = 1 | 2 | 3 | 4;

export const DESK_FIELDS_FRAGMENT = gql`
  fragment DeskFields on Desk {
    id
    name
    tags
    description
    showCheckInTime
    checkInTime
    qrCodeRequired
    isBooked
    isBlocked
    workingHrsActivated
    workingHrsStart
    workingHrsEnd
    workingWeekDays
    account {
      displayName
      email
      accountID
      tenantID
    }
    marker {
      latitude
      longitude
    }
    licensed
  }
`;

export const ADD_DESK_TO_TENANT = gql`
  mutation addDeskToTenantMutation(
    $id: ID
    $name: String
    $description: String
    $showCheckInTime: Boolean
    $checkInTime: Int
    $qrCodeRequired: Boolean
    $tags: [String]
    $isBooked: Boolean
    $isBlocked: Boolean
    $account: AccountRef
    $marker: MarkerRef
    $workingHrsActivated: Boolean
    $workingHrsStart: String
    $workingHrsEnd: String
    $workingWeekDays: [Int]
    $tenantId: String!
  ) {
    addDesk(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          isBooked: $isBooked
          showCheckInTime: $showCheckInTime
          checkInTime: $checkInTime
          qrCodeRequired: $qrCodeRequired
          isBlocked: $isBlocked
          marker: $marker
          account: $account
          workingHrsActivated: $workingHrsActivated
          workingHrsStart: $workingHrsStart
          workingHrsEnd: $workingHrsEnd
          workingWeekDays: $workingWeekDays
          tenantId: $tenantId
          tenant: { id: $id }
        }
      ]
    ) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const ADD_DESK_TO_COMPANY = gql`
  mutation addDeskToCompanyMutation(
    $id: ID
    $name: String
    $tags: [String]
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $showCheckInTime: Boolean
    $checkInTime: Int
    $account: AccountRef
    $qrCodeRequired: Boolean
    $description: String
    $workingHrsActivated: Boolean
    $workingHrsStart: String
    $workingHrsEnd: String
    $workingWeekDays: [Int]
    $tenantId: String!
  ) {
    addDesk(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          isBooked: $isBooked
          showCheckInTime: $showCheckInTime
          isBlocked: $isBlocked
          checkInTime: $checkInTime
          qrCodeRequired: $qrCodeRequired
          marker: $marker
          workingHrsActivated: $workingHrsActivated
          workingHrsStart: $workingHrsStart
          workingHrsEnd: $workingHrsEnd
          workingWeekDays: $workingWeekDays
          account: $account
          tenantId: $tenantId
          company: { id: $id }
        }
      ]
    ) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const ADD_DESK_TO_SITE = gql`
  mutation addDeskToSiteMutation(
    $id: ID
    $name: String
    $description: String
    $isBooked: Boolean
    $marker: MarkerRef
    $showCheckInTime: Boolean
    $checkInTime: Int
    $isBlocked: Boolean
    $qrCodeRequired: Boolean
    $account: AccountRef
    $workingHrsActivated: Boolean
    $workingHrsStart: String
    $workingHrsEnd: String
    $workingWeekDays: [Int]
    $tags: [String]
    $tenantId: String!
  ) {
    addDesk(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          isBooked: $isBooked
          marker: $marker
          showCheckInTime: $showCheckInTime
          isBlocked: $isBlocked
          checkInTime: $checkInTime
          qrCodeRequired: $qrCodeRequired
          account: $account
          workingHrsActivated: $workingHrsActivated
          workingHrsStart: $workingHrsStart
          workingHrsEnd: $workingHrsEnd
          workingWeekDays: $workingWeekDays
          tenantId: $tenantId
          site: { id: $id }
        }
      ]
    ) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const ADD_DESK_TO_BUILDING = gql`
  mutation addDeskToBuildingMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $showCheckInTime: Boolean
    $isBlocked: Boolean
    $checkInTime: Int
    $qrCodeRequired: Boolean
    $isBooked: Boolean
    $marker: MarkerRef
    $workingHrsActivated: Boolean
    $workingHrsStart: String
    $workingHrsEnd: String
    $workingWeekDays: [Int]
    $account: AccountRef
    $tenantId: String!
  ) {
    addDesk(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          isBooked: $isBooked
          marker: $marker
          showCheckInTime: $showCheckInTime
          checkInTime: $checkInTime
          qrCodeRequired: $qrCodeRequired
          isBlocked: $isBlocked
          tenantId: $tenantId
          account: $account
          workingHrsActivated: $workingHrsActivated
          workingHrsStart: $workingHrsStart
          workingWeekDays: $workingWeekDays
          workingHrsEnd: $workingHrsEnd
          building: { id: $id }
        }
      ]
    ) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const ADD_DESK_TO_FLOOR = gql`
  mutation addDeskToFloorMutation(
    $id: ID
    $name: String
    $tags: [String]
    $description: String
    $isBooked: Boolean
    $showCheckInTime: Boolean
    $isBlocked: Boolean
    $checkInTime: Int
    $qrCodeRequired: Boolean
    $account: AccountRef
    $marker: MarkerRef
    $workingHrsActivated: Boolean
    $workingHrsStart: String
    $workingHrsEnd: String
    $workingWeekDays: [Int]
    $tenantId: String!
  ) {
    addDesk(
      input: [
        {
          name: $name
          tags: $tags
          floor: { id: $id }
          description: $description
          isBooked: $isBooked
          showCheckInTime: $showCheckInTime
          isBlocked: $isBlocked
          checkInTime: $checkInTime
          qrCodeRequired: $qrCodeRequired
          account: $account
          workingHrsActivated: $workingHrsActivated
          workingHrsStart: $workingHrsStart
          workingHrsEnd: $workingHrsEnd
          workingWeekDays: $workingWeekDays
          marker: $marker
          tenantId: $tenantId
        }
      ]
    ) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const ADD_DESK_TO_ZONE = gql`
  mutation addDeskToZoneMutation(
    $id: ID
    $name: String
    $tags: [String]
    $description: String
    $isBooked: Boolean
    $showCheckInTime: Boolean
    $isBlocked: Boolean
    $checkInTime: Int
    $qrCodeRequired: Boolean
    $marker: MarkerRef
    $workingHrsActivated: Boolean
    $workingHrsStart: String
    $workingHrsEnd: String
    $workingWeekDays: [Int]
    $account: AccountRef
    $tenantId: String!
  ) {
    addDesk(
      input: [
        {
          name: $name
          tags: $tags
          zone: { id: $id }
          tenantId: $tenantId
          isBooked: $isBooked
          showCheckInTime: $showCheckInTime
          isBlocked: $isBlocked
          checkInTime: $checkInTime
          qrCodeRequired: $qrCodeRequired
          marker: $marker
          workingHrsActivated: $workingHrsActivated
          workingHrsStart: $workingHrsStart
          workingHrsEnd: $workingHrsEnd
          workingWeekDays: $workingWeekDays
          account: $account
          description: $description
        }
      ]
    ) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const UPDATE_DESK = gql`
  mutation updateDeskMutation(
    $id: ID!
    $name: String
    $tags: [String]
    $description: String
    $isBooked: Boolean
    $marker: MarkerRef
    $showCheckInTime: Boolean
    $isBlocked: Boolean
    $workingHrsActivated: Boolean
    $workingHrsStart: String
    $workingHrsEnd: String
    $workingWeekDays: [Int]
    $checkInTime: Int
    $qrCodeRequired: Boolean
    $account: AccountRef
    $tenantId: String!
    $remove: DeskPatch
  ) {
    updateDesk(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          description: $description
          isBooked: $isBooked
          showCheckInTime: $showCheckInTime
          isBlocked: $isBlocked
          checkInTime: $checkInTime
          qrCodeRequired: $qrCodeRequired
          marker: $marker
          workingHrsActivated: $workingHrsActivated
          workingHrsStart: $workingHrsStart
          workingHrsEnd: $workingHrsEnd
          workingWeekDays: $workingWeekDays
          account: $account
          tenantId: $tenantId
        }
        remove: $remove
      }
    ) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const GET_DESKS_SELECT = gql`
  query getDesksSelectQuery($id: String) {
    queryDesk(filter: { tenantId: { eq: $id } }) {
      id
      name
      account {
        accountID
        displayName
        email
        tenantID
      }
    }
  }
`;

export const UPDATE_DESK_LICENSE = gql`
  mutation updateDeskMutation($id: ID!, $tenantId: String!) {
    updateDesk(input: { filter: { id: [$id] }, set: { tenantId: $tenantId } }) {
      desk {
        ...DeskFields
      }
    }
  }
  ${DESK_FIELDS_FRAGMENT}
`;

export const ADD_MARKER_TO_DESK = gql`
  mutation addMarkerToDesk($id: ID!, $marker: MarkerRef) {
    updateDesk(input: { filter: { id: [$id] }, set: { marker: $marker } }) {
      desk {
        id
        marker {
          latitude
          longitude
        }
      }
    }
  }
`;

export const DELETE_USER_FROM_DESK = gql`
  mutation removeUserFromDesk($id: ID!, $userId: String, $email: String) {
    updateDesk(
      input: {
        filter: { id: [$id] }
        remove: { account: { accountID: $userId, email: $email } }
      }
    ) {
      desk {
        id
        account {
          displayName
          email
          accountID
          tenantID
        }
      }
    }
  }
`;

export const ADD_USER_TO_DESK = gql`
  mutation addUserToDesk($id: ID!, $userId: String, $email: String!) {
    updateDesk(
      input: {
        filter: { id: [$id] }
        set: { account: { accountID: $userId, email: $email } }
      }
    ) {
      desk {
        id
        account {
          displayName
          email
          accountID
          tenantID
        }
      }
    }
  }
`;
