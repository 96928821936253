import React, { PropsWithChildren, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { useAccountRequests } from "../../../../../api/grpc/account/useAccountRequests";
import { useTotpInputChange } from "../../../../../lib/hooks/useTotpInputChange";

import { TotpCodeInput } from "../../../../shared/Forms/TotpCodeInput/TotpCodeInput";
import { ModalMFA } from "../ModalMFA";
import { Icon } from "../../../../shared/Icon/Icon";
import { MFAType } from "../../../../../api/grpc/account/account";

export const MfaQRCode = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [qrCodeView, setQrCodeView] = useState(true);
  const { userTotp, enrollUserForTotp, updateUser, user } = useAuthContext();
  const { setMfaModal } = useMfaContext();
  const { timeZone } = useTimeZoneContext();
  const { updateAccount } = useAccountRequests();
  const { handleTotpInputChange, totpCode } = useTotpInputChange();

  const handleEnroll = async () => {
    try {
      if (!user) {
        return;
      }
      setLoading(true);
      await enrollUserForTotp(totpCode.join(""));

      const {
        response: { mfaType },
      } = await updateAccount(
        {
          displayName: user.displayName,
          email: user.email.toLowerCase(),
          firstName: user.firstName,
          lastName: user.lastName,
          newPassword: "",
          phoneNumber: "",
          photoUrl: user.picture,
          googleAccountConnected: user.googleAccountConnected,
          o365AccountConnected: user.o365AccountConnected,
          googleAccountLinkedEmail: user.googleAccountLinkedEmail,
          o365AccountLinkedEmail: user.o365AccountLinkedEmail,
          timeZone,
          mfaType: MFAType.MFA_TYPE_TOTP,
          recoveryEmail: "",
        },
        ["mfaType"]
      );

      updateUser({
        mfaType: mfaType,
      });

      setMfaModal("enabled");
    } catch (error: any) {
      if (error?.code === "auth/invalid-verification-code") {
        setError("Wrong code");
        return;
      }
      setMfaModal("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalMFA
      title="Activate two-factor authentication"
      keyPoints={
        <KeyPoints
          qrCodeView={qrCodeView}
          handleManuallyCodeView={() => setQrCodeView(false)}
          handleQRCodeView={() => setQrCodeView(true)}
        />
      }
      closeButton="Cancel"
      nextButton="Next"
      handleBackButton={() => setMfaModal(undefined)}
      handleNextButton={handleEnroll}
      disabledBack={loading}
      disabledNext={totpCode.join("").length < 6 || loading}
    >
      {userTotp !== undefined && (
        <div className="pt-3 pl-4">
          {qrCodeView ? (
            <QRCodeSVG value={userTotp.qRCodeURL} className="mt-2" />
          ) : (
            <h6 className="ModalMFA__secret">
              {userTotp.totpSecret.secretKey}
            </h6>
          )}

          <FormGroup className="pt-2">
            <Label className="d-block mt-4">
              Enter the 6-digit code generated by your authenticator app.
            </Label>
            <TotpCodeInput
              totpCode={totpCode}
              handleTotpInputChange={handleTotpInputChange}
            />
            {error && (
              <FormFeedback className="invalid-feedback">{error}</FormFeedback>
            )}
          </FormGroup>
        </div>
      )}
    </ModalMFA>
  );
};

const KeyPoints = ({
  handleQRCodeView,
  handleManuallyCodeView,
  qrCodeView,
}: PropsWithChildren<{
  handleManuallyCodeView: () => void;
  handleQRCodeView: () => void;
  qrCodeView: boolean;
}>) => {
  return (
    <>
      {qrCodeView ? (
        <div>
          <div className="d-flex ModalMFA__key">
            <Icon icon="green-flag" />
            <div>
              <span>Scan the QR code into your authenticator app, or</span>
              <a href="#" onClick={handleManuallyCodeView} className="pl-1">
                or click here to manually type the code instead
              </a>
            </div>
          </div>
          <div className="d-flex ModalMFA__key">
            <Icon icon="green-flag" />
            <div>
              <span>Make sure you have installed the</span>

              <a
                href="https://support.google.com/accounts/answer/1066447"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-1 text-underline"
              >
                Google Authenticator
              </a>
              <span>
                app on your mobile, otherwise download it from your application
                distribution platform.
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex ModalMFA__key">
          <Icon icon="green-flag" />
          <div>
            <span>Type the text code into your authenticator app, or</span>

            <a href="#" onClick={handleQRCodeView} className="pl-1">
              click here to scan a QR code instead
            </a>
          </div>
        </div>
      )}
    </>
  );
};
