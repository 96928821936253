import React from "react";
import { BillingsMain } from "../../components/Billings/BillingsMain";
import { PageHeader } from "../../components/shared/PageHeader/PageHeader";
import { tooltips } from "../../lib/utils/tooltips";

export const Billings = () => {
  return (
    <div className="default-page">
      <PageHeader title="Billing" hideBorder message={tooltips.billings} />
      <BillingsMain />
    </div>
  );
};
