import React from "react";
import cs from "classnames";

import { getRandomNumber } from "../../../lib/helpers/getRandomNumber";

import { InformationBox } from "../InformationBox/InformationBox";
import { NavLink } from "react-router-dom";

import "./HomeDetailsCard.scss";

export interface HomeCardList {
  label: JSX.Element;
  value: JSX.Element;
  rowValue?: string;
}

interface Props {
  title: string;
  total?: string;
  tooltip?: string;
  bottomDiff?: boolean;
  list: HomeCardList[];
  listPlaceholder?: string;
  subTitle?: string | React.ReactNode;
  onRowClick?: (value: string) => void;
  link?: string;
  adjustInfoLeft?: boolean;
}

export const HomeDetailsCard = ({
  title,
  subTitle,
  total,
  tooltip,
  bottomDiff,
  list,
  onRowClick,
  listPlaceholder,
  link,
  adjustInfoLeft,
}: Props) => {
  const classes = cs("HomeDetailsCard default-box", {
    "HomeDetailsCard--bottom": !!bottomDiff,
  });

  return (
    <div className={classes}>
      <h3 className="d-flex align-items-center">
        {link ? <NavLink to={link}>{title}</NavLink> : title}
        {tooltip && (
          <InformationBox
            leftDiff
            direction="left"
            message={tooltip}
            adjustInfoLeft={adjustInfoLeft}
          />
        )}
      </h3>
      <div className="HomeDetailsCard__sub-title">
        {typeof subTitle === "string" ? (
          <h5>{subTitle}</h5>
        ) : (
          <div className="d-flex align-items-center">{subTitle}</div>
        )}
        {total && <h5 className="total">{total}</h5>}
      </div>
      <ul className="HomeDetailsCard__list">
        {list.map((item) => {
          const key = getRandomNumber() + "-home-details";

          return (
            <li key={key}>
              <div className="HomeDetailsCard__label">{item.label}</div>
              <div
                className="HomeDetailsCard__value"
                onClick={() => {
                  if (!item.rowValue || !onRowClick) {
                    return;
                  }

                  onRowClick(item.rowValue);
                }}
              >
                {item.value}
              </div>
            </li>
          );
        })}
        {listPlaceholder && list.length === 0 && (
          <li className="placeholder">
            <p>{listPlaceholder}</p>
          </li>
        )}
      </ul>
    </div>
  );
};
