export const numberOfFilters = (
  activeFilters: number,
  activeTagsFilter: string[]
) => {
  if (activeFilters <= 0 && !!!activeTagsFilter.length) {
    return undefined;
  }

  if (activeFilters > 0 && !!activeTagsFilter.length) {
    return activeFilters + 1;
  }

  if (activeFilters <= 0 && !!activeTagsFilter.length) {
    return 1;
  }

  return activeFilters;
};
