import React from "react";

interface Props {
  state: boolean;
  placeholder?: React.ReactNode;
  children: React.ReactNode;
}

export const HandleRequestState = (props: Props) => {
  if (props.state) {
    return <>{props.placeholder}</>;
  }

  return <>{props.children}</>;
};
