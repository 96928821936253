import React, { ReactNode } from "react";
import cs from "classnames";

import "./CenteredBox.scss";

interface CenteredBoxProps {
  constant?: string;
  className?: string;
  children: ReactNode;
}

export const CenteredBox = (props: CenteredBoxProps) => {
  const classes = cs("CenteredBox", props.className);

  return (
    <div className={classes}>
      {props.constant && <h4 className="mb-3">{props.constant}</h4>}
      <div className="CenteredBox__modal p-4 d-flex flex-column align-items-center">
        {props.children}
      </div>
    </div>
  );
};
