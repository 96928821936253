import React from "react";

import { useRoomInsightsContext } from "../../Context/Room/Overview/RoomInsightsContext";

import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { LineChart } from "../../shared/Charts/LineChart/LineChart";
import { ChartCard } from "../../shared/ChartCard/ChartCard";
import { tooltips } from "../../../../lib/utils/tooltips";

export const RoomsLineChart = () => {
  const {
    loadingRange: loading,
    errorRange: error,
    roomsTimeRange: data,
  } = useRoomInsightsContext();

  return (
    <>
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<LoadingBox className="OverviewGraphsRow__line" />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={
            <ChartCard title="Meetings" className="OverviewGraphsRow__line">
              <CenteredBox>{error?.message}</CenteredBox>
            </ChartCard>
          }
        >
          <ChartCard
            title="Meetings"
            className="OverviewGraphsRow__line"
            message={tooltips.insights.rooms.meetings}
            direction="right"
          >
            <LineChart chartData={data} />
          </ChartCard>
        </HandleRequestState>
      </HandleLoadingState>
    </>
  );
};
