import React, { PropsWithChildren, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { useEWSFormik } from "../lib/useEWSFormik";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { config } from "../CalendarForms/GSuiteConnectButton/GSuiteConnectButton";
import { redirectToO365 } from "../CalendarForms/O365ConnectButton/o365-popup";
import { getCalendarTypeSlug } from "../lib/helpers";

import { CalendarType } from "../../../api/grpc/calendarprovider/calendarprovider";
import { ReAuthModal } from "../../Modals/ReAuthModal/ReAuthModal";
import { Button } from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";
import { EWSServiceForm } from "../CalendarForms/EWSServiceForm";
import { IntegrationBanner } from "../shared/IntegrationBanner/IntegrationBanner";
import { Providers } from "../../../lib/context/Calendar/CalendarContextProvider";

export const AdminIntegrationBanner = ({
  calendar,
}: PropsWithChildren<{
  calendar: Providers;
}>) => {
  const [openReAuthModal, setOpenReAuthModal] = useState(false);
  const [openEWSForm, setOpenEWSForm] = useState(false);

  const { refetchCalendars, brokenProviders, setBrokenProviders } =
    useCalendarContext();
  const { user } = useAuthContext();
  const { reAuthenticateIntegration, updateEWSProvider } =
    useCalendarRequests();

  const handleGoogleReAuth = async (token: string) => {
    if (!user) {
      return;
    }

    try {
      await reAuthenticateIntegration(calendar.iD, {
        oneofKind: "gsuite",
        gsuite: {
          oAuthToken: token,
          calendarType: CalendarType.GSUITE_WEB,
        },
      });

      await refetchCalendars();

      if (openReAuthModal) {
        setOpenReAuthModal(false);
      }

      toast.success(
        <div className="SuccessToast">
          <div className="flex-a-center">
            <Icon icon="check-icon" />
            <span className="ml-2">
              Re-authentication finished successfully!
            </span>
          </div>
        </div>,
        {
          position: toast.POSITION.TOP_LEFT,
        }
      );

      return;
    } catch (error: any) {
      toast.error(error?.message ?? "Calendar couldn't be added!");
    }
  };

  const formik = useEWSFormik({
    initialValues: {
      name: calendar.name,
      domain: calendar.hostUrl,
      svcAccount: calendar.email,
      password: "",
      impersonationEnabled: calendar?.settings?.impersonationEnabled || false,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        if (!user || !values) {
          return;
        }

        await updateEWSProvider({
          customerID: user?.customerid || "",
          iD: calendar.iD,
          ...values,
        });

        await refetchCalendars();

        formikHelpers.resetForm();
        setOpenEWSForm(false);

        if (openReAuthModal) {
          setOpenReAuthModal(false);
        }

        toast.success(
          <div className="SuccessToast">
            <div className="flex-a-center">
              <Icon icon="check-icon" />
              <span className="ml-2">
                Re-authentication finished successfully!
              </span>
            </div>
          </div>,
          {
            position: toast.POSITION.TOP_LEFT,
          }
        );
        return;
      } catch (error: any) {
        toast.error(error?.message ?? "Calendar couldn't be re-authenticated!");
      }
    },
  });

  const handleFixNowButton = () => {
    if (calendar.iD.toLowerCase().startsWith("o365")) {
      return redirectToO365("admin", false, calendar.iD);
    }

    if (calendar.iD.toLowerCase().startsWith("gsuite")) {
      return window.google.accounts.oauth2
        .initCodeClient({
          client_id: config.clientId,
          scope: config.scope,
          callback: async (tokenResponse: any) => {
            if (tokenResponse && tokenResponse.code) {
              await handleGoogleReAuth(tokenResponse.code);
            }
          },
        })
        .requestCode();
    }

    return setOpenEWSForm(true);
  };

  const handleCloseBanner = () => {
    setBrokenProviders((prev) => prev.filter((p) => p.iD !== calendar.iD));
  };

  if (!brokenProviders.some((provider) => provider.iD === calendar.iD))
    return null;

  return (
    <>
      {openReAuthModal && (
        <ReAuthModal
          closeModal={() => setOpenReAuthModal(false)}
          handleReAuthButton={handleFixNowButton}
          type="Admin"
          isEws={calendar.iD.toLowerCase().startsWith("ews")}
        />
      )}

      <Modal
        isOpen={openEWSForm}
        className="ReAuthEWS"
        toggle={() => setOpenEWSForm((prev) => !prev)}
      >
        <EWSServiceForm formik={formik} isVerify email={calendar.email} />

        <div className="d-flex justify-content-center gap-1 pt-3 mt-4 border-top">
          <Button
            size="small"
            color="outline-secondary"
            title="Close"
            onClick={() => setOpenEWSForm(false)}
          />
          <Button
            size="small"
            title="Verify"
            color="primary"
            disabled={
              !formik.dirty ||
              (formik.dirty && !!Object.keys(formik.errors).length)
            }
            onClick={formik.submitForm}
          />
        </div>
      </Modal>
      <IntegrationBanner
        classes="TokenToast TokenToast__admins TokenToast__show"
        header={
          <span className="ml-2">
            Admin integration
            <NavLink
              to={`/manage/calendars/${getCalendarTypeSlug(calendar.calType)}/${
                calendar.iD
              }`}
              className="px-1"
            >
              {calendar.name}
            </NavLink>
            re-authentication required
          </span>
        }
        reAuthButton={handleFixNowButton}
        setOpenReAuthModal={() => {
          setOpenReAuthModal(true);
        }}
        handleCloseButton={() => handleCloseBanner()}
      />
    </>
  );
};
