import { MainRouteProps } from "./routes";

export const filterMenuLevel = (menu: MainRouteProps[], role: string) => {
  // If current user is an admin, there's no need to filter the menu
  if (role === "admin") {
    return menu;
  }

  return menu.filter((item) => !item.role || item.role === role);
};
