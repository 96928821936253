import React from "react";
import { NavLink } from "react-router-dom";

import { getCalendarTypeSlug } from "../../../lib/helpers";

import { Icon, IconDefinition } from "../../../../shared/Icon/Icon";
import {
  CalendarType,
  DirectorySyncIntegration,
  GGCalendarProvider,
  GGCalendarProviderStatus,
} from "../../../../../api/grpc/calendarprovider/calendarprovider";
import {
  CALENDARS_ROOT_PATH,
  DIRECTORY_ROOT_PATH,
} from "../../../../../lib/routes/routes";

import "./IntegrationsItem.scss";

interface Props {
  title: string;
  type: CalendarType;
  calendars: (GGCalendarProvider | DirectorySyncIntegration)[];
  isDirectory?: boolean;
  onClick?: () => void;
  icon?: IconDefinition;
  hasFailedCalendar?: boolean;
}

export const IntegrationsItem = (props: Props) => {
  const {
    title,
    type,
    calendars,
    isDirectory,
    onClick,
    icon,
    hasFailedCalendar,
  } = props;
  const slug = getCalendarTypeSlug(type);

  const isGGCalendarProvider = (
    item: GGCalendarProvider | DirectorySyncIntegration
  ): item is GGCalendarProvider => {
    return (item as GGCalendarProvider).status !== undefined;
  };

  return (
    <>
      {calendars.length > 0 && (
        <div className="IntegrationsItem d-flex flex-column">
          <div className="IntegrationsItem__header">
            <Icon icon={isDirectory ? "directory-icon" : icon} />
            <h4 className="IntegrationsItem__title">{title}</h4>
            {hasFailedCalendar && (
              <Icon icon="red-error-icon" className="ml-2" />
            )}
          </div>
          <div className="IntegrationsItem__calendars d-flex flex-column w-100">
            {calendars?.map((item) => {
              return (
                <NavLink
                  key={`${item.iD}-list-calendar`}
                  className={`IntegrationsItem__link pb-1 ${
                    isGGCalendarProvider(item) &&
                    item.status === GGCalendarProviderStatus.FAILED
                      ? "IntegrationsItem__link--error"
                      : ""
                  }`}
                  to={
                    isDirectory
                      ? `${DIRECTORY_ROOT_PATH}/${item.name}/${slug}/${item.iD}`
                      : `${CALENDARS_ROOT_PATH}/${slug}/${item.iD}`
                  }
                  onClick={() => {
                    if (localStorage.getItem("listCalendars")) {
                      localStorage.removeItem("listCalendars");
                    }

                    if (onClick) {
                      return onClick();
                    }

                    return;
                  }}
                >
                  <Icon
                    icon="enter-icon"
                    className={`${
                      isGGCalendarProvider(item) &&
                      item.status === GGCalendarProviderStatus.FAILED
                        ? "IntegrationsItem__enter--error"
                        : ""
                    }`}
                  />
                  {item.name}
                </NavLink>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
