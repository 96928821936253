import React, { Dispatch, SetStateAction } from "react";
import { WorkplaceTypes } from "../../Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { CustomerSettings } from "../../../api/grpc/customer/customer";
import { CustomerSettingsVariables } from "../../Organization/OrganizationSettings/InsightsCard/Insights/Insights";

export interface InsightsLocation {
  locationId: string;
  locationName: string;
  locationType: WorkplaceTypes | "";
}

export interface IInsightsContext {
  startTime: Date;
  endTime: Date;
  setStartTime: Dispatch<SetStateAction<Date>>;
  setEndTime: Dispatch<SetStateAction<Date>>;
  insightsLocation: InsightsLocation;
  setInsightsLocation: Dispatch<SetStateAction<InsightsLocation>>;
  loadingGeneralSettings: boolean;
  errorGeneralSettings?: Error;
  customerSettings?: CustomerSettings;
  handleWorkingHours: ({
    startTime,
    endTime,
    errorReportingEmail,
    meetingSpaceCostPerHr,
    currency,
    workingWeekDays,
    collectInsights,
    ssoEnable,
    disableDeskSimultaneous,
    showEventOrganizer,
  }: CustomerSettingsVariables) => Promise<void>;
}

const values: IInsightsContext = {
  startTime: new Date(),
  endTime: new Date(),
  setStartTime: () => {},
  setEndTime: () => {},
  insightsLocation: {
    locationId: "",
    locationName: "",
    locationType: "",
  },
  setInsightsLocation: () => {},
  loadingGeneralSettings: false,
  errorGeneralSettings: undefined,
  customerSettings: undefined,
  handleWorkingHours: async () => {},
};

export const InsightsContext = React.createContext(values);

export const useInsightsContext = () => {
  const context = React.useContext(InsightsContext);

  if (!context) {
    throw new Error(
      "useInsightsContext must be used inside of InsightsContext"
    );
  }

  return context;
};
