export const editProfileButtonOptions = [
  {
    icon: "transfer-icon",
    label: "Transfer",
    type: "transfer",
  },
  {
    icon: "delete-default",
    label: "Delete",
    type: "delete",
  },
];
