// @generated by protobuf-ts 2.9.4 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "calendarprovider.proto" (package "calendarprovider", syntax proto3)
// tslint:disable
import { Sync } from "./calendarprovider";
import { DirectoryProvider } from "./calendarprovider";
import type { UpdateDirectoryProviderCredentialsRequest } from "./calendarprovider";
import type { UpdateDirectoryIntegrationStatusResponse } from "./calendarprovider";
import type { UpdateDirectoryIntegrationStatusRequest } from "./calendarprovider";
import type { DeleteDirectoryProviderRequest } from "./calendarprovider";
import type { RetrieveDirectoryProviderResponse } from "./calendarprovider";
import type { RetrieveDirectoryProviderRequest } from "./calendarprovider";
import type { ListDirectoryProviderResponse } from "./calendarprovider";
import type { ListDirectoryProviderRequest } from "./calendarprovider";
import type { CreateDirectoryProviderRequest } from "./calendarprovider";
import type { GenerateAuthLinkResponse } from "./calendarprovider";
import type { GenerateAuthLinkRequest } from "./calendarprovider";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { CalendarProvider } from "./calendarprovider";
import type { UpdateCalendarIntegrationStatusResponse } from "./calendarprovider";
import type { UpdateCalendarIntegrationStatusRequest } from "./calendarprovider";
import type { Empty } from "./google/protobuf/empty";
import type { ValidateCalendarIntegrationCredentialsResponse } from "./calendarprovider";
import type { ValidateCalendarIntegrationCredentialsRequest } from "./calendarprovider";
import type { ListMatchedCalendarIntegrationsResponse } from "./calendarprovider";
import type { ListMatchedCalendarIntegrationsRequest } from "./calendarprovider";
import type { GetSupportedIntegrationsResponse } from "./calendarprovider";
import type { GetSupportedIntegrationsRequest } from "./calendarprovider";
import type { UpdateCalendarProviderCredentialsRequest } from "./calendarprovider";
import type { ValidateEWSRoomResponse } from "./calendarprovider";
import type { ValidateEWSRoomRequest } from "./calendarprovider";
import type { EWSProvider } from "./calendarprovider";
import type { RetrieveEWSProviderRequest } from "./calendarprovider";
import type { DeleteProviderResponse } from "./calendarprovider";
import type { DeleteProviderRequest } from "./calendarprovider";
import type { RetrieveProviderResponse } from "./calendarprovider";
import type { RetrieveProviderRequest } from "./calendarprovider";
import type { RetrieveProviderListResponse } from "./calendarprovider";
import type { RetrieveProviderListRequest } from "./calendarprovider";
import type { ListCustomerCalendarsRequest } from "./calendarprovider";
import type { ListCalendarsResponse } from "./calendarprovider";
import type { ListCalendarsRequest } from "./calendarprovider";
import type { PersonalCalendars } from "./calendarprovider";
import type { ListPersonalCalendarsRequest } from "./calendarprovider";
import type { PersonalCalendar } from "./calendarprovider";
import type { LinkPersonalCalendarRequest } from "./calendarprovider";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Provider } from "./calendarprovider";
import type { CreateProviderRequest } from "./calendarprovider";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Endpoint group for operations pertaining to Calendar Providers
 *
 * @generated from protobuf service calendarprovider.CalendarProvider
 */
export interface ICalendarProviderClient {
    /**
     * Create a Calendar Provider based on the calendar Type
     *
     * @generated from protobuf rpc: CreateProvider(calendarprovider.CreateProviderRequest) returns (calendarprovider.Provider);
     */
    createProvider(input: CreateProviderRequest, options?: RpcOptions): UnaryCall<CreateProviderRequest, Provider>;
    /**
     * @generated from protobuf rpc: LinkPersonalCalendar(calendarprovider.LinkPersonalCalendarRequest) returns (calendarprovider.PersonalCalendar);
     */
    linkPersonalCalendar(input: LinkPersonalCalendarRequest, options?: RpcOptions): UnaryCall<LinkPersonalCalendarRequest, PersonalCalendar>;
    /**
     * @generated from protobuf rpc: ListPersonalCalendars(calendarprovider.ListPersonalCalendarsRequest) returns (calendarprovider.PersonalCalendars);
     */
    listPersonalCalendars(input: ListPersonalCalendarsRequest, options?: RpcOptions): UnaryCall<ListPersonalCalendarsRequest, PersonalCalendars>;
    /**
     * List calendars the service account is subscribed to.
     *
     * @generated from protobuf rpc: ListCalendars(calendarprovider.ListCalendarsRequest) returns (calendarprovider.ListCalendarsResponse);
     */
    listCalendars(input: ListCalendarsRequest, options?: RpcOptions): UnaryCall<ListCalendarsRequest, ListCalendarsResponse>;
    /**
     * List calendars the service account is subscribed to.
     *
     * @generated from protobuf rpc: ListCustomerCalendars(calendarprovider.ListCustomerCalendarsRequest) returns (calendarprovider.ListCalendarsResponse);
     */
    listCustomerCalendars(input: ListCustomerCalendarsRequest, options?: RpcOptions): UnaryCall<ListCustomerCalendarsRequest, ListCalendarsResponse>;
    /**
     * Retrieve a list of configured calendar providers
     *
     * @generated from protobuf rpc: RetrieveProviderList(calendarprovider.RetrieveProviderListRequest) returns (calendarprovider.RetrieveProviderListResponse);
     */
    retrieveProviderList(input: RetrieveProviderListRequest, options?: RpcOptions): UnaryCall<RetrieveProviderListRequest, RetrieveProviderListResponse>;
    /**
     * Retrieve a single calendar provider
     *
     * @generated from protobuf rpc: RetrieveProvider(calendarprovider.RetrieveProviderRequest) returns (calendarprovider.RetrieveProviderResponse);
     */
    retrieveProvider(input: RetrieveProviderRequest, options?: RpcOptions): UnaryCall<RetrieveProviderRequest, RetrieveProviderResponse>;
    /**
     * Delete a single calendar provider
     *
     * @generated from protobuf rpc: DeleteProvider(calendarprovider.DeleteProviderRequest) returns (calendarprovider.DeleteProviderResponse);
     */
    deleteProvider(input: DeleteProviderRequest, options?: RpcOptions): UnaryCall<DeleteProviderRequest, DeleteProviderResponse>;
    /**
     * Retrieve EWS connection details
     *
     * @generated from protobuf rpc: RetrieveEWSProviderConnection(calendarprovider.RetrieveEWSProviderRequest) returns (calendarprovider.EWSProvider);
     */
    retrieveEWSProviderConnection(input: RetrieveEWSProviderRequest, options?: RpcOptions): UnaryCall<RetrieveEWSProviderRequest, EWSProvider>;
    /**
     * Update EWS connection details
     *
     * @generated from protobuf rpc: UpdateEWSProvider(calendarprovider.EWSProvider) returns (calendarprovider.Provider);
     */
    updateEWSProvider(input: EWSProvider, options?: RpcOptions): UnaryCall<EWSProvider, Provider>;
    /**
     * Check if room calendar exist for EWS
     *
     * @generated from protobuf rpc: ValidateEWSCalendar(calendarprovider.ValidateEWSRoomRequest) returns (calendarprovider.ValidateEWSRoomResponse);
     */
    validateEWSCalendar(input: ValidateEWSRoomRequest, options?: RpcOptions): UnaryCall<ValidateEWSRoomRequest, ValidateEWSRoomResponse>;
    /**
     * @generated from protobuf rpc: UpdateCalendarProviderCredentials(calendarprovider.UpdateCalendarProviderCredentialsRequest) returns (calendarprovider.Provider);
     */
    updateCalendarProviderCredentials(input: UpdateCalendarProviderCredentialsRequest, options?: RpcOptions): UnaryCall<UpdateCalendarProviderCredentialsRequest, Provider>;
    /**
     * Retrieve a list of supported calendar providers
     *
     * @generated from protobuf rpc: GetSupportedIntegrations(calendarprovider.GetSupportedIntegrationsRequest) returns (calendarprovider.GetSupportedIntegrationsResponse);
     */
    getSupportedIntegrations(input: GetSupportedIntegrationsRequest, options?: RpcOptions): UnaryCall<GetSupportedIntegrationsRequest, GetSupportedIntegrationsResponse>;
    /**
     * ListMatchedCalendarIntegrations retrieve a list of supported admin calendar integrations in same domain
     *
     * @generated from protobuf rpc: ListMatchedCalendarIntegrations(calendarprovider.ListMatchedCalendarIntegrationsRequest) returns (calendarprovider.ListMatchedCalendarIntegrationsResponse);
     */
    listMatchedCalendarIntegrations(input: ListMatchedCalendarIntegrationsRequest, options?: RpcOptions): UnaryCall<ListMatchedCalendarIntegrationsRequest, ListMatchedCalendarIntegrationsResponse>;
    /**
     * ListMatchedCalendarIntegrations retrieve a list of supported admin calendar integrations in same domain
     *
     * @generated from protobuf rpc: ValidateCalendarIntegrationCredentials(calendarprovider.ValidateCalendarIntegrationCredentialsRequest) returns (calendarprovider.ValidateCalendarIntegrationCredentialsResponse);
     */
    validateCalendarIntegrationCredentials(input: ValidateCalendarIntegrationCredentialsRequest, options?: RpcOptions): UnaryCall<ValidateCalendarIntegrationCredentialsRequest, ValidateCalendarIntegrationCredentialsResponse>;
    /**
     * @generated from protobuf rpc: BuildIntegrationCalendarsCache(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    buildIntegrationCalendarsCache(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * @generated from protobuf rpc: UpdateCalendarIntegrationStatus(calendarprovider.UpdateCalendarIntegrationStatusRequest) returns (calendarprovider.UpdateCalendarIntegrationStatusResponse);
     */
    updateCalendarIntegrationStatus(input: UpdateCalendarIntegrationStatusRequest, options?: RpcOptions): UnaryCall<UpdateCalendarIntegrationStatusRequest, UpdateCalendarIntegrationStatusResponse>;
}
/**
 * Endpoint group for operations pertaining to Calendar Providers
 *
 * @generated from protobuf service calendarprovider.CalendarProvider
 */
export class CalendarProviderClient implements ICalendarProviderClient, ServiceInfo {
    typeName = CalendarProvider.typeName;
    methods = CalendarProvider.methods;
    options = CalendarProvider.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Create a Calendar Provider based on the calendar Type
     *
     * @generated from protobuf rpc: CreateProvider(calendarprovider.CreateProviderRequest) returns (calendarprovider.Provider);
     */
    createProvider(input: CreateProviderRequest, options?: RpcOptions): UnaryCall<CreateProviderRequest, Provider> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateProviderRequest, Provider>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: LinkPersonalCalendar(calendarprovider.LinkPersonalCalendarRequest) returns (calendarprovider.PersonalCalendar);
     */
    linkPersonalCalendar(input: LinkPersonalCalendarRequest, options?: RpcOptions): UnaryCall<LinkPersonalCalendarRequest, PersonalCalendar> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<LinkPersonalCalendarRequest, PersonalCalendar>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListPersonalCalendars(calendarprovider.ListPersonalCalendarsRequest) returns (calendarprovider.PersonalCalendars);
     */
    listPersonalCalendars(input: ListPersonalCalendarsRequest, options?: RpcOptions): UnaryCall<ListPersonalCalendarsRequest, PersonalCalendars> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListPersonalCalendarsRequest, PersonalCalendars>("unary", this._transport, method, opt, input);
    }
    /**
     * List calendars the service account is subscribed to.
     *
     * @generated from protobuf rpc: ListCalendars(calendarprovider.ListCalendarsRequest) returns (calendarprovider.ListCalendarsResponse);
     */
    listCalendars(input: ListCalendarsRequest, options?: RpcOptions): UnaryCall<ListCalendarsRequest, ListCalendarsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCalendarsRequest, ListCalendarsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List calendars the service account is subscribed to.
     *
     * @generated from protobuf rpc: ListCustomerCalendars(calendarprovider.ListCustomerCalendarsRequest) returns (calendarprovider.ListCalendarsResponse);
     */
    listCustomerCalendars(input: ListCustomerCalendarsRequest, options?: RpcOptions): UnaryCall<ListCustomerCalendarsRequest, ListCalendarsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCustomerCalendarsRequest, ListCalendarsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve a list of configured calendar providers
     *
     * @generated from protobuf rpc: RetrieveProviderList(calendarprovider.RetrieveProviderListRequest) returns (calendarprovider.RetrieveProviderListResponse);
     */
    retrieveProviderList(input: RetrieveProviderListRequest, options?: RpcOptions): UnaryCall<RetrieveProviderListRequest, RetrieveProviderListResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<RetrieveProviderListRequest, RetrieveProviderListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve a single calendar provider
     *
     * @generated from protobuf rpc: RetrieveProvider(calendarprovider.RetrieveProviderRequest) returns (calendarprovider.RetrieveProviderResponse);
     */
    retrieveProvider(input: RetrieveProviderRequest, options?: RpcOptions): UnaryCall<RetrieveProviderRequest, RetrieveProviderResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<RetrieveProviderRequest, RetrieveProviderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Delete a single calendar provider
     *
     * @generated from protobuf rpc: DeleteProvider(calendarprovider.DeleteProviderRequest) returns (calendarprovider.DeleteProviderResponse);
     */
    deleteProvider(input: DeleteProviderRequest, options?: RpcOptions): UnaryCall<DeleteProviderRequest, DeleteProviderResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteProviderRequest, DeleteProviderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve EWS connection details
     *
     * @generated from protobuf rpc: RetrieveEWSProviderConnection(calendarprovider.RetrieveEWSProviderRequest) returns (calendarprovider.EWSProvider);
     */
    retrieveEWSProviderConnection(input: RetrieveEWSProviderRequest, options?: RpcOptions): UnaryCall<RetrieveEWSProviderRequest, EWSProvider> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<RetrieveEWSProviderRequest, EWSProvider>("unary", this._transport, method, opt, input);
    }
    /**
     * Update EWS connection details
     *
     * @generated from protobuf rpc: UpdateEWSProvider(calendarprovider.EWSProvider) returns (calendarprovider.Provider);
     */
    updateEWSProvider(input: EWSProvider, options?: RpcOptions): UnaryCall<EWSProvider, Provider> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<EWSProvider, Provider>("unary", this._transport, method, opt, input);
    }
    /**
     * Check if room calendar exist for EWS
     *
     * @generated from protobuf rpc: ValidateEWSCalendar(calendarprovider.ValidateEWSRoomRequest) returns (calendarprovider.ValidateEWSRoomResponse);
     */
    validateEWSCalendar(input: ValidateEWSRoomRequest, options?: RpcOptions): UnaryCall<ValidateEWSRoomRequest, ValidateEWSRoomResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValidateEWSRoomRequest, ValidateEWSRoomResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCalendarProviderCredentials(calendarprovider.UpdateCalendarProviderCredentialsRequest) returns (calendarprovider.Provider);
     */
    updateCalendarProviderCredentials(input: UpdateCalendarProviderCredentialsRequest, options?: RpcOptions): UnaryCall<UpdateCalendarProviderCredentialsRequest, Provider> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCalendarProviderCredentialsRequest, Provider>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve a list of supported calendar providers
     *
     * @generated from protobuf rpc: GetSupportedIntegrations(calendarprovider.GetSupportedIntegrationsRequest) returns (calendarprovider.GetSupportedIntegrationsResponse);
     */
    getSupportedIntegrations(input: GetSupportedIntegrationsRequest, options?: RpcOptions): UnaryCall<GetSupportedIntegrationsRequest, GetSupportedIntegrationsResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSupportedIntegrationsRequest, GetSupportedIntegrationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ListMatchedCalendarIntegrations retrieve a list of supported admin calendar integrations in same domain
     *
     * @generated from protobuf rpc: ListMatchedCalendarIntegrations(calendarprovider.ListMatchedCalendarIntegrationsRequest) returns (calendarprovider.ListMatchedCalendarIntegrationsResponse);
     */
    listMatchedCalendarIntegrations(input: ListMatchedCalendarIntegrationsRequest, options?: RpcOptions): UnaryCall<ListMatchedCalendarIntegrationsRequest, ListMatchedCalendarIntegrationsResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchedCalendarIntegrationsRequest, ListMatchedCalendarIntegrationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ListMatchedCalendarIntegrations retrieve a list of supported admin calendar integrations in same domain
     *
     * @generated from protobuf rpc: ValidateCalendarIntegrationCredentials(calendarprovider.ValidateCalendarIntegrationCredentialsRequest) returns (calendarprovider.ValidateCalendarIntegrationCredentialsResponse);
     */
    validateCalendarIntegrationCredentials(input: ValidateCalendarIntegrationCredentialsRequest, options?: RpcOptions): UnaryCall<ValidateCalendarIntegrationCredentialsRequest, ValidateCalendarIntegrationCredentialsResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<ValidateCalendarIntegrationCredentialsRequest, ValidateCalendarIntegrationCredentialsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BuildIntegrationCalendarsCache(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    buildIntegrationCalendarsCache(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCalendarIntegrationStatus(calendarprovider.UpdateCalendarIntegrationStatusRequest) returns (calendarprovider.UpdateCalendarIntegrationStatusResponse);
     */
    updateCalendarIntegrationStatus(input: UpdateCalendarIntegrationStatusRequest, options?: RpcOptions): UnaryCall<UpdateCalendarIntegrationStatusRequest, UpdateCalendarIntegrationStatusResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCalendarIntegrationStatusRequest, UpdateCalendarIntegrationStatusResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service calendarprovider.DirectoryProvider
 */
export interface IDirectoryProviderClient {
    /**
     * GenerateAuthLink generate auth link for integration.
     *
     * @generated from protobuf rpc: GenerateAuthLink(calendarprovider.GenerateAuthLinkRequest) returns (calendarprovider.GenerateAuthLinkResponse);
     */
    generateAuthLink(input: GenerateAuthLinkRequest, options?: RpcOptions): UnaryCall<GenerateAuthLinkRequest, GenerateAuthLinkResponse>;
    /**
     * CreateDirectoryProvider is a RPC method for creating new sync directory integration.
     *
     * @generated from protobuf rpc: CreateDirectoryProvider(calendarprovider.CreateDirectoryProviderRequest) returns (calendarprovider.Provider);
     */
    createDirectoryProvider(input: CreateDirectoryProviderRequest, options?: RpcOptions): UnaryCall<CreateDirectoryProviderRequest, Provider>;
    /**
     * ListDirectoryProvider return list of customer's sync directory integrations
     *
     * @generated from protobuf rpc: ListDirectoryProvider(calendarprovider.ListDirectoryProviderRequest) returns (calendarprovider.ListDirectoryProviderResponse);
     */
    listDirectoryProvider(input: ListDirectoryProviderRequest, options?: RpcOptions): UnaryCall<ListDirectoryProviderRequest, ListDirectoryProviderResponse>;
    /**
     * RetrieveDirectoryProvider return a sync directory integration by id
     *
     * @generated from protobuf rpc: RetrieveDirectoryProvider(calendarprovider.RetrieveDirectoryProviderRequest) returns (calendarprovider.RetrieveDirectoryProviderResponse);
     */
    retrieveDirectoryProvider(input: RetrieveDirectoryProviderRequest, options?: RpcOptions): UnaryCall<RetrieveDirectoryProviderRequest, RetrieveDirectoryProviderResponse>;
    /**
     * DeleteDirectoryProvider remove sync directory integration by id
     *
     * @generated from protobuf rpc: DeleteDirectoryProvider(calendarprovider.DeleteDirectoryProviderRequest) returns (google.protobuf.Empty);
     */
    deleteDirectoryProvider(input: DeleteDirectoryProviderRequest, options?: RpcOptions): UnaryCall<DeleteDirectoryProviderRequest, Empty>;
    /**
     * @generated from protobuf rpc: UpdateDirectoryProviderStatus(calendarprovider.UpdateDirectoryIntegrationStatusRequest) returns (calendarprovider.UpdateDirectoryIntegrationStatusResponse);
     */
    updateDirectoryProviderStatus(input: UpdateDirectoryIntegrationStatusRequest, options?: RpcOptions): UnaryCall<UpdateDirectoryIntegrationStatusRequest, UpdateDirectoryIntegrationStatusResponse>;
    /**
     * @generated from protobuf rpc: UpdateDirectoryProviderCredentials(calendarprovider.UpdateDirectoryProviderCredentialsRequest) returns (calendarprovider.Provider);
     */
    updateDirectoryProviderCredentials(input: UpdateDirectoryProviderCredentialsRequest, options?: RpcOptions): UnaryCall<UpdateDirectoryProviderCredentialsRequest, Provider>;
}
/**
 * @generated from protobuf service calendarprovider.DirectoryProvider
 */
export class DirectoryProviderClient implements IDirectoryProviderClient, ServiceInfo {
    typeName = DirectoryProvider.typeName;
    methods = DirectoryProvider.methods;
    options = DirectoryProvider.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GenerateAuthLink generate auth link for integration.
     *
     * @generated from protobuf rpc: GenerateAuthLink(calendarprovider.GenerateAuthLinkRequest) returns (calendarprovider.GenerateAuthLinkResponse);
     */
    generateAuthLink(input: GenerateAuthLinkRequest, options?: RpcOptions): UnaryCall<GenerateAuthLinkRequest, GenerateAuthLinkResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GenerateAuthLinkRequest, GenerateAuthLinkResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateDirectoryProvider is a RPC method for creating new sync directory integration.
     *
     * @generated from protobuf rpc: CreateDirectoryProvider(calendarprovider.CreateDirectoryProviderRequest) returns (calendarprovider.Provider);
     */
    createDirectoryProvider(input: CreateDirectoryProviderRequest, options?: RpcOptions): UnaryCall<CreateDirectoryProviderRequest, Provider> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateDirectoryProviderRequest, Provider>("unary", this._transport, method, opt, input);
    }
    /**
     * ListDirectoryProvider return list of customer's sync directory integrations
     *
     * @generated from protobuf rpc: ListDirectoryProvider(calendarprovider.ListDirectoryProviderRequest) returns (calendarprovider.ListDirectoryProviderResponse);
     */
    listDirectoryProvider(input: ListDirectoryProviderRequest, options?: RpcOptions): UnaryCall<ListDirectoryProviderRequest, ListDirectoryProviderResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListDirectoryProviderRequest, ListDirectoryProviderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RetrieveDirectoryProvider return a sync directory integration by id
     *
     * @generated from protobuf rpc: RetrieveDirectoryProvider(calendarprovider.RetrieveDirectoryProviderRequest) returns (calendarprovider.RetrieveDirectoryProviderResponse);
     */
    retrieveDirectoryProvider(input: RetrieveDirectoryProviderRequest, options?: RpcOptions): UnaryCall<RetrieveDirectoryProviderRequest, RetrieveDirectoryProviderResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<RetrieveDirectoryProviderRequest, RetrieveDirectoryProviderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteDirectoryProvider remove sync directory integration by id
     *
     * @generated from protobuf rpc: DeleteDirectoryProvider(calendarprovider.DeleteDirectoryProviderRequest) returns (google.protobuf.Empty);
     */
    deleteDirectoryProvider(input: DeleteDirectoryProviderRequest, options?: RpcOptions): UnaryCall<DeleteDirectoryProviderRequest, Empty> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteDirectoryProviderRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateDirectoryProviderStatus(calendarprovider.UpdateDirectoryIntegrationStatusRequest) returns (calendarprovider.UpdateDirectoryIntegrationStatusResponse);
     */
    updateDirectoryProviderStatus(input: UpdateDirectoryIntegrationStatusRequest, options?: RpcOptions): UnaryCall<UpdateDirectoryIntegrationStatusRequest, UpdateDirectoryIntegrationStatusResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateDirectoryIntegrationStatusRequest, UpdateDirectoryIntegrationStatusResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateDirectoryProviderCredentials(calendarprovider.UpdateDirectoryProviderCredentialsRequest) returns (calendarprovider.Provider);
     */
    updateDirectoryProviderCredentials(input: UpdateDirectoryProviderCredentialsRequest, options?: RpcOptions): UnaryCall<UpdateDirectoryProviderCredentialsRequest, Provider> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateDirectoryProviderCredentialsRequest, Provider>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service calendarprovider.Sync
 */
export interface ISyncClient {
    /**
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
    /**
     * @generated from protobuf rpc: RenewIntegrationsToken(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    renewIntegrationsToken(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty>;
}
/**
 * @generated from protobuf service calendarprovider.Sync
 */
export class SyncClient implements ISyncClient, ServiceInfo {
    typeName = Sync.typeName;
    methods = Sync.methods;
    options = Sync.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Sync(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    sync(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RenewIntegrationsToken(google.protobuf.Empty) returns (google.protobuf.Empty);
     */
    renewIntegrationsToken(input: Empty, options?: RpcOptions): UnaryCall<Empty, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Empty>("unary", this._transport, method, opt, input);
    }
}
