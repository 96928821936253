import React from "react";
import { PageHeader } from "../../../../components/shared/PageHeader/PageHeader";
import { FloorPickerButton } from "../../../../components/Floors/FloorPicker/FloorPickerButton";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { tooltips } from "../../../../lib/utils/tooltips";

import "./FloorMain.scss";

export const FloorMain = () => {
  const { pathname } = useRouter();

  return (
    <div className="FloorMain">
      <PageHeader
        title={pathname.toLowerCase().includes("reserve") ? "Map View" : "Maps"}
        message={tooltips.bookings.map.heading}
      >
        <FloorPickerButton
          className="FloorPickerButton"
          adjustable
          size="medium"
          onClick={() => {}}
          color="third"
          disabled={true}
        />
      </PageHeader>

      <h4 className="center-content">Select floor to view</h4>
    </div>
  );
};
