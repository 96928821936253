import React, { PropsWithChildren, useRef, useState } from "react";
import { toast } from "react-toastify";
import { isString } from "lodash";
import { format } from "date-fns";

import { useTemplatesClient } from "../../../../../api/grpc/workplaces/useTemplatesClient";
import { useDeskTemplateContext } from "../../../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";
import { useDeskFormik } from "../../../../shared/Forms/DeskForm/lib/useDeskFormik";
import { useTemplateFormik } from "../../../lib/useTemplateFormik";
import { useDesksRequests } from "../../../../../api/graphql/desks/useDesksRequests";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";
import { useTemplateContext } from "../../../../../lib/context/Templates/Templates/TemplatesContext";
import { useOutsideClickDetection } from "../../../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../../../lib/hooks/useRouter";
import { editProfileButtonOptions } from "../../../lib/editProfileOptions";

import { DeskTemplate } from "../../../../../api/grpc/workplaces/workplaces";
import { CheckInTime } from "../../../../../api/graphql/desks/desks";
import { DeskProfileForm } from "./DeskProfileForm";
import { DoubleButton } from "../../../../shared/DoubleButton/DoubleButton";
import { DropdownList } from "../../../../shared/DropdownList/DropdownList";
import { TEMPLATE_ROOT_PATH } from "../../../../../lib/routes/routes";
import { Icon } from "../../../../shared/Icon/Icon";
import { CancelProfileButton } from "../../../shared/CancelProfileButton";

export const EditDeskProfileForm = ({
  deskData,
}: PropsWithChildren<{
  deskData: DeskTemplate;
}>) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  const { refetchDeskTemplates } = useDeskTemplateContext();
  const { updateDeskTemplate, deleteDeskTemplate } = useTemplatesClient();
  const { removeUserFromDesk } = useDesksRequests();
  const { isOpen, toggleModal } = useToggleModal();
  const { setActiveProfile } = useTemplateContext();
  const { history } = useRouter();

  const templateFormik = useTemplateFormik({
    initialValues: {
      profileName: deskData?.name || "",
      profileDescription: deskData?.description || "",
    },
    onSubmit: async () => {},
  });

  const formik = useDeskFormik({
    initialValues: {
      name: "",
      description: deskData?.deskSettings?.description || "",
      tags: deskData?.deskSettings?.tags || [],
      showCheckInTime: deskData?.deskSettings?.showCheckInTime || false,
      checkInTime: (deskData?.deskSettings?.checkInTime as CheckInTime) || 1,
      isBlocked: deskData?.deskSettings?.isBlocked || false,
      qrCodeRequired: deskData?.deskSettings?.qrCodeRequired || false,
      workingHrsActivated: deskData?.deskSettings?.workingHrsActivated || false,
      workingHrsStart: deskData?.deskSettings?.workingHrsStart || "",
      workingHrsEnd: deskData?.deskSettings?.workingHrsEnd || "",
      account:
        deskData?.deskSettings?.account === null
          ? null
          : {
              accountID:
                deskData?.deskSettings?.account?.accountID +
                  " " +
                  deskData?.deskSettings?.account?.email || "",
              tenantID: user?.customerid,
              displayName: deskData?.deskSettings?.account?.displayName || "",
              email: deskData?.deskSettings?.account?.email || "",
            },
    },
    onSubmit: async () => {},
  });

  const handleEditDeskProfile = async () => {
    try {
      setLoading(true);

      const {
        checkInTime,
        description,
        isBlocked,
        qrCodeRequired,
        showCheckInTime,
        tags,
        workingHrsActivated,
        workingHrsEnd,
        workingHrsStart,
        account,
      } = formik.values;

      const emailAndIdOfAccount = account?.accountID.split(" ") || [];
      const idOfAccount = emailAndIdOfAccount[0];
      const emailOfAccount = emailAndIdOfAccount[1];

      await updateDeskTemplate({
        description: templateFormik.values.profileDescription,
        name: templateFormik.values.profileName,
        id: deskData.id,
        tenantId: user?.customerid || "",
        deskSettings: {
          checkInTime,
          description,
          isBlocked: isBlocked || false,
          qrCodeRequired,
          showCheckInTime,
          tags: tags || [],
          workingHrsActivated,
          workingHrsStart: !!workingHrsStart
            ? isString(workingHrsStart) && workingHrsStart.length <= 9
              ? workingHrsStart
              : format(new Date(workingHrsStart || ""), "HH:mm:ss")
            : "",
          workingHrsEnd: !!workingHrsEnd
            ? isString(workingHrsEnd) && workingHrsEnd.length <= 9
              ? workingHrsEnd
              : format(new Date(workingHrsEnd || ""), "HH:mm:ss")
            : "",
          account: account?.accountID
            ? {
                accountID: idOfAccount,
                displayName: account.displayName,
                email: emailOfAccount,
                tenantID: user?.customerid || "",
              }
            : undefined,
        },
      });

      if (
        deskData?.deskSettings?.account !== null &&
        !account?.accountID.length
      ) {
        await removeUserFromDesk({
          id: deskData.id,
          userId: deskData?.deskSettings?.account?.accountID || "",
          email: deskData?.deskSettings?.account?.email || "",
        });
      }

      toast.success("Desk template updated successfully.");

      await refetchDeskTemplates();
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useOutsideClickDetection(
    ref,
    () => {
      if (!isOpen) {
        return;
      }
      toggleModal();
    },
    isOpen
  );

  const handleDeleteDeskProfile = async () => {
    try {
      toggleModal();

      await deleteDeskTemplate(deskData.id);

      await refetchDeskTemplates();

      toast.success("Desk template deleted successfully.");

      history.push(TEMPLATE_ROOT_PATH);
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handeOptionClick = (type: string) => {
    if (type === "transfer") {
      setActiveProfile({
        label: deskData.name,
        value: deskData.id,
        type: "desk",
        redirect: true,
      });
      history.push(`${TEMPLATE_ROOT_PATH}/transfer`);
      return;
    }

    if (type === "delete") {
      return handleDeleteDeskProfile();
    }
  };

  return (
    <>
      <div className="main-flex mb-3 TemplateManage__head">
        <div className="TemplateManage__info">
          <Icon icon="red-error-icon" />
          <span>
            Note that any updates to the template after it has been transferred
            to desks will require a manual re-transfer to affected desks.
          </span>
        </div>

        <div className="flex-a-center gap-1 TemplateManage__head--buttons">
          <CancelProfileButton className="TemplateManage__head--cancel" />
          <div ref={ref} className="TemplateManage__buttons relative">
            <DoubleButton
              onClick={handleEditDeskProfile}
              secondButtonClick={toggleModal}
              disabled={loading}
            />

            {isOpen && (
              <DropdownList
                onClick={(e) => handeOptionClick(e)}
                options={editProfileButtonOptions}
              />
            )}
          </div>
        </div>
      </div>

      <DeskProfileForm formik={formik} templateFormik={templateFormik} />
    </>
  );
};
