import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useTemplateContext } from "../../../lib/context/Templates/Templates/TemplatesContext";
import { useScrollToTop } from "../../../lib/hooks/useScrollToTop";

import { AddRoomProfile } from "../../../components/SettingsProfiles/ManageProfiles/AddRoomProfile/AddRoomProfile";
import { EditRoomProfile } from "../../../components/SettingsProfiles/ManageProfiles/AddRoomProfile/EditRoomProfile";
import { SubRouteProps } from "../../../lib/routes/routes";
import { TransferProfiles } from "../TransferProfiles/TransferProfiles";
import { SettingsProfiles } from "./SettingsProfiles";
import { AddDeskProfile } from "../../../components/SettingsProfiles/ManageProfiles/AddDeskProfile/AddDeskProfile";
import { EditDeskProfile } from "../../../components/SettingsProfiles/ManageProfiles/AddDeskProfile/EditDeskProfile";

import "../../../components/SettingsProfiles/ManageProfiles/TemplateManage.scss";

export const SettingsProfilesView = () => {
  useScrollToTop();
  const router = useRouter();
  const {
    activeProfile,
    setActiveProfile,
    pickedWorkspaces,
    setPickedWorkspaces,
  } = useTemplateContext();

  useEffect(() => {
    if (activeProfile) {
      setActiveProfile(undefined);
    }

    if (pickedWorkspaces.data.length && pickedWorkspaces.redirect !== true) {
      setPickedWorkspaces({ data: [], redirect: undefined });
    }
  }, []);

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Settings Templates",
      to: `${router.match.url}`,
      id: "settingsProfiles",
      component: SettingsProfiles,
    },
    {
      exact: true,
      label: "Transfer Profiles",
      to: `${router.match.url}/transfer`,
      id: "transfer",
      component: TransferProfiles,
    },
    {
      exact: true,
      label: "Add room profiles",
      to: `${router.match.url}/add-room`,
      id: "add-room",
      component: AddRoomProfile,
    },
    {
      exact: true,
      label: "Edit room profiles",
      to: `${router.match.url}/edit-room/:id`,
      id: "edit-room",
      component: EditRoomProfile,
    },
    {
      exact: true,
      label: "Add desk profiles",
      to: `${router.match.url}/add-desk`,
      id: "add-desk",
      component: AddDeskProfile,
    },
    {
      exact: true,
      label: "Edit desk profiles",
      to: `${router.match.url}/edit-desk/:id`,
      id: "edit-desk",
      component: EditDeskProfile,
    },
  ];

  return (
    <div className="default-page">
      <Switch>
        {routes.map((item) => (
          <Route
            key={item.id}
            path={item.to}
            component={item.component}
            exact={item.exact ? true : undefined}
          />
        ))}
        <Redirect to={routes[0].to} />
      </Switch>
    </div>
  );
};
