import React from "react";

import { useInsightsContext } from "../../../Context/InsightsContext";
import { useBehaviourInsightsContext } from "../../../Context/Room/Behaviour/BehaviourContext";

import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { ChartCard } from "../../../shared/ChartCard/ChartCard";
import { UtilizationOccupancyMostLeast } from "../../Utilization/UtilizationOccupancy/UtilizationOccupancyMostLeast/UtilizationOccupancyMostLeast";
import { UtilizationOccupancyRoomSeating } from "../../Utilization/UtilizationOccupancy/UtilizationOccupancyRoomSeating/UtilizationOccupancyRoomSeating";
import { RealizedMeetingsFunnel } from "../RealizedMeetingsFunnel/RealizedMeetingsFunnel";
import { RoomMeetingBehavior } from "./RoomMeetingBehavior/RoomMeetingBehavior";
import { tooltips } from "../../../../../lib/utils/tooltips";

export const RoomReleaseBehaviour = () => {
  const {
    insightsLocation: { locationType },
  } = useInsightsContext();

  const { loading, error, data } = useBehaviourInsightsContext();

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={
        <div className="mt-3 WorkspaceValueInsights__statistics">
          <LoadingBox minHeight={170} />
          <LoadingBox minHeight={170} />
          <LoadingBox minHeight={170} />
          <LoadingBox minHeight={170} />
        </div>
      }
    >
      <HandleRequestState
        state={!!error}
        placeholder={<CenteredBox>{error?.message}</CenteredBox>}
      >
        <div className="UtilizationOccupancy WorkspaceValueInsights__statistics">
          <ChartCard
            title="Meeting Behavior"
            message={tooltips.insights.rooms.meetingBehavior}
            adjustInfoLeft
            className="ChartCard__occupancy"
          >
            {!!data?.adHocEvents || !!data?.plannedEvents ? (
              <RoomMeetingBehavior
                adHoc={data?.adHocEvents}
                planned={data?.plannedEvents}
              />
            ) : (
              <CenteredBox className="d-block">No meetings</CenteredBox>
            )}
          </ChartCard>

          {locationType !== "Room" && (
            <ChartCard
              title="Most vs Least No-Shows"
              message={tooltips.insights.rooms.mostLeastNoShows}
              className="ChartCard__occupancy"
            >
              {!!data?.roomsByNoShows.length ? (
                <UtilizationOccupancyMostLeast data={data.roomsByNoShows} />
              ) : (
                <CenteredBox className="CenteredBox__disableHeight">
                  Empty data
                </CenteredBox>
              )}
            </ChartCard>
          )}

          <ChartCard
            title="Attendance by Room Capacity"
            message={tooltips.insights.rooms.attendanceByRoomCapacity}
            className="ChartCard__occupancy"
          >
            {!!data?.attendanceByCapacity.length ? (
              <>
                <UtilizationOccupancyRoomSeating
                  data={data.attendanceByCapacity}
                />
                <span className="UtilizationOccupancy__info UtilizationOccupancyRoomSeating__info">
                  No benchmark data available
                </span>
              </>
            ) : (
              <CenteredBox className="CenteredBox__disableHeight">
                Empty data
              </CenteredBox>
            )}
          </ChartCard>

          <ChartCard
            title="Realized Meetings Funnel"
            message={tooltips.insights.rooms.realizedMeetingsFunnel}
            className="ChartCard__occupancy"
          >
            {!!data?.funnel.length ? (
              <RealizedMeetingsFunnel data={data.funnel} />
            ) : (
              <CenteredBox className="CenteredBox__disableHeight">
                Empty data
              </CenteredBox>
            )}
          </ChartCard>
        </div>
      </HandleRequestState>
    </HandleLoadingState>
  );
};
