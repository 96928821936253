import { useDesksRequests } from "../../../../api/graphql/desks/useDesksRequests";
import { DeskObject } from "../../../../api/grpc/contacts/contacts";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { EditUserFields } from "./useEditUserFormik";

export const useUpdateDeskCache = () => {
  const { updateAttachedUserOfDesk, removeUserFromDesk } = useDesksRequests();
  const { user } = useAuthContext();

  const updateDesk = (
    desks: DeskObject[],
    values: EditUserFields,
    id: string,
    desksToString: string[]
  ) => {
    if (desks.length > 0 && values.desk.length === 0) {
      desks.map(async (itm) => {
        await removeUserFromDesk({
          id: itm.iD,
          userId: id,
          email: values.emailID,
        });
      });
    }

    // this if is when there is no desk and user adds 1:
    if (desks.length <= 0 && values.desk.length > 0) {
      values.desk.map(async (itm) => {
        await updateAttachedUserOfDesk({
          accountID: id,
          email: values.emailID,
          id: itm,
          tenantId: user?.customerid || "",
          displayName: values.firstName + " " + values.lastName,
        });
      });
    }

    // if there is a desk handle deleting it or changing it with another desk:
    if (desks.length > 0 && values.desk.length > 0) {
      let desksToRemove = desks.filter(
        (desk) => !values.desk.includes(desk.iD)
      );
      let deskToAdd = values.desk.filter(
        (desk) => !desksToString.includes(desk)
      );
      if (deskToAdd.length > 0 && !deskToAdd.includes("")) {
        deskToAdd.map(async (itm) => {
          await updateAttachedUserOfDesk({
            accountID: id,
            email: values.emailID,
            id: itm,
            tenantId: user?.customerid || "",
            displayName: values.firstName + " " + values.lastName,
          });
        });
      }

      if (desksToRemove.length > 0) {
        desksToRemove.map(async (itm) => {
          await removeUserFromDesk({
            id: itm.iD,
            userId: id,
            email: values.emailID,
          });
        });
      }
    }

    // if (values.desk.length > 0 && values.desk.length > desks.length) {
    //   values.desk.map(async (itm) => {
    //     await updateAttachedUserOfDesk({
    //       accountID: id,
    //       email: values.emailID,
    //       id: itm,
    //       tenantId: user?.customerid || "",
    //       displayName: values.firstName + " " + values.lastName,
    //     });
    //   });
    // }
  };
  return { updateDesk };
};
