import React from "react";
import { differenceInDays, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useBookingsContext } from "../../Context/BookingsContext";

import { trimText } from "../../../../lib/helpers/trimText";
import { trimArray } from "../../../../lib/helpers/trimArray";
import { listRoomEquipment } from "../../../../lib/helpers/listRoomEquipment";
import {
  StructureTable,
  StructureTableColumn,
} from "../../../shared/StructureTable/StructureTable";
import { WorkplacePrefixIcon } from "../../../Workplaces/WorkplaceExpandedList/WorkplacePrefixIcon/WorkplacePrefixIcon";
import { MatchedWorkplaceActions } from "../../shared/MatchedWorkplaceActions";
import {
  AmenitiesFilter,
  AvailableRoom,
} from "../../../../api/grpc/workplaces/workplaces";
import { Icon } from "../../../shared/Icon/Icon";

interface Props {
  rooms: AvailableRoom[];
}

const colums: StructureTableColumn[] = [
  {
    key: "workplace_icon",
    header: "",
  },
  {
    key: "name",
    header: (
      <div className="flex-a-center">
        <Icon icon="location-search" />
        <span className="pl-1">Room</span>
      </div>
    ),
  },
  {
    key: "nextReservation",
    header: (
      <div className="flex-a-center">
        <Icon icon="next-reservation" />
        <span className="pl-1">Next reservation</span>
      </div>
    ),
  },
  {
    key: "seats",
    header: (
      <div className="flex-a-center">
        <Icon icon="seats-search" />
        <span className="pl-1">Seats</span>
      </div>
    ),
  },
  {
    key: "bookingsTags",
    header: (
      <div className="flex-a-center">
        <Icon icon="tags-search" />
        <span className="pl-1">Tags</span>
      </div>
    ),
  },
  {
    key: "equipment",
    header: (
      <div className="flex-a-center">
        <Icon icon="equipment-search" />
        <span className="pl-1">Equipment</span>
      </div>
    ),
  },
  {
    key: "actions",
    header: "",
  },
];

export const BookingsRoomsTable = ({ rooms }: Props) => {
  const { timeZone } = useTimeZoneContext();
  const { day } = useBookingsContext();
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const rows = rooms.map((room) => {
    return {
      workplace_icon: <WorkplacePrefixIcon icon="room_icon_18x18" />,
      name: (
        <h4 className="Bookings__room" title={room.name || "-"}>
          {trimText(room.name || "-", 22)}
        </h4>
      ),
      nextReservation: !!room.nextReservationTime.length ? (
        <span>
          {format(
            utcToZonedTime(new Date(room.nextReservationTime), timeZone),
            differenceInDays(
              utcToZonedTime(new Date(day), timeZone),
              utcToZonedTime(new Date(room.nextReservationTime), timeZone)
            ) === 0
              ? "HH:mm"
              : "dd MMM HH:mm"
          )}
        </span>
      ) : (
        <span className="pb-1 Bookings__empty">-</span>
      ),
      seats: <span>{room.seats}</span>,
      bookingsTags: (
        <>
          <span>{trimArray(room.tags)}</span>
          {room.tags.length > 1 && <div>{room.tags.join(", ")}</div>}
        </>
      ),
      equipment: (
        <>
          <span>
            {trimArray(
              listRoomEquipment(room.availableEquipment as AmenitiesFilter)
            )}
          </span>
          {trimArray(
            listRoomEquipment(room.availableEquipment as AmenitiesFilter)
          ) !== "-" && (
            <div>
              {listRoomEquipment(
                room.availableEquipment as AmenitiesFilter
              ).join(", ")}
            </div>
          )}
        </>
      ),
      actions: (
        <MatchedWorkplaceActions
          workspaceId={room.id}
          type="room"
          icon="rooms-icon"
          locationPath={room.locationPath}
        />
      ),
    };
  });
  return (
    <div>
      <StructureTable
        columns={colums}
        rows={rows}
        className="StructureTable--rooms"
        isMobile={isMobile}
      />
    </div>
  );
};
