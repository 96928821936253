import React from "react";
import { Button } from "../Button/Button";
import { InformationBox } from "../InformationBox/InformationBox";
import { LabelDirectionType } from "../LabelWithInformationBox/LabelWithInformationBox";

import "./InfoCard.scss";

interface Props {
  title: string;
  children?: React.ReactNode;
  hasButton?: boolean;
  buttonTitle?: string;
  onClick?: () => void;
  disabled?: boolean;
  message?: string;
  direction?: LabelDirectionType;
  adjustInfoLeft?: boolean;
  adjustInfoRight?: boolean;
}

export const InfoCard = ({
  title,
  children,
  hasButton,
  buttonTitle,
  onClick,
  disabled,
  message,
  direction,
  adjustInfoLeft,
  adjustInfoRight,
}: Props) => {
  return (
    <div className="InfoCard">
      <div className="flex-a-center mb-4">
        <h4>{title}</h4>
        {message && (
          <InformationBox
            message={message}
            direction={direction}
            adjustInfoLeft={adjustInfoLeft}
            adjustInfoRight={adjustInfoRight}
          />
        )}
      </div>
      {children}
      {hasButton && (
        <Button
          onClick={onClick}
          title={buttonTitle || "Edit"}
          size="medium"
          disabled={disabled}
        />
      )}
    </div>
  );
};
