import React from "react";
import { DeviceWorkspaceType } from "../../shared/Forms/DeviceForm/lib/useDeviceFormik";
import { Icon } from "../../shared/Icon/Icon";

export const DeviceApp = ({
  name,
  typeOfWorkspace,
}: {
  name: string;
  typeOfWorkspace: DeviceWorkspaceType;
}) => {
  return (
    <div
      className={`mb-0 flex-a-center DeviceApp DeviceApp__${
        typeOfWorkspace === "wayFinder" ? "wayfinder" : "room"
      }`}
    >
      <Icon
        icon={
          typeOfWorkspace === "wayFinder"
            ? "wayfinder-purple"
            : "device_icon_18x18"
        }
      />
      <h6>{name}</h6>
    </div>
  );
};
