import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";

import {
  DesksListResponse,
  SearchAccountDesksRequest,
} from "../../../api/grpc/workplaces/workplaces";

export const useAssignedDesks = () => {
  const { getAssignedDesks } = useWorkplacesClient();

  const {
    loading,
    error,
    data: assignedDesks,
    refetch: refetchAssignedDesks,
  } = useGrpcQuery<SearchAccountDesksRequest, DesksListResponse>({
    method: getAssignedDesks,
    variables: {
      accountID: "",
      customerID: "",
    },
  });

  return { loading, error, assignedDesks, refetchAssignedDesks };
};
