import { getMainSidebarItems } from "../../../routes";
import { getFirstRouteFromURL } from "./getFirstRouteFromURL";

export const getSidebarMenuFromURL = (to: string) => {
  const routeFromURL = getFirstRouteFromURL(to);
  const routes = getMainSidebarItems();

  const route = routes.find((o) => o.to === routeFromURL);

  return {
    id: route?.id,
    hasMenu: route?.hasMenu || false,
  };
};
