import React from "react";
import { useBookingsContext } from "../Context/BookingsContext";
import { Button } from "../../shared/Button/Button";

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export const ReserveFooter = ({ onClick, disabled }: Props) => {
  const { handleModalClose } = useBookingsContext();

  return (
    <div className="ReserveFooter">
      <Button
        title="Back"
        onClick={handleModalClose}
        color="outline-secondary"
        size="small"
        className="m-0 max-w-none flex-1"
      />
      <Button
        title="Search"
        onClick={onClick}
        color="business"
        size="small"
        className="ReserveFooter__search m-0 max-w-none flex-1"
        disabled={disabled}
      />
    </div>
  );
};
