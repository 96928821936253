import React, { useRef, useState } from "react";
import cs from "classnames";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../lib/hooks/useRouter";

import { Icon } from "../Icon/Icon";
import { LabelDirectionType } from "../LabelWithInformationBox/LabelWithInformationBox";

import "./InformationBox.scss";

interface Props {
  message?: string;
  leftDiff?: boolean;
  onClick?: () => void;
  direction?: LabelDirectionType;
  className?: string;
  adjustInfoLeft?: boolean;
  adjustInfoRight?: boolean;
  hasZIndex?: boolean;
}

export const InformationBox = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const { pathname } = useRouter();

  const definedZIndex =
    pathname === "/users/sync" || pathname === "/manage/map";

  const mainClasses = cs("InformationBox", props.className, {
    "InformationBox--left-diff": props.leftDiff,
    "InformationBox--z10": definedZIndex || props.hasZIndex,
  });

  const classes = cs("InformationBox__content", props.className, {
    "InformationBox__content--visible": isOpen,
    "InformationBox__content--left": props.direction === "left",
    "InformationBox__content--adjustLeft": props.adjustInfoLeft,
    "InformationBox__content--adjustRight": props.adjustInfoRight,
  });

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }

    setIsOpen((prev) => !prev);
  };

  useOutsideClickDetection(
    ref,
    () => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    isOpen
  );

  return (
    <div className={mainClasses} ref={ref} onClick={handleClick}>
      <Icon icon="cursor-information" />
      <div
        className={classes}
        dangerouslySetInnerHTML={{
          __html:
            props.message && props.message.length > 0
              ? props.message
              : `<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Delectus commodi non quos hic sunt facilis! Quaerat, quia
              pariatur. Sint consequuntur, quos officia aliquam dolor eum.
              Veniam quos temporibus unde ullam!</p>`,
        }}
      />
    </div>
  );
};
