import React from "react";

import { useCustomerDetails } from "../lib/useCustomerDetails";
import { usePartnerData } from "../lib/usePartnerData";
import { useOpenBillingInfo } from "../lib/useOpenBillingInfo";

import { InfoCard } from "../../shared/InfoCard/InfoCard";
import { BillingInfoDetails } from "./BillingInfoDetails/BillingInfoDetails";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { tooltips } from "../../../lib/utils/tooltips";

import "./BillingInfo.scss";

export const BillingInfo = () => {
  const { data: partnerData } = usePartnerData();

  const { loading, error, data, refetch } = useCustomerDetails();
  const { handleBillingModal } = useOpenBillingInfo(refetch);

  return (
    <HandleRequestState
      state={loading}
      placeholder={
        <>
          <LoadingBox minHeight={550} className="BillingInfo--loading" />
        </>
      }
    >
      <HandleRequestState
        state={!!error}
        placeholder={<p>Error loading subscription information</p>}
      >
        <div className="BillingInfo default-card">
          <InfoCard
            title="Company Information"
            hasButton
            buttonTitle="Edit"
            onClick={() => handleBillingModal()}
            disabled={!!partnerData?.email}
            message={tooltips.organizationSettings.billingInfo}
          >
            <BillingInfoDetails data={data} />
          </InfoCard>
        </div>
      </HandleRequestState>
    </HandleRequestState>
  );
};
