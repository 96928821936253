// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "analytics.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message CustomerRequest
 */
export interface CustomerRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * timeZone value used only for Insights request
     *
     * @generated from protobuf field: string timeZone = 2;
     */
    timeZone: string;
}
/**
 * Response message for Workplaces analytics
 *
 * @generated from protobuf message WorkplacesResponse
 */
export interface WorkplacesResponse {
    /**
     * @generated from protobuf field: int32 rooms = 1;
     */
    rooms: number;
    /**
     * @generated from protobuf field: int32 desks = 2;
     */
    desks: number;
    /**
     * @generated from protobuf field: int32 licence = 3;
     */
    licence: number;
    /**
     * @generated from protobuf field: int32 licensed = 4;
     */
    licensed: number;
    /**
     * @generated from protobuf field: int32 wayfinders = 5;
     */
    wayfinders: number;
}
/**
 * Response message for Calendars analytics
 *
 * @generated from protobuf message CalendarsResponse
 */
export interface CalendarsResponse {
    /**
     * @generated from protobuf field: int32 connections = 1;
     */
    connections: number;
    /**
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
    /**
     * @generated from protobuf field: int32 assigned = 3;
     */
    assigned: number;
}
/**
 * Response message for Devices analytics
 *
 * @generated from protobuf message DevicesResponse
 */
export interface DevicesResponse {
    /**
     * @generated from protobuf field: int32 devices = 1;
     */
    devices: number;
    /**
     * @generated from protobuf field: int32 assigned = 2;
     */
    assigned: number;
}
/**
 * Insights message for event Insights
 *
 * @generated from protobuf message InsightsResponse
 */
export interface InsightsResponse {
    /**
     * customer id in format cus-Name-9xsdf
     *
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * Time period start date in format "2006-10-21"
     *
     * @generated from protobuf field: string startDate = 2;
     */
    startDate: string;
    /**
     * Time period end date in format "2006-10-21"
     *
     * @generated from protobuf field: string endDate = 3;
     */
    endDate: string;
    /**
     * data in format date: number_of_events
     * E.g. 2006-10-21: 130
     *
     * @generated from protobuf field: map<string, int32> data = 4;
     */
    data: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message AnalyticsInvoices
 */
export interface AnalyticsInvoices {
    /**
     * @generated from protobuf field: string invoiceID = 1;
     */
    invoiceID: string;
    /**
     * @generated from protobuf field: string planName = 2;
     */
    planName: string;
    /**
     * @generated from protobuf field: string chargeAmount = 3;
     */
    chargeAmount: string;
    /**
     * @generated from protobuf field: string currency = 4;
     */
    currency: string;
    /**
     * @generated from protobuf field: string date = 5;
     */
    date: string;
}
/**
 * @generated from protobuf message AnalyticsBillingStat
 */
export interface AnalyticsBillingStat {
    /**
     * @generated from protobuf field: string upcomingCharge = 1;
     */
    upcomingCharge: string;
    /**
     * @generated from protobuf field: string chargeAmount = 2;
     */
    chargeAmount: string;
    /**
     * @generated from protobuf field: string currency = 3;
     */
    currency: string;
    /**
     * @generated from protobuf field: repeated AnalyticsInvoices invoices = 4;
     */
    invoices: AnalyticsInvoices[];
}
/**
 * @generated from protobuf message AnalyticsSubscriptionStats
 */
export interface AnalyticsSubscriptionStats {
    /**
     * @generated from protobuf field: string planName = 1;
     */
    planName: string;
    /**
     * @generated from protobuf field: string planPrice = 2;
     */
    planPrice: string;
    /**
     * @generated from protobuf field: string planCurrency = 3;
     */
    planCurrency: string;
    /**
     * @generated from protobuf field: int32 roomNumber = 4;
     */
    roomNumber: number;
    /**
     * @generated from protobuf field: int32 deskNumber = 5;
     */
    deskNumber: number;
    /**
     * @generated from protobuf field: int32 licensedRoomNumber = 6;
     */
    licensedRoomNumber: number;
    /**
     * @generated from protobuf field: int32 licensedDeskNumber = 7;
     */
    licensedDeskNumber: number;
    /**
     * @generated from protobuf field: string status = 8;
     */
    status: string;
    /**
     * @generated from protobuf field: int32 userNumber = 9;
     */
    userNumber: number;
    /**
     * @generated from protobuf field: int32 licensedUserNumber = 10;
     */
    licensedUserNumber: number;
    /**
     * @generated from protobuf field: int32 wayfinderNumber = 11;
     */
    wayfinderNumber: number;
    /**
     * @generated from protobuf field: int32 licensedWayfinderNumber = 12;
     */
    licensedWayfinderNumber: number;
}
/**
 * @generated from protobuf message AnalyticsSubscriptionInfoResponse
 */
export interface AnalyticsSubscriptionInfoResponse {
    /**
     * @generated from protobuf field: AnalyticsSubscriptionStats subscription = 1;
     */
    subscription?: AnalyticsSubscriptionStats;
    /**
     * @generated from protobuf field: AnalyticsBillingStat billing = 2;
     */
    billing?: AnalyticsBillingStat;
}
// @generated message type with reflection information, may provide speed optimized methods
class CustomerRequest$Type extends MessageType<CustomerRequest> {
    constructor() {
        super("CustomerRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CustomerRequest>): CustomerRequest {
        const message = { customerID: "", timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerRequest): CustomerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string timeZone */ 2:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string timeZone = 2; */
        if (message.timeZone !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerRequest
 */
export const CustomerRequest = new CustomerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkplacesResponse$Type extends MessageType<WorkplacesResponse> {
    constructor() {
        super("WorkplacesResponse", [
            { no: 1, name: "rooms", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "desks", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "licence", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "licensed", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "wayfinders", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<WorkplacesResponse>): WorkplacesResponse {
        const message = { rooms: 0, desks: 0, licence: 0, licensed: 0, wayfinders: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WorkplacesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorkplacesResponse): WorkplacesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rooms */ 1:
                    message.rooms = reader.int32();
                    break;
                case /* int32 desks */ 2:
                    message.desks = reader.int32();
                    break;
                case /* int32 licence */ 3:
                    message.licence = reader.int32();
                    break;
                case /* int32 licensed */ 4:
                    message.licensed = reader.int32();
                    break;
                case /* int32 wayfinders */ 5:
                    message.wayfinders = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorkplacesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rooms = 1; */
        if (message.rooms !== 0)
            writer.tag(1, WireType.Varint).int32(message.rooms);
        /* int32 desks = 2; */
        if (message.desks !== 0)
            writer.tag(2, WireType.Varint).int32(message.desks);
        /* int32 licence = 3; */
        if (message.licence !== 0)
            writer.tag(3, WireType.Varint).int32(message.licence);
        /* int32 licensed = 4; */
        if (message.licensed !== 0)
            writer.tag(4, WireType.Varint).int32(message.licensed);
        /* int32 wayfinders = 5; */
        if (message.wayfinders !== 0)
            writer.tag(5, WireType.Varint).int32(message.wayfinders);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WorkplacesResponse
 */
export const WorkplacesResponse = new WorkplacesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarsResponse$Type extends MessageType<CalendarsResponse> {
    constructor() {
        super("CalendarsResponse", [
            { no: 1, name: "connections", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "assigned", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CalendarsResponse>): CalendarsResponse {
        const message = { connections: 0, total: 0, assigned: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalendarsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalendarsResponse): CalendarsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 connections */ 1:
                    message.connections = reader.int32();
                    break;
                case /* int32 total */ 2:
                    message.total = reader.int32();
                    break;
                case /* int32 assigned */ 3:
                    message.assigned = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalendarsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 connections = 1; */
        if (message.connections !== 0)
            writer.tag(1, WireType.Varint).int32(message.connections);
        /* int32 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int32(message.total);
        /* int32 assigned = 3; */
        if (message.assigned !== 0)
            writer.tag(3, WireType.Varint).int32(message.assigned);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarsResponse
 */
export const CalendarsResponse = new CalendarsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DevicesResponse$Type extends MessageType<DevicesResponse> {
    constructor() {
        super("DevicesResponse", [
            { no: 1, name: "devices", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "assigned", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DevicesResponse>): DevicesResponse {
        const message = { devices: 0, assigned: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DevicesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DevicesResponse): DevicesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 devices */ 1:
                    message.devices = reader.int32();
                    break;
                case /* int32 assigned */ 2:
                    message.assigned = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DevicesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 devices = 1; */
        if (message.devices !== 0)
            writer.tag(1, WireType.Varint).int32(message.devices);
        /* int32 assigned = 2; */
        if (message.assigned !== 0)
            writer.tag(2, WireType.Varint).int32(message.assigned);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DevicesResponse
 */
export const DevicesResponse = new DevicesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsightsResponse$Type extends MessageType<InsightsResponse> {
    constructor() {
        super("InsightsResponse", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<InsightsResponse>): InsightsResponse {
        const message = { customerID: "", startDate: "", endDate: "", data: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsightsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsightsResponse): InsightsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string startDate */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 3:
                    message.endDate = reader.string();
                    break;
                case /* map<string, int32> data */ 4:
                    this.binaryReadMap4(message.data, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: InsightsResponse["data"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InsightsResponse["data"] | undefined, val: InsightsResponse["data"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field InsightsResponse.data");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: InsightsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string startDate = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        /* map<string, int32> data = 4; */
        for (let k of Object.keys(message.data))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.data[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InsightsResponse
 */
export const InsightsResponse = new InsightsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsInvoices$Type extends MessageType<AnalyticsInvoices> {
    constructor() {
        super("AnalyticsInvoices", [
            { no: 1, name: "invoiceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "planName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chargeAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AnalyticsInvoices>): AnalyticsInvoices {
        const message = { invoiceID: "", planName: "", chargeAmount: "", currency: "", date: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsInvoices>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsInvoices): AnalyticsInvoices {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string invoiceID */ 1:
                    message.invoiceID = reader.string();
                    break;
                case /* string planName */ 2:
                    message.planName = reader.string();
                    break;
                case /* string chargeAmount */ 3:
                    message.chargeAmount = reader.string();
                    break;
                case /* string currency */ 4:
                    message.currency = reader.string();
                    break;
                case /* string date */ 5:
                    message.date = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnalyticsInvoices, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string invoiceID = 1; */
        if (message.invoiceID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.invoiceID);
        /* string planName = 2; */
        if (message.planName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.planName);
        /* string chargeAmount = 3; */
        if (message.chargeAmount !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.chargeAmount);
        /* string currency = 4; */
        if (message.currency !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.currency);
        /* string date = 5; */
        if (message.date !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.date);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AnalyticsInvoices
 */
export const AnalyticsInvoices = new AnalyticsInvoices$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsBillingStat$Type extends MessageType<AnalyticsBillingStat> {
    constructor() {
        super("AnalyticsBillingStat", [
            { no: 1, name: "upcomingCharge", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "chargeAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "invoices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AnalyticsInvoices }
        ]);
    }
    create(value?: PartialMessage<AnalyticsBillingStat>): AnalyticsBillingStat {
        const message = { upcomingCharge: "", chargeAmount: "", currency: "", invoices: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsBillingStat>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsBillingStat): AnalyticsBillingStat {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string upcomingCharge */ 1:
                    message.upcomingCharge = reader.string();
                    break;
                case /* string chargeAmount */ 2:
                    message.chargeAmount = reader.string();
                    break;
                case /* string currency */ 3:
                    message.currency = reader.string();
                    break;
                case /* repeated AnalyticsInvoices invoices */ 4:
                    message.invoices.push(AnalyticsInvoices.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnalyticsBillingStat, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string upcomingCharge = 1; */
        if (message.upcomingCharge !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.upcomingCharge);
        /* string chargeAmount = 2; */
        if (message.chargeAmount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.chargeAmount);
        /* string currency = 3; */
        if (message.currency !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.currency);
        /* repeated AnalyticsInvoices invoices = 4; */
        for (let i = 0; i < message.invoices.length; i++)
            AnalyticsInvoices.internalBinaryWrite(message.invoices[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AnalyticsBillingStat
 */
export const AnalyticsBillingStat = new AnalyticsBillingStat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsSubscriptionStats$Type extends MessageType<AnalyticsSubscriptionStats> {
    constructor() {
        super("AnalyticsSubscriptionStats", [
            { no: 1, name: "planName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "planPrice", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "planCurrency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "roomNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "deskNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "licensedRoomNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "licensedDeskNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "userNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "licensedUserNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "wayfinderNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "licensedWayfinderNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AnalyticsSubscriptionStats>): AnalyticsSubscriptionStats {
        const message = { planName: "", planPrice: "", planCurrency: "", roomNumber: 0, deskNumber: 0, licensedRoomNumber: 0, licensedDeskNumber: 0, status: "", userNumber: 0, licensedUserNumber: 0, wayfinderNumber: 0, licensedWayfinderNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsSubscriptionStats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsSubscriptionStats): AnalyticsSubscriptionStats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string planName */ 1:
                    message.planName = reader.string();
                    break;
                case /* string planPrice */ 2:
                    message.planPrice = reader.string();
                    break;
                case /* string planCurrency */ 3:
                    message.planCurrency = reader.string();
                    break;
                case /* int32 roomNumber */ 4:
                    message.roomNumber = reader.int32();
                    break;
                case /* int32 deskNumber */ 5:
                    message.deskNumber = reader.int32();
                    break;
                case /* int32 licensedRoomNumber */ 6:
                    message.licensedRoomNumber = reader.int32();
                    break;
                case /* int32 licensedDeskNumber */ 7:
                    message.licensedDeskNumber = reader.int32();
                    break;
                case /* string status */ 8:
                    message.status = reader.string();
                    break;
                case /* int32 userNumber */ 9:
                    message.userNumber = reader.int32();
                    break;
                case /* int32 licensedUserNumber */ 10:
                    message.licensedUserNumber = reader.int32();
                    break;
                case /* int32 wayfinderNumber */ 11:
                    message.wayfinderNumber = reader.int32();
                    break;
                case /* int32 licensedWayfinderNumber */ 12:
                    message.licensedWayfinderNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnalyticsSubscriptionStats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string planName = 1; */
        if (message.planName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.planName);
        /* string planPrice = 2; */
        if (message.planPrice !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.planPrice);
        /* string planCurrency = 3; */
        if (message.planCurrency !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.planCurrency);
        /* int32 roomNumber = 4; */
        if (message.roomNumber !== 0)
            writer.tag(4, WireType.Varint).int32(message.roomNumber);
        /* int32 deskNumber = 5; */
        if (message.deskNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.deskNumber);
        /* int32 licensedRoomNumber = 6; */
        if (message.licensedRoomNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.licensedRoomNumber);
        /* int32 licensedDeskNumber = 7; */
        if (message.licensedDeskNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.licensedDeskNumber);
        /* string status = 8; */
        if (message.status !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.status);
        /* int32 userNumber = 9; */
        if (message.userNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.userNumber);
        /* int32 licensedUserNumber = 10; */
        if (message.licensedUserNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.licensedUserNumber);
        /* int32 wayfinderNumber = 11; */
        if (message.wayfinderNumber !== 0)
            writer.tag(11, WireType.Varint).int32(message.wayfinderNumber);
        /* int32 licensedWayfinderNumber = 12; */
        if (message.licensedWayfinderNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.licensedWayfinderNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AnalyticsSubscriptionStats
 */
export const AnalyticsSubscriptionStats = new AnalyticsSubscriptionStats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnalyticsSubscriptionInfoResponse$Type extends MessageType<AnalyticsSubscriptionInfoResponse> {
    constructor() {
        super("AnalyticsSubscriptionInfoResponse", [
            { no: 1, name: "subscription", kind: "message", T: () => AnalyticsSubscriptionStats },
            { no: 2, name: "billing", kind: "message", T: () => AnalyticsBillingStat }
        ]);
    }
    create(value?: PartialMessage<AnalyticsSubscriptionInfoResponse>): AnalyticsSubscriptionInfoResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AnalyticsSubscriptionInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnalyticsSubscriptionInfoResponse): AnalyticsSubscriptionInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* AnalyticsSubscriptionStats subscription */ 1:
                    message.subscription = AnalyticsSubscriptionStats.internalBinaryRead(reader, reader.uint32(), options, message.subscription);
                    break;
                case /* AnalyticsBillingStat billing */ 2:
                    message.billing = AnalyticsBillingStat.internalBinaryRead(reader, reader.uint32(), options, message.billing);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnalyticsSubscriptionInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* AnalyticsSubscriptionStats subscription = 1; */
        if (message.subscription)
            AnalyticsSubscriptionStats.internalBinaryWrite(message.subscription, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* AnalyticsBillingStat billing = 2; */
        if (message.billing)
            AnalyticsBillingStat.internalBinaryWrite(message.billing, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AnalyticsSubscriptionInfoResponse
 */
export const AnalyticsSubscriptionInfoResponse = new AnalyticsSubscriptionInfoResponse$Type();
/**
 * @generated ServiceType for protobuf service Analytics
 */
export const Analytics = new ServiceType("Analytics", [
    { name: "Echo", options: {}, I: Empty, O: Empty },
    { name: "Workplaces", options: { "google.api.http": { get: "/v1/analytics/workplaces/{customerID}" }, "google.api.method_signature": ["customer_id"] }, I: CustomerRequest, O: WorkplacesResponse },
    { name: "Calendars", options: { "google.api.http": { get: "/v1/analytics/calendars/{customerID}" }, "google.api.method_signature": ["customer_id"] }, I: CustomerRequest, O: CalendarsResponse },
    { name: "Devices", options: { "google.api.http": { get: "/v1/analytics/devices/{customerID}" }, "google.api.method_signature": ["customer_id"] }, I: CustomerRequest, O: DevicesResponse },
    { name: "Insights", options: { "google.api.http": { get: "/v1/analytics/insights/{customerID}" }, "google.api.method_signature": ["customer_id"] }, I: CustomerRequest, O: InsightsResponse },
    { name: "Subscription", options: { "google.api.http": { get: "/v1/analytics/subscriptions/{customerID}" }, "google.api.method_signature": ["customer_id"] }, I: CustomerRequest, O: AnalyticsSubscriptionInfoResponse }
]);
