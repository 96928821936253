import React, { FC, useRef, useState } from "react";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { tooltips } from "../../../../lib/utils/tooltips";

import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { Icon } from "../../../shared/Icon/Icon";
import { DebugInfo } from "./DebugInfo/DebugInfo";

export const DebugInfoCard: FC = () => {
  const { user } = useAuthContext();
  const debugContainerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClickDetection(debugContainerRef, () => {
    setIsOpen(false);
  });

  return (
    <InfoCard
      title="Debug"
      message={tooltips.organizationSettings.generalSettings.debug}
      direction="right"
      adjustInfoRight={true}
    >
      <div
        className="OrganizationSettigs__cardContent OrganizationSettigs__cardContent_clickable OrganizationSettigs__debugInfo"
        ref={debugContainerRef}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Icon icon="debug-icon" />
        <h3>Debug info</h3>
        {isOpen && <DebugInfo user={user} />}
      </div>
    </InfoCard>
  );
};
