import React from "react";

import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useRouter } from "../../../../lib/hooks/useRouter";

import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
} from "../../../../api/grpc/contacts/contacts";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { CreatablePeopleList } from "./CreatablePeopleList";
import { SelectPeopleList } from "./SelectPeopleList";
import { BookOnBehalf } from "../BookOnBehalf/BookOnBehalf";

interface Props {
  type: BookingTypes;
  showBookOnBehalf?: boolean;
}

const WorkplaceComponent: {
  [key in BookingTypes]: React.FunctionComponent<any>;
} = {
  room: CreatablePeopleList,
  desk: SelectPeopleList,
};

export const FindPeopleButton = ({ type, showBookOnBehalf }: Props) => {
  const { listPeople } = useContacts();
  const { pathname } = useRouter();

  const { personalCalendar } = useCalendarContext();

  const { loading, error, data } = useGrpcQuery<ListPeopleRequest, People>({
    method: listPeople,
    variables: {
      personalCalendarID: type === "desk" ? "" : personalCalendar[0]?.iD || "",
      customerID: "",
      query: "",
      accountID: "",
      filter: ListPeopleFilter.ALL,
    },
  });

  const Component = WorkplaceComponent[type];

  let componentProps = {
    loading: loading,
    error: error,
    data: data,
  };

  return (
    <>
      {pathname.includes("all-reservations") && type === "desk" ? null : (
        <Component {...componentProps} />
      )}

      {showBookOnBehalf && <BookOnBehalf {...componentProps} />}
    </>
  );
};
