import { AddDeskVariables } from "../../../../api/graphql/desks/desks";
import { DeskFormFields } from "../../../../api/graphql/workplaces";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import {
  DesksBulkCreateRequest,
  SearchDesk,
} from "../../../../api/grpc/workplaces/workplaces";
import { useRootBulkDesks } from "../../lib/useRootBulkDesks";
import { WorkplaceTypes } from "../AddWorkplaceButton/AddWorkplaceButton";

export interface Props {
  parentId?: string;
  parentType: WorkplaceTypes | undefined;
  variables: AddDeskVariables;
  tenantId: string;
  values: DeskFormFields;
  id: string;
}

export const useCreateBulkDesksHelper = () => {
  const { updateRootCache, updateWorkplacesEntity } = useRootBulkDesks();
  const { createBulkDesks } = useWorkplacesClient();

  const createBulkDesksHelper = async ({
    parentId,
    parentType,
    variables,
    tenantId,
    values,
    id,
  }: Props) => {
    const bulkDesksCreationVariables: DesksBulkCreateRequest = {
      ...variables,
      tenantID: tenantId,
      locationID: parentId || id,
      desksNumber: values.desksNumber || 0,
      startIndex: values.startIndex || 0,
      licensed: values.licensed || false,
      isBooked: values.isBooked || false,
    };

    const { response } = await createBulkDesks(bulkDesksCreationVariables);

    const desksFromResponse = response.desks.map((item: SearchDesk) => {
      return {
        ...item,
        __typename: "Desk",
      };
    });

    parentId === undefined
      ? updateRootCache(variables.tenantId, desksFromResponse)
      : updateWorkplacesEntity(
          parentType || "",
          parentId || "",
          desksFromResponse
        );

    if (!!response.licenseErrorMessage.length) {
      return response.licenseErrorMessage;
    }
  };

  return { createBulkDesksHelper };
};
