import React, { useState } from "react";
import { format, subDays, subYears } from "date-fns";
import DatePicker from "react-datepicker";

import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useInsightsContext } from "../../Context/InsightsContext";
import { useDateRangeContext } from "../../Context/DateRangeContext/DateRangeContext";
import { dateFormat } from "../../../Bookings/helpers/displayTimeFormat";

import { Button } from "../../../shared/Button/Button";

import "./DateRangeButton.scss";

const dayOptions: ["7", "30"] = ["7", "30"];

export const DateRangeButton = () => {
  const { isOpen, toggleModal } = useToggleModal();
  const {
    initialDateRange,
    confirmedRange,
    setInitialDateRange,
    setConfirmedRange,
  } = useDateRangeContext();
  const [innerStartTime, setInnerStartTime] = useState(subDays(new Date(), 7));
  const [innerEndTime, setInnerEndTime] = useState(subDays(new Date(), 1));

  const { setEndTime, setStartTime } = useInsightsContext();

  const buttonTitle = (): string => {
    if (initialDateRange) {
      return initialDateRange === "custom"
        ? format(innerStartTime, dateFormat) +
            " - " +
            format(innerEndTime, dateFormat)
        : `Past ${initialDateRange} days`;
    }

    return confirmedRange === "custom"
      ? format(innerStartTime, dateFormat) +
          " - " +
          format(innerEndTime, dateFormat)
      : `Past ${confirmedRange} days`;
  };

  return (
    <div className="DateRangeButton">
      <Button
        title={buttonTitle()}
        icon="calendar_icon_18x18"
        color="primary"
        size="medium"
        className="InsightsHeaderButtons__date"
        onClick={toggleModal}
      />

      {isOpen && (
        <div className="DateRangeButton__content">
          {dayOptions.map((day) => {
            return (
              <span
                key={day}
                className={`d-block label__main cursor-pointer DateRangeButton__content--option ${
                  day === (initialDateRange ? initialDateRange : confirmedRange)
                    ? "DateRangeButton__active"
                    : ""
                }`}
                onClick={() => {
                  setInitialDateRange(day);
                  setInnerStartTime(subDays(new Date(), +day));
                  setInnerEndTime(subDays(new Date(), 1));
                }}
              >
                Past {day} days
              </span>
            );
          })}

          <div className="DateRangeButton__content--picker">
            <label className="label__main pt-3">From</label>
            <DatePicker
              selected={innerStartTime}
              onChange={(date) => {
                if (!date) {
                  return;
                }

                if (
                  initialDateRange !== "custom" ||
                  confirmedRange !== "custom"
                ) {
                  setInitialDateRange("custom");
                }

                return setInnerStartTime(date);
              }}
              timeCaption="From"
              showMonthDropdown
              showYearDropdown
              dateFormat={dateFormat}
              maxDate={innerEndTime}
              minDate={subYears(new Date(), 1)}
            />
          </div>

          <div className="DateRangeButton__content--picker">
            <label className="label__main">To</label>
            <DatePicker
              selected={innerEndTime}
              onChange={(date) => {
                if (!date) {
                  return;
                }

                if (
                  initialDateRange !== "custom" ||
                  confirmedRange !== "custom"
                ) {
                  setInitialDateRange("custom");
                }

                return setInnerEndTime(date);
              }}
              timeCaption="To"
              showMonthDropdown
              showYearDropdown
              dateFormat={dateFormat}
              maxDate={subDays(new Date(), 1)}
              minDate={innerStartTime}
            />
          </div>

          <div className="DateRangeButton__dialog flex-a-center">
            <Button
              onClick={() => {
                setInitialDateRange(null);

                toggleModal();
              }}
              title="Cancel"
              color="outline-secondary"
            />
            <Button
              onClick={() => {
                setConfirmedRange(initialDateRange);

                if (initialDateRange === "custom") {
                  setStartTime(innerStartTime);
                  setEndTime(innerEndTime);
                }

                setInitialDateRange(null);

                toggleModal();

                return;
              }}
              title="Apply"
              color="primary"
              disabled={!initialDateRange}
            />
          </div>
        </div>
      )}
    </div>
  );
};
