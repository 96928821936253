import React from "react";

import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useAnalytics } from "../../../api/grpc/analytics/useAnalytics";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";

import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { ChartBox } from "../../shared/ChartBox/ChartBox";

import { tooltips } from "../../../lib/utils/tooltips";
import { InformationBox } from "../../shared/InformationBox/InformationBox";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";

import {
  CustomerRequest,
  InsightsResponse,
} from "../../../api/grpc/analytics/analytics";

export const InsightsBox = () => {
  // const { getRoomsTimeRangeEvents } = useInsightsRequests();
  const { getInsights } = useAnalytics();
  const { timeZone } = useTimeZoneContext();

  const { loading, error, data } = useGrpcQuery<
    Omit<CustomerRequest, "customerID">,
    InsightsResponse
  >({
    method: getInsights,
    variables: {
      timeZone,
    },
  });

  return (
    <div className="ChartBox">
      <HandleRequestState
        state={loading}
        placeholder={<LoadingBox className="LoadingBox--chart" />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={
            <div className="default-box h-100">
              <h3 className="d-flex align-items-center">
                Insights
                <InformationBox
                  leftDiff
                  message={tooltips.dashboard.insights}
                />
              </h3>

              <h6 className="ChartBox__insightsError">
                {error?.message || "Insights failed to load, please try again!"}
              </h6>
            </div>
          }
        >
          <div className="default-box">
            <h3 className="d-flex align-items-center">
              Insights
              <InformationBox leftDiff message={tooltips.dashboard.insights} />
            </h3>
            <ChartBox data={data} />
          </div>
        </HandleRequestState>
      </HandleRequestState>
    </div>
  );
};
