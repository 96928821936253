import React from "react";
import { ApolloError } from "@apollo/client";

import { WorkplaceFields } from "../../../api/graphql/workplaces";
import { WorkplaceTypes } from "../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

import { WorkplaceRow } from "../WorkplaceRow/WorkplaceRow";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { DeviceType } from "../WorkplaceExpandedList/WorkplaceExpandedDevices/WorkplaceExpandedDevices";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";

import "./WorkplaceList.scss";

export interface WorkplaceListData {
  type: WorkplaceTypes;
  data: WorkplaceFields[] | undefined;
}

interface Props {
  parentId?: string;
  loading?: boolean;
  error?: ApolloError | undefined;
  rows: WorkplaceListData[] | undefined;
  deviceType?: DeviceType;
}

export const WorkplaceList = (props: Props) => {
  const { parentId, loading, error, rows, deviceType } = props;

  return (
    <div className="WorkplaceList">
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<LoadingBox count={6} minHeight={60} />}
      >
        <ApolloErrorGuard
          error={error}
          errorComponent={<p>Error fetching data...</p>}
        >
          {rows?.map(
            (item) =>
              item.data !== undefined &&
              item.data.length > 0 && (
                <WorkplaceRow
                  data={item.data}
                  type={item.type}
                  parentId={parentId}
                  key={`${item.type}-listing`}
                  onClick={() => {}}
                  deviceType={deviceType}
                />
              )
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
