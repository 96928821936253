import React from "react";
import { useCustomerPermissions } from "../../components/Billings/lib/useCustomerPermissions";
import { ErrorMessage } from "../../components/shared/ErrorMessage/ErrorMessage";
import { NestedRoute } from "../../components/shared/NestedRoute/NestedRoute";
import { RestrictedPage } from "../RestrictedPage/RestrictedPage";

export const Insights = () => {
  const { loading, error, isBussinesOrPro } = useCustomerPermissions();

  if (loading || isBussinesOrPro) {
    return <NestedRoute baseRoute="insights-sidebar" />;
  }

  if (error) {
    return (
      <ErrorMessage message="Error fetching user plan, please try reloading the page!" />
    );
  }

  return <RestrictedPage title="Insights" />;
};
