import React from "react";
import Select from "react-select";
import { FormFeedback, FormGroup } from "reactstrap";
import { NavLink } from "react-router-dom";

import { useCalendarProvider } from "../../../api/grpc/calendarprovider/useCalendarProvider";

import { getCalendarTypeSlug } from "../lib/helpers";
import {
  GGCalendarProvider,
  GGCalendarProviderStatus,
} from "../../../api/grpc/calendarprovider/calendarprovider";
import { selectStyles } from "../../../lib/utils/selectStyles";
import { RoomFormik } from "../../shared/Forms/RoomForm/lib/useRoomFormik";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { Icon } from "../../shared/Icon/Icon";

interface Props {
  message: string;
  provider: string;
  formik: RoomFormik;
  roomId?: string;
  activeProvider?: GGCalendarProvider;
}

export const CalendarEmailSelect = (props: Props) => {
  const { formik, message, provider, roomId, activeProvider } = props;
  const { loading, error, data } = useCalendarProvider(provider);

  const value = formik.values.calendarId;

  const selectValue =
    value && data
      ? data
          .filter((item) => item.iD === value)
          .map((item) => ({ label: item.name, value: item.iD }))
      : [];

  const options =
    data
      .filter(
        (item) => !item.hasOwnProperty("room") || item.room?.id === roomId
      )
      .map((item) => ({ label: item.name, value: item.iD })) || [];

  return (
    <HandleRequestState
      state={loading}
      placeholder={<p>Loading calendar emails...</p>}
    >
      <HandleRequestState
        state={error}
        placeholder={
          <>
            <p>Calendars couldn't be loaded, please try again!</p>

            {activeProvider?.status === GGCalendarProviderStatus.FAILED && (
              <div className="flex-a-center">
                <Icon icon="red-error-icon" className="w-20-svg" />
                <span className="pl-2 error-message">
                  Auto-renewal of renew-token failed for the selected calendar.
                  To fix this go to
                  <NavLink
                    to={`/manage/calendars/${getCalendarTypeSlug(
                      activeProvider.calType
                    )}/${activeProvider.iD}`}
                    className="px-1"
                  >
                    Calendar
                  </NavLink>
                  page.
                </span>
              </div>
            )}
          </>
        }
      >
        <FormGroup>
          <LabelWithInformationBox
            for="calendarId"
            message={message}
            title="Calendar email"
          />
          {data.length > 0 ? (
            <>
              <Select
                isClearable
                name="calendarId"
                options={options}
                menuPlacement="top"
                value={selectValue}
                theme={selectStyles}
                onChange={(e) => {
                  const item = data.find((item) => item.iD === e?.value);

                  formik.setFieldValue("resourceEmail", item?.email || "");
                  formik.setFieldValue("calendarId", e?.value || "");
                  formik.setFieldTouched("calendarId");
                }}
              />
              {!!formik.errors.calendarId && formik.touched.calendarId && (
                <FormFeedback className="invalid-feedback">
                  {formik.errors.calendarId}
                </FormFeedback>
              )}
            </>
          ) : (
            <p>
              It seems that this calendar provider doesn't have any calendar
              emails configured yet!
            </p>
          )}
        </FormGroup>
      </HandleRequestState>
    </HandleRequestState>
  );
};
