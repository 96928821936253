import React from "react";
import { NavLink } from "react-router-dom";
import { WORKPLACES_ROOT_PATH } from "../../../lib/routes/routes";

export const NoWorkplacesFound = () => {
  return (
    <div className="EmptyWorkplaces">
      <h4>The workspace you are looking couldn't be found!</h4>
      <NavLink
        to={WORKPLACES_ROOT_PATH}
        className="btn btn-outline-primary text-white"
      >
        Go back to Workplaces
      </NavLink>
    </div>
  );
};
