import React from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { RolePermissions } from "../../../api/grpc/account/account";

export const AllReservationsDescription = () => {
  const { user } = useAuthContext();

  return (
    <div className="AllReservationsDescription w-50-large">
      {user?.permissions.includes(
        RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
      ) ? (
        <>
          <span className="text-secondary">
            Use the All Reservations section to efficiently navigate and make
            workspace reservations for specific dates and locations.
          </span>{" "}
          <span className="text-secondary">
            Add up to 5 rooms or desks to your dashboard for an instant overview
            of their reservation statuses.
          </span>
          <ul className="pro-list">
            <li>
              Manage Bookings:{" "}
              <span className="text-secondary">
                Easily create, modify, or cancel your own reservations.
              </span>
            </li>
          </ul>
        </>
      ) : (
        <>
          <span className="text-secondary">
            As a booking admin, use the All reservations section to efficiently
            manage workspace reservations for specific dates and locations:
          </span>
          <ul className="pro-list">
            <li>
              Add & Monitor Workspaces:{" "}
              <span className="text-secondary">
                Add up to 5 rooms or desks to your dashboard for an instant
                overview of their reservation statuses.
              </span>
            </li>
            <li>
              Manage Bookings:{" "}
              <span className="text-secondary">
                Easily modify or cancel bookings to meet your team's evolving
                needs.
              </span>
            </li>
            <li>
              Book on Behalf:{" "}
              <span className="text-secondary">
                Reserve spaces for colleagues, ensuring everyone has the
                workspace they require.
              </span>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};
