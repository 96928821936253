import React, { PropsWithChildren } from "react";
import { useRouter } from "../../../lib/hooks/useRouter";
import { Button } from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";

export const RegisterCaution = ({
  loading,
  email,
  handleContinue,
}: PropsWithChildren<{
  loading: boolean;
  email: string;
  handleContinue: () => void;
}>) => {
  const { history } = useRouter();

  return (
    <div className="RegisterCaution">
      <div className="d-flex flex-column flex-center gap-1">
        <Icon icon="alert-exclamation" />
        <h3>Admin area - read before proceeding</h3>
      </div>

      <span className="d-block my-4">
        You are about to create a new administrator account type for the domain{" "}
        {email}, but it appears that your organization already has one
        registered.
      </span>

      <span className="d-block my-4">
        If you are a user seeking to connect to an organization, please use an
        invitation by email from your administrator. Request one if needed.
      </span>
      <span className="d-block my-4">
        If you are an administrator who wishes to connect, consider requesting
        an invitation first. Alternatively, if you still wish to register
        another master account for the domain, press “Continue”.
      </span>

      <div className="RegisterCaution__buttons">
        <Button
          size="small"
          color="outline-primary"
          title="Back"
          onClick={() => history.push("/login")}
        />
        <Button
          size="small"
          color="primary"
          title="Continue"
          onClick={handleContinue}
          disabled={loading}
        ></Button>
      </div>
    </div>
  );
};
