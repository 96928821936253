import { useMemo } from "react";

import { LicenseFreeSlotsRequest } from "./subscription";
import { transport } from "../grpcTransport";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { SubscriptionServiceClient } from "./subscription.client";

export const useSubscriptionRequests = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const subscriptionClient = useMemo(
    () => new SubscriptionServiceClient(transport),
    []
  );

  const getCustomerSubscription = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await subscriptionClient.getChargebeeSubscription(
      {
        customerId: user.customerid,
        subscriptionId: user.claims.subscriptionId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getCustomerDetails = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await subscriptionClient.getSubscriptionCustomer(
      {
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const licenseFreeSlots = async ({
    type,
  }: Omit<LicenseFreeSlotsRequest, "customerID" | "subscriptionID">) => {
    if (!user) {
      throw new Error("No user found when updating the license!");
    }

    return await subscriptionClient.licenseFreeSlots(
      {
        customerID: user.customerid,
        subscriptionID: user.claims.subscriptionId,
        type,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listCustomerSubscription = async () => {
    if (!user) {
      throw new Error("No user found when updating the license!");
    }

    return await subscriptionClient.listCustomerSubscription(
      {
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getInvoicePDF = async (invoiceId: string) => {
    return await subscriptionClient.getInvoicePDF(
      {
        invoiceId,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    getCustomerSubscription,
    getCustomerDetails,
    getInvoicePDF,
    licenseFreeSlots,
    listCustomerSubscription,
  };
};
