import React from "react";

import "./ModalMessage.scss";

interface Props {
  message: string;
}

export const ModalMessage = (props: Props) => {
  return <p className="ModalMessage">{props.message}</p>;
};
