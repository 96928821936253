import React, { useState } from "react";

import { useDebounce } from "../../../../lib/hooks/useDebounce";
import { useInsightsContext } from "../../Context/InsightsContext";
import { useRoomInsightsContext } from "../../Context/Room/Overview/RoomInsightsContext";

import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { OverviewGraphsRow } from "../../shared/OverviewGraphsRow/OverviewGraphsRow";
import { InsightsAllRooms } from "../AllRooms/InsightsAllRooms";
import { OverviewGeneralStats } from "../../shared/OverviewGeneralStats/OverviewGeneralStats";

export const RoomValueInsights = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce<string>(searchValue, 500);

  const {
    insightsLocation: { locationType },
  } = useInsightsContext();

  const { generalRoomStats, loadingGeneralStats, errorGeneralStats } =
    useRoomInsightsContext();

  return (
    <>
      <div className="RoomValueInsights WorkspaceValueInsights">
        <HandleLoadingState
          loading={loadingGeneralStats}
          loadingPlaceholder={
            <div className="mt-3 WorkspaceValueInsights__statistics WorkspaceValueInsights__statistics--main">
              {/* we can't use count={4} here because it breaks the style */}
              <LoadingBox minHeight={170} />
              <LoadingBox minHeight={170} />
              <LoadingBox minHeight={170} />
              <LoadingBox minHeight={170} />
            </div>
          }
        >
          <HandleRequestState
            state={!!errorGeneralStats}
            placeholder={
              <CenteredBox>{errorGeneralStats?.message}</CenteredBox>
            }
          >
            {!!!debouncedValue.length && (
              <OverviewGeneralStats data={generalRoomStats} />
            )}
          </HandleRequestState>
        </HandleLoadingState>

        {!!!debouncedValue.length && (
          <OverviewGraphsRow
            loading={loadingGeneralStats}
            error={errorGeneralStats}
            data={generalRoomStats?.hourlyDistribution || []}
          />
        )}
      </div>

      {locationType !== "Room" && (
        <InsightsAllRooms
          searchValue={searchValue}
          debouncedValue={debouncedValue}
          setSearchValue={setSearchValue}
        />
      )}
    </>
  );
};
