import React, { FC, memo } from "react";
import L from "leaflet";
import { MapConsumer } from "react-leaflet";

type MapBoundsControllerProps = {
  mapBounds: L.LatLngBoundsLiteral | null;
  mapMaxBounds: L.LatLngBoundsLiteral | null;
  onReady?: (ready: boolean) => void;
};

const MapBoundsController: FC<MapBoundsControllerProps> = memo(
  ({ mapBounds, mapMaxBounds, onReady }) => {
    return (
      <MapConsumer>
        {(map) => {
          if (mapBounds && mapMaxBounds) {
            map.setMaxBounds(mapMaxBounds).fitBounds(mapBounds);
            setTimeout(() => {
              onReady && onReady(true);
            }, 500);
          }
          return null;
        }}
      </MapConsumer>
    );
  }
);

export { MapBoundsController };
