import React from "react";
import cs from "classnames";

import "./SubscriptionStatus.scss";

interface Props {
  status: StatusInformationKeys;
  className?: string;
}

export type StatusInformationKeys =
  | "future"
  | "in_trial"
  | "active"
  | "non_renewing"
  | "paused"
  | "cancelled";

const StatusInformation: {
  [key in StatusInformationKeys]: { class: string; text: string };
} = {
  future: { class: "SubscriptionStatus--inactive", text: "Future" },
  in_trial: { class: "SubscriptionStatus--paused", text: "In Trial" },
  active: { class: "SubscriptionStatus--active", text: "Active" },
  non_renewing: { class: "SubscriptionStatus--active", text: "Non renewing" },
  paused: { class: "SubscriptionStatus--inactive", text: "Paused" },
  cancelled: { class: "SubscriptionStatus--inactive", text: "Cancelled" },
};

export const SubscriptionStatus = ({ status, className }: Props) => {
  const statusInfo: { class: string; text: string } = StatusInformation[status];

  if (!statusInfo) {
    return <span className="SubscriptionStatus">Undefined</span>;
  }

  const classes = cs("SubscriptionStatus", className, statusInfo.class);

  return <span className={classes}>{statusInfo.text}</span>;
};
