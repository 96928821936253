import React, { useMemo } from "react";
import Select from "react-select";

import { FormGroup } from "reactstrap";
import { useWayfindersQuery } from "../../../lib/hooks/useWayfindersQuery";
import { selectStyles } from "../../../lib/utils/selectStyles";
import { tooltips } from "../../../lib/utils/tooltips";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { DeviceWorkspaceType } from "../../shared/Forms/DeviceForm/EditDeviceForm";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";

interface Props {
  value?: string;
  onChange: (value: string | null, type: DeviceWorkspaceType) => void;
  isDisabled: boolean;
}

export const WayfinderSelect = ({ value, onChange, isDisabled }: Props) => {
  const { loading, error, data } = useWayfindersQuery();

  const options = useMemo(() => {
    return (
      data
        .filter((wayfinder) => wayfinder.devices.length <= 0)
        .map((item) => ({ label: item.name, value: item.id })) || []
    );
  }, [data]);

  const selectValue =
    value && data
      ? data
          .filter((item) => item.id === value)
          .map((item) => ({ label: item.name, value: item.id }))
      : [];

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<h5>Loading...</h5>}
    >
      <ApolloErrorGuard
        error={error}
        errorComponent={<h4>Error fetching wayfinder resources...</h4>}
      >
        <FormGroup>
          <LabelWithInformationBox
            for="wayfinder"
            title="Wayfinder"
            direction="left"
            message={tooltips.devices.wayfinder}
          />
          <Select
            name="room"
            isClearable
            options={options}
            value={selectValue}
            theme={selectStyles}
            isDisabled={isDisabled}
            onChange={(e) => {
              if (!e) {
                onChange(null, "wayFinderId");
                return;
              }

              onChange(e.value, "wayFinderId");
            }}
          />
        </FormGroup>
      </ApolloErrorGuard>
    </HandleLoadingState>
  );
};
