import React from "react";

import { tooltips } from "../../../lib/utils/tooltips";

import { HomeDetailsCard } from "../../shared/HomeDetailsCard/HomeDetailsCard";
import { AnalyticsSubscriptionStats } from "../../../api/grpc/analytics/analytics";
import {
  StatusInformationKeys,
  SubscriptionStatus,
} from "../../Subscription/SubscriptionStatus/SubscriptionStatus";

interface Props {
  data?: AnalyticsSubscriptionStats;
}

export const SubscriptionBox = (props: Props) => {
  const { data } = props;

  if (!data) {
    return (
      <HomeDetailsCard
        list={[]}
        bottomDiff
        link="/settings/billing/subscriptions"
        title="Subscription"
        listPlaceholder="No records found!"
      />
    );
  }

  return (
    <HomeDetailsCard
      bottomDiff
      title="Subscriptions"
      link="/settings/billing/subscriptions"
      tooltip={tooltips.dashboard.subscriptions}
      adjustInfoLeft={true}
      subTitle={
        <>
          <h5>{data.planName}</h5>
          <SubscriptionStatus
            status={data.status.toLowerCase() as StatusInformationKeys}
          />
        </>
      }
      list={[
        {
          label: <h5>Rooms</h5>,
          value: (
            <h5>
              {data.licensedRoomNumber}/{data.roomNumber} allocated
            </h5>
          ),
        },
        {
          label: <h5>Desks</h5>,
          value: (
            <h5>
              {data.licensedDeskNumber}/{data.deskNumber} allocated
            </h5>
          ),
        },
        {
          label: <h5>Users</h5>,
          value: (
            <h5>
              {data.licensedUserNumber}/{data.userNumber} allocated
            </h5>
          ),
        },
        {
          label: <h5>Wayfinders</h5>,
          value: (
            <h5>
              {data.licensedWayfinderNumber}/{data.wayfinderNumber} allocated
            </h5>
          ),
        },
      ]}
    />
  );
};
