import React from "react";
import cs from "classnames";

import { Button, ThemeButtonProps } from "../../shared/Button/Button";
import { AddTeamModal } from "../AddTeamModal/AddTeamModal";
import { useToggleModal } from "../../../lib/hooks/useToggleModal";

interface Props extends ThemeButtonProps {
  parentId?: string;
  adjustable?: boolean;
}

export const AddTeamButton = (props: Props) => {
  const { outline, size, title, parentId, lineHeight, color, className } =
    props;
  const { isOpen, toggleModal } = useToggleModal();

  const classes = cs(
    {
      "adjustable-button": props.adjustable,
    },
    className
  );

  return (
    <div className={classes}>
      <Button
        size={size}
        color={color}
        outline={!!outline}
        onClick={toggleModal}
        lineHeight={lineHeight}
        title={title ? title : "Add Team"}
      />

      {isOpen && (
        <AddTeamModal
          isOpen={isOpen}
          parentId={parentId}
          toggle={toggleModal}
        />
      )}
    </div>
  );
};
