import React, { PropsWithChildren } from "react";
import { HourlyDistribution } from "../../../../api/grpc/insights/insights";
import { tooltips } from "../../../../lib/utils/tooltips";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { ChartCard } from "../../shared/ChartCard/ChartCard";
import { DeskLineChart } from "./DeskValueCharts/DeskLineChart";
import { DeskReservationsDistribution } from "./DeskValueCharts/DeskReservationsDistribution";

export const DeskOverviewCharts = ({
  loading,
  data,
  error,
}: PropsWithChildren<{
  loading: boolean;
  data: HourlyDistribution[];
  error?: Error;
}>) => {
  return (
    <div className="OverviewGraphsRow">
      <DeskLineChart />

      {loading ? (
        <LoadingBox className="OverviewGraphsRow__pie" />
      ) : (
        <ChartCard
          title="Reservation Length Distribution (%)"
          message={tooltips.insights.desks.reservationLengthDistribution}
          className="OverviewGraphsRow__pie"
        >
          {error ? (
            <CenteredBox>{error?.message}</CenteredBox>
          ) : (
            <DeskReservationsDistribution data={data} />
          )}
        </ChartCard>
      )}
    </div>
  );
};
