import React from "react";
import { FormFeedback, FormGroup, Input, InputProps } from "reactstrap";

import { tooltips } from "../../../../lib/utils/tooltips";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";

interface Props extends InputProps {
  calendarProviderId: string;
  error?: string;
}

export const EWSProviderInput = (props: Props) => {
  const { error, calendarProviderId, ...inputProps } = props;

  return (
    <FormGroup>
      <LabelWithInformationBox
        for="calendarId"
        title="Calendar email"
        message={tooltips.rooms.settings.calendarEmail}
      />
      <Input type="text" id="calendarId" name="calendarId" {...inputProps} />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};
