import React, { useState } from "react";
import ModalSidebar from "../../Modals/ModalSidebar/ModalSidebar";
import { EditTeamForm } from "../../shared/Forms/TeamForm/EditTeamForm";
import { useEditTeamFormik } from "../../shared/Forms/TeamForm/lib/useEditTeamFormik";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  data: any;
}

export const EditTeam = ({ isOpen, toggle, data }: Props) => {
  const [loading] = useState(false);

  const formik = useEditTeamFormik({
    initialValues: {
      name: data.name,
      location: data.location,
      company: data.company,
      // member: data.member,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await console.log(values);
    },
  });

  return (
    <ModalSidebar
      title="Edit Team"
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
    >
      <EditTeamForm formik={formik} />
    </ModalSidebar>
  );
};
