import React from "react";
import { Modal } from "reactstrap";
import { DirectorySyncWizardForm } from "../../DirectorySync/DirectorySyncWizardForm/DirectorySyncWizardForm";
import { CalendarsWizardForm } from "../CalendarsWizardForm/CalendarsWizardForm";

import "./CalendarModalWizard.scss";

interface CalendarModalWizardProps {
  isOpen: boolean;
  toggle: () => void;
  type?: string;
  isDirectoryUser?: boolean;
}
export const CalendarModalWizard = ({
  isOpen,
  toggle,
  type,
  isDirectoryUser,
}: CalendarModalWizardProps) => {
  return (
    <Modal isOpen={isOpen} className="CalendarModalWizard" toggle={toggle}>
      {isDirectoryUser ? (
        <DirectorySyncWizardForm />
      ) : (
        <CalendarsWizardForm type={type} toggle={toggle} />
      )}
    </Modal>
  );
};
