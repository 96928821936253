import React from "react";

import { getCalendarTypeName } from "../lib/helpers";
import { tooltips } from "../../../lib/utils/tooltips";
import { IconDefinition } from "../../shared/Icon/Icon.generated";

import { CalendarSystemItem } from "./CalendarSystemItem";
import { CalendarType } from "../../../api/grpc/calendarprovider/calendarprovider";
import { CalendarsWizardHeader } from "../CalendarsWizardForm/CalendarsWizardHeader/CalendarsWizardHeader";
import { CalendarsWizardFooter } from "../CalendarsWizardForm/CalendarsWizardFooter/CalendarsWizardFooter";
import { Providers } from "../../../lib/context/Calendar/CalendarContextProvider";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";

import "./CalendarSystem.scss";

interface Props {
  activeStep: number;
  activeCalendar: string;
  handlePrevClick: () => void;
  handleNextClick: (n: number) => void;
  onCalendarSelect: (id: string, stateData?: any) => void;
  type?: string;
  providers: Providers[];
  nameOfPlan: string;
  loading: boolean;
  error?: Error;
}

export const CalendarSystem = (props: Props) => {
  const items: {
    id: string;
    label: string;
    description: string;
    icon: IconDefinition;
    badge?: string;
  }[] = [
    {
      id: "o365",
      icon: "microsoft-365",
      label: getCalendarTypeName(CalendarType.O365),
      description: tooltips.calendars.wizard.addOffice,
      badge: "Recommended",
    },
    {
      id: "gsuite",
      icon: "google_wp",
      label: getCalendarTypeName(CalendarType.GSUITE),
      description: tooltips.calendars.wizard.addGoogle,
      badge: "Recommended",
    },
    {
      id: "ews",
      icon: "exchange_ews",
      label: getCalendarTypeName(CalendarType.EWS),
      description: tooltips.calendars.wizard.addExchange,
    },
  ];

  return (
    <div className="CalendarSystem">
      <CalendarsWizardHeader
        activeStep={props.activeStep}
        handleClick={(n) => console.info(n)}
        type={props.type}
      />

      <div>
        <HandleLoadingState
          loading={props.loading}
          loadingPlaceholder={<LoadingBox minHeight={60} count={3} />}
        >
          <HandleRequestState
            state={!!props.error}
            placeholder={<div>Couldn't fetch your plan!</div>}
          >
            <div className="CalendarSystem__list">
              {items.map((item, key) => {
                return (
                  <CalendarSystemItem
                    id={item.id}
                    icon={item.icon}
                    label={item.label}
                    key={`${key}-calendar-system`}
                    description={item.description}
                    isActive={props.activeCalendar === item.id}
                    onClick={(id) => props.onCalendarSelect(id)}
                    type={props.type}
                    providers={props.providers}
                    nameOfPlan={props.nameOfPlan || ""}
                    badge={item.badge}
                  />
                );
              })}
            </div>
          </HandleRequestState>
        </HandleLoadingState>
      </div>

      <CalendarsWizardFooter
        activeStep={props.activeStep}
        handlePrevClick={props.handlePrevClick}
        handleNextClick={() => props.handleNextClick(props.activeStep + 1)}
        disabledNext={!!!props.nameOfPlan.length}
      />
    </div>
  );
};
