import React from "react";
import { NavLink } from "react-router-dom";

import { useAuthContext } from "../../lib/context/Auth/AuthContext";

import { AuthenticationPage } from "../shared/AuthenticationPage/AuthenticationPage";

import "./Error404.scss";

export const Error404 = () => {
  const { isAuthenticated, userAccess } = useAuthContext();

  return (
    <AuthenticationPage fullWidth>
      <div className="Error404">
        <p>Oooops... looks an error occured!</p>
        <span>Error Code</span>
        <p className="code">404</p>
        <NavLink
          className="btn btn-primary btn-large btn-rounded"
          to={
            isAuthenticated || userAccess === "no-permission" ? "/" : "/login"
          }
        >
          Go Back Home
        </NavLink>
      </div>
    </AuthenticationPage>
  );
};
