import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

const CompleteSignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First name field is required!"),
  lastName: Yup.string().required("Last name field is required!"),
  accountPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long!")
    .required("Password field is required!"),
  privacyTerms: Yup.boolean().oneOf(
    [true],
    "Please make sure you agree with our privacy policy terms"
  ),
});

export interface CompleteSignupFields {
  firstName: string;
  lastName: string;
  workEmail: string;
  accountPassword: string;
  privacyTerms: boolean;
}

interface UseCompleteSignupFormOptions {
  initialValues: CompleteSignupFields;
  onSubmit: (
    values: CompleteSignupFields,
    formikHelpers: FormikHelpers<CompleteSignupFields>
  ) => Promise<any>;
}

export type CompleteSignupFormik = ReturnType<typeof useCompleteSignupFormik>;

export const useCompleteSignupFormik = (opts: UseCompleteSignupFormOptions) => {
  return useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: CompleteSignupSchema,
    ...opts,
  });
};
