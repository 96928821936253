import React, { PropsWithChildren } from "react";
import { toast } from "react-toastify";
import { useDirectoryIntegrationsRequests } from "../../../../api/grpc/calendarprovider/useDirectoryIntegrationsRequests";
import { useConfirmation } from "../../../../lib/context/Confirmation/ConfirmationContext";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { tooltips } from "../../../../lib/utils/tooltips";

import { DirectoryIntegrationsHeaderButtons } from "./DirectoryIntegrationsHeaderButtons";
import { ResourceActions } from "../../../shared/ResourceActions/ResourceActions";
import { DIRECTORY_ROOT_PATH } from "../../../../lib/routes/routes";

import "./DirectoryIntegrationsHeader.scss";

export const DirectoryIntegrationsHeader = ({
  title,
  id,
  refetch,
  refetchListOfDirectory,
}: PropsWithChildren<{
  title: string;
  id: string;
  refetch: () => void;
  refetchListOfDirectory: (offset?: string) => Promise<void>;
}>) => {
  const { history } = useRouter();

  const { deleteDirectoryIntegration } = useDirectoryIntegrationsRequests();
  const confirmation = useConfirmation();

  const handleDeleteIntegration = async () => {
    // we put this out side of try catch because if we click Cancel it triggers the toast error which breaks UX
    await confirmation.confirm({
      title: "Are you sure you want to delete this integration?",
    });

    try {
      await deleteDirectoryIntegration(id);

      history.replace(DIRECTORY_ROOT_PATH);

      refetch();

      toast.success("Integration removed successfully!");

      return;
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting integration!");
      return;
    }
  };

  return (
    <div className="DirectoryIntegrationsHeader">
      <h1>{title}</h1>

      <div className="main-flex gap-1 flex-wrap DirectoryIntegrationsHeader__content">
        <ResourceActions
          message={tooltips.directorySync.integrationPage}
          onDelete={handleDeleteIntegration}
        />

        <DirectoryIntegrationsHeaderButtons
          refetchListOfDirectory={refetchListOfDirectory}
        />
      </div>
    </div>
  );
};
