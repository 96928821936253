import {
  equipments as equipmentsOptions,
  EquipmentType,
} from "../../components/Bookings/form/Capabilities/capabilitiesOptions";

export const listRoomEquipment = (equiments: EquipmentType) => {
  let activeEquipments: string[] = [];

  Object.entries(equiments)
    .filter(({ "1": value }) => value === true)
    .map(({ "0": key }) => {
      return activeEquipments.push(
        equipmentsOptions[key as keyof EquipmentType]
      );
    });

  return activeEquipments;
};
