import gql from "graphql-tag";

import { ZONE_FIELDS_FRAGMENT } from "../zones/zones";
import { DESK_FIELDS_FRAGMENT } from "../desks/desks";
import { ROOM_FIELDS_FRAGMENT } from "../rooms/rooms";
import { SITE_FIELDS_FRAGMENT } from "../sites/sites";
import { FLOOR_FIELDS_FRAGMENT } from "../floors/floors";
import { BUILDING_FIELDS_FRAGMENT } from "../buildings/buildings";
import { WAY_FINDER_FIELDS_FRAGMENT } from "../wayfinder/wayfinder";

import {
  WorkplaceFields,
  WorkplaceTenantFields,
  WorkplaceDefaultFields,
} from "../workplaces";

export interface CompanyFields extends WorkplaceDefaultFields {
  sites?: WorkplaceFields[];
  buildings?: WorkplaceFields[];
  floors?: WorkplaceFields[];
  zones?: WorkplaceFields[];
  rooms?: WorkplaceFields[];
  desks?: WorkplaceFields[];
  wayfinders?: WorkplaceFields[];
}

export interface UpdateCompanyResponse {
  updateCompany: {
    company: CompanyFields[];
  };
}

export interface CompanyRequestVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  remove?: {
    tags: string[];
  };
}

export interface AddCompanyVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
}

export interface AddCompanyResponse {
  addCompany: {
    company: CompanyFields[];
  };
}

export const COMPANY_FIELDS_FRAGMENT = gql`
  fragment CompanyFields on Company {
    id
    name
    tags
    description
    sites(order: { asc: name }) {
      ...SiteFields
    }
    buildings(order: { asc: name }) {
      ...BuildingFields
    }
    floors(order: { asc: name }) {
      ...FloorFields
    }
    zones(order: { asc: name }) {
      ...ZoneFields
    }
    rooms(order: { asc: name }) {
      ...RoomFields
    }
    desks(order: { asc: name }) {
      ...DeskFields
    }
    wayfinders(order: { asc: name }) {
      ...WayfinderFields
    }
  }
  ${SITE_FIELDS_FRAGMENT}
  ${BUILDING_FIELDS_FRAGMENT}
  ${FLOOR_FIELDS_FRAGMENT}
  ${ZONE_FIELDS_FRAGMENT}
  ${ROOM_FIELDS_FRAGMENT}
  ${DESK_FIELDS_FRAGMENT}
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_COMPANY = gql`
  mutation addCompanyMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addCompany(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          tenant: { id: $id }
        }
      ]
    ) {
      company {
        ...CompanyFields
      }
    }
  }
  ${COMPANY_FIELDS_FRAGMENT}
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompanyMutation(
    $id: ID!
    $name: String
    $tags: [String]
    $description: String
    $tenantId: String!
    $remove: CompanyPatch
  ) {
    updateCompany(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
        }
        remove: $remove
      }
    ) {
      company {
        ...CompanyFields
      }
    }
  }
  ${COMPANY_FIELDS_FRAGMENT}
`;
