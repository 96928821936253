import { toast } from "react-toastify";
import L from "leaflet";
import { useRoomsRequests } from "../../../api/graphql/rooms/useRoomsRequests";
import { useApolloClient } from "@apollo/client";
import { CreatedDrawEvent, EditedDrawEvent } from "../FloorPlan/FloorsPlan";
import {
  ROOM_FIELDS_FRAGMENT,
  RoomFields,
} from "../../../api/graphql/rooms/rooms";

type ShapeHandlerParams = {
  tenantId: string;
  drawEvent: CreatedDrawEvent;
  setActiveRoomIdDraw: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const calculatePolygonCentroid = (
  latLngs: L.LatLng[]
): { lat: number; lng: number } => {
  let latSum = 0,
    lngSum = 0;
  latLngs.forEach((latlng) => {
    latSum += latlng.lat;
    lngSum += latlng.lng;
  });
  return {
    lat: latSum / latLngs.length,
    lng: lngSum / latLngs.length,
  };
};

export const useShapeHandlers = () => {
  const { updateRoomCircle, updateRoomMarker, updateRoomPolygonal } =
    useRoomsRequests();
  const client = useApolloClient();

  const handleCreateShape = async ({
    tenantId,
    drawEvent,
    setActiveRoomIdDraw,
  }: ShapeHandlerParams) => {
    const roomId = sessionStorage.getItem("activeRoomIdDraw");

    if (!roomId?.length) {
      return toast.error("Please select a room to draw");
    }

    if (drawEvent.layerType === "circle") {
      const newLayer = drawEvent.layer as L.Circle;

      const { lat, lng } = newLayer.getLatLng();

      await updateRoomCircle({
        id: roomId,
        tenantId: tenantId,
        circleShape: {
          latitude: lat === 0 ? 1 : Math.round(lat),
          longitude: lng === 0 ? 1 : Math.round(lng),
          radius: Math.round(newLayer.getRadius()),
        },
      });

      await updateRoomMarker({
        id: roomId,
        tenantId: tenantId,
        marker: {
          latitude: Math.round(lat),
          longitude: Math.round(lng),
        },
      });

      setActiveRoomIdDraw(undefined);
      return;
    }

    const newLayer = drawEvent.layer as L.Polygon | L.Rectangle;

    const latLngs = newLayer.getLatLngs()[0] as L.LatLng[];

    const polygonal = latLngs.map((latlng) => ({
      latitude: Math.round(latlng.lat),
      longitude: Math.round(latlng.lng),
      type: drawEvent.layerType === "polygon" ? "polygon" : "rectangle",
    }));

    await updateRoomPolygonal({
      id: roomId,
      tenantId: tenantId,
      polygonalToDelete: [],
      polygonal,
    });

    const { lat, lng } = newLayer.getCenter();

    await updateRoomMarker({
      id: roomId,
      tenantId: tenantId,
      marker: {
        latitude: Math.round(lat),
        longitude: Math.round(lng),
      },
    });

    setActiveRoomIdDraw(undefined);
  };

  const handleEditShape = async ({
    editEvent,
    tenantId,
  }: {
    editEvent: EditedDrawEvent;
    tenantId: string;
  }) => {
    editEvent.layers.eachLayer(async (layer) => {
      const layerId = layer.options.attribution?.split(
        "-innerGoGetIDSplitter-"
      )[0];

      if (!layerId?.length) {
        toast.error("Failed to fetch room id");
        return;
      }

      if (layer instanceof L.Circle) {
        const editedLayer = layer as L.Circle;
        const { lat, lng } = editedLayer.getLatLng();

        await updateRoomCircle({
          id: layerId,
          tenantId: tenantId,
          circleShape: {
            latitude: Math.round(lat),
            longitude: Math.round(lng),
            radius: Math.round(editedLayer.getRadius()),
          },
        });

        await updateRoomMarker({
          id: layerId,
          tenantId: tenantId,
          marker: {
            latitude: Math.round(lat),
            longitude: Math.round(lng),
          },
        });

        return;
      }

      const roomData: RoomFields | null = client.readFragment({
        id: `Room:${layerId}`,
        fragmentName: "RoomFields",
        fragment: ROOM_FIELDS_FRAGMENT,
      });

      if (!roomData) {
        return toast.error("Failed to fetch room data");
      }

      const editedLayer = layer as L.Polygon | L.Rectangle;
      const latLngs = editedLayer.getLatLngs()[0] as L.LatLng[];

      const polygonal = latLngs.map((latlng) => ({
        latitude: Math.round(latlng.lat),
        longitude: Math.round(latlng.lng),
        type: layer instanceof L.Rectangle ? "rectangle" : "polygon",
      }));

      const polygonalToDelete = roomData.polygonal.map(({ id }) => ({
        id: id || "",
      }));

      await updateRoomPolygonal({
        id: layerId,
        tenantId: tenantId,
        polygonal,
        polygonalToDelete,
      });

      //we can't use getCenter since it returns error: Layer must be added on map
      const { lat, lng } = calculatePolygonCentroid(latLngs);

      await updateRoomMarker({
        id: layerId,
        tenantId: tenantId,
        marker: {
          latitude: Math.round(lat),
          longitude: Math.round(lng),
        },
      });
    });
  };

  const handleDeleteShape = async ({
    editEvent,
    tenantId,
  }: {
    editEvent: EditedDrawEvent;
    tenantId: string;
  }) => {
    editEvent.layers.eachLayer(async (layer) => {
      const layerId = layer.options.attribution?.split(
        "-innerGoGetIDSplitter-"
      )[0];

      if (!layerId?.length) {
        toast.error("Failed to fetch room id");
        return;
      }

      if (layer instanceof L.Circle) {
        await updateRoomCircle({
          id: layerId,
          tenantId: tenantId,
          circleShape: {
            latitude: 0,
            longitude: 0,
            radius: 0,
          },
        });

        await updateRoomMarker({
          id: layerId,
          tenantId: tenantId,
          marker: {
            latitude: 0,
            longitude: 0,
          },
        });

        return;
      }

      const roomData: RoomFields | null = client.readFragment({
        id: `Room:${layerId}`,
        fragmentName: "RoomFields",
        fragment: ROOM_FIELDS_FRAGMENT,
      });

      if (!roomData) {
        return toast.error("Failed to fetch room data");
      }

      const polygonalToDelete = roomData.polygonal.map(({ id }) => ({
        id: id || "",
      }));

      await updateRoomPolygonal({
        id: layerId,
        tenantId: tenantId,
        polygonal: [],
        polygonalToDelete,
      });

      await updateRoomMarker({
        id: layerId,
        tenantId: tenantId,
        marker: {
          latitude: 0,
          longitude: 0,
        },
      });
    });
  };

  return {
    handleCreateShape,
    handleEditShape,
    handleDeleteShape,
  };
};
