// this function calculates the difference between two
// numbers and returns it as a percentage

export const calculatePercentage = (oldValue: number, newValue: number) => {
  if (oldValue === newValue) {
    return 0;
  }

  if (oldValue > 0 && newValue === 0) {
    return -100;
  }

  if (oldValue === 0 && newValue > 0) {
    return 100;
  }

  return +(((newValue - oldValue) / oldValue) * 100).toFixed(2);
};
