import { useQuery } from "@apollo/client";

import { useAuthContext } from "../context/Auth/AuthContext";

import { GetWorkplaceRequest } from "../../api/graphql/workplaces";
import {
  GetRoomsSelectResponse,
  GET_ROOMS_SELECT,
} from "../../api/graphql/rooms/rooms";

export const useRoomsQuery = () => {
  const { user } = useAuthContext();
  const { loading, error, data, refetch } = useQuery<
    GetRoomsSelectResponse,
    GetWorkplaceRequest
  >(GET_ROOMS_SELECT, {
    variables: {
      id: user?.customerid || "",
    },
  });

  return { loading, error, data: data?.queryRoom || [], refetch };
};
