import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../../../lib/hooks/useOutsideClickDetection";
import { ChangedRoomsDirectionsVariables } from "../../../../Workplaces/EditWorkplace/EditWayFinder/EditWayFinder";
import { Button } from "../../../Button/Button";
import { Icon, IconDefinition } from "../../../Icon/Icon";
import { directionIcons, DirectionNumberType } from "../lib/getDirectionIcon";

interface Props {
  id: string;
  directionValue: number;
  roomName: string;
  distanceValue: number;
  roomDirectionId: string;
  handleChangedRooms: ({
    id,
    roomDirectionId,
    direction,
    distance,
  }: ChangedRoomsDirectionsVariables) => void;
}

export const WayFinderDirection = ({
  id,
  directionValue,
  roomName,
  distanceValue,
  roomDirectionId,
  handleChangedRooms,
}: Props) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [chosedDirectionValue, setChosedDirectionValue] =
    useState<number>(directionValue);

  const [finalDirectionValue, setFinalDirectionValue] =
    useState<number>(directionValue);

  useOutsideClickDetection(
    ref,
    () => {
      if (open) {
        setOpen(false);
      }
    },
    open
  );

  return (
    <>
      <div className="flex-a-center position-relative WayFinderDirectionTable">
        <div
          onClick={() => setOpen((prev) => !prev)}
          className="cursor-pointer"
        >
          {finalDirectionValue <= 0 ? (
            <div className="WayFinderDirectionTable__button">Set</div>
          ) : (
            <Icon
              icon={
                directionIcons[finalDirectionValue as keyof DirectionNumberType]
              }
            />
          )}
        </div>
      </div>
      <div className="WayFinderDirectionTable__status--icon">
        <Icon
          icon={finalDirectionValue <= 0 ? "approve-empty" : "approve-icon"}
        />
      </div>
      {open && (
        <div
          ref={ref}
          className="position-absolute WayFinderDirectionTable__options  WayFinderDirectionTable__directions"
        >
          <div
            onClick={() => setOpen(false)}
            className="WayFinderDirectionTable__options--close"
          >
            <Icon icon="close-icon" />
          </div>
          <div className="WayFinderDirectionTable__options--icons">
            {Object.entries(directionIcons).map(({ "0": key, "1": value }) => (
              <div
                key={key}
                onClick={() => setChosedDirectionValue(+key)}
                className={
                  chosedDirectionValue === +key
                    ? "WayFinderDirectionTable__active"
                    : ""
                }
              >
                <Icon key={key} icon={value as IconDefinition} />
              </div>
            ))}
          </div>

          <div className="flex-a-center WayFinderDirectionTable__options--buttons">
            <Button
              onClick={() => setChosedDirectionValue(0)}
              title="Clear"
              color="outline-secondary"
            />
            <Button
              onClick={() => {
                setOpen(false);
                handleChangedRooms({
                  id: id,
                  roomDirectionId: roomDirectionId,
                  direction: chosedDirectionValue,
                  distance: distanceValue,
                  name: roomName,
                });

                setFinalDirectionValue(chosedDirectionValue);
              }}
              title="Ok"
              color="primary"
            />
          </div>
        </div>
      )}
    </>
  );
};
