import { FormikCustomLanguageFields } from "../types/types";

export const defaultLanguageObject: FormikCustomLanguageFields = {
  intro: {
    start: "Start",
    errorNoWifi: "Error: no Wi-Fi connection",
    checkConnection: "Please check your internet connection",
  },
  pairing: {
    cannotConnect: "Cannot connect to the server.\nPlease try again later",
    codeWillExpire: "This code will soon expire!",
    standBy: "Please stand by for a few seconds...",
    addDevice: "Go to the Dashboard/Add device and enter:",
    newPairingCode: "New pairing code is being regenerated",
    roomConnection: "Waiting for room connection",
    error: "Error",
    pairing: "Pairing",
    ready: "Ready",
    paired: "Paired",
    newCode: "New code",
    unpair: "Unpair",
    getNewCode: "Get new code",
    goHome: "Go Home",
  },
  map: {
    errorTitle: "Sorry, we couldn't find any map for this floor.",
    errorButtonText: "Reload",
    errorDeviceTitle: "Sorry, no hardware support for maps.",
    errorDeviceButtonText: "Close",
    headerTitle: "Map view",
    flashMessage: {
      showingNearbyRoomsOnly: "Showing nearby rooms only.",
    },
    modalMarker: {
      cancel: "Cancel",
      select: "Select",
      QRCode: "Scan to book via mobile app",
      for: "for",
      until: "until",
      reserved: "Reserved",
      available: "Available",
    },
    modalMarkerScaling: {
      title: "Map marker scaling factor",
      cancel: "Cancel",
      apply: "Apply",
    },
    modalSettings: {
      rooms: "Rooms",
      desks: "Desks",
      onlyFree: "Only free",
      title: "Display options",
      apply: "Apply",
    },
  },
  settings: {
    networkButton: "Network",
    dateButton: "Date & Time",
    localeButton: "Locale",
    generalButton: "General",
    fontButton: "Font Size & Style",
    kioskButton: "Kiosk Mode",
    notificationButton: "Notifications",
    version: "Version",
    network: {
      open: "Open",
      label: "Network",
      wifiTitle: "Wi-Fi Settings",
      wifiDescription: "Access to OS Wi-Fi Settings and more",
      lanTitle: "LAN/Ethernet Settings",
      lanDescription: "Access to advanced OS LAN settings and more",
    },
    date: {
      time: "Date & Time",
      timeTitle: "Date & Time Settings",
      timeDescription: "Access to OS date and time settings and more",
    },
    locale: {
      label: "Locale",
      title: "Localization Settings",
      description: "Access to OS localization settings",
      languageTitle: "Language Select (Main UI)",
    },
    general: {
      general: "General",
      initial: "Check for update",
      clearCache: "Clearing cache...",
      downloaded: "Successfully downloaded",
      startingDownloading: "Starting downloading...",
      downloading: "Downloading latest build...",
      checkPermission: "Checking permission...",
      checkLatest: "Checking latest version...",
      found: "Found build",
      unlinkTitle: "Unlink pairing",
      unlinkSubtitle: "Unlink the device from the GOGET Dashboard.",
      updatesTitle: "Updates",
      screenCleaningTitle: "Screen cleaning mode",
      screenCleaningText1: "Disables touch inputs for 2 mins",
      screenCleaningText2:
        "Clean the screen with a microfiber or similar cloth with small, circular motions.",
      screenCleaningButtonName: "Clean now",
      updatesSubtitle: "Download and install updates.",
      noUpdatesFound: "No updates found",
      toastInstalling: "Installing...",
      toastError: "Something went wrong",
      toastContactAdmin: "Try again or contact admin",
    },
    font: {
      sizeStyle: "Font Size & Style",
      title: "Font Settings",
      description: "Access to OS font settings",
    },
    kiosk: {
      turnOff: "Turn Off",
      turnOn: "Turn On",
      buttonNameOn: "On",
      buttonNameOff: "Off",
      buttonNameSet: "Set",
      buttonNameRemove: "Remove",
      nonRootStep1Title: "Step 1. Set as the Home App",
      nonRootStep1Description:
        'RDX must be the default Home App. Press "Home app" and select RDX. After app restarts, proceed with step 2.',
      nonRootStep2Title: "Step 2. Device Administrator",
      nonRootStep2Description:
        'RDX must be set as Device Administrator. Press "Activate..." to select. After app restarts, proceed with step 3.',
      nonRootStep3Title: "Step 3. Set Protection with PIN",
      nonRootStep3Description:
        'For kiosk mode, you need to add a security protection with PIN. Press "PIN" to activate and remember to write down your code for future use! After app restarts, proceed with step 4.',
      nonRootStep4Title: "Step 4. Apply Kiosk Mode",
      nonRootStep4Description:
        'Press "On" to enable kiosk mode. Full kiosk mode will be applied after app restarts.',
      nonRootStep1OffTitle: "Step 1. Deactivate Protection with PIN",
      nonRootStep1OffDescription:
        'Press to initiate deactivation of Protection settings by entering the security code as prompted. In Protection settings, select "None" and confirm. Do not abort this step! Navigate back and proceed with step 2.',
      nonRootStep2OffTitle: "Step 2. Turn off Device Administrator",
      nonRootStep2OffDescription:
        "RDX must be removed as Device Administrator. Press and then proceed with step 3.",
      nonRootStep3OffTitle: "Step 3. Reset the Home App",
      nonRootStep3OffDescription:
        'Press and unselect RDX in the "Home app" section to reset/clear the Home App. RDX is no longer default Home App. Done!',
      rootStep1Title: "Step1. Turn OFF the Default Application Launcher",
      rootStep1Description:
        "The Default Application Launcher must be turned off in Kiosk Mode",
      rootStep2Title: "Step 2. Turn OFF system UI menu bar",
      rootStep2Description:
        "The system UI menu bar must be turned off to prevent users from accessing other apps or Android OS.",
      rootStep3Title: "Step 3. Restart the device",
      rootStep3Description: "Kiosk mode will be activated after restart.",
      rootStep1aTitleImproved:
        "Step 1a. Turn OFF the Default Application Launcher",
      rootStep1aDescriptionImproved:
        "Must be combined with step 1b to take affect.\nSelect and proceed with step 1b.",
      rootStep1bTitleImproved: "Step 1b. Clear The Default Launcher (Home App)",
      rootStep1bDescriptionImproved:
        "Will open a dialogue, select Home app and select the RDX app as the Home app instead of the Launcher. Return and proceed with step 2a.",
      rootStep2aTitleImproved: "Step 2a. Turn OFF the UI navigation bar",
      rootStep2aDescriptionImproved:
        "Turning off the UI navigation bar makes the Kiosk mode experience better.\nRequires reboot in step 2b to take affect.",
      rootStep2bTitleImproved: "Step 2b. Restart the device",
      rootStep2bDescriptionImproved:
        "The UI navigation bar will be de-activated after restart.\nAfter restart, Kiosk mode will be fully activated.",
      rootStep1OffTitle: "Step1. Turn ON the Default Application Launcher",
      rootStep1OffDescription:
        "The Default Application Launcher must be turned off in Kiosk Mode",
      rootStep2OffTitle: "Step 2. Turn On system UI menu bar",
      rootStep2OffDescription:
        "The system UI menu bar must be turned off to prevent users from accessing other apps or Android OS.",
      rootStep3OffTitle: "Step 3. Restart the device",
      rootStep3OffDescription: "Kiosk mode will be de-activated after restart.",
      rootStep1aOffTitleImproved: "Step 1a. Turn ON the UI navigation bar",
      rootStep1aOffDescriptionImproved:
        "Turning on the UI navigation bar makes it easier to navigate the device when Kiosk mode is off. Requires reboot in step 1b to take affect. ",
      rootStep1bOffTitleImproved: "Step 1b. Restart the device",
      rootStep1bOffDescriptionImproved:
        "The UI navigation bar will be activated after restart. After restart, proceed with step 2a.",
      rootStep2aOffTitleImproved:
        "Step 2a. Turn ON the Default Application Launcher",
      rootStep2aOffDescriptionImproved:
        "Must be combined with step 2b to take affect. Select and proceed with step 2b.",
      rootStep2bOffTitleImproved:
        "Step 2b. Clear The Default Launcher (Home App)",
      rootStep2bOffDescriptionImproved:
        "Will open a dialogue, select Home app and select the Launcher as the home app instead of RDX. Done!",
      homeButtonTitle: "System Home Button",
      homeButtonDescription: "Press to access Android OS or other apps.",
      kioskMode: "Kiosk Mode",
      rootMoreStep1Title: "Clear the Default Launcher (Home App)",
      rootMoreStep1Description: "Press to clear the current home app",
      rootMoreStep1DescriptionImproved:
        "Press to clear the current home app setting",
      rootMoreStep2Title: "System Home Button",
      rootMoreStep2Description: "Press to access Android OS or other app",
      rootMoreStep2DescriptionImproved:
        "Press to access Android OS or other apps",
      rootMoreStep3Title: "Set Device Admin",
      rootMoreStep3Description: "Caution: advanced setting",
      more: "More",
      toastActivateSteps: "You need to activate steps 1-3",
    },
  },
  nearbyRooms: {
    headerTitle: "Nearby Rooms",
    free: "Free",
    busy: "Busy",
    videoConference: "Video Conferencing",
    presentationTech: "Presentation Tech",
    audio: "Audio",
    climate: "Climate (AC)",
    casting: "Casting",
    charging: "Charging Port",
    desk: "Desk (Sit-Stand)",
    accessibility: "Accessibility",
    roomCapacity: "Room Capacity",
    minimumEquipment: "Minimum Equipment",
    resetFilters: "Reset Filters",
    showFreeRooms: "Show free rooms only",
    apply: "Apply",
    matchingRooms: "Matching rooms",
    filters: "Filters",
  },
  home: {
    errorContentBoard: "Sorry, no hardware support for content board.",
    allDay: "all day",
    for: "for",
    checkIn: "Check In",
    soonFree: "Soon free",
    busy: "Busy",
    free: "Free",
    onScreenReservation: "On-screen reservation",
    pending: "Check-in Open",
    next: "Next",
    allDayEvent: "All day event",
    contactlessBooking: "Contactless booking",
    organizer: "Organizer",
    admin: "Admin",
    report: "Report",
    signInOut: "Sign in/out",
    modalPin: {
      titleLock: "Screen Lock",
      subtitleLock: "Enter passcode",
      confirm: "Done",
      error: "Wrong code. Please re-try",
    },
  },
  calendar: {
    day: "Day",
    week: "Week",
    here: "Here",
    busyAllDay: "Busy all day",
    free: "Free",
    onScreenReservation: "On-screen reservation",
    allDay: "All day",
    subject: "Subject",
    required: "required",
    name: "Name",
    makePrivate: "Make private",
    onlineMeeting: "Add online meeting",
    lockPin: "Lock with PIN",
    book: "Book",
    extend: "Extend",
    delete: "Delete",
    checkOut: "Check out",
    update: "Update",
    startTime: "Start Time",
    endTime: "End Time",
    cancel: "Cancel",
    areYouSure: "Are you sure?",
    yes: "Yes",
    no: "No",
    modalPin: {
      titleLock: "Lock booking",
      titleUnlock: "Unlock booking",
      subtitleLock: "Enter a one-time 4-digit code - remember it!",
      subtitleUnlock: "Enter the one-time PIN for this event.",
      confirm: "Confirm",
      error: "Wrong code. Please re-try",
    },
  },
  network: {
    ethernet: "Ethernet",
    wifiTitle: "Wi-Fi Settings",
    noConnection: "Error: no network or server connection",
  },
  modalPin: {
    accessIdentification: "Access identification",
    enterPin: "Enter admin PIN",
    done: "Done",
    error: "Wrong user code or card. Please retry",
  },
  userMenu: {
    booking: "Booking",
    checkOut: "Check out",
    nearbyRooms: "Nearby rooms",
    mapView: "Map view",
    reportErrors: "Report errors",
    admin: "Admin",
    helpWizard: "Help wizard",
  },
  modalQuickBook: {
    title: "Quick Book",
    meetingDetails: "Meeting details",
    subject: "Subject",
    required: "required",
    hostName: "Host name",
    findAnotherRoom: "Find another room",
    book: "Book",
    duration: "Duration",
    max: "Max",
    now: "Now",
    start: "Start",
    nearbyRooms: "Nearby rooms",
    mapView: "Map view",
  },
  modalCheckIn: {
    deleting: "Deleting...",
    checkIn: "Check In",
    confirmMeeting: "please confirm your meeting",
    meetingCancelled: "Meeting will be cancelled in",
  },
  modalLobby: {
    info: "INFO",
    roomIssuesDescription:
      "The room {{roomName}} encountered the following issue{{endSign}}:\n\n",
    noLicense: "• No valid or expired license\n",
    noCalendarConnection: "• No working calendar connection\n",
    checkSettings: "Please check the dashboard settings",
    hide: "HIDE",
  },
  modalReport: {
    resolveErrors: "Resolve errors?",
    reportError: "Report Error",
    report: "Report",
    resolve: "Resolve",
    descriptionResolve: "Press Resolve to clear all errors",
    descriptionReport: "Tap faulty equipment and submit",
    videoConference: "Video Conferencing",
    presentationTech: "Presentation Tech",
    audio: "Audio",
    climate: "Climate (AC)",
    casting: "Casting",
    charging: "Charging Port",
    desk: "Desk (Sit-Stand)",
    accessibility: "Accessibility",
    name: "Name *",
    message: "Message",
  },
  modalDisconnect: {
    info: "INFO",
    roomDisconnected: "The room was disconnected from the dashboard.",
    checkRoomSettings: "Please check the dashboard settings",
    unpair: "Unpair",
  },
  modalScreenCleaning: {
    screenCleaningMode: "Screen cleaning mode",
    timeRemaining: "Time remaining",
    lastCleaned: "Last cleaned",
    unknown: "unknown",
  },
  flashMessage: {
    done: "Done",
    error: "Something went wrong!",
    errorDesc: "Try again or contact admin",
    checkIn: "Check-in complete!",
    slotBusy: "Time slot is busy!",
    unableToSyncMeetings:
      "Unable to sync meetings. Check local network connection and verify the room calendar integration in the GOGET admin dashboard.",
    updateRoomSettings: "Updating room settings…",
    reservationsBlockedAdminPolicy:
      "Reservations blocked for room due to admin policy",
    updateDisplaySettings: "Updating display settings…",
  },
  toastMessage: {
    longPress: "Long Press to Exit",
    noInternetConnection: "No Internet connection!",
    checkingForUpdate: "Checking for update...",
    installingUpdate: "Installing update...",
    failedUpdate: "Update failed...",
    synchronizingError:
      "There was an error with synchronizing resources. Try to reboot your application.",
    pairingError: "There was an error with pairing your device.",
    unpairingError:
      "There was an error with unpairing your device. Try to reboot your application.",
    error: "Something went wrong! Try again.",
  },
  checkInReminder: {
    autoDelete: {
      body: "Since check-in expired your {{roomName}} reservation was automatically released for others to use.If you still need the room, try making a new booking request.Have a great day!",
      subject: "Important! Your {{roomName}} reservation was released",
    },
    reminder: {
      body: "Confirm your reservation {{meetingTitle}} in {{roomName}} within {{interval}} min to keep your reservation.If you do not check in, the room will be released at {{endTime}} for others to reserve.Enjoy your meeting!",
      subject:
        "Important! Check-in for {{roomName}} required within {{interval}} min to keep your reservation.",
    },
  },
};
