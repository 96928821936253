import { useQuery } from "@apollo/client";

import { useAuthContext } from "../context/Auth/AuthContext";

import { GetWorkplaceRequest } from "../../api/graphql/workplaces";

import {
  GetDesksSelectResponse,
  GET_DESKS_SELECT,
} from "../../api/graphql/desks/desks";

export const useDesksQuery = () => {
  const { user } = useAuthContext();

  const { loading, error, data } = useQuery<
    GetDesksSelectResponse,
    GetWorkplaceRequest
  >(GET_DESKS_SELECT, {
    variables: {
      id: user?.customerid || "",
    },
  });

  return { loading, error, data: data?.queryDesk || [] };
};
