import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { trimText } from "../../../../lib/helpers/trimText";
import { getWorkplaceIcon } from "../../helpers/getWorkplaceInformation";

import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { WORKPLACES_ROOT_PATH } from "../../../../lib/routes/routes";
import { DeleteButton } from "../../../shared/DeleteButton/DeleteButton";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";
import { WorkplacePrefixIcon } from "../WorkplacePrefixIcon/WorkplacePrefixIcon";
import { LicenseSwitch } from "../../LicenseSwitch/LicenseSwitch";
import { EntityType } from "../../../../api/grpc/subscription/subscription";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { retrieveCalendarName } from "../WorkplaceExpandedRooms/helpers/retrieveCalendarName";
import { AddDeviceButton } from "../../AddWorkplace/AddWorkplaceButton/AddDeviceButton/AddDeviceButton";
import { SetCalendarButton } from "../../AddWorkplace/SetCalendarButton/SetCalendarButton";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";

interface Props {
  data: WorkplaceFields[];
}

export type WorkplaceLicenseEntity = "Room" | "Desk" | "Wayfinder";

export const licenseType: {
  [key in WorkplaceLicenseEntity]: number;
} = {
  Desk: EntityType.DESK,
  Room: EntityType.ROOM,
  Wayfinder: EntityType.WAYFINDER,
};

export const WorkplaceSearchedItem = ({ data }: Props) => {
  const { deleteWorkplace } = useWorkplaceRequests();
  const history = useHistory();

  const { providers } = useCalendarContext();

  const columns: StructureTableColumn[] = [
    {
      key: "workplace_icon",
      header: "Workspaces",
    },
    {
      key: "workplace_name",
      header: "Name",
    },
    {
      key: "workplace_description",
      header: "Description",
    },
    {
      key: "workplace_tags",
      header: "Tags",
    },
    {
      key: "workplace_calendar",
      header: "Calendar",
    },
    {
      key: "workplace_device",
      header: "Device",
    },
    {
      key: "workplace_license",
      header: "License",
    },
    {
      key: "workplace_delete",
      header: "",
    },
  ];

  const rows: StructureTableRows[] = data.map((item) => {
    const isDeviceParentEntity = ["Room", "Wayfinder"].includes(
      item.__typename
    );

    const devices =
      item?.devices && item.devices.length > 0
        ? item.devices.map((item) => item.name)
        : [];

    const calendarName = isDeviceParentEntity
      ? undefined
      : retrieveCalendarName({
          providers,
          calendarId: item.calendarId,
          providerId: item.calendarProviderId,
        });

    return {
      workplace_icon: (
        <WorkplacePrefixIcon
          icon={getWorkplaceIcon(item.__typename) as IconDefinition}
        />
      ),
      workplace_name: (
        <NavLink
          to={`${WORKPLACES_ROOT_PATH}/${item.__typename.toLowerCase()}/${
            item.id
          }`}
        >
          <h6 className="mb-0" title={item.name}>
            {item.name}
          </h6>
        </NavLink>
      ),
      workplace_tags: (
        <h6 className="mb-0">
          {item?.tags.length > 0 ? trimText(item.tags[0], 25) : "-"}
        </h6>
      ),
      workplace_description: (
        <h6 className="mb-0">{trimText(item.description || "-")}</h6>
      ),
      workplace_calendar:
        item.__typename !== "Room" ? (
          <></>
        ) : calendarName ? (
          <span className="mb-0" title={calendarName}>
            {trimText(calendarName, 25)}
          </span>
        ) : (
          <SetCalendarButton
            outline
            size="small"
            lineHeight="low"
            title="Set Calendar"
            onClick={() => {
              history.push(
                `${WORKPLACES_ROOT_PATH}/room/${item.id}/settings#add-calendar`
              );
            }}
          />
        ),
      workplace_device: !isDeviceParentEntity ? (
        <></>
      ) : devices.length > 0 ? (
        <>
          {devices.map((item, index) => {
            return <span key={`${item}-${index}`}>{trimText(item)}</span>;
          })}
        </>
      ) : (
        <AddDeviceButton
          outline
          size="small"
          lineHeight="low"
          color="secondary"
          title="Set Device"
          parentId={item.id}
          parentType={item.__typename === "Room" ? "Room" : "Wayfinder"}
          numberOfDeviceAdded={item?.devices?.length}
        />
      ),
      workplace_license: ["Room", "Desk", "Wayfinder"].includes(
        item.__typename
      ) ? (
        <LicenseSwitch
          id={item.id}
          type={licenseType[item.__typename as WorkplaceLicenseEntity]}
          value={!!item.licensed}
        />
      ) : (
        <></>
      ),
      workplace_delete: (
        <div className="ml-auto">
          <DeleteButton
            onClick={() => deleteWorkplace(item.id, item.__typename)}
          />
        </div>
      ),
    };
  });

  return (
    <StructureTable
      rows={rows}
      columns={columns}
      noResultsPlaceholder={<CenteredBox>No workspace found</CenteredBox>}
    />
  );
};
