import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { useAuth } from "../../../api/grpc/auth/useAuth";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { loginRequest } from "../authConfig";
import { InteractionStatus } from "@azure/msal-browser";

export const MicrosoftLogin = () => {
  const { instance, accounts, inProgress } = useMsal();
  const { exchangeSocialAuthToken } = useAuth();
  const { signInWithProvider, setSsoLoading } = useAuthContext();

  useEffect(() => {
    const handleLogin = async () => {
      if (accounts.length <= 0 || inProgress === InteractionStatus.None) {
        return;
      }

      try {
        setSsoLoading(true);
        const res = await instance.acquireTokenSilent({
          account: accounts[0],
          scopes: loginRequest.scopes,
        });
        localStorage.setItem("token", res.accessToken);

        const { response } = await exchangeSocialAuthToken(res.accessToken);
        await signInWithProvider(response.token);
        localStorage.setItem("initiateLogin", "1");
      } catch (error: any) {
        localStorage.clear();

        toast.error(error?.message);
      } finally {
        setTimeout(() => {
          setSsoLoading(false);
        }, 1500);
      }
    };

    handleLogin();
  }, [accounts, inProgress]);

  const handleLogin = async () => {
    instance.loginRedirect({
      prompt: "select_account",
      scopes: loginRequest.scopes,
    });
  };
  return (
    <button onClick={() => handleLogin()}>
      <span className="ConnectProviders__office"></span>
      <span>Sign in with Microsoft</span>
    </button>
  );
};
