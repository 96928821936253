import React from "react";
import { BillingBox } from "../BillingBox/BillingBox";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { SubscriptionBox } from "../SubscriptionBox/SubscriptionBox";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { useSubscription } from "../../../lib/hooks/useSubscription";
import { usePartnerData } from "../../Billings/lib/usePartnerData";

import "./SubscriptionWrapper.scss";

export const SubscriptionWrapper = () => {
  const { loading, error, data } = useSubscription();
  const { data: partnerData } = usePartnerData();

  return (
    <div className="SubscriptionWrapper">
      <HandleRequestState
        state={loading}
        placeholder={
          <>
            <LoadingBox minHeight={195} style={{ marginBottom: 20 }} />
            <LoadingBox minHeight={195} />
          </>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={<p>Error loading subscription information</p>}
        >
          <SubscriptionBox data={data?.subscription} />
          {!partnerData?.email && (
            <BillingBox
              data={data?.billing}
              status={data?.subscription?.status?.toLowerCase()}
            />
          )}
        </HandleRequestState>
      </HandleRequestState>
    </div>
  );
};
