import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import {
  ListDesksResponse,
  PersonBookingRequest,
} from "../../../../api/grpc/desk/desk";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";

import { Icon } from "../../../shared/Icon/Icon";
import { useBookingsContext } from "../../Context/BookingsContext";
import { UserModalLoading } from "./UserModalLoading";
import { Person } from "../../../../api/grpc/contacts/contacts";
import { UserInfo } from "./UserInfo";
import { attachHoursToDate } from "../../lib/dateInputConvert";
import { format } from "date-fns";

import "./UserModal.scss";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  person: Person | null;
}

export const UserModal = ({ handleModalClose, isOpen, person }: Props) => {
  const { getUserReserveBookingInfo } = useDeskBookingRequest();
  const { timeZone } = useTimeZoneContext();
  const { allDay } = useBookingsMapContext();
  const {
    day,
    reservDeskVariables: { startTime, endTime },
  } = useBookingsContext();

  let start = attachHoursToDate(format(day, "yyyy-MM-dd"), startTime, timeZone);
  let end = attachHoursToDate(
    format(day, "yyyy-MM-dd"),
    endTime,
    timeZone,
    allDay
  );

  const { loading, error, data } = useGrpcQuery<
    PersonBookingRequest,
    ListDesksResponse
  >({
    method: getUserReserveBookingInfo,
    variables: {
      accountID: person?.accountID || "",
      customerID: "",
      startTime: start,
      endTime: end,
      tagsFilter: [],
    },
  });

  return (
    <Modal className="BookingsModal UserModal" isOpen={isOpen}>
      <ModalHeader>
        <span onClick={handleModalClose} className="btn-close">
          <Icon icon="close-icon" />
        </span>
      </ModalHeader>

      {loading ? (
        <UserModalLoading />
      ) : error ? (
        <div>Error loading user data. Please reload and try again.</div>
      ) : (
        <UserInfo
          imageUrl={person?.avatar?.url}
          name={person?.displayName}
          email={person?.email}
          desks={data?.desks || []}
          personAccountId={person?.accountID}
          handleModalClose={handleModalClose}
        />
      )}
    </Modal>
  );
};
