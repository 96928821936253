import React from "react";
import { IDeviceFilters } from "../../../../views/Manage/Devices/Devices";
import { Button } from "../../../shared/Button/Button";
import { CheckboxButton } from "../../../shared/CheckboxButton/CheckboxButton";
import { ResourceFilterHeader } from "../../../shared/ResourceFilter/ResourceFilterHeader";

interface Option {
  value: string;
  name: string;
  title: string;
}

export interface FilterItem {
  name: string;
  options: Option[];
}

export interface SelectedFilters {
  [filterName: string]: string | undefined;
}

interface Props {
  filters: IDeviceFilters;
  onChange: (updatedFilters: IDeviceFilters) => void;
}

type PossibleFilters = "assigned" | "app" | "status" | "autoUpdate";

const deviceFilters: FilterItem[] = [
  {
    name: "assigned",
    options: [
      {
        value: "assigned",
        name: "assigned",
        title: "Assigned",
      },
      {
        value: "unassigned",
        name: "unassigned",
        title: "Unassigned",
      },
    ],
  },
  {
    name: "app",
    options: [
      {
        value: "room",
        name: "room",
        title: "Room Display X",
      },
      {
        value: "wayFinder",
        name: "wayFinder",
        title: "Wayfinder X",
      },
    ],
  },
  {
    name: "status",
    options: [
      {
        value: "online",
        name: "online",
        title: "Online",
      },
      {
        value: "offline",
        name: "offline",
        title: "Offline",
      },
    ],
  },
  {
    name: "autoUpdate",
    options: [
      {
        value: "auto",
        name: "auto",
        title: "Automatic",
      },
      {
        value: "manual",
        name: "manual",
        title: "Manual",
      },
    ],
  },
];

const definedName: {
  [key in PossibleFilters]: string;
} = {
  assigned: "Pairing status",
  app: "App",
  status: "Connection status",
  autoUpdate: "Updates",
};

export const DeviceFilters = ({ filters, onChange }: Props) => {
  const handleFilterChange = (
    filterName: PossibleFilters,
    selectedValue: string
  ) => {
    const isOptionSelected = filters[filterName] === selectedValue;

    const newFilterValue = isOptionSelected ? undefined : selectedValue;

    const updatedFilters = {
      ...filters,
      [filterName]: newFilterValue,
    };

    onChange(updatedFilters);
  };

  return (
    <div className="child-element">
      <div className="main-flex mb-2">
        <ResourceFilterHeader />

        <Button
          title="Clear all"
          color="outline-secondary"
          className="child-element--clear"
          disabled={
            filters.assigned === undefined &&
            filters.app === undefined &&
            filters.status === undefined &&
            filters.autoUpdate === undefined
          }
          onClick={() => {
            const updatedFilters: {
              [key in PossibleFilters]: undefined;
            } = {
              assigned: undefined,
              app: undefined,
              status: undefined,
              autoUpdate: undefined,
            };

            onChange(updatedFilters);
          }}
        />
      </div>

      <div className="d-flex gap-3 FilterContent">
        {deviceFilters.map((filterItem, index) => {
          return (
            <div key={index}>
              <span className="child-element--head text-nowrap flex">
                {definedName[filterItem.name as PossibleFilters]}
              </span>
              {filterItem.options.map((item, index) => (
                <CheckboxButton
                  key={index}
                  id={item.value}
                  name={item.title}
                  checked={
                    filters[filterItem.name as keyof IDeviceFilters] ===
                    item.value
                  }
                  onChange={() =>
                    handleFilterChange(
                      filterItem.name as PossibleFilters,
                      item.value
                    )
                  }
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
