import React from "react";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { ContactSalesModal } from "../../../ContactSales/ContactSalesModal";
import { BillingInnerCard } from "../../shared/BillingInnerCard/BillingInnerCard";

export interface BillingData {
  title: string;
  content: string;
  hasButton: boolean;
  buttonTitle: string;
  onClick?: () => void;
  outerLinkName?: string;
  outerLink?: string;
}

const data: BillingData[] = [
  {
    title: "Help center",
    content: "For more information about billing and pricing, visit our",
    hasButton: false,
    buttonTitle: "",
    outerLinkName: "help center",
    outerLink: "https://support.gogetcorp.com/",
  },
  {
    title: "Contact sales support",
    content:
      "Our support team is here to help with any questions you may have!",
    hasButton: true,
    buttonTitle: "Contact sales",
  },
];

export const BillingHelp = () => {
  const { isOpen, toggleModal } = useToggleModal();

  return (
    <div>
      {data.map((item: BillingData) => (
        <BillingInnerCard
          key={item.title}
          title={item.title}
          content={item.content}
          hasButton={item.hasButton}
          buttonTitle={item.buttonTitle}
          onClick={toggleModal}
          outerLinkName={item?.outerLinkName}
          outterLink={item?.outerLink}
        />
      ))}
      {isOpen && <ContactSalesModal isOpen={isOpen} toggle={toggleModal} />}
    </div>
  );
};
