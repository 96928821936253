// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "pairing.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Pairing } from "./pairing";
import type { IsPairedResponse } from "./pairing";
import type { IsPairedRequest } from "./pairing";
import type { Empty } from "./google/protobuf/empty";
import type { DeleteRequest } from "./pairing";
import type { RetrieveResponse } from "./pairing";
import type { RetrieveRequest } from "./pairing";
import type { ListResponse } from "./pairing";
import type { ListRequest } from "./pairing";
import type { PairingModel } from "./pairing";
import type { UpdatePairingRequest } from "./pairing";
import type { PairResponse } from "./pairing";
import type { PairRequest } from "./pairing";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GenerateResponse } from "./pairing";
import type { GenerateRequest } from "./pairing";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Endpoint group for operations pertaining to Pairing operations
 *
 * @generated from protobuf service Pairing
 */
export interface IPairingClient {
    /**
     * Generates a pairing code for a device
     *
     * @generated from protobuf rpc: Generate(GenerateRequest) returns (GenerateResponse);
     */
    generate(input: GenerateRequest, options?: RpcOptions): UnaryCall<GenerateRequest, GenerateResponse>;
    /**
     * Pairs a code to an customer and deviceID
     *
     * @generated from protobuf rpc: Pair(PairRequest) returns (PairResponse);
     */
    pair(input: PairRequest, options?: RpcOptions): UnaryCall<PairRequest, PairResponse>;
    /**
     * Updates pairing information
     *
     * @generated from protobuf rpc: UpdatePairing(UpdatePairingRequest) returns (PairingModel);
     */
    updatePairing(input: UpdatePairingRequest, options?: RpcOptions): UnaryCall<UpdatePairingRequest, PairingModel>;
    /**
     * List devices for a customerId. Assigned set to true will list all devices connected to a room.
     * Assigned set to false will list all devices that are not connected to a room.
     *
     * @generated from protobuf rpc: ListDevices(ListRequest) returns (ListResponse);
     */
    listDevices(input: ListRequest, options?: RpcOptions): UnaryCall<ListRequest, ListResponse>;
    /**
     * Retrieve will get details on a specific pairing
     *
     * @generated from protobuf rpc: Retrieve(RetrieveRequest) returns (RetrieveResponse);
     */
    retrieve(input: RetrieveRequest, options?: RpcOptions): UnaryCall<RetrieveRequest, RetrieveResponse>;
    /**
     * Delete will delete a specific pairing
     *
     * @generated from protobuf rpc: Delete(DeleteRequest) returns (google.protobuf.Empty);
     */
    delete(input: DeleteRequest, options?: RpcOptions): UnaryCall<DeleteRequest, Empty>;
    /**
     * IsPaired attempts to retrieve the pairing status of a single deviceID
     *
     * @generated from protobuf rpc: IsPaired(IsPairedRequest) returns (IsPairedResponse);
     */
    isPaired(input: IsPairedRequest, options?: RpcOptions): UnaryCall<IsPairedRequest, IsPairedResponse>;
}
/**
 * Endpoint group for operations pertaining to Pairing operations
 *
 * @generated from protobuf service Pairing
 */
export class PairingClient implements IPairingClient, ServiceInfo {
    typeName = Pairing.typeName;
    methods = Pairing.methods;
    options = Pairing.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Generates a pairing code for a device
     *
     * @generated from protobuf rpc: Generate(GenerateRequest) returns (GenerateResponse);
     */
    generate(input: GenerateRequest, options?: RpcOptions): UnaryCall<GenerateRequest, GenerateResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GenerateRequest, GenerateResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Pairs a code to an customer and deviceID
     *
     * @generated from protobuf rpc: Pair(PairRequest) returns (PairResponse);
     */
    pair(input: PairRequest, options?: RpcOptions): UnaryCall<PairRequest, PairResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<PairRequest, PairResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Updates pairing information
     *
     * @generated from protobuf rpc: UpdatePairing(UpdatePairingRequest) returns (PairingModel);
     */
    updatePairing(input: UpdatePairingRequest, options?: RpcOptions): UnaryCall<UpdatePairingRequest, PairingModel> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePairingRequest, PairingModel>("unary", this._transport, method, opt, input);
    }
    /**
     * List devices for a customerId. Assigned set to true will list all devices connected to a room.
     * Assigned set to false will list all devices that are not connected to a room.
     *
     * @generated from protobuf rpc: ListDevices(ListRequest) returns (ListResponse);
     */
    listDevices(input: ListRequest, options?: RpcOptions): UnaryCall<ListRequest, ListResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListRequest, ListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve will get details on a specific pairing
     *
     * @generated from protobuf rpc: Retrieve(RetrieveRequest) returns (RetrieveResponse);
     */
    retrieve(input: RetrieveRequest, options?: RpcOptions): UnaryCall<RetrieveRequest, RetrieveResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<RetrieveRequest, RetrieveResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Delete will delete a specific pairing
     *
     * @generated from protobuf rpc: Delete(DeleteRequest) returns (google.protobuf.Empty);
     */
    delete(input: DeleteRequest, options?: RpcOptions): UnaryCall<DeleteRequest, Empty> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * IsPaired attempts to retrieve the pairing status of a single deviceID
     *
     * @generated from protobuf rpc: IsPaired(IsPairedRequest) returns (IsPairedResponse);
     */
    isPaired(input: IsPairedRequest, options?: RpcOptions): UnaryCall<IsPairedRequest, IsPairedResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<IsPairedRequest, IsPairedResponse>("unary", this._transport, method, opt, input);
    }
}
