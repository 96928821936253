import React, { PropsWithChildren } from "react";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import cs from "classnames";
import { LabelDirectionType } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";

export const ChartCard = ({
  title,
  message,
  children,
  className,
  direction,
  adjustInfoRight,
  adjustInfoLeft,
}: PropsWithChildren<{
  title?: string;
  message?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  direction?: LabelDirectionType;
  adjustInfoRight?: boolean;
  adjustInfoLeft?: boolean;
}>) => {
  const classes = cs("ChartCard", className);

  return (
    <div className={classes}>
      {title && (
        <div className="flex-a-center ChartCard__header">
          <h5 className="pr-1">{title}</h5>
          {message && (
            <InformationBox
              message={message}
              direction={direction ?? "left"}
              adjustInfoLeft={adjustInfoLeft}
              adjustInfoRight={adjustInfoRight}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
};
