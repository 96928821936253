import { MultiFactorError } from "firebase/auth";
import { createContext, useContext } from "react";

export type VerifyMfaKeys =
  | "verifyCode"
  | "options"
  | "secondaryEmail"
  | "requestDisable";

export type LoginContextValues = {
  mfaStep: VerifyMfaKeys | undefined;
  setMfaStep: React.Dispatch<React.SetStateAction<VerifyMfaKeys | undefined>>;
  errorMfa: MultiFactorError | undefined;
  setErrorMfa: React.Dispatch<
    React.SetStateAction<MultiFactorError | undefined>
  >;
};

export const LoginContext = createContext<LoginContextValues>({
  mfaStep: undefined,
  setMfaStep: () => undefined,
  errorMfa: undefined,
  setErrorMfa: () => undefined,
});

export const useLoginContext = () => {
  const context = useContext(LoginContext);

  if (!context) {
    throw new Error("useLoginContext must be used within LoginContext");
  }

  return context;
};
