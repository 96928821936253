import React, { useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";

import { useAccountRequests } from "../../../../api/grpc/account/useAccountRequests";
import { useAuth } from "../../../../api/grpc/auth/useAuth";
import { useLoginContext } from "../../../../lib/context/LoginContext/LoginContext";

import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import { Input } from "../../../shared/Input/Input";
import { PasswordInput } from "../../../shared/PasswordInput/PasswordInput";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const RequestDisableMfa = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const { setMfaStep } = useLoginContext();
  const { validateCredentials } = useAuth();
  const { disableManualMFA } = useAccountRequests();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {
        response: { isValid },
      } = await validateCredentials({ email, password });

      if (isValid === false) {
        setError("Wrong user email/password");
        setLoading(false);

        return;
      }

      await disableManualMFA({ email });

      setSuccess(true);
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="VerifyMfa">
      <div className="VerifyMfa__info">
        <span>
          You can remove two-factor authentication from your account by
          submitting a request to Goget's security team that will ask you
          additional questions. Once we have confirmed your identity, you can
          log in with your password.
          <span className="d-block mt-3">
            Submit the request by re-entering your Owner/Admin account's user
            email and password.
          </span>
        </span>
      </div>
      <FormGroup>
        <Label>User email</Label>
        <Input
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="VerifyMfa__input"
        />
      </FormGroup>

      <FormGroup>
        <Label for="password">Password</Label>
        <PasswordInput
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="VerifyMfa__input"
          //   isTouched={password.length >= 1}
          //   errorMessage=''
          //   invalid={password.length >= 1}
        />
      </FormGroup>
      {error !== undefined && !success && (
        <FormFeedback className="invalid-feedback">{error}</FormFeedback>
      )}

      {success && (
        <div className="VerifyMfa__success flex-a-center">
          <Icon icon="check-icon" />
          <span>Request sent. We will get back to you shortly. Thank you.</span>
        </div>
      )}

      <div className="VerifyMfa__buttons flex-a-center">
        <Button
          color={success ? "outline-secondary" : "outline-primary"}
          size="small"
          title={success ? "Close" : "Back"}
          onClick={() => {
            if (success) {
              return setMfaStep(undefined);
            }

            return setMfaStep("options");
          }}
        />

        {success !== true && (
          <Button
            color="primary"
            size="small"
            title="Submit"
            onClick={handleSubmit}
            loadSpinner={loading}
            loadTitle="Submitting"
            disabled={
              loading || !emailRegex.test(email) || password.length <= 1
            }
          />
        )}
      </div>
    </div>
  );
};
