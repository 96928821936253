import React from "react";
import { useRouter } from "../../../lib/hooks/useRouter";
import { TEMPLATE_ROOT_PATH } from "../../../lib/routes/routes";
import { Beta } from "../../shared/Beta/Beta";

import { ResourceActions } from "../../shared/ResourceActions/ResourceActions";
import { WorkplaceTypes } from "../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

import "./WorkplaceHeader.scss";

interface Props {
  name: string;
  tags: string[];
  message: string;
  description: string;
  onClick: () => void;
  type: WorkplaceTypes;
  beta?: boolean;
  handleTemplatePicker: () => void;
}

export const WorkplaceHeader = (props: Props) => {
  const {
    name,
    message,
    onClick,
    description,
    tags,
    beta,
    type,
    handleTemplatePicker,
  } = props;
  const { history } = useRouter();

  const handleTemplateIconClick = () => {
    if (!["Room", "Desk"].includes(type)) {
      return;
    }

    handleTemplatePicker();

    history.push(`${TEMPLATE_ROOT_PATH}/transfer`);

    return;
  };

  return (
    <div className="WorkplaceHeader">
      {beta && <Beta />}
      <h1>{name}</h1>
      <ul className="WorkplaceHeader__information">
        {description && (
          <li>
            <span>Description:</span>
            {description}
          </li>
        )}
        {tags && tags.length > 0 && (
          <li>
            <span>Tags:</span>
            {tags.map((item) => (
              <span
                className="WorkplaceHeader__tag"
                key={`${item}-${name}-tag-listing`}
              >
                {item}
              </span>
            ))}
          </li>
        )}
      </ul>
      <ResourceActions
        message={message}
        onDelete={onClick}
        type={type}
        onTemplateClick={handleTemplateIconClick}
      />
    </div>
  );
};
