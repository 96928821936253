import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { DeviceFormFields } from "../../../../../api/graphql/workplaces";

const DeviceSchema = Yup.object().shape({
  type: Yup.string(),
  name: Yup.string().when("type", {
    is: "new",
    then: Yup.string().required("Please enter the name of the device!"),
  }),
  code: Yup.string().when("type", {
    is: "new",
    then: Yup.string().required("Pairing code is required!"),
  }),
  existingDeviceId: Yup.string().when("type", {
    is: "existing",
    then: Yup.string().required("Please select one of the existing devices!"),
  }),
});

export type DeviceFormType = "existing" | "new" | "none";

export type DeviceWorkspaceType = "room" | "wayFinder" | "";

interface UseDeviceFormOptions {
  newDevice?: boolean;
  initialValues?: DeviceFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: DeviceFormFields,
    formikHelpers: FormikHelpers<DeviceFormFields>
  ) => Promise<any>;
}

export type DeviceFormik = ReturnType<typeof useDeviceFormik>;

export const useDeviceFormik = (opts: UseDeviceFormOptions) => {
  const { newDevice, ...rest } = opts;
  return useFormik<DeviceFormFields>({
    initialValues: {
      name: "",
      code: "",
      roomId: "",
      wayFinderId: "",
      description: "",
      existingDeviceId: "",
      typeOfWorkspace: "",
      isOnline: true,
      type: newDevice ? "new" : "none",
      autoUpdate: true,
    },
    ...rest,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: DeviceSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
