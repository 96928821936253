import React from "react";
import cs from "classnames";
import { toast } from "react-toastify";
import { IconDefinition } from "../../shared/Icon/Icon.generated";
import { Providers } from "../../../lib/context/Calendar/CalendarContextProvider";
import { IntegrationsContent } from "../../Integrations/IntegrationsContent";

interface Props {
  id: string;
  label: string;
  isActive: boolean;
  description: string;
  icon: IconDefinition;
  badge?: string;
  onClick: (id: string) => void;
  type?: string;
  providers: Providers[];
  nameOfPlan: string;
}

export const CalendarSystemItem = (props: Props) => {
  const classes = cs("CalendarSystem__item px-2 py-2", {
    "CalendarSystem__item--active": props.isActive,
  });

  const handleClick = (id: string, disabled?: boolean) => {
    if (disabled) {
      return;
    }

    if (props.nameOfPlan.toLowerCase().includes("pro")) {
      return props.onClick(id);
    }

    if (props.type !== "personal" && props.providers.length >= 1) {
      return toast.info(
        "Your plan doesn't support multiple calendar provider integrations (multi-tenancy)"
      );
    }

    props.onClick(id);
  };

  return (
    <div className="CalendarSystemItem">
      <div
        className={classes}
        title={`${props.label} System`}
        onClick={() => handleClick(props.id)}
      >
        <IntegrationsContent
          icon={props.icon}
          label={props.label}
          description={props.description}
          badge={props.badge}
        />
      </div>
    </div>
  );
};
