import { useMemo } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { LoginMethod, Role } from "./account/account";
import { transport } from "../grpcTransport";
import { ContactsServiceClient } from "./contacts.client";
import {
  ContactsRegistrationRequest,
  ContactsTokenValidateRequest,
  CreateAutoSyncRequest,
  DeleteAutoSyncRequest,
  GetAutoSyncRequest,
  ListIntegrationGroupsMembersRequest,
  ListPeopleFilter,
  ListPeopleRequest,
  NullableBoolean,
  PaginatedListIntegrationGroupsMembersRequest,
  SyncIntegrationGroupsMembersRequest,
} from "./contacts";
import { Status } from "../account/account";

interface ContactsDefaultRequest {
  email: string;
  role: Role;
  teamID: number;
  deskID: string[];
  loginMethod: LoginMethod;
}

export interface EditContactVariables {
  id: string;
  firstName: string;
  lastName: string;
  role: Role;
  deskID: string[];
}

export interface GetContactsVariables {
  pageSize: number;
  page: number;
  name: string;
  desks: NullableBoolean;
  licensed: NullableBoolean;
  role: Role[];
  status: Status[];
}

export const useContacts = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const contactsClient = useMemo(
    () => new ContactsServiceClient(transport),
    []
  );

  const getContacts = async ({
    pageSize = 10,
    page = 1,
    name,
    role,
    status,
    licensed,
    desks,
  }: GetContactsVariables) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.contactList(
      {
        page,
        pageSize,
        customerID: user.customerid,
        name: name,
        role: role,
        status: status,
        licensed: licensed,
        desks: desks,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const editContact = async (variables: EditContactVariables) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.contactUpdate(
      {
        ...variables,
        teamID: 0,
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const registerContact = async (variables: ContactsRegistrationRequest) => {
    return await contactsClient.contactRegistration(variables);
  };

  const validateRegisterToken = async (
    variables: ContactsTokenValidateRequest
  ) => {
    return await contactsClient.contactInviteTokenValidate(variables);
  };

  const inviteContact = async (variables: ContactsDefaultRequest) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.contactInvite(
      {
        ...variables,
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const resendInvite = async (id: string) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.contactResendInvite(
      {
        id,
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteContact = async (id: string) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.contactDelete(
      {
        id,
        customerID: user.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listPeople = async ({ personalCalendarID }: ListPeopleRequest) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.listPeople(
      {
        customerID: user?.customerid,
        accountID: user?.claims.user_id,
        personalCalendarID: personalCalendarID,
        query: "",
        filter: ListPeopleFilter.ALL,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const completeSocialRegistration = async (
    id: string,
    email: string,
    tenantId: string
  ) => {
    return await contactsClient.contactSocialRegistration(
      {
        customerId: tenantId,
        email: email,
        socialUserId: id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const listDirectoryUsers = async ({
    providerID,
    filter,
    limit,
    offsetToken,
    search,
  }: Omit<PaginatedListIntegrationGroupsMembersRequest, "customerID">) => {
    if (!user) {
      throw new Error("User was not found!");
    }
    return await contactsClient.paginatedListIntegrationGroupsMembers(
      {
        customerID: user.customerid,
        providerID,
        filter,
        limit,
        offsetToken,
        search,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const syncDirectoryUsers = async ({
    providerID,
    members,
    loginMethod,
    syncAll,
  }: Omit<SyncIntegrationGroupsMembersRequest, "customerID">) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.syncIntegrationGroupsMembers(
      {
        customerID: user.customerid,
        providerID: providerID,
        members: members,
        loginMethod: loginMethod,
        syncAll: syncAll,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const createDirectoryAutoSync = async ({
    providerID,
    loginMethod,
  }: Omit<CreateAutoSyncRequest, "customerID">) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.createAutoSync(
      {
        customerID: user.customerid,
        providerID: providerID,
        loginMethod: loginMethod,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const deleteDirectoryAutoSync = async ({
    providerID,
  }: Omit<DeleteAutoSyncRequest, "customerID">) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.deleteAutoSync(
      {
        customerID: user.customerid,
        providerID: providerID,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDirectoryAutoSync = async ({
    providerID,
  }: Omit<GetAutoSyncRequest, "customerID">) => {
    if (!user) {
      throw new Error("User was not found!");
    }

    return await contactsClient.getAutoSync(
      {
        customerID: user.customerid,
        providerID: providerID,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    editContact,
    getContacts,
    resendInvite,
    inviteContact,
    deleteContact,
    registerContact,
    validateRegisterToken,
    listPeople,
    completeSocialRegistration,
    listDirectoryUsers,
    syncDirectoryUsers,
    createDirectoryAutoSync,
    deleteDirectoryAutoSync,
    getDirectoryAutoSync,
  };
};
