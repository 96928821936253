import React from "react";
import cs from "classnames";
import { ApolloProvider } from "@apollo/client";

import { useAuthContext } from "./lib/context/Auth/AuthContext";
import { useCreateApolloClient } from "./lib/hooks/useCreateApolloClient";

import { Header } from "./components/Header/Header";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { SidebarContextProvider } from "./lib/context/Sidebar/SidebarContextProvider";
import { CustomerContextProvider } from "./lib/context/Customer/CustomerContextProvider";
import { CalendarContextProvider } from "./lib/context/Calendar/CalendarContextProvider";
import { ConfirmationProvider } from "./lib/context/Confirmation/ConfirmationContextProvider";
import { SidebarDifferential } from "./components/Sidebar/SidebarDifferential/SidebarDifferential";
import { MainApp } from "./components/MainApp/MainApp";
import { BookingsContextProvider } from "./components/Bookings/Context/BookingsContextProvider";
import { BookingsMapContextProvider } from "./components/Bookings/BookingsMap/Context/BookingsMapContextProvider";
import { DirectoryContextProvider } from "./components/DirectorySync/Context/DirectoryContextProvider";
import { TimeZoneContextProvider } from "./lib/context/TimeZone/TimeZoneContextProvider";
import { ValidateTokenProvider } from "./lib/context/ValidateToken/ValidateTokenContextProvider";
import { RoomTemplateContextProvider } from "./lib/context/Templates/RoomTemplate/RoomTemplateContextProvider";
import { DeskTemplateContextProvider } from "./lib/context/Templates/DeskTemplate/DeskTemplateContextProvider";
import { TemplatesContextProvider } from "./lib/context/Templates/Templates/TemplatesContextProvider";

interface Props {
  children: React.ReactNode;
}

export const AppWrapper = (props: Props) => {
  const { client } = useCreateApolloClient();
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated || client === null) {
    return <>{props.children}</>;
  }

  const classes = cs("main_content", {
    "main_content--header": isAuthenticated,
  });

  return (
    <div className={classes}>
      <ApolloProvider client={client}>
        <TimeZoneContextProvider>
          <CustomerContextProvider>
            <CalendarContextProvider>
              <ConfirmationProvider>
                <SidebarContextProvider>
                  <BookingsContextProvider>
                    <BookingsMapContextProvider>
                      <ValidateTokenProvider>
                        <DirectoryContextProvider>
                          <RoomTemplateContextProvider>
                            <DeskTemplateContextProvider>
                              <TemplatesContextProvider>
                                <Sidebar />
                                <SidebarDifferential />
                                <MainApp>{props.children}</MainApp>
                                {/* Header needed to be called at the bottom, because of the z-index overlapping of tooltips.  */}
                                <Header />
                              </TemplatesContextProvider>
                            </DeskTemplateContextProvider>
                          </RoomTemplateContextProvider>
                        </DirectoryContextProvider>
                      </ValidateTokenProvider>
                    </BookingsMapContextProvider>
                  </BookingsContextProvider>
                </SidebarContextProvider>
              </ConfirmationProvider>
            </CalendarContextProvider>
          </CustomerContextProvider>
        </TimeZoneContextProvider>
      </ApolloProvider>
    </div>
  );
};
