import React, { useContext } from "react";
import { Subscription } from "../../../../../api/grpc/subscription/subscription";

export interface ICalculatorProContext {
  subscription?: Subscription;
  setSubscription: React.Dispatch<
    React.SetStateAction<Subscription | undefined>
  >;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const CalculatorProContext = React.createContext<ICalculatorProContext>({
  subscription: undefined,
  setSubscription: () => {},
  selectedDate: new Date(),
  setSelectedDate: () => {},
});

export function useCalulactorProContext() {
  return useContext(CalculatorProContext);
}
