import React, { useState } from "react";
import cs from "classnames";

import { Icon } from "../Icon/Icon";
import { Switch } from "../Switch/Switch";

import "./FormSection.scss";

export interface FormSectionProps {
  title?: string;
  width?: number;
  sectionId?: string;
  isActive?: boolean;
  flexible?: boolean;
  switchName?: string;
  toggleable?: boolean;
  children: React.ReactNode;
  onChange?: (value: boolean) => void;
  className?: string;
}

export const FormSection = (props: FormSectionProps) => {
  const {
    title,
    width,
    sectionId,
    toggleable,
    switchName,
    isActive,
    flexible,
    onChange,
    children,
    className,
  } = props;
  const [toggle, setToggle] = useState(false);

  const classes = cs("FormSection", className, {
    "FormSection--switch": switchName !== undefined,
    "FormSection--toggleable": toggleable,
    "FormSection--toggled": toggle || isActive,
    "FormSection--flex": flexible,
  });

  const handleToggleClick = () => {
    if (!toggleable) {
      return;
    }

    setToggle((prev) => !prev);
  };

  return (
    <div className={classes} style={{ width: `${width || 100}%` }}>
      <div className="FormSection__header">
        {title && (
          <h3
            onClick={handleToggleClick}
            className="FormSection__title"
            id={sectionId ? sectionId : ""}
          >
            {title}
          </h3>
        )}
        {switchName !== undefined && (
          <Switch
            value={isActive}
            name={switchName}
            onChange={(value) => {
              if (onChange) {
                onChange(value);
              }
            }}
          />
        )}
        {toggleable && <Icon icon="chevron" onClick={handleToggleClick} />}
      </div>
      <div className="FormSection__content">{children}</div>
    </div>
  );
};
