import { useMemo } from "react";
import { transport } from "../../../api/grpc/grpcTransport";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { SubscriptionServiceClient } from "../subscription/subscription.client";

export const useCardPayment = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const subscriptionClient = useMemo(
    () => new SubscriptionServiceClient(transport),
    []
  );

  const getPaymentCard = async () => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await subscriptionClient.getPaymentCard(
      {
        customerId: user?.customerid,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    getPaymentCard,
  };
};
