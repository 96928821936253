import gql from "graphql-tag";

import { DESK_FIELDS_FRAGMENT } from "../desks/desks";
import { ROOM_FIELDS_FRAGMENT } from "../rooms/rooms";
import { ZONE_FIELDS_FRAGMENT } from "../zones/zones";
import { FLOOR_FIELDS_FRAGMENT } from "../floors/floors";
import { WAY_FINDER_FIELDS_FRAGMENT } from "../wayfinder/wayfinder";

import {
  WorkplaceFields,
  WorkplaceTenantFields,
  WorkplaceDefaultFields,
} from "../workplaces";

export interface BuildingFields extends WorkplaceDefaultFields {
  floors?: WorkplaceFields[];
  zones?: WorkplaceFields[];
  rooms?: WorkplaceFields[];
  desks?: WorkplaceFields[];
  wayfinders?: WorkplaceFields[];
}

export interface UpdateBuildingResponse {
  updateBuilding: {
    building: BuildingFields[];
  };
}

export interface BuildingRequestVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  remove?: {
    tags: string[];
  };
}

export interface AddBuildingResponse {
  addBuilding: {
    building: BuildingFields[];
  };
}

export const BUILDING_FIELDS_FRAGMENT = gql`
  fragment BuildingFields on Building {
    id
    name
    tags
    description
    floors(order: { asc: name }) {
      ...FloorFields
    }
    zones(order: { asc: name }) {
      ...ZoneFields
    }
    rooms(order: { asc: name }) {
      ...RoomFields
    }
    desks(order: { asc: name }) {
      ...DeskFields
    }
    wayfinders(order: { asc: name }) {
      ...WayfinderFields
    }
  }
  ${FLOOR_FIELDS_FRAGMENT}
  ${ZONE_FIELDS_FRAGMENT}
  ${ROOM_FIELDS_FRAGMENT}
  ${DESK_FIELDS_FRAGMENT}
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_BUILDING = gql`
  mutation addBuildingMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addBuilding(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          tenant: { id: $id }
        }
      ]
    ) {
      building {
        ...BuildingFields
      }
    }
  }
  ${BUILDING_FIELDS_FRAGMENT}
`;

export const ADD_BUILDING_TO_COMPANY = gql`
  mutation addBuildingToCompanyMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addBuilding(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          company: { id: $id }
        }
      ]
    ) {
      building {
        ...BuildingFields
      }
    }
  }
  ${BUILDING_FIELDS_FRAGMENT}
`;

export const ADD_BUILDING_TO_SITE = gql`
  mutation addBuildingToSiteMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addBuilding(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          site: { id: $id }
        }
      ]
    ) {
      building {
        ...BuildingFields
      }
    }
  }
  ${BUILDING_FIELDS_FRAGMENT}
`;

export const UPDATE_BUILDING = gql`
  mutation updateBuildingMutation(
    $id: ID!
    $name: String
    $tags: [String]
    $description: String
    $tenantId: String!
    $remove: BuildingPatch
  ) {
    updateBuilding(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
        }
        remove: $remove
      }
    ) {
      building {
        ...BuildingFields
      }
    }
  }
  ${BUILDING_FIELDS_FRAGMENT}
`;
