import React, { useState } from "react";
import { toast } from "react-toastify";
import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import { useSidebarContext } from "../../../lib/context/Sidebar/SidebarContext";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { tooltips } from "../../../lib/utils/tooltips";
import { Icon } from "../../shared/Icon/Icon";
import { InformationBox } from "../../shared/InformationBox/InformationBox";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { UploadImage } from "../../shared/UploadImage/UploadImage";
import { AddFloorMapButton } from "../AddFloorMapButton/AddFloorMapButton";
import { FloorPickerButton } from "../FloorPicker/FloorPickerButton";

import "./FloorsHeader.scss";

interface Props {
  title: string;
  floorId: string;
  imageUrl: string;
  hasImage: boolean;
}

export const FloorsHeader = ({ title, floorId, imageUrl, hasImage }: Props) => {
  const { togglePageSidebar } = useSidebarContext();
  const { syncFloorData } = useWorkplacesClient();
  const [loading, setLoading] = useState(false);
  const { timeZone } = useTimeZoneContext();

  const handleSyncFloorData = async () => {
    try {
      setLoading(true);
      await syncFloorData({ floorId: floorId, timeZone: timeZone });

      toast.success("Map metadata pushed to Wayfinder(s).");

      return;
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="FloorsHeader">
      <PageHeader title={title} message={tooltips.floorPlanHeader}>
        <div className="FloorsHeader__buttons">
          <UploadImage
            title="Upload your floor plan"
            floorId={floorId}
            imageUrl={imageUrl}
            hasImage={!!hasImage}
            buttonText={
              <AddFloorMapButton
                adjustable
                size="medium"
                color="third"
                icon="site_icon_18x18"
                title={hasImage ? "Edit map" : "Add map"}
              />
            }
            onUpload={() => {}}
          />
          <FloorPickerButton
            className="FloorPickerButton"
            adjustable
            size="medium"
            onClick={togglePageSidebar}
            color="third"
          />
        </div>
        {hasImage && (
          <div className="flex-a-center FloorsHeader__pusher">
            <div
              className={`flex-a-center ${loading ? "disabled" : ""}`}
              onClick={() => {
                if (loading) {
                  return;
                }

                return handleSyncFloorData();
              }}
            >
              <Icon icon="push-icon" />
              <span>Push map updates</span>
            </div>
            <InformationBox message={tooltips.floorPusher} />
          </div>
        )}
      </PageHeader>
    </div>
  );
};
