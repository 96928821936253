import React, { PropsWithChildren, useState } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";

import { useDeskTemplateContext } from "../../../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";
import { useRouter } from "../../../../../lib/hooks/useRouter";
import { LabelWithInformationBox } from "../../../LabelWithInformationBox/LabelWithInformationBox";
import { HandleRequestState } from "../../../HandleRequestState/HandleRequestState";
import { HandleLoadingState } from "../../../HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../LoadingBox/LoadingBox";
import { selectStyles } from "../../../../../lib/utils/selectStyles";
import { Button } from "../../../Button/Button";
import { TEMPLATE_ROOT_PATH } from "../../../../../lib/routes/routes";

export const CreateDeskFromTemplate = ({
  fillFieldsFromProfile,
  resetFormikFields,
}: PropsWithChildren<{
  fillFieldsFromProfile?: (id: string) => void;
  resetFormikFields: () => void;
}>) => {
  const { history } = useRouter();
  const { deskTemplates, loadingDeskTemplate, errorDeskTemplates } =
    useDeskTemplateContext();
  const [selectedProfile, setSelectedProfile] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const options = deskTemplates.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <FormGroup className="mt-3">
      <LabelWithInformationBox
        for="deskProfile"
        title="Use template settings"
        message={"message"}
        direction={"left"}
      />
      <HandleRequestState
        state={!!errorDeskTemplates}
        placeholder={
          <div>{errorDeskTemplates || "Error fetching desk profiles"}</div>
        }
      >
        <HandleLoadingState
          loading={loadingDeskTemplate}
          loadingPlaceholder={<LoadingBox minHeight={60} />}
        >
          <div className="border-bottom pb-2">
            {deskTemplates.length > 0 ? (
              <Select
                name="deskProfile"
                isClearable
                isSearchable
                theme={selectStyles}
                options={options}
                value={selectedProfile ? selectedProfile : undefined}
                onChange={(e) => {
                  if (!e) {
                    setSelectedProfile(null);
                    return resetFormikFields();
                  }

                  if (fillFieldsFromProfile) {
                    setSelectedProfile({
                      label: e.label,
                      value: e.value,
                    });
                    return fillFieldsFromProfile(e.value);
                  }
                }}
                className={`${selectedProfile ? "Profile__active" : ""} mb-2`}
              />
            ) : (
              <Button
                title="Add new template"
                color="link"
                onClick={() => history.push(`${TEMPLATE_ROOT_PATH}/add-desk`)}
                className="pl-0"
              />
            )}
          </div>
        </HandleLoadingState>
      </HandleRequestState>
    </FormGroup>
  );
};
