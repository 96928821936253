import React from "react";
import { FormGroup, Label } from "reactstrap";
import cs from "classnames";

import { tooltips } from "../../../../../lib/utils/tooltips";

import { Switch } from "../../../Switch/Switch";
import { FormSection } from "../../../FormSection/FormSection";
import { DisplaySettingsSection } from "./ReservationSettings";
import { MinutesSelect } from "../../MinutesSelect/MinutesSelect";
import { FormSubSection } from "../../../FormSubSection/FormSubSection";
import { LabelWithInformationBox } from "../../../LabelWithInformationBox/LabelWithInformationBox";
import { Input } from "../../../Input/Input";
import { InformationBox } from "../../../InformationBox/InformationBox";

export const DisplaySettings = (props: DisplaySettingsSection) => {
  const {
    formik: { values, errors, touched, handleChange },
    isBussinesOrPro,
    className,
  } = props;

  const { rooms } = tooltips;
  const { display } = values;

  const classes = cs("RoomForm__main", className);

  return (
    <FormSection title="Display settings" toggleable className={classes}>
      <FormSubSection>
        <Switch
          name="display.showOrganizer"
          value={display.showOrganizer}
          title="Show meeting organizer"
          message={rooms.display.showOrganizer}
          onChange={(value) => props.onChange("display.showOrganizer", value)}
        />
        <Switch
          title="Show meeting title"
          name="display.showMeetingTitle"
          value={display.showMeetingTitle}
          message={rooms.display.showTitle}
          onChange={(value) =>
            props.onChange("display.showMeetingTitle", value)
          }
        />
        <Switch
          title="Show attendees"
          name="display.showAttendees"
          value={display.showAttendees}
          message={rooms.display.showAttendees}
          onChange={(value) => props.onChange("display.showAttendees", value)}
        />
        <Switch
          title="Show room capacity"
          name="display.showRoomCapacity"
          value={display.showRoomCapacity}
          message={rooms.display.showCapacity}
          onChange={(value) =>
            props.onChange("display.showRoomCapacity", value)
          }
        />
        <Switch
          title="Show room amenities​"
          name="display.showRoomAmeneties"
          value={display.showRoomAmeneties}
          message={rooms.display.showAmenities}
          onChange={(value) =>
            props.onChange("display.showRoomAmeneties", value)
          }
        />
        <Switch
          title="Check-in required"
          message={rooms.display.checkIn}
          name="display.showCheckinRequired"
          value={!!display.showCheckinRequired}
          onChange={(value) =>
            props.onChange("display.showCheckinRequired", value || null)
          }
        >
          <FormGroup>
            <LabelWithInformationBox
              for="pendingTimeBeforeMeeting"
              title="Pending time before meeting"
            />
            <MinutesSelect
              onChange={props.onChange}
              value={display.pendingTimeBeforeMeeting}
              fieldName="display.pendingTimeBeforeMeeting"
            />
          </FormGroup>
          <FormGroup>
            <LabelWithInformationBox
              title="Pending time after meeting"
              for="display.pendingTimeAfterMeeting"
            />
            <MinutesSelect
              onChange={props.onChange}
              value={display.pendingTimeAfterMeeting}
              fieldName="display.pendingTimeAfterMeeting"
            />
          </FormGroup>

          <FormGroup className="position-relative RoomForm__emailReminder">
            <Input
              type="checkbox"
              id="checkinReminder"
              name="checkinReminder"
              data-cy="checkinReminder"
              checked={display.checkinReminder}
              onChange={(event) => {
                props.onChange("display.checkinReminder", event.target.checked);
              }}
            />
            <Label for="checkinReminder" className="checkbox_label">
              <span>Send email reminder notifications</span>
              <span className="checkbox_box"></span>
            </Label>
            <InformationBox
              message={tooltips.rooms.display.notificationReminder}
              direction="left"
            />
          </FormGroup>
        </Switch>
        <Switch
          title="Soon free"
          name="display.soonFree"
          value={display.soonFree}
          message={rooms.display.soonFree}
          onChange={(value) => props.onChange("display.soonFree", value)}
        />
        <Switch
          title="Energy save mode"
          name="display.soonFree"
          value={display.energySaveMode}
          message={rooms.display.energySave}
          onChange={(value) => props.onChange("display.energySaveMode", value)}
        />
        <Switch
          title="Contactless QR booking"
          name="display.contactlessQR"
          value={isBussinesOrPro ? display.qrCodeRequired : false}
          message={rooms.display.contactlessQR}
          onChange={(value) => props.onChange("display.qrCodeRequired", value)}
          icon={props.downloadQRicon}
          disabled={!isBussinesOrPro}
        />

        <Switch
          title="Content board"
          name="display.contentBoardActivated"
          value={isBussinesOrPro ? display.contentBoardActivated : false}
          message={rooms.display.contentBoardActivated}
          onChange={(value) =>
            props.onChange("display.contentBoardActivated", value)
          }
          disabled={!isBussinesOrPro}
        >
          <FormGroup className="mt-2">
            <LabelWithInformationBox
              for="display.contentBoardURL"
              title="Content URL"
              message={rooms.display.contentBoardURL}
            />
            <Input
              type="text"
              id="display.contentBoardURL"
              onChange={handleChange}
              name="display.contentBoardURL"
              placeholder="Add public URL"
              value={
                isBussinesOrPro
                  ? display.contentBoardURL
                  : "Upgrade your plan to access this feature!"
              }
              disabled={!isBussinesOrPro}
              onBlur={props.formik.handleBlur}
              isTouched={touched.display?.contentBoardURL}
              errorMessage={errors.display?.contentBoardURL}
              invalid={
                !!errors.display?.contentBoardURL &&
                touched.display?.contentBoardURL
              }
            />
            <span className="label__below pt-2">
              Images and webpages supported.
            </span>
          </FormGroup>
        </Switch>

        <Switch
          title="Enable screen lock"
          name="display.enableScreenLock"
          message={tooltips.rooms.display.enableScreenLock}
          value={display.enableScreenLock}
          onChange={(value) =>
            props.onChange("display.enableScreenLock", value)
          }
        >
          <FormGroup>
            <LabelWithInformationBox
              title="Screen lock (4 digits 0-9)"
              for="display.screenLock"
              message={tooltips.rooms.display.screenLock}
            />
            <Input
              id="display.screenLock"
              name="display.screenLock"
              value={display.screenLock}
              onBlur={props.formik.handleBlur}
              isTouched={touched.display?.screenLock}
              errorMessage={errors.display?.screenLock}
              onChange={handleChange}
              invalid={
                !!errors.display?.screenLock && touched.display?.screenLock
              }
            />
          </FormGroup>
        </Switch>
      </FormSubSection>
      {/* <FormGroup>
        <LabelWithInformationBox
          title="Text Scaling"
          for="display.textScaling"
        />
        <Select
          isClearable
          isSearchable={true}
          theme={selectStyles}
          name="display.textScaling"
          options={[
            { value: "small", label: "Small" },
            { value: "normal", label: "Normal" },
            { value: "large", label: "Large" },
          ]}
          onChange={(e) => {
            if (!e || e.value === null) {
              return;
            }

            props.onChange("display.textScaling", e.value);
          }}
          defaultValue={{
            label: "Normal",
            value: "normal",
          }}
          value={{
            label: _.startCase(display.textScaling),
            value: display.textScaling,
          }}
        />
      </FormGroup> */}
    </FormSection>
  );
};
