//this function is helper that get a date as string for example "2022-06-10"
// then it attaches hour and mintues which user chooses from another field

// we have to use parseIso, because if we use new Date, sometimes it takes yesterday
import { set, format, parseISO, addDays } from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

export const attachHoursToDate = (
  dateStr: string,
  hoursAndMinutes: Date,
  timeZone?: string,
  allDay?: boolean
) => {
  const date = parseISO(dateStr);
  const hours = hoursAndMinutes.getHours();
  const minutes = hoursAndMinutes.getMinutes();

  const definedDate = allDay ? addDays(date, 1) : date;
  const combinedDate = set(definedDate, { hours, minutes });

  if (timeZone) {
    return zonedTimeToUtc(combinedDate, timeZone).toISOString();
  }

  return combinedDate.toISOString();
};

export const dateToHoursAndMinutes = (date: Date, timeZone?: string) => {
  if (timeZone) {
    return formatInTimeZone(date, timeZone, "HH:mm");
  }
  return format(date, "HH:mm");
};
