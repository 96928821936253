import React, { useMemo } from "react";
import Select from "react-select";
import { FormGroup } from "reactstrap";

import { useRoomsQuery } from "../../../lib/hooks/useRoomsQuery";

import { tooltips } from "../../../lib/utils/tooltips";
import { selectStyles } from "../../../lib/utils/selectStyles";

import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { DeviceWorkspaceType } from "../../shared/Forms/DeviceForm/EditDeviceForm";
import { InputDescription } from "../../shared/InputDescription/InputDescription";

interface Props {
  value?: string;
  onChange: (value: string | null, type: DeviceWorkspaceType) => void;
  isDisabled: boolean;
}

export const RoomSelect = (props: Props) => {
  const { value, onChange, isDisabled } = props;
  const { loading, error, data } = useRoomsQuery();

  const options = useMemo(() => {
    return (
      data
        .filter((room) => room.devices.length < 3)
        .map((item) => ({ label: item.name, value: item.id })) || []
    );
  }, [data]);

  const selectValue =
    value && data
      ? data
          .filter((item) => item.id === value)
          .map((item) => ({ label: item.name, value: item.id }))
      : [];

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<h5>Loading...</h5>}
    >
      <ApolloErrorGuard
        error={error}
        errorComponent={<h4>Error fetching room resources...</h4>}
      >
        <FormGroup>
          <LabelWithInformationBox
            for="room"
            title="Room"
            direction="left"
            message={tooltips.devices.room}
          />
          <Select
            name="room"
            isClearable
            options={options}
            value={selectValue}
            theme={selectStyles}
            isDisabled={isDisabled}
            onChange={(e) => {
              if (!e) {
                onChange(null, "roomId");
                return;
              }

              onChange(e.value, "roomId");
            }}
          />

          <InputDescription
            title="Note!"
            description="Rooms that already have 3 devices assigned, won't show."
            titleColor="theme"
          />
        </FormGroup>
      </ApolloErrorGuard>
    </HandleLoadingState>
  );
};
