import React from "react";

import { useInsightsContext } from "../../../Context/InsightsContext";
import { useUtilizationInsightsContext } from "../../../Context/Room/Utilization/UtilizationContext";

import { ChartCard } from "../../../shared/ChartCard/ChartCard";
import { UtilizationOccupancyTotal } from "./UtilizationOccupancyTotal/UtilizationOccupancyTotal";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { UtilizationOccupancyByDayOfWeek } from "./UtilizationOccupancyByDayOfWeek/UtilizationOccupancyByDayOfWeek";
import { UtilizationOccupancyMostLeast } from "./UtilizationOccupancyMostLeast/UtilizationOccupancyMostLeast";
import { UtilizationOccupancyRoomSeating } from "./UtilizationOccupancyRoomSeating/UtilizationOccupancyRoomSeating";
import { tooltips } from "../../../../../lib/utils/tooltips";

import "./UtilizationOccupancy.scss";

export const UtilizationOccupancy = () => {
  const {
    loading,
    error,
    totalOccupancy: data,
  } = useUtilizationInsightsContext();
  const {
    insightsLocation: { locationType },
  } = useInsightsContext();

  return (
    <>
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <div className="mt-3 WorkspaceValueInsights__statistics">
            <LoadingBox minHeight={170} />
            <LoadingBox minHeight={170} />
            <LoadingBox minHeight={170} />
            <LoadingBox minHeight={170} />
          </div>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>{error?.message}</CenteredBox>}
        >
          <div className="UtilizationOccupancy WorkspaceValueInsights__statistics">
            <ChartCard
              title="Total occupancy (%)"
              message={tooltips.insights.rooms.totalOccupancy}
              direction="right"
              className="ChartCard__occupancy"
            >
              <UtilizationOccupancyTotal
                value={Math.round(data?.totalOccupancyPercentage || 0)}
              />
              <span className="UtilizationOccupancy__info">
                Adjusted for actual work hours
              </span>
            </ChartCard>

            <ChartCard
              title="Occupancy by Day of Week"
              message={tooltips.insights.rooms.occupancyByDay}
              className="ChartCard__occupancy"
            >
              <UtilizationOccupancyByDayOfWeek
                data={data?.dayOfWeekOccupancy || []}
              />
            </ChartCard>

            {locationType !== "Room" && (
              <ChartCard
                title="Most vs Least Occupied"
                message={tooltips.insights.rooms.mostVsLeastOccupied}
                direction="right"
                className="ChartCard__occupancy"
              >
                {!!data?.roomsStats.length ? (
                  <UtilizationOccupancyMostLeast data={data.roomsStats} />
                ) : (
                  <CenteredBox className="CenteredBox__disableHeight">
                    Empty data
                  </CenteredBox>
                )}
              </ChartCard>
            )}

            <ChartCard
              title="Occupancy by Room Seating"
              message={tooltips.insights.rooms.occupancyByRoomSettings}
              className="ChartCard__occupancy"
            >
              {!!data?.roomsSeatsStats.length ? (
                <>
                  <UtilizationOccupancyRoomSeating
                    data={data.roomsSeatsStats}
                  />
                  <span className="UtilizationOccupancy__info UtilizationOccupancyRoomSeating__info">
                    This performance is on par with other organizations
                  </span>
                </>
              ) : (
                <CenteredBox className="CenteredBox__disableHeight">
                  Empty data
                </CenteredBox>
              )}
            </ChartCard>
          </div>
        </HandleRequestState>
      </HandleLoadingState>
    </>
  );
};
