import React, { useState } from "react";
import { toast } from "react-toastify";

import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../../lib/context/Customer/CustomerContext";
import { useBuildingFormik } from "../../../../shared/Forms/BuildingForm/lib/useBuildingFormik";

import { WorkplaceModalProps } from "../AddWorkplaceModal";

import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";
import { BuildingRequestVariables } from "../../../../../api/graphql/buildings/buildings";
import { BuildingForm } from "../../../../shared/Forms/BuildingForm/BuildingForm";

export const AddBuildingModal = (props: WorkplaceModalProps) => {
  const { isOpen, parentType, parentId, toggle } = props;
  const [loading, setLoading] = useState(false);
  const { addBuilding } = useWorkplaceRequests();
  const { retrieveTenant } = useCustomerContext();

  const formik = useBuildingFormik({
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const { id, tenantId } = await retrieveTenant();

        const variables: BuildingRequestVariables = {
          id: parentId || id,
          tenantId,
          name: values.name,
          tags: values.tags,
          description: values.description,
        };

        await addBuilding(variables, parentType);

        toggle();
        toast.success("Building added successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Building couldn't be added, please reload and try again!"
        );
        setLoading(false);
        console.error(error.message);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Building"
      onSubmit={formik.handleSubmit}
    >
      <BuildingForm formik={formik} informationBoxDirection="left" />
    </ModalSidebar>
  );
};
