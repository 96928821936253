import React, { useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { useSubscriptionRequests } from "../../../api/grpc/subscription/useSubscriptionRequests";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useCheckUserPermissions } from "../../../lib/hooks/useCheckUserPermissions";
import { routes } from "../../../lib/routes/";

import { SidebarMainMenuItem } from "./SidebarMainMenuItem/SidebarMainMenuItem";
import { RouteType } from "../../../lib/routes/routes";
import { ListCustomerSubscriptionResponse } from "../../../api/grpc/subscription/subscription";
import { CustomerSubscriptions } from "../../Billings/CustomerSubscriptions/CustomerSubscriptions";
import { RolePermissions } from "../../../api/grpc/account/account";

import "./SidebarMainMenu.scss";

export const SidebarMainMenu = () => {
  const { user } = useAuthContext();
  const { checkUserPermission } = useCheckUserPermissions();
  const { listCustomerSubscription } = useSubscriptionRequests();

  const { data } = useGrpcQuery<{}, ListCustomerSubscriptionResponse>({
    method: listCustomerSubscription,
    variables: {},
  });

  const permissionMemo = useMemo(
    () => ({
      canViewChildSubscriptions: checkUserPermission(
        RolePermissions.ORGANIZATION_SETTINGS_SUBSCRIPTIONS_VIEW_EDIT
      ),
      allowedRoutes: routes.filter((route) =>
        checkUserPermission(route.permissions || [])
      ),
    }),
    [user?.permissions]
  );

  const childSubscriptions = data?.subscriptions.filter(
    (subscription) => subscription.customerId !== user?.customerid
  );

  const userNavigation = useMemo(() => {
    const copiedRoutes = [...permissionMemo.allowedRoutes];

    //the logic below is to add a conditional route if the user has child subscriptions, ticket: PLN:2409
    if (
      !!childSubscriptions?.length &&
      permissionMemo.canViewChildSubscriptions
    ) {
      const adminRouteIndex = copiedRoutes.findIndex(
        (route) => route.id === "settings-sidebar"
      );
      if (adminRouteIndex !== -1) {
        if (copiedRoutes[adminRouteIndex]?.menu !== undefined) {
          const customerSubsRoute = copiedRoutes[adminRouteIndex].menu?.find(
            (itm) => itm.id === "customerSubscriptions"
          );
          if (!customerSubsRoute) {
            const CustomerSubsComponent: React.FC = () => (
              <CustomerSubscriptions subscriptions={childSubscriptions} />
            );

            copiedRoutes[adminRouteIndex].menu = [
              ...(copiedRoutes[adminRouteIndex]?.menu || []),
              {
                exact: false,
                id: "customerSubscriptions",
                to: "/customer-subscriptions",
                label: "Customer Subscriptions",
                type: RouteType.PRIVATE,
                component: CustomerSubsComponent,
                icon: "teams_icon_18x18",
              },
            ];
          }
        }
      }

      return copiedRoutes.filter(
        (route) => route.type !== RouteType.PUBLIC && route.showInSidebar
      );
    }

    const adminRouteIndex = copiedRoutes.findIndex(
      (route) => route.id === "settings-sidebar"
    );

    if (adminRouteIndex !== -1) {
      copiedRoutes[adminRouteIndex].menu = [
        ...(copiedRoutes[adminRouteIndex].menu?.filter(
          (item) => item.id !== "customerSubscriptions"
        ) || []),
      ];
    }

    return copiedRoutes.filter(
      (route) => route.type !== RouteType.PUBLIC && route.showInSidebar
    );
  }, [user, childSubscriptions]);

  return (
    <PerfectScrollbar>
      <ul className="SidebarMainMenu">
        {userNavigation.map((p, index) => {
          return (
            <SidebarMainMenuItem
              to={p.to}
              id={p.id}
              icon={p.icon}
              label={p.label}
              hasMenu={!!p.menu}
              disabled={p.disabled || false}
              key={`${p.id}-submenu-${index}`}
            />
          );
        })}
      </ul>
    </PerfectScrollbar>
  );
};
