import { useMemo } from "react";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

import { useAuthContext } from "../context/Auth/AuthContext";

export const useCreateApolloClient = () => {
  const { user, getTokenHeader } = useAuthContext();

  const client = useMemo(() => {
    if (!user) {
      return null;
    }

    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_WORKPLACES_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
      },
    }) as HttpLink;

    const authLink = setContext((_, { headers }) => {
      const contextHeaders = getTokenHeader()
        .then((token) => ({
          headers: {
            ...headers,
            jwt: token,
          },
        }))
        .catch((error) => console.error(error));

      return contextHeaders || {};
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [user]);

  return {
    client,
  };
};
