import React from "react";
import cs from "classnames";

import { usePaginationContext } from "../../../lib/context/Pagination/PaginationContext";

import { PaginationList } from "./PaginationList/PaginationList";
import { PaginationSelect } from "./PaginationSelect/PaginationSelect";

import "./Pagination.scss";

interface Props {
  className?: string;
}

export const Pagination = ({ className }: Props) => {
  const { total } = usePaginationContext();

  const classes = cs("Pagination", className);

  return (
    <div className={classes}>
      {total > 1 && <PaginationList />}
      <PaginationSelect />
    </div>
  );
};
