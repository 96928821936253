import React, { useMemo, useState } from "react";
import Select from "react-select";
import { People, Person } from "../../../../api/grpc/contacts/contacts";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { UserModal } from "../../shared/UserModal/UserModal";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";

interface Props {
  loading: boolean;
  error: any;
  data?: People;
  bookOnBehalf?: boolean;
}

export const SelectPeopleList = ({
  loading,
  error,
  data,
  bookOnBehalf,
}: Props) => {
  const [selectedUser, setSelectedUser] = useState<Person | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { setBookOnBehalfUser } = useBookingsMapContext();

  const options = useMemo(() => {
    return data?.people.map((item) => ({
      value: item,
      label: item.displayName,
    }));
  }, [data]);

  const handleSelectChange = (person: Person | null) => {
    if (!person) {
      setSelectedUser(null);
      return;
    }

    setSelectedUser(person);

    if (bookOnBehalf) {
      setBookOnBehalfUser(person);
    } else {
      setIsOpen(true);
    }
  };

  const selectedValue = useMemo(() => {
    if (!selectedUser) {
      return null;
    }

    return {
      label: selectedUser.displayName,
      value: selectedUser,
    };
  }, [selectedUser]);

  return (
    <>
      {loading ? (
        <LoadingBox minHeight={50} />
      ) : error ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <Select
          className="FindPeople FindPeopleSearchDesk no-min-height"
          isClearable
          options={options}
          placeholder={bookOnBehalf ? "Search user..." : "Find people"}
          onChange={(e) => handleSelectChange(e?.value || null)}
          value={selectedValue}
        />
      )}

      {isOpen && (
        <UserModal
          person={selectedUser}
          isOpen={isOpen}
          handleModalClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
