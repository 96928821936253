import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { BookingsSearch } from "./BookingsSearch/BookingsSearch";
import { useRouter } from "../../lib/hooks/useRouter";

import { BookingsMain } from "./BookingsMain";
import { FloorMain } from "../../views/Manage/Floor/FloorMain/FloorMain";
import { BookingsMapSingle } from "./BookingsMap/BookingsMapSingle/BookingsMapSingle";

export const BookingsRoutes = () => {
  const { match } = useRouter();

  const bookingsRoutes = [
    {
      to: match.url,
      exact: true,
      label: "BookingsMain",
      component: BookingsMain,
      id: "bookings-main",
    },
    {
      to: `${match.url}/search/:type`,
      label: "BookingsSearch",
      component: BookingsSearch,
      id: "bookings-search",
    },
    {
      exact: true,
      to: `${match.url}/map`,
      label: "BookingsMap",
      component: FloorMain,
      id: "bookings-map",
    },
    {
      to: `${match.url}/map/:id`,
      label: "BookingsMapSingle",
      component: BookingsMapSingle,
      id: "bookings-map-single",
    },
  ];

  return (
    <Switch>
      {bookingsRoutes.map((item) => (
        <Route
          key={item.id}
          path={item.to}
          component={item.component}
          exact={item.exact ? true : undefined}
        />
      ))}
      <Redirect to={bookingsRoutes[0].to} />
    </Switch>
  );
};
