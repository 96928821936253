import React from "react";
import { Icon } from "../Icon/Icon";

import "./DeleteButton.scss";

interface Props {
  onClick: () => void;
}

export const DeleteButton = (props: Props) => {
  return (
    <div className="DeleteButton" onClick={() => props.onClick()}>
      <Icon icon="delete-icon" />
    </div>
  );
};
