import React, { useEffect, useState } from "react";
import { useTemplatesClient } from "../../../../api/grpc/workplaces/useTemplatesClient";
import { RoomTemplate } from "../../../../api/grpc/workplaces/workplaces";
import {
  RoomTemplateContext,
  IRoomTemplateContext,
} from "./RoomTemplateContext";

interface Props {
  children: React.ReactNode;
}

export const RoomTemplateContextProvider = ({ children }: Props) => {
  const [roomTemplates, setRoomTemplates] = useState<RoomTemplate[]>([]);
  const [loadingRoomTemplate, setLoadingRoomTemplate] = useState(false);
  const [errorRoomTemplates, setErrorRoomTemplates] = useState<
    string | undefined
  >(undefined);
  const { listRoomTemplates } = useTemplatesClient();

  useEffect(() => {
    fetchRoomTemplates();
  }, []);

  const fetchRoomTemplates = async () => {
    try {
      setLoadingRoomTemplate(true);

      const {
        response: { results },
      } = await listRoomTemplates();

      setRoomTemplates(results);
    } catch (error: any) {
      setErrorRoomTemplates(error?.message);
    } finally {
      setLoadingRoomTemplate(false);
    }
  };

  const context: IRoomTemplateContext = {
    roomTemplates,
    errorRoomTemplates,
    loadingRoomTemplate,
    refetchRoomTemplates: fetchRoomTemplates,
  };

  return (
    <RoomTemplateContext.Provider value={context}>
      {children}
    </RoomTemplateContext.Provider>
  );
};
