import React, { PropsWithChildren, useState } from "react";
import { toast } from "react-toastify";
import cs from "classnames";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useAccountRequests } from "../../../../api/grpc/account/useAccountRequests";

import { Button } from "../../../shared/Button/Button";
import { MfaTypePicker } from "../Modal/ModalMFASteps/MfaTypePicker";
import { MfaQRCode } from "../Modal/ModalMFASteps/MfaQRCode";
import {
  MfaModalKeys,
  useMfaContext,
} from "../../../../lib/context/MFA/MfaContext";
import { MfaReAuthUser } from "../Modal/ModalMFASteps/MfaReAuthUser";
import { MfaConfirm } from "../Modal/ModalResponse/MfaConfirm";
import { MfaDisabled } from "../Modal/ModalResponse/MfaDisabled";
import { MfaEnabled } from "../Modal/ModalResponse/MfaEnabled";
import { MfaError } from "../Modal/ModalResponse/MfaError";
import { MfaSecondaryEmail } from "../Modal/ModalMFASteps/MfaSecondaryEmail";
import { FormItem } from "../../../shared/FormItem/FormItem";
import { MFAType } from "../../../../api/grpc/account/account";

import "./EnableMFAOption.scss";

const modals: { [key in MfaModalKeys]: React.FunctionComponent } = {
  mfaPicker: MfaTypePicker,
  QRCode: MfaQRCode,
  authentication: MfaReAuthUser,
  confirm: MfaConfirm,
  enabled: MfaEnabled,
  disabled: MfaDisabled,
  error: MfaError,
  secondaryEmail: MfaSecondaryEmail,
};

export const EnableMFAOption = ({
  userEnrolled,
  ssoUser,
}: PropsWithChildren<{ userEnrolled: boolean; ssoUser: boolean }>) => {
  const { mfaModal, setMfaModal } = useMfaContext();
  const { user } = useAuthContext();
  const { getAccountMFA } = useAccountRequests();
  const [loading, setLoading] = useState(false);

  const classes = cs("ml-1 Status Status--active", {
    "Status--sso": ssoUser,
    "Status--active": userEnrolled,
    "Status--inactive": !userEnrolled,
  });

  const defineStatus = (): string => {
    if (ssoUser) {
      return "SSO user - 2FA managed by IAM";
    }

    if (userEnrolled) {
      return "ON";
    }

    return "OFF";
  };

  const Modal = modals[mfaModal as MfaModalKeys];

  const handleMfaButton = async () => {
    try {
      setLoading(true);
      if (ssoUser) {
        return;
      }

      const { response } = await getAccountMFA();

      //this is an extra checker to handle ticket: PLN-3711
      if (response.mfaType !== user?.mfaType) {
        toast.error(
          `MFA is already ${
            response.mfaType === MFAType.MFA_TYPE_TOTP ? "enabled" : "disabled"
          }. Please log out of all active browser sessions and log in again.`
        );
        return;
      }

      setMfaModal("authentication");
      return;
    } catch (error) {
      console.log(error);
      toast.error("Failed to retrieve MFA status. Please contact support.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormItem
        title="Two-factor authentication"
        icon="key-icon"
        status={defineStatus()}
        statusClassName={classes}
        className="EnableMFAOption"
      >
        <div className="EnableMFAOption__info">
          {ssoUser ? (
            <span>
              Two-factor authentication for SSO users is managed by the calendar
              provider IAM. Since your account enforces SSO sign-in rather than
              user/password authentication, this option is not available.
              Instead, 2FA is supported via your calendar provider's IAM sign-in
              flow.
            </span>
          ) : (
            <span>
              By setting up MFA, you add an extra layer of security to your
              Goget <b>Owner/Admin</b> account sign-in. For example, you first
              enter your password and, when prompted, you also type a
              dynamically generated verification code provided by an
              authenticator app.
            </span>
          )}
        </div>

        <div>
          <Button
            title={userEnrolled ? "Disable" : "Enable"}
            size="small"
            color={userEnrolled ? "outline-secondary" : "secondary"}
            onClick={handleMfaButton}
            className={cs({ disabled: ssoUser })}
            disabled={loading || ssoUser}
            loadTitle="Processing..."
            loadSpinner={loading}
          />

          {userEnrolled &&
            (user?.recoveryEmail?.length ? (
              <span className="ml-4">Recovery email: {user.recoveryEmail}</span>
            ) : (
              <Button
                size="small"
                color="simple"
                title="Add secondary recovery email"
                onClick={() => setMfaModal("secondaryEmail")}
                className="ml-3"
              />
            ))}
        </div>
      </FormItem>

      {mfaModal !== undefined && <Modal />}
    </>
  );
};
