import { routes } from "./routes";

export const getMainSidebarItems = () => {
  return routes.map((item) => {
    return {
      id: item.id,
      to: item.to,
      hasMenu: !!item.menu,
    };
  });
};
