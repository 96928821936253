import React, { useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { useAccountRequests } from "../../../../../api/grpc/account/useAccountRequests";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { Icon } from "../../../../shared/Icon/Icon";
import { Input } from "../../../../shared/Input/Input";
import { ModalMFA } from "../ModalMFA";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const MfaSecondaryEmail = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const { setMfaModal } = useMfaContext();
  const { updateUser, user } = useAuthContext();
  const { setRecoveryEmail, updateAccount } = useAccountRequests();
  const { timeZone } = useTimeZoneContext();

  const handleSecondaryEmail = async () => {
    try {
      if (!user) {
        return;
      }
      setLoading(true);

      await setRecoveryEmail(secondaryEmail);

      const {
        response: { recoveryEmail },
      } = await updateAccount(
        {
          displayName: user.displayName,
          email: user.email.toLowerCase(),
          firstName: user.firstName,
          lastName: user.lastName,
          newPassword: "",
          phoneNumber: "",
          photoUrl: user.picture,
          googleAccountConnected: user.googleAccountConnected,
          o365AccountConnected: user.o365AccountConnected,
          googleAccountLinkedEmail: user.googleAccountLinkedEmail,
          o365AccountLinkedEmail: user.o365AccountLinkedEmail,
          timeZone,
          mfaType: user.mfaType,
          recoveryEmail: secondaryEmail,
        },
        ["recoveryEmail"]
      );

      updateUser({
        recoveryEmail: recoveryEmail,
      });

      setSuccess(true);
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalMFA
      title="Set up two-factor authentication"
      keyPoints={[
        "In case you lose your phone , or get locked out of your 2FA you can get a recovery link sent to your registered secondary email to disable it. If you want to change your secondary email you need to disenroll and create a new 2FA session.",
      ]}
      closeButton="Close"
      handleBackButton={() => setMfaModal(undefined)}
      handleNextButton={handleSecondaryEmail}
      disabledNext={loading || !emailRegex.test(secondaryEmail)}
      disabledBack={loading}
      nextButton={success ? "" : "Confirm"}
      className="MfaSecondaryEmail"
    >
      <FormGroup>
        <Label>Enter your secondary email</Label>
        <Input
          id="secondaryEmail"
          name="secondaryEmail"
          value={secondaryEmail}
          onChange={(e) => setSecondaryEmail(e.target.value)}
          className="MfaSecondaryEmail__secondaryEmail"
        />
        {success && (
          <div className="MfaSecondaryEmail__success flex-a-center">
            <Icon icon="check-icon" />
            <span>Secondary email added. You can now close this window.</span>
          </div>
        )}
        {error && (
          <FormFeedback className="invalid-feedback">{error}</FormFeedback>
        )}
      </FormGroup>
    </ModalMFA>
  );
};
