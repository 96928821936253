import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input, InputGroup, Form, FormGroup, FormFeedback } from "reactstrap";
import {
  GENERAL_STRINGS as t,
  FROM_STRINGS as f,
} from "../../lib/utils/constants";
import { CenteredBox } from "../../components/shared/CenteredBox/CenteredBox";

const ContactSchema = Yup.object().shape({
  userName: Yup.string().min(2, f.validUserName).required(f.required),
  email: Yup.string().email(f.invalidEmail).required(f.required),
});

export const Contact = () => {
  const formik = useFormik({
    initialErrors: {},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ContactSchema,
    initialValues: { userName: "", email: "", message: "" },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const { values, handleChange, errors, handleBlur } = formik;

  return (
    <div className="Contact mt-5 mb-5">
      <CenteredBox constant={t.contactUs}>
        <Form
          className="Contact__form w-100 p-4"
          onSubmit={formik.handleSubmit}
        >
          <FormGroup>
            <Input
              type="text"
              id="userName"
              name="userName"
              onBlur={handleBlur}
              placeholder={t.name}
              value={values.userName}
              onChange={handleChange}
              invalid={!!errors.userName}
            />
            <FormFeedback>{errors.userName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              name="email"
              onBlur={handleBlur}
              value={values.email}
              placeholder={f.email}
              onChange={handleChange}
              invalid={!!errors.email}
            />
            {errors?.email && <FormFeedback>{errors.email}</FormFeedback>}
          </FormGroup>
          <InputGroup className="mb-3">
            <Input
              id="message"
              name="message"
              type="textarea"
              value={values.message}
              placeholder={f.message}
              onChange={handleChange}
            />
          </InputGroup>
          <button type="submit" className="btn btn-primary w-100">
            {f.submit}
          </button>
        </Form>
      </CenteredBox>
    </div>
  );
};
