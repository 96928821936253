import React from "react";
import cs from "classnames";

import { BookingTypes } from "../../../Bookings/BookingsSearch/BookingsSearch";

interface Props {
  type: BookingTypes;
  isCheckOut: boolean;
  allDay: boolean;
  startTime: string;
  endTime: string;
  status: string;
}

export const ReservationEventStatus = ({
  isCheckOut,
  startTime,
  allDay,
  endTime,
  status,
  type,
}: Props) => {
  const defineStatusName = () => {
    if (isCheckOut) {
      return "Checked out";
    }

    if (
      new Date() > new Date(startTime) &&
      new Date() < new Date(endTime) &&
      !allDay
    ) {
      return "Ongoing";
    }

    if (type === "room") {
      if (status === "Declined") {
        return "Room declined";
      }

      if (status === "Maybe") {
        return "Room awaiting approval";
      }
    }

    return "";
  };

  return (
    <span
      className={cs("ReservationEvent__status", {
        "ReservationEvent__status--checkOut": isCheckOut,
        "ReservationEvent__status--ongoing":
          new Date() > new Date(startTime) &&
          new Date() < new Date(endTime) &&
          !allDay,
        "ReservationEvent__status--decline": status === "Declined",
        "ReservationEvent__status--await": status === "Maybe",
      })}
    >
      {defineStatusName()}
    </span>
  );
};
