import { useMemo } from "react";
import { FloorChildrenType } from "../../Floors/lib/floorData";

export const useEmptyRoomsAndDesks = (
  roomsOfFloor: FloorChildrenType[],
  desksOfFloor: FloorChildrenType[],
  wayfindersOfFloor: FloorChildrenType[]
): boolean => {
  return useMemo(() => {
    return (
      roomsOfFloor.length === 0 &&
      desksOfFloor.length === 0 &&
      wayfindersOfFloor.length === 0
    );
  }, [roomsOfFloor, desksOfFloor]);
};
