import React, { PropsWithChildren, useRef, useState } from "react";
import { useTemplateContext } from "../../../../lib/context/Templates/Templates/TemplatesContext";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { TEMPLATE_ROOT_PATH } from "../../../../lib/routes/routes";
import { MainResources } from "../../../../lib/types/main.types";

import { DropdownList } from "../../../shared/DropdownList/DropdownList";
import { Icon } from "../../../shared/Icon/Icon";

const options = [
  {
    icon: "transfer-icon",
    label: "Apply",
    type: "apply",
  },
  {
    icon: "edit-icon",
    label: "Edit",
    type: "edit",
  },
  {
    icon: "delete-default",
    label: "Delete",
    type: "delete",
  },
];

export const ActionList = ({
  id,
  name,
  profileType,
  handleDeleteTemplate,
  handleEditTemplate,
}: PropsWithChildren<{
  id: string;
  name: string;
  profileType: MainResources;
  handleDeleteTemplate: () => Promise<void>;
  handleEditTemplate: () => void;
}>) => {
  const ref = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const { history } = useRouter();
  const { setActiveProfile } = useTemplateContext();

  useOutsideClickDetection(
    ref,
    () => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    },
    showDropdown
  );

  const handleClickOption = (type: string) => {
    if (type === "delete") {
      return handleDeleteTemplate();
    }

    if (type === "edit") {
      return handleEditTemplate();
    }

    if (type === "apply") {
      setActiveProfile({
        label: name,
        value: id,
        type: profileType,
        redirect: true,
      });
      history.push(`${TEMPLATE_ROOT_PATH}/transfer`);
      return;
    }
  };

  return (
    <div ref={ref} className="ActionList">
      <div>
        <Icon
          icon="actions-icon"
          onClick={() => setShowDropdown((prev) => !prev)}
        />
      </div>

      {showDropdown && (
        <DropdownList
          extended
          color="gray"
          options={options}
          onClick={(value) => handleClickOption(value)}
        />
      )}
    </div>
  );
};
