import { useEffect, useState } from "react";

import { useCalendarRequests } from "./useCalendarRequests";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";

import { EWSProvider } from "./calendarprovider";

export const useCalendarSettings = (providerId: string) => {
  const { retrieveEWSProviderConnection } = useCalendarRequests();
  const { providers, updateProviderSettings } = useCalendarContext();

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(() => {
    const provider = providers.find((item) => item.iD === providerId);

    return provider?.settings === undefined;
  });

  const [data, setData] = useState<EWSProvider | undefined>(() => {
    const provider = providers.find((item) => item.iD === providerId);

    return provider?.settings;
  });

  useEffect(() => {
    loadSettings();
  }, [providers]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadSettings = async () => {
    if (data !== undefined) {
      return;
    }

    const provider = providers.find((item) => {
      return item.iD === providerId;
    });

    if (!provider) {
      setLoading(false);
      return;
    }

    if (provider.settings) {
      setData(provider.settings);
      setLoading(false);
      return;
    }

    try {
      setError(false);

      const res = await retrieveEWSProviderConnection(providerId);

      updateProviderSettings(providerId, res.response);

      setLoading(false);
      setData(res.response);
    } catch (error: any) {
      setError(true);
      // console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    data,
  };
};
