import { Theme } from "react-select";

export const selectStyles = (theme: Theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary: "#dee2e6",
      primary25: "#2cd0af",
      neutral20: "#ebebeb",
    },
  };
};
