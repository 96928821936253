import React from "react";
import { NavLink } from "react-router-dom";
import { BILLING_ROOT_PATH } from "../../../lib/routes/routes";
import { Button } from "../Button/Button";

export const UpgradePlanButton = () => {
  return (
    <NavLink to={BILLING_ROOT_PATH}>
      <Button title="Upgrade plan" size="medium" color="primary" />
    </NavLink>
  );
};
