// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "pairing.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FieldMask } from "./google/protobuf/field_mask";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * @generated from protobuf message PairingModel
 */
export interface PairingModel {
    /**
     * The customer ID
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * Unix timestamp when the pairing was successfully paired
     *
     * @generated from protobuf field: int64 pairedOn = 2;
     */
    pairedOn: number;
    /**
     * The device Identifer
     *
     * @generated from protobuf field: string device_id = 3;
     */
    deviceId: string;
    /**
     * The assigned status of the device
     *
     * @generated from protobuf field: bool assigned = 4;
     */
    assigned: boolean;
    /**
     * Human readable name of device.
     *
     * @generated from protobuf field: string name = 5;
     */
    name: string;
}
/**
 * @generated from protobuf message GenerateRequest
 */
export interface GenerateRequest {
    /**
     * The client's device ID - for example RDX MAC ADDR
     *
     * @generated from protobuf field: string device_id = 1;
     */
    deviceId: string;
    /**
     * Type of paired device
     *
     * @generated from protobuf field: PairingDeviceType device_type = 2;
     */
    deviceType: PairingDeviceType;
}
/**
 * @generated from protobuf message GenerateResponse
 */
export interface GenerateResponse {
    /**
     * The code for pairing
     *
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * The time the pairing code expires unix timestamp (UTC)
     *
     * @generated from protobuf field: int64 expires_at = 2;
     */
    expiresAt: number;
    /**
     * Type of paired device
     *
     * @generated from protobuf field: PairingDeviceType device_type = 3;
     */
    deviceType: PairingDeviceType;
}
/**
 * @generated from protobuf message PairRequest
 */
export interface PairRequest {
    /**
     * The pairing code
     *
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * The customers ID
     *
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
    /**
     * Type of paired device
     *
     * @generated from protobuf field: PairingDeviceType device_type = 3;
     */
    deviceType: PairingDeviceType;
}
/**
 * @generated from protobuf message PairResponse
 */
export interface PairResponse {
    /**
     * The device ID that this pairing is connected to
     *
     * @generated from protobuf field: string device_id = 1;
     */
    deviceId: string;
    /**
     * The pairing timestamp
     *
     * @generated from protobuf field: google.protobuf.Timestamp paired_on = 2;
     */
    pairedOn?: Timestamp;
    /**
     * Type of paired device
     *
     * @generated from protobuf field: PairingDeviceType device_type = 3;
     */
    deviceType: PairingDeviceType;
}
/**
 * @generated from protobuf message ListRequest
 */
export interface ListRequest {
    /**
     * The customers ID
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * The device assigned status
     *
     * @generated from protobuf field: bool assigned = 2;
     */
    assigned: boolean;
}
/**
 * @generated from protobuf message ListResponse
 */
export interface ListResponse {
    /**
     * A list of pairings
     *
     * @generated from protobuf field: repeated PairingModel pairings = 1;
     */
    pairings: PairingModel[];
}
/**
 * @generated from protobuf message RetrieveRequest
 */
export interface RetrieveRequest {
    /**
     * The customers ID
     *
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * The device ID to retrieve
     *
     * @generated from protobuf field: string device_id = 2;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message RetrieveResponse
 */
export interface RetrieveResponse {
    /**
     * The pairing
     *
     * @generated from protobuf field: PairingModel pairing = 1;
     */
    pairing?: PairingModel;
}
/**
 * @generated from protobuf message DeleteRequest
 */
export interface DeleteRequest {
    /**
     * The pairing deviceID to be deleted
     *
     * @generated from protobuf field: string device_id = 1;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message UpdatePairingRequest
 */
export interface UpdatePairingRequest {
    /**
     * The pairing to update.
     *
     * @generated from protobuf field: PairingModel pairing = 1;
     */
    pairing?: PairingModel;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
     */
    updateMask?: FieldMask;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string device_id = 4;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message IsPairedRequest
 */
export interface IsPairedRequest {
    /**
     * @generated from protobuf field: string device_id = 1;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message IsPairedResponse
 */
export interface IsPairedResponse {
    /**
     * @generated from protobuf field: bool paired = 1;
     */
    paired: boolean;
}
/**
 * @generated from protobuf enum PairingDeviceType
 */
export enum PairingDeviceType {
    /**
     * @generated from protobuf enum value: PairingDeviceType_Unknown = 0;
     */
    PairingDeviceType_Unknown = 0,
    /**
     * @generated from protobuf enum value: PairingDeviceType_RDX = 1;
     */
    PairingDeviceType_RDX = 1,
    /**
     * @generated from protobuf enum value: PairingDeviceType_RFX = 2;
     */
    PairingDeviceType_RFX = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class PairingModel$Type extends MessageType<PairingModel> {
    constructor() {
        super("PairingModel", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pairedOn", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "assigned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PairingModel>): PairingModel {
        const message = { customerId: "", pairedOn: 0, deviceId: "", assigned: false, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PairingModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PairingModel): PairingModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* int64 pairedOn */ 2:
                    message.pairedOn = reader.int64().toNumber();
                    break;
                case /* string device_id */ 3:
                    message.deviceId = reader.string();
                    break;
                case /* bool assigned */ 4:
                    message.assigned = reader.bool();
                    break;
                case /* string name */ 5:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PairingModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* int64 pairedOn = 2; */
        if (message.pairedOn !== 0)
            writer.tag(2, WireType.Varint).int64(message.pairedOn);
        /* string device_id = 3; */
        if (message.deviceId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceId);
        /* bool assigned = 4; */
        if (message.assigned !== false)
            writer.tag(4, WireType.Varint).bool(message.assigned);
        /* string name = 5; */
        if (message.name !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PairingModel
 */
export const PairingModel = new PairingModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateRequest$Type extends MessageType<GenerateRequest> {
    constructor() {
        super("GenerateRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "device_type", kind: "enum", T: () => ["PairingDeviceType", PairingDeviceType] }
        ]);
    }
    create(value?: PartialMessage<GenerateRequest>): GenerateRequest {
        const message = { deviceId: "", deviceType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GenerateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateRequest): GenerateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* PairingDeviceType device_type */ 2:
                    message.deviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* PairingDeviceType device_type = 2; */
        if (message.deviceType !== 0)
            writer.tag(2, WireType.Varint).int32(message.deviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GenerateRequest
 */
export const GenerateRequest = new GenerateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateResponse$Type extends MessageType<GenerateResponse> {
    constructor() {
        super("GenerateResponse", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expires_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "device_type", kind: "enum", T: () => ["PairingDeviceType", PairingDeviceType] }
        ]);
    }
    create(value?: PartialMessage<GenerateResponse>): GenerateResponse {
        const message = { code: "", expiresAt: 0, deviceType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GenerateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateResponse): GenerateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int64 expires_at */ 2:
                    message.expiresAt = reader.int64().toNumber();
                    break;
                case /* PairingDeviceType device_type */ 3:
                    message.deviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int64 expires_at = 2; */
        if (message.expiresAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiresAt);
        /* PairingDeviceType device_type = 3; */
        if (message.deviceType !== 0)
            writer.tag(3, WireType.Varint).int32(message.deviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GenerateResponse
 */
export const GenerateResponse = new GenerateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PairRequest$Type extends MessageType<PairRequest> {
    constructor() {
        super("PairRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "device_type", kind: "enum", T: () => ["PairingDeviceType", PairingDeviceType] }
        ]);
    }
    create(value?: PartialMessage<PairRequest>): PairRequest {
        const message = { code: "", customerId: "", deviceType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PairRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PairRequest): PairRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* PairingDeviceType device_type */ 3:
                    message.deviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PairRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* PairingDeviceType device_type = 3; */
        if (message.deviceType !== 0)
            writer.tag(3, WireType.Varint).int32(message.deviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PairRequest
 */
export const PairRequest = new PairRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PairResponse$Type extends MessageType<PairResponse> {
    constructor() {
        super("PairResponse", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "paired_on", kind: "message", T: () => Timestamp },
            { no: 3, name: "device_type", kind: "enum", T: () => ["PairingDeviceType", PairingDeviceType] }
        ]);
    }
    create(value?: PartialMessage<PairResponse>): PairResponse {
        const message = { deviceId: "", deviceType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PairResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PairResponse): PairResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* google.protobuf.Timestamp paired_on */ 2:
                    message.pairedOn = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.pairedOn);
                    break;
                case /* PairingDeviceType device_type */ 3:
                    message.deviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PairResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* google.protobuf.Timestamp paired_on = 2; */
        if (message.pairedOn)
            Timestamp.internalBinaryWrite(message.pairedOn, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* PairingDeviceType device_type = 3; */
        if (message.deviceType !== 0)
            writer.tag(3, WireType.Varint).int32(message.deviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PairResponse
 */
export const PairResponse = new PairResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRequest$Type extends MessageType<ListRequest> {
    constructor() {
        super("ListRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "assigned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListRequest>): ListRequest {
        const message = { customerId: "", assigned: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRequest): ListRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* bool assigned */ 2:
                    message.assigned = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* bool assigned = 2; */
        if (message.assigned !== false)
            writer.tag(2, WireType.Varint).bool(message.assigned);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListRequest
 */
export const ListRequest = new ListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResponse$Type extends MessageType<ListResponse> {
    constructor() {
        super("ListResponse", [
            { no: 1, name: "pairings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PairingModel }
        ]);
    }
    create(value?: PartialMessage<ListResponse>): ListResponse {
        const message = { pairings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListResponse): ListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PairingModel pairings */ 1:
                    message.pairings.push(PairingModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated PairingModel pairings = 1; */
        for (let i = 0; i < message.pairings.length; i++)
            PairingModel.internalBinaryWrite(message.pairings[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListResponse
 */
export const ListResponse = new ListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveRequest$Type extends MessageType<RetrieveRequest> {
    constructor() {
        super("RetrieveRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveRequest>): RetrieveRequest {
        const message = { customerId: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RetrieveRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveRequest): RetrieveRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string device_id */ 2:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string device_id = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RetrieveRequest
 */
export const RetrieveRequest = new RetrieveRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveResponse$Type extends MessageType<RetrieveResponse> {
    constructor() {
        super("RetrieveResponse", [
            { no: 1, name: "pairing", kind: "message", T: () => PairingModel }
        ]);
    }
    create(value?: PartialMessage<RetrieveResponse>): RetrieveResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RetrieveResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveResponse): RetrieveResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PairingModel pairing */ 1:
                    message.pairing = PairingModel.internalBinaryRead(reader, reader.uint32(), options, message.pairing);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PairingModel pairing = 1; */
        if (message.pairing)
            PairingModel.internalBinaryWrite(message.pairing, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RetrieveResponse
 */
export const RetrieveResponse = new RetrieveResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRequest$Type extends MessageType<DeleteRequest> {
    constructor() {
        super("DeleteRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteRequest>): DeleteRequest {
        const message = { deviceId: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteRequest): DeleteRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteRequest
 */
export const DeleteRequest = new DeleteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePairingRequest$Type extends MessageType<UpdatePairingRequest> {
    constructor() {
        super("UpdatePairingRequest", [
            { no: 1, name: "pairing", kind: "message", T: () => PairingModel },
            { no: 2, name: "update_mask", kind: "message", T: () => FieldMask },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdatePairingRequest>): UpdatePairingRequest {
        const message = { customerId: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePairingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePairingRequest): UpdatePairingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PairingModel pairing */ 1:
                    message.pairing = PairingModel.internalBinaryRead(reader, reader.uint32(), options, message.pairing);
                    break;
                case /* google.protobuf.FieldMask update_mask */ 2:
                    message.updateMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.updateMask);
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                case /* string device_id */ 4:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePairingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* PairingModel pairing = 1; */
        if (message.pairing)
            PairingModel.internalBinaryWrite(message.pairing, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.FieldMask update_mask = 2; */
        if (message.updateMask)
            FieldMask.internalBinaryWrite(message.updateMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        /* string device_id = 4; */
        if (message.deviceId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdatePairingRequest
 */
export const UpdatePairingRequest = new UpdatePairingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsPairedRequest$Type extends MessageType<IsPairedRequest> {
    constructor() {
        super("IsPairedRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IsPairedRequest>): IsPairedRequest {
        const message = { deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IsPairedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsPairedRequest): IsPairedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsPairedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IsPairedRequest
 */
export const IsPairedRequest = new IsPairedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsPairedResponse$Type extends MessageType<IsPairedResponse> {
    constructor() {
        super("IsPairedResponse", [
            { no: 1, name: "paired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<IsPairedResponse>): IsPairedResponse {
        const message = { paired: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IsPairedResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsPairedResponse): IsPairedResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool paired */ 1:
                    message.paired = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsPairedResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool paired = 1; */
        if (message.paired !== false)
            writer.tag(1, WireType.Varint).bool(message.paired);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IsPairedResponse
 */
export const IsPairedResponse = new IsPairedResponse$Type();
/**
 * @generated ServiceType for protobuf service Pairing
 */
export const Pairing = new ServiceType("Pairing", [
    { name: "Generate", options: { "google.api.http": { post: "/v1/pairings:generate", body: "*" } }, I: GenerateRequest, O: GenerateResponse },
    { name: "Pair", options: { "google.api.http": { post: "/v1/customers/{customerId}/pairings:pair", body: "*" } }, I: PairRequest, O: PairResponse },
    { name: "UpdatePairing", options: { "google.api.http": { patch: "/v1/pairings", body: "*" }, "google.api.method_signature": ["pairing,update_mask"] }, I: UpdatePairingRequest, O: PairingModel },
    { name: "ListDevices", options: { "google.api.http": { get: "/v1/customers/{customerId}/pairings" }, "google.api.method_signature": ["parent"] }, I: ListRequest, O: ListResponse },
    { name: "Retrieve", options: { "google.api.http": { get: "/v1/customers/{customerId}/pairings/{deviceId}" }, "google.api.method_signature": ["customer_id,device_id"] }, I: RetrieveRequest, O: RetrieveResponse },
    { name: "Delete", options: { "google.api.http": { delete: "/v1/customers/{customerId}/pairings/{deviceId}" }, "google.api.method_signature": ["customer_id,device_id"] }, I: DeleteRequest, O: Empty },
    { name: "IsPaired", options: { "google.api.http": { get: "/v1/pairings/{deviceId}" }, "google.api.method_signature": ["device_id"] }, I: IsPairedRequest, O: IsPairedResponse }
]);
