import React from "react";
import { Form, FormGroup } from "reactstrap";

import { EWSFormik } from "../lib/useEWSFormik";
import { Input } from "../../shared/Input/Input";
import { PasswordInput } from "../../shared/PasswordInput/PasswordInput";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { tooltips } from "../../../lib/utils/tooltips";
import { Icon } from "../../shared/Icon/Icon";
import { Switch } from "../../shared/Switch/Switch";

interface Props {
  formik: EWSFormik;
  type?: string;
  isVerify?: boolean;
  email?: string;
}

export const EWSServiceForm = (props: Props) => {
  const { formik, type, isVerify, email } = props;

  let isPersonalIntegration = type === "personal";

  return (
    <>
      {isVerify && (
        <div className="flex-a-center mb-4">
          <Icon icon="exchange_ews" />
          <span className="pl-2">{email ?? ""}</span>
        </div>
      )}
      <Form className="EWSServiceForm">
        {!isVerify && (
          <FormGroup>
            <LabelWithInformationBox
              for="name"
              title="Name"
              message={
                isPersonalIntegration
                  ? tooltips.personalCalendar.personalEWSName
                  : tooltips.calendars.ews.name
              }
            />
            <Input
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isTouched={formik.touched.name}
              errorMessage={formik.errors.name}
              invalid={!!formik.errors.name && formik.touched.name}
            />
          </FormGroup>
        )}
        <FormGroup>
          <LabelWithInformationBox
            for="domain"
            title="Exchange EWS URL"
            message={
              isPersonalIntegration
                ? tooltips.personalCalendar.personalEWSUrl
                : tooltips.calendars.ews.url
            }
          />
          <Input
            type="text"
            id="domain"
            name="domain"
            onBlur={formik.handleBlur}
            value={formik.values.domain}
            onChange={formik.handleChange}
            isTouched={formik.touched.domain}
            errorMessage={formik.errors.domain}
            invalid={!!formik.errors.domain && formik.touched.domain}
          />
        </FormGroup>
        <FormGroup>
          <LabelWithInformationBox
            for="svcAccount"
            title={
              isPersonalIntegration
                ? "Username (email or domain\\user)"
                : "Service account username"
            }
            message={
              isPersonalIntegration
                ? tooltips.personalCalendar.personalEWSUsername
                : tooltips.calendars.ews.username
            }
          />
          <Input
            type="text"
            id="svcAccount"
            name="svcAccount"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.svcAccount}
            isTouched={formik.touched.svcAccount}
            errorMessage={formik.errors.svcAccount}
            invalid={!!formik.errors.svcAccount && formik.touched.svcAccount}
          />
        </FormGroup>
        <FormGroup>
          <LabelWithInformationBox
            for="password"
            title={
              isPersonalIntegration ? "Password" : "Service account password"
            }
            message={
              isPersonalIntegration
                ? tooltips.personalCalendar.personalEWSPassword
                : tooltips.calendars.ews.password
            }
          />
          <PasswordInput
            id="password"
            name="password"
            placeholder="******"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            isTouched={formik.touched.password}
            errorMessage={formik.errors.password}
            invalid={!!formik.errors.password && formik.touched.password}
          />
        </FormGroup>
        {!isPersonalIntegration && (
          <Switch
            name="impersonationEnabled"
            value={formik.values.impersonationEnabled}
            title="Use Exchange impersonation (advanced)"
            message={tooltips.calendars.ews.impersonation}
            className="mb-4 EWSServiceForm__switch"
            onChange={(value) => {
              if (value === undefined) {
                return;
              }

              formik.setFieldValue("impersonationEnabled", value);
            }}
          />
        )}
      </Form>
    </>
  );
};
