import React, { useState, useCallback } from "react";
import Slider from "rc-slider";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";

import { useUploadImageContext } from "../UploadImageContext/UploadImageContext";

import "rc-slider/assets/index.css";
import "./CropImage.scss";

interface Props {
  image: string;
}

export const CropImage = (props: Props) => {
  const { zoom, setZoom, cropInformation } = useUploadImageContext();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      cropInformation.current = { croppedAreaPixels, croppedArea };
    },
    []
  );

  return (
    <div className="CropImage">
      <div className="CropImage__image">
        <Cropper
          showGrid
          aspect={1}
          maxZoom={2}
          crop={crop}
          zoom={zoom}
          cropShape="rect"
          image={props.image}
          objectFit="contain"
          onCropChange={setCrop}
          onZoomChange={setZoom}
          zoomWithScroll={false}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="CropImage__slider">
        <Slider min={1} max={2} step={0.1} onChange={setZoom} />
      </div>
    </div>
  );
};
