import React, { PropsWithChildren } from "react";
import { IconDefinition } from "../Icon/Icon.generated";
import { Icon } from "../Icon/Icon";

import "./PageDescription.scss";

export const PageDescription = ({
  icon,
  description,
  secondDescription,
}: PropsWithChildren<{
  icon?: IconDefinition;
  description: string;
  secondDescription?: string;
}>) => {
  return (
    <div className="PageDescription">
      <Icon className="pt-2 mr-2" icon={icon ?? "page-description"} />

      <div className="PageDescription__info">
        <span>{description}</span>
        {secondDescription && <span>{secondDescription}</span>}
      </div>
    </div>
  );
};
