import React from "react";
import { PageHeader } from "../../../../components/shared/PageHeader/PageHeader";
import { tooltips } from "../../../../lib/utils/tooltips";

export const DirectoryMain = () => {
  return (
    <div>
      <PageHeader
        title="Directory Sync"
        message={tooltips.directorySync.sync}
      />

      <h4 className="center-content DirectorySync__title">
        Select or add directory integration
      </h4>
    </div>
  );
};
