import React from "react";
import cs from "classnames";

interface Props {
  imageUrl?: string;
  name?: string;
  className?: string;
}

export const PersonCredentials = ({ imageUrl, name, className }: Props) => {
  const classes = cs(className);
  return (
    <>
      {imageUrl ? (
        <img src={imageUrl} alt="User" />
      ) : (
        <div className={classes}>{name?.charAt(0)}</div>
      )}
    </>
  );
};
