import React, { FC, useCallback } from "react";

import { Popup } from "../../shared/Popup/Popup";
import { FloorMapControlButton } from "../FloorMapControlButton/FloorMapControlButton";
import { RadioButton } from "../../shared/RadioButton/RadioButton";

import "./FloorMarkersSettings.scss";

const SCALE_VALUES: number[] = [1, 0.85, 0.7, 0.5];

type Props = {
  scaleFactor: number;
  onScaleFactorChange: (value: number) => void;
};

export const FloorMarkersSettings: FC<Props> = ({
  scaleFactor,
  onScaleFactorChange,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onScaleFactorChange(+e.target.value);
    },
    [onScaleFactorChange]
  );

  return (
    <Popup
      trigger={() => <FloorMapControlButton icon="scale" />}
      position="bottom left"
      title="Map marker scaling factor"
    >
      <div className="FloorMarkersSettings">
        {SCALE_VALUES.map((value) => (
          <RadioButton
            key={value.toString()}
            title={`${value}x`}
            value={value.toString()}
            onChange={handleChange}
            checked={scaleFactor === value}
            name="scale"
          />
        ))}
      </div>
    </Popup>
  );
};
