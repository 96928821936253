import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface AccountFields {
  firstName: string;
  lastName: string;
  displayName: string;
  currentPassword: string;
  email: string;
  newPassword: string;
  confirmPassword: string;
}

interface UseAccountFormOptions {
  initialValues: AccountFields;
  onSubmit: (
    values: AccountFields,
    formikHelpers: FormikHelpers<AccountFields>
  ) => Promise<any>;
}

const AccountSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  displayName: Yup.string().required("Display name is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email field is required!"),
  newPassword: Yup.string().min(
    8,
    "Password must be at least 8 characters long!"
  ),
  currentPassword: Yup.string().test(
    "passwords-exists",
    "Please enter your current password!",
    function (value) {
      if (this.parent.newPassword !== undefined) {
        return value !== undefined;
      }

      return true;
    }
  ),
  confirmPassword: Yup.string().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.newPassword === value;
    }
  ),
});

export type AccountFormik = ReturnType<typeof useAccountFormik>;

export const useAccountFormik = ({
  onSubmit,
  initialValues,
}: UseAccountFormOptions) => {
  return useFormik<AccountFields>({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AccountSchema,
    enableReinitialize: true,
    onSubmit: async (values, formikHelpers) => {
      await onSubmit(values, formikHelpers);
    },
  });
};
