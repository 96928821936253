import React from "react";
import { Form } from "reactstrap";

import { DisplaySettingsFormik } from "./lib/useDisplaySettingsForm";

import { MainSettings } from "./Sections/MainSettings";
import { DisplaySettings } from "./Sections/DisplaySettings";
import { ReservationSettings } from "./Sections/ReservationSettings";
import { CustomerSettings } from "../../../../api/grpc/customer/customer";

interface Props {
  roomId: string;
  formik: DisplaySettingsFormik;
  downloadQRicon?: JSX.Element;
  isBussinesOrPro?: boolean;
  currentCustomLanguageId?: string;
  displayEditWorkHours?: boolean;
  isProfile?: boolean;
  loading: boolean;
  error: any;
  workingHoursData?: CustomerSettings;
  refetch: () => void;
}

export const DisplaySettingsForm = (props: Props) => {
  const {
    roomId,
    formik,
    downloadQRicon,
    isBussinesOrPro,
    currentCustomLanguageId,
    displayEditWorkHours,
    isProfile,
    error,
    loading,
    refetch,
    workingHoursData,
  } = props;

  const handleFormikChange = (
    field: string,
    value: string | string[] | boolean | number | number[] | Date | null
  ) => {
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field);
  };

  return (
    <Form className="RoomForm">
      <MainSettings
        formik={formik}
        onChange={(key, value) => handleFormikChange(key, value)}
        isBussinesOrPro={isBussinesOrPro}
        roomId={roomId}
        currentCustomLanguageId={currentCustomLanguageId}
        className={isProfile ? "ProfilesForm" : undefined}
      />
      <DisplaySettings
        formik={formik}
        onChange={(key, value) => handleFormikChange(key, value)}
        downloadQRicon={downloadQRicon}
        isBussinesOrPro={isBussinesOrPro}
        className={isProfile ? "ProfilesForm" : undefined}
      />
      <ReservationSettings
        roomId={roomId}
        formik={formik}
        onChange={(key, value) => handleFormikChange(key, value)}
        isBussinesOrPro={isBussinesOrPro}
        displayEditWorkHours={displayEditWorkHours}
        className={isProfile ? "ProfilesForm" : undefined}
        isProfile={isProfile}
        error={error}
        loading={loading}
        refetch={refetch}
        workingHoursData={workingHoursData}
      />
    </Form>
  );
};
