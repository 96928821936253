import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { WayfinderFormFields } from "../../../../../api/graphql/wayfinder/wayfinder";

import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";

const WayFinderSchema = Yup.object().shape({
  name: Yup.string().min(2, f.validName).required(f.required),
});

interface UseWayFinderFormOptions {
  initialValues?: WayfinderFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: WayfinderFormFields,
    formikHelpers: FormikHelpers<WayfinderFormFields>
  ) => Promise<any>;
}

export type WayFinderFormik = ReturnType<typeof useWayFinderFormik>;

export const useWayFinderFormik = (opts: UseWayFinderFormOptions) => {
  return useFormik<WayfinderFormFields>({
    initialValues: {
      name: "",
      tags: [],
      description: "",
      desks: [],
      marker: { latitude: 0, longitude: 0 },
      rooms: [],
      licensed: false,
      distanceUnit: "METRE_M",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: WayFinderSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
