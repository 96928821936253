import { SettingsState } from "../ManageProfiles/ApplyProfiles/Steps/RoomStep/RoomSettingsStep";
import { SettingOption } from "./roomSettingsOptions";

export const collectSettingsFields = (
  settingsState: SettingsState,
  options: SettingOption[]
): string[] => {
  let fields: string[] = [];
  options.forEach((option) => {
    if (settingsState[option.value]) {
      fields.push(option.value);
      if (option.children?.length) {
        fields.push(...option.children);
      }
    }
  });
  return fields;
};
