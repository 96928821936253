import React from "react";
import { Button } from "reactstrap";

import appStore from "../../../assets/images/appstore.png";
import googlePlay from "../../../assets/images/google-play.png";
import googlePlayQR from "../../../assets/images/googleplay-QR.png";
import appStoreQR from "../../../assets/images/appstore-QR.png";

import "./AccountActivated.scss";

export const AccountActivated = () => {
  return (
    <div className="AccountActivated">
      <p>There are two options for reserving workspaces with GOGET:</p>
      <ul>
        <li>
          <p className="d-inline">Login to the </p>
          <a
            href="https://admin.gogetcorp.com/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Web Dashboard.
          </a>
        </li>
        <li>
          <p>
            Install the GOGET booking app on your smartphone with the link or QR
            code.
          </p>
        </li>
      </ul>
      <ul className="AccountActivated__platforms">
        <li>
          <div className="AccountActivated__icon">
            <img src={appStore} alt="Download on App Store" />
          </div>
          <div className="AccountActivated__qr-code">
            <img src={appStoreQR} alt="QR Code for App Store" />
          </div>
        </li>
        <li>
          <div className="AccountActivated__icon">
            <img src={googlePlay} alt="Download on Google Play" />
          </div>
          <div className="AccountActivated__qr-code">
            <img src={googlePlayQR} alt="QR Code for Google Play" />
          </div>
        </li>
      </ul>
      <Button color="primary" outline>
        It is recommended that you close this browser window
      </Button>
    </div>
  );
};
