import React, { useEffect } from "react";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";

import { CalendarsRoutes } from "./CalendarsRoutes";
import { CalendarSidebar } from "../../../components/Calendars/CalendarSidebar/CalendarSidebar";

export const Calendars = () => {
  const { refetchCalendars } = useCalendarContext();

  useEffect(() => {
    //this refetch is needed because of PLN-3230 ticket
    const refetchAllCalendars = async () => {
      return await refetchCalendars();
    };

    refetchAllCalendars();
  }, []);

  return (
    <div className="Calendars h-100 d-flex">
      <CalendarSidebar />

      <CalendarsRoutes />
    </div>
  );
};
