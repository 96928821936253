import React from "react";
import {
  HeatMapResponse,
  TotalOccupancyResponse,
} from "../../../../../api/grpc/insights/insights";

export interface UtilizationInsights {
  loading: boolean;
  error?: Error;
  totalOccupancy?: TotalOccupancyResponse;
  roomsDensity?: HeatMapResponse;
  loadingDensity: boolean;
  errorDensity?: Error;
}

const values: UtilizationInsights = {
  loading: false,
  error: undefined,
  totalOccupancy: undefined,
  loadingDensity: false,
  errorDensity: undefined,
  roomsDensity: undefined,
};

export const UtilizationInsightsContext = React.createContext(values);

export const useUtilizationInsightsContext = () => {
  const context = React.useContext(UtilizationInsightsContext);

  if (!context) {
    throw new Error(
      "useUtilizationInsightsContext must be used inside of UtilizationInsightsContext"
    );
  }

  return context;
};
