import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

import { useRouter } from "../../../lib/hooks/useRouter";

import { KeywordInput } from "../../shared/KeywordInput/KeywordInput";
import { ConfirmationModalState } from "../../../lib/context/Confirmation/ConfirmationContextProvider";

import "./ConfirmationModal.scss";

export const ConfirmationModal = (props: ConfirmationModalState) => {
  const { title, isOpen, description, keywordDelete, onCancel, onConfirm } =
    props;
  const { pathname } = useRouter();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    onCancel();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDisabled(!!keywordDelete);
  }, [isOpen, keywordDelete]);

  return (
    <Modal isOpen={isOpen} className="ConfirmationModal">
      <div className="modal-header ConfirmationModal__header">
        <h5 className="modal-title">{title}</h5>
      </div>
      {(description || keywordDelete) && (
        <ModalBody className="ConfirmationModal__body">
          {description && <p>{description}</p>}
          {keywordDelete && (
            <KeywordInput
              keyword={keywordDelete}
              onChange={(matched) => {
                setDisabled(!matched);
              }}
            />
          )}
        </ModalBody>
      )}
      <ModalFooter className="ConfirmationModal__footer d-flex">
        <Button
          outline
          type="submit"
          color="secondary"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          className="ml-2"
          disabled={disabled}
          onClick={() => onConfirm()}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};
