import L from "leaflet";

type DeskIconParams = {
  id: string;
  isBooked: boolean;
  isFocused: boolean;
  isBlocked: boolean;
  size: number;
};

const DeskIcon = ({
  isBooked,
  id,
  isFocused,
  isBlocked,
  size,
}: DeskIconParams) =>
  new L.DivIcon({
    html: `
      <svg viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter${id}0_d_3822_22716)">
      <circle cx="31" cy="31" r="21" fill="${isFocused ? "#00555E" : "white"}"/>
      </g>
      <circle cx="31" cy="31" r="15" fill="${
        isBlocked ? "#ADAEB9" : isBooked ? "#FF0000" : "#01EC96"
      }"/>
      ${
        isBlocked
          ? `
            <path opacity="0.5" d="M26.3794 25.2251C26.2282 25.079 26.0257 24.9982 25.8154 25C25.6052 25.0019 25.404 25.0862 25.2554 25.2349C25.1067 25.3836 25.0224 25.5847 25.0205 25.795C25.0187 26.0052 25.0995 26.2078 25.2456 26.359L29.8764 30.9901L25.2448 35.6213C25.1682 35.6952 25.1071 35.7837 25.0651 35.8816C25.0231 35.9794 25.001 36.0846 25 36.1911C24.9991 36.2976 25.0194 36.4032 25.0597 36.5017C25.1 36.6003 25.1596 36.6898 25.2349 36.7651C25.3102 36.8404 25.3997 36.9 25.4982 36.9403C25.5968 36.9806 25.7024 37.0009 25.8088 37C25.9153 36.999 26.0205 36.9769 26.1184 36.9349C26.2162 36.8929 26.3047 36.8318 26.3786 36.7552L31.0103 32.1241L35.6411 36.7552C35.7923 36.9013 35.9948 36.9821 36.2051 36.9803C36.4153 36.9784 36.6165 36.8941 36.7651 36.7454C36.9138 36.5967 36.9981 36.3956 37 36.1853C37.0018 35.9751 36.921 35.7725 36.7749 35.6213L32.1441 30.9901L36.7749 26.359C36.921 26.2078 37.0018 26.0052 37 25.795C36.9981 25.5847 36.9138 25.3836 36.7651 25.2349C36.6165 25.0862 36.4153 25.0019 36.2051 25C35.9948 24.9982 35.7923 25.079 35.6411 25.2251L31.0103 29.8562L26.3794 25.2251Z" fill="#666666"/>`
          : `
            <path d="M39.9966 29.6211C40.0063 29.5103 39.973 29.4001 39.9036 29.3133L36.625 25.4961H25.375L22.096 29.3132C22.0268 29.4001 21.9936 29.5104 22.0034 29.6211H22V29.9961H22.0038C22.0024 30.0484 22.0121 30.1004 22.0323 30.1487C22.0926 30.284 22.2269 30.3712 22.375 30.3711H24.25V38.2461C24.25 38.4532 24.4179 38.6211 24.625 38.6211H25C25.2071 38.6211 25.375 38.4532 25.375 38.2461V30.3711H26.5V34.4961C26.5 34.7032 26.6679 34.8711 26.875 34.8711H27.25C27.4571 34.8711 27.625 34.7032 27.625 34.4961V30.3711H34.75V34.4961C34.75 34.7032 34.918 34.8711 35.1251 34.8711H35.5C35.7071 34.8711 35.875 34.7032 35.875 34.4961V30.3711H37V38.2461C37 38.4532 37.1679 38.6211 37.375 38.6211H37.75C37.9571 38.6211 38.125 38.4532 38.125 38.2461V30.3711H39.625C39.7732 30.3712 39.9075 30.2841 39.9678 30.1487C39.9879 30.1004 39.9976 30.0484 39.9963 29.9961H40V29.6211H39.9966Z" fill="black" fill-opacity="0.5"/>
      `
      }
      <defs>
      <filter id="filter${id}0_d_3822_22716" x="0" y="0" width="62" height="62" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1${id}_dropShadow_3822_22716"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1${id}_dropShadow_3822_22716" result="shape"/>
      </filter>
      </defs>
      </svg>
  `,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    className: "",
  });

export { DeskIcon };
