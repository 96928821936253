import React, { useRef } from "react";
import { useCustomerContext } from "../../../lib/context/Customer/CustomerContext";

import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";

import { EmptyWorkplaces } from "../EmptyWorkplaces/EmptyWorkplaces";
import { WorkplacesDefaultList } from "../WorkplaceList/WorkplacesDefaultList/WorkplacesDefaultList";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";

const hasWorkplaces = (customer: any) => {
  if (!customer || customer.length > 0) {
    return false;
  }

  const {
    companies,
    sites,
    buildings,
    floors,
    zones,
    rooms,
    desks,
    wayfinders,
  } = customer;

  return (
    (companies && companies.length > 0) ||
    (sites && sites.length > 0) ||
    (buildings && buildings.length > 0) ||
    (floors && floors.length > 0) ||
    (zones && zones.length > 0) ||
    (rooms && rooms.length > 0) ||
    (desks && desks.length > 0) ||
    (wayfinders && wayfinders.length > 0)
  );
};

export const DefaultWorkplaces = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { loading, error, customer } = useCustomerContext();

  const hasCustomerWorkplaces = hasWorkplaces(customer);

  return (
    <div ref={ref} className="DefaultWorkplaces w-100 h-100">
      <HandleLoadingState
        loading={loading}
        fullWidth={true}
        loadingPlaceholder={<LoadingBox count={6} minHeight={60} />}
      >
        <ApolloErrorGuard
          error={error}
          errorComponent={<p>Data couldn't be loaded...</p>}
        >
          {!hasCustomerWorkplaces ? (
            <EmptyWorkplaces />
          ) : (
            <WorkplacesDefaultList />
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
