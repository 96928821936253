import React from "react";
import {
  DesksTotalOccupancyResponse,
  HeatMapResponse,
} from "../../../../../api/grpc/insights/insights";

export interface UtilizationInsights {
  loadingOccupancy: boolean;
  errorOccupancy?: Error;
  dataOccupancy?: DesksTotalOccupancyResponse;
  loadingHeatmap: boolean;
  errorHeatmap?: Error;
  dataHeatmap?: HeatMapResponse;
}

const values: UtilizationInsights = {
  loadingOccupancy: false,
  errorOccupancy: undefined,
  dataOccupancy: undefined,
  loadingHeatmap: false,
  errorHeatmap: undefined,
  dataHeatmap: undefined,
};

export const UtilizationInsightsContext = React.createContext(values);

export const useDeskUtilizationInsightsContext = () => {
  const context = React.useContext(UtilizationInsightsContext);

  if (!context) {
    throw new Error(
      "useDeskUtilizationInsightsContext must be used inside of UtilizationInsightsContext"
    );
  }

  return context;
};
