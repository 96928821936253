import React from "react";
import { Tags } from "../../../../api/grpc/workplaces/workplaces";
import { useBookingsContext } from "../../Context/BookingsContext";

import "./TagFilter.scss";

interface Props {
  data?: Tags;
  loading: boolean;
  error: any;
  onClick: (e: string) => void;
}

export const TagFilter = ({ loading, error, data, onClick }: Props) => {
  const { activeTagsFilter, handleActiveTagsFilter } = useBookingsContext();

  const handleTagClick = (tag: string) => {
    handleActiveTagsFilter(tag);

    return onClick(tag);
  };

  return (
    <div className="TagFilter">
      {loading ? (
        <div>Fetching tags...</div>
      ) : error ? (
        <div>Error fetching tags, please try again</div>
      ) : (
        <>
          {data && data.tags.length <= 0 ? (
            <div>This location has no added tags.</div>
          ) : (
            data?.tags.map((tag, idx) => (
              <div
                className={`TagFilter__item ${
                  activeTagsFilter.includes(tag) ? "active" : " "
                }`}
                onClick={() => handleTagClick(tag)}
                key={`${tag}-${idx}`}
              >
                {tag}
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};
