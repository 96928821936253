import React, { PropsWithChildren } from "react";
import { ResponsiveBar } from "@nivo/bar";

import { UtilizationData } from "../UtilizationOccupancyByDayOfWeek/UtilizationOccupancyByDayOfWeek";

export const UtilizationOccupancyRoomSeating = ({
  data,
}: PropsWithChildren<{
  data: UtilizationData[];
}>) => {
  const dataRoom = data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      value: Math.round(item.value),
      color:
        Math.round(item.value) >= 50 && Math.round(item.value) <= 80
          ? "#2CD0AF"
          : "#6B32D5",
    };
  });

  return (
    <ResponsiveBar
      data={dataRoom}
      indexBy="name"
      colors={{ datum: "data.color" }}
      margin={{ top: 15, right: 20, bottom: 90, left: 60 }}
      padding={0.83}
      maxValue={100}
      borderRadius={5}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        legendPosition: "middle",
      }}
      enableGridX={true}
      enableGridY={false}
      axisLeft={null}
      isInteractive={false}
      theme={{
        fontFamily: "Sofia Pro",
        fontSize: 13,

        grid: {
          line: {
            stroke: "#F1F5F5",
            strokeWidth: 9,
          },
        },
      }}
      role="application"
      layers={[
        "grid",
        "axes",
        "bars",
        "markers",
        "legends",
        "annotations",
        ({ bars }) => {
          return (
            <g>
              {bars.map(({ absX, data }, index) => {
                return (
                  <text
                    transform={`translate(${absX - 60}, -10)`}
                    textAnchor="right"
                    key={`${data}-${index}`}
                    dominantBaseline="central"
                    className="UtilizationOccupancyRoomSeating__values"
                  >{`${data.formattedValue + "%" ?? ""}`}</text>
                );
              })}
            </g>
          );
        },
      ]}
      markers={[
        {
          axis: "y",
          value: 80,
          lineStyle: {
            stroke: "rgba(0, 0, 0, .35)",
            strokeWidth: 1,
            strokeDasharray: "4 4",
            borderRadius: 5,
          },
          legend: "▼ 80%",
          legendOrientation: "horizontal",
          legendPosition: "left",
          textStyle: {
            fill: "#ADAEB9",
            fontSize: 11,
          },
        },
        {
          axis: "y",
          value: 65,
          lineStyle: {
            strokeWidth: 0,
          },
          legend: "Ideal",
          legendOrientation: "horizontal",
          legendPosition: "left",
          textStyle: {
            fill: "#ADAEB9",
            fontSize: 11,
          },
        },
        {
          axis: "y",
          value: 50,
          lineStyle: {
            stroke: "rgba(0, 0, 0, .35)",
            strokeWidth: 1,
            strokeDasharray: "4 4",
            borderRadius: 5,
          },
          legend: "▲ 50%",
          legendOrientation: "horizontal",
          legendPosition: "left",
          textStyle: {
            fill: "#ADAEB9",
            fontSize: 11,
          },
        },
      ]}
    />
  );
};
