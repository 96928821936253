import React, { useState } from "react";
import { utcToZonedTime } from "date-fns-tz";
import { toast } from "react-toastify";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useCheckOutRoom } from "../helpers/useCheckOutRoom";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";

import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";
import { SUCCESS_STRINGS } from "../../../../lib/utils/constants";

export const CheckOutModal = ({
  type,
  workplaceId,
  event,
  startTime,
  endTime,
  name,
  locationPath,
  isOpen,
  toggleModal,
  userName,
  refetchDesk,
  refetchRoom,
  disableEventActions,
  isAuthedUsersEvent,
  hideMeetingTitle,
  showAttendees,
}: ExtendedProps) => {
  const [loading, setLoading] = useState(false);
  const { timeZone } = useTimeZoneContext();
  const { checkOutDesk } = useDeskBookingRequest();
  const { checkOutRoom } = useCheckOutRoom();

  const { personalCalendar } = useCalendarContext();
  const definedUsername =
    !!personalCalendar?.length && isAuthedUsersEvent
      ? event?.organizer?.displayName || ""
      : userName;

  const handleCheckInDesk = async () => {
    try {
      setLoading(true);
      if (type === "desk") {
        await checkOutDesk(event?.metaData?.DESK_EVENT_ID);

        toast.success(SUCCESS_STRINGS.checkOutDone);

        if (refetchDesk) {
          refetchDesk();
        }

        return;
      }

      await checkOutRoom(workplaceId, event as RoomEvent, isAuthedUsersEvent);

      if (refetchRoom) {
        refetchRoom();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(
        error?.message || "Check-out failed, please reaload and try again"
      );
    }
  };

  return (
    <BookingsModal
      isOpen={isOpen}
      toggle={toggleModal}
      title="End now?"
      icon="check-out-reservation"
      workspaceType={type}
      workspaceName={name}
      day={useFormatReservationDate(
        utcToZonedTime(new Date(startTime), timeZone)
      )}
      // day={useFormatReservationDate(new Date(startTime))}
      startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
      endTime={
        type === "desk" && event.allDay === true
          ? "00:00"
          : dateToHoursAndMinutes(new Date(endTime), timeZone)
      }
      userName={definedUsername}
      locationPath={locationPath}
      type="check-out"
      buttonClick={handleCheckInDesk}
      buttonTitle="Delete"
      handleModalClose={toggleModal}
      classNames="modal-footer-row"
      subject={event?.title}
      disableButton={loading}
      allDay={event?.allDay}
      hideMeetingTitle={hideMeetingTitle}
      disableEventActions={disableEventActions}
      attendees={event.attendees}
      showAttendees={showAttendees}
    />
  );
};
