import React, { useRef, useState } from "react";
import { Form, FormGroup } from "reactstrap";

import { tooltips } from "../../../../lib/utils/tooltips";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import { toast } from "react-toastify";

import { Input } from "../../Input/Input";
import { Icon } from "../../Icon/Icon";
import { RoomSelect } from "../../../Workplaces/RoomSelect/RoomSelect";

import { EditDeviceFormik } from "./lib/useEditDeviceFormik";
import { LabelWithInformationBox } from "../../LabelWithInformationBox/LabelWithInformationBox";
import { WayfinderSelect } from "../../../Workplaces/WayfinderSelect/WayfinderSelect";
import { DeviceInformation } from "../../../Workplaces/EditWorkplace/EditDevice/types";
import { NameAndValueRow } from "../../NameAndValueRow/NameAndValueRow";
import { InputDescription } from "../../InputDescription/InputDescription";
import { DebugDeviceInfo } from "./DebugDeviceInfo";
import { UpdateDeviceVersion } from "./UpdateDeviceVersion";
import { Switch } from "../../Switch/Switch";

import "./DeviceForm.scss";

export type DeviceWorkspaceType = "roomId" | "wayFinderId";

interface Props {
  formik: EditDeviceFormik;
  isWayfinderDevice: boolean;
  deviceInfo: DeviceInformation | null;
  deviceId: string;
  workspaceId?: string;
  shouldUpdateDevice: boolean;
  appVersion?: string;
}

export const EditDeviceForm = (props: Props) => {
  const ref = useRef(null);

  const {
    formik,
    isWayfinderDevice,
    deviceInfo,
    deviceId,
    workspaceId,
    shouldUpdateDevice,
    appVersion,
  } = props;

  const { updateDeviceVersion } = useWorkplacesClient();

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleWorkspaceChange = (
    value: string | null,
    type: DeviceWorkspaceType
  ) => {
    formik.setFieldValue(type, value);
    formik.setFieldTouched(type);
  };

  useOutsideClickDetection(ref, () => {
    setIsOpen(false);
  });

  const handleUpdateDevice = async () => {
    if (!shouldUpdateDevice) {
      toast.warn("Device already running the latest version.");
      return;
    }

    try {
      setIsUpdating(true);

      await updateDeviceVersion({
        deviceId: deviceId,
      });

      toast.success(
        "Updating version of device. Note that this may take several hours to confirm."
      );
    } catch (error: any) {
      toast.error(error?.message ?? "Coulnd't update version of device!");
    } finally {
      setTimeout(() => {
        setIsUpdating(false);
      }, 2000);
    }
  };

  return (
    <Form className="DeviceForm">
      {/* <FormSection title="Settings"> */}
      <h6 className="mt-0 mb-4 DeviceForm__info">Settings</h6>

      <FormGroup>
        <LabelWithInformationBox
          for="name"
          title="Name"
          direction="left"
          message={tooltips.devices.name}
        />
        <Input
          id="name"
          type="text"
          name="name"
          value={formik.values.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.name}
          errorMessage={formik.errors.name}
          invalid={!!formik.errors.name && formik.touched.name}
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="description"
          title="Description"
          direction="left"
          message={tooltips.devices.description}
        />
        <Input
          type="text"
          id="description"
          name="description"
          onBlur={formik.handleBlur}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </FormGroup>
      {isWayfinderDevice ? (
        <WayfinderSelect
          value={formik.values.wayFinderId}
          onChange={handleWorkspaceChange}
          isDisabled={!!formik.values.roomId}
        />
      ) : (
        <RoomSelect
          value={formik.values.roomId}
          onChange={handleWorkspaceChange}
          isDisabled={!!formik.values.wayFinderId}
        />
      )}
      {/* </FormSection> */}

      {!!deviceInfo ? (
        <>
          <h6 className="DeviceForm__info">Software information</h6>
          {Object.entries(deviceInfo.softwareInfo).map(
            ({ "0": key, "1": value }) => (
              <NameAndValueRow key={key} name={key} value={value} />
            )
          )}

          <UpdateDeviceVersion
            shouldUpdate={shouldUpdateDevice}
            appVersion={appVersion ?? ""}
            handleUpdateDevice={handleUpdateDevice}
            isUpdating={isUpdating}
          />

          <Switch
            name="autoUpdate"
            title="Update automatically"
            direction="left"
            message={tooltips.devices.autoUpdate}
            value={formik.values.autoUpdate}
            className="DeviceForm__autoUpdate"
            onChange={(value) => {
              formik.setFieldValue("autoUpdate", value);
              formik.setFieldTouched("autoUpdate");
            }}
            paddingTop
          />

          <h6 className="DeviceForm__info">Hardware information</h6>
          {Object.entries(deviceInfo.hardwareInfo).map(
            ({ "0": key, "1": value }) => (
              <NameAndValueRow key={key} name={key} value={value} />
            )
          )}
        </>
      ) : (
        <>
          <h6 className="DeviceForm__info">Software information</h6>

          <InputDescription description="Unassigned or no data found." />

          <h6 className="DeviceForm__info">Hardware information</h6>

          <InputDescription description="Unassigned or no data found." />
        </>
      )}

      <FormGroup className="DeviceForm__debug position-relative">
        <div className="flex-a-center pt-2" ref={ref}>
          <div
            onClick={() => setIsOpen((prev) => !prev)}
            className="flex-a-center DebugDevice"
          >
            <Icon icon="debug-icon" />
            <span className="label__main pl-1">Debug device</span>
          </div>

          {isOpen && (
            <DebugDeviceInfo deviceId={deviceId} workspaceId={workspaceId} />
          )}
        </div>
      </FormGroup>
      {/* <FormSection title="Hardwarestatus">
        <FormGroup>
          <Label for="brand">Brand</Label>
          <Input readOnly type="text" id="brand" name="brand" value="Android" />
        </FormGroup>
        <FormGroup>
          <Label for="description">OS</Label>
          <Input
            readOnly
            type="text"
            id="description"
            name="description"
            value="Android 10"
          />
        </FormGroup>
      </FormSection> */}
    </Form>
  );
};
