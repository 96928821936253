import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useCompanyFormik } from "../../../shared/Forms/CompanyForm/lib/useCompanyFormik";

import { SubRouteProps } from "../../../../lib/routes/routes";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { CompanyRequestVariables } from "../../../../api/graphql/companies/companies";

import { tooltips } from "../../../../lib/utils/tooltips";
import { EditSettings } from "../EditSettings/EditSettings";
import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import { CompanyForm } from "../../../shared/Forms/CompanyForm/CompanyForm";
import { COMPANY_FIELDS_FRAGMENT } from "../../../../api/graphql/companies/companies";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";
import { EditViewsTabWorkspaces } from "../EditViewsTabWorkspaces/EditViewsTabWorkspaces";

export const EditCompany = (props: EditWorkplaceProps) => {
  const { id } = props;
  const { match } = useRouter();
  const client = useApolloClient();
  const { customer } = useCustomerContext();
  const { updateCompany } = useWorkplaceRequests();
  const [companyData, setCompanyData] = useState<WorkplaceFields | null>(null);

  // TO DO: Move on how the data are loaded to a hook useWorkplaceData
  const updateData = useCallback(() => {
    setCompanyData(
      client.readFragment({
        id: `Company:${id}`,
        fragmentName: "CompanyFields",
        fragment: COMPANY_FIELDS_FRAGMENT,
      })
    );
  }, [id]);

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useCompanyFormik({
    initialValues: {
      name: companyData?.name || "",
      tags: companyData?.tags || [],
      description: companyData?.description || "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const removedTags =
          companyData?.tags.filter((item) => !values.tags.includes(item)) || [];

        const variables: CompanyRequestVariables = {
          id: props.id,
          name: values.name,
          tags: values.tags,
          description: values.description,
          tenantId: customer.tenantId,
          remove: {
            tags: removedTags,
          },
        };

        await updateCompany(variables);

        toast.success("Company updated successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Company couldn't be updated, please reload and try again!"
        );
        console.error(error.message);
      }
    },
  });

  const rows: WorkplaceListData[] = [
    {
      data: companyData?.sites,
      type: "Site",
    },
    {
      data: companyData?.buildings,
      type: "Building",
    },
    {
      data: companyData?.floors,
      type: "Floor",
    },
    {
      data: companyData?.zones,
      type: "Zone",
    },
    {
      data: companyData?.rooms,
      type: "Room",
    },
    {
      data: companyData?.desks,
      type: "Desk",
    },
    {
      data: companyData?.wayfinders,
      type: "Wayfinder",
    },
  ];

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "View",
      to: match.url,
      id: "view-page-company",
      render: () => (
        <EditViewsTabWorkspaces type="Company" id={id} rows={rows} />
      ),
    },
    {
      exact: true,
      label: "Settings",
      id: "settings-page-company",
      to: `${match.url}/settings`,
      render: () => (
        <EditSettings
          title="General settings"
          // hasChanges={formik.dirty}
          onSubmit={formik.submitForm}
        >
          <CompanyForm formik={formik} />
        </EditSettings>
      ),
    },
  ];

  return (
    <EditWorkplaceWrapper
      type="Company"
      id={props.id}
      routes={routes}
      data={companyData || undefined}
      message={tooltips.workplaces.company}
    />
  );
};
