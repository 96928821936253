import React from "react";
import { toast } from "react-toastify";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useEWSFormik } from "../../../components/Calendars/lib/useEWSFormik";
import { useAuthContext } from "../Auth/AuthContext";
import { useCalendarContext } from "../Calendar/CalendarContext";
import { useValidPersonalToken } from "./ValidateTokenContext";

import { Icon } from "../../../components/shared/Icon/Icon";
import { CalendarCredentialsStatus } from "../../../api/grpc/calendarprovider/calendarprovider";
import { EWSServiceForm } from "../../../components/Calendars/CalendarForms/EWSServiceForm";
import { Button } from "../../../components/shared/Button/Button";

export const ValidateEWS = () => {
  const { personalCalendar, refetchPersonalCalendar } = useCalendarContext();
  const { user } = useAuthContext();
  const {
    setStatusOfToken,
    setOpenEWSForm,
    openReAuthModal,
    setOpenReAuthModal,
  } = useValidPersonalToken();
  const { updateEWSProvider } = useCalendarRequests();

  const formik = useEWSFormik({
    initialValues: {
      name: personalCalendar[0].name,
      domain: personalCalendar[0].hostUrl,
      svcAccount: personalCalendar[0].email,
      password: "",
      impersonationEnabled: false,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        if (!user || !values) {
          return;
        }

        await updateEWSProvider({
          customerID: user?.customerid || "",
          iD: personalCalendar[0].iD,
          ...values,
        });

        await refetchPersonalCalendar();

        setStatusOfToken(
          CalendarCredentialsStatus.CalendarCredentialsStatusValid
        );

        formikHelpers.resetForm();
        setOpenEWSForm(false);

        if (openReAuthModal) {
          setOpenReAuthModal(false);
        }

        toast.success(
          <div className="SuccessToast">
            <div className="flex-a-center">
              <Icon icon="check-icon" />
              <span className="ml-2">
                Re-authentication finished successfully!
              </span>
            </div>
          </div>,
          {
            position: toast.POSITION.TOP_LEFT,
          }
        );
        return;
      } catch (error: any) {
        toast.error(error?.message ?? "Calendar couldn't be re-authenticated!");
      }
    },
  });

  return (
    <>
      <EWSServiceForm
        formik={formik}
        type="personal"
        isVerify
        email={personalCalendar[0]?.email}
      />

      <div className="d-flex justify-content-center gap-1 pt-3 mt-4 border-top">
        <Button
          size="small"
          color="outline-secondary"
          title="Close"
          onClick={() => setOpenEWSForm(false)}
        />
        <Button
          size="small"
          title="Verify"
          color="primary"
          disabled={
            !formik.dirty ||
            (formik.dirty && !!Object.keys(formik.errors).length)
          }
          onClick={formik.submitForm}
        />
      </div>
    </>
  );
};
