import React from "react";
import { NavLink } from "react-router-dom";

import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";

import { trimText } from "../../../../lib/helpers/trimText";
import { getWorkplaceName } from "../../helpers/getWorkplaceInformation";

import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import { WORKPLACES_ROOT_PATH } from "../../../../lib/routes/routes";

import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";
import { LicenseSwitch } from "../../LicenseSwitch/LicenseSwitch";
import { DeleteButton } from "../../../shared/DeleteButton/DeleteButton";
import { WorkplacePrefixIcon } from "../WorkplacePrefixIcon/WorkplacePrefixIcon";
import { EntityType } from "../../../../api/grpc/subscription/subscription";

interface Props {
  onClose: () => void;
  data: WorkplaceFields[];
}

export const WorkplaceExpandedDesks = ({ data, onClose }: Props) => {
  const { deleteWorkplace } = useWorkplaceRequests();

  const workplaceName = getWorkplaceName("Desk", data.length);

  const columns: StructureTableColumn[] = [
    {
      key: "workplace_icon",
      header: workplaceName,
      onClick: () => onClose(),
    },
    {
      key: "workplace_name",
      header: "Name",
    },
    {
      key: "workplace_description",
      header: "Description",
    },
    {
      key: "workplace_tags",
      header: "Tags",
    },
    {
      key: "workplace_user",
      header: "Assigned User",
    },
    {
      key: "workplace_license",
      header: "License",
    },
    {
      key: "workplace_delete",
      header: "",
    },
  ];

  const rows: StructureTableRows[] = data.map((item) => {
    return {
      workplace_icon: <WorkplacePrefixIcon icon="desk_icon_48x48" />,
      workplace_name: (
        <NavLink to={`${WORKPLACES_ROOT_PATH}/desk/${item.id}`}>
          <h6 className="mb-0" title={item.name}>
            {item.name}
          </h6>
        </NavLink>
      ),
      workplace_description: (
        <h6 className="mb-0" title={item.description}>
          {trimText(item.description || "-")}
        </h6>
      ),
      workplace_tags: (
        <h6 className="mb-0" title={item.tags[0]}>
          {trimText(item.tags[0] || "-", 25)}
        </h6>
      ),
      workplace_user: (
        <h6 className="mb-0" title={item.account?.displayName}>
          {trimText(item.account?.displayName || "-")}
        </h6>
      ),
      workplace_license: (
        <LicenseSwitch
          id={item.id}
          type={EntityType.DESK}
          value={!!item.licensed}
        />
      ),
      workplace_delete: (
        <div className="ml-auto">
          <DeleteButton
            onClick={() => deleteWorkplace(item.id, item.__typename)}
          />
        </div>
      ),
    };
  });

  return (
    <StructureTable
      rows={rows}
      columns={columns}
      noResultsPlaceholder={"--"}
      className="StructureTable--desks"
    />
  );
};
