import React from "react";

import { FilterBox } from "../FilterBox/FilterBox";
import { SearchBox } from "../SearchBox/SearchBox";
import cs from "classnames";

import "./ResourceFilter.scss";

interface Props {
  children: JSX.Element;
  searchValue: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  numberOfFilters?: number;
  disabledFilters?: boolean;
  className?: string;
}

export const ResourceFilter = ({
  children,
  searchValue,
  onChangeSearch,
  clearSearchValue,
  numberOfFilters,
  disabledFilters,
  className,
}: Props) => {
  const classes = cs("ResourceFilter", className, {
    ResourceFilter__disable: disabledFilters,
  });

  return (
    <div className={classes}>
      <div className="ResourceFilter__wrapper">
        <FilterBox
          numberOfFilters={numberOfFilters}
          disabledFilters={disabledFilters}
        >
          {children}
        </FilterBox>
        <SearchBox
          searchValue={searchValue}
          onChange={onChangeSearch}
          disabledFilters={disabledFilters}
          clearSearchValue={clearSearchValue}
        />
      </div>
    </div>
  );
};
