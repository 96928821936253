import React from "react";
import { FloorsHeader } from "../../../../components/Floors/FloorsHeader/FloorsHeader";
import { useApolloClient } from "@apollo/client";
import { FLOOR_FIELDS_FRAGMENT } from "../../../../api/graphql/floors/floors";
import { RouteComponentProps } from "react-router-dom";
import { FloorPlan } from "../../../../components/Floors/FloorPlan/FloorPlan";

import "../Floors.scss";

interface Props {
  id: string;
}

export const FloorSingle = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<Props>) => {
  const client = useApolloClient();

  const floorData = client.readFragment({
    id: `Floor:${id}`,
    fragmentName: "FloorFields",
    fragment: FLOOR_FIELDS_FRAGMENT,
  });

  return (
    <>
      <FloorsHeader
        title={floorData?.name}
        floorId={id}
        imageUrl={floorData?.map?.url}
        hasImage={floorData?.map}
      />

      <FloorPlan data={floorData} />
    </>
  );
};
