import React from "react";
import ContentLoader from "react-content-loader";

export const WorkplacesTreeLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={400}
    viewBox="0 0 400 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="18" y="0" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="0" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="22" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="22" rx="5" ry="5" width="8" height="8" />

    <rect x="58" y="44" rx="5" ry="5" width="150" height="8" />
    <rect x="40" y="44" rx="5" ry="5" width="8" height="8" />

    <rect x="58" y="66" rx="5" ry="5" width="150" height="8" />
    <rect x="40" y="66" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="88" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="88" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="110" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="110" rx="5" ry="5" width="8" height="8" />

    <rect x="18" y="132" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="132" rx="5" ry="5" width="8" height="8" />

    <rect x="18" y="154" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="154" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="176" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="176" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="198" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="198" rx="5" ry="5" width="8" height="8" />

    <rect x="18" y="220" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="220" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="242" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="242" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="264" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="264" rx="5" ry="5" width="8" height="8" />

    <rect x="18" y="286" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="286" rx="5" ry="5" width="8" height="8" />

    <rect x="18" y="308" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="308" rx="5" ry="5" width="8" height="8" />

    <rect x="18" y="330" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="330" rx="5" ry="5" width="8" height="8" />

    <rect x="38" y="352" rx="5" ry="5" width="150" height="8" />
    <rect x="20" y="352" rx="5" ry="5" width="8" height="8" />

    <rect x="18" y="374" rx="5" ry="5" width="150" height="8" />
    <rect x="0" y="374" rx="5" ry="5" width="8" height="8" />
  </ContentLoader>
);
