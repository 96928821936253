import React from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { RolePermissions } from "../../../api/grpc/account/account";

interface Props {
  children?: React.ReactNode;
  permission?: RolePermissions | RolePermissions[];
  placeholder?: React.ReactNode | string;
}

export const PermissionWrapper = ({
  children,
  permission,
  placeholder,
}: Props) => {
  const { user } = useAuthContext();

  if (!permission) {
    return <>{children}</>;
  }

  if (!user) {
    return null;
  }

  const hasPermission = Array.isArray(permission)
    ? permission.some((p) => user?.permissions?.includes(p))
    : user?.permissions?.includes(permission);

  return <>{hasPermission ? children : placeholder ? placeholder : null}</>;
};
