import React, { PropsWithChildren } from "react";
import { SharedPieChart } from "./SharedPieChart/SharedPieChart";

export const NoShowsPieChart = ({
  value,
  type,
}: PropsWithChildren<{ value: number; type: "meetings" | "reservations" }>) => {
  const data = [
    {
      id: "% of no-shows",
      label: "missed",
      value: value,
      color: "#00EC97",
    },
    {
      id: `% of ${type}`,
      label: `% of ${type}`,
      value: 100 - value,
      color: "#ADAEB9",
    },
  ];

  return <SharedPieChart data={data} value={value} />;
};
