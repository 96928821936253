import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { LabelWithInformationBox } from "../LabelWithInformationBox/LabelWithInformationBox";

interface Props {
  keyword: string;
  onChange: (matched: boolean) => void;
}

export const KeywordInput = (props: Props) => {
  const { keyword, onChange } = props;
  const [value, setValue] = useState("");

  const handleChange = (value: string) => {
    setValue(value);

    onChange(keyword === value);
  };

  return (
    <FormGroup>
      <LabelWithInformationBox
        for="keyword"
        title={`Type "${keyword}" to confirm the action`}
      />
      <Input
        type="text"
        id="keyword"
        name="keyword"
        value={value}
        placeholder={keyword}
        onChange={(e) => handleChange(e.target.value)}
      />
    </FormGroup>
  );
};
