import React from "react";

import { tooltips } from "../../lib/utils/tooltips";

import { DevicesBox } from "../../components/Home/DevicesBox/DevicesBox";
import { InsightsBox } from "../../components/Home/InsightsBox/InsightsBox";
import { CalendarsBox } from "../../components/Home/CalendarsBox/CalendarsBox";
import { WorkplacesBox } from "../../components/Home/WorkplacesBox/WorkplacesBox";
import { SubscriptionWrapper } from "../../components/Home/SubscriptionWrapper/SubscriptionWrapper";
import { PageHeader } from "../../components/shared/PageHeader/PageHeader";

import "./Home.scss";

export const Home = () => {
  return (
    <div className="Home default-page">
      <PageHeader title="Dashboard" message={tooltips.dashboard.heading} />
      <div className="Home__boxes">
        <WorkplacesBox />
        <CalendarsBox />
        <DevicesBox />

        <InsightsBox />

        <SubscriptionWrapper />
      </div>
    </div>
  );
};
