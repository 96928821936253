import gql from "graphql-tag";

export interface WorkspaceNameResponse {
  getWorkplace: {
    name: string;
  };
}

export type DefaultLocationPath = {
  id: string;
  referenceId: string;
  name: string;
};

export type GetDefaultLocationPathRequestVariables = {
  accountId: string;
};

export type GetDefaultLocationPathResponse = {
  queryDefaultLocationPath: DefaultLocationPath[];
};

export type AddDefaultLocationPathVariables = {
  accountId: string;
  tenantId: string;
  referenceId: string;
  name: string;
};

export type AddDefaultLocationPathResponse = {};

export type DeleteDefaultLocationPathVariables = {
  accountId: string;
};

export type UpdateDefaultLocationPathVariables = {
  accountId: string;
  tenantId: string;
  referenceId: string;
  name: string;
};

export type UpdateDefaultLocationPathResponse = {};

export type DeleteDefaultLocationPathResponse = {};

export const GET_WORKSPACE_NAME = gql`
  query getWorkplaceName($id: ID!) {
    getWorkplace(id: $id) {
      name
      __typename
    }
  }
`;

export const GET_DEFAULT_LOCATION_PATH = gql`
  query getDefaultLocationPathQuery($accountId: String!) {
    queryDefaultLocationPath(filter: { accountId: { eq: $accountId } }) {
      id
      accountId
      tenantId
      referenceId
      name
    }
  }
`;

export const ADD_DEFAULT_LOCATION_PATH_GQL_MUTATION = gql`
  mutation addDefaultLocationPathMutation(
    $accountId: String!
    $tenantId: String!
    $referenceId: String!
    $name: String!
  ) {
    addDefaultLocationPath(
      input: [
        {
          accountId: $accountId
          tenantId: $tenantId
          referenceId: $referenceId
          name: $name
        }
      ]
    ) {
      defaultLocationPath(filter: { accountId: { eq: $accountId } }) {
        referenceId
        name
      }
    }
  }
`;

export const DELETE_DEFAULT_LOCATION_PATH = gql`
  mutation deleteDefaultLocationPath($accountId: String!) {
    deleteDefaultLocationPath(filter: { accountId: { eq: $accountId } }) {
      defaultLocationPath(filter: { accountId: { eq: $accountId } }) {
        referenceId
      }
    }
  }
`;

export const UPDATE_DEFAULT_LOCATION_PATH = gql`
  mutation updateDefaultLocationPathMutation(
    $accountId: String!
    $tenantId: String!
    $referenceId: String!
    $name: String!
  ) {
    updateDefaultLocationPath(
      input: {
        filter: { accountId: { eq: $accountId } }
        set: {
          accountId: $accountId
          tenantId: $tenantId
          referenceId: $referenceId
          name: $name
        }
      }
    ) {
      defaultLocationPath(filter: { accountId: { eq: $accountId } }) {
        referenceId
        name
      }
    }
  }
`;
