import React, { PropsWithChildren, useState } from "react";
import { toast } from "react-toastify";
import { Form, Modal } from "reactstrap";
import { useSelectLoginMethod } from "./lib/useSelectLoginMethod";
import { useDirectoryContext } from "../../Context/DirectoryContext";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";
import { ModalFooterContent } from "../../../Modals/ModalFooterContent/ModalFooterContent";
import { ModalHeaderContent } from "../../../Modals/ModalHeaderContent/ModalHeaderContent";
import { FormSubSection } from "../../../shared/FormSubSection/FormSubSection";
import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import { IntegrationsUsersSelector } from "./IntegrationsUsersSelector";
import { SyncStatus } from "../../../../api/grpc/contacts/contacts";
import { getCalendarTypeSlug } from "../../../Calendars/lib/helpers";
import { CalendarType } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { tooltips } from "../../../../lib/utils/tooltips";

interface Row {
  id: number;
  icon: IconDefinition;
  title: string;
  color: string;
}

export const IntegrationsUsersModal = ({
  isOpen,
  toggleModal,
}: PropsWithChildren<{
  isOpen: boolean;
  toggleModal: () => void;
}>) => {
  const { syncDirectoryUsers } = useContacts();
  const { query, history } = useRouter();
  const { loading, error, isBasicOrBussines } = useCustomerPermissions();
  const [loadingButton, setLoadingButton] = useState(false);
  const { loginMethod, setLoginMethod, basicOptions } = useSelectLoginMethod();
  const { selectedUsers, selectAllUsers } = useDirectoryContext();

  const isGoogleIntegration =
    query.directoryType === getCalendarTypeSlug(CalendarType.GSUITE_SYNC);

  const countStatus = (status: SyncStatus): number =>
    selectedUsers.filter((user) => user.status === status).length;

  let newUsers = countStatus(SyncStatus.NEW);
  let removedUsers = countStatus(SyncStatus.REMOVED);
  let existingUsers = countStatus(SyncStatus.UPDATED);

  const rows: Array<Row> = [
    {
      id: 1,
      icon: "polygon-green",
      title: `Add and send invite to ${newUsers} new ${
        newUsers === 1 ? "user" : "users"
      }`,
      color: "#2CD0AF",
    },
    {
      id: 2,
      icon: "inactive-red",
      title: `Inactivate ${removedUsers} ${
        removedUsers === 1 ? "user" : "users"
      } no longer in directory`,
      color: "#E7853F",
    },
    {
      id: 3,
      icon: "sync-icon",
      title: `Update ${existingUsers} existing ${
        existingUsers === 1 ? "user" : "users"
      }`,
      color: "#ADAEB9",
    },
  ];

  const handleSyncUsers = async () => {
    try {
      setLoadingButton(true);

      if (error) {
        toast.error(error?.message ?? "Error fetching user's plan!");
        return;
      }

      //if the option for selecting all users is true, then we send empty [] for members
      await syncDirectoryUsers({
        providerID: query.id,
        members: selectAllUsers ? [] : selectedUsers,
        loginMethod: isBasicOrBussines ? basicOptions.value : loginMethod.value,
        syncAll: selectAllUsers,
      });

      toast.success("Syncing users, please wait a few minutes.");
      history.replace("/users/people");
      return;
    } catch (error: any) {
      console.error(error);

      toast.error(error?.message ?? "Couldn't sync users!");
      return;
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Modal
      title="Sync users"
      isOpen={isOpen}
      onToggle={toggleModal}
      className="WorkHours"
    >
      <ModalHeaderContent title="Sync users" handleToggle={toggleModal} />

      <span className="WorkHours__info">You will now:</span>

      {rows.map((row) => (
        <div className="WorkHours__info flex-a-center gap-1" key={row.id}>
          <Icon icon={row.icon} className="w-20-svg" />
          <span style={{ color: row.color }}>{row.title}</span>
        </div>
      ))}

      <Form>
        <FormSubSection
          title="New user settings"
          column
          message={tooltips.directorySync.newUserSetting}
          className="p-modal mt-4"
        >
          <IntegrationsUsersSelector
            isBasicOrBussines={isBasicOrBussines}
            loginMethod={loginMethod}
            setLoginMethod={setLoginMethod}
            isGoogleIntegration={isGoogleIntegration}
          />
        </FormSubSection>
      </Form>

      <ModalFooterContent
        toggleModal={toggleModal}
        handleSave={handleSyncUsers}
        title="Sync users"
        disabled={loading || loadingButton}
      />
    </Modal>
  );
};
