import React from "react";
import { useBookingsContext } from "../../../Context/BookingsContext";
import { UserInfoRowItem } from "../../../shared/UserModal/UserInfoRowItem";
import { equipments, EquipmentType } from "../capabilitiesOptions";
import { Input } from "../../../../shared/Input/Input";
import { Label } from "reactstrap";

export const Equipment = () => {
  const { handleEquipmentChange, equipment: equipmentFromState } =
    useBookingsContext();

  const handleEquipment = (e: keyof EquipmentType) => {
    handleEquipmentChange(e);
  };

  return (
    <div className="Equipment">
      <UserInfoRowItem icon="tv-icon" content="Equipment" />
      {Object.entries(equipments).map(({ "0": key, "1": value }) => (
        <div className="Equipment__input" key={key}>
          <Input
            type="checkbox"
            id={key}
            for={key}
            name={key}
            value={key}
            checked={equipmentFromState[key as keyof EquipmentType]}
            onChange={(e) =>
              handleEquipment(e.target.value as keyof EquipmentType)
            }
          />
          <Label for={key} className="checkbox_label">
            {value}
            <span className="checkbox_box"></span>
          </Label>
        </div>
      ))}
    </div>
  );
};
