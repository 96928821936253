import React from "react";
import cs from "classnames";

import { Redirect, Route, Switch } from "react-router-dom";
import { useTemplateContext } from "../../../../lib/context/Templates/Templates/TemplatesContext";

import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";

import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import { SubRouteProps } from "../../../../lib/routes/routes";
import { WorkplaceHeader } from "../../WorkplaceHeader/WorkplaceHeader";
import { NoWorkplacesFound } from "../../NoWorkplacesFound/NoWorkplacesFound";
import { ResourceNavigation } from "../../../shared/ResourceNavigation/ResourceNavigation";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import {
  AddWorkplaceButton,
  WorkplaceTypes,
} from "../../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { AddDeviceButton } from "../../AddWorkplace/AddWorkplaceButton/AddDeviceButton/AddDeviceButton";

import "./EditWorkplaceWrapper.scss";

interface Props {
  id: string;
  message: string;
  type: WorkplaceTypes;
  routes: SubRouteProps[];
  data: WorkplaceFields | undefined;
  hasDevice?: boolean;
  numberOfDeviceAdded?: number;
  className?: string;
}

export const EditWorkplaceWrapper = (props: Props) => {
  const {
    type,
    message,
    id,
    routes,
    data,
    hasDevice,
    className,
    numberOfDeviceAdded,
  } = props;
  const { setPickedWorkspaces } = useTemplateContext();
  const { deleteWorkplace } = useWorkplaceRequests();

  const classes = cs("EditWorkplaceWrapper", className);
  const handleTemplatePicker = () => {
    if (!data) {
      return;
    }

    setPickedWorkspaces({ data: [data], redirect: true });
  };

  return (
    <div className={classes}>
      <HandleRequestState
        state={data === undefined}
        placeholder={<NoWorkplacesFound />}
      >
        {data && (
          <>
            <WorkplaceHeader
              type={type}
              name={data.name}
              tags={data.tags}
              message={message}
              description={data.description}
              onClick={() => deleteWorkplace(data.id, data.__typename, true)}
              handleTemplatePicker={handleTemplatePicker}
            />
            <ResourceNavigation routes={routes}>
              <AddWorkplaceButtonWrapper
                id={id}
                type={type}
                hasDevice={hasDevice}
                numberOfDeviceAdded={numberOfDeviceAdded}
              />
            </ResourceNavigation>
            <div className="EditWorkplaceWrapper__route">
              <Switch>
                {routes.map((item) => (
                  <Route
                    key={item.id}
                    path={item.to}
                    render={item.render}
                    exact={item.exact ? true : undefined}
                  />
                ))}
                <Redirect to={routes[0].to} />
              </Switch>
            </div>
          </>
        )}
      </HandleRequestState>
    </div>
  );
};

const AddWorkplaceButtonWrapper = ({
  id,
  type,
  hasDevice,
  numberOfDeviceAdded,
}: {
  id: string;
  type: WorkplaceTypes;
  hasDevice?: boolean;
  numberOfDeviceAdded?: number;
}) => {
  if (type === "Desk") {
    return <></>;
  }

  //wayfinder can only have one device, so
  // we hide Add Device button if that's the case:
  if (type === "Wayfinder" && hasDevice) {
    return <></>;
  }

  return type === "Room" || type === "Wayfinder" ? (
    <AddDeviceButton
      adjustable
      parentId={id}
      size="medium"
      parentType={type as "Room" | "Wayfinder"}
      numberOfDeviceAdded={numberOfDeviceAdded}
      icon="plus-sign"
      className="ml-auto AddDeviceButton__icon"
    />
  ) : (
    <AddWorkplaceButton
      adjustable
      type={type}
      parentId={id}
      className="ml-auto"
    />
  );
};
