import React from "react";
import { ChangedRoomsDirectionsVariables } from "../../../../../Workplaces/EditWorkplace/EditWayFinder/EditWayFinder";
import { Input } from "../../../../Input/Input";

interface Props {
  id: string;
  value: number;
  roomDirectionId: string;
  roomName: string;
  directionValue: number;
  distanceValue: number;
  handleChangedRooms: ({
    id,
    roomDirectionId,
    direction,
    distance,
  }: ChangedRoomsDirectionsVariables) => void;
  unitValue: string;
}

export const DistanceValue = ({
  id,
  value,
  roomName,
  roomDirectionId,
  directionValue,
  distanceValue,
  handleChangedRooms,
  unitValue,
}: Props) => {
  return (
    <div className="DistanceValue">
      <Input
        type="number"
        defaultValue={value}
        className="DistanceValue__value"
        onBlur={(e) => {
          if (+e.target.value === distanceValue) {
            return;
          }

          handleChangedRooms({
            id: id,
            roomDirectionId: roomDirectionId,
            distance: +e.target.value,
            direction: directionValue,
            name: roomName,
          });
        }}
      />
      <span className="DistanceValue__unit">
        {unitValue.split("_")[1].toLowerCase()}
      </span>
    </div>
  );
};
