import { useApolloClient } from "@apollo/client";
import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../../api/graphql/workplaces";
import { useAuthContext } from "../context/Auth/AuthContext";

export const useRootIdQuery = () => {
  const { user } = useAuthContext();

  const client = useApolloClient();

  const rootWorkplaceQuery = client.readQuery<
    GetWorkplacesResponse,
    WorkplaceRequestVariables
  >({
    query: GET_ROOT_WORKPLACES,
    variables: {
      tenantId: user?.customerid || "",
    },
  });

  let rootId: string;

  if (!!rootWorkplaceQuery?.queryTenant?.length) {
    rootId = rootWorkplaceQuery.queryTenant[0].id;
  } else {
    rootId = "";
  }

  return { rootId };
};
