import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Error404 } from "../../components/Error404/Error404";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { DeviceType } from "../../lib/context/Auth/AuthContextProvider";
import { downloadFile } from "../../lib/helpers/downloadFile";

interface Props {
  id: string;
}

export const DownloadApk = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<Props>) => {
  const { downloadApk } = useAuthContext();

  useEffect(() => {
    if (!["rdx", "wfx"].includes(id)) {
      return;
    }

    const handleDownloadApk = async () => {
      const {
        response: { url },
      } = await downloadApk(id as DeviceType);
      downloadFile(url);
    };
    handleDownloadApk();
  }, []);

  if (!["rdx", "wfx"].includes(id)) {
    return <Error404 />;
  }

  return (
    <div className="mt-5 text-center">
      <span className="d-block">Your download will begin automatically.</span>
      <span>Thank you for downloading</span>
    </div>
  );
};
