import React from "react";

import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";

import { getCalendarTypeName } from "../../lib/helpers";
import { IntegrationsItem } from "./IntegrationsItem/IntegrationsItem";
import {
  CalendarType,
  GGCalendarProviderStatus,
} from "../../../../api/grpc/calendarprovider/calendarprovider";

import "./Integrations.scss";

export const Integrations = () => {
  const { providers } = useCalendarContext();

  const ewsCalendars = providers.filter(
    (item) => item.calType === CalendarType.EWS
  );

  const googleCalendars = providers.filter(
    (item) => item.calType === CalendarType.GSUITE
  );

  const o365Calendars = providers.filter(
    (item) => item.calType === CalendarType.O365
  );

  return (
    <div className="CalendarProviders">
      <div className="Integrations">
        <IntegrationsItem
          type={CalendarType.EWS}
          calendars={ewsCalendars}
          title={getCalendarTypeName(CalendarType.EWS)}
          icon="exchange_ews"
          hasFailedCalendar={ewsCalendars.some(
            (calendar) => calendar.status === GGCalendarProviderStatus.FAILED
          )}
        />
        <IntegrationsItem
          type={CalendarType.GSUITE}
          calendars={googleCalendars}
          title={getCalendarTypeName(CalendarType.GSUITE)}
          icon="google_wp"
          hasFailedCalendar={googleCalendars.some(
            (calendar) => calendar.status === GGCalendarProviderStatus.FAILED
          )}
        />
        <IntegrationsItem
          type={CalendarType.O365}
          calendars={o365Calendars}
          title={getCalendarTypeName(CalendarType.O365)}
          icon="microsoft-365"
          hasFailedCalendar={o365Calendars.some(
            (calendar) => calendar.status === GGCalendarProviderStatus.FAILED
          )}
        />
      </div>
    </div>
  );
};
