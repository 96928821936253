import React from "react";

import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useAnalytics } from "../../../api/grpc/analytics/useAnalytics";

import { tooltips } from "../../../lib/utils/tooltips";
import {
  CustomerRequest,
  WorkplacesResponse,
} from "../../../api/grpc/analytics/analytics";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";

import { HomeCard } from "../../shared/HomeCard/HomeCard";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";

export const WorkplacesBox = () => {
  const { getWorkplaces } = useAnalytics();
  const { timeZone } = useTimeZoneContext();

  const { loading, error, data } = useGrpcQuery<
    Omit<CustomerRequest, "customerID">,
    WorkplacesResponse
  >({
    method: getWorkplaces,
    variables: {
      timeZone,
    },
  });

  return (
    <HandleRequestState state={loading} placeholder={<LoadingBox />}>
      <HandleRequestState
        state={!!error}
        placeholder={
          <HomeCard
            disabled
            title="Workspaces"
            icon="dashboard-workplace-icon"
            tooltip={tooltips.dashboard.workplaces}
            list={[
              { title: "Rooms" },
              { title: "Desks" },
              {
                title: "Licensed items",
              },
            ]}
          />
        }
      >
        <HomeCard
          title="Workspaces"
          icon="dashboard-workplace-icon"
          link="/manage/organization"
          tooltip={tooltips.dashboard.workplaces}
          className="Workspaces"
          hasDivider
          adjustInfoLeft
          list={[
            { title: "Rooms", value: data?.rooms || 0 },
            { title: "Desks", value: data?.desks || 0 },
            { title: "Wayfinders", value: data?.wayfinders || 0 },
            {
              title: "Licensed items",
              value: data?.licence || 0,
              progress: data?.licensed || 0,
            },
          ]}
        />
      </HandleRequestState>
    </HandleRequestState>
  );
};
