import React from "react";
import { Spinner } from "reactstrap";

export const CenteredLoader = () => {
  return (
    <div className="w-100 d-flex w-100 h-100 align-items-center justify-content-center">
      <Spinner color="primary" style={{ width: "5rem", height: "5rem" }} />
    </div>
  );
};
