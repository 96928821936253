export const getImageSize = async (
  url: string
): Promise<{
  width: number;
  height: number;
}> => {
  const img = new Image();

  img.src = url;

  return new Promise((res) => {
    img.onload = () => {
      res({ width: img.width, height: img.height });
    };
  });
};
