import React from "react";

import { InformationBox } from "../InformationBox/InformationBox";
import { UserViewType } from "../UserViewType/UserViewType";

import "./StructureHeader.scss";

interface Props {
  title: string;
  hideBorder?: boolean;
  message?: string | undefined;
  showUserViewType?: boolean;
}

export const StructureHeader = ({
  title,
  hideBorder,
  message,
  showUserViewType,
}: Props) => {
  return (
    <div className="StructureHeader">
      <div className="StructureHeader__title">
        <h1>{title}</h1>
        {message && <InformationBox message={message} />}
        {showUserViewType && <UserViewType />}
      </div>
    </div>
  );
};
