import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useSiteFormik } from "../../../shared/Forms/SiteForm/lib/useSiteFormik";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";

import { SubRouteProps } from "../../../../lib/routes/routes";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import { SiteForm } from "../../../shared/Forms/SiteForm/SiteForm";
import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import {
  SiteRequestVariables,
  SITE_FIELDS_FRAGMENT,
} from "../../../../api/graphql/sites/sites";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { EditSettings } from "../EditSettings/EditSettings";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";
import { tooltips } from "../../../../lib/utils/tooltips";
import { EditViewsTabWorkspaces } from "../EditViewsTabWorkspaces/EditViewsTabWorkspaces";

export const EditSite = (props: EditWorkplaceProps) => {
  const { id } = props;
  const router = useRouter();
  const client = useApolloClient();
  const { customer } = useCustomerContext();
  const { updateSite } = useWorkplaceRequests();
  const [siteData, setSiteData] = useState<WorkplaceFields | null>(null);

  // TO DO: Move on how the data are loaded to a hook useWorkplaceData
  const updateData = useCallback(() => {
    setSiteData(
      client.readFragment({
        id: `Site:${id}`,
        fragmentName: "SiteFields",
        fragment: SITE_FIELDS_FRAGMENT,
      })
    );
  }, [id]);

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useSiteFormik({
    initialValues: {
      name: siteData?.name || "",
      description: siteData?.description || "",
      tags: siteData?.tags || [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const removedTags =
          siteData?.tags.filter((item) => !values.tags.includes(item)) || [];

        const variables: SiteRequestVariables = {
          id: props.id,
          name: values.name,
          tags: values.tags,
          description: values.description,
          tenantId: customer.tenantId,
          remove: {
            tags: removedTags,
          },
        };

        await updateSite(variables);

        toast.success("Site edited successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Site couldn't be updated, please reload and try again!"
        );
        console.error(error.message);
      }
    },
  });

  const rows: WorkplaceListData[] = [
    {
      data: siteData?.buildings,
      type: "Building",
    },
    {
      data: siteData?.floors,
      type: "Floor",
    },
    {
      data: siteData?.zones,
      type: "Zone",
    },
    {
      data: siteData?.rooms,
      type: "Room",
    },

    {
      data: siteData?.desks,
      type: "Desk",
    },

    {
      data: siteData?.wayfinders,
      type: "Wayfinder",
    },
  ];

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "View",
      to: router.match.url,
      id: "view-page-site",
      render: () => <EditViewsTabWorkspaces type="Site" id={id} rows={rows} />,
      // render: () => <EditViewsTab id={props.id} rows={rows} />,
    },
    {
      exact: true,
      label: "Settings",
      id: "settings-page-site",
      to: `${router.match.url}/settings`,
      render: () => (
        <EditSettings
          title="General settings"
          // hasChanges={formik.dirty}
          onSubmit={formik.handleSubmit}
        >
          <SiteForm formik={formik} />
        </EditSettings>
      ),
    },
  ];

  return (
    <EditWorkplaceWrapper
      type="Site"
      id={props.id}
      routes={routes}
      data={siteData || undefined}
      message={tooltips.workplaces.site}
    />
  );
};
