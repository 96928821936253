import React from "react";
import { NavLink } from "react-router-dom";
import { Form, Label, Input, Button, FormGroup, Spinner } from "reactstrap";

import { ACCOUNT_STRINGS as t } from "../../lib/utils/constants";

interface ResetPasswordFormProps {
  email: string;
  loading: boolean;
  handleChange: (value: string) => void;
  handleSubmit: () => void;
}

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const isEmailValid = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(props.email);
  };

  return (
    <Form>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          value={props.email}
          onChange={(e) => props.handleChange(e.target.value)}
        />
      </FormGroup>

      <div className="AuthenticationPage__buttons">
        <Button
          size="large"
          type="button"
          color="primary"
          onClick={props.handleSubmit}
          disabled={!isEmailValid() || props.loading}
        >
          {props.loading ? (
            <>
              <span className="mr-3">Loading...</span>
              <Spinner size="sm" color="light" />
            </>
          ) : (
            t.resetPassword
          )}
        </Button>
        <NavLink to="/login" className="d-inline-block">
          Go to login page
        </NavLink>
      </div>
    </Form>
  );
};
