import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../api/grpc/auth/useAuth";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { MicrosoftLogin } from "./MicrosoftLogin";

export const ProvidersButtons = () => {
  const { exchangeSocialAuthToken } = useAuth();
  const { signInWithProvider } = useAuthContext();

  const handleGoogleLogin = async (res: any) => {
    localStorage.setItem("token", res.credential);
    try {
      const { response } = await exchangeSocialAuthToken(res.credential);

      await signInWithProvider(response.token);
      localStorage.setItem("initiateLogin", "1");
    } catch (error: any) {
      toast.error(error?.message ?? "User not found!");
      console.error("error", error);
    }
  };

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GCP_CLIENT_ID,
      callback: handleGoogleLogin,
    });

    window.google.accounts.id.renderButton(document.getElementById("signIn"), {
      theme: "outline",
      size: "large",
      width: 230,
    });
  }, []);

  return (
    <div className="ConnectProviders flex-a-center justify-content-center">
      <div id="signIn"></div>

      <MicrosoftLogin />
    </div>
  );
};
