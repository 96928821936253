import { IconDefinition } from "../../shared/Icon/Icon";
import { WorkplaceTypes } from "../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

interface ListType {
  type: WorkplaceTypes;
  name: { plural: string; singular: string };
  icon: { small: IconDefinition; big: IconDefinition };
}

export const workplaceTypesData: ListType[] = [
  {
    type: "Company",
    name: { plural: "Companies", singular: "Company" },
    icon: { small: "company_icon_18x18", big: "company_icon_48x48" },
  },
  {
    type: "Site",
    name: { plural: "Sites", singular: "Site" },
    icon: { small: "site_icon_18x18", big: "site_icon_48x48" },
  },
  {
    type: "Building",
    name: { plural: "Buildings", singular: "Building" },
    icon: { small: "buildings-icon", big: "buildings-icon" },
  },
  {
    type: "Floor",
    name: { plural: "Floors", singular: "Floor" },
    icon: { small: "floors-icon", big: "floors-icon" },
  },
  {
    type: "Zone",
    name: { plural: "Zones", singular: "Zone" },
    icon: { small: "zone_icon_18x18", big: "zone_icon_48x48" },
  },
  {
    type: "Room",
    name: { plural: "Rooms", singular: "Room" },
    icon: { small: "rooms-icon", big: "rooms-icon" },
  },
  {
    type: "Desk",
    name: { plural: "Desks", singular: "Desk" },
    icon: { small: "desk_icon_18x18", big: "desk_icon_48x48" },
  },
  {
    type: "Wayfinder",
    name: { plural: "Wayfinders", singular: "Wayfinder" },
    icon: { small: "wayFinder_icon_18x18", big: "wayFinder_icon_48x48" },
  },
  {
    type: "Device",
    name: { plural: "Devices", singular: "Device" },
    icon: { small: "device_icon_18x18", big: "device_icon_48x48" },
  },
];

export const getWorkplaceIcon = (
  type: WorkplaceTypes,
  isBigIcon: boolean = false
) => {
  const item =
    workplaceTypesData.find((item) => item.type === type) ||
    workplaceTypesData[0];

  return isBigIcon ? item.icon.big : item.icon.small;
};

export const getWorkplaceName = (type: WorkplaceTypes, length?: number) => {
  const item = workplaceTypesData.find((item) => item.type === type);

  if (!item) {
    return "";
  }

  const { singular, plural } = item.name;

  return length && length === 1 ? singular : plural;
};
