import React, { useRef, useState } from "react";
import { useOutsideClickDetection } from "../../../../../lib/hooks/useOutsideClickDetection";
import { useInsightsContext } from "../../../Context/InsightsContext";

import { MeetingSpaceCostContent } from "./MeetingSpaceCostContent";

import "./MeetingSpaceCost.scss";

export const MeetingSpaceCost = () => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // we set state for cost widget opener here because if we do it in the MeetingSpaceCostContent
  // it has a bug that when we click on the content it toggles and closes the widget.
  const [openCost, setIsOpenCost] = useState(false);

  const {
    loadingGeneralSettings,
    errorGeneralSettings,
    customerSettings,
    handleWorkingHours,
  } = useInsightsContext();

  useOutsideClickDetection(
    ref,
    () => {
      if (openCost) {
        return;
      }
      return setIsOpen(false);
    },
    openCost
  );

  return (
    <div className="MeetingSpaceCost" ref={ref}>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex-a-center MeetingSpaceCost__toggler"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      {isOpen && (
        <MeetingSpaceCostContent
          value={Math.round(customerSettings?.meetingSpaceCostPerHr || 0)}
          closeModal={() => setIsOpen(false)}
          handleCostPerHr={handleWorkingHours}
          currency={customerSettings?.currency}
          error={errorGeneralSettings}
          loading={loadingGeneralSettings}
          isOpen={openCost}
          setIsOpen={setIsOpenCost}
        />
      )}
    </div>
  );
};
