import { WorkplaceFields } from "../../../api/graphql/workplaces";

// this function only returns true if room or desk is licensed and has calendarId and calendarProviderId
// and other workplaces

export const isValidWorkplace = (workplace: WorkplaceFields) => {
  if (workplace.__typename === "Wayfinder") {
    return false;
  }

  if (!["Room", "Desk"].includes(workplace.__typename)) {
    return true;
  }

  if (!workplace.licensed) {
    return false;
  }

  if (
    workplace.__typename === "Room" &&
    (!workplace.calendarId?.length || !workplace.calendarProviderId?.length)
  ) {
    return false;
  }

  return true;
};
