import React, { useState } from "react";
import { format } from "date-fns";

import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { useInsightsContext } from "../../InsightsContext";

import {
  DeskHistoryEventsResponse,
  DeskInsightsOverviewResponse,
  GeneralStatsByDesksResponse,
  HistoryEventsRequest,
  TimeRangeEventsResponse,
} from "../../../../../api/grpc/insights/insights";
import {
  TimeRangeRequest,
  useDeskInsightsRequests,
} from "../../../../../api/grpc/insights/useDeskInsightsRequests";
import { OverviewInsights, OverviewInsightsContext } from "./OverviewContext";
import { OrganizerFilter } from "../../Room/Overview/RoomInsightsContextProvider";

interface Props {
  children: React.ReactNode;
}

export const OverviewContextProvider = ({ children }: Props) => {
  const {
    getDesksOverview,
    getDeskReservationTimeRange,
    getGeneralDeskStats,
    getDeskReservationsHistory,
  } = useDeskInsightsRequests();
  const { timeZone } = useTimeZoneContext();
  const [organizers, setOrganizers] = useState<OrganizerFilter[] | undefined>(
    undefined
  );
  const [offset, setOffset] = useState(0);
  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const {
    loading: loadingOverview,
    error: errorOverview,
    data: dataOverview,
  } = useGrpcQuery<TimeRangeRequest, DeskInsightsOverviewResponse>({
    method: getDesksOverview,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingRange,
    error: errorRange,
    data: dataRange,
  } = useGrpcQuery<TimeRangeRequest, TimeRangeEventsResponse>({
    method: getDeskReservationTimeRange,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingGeneral,
    error: errorGeneral,
    data: dataGeneral,
  } = useGrpcQuery<TimeRangeRequest, GeneralStatsByDesksResponse>({
    method: getGeneralDeskStats,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingDeskHistory,
    error: errorDeskHistory,
    data: dataDeskHistory,
  } = useGrpcQuery<HistoryEventsRequest, DeskHistoryEventsResponse>({
    method: getDeskReservationsHistory,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
      customerID: "",
      limit: 250,
      offset,
      organizers: organizers?.map((item) => item.value) || [],
    },
  });

  const context: OverviewInsights = {
    loadingOverview,
    errorOverview,
    dataOverview,
    loadingRange,
    errorRange,
    dataRange,
    loadingGeneral,
    errorGeneral,
    dataGeneral,
    loadingDeskHistory,
    errorDeskHistory,
    dataDeskHistory,
    organizers,
    setOrganizers,
    offset,
    setOffset,
  };

  return (
    <OverviewInsightsContext.Provider value={context}>
      {children}
    </OverviewInsightsContext.Provider>
  );
};
