//this hook is to display selected floor from Display settings of
// Wayfinder and Room
import React from "react";
import { useApolloClient } from "@apollo/client";
import { useMemo } from "react";

import { NoFloorSelected } from "../../components/shared/Forms/WayFinderForm/shared/NoFloorSelected/NoFloorSelected";
import { SelectedFloor } from "../../components/shared/Forms/WayFinderForm/shared/SelectedFloor/SelectedFloor";

import {
  FloorFields,
  FLOOR_FIELDS_FRAGMENT,
} from "../../api/graphql/floors/floors";

export const useSelectedFloor = (floor: { id: string } | null): JSX.Element => {
  const client = useApolloClient();

  return useMemo(() => {
    if (!floor) {
      return <NoFloorSelected />;
    }

    let selectedFloor: FloorFields | null = client.readFragment({
      id: `Floor:${floor.id}`,
      fragmentName: "FloorFields",
      fragment: FLOOR_FIELDS_FRAGMENT,
    });

    return <SelectedFloor name={selectedFloor?.name || ""} />;
  }, [floor]);
};
