import React, { PropsWithChildren, useState } from "react";
import { Subscription } from "../../../../../api/grpc/subscription/subscription";
import { CalendarsWizardFooter } from "../../../../Calendars/CalendarsWizardForm/CalendarsWizardFooter/CalendarsWizardFooter";
import { CalendarsWizardHeaderItem } from "../../../../Calendars/CalendarsWizardForm/CalendarsWizardHeader/CalendarsWizardHeaderItem/CalendarsWizardHeaderItem";
import { useCalulactorProContext } from "../Context/CalculatorProContext";
import { DatePrice } from "./DatePrice/DatePrice";
import { useDatePriceFormik } from "./DatePrice/useDatePriceFormik";
import { ProRate } from "./ProRate/ProRate";
import { SelectSub } from "./SelectSub/SelectSub";

const steps = [
  {
    id: "select-subscription",
    title: "Select subscription",
  },
  {
    id: "date-price",
    title: "Set activation date & price",
  },
  {
    id: "pro-rating",
    title: "See pro-rating %",
  },
];

export const CalulatorProRateSteps = ({
  subscriptions,
  toggleModal,
}: PropsWithChildren<{
  subscriptions: Subscription[];
  toggleModal: () => void;
}>) => {
  const [activeStep, setActiveStep] = useState(1);
  const { subscription, setSubscription, setSelectedDate } =
    useCalulactorProContext();

  const handlePrevClick = () => {
    if (activeStep === 1) {
      return;
    }

    setActiveStep((prevValue) => prevValue - 1);
  };

  const handleStepUpdate = (n: number) => {
    if (n === 4) {
      return;
    }

    setActiveStep(n);
  };

  const formik = useDatePriceFormik({
    onSubmit: async () => {},
  });

  return (
    <div className="CalendarsWizardForm">
      <div className="CalendarsWizardHeader CalendarsWizardHeader__three">
        {steps.map((item, key: number) => {
          const itemIndex = ++key;

          return (
            <CalendarsWizardHeaderItem
              item={item}
              number={itemIndex}
              key={`${item.id}-header`}
              isActive={activeStep === itemIndex}
              isCompleted={itemIndex < activeStep}
              onClick={() => {}}
            />
          );
        })}
      </div>

      <div
        className={
          activeStep === 3
            ? "CalculatorProRate__content"
            : "CalendarsWizardForm__profiles"
        }
      >
        {activeStep === 1 && <SelectSub subscriptions={subscriptions} />}
        {activeStep === 2 && <DatePrice formik={formik} />}
        {activeStep === 3 && (
          <ProRate
            itemPrice={formik.values.itemPrice || 0}
            userItemPrice={formik.values.userItemPrice || 0}
          />
        )}
      </div>

      <CalendarsWizardFooter
        activeStep={activeStep}
        handlePrevClick={handlePrevClick}
        handleNextClick={() => {
          return handleStepUpdate(activeStep + 1);
        }}
        handleSubmitClick={() => {
          setSubscription(undefined);
          setSelectedDate(new Date());
          return toggleModal();
        }}
        createButton={activeStep === 3}
        nextButtonTitle="Close"
        disabledNext={
          activeStep === 1
            ? subscription === undefined
            : !formik.dirty || !!Object.keys(formik.errors).length
        }
      />
    </div>
  );
};
