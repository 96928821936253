import React from "react";
import { Button, ThemeButtonProps } from "../../../shared/Button/Button";

export const SetCalendarButton = (props: ThemeButtonProps) => {
  const { size, outline, color, lineHeight, title, onClick } = props;

  return (
    <div className="SetCalendarButton">
      <Button
        size={size}
        color={color}
        onClick={onClick}
        outline={!!outline}
        lineHeight={lineHeight}
        title={title ? title : "Add Calendar"}
      />
    </div>
  );
};
