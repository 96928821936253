import React, { useState } from "react";
import { IconDefinition } from "../../shared/Icon/Icon.generated";
import { Icon } from "../../shared/Icon/Icon";
import {
  FloorChildrenExpandedList,
  FloorChildrenType,
} from "./FloorChildrenExpandedList";
import { FloorChildrenEntity } from "./FloorChildrenList";

interface Props {
  parentId?: string;
  data: FloorChildrenEntity[];
  icon: IconDefinition;
  type: FloorChildrenType;
  onClick: () => void;
  typeOfMap?: "bookings" | "floor";
}

export const FloorChildrenRow = (props: Props) => {
  const { data, type, icon, typeOfMap } = props;

  const [isToggled, setIsToggled] = useState<boolean>(false);

  const handleToggle = () => {
    setIsToggled((prev) => !prev);
  };

  return (
    <>
      {isToggled ? (
        <>
          <FloorChildrenExpandedList
            data={data}
            type={type}
            onClose={handleToggle}
            typeOfMap={typeOfMap}
          />
        </>
      ) : (
        <div onClick={handleToggle} className="WorkplaceRow list-row">
          <div className="WorkplaceRow__toggle">
            <Icon icon="toggle-list-icon" />
          </div>
          <Icon icon={icon} className="WorkplaceRow__icon" />
          <h4 className="WorkplaceRow__title">{type}</h4>
        </div>
      )}
    </>
  );
};
