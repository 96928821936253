import React from "react";
import Select from "react-select";
import { Role } from "../../../api/grpc/contacts/account/account";

import { selectStyles } from "../../../lib/utils/selectStyles";

interface Props {
  value: Role;
  fieldName: string;
  onChange: (field: string, value: number) => void;
}

const options = [
  { value: Role.ADMIN, label: "Admin" },
  { value: Role.COORDINATOR, label: "Coordinator" },
  { value: Role.MEMBER, label: "Member" },
  // { value: Role.SUPPORT, label: "Support" },
];

export const RoleSelect = ({ fieldName, value, onChange }: Props) => {
  const selectedOption = options.find((option) => option.value === value);

  return (
    <Select
      name={fieldName}
      options={options}
      theme={selectStyles}
      isClearable={false}
      value={selectedOption || null} // If no match, set to null
      onChange={(e) => {
        if (!e || e.value === undefined) {
          return;
        }

        onChange(fieldName, e.value);
      }}
    />
  );
};
