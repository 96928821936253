import React, { useState } from "react";
import { DirectorySyncServiceAccount } from "../DirectorySyncServiceAccount/DirectorySyncServiceAccount";
import { DirectorySyncSystem } from "../DirectorySyncSystem/DirectorySyncSystem";

export const DirectorySyncWizardForm = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [calendarSystem, setCalendarSystem] = useState("");

  const handlePrevClick = () => {
    if (activeStep === 1) {
      return;
    }

    setActiveStep((prevValue) => prevValue - 1);
  };

  const handleStepUpdate = (n: number) => {
    if (n === 2 && !calendarSystem) return;

    setActiveStep(n);
  };

  return (
    <div className="CalendarsWizardForm">
      {activeStep === 1 && (
        <DirectorySyncSystem
          activeStep={activeStep}
          activeCalendar={calendarSystem}
          handlePrevClick={handlePrevClick}
          handleNextClick={(n: number) => handleStepUpdate(n)}
          onClick={(id) => setCalendarSystem(id)}
        />
      )}
      {activeStep === 2 && (
        <DirectorySyncServiceAccount
          activeStep={activeStep}
          activeCalendar={calendarSystem}
          handlePrevClick={handlePrevClick}
        />
      )}
    </div>
  );
};
