import { DeskTemplateSettings } from "../../../api/grpc/workplaces/workplaces";

type SettingOption = {
  name: string;
  value: keyof DeskTemplateSettings;
  children?: Array<keyof DeskTemplateSettings>;
};

type DeskCoreSettings = {
  [key in keyof DeskTemplateSettings]?: {
    label: string;
    children?: Array<keyof DeskTemplateSettings>;
  };
};

const deskCoreSettings: DeskCoreSettings = {
  description: { label: "Description" },
  tags: { label: "Tags" },
  isBlocked: { label: "Block desk" },
  account: { label: "Assigned user" },
  showCheckInTime: {
    label: "Check-in required",
    children: ["qrCodeRequired", "checkInTime"],
  },
  workingHrsActivated: {
    label: "Schedule only during work hours",
    children: ["workingHrsStart", "workingHrsEnd"],
  },
};

export const deskSettingsOptions = (): { options: SettingOption[] } => {
  const options = Object.entries(deskCoreSettings).map(
    ([key, { label, children }]) => ({
      name: label,
      value: key as keyof DeskTemplateSettings,
      children: (children as Array<keyof DeskTemplateSettings>) || [],
    })
  );

  return { options };
};
