import React from "react";
import { NavLink } from "react-router-dom";
import { Form, Col, FormGroup, Label, Row, Button, Spinner } from "reactstrap";
import { Input } from "../../shared/Input/Input";
import { PasswordInput } from "../../shared/PasswordInput/PasswordInput";

import { SetPasswordFormik } from "../lib/useSetPasswordFormik";

interface Props {
  loading: boolean;
  hideRedirect?: boolean;
  formik: SetPasswordFormik;
}

export const SetPasswordForm = (props: Props) => {
  const { loading, hideRedirect, formik } = props;

  return (
    <Form className="Contact__form w-100" onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs="12" sm="12">
          <FormGroup>
            <Label for="emailPassword">Email</Label>
            <Input
              type="email"
              readOnly={true}
              id="emailPassword"
              placeholder="Email"
              name="emailPassword"
              value={formik.values.emailPassword}
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm="12">
          <FormGroup>
            <Label for="newResetPassword">New Password</Label>
            <PasswordInput
              id="newResetPassword"
              name="newResetPassword"
              placeholder="New Password"
              autoComplete="new-password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.newResetPassword}
              isTouched={formik.touched.newResetPassword}
              errorMessage={formik.errors.newResetPassword}
              invalid={
                !!formik.errors.newResetPassword &&
                formik.touched.newResetPassword
              }
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm="12">
          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <PasswordInput
              id="confirmPassword"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              autoComplete="new-password"
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              invalid={
                !!formik.errors.confirmPassword &&
                formik.touched.confirmPassword
              }
              isTouched={formik.touched.confirmPassword}
              errorMessage={formik.errors.confirmPassword}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="AuthenticationPage__buttons">
        <Button
          size="large"
          type="submit"
          color="primary"
          disabled={loading || !formik.dirty}
        >
          {loading ? (
            <>
              <span className="mr-3">Updating Password...</span>
              <Spinner size="sm" color="light" />
            </>
          ) : (
            "Set Password"
          )}
        </Button>
        {!hideRedirect && (
          <NavLink to="/login" className="d-inline-block">
            Return to home page
          </NavLink>
        )}
      </div>
    </Form>
  );
};
