import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import cs from "classnames";

import { Icon } from "../../shared/Icon/Icon";

import "./ModalSidebar.scss";

interface ModalSidebarProps {
  isOpen: boolean;
  title: string;
  loading?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  className?: string;
  submitButtonLabel?: string;
  onToggle: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export default function ModalSidebar(props: ModalSidebarProps) {
  const handleSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const handleCancel = () => {
    props.onToggle();
  };

  const classes = cs("ModalSidebar", props.className);

  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle} className={classes}>
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={props.onToggle}
        >
          <Icon icon="close-icon" />
        </button>
      </div>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          className="px-4 py-2"
          onClick={handleSubmit}
          disabled={props.isDisabled || props.loading}
        >
          {props.loading ? "Processing..." : props.submitButtonLabel || "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
