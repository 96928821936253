import React, { PropsWithChildren } from "react";

export const ResourceFilterHeader = ({
  name,
}: PropsWithChildren<{
  name?: string;
}>) => {
  return (
    <h5 className="font-weight-normal ResourceFilter__subTitle">
      {name ?? "Quick filters"}
    </h5>
  );
};
