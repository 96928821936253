import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDirectoryIntegrationsRequests } from "../../../api/grpc/calendarprovider/useDirectoryIntegrationsRequests";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import {
  IntegrationGroupMember,
  SyncStatus,
} from "../../../api/grpc/contacts/contacts";
import { DirectoryContext, IDirectoryContext } from "./DirectoryContext";
import {
  CalendarType,
  DirectorySyncIntegration,
  GGCalendarProviderStatus,
  ListDirectoryProviderResponse,
} from "../../../api/grpc/calendarprovider/calendarprovider";

interface Props {
  children: React.ReactNode;
}

export const DirectoryContextProvider = ({ children }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<IntegrationGroupMember[]>(
    []
  );
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [brokenIntegrations, setBrokenIntegrations] = useState<
    DirectorySyncIntegration[]
  >([]);
  const [filterStatus, setFilterStatus] = useState<SyncStatus[]>([]);
  const { listOfDirectoryIntegration, generateDirectoryIntegrationAuthLink } =
    useDirectoryIntegrationsRequests();
  const { user } = useAuthContext();

  const { loading, error, data, refetch } = useGrpcQuery<
    {},
    ListDirectoryProviderResponse
  >({
    method: listOfDirectoryIntegration,
    variables: {},
  });

  const handleFixDirectoryButton = async (id: string) => {
    const isO365 = id.toLowerCase().startsWith("o365");

    const redirectURL = isO365
      ? (process.env.REACT_APP_O365_DIRECTORY as string)
      : (process.env.REACT_APP_GCP_DIRECTORY as string);

    const { response } = await generateDirectoryIntegrationAuthLink({
      redirectURL,
      integrationType: isO365
        ? CalendarType.O365_SYNC
        : CalendarType.GSUITE_SYNC,
    });

    if (!response.authLink.length) {
      toast.error("Failed to generate the authentication link");
      return;
    }

    localStorage.setItem("directoryReAuth", id);

    window.location.href = response.authLink;
    return;
  };

  useEffect(() => {
    if (
      !data?.integrations ||
      ["contact", "member"].includes(user?.claims.role)
    ) {
      return;
    }

    setBrokenIntegrations(
      data.integrations.filter(
        (integration) => integration.status === GGCalendarProviderStatus.FAILED
      )
    );
  }, [data, user]);

  const context: IDirectoryContext = {
    selectedUsers,
    setSelectedUsers,
    selectAllUsers,
    setSelectAllUsers,
    filterStatus,
    setFilterStatus,
    searchValue,
    setSearchValue,
    data,
    loading,
    error,
    refetch,
    handleFixDirectoryButton,
    brokenIntegrations,
    setBrokenIntegrations,
    dynamicStylesDirectory: {
      "--directory-count": brokenIntegrations.length,
    } as React.CSSProperties,
  };

  return (
    <DirectoryContext.Provider value={context}>
      {children}
    </DirectoryContext.Provider>
  );
};
