import React from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";

import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import {
  Event as DeskEvent,
  EventCheckInStatus,
} from "../../../../api/grpc/desk/ggevent/ggevent";
import { Icon } from "../../../shared/Icon/Icon";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { ReservationListActions } from "./ReservationListActions";
import { ReservationListName } from "./ReservationListName";

import "./ReservationListCard.scss";

interface Props {
  type: BookingTypes;
  name: string;
  workplaceId: string;
  event: DeskEvent | RoomEvent;
  allowDeleteOption: boolean;
  locationPath: DeskLocationItem[];
  checkInStatus?: EventCheckInStatus | null;
  refetchRoom?: () => void;
  refetchDesk?: () => void;
  qrCodeRequired?: boolean;
  isAttendee?: boolean;
  organizerName?: string;
  isAuthedUsersEvent?: boolean;
  checkInTime?: number;
}

export const ReservationListCard = ({
  type,
  name,
  workplaceId,
  checkInStatus,
  event,
  refetchDesk,
  refetchRoom,
  locationPath,
  qrCodeRequired,
  allowDeleteOption,
  isAttendee,
  organizerName,
  isAuthedUsersEvent,
  checkInTime,
}: Props) => {
  const { timeZone } = useTimeZoneContext();

  const reservedRoom = event?.locations?.find(
    (location) => location.type === "Room"
  );

  const defineStatus = !reservedRoom
    ? false
    : ["Declined", "Maybe"].includes(reservedRoom?.status);

  return (
    <div
      className={`ReservationListCard ${
        defineStatus ? "ReservationListCard__warning" : ""
      }`}
    >
      <ReservationListName
        icon={
          type === "desk"
            ? "desk_icon_18x18"
            : isAttendee
            ? "people-icon-bold"
            : "rooms-icon"
        }
        status={reservedRoom?.status || ""}
        type={type}
        name={type === "desk" || !isAttendee ? name : organizerName || ""}
        isAttendee={isAttendee}
        roomName={reservedRoom?.displayName}
        subject={event?.title}
      />

      <div className="ReservationListCard__time flex-a-center">
        {type === "desk" && (
          <Icon
            icon={
              checkInStatus === EventCheckInStatus.CHECK_IN_ACTIVATED ||
              checkInStatus === EventCheckInStatus.CHECK_IN_NONE
                ? "approve-icon"
                : "approve-empty"
            }
          />
        )}

        {type === "room" && defineStatus && (
          <Icon
            icon={
              reservedRoom?.status === "Declined"
                ? "delete-mark"
                : "question-mark"
            }
          />
        )}

        <h6
          className={`pt-1 ${
            reservedRoom?.status === "Declined"
              ? "text-decoration-line-through"
              : ""
          }`}
        >
          {event.allDay === true
            ? "All day"
            : dateToHoursAndMinutes(new Date(event.startTime), timeZone) +
              " - " +
              dateToHoursAndMinutes(new Date(event.endTime), timeZone)}
        </h6>

        <ReservationListActions
          type={type}
          workplaceId={workplaceId}
          event={event}
          refetchDesk={refetchDesk}
          refetchRoom={refetchRoom}
          startTime={event.startTime}
          endTime={event.endTime}
          name={name}
          locationPath={locationPath}
          checkInStatus={checkInStatus || null}
          qrCodeRequired={qrCodeRequired}
          allowDeleteOption={allowDeleteOption}
          isAttendee={isAttendee}
          isAuthedUsersEvent={isAuthedUsersEvent}
          checkInTime={checkInTime}
          organizerName={organizerName}
          showAttendees={true}
        />
      </div>
    </div>
  );
};
