import React, { PropsWithChildren } from "react";
import cs from "classnames";

import { Icon } from "../../../../shared/Icon/Icon";
import { IconDefinition } from "../../../../shared/Icon/Icon.generated";

export const ModalMFANotification = ({
  icon,
  title,
  className,
}: PropsWithChildren<{
  icon: IconDefinition;
  title: string;
  className?: string;
}>) => {
  const classNames = cs("ModalResponse__content", className);
  return (
    <div className={classNames}>
      <Icon icon={icon} />
      <h6>{title}</h6>
    </div>
  );
};
