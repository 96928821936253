import React, { PropsWithChildren } from "react";

import { Icon } from "../../../shared/Icon/Icon";
import { DirectoryIntegrationsAutoSync } from "../DirectoryIntegrationsAutoSync/DirectoryIntegrationsAutoSync";
import { SyncUsersButton } from "../shared/SyncUsersButton";

export const DirectoryIntegrationsHeaderButtons = ({
  refetchListOfDirectory,
}: PropsWithChildren<{
  refetchListOfDirectory: (offset?: string) => Promise<void>;
}>) => {
  return (
    <div className="flex-a-center gap-1 ml-auto DirectoryIntegrationsHeaderButtons">
      <Icon
        icon="sync-icon"
        className="cursor-pointer"
        onClick={() => refetchListOfDirectory("")}
      />

      <DirectoryIntegrationsAutoSync />

      <SyncUsersButton />
    </div>
  );
};
