import React from "react";
import { Form, FormFeedback, FormGroup } from "reactstrap";

import { TeamFormik } from "./lib/useTeamFormik";

import { Input } from "../../Input/Input";
import { LabelWithInformationBox } from "../../LabelWithInformationBox/LabelWithInformationBox";
import { MemberSelect } from "../../../Teams/MemberSelect/MemberSelect";

interface Props {
  formik: TeamFormik;
  addNewTeam?: boolean;
}

export const TeamForm = (props: Props) => {
  const { formik } = props;

  return (
    <Form>
      <FormGroup>
        <LabelWithInformationBox for="name" title="Name" direction="left" />
        <Input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isTouched={formik.touched.name}
          errorMessage={formik.errors.name}
          invalid={!!formik.errors.name && formik.touched.name}
        />
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="member"
          title="Members"
          direction="left"
        />
        <MemberSelect
          fieldName="member"
          value={formik.values.member}
          onChange={(field, value) => {
            formik.setFieldTouched(field, true);
            formik.setFieldValue(field, value);
          }}
        />
        {!!formik.errors.member && formik.touched.member && (
          <FormFeedback className="invalid-feedback">
            {formik.errors.member}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="location"
          title="Choose location"
          direction="left"
        />
        <Input
          type="text"
          id="location"
          name="location"
          placeholder="Company A"
          className="mb-3"
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isTouched={formik.touched.location}
          errorMessage={formik.errors.location}
          invalid={!!formik.errors.location && formik.touched.location}
        />
        <Input
          type="text"
          id="location"
          name="location"
          placeholder="All locations"
          value={formik.values.location}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.location}
          errorMessage={formik.errors.location}
          invalid={!!formik.errors.location && formik.touched.location}
        />
      </FormGroup>
    </Form>
  );
};
