// @generated by protobuf-ts 2.9.4 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "workplaces.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Struct } from "./google/protobuf/struct";
import { BusyTimeSlots } from "./ggevent/ggevent";
import { Attendee } from "./ggevent/ggevent";
/**
 * @generated from protobuf message Tags
 */
export interface Tags {
    /**
     * @generated from protobuf field: repeated string tags = 1;
     */
    tags: string[];
}
/**
 * @generated from protobuf message FloorStatusRequest
 */
export interface FloorStatusRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string floor_id = 2;
     */
    floorId: string;
    /**
     * @generated from protobuf field: string date_time = 3;
     */
    dateTime: string;
}
/**
 * @generated from protobuf message Marker
 */
export interface Marker {
    /**
     * @generated from protobuf field: int32 latitude = 1;
     */
    latitude: number;
    /**
     * @generated from protobuf field: int32 longitude = 2;
     */
    longitude: number;
}
/**
 * @generated from protobuf message FloorMapRequest
 */
export interface FloorMapRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string floor_id = 2;
     */
    floorId: string;
    /**
     * @generated from protobuf field: string date_time = 3;
     */
    dateTime: string;
    /**
     * @generated from protobuf field: string timeZone = 4;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message FloorMapMetadataRequest
 */
export interface FloorMapMetadataRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string floor_id = 2;
     */
    floorId: string;
}
/**
 * @generated from protobuf message RDXFloorMapRequest
 */
export interface RDXFloorMapRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string room_id = 2;
     */
    roomId: string;
    /**
     * @generated from protobuf field: string date_time = 3;
     */
    dateTime: string;
    /**
     * @generated from protobuf field: string timeZone = 4;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message RFXFloorMapMetadataRequest
 */
export interface RFXFloorMapMetadataRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string rfx_id = 2;
     */
    rfxId: string;
}
/**
 * @generated from protobuf message ZonesMap
 */
export interface ZonesMap {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated MapEntity rooms = 3;
     */
    rooms: MapEntity[];
    /**
     * @generated from protobuf field: repeated MapEntity desks = 4;
     */
    desks: MapEntity[];
    /**
     * @generated from protobuf field: repeated MapEntity wayfinders = 5;
     */
    wayfinders: MapEntity[];
}
/**
 * @generated from protobuf message CircleShape
 */
export interface CircleShape {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int32 latitude = 2;
     */
    latitude: number;
    /**
     * @generated from protobuf field: int32 longitude = 3;
     */
    longitude: number;
    /**
     * @generated from protobuf field: int32 radius = 4;
     */
    radius: number;
}
/**
 * @generated from protobuf message Polygonal
 */
export interface Polygonal {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int32 latitude = 2;
     */
    latitude: number;
    /**
     * @generated from protobuf field: int32 longitude = 3;
     */
    longitude: number;
    /**
     * @generated from protobuf field: string type = 4;
     */
    type: string;
}
/**
 * @generated from protobuf message MapEntity
 */
export interface MapEntity {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string tenant_id = 2;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: Marker marker = 3;
     */
    marker?: Marker;
    /**
     * @generated from protobuf field: bool is_booked = 4;
     */
    isBooked: boolean;
    /**
     * @generated from protobuf field: ggevent.Attendee organizer = 5;
     */
    organizer?: Attendee;
    /**
     * deskBusySlots return an array with busy slots
     *
     * @generated from protobuf field: repeated ggevent.BusyTimeSlots busySlots = 6;
     */
    busySlots: BusyTimeSlots[];
    /**
     * @generated from protobuf field: bool isAssigned = 7;
     */
    isAssigned: boolean;
    /**
     * @generated from protobuf field: string name = 8;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string tags = 9;
     */
    tags: string[];
    /**
     * @generated from protobuf field: bool isBlocked = 10;
     */
    isBlocked: boolean;
    /**
     * Deprecated: use `deleteMeeting`
     *
     * @generated from protobuf field: bool allowDeleteMeetings = 11;
     */
    allowDeleteMeetings: boolean;
    /**
     * @generated from protobuf field: bool deleteMeeting = 12;
     */
    deleteMeeting: boolean;
    /**
     * @generated from protobuf field: bool endMeetingEarly = 13;
     */
    endMeetingEarly: boolean;
    /**
     * @generated from protobuf field: bool extendMeeting = 14;
     */
    extendMeeting: boolean;
    /**
     * @generated from protobuf field: CircleShape circleShape = 15;
     */
    circleShape?: CircleShape;
    /**
     * @generated from protobuf field: repeated Polygonal polygonal = 16;
     */
    polygonal: Polygonal[];
    /**
     * @generated from protobuf field: int32 numberOfSeats = 17;
     */
    numberOfSeats: number;
    /**
     * @generated from protobuf field: AmenitiesFilter amenities = 18;
     */
    amenities?: AmenitiesFilter;
}
/**
 * @generated from protobuf message FloorMapResponse
 */
export interface FloorMapResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated MapEntity rooms = 3;
     */
    rooms: MapEntity[];
    /**
     * @generated from protobuf field: repeated MapEntity desks = 4;
     */
    desks: MapEntity[];
    /**
     * @generated from protobuf field: repeated ZonesMap zones = 5;
     */
    zones: ZonesMap[];
    /**
     * @generated from protobuf field: string url = 6;
     */
    url: string;
    /**
     * @generated from protobuf field: float opacity = 7;
     */
    opacity: number;
    /**
     * @generated from protobuf field: bool showGridline = 8;
     */
    showGridline: boolean;
    /**
     * @generated from protobuf field: repeated MapEntity wayfinders = 9;
     */
    wayfinders: MapEntity[];
    /**
     * @generated from protobuf field: bool showTooltips = 10;
     */
    showTooltips: boolean;
    /**
     * @generated from protobuf field: bool showRoomMarkers = 18;
     */
    showRoomMarkers: boolean;
}
/**
 * @generated from protobuf message DeleteMapRequest
 */
export interface DeleteMapRequest {
    /**
     * @generated from protobuf field: string imageUrl = 1;
     */
    imageUrl: string;
}
/**
 * @generated from protobuf message MapRequest
 */
export interface MapRequest {
    /**
     * @generated from protobuf field: bytes imageData = 1;
     */
    imageData: Uint8Array;
    /**
     * @generated from protobuf field: string imageType = 2;
     */
    imageType: string;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string account_id = 4;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string floor_id = 5;
     */
    floorId: string;
}
/**
 * @generated from protobuf message MapResponse
 */
export interface MapResponse {
    /**
     * @generated from protobuf field: string image_url = 1;
     */
    imageUrl: string;
}
/**
 * @generated from protobuf message ListRoomsRequest
 */
export interface ListRoomsRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ListRoomsResponse
 */
export interface ListRoomsResponse {
    /**
     * @generated from protobuf field: repeated ListRoom rooms = 1;
     */
    rooms: ListRoom[];
}
/**
 * @generated from protobuf message CustomerActiveRoomsRequest
 */
export interface CustomerActiveRoomsRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CustomerActiveRoomsResponse
 */
export interface CustomerActiveRoomsResponse {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: repeated RoomsData rooms = 2;
     */
    rooms: RoomsData[];
}
/**
 * @generated from protobuf message ListRoom
 */
export interface ListRoom {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string resource_email = 3;
     */
    resourceEmail: string;
    /**
     * @generated from protobuf field: string calendar_id = 4;
     */
    calendarId: string;
}
/**
 * @generated from protobuf message GetRoomRequest
 */
export interface GetRoomRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // Room id
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string; // customer ID
    /**
     * @generated from protobuf field: string timeZone = 3;
     */
    timeZone: string; // user timezone
    /**
     * @generated from protobuf field: string time = 4;
     */
    time: string;
    /**
     * @generated from protobuf field: string personal_calendar_id = 5;
     */
    personalCalendarId: string; // this value required for set correct internal/external status for rooms
}
/**
 * @generated from protobuf message GetRoomResponse
 */
export interface GetRoomResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string calendar_provider_id = 3;
     */
    calendarProviderId: string;
    /**
     * @generated from protobuf field: string calendar_id = 4;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: string resource_email = 5;
     */
    resourceEmail: string;
    /**
     * @generated from protobuf field: string availableUntil = 6;
     */
    availableUntil: string; // The time in UTC the room is available until.
    /**
     * @generated from protobuf field: string availableFrom = 7;
     */
    availableFrom: string; // The time in UTC the room is first available from.
    /**
     * @generated from protobuf field: bool isBusy = 8;
     */
    isBusy: boolean; // Read-only. IsBusy is true when room is currently busy.
    /**
     * @generated from protobuf field: repeated LocationItem path = 9;
     */
    path: LocationItem[];
    /**
     * @generated from protobuf field: int32 seats = 10;
     */
    seats: number;
    /**
     * @generated from protobuf field: AmenitiesFilter availableEquipment = 11;
     */
    availableEquipment?: AmenitiesFilter;
    /**
     * @generated from protobuf field: RoomDisplaySettings displaySettings = 12;
     */
    displaySettings?: RoomDisplaySettings;
    /**
     * @generated from protobuf field: bool isExternal = 13;
     */
    isExternal: boolean;
}
/**
 * @generated from protobuf message ListTagsRequest
 */
export interface ListTagsRequest {
    /**
     * @generated from protobuf field: string location_id = 1;
     */
    locationId: string; // ID of the GraphQL object that the rooms are under.
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string; // Google Tenant ID
    /**
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number; // Limit amount of tags returned.
    /**
     * @generated from protobuf field: ListTagsEntity entityType = 4;
     */
    entityType: ListTagsEntity; // return
}
/**
 * @generated from protobuf message ListAvailableRoomsRequest
 */
export interface ListAvailableRoomsRequest {
    /**
     * @generated from protobuf field: string location_id = 1;
     */
    locationId: string; // ID of the GraphQL object that the rooms are under.
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string; // Google Tenant ID
    /**
     * @generated from protobuf field: string from = 3;
     */
    from: string; // From datetime that the room should be available from in RFC339 format.
    /**
     * @generated from protobuf field: string to = 4;
     */
    to: string; // To datetime that the room should be available to in RFC339 format.
    /**
     * @generated from protobuf field: repeated string tags = 5;
     */
    tags: string[]; // List of tags belonging to the room(s).
    /**
     * @generated from protobuf field: AmenitiesFilter equipment = 6;
     */
    equipment?: AmenitiesFilter; // Filter to include what equipment a room should provide.
    /**
     * @generated from protobuf field: int32 min_nr_seats = 7;
     */
    minNrSeats: number; // Minimum number of seats the room should provide. Default is 0.
    /**
     * @generated from protobuf field: int32 max_nr_seats = 8;
     */
    maxNrSeats: number; // Maximum number of seats the room should provide. Default is 0.
    /**
     * @generated from protobuf field: string timeZone = 9;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: string personal_calendar_provider_id = 10;
     */
    personalCalendarProviderId: string; // this value required for set correct internal/external status for rooms
    /**
     * @generated from protobuf field: int32 page = 11;
     */
    page: number; // need for pagination
}
/**
 * Response for ListAvailableRooms
 *
 * @generated from protobuf message AvailableRoomsResponse
 */
export interface AvailableRoomsResponse {
    /**
     * @generated from protobuf field: repeated AvailableRoom rooms = 1;
     */
    rooms: AvailableRoom[]; // List of rooms
    /**
     * @generated from protobuf field: int32 next_page = 2;
     */
    nextPage: number;
}
/**
 * @generated from protobuf message AvailableRoom
 */
export interface AvailableRoom {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // GraphQL object id.
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // Name of the room.
    /**
     * @generated from protobuf field: string calendarID = 3;
     */
    calendarID: string; // CalendarID of the room which is the email or id of the room resource.
    /**
     * @generated from protobuf field: string calendarProviderID = 4;
     */
    calendarProviderID: string; // The ID of the calendar integration.
    /**
     * @generated from protobuf field: string availableFrom = 5;
     */
    availableFrom: string; // Datetime the room is available from in RFC339 format.
    /**
     * @generated from protobuf field: string availableTo = 6;
     */
    availableTo: string; // Datetime the room is available to in RFC339 format.
    /**
     * @generated from protobuf field: string resourceEmail = 7;
     */
    resourceEmail: string;
    /**
     * @generated from protobuf field: repeated LocationItem locationPath = 8;
     */
    locationPath: LocationItem[];
    /**
     * @generated from protobuf field: string nextReservationTime = 9;
     */
    nextReservationTime: string;
    /**
     * @generated from protobuf field: string description = 10;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string tags = 11;
     */
    tags: string[];
    /**
     * @generated from protobuf field: AmenitiesFilter availableEquipment = 12;
     */
    availableEquipment?: AmenitiesFilter;
    /**
     * @generated from protobuf field: bool isExternal = 13;
     */
    isExternal: boolean;
    /**
     * @generated from protobuf field: int32 seats = 14;
     */
    seats: number;
}
/**
 * @generated from protobuf message NearbyRoomsRequest
 */
export interface NearbyRoomsRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: repeated string room_ids = 2;
     */
    roomIds: string[];
    /**
     * @generated from protobuf field: int32 min_nr_seats = 3;
     */
    minNrSeats: number;
    /**
     * @generated from protobuf field: int32 max_nr_seats = 4;
     */
    maxNrSeats: number;
    /**
     * @generated from protobuf field: AmenitiesFilter filter = 5;
     */
    filter?: AmenitiesFilter;
    /**
     * @generated from protobuf field: bool showAll = 6;
     */
    showAll: boolean;
    /**
     * @generated from protobuf field: string date = 7;
     */
    date: string;
    /**
     * @generated from protobuf field: string timeZone = 8;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message AmenitiesFilter
 */
export interface AmenitiesFilter {
    /**
     * @generated from protobuf field: bool accessibility = 1;
     */
    accessibility: boolean;
    /**
     * @generated from protobuf field: bool casting = 2;
     */
    casting: boolean;
    /**
     * @generated from protobuf field: bool climate = 3;
     */
    climate: boolean;
    /**
     * @generated from protobuf field: bool desk = 4;
     */
    desk: boolean;
    /**
     * @generated from protobuf field: bool audio = 5;
     */
    audio: boolean;
    /**
     * @generated from protobuf field: bool chargingPort = 6;
     */
    chargingPort: boolean;
    /**
     * @generated from protobuf field: bool presentationTech = 7;
     */
    presentationTech: boolean;
    /**
     * @generated from protobuf field: bool videoConference = 8;
     */
    videoConference: boolean;
    /**
     * @generated from protobuf field: bool faluty = 9;
     */
    faluty: boolean;
    /**
     * @generated from protobuf field: string id = 10;
     */
    id: string;
}
/**
 * @generated from protobuf message NearbyRoomsResponse
 */
export interface NearbyRoomsResponse {
    /**
     * @generated from protobuf field: repeated NearbyRoom nearbyRooms = 1;
     */
    nearbyRooms: NearbyRoom[];
}
/**
 * @generated from protobuf message NearbyRoom
 */
export interface NearbyRoom {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string calendarID = 3;
     */
    calendarID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 4;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: int32 numberOfSeats = 5;
     */
    numberOfSeats: number;
    /**
     * @generated from protobuf field: bool isFree = 6;
     */
    isFree: boolean;
    /**
     * @generated from protobuf field: AmenitiesFilter filter = 7;
     */
    filter?: AmenitiesFilter;
    /**
     * @generated from protobuf field: string error = 8;
     */
    error: string;
    /**
     * @generated from protobuf field: string locationID = 9;
     */
    locationID: string;
    /**
     * @generated from protobuf field: bool extendMeeting = 10;
     */
    extendMeeting: boolean;
    /**
     * @generated from protobuf field: bool deleteMeeting = 11;
     */
    deleteMeeting: boolean;
    /**
     * @generated from protobuf field: bool endMeetingEarly = 12;
     */
    endMeetingEarly: boolean;
}
/**
 * @generated from protobuf message WorkplaceQueryRequest
 */
export interface WorkplaceQueryRequest {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: google.protobuf.Struct variables = 2;
     */
    variables?: Struct;
    /**
     * @generated from protobuf field: string operationName = 3;
     */
    operationName: string;
}
/**
 * @generated from protobuf message WorkplaceQueryResponse
 */
export interface WorkplaceQueryResponse {
    /**
     * @generated from protobuf field: google.protobuf.Struct data = 1;
     */
    data?: Struct;
    /**
     * @generated from protobuf field: google.protobuf.Struct extensions = 2;
     */
    extensions?: Struct;
    /**
     * @generated from protobuf field: repeated GraphQLError errors = 3;
     */
    errors: GraphQLError[];
}
/**
 * @generated from protobuf message GraphQLError
 */
export interface GraphQLError {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
    /**
     * @generated from protobuf field: repeated Location locations = 2;
     */
    locations: Location[];
    /**
     * @generated from protobuf field: repeated string path = 3;
     */
    path: string[];
}
/**
 * @generated from protobuf message Location
 */
export interface Location {
    /**
     * @generated from protobuf field: int32 line = 1;
     */
    line: number;
    /**
     * @generated from protobuf field: int32 column = 2;
     */
    column: number;
}
/**
 * @generated from protobuf message CalendarRoomsRequest
 */
export interface CalendarRoomsRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarProviderId = 2;
     */
    calendarProviderId: string;
}
/**
 * @generated from protobuf message CalendarRoom
 */
export interface CalendarRoom {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string calendarId = 3;
     */
    calendarId: string;
}
/**
 * @generated from protobuf message CalendarRoomsResponse
 */
export interface CalendarRoomsResponse {
    /**
     * @generated from protobuf field: repeated CalendarRoom rooms = 1;
     */
    rooms: CalendarRoom[];
}
/**
 * @generated from protobuf message DeleteWorkplaceRequest
 */
export interface DeleteWorkplaceRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message DisconnectCalendarProviderResponse
 */
export interface DisconnectCalendarProviderResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message SearchRequest
 */
export interface SearchRequest {
    /**
     * @generated from protobuf field: string locationID = 1;
     */
    locationID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated string filterTags = 3;
     */
    filterTags: string[]; // return desks with tags in list
    /**
     * @generated from protobuf field: bool onlyFree = 4;
     */
    onlyFree: boolean;
    /**
     * @generated from protobuf field: bool ignoreBlocked = 5;
     */
    ignoreBlocked: boolean;
    /**
     * @generated from protobuf field: bool allDesks = 6;
     */
    allDesks: boolean;
    /**
     * @generated from protobuf field: string timeZone = 7;
     */
    timeZone: string;
    /**
     * @generated from protobuf field: int32 page = 8;
     */
    page: number; // need for pagination
    /**
     * @generated from protobuf field: int32 pageSize = 9;
     */
    pageSize: number; // need for pagination
}
/**
 * @generated from protobuf message BatchSearchRequest
 */
export interface BatchSearchRequest {
    /**
     * @generated from protobuf field: repeated string locationsID = 1;
     */
    locationsID: string[]; // locations should be with the same type
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: repeated string filterTags = 3;
     */
    filterTags: string[]; // return desks with tags in list
    /**
     * @generated from protobuf field: bool onlyFree = 4;
     */
    onlyFree: boolean;
}
/**
 * @generated from protobuf message SearchAccountDesksRequest
 */
export interface SearchAccountDesksRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message SearchDesk
 */
export interface SearchDesk {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: bool licensed = 5;
     */
    licensed: boolean;
    /**
     * @generated from protobuf field: string typeName = 6;
     */
    typeName: string;
    /**
     * @generated from protobuf field: string locationID = 7;
     */
    locationID: string;
    /**
     * @generated from protobuf field: repeated LocationItem locationPath = 8;
     */
    locationPath: LocationItem[];
    /**
     * @generated from protobuf field: bool checkInRequired = 9;
     */
    checkInRequired: boolean;
    /**
     * @generated from protobuf field: int32 checkInTime = 10;
     */
    checkInTime: number;
    /**
     * @generated from protobuf field: bool qrCodeRequired = 11;
     */
    qrCodeRequired: boolean;
    /**
     * @generated from protobuf field: Marker marker = 12;
     */
    marker?: Marker;
    /**
     * @generated from protobuf field: bool workingHrsActivated = 13;
     */
    workingHrsActivated: boolean;
    /**
     * @generated from protobuf field: string workingHrsStart = 14;
     */
    workingHrsStart: string;
    /**
     * @generated from protobuf field: string workingHrsEnd = 15;
     */
    workingHrsEnd: string;
    /**
     * @generated from protobuf field: WorkingHrs WorkingHrs = 16 [json_name = "WorkingHrs"];
     */
    workingHrs?: WorkingHrs;
    /**
     * @generated from protobuf field: SearchDesk.Account account = 17;
     */
    account?: SearchDesk_Account;
    /**
     * @generated from protobuf field: bool isBlocked = 18;
     */
    isBlocked: boolean;
    /**
     * @generated from protobuf field: repeated int32 workingWeekDays = 19;
     */
    workingWeekDays: number[];
    /**
     * @generated from protobuf field: bool showCheckInTime = 20;
     */
    showCheckInTime: boolean;
    /**
     * @generated from protobuf field: bool isBooked = 21;
     */
    isBooked: boolean;
}
/**
 * @generated from protobuf message SearchDesk.Account
 */
export interface SearchDesk_Account {
    /**
     * @generated from protobuf field: string displayName = 1;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string tenantID = 3;
     */
    tenantID: string;
    /**
     * @generated from protobuf field: string accountID = 4;
     */
    accountID: string;
}
/**
 * @generated from protobuf message SearchDesksResponse
 */
export interface SearchDesksResponse {
    /**
     * @generated from protobuf field: repeated SearchDesk desks = 1;
     */
    desks: SearchDesk[];
}
/**
 * @generated from protobuf message LocationItem
 */
export interface LocationItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: int32 index = 4;
     */
    index: number;
}
/**
 * @generated from protobuf message LocationPathResponse
 */
export interface LocationPathResponse {
    /**
     * @generated from protobuf field: repeated LocationItem path = 1;
     */
    path: LocationItem[];
}
/**
 * @generated from protobuf message BatchLocationPathResponse
 */
export interface BatchLocationPathResponse {
    /**
     * @generated from protobuf field: repeated BatchLocationPathResponse.BatchLocationItem items = 1;
     */
    items: BatchLocationPathResponse_BatchLocationItem[];
}
/**
 * @generated from protobuf message BatchLocationPathResponse.BatchLocationItem
 */
export interface BatchLocationPathResponse_BatchLocationItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: repeated LocationItem path = 4;
     */
    path: LocationItem[];
}
/**
 * @generated from protobuf message SameLevelDesksRequest
 */
export interface SameLevelDesksRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: bool licensedOnly = 3;
     */
    licensedOnly: boolean;
}
/**
 * @generated from protobuf message DesksListResponse
 */
export interface DesksListResponse {
    /**
     * @generated from protobuf field: repeated SearchDesk desks = 1;
     */
    desks: SearchDesk[];
}
/**
 * @generated from protobuf message DesksInfoRequest
 */
export interface DesksInfoRequest {
    /**
     * @generated from protobuf field: repeated string id = 1;
     */
    id: string[];
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string timeZone = 4;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message RoomsInfo
 */
export interface RoomsInfo {
    /**
     * @generated from protobuf field: string calendarID = 1;
     */
    calendarID: string;
    /**
     * @generated from protobuf field: string roomID = 2;
     */
    roomID: string;
}
/**
 * @generated from protobuf message RoomsInfoRequest
 */
export interface RoomsInfoRequest {
    /**
     * @generated from protobuf field: repeated RoomsInfo rooms = 1;
     */
    rooms: RoomsInfo[];
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string wayfinderID = 3;
     */
    wayfinderID: string;
    /**
     * @generated from protobuf field: string timeZone = 4;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message GraphCustomLanguage
 */
export interface GraphCustomLanguage {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
}
/**
 * @generated from protobuf message RoomDisplaySettings
 */
export interface RoomDisplaySettings {
    /**
     * @generated from protobuf field: bool showCheckinRequired = 1;
     */
    showCheckinRequired: boolean;
    /**
     * @generated from protobuf field: bool workingHrsActivated = 2;
     */
    workingHrsActivated: boolean;
    /**
     * Deprecated: use workingHrs
     *
     * @generated from protobuf field: string workingHrsStart = 3;
     */
    workingHrsStart: string;
    /**
     * Deprecated: use workingHrs
     *
     * @generated from protobuf field: string workingHrsEnd = 4;
     */
    workingHrsEnd: string;
    /**
     * @generated from protobuf field: WorkingHrs workingHrs = 5;
     */
    workingHrs?: WorkingHrs;
    /**
     * @generated from protobuf field: repeated int32 workingWeekDays = 6;
     */
    workingWeekDays: number[];
    /**
     * @generated from protobuf field: bool deleteMeeting = 7;
     */
    deleteMeeting: boolean;
    /**
     * @generated from protobuf field: bool endMeetingEarly = 8;
     */
    endMeetingEarly: boolean;
    /**
     * @generated from protobuf field: bool extendMeeting = 9;
     */
    extendMeeting: boolean;
    /**
     * @generated from protobuf field: GraphCustomLanguage customLanguage = 10;
     */
    customLanguage?: GraphCustomLanguage;
    /**
     * Deprecated: use deleteMeeting
     *
     * @generated from protobuf field: bool allowToDeleteMeetings = 17;
     */
    allowToDeleteMeetings: boolean;
    /**
     * @generated from protobuf field: string language = 20;
     */
    language: string;
    /**
     * @generated from protobuf field: bool removeGhostRecurringEvents = 21;
     */
    removeGhostRecurringEvents: boolean;
    /**
     * @generated from protobuf field: bool showMeetingOrganizer = 22;
     */
    showMeetingOrganizer: boolean;
    /**
     * @generated from protobuf field: bool showMeetingTitle = 23;
     */
    showMeetingTitle: boolean;
    /**
     * @generated from protobuf field: bool showAttendees = 24;
     */
    showAttendees: boolean;
}
/**
 * @generated from protobuf message RoomDirection
 */
export interface RoomDirection {
    /**
     * @generated from protobuf field: int32 distance = 1;
     */
    distance: number;
    /**
     * @generated from protobuf field: int32 direction = 2;
     */
    direction: number;
    /**
     * @generated from protobuf field: string distanceUnit = 3;
     */
    distanceUnit: string;
    /**
     * @generated from protobuf field: string id = 4;
     */
    id: string;
    /**
     * @generated from protobuf field: string roomID = 5;
     */
    roomID: string;
    /**
     * @generated from protobuf field: string wayfinderID = 6;
     */
    wayfinderID: string;
}
/**
 * @generated from protobuf message RoomsData
 */
export interface RoomsData {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string locationID = 3;
     */
    locationID: string;
    /**
     * @generated from protobuf field: string calendarID = 4;
     */
    calendarID: string;
    /**
     * @generated from protobuf field: string calendarProviderID = 5;
     */
    calendarProviderID: string;
    /**
     * @generated from protobuf field: repeated LocationItem locationPath = 6;
     */
    locationPath: LocationItem[];
    /**
     * @generated from protobuf field: bool checkInRequired = 9;
     */
    checkInRequired: boolean;
    /**
     * @generated from protobuf field: int32 checkInTime = 10;
     */
    checkInTime: number;
    /**
     * @generated from protobuf field: bool qrCodeRequired = 11;
     */
    qrCodeRequired: boolean;
    /**
     * @generated from protobuf field: string resourceEmail = 12;
     */
    resourceEmail: string;
    /**
     * @generated from protobuf field: RoomDisplaySettings displaySettings = 13;
     */
    displaySettings?: RoomDisplaySettings;
    /**
     * @generated from protobuf field: int32 numberOfSeats = 14;
     */
    numberOfSeats: number;
    /**
     * @generated from protobuf field: repeated RoomDirection direction = 15;
     */
    direction: RoomDirection[];
    /**
     * @generated from protobuf field: bool licensed = 16;
     */
    licensed: boolean;
    /**
     * @generated from protobuf field: bool calendarProviderActive = 17;
     */
    calendarProviderActive: boolean;
    /**
     * @generated from protobuf field: bool checkinReminder = 18;
     */
    checkinReminder: boolean;
    /**
     * @generated from protobuf field: bool isBlocked = 19;
     */
    isBlocked: boolean;
    /**
     * @generated from protobuf field: repeated string tags = 20;
     */
    tags: string[];
    /**
     * @generated from protobuf field: AmenitiesFilter equipment = 21;
     */
    equipment?: AmenitiesFilter;
}
/**
 * @generated from protobuf message RoomsInfoResponse
 */
export interface RoomsInfoResponse {
    /**
     * @generated from protobuf field: repeated RoomsData rooms = 1;
     */
    rooms: RoomsData[];
}
/**
 * @generated from protobuf message GenerateQRCodeRequest
 */
export interface GenerateQRCodeRequest {
    /**
     * @generated from protobuf field: string entityID = 1;
     */
    entityID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: ListTagsEntity entityType = 3;
     */
    entityType: ListTagsEntity;
}
/**
 * @generated from protobuf message GenerateQRCodeResponse
 */
export interface GenerateQRCodeResponse {
    /**
     * @generated from protobuf field: bytes base64 = 1;
     */
    base64: Uint8Array;
}
/**
 * @generated from protobuf message GetDeskRequest
 */
export interface GetDeskRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // Desk id
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string; // customer ID
    /**
     * @generated from protobuf field: string timeZone = 3;
     */
    timeZone: string; // user timezone
    /**
     * @generated from protobuf field: string time = 4;
     */
    time: string;
    /**
     * @generated from protobuf field: string accountId = 5;
     */
    accountId: string;
}
/**
 * @generated from protobuf message GetDeskResponse
 */
export interface GetDeskResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string availableFrom = 3;
     */
    availableFrom: string; // The time in UTC the desk is first available from.
    /**
     * @generated from protobuf field: string availableUntil = 4;
     */
    availableUntil: string; // The time in UTC the desk is available until.
    /**
     * @generated from protobuf field: bool isBusy = 5;
     */
    isBusy: boolean; // Read-only. IsBusy is true when desk is currently busy.
    /**
     * @generated from protobuf field: repeated string tags = 6;
     */
    tags: string[];
    /**
     * @generated from protobuf field: repeated LocationItem path = 7;
     */
    path: LocationItem[];
    /**
     * @generated from protobuf field: WorkingHrs workingHrs = 8;
     */
    workingHrs?: WorkingHrs;
    /**
     * @generated from protobuf field: bool isBlocked = 9;
     */
    isBlocked: boolean;
    /**
     * @generated from protobuf field: bool isAssigned = 10;
     */
    isAssigned: boolean;
    /**
     * @generated from protobuf field: repeated ggevent.BusyTimeSlots busySlots = 11;
     */
    busySlots: BusyTimeSlots[];
}
/**
 * @generated from protobuf message DesksBulkCreateRequest
 */
export interface DesksBulkCreateRequest {
    /**
     * @generated from protobuf field: int32 desksNumber = 1;
     */
    desksNumber: number;
    /**
     * @generated from protobuf field: int32 startIndex = 2;
     */
    startIndex: number;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string tags = 5;
     */
    tags: string[];
    /**
     * @generated from protobuf field: bool isBooked = 6;
     */
    isBooked: boolean;
    /**
     * @generated from protobuf field: bool showCheckInTime = 7;
     */
    showCheckInTime: boolean;
    /**
     * @generated from protobuf field: bool qrCodeRequired = 8;
     */
    qrCodeRequired: boolean;
    /**
     * @generated from protobuf field: uint32 checkInTime = 9;
     */
    checkInTime: number;
    /**
     * @generated from protobuf field: string tenantID = 10;
     */
    tenantID: string;
    /**
     * @generated from protobuf field: string locationID = 11;
     */
    locationID: string;
    /**
     * @generated from protobuf field: Marker marker = 12;
     */
    marker?: Marker;
    /**
     * @generated from protobuf field: bool licensed = 13;
     */
    licensed: boolean;
}
/**
 * @generated from protobuf message DesksBulkCreateResponse
 */
export interface DesksBulkCreateResponse {
    /**
     * @generated from protobuf field: repeated SearchDesk desks = 1;
     */
    desks: SearchDesk[];
    /**
     * @generated from protobuf field: string licenseErrorMessage = 2;
     */
    licenseErrorMessage: string;
}
/**
 * @generated from protobuf message WorkingHrs
 */
export interface WorkingHrs {
    /**
     * time in format hh:mm:ss
     *
     * @generated from protobuf field: string from = 1;
     */
    from: string;
    /**
     * time in format hh:mm:ss
     *
     * @generated from protobuf field: string to = 2;
     */
    to: string;
    /**
     * @generated from protobuf field: string tenant_id = 4;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: bool isGlobal = 5;
     */
    isGlobal: boolean;
}
/**
 * @generated from protobuf message UpdateGlobalWorkingHrsRequest
 */
export interface UpdateGlobalWorkingHrsRequest {
    /**
     * time in format hh:mm:ss
     *
     * @generated from protobuf field: string from = 1;
     */
    from: string;
    /**
     * time in format hh:mm:ss
     *
     * @generated from protobuf field: string to = 2;
     */
    to: string;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
    /**
     * old_working_week_days is a working week days indexes (0 - Sunday, 1 - Monday, ....)
     *
     * @generated from protobuf field: repeated int32 old_working_week_days = 4;
     */
    oldWorkingWeekDays: number[];
    /**
     * new_working_week_days is a working week days indexes (0 - Sunday, 1 - Monday, ....)
     *
     * @generated from protobuf field: repeated int32 new_working_week_days = 5;
     */
    newWorkingWeekDays: number[];
}
/**
 * @generated from protobuf message UpdateGlobalWorkingHrsResponse
 */
export interface UpdateGlobalWorkingHrsResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message SendErrorReportRequest
 */
export interface SendErrorReportRequest {
    /**
     * @generated from protobuf field: string room_id = 1;
     */
    roomId: string;
    /**
     * @generated from protobuf field: ReportState error_state = 2;
     */
    errorState: ReportState;
    /**
     * @generated from protobuf field: string room_name = 3;
     */
    roomName: string;
    /**
     * @generated from protobuf field: string timestamp = 4;
     */
    timestamp: string;
    /**
     * @generated from protobuf field: ErrorReportType type = 5;
     */
    type: ErrorReportType;
    /**
     * @generated from protobuf field: repeated string faulty_equipment = 6;
     */
    faultyEquipment: string[];
    /**
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * @generated from protobuf field: string message = 8;
     */
    message: string;
    /**
     * @generated from protobuf field: string customer_id = 9;
     */
    customerId: string;
}
/**
 * @generated from protobuf message SetDevicePingInfoRequest
 */
export interface SetDevicePingInfoRequest {
    /**
     * @generated from protobuf field: string deviceID = 1;
     */
    deviceID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message UploadCustomLanguageRequest
 */
export interface UploadCustomLanguageRequest {
    /**
     * @generated from protobuf field: bytes language_data = 1;
     */
    languageData: Uint8Array;
    /**
     * @generated from protobuf field: string language_code = 2;
     */
    languageCode: string;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message UploadCustomLanguageResponse
 */
export interface UploadCustomLanguageResponse {
    /**
     * @generated from protobuf field: string lang_url = 1;
     */
    langUrl: string;
}
/**
 * @generated from protobuf message DeleteCustomLanguageRequest
 */
export interface DeleteCustomLanguageRequest {
    /**
     * @generated from protobuf field: string lang_url = 1;
     */
    langUrl: string;
}
/**
 * @generated from protobuf message GetCustomLanguageRequest
 */
export interface GetCustomLanguageRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string lang_code = 2;
     */
    langCode: string;
}
/**
 * @generated from protobuf message CustomLanguageResponse
 */
export interface CustomLanguageResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
    /**
     * @generated from protobuf field: string language_code = 3;
     */
    languageCode: string;
    /**
     * @generated from protobuf field: bytes language_data = 4;
     */
    languageData: Uint8Array;
}
/**
 * @generated from protobuf message GetDefaultLanguageRequest
 */
export interface GetDefaultLanguageRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: CustomLanguageAppType app_type = 2;
     */
    appType: CustomLanguageAppType;
}
/**
 * @generated from protobuf message GetDefaultLanguageResponse
 */
export interface GetDefaultLanguageResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
    /**
     * @generated from protobuf field: string language_code = 3;
     */
    languageCode: string;
    /**
     * @generated from protobuf field: bytes language_data = 4;
     */
    languageData: Uint8Array;
}
/**
 * @generated from protobuf message CheckDeviceVersionRequest
 */
export interface CheckDeviceVersionRequest {
    /**
     * @generated from protobuf field: string device_id = 1;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CheckDeviceVersionResponse
 */
export interface CheckDeviceVersionResponse {
    /**
     * @generated from protobuf field: string latest_version = 1;
     */
    latestVersion: string;
    /**
     * @generated from protobuf field: bool is_update_required = 2;
     */
    isUpdateRequired: boolean;
}
/**
 * @generated from protobuf message UpdateDeviceAppRequest
 */
export interface UpdateDeviceAppRequest {
    /**
     * @generated from protobuf field: string device_id = 1;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CreateCustomerGlobalCustomLanguagesRequest
 */
export interface CreateCustomerGlobalCustomLanguagesRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ListCustomerLanguagesRequest
 */
export interface ListCustomerLanguagesRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: CustomLanguageAppType app_type = 2;
     */
    appType: CustomLanguageAppType;
}
/**
 * @generated from protobuf message CustomerLanguages
 */
export interface CustomerLanguages {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 4;
     */
    value: string;
    /**
     * @generated from protobuf field: string customer_id = 5;
     */
    customerId: string;
    /**
     * @generated from protobuf field: bool is_global = 6;
     */
    isGlobal: boolean;
}
/**
 * @generated from protobuf message ListCustomerLanguagesResponse
 */
export interface ListCustomerLanguagesResponse {
    /**
     * @generated from protobuf field: repeated CustomerLanguages data = 1;
     */
    data: CustomerLanguages[];
}
/**
 * @generated from protobuf message DeleteCustomerGlobalCustomLanguagesRequest
 */
export interface DeleteCustomerGlobalCustomLanguagesRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message RetrieveCustomerGlobalCustomLanguagesRequest
 */
export interface RetrieveCustomerGlobalCustomLanguagesRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf message RetrieveCustomerGlobalCustomLanguagesResponse
 */
export interface RetrieveCustomerGlobalCustomLanguagesResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
    /**
     * @generated from protobuf field: bool isGlobal = 4;
     */
    isGlobal: boolean;
    /**
     * @generated from protobuf field: bytes language_data = 5;
     */
    languageData: Uint8Array;
}
/**
 * @generated from protobuf message UpdateCustomerGlobalCustomLanguagesRequest
 */
export interface UpdateCustomerGlobalCustomLanguagesRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: bytes language_data = 4;
     */
    languageData: Uint8Array;
}
/**
 * @generated from protobuf message UpdateCustomerGlobalCustomLanguagesResponse
 */
export interface UpdateCustomerGlobalCustomLanguagesResponse {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
    /**
     * @generated from protobuf field: bool is_global = 4;
     */
    isGlobal: boolean;
    /**
     * @generated from protobuf field: bytes language_data = 5;
     */
    languageData: Uint8Array;
}
/**
 * @generated from protobuf message CustomLanguageFieldPath
 */
export interface CustomLanguageFieldPath {
    /**
     * @generated from protobuf field: string path = 1;
     */
    path: string; // path to fields in language_data. Ex ["settings/version", "general/room"] etc
    /**
     * @generated from protobuf field: CustomLanguageAppType app_type = 2;
     */
    appType: CustomLanguageAppType;
}
/**
 * @generated from protobuf message SyncCustomLanguageFieldsRequest
 */
export interface SyncCustomLanguageFieldsRequest {
    /**
     * @generated from protobuf field: repeated CustomLanguageFieldPath fieldsPath = 1;
     */
    fieldsPath: CustomLanguageFieldPath[];
}
/**
 * @generated from protobuf message AssignCustomerGlobalCustomLanguageRequest
 */
export interface AssignCustomerGlobalCustomLanguageRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string language_id = 2;
     */
    languageId: string;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
    /**
     * @generated from protobuf field: CustomLanguageAppType app_type = 4;
     */
    appType: CustomLanguageAppType;
}
/**
 * @generated from protobuf message OnLevelSearchRequest
 */
export interface OnLevelSearchRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: OnLevelSearchRequestLicenseStatus licenseStatus = 3;
     */
    licenseStatus: OnLevelSearchRequestLicenseStatus;
    /**
     * @generated from protobuf field: string nameFilter = 4;
     */
    nameFilter: string;
    /**
     * @generated from protobuf field: repeated WorkplaceLocationType locationFilter = 5;
     */
    locationFilter: WorkplaceLocationType[];
    /**
     * @generated from protobuf field: repeated WorkplaceResourceType resourceFilter = 6;
     */
    resourceFilter: WorkplaceResourceType[];
    /**
     * @generated from protobuf field: repeated string tagsFilter = 7;
     */
    tagsFilter: string[];
}
/**
 * @generated from protobuf message OnLevelSearchResponse
 */
export interface OnLevelSearchResponse {
    /**
     * @generated from protobuf field: repeated OnLevelSearchResponse.OnLevelSearchItem data = 1;
     */
    data: OnLevelSearchResponse_OnLevelSearchItem[];
}
/**
 * @generated from protobuf message OnLevelSearchResponse.OnLevelSearchDevice
 */
export interface OnLevelSearchResponse_OnLevelSearchDevice {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message OnLevelSearchResponse.OnLevelSearchItem
 */
export interface OnLevelSearchResponse_OnLevelSearchItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: bool licensed = 3;
     */
    licensed: boolean;
    /**
     * @generated from protobuf field: repeated string tags = 4;
     */
    tags: string[];
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: string calendar_id = 6;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: string calendar_provider_id = 7;
     */
    calendarProviderId: string;
    /**
     * @generated from protobuf field: repeated OnLevelSearchResponse.OnLevelSearchDevice devices = 8;
     */
    devices: OnLevelSearchResponse_OnLevelSearchDevice[];
    /**
     * @generated from protobuf field: string type_name = 9;
     */
    typeName: string;
}
/**
 * @generated from protobuf message SyncFloorMapMetadataRequest
 */
export interface SyncFloorMapMetadataRequest {
    /**
     * @generated from protobuf field: string floorId = 1;
     */
    floorId: string;
    /**
     * @generated from protobuf field: string customerId = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string timeZone = 3;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message SyncFloorMapMetadataResponse
 */
export interface SyncFloorMapMetadataResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message GetMapEntityFloorIDRequest
 */
export interface GetMapEntityFloorIDRequest {
    /**
     * @generated from protobuf field: string entityId = 1;
     */
    entityId: string;
    /**
     * @generated from protobuf field: WorkplaceResourceType entityType = 2;
     */
    entityType: WorkplaceResourceType;
    /**
     * @generated from protobuf field: string customerId = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message GetMapEntityFloorIDResponse
 */
export interface GetMapEntityFloorIDResponse {
    /**
     * @generated from protobuf field: string floorId = 1;
     */
    floorId: string;
}
/**
 * @generated from protobuf message GetConnectedFloorMapRequest
 */
export interface GetConnectedFloorMapRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string floorId = 2;
     */
    floorId: string;
}
/**
 * @generated from protobuf message GetConnectedFloorMapResponse
 */
export interface GetConnectedFloorMapResponse {
    /**
     * @generated from protobuf field: string mapId = 1;
     */
    mapId: string;
}
/**
 * @generated from protobuf message UpdateRoomCalIntegrationStatusRequest
 */
export interface UpdateRoomCalIntegrationStatusRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string calendarProviderId = 2;
     */
    calendarProviderId: string;
    /**
     * @generated from protobuf field: bool isActive = 3;
     */
    isActive: boolean;
}
/**
 * @generated from protobuf message UpdateRoomCalIntegrationStatusResponse
 */
export interface UpdateRoomCalIntegrationStatusResponse {
    /**
     * @generated from protobuf field: string status = 1;
     */
    status: string;
}
/**
 * @generated from protobuf message RoomTemplateSettings
 */
export interface RoomTemplateSettings {
    /**
     * @generated from protobuf field: string description = 1;
     */
    description: string;
    /**
     * @generated from protobuf field: int32 numberOfSeats = 2;
     */
    numberOfSeats: number;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
    /**
     * @generated from protobuf field: AmenitiesFilter amenities = 4;
     */
    amenities?: AmenitiesFilter;
    /**
     * @generated from protobuf field: bool brokenAmenitiesReporting = 5;
     */
    brokenAmenitiesReporting: boolean;
    /**
     * @generated from protobuf field: bool isBlocked = 6;
     */
    isBlocked: boolean;
}
/**
 * @generated from protobuf message MapFloor
 */
export interface MapFloor {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message RoomTemplateDisplaySettings
 */
export interface RoomTemplateDisplaySettings {
    /**
     * @generated from protobuf field: string adminPin = 1;
     */
    adminPin: string;
    /**
     * @generated from protobuf field: string backgroundImageUrl = 2;
     */
    backgroundImageUrl: string;
    /**
     * @generated from protobuf field: bool bwFilter = 3;
     */
    bwFilter: boolean;
    /**
     * @generated from protobuf field: string logoImageUrl = 4;
     */
    logoImageUrl: string;
    /**
     * @generated from protobuf field: string customLanguageId = 5;
     */
    customLanguageId: string;
    /**
     * @generated from protobuf field: bool showOrganizer = 6;
     */
    showOrganizer: boolean;
    /**
     * @generated from protobuf field: bool showMeetingTitle = 7;
     */
    showMeetingTitle: boolean;
    /**
     * @generated from protobuf field: bool showAttendees = 8;
     */
    showAttendees: boolean;
    /**
     * @generated from protobuf field: bool showRoomCapacity = 9;
     */
    showRoomCapacity: boolean;
    /**
     * @generated from protobuf field: bool showRoomAmeneties = 10;
     */
    showRoomAmeneties: boolean;
    /**
     * @generated from protobuf field: bool showCheckinRequired = 11;
     */
    showCheckinRequired: boolean;
    /**
     * @generated from protobuf field: int32 pendingTimeBeforeMeeting = 12;
     */
    pendingTimeBeforeMeeting: number;
    /**
     * @generated from protobuf field: int32 pendingTimeAfterMeeting = 13;
     */
    pendingTimeAfterMeeting: number;
    /**
     * @generated from protobuf field: bool soonFree = 14;
     */
    soonFree: boolean;
    /**
     * @generated from protobuf field: bool energySaveMode = 15;
     */
    energySaveMode: boolean;
    /**
     * @generated from protobuf field: string energySaveModeStart = 16;
     */
    energySaveModeStart: string;
    /**
     * @generated from protobuf field: string energySaveModeEnd = 17;
     */
    energySaveModeEnd: string;
    /**
     * @generated from protobuf field: bool qrCodeRequired = 18;
     */
    qrCodeRequired: boolean;
    /**
     * @generated from protobuf field: bool contentBoardActivated = 19;
     */
    contentBoardActivated: boolean;
    /**
     * @generated from protobuf field: string contentBoardURL = 20;
     */
    contentBoardURL: string;
    /**
     * @generated from protobuf field: bool enableScreenLock = 21;
     */
    enableScreenLock: boolean;
    /**
     * @generated from protobuf field: string screenLock = 22;
     */
    screenLock: string;
    /**
     * @generated from protobuf field: bool onScreenReservation = 23;
     */
    onScreenReservation: boolean;
    /**
     * @generated from protobuf field: bool showMeetingTitleInput = 24;
     */
    showMeetingTitleInput: boolean;
    /**
     * @generated from protobuf field: bool requireMeetingTitleInput = 25;
     */
    requireMeetingTitleInput: boolean;
    /**
     * @generated from protobuf field: bool showYourNameInput = 26;
     */
    showYourNameInput: boolean;
    /**
     * @generated from protobuf field: bool requireYourNameInput = 27;
     */
    requireYourNameInput: boolean;
    /**
     * @generated from protobuf field: bool workingHrsActivated = 28;
     */
    workingHrsActivated: boolean;
    /**
     * @generated from protobuf field: string workingHrsStart = 29;
     */
    workingHrsStart: string;
    /**
     * @generated from protobuf field: string workingHrsEnd = 30;
     */
    workingHrsEnd: string;
    /**
     * @generated from protobuf field: bool requireHeadcount = 31;
     */
    requireHeadcount: boolean;
    /**
     * @generated from protobuf field: bool oneTimePinProtection = 32;
     */
    oneTimePinProtection: boolean;
    /**
     * @generated from protobuf field: bool deleteMeeting = 33;
     */
    deleteMeeting: boolean;
    /**
     * @generated from protobuf field: bool endMeetingEarly = 34;
     */
    endMeetingEarly: boolean;
    /**
     * @generated from protobuf field: bool extendMeeting = 35;
     */
    extendMeeting: boolean;
    /**
     * @generated from protobuf field: bool nearbyEnabled = 36;
     */
    nearbyEnabled: boolean;
    /**
     * @generated from protobuf field: repeated string nearbyRooms = 37;
     */
    nearbyRooms: string[];
    /**
     * @generated from protobuf field: bool mapView = 38;
     */
    mapView: boolean;
    /**
     * @generated from protobuf field: string floorId = 39;
     */
    floorId: string;
    /**
     * @generated from protobuf field: CustomerLanguages customLanguage = 40;
     */
    customLanguage?: CustomerLanguages;
    /**
     * @generated from protobuf field: MapFloor floor = 41;
     */
    floor?: MapFloor;
    /**
     * @generated from protobuf field: bool checkinReminder = 42;
     */
    checkinReminder: boolean;
}
/**
 * @generated from protobuf message CreateRoomTemplateRequest
 */
export interface CreateRoomTemplateRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string tenantId = 3;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: RoomTemplateSettings roomSettings = 4;
     */
    roomSettings?: RoomTemplateSettings;
    /**
     * @generated from protobuf field: RoomTemplateDisplaySettings displaySettings = 5;
     */
    displaySettings?: RoomTemplateDisplaySettings;
}
/**
 * @generated from protobuf message RoomTemplate
 */
export interface RoomTemplate {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string tenantId = 4;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: RoomTemplateSettings roomSettings = 5;
     */
    roomSettings?: RoomTemplateSettings;
    /**
     * @generated from protobuf field: RoomTemplateDisplaySettings displaySettings = 6;
     */
    displaySettings?: RoomTemplateDisplaySettings;
}
/**
 * @generated from protobuf message ListRoomTemplatesRequest
 */
export interface ListRoomTemplatesRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message RoomTemplatesResponse
 */
export interface RoomTemplatesResponse {
    /**
     * @generated from protobuf field: repeated RoomTemplate results = 1;
     */
    results: RoomTemplate[];
}
/**
 * @generated from protobuf message UpdateRoomTemplateRequest
 */
export interface UpdateRoomTemplateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string tenantId = 4;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: RoomTemplateSettings roomSettings = 5;
     */
    roomSettings?: RoomTemplateSettings;
    /**
     * @generated from protobuf field: RoomTemplateDisplaySettings displaySettings = 6;
     */
    displaySettings?: RoomTemplateDisplaySettings;
}
/**
 * @generated from protobuf message DeleteRoomTemplateRequest
 */
export interface DeleteRoomTemplateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customerId = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ApplyRoomTemplateRequest
 */
export interface ApplyRoomTemplateRequest {
    /**
     * @generated from protobuf field: string templateId = 1;
     */
    templateId: string;
    /**
     * @generated from protobuf field: string customerId = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: repeated string roomsId = 3;
     */
    roomsId: string[];
    /**
     * @generated from protobuf field: repeated string roomSettingsFields = 4;
     */
    roomSettingsFields: string[];
    /**
     * @generated from protobuf field: repeated string displaySettingsFields = 5;
     */
    displaySettingsFields: string[];
}
/**
 * @generated from protobuf message ApplyRoomTemplateResponse
 */
export interface ApplyRoomTemplateResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message DeskTemplateSettings
 */
export interface DeskTemplateSettings {
    /**
     * @generated from protobuf field: string description = 1;
     */
    description: string;
    /**
     * @generated from protobuf field: repeated string tags = 2;
     */
    tags: string[];
    /**
     * @generated from protobuf field: bool isBlocked = 3;
     */
    isBlocked: boolean;
    /**
     * @generated from protobuf field: DeskTemplateSettings.Account account = 4;
     */
    account?: DeskTemplateSettings_Account;
    /**
     * @generated from protobuf field: bool showCheckInTime = 5;
     */
    showCheckInTime: boolean;
    /**
     * @generated from protobuf field: int32 checkInTime = 6;
     */
    checkInTime: number;
    /**
     * @generated from protobuf field: bool qrCodeRequired = 7;
     */
    qrCodeRequired: boolean;
    /**
     * @generated from protobuf field: bool workingHrsActivated = 8;
     */
    workingHrsActivated: boolean;
    /**
     * @generated from protobuf field: string workingHrsStart = 9;
     */
    workingHrsStart: string;
    /**
     * @generated from protobuf field: string workingHrsEnd = 10;
     */
    workingHrsEnd: string;
}
/**
 * @generated from protobuf message DeskTemplateSettings.Account
 */
export interface DeskTemplateSettings_Account {
    /**
     * @generated from protobuf field: string displayName = 1;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string tenantID = 3;
     */
    tenantID: string;
    /**
     * @generated from protobuf field: string accountID = 4;
     */
    accountID: string;
}
/**
 * @generated from protobuf message CreateDeskTemplateRequest
 */
export interface CreateDeskTemplateRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string tenantId = 3;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: DeskTemplateSettings deskSettings = 4;
     */
    deskSettings?: DeskTemplateSettings;
}
/**
 * @generated from protobuf message DeskTemplate
 */
export interface DeskTemplate {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string tenantId = 4;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: DeskTemplateSettings deskSettings = 5;
     */
    deskSettings?: DeskTemplateSettings;
}
/**
 * @generated from protobuf message ListDeskTemplatesRequest
 */
export interface ListDeskTemplatesRequest {
    /**
     * @generated from protobuf field: string customerId = 1;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DeskTemplatesResponse
 */
export interface DeskTemplatesResponse {
    /**
     * @generated from protobuf field: repeated DeskTemplate results = 1;
     */
    results: DeskTemplate[];
}
/**
 * @generated from protobuf message UpdateDeskTemplateRequest
 */
export interface UpdateDeskTemplateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: string tenantId = 4;
     */
    tenantId: string;
    /**
     * @generated from protobuf field: DeskTemplateSettings deskSettings = 5;
     */
    deskSettings?: DeskTemplateSettings;
}
/**
 * @generated from protobuf message DeleteDeskTemplateRequest
 */
export interface DeleteDeskTemplateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customerId = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ApplyDeskTemplateRequest
 */
export interface ApplyDeskTemplateRequest {
    /**
     * @generated from protobuf field: string templateId = 1;
     */
    templateId: string;
    /**
     * @generated from protobuf field: string customerId = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: repeated string desksId = 3;
     */
    desksId: string[];
    /**
     * @generated from protobuf field: repeated string deskSettingsFields = 4;
     */
    deskSettingsFields: string[];
}
/**
 * @generated from protobuf message ApplyDeskTemplateResponse
 */
export interface ApplyDeskTemplateResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message ListAvailableDesksRequest
 */
export interface ListAvailableDesksRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string customerId = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: repeated string tagsFilter = 3;
     */
    tagsFilter: string[];
}
/**
 * @generated from protobuf message ListAvailableDesksResponse
 */
export interface ListAvailableDesksResponse {
    /**
     * @generated from protobuf field: repeated SearchDesk desks = 1;
     */
    desks: SearchDesk[];
}
/**
 * @generated from protobuf message CreateBookingDashboardItem
 */
export interface CreateBookingDashboardItem {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: BookingDashboardLocationType locationType = 2;
     */
    locationType: BookingDashboardLocationType;
}
/**
 * @generated from protobuf message CreateBookingDashboardRequest
 */
export interface CreateBookingDashboardRequest {
    /**
     * @generated from protobuf field: repeated CreateBookingDashboardItem location = 1;
     */
    location: CreateBookingDashboardItem[];
    /**
     * @generated from protobuf field: string accountId = 2;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string customerId = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message BookingDashboardRoom
 */
export interface BookingDashboardRoom {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
    /**
     * @generated from protobuf field: AmenitiesFilter amenities = 4;
     */
    amenities?: AmenitiesFilter;
    /**
     * @generated from protobuf field: repeated LocationItem path = 5;
     */
    path: LocationItem[];
    /**
     * @generated from protobuf field: int32 seats = 6;
     */
    seats: number;
    /**
     * @generated from protobuf field: string calendarProviderId = 7;
     */
    calendarProviderId: string;
    /**
     * @generated from protobuf field: string calendarId = 8;
     */
    calendarId: string;
    /**
     * @generated from protobuf field: string customerId = 9;
     */
    customerId: string;
    /**
     * @generated from protobuf field: bool licensed = 10;
     */
    licensed: boolean;
}
/**
 * @generated from protobuf message BookingDashboardDesk
 */
export interface BookingDashboardDesk {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
    /**
     * @generated from protobuf field: repeated LocationItem path = 5;
     */
    path: LocationItem[];
    /**
     * @generated from protobuf field: bool licensed = 6;
     */
    licensed: boolean;
    /**
     * @generated from protobuf field: bool qrCodeRequired = 7;
     */
    qrCodeRequired: boolean;
    /**
     * @generated from protobuf field: bool workingHrsActivated = 8;
     */
    workingHrsActivated: boolean;
    /**
     * @generated from protobuf field: bool isBlocked = 9;
     */
    isBlocked: boolean;
    /**
     * @generated from protobuf field: bool showCheckInTime = 10;
     */
    showCheckInTime: boolean;
}
/**
 * @generated from protobuf message BookingDashboardItem
 */
export interface BookingDashboardItem {
    /**
     * @generated from protobuf oneof: location
     */
    location: {
        oneofKind: "room";
        /**
         * @generated from protobuf field: BookingDashboardRoom room = 1;
         */
        room: BookingDashboardRoom;
    } | {
        oneofKind: "desk";
        /**
         * @generated from protobuf field: BookingDashboardDesk desk = 2;
         */
        desk: BookingDashboardDesk;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: string locationId = 3;
     */
    locationId: string;
    /**
     * @generated from protobuf field: BookingDashboardLocationType locationType = 4;
     */
    locationType: BookingDashboardLocationType;
}
/**
 * @generated from protobuf message BookingDashboardResponse
 */
export interface BookingDashboardResponse {
    /**
     * @generated from protobuf field: repeated BookingDashboardItem items = 1;
     */
    items: BookingDashboardItem[];
    /**
     * @generated from protobuf field: string accountId = 2;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string customerId = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message GetBookingDashboardRequest
 */
export interface GetBookingDashboardRequest {
    /**
     * @generated from protobuf field: string accountId = 1;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string customerId = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DeleteBookingDashboardItemRequest
 */
export interface DeleteBookingDashboardItemRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string accountId = 2;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string customerId = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CreateInterestedWorkplacesRequest
 */
export interface CreateInterestedWorkplacesRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string accountId = 2;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string customerId = 3;
     */
    customerId: string;
    /**
     * @generated from protobuf field: AccountInterestedWorkplaces type = 4;
     */
    type: AccountInterestedWorkplaces;
}
/**
 * @generated from protobuf message CreateInterestedWorkplacesResponse
 */
export interface CreateInterestedWorkplacesResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
}
/**
 * @generated from protobuf message DeleteInterestedWorkplacesRequest
 */
export interface DeleteInterestedWorkplacesRequest {
    /**
     * @generated from protobuf field: string locationId = 1;
     */
    locationId: string;
    /**
     * @generated from protobuf field: string accountId = 2;
     */
    accountId: string;
    /**
     * @generated from protobuf field: string customerId = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf enum ListTagsEntity
 */
export enum ListTagsEntity {
    /**
     * @generated from protobuf enum value: TYPE_ROOM = 0;
     */
    TYPE_ROOM = 0,
    /**
     * @generated from protobuf enum value: TYPE_DESK = 1;
     */
    TYPE_DESK = 1,
    /**
     * Can be tenant, company, site, building, floor, zone
     *
     * @generated from protobuf enum value: TYPE_LOCATION = 2;
     */
    TYPE_LOCATION = 2
}
/**
 * @generated from protobuf enum ErrorReportType
 */
export enum ErrorReportType {
    /**
     * @generated from protobuf enum value: ERROR_REPORT_TYPE_REPORT = 0;
     */
    REPORT = 0,
    /**
     * @generated from protobuf enum value: ERROR_REPORT_TYPE_RESOLVE = 1;
     */
    RESOLVE = 1
}
/**
 * @generated from protobuf enum ReportState
 */
export enum ReportState {
    /**
     * @generated from protobuf enum value: REPORT_STATE_ACTIVE = 0;
     */
    ACTIVE = 0,
    /**
     * @generated from protobuf enum value: REPORT_STATE_INACTIVE = 1;
     */
    INACTIVE = 1
}
/**
 * @generated from protobuf enum CustomLanguageAppType
 */
export enum CustomLanguageAppType {
    /**
     * @generated from protobuf enum value: CUSTOM_LANGUAGE_APP_TYPE_RDX = 0;
     */
    RDX = 0,
    /**
     * @generated from protobuf enum value: CUSTOM_LANGUAGE_APP_TYPE_RFX = 1;
     */
    RFX = 1
}
/**
 * @generated from protobuf enum OnLevelSearchRequestLicenseStatus
 */
export enum OnLevelSearchRequestLicenseStatus {
    /**
     * @generated from protobuf enum value: OnLevelSearchRequestLicenseStatusNone = 0;
     */
    OnLevelSearchRequestLicenseStatusNone = 0,
    /**
     * @generated from protobuf enum value: OnLevelSearchRequestLicenseStatusActive = 1;
     */
    OnLevelSearchRequestLicenseStatusActive = 1,
    /**
     * @generated from protobuf enum value: OnLevelSearchRequestLicenseStatusNotActive = 2;
     */
    OnLevelSearchRequestLicenseStatusNotActive = 2
}
/**
 * @generated from protobuf enum WorkplaceLocationType
 */
export enum WorkplaceLocationType {
    /**
     * @generated from protobuf enum value: WorkplaceLocationTypeAll = 0;
     */
    WorkplaceLocationTypeAll = 0,
    /**
     * @generated from protobuf enum value: WorkplaceLocationTypeTenant = 1;
     */
    WorkplaceLocationTypeTenant = 1,
    /**
     * @generated from protobuf enum value: WorkplaceLocationTypeCompany = 2;
     */
    WorkplaceLocationTypeCompany = 2,
    /**
     * @generated from protobuf enum value: WorkplaceLocationTypeSite = 3;
     */
    WorkplaceLocationTypeSite = 3,
    /**
     * @generated from protobuf enum value: WorkplaceLocationTypeBuilding = 4;
     */
    WorkplaceLocationTypeBuilding = 4,
    /**
     * @generated from protobuf enum value: WorkplaceLocationTypeFloor = 5;
     */
    WorkplaceLocationTypeFloor = 5,
    /**
     * @generated from protobuf enum value: WorkplaceLocationTypeZone = 6;
     */
    WorkplaceLocationTypeZone = 6
}
/**
 * @generated from protobuf enum WorkplaceResourceType
 */
export enum WorkplaceResourceType {
    /**
     * @generated from protobuf enum value: WorkplaceResourceTypeAll = 0;
     */
    WorkplaceResourceTypeAll = 0,
    /**
     * @generated from protobuf enum value: WorkplaceResourceTypeRoom = 1;
     */
    WorkplaceResourceTypeRoom = 1,
    /**
     * @generated from protobuf enum value: WorkplaceResourceTypeDesk = 2;
     */
    WorkplaceResourceTypeDesk = 2,
    /**
     * @generated from protobuf enum value: WorkplaceResourceTypeWayfinder = 3;
     */
    WorkplaceResourceTypeWayfinder = 3
}
/**
 * @generated from protobuf enum BookingDashboardLocationType
 */
export enum BookingDashboardLocationType {
    /**
     * @generated from protobuf enum value: BD_LOCATION_TYPE_ROOM = 0;
     */
    BD_LOCATION_TYPE_ROOM = 0,
    /**
     * @generated from protobuf enum value: BD_LOCATION_TYPE_DESK = 1;
     */
    BD_LOCATION_TYPE_DESK = 1
}
/**
 * @generated from protobuf enum AccountInterestedWorkplaces
 */
export enum AccountInterestedWorkplaces {
    /**
     * @generated from protobuf enum value: AccountInterestedWorkplaces_UNKNOWN = 0;
     */
    AccountInterestedWorkplaces_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: AccountInterestedWorkplaces_ROOM = 1;
     */
    AccountInterestedWorkplaces_ROOM = 1,
    /**
     * @generated from protobuf enum value: AccountInterestedWorkplaces_DESK = 2;
     */
    AccountInterestedWorkplaces_DESK = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Tags$Type extends MessageType<Tags> {
    constructor() {
        super("Tags", [
            { no: 1, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Tags>): Tags {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tags = [];
        if (value !== undefined)
            reflectionMergePartial<Tags>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tags): Tags {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string tags */ 1:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tags, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string tags = 1; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Tags
 */
export const Tags = new Tags$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FloorStatusRequest$Type extends MessageType<FloorStatusRequest> {
    constructor() {
        super("FloorStatusRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "floor_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FloorStatusRequest>): FloorStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.floorId = "";
        message.dateTime = "";
        if (value !== undefined)
            reflectionMergePartial<FloorStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FloorStatusRequest): FloorStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string floor_id */ 2:
                    message.floorId = reader.string();
                    break;
                case /* string date_time */ 3:
                    message.dateTime = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FloorStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string floor_id = 2; */
        if (message.floorId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.floorId);
        /* string date_time = 3; */
        if (message.dateTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dateTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FloorStatusRequest
 */
export const FloorStatusRequest = new FloorStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Marker$Type extends MessageType<Marker> {
    constructor() {
        super("Marker", [
            { no: 1, name: "latitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Marker>): Marker {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.latitude = 0;
        message.longitude = 0;
        if (value !== undefined)
            reflectionMergePartial<Marker>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Marker): Marker {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 latitude */ 1:
                    message.latitude = reader.int32();
                    break;
                case /* int32 longitude */ 2:
                    message.longitude = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Marker, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 latitude = 1; */
        if (message.latitude !== 0)
            writer.tag(1, WireType.Varint).int32(message.latitude);
        /* int32 longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Varint).int32(message.longitude);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Marker
 */
export const Marker = new Marker$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FloorMapRequest$Type extends MessageType<FloorMapRequest> {
    constructor() {
        super("FloorMapRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "floor_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FloorMapRequest>): FloorMapRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.floorId = "";
        message.dateTime = "";
        message.timeZone = "";
        if (value !== undefined)
            reflectionMergePartial<FloorMapRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FloorMapRequest): FloorMapRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string floor_id */ 2:
                    message.floorId = reader.string();
                    break;
                case /* string date_time */ 3:
                    message.dateTime = reader.string();
                    break;
                case /* string timeZone */ 4:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FloorMapRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string floor_id = 2; */
        if (message.floorId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.floorId);
        /* string date_time = 3; */
        if (message.dateTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dateTime);
        /* string timeZone = 4; */
        if (message.timeZone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FloorMapRequest
 */
export const FloorMapRequest = new FloorMapRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FloorMapMetadataRequest$Type extends MessageType<FloorMapMetadataRequest> {
    constructor() {
        super("FloorMapMetadataRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "floor_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FloorMapMetadataRequest>): FloorMapMetadataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.floorId = "";
        if (value !== undefined)
            reflectionMergePartial<FloorMapMetadataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FloorMapMetadataRequest): FloorMapMetadataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string floor_id */ 2:
                    message.floorId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FloorMapMetadataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string floor_id = 2; */
        if (message.floorId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.floorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FloorMapMetadataRequest
 */
export const FloorMapMetadataRequest = new FloorMapMetadataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RDXFloorMapRequest$Type extends MessageType<RDXFloorMapRequest> {
    constructor() {
        super("RDXFloorMapRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RDXFloorMapRequest>): RDXFloorMapRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.roomId = "";
        message.dateTime = "";
        message.timeZone = "";
        if (value !== undefined)
            reflectionMergePartial<RDXFloorMapRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RDXFloorMapRequest): RDXFloorMapRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string room_id */ 2:
                    message.roomId = reader.string();
                    break;
                case /* string date_time */ 3:
                    message.dateTime = reader.string();
                    break;
                case /* string timeZone */ 4:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RDXFloorMapRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string room_id = 2; */
        if (message.roomId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomId);
        /* string date_time = 3; */
        if (message.dateTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dateTime);
        /* string timeZone = 4; */
        if (message.timeZone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RDXFloorMapRequest
 */
export const RDXFloorMapRequest = new RDXFloorMapRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RFXFloorMapMetadataRequest$Type extends MessageType<RFXFloorMapMetadataRequest> {
    constructor() {
        super("RFXFloorMapMetadataRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rfx_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RFXFloorMapMetadataRequest>): RFXFloorMapMetadataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.rfxId = "";
        if (value !== undefined)
            reflectionMergePartial<RFXFloorMapMetadataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RFXFloorMapMetadataRequest): RFXFloorMapMetadataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string rfx_id */ 2:
                    message.rfxId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RFXFloorMapMetadataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string rfx_id = 2; */
        if (message.rfxId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rfxId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RFXFloorMapMetadataRequest
 */
export const RFXFloorMapMetadataRequest = new RFXFloorMapMetadataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ZonesMap$Type extends MessageType<ZonesMap> {
    constructor() {
        super("ZonesMap", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MapEntity },
            { no: 4, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MapEntity },
            { no: 5, name: "wayfinders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MapEntity }
        ]);
    }
    create(value?: PartialMessage<ZonesMap>): ZonesMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.rooms = [];
        message.desks = [];
        message.wayfinders = [];
        if (value !== undefined)
            reflectionMergePartial<ZonesMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ZonesMap): ZonesMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated MapEntity rooms */ 3:
                    message.rooms.push(MapEntity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated MapEntity desks */ 4:
                    message.desks.push(MapEntity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated MapEntity wayfinders */ 5:
                    message.wayfinders.push(MapEntity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ZonesMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated MapEntity rooms = 3; */
        for (let i = 0; i < message.rooms.length; i++)
            MapEntity.internalBinaryWrite(message.rooms[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated MapEntity desks = 4; */
        for (let i = 0; i < message.desks.length; i++)
            MapEntity.internalBinaryWrite(message.desks[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated MapEntity wayfinders = 5; */
        for (let i = 0; i < message.wayfinders.length; i++)
            MapEntity.internalBinaryWrite(message.wayfinders[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ZonesMap
 */
export const ZonesMap = new ZonesMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CircleShape$Type extends MessageType<CircleShape> {
    constructor() {
        super("CircleShape", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "latitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "longitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "radius", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CircleShape>): CircleShape {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.latitude = 0;
        message.longitude = 0;
        message.radius = 0;
        if (value !== undefined)
            reflectionMergePartial<CircleShape>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CircleShape): CircleShape {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int32 latitude */ 2:
                    message.latitude = reader.int32();
                    break;
                case /* int32 longitude */ 3:
                    message.longitude = reader.int32();
                    break;
                case /* int32 radius */ 4:
                    message.radius = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CircleShape, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 latitude = 2; */
        if (message.latitude !== 0)
            writer.tag(2, WireType.Varint).int32(message.latitude);
        /* int32 longitude = 3; */
        if (message.longitude !== 0)
            writer.tag(3, WireType.Varint).int32(message.longitude);
        /* int32 radius = 4; */
        if (message.radius !== 0)
            writer.tag(4, WireType.Varint).int32(message.radius);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CircleShape
 */
export const CircleShape = new CircleShape$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Polygonal$Type extends MessageType<Polygonal> {
    constructor() {
        super("Polygonal", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "latitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "longitude", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Polygonal>): Polygonal {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.latitude = 0;
        message.longitude = 0;
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<Polygonal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Polygonal): Polygonal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int32 latitude */ 2:
                    message.latitude = reader.int32();
                    break;
                case /* int32 longitude */ 3:
                    message.longitude = reader.int32();
                    break;
                case /* string type */ 4:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Polygonal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 latitude = 2; */
        if (message.latitude !== 0)
            writer.tag(2, WireType.Varint).int32(message.latitude);
        /* int32 longitude = 3; */
        if (message.longitude !== 0)
            writer.tag(3, WireType.Varint).int32(message.longitude);
        /* string type = 4; */
        if (message.type !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Polygonal
 */
export const Polygonal = new Polygonal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MapEntity$Type extends MessageType<MapEntity> {
    constructor() {
        super("MapEntity", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "marker", kind: "message", T: () => Marker },
            { no: 4, name: "is_booked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "organizer", kind: "message", T: () => Attendee },
            { no: 6, name: "busySlots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusyTimeSlots },
            { no: 7, name: "isAssigned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "allowDeleteMeetings", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "deleteMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "endMeetingEarly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "extendMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "circleShape", kind: "message", T: () => CircleShape },
            { no: 16, name: "polygonal", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Polygonal },
            { no: 17, name: "numberOfSeats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "amenities", kind: "message", T: () => AmenitiesFilter }
        ]);
    }
    create(value?: PartialMessage<MapEntity>): MapEntity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.tenantId = "";
        message.isBooked = false;
        message.busySlots = [];
        message.isAssigned = false;
        message.name = "";
        message.tags = [];
        message.isBlocked = false;
        message.allowDeleteMeetings = false;
        message.deleteMeeting = false;
        message.endMeetingEarly = false;
        message.extendMeeting = false;
        message.polygonal = [];
        message.numberOfSeats = 0;
        if (value !== undefined)
            reflectionMergePartial<MapEntity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapEntity): MapEntity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string tenant_id */ 2:
                    message.tenantId = reader.string();
                    break;
                case /* Marker marker */ 3:
                    message.marker = Marker.internalBinaryRead(reader, reader.uint32(), options, message.marker);
                    break;
                case /* bool is_booked */ 4:
                    message.isBooked = reader.bool();
                    break;
                case /* ggevent.Attendee organizer */ 5:
                    message.organizer = Attendee.internalBinaryRead(reader, reader.uint32(), options, message.organizer);
                    break;
                case /* repeated ggevent.BusyTimeSlots busySlots */ 6:
                    message.busySlots.push(BusyTimeSlots.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool isAssigned */ 7:
                    message.isAssigned = reader.bool();
                    break;
                case /* string name */ 8:
                    message.name = reader.string();
                    break;
                case /* repeated string tags */ 9:
                    message.tags.push(reader.string());
                    break;
                case /* bool isBlocked */ 10:
                    message.isBlocked = reader.bool();
                    break;
                case /* bool allowDeleteMeetings */ 11:
                    message.allowDeleteMeetings = reader.bool();
                    break;
                case /* bool deleteMeeting */ 12:
                    message.deleteMeeting = reader.bool();
                    break;
                case /* bool endMeetingEarly */ 13:
                    message.endMeetingEarly = reader.bool();
                    break;
                case /* bool extendMeeting */ 14:
                    message.extendMeeting = reader.bool();
                    break;
                case /* CircleShape circleShape */ 15:
                    message.circleShape = CircleShape.internalBinaryRead(reader, reader.uint32(), options, message.circleShape);
                    break;
                case /* repeated Polygonal polygonal */ 16:
                    message.polygonal.push(Polygonal.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 numberOfSeats */ 17:
                    message.numberOfSeats = reader.int32();
                    break;
                case /* AmenitiesFilter amenities */ 18:
                    message.amenities = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.amenities);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MapEntity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string tenant_id = 2; */
        if (message.tenantId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tenantId);
        /* Marker marker = 3; */
        if (message.marker)
            Marker.internalBinaryWrite(message.marker, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool is_booked = 4; */
        if (message.isBooked !== false)
            writer.tag(4, WireType.Varint).bool(message.isBooked);
        /* ggevent.Attendee organizer = 5; */
        if (message.organizer)
            Attendee.internalBinaryWrite(message.organizer, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated ggevent.BusyTimeSlots busySlots = 6; */
        for (let i = 0; i < message.busySlots.length; i++)
            BusyTimeSlots.internalBinaryWrite(message.busySlots[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool isAssigned = 7; */
        if (message.isAssigned !== false)
            writer.tag(7, WireType.Varint).bool(message.isAssigned);
        /* string name = 8; */
        if (message.name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.name);
        /* repeated string tags = 9; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.tags[i]);
        /* bool isBlocked = 10; */
        if (message.isBlocked !== false)
            writer.tag(10, WireType.Varint).bool(message.isBlocked);
        /* bool allowDeleteMeetings = 11; */
        if (message.allowDeleteMeetings !== false)
            writer.tag(11, WireType.Varint).bool(message.allowDeleteMeetings);
        /* bool deleteMeeting = 12; */
        if (message.deleteMeeting !== false)
            writer.tag(12, WireType.Varint).bool(message.deleteMeeting);
        /* bool endMeetingEarly = 13; */
        if (message.endMeetingEarly !== false)
            writer.tag(13, WireType.Varint).bool(message.endMeetingEarly);
        /* bool extendMeeting = 14; */
        if (message.extendMeeting !== false)
            writer.tag(14, WireType.Varint).bool(message.extendMeeting);
        /* CircleShape circleShape = 15; */
        if (message.circleShape)
            CircleShape.internalBinaryWrite(message.circleShape, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* repeated Polygonal polygonal = 16; */
        for (let i = 0; i < message.polygonal.length; i++)
            Polygonal.internalBinaryWrite(message.polygonal[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* int32 numberOfSeats = 17; */
        if (message.numberOfSeats !== 0)
            writer.tag(17, WireType.Varint).int32(message.numberOfSeats);
        /* AmenitiesFilter amenities = 18; */
        if (message.amenities)
            AmenitiesFilter.internalBinaryWrite(message.amenities, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MapEntity
 */
export const MapEntity = new MapEntity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FloorMapResponse$Type extends MessageType<FloorMapResponse> {
    constructor() {
        super("FloorMapResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MapEntity },
            { no: 4, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MapEntity },
            { no: 5, name: "zones", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ZonesMap },
            { no: 6, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "opacity", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "showGridline", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "wayfinders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MapEntity },
            { no: 10, name: "showTooltips", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "showRoomMarkers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FloorMapResponse>): FloorMapResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.rooms = [];
        message.desks = [];
        message.zones = [];
        message.url = "";
        message.opacity = 0;
        message.showGridline = false;
        message.wayfinders = [];
        message.showTooltips = false;
        message.showRoomMarkers = false;
        if (value !== undefined)
            reflectionMergePartial<FloorMapResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FloorMapResponse): FloorMapResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated MapEntity rooms */ 3:
                    message.rooms.push(MapEntity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated MapEntity desks */ 4:
                    message.desks.push(MapEntity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ZonesMap zones */ 5:
                    message.zones.push(ZonesMap.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string url */ 6:
                    message.url = reader.string();
                    break;
                case /* float opacity */ 7:
                    message.opacity = reader.float();
                    break;
                case /* bool showGridline */ 8:
                    message.showGridline = reader.bool();
                    break;
                case /* repeated MapEntity wayfinders */ 9:
                    message.wayfinders.push(MapEntity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool showTooltips */ 10:
                    message.showTooltips = reader.bool();
                    break;
                case /* bool showRoomMarkers */ 18:
                    message.showRoomMarkers = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FloorMapResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated MapEntity rooms = 3; */
        for (let i = 0; i < message.rooms.length; i++)
            MapEntity.internalBinaryWrite(message.rooms[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated MapEntity desks = 4; */
        for (let i = 0; i < message.desks.length; i++)
            MapEntity.internalBinaryWrite(message.desks[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated ZonesMap zones = 5; */
        for (let i = 0; i < message.zones.length; i++)
            ZonesMap.internalBinaryWrite(message.zones[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string url = 6; */
        if (message.url !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.url);
        /* float opacity = 7; */
        if (message.opacity !== 0)
            writer.tag(7, WireType.Bit32).float(message.opacity);
        /* bool showGridline = 8; */
        if (message.showGridline !== false)
            writer.tag(8, WireType.Varint).bool(message.showGridline);
        /* repeated MapEntity wayfinders = 9; */
        for (let i = 0; i < message.wayfinders.length; i++)
            MapEntity.internalBinaryWrite(message.wayfinders[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bool showTooltips = 10; */
        if (message.showTooltips !== false)
            writer.tag(10, WireType.Varint).bool(message.showTooltips);
        /* bool showRoomMarkers = 18; */
        if (message.showRoomMarkers !== false)
            writer.tag(18, WireType.Varint).bool(message.showRoomMarkers);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FloorMapResponse
 */
export const FloorMapResponse = new FloorMapResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMapRequest$Type extends MessageType<DeleteMapRequest> {
    constructor() {
        super("DeleteMapRequest", [
            { no: 1, name: "imageUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMapRequest>): DeleteMapRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imageUrl = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteMapRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMapRequest): DeleteMapRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string imageUrl */ 1:
                    message.imageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMapRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string imageUrl = 1; */
        if (message.imageUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.imageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteMapRequest
 */
export const DeleteMapRequest = new DeleteMapRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MapRequest$Type extends MessageType<MapRequest> {
    constructor() {
        super("MapRequest", [
            { no: 1, name: "imageData", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "imageType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "floor_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MapRequest>): MapRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imageData = new Uint8Array(0);
        message.imageType = "";
        message.customerId = "";
        message.accountId = "";
        message.floorId = "";
        if (value !== undefined)
            reflectionMergePartial<MapRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapRequest): MapRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes imageData */ 1:
                    message.imageData = reader.bytes();
                    break;
                case /* string imageType */ 2:
                    message.imageType = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                case /* string account_id */ 4:
                    message.accountId = reader.string();
                    break;
                case /* string floor_id */ 5:
                    message.floorId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MapRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes imageData = 1; */
        if (message.imageData.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.imageData);
        /* string imageType = 2; */
        if (message.imageType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.imageType);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        /* string account_id = 4; */
        if (message.accountId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountId);
        /* string floor_id = 5; */
        if (message.floorId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.floorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MapRequest
 */
export const MapRequest = new MapRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MapResponse$Type extends MessageType<MapResponse> {
    constructor() {
        super("MapResponse", [
            { no: 1, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MapResponse>): MapResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imageUrl = "";
        if (value !== undefined)
            reflectionMergePartial<MapResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapResponse): MapResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string image_url */ 1:
                    message.imageUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MapResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string image_url = 1; */
        if (message.imageUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.imageUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MapResponse
 */
export const MapResponse = new MapResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRoomsRequest$Type extends MessageType<ListRoomsRequest> {
    constructor() {
        super("ListRoomsRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListRoomsRequest>): ListRoomsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<ListRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRoomsRequest): ListRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListRoomsRequest
 */
export const ListRoomsRequest = new ListRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRoomsResponse$Type extends MessageType<ListRoomsResponse> {
    constructor() {
        super("ListRoomsResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListRoom }
        ]);
    }
    create(value?: PartialMessage<ListRoomsResponse>): ListRoomsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rooms = [];
        if (value !== undefined)
            reflectionMergePartial<ListRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRoomsResponse): ListRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ListRoom rooms */ 1:
                    message.rooms.push(ListRoom.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ListRoom rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            ListRoom.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListRoomsResponse
 */
export const ListRoomsResponse = new ListRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerActiveRoomsRequest$Type extends MessageType<CustomerActiveRoomsRequest> {
    constructor() {
        super("CustomerActiveRoomsRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CustomerActiveRoomsRequest>): CustomerActiveRoomsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<CustomerActiveRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerActiveRoomsRequest): CustomerActiveRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerActiveRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerActiveRoomsRequest
 */
export const CustomerActiveRoomsRequest = new CustomerActiveRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerActiveRoomsResponse$Type extends MessageType<CustomerActiveRoomsResponse> {
    constructor() {
        super("CustomerActiveRoomsResponse", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomsData }
        ]);
    }
    create(value?: PartialMessage<CustomerActiveRoomsResponse>): CustomerActiveRoomsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.rooms = [];
        if (value !== undefined)
            reflectionMergePartial<CustomerActiveRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerActiveRoomsResponse): CustomerActiveRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* repeated RoomsData rooms */ 2:
                    message.rooms.push(RoomsData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerActiveRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* repeated RoomsData rooms = 2; */
        for (let i = 0; i < message.rooms.length; i++)
            RoomsData.internalBinaryWrite(message.rooms[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerActiveRoomsResponse
 */
export const CustomerActiveRoomsResponse = new CustomerActiveRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRoom$Type extends MessageType<ListRoom> {
    constructor() {
        super("ListRoom", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "resource_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListRoom>): ListRoom {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.resourceEmail = "";
        message.calendarId = "";
        if (value !== undefined)
            reflectionMergePartial<ListRoom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRoom): ListRoom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string resource_email */ 3:
                    message.resourceEmail = reader.string();
                    break;
                case /* string calendar_id */ 4:
                    message.calendarId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRoom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string resource_email = 3; */
        if (message.resourceEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.resourceEmail);
        /* string calendar_id = 4; */
        if (message.calendarId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.calendarId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListRoom
 */
export const ListRoom = new ListRoom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRoomRequest$Type extends MessageType<GetRoomRequest> {
    constructor() {
        super("GetRoomRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "personal_calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRoomRequest>): GetRoomRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerId = "";
        message.timeZone = "";
        message.time = "";
        message.personalCalendarId = "";
        if (value !== undefined)
            reflectionMergePartial<GetRoomRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRoomRequest): GetRoomRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* string timeZone */ 3:
                    message.timeZone = reader.string();
                    break;
                case /* string time */ 4:
                    message.time = reader.string();
                    break;
                case /* string personal_calendar_id */ 5:
                    message.personalCalendarId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRoomRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* string timeZone = 3; */
        if (message.timeZone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timeZone);
        /* string time = 4; */
        if (message.time !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.time);
        /* string personal_calendar_id = 5; */
        if (message.personalCalendarId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.personalCalendarId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetRoomRequest
 */
export const GetRoomRequest = new GetRoomRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRoomResponse$Type extends MessageType<GetRoomResponse> {
    constructor() {
        super("GetRoomResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendar_provider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "resource_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "availableUntil", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "availableFrom", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "isBusy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "path", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem },
            { no: 10, name: "seats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "availableEquipment", kind: "message", T: () => AmenitiesFilter },
            { no: 12, name: "displaySettings", kind: "message", T: () => RoomDisplaySettings },
            { no: 13, name: "isExternal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetRoomResponse>): GetRoomResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.calendarProviderId = "";
        message.calendarId = "";
        message.resourceEmail = "";
        message.availableUntil = "";
        message.availableFrom = "";
        message.isBusy = false;
        message.path = [];
        message.seats = 0;
        message.isExternal = false;
        if (value !== undefined)
            reflectionMergePartial<GetRoomResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRoomResponse): GetRoomResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string calendar_provider_id */ 3:
                    message.calendarProviderId = reader.string();
                    break;
                case /* string calendar_id */ 4:
                    message.calendarId = reader.string();
                    break;
                case /* string resource_email */ 5:
                    message.resourceEmail = reader.string();
                    break;
                case /* string availableUntil */ 6:
                    message.availableUntil = reader.string();
                    break;
                case /* string availableFrom */ 7:
                    message.availableFrom = reader.string();
                    break;
                case /* bool isBusy */ 8:
                    message.isBusy = reader.bool();
                    break;
                case /* repeated LocationItem path */ 9:
                    message.path.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 seats */ 10:
                    message.seats = reader.int32();
                    break;
                case /* AmenitiesFilter availableEquipment */ 11:
                    message.availableEquipment = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.availableEquipment);
                    break;
                case /* RoomDisplaySettings displaySettings */ 12:
                    message.displaySettings = RoomDisplaySettings.internalBinaryRead(reader, reader.uint32(), options, message.displaySettings);
                    break;
                case /* bool isExternal */ 13:
                    message.isExternal = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRoomResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string calendar_provider_id = 3; */
        if (message.calendarProviderId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarProviderId);
        /* string calendar_id = 4; */
        if (message.calendarId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.calendarId);
        /* string resource_email = 5; */
        if (message.resourceEmail !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.resourceEmail);
        /* string availableUntil = 6; */
        if (message.availableUntil !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.availableUntil);
        /* string availableFrom = 7; */
        if (message.availableFrom !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.availableFrom);
        /* bool isBusy = 8; */
        if (message.isBusy !== false)
            writer.tag(8, WireType.Varint).bool(message.isBusy);
        /* repeated LocationItem path = 9; */
        for (let i = 0; i < message.path.length; i++)
            LocationItem.internalBinaryWrite(message.path[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* int32 seats = 10; */
        if (message.seats !== 0)
            writer.tag(10, WireType.Varint).int32(message.seats);
        /* AmenitiesFilter availableEquipment = 11; */
        if (message.availableEquipment)
            AmenitiesFilter.internalBinaryWrite(message.availableEquipment, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* RoomDisplaySettings displaySettings = 12; */
        if (message.displaySettings)
            RoomDisplaySettings.internalBinaryWrite(message.displaySettings, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool isExternal = 13; */
        if (message.isExternal !== false)
            writer.tag(13, WireType.Varint).bool(message.isExternal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetRoomResponse
 */
export const GetRoomResponse = new GetRoomResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTagsRequest$Type extends MessageType<ListTagsRequest> {
    constructor() {
        super("ListTagsRequest", [
            { no: 1, name: "location_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "entityType", kind: "enum", T: () => ["ListTagsEntity", ListTagsEntity] }
        ]);
    }
    create(value?: PartialMessage<ListTagsRequest>): ListTagsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.customerId = "";
        message.limit = 0;
        message.entityType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListTagsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTagsRequest): ListTagsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string location_id */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* int32 limit */ 3:
                    message.limit = reader.int32();
                    break;
                case /* ListTagsEntity entityType */ 4:
                    message.entityType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTagsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string location_id = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* int32 limit = 3; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* ListTagsEntity entityType = 4; */
        if (message.entityType !== 0)
            writer.tag(4, WireType.Varint).int32(message.entityType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListTagsRequest
 */
export const ListTagsRequest = new ListTagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAvailableRoomsRequest$Type extends MessageType<ListAvailableRoomsRequest> {
    constructor() {
        super("ListAvailableRoomsRequest", [
            { no: 1, name: "location_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "equipment", kind: "message", T: () => AmenitiesFilter },
            { no: 7, name: "min_nr_seats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "max_nr_seats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "personal_calendar_provider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListAvailableRoomsRequest>): ListAvailableRoomsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.customerId = "";
        message.from = "";
        message.to = "";
        message.tags = [];
        message.minNrSeats = 0;
        message.maxNrSeats = 0;
        message.timeZone = "";
        message.personalCalendarProviderId = "";
        message.page = 0;
        if (value !== undefined)
            reflectionMergePartial<ListAvailableRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAvailableRoomsRequest): ListAvailableRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string location_id */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* string from */ 3:
                    message.from = reader.string();
                    break;
                case /* string to */ 4:
                    message.to = reader.string();
                    break;
                case /* repeated string tags */ 5:
                    message.tags.push(reader.string());
                    break;
                case /* AmenitiesFilter equipment */ 6:
                    message.equipment = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.equipment);
                    break;
                case /* int32 min_nr_seats */ 7:
                    message.minNrSeats = reader.int32();
                    break;
                case /* int32 max_nr_seats */ 8:
                    message.maxNrSeats = reader.int32();
                    break;
                case /* string timeZone */ 9:
                    message.timeZone = reader.string();
                    break;
                case /* string personal_calendar_provider_id */ 10:
                    message.personalCalendarProviderId = reader.string();
                    break;
                case /* int32 page */ 11:
                    message.page = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAvailableRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string location_id = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* string from = 3; */
        if (message.from !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.from);
        /* string to = 4; */
        if (message.to !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.to);
        /* repeated string tags = 5; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.tags[i]);
        /* AmenitiesFilter equipment = 6; */
        if (message.equipment)
            AmenitiesFilter.internalBinaryWrite(message.equipment, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int32 min_nr_seats = 7; */
        if (message.minNrSeats !== 0)
            writer.tag(7, WireType.Varint).int32(message.minNrSeats);
        /* int32 max_nr_seats = 8; */
        if (message.maxNrSeats !== 0)
            writer.tag(8, WireType.Varint).int32(message.maxNrSeats);
        /* string timeZone = 9; */
        if (message.timeZone !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.timeZone);
        /* string personal_calendar_provider_id = 10; */
        if (message.personalCalendarProviderId !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.personalCalendarProviderId);
        /* int32 page = 11; */
        if (message.page !== 0)
            writer.tag(11, WireType.Varint).int32(message.page);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListAvailableRoomsRequest
 */
export const ListAvailableRoomsRequest = new ListAvailableRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvailableRoomsResponse$Type extends MessageType<AvailableRoomsResponse> {
    constructor() {
        super("AvailableRoomsResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AvailableRoom },
            { no: 2, name: "next_page", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AvailableRoomsResponse>): AvailableRoomsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rooms = [];
        message.nextPage = 0;
        if (value !== undefined)
            reflectionMergePartial<AvailableRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AvailableRoomsResponse): AvailableRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated AvailableRoom rooms */ 1:
                    message.rooms.push(AvailableRoom.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 next_page */ 2:
                    message.nextPage = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AvailableRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated AvailableRoom rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            AvailableRoom.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 next_page = 2; */
        if (message.nextPage !== 0)
            writer.tag(2, WireType.Varint).int32(message.nextPage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AvailableRoomsResponse
 */
export const AvailableRoomsResponse = new AvailableRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvailableRoom$Type extends MessageType<AvailableRoom> {
    constructor() {
        super("AvailableRoom", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "availableFrom", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "availableTo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "resourceEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "locationPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem },
            { no: 9, name: "nextReservationTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "availableEquipment", kind: "message", T: () => AmenitiesFilter },
            { no: 13, name: "isExternal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "seats", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AvailableRoom>): AvailableRoom {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.calendarID = "";
        message.calendarProviderID = "";
        message.availableFrom = "";
        message.availableTo = "";
        message.resourceEmail = "";
        message.locationPath = [];
        message.nextReservationTime = "";
        message.description = "";
        message.tags = [];
        message.isExternal = false;
        message.seats = 0;
        if (value !== undefined)
            reflectionMergePartial<AvailableRoom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AvailableRoom): AvailableRoom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string calendarID */ 3:
                    message.calendarID = reader.string();
                    break;
                case /* string calendarProviderID */ 4:
                    message.calendarProviderID = reader.string();
                    break;
                case /* string availableFrom */ 5:
                    message.availableFrom = reader.string();
                    break;
                case /* string availableTo */ 6:
                    message.availableTo = reader.string();
                    break;
                case /* string resourceEmail */ 7:
                    message.resourceEmail = reader.string();
                    break;
                case /* repeated LocationItem locationPath */ 8:
                    message.locationPath.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string nextReservationTime */ 9:
                    message.nextReservationTime = reader.string();
                    break;
                case /* string description */ 10:
                    message.description = reader.string();
                    break;
                case /* repeated string tags */ 11:
                    message.tags.push(reader.string());
                    break;
                case /* AmenitiesFilter availableEquipment */ 12:
                    message.availableEquipment = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.availableEquipment);
                    break;
                case /* bool isExternal */ 13:
                    message.isExternal = reader.bool();
                    break;
                case /* int32 seats */ 14:
                    message.seats = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AvailableRoom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string calendarID = 3; */
        if (message.calendarID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarID);
        /* string calendarProviderID = 4; */
        if (message.calendarProviderID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.calendarProviderID);
        /* string availableFrom = 5; */
        if (message.availableFrom !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.availableFrom);
        /* string availableTo = 6; */
        if (message.availableTo !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.availableTo);
        /* string resourceEmail = 7; */
        if (message.resourceEmail !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.resourceEmail);
        /* repeated LocationItem locationPath = 8; */
        for (let i = 0; i < message.locationPath.length; i++)
            LocationItem.internalBinaryWrite(message.locationPath[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string nextReservationTime = 9; */
        if (message.nextReservationTime !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.nextReservationTime);
        /* string description = 10; */
        if (message.description !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.description);
        /* repeated string tags = 11; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.tags[i]);
        /* AmenitiesFilter availableEquipment = 12; */
        if (message.availableEquipment)
            AmenitiesFilter.internalBinaryWrite(message.availableEquipment, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool isExternal = 13; */
        if (message.isExternal !== false)
            writer.tag(13, WireType.Varint).bool(message.isExternal);
        /* int32 seats = 14; */
        if (message.seats !== 0)
            writer.tag(14, WireType.Varint).int32(message.seats);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AvailableRoom
 */
export const AvailableRoom = new AvailableRoom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NearbyRoomsRequest$Type extends MessageType<NearbyRoomsRequest> {
    constructor() {
        super("NearbyRoomsRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "room_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "min_nr_seats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "max_nr_seats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "filter", kind: "message", T: () => AmenitiesFilter },
            { no: 6, name: "showAll", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NearbyRoomsRequest>): NearbyRoomsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.roomIds = [];
        message.minNrSeats = 0;
        message.maxNrSeats = 0;
        message.showAll = false;
        message.date = "";
        message.timeZone = "";
        if (value !== undefined)
            reflectionMergePartial<NearbyRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NearbyRoomsRequest): NearbyRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* repeated string room_ids */ 2:
                    message.roomIds.push(reader.string());
                    break;
                case /* int32 min_nr_seats */ 3:
                    message.minNrSeats = reader.int32();
                    break;
                case /* int32 max_nr_seats */ 4:
                    message.maxNrSeats = reader.int32();
                    break;
                case /* AmenitiesFilter filter */ 5:
                    message.filter = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* bool showAll */ 6:
                    message.showAll = reader.bool();
                    break;
                case /* string date */ 7:
                    message.date = reader.string();
                    break;
                case /* string timeZone */ 8:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NearbyRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* repeated string room_ids = 2; */
        for (let i = 0; i < message.roomIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.roomIds[i]);
        /* int32 min_nr_seats = 3; */
        if (message.minNrSeats !== 0)
            writer.tag(3, WireType.Varint).int32(message.minNrSeats);
        /* int32 max_nr_seats = 4; */
        if (message.maxNrSeats !== 0)
            writer.tag(4, WireType.Varint).int32(message.maxNrSeats);
        /* AmenitiesFilter filter = 5; */
        if (message.filter)
            AmenitiesFilter.internalBinaryWrite(message.filter, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool showAll = 6; */
        if (message.showAll !== false)
            writer.tag(6, WireType.Varint).bool(message.showAll);
        /* string date = 7; */
        if (message.date !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.date);
        /* string timeZone = 8; */
        if (message.timeZone !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NearbyRoomsRequest
 */
export const NearbyRoomsRequest = new NearbyRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AmenitiesFilter$Type extends MessageType<AmenitiesFilter> {
    constructor() {
        super("AmenitiesFilter", [
            { no: 1, name: "accessibility", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "casting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "climate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "desk", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "audio", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "chargingPort", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "presentationTech", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "videoConference", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "faluty", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AmenitiesFilter>): AmenitiesFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessibility = false;
        message.casting = false;
        message.climate = false;
        message.desk = false;
        message.audio = false;
        message.chargingPort = false;
        message.presentationTech = false;
        message.videoConference = false;
        message.faluty = false;
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<AmenitiesFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AmenitiesFilter): AmenitiesFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool accessibility */ 1:
                    message.accessibility = reader.bool();
                    break;
                case /* bool casting */ 2:
                    message.casting = reader.bool();
                    break;
                case /* bool climate */ 3:
                    message.climate = reader.bool();
                    break;
                case /* bool desk */ 4:
                    message.desk = reader.bool();
                    break;
                case /* bool audio */ 5:
                    message.audio = reader.bool();
                    break;
                case /* bool chargingPort */ 6:
                    message.chargingPort = reader.bool();
                    break;
                case /* bool presentationTech */ 7:
                    message.presentationTech = reader.bool();
                    break;
                case /* bool videoConference */ 8:
                    message.videoConference = reader.bool();
                    break;
                case /* bool faluty */ 9:
                    message.faluty = reader.bool();
                    break;
                case /* string id */ 10:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AmenitiesFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool accessibility = 1; */
        if (message.accessibility !== false)
            writer.tag(1, WireType.Varint).bool(message.accessibility);
        /* bool casting = 2; */
        if (message.casting !== false)
            writer.tag(2, WireType.Varint).bool(message.casting);
        /* bool climate = 3; */
        if (message.climate !== false)
            writer.tag(3, WireType.Varint).bool(message.climate);
        /* bool desk = 4; */
        if (message.desk !== false)
            writer.tag(4, WireType.Varint).bool(message.desk);
        /* bool audio = 5; */
        if (message.audio !== false)
            writer.tag(5, WireType.Varint).bool(message.audio);
        /* bool chargingPort = 6; */
        if (message.chargingPort !== false)
            writer.tag(6, WireType.Varint).bool(message.chargingPort);
        /* bool presentationTech = 7; */
        if (message.presentationTech !== false)
            writer.tag(7, WireType.Varint).bool(message.presentationTech);
        /* bool videoConference = 8; */
        if (message.videoConference !== false)
            writer.tag(8, WireType.Varint).bool(message.videoConference);
        /* bool faluty = 9; */
        if (message.faluty !== false)
            writer.tag(9, WireType.Varint).bool(message.faluty);
        /* string id = 10; */
        if (message.id !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AmenitiesFilter
 */
export const AmenitiesFilter = new AmenitiesFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NearbyRoomsResponse$Type extends MessageType<NearbyRoomsResponse> {
    constructor() {
        super("NearbyRoomsResponse", [
            { no: 1, name: "nearbyRooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NearbyRoom }
        ]);
    }
    create(value?: PartialMessage<NearbyRoomsResponse>): NearbyRoomsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nearbyRooms = [];
        if (value !== undefined)
            reflectionMergePartial<NearbyRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NearbyRoomsResponse): NearbyRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated NearbyRoom nearbyRooms */ 1:
                    message.nearbyRooms.push(NearbyRoom.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NearbyRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated NearbyRoom nearbyRooms = 1; */
        for (let i = 0; i < message.nearbyRooms.length; i++)
            NearbyRoom.internalBinaryWrite(message.nearbyRooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NearbyRoomsResponse
 */
export const NearbyRoomsResponse = new NearbyRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NearbyRoom$Type extends MessageType<NearbyRoom> {
    constructor() {
        super("NearbyRoom", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "numberOfSeats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "isFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "filter", kind: "message", T: () => AmenitiesFilter },
            { no: 8, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "extendMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "deleteMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "endMeetingEarly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NearbyRoom>): NearbyRoom {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.calendarID = "";
        message.calendarProviderID = "";
        message.numberOfSeats = 0;
        message.isFree = false;
        message.error = "";
        message.locationID = "";
        message.extendMeeting = false;
        message.deleteMeeting = false;
        message.endMeetingEarly = false;
        if (value !== undefined)
            reflectionMergePartial<NearbyRoom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NearbyRoom): NearbyRoom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string calendarID */ 3:
                    message.calendarID = reader.string();
                    break;
                case /* string calendarProviderID */ 4:
                    message.calendarProviderID = reader.string();
                    break;
                case /* int32 numberOfSeats */ 5:
                    message.numberOfSeats = reader.int32();
                    break;
                case /* bool isFree */ 6:
                    message.isFree = reader.bool();
                    break;
                case /* AmenitiesFilter filter */ 7:
                    message.filter = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* string error */ 8:
                    message.error = reader.string();
                    break;
                case /* string locationID */ 9:
                    message.locationID = reader.string();
                    break;
                case /* bool extendMeeting */ 10:
                    message.extendMeeting = reader.bool();
                    break;
                case /* bool deleteMeeting */ 11:
                    message.deleteMeeting = reader.bool();
                    break;
                case /* bool endMeetingEarly */ 12:
                    message.endMeetingEarly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NearbyRoom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string calendarID = 3; */
        if (message.calendarID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarID);
        /* string calendarProviderID = 4; */
        if (message.calendarProviderID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.calendarProviderID);
        /* int32 numberOfSeats = 5; */
        if (message.numberOfSeats !== 0)
            writer.tag(5, WireType.Varint).int32(message.numberOfSeats);
        /* bool isFree = 6; */
        if (message.isFree !== false)
            writer.tag(6, WireType.Varint).bool(message.isFree);
        /* AmenitiesFilter filter = 7; */
        if (message.filter)
            AmenitiesFilter.internalBinaryWrite(message.filter, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string error = 8; */
        if (message.error !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.error);
        /* string locationID = 9; */
        if (message.locationID !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.locationID);
        /* bool extendMeeting = 10; */
        if (message.extendMeeting !== false)
            writer.tag(10, WireType.Varint).bool(message.extendMeeting);
        /* bool deleteMeeting = 11; */
        if (message.deleteMeeting !== false)
            writer.tag(11, WireType.Varint).bool(message.deleteMeeting);
        /* bool endMeetingEarly = 12; */
        if (message.endMeetingEarly !== false)
            writer.tag(12, WireType.Varint).bool(message.endMeetingEarly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NearbyRoom
 */
export const NearbyRoom = new NearbyRoom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkplaceQueryRequest$Type extends MessageType<WorkplaceQueryRequest> {
    constructor() {
        super("WorkplaceQueryRequest", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "variables", kind: "message", T: () => Struct },
            { no: 3, name: "operationName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WorkplaceQueryRequest>): WorkplaceQueryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.query = "";
        message.operationName = "";
        if (value !== undefined)
            reflectionMergePartial<WorkplaceQueryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorkplaceQueryRequest): WorkplaceQueryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* google.protobuf.Struct variables */ 2:
                    message.variables = Struct.internalBinaryRead(reader, reader.uint32(), options, message.variables);
                    break;
                case /* string operationName */ 3:
                    message.operationName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorkplaceQueryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* google.protobuf.Struct variables = 2; */
        if (message.variables)
            Struct.internalBinaryWrite(message.variables, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string operationName = 3; */
        if (message.operationName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operationName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WorkplaceQueryRequest
 */
export const WorkplaceQueryRequest = new WorkplaceQueryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkplaceQueryResponse$Type extends MessageType<WorkplaceQueryResponse> {
    constructor() {
        super("WorkplaceQueryResponse", [
            { no: 1, name: "data", kind: "message", T: () => Struct },
            { no: 2, name: "extensions", kind: "message", T: () => Struct },
            { no: 3, name: "errors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GraphQLError }
        ]);
    }
    create(value?: PartialMessage<WorkplaceQueryResponse>): WorkplaceQueryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.errors = [];
        if (value !== undefined)
            reflectionMergePartial<WorkplaceQueryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorkplaceQueryResponse): WorkplaceQueryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Struct data */ 1:
                    message.data = Struct.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* google.protobuf.Struct extensions */ 2:
                    message.extensions = Struct.internalBinaryRead(reader, reader.uint32(), options, message.extensions);
                    break;
                case /* repeated GraphQLError errors */ 3:
                    message.errors.push(GraphQLError.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorkplaceQueryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Struct data = 1; */
        if (message.data)
            Struct.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Struct extensions = 2; */
        if (message.extensions)
            Struct.internalBinaryWrite(message.extensions, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated GraphQLError errors = 3; */
        for (let i = 0; i < message.errors.length; i++)
            GraphQLError.internalBinaryWrite(message.errors[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WorkplaceQueryResponse
 */
export const WorkplaceQueryResponse = new WorkplaceQueryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphQLError$Type extends MessageType<GraphQLError> {
    constructor() {
        super("GraphQLError", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 3, name: "path", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GraphQLError>): GraphQLError {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        message.locations = [];
        message.path = [];
        if (value !== undefined)
            reflectionMergePartial<GraphQLError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GraphQLError): GraphQLError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                case /* repeated Location locations */ 2:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string path */ 3:
                    message.path.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GraphQLError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        /* repeated Location locations = 2; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string path = 3; */
        for (let i = 0; i < message.path.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.path[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GraphQLError
 */
export const GraphQLError = new GraphQLError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("Location", [
            { no: 1, name: "line", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "column", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.line = 0;
        message.column = 0;
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 line */ 1:
                    message.line = reader.int32();
                    break;
                case /* int32 column */ 2:
                    message.column = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 line = 1; */
        if (message.line !== 0)
            writer.tag(1, WireType.Varint).int32(message.line);
        /* int32 column = 2; */
        if (message.column !== 0)
            writer.tag(2, WireType.Varint).int32(message.column);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarRoomsRequest$Type extends MessageType<CalendarRoomsRequest> {
    constructor() {
        super("CalendarRoomsRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CalendarRoomsRequest>): CalendarRoomsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.calendarProviderId = "";
        if (value !== undefined)
            reflectionMergePartial<CalendarRoomsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalendarRoomsRequest): CalendarRoomsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarProviderId */ 2:
                    message.calendarProviderId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalendarRoomsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarProviderId = 2; */
        if (message.calendarProviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarRoomsRequest
 */
export const CalendarRoomsRequest = new CalendarRoomsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarRoom$Type extends MessageType<CalendarRoom> {
    constructor() {
        super("CalendarRoom", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "calendarId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CalendarRoom>): CalendarRoom {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.calendarId = "";
        if (value !== undefined)
            reflectionMergePartial<CalendarRoom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalendarRoom): CalendarRoom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string calendarId */ 3:
                    message.calendarId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalendarRoom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string calendarId = 3; */
        if (message.calendarId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.calendarId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarRoom
 */
export const CalendarRoom = new CalendarRoom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalendarRoomsResponse$Type extends MessageType<CalendarRoomsResponse> {
    constructor() {
        super("CalendarRoomsResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CalendarRoom }
        ]);
    }
    create(value?: PartialMessage<CalendarRoomsResponse>): CalendarRoomsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rooms = [];
        if (value !== undefined)
            reflectionMergePartial<CalendarRoomsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalendarRoomsResponse): CalendarRoomsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CalendarRoom rooms */ 1:
                    message.rooms.push(CalendarRoom.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalendarRoomsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated CalendarRoom rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            CalendarRoom.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CalendarRoomsResponse
 */
export const CalendarRoomsResponse = new CalendarRoomsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteWorkplaceRequest$Type extends MessageType<DeleteWorkplaceRequest> {
    constructor() {
        super("DeleteWorkplaceRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteWorkplaceRequest>): DeleteWorkplaceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteWorkplaceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteWorkplaceRequest): DeleteWorkplaceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteWorkplaceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteWorkplaceRequest
 */
export const DeleteWorkplaceRequest = new DeleteWorkplaceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisconnectCalendarProviderResponse$Type extends MessageType<DisconnectCalendarProviderResponse> {
    constructor() {
        super("DisconnectCalendarProviderResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DisconnectCalendarProviderResponse>): DisconnectCalendarProviderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<DisconnectCalendarProviderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisconnectCalendarProviderResponse): DisconnectCalendarProviderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisconnectCalendarProviderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisconnectCalendarProviderResponse
 */
export const DisconnectCalendarProviderResponse = new DisconnectCalendarProviderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRequest$Type extends MessageType<SearchRequest> {
    constructor() {
        super("SearchRequest", [
            { no: 1, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "filterTags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "onlyFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "ignoreBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "allDesks", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRequest>): SearchRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationID = "";
        message.customerID = "";
        message.filterTags = [];
        message.onlyFree = false;
        message.ignoreBlocked = false;
        message.allDesks = false;
        message.timeZone = "";
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<SearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRequest): SearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationID */ 1:
                    message.locationID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* repeated string filterTags */ 3:
                    message.filterTags.push(reader.string());
                    break;
                case /* bool onlyFree */ 4:
                    message.onlyFree = reader.bool();
                    break;
                case /* bool ignoreBlocked */ 5:
                    message.ignoreBlocked = reader.bool();
                    break;
                case /* bool allDesks */ 6:
                    message.allDesks = reader.bool();
                    break;
                case /* string timeZone */ 7:
                    message.timeZone = reader.string();
                    break;
                case /* int32 page */ 8:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 9:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationID = 1; */
        if (message.locationID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* repeated string filterTags = 3; */
        for (let i = 0; i < message.filterTags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.filterTags[i]);
        /* bool onlyFree = 4; */
        if (message.onlyFree !== false)
            writer.tag(4, WireType.Varint).bool(message.onlyFree);
        /* bool ignoreBlocked = 5; */
        if (message.ignoreBlocked !== false)
            writer.tag(5, WireType.Varint).bool(message.ignoreBlocked);
        /* bool allDesks = 6; */
        if (message.allDesks !== false)
            writer.tag(6, WireType.Varint).bool(message.allDesks);
        /* string timeZone = 7; */
        if (message.timeZone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.timeZone);
        /* int32 page = 8; */
        if (message.page !== 0)
            writer.tag(8, WireType.Varint).int32(message.page);
        /* int32 pageSize = 9; */
        if (message.pageSize !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SearchRequest
 */
export const SearchRequest = new SearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchSearchRequest$Type extends MessageType<BatchSearchRequest> {
    constructor() {
        super("BatchSearchRequest", [
            { no: 1, name: "locationsID", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "filterTags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "onlyFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BatchSearchRequest>): BatchSearchRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationsID = [];
        message.customerID = "";
        message.filterTags = [];
        message.onlyFree = false;
        if (value !== undefined)
            reflectionMergePartial<BatchSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchSearchRequest): BatchSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string locationsID */ 1:
                    message.locationsID.push(reader.string());
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* repeated string filterTags */ 3:
                    message.filterTags.push(reader.string());
                    break;
                case /* bool onlyFree */ 4:
                    message.onlyFree = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string locationsID = 1; */
        for (let i = 0; i < message.locationsID.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.locationsID[i]);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* repeated string filterTags = 3; */
        for (let i = 0; i < message.filterTags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.filterTags[i]);
        /* bool onlyFree = 4; */
        if (message.onlyFree !== false)
            writer.tag(4, WireType.Varint).bool(message.onlyFree);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BatchSearchRequest
 */
export const BatchSearchRequest = new BatchSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchAccountDesksRequest$Type extends MessageType<SearchAccountDesksRequest> {
    constructor() {
        super("SearchAccountDesksRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchAccountDesksRequest>): SearchAccountDesksRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = "";
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<SearchAccountDesksRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchAccountDesksRequest): SearchAccountDesksRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchAccountDesksRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SearchAccountDesksRequest
 */
export const SearchAccountDesksRequest = new SearchAccountDesksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchDesk$Type extends MessageType<SearchDesk> {
    constructor() {
        super("SearchDesk", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "licensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "typeName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "locationPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem },
            { no: 9, name: "checkInRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "checkInTime", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "qrCodeRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "marker", kind: "message", T: () => Marker },
            { no: 13, name: "workingHrsActivated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "workingHrsStart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "workingHrsEnd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "WorkingHrs", kind: "message", jsonName: "WorkingHrs", T: () => WorkingHrs },
            { no: 17, name: "account", kind: "message", T: () => SearchDesk_Account },
            { no: 18, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "workingWeekDays", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "showCheckInTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "isBooked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SearchDesk>): SearchDesk {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.tags = [];
        message.description = "";
        message.licensed = false;
        message.typeName = "";
        message.locationID = "";
        message.locationPath = [];
        message.checkInRequired = false;
        message.checkInTime = 0;
        message.qrCodeRequired = false;
        message.workingHrsActivated = false;
        message.workingHrsStart = "";
        message.workingHrsEnd = "";
        message.isBlocked = false;
        message.workingWeekDays = [];
        message.showCheckInTime = false;
        message.isBooked = false;
        if (value !== undefined)
            reflectionMergePartial<SearchDesk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchDesk): SearchDesk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* bool licensed */ 5:
                    message.licensed = reader.bool();
                    break;
                case /* string typeName */ 6:
                    message.typeName = reader.string();
                    break;
                case /* string locationID */ 7:
                    message.locationID = reader.string();
                    break;
                case /* repeated LocationItem locationPath */ 8:
                    message.locationPath.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool checkInRequired */ 9:
                    message.checkInRequired = reader.bool();
                    break;
                case /* int32 checkInTime */ 10:
                    message.checkInTime = reader.int32();
                    break;
                case /* bool qrCodeRequired */ 11:
                    message.qrCodeRequired = reader.bool();
                    break;
                case /* Marker marker */ 12:
                    message.marker = Marker.internalBinaryRead(reader, reader.uint32(), options, message.marker);
                    break;
                case /* bool workingHrsActivated */ 13:
                    message.workingHrsActivated = reader.bool();
                    break;
                case /* string workingHrsStart */ 14:
                    message.workingHrsStart = reader.string();
                    break;
                case /* string workingHrsEnd */ 15:
                    message.workingHrsEnd = reader.string();
                    break;
                case /* WorkingHrs WorkingHrs = 16 [json_name = "WorkingHrs"];*/ 16:
                    message.workingHrs = WorkingHrs.internalBinaryRead(reader, reader.uint32(), options, message.workingHrs);
                    break;
                case /* SearchDesk.Account account */ 17:
                    message.account = SearchDesk_Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* bool isBlocked */ 18:
                    message.isBlocked = reader.bool();
                    break;
                case /* repeated int32 workingWeekDays */ 19:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.workingWeekDays.push(reader.int32());
                    else
                        message.workingWeekDays.push(reader.int32());
                    break;
                case /* bool showCheckInTime */ 20:
                    message.showCheckInTime = reader.bool();
                    break;
                case /* bool isBooked */ 21:
                    message.isBooked = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchDesk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* bool licensed = 5; */
        if (message.licensed !== false)
            writer.tag(5, WireType.Varint).bool(message.licensed);
        /* string typeName = 6; */
        if (message.typeName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.typeName);
        /* string locationID = 7; */
        if (message.locationID !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.locationID);
        /* repeated LocationItem locationPath = 8; */
        for (let i = 0; i < message.locationPath.length; i++)
            LocationItem.internalBinaryWrite(message.locationPath[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool checkInRequired = 9; */
        if (message.checkInRequired !== false)
            writer.tag(9, WireType.Varint).bool(message.checkInRequired);
        /* int32 checkInTime = 10; */
        if (message.checkInTime !== 0)
            writer.tag(10, WireType.Varint).int32(message.checkInTime);
        /* bool qrCodeRequired = 11; */
        if (message.qrCodeRequired !== false)
            writer.tag(11, WireType.Varint).bool(message.qrCodeRequired);
        /* Marker marker = 12; */
        if (message.marker)
            Marker.internalBinaryWrite(message.marker, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool workingHrsActivated = 13; */
        if (message.workingHrsActivated !== false)
            writer.tag(13, WireType.Varint).bool(message.workingHrsActivated);
        /* string workingHrsStart = 14; */
        if (message.workingHrsStart !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.workingHrsStart);
        /* string workingHrsEnd = 15; */
        if (message.workingHrsEnd !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.workingHrsEnd);
        /* WorkingHrs WorkingHrs = 16 [json_name = "WorkingHrs"]; */
        if (message.workingHrs)
            WorkingHrs.internalBinaryWrite(message.workingHrs, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* SearchDesk.Account account = 17; */
        if (message.account)
            SearchDesk_Account.internalBinaryWrite(message.account, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* bool isBlocked = 18; */
        if (message.isBlocked !== false)
            writer.tag(18, WireType.Varint).bool(message.isBlocked);
        /* repeated int32 workingWeekDays = 19; */
        if (message.workingWeekDays.length) {
            writer.tag(19, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.workingWeekDays.length; i++)
                writer.int32(message.workingWeekDays[i]);
            writer.join();
        }
        /* bool showCheckInTime = 20; */
        if (message.showCheckInTime !== false)
            writer.tag(20, WireType.Varint).bool(message.showCheckInTime);
        /* bool isBooked = 21; */
        if (message.isBooked !== false)
            writer.tag(21, WireType.Varint).bool(message.isBooked);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SearchDesk
 */
export const SearchDesk = new SearchDesk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchDesk_Account$Type extends MessageType<SearchDesk_Account> {
    constructor() {
        super("SearchDesk.Account", [
            { no: 1, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tenantID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchDesk_Account>): SearchDesk_Account {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.displayName = "";
        message.email = "";
        message.tenantID = "";
        message.accountID = "";
        if (value !== undefined)
            reflectionMergePartial<SearchDesk_Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchDesk_Account): SearchDesk_Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string displayName */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string tenantID */ 3:
                    message.tenantID = reader.string();
                    break;
                case /* string accountID */ 4:
                    message.accountID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchDesk_Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string displayName = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string tenantID = 3; */
        if (message.tenantID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tenantID);
        /* string accountID = 4; */
        if (message.accountID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SearchDesk.Account
 */
export const SearchDesk_Account = new SearchDesk_Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchDesksResponse$Type extends MessageType<SearchDesksResponse> {
    constructor() {
        super("SearchDesksResponse", [
            { no: 1, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchDesk }
        ]);
    }
    create(value?: PartialMessage<SearchDesksResponse>): SearchDesksResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.desks = [];
        if (value !== undefined)
            reflectionMergePartial<SearchDesksResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchDesksResponse): SearchDesksResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SearchDesk desks */ 1:
                    message.desks.push(SearchDesk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchDesksResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated SearchDesk desks = 1; */
        for (let i = 0; i < message.desks.length; i++)
            SearchDesk.internalBinaryWrite(message.desks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SearchDesksResponse
 */
export const SearchDesksResponse = new SearchDesksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationItem$Type extends MessageType<LocationItem> {
    constructor() {
        super("LocationItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LocationItem>): LocationItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.type = "";
        message.index = 0;
        if (value !== undefined)
            reflectionMergePartial<LocationItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationItem): LocationItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* int32 index */ 4:
                    message.index = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* int32 index = 4; */
        if (message.index !== 0)
            writer.tag(4, WireType.Varint).int32(message.index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LocationItem
 */
export const LocationItem = new LocationItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationPathResponse$Type extends MessageType<LocationPathResponse> {
    constructor() {
        super("LocationPathResponse", [
            { no: 1, name: "path", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem }
        ]);
    }
    create(value?: PartialMessage<LocationPathResponse>): LocationPathResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.path = [];
        if (value !== undefined)
            reflectionMergePartial<LocationPathResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationPathResponse): LocationPathResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LocationItem path */ 1:
                    message.path.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationPathResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated LocationItem path = 1; */
        for (let i = 0; i < message.path.length; i++)
            LocationItem.internalBinaryWrite(message.path[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LocationPathResponse
 */
export const LocationPathResponse = new LocationPathResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchLocationPathResponse$Type extends MessageType<BatchLocationPathResponse> {
    constructor() {
        super("BatchLocationPathResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BatchLocationPathResponse_BatchLocationItem }
        ]);
    }
    create(value?: PartialMessage<BatchLocationPathResponse>): BatchLocationPathResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<BatchLocationPathResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchLocationPathResponse): BatchLocationPathResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BatchLocationPathResponse.BatchLocationItem items */ 1:
                    message.items.push(BatchLocationPathResponse_BatchLocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchLocationPathResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BatchLocationPathResponse.BatchLocationItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            BatchLocationPathResponse_BatchLocationItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BatchLocationPathResponse
 */
export const BatchLocationPathResponse = new BatchLocationPathResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchLocationPathResponse_BatchLocationItem$Type extends MessageType<BatchLocationPathResponse_BatchLocationItem> {
    constructor() {
        super("BatchLocationPathResponse.BatchLocationItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "path", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem }
        ]);
    }
    create(value?: PartialMessage<BatchLocationPathResponse_BatchLocationItem>): BatchLocationPathResponse_BatchLocationItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.type = "";
        message.path = [];
        if (value !== undefined)
            reflectionMergePartial<BatchLocationPathResponse_BatchLocationItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchLocationPathResponse_BatchLocationItem): BatchLocationPathResponse_BatchLocationItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* repeated LocationItem path */ 4:
                    message.path.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchLocationPathResponse_BatchLocationItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* repeated LocationItem path = 4; */
        for (let i = 0; i < message.path.length; i++)
            LocationItem.internalBinaryWrite(message.path[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BatchLocationPathResponse.BatchLocationItem
 */
export const BatchLocationPathResponse_BatchLocationItem = new BatchLocationPathResponse_BatchLocationItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SameLevelDesksRequest$Type extends MessageType<SameLevelDesksRequest> {
    constructor() {
        super("SameLevelDesksRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "licensedOnly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SameLevelDesksRequest>): SameLevelDesksRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerID = "";
        message.licensedOnly = false;
        if (value !== undefined)
            reflectionMergePartial<SameLevelDesksRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SameLevelDesksRequest): SameLevelDesksRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* bool licensedOnly */ 3:
                    message.licensedOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SameLevelDesksRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* bool licensedOnly = 3; */
        if (message.licensedOnly !== false)
            writer.tag(3, WireType.Varint).bool(message.licensedOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SameLevelDesksRequest
 */
export const SameLevelDesksRequest = new SameLevelDesksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DesksListResponse$Type extends MessageType<DesksListResponse> {
    constructor() {
        super("DesksListResponse", [
            { no: 1, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchDesk }
        ]);
    }
    create(value?: PartialMessage<DesksListResponse>): DesksListResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.desks = [];
        if (value !== undefined)
            reflectionMergePartial<DesksListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DesksListResponse): DesksListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SearchDesk desks */ 1:
                    message.desks.push(SearchDesk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DesksListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated SearchDesk desks = 1; */
        for (let i = 0; i < message.desks.length; i++)
            SearchDesk.internalBinaryWrite(message.desks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DesksListResponse
 */
export const DesksListResponse = new DesksListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DesksInfoRequest$Type extends MessageType<DesksInfoRequest> {
    constructor() {
        super("DesksInfoRequest", [
            { no: 1, name: "id", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DesksInfoRequest>): DesksInfoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = [];
        message.customerID = "";
        message.timeZone = "";
        if (value !== undefined)
            reflectionMergePartial<DesksInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DesksInfoRequest): DesksInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string id */ 1:
                    message.id.push(reader.string());
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string timeZone */ 4:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DesksInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string id = 1; */
        for (let i = 0; i < message.id.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.id[i]);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string timeZone = 4; */
        if (message.timeZone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DesksInfoRequest
 */
export const DesksInfoRequest = new DesksInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsInfo$Type extends MessageType<RoomsInfo> {
    constructor() {
        super("RoomsInfo", [
            { no: 1, name: "calendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoomsInfo>): RoomsInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.calendarID = "";
        message.roomID = "";
        if (value !== undefined)
            reflectionMergePartial<RoomsInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsInfo): RoomsInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string calendarID */ 1:
                    message.calendarID = reader.string();
                    break;
                case /* string roomID */ 2:
                    message.roomID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string calendarID = 1; */
        if (message.calendarID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.calendarID);
        /* string roomID = 2; */
        if (message.roomID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roomID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsInfo
 */
export const RoomsInfo = new RoomsInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsInfoRequest$Type extends MessageType<RoomsInfoRequest> {
    constructor() {
        super("RoomsInfoRequest", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomsInfo },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "wayfinderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoomsInfoRequest>): RoomsInfoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rooms = [];
        message.customerID = "";
        message.wayfinderID = "";
        message.timeZone = "";
        if (value !== undefined)
            reflectionMergePartial<RoomsInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsInfoRequest): RoomsInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RoomsInfo rooms */ 1:
                    message.rooms.push(RoomsInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* string wayfinderID */ 3:
                    message.wayfinderID = reader.string();
                    break;
                case /* string timeZone */ 4:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated RoomsInfo rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            RoomsInfo.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* string wayfinderID = 3; */
        if (message.wayfinderID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.wayfinderID);
        /* string timeZone = 4; */
        if (message.timeZone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsInfoRequest
 */
export const RoomsInfoRequest = new RoomsInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GraphCustomLanguage$Type extends MessageType<GraphCustomLanguage> {
    constructor() {
        super("GraphCustomLanguage", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GraphCustomLanguage>): GraphCustomLanguage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.value = "";
        message.url = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<GraphCustomLanguage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GraphCustomLanguage): GraphCustomLanguage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GraphCustomLanguage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GraphCustomLanguage
 */
export const GraphCustomLanguage = new GraphCustomLanguage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomDisplaySettings$Type extends MessageType<RoomDisplaySettings> {
    constructor() {
        super("RoomDisplaySettings", [
            { no: 1, name: "showCheckinRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "workingHrsActivated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "workingHrsStart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "workingHrsEnd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "workingHrs", kind: "message", T: () => WorkingHrs },
            { no: 6, name: "workingWeekDays", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "deleteMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "endMeetingEarly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "extendMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "customLanguage", kind: "message", T: () => GraphCustomLanguage },
            { no: 17, name: "allowToDeleteMeetings", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "removeGhostRecurringEvents", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "showMeetingOrganizer", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "showMeetingTitle", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 24, name: "showAttendees", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RoomDisplaySettings>): RoomDisplaySettings {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.showCheckinRequired = false;
        message.workingHrsActivated = false;
        message.workingHrsStart = "";
        message.workingHrsEnd = "";
        message.workingWeekDays = [];
        message.deleteMeeting = false;
        message.endMeetingEarly = false;
        message.extendMeeting = false;
        message.allowToDeleteMeetings = false;
        message.language = "";
        message.removeGhostRecurringEvents = false;
        message.showMeetingOrganizer = false;
        message.showMeetingTitle = false;
        message.showAttendees = false;
        if (value !== undefined)
            reflectionMergePartial<RoomDisplaySettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomDisplaySettings): RoomDisplaySettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool showCheckinRequired */ 1:
                    message.showCheckinRequired = reader.bool();
                    break;
                case /* bool workingHrsActivated */ 2:
                    message.workingHrsActivated = reader.bool();
                    break;
                case /* string workingHrsStart */ 3:
                    message.workingHrsStart = reader.string();
                    break;
                case /* string workingHrsEnd */ 4:
                    message.workingHrsEnd = reader.string();
                    break;
                case /* WorkingHrs workingHrs */ 5:
                    message.workingHrs = WorkingHrs.internalBinaryRead(reader, reader.uint32(), options, message.workingHrs);
                    break;
                case /* repeated int32 workingWeekDays */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.workingWeekDays.push(reader.int32());
                    else
                        message.workingWeekDays.push(reader.int32());
                    break;
                case /* bool deleteMeeting */ 7:
                    message.deleteMeeting = reader.bool();
                    break;
                case /* bool endMeetingEarly */ 8:
                    message.endMeetingEarly = reader.bool();
                    break;
                case /* bool extendMeeting */ 9:
                    message.extendMeeting = reader.bool();
                    break;
                case /* GraphCustomLanguage customLanguage */ 10:
                    message.customLanguage = GraphCustomLanguage.internalBinaryRead(reader, reader.uint32(), options, message.customLanguage);
                    break;
                case /* bool allowToDeleteMeetings */ 17:
                    message.allowToDeleteMeetings = reader.bool();
                    break;
                case /* string language */ 20:
                    message.language = reader.string();
                    break;
                case /* bool removeGhostRecurringEvents */ 21:
                    message.removeGhostRecurringEvents = reader.bool();
                    break;
                case /* bool showMeetingOrganizer */ 22:
                    message.showMeetingOrganizer = reader.bool();
                    break;
                case /* bool showMeetingTitle */ 23:
                    message.showMeetingTitle = reader.bool();
                    break;
                case /* bool showAttendees */ 24:
                    message.showAttendees = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomDisplaySettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool showCheckinRequired = 1; */
        if (message.showCheckinRequired !== false)
            writer.tag(1, WireType.Varint).bool(message.showCheckinRequired);
        /* bool workingHrsActivated = 2; */
        if (message.workingHrsActivated !== false)
            writer.tag(2, WireType.Varint).bool(message.workingHrsActivated);
        /* string workingHrsStart = 3; */
        if (message.workingHrsStart !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.workingHrsStart);
        /* string workingHrsEnd = 4; */
        if (message.workingHrsEnd !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.workingHrsEnd);
        /* WorkingHrs workingHrs = 5; */
        if (message.workingHrs)
            WorkingHrs.internalBinaryWrite(message.workingHrs, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated int32 workingWeekDays = 6; */
        if (message.workingWeekDays.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.workingWeekDays.length; i++)
                writer.int32(message.workingWeekDays[i]);
            writer.join();
        }
        /* bool deleteMeeting = 7; */
        if (message.deleteMeeting !== false)
            writer.tag(7, WireType.Varint).bool(message.deleteMeeting);
        /* bool endMeetingEarly = 8; */
        if (message.endMeetingEarly !== false)
            writer.tag(8, WireType.Varint).bool(message.endMeetingEarly);
        /* bool extendMeeting = 9; */
        if (message.extendMeeting !== false)
            writer.tag(9, WireType.Varint).bool(message.extendMeeting);
        /* GraphCustomLanguage customLanguage = 10; */
        if (message.customLanguage)
            GraphCustomLanguage.internalBinaryWrite(message.customLanguage, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* bool allowToDeleteMeetings = 17; */
        if (message.allowToDeleteMeetings !== false)
            writer.tag(17, WireType.Varint).bool(message.allowToDeleteMeetings);
        /* string language = 20; */
        if (message.language !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.language);
        /* bool removeGhostRecurringEvents = 21; */
        if (message.removeGhostRecurringEvents !== false)
            writer.tag(21, WireType.Varint).bool(message.removeGhostRecurringEvents);
        /* bool showMeetingOrganizer = 22; */
        if (message.showMeetingOrganizer !== false)
            writer.tag(22, WireType.Varint).bool(message.showMeetingOrganizer);
        /* bool showMeetingTitle = 23; */
        if (message.showMeetingTitle !== false)
            writer.tag(23, WireType.Varint).bool(message.showMeetingTitle);
        /* bool showAttendees = 24; */
        if (message.showAttendees !== false)
            writer.tag(24, WireType.Varint).bool(message.showAttendees);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomDisplaySettings
 */
export const RoomDisplaySettings = new RoomDisplaySettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomDirection$Type extends MessageType<RoomDirection> {
    constructor() {
        super("RoomDirection", [
            { no: 1, name: "distance", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "direction", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "distanceUnit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "roomID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "wayfinderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoomDirection>): RoomDirection {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.distance = 0;
        message.direction = 0;
        message.distanceUnit = "";
        message.id = "";
        message.roomID = "";
        message.wayfinderID = "";
        if (value !== undefined)
            reflectionMergePartial<RoomDirection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomDirection): RoomDirection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 distance */ 1:
                    message.distance = reader.int32();
                    break;
                case /* int32 direction */ 2:
                    message.direction = reader.int32();
                    break;
                case /* string distanceUnit */ 3:
                    message.distanceUnit = reader.string();
                    break;
                case /* string id */ 4:
                    message.id = reader.string();
                    break;
                case /* string roomID */ 5:
                    message.roomID = reader.string();
                    break;
                case /* string wayfinderID */ 6:
                    message.wayfinderID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomDirection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 distance = 1; */
        if (message.distance !== 0)
            writer.tag(1, WireType.Varint).int32(message.distance);
        /* int32 direction = 2; */
        if (message.direction !== 0)
            writer.tag(2, WireType.Varint).int32(message.direction);
        /* string distanceUnit = 3; */
        if (message.distanceUnit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.distanceUnit);
        /* string id = 4; */
        if (message.id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.id);
        /* string roomID = 5; */
        if (message.roomID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.roomID);
        /* string wayfinderID = 6; */
        if (message.wayfinderID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.wayfinderID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomDirection
 */
export const RoomDirection = new RoomDirection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsData$Type extends MessageType<RoomsData> {
    constructor() {
        super("RoomsData", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "calendarID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "calendarProviderID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "locationPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem },
            { no: 9, name: "checkInRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "checkInTime", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "qrCodeRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "resourceEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "displaySettings", kind: "message", T: () => RoomDisplaySettings },
            { no: 14, name: "numberOfSeats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "direction", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomDirection },
            { no: 16, name: "licensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "calendarProviderActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "checkinReminder", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "equipment", kind: "message", T: () => AmenitiesFilter }
        ]);
    }
    create(value?: PartialMessage<RoomsData>): RoomsData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.locationID = "";
        message.calendarID = "";
        message.calendarProviderID = "";
        message.locationPath = [];
        message.checkInRequired = false;
        message.checkInTime = 0;
        message.qrCodeRequired = false;
        message.resourceEmail = "";
        message.numberOfSeats = 0;
        message.direction = [];
        message.licensed = false;
        message.calendarProviderActive = false;
        message.checkinReminder = false;
        message.isBlocked = false;
        message.tags = [];
        if (value !== undefined)
            reflectionMergePartial<RoomsData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsData): RoomsData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string locationID */ 3:
                    message.locationID = reader.string();
                    break;
                case /* string calendarID */ 4:
                    message.calendarID = reader.string();
                    break;
                case /* string calendarProviderID */ 5:
                    message.calendarProviderID = reader.string();
                    break;
                case /* repeated LocationItem locationPath */ 6:
                    message.locationPath.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool checkInRequired */ 9:
                    message.checkInRequired = reader.bool();
                    break;
                case /* int32 checkInTime */ 10:
                    message.checkInTime = reader.int32();
                    break;
                case /* bool qrCodeRequired */ 11:
                    message.qrCodeRequired = reader.bool();
                    break;
                case /* string resourceEmail */ 12:
                    message.resourceEmail = reader.string();
                    break;
                case /* RoomDisplaySettings displaySettings */ 13:
                    message.displaySettings = RoomDisplaySettings.internalBinaryRead(reader, reader.uint32(), options, message.displaySettings);
                    break;
                case /* int32 numberOfSeats */ 14:
                    message.numberOfSeats = reader.int32();
                    break;
                case /* repeated RoomDirection direction */ 15:
                    message.direction.push(RoomDirection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool licensed */ 16:
                    message.licensed = reader.bool();
                    break;
                case /* bool calendarProviderActive */ 17:
                    message.calendarProviderActive = reader.bool();
                    break;
                case /* bool checkinReminder */ 18:
                    message.checkinReminder = reader.bool();
                    break;
                case /* bool isBlocked */ 19:
                    message.isBlocked = reader.bool();
                    break;
                case /* repeated string tags */ 20:
                    message.tags.push(reader.string());
                    break;
                case /* AmenitiesFilter equipment */ 21:
                    message.equipment = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.equipment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string locationID = 3; */
        if (message.locationID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.locationID);
        /* string calendarID = 4; */
        if (message.calendarID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.calendarID);
        /* string calendarProviderID = 5; */
        if (message.calendarProviderID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.calendarProviderID);
        /* repeated LocationItem locationPath = 6; */
        for (let i = 0; i < message.locationPath.length; i++)
            LocationItem.internalBinaryWrite(message.locationPath[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool checkInRequired = 9; */
        if (message.checkInRequired !== false)
            writer.tag(9, WireType.Varint).bool(message.checkInRequired);
        /* int32 checkInTime = 10; */
        if (message.checkInTime !== 0)
            writer.tag(10, WireType.Varint).int32(message.checkInTime);
        /* bool qrCodeRequired = 11; */
        if (message.qrCodeRequired !== false)
            writer.tag(11, WireType.Varint).bool(message.qrCodeRequired);
        /* string resourceEmail = 12; */
        if (message.resourceEmail !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.resourceEmail);
        /* RoomDisplaySettings displaySettings = 13; */
        if (message.displaySettings)
            RoomDisplaySettings.internalBinaryWrite(message.displaySettings, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* int32 numberOfSeats = 14; */
        if (message.numberOfSeats !== 0)
            writer.tag(14, WireType.Varint).int32(message.numberOfSeats);
        /* repeated RoomDirection direction = 15; */
        for (let i = 0; i < message.direction.length; i++)
            RoomDirection.internalBinaryWrite(message.direction[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* bool licensed = 16; */
        if (message.licensed !== false)
            writer.tag(16, WireType.Varint).bool(message.licensed);
        /* bool calendarProviderActive = 17; */
        if (message.calendarProviderActive !== false)
            writer.tag(17, WireType.Varint).bool(message.calendarProviderActive);
        /* bool checkinReminder = 18; */
        if (message.checkinReminder !== false)
            writer.tag(18, WireType.Varint).bool(message.checkinReminder);
        /* bool isBlocked = 19; */
        if (message.isBlocked !== false)
            writer.tag(19, WireType.Varint).bool(message.isBlocked);
        /* repeated string tags = 20; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.tags[i]);
        /* AmenitiesFilter equipment = 21; */
        if (message.equipment)
            AmenitiesFilter.internalBinaryWrite(message.equipment, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsData
 */
export const RoomsData = new RoomsData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomsInfoResponse$Type extends MessageType<RoomsInfoResponse> {
    constructor() {
        super("RoomsInfoResponse", [
            { no: 1, name: "rooms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomsData }
        ]);
    }
    create(value?: PartialMessage<RoomsInfoResponse>): RoomsInfoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rooms = [];
        if (value !== undefined)
            reflectionMergePartial<RoomsInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomsInfoResponse): RoomsInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RoomsData rooms */ 1:
                    message.rooms.push(RoomsData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomsInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated RoomsData rooms = 1; */
        for (let i = 0; i < message.rooms.length; i++)
            RoomsData.internalBinaryWrite(message.rooms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomsInfoResponse
 */
export const RoomsInfoResponse = new RoomsInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateQRCodeRequest$Type extends MessageType<GenerateQRCodeRequest> {
    constructor() {
        super("GenerateQRCodeRequest", [
            { no: 1, name: "entityID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "entityType", kind: "enum", T: () => ["ListTagsEntity", ListTagsEntity] }
        ]);
    }
    create(value?: PartialMessage<GenerateQRCodeRequest>): GenerateQRCodeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entityID = "";
        message.customerID = "";
        message.entityType = 0;
        if (value !== undefined)
            reflectionMergePartial<GenerateQRCodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateQRCodeRequest): GenerateQRCodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string entityID */ 1:
                    message.entityID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* ListTagsEntity entityType */ 3:
                    message.entityType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateQRCodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string entityID = 1; */
        if (message.entityID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.entityID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* ListTagsEntity entityType = 3; */
        if (message.entityType !== 0)
            writer.tag(3, WireType.Varint).int32(message.entityType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GenerateQRCodeRequest
 */
export const GenerateQRCodeRequest = new GenerateQRCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateQRCodeResponse$Type extends MessageType<GenerateQRCodeResponse> {
    constructor() {
        super("GenerateQRCodeResponse", [
            { no: 1, name: "base64", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateQRCodeResponse>): GenerateQRCodeResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.base64 = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<GenerateQRCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateQRCodeResponse): GenerateQRCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes base64 */ 1:
                    message.base64 = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateQRCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes base64 = 1; */
        if (message.base64.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.base64);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GenerateQRCodeResponse
 */
export const GenerateQRCodeResponse = new GenerateQRCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDeskRequest$Type extends MessageType<GetDeskRequest> {
    constructor() {
        super("GetDeskRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDeskRequest>): GetDeskRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerId = "";
        message.timeZone = "";
        message.time = "";
        message.accountId = "";
        if (value !== undefined)
            reflectionMergePartial<GetDeskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDeskRequest): GetDeskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* string timeZone */ 3:
                    message.timeZone = reader.string();
                    break;
                case /* string time */ 4:
                    message.time = reader.string();
                    break;
                case /* string accountId */ 5:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDeskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* string timeZone = 3; */
        if (message.timeZone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timeZone);
        /* string time = 4; */
        if (message.time !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.time);
        /* string accountId = 5; */
        if (message.accountId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDeskRequest
 */
export const GetDeskRequest = new GetDeskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDeskResponse$Type extends MessageType<GetDeskResponse> {
    constructor() {
        super("GetDeskResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "availableFrom", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "availableUntil", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "isBusy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "path", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem },
            { no: 8, name: "workingHrs", kind: "message", T: () => WorkingHrs },
            { no: 9, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "isAssigned", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "busySlots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BusyTimeSlots }
        ]);
    }
    create(value?: PartialMessage<GetDeskResponse>): GetDeskResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.availableFrom = "";
        message.availableUntil = "";
        message.isBusy = false;
        message.tags = [];
        message.path = [];
        message.isBlocked = false;
        message.isAssigned = false;
        message.busySlots = [];
        if (value !== undefined)
            reflectionMergePartial<GetDeskResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDeskResponse): GetDeskResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string availableFrom */ 3:
                    message.availableFrom = reader.string();
                    break;
                case /* string availableUntil */ 4:
                    message.availableUntil = reader.string();
                    break;
                case /* bool isBusy */ 5:
                    message.isBusy = reader.bool();
                    break;
                case /* repeated string tags */ 6:
                    message.tags.push(reader.string());
                    break;
                case /* repeated LocationItem path */ 7:
                    message.path.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* WorkingHrs workingHrs */ 8:
                    message.workingHrs = WorkingHrs.internalBinaryRead(reader, reader.uint32(), options, message.workingHrs);
                    break;
                case /* bool isBlocked */ 9:
                    message.isBlocked = reader.bool();
                    break;
                case /* bool isAssigned */ 10:
                    message.isAssigned = reader.bool();
                    break;
                case /* repeated ggevent.BusyTimeSlots busySlots */ 11:
                    message.busySlots.push(BusyTimeSlots.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDeskResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string availableFrom = 3; */
        if (message.availableFrom !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.availableFrom);
        /* string availableUntil = 4; */
        if (message.availableUntil !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.availableUntil);
        /* bool isBusy = 5; */
        if (message.isBusy !== false)
            writer.tag(5, WireType.Varint).bool(message.isBusy);
        /* repeated string tags = 6; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.tags[i]);
        /* repeated LocationItem path = 7; */
        for (let i = 0; i < message.path.length; i++)
            LocationItem.internalBinaryWrite(message.path[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* WorkingHrs workingHrs = 8; */
        if (message.workingHrs)
            WorkingHrs.internalBinaryWrite(message.workingHrs, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool isBlocked = 9; */
        if (message.isBlocked !== false)
            writer.tag(9, WireType.Varint).bool(message.isBlocked);
        /* bool isAssigned = 10; */
        if (message.isAssigned !== false)
            writer.tag(10, WireType.Varint).bool(message.isAssigned);
        /* repeated ggevent.BusyTimeSlots busySlots = 11; */
        for (let i = 0; i < message.busySlots.length; i++)
            BusyTimeSlots.internalBinaryWrite(message.busySlots[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDeskResponse
 */
export const GetDeskResponse = new GetDeskResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DesksBulkCreateRequest$Type extends MessageType<DesksBulkCreateRequest> {
    constructor() {
        super("DesksBulkCreateRequest", [
            { no: 1, name: "desksNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "startIndex", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "isBooked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "showCheckInTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "qrCodeRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "checkInTime", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "tenantID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "locationID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "marker", kind: "message", T: () => Marker },
            { no: 13, name: "licensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DesksBulkCreateRequest>): DesksBulkCreateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.desksNumber = 0;
        message.startIndex = 0;
        message.name = "";
        message.description = "";
        message.tags = [];
        message.isBooked = false;
        message.showCheckInTime = false;
        message.qrCodeRequired = false;
        message.checkInTime = 0;
        message.tenantID = "";
        message.locationID = "";
        message.licensed = false;
        if (value !== undefined)
            reflectionMergePartial<DesksBulkCreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DesksBulkCreateRequest): DesksBulkCreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 desksNumber */ 1:
                    message.desksNumber = reader.int32();
                    break;
                case /* int32 startIndex */ 2:
                    message.startIndex = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* repeated string tags */ 5:
                    message.tags.push(reader.string());
                    break;
                case /* bool isBooked */ 6:
                    message.isBooked = reader.bool();
                    break;
                case /* bool showCheckInTime */ 7:
                    message.showCheckInTime = reader.bool();
                    break;
                case /* bool qrCodeRequired */ 8:
                    message.qrCodeRequired = reader.bool();
                    break;
                case /* uint32 checkInTime */ 9:
                    message.checkInTime = reader.uint32();
                    break;
                case /* string tenantID */ 10:
                    message.tenantID = reader.string();
                    break;
                case /* string locationID */ 11:
                    message.locationID = reader.string();
                    break;
                case /* Marker marker */ 12:
                    message.marker = Marker.internalBinaryRead(reader, reader.uint32(), options, message.marker);
                    break;
                case /* bool licensed */ 13:
                    message.licensed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DesksBulkCreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 desksNumber = 1; */
        if (message.desksNumber !== 0)
            writer.tag(1, WireType.Varint).int32(message.desksNumber);
        /* int32 startIndex = 2; */
        if (message.startIndex !== 0)
            writer.tag(2, WireType.Varint).int32(message.startIndex);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* repeated string tags = 5; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.tags[i]);
        /* bool isBooked = 6; */
        if (message.isBooked !== false)
            writer.tag(6, WireType.Varint).bool(message.isBooked);
        /* bool showCheckInTime = 7; */
        if (message.showCheckInTime !== false)
            writer.tag(7, WireType.Varint).bool(message.showCheckInTime);
        /* bool qrCodeRequired = 8; */
        if (message.qrCodeRequired !== false)
            writer.tag(8, WireType.Varint).bool(message.qrCodeRequired);
        /* uint32 checkInTime = 9; */
        if (message.checkInTime !== 0)
            writer.tag(9, WireType.Varint).uint32(message.checkInTime);
        /* string tenantID = 10; */
        if (message.tenantID !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.tenantID);
        /* string locationID = 11; */
        if (message.locationID !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.locationID);
        /* Marker marker = 12; */
        if (message.marker)
            Marker.internalBinaryWrite(message.marker, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool licensed = 13; */
        if (message.licensed !== false)
            writer.tag(13, WireType.Varint).bool(message.licensed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DesksBulkCreateRequest
 */
export const DesksBulkCreateRequest = new DesksBulkCreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DesksBulkCreateResponse$Type extends MessageType<DesksBulkCreateResponse> {
    constructor() {
        super("DesksBulkCreateResponse", [
            { no: 1, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchDesk },
            { no: 2, name: "licenseErrorMessage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DesksBulkCreateResponse>): DesksBulkCreateResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.desks = [];
        message.licenseErrorMessage = "";
        if (value !== undefined)
            reflectionMergePartial<DesksBulkCreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DesksBulkCreateResponse): DesksBulkCreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SearchDesk desks */ 1:
                    message.desks.push(SearchDesk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string licenseErrorMessage */ 2:
                    message.licenseErrorMessage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DesksBulkCreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated SearchDesk desks = 1; */
        for (let i = 0; i < message.desks.length; i++)
            SearchDesk.internalBinaryWrite(message.desks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string licenseErrorMessage = 2; */
        if (message.licenseErrorMessage !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.licenseErrorMessage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DesksBulkCreateResponse
 */
export const DesksBulkCreateResponse = new DesksBulkCreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WorkingHrs$Type extends MessageType<WorkingHrs> {
    constructor() {
        super("WorkingHrs", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "isGlobal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<WorkingHrs>): WorkingHrs {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.from = "";
        message.to = "";
        message.tenantId = "";
        message.isGlobal = false;
        if (value !== undefined)
            reflectionMergePartial<WorkingHrs>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WorkingHrs): WorkingHrs {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                case /* string tenant_id */ 4:
                    message.tenantId = reader.string();
                    break;
                case /* bool isGlobal */ 5:
                    message.isGlobal = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WorkingHrs, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        /* string tenant_id = 4; */
        if (message.tenantId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tenantId);
        /* bool isGlobal = 5; */
        if (message.isGlobal !== false)
            writer.tag(5, WireType.Varint).bool(message.isGlobal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WorkingHrs
 */
export const WorkingHrs = new WorkingHrs$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGlobalWorkingHrsRequest$Type extends MessageType<UpdateGlobalWorkingHrsRequest> {
    constructor() {
        super("UpdateGlobalWorkingHrsRequest", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "old_working_week_days", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "new_working_week_days", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateGlobalWorkingHrsRequest>): UpdateGlobalWorkingHrsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.from = "";
        message.to = "";
        message.customerId = "";
        message.oldWorkingWeekDays = [];
        message.newWorkingWeekDays = [];
        if (value !== undefined)
            reflectionMergePartial<UpdateGlobalWorkingHrsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateGlobalWorkingHrsRequest): UpdateGlobalWorkingHrsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                case /* repeated int32 old_working_week_days */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.oldWorkingWeekDays.push(reader.int32());
                    else
                        message.oldWorkingWeekDays.push(reader.int32());
                    break;
                case /* repeated int32 new_working_week_days */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.newWorkingWeekDays.push(reader.int32());
                    else
                        message.newWorkingWeekDays.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateGlobalWorkingHrsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        /* repeated int32 old_working_week_days = 4; */
        if (message.oldWorkingWeekDays.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.oldWorkingWeekDays.length; i++)
                writer.int32(message.oldWorkingWeekDays[i]);
            writer.join();
        }
        /* repeated int32 new_working_week_days = 5; */
        if (message.newWorkingWeekDays.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.newWorkingWeekDays.length; i++)
                writer.int32(message.newWorkingWeekDays[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateGlobalWorkingHrsRequest
 */
export const UpdateGlobalWorkingHrsRequest = new UpdateGlobalWorkingHrsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGlobalWorkingHrsResponse$Type extends MessageType<UpdateGlobalWorkingHrsResponse> {
    constructor() {
        super("UpdateGlobalWorkingHrsResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateGlobalWorkingHrsResponse>): UpdateGlobalWorkingHrsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateGlobalWorkingHrsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateGlobalWorkingHrsResponse): UpdateGlobalWorkingHrsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateGlobalWorkingHrsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateGlobalWorkingHrsResponse
 */
export const UpdateGlobalWorkingHrsResponse = new UpdateGlobalWorkingHrsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendErrorReportRequest$Type extends MessageType<SendErrorReportRequest> {
    constructor() {
        super("SendErrorReportRequest", [
            { no: 1, name: "room_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "error_state", kind: "enum", T: () => ["ReportState", ReportState, "REPORT_STATE_"] },
            { no: 3, name: "room_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["ErrorReportType", ErrorReportType, "ERROR_REPORT_TYPE_"] },
            { no: 6, name: "faulty_equipment", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendErrorReportRequest>): SendErrorReportRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.roomId = "";
        message.errorState = 0;
        message.roomName = "";
        message.timestamp = "";
        message.type = 0;
        message.faultyEquipment = [];
        message.name = "";
        message.message = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<SendErrorReportRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendErrorReportRequest): SendErrorReportRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string room_id */ 1:
                    message.roomId = reader.string();
                    break;
                case /* ReportState error_state */ 2:
                    message.errorState = reader.int32();
                    break;
                case /* string room_name */ 3:
                    message.roomName = reader.string();
                    break;
                case /* string timestamp */ 4:
                    message.timestamp = reader.string();
                    break;
                case /* ErrorReportType type */ 5:
                    message.type = reader.int32();
                    break;
                case /* repeated string faulty_equipment */ 6:
                    message.faultyEquipment.push(reader.string());
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* string message */ 8:
                    message.message = reader.string();
                    break;
                case /* string customer_id */ 9:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendErrorReportRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string room_id = 1; */
        if (message.roomId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roomId);
        /* ReportState error_state = 2; */
        if (message.errorState !== 0)
            writer.tag(2, WireType.Varint).int32(message.errorState);
        /* string room_name = 3; */
        if (message.roomName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.roomName);
        /* string timestamp = 4; */
        if (message.timestamp !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timestamp);
        /* ErrorReportType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* repeated string faulty_equipment = 6; */
        for (let i = 0; i < message.faultyEquipment.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.faultyEquipment[i]);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* string message = 8; */
        if (message.message !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.message);
        /* string customer_id = 9; */
        if (message.customerId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SendErrorReportRequest
 */
export const SendErrorReportRequest = new SendErrorReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetDevicePingInfoRequest$Type extends MessageType<SetDevicePingInfoRequest> {
    constructor() {
        super("SetDevicePingInfoRequest", [
            { no: 1, name: "deviceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetDevicePingInfoRequest>): SetDevicePingInfoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deviceID = "";
        message.customerID = "";
        if (value !== undefined)
            reflectionMergePartial<SetDevicePingInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetDevicePingInfoRequest): SetDevicePingInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string deviceID */ 1:
                    message.deviceID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetDevicePingInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string deviceID = 1; */
        if (message.deviceID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SetDevicePingInfoRequest
 */
export const SetDevicePingInfoRequest = new SetDevicePingInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadCustomLanguageRequest$Type extends MessageType<UploadCustomLanguageRequest> {
    constructor() {
        super("UploadCustomLanguageRequest", [
            { no: 1, name: "language_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "language_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UploadCustomLanguageRequest>): UploadCustomLanguageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.languageData = new Uint8Array(0);
        message.languageCode = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<UploadCustomLanguageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadCustomLanguageRequest): UploadCustomLanguageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes language_data */ 1:
                    message.languageData = reader.bytes();
                    break;
                case /* string language_code */ 2:
                    message.languageCode = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadCustomLanguageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes language_data = 1; */
        if (message.languageData.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.languageData);
        /* string language_code = 2; */
        if (message.languageCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.languageCode);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UploadCustomLanguageRequest
 */
export const UploadCustomLanguageRequest = new UploadCustomLanguageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadCustomLanguageResponse$Type extends MessageType<UploadCustomLanguageResponse> {
    constructor() {
        super("UploadCustomLanguageResponse", [
            { no: 1, name: "lang_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UploadCustomLanguageResponse>): UploadCustomLanguageResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.langUrl = "";
        if (value !== undefined)
            reflectionMergePartial<UploadCustomLanguageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadCustomLanguageResponse): UploadCustomLanguageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string lang_url */ 1:
                    message.langUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadCustomLanguageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string lang_url = 1; */
        if (message.langUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.langUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UploadCustomLanguageResponse
 */
export const UploadCustomLanguageResponse = new UploadCustomLanguageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCustomLanguageRequest$Type extends MessageType<DeleteCustomLanguageRequest> {
    constructor() {
        super("DeleteCustomLanguageRequest", [
            { no: 1, name: "lang_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteCustomLanguageRequest>): DeleteCustomLanguageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.langUrl = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteCustomLanguageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCustomLanguageRequest): DeleteCustomLanguageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string lang_url */ 1:
                    message.langUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCustomLanguageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string lang_url = 1; */
        if (message.langUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.langUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteCustomLanguageRequest
 */
export const DeleteCustomLanguageRequest = new DeleteCustomLanguageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomLanguageRequest$Type extends MessageType<GetCustomLanguageRequest> {
    constructor() {
        super("GetCustomLanguageRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "lang_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomLanguageRequest>): GetCustomLanguageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.langCode = "";
        if (value !== undefined)
            reflectionMergePartial<GetCustomLanguageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomLanguageRequest): GetCustomLanguageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string lang_code */ 2:
                    message.langCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomLanguageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string lang_code = 2; */
        if (message.langCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.langCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetCustomLanguageRequest
 */
export const GetCustomLanguageRequest = new GetCustomLanguageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomLanguageResponse$Type extends MessageType<CustomLanguageResponse> {
    constructor() {
        super("CustomLanguageResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "language_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "language_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<CustomLanguageResponse>): CustomLanguageResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.url = "";
        message.languageCode = "";
        message.languageData = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<CustomLanguageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomLanguageResponse): CustomLanguageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                case /* string language_code */ 3:
                    message.languageCode = reader.string();
                    break;
                case /* bytes language_data */ 4:
                    message.languageData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomLanguageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        /* string language_code = 3; */
        if (message.languageCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.languageCode);
        /* bytes language_data = 4; */
        if (message.languageData.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.languageData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomLanguageResponse
 */
export const CustomLanguageResponse = new CustomLanguageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDefaultLanguageRequest$Type extends MessageType<GetDefaultLanguageRequest> {
    constructor() {
        super("GetDefaultLanguageRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "app_type", kind: "enum", T: () => ["CustomLanguageAppType", CustomLanguageAppType, "CUSTOM_LANGUAGE_APP_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetDefaultLanguageRequest>): GetDefaultLanguageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.appType = 0;
        if (value !== undefined)
            reflectionMergePartial<GetDefaultLanguageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDefaultLanguageRequest): GetDefaultLanguageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* CustomLanguageAppType app_type */ 2:
                    message.appType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDefaultLanguageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* CustomLanguageAppType app_type = 2; */
        if (message.appType !== 0)
            writer.tag(2, WireType.Varint).int32(message.appType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDefaultLanguageRequest
 */
export const GetDefaultLanguageRequest = new GetDefaultLanguageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDefaultLanguageResponse$Type extends MessageType<GetDefaultLanguageResponse> {
    constructor() {
        super("GetDefaultLanguageResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "language_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "language_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetDefaultLanguageResponse>): GetDefaultLanguageResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.url = "";
        message.languageCode = "";
        message.languageData = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<GetDefaultLanguageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDefaultLanguageResponse): GetDefaultLanguageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                case /* string language_code */ 3:
                    message.languageCode = reader.string();
                    break;
                case /* bytes language_data */ 4:
                    message.languageData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDefaultLanguageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        /* string language_code = 3; */
        if (message.languageCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.languageCode);
        /* bytes language_data = 4; */
        if (message.languageData.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.languageData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDefaultLanguageResponse
 */
export const GetDefaultLanguageResponse = new GetDefaultLanguageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckDeviceVersionRequest$Type extends MessageType<CheckDeviceVersionRequest> {
    constructor() {
        super("CheckDeviceVersionRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckDeviceVersionRequest>): CheckDeviceVersionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deviceId = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<CheckDeviceVersionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckDeviceVersionRequest): CheckDeviceVersionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckDeviceVersionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckDeviceVersionRequest
 */
export const CheckDeviceVersionRequest = new CheckDeviceVersionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckDeviceVersionResponse$Type extends MessageType<CheckDeviceVersionResponse> {
    constructor() {
        super("CheckDeviceVersionResponse", [
            { no: 1, name: "latest_version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_update_required", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckDeviceVersionResponse>): CheckDeviceVersionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.latestVersion = "";
        message.isUpdateRequired = false;
        if (value !== undefined)
            reflectionMergePartial<CheckDeviceVersionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckDeviceVersionResponse): CheckDeviceVersionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string latest_version */ 1:
                    message.latestVersion = reader.string();
                    break;
                case /* bool is_update_required */ 2:
                    message.isUpdateRequired = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckDeviceVersionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string latest_version = 1; */
        if (message.latestVersion !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.latestVersion);
        /* bool is_update_required = 2; */
        if (message.isUpdateRequired !== false)
            writer.tag(2, WireType.Varint).bool(message.isUpdateRequired);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckDeviceVersionResponse
 */
export const CheckDeviceVersionResponse = new CheckDeviceVersionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateDeviceAppRequest$Type extends MessageType<UpdateDeviceAppRequest> {
    constructor() {
        super("UpdateDeviceAppRequest", [
            { no: 1, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateDeviceAppRequest>): UpdateDeviceAppRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deviceId = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateDeviceAppRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateDeviceAppRequest): UpdateDeviceAppRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_id */ 1:
                    message.deviceId = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateDeviceAppRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_id = 1; */
        if (message.deviceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.deviceId);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateDeviceAppRequest
 */
export const UpdateDeviceAppRequest = new UpdateDeviceAppRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomerGlobalCustomLanguagesRequest$Type extends MessageType<CreateCustomerGlobalCustomLanguagesRequest> {
    constructor() {
        super("CreateCustomerGlobalCustomLanguagesRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateCustomerGlobalCustomLanguagesRequest>): CreateCustomerGlobalCustomLanguagesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<CreateCustomerGlobalCustomLanguagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCustomerGlobalCustomLanguagesRequest): CreateCustomerGlobalCustomLanguagesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCustomerGlobalCustomLanguagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateCustomerGlobalCustomLanguagesRequest
 */
export const CreateCustomerGlobalCustomLanguagesRequest = new CreateCustomerGlobalCustomLanguagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomerLanguagesRequest$Type extends MessageType<ListCustomerLanguagesRequest> {
    constructor() {
        super("ListCustomerLanguagesRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "app_type", kind: "enum", T: () => ["CustomLanguageAppType", CustomLanguageAppType, "CUSTOM_LANGUAGE_APP_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ListCustomerLanguagesRequest>): ListCustomerLanguagesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.appType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListCustomerLanguagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomerLanguagesRequest): ListCustomerLanguagesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* CustomLanguageAppType app_type */ 2:
                    message.appType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomerLanguagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* CustomLanguageAppType app_type = 2; */
        if (message.appType !== 0)
            writer.tag(2, WireType.Varint).int32(message.appType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListCustomerLanguagesRequest
 */
export const ListCustomerLanguagesRequest = new ListCustomerLanguagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerLanguages$Type extends MessageType<CustomerLanguages> {
    constructor() {
        super("CustomerLanguages", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_global", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CustomerLanguages>): CustomerLanguages {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.url = "";
        message.name = "";
        message.value = "";
        message.customerId = "";
        message.isGlobal = false;
        if (value !== undefined)
            reflectionMergePartial<CustomerLanguages>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomerLanguages): CustomerLanguages {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string value */ 4:
                    message.value = reader.string();
                    break;
                case /* string customer_id */ 5:
                    message.customerId = reader.string();
                    break;
                case /* bool is_global */ 6:
                    message.isGlobal = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomerLanguages, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string value = 4; */
        if (message.value !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.value);
        /* string customer_id = 5; */
        if (message.customerId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerId);
        /* bool is_global = 6; */
        if (message.isGlobal !== false)
            writer.tag(6, WireType.Varint).bool(message.isGlobal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerLanguages
 */
export const CustomerLanguages = new CustomerLanguages$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomerLanguagesResponse$Type extends MessageType<ListCustomerLanguagesResponse> {
    constructor() {
        super("ListCustomerLanguagesResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CustomerLanguages }
        ]);
    }
    create(value?: PartialMessage<ListCustomerLanguagesResponse>): ListCustomerLanguagesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = [];
        if (value !== undefined)
            reflectionMergePartial<ListCustomerLanguagesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCustomerLanguagesResponse): ListCustomerLanguagesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CustomerLanguages data */ 1:
                    message.data.push(CustomerLanguages.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCustomerLanguagesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated CustomerLanguages data = 1; */
        for (let i = 0; i < message.data.length; i++)
            CustomerLanguages.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListCustomerLanguagesResponse
 */
export const ListCustomerLanguagesResponse = new ListCustomerLanguagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteCustomerGlobalCustomLanguagesRequest$Type extends MessageType<DeleteCustomerGlobalCustomLanguagesRequest> {
    constructor() {
        super("DeleteCustomerGlobalCustomLanguagesRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteCustomerGlobalCustomLanguagesRequest>): DeleteCustomerGlobalCustomLanguagesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteCustomerGlobalCustomLanguagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteCustomerGlobalCustomLanguagesRequest): DeleteCustomerGlobalCustomLanguagesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteCustomerGlobalCustomLanguagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteCustomerGlobalCustomLanguagesRequest
 */
export const DeleteCustomerGlobalCustomLanguagesRequest = new DeleteCustomerGlobalCustomLanguagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveCustomerGlobalCustomLanguagesRequest$Type extends MessageType<RetrieveCustomerGlobalCustomLanguagesRequest> {
    constructor() {
        super("RetrieveCustomerGlobalCustomLanguagesRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveCustomerGlobalCustomLanguagesRequest>): RetrieveCustomerGlobalCustomLanguagesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<RetrieveCustomerGlobalCustomLanguagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveCustomerGlobalCustomLanguagesRequest): RetrieveCustomerGlobalCustomLanguagesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveCustomerGlobalCustomLanguagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RetrieveCustomerGlobalCustomLanguagesRequest
 */
export const RetrieveCustomerGlobalCustomLanguagesRequest = new RetrieveCustomerGlobalCustomLanguagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RetrieveCustomerGlobalCustomLanguagesResponse$Type extends MessageType<RetrieveCustomerGlobalCustomLanguagesResponse> {
    constructor() {
        super("RetrieveCustomerGlobalCustomLanguagesResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "isGlobal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "language_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<RetrieveCustomerGlobalCustomLanguagesResponse>): RetrieveCustomerGlobalCustomLanguagesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.url = "";
        message.isGlobal = false;
        message.languageData = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<RetrieveCustomerGlobalCustomLanguagesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RetrieveCustomerGlobalCustomLanguagesResponse): RetrieveCustomerGlobalCustomLanguagesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* bool isGlobal */ 4:
                    message.isGlobal = reader.bool();
                    break;
                case /* bytes language_data */ 5:
                    message.languageData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RetrieveCustomerGlobalCustomLanguagesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* bool isGlobal = 4; */
        if (message.isGlobal !== false)
            writer.tag(4, WireType.Varint).bool(message.isGlobal);
        /* bytes language_data = 5; */
        if (message.languageData.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.languageData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RetrieveCustomerGlobalCustomLanguagesResponse
 */
export const RetrieveCustomerGlobalCustomLanguagesResponse = new RetrieveCustomerGlobalCustomLanguagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomerGlobalCustomLanguagesRequest$Type extends MessageType<UpdateCustomerGlobalCustomLanguagesRequest> {
    constructor() {
        super("UpdateCustomerGlobalCustomLanguagesRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "language_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCustomerGlobalCustomLanguagesRequest>): UpdateCustomerGlobalCustomLanguagesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerId = "";
        message.name = "";
        message.languageData = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomerGlobalCustomLanguagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomerGlobalCustomLanguagesRequest): UpdateCustomerGlobalCustomLanguagesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* bytes language_data */ 4:
                    message.languageData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCustomerGlobalCustomLanguagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* bytes language_data = 4; */
        if (message.languageData.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.languageData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateCustomerGlobalCustomLanguagesRequest
 */
export const UpdateCustomerGlobalCustomLanguagesRequest = new UpdateCustomerGlobalCustomLanguagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCustomerGlobalCustomLanguagesResponse$Type extends MessageType<UpdateCustomerGlobalCustomLanguagesResponse> {
    constructor() {
        super("UpdateCustomerGlobalCustomLanguagesResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_global", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "language_data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCustomerGlobalCustomLanguagesResponse>): UpdateCustomerGlobalCustomLanguagesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.url = "";
        message.isGlobal = false;
        message.languageData = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<UpdateCustomerGlobalCustomLanguagesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCustomerGlobalCustomLanguagesResponse): UpdateCustomerGlobalCustomLanguagesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* bool is_global */ 4:
                    message.isGlobal = reader.bool();
                    break;
                case /* bytes language_data */ 5:
                    message.languageData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCustomerGlobalCustomLanguagesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* bool is_global = 4; */
        if (message.isGlobal !== false)
            writer.tag(4, WireType.Varint).bool(message.isGlobal);
        /* bytes language_data = 5; */
        if (message.languageData.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.languageData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateCustomerGlobalCustomLanguagesResponse
 */
export const UpdateCustomerGlobalCustomLanguagesResponse = new UpdateCustomerGlobalCustomLanguagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomLanguageFieldPath$Type extends MessageType<CustomLanguageFieldPath> {
    constructor() {
        super("CustomLanguageFieldPath", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "app_type", kind: "enum", T: () => ["CustomLanguageAppType", CustomLanguageAppType, "CUSTOM_LANGUAGE_APP_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<CustomLanguageFieldPath>): CustomLanguageFieldPath {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.path = "";
        message.appType = 0;
        if (value !== undefined)
            reflectionMergePartial<CustomLanguageFieldPath>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomLanguageFieldPath): CustomLanguageFieldPath {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string path */ 1:
                    message.path = reader.string();
                    break;
                case /* CustomLanguageAppType app_type */ 2:
                    message.appType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomLanguageFieldPath, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string path = 1; */
        if (message.path !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.path);
        /* CustomLanguageAppType app_type = 2; */
        if (message.appType !== 0)
            writer.tag(2, WireType.Varint).int32(message.appType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomLanguageFieldPath
 */
export const CustomLanguageFieldPath = new CustomLanguageFieldPath$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncCustomLanguageFieldsRequest$Type extends MessageType<SyncCustomLanguageFieldsRequest> {
    constructor() {
        super("SyncCustomLanguageFieldsRequest", [
            { no: 1, name: "fieldsPath", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CustomLanguageFieldPath }
        ]);
    }
    create(value?: PartialMessage<SyncCustomLanguageFieldsRequest>): SyncCustomLanguageFieldsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fieldsPath = [];
        if (value !== undefined)
            reflectionMergePartial<SyncCustomLanguageFieldsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncCustomLanguageFieldsRequest): SyncCustomLanguageFieldsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CustomLanguageFieldPath fieldsPath */ 1:
                    message.fieldsPath.push(CustomLanguageFieldPath.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncCustomLanguageFieldsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated CustomLanguageFieldPath fieldsPath = 1; */
        for (let i = 0; i < message.fieldsPath.length; i++)
            CustomLanguageFieldPath.internalBinaryWrite(message.fieldsPath[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SyncCustomLanguageFieldsRequest
 */
export const SyncCustomLanguageFieldsRequest = new SyncCustomLanguageFieldsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignCustomerGlobalCustomLanguageRequest$Type extends MessageType<AssignCustomerGlobalCustomLanguageRequest> {
    constructor() {
        super("AssignCustomerGlobalCustomLanguageRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "language_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "app_type", kind: "enum", T: () => ["CustomLanguageAppType", CustomLanguageAppType, "CUSTOM_LANGUAGE_APP_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<AssignCustomerGlobalCustomLanguageRequest>): AssignCustomerGlobalCustomLanguageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.languageId = "";
        message.customerId = "";
        message.appType = 0;
        if (value !== undefined)
            reflectionMergePartial<AssignCustomerGlobalCustomLanguageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssignCustomerGlobalCustomLanguageRequest): AssignCustomerGlobalCustomLanguageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string language_id */ 2:
                    message.languageId = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                case /* CustomLanguageAppType app_type */ 4:
                    message.appType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssignCustomerGlobalCustomLanguageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string language_id = 2; */
        if (message.languageId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.languageId);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        /* CustomLanguageAppType app_type = 4; */
        if (message.appType !== 0)
            writer.tag(4, WireType.Varint).int32(message.appType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssignCustomerGlobalCustomLanguageRequest
 */
export const AssignCustomerGlobalCustomLanguageRequest = new AssignCustomerGlobalCustomLanguageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnLevelSearchRequest$Type extends MessageType<OnLevelSearchRequest> {
    constructor() {
        super("OnLevelSearchRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "licenseStatus", kind: "enum", T: () => ["OnLevelSearchRequestLicenseStatus", OnLevelSearchRequestLicenseStatus] },
            { no: 4, name: "nameFilter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "locationFilter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["WorkplaceLocationType", WorkplaceLocationType] },
            { no: 6, name: "resourceFilter", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["WorkplaceResourceType", WorkplaceResourceType] },
            { no: 7, name: "tagsFilter", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OnLevelSearchRequest>): OnLevelSearchRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerId = "";
        message.licenseStatus = 0;
        message.nameFilter = "";
        message.locationFilter = [];
        message.resourceFilter = [];
        message.tagsFilter = [];
        if (value !== undefined)
            reflectionMergePartial<OnLevelSearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnLevelSearchRequest): OnLevelSearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* OnLevelSearchRequestLicenseStatus licenseStatus */ 3:
                    message.licenseStatus = reader.int32();
                    break;
                case /* string nameFilter */ 4:
                    message.nameFilter = reader.string();
                    break;
                case /* repeated WorkplaceLocationType locationFilter */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.locationFilter.push(reader.int32());
                    else
                        message.locationFilter.push(reader.int32());
                    break;
                case /* repeated WorkplaceResourceType resourceFilter */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.resourceFilter.push(reader.int32());
                    else
                        message.resourceFilter.push(reader.int32());
                    break;
                case /* repeated string tagsFilter */ 7:
                    message.tagsFilter.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnLevelSearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* OnLevelSearchRequestLicenseStatus licenseStatus = 3; */
        if (message.licenseStatus !== 0)
            writer.tag(3, WireType.Varint).int32(message.licenseStatus);
        /* string nameFilter = 4; */
        if (message.nameFilter !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.nameFilter);
        /* repeated WorkplaceLocationType locationFilter = 5; */
        if (message.locationFilter.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.locationFilter.length; i++)
                writer.int32(message.locationFilter[i]);
            writer.join();
        }
        /* repeated WorkplaceResourceType resourceFilter = 6; */
        if (message.resourceFilter.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.resourceFilter.length; i++)
                writer.int32(message.resourceFilter[i]);
            writer.join();
        }
        /* repeated string tagsFilter = 7; */
        for (let i = 0; i < message.tagsFilter.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.tagsFilter[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OnLevelSearchRequest
 */
export const OnLevelSearchRequest = new OnLevelSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnLevelSearchResponse$Type extends MessageType<OnLevelSearchResponse> {
    constructor() {
        super("OnLevelSearchResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OnLevelSearchResponse_OnLevelSearchItem }
        ]);
    }
    create(value?: PartialMessage<OnLevelSearchResponse>): OnLevelSearchResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = [];
        if (value !== undefined)
            reflectionMergePartial<OnLevelSearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnLevelSearchResponse): OnLevelSearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated OnLevelSearchResponse.OnLevelSearchItem data */ 1:
                    message.data.push(OnLevelSearchResponse_OnLevelSearchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnLevelSearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated OnLevelSearchResponse.OnLevelSearchItem data = 1; */
        for (let i = 0; i < message.data.length; i++)
            OnLevelSearchResponse_OnLevelSearchItem.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OnLevelSearchResponse
 */
export const OnLevelSearchResponse = new OnLevelSearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnLevelSearchResponse_OnLevelSearchDevice$Type extends MessageType<OnLevelSearchResponse_OnLevelSearchDevice> {
    constructor() {
        super("OnLevelSearchResponse.OnLevelSearchDevice", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OnLevelSearchResponse_OnLevelSearchDevice>): OnLevelSearchResponse_OnLevelSearchDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<OnLevelSearchResponse_OnLevelSearchDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnLevelSearchResponse_OnLevelSearchDevice): OnLevelSearchResponse_OnLevelSearchDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnLevelSearchResponse_OnLevelSearchDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OnLevelSearchResponse.OnLevelSearchDevice
 */
export const OnLevelSearchResponse_OnLevelSearchDevice = new OnLevelSearchResponse_OnLevelSearchDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnLevelSearchResponse_OnLevelSearchItem$Type extends MessageType<OnLevelSearchResponse_OnLevelSearchItem> {
    constructor() {
        super("OnLevelSearchResponse.OnLevelSearchItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "licensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "calendar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "calendar_provider_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "devices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OnLevelSearchResponse_OnLevelSearchDevice },
            { no: 9, name: "type_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OnLevelSearchResponse_OnLevelSearchItem>): OnLevelSearchResponse_OnLevelSearchItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.licensed = false;
        message.tags = [];
        message.description = "";
        message.calendarId = "";
        message.calendarProviderId = "";
        message.devices = [];
        message.typeName = "";
        if (value !== undefined)
            reflectionMergePartial<OnLevelSearchResponse_OnLevelSearchItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnLevelSearchResponse_OnLevelSearchItem): OnLevelSearchResponse_OnLevelSearchItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool licensed */ 3:
                    message.licensed = reader.bool();
                    break;
                case /* repeated string tags */ 4:
                    message.tags.push(reader.string());
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string calendar_id */ 6:
                    message.calendarId = reader.string();
                    break;
                case /* string calendar_provider_id */ 7:
                    message.calendarProviderId = reader.string();
                    break;
                case /* repeated OnLevelSearchResponse.OnLevelSearchDevice devices */ 8:
                    message.devices.push(OnLevelSearchResponse_OnLevelSearchDevice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string type_name */ 9:
                    message.typeName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnLevelSearchResponse_OnLevelSearchItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool licensed = 3; */
        if (message.licensed !== false)
            writer.tag(3, WireType.Varint).bool(message.licensed);
        /* repeated string tags = 4; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.tags[i]);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string calendar_id = 6; */
        if (message.calendarId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.calendarId);
        /* string calendar_provider_id = 7; */
        if (message.calendarProviderId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.calendarProviderId);
        /* repeated OnLevelSearchResponse.OnLevelSearchDevice devices = 8; */
        for (let i = 0; i < message.devices.length; i++)
            OnLevelSearchResponse_OnLevelSearchDevice.internalBinaryWrite(message.devices[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string type_name = 9; */
        if (message.typeName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.typeName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OnLevelSearchResponse.OnLevelSearchItem
 */
export const OnLevelSearchResponse_OnLevelSearchItem = new OnLevelSearchResponse_OnLevelSearchItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncFloorMapMetadataRequest$Type extends MessageType<SyncFloorMapMetadataRequest> {
    constructor() {
        super("SyncFloorMapMetadataRequest", [
            { no: 1, name: "floorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timeZone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncFloorMapMetadataRequest>): SyncFloorMapMetadataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.floorId = "";
        message.customerId = "";
        message.timeZone = "";
        if (value !== undefined)
            reflectionMergePartial<SyncFloorMapMetadataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncFloorMapMetadataRequest): SyncFloorMapMetadataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string floorId */ 1:
                    message.floorId = reader.string();
                    break;
                case /* string customerId */ 2:
                    message.customerId = reader.string();
                    break;
                case /* string timeZone */ 3:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncFloorMapMetadataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string floorId = 1; */
        if (message.floorId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.floorId);
        /* string customerId = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* string timeZone = 3; */
        if (message.timeZone !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SyncFloorMapMetadataRequest
 */
export const SyncFloorMapMetadataRequest = new SyncFloorMapMetadataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncFloorMapMetadataResponse$Type extends MessageType<SyncFloorMapMetadataResponse> {
    constructor() {
        super("SyncFloorMapMetadataResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncFloorMapMetadataResponse>): SyncFloorMapMetadataResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<SyncFloorMapMetadataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncFloorMapMetadataResponse): SyncFloorMapMetadataResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncFloorMapMetadataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SyncFloorMapMetadataResponse
 */
export const SyncFloorMapMetadataResponse = new SyncFloorMapMetadataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMapEntityFloorIDRequest$Type extends MessageType<GetMapEntityFloorIDRequest> {
    constructor() {
        super("GetMapEntityFloorIDRequest", [
            { no: 1, name: "entityId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "entityType", kind: "enum", T: () => ["WorkplaceResourceType", WorkplaceResourceType] },
            { no: 3, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMapEntityFloorIDRequest>): GetMapEntityFloorIDRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entityId = "";
        message.entityType = 0;
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<GetMapEntityFloorIDRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMapEntityFloorIDRequest): GetMapEntityFloorIDRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string entityId */ 1:
                    message.entityId = reader.string();
                    break;
                case /* WorkplaceResourceType entityType */ 2:
                    message.entityType = reader.int32();
                    break;
                case /* string customerId */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMapEntityFloorIDRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string entityId = 1; */
        if (message.entityId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.entityId);
        /* WorkplaceResourceType entityType = 2; */
        if (message.entityType !== 0)
            writer.tag(2, WireType.Varint).int32(message.entityType);
        /* string customerId = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetMapEntityFloorIDRequest
 */
export const GetMapEntityFloorIDRequest = new GetMapEntityFloorIDRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMapEntityFloorIDResponse$Type extends MessageType<GetMapEntityFloorIDResponse> {
    constructor() {
        super("GetMapEntityFloorIDResponse", [
            { no: 1, name: "floorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMapEntityFloorIDResponse>): GetMapEntityFloorIDResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.floorId = "";
        if (value !== undefined)
            reflectionMergePartial<GetMapEntityFloorIDResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMapEntityFloorIDResponse): GetMapEntityFloorIDResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string floorId */ 1:
                    message.floorId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMapEntityFloorIDResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string floorId = 1; */
        if (message.floorId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.floorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetMapEntityFloorIDResponse
 */
export const GetMapEntityFloorIDResponse = new GetMapEntityFloorIDResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConnectedFloorMapRequest$Type extends MessageType<GetConnectedFloorMapRequest> {
    constructor() {
        super("GetConnectedFloorMapRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "floorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetConnectedFloorMapRequest>): GetConnectedFloorMapRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.floorId = "";
        if (value !== undefined)
            reflectionMergePartial<GetConnectedFloorMapRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConnectedFloorMapRequest): GetConnectedFloorMapRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string floorId */ 2:
                    message.floorId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConnectedFloorMapRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string floorId = 2; */
        if (message.floorId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.floorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetConnectedFloorMapRequest
 */
export const GetConnectedFloorMapRequest = new GetConnectedFloorMapRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConnectedFloorMapResponse$Type extends MessageType<GetConnectedFloorMapResponse> {
    constructor() {
        super("GetConnectedFloorMapResponse", [
            { no: 1, name: "mapId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetConnectedFloorMapResponse>): GetConnectedFloorMapResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mapId = "";
        if (value !== undefined)
            reflectionMergePartial<GetConnectedFloorMapResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConnectedFloorMapResponse): GetConnectedFloorMapResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mapId */ 1:
                    message.mapId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConnectedFloorMapResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mapId = 1; */
        if (message.mapId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.mapId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetConnectedFloorMapResponse
 */
export const GetConnectedFloorMapResponse = new GetConnectedFloorMapResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRoomCalIntegrationStatusRequest$Type extends MessageType<UpdateRoomCalIntegrationStatusRequest> {
    constructor() {
        super("UpdateRoomCalIntegrationStatusRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "calendarProviderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateRoomCalIntegrationStatusRequest>): UpdateRoomCalIntegrationStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        message.calendarProviderId = "";
        message.isActive = false;
        if (value !== undefined)
            reflectionMergePartial<UpdateRoomCalIntegrationStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRoomCalIntegrationStatusRequest): UpdateRoomCalIntegrationStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string calendarProviderId */ 2:
                    message.calendarProviderId = reader.string();
                    break;
                case /* bool isActive */ 3:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRoomCalIntegrationStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string calendarProviderId = 2; */
        if (message.calendarProviderId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.calendarProviderId);
        /* bool isActive = 3; */
        if (message.isActive !== false)
            writer.tag(3, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateRoomCalIntegrationStatusRequest
 */
export const UpdateRoomCalIntegrationStatusRequest = new UpdateRoomCalIntegrationStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRoomCalIntegrationStatusResponse$Type extends MessageType<UpdateRoomCalIntegrationStatusResponse> {
    constructor() {
        super("UpdateRoomCalIntegrationStatusResponse", [
            { no: 1, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateRoomCalIntegrationStatusResponse>): UpdateRoomCalIntegrationStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateRoomCalIntegrationStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRoomCalIntegrationStatusResponse): UpdateRoomCalIntegrationStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string status */ 1:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRoomCalIntegrationStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string status = 1; */
        if (message.status !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateRoomCalIntegrationStatusResponse
 */
export const UpdateRoomCalIntegrationStatusResponse = new UpdateRoomCalIntegrationStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomTemplateSettings$Type extends MessageType<RoomTemplateSettings> {
    constructor() {
        super("RoomTemplateSettings", [
            { no: 1, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "numberOfSeats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amenities", kind: "message", T: () => AmenitiesFilter },
            { no: 5, name: "brokenAmenitiesReporting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RoomTemplateSettings>): RoomTemplateSettings {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.description = "";
        message.numberOfSeats = 0;
        message.tags = [];
        message.brokenAmenitiesReporting = false;
        message.isBlocked = false;
        if (value !== undefined)
            reflectionMergePartial<RoomTemplateSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomTemplateSettings): RoomTemplateSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string description */ 1:
                    message.description = reader.string();
                    break;
                case /* int32 numberOfSeats */ 2:
                    message.numberOfSeats = reader.int32();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                case /* AmenitiesFilter amenities */ 4:
                    message.amenities = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.amenities);
                    break;
                case /* bool brokenAmenitiesReporting */ 5:
                    message.brokenAmenitiesReporting = reader.bool();
                    break;
                case /* bool isBlocked */ 6:
                    message.isBlocked = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomTemplateSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string description = 1; */
        if (message.description !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.description);
        /* int32 numberOfSeats = 2; */
        if (message.numberOfSeats !== 0)
            writer.tag(2, WireType.Varint).int32(message.numberOfSeats);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        /* AmenitiesFilter amenities = 4; */
        if (message.amenities)
            AmenitiesFilter.internalBinaryWrite(message.amenities, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool brokenAmenitiesReporting = 5; */
        if (message.brokenAmenitiesReporting !== false)
            writer.tag(5, WireType.Varint).bool(message.brokenAmenitiesReporting);
        /* bool isBlocked = 6; */
        if (message.isBlocked !== false)
            writer.tag(6, WireType.Varint).bool(message.isBlocked);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomTemplateSettings
 */
export const RoomTemplateSettings = new RoomTemplateSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MapFloor$Type extends MessageType<MapFloor> {
    constructor() {
        super("MapFloor", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MapFloor>): MapFloor {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<MapFloor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MapFloor): MapFloor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MapFloor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MapFloor
 */
export const MapFloor = new MapFloor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomTemplateDisplaySettings$Type extends MessageType<RoomTemplateDisplaySettings> {
    constructor() {
        super("RoomTemplateDisplaySettings", [
            { no: 1, name: "adminPin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "backgroundImageUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bwFilter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "logoImageUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customLanguageId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "showOrganizer", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "showMeetingTitle", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "showAttendees", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "showRoomCapacity", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "showRoomAmeneties", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "showCheckinRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "pendingTimeBeforeMeeting", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "pendingTimeAfterMeeting", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "soonFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "energySaveMode", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "energySaveModeStart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "energySaveModeEnd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "qrCodeRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "contentBoardActivated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "contentBoardURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "enableScreenLock", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "screenLock", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "onScreenReservation", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 24, name: "showMeetingTitleInput", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 25, name: "requireMeetingTitleInput", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "showYourNameInput", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 27, name: "requireYourNameInput", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 28, name: "workingHrsActivated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 29, name: "workingHrsStart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "workingHrsEnd", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "requireHeadcount", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 32, name: "oneTimePinProtection", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 33, name: "deleteMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 34, name: "endMeetingEarly", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 35, name: "extendMeeting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 36, name: "nearbyEnabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 37, name: "nearbyRooms", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "mapView", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 39, name: "floorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "customLanguage", kind: "message", T: () => CustomerLanguages },
            { no: 41, name: "floor", kind: "message", T: () => MapFloor },
            { no: 42, name: "checkinReminder", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RoomTemplateDisplaySettings>): RoomTemplateDisplaySettings {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.adminPin = "";
        message.backgroundImageUrl = "";
        message.bwFilter = false;
        message.logoImageUrl = "";
        message.customLanguageId = "";
        message.showOrganizer = false;
        message.showMeetingTitle = false;
        message.showAttendees = false;
        message.showRoomCapacity = false;
        message.showRoomAmeneties = false;
        message.showCheckinRequired = false;
        message.pendingTimeBeforeMeeting = 0;
        message.pendingTimeAfterMeeting = 0;
        message.soonFree = false;
        message.energySaveMode = false;
        message.energySaveModeStart = "";
        message.energySaveModeEnd = "";
        message.qrCodeRequired = false;
        message.contentBoardActivated = false;
        message.contentBoardURL = "";
        message.enableScreenLock = false;
        message.screenLock = "";
        message.onScreenReservation = false;
        message.showMeetingTitleInput = false;
        message.requireMeetingTitleInput = false;
        message.showYourNameInput = false;
        message.requireYourNameInput = false;
        message.workingHrsActivated = false;
        message.workingHrsStart = "";
        message.workingHrsEnd = "";
        message.requireHeadcount = false;
        message.oneTimePinProtection = false;
        message.deleteMeeting = false;
        message.endMeetingEarly = false;
        message.extendMeeting = false;
        message.nearbyEnabled = false;
        message.nearbyRooms = [];
        message.mapView = false;
        message.floorId = "";
        message.checkinReminder = false;
        if (value !== undefined)
            reflectionMergePartial<RoomTemplateDisplaySettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomTemplateDisplaySettings): RoomTemplateDisplaySettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string adminPin */ 1:
                    message.adminPin = reader.string();
                    break;
                case /* string backgroundImageUrl */ 2:
                    message.backgroundImageUrl = reader.string();
                    break;
                case /* bool bwFilter */ 3:
                    message.bwFilter = reader.bool();
                    break;
                case /* string logoImageUrl */ 4:
                    message.logoImageUrl = reader.string();
                    break;
                case /* string customLanguageId */ 5:
                    message.customLanguageId = reader.string();
                    break;
                case /* bool showOrganizer */ 6:
                    message.showOrganizer = reader.bool();
                    break;
                case /* bool showMeetingTitle */ 7:
                    message.showMeetingTitle = reader.bool();
                    break;
                case /* bool showAttendees */ 8:
                    message.showAttendees = reader.bool();
                    break;
                case /* bool showRoomCapacity */ 9:
                    message.showRoomCapacity = reader.bool();
                    break;
                case /* bool showRoomAmeneties */ 10:
                    message.showRoomAmeneties = reader.bool();
                    break;
                case /* bool showCheckinRequired */ 11:
                    message.showCheckinRequired = reader.bool();
                    break;
                case /* int32 pendingTimeBeforeMeeting */ 12:
                    message.pendingTimeBeforeMeeting = reader.int32();
                    break;
                case /* int32 pendingTimeAfterMeeting */ 13:
                    message.pendingTimeAfterMeeting = reader.int32();
                    break;
                case /* bool soonFree */ 14:
                    message.soonFree = reader.bool();
                    break;
                case /* bool energySaveMode */ 15:
                    message.energySaveMode = reader.bool();
                    break;
                case /* string energySaveModeStart */ 16:
                    message.energySaveModeStart = reader.string();
                    break;
                case /* string energySaveModeEnd */ 17:
                    message.energySaveModeEnd = reader.string();
                    break;
                case /* bool qrCodeRequired */ 18:
                    message.qrCodeRequired = reader.bool();
                    break;
                case /* bool contentBoardActivated */ 19:
                    message.contentBoardActivated = reader.bool();
                    break;
                case /* string contentBoardURL */ 20:
                    message.contentBoardURL = reader.string();
                    break;
                case /* bool enableScreenLock */ 21:
                    message.enableScreenLock = reader.bool();
                    break;
                case /* string screenLock */ 22:
                    message.screenLock = reader.string();
                    break;
                case /* bool onScreenReservation */ 23:
                    message.onScreenReservation = reader.bool();
                    break;
                case /* bool showMeetingTitleInput */ 24:
                    message.showMeetingTitleInput = reader.bool();
                    break;
                case /* bool requireMeetingTitleInput */ 25:
                    message.requireMeetingTitleInput = reader.bool();
                    break;
                case /* bool showYourNameInput */ 26:
                    message.showYourNameInput = reader.bool();
                    break;
                case /* bool requireYourNameInput */ 27:
                    message.requireYourNameInput = reader.bool();
                    break;
                case /* bool workingHrsActivated */ 28:
                    message.workingHrsActivated = reader.bool();
                    break;
                case /* string workingHrsStart */ 29:
                    message.workingHrsStart = reader.string();
                    break;
                case /* string workingHrsEnd */ 30:
                    message.workingHrsEnd = reader.string();
                    break;
                case /* bool requireHeadcount */ 31:
                    message.requireHeadcount = reader.bool();
                    break;
                case /* bool oneTimePinProtection */ 32:
                    message.oneTimePinProtection = reader.bool();
                    break;
                case /* bool deleteMeeting */ 33:
                    message.deleteMeeting = reader.bool();
                    break;
                case /* bool endMeetingEarly */ 34:
                    message.endMeetingEarly = reader.bool();
                    break;
                case /* bool extendMeeting */ 35:
                    message.extendMeeting = reader.bool();
                    break;
                case /* bool nearbyEnabled */ 36:
                    message.nearbyEnabled = reader.bool();
                    break;
                case /* repeated string nearbyRooms */ 37:
                    message.nearbyRooms.push(reader.string());
                    break;
                case /* bool mapView */ 38:
                    message.mapView = reader.bool();
                    break;
                case /* string floorId */ 39:
                    message.floorId = reader.string();
                    break;
                case /* CustomerLanguages customLanguage */ 40:
                    message.customLanguage = CustomerLanguages.internalBinaryRead(reader, reader.uint32(), options, message.customLanguage);
                    break;
                case /* MapFloor floor */ 41:
                    message.floor = MapFloor.internalBinaryRead(reader, reader.uint32(), options, message.floor);
                    break;
                case /* bool checkinReminder */ 42:
                    message.checkinReminder = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomTemplateDisplaySettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string adminPin = 1; */
        if (message.adminPin !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.adminPin);
        /* string backgroundImageUrl = 2; */
        if (message.backgroundImageUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.backgroundImageUrl);
        /* bool bwFilter = 3; */
        if (message.bwFilter !== false)
            writer.tag(3, WireType.Varint).bool(message.bwFilter);
        /* string logoImageUrl = 4; */
        if (message.logoImageUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.logoImageUrl);
        /* string customLanguageId = 5; */
        if (message.customLanguageId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customLanguageId);
        /* bool showOrganizer = 6; */
        if (message.showOrganizer !== false)
            writer.tag(6, WireType.Varint).bool(message.showOrganizer);
        /* bool showMeetingTitle = 7; */
        if (message.showMeetingTitle !== false)
            writer.tag(7, WireType.Varint).bool(message.showMeetingTitle);
        /* bool showAttendees = 8; */
        if (message.showAttendees !== false)
            writer.tag(8, WireType.Varint).bool(message.showAttendees);
        /* bool showRoomCapacity = 9; */
        if (message.showRoomCapacity !== false)
            writer.tag(9, WireType.Varint).bool(message.showRoomCapacity);
        /* bool showRoomAmeneties = 10; */
        if (message.showRoomAmeneties !== false)
            writer.tag(10, WireType.Varint).bool(message.showRoomAmeneties);
        /* bool showCheckinRequired = 11; */
        if (message.showCheckinRequired !== false)
            writer.tag(11, WireType.Varint).bool(message.showCheckinRequired);
        /* int32 pendingTimeBeforeMeeting = 12; */
        if (message.pendingTimeBeforeMeeting !== 0)
            writer.tag(12, WireType.Varint).int32(message.pendingTimeBeforeMeeting);
        /* int32 pendingTimeAfterMeeting = 13; */
        if (message.pendingTimeAfterMeeting !== 0)
            writer.tag(13, WireType.Varint).int32(message.pendingTimeAfterMeeting);
        /* bool soonFree = 14; */
        if (message.soonFree !== false)
            writer.tag(14, WireType.Varint).bool(message.soonFree);
        /* bool energySaveMode = 15; */
        if (message.energySaveMode !== false)
            writer.tag(15, WireType.Varint).bool(message.energySaveMode);
        /* string energySaveModeStart = 16; */
        if (message.energySaveModeStart !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.energySaveModeStart);
        /* string energySaveModeEnd = 17; */
        if (message.energySaveModeEnd !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.energySaveModeEnd);
        /* bool qrCodeRequired = 18; */
        if (message.qrCodeRequired !== false)
            writer.tag(18, WireType.Varint).bool(message.qrCodeRequired);
        /* bool contentBoardActivated = 19; */
        if (message.contentBoardActivated !== false)
            writer.tag(19, WireType.Varint).bool(message.contentBoardActivated);
        /* string contentBoardURL = 20; */
        if (message.contentBoardURL !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.contentBoardURL);
        /* bool enableScreenLock = 21; */
        if (message.enableScreenLock !== false)
            writer.tag(21, WireType.Varint).bool(message.enableScreenLock);
        /* string screenLock = 22; */
        if (message.screenLock !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.screenLock);
        /* bool onScreenReservation = 23; */
        if (message.onScreenReservation !== false)
            writer.tag(23, WireType.Varint).bool(message.onScreenReservation);
        /* bool showMeetingTitleInput = 24; */
        if (message.showMeetingTitleInput !== false)
            writer.tag(24, WireType.Varint).bool(message.showMeetingTitleInput);
        /* bool requireMeetingTitleInput = 25; */
        if (message.requireMeetingTitleInput !== false)
            writer.tag(25, WireType.Varint).bool(message.requireMeetingTitleInput);
        /* bool showYourNameInput = 26; */
        if (message.showYourNameInput !== false)
            writer.tag(26, WireType.Varint).bool(message.showYourNameInput);
        /* bool requireYourNameInput = 27; */
        if (message.requireYourNameInput !== false)
            writer.tag(27, WireType.Varint).bool(message.requireYourNameInput);
        /* bool workingHrsActivated = 28; */
        if (message.workingHrsActivated !== false)
            writer.tag(28, WireType.Varint).bool(message.workingHrsActivated);
        /* string workingHrsStart = 29; */
        if (message.workingHrsStart !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.workingHrsStart);
        /* string workingHrsEnd = 30; */
        if (message.workingHrsEnd !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.workingHrsEnd);
        /* bool requireHeadcount = 31; */
        if (message.requireHeadcount !== false)
            writer.tag(31, WireType.Varint).bool(message.requireHeadcount);
        /* bool oneTimePinProtection = 32; */
        if (message.oneTimePinProtection !== false)
            writer.tag(32, WireType.Varint).bool(message.oneTimePinProtection);
        /* bool deleteMeeting = 33; */
        if (message.deleteMeeting !== false)
            writer.tag(33, WireType.Varint).bool(message.deleteMeeting);
        /* bool endMeetingEarly = 34; */
        if (message.endMeetingEarly !== false)
            writer.tag(34, WireType.Varint).bool(message.endMeetingEarly);
        /* bool extendMeeting = 35; */
        if (message.extendMeeting !== false)
            writer.tag(35, WireType.Varint).bool(message.extendMeeting);
        /* bool nearbyEnabled = 36; */
        if (message.nearbyEnabled !== false)
            writer.tag(36, WireType.Varint).bool(message.nearbyEnabled);
        /* repeated string nearbyRooms = 37; */
        for (let i = 0; i < message.nearbyRooms.length; i++)
            writer.tag(37, WireType.LengthDelimited).string(message.nearbyRooms[i]);
        /* bool mapView = 38; */
        if (message.mapView !== false)
            writer.tag(38, WireType.Varint).bool(message.mapView);
        /* string floorId = 39; */
        if (message.floorId !== "")
            writer.tag(39, WireType.LengthDelimited).string(message.floorId);
        /* CustomerLanguages customLanguage = 40; */
        if (message.customLanguage)
            CustomerLanguages.internalBinaryWrite(message.customLanguage, writer.tag(40, WireType.LengthDelimited).fork(), options).join();
        /* MapFloor floor = 41; */
        if (message.floor)
            MapFloor.internalBinaryWrite(message.floor, writer.tag(41, WireType.LengthDelimited).fork(), options).join();
        /* bool checkinReminder = 42; */
        if (message.checkinReminder !== false)
            writer.tag(42, WireType.Varint).bool(message.checkinReminder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomTemplateDisplaySettings
 */
export const RoomTemplateDisplaySettings = new RoomTemplateDisplaySettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRoomTemplateRequest$Type extends MessageType<CreateRoomTemplateRequest> {
    constructor() {
        super("CreateRoomTemplateRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tenantId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "roomSettings", kind: "message", T: () => RoomTemplateSettings },
            { no: 5, name: "displaySettings", kind: "message", T: () => RoomTemplateDisplaySettings }
        ]);
    }
    create(value?: PartialMessage<CreateRoomTemplateRequest>): CreateRoomTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.description = "";
        message.tenantId = "";
        if (value !== undefined)
            reflectionMergePartial<CreateRoomTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRoomTemplateRequest): CreateRoomTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* string tenantId */ 3:
                    message.tenantId = reader.string();
                    break;
                case /* RoomTemplateSettings roomSettings */ 4:
                    message.roomSettings = RoomTemplateSettings.internalBinaryRead(reader, reader.uint32(), options, message.roomSettings);
                    break;
                case /* RoomTemplateDisplaySettings displaySettings */ 5:
                    message.displaySettings = RoomTemplateDisplaySettings.internalBinaryRead(reader, reader.uint32(), options, message.displaySettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRoomTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* string tenantId = 3; */
        if (message.tenantId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tenantId);
        /* RoomTemplateSettings roomSettings = 4; */
        if (message.roomSettings)
            RoomTemplateSettings.internalBinaryWrite(message.roomSettings, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* RoomTemplateDisplaySettings displaySettings = 5; */
        if (message.displaySettings)
            RoomTemplateDisplaySettings.internalBinaryWrite(message.displaySettings, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateRoomTemplateRequest
 */
export const CreateRoomTemplateRequest = new CreateRoomTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomTemplate$Type extends MessageType<RoomTemplate> {
    constructor() {
        super("RoomTemplate", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tenantId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "roomSettings", kind: "message", T: () => RoomTemplateSettings },
            { no: 6, name: "displaySettings", kind: "message", T: () => RoomTemplateDisplaySettings }
        ]);
    }
    create(value?: PartialMessage<RoomTemplate>): RoomTemplate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.description = "";
        message.tenantId = "";
        if (value !== undefined)
            reflectionMergePartial<RoomTemplate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomTemplate): RoomTemplate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string tenantId */ 4:
                    message.tenantId = reader.string();
                    break;
                case /* RoomTemplateSettings roomSettings */ 5:
                    message.roomSettings = RoomTemplateSettings.internalBinaryRead(reader, reader.uint32(), options, message.roomSettings);
                    break;
                case /* RoomTemplateDisplaySettings displaySettings */ 6:
                    message.displaySettings = RoomTemplateDisplaySettings.internalBinaryRead(reader, reader.uint32(), options, message.displaySettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomTemplate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string tenantId = 4; */
        if (message.tenantId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tenantId);
        /* RoomTemplateSettings roomSettings = 5; */
        if (message.roomSettings)
            RoomTemplateSettings.internalBinaryWrite(message.roomSettings, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* RoomTemplateDisplaySettings displaySettings = 6; */
        if (message.displaySettings)
            RoomTemplateDisplaySettings.internalBinaryWrite(message.displaySettings, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomTemplate
 */
export const RoomTemplate = new RoomTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRoomTemplatesRequest$Type extends MessageType<ListRoomTemplatesRequest> {
    constructor() {
        super("ListRoomTemplatesRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListRoomTemplatesRequest>): ListRoomTemplatesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<ListRoomTemplatesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRoomTemplatesRequest): ListRoomTemplatesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRoomTemplatesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListRoomTemplatesRequest
 */
export const ListRoomTemplatesRequest = new ListRoomTemplatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoomTemplatesResponse$Type extends MessageType<RoomTemplatesResponse> {
    constructor() {
        super("RoomTemplatesResponse", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RoomTemplate }
        ]);
    }
    create(value?: PartialMessage<RoomTemplatesResponse>): RoomTemplatesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.results = [];
        if (value !== undefined)
            reflectionMergePartial<RoomTemplatesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoomTemplatesResponse): RoomTemplatesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated RoomTemplate results */ 1:
                    message.results.push(RoomTemplate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoomTemplatesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated RoomTemplate results = 1; */
        for (let i = 0; i < message.results.length; i++)
            RoomTemplate.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RoomTemplatesResponse
 */
export const RoomTemplatesResponse = new RoomTemplatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRoomTemplateRequest$Type extends MessageType<UpdateRoomTemplateRequest> {
    constructor() {
        super("UpdateRoomTemplateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tenantId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "roomSettings", kind: "message", T: () => RoomTemplateSettings },
            { no: 6, name: "displaySettings", kind: "message", T: () => RoomTemplateDisplaySettings }
        ]);
    }
    create(value?: PartialMessage<UpdateRoomTemplateRequest>): UpdateRoomTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.description = "";
        message.tenantId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateRoomTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRoomTemplateRequest): UpdateRoomTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string tenantId */ 4:
                    message.tenantId = reader.string();
                    break;
                case /* RoomTemplateSettings roomSettings */ 5:
                    message.roomSettings = RoomTemplateSettings.internalBinaryRead(reader, reader.uint32(), options, message.roomSettings);
                    break;
                case /* RoomTemplateDisplaySettings displaySettings */ 6:
                    message.displaySettings = RoomTemplateDisplaySettings.internalBinaryRead(reader, reader.uint32(), options, message.displaySettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRoomTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string tenantId = 4; */
        if (message.tenantId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tenantId);
        /* RoomTemplateSettings roomSettings = 5; */
        if (message.roomSettings)
            RoomTemplateSettings.internalBinaryWrite(message.roomSettings, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* RoomTemplateDisplaySettings displaySettings = 6; */
        if (message.displaySettings)
            RoomTemplateDisplaySettings.internalBinaryWrite(message.displaySettings, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateRoomTemplateRequest
 */
export const UpdateRoomTemplateRequest = new UpdateRoomTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteRoomTemplateRequest$Type extends MessageType<DeleteRoomTemplateRequest> {
    constructor() {
        super("DeleteRoomTemplateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteRoomTemplateRequest>): DeleteRoomTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteRoomTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteRoomTemplateRequest): DeleteRoomTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customerId */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteRoomTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customerId = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteRoomTemplateRequest
 */
export const DeleteRoomTemplateRequest = new DeleteRoomTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyRoomTemplateRequest$Type extends MessageType<ApplyRoomTemplateRequest> {
    constructor() {
        super("ApplyRoomTemplateRequest", [
            { no: 1, name: "templateId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "roomsId", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "roomSettingsFields", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "displaySettingsFields", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplyRoomTemplateRequest>): ApplyRoomTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.templateId = "";
        message.customerId = "";
        message.roomsId = [];
        message.roomSettingsFields = [];
        message.displaySettingsFields = [];
        if (value !== undefined)
            reflectionMergePartial<ApplyRoomTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyRoomTemplateRequest): ApplyRoomTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string templateId */ 1:
                    message.templateId = reader.string();
                    break;
                case /* string customerId */ 2:
                    message.customerId = reader.string();
                    break;
                case /* repeated string roomsId */ 3:
                    message.roomsId.push(reader.string());
                    break;
                case /* repeated string roomSettingsFields */ 4:
                    message.roomSettingsFields.push(reader.string());
                    break;
                case /* repeated string displaySettingsFields */ 5:
                    message.displaySettingsFields.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyRoomTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string templateId = 1; */
        if (message.templateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.templateId);
        /* string customerId = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* repeated string roomsId = 3; */
        for (let i = 0; i < message.roomsId.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.roomsId[i]);
        /* repeated string roomSettingsFields = 4; */
        for (let i = 0; i < message.roomSettingsFields.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.roomSettingsFields[i]);
        /* repeated string displaySettingsFields = 5; */
        for (let i = 0; i < message.displaySettingsFields.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.displaySettingsFields[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApplyRoomTemplateRequest
 */
export const ApplyRoomTemplateRequest = new ApplyRoomTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyRoomTemplateResponse$Type extends MessageType<ApplyRoomTemplateResponse> {
    constructor() {
        super("ApplyRoomTemplateResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplyRoomTemplateResponse>): ApplyRoomTemplateResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<ApplyRoomTemplateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyRoomTemplateResponse): ApplyRoomTemplateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyRoomTemplateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApplyRoomTemplateResponse
 */
export const ApplyRoomTemplateResponse = new ApplyRoomTemplateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskTemplateSettings$Type extends MessageType<DeskTemplateSettings> {
    constructor() {
        super("DeskTemplateSettings", [
            { no: 1, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "account", kind: "message", T: () => DeskTemplateSettings_Account },
            { no: 5, name: "showCheckInTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "checkInTime", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "qrCodeRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "workingHrsActivated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "workingHrsStart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "workingHrsEnd", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeskTemplateSettings>): DeskTemplateSettings {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.description = "";
        message.tags = [];
        message.isBlocked = false;
        message.showCheckInTime = false;
        message.checkInTime = 0;
        message.qrCodeRequired = false;
        message.workingHrsActivated = false;
        message.workingHrsStart = "";
        message.workingHrsEnd = "";
        if (value !== undefined)
            reflectionMergePartial<DeskTemplateSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskTemplateSettings): DeskTemplateSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string description */ 1:
                    message.description = reader.string();
                    break;
                case /* repeated string tags */ 2:
                    message.tags.push(reader.string());
                    break;
                case /* bool isBlocked */ 3:
                    message.isBlocked = reader.bool();
                    break;
                case /* DeskTemplateSettings.Account account */ 4:
                    message.account = DeskTemplateSettings_Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* bool showCheckInTime */ 5:
                    message.showCheckInTime = reader.bool();
                    break;
                case /* int32 checkInTime */ 6:
                    message.checkInTime = reader.int32();
                    break;
                case /* bool qrCodeRequired */ 7:
                    message.qrCodeRequired = reader.bool();
                    break;
                case /* bool workingHrsActivated */ 8:
                    message.workingHrsActivated = reader.bool();
                    break;
                case /* string workingHrsStart */ 9:
                    message.workingHrsStart = reader.string();
                    break;
                case /* string workingHrsEnd */ 10:
                    message.workingHrsEnd = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskTemplateSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string description = 1; */
        if (message.description !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.description);
        /* repeated string tags = 2; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.tags[i]);
        /* bool isBlocked = 3; */
        if (message.isBlocked !== false)
            writer.tag(3, WireType.Varint).bool(message.isBlocked);
        /* DeskTemplateSettings.Account account = 4; */
        if (message.account)
            DeskTemplateSettings_Account.internalBinaryWrite(message.account, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool showCheckInTime = 5; */
        if (message.showCheckInTime !== false)
            writer.tag(5, WireType.Varint).bool(message.showCheckInTime);
        /* int32 checkInTime = 6; */
        if (message.checkInTime !== 0)
            writer.tag(6, WireType.Varint).int32(message.checkInTime);
        /* bool qrCodeRequired = 7; */
        if (message.qrCodeRequired !== false)
            writer.tag(7, WireType.Varint).bool(message.qrCodeRequired);
        /* bool workingHrsActivated = 8; */
        if (message.workingHrsActivated !== false)
            writer.tag(8, WireType.Varint).bool(message.workingHrsActivated);
        /* string workingHrsStart = 9; */
        if (message.workingHrsStart !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.workingHrsStart);
        /* string workingHrsEnd = 10; */
        if (message.workingHrsEnd !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.workingHrsEnd);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskTemplateSettings
 */
export const DeskTemplateSettings = new DeskTemplateSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskTemplateSettings_Account$Type extends MessageType<DeskTemplateSettings_Account> {
    constructor() {
        super("DeskTemplateSettings.Account", [
            { no: 1, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tenantID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeskTemplateSettings_Account>): DeskTemplateSettings_Account {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.displayName = "";
        message.email = "";
        message.tenantID = "";
        message.accountID = "";
        if (value !== undefined)
            reflectionMergePartial<DeskTemplateSettings_Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskTemplateSettings_Account): DeskTemplateSettings_Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string displayName */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string tenantID */ 3:
                    message.tenantID = reader.string();
                    break;
                case /* string accountID */ 4:
                    message.accountID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskTemplateSettings_Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string displayName = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string tenantID = 3; */
        if (message.tenantID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tenantID);
        /* string accountID = 4; */
        if (message.accountID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskTemplateSettings.Account
 */
export const DeskTemplateSettings_Account = new DeskTemplateSettings_Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateDeskTemplateRequest$Type extends MessageType<CreateDeskTemplateRequest> {
    constructor() {
        super("CreateDeskTemplateRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tenantId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "deskSettings", kind: "message", T: () => DeskTemplateSettings }
        ]);
    }
    create(value?: PartialMessage<CreateDeskTemplateRequest>): CreateDeskTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.description = "";
        message.tenantId = "";
        if (value !== undefined)
            reflectionMergePartial<CreateDeskTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateDeskTemplateRequest): CreateDeskTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* string tenantId */ 3:
                    message.tenantId = reader.string();
                    break;
                case /* DeskTemplateSettings deskSettings */ 4:
                    message.deskSettings = DeskTemplateSettings.internalBinaryRead(reader, reader.uint32(), options, message.deskSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateDeskTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* string tenantId = 3; */
        if (message.tenantId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tenantId);
        /* DeskTemplateSettings deskSettings = 4; */
        if (message.deskSettings)
            DeskTemplateSettings.internalBinaryWrite(message.deskSettings, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateDeskTemplateRequest
 */
export const CreateDeskTemplateRequest = new CreateDeskTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskTemplate$Type extends MessageType<DeskTemplate> {
    constructor() {
        super("DeskTemplate", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tenantId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "deskSettings", kind: "message", T: () => DeskTemplateSettings }
        ]);
    }
    create(value?: PartialMessage<DeskTemplate>): DeskTemplate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.description = "";
        message.tenantId = "";
        if (value !== undefined)
            reflectionMergePartial<DeskTemplate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskTemplate): DeskTemplate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string tenantId */ 4:
                    message.tenantId = reader.string();
                    break;
                case /* DeskTemplateSettings deskSettings */ 5:
                    message.deskSettings = DeskTemplateSettings.internalBinaryRead(reader, reader.uint32(), options, message.deskSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskTemplate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string tenantId = 4; */
        if (message.tenantId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tenantId);
        /* DeskTemplateSettings deskSettings = 5; */
        if (message.deskSettings)
            DeskTemplateSettings.internalBinaryWrite(message.deskSettings, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskTemplate
 */
export const DeskTemplate = new DeskTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDeskTemplatesRequest$Type extends MessageType<ListDeskTemplatesRequest> {
    constructor() {
        super("ListDeskTemplatesRequest", [
            { no: 1, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListDeskTemplatesRequest>): ListDeskTemplatesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<ListDeskTemplatesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDeskTemplatesRequest): ListDeskTemplatesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerId */ 1:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDeskTemplatesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerId = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListDeskTemplatesRequest
 */
export const ListDeskTemplatesRequest = new ListDeskTemplatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskTemplatesResponse$Type extends MessageType<DeskTemplatesResponse> {
    constructor() {
        super("DeskTemplatesResponse", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeskTemplate }
        ]);
    }
    create(value?: PartialMessage<DeskTemplatesResponse>): DeskTemplatesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.results = [];
        if (value !== undefined)
            reflectionMergePartial<DeskTemplatesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskTemplatesResponse): DeskTemplatesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DeskTemplate results */ 1:
                    message.results.push(DeskTemplate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskTemplatesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated DeskTemplate results = 1; */
        for (let i = 0; i < message.results.length; i++)
            DeskTemplate.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeskTemplatesResponse
 */
export const DeskTemplatesResponse = new DeskTemplatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateDeskTemplateRequest$Type extends MessageType<UpdateDeskTemplateRequest> {
    constructor() {
        super("UpdateDeskTemplateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tenantId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "deskSettings", kind: "message", T: () => DeskTemplateSettings }
        ]);
    }
    create(value?: PartialMessage<UpdateDeskTemplateRequest>): UpdateDeskTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.description = "";
        message.tenantId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateDeskTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateDeskTemplateRequest): UpdateDeskTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* string tenantId */ 4:
                    message.tenantId = reader.string();
                    break;
                case /* DeskTemplateSettings deskSettings */ 5:
                    message.deskSettings = DeskTemplateSettings.internalBinaryRead(reader, reader.uint32(), options, message.deskSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateDeskTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* string tenantId = 4; */
        if (message.tenantId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tenantId);
        /* DeskTemplateSettings deskSettings = 5; */
        if (message.deskSettings)
            DeskTemplateSettings.internalBinaryWrite(message.deskSettings, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateDeskTemplateRequest
 */
export const UpdateDeskTemplateRequest = new UpdateDeskTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteDeskTemplateRequest$Type extends MessageType<DeleteDeskTemplateRequest> {
    constructor() {
        super("DeleteDeskTemplateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteDeskTemplateRequest>): DeleteDeskTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteDeskTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteDeskTemplateRequest): DeleteDeskTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customerId */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteDeskTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customerId = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteDeskTemplateRequest
 */
export const DeleteDeskTemplateRequest = new DeleteDeskTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyDeskTemplateRequest$Type extends MessageType<ApplyDeskTemplateRequest> {
    constructor() {
        super("ApplyDeskTemplateRequest", [
            { no: 1, name: "templateId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "desksId", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "deskSettingsFields", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplyDeskTemplateRequest>): ApplyDeskTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.templateId = "";
        message.customerId = "";
        message.desksId = [];
        message.deskSettingsFields = [];
        if (value !== undefined)
            reflectionMergePartial<ApplyDeskTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyDeskTemplateRequest): ApplyDeskTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string templateId */ 1:
                    message.templateId = reader.string();
                    break;
                case /* string customerId */ 2:
                    message.customerId = reader.string();
                    break;
                case /* repeated string desksId */ 3:
                    message.desksId.push(reader.string());
                    break;
                case /* repeated string deskSettingsFields */ 4:
                    message.deskSettingsFields.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyDeskTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string templateId = 1; */
        if (message.templateId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.templateId);
        /* string customerId = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* repeated string desksId = 3; */
        for (let i = 0; i < message.desksId.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.desksId[i]);
        /* repeated string deskSettingsFields = 4; */
        for (let i = 0; i < message.deskSettingsFields.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.deskSettingsFields[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApplyDeskTemplateRequest
 */
export const ApplyDeskTemplateRequest = new ApplyDeskTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyDeskTemplateResponse$Type extends MessageType<ApplyDeskTemplateResponse> {
    constructor() {
        super("ApplyDeskTemplateResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplyDeskTemplateResponse>): ApplyDeskTemplateResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<ApplyDeskTemplateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyDeskTemplateResponse): ApplyDeskTemplateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyDeskTemplateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApplyDeskTemplateResponse
 */
export const ApplyDeskTemplateResponse = new ApplyDeskTemplateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAvailableDesksRequest$Type extends MessageType<ListAvailableDesksRequest> {
    constructor() {
        super("ListAvailableDesksRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tagsFilter", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListAvailableDesksRequest>): ListAvailableDesksRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.customerId = "";
        message.tagsFilter = [];
        if (value !== undefined)
            reflectionMergePartial<ListAvailableDesksRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAvailableDesksRequest): ListAvailableDesksRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string customerId */ 2:
                    message.customerId = reader.string();
                    break;
                case /* repeated string tagsFilter */ 3:
                    message.tagsFilter.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAvailableDesksRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string customerId = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* repeated string tagsFilter = 3; */
        for (let i = 0; i < message.tagsFilter.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tagsFilter[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListAvailableDesksRequest
 */
export const ListAvailableDesksRequest = new ListAvailableDesksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAvailableDesksResponse$Type extends MessageType<ListAvailableDesksResponse> {
    constructor() {
        super("ListAvailableDesksResponse", [
            { no: 1, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchDesk }
        ]);
    }
    create(value?: PartialMessage<ListAvailableDesksResponse>): ListAvailableDesksResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.desks = [];
        if (value !== undefined)
            reflectionMergePartial<ListAvailableDesksResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAvailableDesksResponse): ListAvailableDesksResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SearchDesk desks */ 1:
                    message.desks.push(SearchDesk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAvailableDesksResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated SearchDesk desks = 1; */
        for (let i = 0; i < message.desks.length; i++)
            SearchDesk.internalBinaryWrite(message.desks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ListAvailableDesksResponse
 */
export const ListAvailableDesksResponse = new ListAvailableDesksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateBookingDashboardItem$Type extends MessageType<CreateBookingDashboardItem> {
    constructor() {
        super("CreateBookingDashboardItem", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "locationType", kind: "enum", T: () => ["BookingDashboardLocationType", BookingDashboardLocationType] }
        ]);
    }
    create(value?: PartialMessage<CreateBookingDashboardItem>): CreateBookingDashboardItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.locationType = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateBookingDashboardItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBookingDashboardItem): CreateBookingDashboardItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* BookingDashboardLocationType locationType */ 2:
                    message.locationType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBookingDashboardItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* BookingDashboardLocationType locationType = 2; */
        if (message.locationType !== 0)
            writer.tag(2, WireType.Varint).int32(message.locationType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateBookingDashboardItem
 */
export const CreateBookingDashboardItem = new CreateBookingDashboardItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateBookingDashboardRequest$Type extends MessageType<CreateBookingDashboardRequest> {
    constructor() {
        super("CreateBookingDashboardRequest", [
            { no: 1, name: "location", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CreateBookingDashboardItem },
            { no: 2, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateBookingDashboardRequest>): CreateBookingDashboardRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.location = [];
        message.accountId = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<CreateBookingDashboardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBookingDashboardRequest): CreateBookingDashboardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CreateBookingDashboardItem location */ 1:
                    message.location.push(CreateBookingDashboardItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string accountId */ 2:
                    message.accountId = reader.string();
                    break;
                case /* string customerId */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBookingDashboardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated CreateBookingDashboardItem location = 1; */
        for (let i = 0; i < message.location.length; i++)
            CreateBookingDashboardItem.internalBinaryWrite(message.location[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string accountId = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* string customerId = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateBookingDashboardRequest
 */
export const CreateBookingDashboardRequest = new CreateBookingDashboardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookingDashboardRoom$Type extends MessageType<BookingDashboardRoom> {
    constructor() {
        super("BookingDashboardRoom", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amenities", kind: "message", T: () => AmenitiesFilter },
            { no: 5, name: "path", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem },
            { no: 6, name: "seats", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "calendarProviderId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "calendarId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "licensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BookingDashboardRoom>): BookingDashboardRoom {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.tags = [];
        message.path = [];
        message.seats = 0;
        message.calendarProviderId = "";
        message.calendarId = "";
        message.customerId = "";
        message.licensed = false;
        if (value !== undefined)
            reflectionMergePartial<BookingDashboardRoom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BookingDashboardRoom): BookingDashboardRoom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                case /* AmenitiesFilter amenities */ 4:
                    message.amenities = AmenitiesFilter.internalBinaryRead(reader, reader.uint32(), options, message.amenities);
                    break;
                case /* repeated LocationItem path */ 5:
                    message.path.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 seats */ 6:
                    message.seats = reader.int32();
                    break;
                case /* string calendarProviderId */ 7:
                    message.calendarProviderId = reader.string();
                    break;
                case /* string calendarId */ 8:
                    message.calendarId = reader.string();
                    break;
                case /* string customerId */ 9:
                    message.customerId = reader.string();
                    break;
                case /* bool licensed */ 10:
                    message.licensed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BookingDashboardRoom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        /* AmenitiesFilter amenities = 4; */
        if (message.amenities)
            AmenitiesFilter.internalBinaryWrite(message.amenities, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated LocationItem path = 5; */
        for (let i = 0; i < message.path.length; i++)
            LocationItem.internalBinaryWrite(message.path[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 seats = 6; */
        if (message.seats !== 0)
            writer.tag(6, WireType.Varint).int32(message.seats);
        /* string calendarProviderId = 7; */
        if (message.calendarProviderId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.calendarProviderId);
        /* string calendarId = 8; */
        if (message.calendarId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.calendarId);
        /* string customerId = 9; */
        if (message.customerId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.customerId);
        /* bool licensed = 10; */
        if (message.licensed !== false)
            writer.tag(10, WireType.Varint).bool(message.licensed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BookingDashboardRoom
 */
export const BookingDashboardRoom = new BookingDashboardRoom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookingDashboardDesk$Type extends MessageType<BookingDashboardDesk> {
    constructor() {
        super("BookingDashboardDesk", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "path", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocationItem },
            { no: 6, name: "licensed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "qrCodeRequired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "workingHrsActivated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "isBlocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "showCheckInTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BookingDashboardDesk>): BookingDashboardDesk {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.tags = [];
        message.path = [];
        message.licensed = false;
        message.qrCodeRequired = false;
        message.workingHrsActivated = false;
        message.isBlocked = false;
        message.showCheckInTime = false;
        if (value !== undefined)
            reflectionMergePartial<BookingDashboardDesk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BookingDashboardDesk): BookingDashboardDesk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                case /* repeated LocationItem path */ 5:
                    message.path.push(LocationItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool licensed */ 6:
                    message.licensed = reader.bool();
                    break;
                case /* bool qrCodeRequired */ 7:
                    message.qrCodeRequired = reader.bool();
                    break;
                case /* bool workingHrsActivated */ 8:
                    message.workingHrsActivated = reader.bool();
                    break;
                case /* bool isBlocked */ 9:
                    message.isBlocked = reader.bool();
                    break;
                case /* bool showCheckInTime */ 10:
                    message.showCheckInTime = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BookingDashboardDesk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        /* repeated LocationItem path = 5; */
        for (let i = 0; i < message.path.length; i++)
            LocationItem.internalBinaryWrite(message.path[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool licensed = 6; */
        if (message.licensed !== false)
            writer.tag(6, WireType.Varint).bool(message.licensed);
        /* bool qrCodeRequired = 7; */
        if (message.qrCodeRequired !== false)
            writer.tag(7, WireType.Varint).bool(message.qrCodeRequired);
        /* bool workingHrsActivated = 8; */
        if (message.workingHrsActivated !== false)
            writer.tag(8, WireType.Varint).bool(message.workingHrsActivated);
        /* bool isBlocked = 9; */
        if (message.isBlocked !== false)
            writer.tag(9, WireType.Varint).bool(message.isBlocked);
        /* bool showCheckInTime = 10; */
        if (message.showCheckInTime !== false)
            writer.tag(10, WireType.Varint).bool(message.showCheckInTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BookingDashboardDesk
 */
export const BookingDashboardDesk = new BookingDashboardDesk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookingDashboardItem$Type extends MessageType<BookingDashboardItem> {
    constructor() {
        super("BookingDashboardItem", [
            { no: 1, name: "room", kind: "message", oneof: "location", T: () => BookingDashboardRoom },
            { no: 2, name: "desk", kind: "message", oneof: "location", T: () => BookingDashboardDesk },
            { no: 3, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "locationType", kind: "enum", T: () => ["BookingDashboardLocationType", BookingDashboardLocationType] }
        ]);
    }
    create(value?: PartialMessage<BookingDashboardItem>): BookingDashboardItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.location = { oneofKind: undefined };
        message.locationId = "";
        message.locationType = 0;
        if (value !== undefined)
            reflectionMergePartial<BookingDashboardItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BookingDashboardItem): BookingDashboardItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* BookingDashboardRoom room */ 1:
                    message.location = {
                        oneofKind: "room",
                        room: BookingDashboardRoom.internalBinaryRead(reader, reader.uint32(), options, (message.location as any).room)
                    };
                    break;
                case /* BookingDashboardDesk desk */ 2:
                    message.location = {
                        oneofKind: "desk",
                        desk: BookingDashboardDesk.internalBinaryRead(reader, reader.uint32(), options, (message.location as any).desk)
                    };
                    break;
                case /* string locationId */ 3:
                    message.locationId = reader.string();
                    break;
                case /* BookingDashboardLocationType locationType */ 4:
                    message.locationType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BookingDashboardItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* BookingDashboardRoom room = 1; */
        if (message.location.oneofKind === "room")
            BookingDashboardRoom.internalBinaryWrite(message.location.room, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* BookingDashboardDesk desk = 2; */
        if (message.location.oneofKind === "desk")
            BookingDashboardDesk.internalBinaryWrite(message.location.desk, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string locationId = 3; */
        if (message.locationId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.locationId);
        /* BookingDashboardLocationType locationType = 4; */
        if (message.locationType !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BookingDashboardItem
 */
export const BookingDashboardItem = new BookingDashboardItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookingDashboardResponse$Type extends MessageType<BookingDashboardResponse> {
    constructor() {
        super("BookingDashboardResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BookingDashboardItem },
            { no: 2, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BookingDashboardResponse>): BookingDashboardResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        message.accountId = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<BookingDashboardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BookingDashboardResponse): BookingDashboardResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated BookingDashboardItem items */ 1:
                    message.items.push(BookingDashboardItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string accountId */ 2:
                    message.accountId = reader.string();
                    break;
                case /* string customerId */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BookingDashboardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated BookingDashboardItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            BookingDashboardItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string accountId = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* string customerId = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BookingDashboardResponse
 */
export const BookingDashboardResponse = new BookingDashboardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookingDashboardRequest$Type extends MessageType<GetBookingDashboardRequest> {
    constructor() {
        super("GetBookingDashboardRequest", [
            { no: 1, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBookingDashboardRequest>): GetBookingDashboardRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountId = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<GetBookingDashboardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBookingDashboardRequest): GetBookingDashboardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountId */ 1:
                    message.accountId = reader.string();
                    break;
                case /* string customerId */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBookingDashboardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountId = 1; */
        if (message.accountId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountId);
        /* string customerId = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetBookingDashboardRequest
 */
export const GetBookingDashboardRequest = new GetBookingDashboardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteBookingDashboardItemRequest$Type extends MessageType<DeleteBookingDashboardItemRequest> {
    constructor() {
        super("DeleteBookingDashboardItemRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteBookingDashboardItemRequest>): DeleteBookingDashboardItemRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.accountId = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteBookingDashboardItemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteBookingDashboardItemRequest): DeleteBookingDashboardItemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string accountId */ 2:
                    message.accountId = reader.string();
                    break;
                case /* string customerId */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteBookingDashboardItemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string accountId = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* string customerId = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteBookingDashboardItemRequest
 */
export const DeleteBookingDashboardItemRequest = new DeleteBookingDashboardItemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateInterestedWorkplacesRequest$Type extends MessageType<CreateInterestedWorkplacesRequest> {
    constructor() {
        super("CreateInterestedWorkplacesRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "enum", T: () => ["AccountInterestedWorkplaces", AccountInterestedWorkplaces] }
        ]);
    }
    create(value?: PartialMessage<CreateInterestedWorkplacesRequest>): CreateInterestedWorkplacesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.accountId = "";
        message.customerId = "";
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateInterestedWorkplacesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateInterestedWorkplacesRequest): CreateInterestedWorkplacesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string accountId */ 2:
                    message.accountId = reader.string();
                    break;
                case /* string customerId */ 3:
                    message.customerId = reader.string();
                    break;
                case /* AccountInterestedWorkplaces type */ 4:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateInterestedWorkplacesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string accountId = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* string customerId = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        /* AccountInterestedWorkplaces type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateInterestedWorkplacesRequest
 */
export const CreateInterestedWorkplacesRequest = new CreateInterestedWorkplacesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateInterestedWorkplacesResponse$Type extends MessageType<CreateInterestedWorkplacesResponse> {
    constructor() {
        super("CreateInterestedWorkplacesResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateInterestedWorkplacesResponse>): CreateInterestedWorkplacesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<CreateInterestedWorkplacesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateInterestedWorkplacesResponse): CreateInterestedWorkplacesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateInterestedWorkplacesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateInterestedWorkplacesResponse
 */
export const CreateInterestedWorkplacesResponse = new CreateInterestedWorkplacesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteInterestedWorkplacesRequest$Type extends MessageType<DeleteInterestedWorkplacesRequest> {
    constructor() {
        super("DeleteInterestedWorkplacesRequest", [
            { no: 1, name: "locationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteInterestedWorkplacesRequest>): DeleteInterestedWorkplacesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locationId = "";
        message.accountId = "";
        message.customerId = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteInterestedWorkplacesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteInterestedWorkplacesRequest): DeleteInterestedWorkplacesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string locationId */ 1:
                    message.locationId = reader.string();
                    break;
                case /* string accountId */ 2:
                    message.accountId = reader.string();
                    break;
                case /* string customerId */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteInterestedWorkplacesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string locationId = 1; */
        if (message.locationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.locationId);
        /* string accountId = 2; */
        if (message.accountId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountId);
        /* string customerId = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteInterestedWorkplacesRequest
 */
export const DeleteInterestedWorkplacesRequest = new DeleteInterestedWorkplacesRequest$Type();
/**
 * @generated ServiceType for protobuf service Workplaces
 */
export const Workplaces = new ServiceType("Workplaces", [
    { name: "WorkplaceQuery", options: { "google.api.http": { post: "/v1/workplaces", body: "*" } }, I: WorkplaceQueryRequest, O: WorkplaceQueryResponse },
    { name: "DeleteWorkplace", options: { "google.api.http": { delete: "/v1/workplaces" } }, I: DeleteWorkplaceRequest, O: Empty },
    { name: "CalendarRooms", options: { "google.api.http": { get: "/v1/workplaces/rooms" } }, I: CalendarRoomsRequest, O: CalendarRoomsResponse },
    { name: "ListNearbyRooms", options: {}, I: NearbyRoomsRequest, O: NearbyRoomsResponse },
    { name: "DisconnectCalendarProvider", options: { "google.api.http": { patch: "/v1/workplaces/rooms/disconnect" } }, I: CalendarRoomsRequest, O: DisconnectCalendarProviderResponse },
    { name: "ListAvailableRooms", options: { "google.api.http": { get: "/v1/workplaces/availableRooms" } }, I: ListAvailableRoomsRequest, O: AvailableRoomsResponse },
    { name: "ListAvailableDesks", options: { "google.api.http": { get: "/v1/workplaces/availableDesks" } }, I: ListAvailableDesksRequest, O: ListAvailableDesksResponse },
    { name: "ListTags", options: { "google.api.http": { get: "/v1/workplaces/tags" } }, I: ListTagsRequest, O: Tags },
    { name: "SearchDesks", options: { "google.api.http": { get: "/v1/workplaces/search/desks" } }, I: SearchRequest, O: SearchDesksResponse },
    { name: "SearchAccountDesks", options: { "google.api.http": { get: "/v1/workplaces/search/desks-acc" } }, I: SearchAccountDesksRequest, O: DesksListResponse },
    { name: "SearchRooms", options: { "google.api.http": { get: "/v1/workplaces/search/rooms" } }, I: SearchRequest, O: RoomsInfoResponse },
    { name: "LocationPath", options: { "google.api.http": { get: "/v1/workplaces/search/path" } }, I: SearchRequest, O: LocationPathResponse },
    { name: "BatchLocationPath", options: { "google.api.http": { get: "/v1/workplaces/search/path/batch" } }, I: BatchSearchRequest, O: BatchLocationPathResponse },
    { name: "DesksInfo", options: { "google.api.http": { get: "/v1/workplaces/desks/info" } }, I: DesksInfoRequest, O: DesksListResponse },
    { name: "SameLevelDesks", options: { "google.api.http": { get: "/v1/workplaces/desks/same-level" } }, I: SameLevelDesksRequest, O: DesksListResponse },
    { name: "RoomsInfo", options: { "google.api.http": { post: "/v1/workplaces/rooms/info", body: "*" } }, I: RoomsInfoRequest, O: RoomsInfoResponse },
    { name: "GetRoom", options: { "google.api.http": { get: "/v1/workplaces/rooms/{customer_id}/{id}" } }, I: GetRoomRequest, O: GetRoomResponse },
    { name: "ListRooms", options: { "google.api.http": { get: "/v1/workplaces/rooms/{customer_id}" } }, I: ListRoomsRequest, O: ListRoomsResponse },
    { name: "CustomerActiveRooms", options: {}, I: CustomerActiveRoomsRequest, O: CustomerActiveRoomsResponse },
    { name: "UploadMap", options: {}, I: MapRequest, O: MapResponse },
    { name: "DeleteMap", options: {}, I: DeleteMapRequest, O: Empty },
    { name: "GenerateQRCode", options: { "google.api.http": { get: "/v1/workplaces/qrcode" } }, I: GenerateQRCodeRequest, O: GenerateQRCodeResponse },
    { name: "GetDesk", options: { "google.api.http": { get: "/v1/workplaces/desks/{customer_id}/{id}" } }, I: GetDeskRequest, O: GetDeskResponse },
    { name: "FloorMap", options: { "google.api.http": { get: "/v1/workplaces/floor/{customer_id}/{floor_id}" } }, I: FloorMapRequest, O: FloorMapResponse },
    { name: "FloorMapMetadata", options: { "google.api.http": { get: "/v1/workplaces/floor/{customer_id}/{floor_id}/metadata" } }, I: FloorMapMetadataRequest, O: FloorMapResponse },
    { name: "RFXFloorMapMetadata", options: { "google.api.http": { get: "/v1/workplaces/floor/{customer_id}/rfx/metadata" } }, I: RFXFloorMapMetadataRequest, O: FloorMapResponse },
    { name: "RDXFloorMap", options: { "google.api.http": { get: "/v1/workplaces/floor/{customer_id}/{room_id}/rdx" } }, I: RDXFloorMapRequest, O: FloorMapResponse },
    { name: "DesksBulkCreate", options: { "google.api.http": { post: "/v1/workplaces/desks/bulk", body: "*" } }, I: DesksBulkCreateRequest, O: DesksBulkCreateResponse },
    { name: "UpdateGlobalWorkingHrs", options: { "google.api.http": { put: "/v1/workplaces/working_hrs", body: "*" } }, I: UpdateGlobalWorkingHrsRequest, O: UpdateGlobalWorkingHrsResponse },
    { name: "SendErrorReport", options: { "google.api.http": { post: "/v1/workplaces/send_error", body: "*" } }, I: SendErrorReportRequest, O: Empty },
    { name: "SetDevicePingInfo", options: { "google.api.http": { post: "/v1/workplaces/last_ping", body: "*" } }, I: SetDevicePingInfoRequest, O: Empty },
    { name: "GetConnectedFloorMap", options: { "google.api.http": { get: "/v1/workplaces/floor/map" } }, I: GetConnectedFloorMapRequest, O: GetConnectedFloorMapResponse },
    { name: "UploadCustomLanguage", options: { "google.api.http": { post: "/v1/workplaces/custom_language", body: "*" } }, I: UploadCustomLanguageRequest, O: UploadCustomLanguageResponse },
    { name: "DeleteCustomLanguage", options: { "google.api.http": { delete: "/v1/workplaces/custom_language/{lang_url}" }, "google.api.method_signature": ["lang_url"] }, I: DeleteCustomLanguageRequest, O: Empty },
    { name: "CustomLanguage", options: { "google.api.http": { get: "/v1/workplaces/custom_language/{customer_id}/{lang_code}" }, "google.api.method_signature": ["customer_id,lang_code"] }, I: GetCustomLanguageRequest, O: CustomLanguageResponse },
    { name: "SyncDisplaySettingsCustomerID", options: {}, I: Empty, O: Empty },
    { name: "CheckDeviceVersion", options: { "google.api.http": { get: "/v1/workplaces/device_version" } }, I: CheckDeviceVersionRequest, O: CheckDeviceVersionResponse },
    { name: "UpdateDeviceApp", options: { "google.api.http": { post: "/v1/workplaces/device_app", body: "*" } }, I: UpdateDeviceAppRequest, O: Empty },
    { name: "CreateCustomerGlobalCustomLanguages", options: { "google.api.http": { post: "/v1/workplaces/lang", body: "*" } }, I: CreateCustomerGlobalCustomLanguagesRequest, O: Empty },
    { name: "ListCustomerLanguages", options: { "google.api.http": { get: "/v1/workplaces/lang" } }, I: ListCustomerLanguagesRequest, O: ListCustomerLanguagesResponse },
    { name: "DeleteCustomerGlobalCustomLanguages", options: { "google.api.http": { delete: "/v1/workplaces/lang" } }, I: DeleteCustomerGlobalCustomLanguagesRequest, O: Empty },
    { name: "RetrieveCustomerGlobalCustomLanguages", options: { "google.api.http": { get: "/v1/workplaces/lang/retrieve" } }, I: RetrieveCustomerGlobalCustomLanguagesRequest, O: RetrieveCustomerGlobalCustomLanguagesResponse },
    { name: "UpdateCustomerGlobalCustomLanguages", options: { "google.api.http": { post: "/v1/workplaces/update", body: "*" } }, I: UpdateCustomerGlobalCustomLanguagesRequest, O: UpdateCustomerGlobalCustomLanguagesResponse },
    { name: "SyncCustomLanguageFields", options: { "google.api.http": { post: "/v1/workplaces/lang/sync", body: "*" } }, I: SyncCustomLanguageFieldsRequest, O: Empty },
    { name: "OnLevelSearch", options: { "google.api.http": { get: "/v1/workplaces/search/onlevel" } }, I: OnLevelSearchRequest, O: OnLevelSearchResponse },
    { name: "Sync", options: {}, I: Empty, O: Empty },
    { name: "SyncFloorMapMetadata", options: { "google.api.http": { post: "/v1/workplaces/map/sync" } }, I: SyncFloorMapMetadataRequest, O: SyncFloorMapMetadataResponse },
    { name: "GetMapEntityFloorID", options: { "google.api.http": { get: "/v1/workplaces/map/floor" } }, I: GetMapEntityFloorIDRequest, O: GetMapEntityFloorIDResponse },
    { name: "UpdateRoomCalIntegrationStatus", options: { "google.api.http": { post: "/v1/workplaces/rooms/integration-status" } }, I: UpdateRoomCalIntegrationStatusRequest, O: UpdateRoomCalIntegrationStatusResponse },
    { name: "DefaultLanguage", options: { "google.api.http": { get: "/v1/workplaces/default-language/{id}" }, "google.api.method_signature": ["id"] }, I: GetDefaultLanguageRequest, O: GetDefaultLanguageResponse },
    { name: "CreateRoomTemplate", options: { "google.api.http": { post: "/v1/workplaces/templates/rooms", body: "*" } }, I: CreateRoomTemplateRequest, O: RoomTemplate },
    { name: "ListRoomTemplates", options: { "google.api.http": { get: "/v1/workplaces/templates/rooms" } }, I: ListRoomTemplatesRequest, O: RoomTemplatesResponse },
    { name: "UpdateRoomTemplate", options: { "google.api.http": { put: "/v1/workplaces/templates/rooms/update", body: "*" } }, I: UpdateRoomTemplateRequest, O: RoomTemplate },
    { name: "DeleteRoomTemplate", options: { "google.api.http": { delete: "/v1/workplaces/templates/rooms/delete", body: "*" } }, I: DeleteRoomTemplateRequest, O: Empty },
    { name: "ApplyRoomTemplate", options: { "google.api.http": { post: "/v1/workplaces/templates/rooms/apply", body: "*" } }, I: ApplyRoomTemplateRequest, O: ApplyRoomTemplateResponse },
    { name: "CreateDeskTemplate", options: { "google.api.http": { post: "/v1/workplaces/templates/desks", body: "*" } }, I: CreateDeskTemplateRequest, O: DeskTemplate },
    { name: "ListDeskTemplates", options: { "google.api.http": { get: "/v1/workplaces/templates/desks" } }, I: ListDeskTemplatesRequest, O: DeskTemplatesResponse },
    { name: "UpdateDeskTemplate", options: { "google.api.http": { put: "/v1/workplaces/templates/desks/update", body: "*" } }, I: UpdateDeskTemplateRequest, O: DeskTemplate },
    { name: "DeleteDeskTemplate", options: { "google.api.http": { delete: "/v1/workplaces/templates/desks/delete", body: "*" } }, I: DeleteDeskTemplateRequest, O: Empty },
    { name: "ApplyDeskTemplate", options: { "google.api.http": { post: "/v1/workplaces/templates/desks/apply", body: "*" } }, I: ApplyDeskTemplateRequest, O: ApplyDeskTemplateResponse },
    { name: "ExportDevices", options: {}, I: Empty, O: Empty },
    { name: "CreateBookingDashboard", options: {}, I: CreateBookingDashboardRequest, O: BookingDashboardResponse },
    { name: "GetBookingDashboard", options: {}, I: GetBookingDashboardRequest, O: BookingDashboardResponse },
    { name: "DeleteBookingDashboardItem", options: {}, I: DeleteBookingDashboardItemRequest, O: Empty },
    { name: "CreateInterestedWorkplaces", options: {}, I: CreateInterestedWorkplacesRequest, O: CreateInterestedWorkplacesResponse },
    { name: "DeleteInterestedWorkplaces", options: {}, I: DeleteInterestedWorkplacesRequest, O: Empty }
]);
