import { createContext, useContext } from "react";
import { RoomTemplate } from "../../../../api/grpc/workplaces/workplaces";

export interface IRoomTemplateContext {
  roomTemplates: RoomTemplate[];
  errorRoomTemplates?: string;
  loadingRoomTemplate: boolean;
  refetchRoomTemplates: () => Promise<void>;
}

export const RoomTemplateContext = createContext<IRoomTemplateContext>({
  roomTemplates: [],
  errorRoomTemplates: undefined,
  loadingRoomTemplate: false,
  refetchRoomTemplates: async () => {},
});

export const useRoomTemplateContext = () => useContext(RoomTemplateContext);

export const { Provider } = RoomTemplateContext;
