import React from "react";
import { Person } from "../../../../../api/grpc/contacts/contacts";
import { FindPeopleSelector } from "../ModalTypes/FindPeopleSelector";

interface Props {
  onChange: (values: Person[]) => void;
  attendees: Person[];
}

export const FindPeopleModal = ({ onChange, attendees }: Props) => {
  return <FindPeopleSelector onChange={onChange} attendees={attendees} />;
};
