import { BUILDING_FIELDS_FRAGMENT } from "../../../api/graphql/buildings/buildings";
import { COMPANY_FIELDS_FRAGMENT } from "../../../api/graphql/companies/companies";
import { DESK_FIELDS_FRAGMENT } from "../../../api/graphql/desks/desks";
import { FLOOR_FIELDS_FRAGMENT } from "../../../api/graphql/floors/floors";
import { ROOM_FIELDS_FRAGMENT } from "../../../api/graphql/rooms/rooms";
import { SITE_FIELDS_FRAGMENT } from "../../../api/graphql/sites/sites";
import { WAY_FINDER_FIELDS_FRAGMENT } from "../../../api/graphql/wayfinder/wayfinder";
import { ZONE_FIELDS_FRAGMENT } from "../../../api/graphql/zones/zones";
import { WorkplaceTypes } from "../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

interface ListType {
  type: WorkplaceTypes;
  fragmentName: string;
  fragment: any;
}

export const workplaceTypesData: ListType[] = [
  {
    type: "Company",
    fragmentName: "CompanyFields",
    fragment: COMPANY_FIELDS_FRAGMENT,
  },
  {
    type: "Site",
    fragmentName: "SiteFields",
    fragment: SITE_FIELDS_FRAGMENT,
  },
  {
    type: "Building",
    fragmentName: "BuildingFields",
    fragment: BUILDING_FIELDS_FRAGMENT,
  },
  {
    type: "Floor",
    fragmentName: "FloorFields",
    fragment: FLOOR_FIELDS_FRAGMENT,
  },
  {
    type: "Zone",
    fragmentName: "ZoneFields",
    fragment: ZONE_FIELDS_FRAGMENT,
  },
  {
    type: "Room",
    fragmentName: "RoomFields",
    fragment: ROOM_FIELDS_FRAGMENT,
  },
  {
    type: "Desk",
    fragmentName: "DeskFields",
    fragment: DESK_FIELDS_FRAGMENT,
  },
  {
    type: "Wayfinder",
    fragmentName: "WayfinderFields",
    fragment: WAY_FINDER_FIELDS_FRAGMENT,
  },
];

// @TODO add obj to types to find them easy

export const getWorkplaceFragmentName = (type: string) => {
  const item = workplaceTypesData.find((item) => item.type === type);

  if (!item) {
    return;
  }

  const workplaceFragmentName = item.fragmentName;

  return workplaceFragmentName;
};

export const getWorkplaceFragment = (type: string) => {
  const item = workplaceTypesData.find((item) => item.type === type);

  if (!item) {
    return;
  }

  const workplaceFragment = item.fragment;

  return workplaceFragment;
};

export const getWorkplaceId = (type: string, id: string) => {
  const item = workplaceTypesData.find((item) => item.type === type);

  const typeItem = item?.type;

  return `${typeItem}:${id}`;
};
