import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FormFeedback } from "reactstrap";

import { useCustomerContext } from "../../../../../lib/context/Customer/CustomerContext";
import { useDevicesRequests } from "../../../../../api/graphql/devices/useDevicesRequests";

import { selectStyles } from "../../../../../lib/utils/selectStyles";
import { HandleRequestState } from "../../../HandleRequestState/HandleRequestState";
import { WorkplaceTypes } from "../../../../Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

interface Props {
  name: string;
  invalid: boolean;
  errorMessage: string;
  onChange: (value: string) => void;
  parentType?: WorkplaceTypes;
}

export const ExistingDeviceSelect = (props: Props) => {
  const { name, invalid, errorMessage, onChange, parentType } = props;
  const { customer } = useCustomerContext();
  const [data, setData] = useState<{ label: string; value: string }[]>([]);
  const {
    getDevices: {
      request,
      state: { loading, error, data: requestData },
    },
  } = useDevicesRequests();

  let workspaceType: "room" | "wayFinder" =
    parentType === "Wayfinder" ? "wayFinder" : "room";

  useEffect(() => {
    request({
      variables: {
        filter: {
          and: [
            {
              tenantId: {
                eq: customer?.tenantId,
              },
            },
          ],
        },
      },
    });
  }, []);

  useEffect(() => {
    setData(() => {
      if (!requestData) {
        return [];
      }

      const unAssignedDevices = requestData.queryDevice.filter((item) => {
        return (
          item[workspaceType] === null && item.typeOfWorkspace === workspaceType
        );
      });

      return unAssignedDevices.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    });
  }, [requestData]);

  return (
    <HandleRequestState state={loading} placeholder={<p>Loading...</p>}>
      <HandleRequestState
        state={!!error}
        placeholder={<p>Couldn't load existing devices, please try again!</p>}
      >
        <Select
          name={name}
          isClearable
          options={data}
          theme={selectStyles}
          onChange={(e) => {
            if (e === null) {
              onChange("");
              return;
            }

            onChange(e.value);
          }}
        />
        {invalid && (
          <FormFeedback className="invalid-feedback">
            {errorMessage}
          </FormFeedback>
        )}
      </HandleRequestState>
    </HandleRequestState>
  );
};
