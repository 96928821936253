import React from "react";
import cs from "classnames";
import img from "../../../../assets/images/features-group.png";

import "./WorkspacePlatformMessage.scss";

interface Props {
  isDefault?: boolean;
}

export const WorkspacePlatformMessage = ({ isDefault }: Props) => {
  const classes = cs("WorkspacePlatformMessage", {
    WorkspacePlatformMessage__primary: isDefault,
  });

  return (
    <div className={classes}>
      {isDefault ? (
        <>
          <h4>Workspace Platform</h4>
          <span>Bridges the gap between people and their workspace</span>

          <img src={img} alt="Features" />
        </>
      ) : (
        <>
          <h4>Magic is in the details!</h4>
          <span>Yes, it is indeed!</span>
        </>
      )}
    </div>
  );
};
