import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { EditTeamFormFields } from "../../../../Teams/interface/teams/teams";

const TeamSchema = Yup.object().shape({
  name: Yup.string().required("Please enter the name of the team"),
});

interface UseTeamFormOptions {
  initialValues?: EditTeamFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: EditTeamFormFields,
    formikHelpers: FormikHelpers<EditTeamFormFields>
  ) => Promise<any>;
}

export type EditTeamFormik = ReturnType<typeof useEditTeamFormik>;

export const useEditTeamFormik = (opts: UseTeamFormOptions) => {
  return useFormik<EditTeamFormFields>({
    initialValues: {
      name: "",
      location: "",
      company: "",
    },
    ...opts,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: TeamSchema,
    onSubmit: async (values, formikHelpers) => {
      await console.log(values, formikHelpers);
    },
  });
};
