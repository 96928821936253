import React, { useState } from "react";
import { Icon } from "../../../shared/Icon/Icon";
import { CustomerSettingsVariables } from "../InsightsCard/Insights/Insights";
import { WorkspaceCostModal } from "./WorkspaceCostModal";

interface Props {
  handleCostPerHr: ({
    meetingSpaceCostPerHr,
  }: CustomerSettingsVariables) => void;
  cost?: number;
  currency?: string;
  loading: boolean;
  error?: Error;
}

export const WorkspaceCost = ({
  handleCostPerHr,
  cost,
  currency,
  loading,
  error,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="flex-a-center cursor-pointer"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Icon icon="workspace-cost" />
        <h3>Workspace cost</h3>
      </div>

      {isOpen && (
        <WorkspaceCostModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          cost={cost}
          currency={currency}
          handleCostPerHr={handleCostPerHr}
          loading={loading}
          error={error}
        />
      )}
    </>
  );
};
