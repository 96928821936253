import React from "react";
import { RoomReleaseBehaviour } from "./RoomReleaseBehaviour/RoomReleaseBehaviour";
import { RoomReleaseSankey } from "./RoomReleaseSankey/RoomReleaseSankey";

export const RoomRelease = () => {
  return (
    <div className="RoomValueInsights WorkspaceValueInsights">
      <RoomReleaseBehaviour />

      <RoomReleaseSankey />
    </div>
  );
};
