import { useChannelPartnerRequests } from "../../../api/grpc/subscription/useChannelPartner";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { CBCustomer } from "../../../api/grpc/subscription/subscription";

export const usePartnerData = () => {
  const { getChannelPartner, createChannelPartner, deleteChannelPartner } =
    useChannelPartnerRequests();
  const { loading, error, data } = useGrpcQuery<{}, CBCustomer>({
    method: getChannelPartner,
    variables: {},
  });

  return { loading, error, data, createChannelPartner, deleteChannelPartner };
};
