import React from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { Status } from "../../shared/Status/Status";
import { trimText } from "../../../lib/helpers/trimText";
import { PeopleActions } from "../../shared/PeopleActions/PeopleActions";

import { Contact } from "../../../api/grpc/contacts/contacts";
import {
  Role,
  Status as StatusType,
} from "../../../api/grpc/contacts/account/account";

import {
  StructureTable,
  StructureTableColumn,
} from "../../shared/StructureTable/StructureTable";
import { Pagination } from "../../shared/Pagination/Pagination";
import { EntityType } from "../../../api/grpc/subscription/subscription";
import { LicenseSwitch } from "../../Workplaces/LicenseSwitch/LicenseSwitch";
import { PeopleRole } from "./PeopleRole";
import { IconDefinition } from "../../shared/Icon/Icon.generated";
import { LoginMethod } from "../../../api/grpc/account/account";

import "./PeopleTable.scss";

interface Props {
  contacts: Contact[];
  refetch: () => void;
}

const PeopleRoleType: {
  [key in LoginMethod]: IconDefinition | undefined;
} = {
  "0": undefined,
  "1": undefined,
  "2": "google_wp",
  "3": "microsoft-logo",
};

const columns: StructureTableColumn[] = [
  {
    key: "emailId",
    header: "Email ID",
  },
  {
    key: "name",
    header: "Name",
  },
  {
    key: "role",
    header: "Role",
  },
  {
    key: "desk",
    header: "Desk",
  },
  {
    key: "teams",
    header: "Teams",
  },

  {
    key: "status",
    header: "Status",
  },
  {
    key: "licensed",
    header: "Licensed",
  },
  {
    key: "actions",
    header: "",
  },
];

export const PeopleTable = ({ contacts, refetch }: Props) => {
  const { user } = useAuthContext();

  const rows = contacts.map((item) => {
    const isNotActive = item.status === StatusType.INACTIVE;
    const userName = item.firstName + " " + item.lastName;
    const isCurrentUser = item.id === user?.claims.user_id;
    const listOfDesks = item.desks.map((desk) => " " + desk.deskName);
    const hasSocialLogin =
      item.loginMethod === LoginMethod.GSUITE ||
      item.loginMethod === LoginMethod.O365;
    const isOwner = item.role === Role.OWNER;

    return {
      emailId: (
        <h4 className={isNotActive ? "inactive" : ""} title={item.email || "-"}>
          {trimText(item.email || "-", 35)}
        </h4>
      ),
      name: (
        <h4 className={isNotActive ? "inactive" : ""} title={userName}>
          {`${trimText(userName, 25)}`} {isCurrentUser ? "(You)" : ""}
        </h4>
      ),
      role: (
        <PeopleRole
          isNotActive={isNotActive}
          role={item.role}
          icon={hasSocialLogin ? PeopleRoleType[item.loginMethod] : undefined}
          loginMethod={item.loginMethod}
        />
      ),
      desk: (
        <h4 className={isNotActive ? "inactive" : ""} title={userName}>
          {trimText(String(listOfDesks) || "-", 22)}
        </h4>
      ),
      teams: <h4 className={isNotActive ? "inactive" : ""}>-</h4>,

      status: <Status status={item.status} />,
      licensed:
        item.role === Role.SUPPORT || item.role === Role.OWNER ? (
          <></>
        ) : (
          <LicenseSwitch
            id={item.id}
            value={item.licensed}
            type={EntityType.USER}
            refetch={refetch}
          />
        ),
      actions:
        isCurrentUser || isOwner ? <></> : <PeopleActions contact={item} />,
    };
  });

  return (
    <div className="PeopleTable">
      <StructureTable small columns={columns} rows={rows} />
      <Pagination />
    </div>
  );
};
