import { useApolloClient } from "@apollo/client";

import { SITE_FIELDS_FRAGMENT } from "../sites/sites";
import { COMPANY_FIELDS_FRAGMENT } from "../companies/companies";

import {
  GET_ROOT_WORKPLACES,
  GetWorkplacesResponse,
  WorkplaceRequestVariables,
} from "../workplaces";
import {
  ADD_BUILDING,
  UPDATE_BUILDING,
  UpdateBuildingResponse,
  AddBuildingResponse,
  BuildingRequestVariables,
  ADD_BUILDING_TO_COMPANY,
  ADD_BUILDING_TO_SITE,
  BUILDING_FIELDS_FRAGMENT,
} from "../buildings/buildings";

export const useBuildingsRequests = () => {
  const client = useApolloClient();

  const addBuildingToTenant = async (variables: BuildingRequestVariables) => {
    return await client.mutate<AddBuildingResponse, BuildingRequestVariables>({
      mutation: ADD_BUILDING,
      variables,
      update: (cache, newData) => {
        const previousQuery = cache.readQuery<
          GetWorkplacesResponse,
          WorkplaceRequestVariables
        >({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
        });

        const tenantResults = previousQuery?.queryTenant[0];
        const newBuilding = newData.data?.addBuilding.building[0];

        cache.writeQuery({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
          data: {
            queryTenant: [
              {
                ...tenantResults,
                buildings: [...(tenantResults?.buildings || []), newBuilding],
              },
            ],
          },
        });
      },
    });
  };

  const addBuildingToCompany = async (variables: BuildingRequestVariables) => {
    return await client.mutate<AddBuildingResponse, BuildingRequestVariables>({
      mutation: ADD_BUILDING_TO_COMPANY,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            buildings: [
              ...previousData.buildings,
              newData.data?.addBuilding.building[0],
            ],
          },
        });
      },
    });
  };

  const addBuildingToSite = async (variables: BuildingRequestVariables) => {
    return await client.mutate<AddBuildingResponse, BuildingRequestVariables>({
      mutation: ADD_BUILDING_TO_SITE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            buildings: [
              ...previousData.buildings,
              newData.data?.addBuilding.building[0],
            ],
          },
        });
      },
    });
  };

  const addBuilding = async (
    variables: BuildingRequestVariables,
    parentType?: string
  ) => {
    if (parentType === "Company") {
      return await addBuildingToCompany(variables);
    }

    if (parentType === "Site") {
      return await addBuildingToSite(variables);
    }

    return await addBuildingToTenant(variables);
  };

  const updateBuilding = async (variables: BuildingRequestVariables) => {
    return await client.mutate<
      UpdateBuildingResponse,
      BuildingRequestVariables
    >({
      mutation: UPDATE_BUILDING,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateBuilding.building[0],
          },
        });
      },
    });
  };

  return { addBuilding, updateBuilding };
};
