import { IconDefinition } from "../../../Icon/Icon.generated";

// we store in backend the value of direction as a number
// that represent the icon so we have defined this type:
export type DirectionNumberType = {
  1: IconDefinition;
  2: IconDefinition;
  3: IconDefinition;
  4: IconDefinition;
  5: IconDefinition;
  6: IconDefinition;
  7: IconDefinition;
  8: IconDefinition;
  9: IconDefinition;
  10: IconDefinition;
  11: IconDefinition;
  12: IconDefinition;
  13: IconDefinition;
  14: IconDefinition;
  15: IconDefinition;
  16: IconDefinition;
  17: IconDefinition;
  18: IconDefinition;
};

export const directionIcons: DirectionNumberType = {
  1: "stairs-up",
  2: "stairs-down",
  3: "arrow-up",
  4: "arrow-up-right",
  5: "arrow-up-left",
  6: "arrow-left",
  7: "arrow-down",
  8: "arrow-down-right",
  9: "arrow-down-left",
  10: "arrow-direction-right",
  11: "arrow-curl-up",
  12: "arrow-curl-up-right",
  13: "arrow-curl-up-left",
  14: "arrow-curl-right",
  15: "arrow-curl-right-half",
  16: "arrow-curl-left",
  17: "arrow-curl-down-right",
  18: "arrow-curl-down-left",
};
