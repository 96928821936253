import React from "react";

import { WorkplaceTypes } from "../AddWorkplaceButton/AddWorkplaceButton";

import { AddZoneModal } from "./AddZoneModal/AddZoneModal";
import { AddRoomModal } from "./AddRoomModal/AddRoomModal";
import { AddSiteModal } from "./AddSiteModal/AddSiteModal";
import { AddDeskModal } from "./AddDeskModal/AddDeskModal";
import { AddFloorModal } from "./AddFloorModal/AddFloorModal";
import { AddDeviceModal } from "./AddDeviceModal/AddDeviceModal";
import { AddCompanyModal } from "./AddCompanyModal/AddCompanyModal";
import { AddBuildingModal } from "./AddBuildingModal/AddBuildingModal";
import { AddWayFinderModal } from "./AddWayFinderModal/AddWayFinderModal";

export interface WorkplaceModalProps {
  isOpen: boolean;
  parentId?: string;
  toggle: () => void;
  type: WorkplaceTypes | null;
  parentType?: WorkplaceTypes;
  numberOfDeviceAdded?: number;
  refetch?: () => void;
}

interface ModalProps {
  type: WorkplaceTypes;
  component: React.FunctionComponent<WorkplaceModalProps>;
}

export const WorkplaceModals: ModalProps[] = [
  {
    type: "Company",
    component: AddCompanyModal,
  },
  {
    type: "Site",
    component: AddSiteModal,
  },
  {
    type: "Building",
    component: AddBuildingModal,
  },
  {
    type: "Floor",
    component: AddFloorModal,
  },
  {
    type: "Zone",
    component: AddZoneModal,
  },
  {
    type: "Room",
    component: AddRoomModal,
  },
  {
    type: "Desk",
    component: AddDeskModal,
  },
  {
    type: "Device",
    component: AddDeviceModal,
  },
  {
    type: "Wayfinder",
    component: AddWayFinderModal,
  },
];

// TO DO: Fix Warning: Can't perform a React state update on an unmounted component.
export const AddWorkplaceModal = (props: WorkplaceModalProps) => {
  const WorkplacesModal =
    WorkplaceModals.find((item) => item.type === props.type)?.component || null;

  return WorkplacesModal ? <WorkplacesModal {...props} /> : <></>;
};
