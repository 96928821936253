import React, { PropsWithChildren, useState } from "react";
import { toast } from "react-toastify";
import { FormGroup, Label } from "reactstrap";
import { useAccountRequests } from "../../../../api/grpc/account/useAccountRequests";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";

import { useLoginContext } from "../../../../lib/context/LoginContext/LoginContext";
import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import { Input } from "../../../shared/Input/Input";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const SecondaryEmailStep = ({
  email,
}: PropsWithChildren<{ email?: string }>) => {
  const [loading, setLoading] = useState(false);
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const { setMfaStep } = useLoginContext();
  const { recoveryEmail } = useAuthContext();
  const { sendRecoveryEmail } = useAccountRequests();

  const handleSendLink = async () => {
    try {
      if (!email?.length || !secondaryEmail.length) {
        return;
      }

      setLoading(true);

      await sendRecoveryEmail(email, secondaryEmail);

      setSuccess(true);
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Couldn't sent recovery link to your seconday email, please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="VerifyMfa">
      <div className="VerifyMfa__info">
        <span>
          If you have registered a secondary email we can send you a recovery
          link to unenroll from your two-factory authentication, whereafter you
          can sign in using basic authentication. Remember to add a new 2FA
          session after you have signed in.
        </span>
      </div>
      <FormGroup>
        <Label>
          Confirm secondary email{" "}
          {!!recoveryEmail.length ? `(${recoveryEmail})` : ""}
        </Label>
        <Input
          id="secondaryEmail"
          name="secondaryEmail"
          value={secondaryEmail}
          onChange={(e) => setSecondaryEmail(e.target.value)}
          className="VerifyMfa__input"
        />
        {success && (
          <div className="VerifyMfa__success mt-2 flex-a-center">
            <Icon icon="check-icon" />
            <span>
              Recovery link sent to your secondary email. You can now close this
              window.
            </span>
          </div>
        )}
      </FormGroup>

      <div className="VerifyMfa__buttons flex-a-center">
        <Button
          color="outline-primary"
          size="small"
          title="Back"
          onClick={() => setMfaStep("options")}
        />

        <Button
          color="primary"
          size="small"
          title="Send"
          onClick={handleSendLink}
          loadSpinner={loading}
          disabled={loading || !emailRegex.test(secondaryEmail)}
          loadTitle="Sending link"
        />
      </div>

      <div className="VerifyMfa__singleButton">
        <Button
          color="link"
          title="I don't have a secondary email set up"
          onClick={() => setMfaStep("requestDisable")}
        />
      </div>
    </div>
  );
};
