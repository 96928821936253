import React, { forwardRef } from "react";
import classNames from "classnames";

import { Icon } from "../../shared/Icon/Icon";
import { IconDefinition } from "../../shared/Icon/Icon.generated";

import "./FloorMapControlButton.scss";

type Props = {
  icon: IconDefinition;
  onClick?: () => void;
  className?: string;
};

export const FloorMapControlButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon, onClick, className }, ref) => {
    return (
      <button
        className={classNames("FloorMapControlButton", className)}
        ref={ref}
        onClick={onClick}
      >
        <Icon icon={icon} />
      </button>
    );
  }
);
