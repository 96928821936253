import React from "react";
import { Navbar } from "reactstrap";
import { NavLink } from "react-router-dom";

import { useCalendarContext } from "../../lib/context/Calendar/CalendarContext";
import { useSidebarContext } from "../../lib/context/Sidebar/SidebarContext";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useDirectoryContext } from "../DirectorySync/Context/DirectoryContext";

import { HeaderNavigation } from "./HeaderNavigation/HeaderNavigation";
import { PersonalCalendarValidation } from "../Calendars/PersonalCalendarValidation/PersonalCalendarValidation";
// import { Beta } from "../sherared/Beta/Beta";
import { AdminIntegrationBanner } from "../Calendars/AdminIntegrationBanner/AdminIntegrationBanner";
import { DirectoryIntegrationBanner } from "../DirectorySync/DirectoryIntegrationBanner/DirectoryIntegrationBanner";

import logo from "../../assets/images/logo.png";
import "./Header.scss";

export const Header = () => {
  const { toggleMainMenu, closeSidebar } = useSidebarContext();
  const { brokenProviders } = useCalendarContext();
  const { brokenIntegrations } = useDirectoryContext();
  const { user } = useAuthContext();

  const handleHamburgerToggle = () => {
    toggleMainMenu();
  };

  return (
    <div
      onClick={() => closeSidebar()}
      className="Header w-100 border-bottom p-0 pb-0"
    >
      {!["contact", "member"].includes(user?.claims.role) && (
        <>
          {brokenProviders.map((calendar) => (
            //admin integration error banners
            <AdminIntegrationBanner calendar={calendar} key={calendar.iD} />
          ))}
          {brokenIntegrations.map((directory) => (
            //directory integration error banners
            <DirectoryIntegrationBanner
              directory={directory}
              key={directory.iD}
            />
          ))}{" "}
        </>
      )}

      <PersonalCalendarValidation />
      <Navbar expand="md" className="Header__navbar">
        <span
          className="Header__hamburger"
          onClick={handleHamburgerToggle}
        ></span>
        <div className="Header__logo">
          <NavLink to="/">
            <img src={logo} alt="GOGET" />
            {/* <Beta /> */}
          </NavLink>
        </div>
        <HeaderNavigation />
      </Navbar>
    </div>
  );
};
