import React from "react";
import Select from "react-select";
import { selectStyles } from "../../../../lib/utils/selectStyles";

interface Props {
  value: number;
  fieldName: string;
  onChange: (field: string, value: number) => void;
}

const minutes = [
  { value: 0, label: "0 Minutes" },
  { value: 5, label: "5 Minutes" },
  { value: 10, label: "10 Minutes" },
  { value: 15, label: "15 Minutes" },
];

export const MinutesSelect = ({ fieldName, value, onChange }: Props) => {
  const hasValue = typeof value === "number";

  return (
    <Select
      isClearable
      options={minutes}
      theme={selectStyles}
      name={fieldName}
      onChange={(e) => {
        if (!e || e.value === undefined) {
          return;
        }

        onChange(fieldName, e.value);
      }}
      value={{
        label: `${hasValue ? value : 5} Minutes`,
        value: hasValue ? value : 5,
      }}
    />
  );
};
