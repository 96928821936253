import React, { Dispatch, SetStateAction, useState } from "react";

import { Switch } from "../../../../shared/Switch/Switch";
import { CustomerSettingsVariables } from "./Insights";
import { tooltips } from "../../../../../lib/utils/tooltips";
import { OrganizationSettingsModal } from "../../shared/OrganizationSettingsModal";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleInsightsCollect: ({
    collectInsights,
    showEventOrganizer,
  }: CustomerSettingsVariables) => void;
  collectData?: boolean;
  showEventOrganizer?: boolean;
  loading: boolean;
  error?: Error;
}

export const InsightsModal = ({
  isOpen,
  setIsOpen,
  handleInsightsCollect,
  collectData,
  showEventOrganizer,
  loading,
  error,
}: Props) => {
  const [collectDataToChange, setCollectDataToChange] = useState<
    boolean | undefined
  >(undefined);
  const [showEventOrganizerToChange, setShowEventOrganizerToChange] = useState<
    boolean | undefined
  >(undefined);

  return (
    <OrganizationSettingsModal
      title="Insights"
      formTitle="Manage data collection"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      formClassName="Insights"
      loading={loading}
      toggleModal={() => {
        setIsOpen(false);
        return;
      }}
      handleSave={() => {
        handleInsightsCollect({
          collectInsights: collectDataToChange,
          showEventOrganizer: showEventOrganizerToChange,
        });
        setIsOpen(false);
        return;
      }}
      error={error}
    >
      <Switch
        name="collectData"
        value={
          collectDataToChange !== undefined ? collectDataToChange : collectData
        }
        onChange={() => {
          setCollectDataToChange(
            collectDataToChange !== undefined
              ? !collectDataToChange
              : !collectData
          );
        }}
        title="Enable data collection of events"
        message={tooltips.account.enableDataCollection}
        className="mb-4"
      />

      <Switch
        name="showEventOrganizer"
        value={
          showEventOrganizerToChange !== undefined
            ? showEventOrganizerToChange
            : showEventOrganizer
        }
        onChange={() => {
          setShowEventOrganizerToChange(
            showEventOrganizerToChange !== undefined
              ? !showEventOrganizerToChange
              : !showEventOrganizer
          );
        }}
        title="Show organizer for events"
        message="message"
        className="mb-3"
      />
    </OrganizationSettingsModal>
  );
};
