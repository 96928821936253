import React from "react";
import iphoneImg from "../../../assets/images/iPhone-13.png";
import appstoreImg from "../../../assets/images/appstore.png";
import playstoreImg from "../../../assets/images/google-play.png";
import appStoreQR from "../../../assets/images/appstore-QR.png";
import googlePlayQR from "../../../assets/images/googleplay-QR.png";

import "./PromotionCard.scss";

export const PromotionCard = () => {
  return (
    <div className="PromotionCard">
      <img src={iphoneImg} alt="Promotion" className="PromotionCard__phone" />
      <h6>Download the mobile app for a better experience</h6>
      <div className="PromotionCard__store">
        <img src={appstoreImg} alt="App store" />
        <img src={playstoreImg} alt="Google play store" />
      </div>

      <div className="PromotionCard__qr">
        <img src={appStoreQR} alt="App store" />
        <img src={googlePlayQR} alt="Google play store" />
      </div>
    </div>
  );
};
