import React, { Dispatch, SetStateAction, useMemo } from "react";

import { useRoomInsightsContext } from "../../Context/Room/Overview/RoomInsightsContext";

import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { SearchBox } from "../../../shared/SearchBox/SearchBox";
import { InsightsExpandedRooms } from "./InsightsExpandedRooms";
import { GeneralStatsByRoomsResponse } from "../../../../api/grpc/insights/insights";

interface Props {
  searchValue: string;
  debouncedValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

export const InsightsAllRooms = ({
  debouncedValue,
  searchValue,
  setSearchValue,
}: Props) => {
  const {
    loadingRooms: loading,
    errorRooms: error,
    rooms: data,
  } = useRoomInsightsContext();

  const roomsData: GeneralStatsByRoomsResponse | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    if (!!!debouncedValue.length) {
      return data;
    }

    let rooms = data.data.filter((room) =>
      room.name.toLowerCase().includes(debouncedValue.toLowerCase())
    );

    return {
      customerID: data.customerID,
      data: rooms,
      endDate: data.endDate,
      startDate: data.startDate,
    };
  }, [debouncedValue, data]);

  return (
    <HandleLoadingState loading={loading} loadingPlaceholder={<LoadingBox />}>
      <HandleRequestState
        state={!!error}
        placeholder={<CenteredBox>{error?.message}</CenteredBox>}
      >
        {roomsData !== undefined && (
          <>
            <SearchBox
              searchValue={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              clearSearchValue={() => setSearchValue("")}
            />
            {!!debouncedValue.length && !!!roomsData.data.length ? (
              <CenteredBox>No room found</CenteredBox>
            ) : (
              <InsightsExpandedRooms
                data={roomsData}
                showPagination={!!!debouncedValue.length}
              />
            )}
          </>
        )}
      </HandleRequestState>
    </HandleLoadingState>
  );
};
