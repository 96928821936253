import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTemplatesClient } from "../../../../../../api/grpc/workplaces/useTemplatesClient";
import { useCustomerContext } from "../../../../../../lib/context/Customer/CustomerContext";
import { useTemplateContext } from "../../../../../../lib/context/Templates/Templates/TemplatesContext";
import { useRouter } from "../../../../../../lib/hooks/useRouter";
import {
  TEMPLATE_ROOT_PATH,
  WORKPLACES_ROOT_PATH,
} from "../../../../../../lib/routes/routes";

import { Button } from "../../../../../shared/Button/Button";
import { CheckboxButton } from "../../../../../shared/CheckboxButton/CheckboxButton";
import { collectSettingsFields } from "../../../../lib/collectSettingsFields";
import {
  roomCoreSettingsOptions,
  roomDisplaySettingsOptions,
} from "../../../../lib/roomSettingsOptions";

export type SettingsState = Record<string, boolean>;
type Option = { value: string };

export const RoomSettingsStep: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [coreSettings, setCoreSettings] = useState<SettingsState>({});
  const [mainSettings, setMainSettings] = useState<SettingsState>({});
  const [displaySettings, setDisplaySettings] = useState<SettingsState>({});
  const [reservationSettings, setReservationSettings] = useState<SettingsState>(
    {}
  );

  const { name, options } = roomCoreSettingsOptions();
  const { mainOptions, displayOptions, reservationOptions } =
    roomDisplaySettingsOptions();
  const { refetch: refetchWorkpaces } = useCustomerContext();
  const { applyRoomTemplate } = useTemplatesClient();
  const {
    pickedWorkspaces,
    activeProfile,
    setActiveProfile,
    setPickedWorkspaces,
  } = useTemplateContext();
  const { history } = useRouter();

  const updateState = (
    state: SettingsState,
    setState: React.Dispatch<React.SetStateAction<SettingsState>>,
    key: string,
    value: boolean
  ) => {
    const newState = { ...state };
    if (value) {
      newState[key] = true;
    } else {
      delete newState[key];
    }
    setState(newState);
  };

  const handleCheckboxChange = (
    category: "core" | "main" | "display" | "reservation",
    key: string,
    value: boolean
  ) => {
    switch (category) {
      case "core":
        updateState(coreSettings, setCoreSettings, key, value);
        break;
      case "main":
        updateState(mainSettings, setMainSettings, key, value);
        break;
      case "display":
        updateState(displaySettings, setDisplaySettings, key, value);
        break;
      case "reservation":
        updateState(reservationSettings, setReservationSettings, key, value);
        break;
      default:
        break;
    }
  };

  const toggleSelectAll = (active: boolean) => {
    setSelectAll((prev) => !prev);

    const applyToAll = (settingsOptions: Option[]): SettingsState => {
      if (active) {
        return settingsOptions.reduce(
          (acc, { value }) => ({ ...acc, [value]: true }),
          {}
        );
      } else {
        return {};
      }
    };

    setCoreSettings(applyToAll(options));
    setMainSettings(applyToAll(mainOptions.options));
    setDisplaySettings(applyToAll(displayOptions.options));
    setReservationSettings(applyToAll(reservationOptions.options));
  };

  const handleApplyRoom = async () => {
    try {
      if (!activeProfile) {
        toast.error("Room profile not found!");
        return;
      }

      setLoading(true);

      const roomSettingsFields = collectSettingsFields(coreSettings, options);
      const mainSettingsFields = collectSettingsFields(
        mainSettings,
        mainOptions.options
      );
      const displaySettingsFields = collectSettingsFields(
        displaySettings,
        displayOptions.options
      );
      const reservationSettingsFields = collectSettingsFields(
        reservationSettings,
        reservationOptions.options
      );

      await applyRoomTemplate({
        roomsId: pickedWorkspaces.data.map((workspace) => workspace.id),
        templateId: activeProfile.value,
        roomSettingsFields: roomSettingsFields,
        displaySettingsFields: [
          ...mainSettingsFields,
          ...displaySettingsFields,
          ...reservationSettingsFields,
        ],
      });

      await refetchWorkpaces();

      if (pickedWorkspaces.redirect) {
        history.push(
          `${WORKPLACES_ROOT_PATH}/room/${pickedWorkspaces.data[0].id}`
        );
      } else {
        history.push(TEMPLATE_ROOT_PATH);
      }

      setActiveProfile(undefined);
      setPickedWorkspaces({
        data: [],
        redirect: undefined,
      });

      toast.success("Room template settings transferred successfully.");
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-around flex-wrap gap-2">
        <div className="w-25-large">
          <h6 className="border-bottom pb-2 mb-3">Room settings</h6>

          <span className="SelectSettingsStep__subTitle">{name}</span>

          <div className="mt-3">
            {options.map(({ name, value }, index) => (
              <CheckboxButton
                key={value + "-" + index}
                id={value}
                name={name}
                checked={coreSettings[value]}
                onChange={() => {
                  return handleCheckboxChange(
                    "core",
                    value,
                    !coreSettings[value]
                  );
                }}
              />
            ))}
          </div>
        </div>

        <div>
          <h6 className="border-bottom pb-2 mb-3">Display settings</h6>

          <div className="d-flex flex-wrap gap-3">
            <div>
              <span className="SelectSettingsStep__subTitle">
                {mainOptions.name}
              </span>

              <div className="mt-3">
                {mainOptions.options.map(({ name, value }, index) => (
                  <CheckboxButton
                    key={value + "-" + index}
                    id={value}
                    name={name || ""}
                    checked={mainSettings[value]}
                    onChange={() => {
                      return handleCheckboxChange(
                        "main",
                        value,
                        !mainSettings[value]
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            <div>
              <span className="SelectSettingsStep__subTitle">
                {displayOptions.name}
              </span>

              <div className="mt-3">
                {displayOptions.options.map(({ name, value }, index) => (
                  <CheckboxButton
                    key={value + "-" + index}
                    id={value}
                    name={name || ""}
                    checked={displaySettings[value]}
                    onChange={() => {
                      return handleCheckboxChange(
                        "display",
                        value,
                        !displaySettings[value]
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            <div>
              <span className="SelectSettingsStep__subTitle">
                {reservationOptions.name}
              </span>

              <div className="mt-3">
                {reservationOptions.options.map(({ name, value }, index) => (
                  <CheckboxButton
                    key={value + "-" + index}
                    id={value}
                    name={name || ""}
                    checked={reservationSettings[value]}
                    onChange={() => {
                      handleCheckboxChange(
                        "reservation",
                        value,
                        !reservationSettings[value]
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button
        size="small"
        color="primary"
        title="Apply"
        className="ml-3 SelectSettingsStep__submit"
        disabled={
          loading ||
          (Object.keys(coreSettings).length <= 0 &&
            Object.keys({
              ...mainSettings,
              ...displaySettings,
              ...reservationSettings,
            }).length <= 0)
        }
        onClick={handleApplyRoom}
      />

      <Button
        title={selectAll ? "Unselect all" : "Select all"}
        size="small"
        color="outline-primary"
        className="SelectSettingsStep__selector"
        onClick={() => toggleSelectAll(!selectAll)}
      />
    </>
  );
};
