import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCustomerRequests } from "../../../api/grpc/customer/useCustomerRequests";
import { useContactSalesFormik } from "../../Billings/lib/useContactSalesFormik";
import { ContactSalesForm } from "../../ContactSales/ContactSalesForm";
import ModalSidebar from "../../Modals/ModalSidebar/ModalSidebar";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const LeaveFeedbackModal = ({ isOpen, toggle }: Props) => {
  const [loading, setLoading] = useState(false);
  const { leaveFeedback } = useCustomerRequests();

  const formik = useContactSalesFormik({
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const { name, email, message } = values;

        await leaveFeedback({ name, email, feebackBody: message });
        toast.success(
          "We love hearing from you! Thank you for leaving feedback for us"
        );
        toggle();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error.message);
        console.log(error.message);
      }
    },
  });

  //for feedback form we have re-used contact sales form since they have same fields
  return (
    <>
      <ModalSidebar
        title="Send feedback"
        isOpen={isOpen}
        loading={loading}
        onToggle={toggle}
        className="ModalSidebar--contact ModalSidebar--middle"
        submitButtonLabel="Leave feedback"
        onSubmit={formik.handleSubmit}
      >
        <ContactSalesForm
          formik={formik}
          bodyLabelText="We'd love to hear your feedback:"
        />
      </ModalSidebar>
    </>
  );
};
