import React, { ReactNode, useEffect } from "react";
import cs from "classnames";

import { useSidebarContext } from "../../../lib/context/Sidebar/SidebarContext";

import "./PageSidebar.scss";

interface Props {
  className?: string;
  children: ReactNode;
  showOnDesktop?: boolean;
  showOnInitialization?: boolean;
  hideToggler?: boolean;
  alwaysShowToggler?: boolean;
}

export const PageSidebar = (props: Props) => {
  const { togglePageSidebar, visiblePageSidebar, openPageSidebar } =
    useSidebarContext();

  useEffect(() => {
    if (props.showOnInitialization) {
      openPageSidebar();
    }
  }, []);

  const classes = cs("PageSidebar", props.className, "d-flex flex-column", {
    "PageSidebar--toggled": visiblePageSidebar || props.showOnInitialization,
    "PageSidebar--desktop": props.showOnDesktop,
    "PageSidebar--hide": props.hideToggler,
  });

  const togglerClasses = cs("PageSidebar__mobile_toggler", {
    "d-none": props.hideToggler,
    "PageSidebar__mobile_toggler--show": props.alwaysShowToggler,
  });

  return (
    <div className={classes}>
      <div className={togglerClasses} onClick={togglePageSidebar}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="PageSidebar__items">{props.children}</div>
    </div>
  );
};
