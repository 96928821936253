import React, { PropsWithChildren } from "react";
import { GeneralStatsResponse } from "../../../../api/grpc/insights/insights";
import { tooltips } from "../../../../lib/utils/tooltips";
import { ChartCard } from "../ChartCard/ChartCard";
import { NoShowsPieChart } from "../Charts/PieChart/NoShowsPieChart";
import { calculatePercentage } from "../lib/calculatePercentageOfTwoNumber";
import { formatMinutesAsHours } from "../lib/convertMinutesToHours";
import { MeetingsAverageRow } from "../MeetingsAverageRow/MeetingsAverageRow";
import { RecuperationItem } from "../RecuperationItem/RecuperationItem";
import { MeetingSpaceCost } from "./MeetingSpaceCost/MeetingSpaceCost";

export const OverviewGeneralStats = ({
  data,
}: PropsWithChildren<{
  data?: GeneralStatsResponse;
}>) => {
  return (
    <div className="WorkspaceValueInsights__statistics WorkspaceValueInsights__statistics--main">
      <ChartCard className="d-flex justify-content-between ChartCard__adjustable">
        <MeetingsAverageRow
          title="Meetings"
          value={data?.totalMeetings || 0}
          description="Total nr of meetings"
          percentage={
            data?.prevPeriodIncluded
              ? calculatePercentage(
                  data?.prevPeriodTotalMeetings || 0,
                  data?.totalMeetings || 0
                )
              : undefined
          }
        />

        <div className="divider"></div>

        <MeetingsAverageRow
          title="Frequency"
          value={Math.round(data?.avgMeetings || 0)}
          description="Avg. daily meetings"
          percentage={
            data?.prevPeriodIncluded
              ? calculatePercentage(
                  data?.prevPeriodAVGMeetings || 0,
                  data?.avgMeetings || 0
                )
              : undefined
          }
        />
      </ChartCard>

      <ChartCard className="d-flex justify-content-between ChartCard__adjustable">
        <MeetingsAverageRow
          title="Meeting Time"
          value={formatMinutesAsHours(data?.totalMinutes || 0)}
          description="Total hours"
          percentage={
            data?.prevPeriodIncluded
              ? calculatePercentage(
                  data?.prevPeriodTotalMinutes || 0,
                  data?.totalMinutes || 0
                )
              : undefined
          }
        />

        <div className="divider"></div>

        <MeetingsAverageRow
          title="Avg. Duration"
          value={Math.round(data?.avgMinutes || 0)}
          description="Minutes per meeting"
          percentage={
            data?.prevPeriodIncluded
              ? calculatePercentage(
                  data?.prevPeriodAVGMinutes || 0,
                  data?.avgMinutes || 0
                )
              : undefined
          }
        />
      </ChartCard>
      <ChartCard className="flex-a-center justify-content-between">
        <MeetingsAverageRow
          title="No-Shows"
          titleDescription="Check-in expired"
          message={tooltips.insights.rooms.noShows}
          value={Math.round(data?.noShowsMinutes || 0)}
          description="Hours accumulated"
          className="align-self-start"
          direction="right"
        />

        <NoShowsPieChart
          value={Math.round(data?.noShows || 0)}
          type="meetings"
        />
      </ChartCard>

      <ChartCard
        title="Reclaimed"
        message={tooltips.insights.rooms.reclaimed}
        direction="right"
        className="position-relative"
        adjustInfoRight
      >
        <div className="flex-a-center flex-justify-evenly pt-2">
          <RecuperationItem
            icon="clock-icon"
            title={formatMinutesAsHours(data?.recuperatedMinutes || 0) + " h"}
            className="RecuperationItem__time"
          />
          <RecuperationItem
            icon="dollar-sign"
            title={Math.round(data?.recuperatedMoney || 0) + " $"}
            className="RecuperationItem__estimate"
          />
        </div>

        <MeetingSpaceCost />
      </ChartCard>
    </div>
  );
};
