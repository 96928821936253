import React from "react";
import {
  WorkspaceCostData,
  WorkspaceCostInfoRow,
} from "./WorkspaceCostInfoRow";

const infoData: WorkspaceCostData[] = [
  {
    step: "Calculation steps",
    value: "Values",
    definition: "Definitions",
  },
  {
    step: "Total rent per year",
    stepMark: "A.",
    value: "100.000 (USD)",
    definition: "",
  },
  {
    step: "Meeting space ratio",
    stepMark: "B.",
    value: "40%",
    definition: "Meeting space area/total area",
  },
  {
    step: "Yearly cost meeting spaces",
    stepMark: "C.",
    value: "40.000 (USD)",
    definition: "A*B",
  },
  {
    step: "Work hours per year",
    stepMark: "D.",
    value: "2000 h",
    definition: "Net work hours/year",
  },
  {
    step: "Meeting space cost/work hour",
    stepMark: "arrow-right",
    value: "20 USD/h",
    definition: "C/D. Input this value below",
  },
];

export const WorkspaceCostInfo = () => {
  return (
    <div className="WorkspaceCostInfo">
      {infoData.map((info) => (
        <WorkspaceCostInfoRow
          key={info.value}
          step={info.step}
          stepMark={info.stepMark}
          value={info.value}
          definition={info.definition}
        />
      ))}
    </div>
  );
};
