import React, { PropsWithChildren } from "react";

export const NameAndValueRow = ({
  name,
  value,
}: PropsWithChildren<{
  name: string;
  value: string | number;
}>) => {
  return (
    <div className="NameAndValueRow">
      <span>{name}</span>: {value}
    </div>
  );
};
