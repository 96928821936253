import { Status } from "../../../api/grpc/account/account";
import { Role } from "../../../api/grpc/contacts/account/account";
import {
  ContactsListResponse,
  NullableBoolean,
} from "../../../api/grpc/contacts/contacts";
import {
  GetContactsVariables,
  useContacts,
} from "../../../api/grpc/contacts/useContacts";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";

interface ContactsParams {
  page: number;
  pageSize: number;
  name?: string;
  role?: Role[];
  status?: Status[];
  licensed?: NullableBoolean;
  desks?: NullableBoolean;
}

export const useGetContacts = ({
  page,
  pageSize,
  name,
  role,
  status,
  licensed,
  desks,
}: ContactsParams) => {
  const { getContacts } = useContacts();

  let hidePagination =
    !!name?.length ||
    !!role?.length ||
    !!status?.length ||
    licensed !== NullableBoolean.NULL ||
    desks !== NullableBoolean.NULL;

  const { loading, error, data, refetch } = useGrpcQuery<
    GetContactsVariables,
    ContactsListResponse
  >({
    method: getContacts,
    variables: {
      page: page,
      pageSize: hidePagination ? 0 : pageSize,
      name: name ?? "",
      role: role ?? [],
      status: status ?? [],
      licensed: licensed ?? NullableBoolean.NULL,
      desks: desks ?? NullableBoolean.NULL,
    },
  });

  return { loading, error, data, refetch };
};
