import React, { PropsWithChildren, useState } from "react";
import { Button } from "../Button/Button";
import { TagList } from "./TagList";

export const TagPicker = ({
  id,
  activeTagsFilter,
  setActiveTagsFilter,
}: PropsWithChildren<{
  id: string;
  activeTagsFilter: string[];

  setActiveTagsFilter: React.Dispatch<React.SetStateAction<string[]>>;
}>) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        title="Tags"
        color={!!activeTagsFilter.length ? "primary" : "outline-primary"}
        className="child-element--clear"
        onClick={() => setOpen((prev) => !prev)}
      />

      {open && (
        <TagList
          id={id}
          setActiveTagsFilter={setActiveTagsFilter}
          activeTagsFilter={activeTagsFilter}
          closeModal={() => setOpen(false)}
        />
      )}
    </div>
  );
};
