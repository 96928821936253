import React, { PropsWithChildren } from "react";
import { UtilizationData } from "../UtilizationOccupancyByDayOfWeek/UtilizationOccupancyByDayOfWeek";
import { ResponsiveBarChart } from "./ResponsiveBarChart";

export const UtilizationOccupancyMostLeast = ({
  data,
}: PropsWithChildren<{
  data: UtilizationData[];
}>) => {
  let sortedData = data.sort((room1, room2) =>
    room1.value < room2.value ? 1 : room1.value > room2.value ? -1 : 0
  );

  let definedData: {
    id: string;
    name: string;
    value: number;
    color: string;
  }[] = [];

  if (sortedData.length <= 4) {
    definedData = sortedData.map((item) => {
      return {
        id: item.id,
        value: Math.round(item.value),
        name: item.name + "-innerGoGetIDSplitter-" + item.id,
        color: "#29C0A2",
      };
    });
  } else {
    let topOccupiedData = sortedData.slice(0, 3).map((item) => {
      return {
        id: item.id,
        value: Math.round(item.value),
        name: item.name + "-innerGoGetIDSplitter-" + item.id,
        color: "#29C0A2",
      };
    });

    let leastOccupiedData = sortedData
      .slice(Math.max(sortedData.length - (sortedData.length === 5 ? 2 : 3), 0))
      .map((item) => {
        return {
          id: item.id,
          value: Math.round(item.value),
          name: item.name + "-innerGoGetIDSplitter-" + item.id,
          color: "#E24A1A",
        };
      });

    definedData = [...topOccupiedData, ...leastOccupiedData].map((item) => {
      return {
        id: item.id,
        value: Math.round(item.value),
        name: item.name,
        color: item.color,
      };
    });
  }

  return (
    <ResponsiveBarChart data={definedData} dataLength={sortedData.length} />
  );
};
