import React, { PropsWithChildren } from "react";
import { ResponsiveSankey } from "@nivo/sankey";

export const SankeyChart = ({
  links,
  nodes,
  type,
}: PropsWithChildren<{
  links: { source: string; target: string; value: number }[];
  nodes: { id: string }[];
  type: "Room" | "Desk";
}>) => {
  const sortedLinks = [...links].sort((a, b) => a.value - b.value);

  return (
    <>
      <ResponsiveSankey
        data={{
          nodes: nodes,
          links: sortedLinks,
        }}
        margin={{ top: 40, right: 50, bottom: 70, left: 50 }}
        align="justify"
        colors={{ scheme: "category10" }}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={17}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeInnerPadding={2}
        nodeBorderColor={{
          from: "color",
          modifiers: [["darker", 0.8]],
        }}
        nodeBorderRadius={0}
        linkOpacity={0.4}
        linkHoverOthersOpacity={0.1}
        linkContract={3}
        enableLinkGradient={true}
        label={(e) => {
          return e.id.split("-innerGoGetIDSplitter-")[0];
        }}
        labelPosition="outside"
        labelOrientation="vertical"
        labelPadding={16}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1]],
        }}
        theme={{
          tooltip: {
            container: {
              borderRadius: 5,
            },
            chip: {
              borderRadius: 50,
            },
          },
        }}
        linkTooltip={({ link }) => (
          <div className="RoomReleaseSankey__chart flex-a-center">
            <div
              className="RoomReleaseSankey__chart--square"
              style={{
                backgroundColor: link.source.color,
              }}
            ></div>
            <span>{link.source.label + " > "} </span>
            <span className="pl-1">{link.target.label}</span>
            <div
              className="RoomReleaseSankey__chart--square"
              style={{
                backgroundColor: link.target.color,
              }}
            ></div>
            <span className="pl-1">{link.formattedValue}</span>
          </div>
        )}
      />

      <div
        className={`RoomReleaseSankey__text RoomReleaseSankey__text--flow ${
          nodes.length > 7 ? "RoomReleaseSankey__text--flow--max" : ""
        }`}
      >
        {type} booking flow across workspaces
      </div>
      <div
        className={`RoomReleaseSankey__text RoomReleaseSankey__text--info
     ${nodes.length > 7 ? "RoomReleaseSankey__text--info--max" : ""} 
    `}
      >
        Powered by GOGET
      </div>
    </>
  );
};
