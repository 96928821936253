import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { useRouter } from "../../../lib/hooks/useRouter";
import { TEMPLATE_ROOT_PATH } from "../../../lib/routes/routes";

import { Button } from "../../shared/Button/Button";

export const CancelProfileButton = ({
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  const { history } = useRouter();

  return (
    <Button
      title="Cancel"
      color="outline-secondary"
      size="small"
      className={cs(className)}
      onClick={() => history.push(TEMPLATE_ROOT_PATH)}
    />
  );
};
