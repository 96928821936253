import React, { PropsWithChildren } from "react";
import { Spinner } from "reactstrap";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import {
  useLoginContext,
  VerifyMfaKeys,
} from "../../../lib/context/LoginContext/LoginContext";
import { AuthenticationPage } from "../../shared/AuthenticationPage/AuthenticationPage";
import { RecoverMfaOptions } from "./Steps/RecoverMfaOptions";
import { RequestDisableMfa } from "./Steps/RequestDisableMfa";
import { SecondaryEmailStep } from "./Steps/SecondaryEmailStep";
import { VerifyMfa } from "./Steps/VerifyMfa";

interface ExpandedProps {
  email?: string;
}

const component: {
  [key in VerifyMfaKeys]: {
    component: React.FunctionComponent<ExpandedProps>;
    title: string;
  };
} = {
  options: {
    component: RecoverMfaOptions,
    title: "Authentication options",
  },
  verifyCode: {
    component: VerifyMfa,
    title: "Sign in by using the authenticator app",
  },
  secondaryEmail: {
    component: SecondaryEmailStep,
    title: "Use secondary email",
  },
  requestDisable: {
    component: RequestDisableMfa,
    title: "Request removal of two-factor authentication",
  },
};

export const StartVerifyMfa = ({
  primaryEmail,
}: PropsWithChildren<{
  primaryEmail: string;
}>) => {
  const { ssoLoading } = useAuthContext();
  const { mfaStep } = useLoginContext();
  const Component = component[mfaStep as VerifyMfaKeys].component;

  let componentProps: ExpandedProps = {};

  if (mfaStep === "secondaryEmail") {
    componentProps.email = primaryEmail;
  }

  return (
    <AuthenticationPage
      isDefault={true}
      formTitle={component[mfaStep as VerifyMfaKeys].title}
      formContentClassname={
        ssoLoading
          ? "flex-column-reverse align-items-center justify-content-center"
          : "mt-4"
      }
    >
      <Spinner color="success" className={!ssoLoading ? "d-none" : ""} />

      <div className={ssoLoading ? "d-none" : ""}>
        <Component {...componentProps} />
      </div>
    </AuthenticationPage>
  );
};
