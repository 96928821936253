import React from "react";
import { BookingOptions } from "../../components/Bookings/Reserve/BookingsOptions/BookingOptions";
import { ReserveCard } from "../../components/Bookings/Reserve/ReserveCard";

export const BookingsMain = () => {
  return (
    <ReserveCard type="main">
      <BookingOptions />
    </ReserveCard>
  );
};
