import React from "react";
import { differenceInDays, format } from "date-fns";
import { useBookingsMapContext } from "../../Bookings/BookingsMap/Context/BookingsMapContext";
import { usePaginationHelper } from "../../../lib/hooks/usePaginationHelper";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime } from "date-fns-tz";
import { PaginationContext } from "../../../lib/context/Pagination/PaginationContext";
import { Pagination } from "../../shared/Pagination/Pagination";
import { StructureTable } from "../../shared/StructureTable/StructureTable";
import { FloorChildrenEntity } from "./FloorChildrenList";

interface Props {
  data: FloorChildrenEntity[];
  onClose: () => void;
  typeOfMap?: "bookings" | "floor";
}

export const FloorDeskExpandedList = ({ data, onClose, typeOfMap }: Props) => {
  const { page, pageSize, pagination } = usePaginationHelper(data.length);
  const { timeZone } = useTimeZoneContext();
  const { day } = useBookingsMapContext();

  const indexOfLastItem = page * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentDeskData = data?.slice(indexOfFirstItem, indexOfLastItem);

  const columns = [
    { key: "name", header: "Name", onClick: () => onClose() },
    { key: "add", header: typeOfMap === "bookings" ? "Reserve" : "Add" },
    { key: "location", header: "" },
  ];

  typeOfMap === "bookings" &&
    columns.splice(1, 0, { key: "freeUntil", header: "Free until" });

  const rows = currentDeskData?.map((item: FloorChildrenEntity) => {
    return {
      name: (
        <div className={`${item.location?.length ? "name__adjust" : ""}`}>
          {item.name}
        </div>
      ),
      add: item.add,
      location: item.location,

      freeUntil: (
        <div className="my-0 mx-auto">
          {item?.nextReservation && item.nextReservation[0]?.isAllDay === true
            ? "All day"
            : item.isBooked
            ? "-"
            : item.nextReservation !== undefined &&
              item.nextReservation.length > 0
            ? format(
                utcToZonedTime(
                  new Date(item.nextReservation[0]?.startTime),
                  timeZone
                ),
                differenceInDays(
                  utcToZonedTime(new Date(day), timeZone),
                  utcToZonedTime(
                    new Date(item.nextReservation[0]?.startTime),
                    timeZone
                  )
                ) === 0
                  ? "HH:mm"
                  : "dd MMM HH:mm"
              )
            : "00:00"}
        </div>
      ),
    };
  });

  return (
    <PaginationContext.Provider value={pagination}>
      <StructureTable rows={rows} columns={columns} />
      <Pagination />
    </PaginationContext.Provider>
  );
};
