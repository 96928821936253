import { routes } from "./routes";

export const getSecondLevelRoutes = () => {
  const list = routes.map((item) => {
    if (!item.menu) {
      return [];
    }

    return {
      parent: item.id,
      parentPath: item.to,
      menu: item.menu,
    };
  });

  return list.flat();
};
