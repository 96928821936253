import L from "leaflet";

type WayfinderIconParams = {
  width: number;
};

const SIZE_RATIO = 1.38;

const WayfinderIcon = ({ width }: WayfinderIconParams) =>
  new L.DivIcon({
    html: `
      <svg viewBox="0 0 88 122" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44 103.428L39.5474 107.45L44 112.38L48.4526 107.45L44 103.428ZM44 103.428C48.4526 107.45 48.4534 107.449 48.4543 107.448L48.4567 107.446L48.4638 107.438L48.4865 107.413L48.5661 107.324C48.634 107.248 48.7314 107.138 48.8563 106.997C49.1061 106.714 49.4662 106.303 49.9207 105.776C50.8293 104.721 52.1174 103.198 53.6587 101.299C56.7361 97.5071 60.849 92.1836 64.9738 86.07C69.0871 79.9735 73.2864 72.9826 76.4756 65.8637C79.6296 58.8235 82 51.214 82 44C82 33.9217 77.9964 24.2563 70.8701 17.1299C63.7437 10.0036 54.0782 6 44 6C33.9218 6 24.2563 10.0036 17.1299 17.1299C10.0036 24.2563 6 33.9217 6 44C6 51.214 8.37037 58.8235 11.5244 65.8637C14.7136 72.9826 18.9129 79.9735 23.0262 86.07C27.151 92.1836 31.2639 97.5071 34.3413 101.299C35.8826 103.198 37.1707 104.721 38.0794 105.776C38.5338 106.303 38.8939 106.714 39.1437 106.997C39.2686 107.138 39.366 107.248 39.4339 107.324L39.5135 107.413L39.5362 107.438L39.5433 107.446L39.5457 107.448C39.5466 107.449 39.5474 107.45 44 103.428Z" fill="white" stroke="white" stroke-width="12"/>
        <path d="M44 55.4285C40.969 55.4285 38.0621 54.2245 35.9188 52.0812C33.7755 49.9379 32.5714 47.031 32.5714 44C32.5714 40.9689 33.7755 38.062 35.9188 35.9188C38.0621 33.7755 40.969 32.5714 44 32.5714C47.031 32.5714 49.9379 33.7755 52.0812 35.9188C54.2245 38.062 55.4286 40.9689 55.4286 44C55.4286 45.5008 55.133 46.9869 54.5586 48.3735C53.9843 49.7601 53.1425 51.0199 52.0812 52.0812C51.02 53.1424 49.7601 53.9842 48.3735 54.5586C46.9869 55.1329 45.5008 55.4285 44 55.4285ZM44 12C35.5131 12 27.3737 15.3714 21.3726 21.3726C15.3714 27.3737 12 35.513 12 44C12 67.9999 44 103.428 44 103.428C44 103.428 76 67.9999 76 44C76 35.513 72.6286 27.3737 66.6274 21.3726C60.6263 15.3714 52.4869 12 44 12Z" fill="#FF0000"/>
      </svg>
    `,
    iconSize: [width, width * SIZE_RATIO],
    iconAnchor: [width / 2, width * SIZE_RATIO],
    className: "",
  });

export { WayfinderIcon };
