import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTemplatesClient } from "../../../../../../api/grpc/workplaces/useTemplatesClient";
import { useCustomerContext } from "../../../../../../lib/context/Customer/CustomerContext";
import { useTemplateContext } from "../../../../../../lib/context/Templates/Templates/TemplatesContext";
import { useRouter } from "../../../../../../lib/hooks/useRouter";
import {
  TEMPLATE_ROOT_PATH,
  WORKPLACES_ROOT_PATH,
} from "../../../../../../lib/routes/routes";
import { Button } from "../../../../../shared/Button/Button";
import { CheckboxButton } from "../../../../../shared/CheckboxButton/CheckboxButton";
import { deskSettingsOptions } from "../../../../lib/deskSettingsOption";

type SettingsState = Record<string, boolean>;

export const DeskSettingsStep = () => {
  const [loading, setLoading] = useState(false);
  const [coreSettings, setCoreSettings] = useState<SettingsState>({});
  const { options } = deskSettingsOptions();
  const {
    pickedWorkspaces,
    activeProfile,
    setActiveProfile,
    setPickedWorkspaces,
  } = useTemplateContext();
  const { applyDeskTemplate } = useTemplatesClient();
  const { refetch: refetchWorkspaces } = useCustomerContext();
  const { history } = useRouter();

  const handleApplyDesk = async () => {
    try {
      if (!activeProfile) {
        toast.error("Desk profile not found!");
        return;
      }

      setLoading(true);

      let deskSettingsFields: string[] = [];

      options.forEach((option) => {
        if (coreSettings[option.value]) {
          deskSettingsFields.push(option.value);
          if (option.children) {
            deskSettingsFields.push(...option.children);
          }
        }
      });

      await applyDeskTemplate({
        desksId: pickedWorkspaces.data.map((workspace) => workspace.id),
        templateId: activeProfile.value,
        deskSettingsFields,
      });

      await refetchWorkspaces();

      if (pickedWorkspaces.redirect) {
        history.push(
          `${WORKPLACES_ROOT_PATH}/desk/${pickedWorkspaces.data[0].id}`
        );
      } else {
        history.push(TEMPLATE_ROOT_PATH);
      }

      setActiveProfile(undefined);
      setPickedWorkspaces({
        data: [],
        redirect: undefined,
      });

      toast.success("Desk template settings transferred successfully.");
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-25-large m-auto">
      <h6 className="border-bottom pb-2 mb-3">Desk settings</h6>

      <div className="mt-3">
        {options.map(({ name, value }, index) => (
          <CheckboxButton
            key={value + "-" + index}
            id={value}
            name={name}
            checked={coreSettings[value]}
            onChange={() => {
              const newSettings = { ...coreSettings };

              if (newSettings[value]) {
                delete newSettings[value];
              } else {
                newSettings[value] = true;
              }
              setCoreSettings(newSettings);
            }}
          />
        ))}
      </div>

      <Button
        size="small"
        color="primary"
        title="Apply"
        className="ml-3 SelectSettingsStep__submit"
        disabled={loading || Object.keys(coreSettings).length === 0}
        onClick={handleApplyDesk}
      />

      <Button
        title={
          Object.keys(coreSettings).length === options.length
            ? "Unselect all"
            : "Select all"
        }
        size="small"
        color="outline-primary"
        className="SelectSettingsStep__selector"
        onClick={() => {
          if (Object.keys(coreSettings).length) {
            setCoreSettings({});

            return;
          }

          const newSettings = options.reduce((settings, option) => {
            settings[option.value] = true;
            return settings;
          }, {} as SettingsState);
          setCoreSettings(newSettings);
        }}
      />
    </div>
  );
};
