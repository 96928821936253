import gql from "graphql-tag";

import { DESK_FIELDS_FRAGMENT } from "../desks/desks";
import { ROOM_FIELDS_FRAGMENT } from "../rooms/rooms";
import { WAY_FINDER_FIELDS_FRAGMENT } from "../wayfinder/wayfinder";

import {
  WorkplaceFields,
  WorkplaceTenantFields,
  WorkplaceDefaultFields,
} from "../workplaces";

export interface ZoneFields extends WorkplaceDefaultFields {
  rooms?: WorkplaceFields[];
  desks?: WorkplaceFields[];
  wayfinders?: WorkplaceFields[];
}

export interface UpdateZoneResponse {
  updateZone: {
    zone: ZoneFields[];
  };
}

export interface ZoneRequestVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
  remove?: {
    tags: string[];
  };
}

export interface AddZoneVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  tags: string[];
  description: string;
}

export interface AddZoneResponse {
  addZone: {
    zone: ZoneFields[];
  };
}

export const ZONE_FIELDS_FRAGMENT = gql`
  fragment ZoneFields on Zone {
    id
    name
    tags
    description
    rooms(order: { asc: name }) {
      ...RoomFields
    }
    desks(order: { asc: name }) {
      ...DeskFields
    }
    wayfinders(order: { asc: name }) {
      ...WayfinderFields
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
  ${DESK_FIELDS_FRAGMENT}
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_ZONE_TO_TENANT = gql`
  mutation addZoneToTenantMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addZone(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          tenant: { id: $id }
        }
      ]
    ) {
      zone {
        ...ZoneFields
      }
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
`;

export const ADD_ZONE_TO_COMPANY = gql`
  mutation addZoneToCompanyMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addZone(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          company: { id: $id }
        }
      ]
    ) {
      zone {
        ...ZoneFields
      }
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
`;

export const ADD_ZONE_TO_SITE = gql`
  mutation addZoneToSiteMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addZone(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          site: { id: $id }
        }
      ]
    ) {
      zone {
        ...ZoneFields
      }
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
`;

export const ADD_ZONE_TO_BUILDING = gql`
  mutation addZoneToBuildingMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addZone(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          building: { id: $id }
        }
      ]
    ) {
      zone {
        ...ZoneFields
      }
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
`;

export const ADD_ZONE_TO_FLOOR = gql`
  mutation addZoneToFloorMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
  ) {
    addZone(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          floor: { id: $id }
        }
      ]
    ) {
      zone {
        ...ZoneFields
      }
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
`;

export const UPDATE_ZONE = gql`
  mutation updateZoneMutation(
    $id: ID!
    $name: String
    $tags: [String]
    $description: String
    $tenantId: String!
    $remove: ZonePatch
  ) {
    updateZone(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          tenantId: $tenantId
          description: $description
        }
        remove: $remove
      }
    ) {
      zone {
        ...ZoneFields
      }
    }
  }
  ${ZONE_FIELDS_FRAGMENT}
`;
