import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { RESOURCE_STRINGS as f } from "../../../lib/utils/constants";

export interface TemplateFormFields {
  profileName: string;
  profileDescription: string;
}

const TemplateSchema = Yup.object().shape({
  profileName: Yup.string()
    .min(2, "Please enter a valid template name")
    .required(f.required),
  //   profileDescription: Yup.string().required(f.required),
});

interface UseTemplateFormOptions {
  enableReinitialize?: boolean;
  initialValues?: TemplateFormFields;
  onSubmit: (
    values: TemplateFormFields,
    formikHelpers: FormikHelpers<TemplateFormFields>
  ) => Promise<any>;
}

export type RoomFormik = ReturnType<typeof useTemplateFormik>;

export const useTemplateFormik = (opts: UseTemplateFormOptions) => {
  return useFormik<TemplateFormFields>({
    initialValues: {
      profileName: "",
      profileDescription: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: TemplateSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
