import React from "react";

import { useAnalytics } from "../../../api/grpc/analytics/useAnalytics";

import { tooltips } from "../../../lib/utils/tooltips";
import { HomeCard } from "../../shared/HomeCard/HomeCard";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";

import {
  CustomerRequest,
  DevicesResponse,
} from "../../../api/grpc/analytics/analytics";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";

export const DevicesBox = () => {
  const { getDevices } = useAnalytics();
  const { timeZone } = useTimeZoneContext();

  const { loading, error, data } = useGrpcQuery<
    Omit<CustomerRequest, "customerID">,
    DevicesResponse
  >({
    method: getDevices,
    variables: {
      timeZone,
    },
  });

  return (
    <HandleRequestState state={loading} placeholder={<LoadingBox />}>
      <HandleRequestState
        state={!!error}
        placeholder={
          <HomeCard
            disabled
            title="Devices"
            icon="device_icon_72x72"
            list={[
              { title: "Devices" },
              {
                title: "Assigned devices",
                progress: data?.assigned,
              },
            ]}
          />
        }
      >
        <HomeCard
          title="Devices"
          icon="device_icon_large"
          link="/manage/devices"
          tooltip={tooltips.dashboard.devices}
          list={[
            { title: "Devices", value: data?.devices || 0 },
            {
              title: "Assigned devices",
              value: data?.devices || 0,
              progress: data?.assigned,
            },
          ]}
        />
      </HandleRequestState>
    </HandleRequestState>
  );
};
