import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useCustomerRequests } from "../../../api/grpc/customer/useCustomerRequests";
import { Customer } from "../../../api/grpc/customer/customer";

export const useAccountDetails = () => {
  const { getCustomerDetails } = useCustomerRequests();

  const { loading, error, data, refetch } = useGrpcQuery<{}, Customer>({
    method: getCustomerDetails,
    variables: {},
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
