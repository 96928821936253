import React from "react";
import { AvailableRoom } from "../../../../api/grpc/workplaces/workplaces";
import {
  BookingsSearchHeader,
  SearchHeaderInfo,
} from "../BookingsSearchHeader/BookingsSearchHeader";
import { BookingsRoomsTable } from "./BookingsRoomsTable";

interface Props {
  rooms: AvailableRoom[] | null;
  info: SearchHeaderInfo;
}

export const BookingsRoomsResult = ({ rooms, info }: Props) => {
  return (
    <>
      {rooms && rooms?.length > 0 ? (
        <div>
          <BookingsSearchHeader type="room" info={info} />
          <BookingsRoomsTable rooms={rooms} />
        </div>
      ) : (
        <div>No results...</div>
      )}
    </>
  );
};
