import React, { useEffect } from "react";
import { useWorkplaceRequests } from "../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useSidebarContext } from "../../lib/context/Sidebar/SidebarContext";
import { useRouter } from "../../lib/hooks/useRouter";
import { RESERVATIONS_MAP_ROOT_PATH } from "../../lib/routes/routes";
import { FilteredWorkplacesTree } from "../../views/Manage/Workplaces/FilteredWorkplaces/FilteredWorkplacesTree";
import { FloorPickerButton } from "../Floors/FloorPicker/FloorPickerButton";
import { PageHeader } from "../shared/PageHeader/PageHeader";
import { useBookingsContext } from "./Context/BookingsContext";

// this component is only for routes.ts file
// since we can't re-use FloorMain cmp, we needed
// to create this new one:

export const BookingsMainRoute = () => {
  const { togglePageSidebar } = useSidebarContext();
  const { user } = useAuthContext();
  const { getRootWorkplaces } = useWorkplaceRequests();
  const { pathname } = useRouter();
  const [request, { loading, error, data }] = getRootWorkplaces;
  const { handleLocationId, emptyActiveTags } = useBookingsContext();

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  return (
    <div className="FloorMain">
      <FilteredWorkplacesTree
        showOnDesktop={false}
        loading={loading}
        error={error}
        rootWorkplaces={rootWorkplaces}
        showOnInitialization={true}
        isPickerOnly={!pathname.includes(RESERVATIONS_MAP_ROOT_PATH)}
        onTitleClick={() => {
          togglePageSidebar();
          handleLocationId("root", "All locations");
          emptyActiveTags();
          return;
        }}
        pathTo={RESERVATIONS_MAP_ROOT_PATH}
      />

      <PageHeader title="Maps">
        <FloorPickerButton
          className="FloorPickerButton"
          adjustable
          size="medium"
          onClick={togglePageSidebar}
          color="third"
        />
      </PageHeader>

      <h4 className="center-content">Select floor to view</h4>
    </div>
  );
};
