import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { OnLevelSearchResponse_OnLevelSearchItem } from "../../../../api/grpc/workplaces/workplaces";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { trimText } from "../../../../lib/helpers/trimText";
import { WORKPLACES_ROOT_PATH } from "../../../../lib/routes/routes";
import { DeleteButton } from "../../../shared/DeleteButton/DeleteButton";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";
import { AddDeviceButton } from "../../AddWorkplace/AddWorkplaceButton/AddDeviceButton/AddDeviceButton";
import { WorkplaceTypes } from "../../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { SetCalendarButton } from "../../AddWorkplace/SetCalendarButton/SetCalendarButton";
import { getWorkplaceIcon } from "../../helpers/getWorkplaceInformation";
import { LicenseSwitch } from "../../LicenseSwitch/LicenseSwitch";
import { retrieveCalendarName } from "../../WorkplaceExpandedList/WorkplaceExpandedRooms/helpers/retrieveCalendarName";
import { WorkplacePrefixIcon } from "../../WorkplaceExpandedList/WorkplacePrefixIcon/WorkplacePrefixIcon";
import {
  licenseType,
  WorkplaceLicenseEntity,
} from "../../WorkplaceExpandedList/WorkplaceSearchedItem/WorkplaceSearchedItem";

interface Props {
  data: OnLevelSearchResponse_OnLevelSearchItem[];
  refetch?: () => void;
}

export const WorkspaceChildrenList = ({ data, refetch }: Props) => {
  const { deleteWorkplace } = useWorkplaceRequests();
  const history = useHistory();

  const { providers } = useCalendarContext();

  const columns: StructureTableColumn[] = [
    {
      key: "workplace_icon",
      header: "Workspaces",
    },
    {
      key: "workplace_name",
      header: "Name",
    },
    {
      key: "workplace_description",
      header: "Description",
    },
    {
      key: "workplace_tags",
      header: "Tags",
    },
    {
      key: "workplace_calendar",
      header: "Calendar",
    },
    {
      key: "workplace_device",
      header: "Device",
    },
    {
      key: "workplace_license",
      header: "License",
    },
    {
      key: "workplace_delete",
      header: "",
    },
  ];

  const rows: StructureTableRows[] = data.map((item) => {
    const isDeviceParentEntity = ["Room", "Wayfinder"].includes(item.typeName);

    const devices =
      item?.devices && item.devices.length > 0
        ? item.devices.map((item) => item.name)
        : [];

    const calendarName =
      item.typeName !== "Room"
        ? undefined
        : retrieveCalendarName({
            providers,
            calendarId: item.calendarId,
            providerId: item.calendarProviderId,
          });

    return {
      workplace_icon: (
        <WorkplacePrefixIcon
          icon={
            getWorkplaceIcon(item.typeName as WorkplaceTypes) as IconDefinition
          }
        />
      ),
      workplace_name: (
        <NavLink
          to={`${WORKPLACES_ROOT_PATH}/${item.typeName.toLowerCase()}/${
            item.id
          }`}
        >
          <h6 className="mb-0" title={item.name}>
            {item.name}
          </h6>
        </NavLink>
      ),
      workplace_tags: (
        <h6 className="mb-0">
          {item?.tags.length > 0 ? trimText(item.tags[0], 25) : "-"}
        </h6>
      ),
      workplace_description: (
        <h6 className="mb-0">{trimText(item.description || "-")}</h6>
      ),
      workplace_calendar:
        item.typeName !== "Room" ? (
          <></>
        ) : calendarName ? (
          <span className="mb-0" title={calendarName}>
            {trimText(calendarName, 18)}
          </span>
        ) : (
          <SetCalendarButton
            outline
            size="small"
            lineHeight="low"
            title="Set Calendar"
            onClick={() => {
              history.push(
                `${WORKPLACES_ROOT_PATH}/room/${item.id}/settings#add-calendar`
              );
            }}
          />
        ),
      workplace_device: !isDeviceParentEntity ? (
        <></>
      ) : devices.length > 0 ? (
        <>
          {devices.map((item, index) => {
            return <span key={`${item}-${index}`}>{trimText(item)}</span>;
          })}
        </>
      ) : (
        <AddDeviceButton
          outline
          size="small"
          lineHeight="low"
          color="secondary"
          title="Set Device"
          parentId={item.id}
          parentType={item.typeName === "Room" ? "Room" : "Wayfinder"}
          numberOfDeviceAdded={item?.devices?.length}
          refetch={refetch}
        />
      ),
      workplace_license: ["Room", "Desk", "Wayfinder"].includes(
        item.typeName
      ) ? (
        <LicenseSwitch
          id={item.id}
          type={licenseType[item.typeName as WorkplaceLicenseEntity]}
          value={!!item.licensed}
          refetch={refetch}
        />
      ) : (
        <></>
      ),
      workplace_delete: (
        <div className="ml-auto">
          <DeleteButton
            onClick={async () => {
              await deleteWorkplace(item.id, item.typeName);

              if (refetch) {
                refetch();
              }
            }}
          />
        </div>
      ),
    };
  });

  return (
    <StructureTable rows={rows} columns={columns} noResultsPlaceholder={"--"} />
  );
};
