import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { HourlyDistribution } from "../../../../../api/grpc/insights/insights";

export interface StaticLegendData {
  id: string;
  color: string;
}

const legendProps = {
  itemsSpacing: 75,
  itemWidth: 20,
  itemHeight: 15,
  itemOpacity: 1,
  symbolSize: 15,
  itemTextColor: "#000",
  justify: false,
};

interface Props {
  data: HourlyDistribution[];
  staticLegendData: StaticLegendData[];
}

export const PieChart = ({ data, staticLegendData }: Props) => {
  return (
    <ResponsivePie
      data={data}
      colors={{ datum: "data.color" }}
      margin={{ top: 15, right: 20, bottom: 120, left: 20 }}
      innerRadius={0.4}
      padAngle={0.7}
      activeOuterRadiusOffset={5}
      activeInnerRadiusOffset={5}
      borderWidth={1}
      arcLabel={(d) => `${d.value}%`}
      arcLabelsTextColor="#fff"
      borderColor={{
        from: "color",
      }}
      enableArcLinkLabels={false}
      theme={{
        tooltip: {
          container: {
            borderRadius: 12,
          },
          chip: {
            borderRadius: 50,
          },
        },
      }}
      legends={[
        {
          ...legendProps,
          anchor: "bottom",
          direction: "row",
          translateX: -25,
          translateY: 75,
          itemDirection: "left-to-right",
          symbolShape: "circle",
          data: staticLegendData
            .slice(2, Math.floor(staticLegendData.length))
            .map((cur) => ({
              id: cur.id,
              label: cur.id,

              color: cur.color,
            })),
        },
        {
          ...legendProps,
          anchor: "bottom",
          direction: "row",
          translateX: -25,
          translateY: 35,
          itemDirection: "left-to-right",
          symbolShape: "circle",
          data: staticLegendData
            .slice(0, Math.floor(staticLegendData.length / 2))
            .map((cur) => ({
              id: cur.id,
              label: cur.id,

              color: cur.color,
            })),
        },
      ]}
    />
  );
};
