import React, { PropsWithChildren } from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";

import { useToggleModal } from "../../../../lib/hooks/useToggleModal";

import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { BillingInnerCard } from "../../shared/BillingInnerCard/BillingInnerCard";
import { ProductsData } from "../../Subscriptions/Products/BillingProducts";
import { CalulatorProRateSteps } from "./CalulatorProRateSteps/CalulatorProRateSteps";
import { Subscription } from "../../../../api/grpc/subscription/subscription";

import Goget from "../../../../assets/images/GOGET-product.png";
import Floorplan from "../../../../assets/images/floor-map-2 1.png";

export const CalculatorProRate = ({
  subscriptions,
}: PropsWithChildren<{
  subscriptions: Subscription[];
}>) => {
  const { isOpen, toggleModal } = useToggleModal();

  const handleDownload = async () => {
    try {
      const url =
        process.env.REACT_APP_API_ENV + "GOGET - Channel partner guide.pdf";

      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "GOGET - Channel partner guide");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      a.remove();
    } catch (error: any) {
      toast.error("Couldn't download PDF file, please reload and try again.");
    }
  };

  const data: ProductsData[] = [
    {
      title: "Calculate pro-rating",
      hasButton: true,
      buttonTitle: "Calculate",
      onClick: toggleModal,
      image: <img src={Goget} alt="Calculator" />,
      content:
        "Check the amount clients should pay when adding items to an existing subscription.",
      secondDescription: "",
    },
    {
      title: "Partner help guide",
      hasButton: true,
      buttonTitle: "Download",
      onClick: handleDownload,
      image: <img src={Floorplan} alt="Calculator" />,
      content:
        "Download our partner help guide, which covers how to administer our solution.",
      secondDescription: "",
    },
  ];

  return (
    <>
      <InfoCard title="Tools & Guides">
        {data.map((item) => (
          <BillingInnerCard
            key={item.title}
            title={item.title}
            content={item.content}
            hasButton={item.hasButton}
            buttonTitle={item.buttonTitle}
            onClick={item.onClick}
            imageSrc={item.image}
            secondDescription={item.secondDescription}
            className="Products"
          />
        ))}
      </InfoCard>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          className="CalculatorProRate"
        >
          <CalulatorProRateSteps
            subscriptions={subscriptions}
            toggleModal={toggleModal}
          />
        </Modal>
      )}
    </>
  );
};
