import React from "react";
import cs from "classnames";
import { LabelWithInformationBox } from "../LabelWithInformationBox/LabelWithInformationBox";

import "./FormSubSection.scss";

interface Props {
  title?: string;
  message?: string;
  column?: boolean;
  children: React.ReactNode;
  className?: string;
  defaultContent?: boolean;
}

export const FormSubSection = (props: Props) => {
  const classes = cs("FormSubSection", props.className, {
    "FormSubSection--heading": !!props.title || props.defaultContent,
    "FormSubSection--column": props.column,
  });

  return (
    <div className={classes}>
      {props.title && (
        <LabelWithInformationBox title={props.title} message={props.message} />
      )}

      <div className="FormSubSection__content">{props.children}</div>
    </div>
  );
};
