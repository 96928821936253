import React, { PropsWithChildren, useRef } from "react";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useDirectoryContext } from "../../Context/DirectoryContext";
import { Button } from "../../../shared/Button/Button";
import { CheckboxButton } from "../../../shared/CheckboxButton/CheckboxButton";
import { Icon } from "../../../shared/Icon/Icon";

import "./SelectAllIntegrations.scss";

export const SelectAllIntegrations = ({
  handleSelectAll,
  numberOfAllUsers,
}: PropsWithChildren<{
  handleSelectAll: (isSelect: boolean) => void;
  numberOfAllUsers: number;
}>) => {
  const { selectAllUsers, setSelectAllUsers } = useDirectoryContext();
  const ref = useRef(null);

  const { isOpen, toggleModal } = useToggleModal();

  useOutsideClickDetection(
    ref,
    () => {
      if (!isOpen) {
        return;
      }
      toggleModal();
    },
    isOpen
  );

  return (
    <div className="SelectAllIntegrations" ref={ref}>
      <div className="flex-a-center SelectAllIntegrations__check">
        <CheckboxButton
          id="selectAll"
          name="selectAll"
          checked={selectAllUsers}
          onChange={() => {
            handleSelectAll(selectAllUsers);
            setSelectAllUsers((prev) => !prev);
          }}
          hideLabel
          className="IntegrationAllCheck"
        />

        <Icon
          icon="down-polygon"
          className="cursor-pointer"
          onClick={toggleModal}
        />
      </div>

      {isOpen && (
        <div className="SelectAllIntegrations__selector mt-1">
          <Button
            title={
              selectAllUsers ? "Unselect all" : `Select all ${numberOfAllUsers}`
            }
            color="outline-primary"
            className="child-element--clear d-flex"
            onClick={() => {
              if (selectAllUsers) {
                setSelectAllUsers(false);
                handleSelectAll(selectAllUsers);
                return;
              }

              setSelectAllUsers(true);
              handleSelectAll(selectAllUsers);
            }}
          />
        </div>
      )}
    </div>
  );
};
