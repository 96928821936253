import React, { useEffect } from "react";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useDefaultLocationHelpers } from "../../lib/defaultLocationHelpers";
import { useSidebarContext } from "../../../../lib/context/Sidebar/SidebarContext";
import { useQuery } from "@apollo/client";

import { tooltips } from "../../../../lib/utils/tooltips";
import { FilteredWorkplacesTree } from "../../../../views/Manage/Workplaces/FilteredWorkplaces/FilteredWorkplacesTree";
import { Button } from "../../../shared/Button/Button";
import { FormSection } from "../../../shared/FormSection/FormSection";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import {
  GetDefaultLocationPathRequestVariables,
  GetDefaultLocationPathResponse,
  GET_DEFAULT_LOCATION_PATH,
} from "../../../../api/graphql/bookings/bookings";
import { BookingsSearchButton } from "../../BookingsSearch/BookingsSearchButton/BookingsSearchButton";

export const LocationSettings = () => {
  const { user } = useAuthContext();
  const { togglePageSidebar } = useSidebarContext();
  const { getRootWorkplaces } = useWorkplaceRequests();
  const [request, { loading, error, data }] = getRootWorkplaces;
  const { handleDeleteDefaultLocation } = useDefaultLocationHelpers();
  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  const defaultLocation = useQuery<
    GetDefaultLocationPathResponse,
    GetDefaultLocationPathRequestVariables
  >(GET_DEFAULT_LOCATION_PATH, {
    fetchPolicy: "network-only",
    variables: {
      accountId: user?.claims.user_id || "",
    },
  });

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  return (
    <FormSection title="My default location" toggleable>
      <HandleLoadingState
        loading={defaultLocation.loading}
        loadingPlaceholder={<LoadingBox minHeight={50} />}
      >
        <HandleRequestState
          state={defaultLocation.data === undefined}
          placeholder={<div>Could't fetch location data</div>}
        >
          <LabelWithInformationBox
            className="pb-1x"
            title="Choose location"
            message={tooltips.defaultLocation.chooseLocation}
          />
          {defaultLocation.data?.queryDefaultLocationPath[0]?.referenceId ? (
            <BookingsSearchButton
              id={defaultLocation.data.queryDefaultLocationPath[0].referenceId}
              togglePageSidebar={togglePageSidebar}
              isSmall
            />
          ) : (
            <Button
              title="All locations"
              color="primary"
              size="small"
              icon="location-reserve"
              onClick={togglePageSidebar}
            />
          )}

          <FilteredWorkplacesTree
            showOnDesktop={false}
            loading={loading}
            error={error}
            rootWorkplaces={rootWorkplaces}
            showOnInitialization={false}
            className="PageSidebar--Custom PageSidebar--fixedHeight"
            isLocationDefault
            defaultLocation={defaultLocation}
            onTitleClick={() => {
              return handleDeleteDefaultLocation();
            }}
            pathTo="#"
          />
        </HandleRequestState>
      </HandleLoadingState>
    </FormSection>
  );
};
