import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";
import { FormikCustomLanguageFields } from "../types/types";

const CustomLanguageSchema = Yup.object({
  name: Yup.string().required(f.required),
  intro: Yup.object().shape({
    start: Yup.string().required(f.required),
    errorNoWifi: Yup.string().required(f.required),
    checkConnection: Yup.string().required(f.required),
  }),
  pairing: Yup.object().shape({
    cannotConnect: Yup.string().required(f.required),
    codeWillExpire: Yup.string().required(f.required),
    standBy: Yup.string().required(f.required),
    addDevice: Yup.string().required(f.required),
    newPairingCode: Yup.string().required(f.required),
    roomConnection: Yup.string().required(f.required),
    error: Yup.string().required(f.required),
    pairing: Yup.string().required(f.required),
    ready: Yup.string().required(f.required),
    paired: Yup.string().required(f.required),
    newCode: Yup.string().required(f.required),
    unpair: Yup.string().required(f.required),
    getNewCode: Yup.string().required(f.required),
    goHome: Yup.string().required(f.required),
  }),
  settings: Yup.object().shape({
    networkButton: Yup.string().required(f.required),
    dateButton: Yup.string().required(f.required),
    localeButton: Yup.string().required(f.required),
    generalButton: Yup.string().required(f.required),
    fontButton: Yup.string().required(f.required),
    kioskButton: Yup.string().required(f.required),
    notificationButton: Yup.string().required(f.required),
    version: Yup.string().required(f.required),
    network: Yup.object().shape({
      open: Yup.string().required(f.required),
      label: Yup.string().required(f.required),
      wifiTitle: Yup.string().required(f.required),
      wifiDescription: Yup.string().required(f.required),
      lanTitle: Yup.string().required(f.required),
      lanDescription: Yup.string().required(f.required),
    }),
    date: Yup.object().shape({
      time: Yup.string().required(f.required),
      timeTitle: Yup.string().required(f.required),
      timeDescription: Yup.string().required(f.required),
    }),
    locale: Yup.object().shape({
      label: Yup.string().required(f.required),
      title: Yup.string().required(f.required),
      description: Yup.string().required(f.required),
      languageTitle: Yup.string().required(f.required),
    }),
    general: Yup.object().shape({
      general: Yup.string().required(f.required),
      initial: Yup.string().required(f.required),
      clearCache: Yup.string().required(f.required),
      downloaded: Yup.string().required(f.required),
      startingDownloading: Yup.string().required(f.required),
      downloading: Yup.string().required(f.required),
      checkPermission: Yup.string().required(f.required),
      checkLatest: Yup.string().required(f.required),
      found: Yup.string().required(f.required),
      unlinkTitle: Yup.string().required(f.required),
      unlinkSubtitle: Yup.string().required(f.required),
      updatesTitle: Yup.string().required(f.required),
      updatesSubtitle: Yup.string().required(f.required),
      noUpdatesFound: Yup.string().required(f.required),
      toastInstalling: Yup.string().required(f.required),
      toastError: Yup.string().required(f.required),
      toastContactAdmin: Yup.string().required(f.required),
    }),
    font: Yup.object().shape({
      sizeStyle: Yup.string().required(f.required),
      title: Yup.string().required(f.required),
      description: Yup.string().required(f.required),
    }),
    kiosk: Yup.object().shape({
      turnOff: Yup.string().required(f.required),
      turnOn: Yup.string().required(f.required),
      buttonNameOn: Yup.string().required(f.required),
      buttonNameOff: Yup.string().required(f.required),
      buttonNameSet: Yup.string().required(f.required),
      buttonNameRemove: Yup.string().required(f.required),
      nonRootStep1Title: Yup.string().required(f.required),
      nonRootStep1Description: Yup.string().required(f.required),
      nonRootStep2Title: Yup.string().required(f.required),
      nonRootStep2Description: Yup.string().required(f.required),
      nonRootStep3Title: Yup.string().required(f.required),
      nonRootStep3Description: Yup.string().required(f.required),
      nonRootStep4Title: Yup.string().required(f.required),
      nonRootStep4Description: Yup.string().required(f.required),
      nonRootStep1OffTitle: Yup.string().required(f.required),
      nonRootStep1OffDescription: Yup.string().required(f.required),
      nonRootStep2OffTitle: Yup.string().required(f.required),
      nonRootStep2OffDescription: Yup.string().required(f.required),
      nonRootStep3OffTitle: Yup.string().required(f.required),
      nonRootStep3OffDescription: Yup.string().required(f.required),
      rootStep1Title: Yup.string().required(f.required),
      rootStep1Description: Yup.string().required(f.required),
      rootStep2Title: Yup.string().required(f.required),
      rootStep2Description: Yup.string().required(f.required),
      rootStep3Title: Yup.string().required(f.required),
      rootStep3Description: Yup.string().required(f.required),
      rootStep1aTitleImproved: Yup.string().required(f.required),
      rootStep1aDescriptionImproved: Yup.string().required(f.required),
      rootStep1bTitleImproved: Yup.string().required(f.required),
      rootStep1bDescriptionImproved: Yup.string().required(f.required),
      rootStep2aTitleImproved: Yup.string().required(f.required),
      rootStep2aDescriptionImproved: Yup.string().required(f.required),
      rootStep2bTitleImproved: Yup.string().required(f.required),
      rootStep2bDescriptionImproved: Yup.string().required(f.required),
      rootStep1OffTitle: Yup.string().required(f.required),
      rootStep1OffDescription: Yup.string().required(f.required),
      rootStep2OffTitle: Yup.string().required(f.required),
      rootStep2OffDescription: Yup.string().required(f.required),
      rootStep3OffTitle: Yup.string().required(f.required),
      rootStep3OffDescription: Yup.string().required(f.required),
      rootStep1aOffTitleImproved: Yup.string().required(f.required),
      rootStep1aOffDescriptionImproved: Yup.string().required(f.required),
      rootStep1bOffTitleImproved: Yup.string().required(f.required),
      rootStep1bOffDescriptionImproved: Yup.string().required(f.required),
      rootStep2aOffTitleImproved: Yup.string().required(f.required),
      rootStep2aOffDescriptionImproved: Yup.string().required(f.required),
      rootStep2bOffTitleImproved: Yup.string().required(f.required),
      rootStep2bOffDescriptionImproved: Yup.string().required(f.required),
      homeButtonTitle: Yup.string().required(f.required),
      homeButtonDescription: Yup.string().required(f.required),
      kioskMode: Yup.string().required(f.required),
      rootMoreStep1Title: Yup.string().required(f.required),
      rootMoreStep1Description: Yup.string().required(f.required),
      rootMoreStep1DescriptionImproved: Yup.string().required(f.required),
      rootMoreStep2Title: Yup.string().required(f.required),
      rootMoreStep2Description: Yup.string().required(f.required),
      rootMoreStep2DescriptionImproved: Yup.string().required(f.required),
      rootMoreStep3Title: Yup.string().required(f.required),
      rootMoreStep3Description: Yup.string().required(f.required),
      more: Yup.string().required(f.required),
      toastActivateSteps: Yup.string().required(f.required),
    }),
  }),
  map: Yup.object().shape({
    errorTitle: Yup.string().required(f.required),
    errorButtonText: Yup.string().required(f.required),
    errorDeviceTitle: Yup.string().required(f.required),
    errorDeviceButtonText: Yup.string().required(f.required),
    headerTitle: Yup.string().required(f.required),
    flashMessage: Yup.object().shape({
      showingNearbyRoomsOnly: Yup.string().required(f.required),
    }),
    modalMarker: Yup.object().shape({
      cancel: Yup.string().required(f.required),
      select: Yup.string().required(f.required),
      QRCode: Yup.string().required(f.required),
      for: Yup.string().required(f.required),
      until: Yup.string().required(f.required),
      reserved: Yup.string().required(f.required),
      available: Yup.string().required(f.required),
    }),
    modalMarkerScaling: Yup.object().shape({
      cancel: Yup.string().required(f.required),
      title: Yup.string().required(f.required),
      apply: Yup.string().required(f.required),
    }),
    modalSettings: Yup.object().shape({
      rooms: Yup.string().required(f.required),
      desks: Yup.string().required(f.required),
      onlyFree: Yup.string().required(f.required),
      title: Yup.string().required(f.required),
      apply: Yup.string().required(f.required),
    }),
  }),
  nearbyRooms: Yup.object().shape({
    headerTitle: Yup.string().required(f.required),
    free: Yup.string().required(f.required),
    busy: Yup.string().required(f.required),
    videoConference: Yup.string().required(f.required),
    presentationTech: Yup.string().required(f.required),
    audio: Yup.string().required(f.required),
    climate: Yup.string().required(f.required),
    casting: Yup.string().required(f.required),
    charging: Yup.string().required(f.required),
    desk: Yup.string().required(f.required),
    accessibility: Yup.string().required(f.required),
    roomCapacity: Yup.string().required(f.required),
    minimumEquipment: Yup.string().required(f.required),
    resetFilters: Yup.string().required(f.required),
    showFreeRooms: Yup.string().required(f.required),
    apply: Yup.string().required(f.required),
    matchingRooms: Yup.string().required(f.required),
    filters: Yup.string().required(f.required),
  }),
  home: Yup.object().shape({
    errorContentBoard: Yup.string().required(f.required),
    allDay: Yup.string().required(f.required),
    for: Yup.string().required(f.required),
    checkIn: Yup.string().required(f.required),
    soonFree: Yup.string().required(f.required),
    busy: Yup.string().required(f.required),
    free: Yup.string().required(f.required),
    onScreenReservation: Yup.string().required(f.required),
    pending: Yup.string().required(f.required),
    next: Yup.string().required(f.required),
    allDayEvent: Yup.string().required(f.required),
    contactlessBooking: Yup.string().required(f.required),
    organizer: Yup.string().required(f.required),
    admin: Yup.string().required(f.required),
    report: Yup.string().required(f.required),
    signInOut: Yup.string().required(f.required),
    modalPin: Yup.object().shape({
      titleLock: Yup.string().required(f.required),
      subtitleLock: Yup.string().required(f.required),
      confirm: Yup.string().required(f.required),
      error: Yup.string().required(f.required),
    }),
  }),
  calendar: Yup.object().shape({
    day: Yup.string().required(f.required),
    week: Yup.string().required(f.required),
    here: Yup.string().required(f.required),
    busyAllDay: Yup.string().required(f.required),
    free: Yup.string().required(f.required),
    onScreenReservation: Yup.string().required(f.required),
    allDay: Yup.string().required(f.required),
    subject: Yup.string().required(f.required),
    required: Yup.string().required(f.required),
    name: Yup.string().required(f.required),
    makePrivate: Yup.string().required(f.required),
    onlineMeeting: Yup.string().required(f.required),
    lockPin: Yup.string().required(f.required),
    book: Yup.string().required(f.required),
    extend: Yup.string().required(f.required),
    delete: Yup.string().required(f.required),
    checkOut: Yup.string().required(f.required),
    update: Yup.string().required(f.required),
    startTime: Yup.string().required(f.required),
    endTime: Yup.string().required(f.required),
    cancel: Yup.string().required(f.required),
    areYouSure: Yup.string().required(f.required),
    yes: Yup.string().required(f.required),
    no: Yup.string().required(f.required),
    modalPin: Yup.object().shape({
      titleLock: Yup.string().required(f.required),
      titleUnlock: Yup.string().required(f.required),
      subtitleLock: Yup.string().required(f.required),
      subtitleUnlock: Yup.string().required(f.required),
      confirm: Yup.string().required(f.required),
      error: Yup.string().required(f.required),
    }),
  }),
  network: Yup.object().shape({
    ethernet: Yup.string().required(f.required),
    wifiTitle: Yup.string().required(f.required),
    noConnection: Yup.string().required(f.required),
  }),
  modalPin: Yup.object().shape({
    accessIdentification: Yup.string().required(f.required),
    enterPin: Yup.string().required(f.required),
    done: Yup.string().required(f.required),
    error: Yup.string().required(f.required),
  }),
  userMenu: Yup.object().shape({
    booking: Yup.string().required(f.required),
    checkOut: Yup.string().required(f.required),
    nearbyRooms: Yup.string().required(f.required),
    mapView: Yup.string().required(f.required),
    reportErrors: Yup.string().required(f.required),
    admin: Yup.string().required(f.required),
    helpWizard: Yup.string().required(f.required),
  }),
  modalQuickBook: Yup.object().shape({
    title: Yup.string().required(f.required),
    meetingDetails: Yup.string().required(f.required),
    subject: Yup.string().required(f.required),
    required: Yup.string().required(f.required),
    hostName: Yup.string().required(f.required),
    findAnotherRoom: Yup.string().required(f.required),
    book: Yup.string().required(f.required),
    duration: Yup.string().required(f.required),
    max: Yup.string().required(f.required),
    now: Yup.string().required(f.required),
    start: Yup.string().required(f.required),
    nearbyRooms: Yup.string().required(f.required),
    mapView: Yup.string().required(f.required),
  }),
  modalCheckIn: Yup.object().shape({
    deleting: Yup.string().required(f.required),
    checkIn: Yup.string().required(f.required),
    confirmMeeting: Yup.string().required(f.required),
    meetingCancelled: Yup.string().required(f.required),
  }),
  modalLobby: Yup.object().shape({
    info: Yup.string().required(f.required),
    roomIssuesDescription: Yup.string()
      .required(f.required)
      .test(
        "includes-cols",
        "The field must include {{roomName}} and {{endSign}}. Also please mind spacing",
        (value) => {
          if (!value) {
            return;
          }

          if (value.startsWith("{{roomName}}")) {
            return (
              value.includes("{{roomName}} ") && value.includes("{{endSign}}")
            );
          }

          if (value.startsWith("{{endSign}}")) {
            return (
              value.includes("{{endSign}}") && value.includes(" {{roomName}} ")
            );
          }

          return (
            value.includes("{{endSign}}") && value.includes(" {{roomName}} ")
          );
        }
      ),
    noLicense: Yup.string().required(f.required),
    noCalendarConnection: Yup.string().required(f.required),
    checkSettings: Yup.string().required(f.required),
    hide: Yup.string().required(f.required),
  }),
  modalReport: Yup.object().shape({
    resolveErrors: Yup.string().required(f.required),
    reportError: Yup.string().required(f.required),
    report: Yup.string().required(f.required),
    resolve: Yup.string().required(f.required),
    descriptionResolve: Yup.string().required(f.required),
    descriptionReport: Yup.string().required(f.required),
    videoConference: Yup.string().required(f.required),
    presentationTech: Yup.string().required(f.required),
    audio: Yup.string().required(f.required),
    climate: Yup.string().required(f.required),
    casting: Yup.string().required(f.required),
    charging: Yup.string().required(f.required),
    desk: Yup.string().required(f.required),
    accessibility: Yup.string().required(f.required),
    name: Yup.string().required(f.required),
    message: Yup.string().required(f.required),
  }),
  modalDisconnect: Yup.object().shape({
    info: Yup.string().required(f.required),
    roomDisconnected: Yup.string().required(f.required),
    checkRoomSettings: Yup.string().required(f.required),
    unpair: Yup.string().required(f.required),
  }),
  flashMessage: Yup.object().shape({
    done: Yup.string().required(f.required),
    error: Yup.string().required(f.required),
    errorDesc: Yup.string().required(f.required),
    checkIn: Yup.string().required(f.required),
    slotBusy: Yup.string().required(f.required),
    updateRoomSettings: Yup.string().required(f.required),
    reservationsBlockedAdminPolicy: Yup.string().required(f.required),
    updateDisplaySettings: Yup.string().required(f.required),
  }),
  toastMessage: Yup.object().shape({
    longPress: Yup.string().required(f.required),
    checkingForUpdate: Yup.string().required(f.required),
    installingUpdate: Yup.string().required(f.required),
    failedUpdate: Yup.string().required(f.required),
    synchronizingError: Yup.string().required(f.required),
    pairingError: Yup.string().required(f.required),
    unpairingError: Yup.string().required(f.required),
    error: Yup.string().required(f.required),
  }),
  checkInReminder: Yup.object().shape({
    autoDelete: Yup.object().shape({
      body: Yup.string()
        .required(f.required)
        .test(
          "body-roomName",
          "The field must include {{roomName}}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            if (value.startsWith("{{roomName}}")) {
              return value.includes("{{roomName}} ");
            }

            if (
              value.endsWith("{{roomName}}") ||
              value.endsWith("{{roomName}}.")
            ) {
              return value.includes(" {{roomName}}");
            }

            return value.includes(" {{roomName}} ");
          }
        ),
      subject: Yup.string()
        .required(f.required)
        .test(
          "subject-roomName",
          "The field must include {{roomName}}. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            if (value.startsWith("{{roomName}}")) {
              return value.includes("{{roomName}} ");
            }

            if (
              value.endsWith("{{roomName}}") ||
              value.endsWith("{{roomName}}.")
            ) {
              return value.includes(" {{roomName}}");
            }

            return value.includes(" {{roomName}} ");
          }
        ),
    }),
    reminder: Yup.object().shape({
      body: Yup.string()
        .required(f.required)
        .test(
          "reminder-body-curly",
          "The field must include words with {curly} brackets. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return (
              value.includes("{{roomName}}") &&
              value.includes("{{meetingTitle}}") &&
              value.includes("{{interval}}")
            );
          }
        ),
      subject: Yup.string()
        .required(f.required)
        .test(
          "reminder-subject-curly",
          "The field must include words with {curly} brackets. Also please mind spacing",
          (value) => {
            if (!value) {
              return;
            }

            return (
              value.includes("{{roomName}}") && value.includes("{{interval}}")
            );
          }
        ),
    }),
  }),
});

interface UseCustomLanguageSchemaOptions {
  enableReinitialize?: boolean;
  onSubmit: (
    values: FormikCustomLanguageFields,
    formikHelpers: FormikHelpers<FormikCustomLanguageFields>
  ) => Promise<any>;
}

export type DisplaySettingsFormik = ReturnType<typeof useCustomLanguageFormik>;

export const useCustomLanguageFormik = (
  data: FormikCustomLanguageFields | null | undefined,
  opts: UseCustomLanguageSchemaOptions
) => {
  return useFormik<FormikCustomLanguageFields>({
    initialValues: {
      name: data?.name || "",
      intro: {
        start: data?.intro.start || "",
        errorNoWifi: data?.intro.errorNoWifi || "",
        checkConnection: data?.intro.checkConnection || "",
      },
      pairing: {
        cannotConnect: data?.pairing.cannotConnect || "",
        codeWillExpire: data?.pairing.codeWillExpire || "",
        standBy: data?.pairing.standBy || "",
        addDevice: data?.pairing.addDevice || "",
        newPairingCode: data?.pairing.newPairingCode || "",
        roomConnection: data?.pairing.roomConnection || "",
        error: data?.pairing.error || "",
        pairing: data?.pairing.pairing || "",
        ready: data?.pairing.ready || "",
        paired: data?.pairing.paired || "",
        newCode: data?.pairing.newCode || "",
        unpair: data?.pairing.unpair || "",
        getNewCode: data?.pairing.getNewCode || "",
        goHome: data?.pairing.goHome || "",
      },
      map: {
        errorTitle: data?.map?.errorTitle || "",
        errorButtonText: data?.map?.errorButtonText || "",
        errorDeviceTitle: data?.map?.errorDeviceTitle || "",
        errorDeviceButtonText: data?.map?.errorDeviceButtonText || "",
        headerTitle: data?.map?.headerTitle || "",
        flashMessage: {
          showingNearbyRoomsOnly:
            data?.map?.flashMessage.showingNearbyRoomsOnly || "",
        },
        modalMarker: {
          cancel: data?.map.modalMarker.cancel || "",
          select: data?.map?.modalMarker.select || "",
          QRCode: data?.map?.modalMarker.QRCode || "",
          for: data?.map?.modalMarker.for || "",
          until: data?.map?.modalMarker.until || "",
          reserved: data?.map?.modalMarker.reserved || "",
          available: data?.map?.modalMarker.available || "",
        },
        modalMarkerScaling: {
          title: data?.map.modalMarkerScaling.title || "",
          cancel: data?.map.modalMarkerScaling.cancel || "",
          apply: data?.map.modalMarkerScaling.apply || "",
        },
        modalSettings: {
          rooms: data?.map.modalSettings.rooms || "",
          desks: data?.map.modalSettings.desks || "",
          onlyFree: data?.map.modalSettings.onlyFree || "",
          title: data?.map.modalSettings.title || "",
          apply: data?.map.modalSettings.apply || "",
        },
      },
      settings: {
        networkButton: data?.settings.networkButton || "",
        dateButton: data?.settings.dateButton || "",
        localeButton: data?.settings.localeButton || "",
        generalButton: data?.settings.generalButton || "",
        fontButton: data?.settings.fontButton || "",
        kioskButton: data?.settings.kioskButton || "",
        notificationButton: data?.settings.notificationButton || "",
        version: data?.settings.version || "",
        network: {
          open: data?.settings.network.open || "",
          label: data?.settings.network.label || "",
          wifiTitle: data?.settings.network.wifiTitle || "",
          wifiDescription: data?.settings.network.wifiDescription || "",
          lanTitle: data?.settings.network.lanTitle || "",
          lanDescription: data?.settings.network.lanDescription || "",
        },
        date: {
          time: data?.settings.date.time || "",
          timeTitle: data?.settings.date.timeTitle || "",
          timeDescription: data?.settings.date.timeDescription || "",
        },
        locale: {
          label: data?.settings.locale.label || "",
          title: data?.settings.locale.title || "",
          description: data?.settings.locale.description || "",
          languageTitle: data?.settings.locale.languageTitle || "",
        },
        general: {
          general: data?.settings.general.general || "",
          initial: data?.settings.general.initial || "",
          clearCache: data?.settings.general.clearCache || "",
          downloaded: data?.settings.general.downloaded || "",
          startingDownloading: data?.settings.general.startingDownloading || "",
          downloading: data?.settings.general.downloading || "",
          checkPermission: data?.settings.general.checkPermission || "",
          checkLatest: data?.settings.general.checkLatest || "",
          found: data?.settings.general.found || "",
          unlinkTitle: data?.settings.general.unlinkTitle || "",
          unlinkSubtitle: data?.settings.general.unlinkSubtitle || "",
          updatesTitle: data?.settings.general.updatesTitle || "",
          updatesSubtitle: data?.settings.general.updatesSubtitle || "",
          noUpdatesFound: data?.settings.general.noUpdatesFound || "",
          screenCleaningTitle: data?.settings.general.screenCleaningTitle || "",
          screenCleaningText1: data?.settings.general.screenCleaningText1 || "",
          screenCleaningText2: data?.settings.general.screenCleaningText2 || "",
          screenCleaningButtonName:
            data?.settings.general.screenCleaningButtonName || "",
          toastInstalling: data?.settings.general.toastInstalling || "",
          toastError: data?.settings.general.toastError || "",
          toastContactAdmin: data?.settings.general.toastContactAdmin || "",
        },
        font: {
          sizeStyle: data?.settings.font.sizeStyle || "",
          title: data?.settings.font.title || "",
          description: data?.settings.font.description || "",
        },
        kiosk: {
          turnOff: data?.settings.kiosk.turnOff || "",
          turnOn: data?.settings.kiosk.turnOn || "",
          buttonNameOn: data?.settings.kiosk.buttonNameOn || "",
          buttonNameOff: data?.settings.kiosk.buttonNameOff || "",
          buttonNameSet: data?.settings.kiosk.buttonNameSet || "",
          buttonNameRemove: data?.settings.kiosk.buttonNameRemove || "",
          nonRootStep1Title: data?.settings.kiosk.nonRootStep1Title || "",
          nonRootStep1Description:
            data?.settings.kiosk.nonRootStep1Description || "",
          nonRootStep2Title: data?.settings.kiosk.nonRootStep2Title || "",
          nonRootStep2Description:
            data?.settings.kiosk.nonRootStep2Description || "",
          nonRootStep3Title: data?.settings.kiosk.nonRootStep3Title || "",
          nonRootStep3Description:
            data?.settings.kiosk.nonRootStep3Description || "",
          nonRootStep4Title: data?.settings.kiosk.nonRootStep4Title || "",
          nonRootStep4Description:
            data?.settings.kiosk.nonRootStep4Description || "",
          nonRootStep1OffTitle: data?.settings.kiosk.nonRootStep1OffTitle || "",
          nonRootStep1OffDescription:
            data?.settings.kiosk.nonRootStep1OffDescription || "",
          nonRootStep2OffTitle: data?.settings.kiosk.nonRootStep2OffTitle || "",
          nonRootStep2OffDescription:
            data?.settings.kiosk.nonRootStep2OffDescription || "",
          nonRootStep3OffTitle: data?.settings.kiosk.nonRootStep3OffTitle || "",
          nonRootStep3OffDescription:
            data?.settings.kiosk.nonRootStep3OffDescription || "",
          rootStep1Title: data?.settings.kiosk.rootStep1Title || "",
          rootStep1Description: data?.settings.kiosk.rootStep1Description || "",
          rootStep2Title: data?.settings.kiosk.rootStep2Title || "",
          rootStep2Description: data?.settings.kiosk.rootStep2Description || "",
          rootStep3Title: data?.settings.kiosk.rootStep3Title || "",
          rootStep3Description: data?.settings.kiosk.rootStep3Description || "",
          rootStep1aTitleImproved:
            data?.settings.kiosk.rootStep1aTitleImproved || "",
          rootStep1aDescriptionImproved:
            data?.settings.kiosk.rootStep1aDescriptionImproved || "",
          rootStep1bTitleImproved:
            data?.settings.kiosk.rootStep1bTitleImproved || "",
          rootStep1bDescriptionImproved:
            data?.settings.kiosk.rootStep1bDescriptionImproved || "",
          rootStep2aTitleImproved:
            data?.settings.kiosk.rootStep2aTitleImproved || "",
          rootStep2aDescriptionImproved:
            data?.settings.kiosk.rootStep2aDescriptionImproved || "",
          rootStep2bTitleImproved:
            data?.settings.kiosk.rootStep2bTitleImproved || "",
          rootStep2bDescriptionImproved:
            data?.settings.kiosk.rootStep2bDescriptionImproved || "",
          rootStep1OffTitle: data?.settings.kiosk.rootStep1OffTitle || "",
          rootStep1OffDescription:
            data?.settings.kiosk.rootStep1OffDescription || "",
          rootStep2OffTitle: data?.settings.kiosk.rootStep2OffTitle || "",
          rootStep2OffDescription:
            data?.settings.kiosk.rootStep2OffDescription || "",
          rootStep3OffTitle: data?.settings.kiosk.rootStep3OffTitle || "",
          rootStep3OffDescription:
            data?.settings.kiosk.rootStep3OffDescription || "",
          rootStep1aOffTitleImproved:
            data?.settings.kiosk.rootStep1aOffTitleImproved || "",
          rootStep1aOffDescriptionImproved:
            data?.settings.kiosk.rootStep1aOffDescriptionImproved || "",
          rootStep1bOffTitleImproved:
            data?.settings.kiosk.rootStep1bOffTitleImproved || "",
          rootStep1bOffDescriptionImproved:
            data?.settings.kiosk.rootStep1bOffDescriptionImproved || "",
          rootStep2aOffTitleImproved:
            data?.settings.kiosk.rootStep2aOffTitleImproved || "",
          rootStep2aOffDescriptionImproved:
            data?.settings.kiosk.rootStep2aOffDescriptionImproved || "",
          rootStep2bOffTitleImproved:
            data?.settings.kiosk.rootStep2bOffTitleImproved || "",
          rootStep2bOffDescriptionImproved:
            data?.settings.kiosk.rootStep2bOffDescriptionImproved || "",
          homeButtonTitle: data?.settings.kiosk.homeButtonTitle || "",
          homeButtonDescription:
            data?.settings.kiosk.homeButtonDescription || "",
          kioskMode: data?.settings.kiosk.kioskMode || "",
          rootMoreStep1Title: data?.settings.kiosk.rootMoreStep1Title || "",
          rootMoreStep1Description:
            data?.settings.kiosk.rootMoreStep1Description || "",
          rootMoreStep1DescriptionImproved:
            data?.settings.kiosk.rootMoreStep1DescriptionImproved || "",
          rootMoreStep2Title: data?.settings.kiosk.rootMoreStep2Title || "",
          rootMoreStep2Description:
            data?.settings.kiosk.rootMoreStep2Description || "",
          rootMoreStep2DescriptionImproved:
            data?.settings.kiosk.rootMoreStep2DescriptionImproved || "",
          rootMoreStep3Title: data?.settings.kiosk.rootMoreStep3Title || "",
          rootMoreStep3Description:
            data?.settings.kiosk.rootMoreStep3Description || "",
          more: data?.settings.kiosk.more || "",
          toastActivateSteps: data?.settings.kiosk.toastActivateSteps || "",
        },
      },
      nearbyRooms: {
        headerTitle: data?.nearbyRooms.headerTitle || "",
        free: data?.nearbyRooms.free || "",
        busy: data?.nearbyRooms.busy || "",
        videoConference: data?.nearbyRooms.videoConference || "",
        presentationTech: data?.nearbyRooms.presentationTech || "",
        audio: data?.nearbyRooms.audio || "",
        climate: data?.nearbyRooms.climate || "",
        casting: data?.nearbyRooms.casting || "",
        charging: data?.nearbyRooms.charging || "",
        desk: data?.nearbyRooms.desk || "",
        accessibility: data?.nearbyRooms.accessibility || "",
        roomCapacity: data?.nearbyRooms.roomCapacity || "",
        minimumEquipment: data?.nearbyRooms.minimumEquipment || "",
        resetFilters: data?.nearbyRooms.resetFilters || "",
        showFreeRooms: data?.nearbyRooms.showFreeRooms || "",
        apply: data?.nearbyRooms.apply || "",
        matchingRooms: data?.nearbyRooms.matchingRooms || "",
        filters: data?.nearbyRooms.filters || "",
      },
      home: {
        errorContentBoard: data?.home.errorContentBoard || "",
        allDay: data?.home.allDay || "",
        for: data?.home.for || "",
        checkIn: data?.home.checkIn || "",
        soonFree: data?.home.soonFree || "",
        busy: data?.home.busy || "",
        free: data?.home.free || "",
        onScreenReservation: data?.home.onScreenReservation || "",
        pending: data?.home.pending || "",
        next: data?.home.next || "",
        allDayEvent: data?.home.allDayEvent || "",
        contactlessBooking: data?.home.contactlessBooking || "",
        organizer: data?.home.organizer || "",
        admin: data?.home.admin || "",
        report: data?.home.report || "",
        signInOut: data?.home.signInOut || "",
        modalPin: {
          titleLock: data?.home.modalPin.titleLock || "",
          subtitleLock: data?.home.modalPin.subtitleLock || "",
          confirm: data?.home.modalPin.confirm || "",
          error: data?.home.modalPin.error || "",
        },
      },
      calendar: {
        day: data?.calendar.day || "",
        week: data?.calendar.week || "",
        here: data?.calendar.here || "",
        busyAllDay: data?.calendar.busyAllDay || "",
        free: data?.calendar.free || "",
        onScreenReservation: data?.calendar.onScreenReservation || "",
        allDay: data?.calendar.allDay || "",
        subject: data?.calendar.subject || "",
        required: data?.calendar.required || "",
        name: data?.calendar.name || "",
        makePrivate: data?.calendar.makePrivate || "",
        onlineMeeting: data?.calendar.onlineMeeting || "",
        lockPin: data?.calendar.lockPin || "",
        book: data?.calendar.book || "",
        extend: data?.calendar.extend || "",
        delete: data?.calendar.delete || "",
        checkOut: data?.calendar.checkOut || "",
        update: data?.calendar.update || "",
        startTime: data?.calendar.startTime || "",
        endTime: data?.calendar.endTime || "",
        cancel: data?.calendar.cancel || "",
        areYouSure: data?.calendar.areYouSure || "",
        yes: data?.calendar.yes || "",
        no: data?.calendar.no || "",
        modalPin: {
          titleLock: data?.calendar.modalPin.titleLock || "",
          titleUnlock: data?.calendar.modalPin.titleUnlock || "",
          subtitleLock: data?.calendar.modalPin.subtitleLock || "",
          subtitleUnlock: data?.calendar.modalPin.subtitleUnlock || "",
          confirm: data?.calendar.modalPin.confirm || "",
          error: data?.calendar.modalPin.error || "",
        },
      },
      network: {
        ethernet: data?.network.ethernet || "",
        wifiTitle: data?.network.wifiTitle || "",
        noConnection: data?.network.noConnection || "",
      },
      modalPin: {
        accessIdentification: data?.modalPin.accessIdentification || "",
        enterPin: data?.modalPin.enterPin || "",
        done: data?.modalPin.done || "",
        error: data?.modalPin.error || "",
      },
      userMenu: {
        booking: data?.userMenu.booking || "",
        checkOut: data?.userMenu.checkOut || "",
        nearbyRooms: data?.userMenu.nearbyRooms || "",
        mapView: data?.userMenu.mapView || "",
        reportErrors: data?.userMenu.reportErrors || "",
        admin: data?.userMenu.admin || "",
        helpWizard: data?.userMenu.helpWizard || "",
      },
      modalQuickBook: {
        title: data?.modalQuickBook.title || "",
        meetingDetails: data?.modalQuickBook.meetingDetails || "",
        subject: data?.modalQuickBook.subject || "",
        required: data?.modalQuickBook.required || "",
        hostName: data?.modalQuickBook.hostName || "",
        findAnotherRoom: data?.modalQuickBook.findAnotherRoom || "",
        book: data?.modalQuickBook.book || "",
        duration: data?.modalQuickBook.duration || "",
        max: data?.modalQuickBook.max || "",
        now: data?.modalQuickBook.now || "",
        start: data?.modalQuickBook.start || "",
        nearbyRooms: data?.modalQuickBook.nearbyRooms || "",
        mapView: data?.modalQuickBook.mapView || "",
      },
      modalCheckIn: {
        deleting: data?.modalCheckIn.deleting || "",
        checkIn: data?.modalCheckIn.checkIn || "",
        confirmMeeting: data?.modalCheckIn.confirmMeeting || "",
        meetingCancelled: data?.modalCheckIn.meetingCancelled || "",
      },
      modalLobby: {
        info: data?.modalLobby.info || "",
        roomIssuesDescription: data?.modalLobby.roomIssuesDescription || "",
        noLicense: data?.modalLobby.noLicense || "",
        noCalendarConnection: data?.modalLobby.noCalendarConnection || "",
        checkSettings: data?.modalLobby.checkSettings || "",
        hide: data?.modalLobby.hide || "",
      },
      modalReport: {
        resolveErrors: data?.modalReport.resolveErrors || "",
        reportError: data?.modalReport.reportError || "",
        report: data?.modalReport.report || "",
        resolve: data?.modalReport.resolve || "",
        descriptionResolve: data?.modalReport.descriptionResolve || "",
        descriptionReport: data?.modalReport.descriptionReport || "",
        videoConference: data?.modalReport.videoConference || "",
        presentationTech: data?.modalReport.presentationTech || "",
        audio: data?.modalReport.audio || "",
        climate: data?.modalReport.climate || "",
        casting: data?.modalReport.casting || "",
        charging: data?.modalReport.charging || "",
        desk: data?.modalReport.desk || "",
        accessibility: data?.modalReport.accessibility || "",
        name: data?.modalReport.name || "",
        message: data?.modalReport.message || "",
      },
      modalDisconnect: {
        info: data?.modalDisconnect.info || "",
        roomDisconnected: data?.modalDisconnect.roomDisconnected || "",
        checkRoomSettings: data?.modalDisconnect.checkRoomSettings || "",
        unpair: data?.modalDisconnect.unpair || "",
      },
      modalScreenCleaning: {
        screenCleaningMode: data?.modalScreenCleaning.screenCleaningMode || "",
        timeRemaining: data?.modalScreenCleaning.timeRemaining || "",
        lastCleaned: data?.modalScreenCleaning.lastCleaned || "",
        unknown: data?.modalScreenCleaning.unknown || "",
      },
      flashMessage: {
        done: data?.flashMessage.done || "",
        error: data?.flashMessage.error || "",
        errorDesc: data?.flashMessage.errorDesc || "",
        checkIn: data?.flashMessage.checkIn || "",
        slotBusy: data?.flashMessage.slotBusy || "",
        unableToSyncMeetings: data?.flashMessage.unableToSyncMeetings || "",
        updateRoomSettings: data?.flashMessage.updateRoomSettings || "",
        reservationsBlockedAdminPolicy:
          data?.flashMessage.reservationsBlockedAdminPolicy || "",
        updateDisplaySettings: data?.flashMessage.updateDisplaySettings || "",
      },
      toastMessage: {
        longPress: data?.toastMessage.longPress || "",
        noInternetConnection: data?.toastMessage.noInternetConnection || "",
        checkingForUpdate: data?.toastMessage.checkingForUpdate || "",
        installingUpdate: data?.toastMessage.installingUpdate || "",
        failedUpdate: data?.toastMessage.failedUpdate || "",
        synchronizingError: data?.toastMessage.synchronizingError || "",
        pairingError: data?.toastMessage.pairingError || "",
        unpairingError: data?.toastMessage.unpairingError || "",
        error: data?.toastMessage.error || "",
      },
      checkInReminder: {
        autoDelete: {
          body: data?.checkInReminder.autoDelete.body || "",
          subject: data?.checkInReminder.autoDelete.subject || "",
        },
        reminder: {
          body: data?.checkInReminder.reminder.body || "",
          subject: data?.checkInReminder.reminder.subject || "",
        },
      },
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: CustomLanguageSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
