import React, { useEffect, useState } from "react";
import { useRouter } from "../../lib/hooks/useRouter";
import { useAccountRequests } from "../../api/grpc/account/useAccountRequests";

import { AuthenticationPage } from "../shared/AuthenticationPage/AuthenticationPage";
import { ModalMFANotification } from "../Account/EnableMFA/Modal/ModalMFANotification/ModalMFANotification";
import { Button } from "../shared/Button/Button";
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState";
import { CenteredLoader } from "../shared/CenteredLoader/CenteredLoader";

import "./RecoverAccountMFA.scss";

export const RecoverAccountMFA = () => {
  const { history, query } = useRouter();
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const { disableMFA } = useAccountRequests();

  useEffect(() => {
    handleDisableMFA();
  }, []);

  const handleDisableMFA = async () => {
    if (!query.token || !query.accountID) {
      setLoading(false);

      return;
    }

    try {
      await disableMFA({
        accountID: query.accountID,
        token: query.token,
      });
    } catch (error) {
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AuthenticationPage
        isDefault={true}
        formTitle="Disable two-factor authentication"
      >
        <div className="RecoverAccountMFA">
          <HandleLoadingState
            loading={loading}
            loadingPlaceholder={
              <div className="mt-3">
                <CenteredLoader />
                <h6 className="text-center pt-4">Disabling MFA</h6>
              </div>
            }
          >
            <>
              {showError ? (
                <>
                  <span className="RecoverAccountMFA__info">
                    The link has expired or has already been used. Request a new
                    link to disable two-factor authentication.{" "}
                  </span>

                  <ModalMFANotification
                    title="Expired or already used link"
                    icon="error-filled"
                  />
                </>
              ) : (
                <ModalMFANotification
                  icon="check-icon"
                  title="Two-factor authentication disabled!"
                  className="ModalResponse__content--disable"
                />
              )}

              <div className="RecoverAccountMFA__button">
                <Button
                  title="Return to sign-in page"
                  color="outline-secondary"
                  size="small"
                  onClick={() => history.push("/login")}
                />
              </div>
            </>
          </HandleLoadingState>
        </div>
      </AuthenticationPage>
    </>
  );
};
