import React, { useRef, useState } from "react";
import cs from "classnames";

import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { usePaginationContext } from "../../../../lib/context/Pagination/PaginationContext";

import { Icon } from "../../Icon/Icon";

import "./PaginationSelect.scss";

const countOptions = [10, 50, 100];

export const PaginationSelect = () => {
  const ref = useRef(null);
  const { pageSize, setPageSize } = usePaginationContext();
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(() => {
    const index = countOptions.indexOf(pageSize);

    return index !== -1 ? countOptions[index] : 10;
  });

  const listClasses = cs("PaginationSelect__list", {
    "PaginationSelect__list--toggled": isOpen,
  });

  useOutsideClickDetection(ref, () => {
    setIsOpen(false);
  });

  const handleOptionClick = (value: number) => () => {
    if (value === count) {
      setIsOpen(false);
      return;
    }

    setCount(value);
    setIsOpen(false);

    setPageSize(value);
  };

  return (
    <div className="PaginationSelect">
      <span className="PaginationSelect__title">Items</span>
      <ul ref={ref} className={listClasses}>
        <li className="active" onClick={() => setIsOpen((prev) => !prev)}>
          <span>{count}</span>
          <Icon icon="arrow-icon" />
        </li>
        {countOptions.map((item) => (
          <li
            onClick={handleOptionClick(item)}
            key={`${item}-pagination-select`}
            className={`${item === count ? "selected" : ""}`}
          >
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
