import React from "react";

import { useInsightsContext } from "../../../Context/InsightsContext";
import { useSortInsightsData } from "../../../shared/lib/useSortInsightsData";
import { useDeskUtilizationInsightsContext } from "../../../Context/Desk/Utilization/UtilizationContext";

import { ChartCard } from "../../../shared/ChartCard/ChartCard";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { UtilizationOccupancyTotal } from "../../../Room/Utilization/UtilizationOccupancy/UtilizationOccupancyTotal/UtilizationOccupancyTotal";
import { UtilizationOccupancyByDayOfWeek } from "../../../Room/Utilization/UtilizationOccupancy/UtilizationOccupancyByDayOfWeek/UtilizationOccupancyByDayOfWeek";
import { MostAndLeastPopularDesk } from "./MostAndLeastPopularDesk/MostAndLeastPopularDesk";
import { tooltips } from "../../../../../lib/utils/tooltips";

export const DeskUtilizationOccupancy = () => {
  const {
    insightsLocation: { locationType },
  } = useInsightsContext();

  const {
    loadingOccupancy: loading,
    errorOccupancy: error,
    dataOccupancy: data,
  } = useDeskUtilizationInsightsContext();

  let mostAndLeastPopularDeskSortedData = useSortInsightsData(
    data?.popularDesksStats
  );

  return (
    <>
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <div className="mt-3 WorkspaceValueInsights__statistics">
            <LoadingBox minHeight={170} />
            <LoadingBox minHeight={170} />
            <LoadingBox minHeight={170} />
            <LoadingBox minHeight={170} />
          </div>
        }
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>{error?.message}</CenteredBox>}
        >
          <div className="UtilizationOccupancy WorkspaceValueInsights__statistics">
            <ChartCard
              title="Total occupancy (%)"
              message={tooltips.insights.desks.totalOccupancy}
              adjustInfoLeft
              className="ChartCard__occupancy"
            >
              <UtilizationOccupancyTotal
                value={Math.round(data?.totalOccupancyPercentage || 0)}
              />
              <span className="UtilizationOccupancy__info">
                Adjusted for actual work hours
              </span>
            </ChartCard>

            <ChartCard
              title="Occupancy by Day of Week"
              message={tooltips.insights.desks.occupancyByDayOfWeek}
              className="ChartCard__occupancy"
            >
              <UtilizationOccupancyByDayOfWeek
                data={data?.dayOfWeekOccupancy || []}
              />
            </ChartCard>

            {locationType !== "Desk" && (
              <ChartCard
                title="Most Popular Desks"
                message={tooltips.insights.desks.mostPopularDesks}
                className="ChartCard__occupancy"
              >
                {!!data?.popularDesksStats.length ? (
                  <MostAndLeastPopularDesk
                    data={mostAndLeastPopularDeskSortedData.slice(0, 6)}
                    type="most"
                  />
                ) : (
                  <CenteredBox className="CenteredBox__disableHeight">
                    Empty data
                  </CenteredBox>
                )}
              </ChartCard>
            )}

            {locationType !== "Desk" && (
              <ChartCard
                title="Least Popular Desks"
                message={tooltips.insights.desks.leastPopularDesks}
                className="ChartCard__occupancy"
              >
                {!!data?.popularDesksStats.length ? (
                  <MostAndLeastPopularDesk
                    data={mostAndLeastPopularDeskSortedData.slice(
                      Math.max(mostAndLeastPopularDeskSortedData.length - 6, 0)
                    )}
                    type="least"
                  />
                ) : (
                  <CenteredBox className="CenteredBox__disableHeight">
                    Empty data
                  </CenteredBox>
                )}
              </ChartCard>
            )}
          </div>
        </HandleRequestState>
      </HandleLoadingState>
    </>
  );
};
