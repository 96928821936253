import React from "react";
import { BusyTimeSlots } from "../../../../../api/grpc/desk/ggevent/ggevent";
import { BookingTypes } from "../../../BookingsSearch/BookingsSearch";
import { BookingsMapDeleteDesk } from "./BookingsMapDeleteDesk";
import { BookingsMapDeleteRoom } from "./RoomDelete/BookingsMapDeleteRoom";

interface Props {
  type: BookingTypes;
  id: string;
  busySlots: BusyTimeSlots[];
  toggleModal: () => void;
  refetch: () => void;
}

const components: {
  [key in BookingTypes]: React.FunctionComponent<Props>;
} = {
  desk: BookingsMapDeleteDesk,
  room: BookingsMapDeleteRoom,
};

export const BookingsMapDeleteModal = ({
  type,
  id,
  busySlots,
  toggleModal,
  refetch,
}: Props) => {
  const Component = components[type];

  const componentProps: Props = {
    busySlots,
    type,
    id,
    toggleModal,
    refetch,
  };

  return <Component {...componentProps} />;
};
