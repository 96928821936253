import React from "react";
import Select from "react-select";

import { selectStyles } from "../../../lib/utils/selectStyles";

interface Props {
  value: string;
  fieldName: string;
  onChange: (field: string, value: string) => void;
}

const minutes = [
  { value: "team-1", label: "Team 1" },
  { value: "team-2", label: "Team 2" },
];

export const TeamSelect = ({ fieldName, onChange }: Props) => {
  return (
    <Select
      isClearable
      tabIndex="-1"
      options={minutes}
      theme={selectStyles}
      name={fieldName}
      onChange={(e) => {
        if (!e || e.value === undefined) {
          return;
        }

        onChange(fieldName, e.value);
      }}
    />
  );
};
