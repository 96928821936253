import React from "react";
import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";

interface Props {
  icon: IconDefinition;
  name: string;
}

export const TeamItemRow = ({ icon, name }: Props) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <Icon icon={icon} />
      <span className="pl-3">{name}</span>
    </div>
  );
};
