import React, { PropsWithChildren, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTemplatesClient } from "../../../../api/grpc/workplaces/useTemplatesClient";
import { useDeskTemplateContext } from "../../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";
import { useRoomTemplateContext } from "../../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { TEMPLATE_ROOT_PATH } from "../../../../lib/routes/routes";
import {
  DeskTemplate,
  RoomTemplate,
} from "../../../../api/grpc/workplaces/workplaces";
import { DropdownList } from "../../../shared/DropdownList/DropdownList";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { Icon, IconDefinition } from "../../../shared/Icon/Icon";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { ProfileItem } from "./ProfileItem";
import { MainResources } from "../../../../lib/types/main.types";

const options: {
  icon: IconDefinition;
  label: string;
  type: MainResources;
}[] = [
  {
    icon: "room_icon_18x18",
    label: "Add room template",
    type: "room",
  },
  {
    icon: "desk_icon_18x18",
    label: "Add desk template",
    type: "desk",
  },
];

export const ProfileTable = ({
  rooms,
  desks,
}: PropsWithChildren<{
  rooms: RoomTemplate[];
  desks: DeskTemplate[];
}>) => {
  const ref = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { deleteDeskTemplate, deleteRoomTemplate } = useTemplatesClient();
  const { history } = useRouter();
  const { refetchRoomTemplates } = useRoomTemplateContext();
  const { refetchDeskTemplates } = useDeskTemplateContext();

  useOutsideClickDetection(
    ref,
    () => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    },
    showDropdown
  );

  const handleDeleteTemplate = async (type: MainResources, id: string) => {
    try {
      setDeleteLoading(true);
      if (type === "room") {
        await deleteRoomTemplate(id);

        await refetchRoomTemplates();

        toast.success("Room template deleted successfully.");
      } else {
        await deleteDeskTemplate(id);

        await refetchDeskTemplates();

        toast.success("Desk template deleted successfully.");
      }
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <HandleLoadingState
      loading={deleteLoading}
      loadingPlaceholder={<LoadingBox count={3} minHeight={70} />}
    >
      <div className="ProfileTable">
        {rooms.map(({ id, description, name }) => (
          <ProfileItem
            key={id}
            id={id}
            name={name}
            description={description}
            profileType="room"
            icon="roomTemplate"
            handleDeleteTemplate={() => handleDeleteTemplate("room", id)}
            handleEditTemplate={() =>
              history.push(`${TEMPLATE_ROOT_PATH}/edit-room/${id}`)
            }
          />
        ))}

        {desks.map(({ id, name, description }) => (
          <ProfileItem
            key={id}
            id={id}
            name={name}
            description={description}
            profileType="desk"
            icon="deskTemplate"
            handleDeleteTemplate={() => handleDeleteTemplate("desk", id)}
            handleEditTemplate={() =>
              history.push(`${TEMPLATE_ROOT_PATH}/edit-desk/${id}`)
            }
          />
        ))}

        <div
          ref={ref}
          className="ProfileTable__item template"
          onClick={() => setShowDropdown((prev) => !prev)}
        >
          <Icon icon="plus-icon-bordered" />
          <h6>Add template</h6>

          {showDropdown && (
            <DropdownList
              extended
              color="gray"
              options={options}
              onClick={(value) => {
                if (value === "room") {
                  history.push(`${TEMPLATE_ROOT_PATH}/add-room`);
                  return;
                }

                history.push(`${TEMPLATE_ROOT_PATH}/add-desk`);
                return;
              }}
            />
          )}
        </div>
      </div>
    </HandleLoadingState>
  );
};
