export const formatMinutesAsHours = (minutes: number) => {
  if (minutes === 0) {
    return 0;
  }

  const hours = minutes / 60;

  if (hours <= 29) {
    return hours.toFixed(2);
  }

  return Math.round(hours);
};
