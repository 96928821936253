import React from "react";
import { LocationItem } from "../../../../api/grpc/workplaces/workplaces";
import cs from "classnames";
import _ from "lodash";

import "./LocationPath.scss";

interface Props {
  location: LocationItem[];
}

export const LocationPath = ({ location }: Props) => {
  const classes = cs("LocationPath", {
    LocationPath__multiple: location.length > 1,
  });

  return (
    <div className={classes}>
      {_.orderBy(location, ["index"], ["asc"]).map((item) => (
        <span key={item.id}>{item.name}</span>
      ))}
    </div>
  );
};
