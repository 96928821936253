import { createContext, useContext } from "react";

import { CreateCustomerRequest } from "../../../api/grpc/customer/customer";
import { RpcInterceptor } from "@protobuf-ts/runtime-rpc";
import { DeviceType } from "./AuthContextProvider";
import { UserCredential, TotpSecret, MultiFactorError } from "firebase/auth";
import {
  FavoriteWorkplace,
  MFAType,
  RolePermissions,
} from "../../../api/grpc/account/account";

export interface ChargebeeProperties {
  sectionType: any;
  params: {
    subscriptionId: string | undefined;
  };
}

const firebaseAuthInitialState = {
  // additionalUserInfo: undefined,
  // credential: null,
  operationType: null,
  user: null,
  providerId: null,
};

export type SessionType = "ADDRESS" | "PAYMENT_SOURCES" | "EDIT_SUBSCRIPTION";

export type ProviderType = "google" | "o365";

export type GenerateTotpResponse = {
  totpSecret: TotpSecret;
  QRCodeURL: string;
};

export interface UserFields {
  name: string;
  email: string;
  token: string;
  orgid: string;
  picture: string;
  lastName: string;
  firstName: string;
  customerid: string;
  displayName: string;
  allowToFind: boolean;
  googleAccountConnected: boolean;
  o365AccountConnected: boolean;
  googleAccountLinkedEmail: string;
  o365AccountLinkedEmail: string;
  mfaType: MFAType;
  permissions: RolePermissions[];
  favoriteWorkplaces: FavoriteWorkplace[];
  // TO DO: Remove any type for claims
  claims: any;
  timeZone: string;
  mfaEnabled?: boolean;
  recoveryEmail?: string;
}

export type OptionalUserFields = Partial<UserFields>;

export type UserAccess =
  | "authenticated"
  | "not-authenticated"
  | "no-permission";

export interface AuthContext {
  loading: boolean;
  userAccess: UserAccess;
  isAuthenticated: boolean;
  signOut: () => Promise<void>;
  user: UserFields | undefined;
  ssoLoading: boolean;
  setSsoLoading: React.Dispatch<React.SetStateAction<boolean>>;
  chargebeeProperties: (type: SessionType) => {};
  tokenInterceptor: RpcInterceptor;
  resetPassword: (email: string) => any;
  updateUser: (fields: OptionalUserFields) => void;
  getTenant: (username: string) => Promise<any>;
  updatePassword: (password: string) => Promise<void>;
  getTokenHeader: () => Promise<string | undefined>;
  createCustomer: (input: CreateCustomerRequest) => Promise<any>;
  verifyPasswordResetCode: (code: string, tenantId: string) => Promise<any>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<any>;
  login: (
    username: string,
    password: string,
    tenant: string
  ) => Promise<UserCredential | undefined>;
  verifyPassword: (password: string) => Promise<UserCredential | undefined>;
  downloadApk: (type: DeviceType) => Promise<any>;
  signInWithProvider: (token: string) => Promise<any>;
  // linkGoogleAccount: () => Promise<UserCredential | undefined>;
  signUpWithProvider: (
    tenenatId: string,
    providerType: ProviderType
  ) => Promise<UserCredential | undefined>;
  // linkMicrosoftAccount: () => Promise<UserCredential | undefined>;
  // unLinkGoogleAccount: () => Promise<any>;
  // unLinkMicrosoftAccount: () => Promise<any>;
  signOutFromProvider: () => Promise<void>;
  updateUserAvatarAfterPersonalLinked: () => Promise<void>;
  generateTotpSecret: () => Promise<void>;
  enrollUserForTotp: (codeFromAuthApp: string) => Promise<void>;
  unEnrollUserForTotp: () => Promise<void>;
  verifyEnrollement: () => void;
  recoveryEmail: string;
  setRecoveryEmail: React.Dispatch<React.SetStateAction<string>>;
  isMemberRole: boolean;
  loginWithTotp: (
    error: MultiFactorError,
    codeFromApp: string
  ) => Promise<UserCredential | undefined>;
  userTotp?:
    | {
        totpSecret: TotpSecret;
        qRCodeURL: string;
      }
    | undefined;
}

export const AuthContext = createContext<AuthContext>({
  loading: false,
  user: undefined,
  userAccess: "not-authenticated",
  isAuthenticated: false,
  updateUser: () => {},
  ssoLoading: false,
  setSsoLoading: () => {},
  tokenInterceptor: {},
  login: async () => undefined,
  signOut: async () => {},
  getTenant: async () => {},
  resetPassword: async () => {},
  createCustomer: async () => {},
  updatePassword: async () => {},
  confirmPasswordReset: async () => {},
  getTokenHeader: async () => undefined,
  verifyPassword: async () => undefined,
  verifyPasswordResetCode: async () => {},
  chargebeeProperties: async () => {},
  recoveryEmail: "",
  setRecoveryEmail: () => {},
  isMemberRole: false,
  downloadApk: async () => {},
  signInWithProvider: async () => firebaseAuthInitialState,
  // linkGoogleAccount: async () => firebaseAuthInitialState,
  // linkMicrosoftAccount: async () => firebaseAuthInitialState,
  signUpWithProvider: async () => {
    return undefined;
  },
  // unLinkGoogleAccount: async () => {},
  // unLinkMicrosoftAccount: async () => {},
  signOutFromProvider: async () => {},
  updateUserAvatarAfterPersonalLinked: async () => {},
  generateTotpSecret: async () => undefined,
  enrollUserForTotp: async () => {},
  verifyEnrollement: () => undefined,
  unEnrollUserForTotp: async () => {},
  loginWithTotp: async () => undefined,
  userTotp: undefined,
});

export const useAuthContext = () => useContext(AuthContext);

export const { Provider } = AuthContext;
