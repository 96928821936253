import { FormikCustomWayfinderLanguageFields } from "../types/types";

export const defualtWayfinderLanguage: FormikCustomWayfinderLanguageFields = {
  common: {
    app: {
      version: "Version {version}",
      device_id: "Device ID: {device_id}",
    },
    actions: {
      submit: "Submit",
      done: "Done",
      open: "Open",
      cancel: "Cancel",
      start: "Start",
      unpair: "Unpair",
      close: "Close",
    },
    footer: {
      powered_by: "Powered by",
    },
    errors: {
      unpair_error: "The unpair process wasn't successful. Try again later",
      unable_get_data: "Unable to get {entity} data",
      internet_connection: "Check your internet connection",
    },
    time: {
      all_day: "All time",
      time: "Time",
      free_all_day: "Free all day",
      free_until: "Free until {time}",
      busy_all_day: "Busy all day",
      busy_until: "Busy until {time}",
      time_left: "{time} left",
      now: "Now",
      from: "From",
      until: "Until",
      duration: "Duration",
    },
    equipment: {
      accessibility: "Accessibility",
      audio: "Audio",
      video_conferencing: "Video Conferencing",
      climate: "Climate (AC)",
      desk_sit_stand: "Desk (Sit-Stand)",
      presentation_tech: "Presentation Tech",
      charging_port: "Charging Port",
      casting: "Casting",
    },
    entities: {
      desk: "desk",
      room: "room",
    },
  },
  screens: {
    settings: {
      screen_title: "Settings",
      general_tab_name: "General",
      network_tab_name: "Network",
      datetime_tab_name: "Date & time",
      locale_tab_name: "Locale",
      language_select_title: "Language select",
      language_select_subtitle: "Main UI language",
      orientation_title: "Change screen orientation",
      orientation_subtitle: "Press Open > Screen Settings > Screen orientation",
      updates_title: "Updates",
      updates_checking: "Checking latest version...",
      no_updates_required: "You are already using the latest version",
      update_available: "RoomFinderX {version} available",
      update_to: "Update to v{version}",
      updates_info_error: "Failed to load info about latest version",
      update_failed: "Application update failed. Try again later",
      unassign_title: "Unassign device",
      unassign_subtitle:
        "Unassigns any assigned Wayfinder object, but the device remains paired.",
      unpair_title: "Unpair device",
      unpair_subtitle:
        "Completely removes the device from the GOGET system, including unassigns the Wayfinder object.",
      wifi_settings_title: "Wi-Fi Settings",
      wifi_settings_subtitle: "Access to OS Wi-Fi Settings and more",
      lan_settings_title: "LAN/Ethernet Settings",
      lan_settings_subtitle: "Access to advanced OS LAN settings and more",
      datetime_settings_title: "Date & Time Settings",
      datetime_settings_subtitle:
        "Access to OS date and time settings and more",
      localization_settings_title: "Localization Settings",
      localization_settings_subtitle: "Access to OS localization settings",
    },
    pairing: {
      pairing_code_title: "Pairing",
      pairing_code_description: "Go to the dashboard, add device and enter:",
      device_paired_title: "Ready",
      device_paired_subtitle: "Waiting for room connections",
      device_paired_label: "Paired",
      new_code_btn_text: "New Code",
      connection_error_title: "Error: no network or server connection",
      connection_error_description:
        "Please check your internet connection or restart the application",
    },
    main_board: {
      events_title: "Events",
      events_list_empty: "No upcoming events",
      event_status_ongoing: "Ongoing",
      event_status_later_today: "Later today",
      rooms_title: "Available",
      rooms_list_empty: "No rooms available",
      room_reserve_description: "Tap room to reserve",
      map_title: "Map view",
      occupancy_desks: "Desks available",
      occupancy_rooms: "Rooms available",
      occupancy_general: "Workspace occupancy",
      occupancy_general_calm: "CALM",
      occupancy_general_crowded: "CROWDED",
      occupancy_general_full: "FULL",
    },
  },
  modals: {
    pin_code: {
      title: "Access identification",
      subtitle: "Enter admin PIN",
      wrong_pin: "Wrong pin code. Please retry",
    },
    room_booking: {
      user_name_field: "Name",
      subject_field: "Subject",
      people_count_field: "People count",
      online_meeting_field: "Online meeting",
      book_now: "Book now",
      book_success: "The room was booked successfully",
      qr_code_description: "Scan to book. Use the GOGET mobile app",
      no_internet_error:
        "No internet connection. Room reservations are not available.",
      in_use: "In use",
      in_use_description: "Ad-hoc reservations not available",
    },
    desk_booking: {
      qr_code_description: "Scan to book. Use the GOGET mobile app",
    },
  },
};
