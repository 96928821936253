import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { useDirectoryContext } from "../../Context/DirectoryContext";

import {
  CalendarType,
  DirectorySyncIntegration,
  GGCalendarProviderStatus,
} from "../../../../api/grpc/calendarprovider/calendarprovider";
import { IntegrationsItem } from "../../../Calendars/CalendarSidebar/Integrations/IntegrationsItem/IntegrationsItem";
import { getCalendarTypeName } from "../../../Calendars/lib/helpers";

interface DirectoryIntegrationsListProps {
  data?: DirectorySyncIntegration[];
}

export const DirectoryIntegrationsList: React.FC<
  PropsWithChildren<DirectoryIntegrationsListProps>
> = ({ data }) => {
  const { selectedUsers, setSelectedUsers } = useDirectoryContext();

  const handleClick = useCallback(() => {
    if (selectedUsers.length) {
      setSelectedUsers([]);
    }
  }, [selectedUsers, setSelectedUsers]);

  const integrations = useMemo(() => {
    if (!data?.length) return null;

    const types = [CalendarType.O365_SYNC, CalendarType.GSUITE_SYNC];

    return types.map((type) => {
      const calendars = data.filter((item) => item.integrationType === type);
      const hasFailedCalendar = data.some(
        (calendar) =>
          calendar.status === GGCalendarProviderStatus.FAILED &&
          calendar.integrationType === type
      );

      return (
        <IntegrationsItem
          key={type}
          type={type}
          title={getCalendarTypeName(type)}
          calendars={calendars}
          isDirectory={true}
          hasFailedCalendar={hasFailedCalendar}
          onClick={handleClick}
        />
      );
    });
  }, [data, handleClick]);

  if (!integrations) return null;

  return (
    <div className="CalendarProviders">
      <div className="Integrations">{integrations}</div>
    </div>
  );
};
