import React from "react";
import cs from "classnames";
import { NavLink } from "react-router-dom";

import { Icon, IconDefinition } from "../../../shared/Icon/Icon";
import { MainResources } from "../../../../lib/types/main.types";

interface Props {
  title: string;
  icon: IconDefinition;
  onClick: () => void;
  type: MainResources | "map";
  to?: string;
}

export const BookingOption = ({ title, icon, onClick, type, to }: Props) => {
  const classes = cs(
    "BookingOption flex-a-center justify-content-between p-3",
    {
      "BookingOption--room": type === "room",
      "BookingOption--desk": type === "desk",
      "BookingOption--map": type === "map",
    }
  );

  const content = (
    <>
      <div className="flex-a-center">
        <Icon icon={icon} />
        <span className="pl-4">{title}</span>
      </div>
      <Icon icon="chevron-simple-right" />
    </>
  );

  return to ? (
    <NavLink to={to} className={classes}>
      {content}
    </NavLink>
  ) : (
    <div className={classes} onClick={onClick}>
      {content}
    </div>
  );
};
