import { createContext, useContext } from "react";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { MainResources } from "../../../types/main.types";

export interface TemplateOption {
  value: string;
  label: string;
  type?: MainResources;
  redirect?: boolean | "workspace";
}

export interface IPickedWorkspaces {
  data: [] | WorkplaceFields[];
  redirect?: boolean;
}

export interface ITemplatesContext {
  activeProfile: TemplateOption | undefined;
  setActiveProfile: React.Dispatch<
    React.SetStateAction<TemplateOption | undefined>
  >;
  pickedWorkspaces: IPickedWorkspaces;
  setPickedWorkspaces: React.Dispatch<React.SetStateAction<IPickedWorkspaces>>;
}

export const TemplatesContext = createContext<ITemplatesContext>({
  activeProfile: undefined,
  setActiveProfile: () => {},
  pickedWorkspaces: { data: [], redirect: false },
  setPickedWorkspaces: () => {},
});

export const useTemplateContext = () => useContext(TemplatesContext);

export const { Provider } = TemplatesContext;
