import React, { useMemo } from "react";
import { FormGroup } from "reactstrap";
import { useDesksQuery } from "../../../lib/hooks/useDesksQuery";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import Select from "react-select";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { selectStyles } from "../../../lib/utils/selectStyles";
import { tooltips } from "../../../lib/utils/tooltips";

interface Props {
  value: string[];
  onChange: (e: { label: string; value: string }) => void;
}

export const DeskSelect = ({ value, onChange }: Props) => {
  const { loading, error, data } = useDesksQuery();

  // show the desk that are free (have no attached used)
  const options = useMemo(() => {
    return (
      data
        .filter((item) => item.account === null)
        .map((item) => ({ label: item.name, value: item.id })) || []
    );
  }, [data]);

  const selectValue = data
    .filter((item) => item.id === value[0])
    .map((item) => ({ label: item.name, value: item.id }));

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<h5>Loading...</h5>}
    >
      <ApolloErrorGuard
        error={error}
        errorComponent={<h4>Error fetching desk resources...</h4>}
      >
        <FormGroup>
          <LabelWithInformationBox
            for="desk"
            title="Assign desk (optional)"
            direction="left"
            message={tooltips.desks.deskSelect}
          />
          <Select
            name="desk"
            isClearable
            isSearchable
            options={options}
            value={selectValue}
            theme={selectStyles}
            onChange={(e) => {
              if (e === null) {
                e = {
                  label: "",
                  value: "",
                };
              }

              onChange(e);
            }}
          />
        </FormGroup>
      </ApolloErrorGuard>
    </HandleLoadingState>
  );
};
