import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { orderBy } from "lodash";
import { differenceInDays, format } from "date-fns";
import cs from "classnames";
import { toast } from "react-toastify";

import { useInsightsContext } from "../../../Context/InsightsContext";

import { ResponsiveLine } from "@nivo/line";
import { TimeRangeEventsResponse } from "../../../../../api/grpc/insights/insights";
import { AggregatedDataType } from "../../types/insightsTypes";

export const LineChart = ({
  chartData,
}: PropsWithChildren<{
  chartData?: TimeRangeEventsResponse;
}>) => {
  const [aggregatedData, setAggregatedData] =
    useState<AggregatedDataType>("day");

  const { startTime, endTime } = useInsightsContext();

  const daysDifference = differenceInDays(endTime, startTime);

  const isWeekLong = daysDifference <= 7;

  const switchAggregatedData = (type: AggregatedDataType) => () => {
    if (aggregatedData === type) {
      return;
    }

    if (type === "month" && daysDifference <= 30) {
      return toast.warn(
        "Aggregated values by month are only available for longer date ranges!"
      );
    }

    setAggregatedData(type);
  };

  useEffect(() => {
    if (aggregatedData === "day") {
      return;
    }

    if (daysDifference <= 30 && aggregatedData === "month") {
      return setAggregatedData("day");
    }
  }, [startTime, endTime]);

  const groupedDataByMonth = useMemo(() => {
    return Object.entries(chartData?.data || {}).reduce(
      (all: any, nextEvent: any) => {
        const dateOfFirstEvent = new Date(nextEvent[0]);

        const key = `${dateOfFirstEvent.getFullYear()}-${
          dateOfFirstEvent.getUTCMonth() + 1
        }-0`;

        const eventOnSameMonth = all.find((v: any) => v.a === key);

        if (eventOnSameMonth) {
          eventOnSameMonth.b += Number(nextEvent[1]);
        } else {
          all.push({
            a: key,
            b: Number(nextEvent[1]),
          });
        }

        return all;
      },
      []
    );
  }, [chartData]);

  const data = useMemo(() => {
    return [
      {
        id: "events",
        color: "blue",
        data:
          daysDifference > 30 && aggregatedData === "month"
            ? orderBy(
                groupedDataByMonth,
                [
                  (item) => {
                    return format(
                      new Date(
                        item.a.split("-")[0],
                        item.a.split("-")[1],
                        item.a.split("-")[2]
                      ),
                      "yyyy-MM-dd"
                    );
                  },
                ],
                ["asc"]
              ).map((i) => {
                return {
                  x: new Date(
                    i.a.split("-")[0],
                    i.a.split("-")[1],
                    i.a.split("-")[2]
                  ),
                  y: i.b,
                };
              })
            : orderBy(
                Object.entries(chartData?.data || {}).map(
                  ({ "0": key, "1": value }) => {
                    return {
                      x: new Date(key),
                      y: value,
                    };
                  }
                ),
                [
                  (item) => {
                    return format(new Date(item.x), "yyyy-MM-dd");
                  },
                ],
                ["asc"]
              ).map((i) => {
                return {
                  x: i.x,
                  y: i.y,
                };
              }),
      },
    ];
  }, [chartData, groupedDataByMonth, daysDifference, aggregatedData]);

  return (
    <>
      <div className="flex-a-center Aggregated">
        <span className="mr-3 label__main">Aggregated values by</span>

        <div
          onClick={switchAggregatedData("day")}
          className={cs(
            "Aggregated__buttons Aggregated__buttons--day",
            aggregatedData === "day" && "Aggregated__buttons--active"
          )}
        >
          Day
        </div>

        <div
          onClick={switchAggregatedData("month")}
          className={cs(
            "Aggregated__buttons Aggregated__buttons--month",
            aggregatedData === "month" && "Aggregated__buttons--active"
          )}
        >
          Month
        </div>
      </div>
      <ResponsiveLine
        data={data}
        colors="#156C79"
        lineWidth={1}
        margin={{ top: 30, right: 30, bottom: 80, left: 60 }}
        tooltip={({ point }) => (
          <div className="ChartBox__tooltip flex-a-center">
            <span className="ChartBox__tooltip--box"></span>
            <span>
              <strong>{point.data.xFormatted}</strong>, Meetings:{" "}
              <strong>{point.data.yFormatted}</strong>
            </span>
          </div>
        )}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          precision:
            daysDifference > 30 && aggregatedData === "month" ? "month" : "day",
          useUTC: false,
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: "linear",
          stacked: false,
        }}
        axisBottom={{
          tickSize: 0,
          format: (tick) =>
            daysDifference > 30
              ? format(new Date(tick), "MMMM")
              : format(new Date(tick), isWeekLong ? "EEEE" : "EEE dd"),
          tickValues: `every ${
            daysDifference > 30
              ? "month"
              : daysDifference > 10
              ? "2 days"
              : "day"
          }`,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 25,
          format: (e) => Math.floor(e) === e && e,
        }}
        enableGridY={false}
        enablePoints={false}
        enableArea={true}
        areaOpacity={1}
        curve="monotoneX"
        useMesh
        animate
        isInteractive
        theme={{
          textColor: "#9C9C9C",
          tooltip: {
            container: {
              borderRadius: 12,
            },
          },
        }}
        defs={[
          {
            id: "gradientC",
            type: "linearGradient",
            colors: [
              { offset: 20, color: "#13807E" },
              { offset: 100, color: "#00EA96" },
            ],
          },
        ]}
        fill={[{ match: "*", id: "gradientC" }]}
      />
    </>
  );
};
