import React from "react";
import { Icon } from "../../../../Icon/Icon";

export const NoFloorSelected = () => {
  return (
    <div className="NoFloorSelected flex-a-center pt-2">
      <Icon icon="warning-triangle" />
      <span className="label__main pl-1">No floor selected</span>
    </div>
  );
};
