import React from "react";
import { WorkplaceTypes } from "../../Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

import { DeleteButton } from "../DeleteButton/DeleteButton";
import { Icon } from "../Icon/Icon";
import { InformationBox } from "../InformationBox/InformationBox";

import "./ResourceActions.scss";

interface Props {
  message: string;
  onDelete: () => void;
  type?: WorkplaceTypes;
  onTemplateClick?: () => void;
}

export const ResourceActions = (props: Props) => {
  const { message, type, onTemplateClick } = props;

  return (
    <div className="ResourceActions d-flex align-items-center">
      <InformationBox message={message} />
      {["Desk", "Room"].includes(type as WorkplaceTypes) && (
        <Icon
          className="ResourceActions__template"
          onClick={() => {
            if (onTemplateClick) {
              return onTemplateClick();
            }
          }}
          icon={
            type === "Room"
              ? "room_template_icon_18x18"
              : "desk_template_icon_18x18"
          }
        />
      )}
      <DeleteButton onClick={props.onDelete} />
    </div>
  );
};
