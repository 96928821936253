import React from "react";
import { Input } from "../Input/Input";
import { FormGroup, Label } from "reactstrap";
import cs from "classnames";

interface Props {
  id: string;
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  hideLabel?: boolean;
}

export const CheckboxButton = ({
  id,
  name,
  checked,
  onChange,
  className,
  hideLabel,
}: Props) => {
  const classes = cs("mb-0 mt-1", className);

  return (
    <FormGroup className={classes}>
      <Input
        type="checkbox"
        id={id}
        htmlFor={id}
        name={name}
        value={id}
        checked={checked}
        onChange={onChange}
      />

      <Label htmlFor={id} className="checkbox_label">
        <span className="checkbox_box"></span>
        {!hideLabel && (
          <span className="checkbox_name text-nowrap">{name}</span>
        )}
      </Label>
    </FormGroup>
  );
};
