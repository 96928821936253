import { toast } from "react-toastify";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { openCustomerPortal } from "../../Subscription/lib/openCustomerPortal";

export const useOpenBillingInfo = (refetch: () => void) => {
  const { user, tokenInterceptor, chargebeeProperties } = useAuthContext();

  const handleBillingModal = async () => {
    try {
      const customerPortal = await openCustomerPortal(
        user?.customerid || "",
        tokenInterceptor
      );

      return customerPortal?.cbPortal.open(
        {
          close: () => refetch(),
        },
        chargebeeProperties("ADDRESS")
      );
    } catch (error: any) {
      toast.error(
        "Failed to fetch your data to open widget, please reload and try again!"
      );
      console.log(error);
    }
  };

  return { handleBillingModal };
};
