import React, { useState } from "react";
import { TeamItem } from "../TeamItem/TeamItem";
import { EditTeam } from "../EditTeam/EditTeam";

import teamData from "./teamData.json";
import "./TeamList.scss";

export const TeamList = () => {
  const [selectedTeam, setSelectedTeam] = useState<any | undefined>(undefined);

  const handleRowClick = (index: number) => () => {
    const teamDetails = teamData[index - 1];

    setSelectedTeam(() => {
      return {
        id: teamDetails.id,
        name: teamDetails.name,
        location: teamDetails.location,
        member: teamDetails.member,
      };
    });
  };

  return (
    <div className="mt-4">
      {/* <ResourceFilter /> */}

      <div className="TeamList__teams mt-4">
        {teamData.map((team) => (
          <TeamItem
            key={team.id}
            team={team}
            onClick={handleRowClick(team.id)}
          />
        ))}
      </div>
      {selectedTeam !== undefined && (
        <EditTeam
          isOpen={selectedTeam !== undefined}
          toggle={() => setSelectedTeam(undefined)}
          data={selectedTeam}
        />
      )}
    </div>
  );
};
