import React, { FC } from "react";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";
import { Resource } from "@gogetcorp/floor-map-bridge";
import { format } from "date-fns";
import { formatDateName } from "../utils/date.utils";

type ReservationIconParams = {
  isActive: boolean;
  reservations: Resource.Reservation[];
};

type AvatarProps = {
  avatarUrl?: string;
  name: string;
};

const Avatar: FC<AvatarProps> = ({ avatarUrl, name }) => {
  return (
    <div
      style={{
        width: 30,
        height: 30,
        borderRadius: 15,
        overflow: "hidden",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#fff",
        borderWidth: 2,
        boxSizing: "border-box",
        border: "1px solid #fff",
      }}
    >
      {avatarUrl ? (
        <img
          style={{ width: "100%", height: "100%" }}
          src={avatarUrl}
          alt={name}
        />
      ) : (
        <div
          style={{
            fontFamily: "FuturaEF",
            fontSize: 18,
            textTransform: "uppercase",
          }}
        >
          {name[0]}
        </div>
      )}
    </div>
  );
};

export const RESERVATION_MARKER_WIDTH_PX = 240;

const ReservationsIcon = ({
  isActive,
  reservations,
}: ReservationIconParams) => {
  if (reservations.length === 0) {
    return;
  }

  const dateName =
    reservations[0].timeSlots.length > 0
      ? formatDateName(new Date(reservations[0].timeSlots[0].startTime))
      : "unknown";

  return new L.DivIcon({
    html: ReactDOMServer.renderToString(
      <div style={{ width: 1, height: 1 }}>
        <div
          style={{
            position: "absolute",
            left: 13,
            bottom: 13,
            display: "flex",
            background: "#0D99FF",
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px 20px 20px 1px",
            ...(isActive
              ? {
                  flexDirection: "column",
                  rowGap: 10,
                  width: RESERVATION_MARKER_WIDTH_PX,
                  padding: "8px 10px 8px 10px",
                }
              : { padding: 4 }),
          }}
        >
          {isActive
            ? reservations.map((reservation, index) => {
                return (
                  <>
                    {index > 0 && (
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                          backgroundColor: "rgba(255, 255, 255, 0.4)",
                        }}
                      />
                    )}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 1fr",
                        columnGap: "9px",
                        flex: 1,
                      }}
                    >
                      <Avatar
                        name={reservation.organizer.name}
                        avatarUrl={reservation.organizer.avatarUrl}
                      />
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr auto",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "FuturaEF,sans-serif",
                              fontSize: "16px",
                              fontWeight: 600,
                              lineHeight: "19px",
                              color: "#fff",
                              textTransform: "uppercase",
                              flex: 1,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {reservation.organizer.name}
                          </div>
                          {index === 0 && (
                            <div
                              style={{
                                fontFamily: "FuturaEF,sans-serif",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                color: "rgba(255, 255, 255, 0.5)",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {dateName}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            fontFamily: "FuturaEF,sans-serif",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "16px",
                            color: "#fff",
                            marginTop: 6,
                          }}
                        >
                          {reservation.timeSlots
                            .map(
                              (timeSlot) =>
                                timeSlot.formatted ||
                                `${format(
                                  new Date(timeSlot.startTime),
                                  "HH:mm"
                                )}-${format(
                                  new Date(timeSlot.endTime),
                                  "HH:mm"
                                )}`
                            )
                            .join(", ")}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : reservations.map((reservation, index) => (
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: index === 1 ? -15 : 0,
                    zIndex: reservations.length - index,
                    borderColor: "#fff",
                    borderWidth: 2,
                    boxSizing: "border-box",
                    textTransform: "uppercase",
                    border: "1px solid #fff",
                  }}
                >
                  {reservation.organizer.avatarUrl ? (
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={reservation.organizer.avatarUrl}
                      alt={reservation.organizer.name}
                    />
                  ) : (
                    <div style={{ fontFamily: "FuturaEF", fontSize: 18 }}>
                      {reservation.organizer.name[0]}
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>
    ),
    iconSize: [1, 1],
    iconAnchor: [0, 0],
  });
};

export { ReservationsIcon };
