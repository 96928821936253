import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useRouter } from "../../../../lib/hooks/useRouter";

import { EditWorkplace } from "../EditWorkplace/EditWorkplace";
import { DefaultWorkplaces } from "../../../../components/Workplaces/DefaultWorkplaces/DefaultWorkplaces";

import "./WorkplacesContent.scss";

export const WorkplacesContent = () => {
  const {
    match: { url },
  } = useRouter();

  return (
    <div className="WorkplacesContent">
      <div className="WorkplacesContent__main">
        <Switch>
          <Route exact path={url} component={DefaultWorkplaces} />

          <Route path={`${url}/:type/:id`} component={EditWorkplace} />

          <Redirect to={url} />
        </Switch>
      </div>
    </div>
  );
};
