import React, { useCallback } from "react";
import cs from "classnames";
import { toast } from "react-toastify";

import { listRoomEquipment } from "../../../../lib/helpers/listRoomEquipment";

import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";

import { MainResources } from "../../../../lib/types/main.types";
import { Icon } from "../../../shared/Icon/Icon";
import { ReservationCardElement } from "./ReservationCardElement";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import { LocationPath } from "../../../Bookings/shared/LocationPath/LocationPath";
import { RoomEquipment } from "../../../../api/grpc/booking/booking";
import { AccountInterestedWorkplaces } from "../../../../api/grpc/workplaces/workplaces";
import { FavoriteWorkplaceEnum } from "../../../../api/grpc/account/account";

interface Props {
  type: MainResources;
  workplaceID: string;
  name: string;
  tags: string;
  locationPath: DeskLocationItem[];
  equipment?: RoomEquipment;
  roomNumberOfSeats?: number;
}

export const ReservationCardHeader = ({
  type,
  workplaceID,
  tags,
  name,
  locationPath,
  equipment,
  roomNumberOfSeats,
}: Props) => {
  const { createInterestedWorkplaces, deleteInterestedWorkplaces } =
    useWorkplacesClient();
  const { updateUser, user } = useAuthContext();

  const addFavoriteWorkspace = useCallback(async () => {
    const currentFavoriteWorkplaces = user?.favoriteWorkplaces || [];

    const workspaceType: AccountInterestedWorkplaces =
      type === "desk"
        ? AccountInterestedWorkplaces.AccountInterestedWorkplaces_DESK
        : AccountInterestedWorkplaces.AccountInterestedWorkplaces_ROOM;

    // optimistic update
    updateUser({
      favoriteWorkplaces: [
        ...currentFavoriteWorkplaces,
        {
          id: workplaceID,
          type:
            type === "desk"
              ? FavoriteWorkplaceEnum.FavoriteWorkplace_DESK
              : FavoriteWorkplaceEnum.FavoriteWorkplace_ROOM,
        },
      ],
    });

    try {
      await createInterestedWorkplaces({
        locationId: workplaceID,
        type: workspaceType,
      });
    } catch (e: any) {
      toast.error(e?.message);
      updateUser({
        favoriteWorkplaces: currentFavoriteWorkplaces,
      });
    }
  }, [user, createInterestedWorkplaces]);

  const deletefavoriteWorkspace = useCallback(async () => {
    // optimistic update
    updateUser({
      favoriteWorkplaces: user?.favoriteWorkplaces?.filter(
        (item) => item.id !== workplaceID
      ),
    });

    try {
      await deleteInterestedWorkplaces({
        locationId: workplaceID,
      });
    } catch (e: any) {
      toast.error(e?.message);
      updateUser({
        favoriteWorkplaces: user?.favoriteWorkplaces,
      });
      console.error(e);
    }
  }, [user, deleteInterestedWorkplaces]);

  const worksapceIsAddedToFavorites = user?.favoriteWorkplaces?.some(
    (item) => item.id === workplaceID
  );

  return (
    <div className="ReservationCardHeader">
      <div className="flex-a-center ReservationCard__head justify-content-between mb-3">
        <div className="flex-a-center ReservationListCard__icon overflow-text">
          <Icon
            icon={type === "room" ? "room_icon_18x18" : "desk_icon_18x18"}
          />
          <h6 className="pl-2 pr-2 overflow-text text-black">{name}</h6>
        </div>

        <div className="flex-a-center">
          {type === "room" && (
            <div className="ReservationCardHeader__seats mr-2 flex-a-center">
              <Icon icon="user-rounded" />
              <h6 className="pl-2 text-pro">{roomNumberOfSeats || 0}</h6>
            </div>
          )}

          <div className="ReservationCardHeader__favorite">
            <Icon
              icon="star-icon"
              className={cs("cursor-pointer", {
                ReservationCardHeader__active: worksapceIsAddedToFavorites,
              })}
              onClick={() => {
                if (worksapceIsAddedToFavorites) {
                  deletefavoriteWorkspace();
                } else {
                  addFavoriteWorkspace();
                }
              }}
            />

            <div className="ReservationCardHeader__favorite--tooltip text-pro">
              Mark as favorite to preselect for later.
            </div>
          </div>
        </div>
      </div>

      <ReservationCardElement
        icon="tags-mobile"
        text={tags.length > 0 ? tags : "-"}
        className="mb-2"
        showTooltip={tags.length > 0}
      />

      {type === "room" ? (
        <ReservationCardElement
          icon="equipment-mobile"
          text={equipment ? listRoomEquipment(equipment).join(", ") : "-"}
          showTooltip={
            equipment ? listRoomEquipment(equipment).length > 0 : false
          }
        />
      ) : (
        <ReservationCardElement
          icon="location-icon"
          text={locationPath.length > 0 ? undefined : "All locations"}
          showTooltip={locationPath.length > 0}
          element={
            locationPath.length > 0 ? (
              <LocationPath location={locationPath} />
            ) : undefined
          }
        />
      )}
    </div>
  );
};
