import React, { PropsWithChildren } from "react";
import { Subscription } from "../../../../api/grpc/subscription/subscription";
import { capitalizeString } from "../../../../lib/helpers/capitalizeString";
import { tooltips } from "../../../../lib/utils/tooltips";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";
import {
  StatusInformationKeys,
  SubscriptionStatus,
} from "../../../Subscription/SubscriptionStatus/SubscriptionStatus";

import "./CustomerSubscriptionsTable.scss";

export const CustomerSubscriptionsTable = ({
  subscriptions,
}: PropsWithChildren<{
  subscriptions: Subscription[];
}>) => {
  const columns: StructureTableColumn[] = [
    {
      key: "status",
      header: (
        <div className="flex-a-center gap1">
          <span className="pr-1">Status</span>
          <InformationBox
            message={tooltips.organizationSettings.customerSettings.status}
          />
        </div>
      ),
    },
    {
      key: "customer_info",
      header: (
        <div className="flex-a-center gap1">
          <span className="pr-1">Customer info</span>
          <InformationBox
            message={
              tooltips.organizationSettings.customerSettings.customerInfo
            }
          />
        </div>
      ),
    },
    {
      key: "subscription_plan",
      header: (
        <div className="flex-a-center gap1">
          <span className="pr-1">Subscription plan</span>
          <InformationBox
            message={
              tooltips.organizationSettings.customerSettings.subscriptionPlan
            }
          />
        </div>
      ),
    },
    {
      key: "subscription_items",
      header: (
        <div className="flex-a-center gap1">
          <span className="pr-1">Subscription items</span>
          <InformationBox
            message={
              tooltips.organizationSettings.customerSettings.subscriptionItems
            }
            direction="left"
          />
        </div>
      ),
    },
    {
      key: "next_renewal",
      header: (
        <div className="flex-a-center gap1">
          <span className="pr-1 text-nowrap">Expiry date</span>
          <InformationBox
            message={tooltips.organizationSettings.customerSettings.expiryDate}
            direction="left"
          />
        </div>
      ),
    },
  ];

  const rows: StructureTableRows[] = subscriptions.map((item) => {
    return {
      status: (
        <SubscriptionStatus
          status={item.status.toLowerCase() as StatusInformationKeys}
        />
      ),
      customer_info: (
        <div className="flex-shrink-1 flex-grow-1 flex-basis-0">
          <span className="text-break">{item.company}</span>
          <span className="d-block text-break">{item.email}</span>
        </div>
      ),
      subscription_plan: <div>{item.name}</div>,
      subscription_items: item.addons.map((addon) => (
        <div className="SubscriptionItemsCount">
          <span>{capitalizeString(addon.name)}</span>
          <span>{addon.quantity}x</span>
        </div>
      )),
      next_renewal: <span>{item.nextRenewal || item.cancelDate}</span>,
    };
  });

  return (
    <StructureTable
      columns={columns}
      rows={rows}
      className="CustomerSubscriptionsTable mt-4"
      small
    />
  );
};
