import React from "react";

import { CenteredBox } from "../CenteredBox/CenteredBox";
import { InformationBox } from "../InformationBox/InformationBox";

import "./CenteredInformationBox.scss";

interface Props {
  message: string;
  infoMessage: string;
}

export const CenteredInformationBox = (props: Props) => {
  const { message, infoMessage } = props;

  return (
    <CenteredBox className="CenteredInformationBox">
      <InformationBox message={infoMessage} />
      <h3>{message}</h3>
    </CenteredBox>
  );
};
