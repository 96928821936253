import { CalendarType } from "../../../api/grpc/calendarprovider/calendarprovider";
import { IconDefinition } from "../../shared/Icon/Icon.generated";

const items: { [key in CalendarType]: { name: string; slug: string } } = {
  [CalendarType.ALL]: { name: "ALL", slug: "" },
  [CalendarType.EWS]: { name: "Exchange EWS", slug: "ews" },
  [CalendarType.GSUITE]: { name: "Google Workspace", slug: "gSuite" },
  [CalendarType.O365]: { name: "Microsoft 365", slug: "o365" },
  [CalendarType.GSUITE_MOBILE]: {
    name: "Google Workspace Mobile",
    slug: "gSuiteMobile",
  },
  [CalendarType.GSUITE_WEB]: {
    name: "Google Workspace Web",
    slug: "gSuiteWeb",
  },
  [CalendarType.O365_MOBILE]: {
    name: "Microsoft 365 Mobile + Teams",
    slug: "o365Mobile",
  },
  [CalendarType.O365_WEB]: {
    name: "Microsoft 365 Web + Teams",
    slug: "o365Web",
  },
  [CalendarType.GSUITE_SYNC]: {
    name: "Google Workspace",
    slug: "gSuiteSync",
  },
  [CalendarType.O365_SYNC]: {
    name: "Microsoft 365",
    slug: "o365WebSync",
  },
};

export const getCalendarTypeName = (type: CalendarType) => items[type].name;

export const getCalendarTypeSlug = (type: CalendarType) => items[type].slug;

export const getCalendarIcon: (id: string) => IconDefinition = (id: string) => {
  if (id.toLowerCase().startsWith("gsuite")) {
    return "google_wp";
  }

  if (id.toLowerCase().startsWith("o365")) {
    return "microsoft-365";
  }

  return "exchange_ews";
};
