import React from "react";
import { Switch } from "../../../shared/Switch/Switch";

interface Props {
  makePrivate: boolean;
  isOnlineMeeting: boolean;
  onChangePrivate: (value: boolean) => void;
  onChangeOnlineMeeting: (value: boolean) => void;
}

export const RoomBookingInputs = ({
  makePrivate,
  isOnlineMeeting,
  onChangePrivate,
  onChangeOnlineMeeting,
}: Props) => {
  return (
    <div className="flex-a-center justify-content-between gap-min">
      <div className="flex-a-center SearchWorkplaceForm__switch justify-content-center">
        <Switch
          name="makePrivate"
          value={makePrivate}
          onChange={onChangePrivate}
          subTitle="Make private"
        />
      </div>

      <div className="flex-a-center SearchWorkplaceForm__switch justify-content-center">
        <Switch
          name="isOnlineMeeting"
          value={isOnlineMeeting}
          title="Add virtual meeting"
          onChange={onChangeOnlineMeeting}
        />
      </div>
    </div>
  );
};
