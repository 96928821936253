import React, { PropsWithChildren } from "react";
import { differenceInDays, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../../../../lib/context/TimeZone/TimeZoneContext";
import { useCalulactorProContext } from "../../Context/CalculatorProContext";

import { LabelWithInformationBox } from "../../../../../shared/LabelWithInformationBox/LabelWithInformationBox";

import "./ProRate.scss";

export const ProRate = ({
  itemPrice,
  userItemPrice,
}: PropsWithChildren<{
  itemPrice: number;
  userItemPrice: number;
}>) => {
  const { subscription, selectedDate } = useCalulactorProContext();
  const { timeZone } = useTimeZoneContext();

  const isThreeYearsSub = subscription?.name.toLowerCase().includes("3");

  const dayDifference = differenceInDays(
    utcToZonedTime(
      new Date(subscription?.nextRenewal || subscription?.cancelDate || ""),
      timeZone
    ),
    utcToZonedTime(new Date(selectedDate), timeZone)
  );

  const percentage = (dayDifference + 1) / (365 * (isThreeYearsSub ? 3 : 1));

  const displayFormattedNumber = (inputNumber: string) => {
    let number = parseFloat(inputNumber);

    if (isNaN(number)) {
      return "Invalid Number";
    }

    let roundedNumber = Math.round(number * 10) / 10;

    let formattedNumber =
      roundedNumber % 1 === 0
        ? roundedNumber.toFixed(0)
        : roundedNumber.toFixed(1);

    return formattedNumber;
  };

  return (
    <div className="ProRate">
      <LabelWithInformationBox
        title="This subscription item should be pro-rated at:"
        className="justify-content-center mb-3"
      />

      <div className="flex-a-center ProRate__final">
        <div className="ProRate__result">
          <h6>{(percentage * 100).toFixed(0) + "%"}</h6>
          <LabelWithInformationBox
            title={`${dayDifference + 1} of ${
              isThreeYearsSub ? "1095" : "365"
            } days`}
          />
        </div>

        <div className="divider"></div>

        <div>
          <LabelWithInformationBox
            title={`Quote your customer: ${displayFormattedNumber(
              String(itemPrice * +percentage.toFixed(2))
            )}`}
          />

          <LabelWithInformationBox
            title={`Price from GOGET: ${displayFormattedNumber(
              String(userItemPrice * +percentage.toFixed(2))
            )}`}
          />
        </div>
      </div>

      <div className="ProRate__data">
        <LabelWithInformationBox title="Data:" />
        <LabelWithInformationBox
          title={`Subscription plan: ${subscription?.name}`}
        />
        <LabelWithInformationBox
          title={`Activation date: ${format(
            new Date(selectedDate),
            "yyyy-MM-dd"
          )}`}
        />
        <LabelWithInformationBox
          title={`Expiry date: ${
            subscription?.nextRenewal || subscription?.cancelDate
          }`}
        />
      </div>

      <div className="ProRate__general">
        <h5>General example:</h5>
        <span>
          If the client pays $100 for the full subscription period, and if
          pro-rating is, for example, 40%, they should pay $40 for additional
          subscription items.
        </span>
        <span>Calculation steps: full price $100 x 0.4 = $40.</span>
        <span>
          The same pro-rating percentage will be applied by GOGET when ordering
          from us. Therefore, you will pay 40% of the full price as stated in
          our Channel Partner price lists for the additional subscription items.
        </span>
      </div>
    </div>
  );
};
