import React from "react";
import {
  DeskHistoryEventsResponse,
  DeskInsightsOverviewResponse,
  GeneralStatsByDesksResponse,
  TimeRangeEventsResponse,
} from "../../../../../api/grpc/insights/insights";
import { OrganizerFilter } from "../../Room/Overview/RoomInsightsContextProvider";

export interface OverviewInsights {
  loadingOverview: boolean;
  errorOverview?: Error;
  dataOverview?: DeskInsightsOverviewResponse;
  loadingGeneral: boolean;
  errorGeneral?: Error;
  dataGeneral?: GeneralStatsByDesksResponse;
  loadingRange: boolean;
  errorRange?: Error;
  dataRange?: TimeRangeEventsResponse;
  loadingDeskHistory: boolean;
  errorDeskHistory?: Error;
  dataDeskHistory?: DeskHistoryEventsResponse;
  organizers?: OrganizerFilter[];
  setOrganizers: React.Dispatch<
    React.SetStateAction<OrganizerFilter[] | undefined>
  >;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
}

const values: OverviewInsights = {
  loadingOverview: false,
  errorOverview: undefined,
  dataOverview: undefined,
  loadingGeneral: false,
  errorGeneral: undefined,
  dataGeneral: undefined,
  loadingRange: false,
  errorRange: undefined,
  dataRange: undefined,
  loadingDeskHistory: false,
  errorDeskHistory: undefined,
  dataDeskHistory: undefined,
  organizers: [],
  setOrganizers: () => {},
  offset: 0,
  setOffset: () => {},
};

export const OverviewInsightsContext = React.createContext(values);

export const useDeskOverviewInsightsContext = () => {
  const context = React.useContext(OverviewInsightsContext);

  if (!context) {
    throw new Error(
      "useDeskOverviewInsightsContext must be used inside of OverviewInsightsContext"
    );
  }

  return context;
};
