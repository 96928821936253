import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouter } from "../../lib/hooks/useRouter";
import { tooltips } from "../../lib/utils/tooltips";

import { PageHeader } from "../../components/shared/PageHeader/PageHeader";
import { ResourceNavigation } from "../../components/shared/ResourceNavigation/ResourceNavigation";
import { OrganizationSettings } from "../../components/Organization/OrganizationSettings/OrganizationSettings";

import { SubRouteProps } from "../../lib/routes/routes";

import "./Organization.scss";

export const Organization: FC = () => {
  const router = useRouter();

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Organization Settings",
      to: `${router.match.url}/organization-settings`,
      id: "organization-settingss",
      render: () => <OrganizationSettings />,
    },
  ];
  return (
    <div className="default-page">
      <PageHeader
        title="General"
        hideBorder
        message={tooltips.organizationSettings.generalSettings.header}
      />
      <div className="OrganizationMain">
        <ResourceNavigation routes={routes} />
        <Switch>
          {routes.map((item) => (
            <Route
              key={item.id}
              path={item.to}
              render={item.render}
              exact={item.exact ? true : undefined}
            />
          ))}
          <Redirect to={routes[0].to} />
        </Switch>
      </div>
    </div>
  );
};
