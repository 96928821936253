import React, { useState } from "react";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useRegisterFormik } from "../../components/Register/lib/useRegisterFormik";

import { RegisterForm } from "../../components/Register/RegisterForm";

import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";
import { RegisterConfirmation } from "../../components/Register/RegisterConfirmation/RegisterConfirmation";
import { RegisterCaution } from "../../components/Register/RegisterCaution/RegisterCaution";

export const Register = () => {
  const authCtx = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [showCaution, setShowCaution] = useState(false);
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const [showThankYou, setShowThankYou] = useState(false);

  const formik = useRegisterFormik({
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const name = `${values.firstName} ${values.lastName}`;

        await authCtx.createCustomer({
          customer: {
            id: "",
            vat: "",
            name: name,
            vatStatus: "",
            interested: values.interest,
            companyName: values.companyName,
            deskNumber: values.deskNumber,
            password: values.accountPassword,
            roomsNumber: values.numberOfRooms,
            whenYouNeed: values.delivery,
            recoveryEmail: "",
            billingDetails: {
              billingId: 0,
              address: "",
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.workEmail.toLowerCase(),
              city: "",
              country: values.country,
              countryCode: "",
              phone: "",
              postalCode: "",
              state: "",
              line1: "",
              line2: "",
              line3: "",
              currency: "",
            },
          },
          validateDomain: !showCaution,
        });

        toast.success("Account created successfully!");

        setShowThankYou(true);
      } catch (error: any) {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }

        if (
          error?.message ===
          "Customer with same email domain already exists in db"
        ) {
          setShowCaution(true);
          return;
        }

        toast.error(
          error.message ? error.message : "Account couldn't be created!"
        );
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <AuthenticationPage
      isDefault={true}
      formTitle={showCaution ? undefined : "Register"}
      hideFormTitle={showThankYou}
      formContentClassname="AuthenticationPage__register"
    >
      {showThankYou ? (
        <RegisterConfirmation email={formik.values.workEmail.toLowerCase()} />
      ) : showCaution ? (
        <RegisterCaution
          handleContinue={formik.handleSubmit}
          email={"@" + formik.values.workEmail.toLowerCase().split("@")[1]}
          loading={loading}
        />
      ) : (
        <RegisterForm
          formik={formik}
          loading={loading}
          recaptchaRef={recaptchaRef}
        />
      )}
    </AuthenticationPage>
  );
};
