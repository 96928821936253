import gql from "graphql-tag";

import { WorkplaceTenantFields, WorkplaceFields } from "../workplaces";

export type Marker = {
  latitude: number;
  longitude: number;
};

export type Polygonal = {
  id?: string;
  type: string;
  latitude: number;
  longitude: number;
};

export type CircleShape = {
  latitude: number;
  longitude: number;
  radius: number;
};

export type RoomDirection = {
  id?: string;
  distance: number;
  direction: number;
  room?: {
    id: string;
    name: string;
  };
};

export type CustomLanguageType = {
  id: string;
  value: string;
  isGlobal: boolean;
  name: string;
};

export const defaultDisplaySettings = {
  showOrganizer: true,
  showAttendees: true,
  deleteMeeting: true,
  extendMeeting: true,
  endMeetingEarly: true,
  showYourNameInput: true,
  onScreenReservation: true,
  pendingTimeAfterMeeting: 15,
  pendingTimeBeforeMeeting: 15,
  contentBoardActivated: false,
  energySaveMode: false,
  contentBoardURL: "",
  bwFilter: false,
};

export interface FormikDisplaySettingsFields {
  main: {
    adminPin: string;
    backgroundImageUrl: string;
    logoImageUrl: string;
  };
  display: {
    showOrganizer: boolean;
    showMeetingTitle: boolean;
    showAttendees: boolean;
    showRoomCapacity: boolean;
    showRoomAmeneties: boolean;
    showCheckinRequired: boolean;
    checkinReminder: boolean;
    pendingTimeAfterMeeting: number;
    pendingTimeBeforeMeeting: number;
    soonFree: boolean;
    energySaveMode: boolean;
    energySaveModeStart: string;
    energySaveModeEnd: string;
    qrCodeRequired: boolean;
    textScaling: "small" | "normal" | "large" | string;
    contentBoardActivated: boolean;
    contentBoardURL: string;
    enableScreenLock: boolean;
    screenLock: string;
    customerId: string;
    customLanguage?: CustomLanguageType;
    bwFilter: boolean;
    workingWeekDays: number[];
  };
  reservation: {
    onScreenReservation: {
      showMeetingTitleInput: boolean | undefined;
      requireMeetingTitleInput: boolean | undefined;
      showYourNameInput: boolean | undefined;
      requireYourNameInput: boolean | undefined;
      enforceWorkingHours: boolean | undefined;
      workingHrsActivated: boolean | undefined;
      requireHeadcount: boolean | undefined;
      oneTimePinProtection: boolean | undefined;
      workingHrsStart?: string;
      workingHrsEnd?: string;
    } | null;
    deleteMeeting: boolean;
    endMeetingEarly: boolean;
    extendMeeting: boolean;
    nearbyRooms: string[];
    mapView: boolean;
    nearbyEnabled: boolean;
    floor: {
      id: string;
    } | null;
  };
}

export interface DisplaySettingsFields {
  adminPin?: string;
  backgroundImageUrl?: string;
  logoImageUrl?: string;

  showOrganizer?: boolean;
  showMeetingTitle?: boolean;
  showAttendees?: boolean;
  showRoomCapacity?: boolean;
  showRoomAmeneties?: boolean;
  showCheckinRequired?: boolean;
  checkinReminder?: boolean;
  pendingTimeBeforeMeeting?: number;
  pendingTimeAfterMeeting?: number;
  soonFree?: boolean;
  energySaveMode?: boolean;
  energySaveModeStart?: string;
  energySaveModeEnd?: string;
  qrCodeRequired?: boolean;
  textScaling?: string;
  contentBoardActivated?: boolean;
  contentBoardURL?: string;
  enableScreenLock?: boolean;
  screenLock?: string;
  bwFilter?: boolean;
  workingWeekDays?: number[];
  customerId?: string;

  onScreenReservation?: boolean;
  showMeetingTitleInput?: boolean;
  requireMeetingTitleInput?: boolean;
  showYourNameInput?: boolean;
  workingHrsActivated?: boolean;
  requireHeadcount?: boolean;
  oneTimePinProtection?: boolean;
  customLanguage?: CustomLanguageType;
  workingHrsStart?: string;
  workingHrsEnd?: string;
  requireYourNameInput?: boolean;
  enforceWorkingHours?: boolean;
  deleteMeeting?: boolean;
  endMeetingEarly?: boolean;
  extendMeeting?: boolean;
  nearbyRooms?: string[];
  mapView?: boolean;
  floor?: {
    id: string;
  } | null;
  contactlessQR?: boolean;
  nearbyEnabled?: boolean;
}

export type Amenity =
  | "accessibility"
  | "casting"
  | "climate"
  | "desk"
  | "audio"
  | "chargingPort"
  | "presentationTech"
  | "videoConference"
  | "reportingEmail";

export interface RoomAmenities {
  desk?: boolean;
  audio?: boolean;
  casting?: boolean;
  climate?: boolean;
  chargingPort?: boolean;
  reportingEmail?: string;
  accessibility?: boolean;
  videoConference?: boolean;
  presentationTech?: boolean;
}

export interface RoomFields extends WorkplaceFields {
  tags: string[];
  description: string;
  licensed: boolean;
  calendarId: string;
  numberOfSeats: number;
  calendarProviderActive: boolean;
  brokenAmenitiesReporting?: boolean;
  amenities: RoomAmenities;
  calendarProviderId: string;
  devices: WorkplaceFields[];
  displaySettings: DisplaySettingsFields | null;
  isBooked: boolean;
  marker: Marker;
  circleShape: CircleShape;
  polygonal: Polygonal[];
  roomDirection: RoomDirection[];
  resourceEmail: string;
  isBlocked: boolean;
}

export interface GetRoomResponse {
  getRoom: WorkplaceFields;
}

export interface RoomSelectFields {
  id: string;
  name: string;
  devices: [
    {
      id: string;
      name: string;
    }
  ];
}

export interface GetRoomsSelectResponse {
  queryRoom: RoomSelectFields[];
}

export interface UpdateRoomResponse {
  updateRoom: {
    room: RoomFields[];
  };
}

export interface RoomRequestVariables extends WorkplaceTenantFields {
  customerId?: string;
  name: string;
  tags: string[];
  description: string;
  calendarId: String;
  resourceEmail: String;
  numberOfSeats: number;
  calendarProviderActive: boolean;
  isBooked?: boolean;
  showCheckInTime: boolean;
  checkInTime: number;
  marker: Marker;
  circleShape: CircleShape;
  polygonal: Polygonal[];
  roomDirection: RoomDirection[];
  calendarProviderId: String;
  amenities: RoomAmenities;
  brokenAmenitiesReporting?: boolean;
  displaySettings?: DisplaySettingsFields;
  workingWeekDays?: number[];
  nearbyEnabled?: boolean;
  isBlocked: boolean;
  remove?: {
    tags: string[];
  };
}

export interface UpdateRoomLicenseVariables extends WorkplaceTenantFields {
  licensed: boolean;
}

export interface UpdateRoomMarkerVariables extends WorkplaceTenantFields {
  marker: Marker;
}

export interface UpdateRoomCircleVariables extends WorkplaceTenantFields {
  circleShape: CircleShape;
}

export interface UpdateRoomPolygonalVariables extends WorkplaceTenantFields {
  polygonal: Polygonal[];
  polygonalToDelete: { id: string }[];
}

export interface UpdateRoomDirectionVariables extends WorkplaceTenantFields {
  roomDirection: RoomDirection[];
}

export interface UpdateRoomDisplaySettingsVariables {
  id: string;
  displaySettings: DisplaySettingsFields;
}

export interface AddRoomResponse {
  addRoom: {
    room: WorkplaceFields[];
  };
}

export const ROOM_DISPLAY_SETTINGS_FRAGMENT = gql`
  fragment RoomDisplaySettings on Room {
    displaySettings {
      id

      adminPin
      backgroundImageUrl
      logoImageUrl

      showOrganizer
      showMeetingTitle
      showAttendees
      showRoomCapacity
      showRoomAmeneties
      showCheckinRequired
      checkinReminder
      pendingTimeBeforeMeeting
      pendingTimeAfterMeeting
      soonFree
      energySaveMode
      energySaveModeStart
      energySaveModeEnd
      qrCodeRequired
      contentBoardActivated
      contentBoardURL
      enableScreenLock
      screenLock
      bwFilter
      workingWeekDays
      customerId
      customLanguage {
        id
        value
        isGlobal
        name
      }

      textScaling
      nearbyEnabled
      onScreenReservation
      showMeetingTitleInput
      requireMeetingTitleInput
      showYourNameInput
      workingHrsActivated
      requireHeadcount
      oneTimePinProtection
      workingHrsStart
      workingHrsEnd
      requireYourNameInput
      enforceWorkingHours
      deleteMeeting
      endMeetingEarly
      extendMeeting
      nearbyRooms
      mapView
      floor {
        id
      }
    }
  }
`;

export const ROOM_FIELDS_FRAGMENT = gql`
  fragment RoomFields on Room {
    id
    name
    tags
    description
    tenantId
    licensed
    calendarId
    numberOfSeats
    calendarProviderActive
    isBlocked
    marker {
      latitude
      longitude
    }
    circleShape {
      latitude
      longitude
      radius
    }
    polygonal {
      id
      type
      latitude
      longitude
    }
    roomDirection {
      id
      distance
      direction
    }
    isBooked
    calendarProviderId
    resourceEmail
    devices {
      id
      name
      tags
      description
      deviceID
      isOnline
      hardwareInfo {
        model
      }
      softwareInfo {
        version
      }
    }
    amenities {
      desk
      audio
      casting
      climate
      chargingPort
      accessibility
      videoConference
      presentationTech
    }
    brokenAmenitiesReporting
    ...RoomDisplaySettings
  }
  ${ROOM_DISPLAY_SETTINGS_FRAGMENT}
`;

export const ADD_ROOM_TO_TENANT = gql`
  mutation addRoomToTenantMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $numberOfSeats: Int
    $calendarProviderActive: Boolean
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $circleShape: CircleShapeRef
    $polygonal: [PolygonalRef]
    $roomDirection: [RoomDirectionRef]
    $calendarId: String
    $resourceEmail: String
    $amenities: AmenitiesRef
    $brokenAmenitiesReporting: Boolean
    $calendarProviderId: String
    $displaySettings: DisplaySettingsRef
  ) {
    addRoom(
      input: [
        {
          name: $name
          tags: $tags
          tenant: { id: $id }
          description: $description
          tenantId: $tenantId
          amenities: $amenities
          brokenAmenitiesReporting: $brokenAmenitiesReporting
          calendarId: $calendarId
          resourceEmail: $resourceEmail
          numberOfSeats: $numberOfSeats
          calendarProviderActive: $calendarProviderActive
          isBooked: $isBooked
          isBlocked: $isBlocked
          marker: $marker
          circleShape: $circleShape
          polygonal: $polygonal
          roomDirection: $roomDirection
          calendarProviderId: $calendarProviderId
          displaySettings: $displaySettings
        }
      ]
    ) {
      room {
        ...RoomFields
      }
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const ADD_ROOM_TO_COMPANY = gql`
  mutation addRoomToCompanyMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $numberOfSeats: Int
    $calendarProviderActive: Boolean
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $circleShape: CircleShapeRef
    $polygonal: [PolygonalRef]
    $roomDirection: [RoomDirectionRef]
    $calendarId: String
    $resourceEmail: String
    $amenities: AmenitiesRef
    $brokenAmenitiesReporting: Boolean
    $calendarProviderId: String
    $displaySettings: DisplaySettingsRef
  ) {
    addRoom(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          company: { id: $id }
          amenities: $amenities
          brokenAmenitiesReporting: $brokenAmenitiesReporting
          calendarId: $calendarId
          resourceEmail: $resourceEmail
          calendarProviderId: $calendarProviderId
          numberOfSeats: $numberOfSeats
          calendarProviderActive: $calendarProviderActive
          isBooked: $isBooked
          isBlocked: $isBlocked
          marker: $marker
          circleShape: $circleShape
          polygonal: $polygonal
          roomDirection: $roomDirection
          displaySettings: $displaySettings
        }
      ]
    ) {
      room {
        ...RoomFields
      }
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const ADD_ROOM_TO_SITE = gql`
  mutation addRoomToSiteMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $numberOfSeats: Int
    $calendarProviderActive: Boolean
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $circleShape: CircleShapeRef
    $polygonal: [PolygonalRef]
    $roomDirection: [RoomDirectionRef]
    $calendarId: String
    $resourceEmail: String
    $amenities: AmenitiesRef
    $brokenAmenitiesReporting: Boolean
    $calendarProviderId: String
    $displaySettings: DisplaySettingsRef
  ) {
    addRoom(
      input: [
        {
          name: $name
          tags: $tags
          site: { id: $id }
          description: $description
          tenantId: $tenantId
          amenities: $amenities
          brokenAmenitiesReporting: $brokenAmenitiesReporting
          calendarId: $calendarId
          resourceEmail: $resourceEmail
          calendarProviderId: $calendarProviderId
          numberOfSeats: $numberOfSeats
          calendarProviderActive: $calendarProviderActive
          isBooked: $isBooked
          isBlocked: $isBlocked
          marker: $marker
          circleShape: $circleShape
          polygonal: $polygonal
          roomDirection: $roomDirection
          displaySettings: $displaySettings
        }
      ]
    ) {
      room {
        ...RoomFields
      }
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const ADD_ROOM_TO_BUILDING = gql`
  mutation addRoomToBuildingMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $numberOfSeats: Int
    $calendarProviderActive: Boolean
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $circleShape: CircleShapeRef
    $polygonal: [PolygonalRef]
    $roomDirection: [RoomDirectionRef]
    $calendarId: String
    $resourceEmail: String
    $amenities: AmenitiesRef
    $brokenAmenitiesReporting: Boolean
    $calendarProviderId: String
    $displaySettings: DisplaySettingsRef
  ) {
    addRoom(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          building: { id: $id }
          amenities: $amenities
          brokenAmenitiesReporting: $brokenAmenitiesReporting
          calendarId: $calendarId
          resourceEmail: $resourceEmail
          calendarProviderId: $calendarProviderId
          numberOfSeats: $numberOfSeats
          calendarProviderActive: $calendarProviderActive
          isBooked: $isBooked
          isBlocked: $isBlocked
          marker: $marker
          circleShape: $circleShape
          polygonal: $polygonal
          roomDirection: $roomDirection
          displaySettings: $displaySettings
        }
      ]
    ) {
      room {
        ...RoomFields
      }
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const ADD_ROOM_TO_FLOOR = gql`
  mutation addRoomToFloorMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $numberOfSeats: Int
    $calendarProviderActive: Boolean
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $circleShape: CircleShapeRef
    $polygonal: [PolygonalRef]
    $roomDirection: [RoomDirectionRef]
    $calendarId: String
    $resourceEmail: String
    $amenities: AmenitiesRef
    $brokenAmenitiesReporting: Boolean
    $calendarProviderId: String
    $displaySettings: DisplaySettingsRef
  ) {
    addRoom(
      input: [
        {
          name: $name
          tags: $tags
          floor: { id: $id }
          description: $description
          tenantId: $tenantId
          amenities: $amenities
          brokenAmenitiesReporting: $brokenAmenitiesReporting
          calendarId: $calendarId
          resourceEmail: $resourceEmail
          calendarProviderId: $calendarProviderId
          numberOfSeats: $numberOfSeats
          calendarProviderActive: $calendarProviderActive
          isBooked: $isBooked
          isBlocked: $isBlocked
          marker: $marker
          circleShape: $circleShape
          polygonal: $polygonal
          roomDirection: $roomDirection
          displaySettings: $displaySettings
        }
      ]
    ) {
      room {
        ...RoomFields
      }
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const ADD_ROOM_TO_ZONE = gql`
  mutation addRoomToZoneMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $numberOfSeats: Int
    $calendarProviderActive: Boolean
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $circleShape: CircleShapeRef
    $polygonal: [PolygonalRef]
    $roomDirection: [RoomDirectionRef]
    $calendarId: String
    $resourceEmail: String
    $amenities: AmenitiesRef
    $brokenAmenitiesReporting: Boolean
    $calendarProviderId: String
    $displaySettings: DisplaySettingsRef
  ) {
    addRoom(
      input: [
        {
          name: $name
          tags: $tags
          zone: { id: $id }
          description: $description
          tenantId: $tenantId
          amenities: $amenities
          brokenAmenitiesReporting: $brokenAmenitiesReporting
          calendarId: $calendarId
          resourceEmail: $resourceEmail
          calendarProviderId: $calendarProviderId
          numberOfSeats: $numberOfSeats
          calendarProviderActive: $calendarProviderActive
          isBooked: $isBooked
          isBlocked: $isBlocked
          marker: $marker
          circleShape: $circleShape
          polygonal: $polygonal
          roomDirection: $roomDirection
          displaySettings: $displaySettings
        }
      ]
    ) {
      room {
        ...RoomFields
      }
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const UPDATE_ROOM = gql`
  mutation updateRoomSettings(
    $id: ID!
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $numberOfSeats: Int
    $calendarProviderActive: Boolean
    $isBooked: Boolean
    $isBlocked: Boolean
    $marker: MarkerRef
    $circleShape: CircleShapeRef
    $polygonal: [PolygonalRef]
    $calendarId: String
    $resourceEmail: String
    $calendarProviderId: String
    $amenities: AmenitiesRef
    $brokenAmenitiesReporting: Boolean
    $remove: RoomPatch
  ) {
    updateRoom(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          amenities: $amenities
          brokenAmenitiesReporting: $brokenAmenitiesReporting
          calendarId: $calendarId
          resourceEmail: $resourceEmail
          numberOfSeats: $numberOfSeats
          calendarProviderActive: $calendarProviderActive
          isBooked: $isBooked
          isBlocked: $isBlocked
          marker: $marker
          circleShape: $circleShape
          polygonal: $polygonal
          calendarProviderId: $calendarProviderId
        }
        remove: $remove
      }
    ) {
      room {
        ...RoomFields
      }
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const UPDATE_ROOM_LICENSE = gql`
  mutation updateRoomSettings(
    $id: ID!
    $tenantId: String!
    $licensed: Boolean
  ) {
    updateRoom(
      input: {
        filter: { id: [$id] }
        set: { tenantId: $tenantId, licensed: $licensed }
      }
    ) {
      room {
        id
        tenantId
        licensed
      }
    }
  }
`;

export const UPDATE_ROOM_DISPLAY_SETTINGS = gql`
  mutation updateDisplaySettings(
    $id: ID!
    $displaySettings: DisplaySettingsRef
  ) {
    updateRoom(
      input: {
        filter: { id: [$id] }
        set: { displaySettings: $displaySettings }
      }
    ) {
      room {
        id
        tenantId
        devices {
          id
          deviceID
          tenantId
        }
        ...RoomDisplaySettings
      }
    }
  }
  ${ROOM_DISPLAY_SETTINGS_FRAGMENT}
`;

export const GET_ROOM = gql`
  query getRoomQuery($id: ID!) {
    getRoom(id: $id) {
      ...RoomFields
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
`;

export const GET_ROOMS_SELECT = gql`
  query getRoomsSelectQuery($id: String) {
    queryRoom(filter: { tenantId: { eq: $id } }) {
      id
      name
      devices {
        id
        name
      }
    }
  }
`;

export const ADD_MARKER_TO_ROOM = gql`
  mutation addMarkerToRoom($id: ID!, $marker: MarkerRef) {
    updateRoom(input: { filter: { id: [$id] }, set: { marker: $marker } }) {
      room {
        id
        marker {
          latitude
          longitude
        }
      }
    }
  }
`;

export const ADD_CIRCLE_TO_ROOM = gql`
  mutation addCircleToRoom($id: ID!, $circleShape: CircleShapeRef) {
    updateRoom(
      input: { filter: { id: [$id] }, set: { circleShape: $circleShape } }
    ) {
      room {
        id
        circleShape {
          latitude
          longitude
          radius
        }
      }
    }
  }
`;

export const ADD_POLYGONAL_TO_ROOM = gql`
  mutation addPolygonalToRoom(
    $id: ID!
    $polygonal: [PolygonalRef]
    $polygonalToDelete: [PolygonalRef]
  ) {
    updateRoom(
      input: {
        filter: { id: [$id] }
        set: { polygonal: $polygonal }
        remove: { polygonal: $polygonalToDelete }
      }
    ) {
      room {
        id
        polygonal {
          id
          type
          latitude
          longitude
        }
      }
    }
  }
`;
