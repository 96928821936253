import React from "react";
import { InputDescription } from "../../shared/InputDescription/InputDescription";
import { AddDeviceButton } from "../AddWorkplace/AddWorkplaceButton/AddDeviceButton/AddDeviceButton";
import {
  AddWorkplaceButton,
  WorkplaceTypes,
} from "../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

import "./EmptyWorkplaces.scss";

interface Props {
  parentId?: string;
  type?: WorkplaceTypes;
}

export const EmptyWorkplaces = (props: Props) => {
  const { parentId, type } = props;

  return (
    <div className="EmptyWorkplaces">
      {type === "Device" ||
      type === "Room" ||
      type === "Wayfinder" ||
      type === "Desk" ? (
        <>
          <h4>It seems that there isn’t any device added!</h4>
          <AddDeviceButton
            size="medium"
            parentId={parentId}
            parentType={type as "Room" | "Wayfinder"}
            icon="plus-sign"
            className="AddDeviceButton__icon"
          />

          <InputDescription
            title="Haven’t installed the application yet?"
            titleColor="theme"
            className="mt-4 mb-2"
          />

          <a
            href="https://support.gogetcorp.com/hc/en-us/articles/6826596940956-How-to-download-install-GOGET-applications"
            target="_blank"
            rel="noopener noreferrer"
            className="EmptyWorkplaces__guide"
          >
            <span>How to install</span>
          </a>
        </>
      ) : (
        <>
          <h4>Start adding your workspaces structure!</h4>
          <AddWorkplaceButton parentId={parentId} type={type} />
        </>
      )}
    </div>
  );
};
