import React from "react";

import { useDeskOverviewInsightsContext } from "../../../Context/Desk/Overview/OverviewContext";

import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { LineChart } from "../../../shared/Charts/LineChart/LineChart";
import { ChartCard } from "../../../shared/ChartCard/ChartCard";
import { tooltips } from "../../../../../lib/utils/tooltips";

export const DeskLineChart = () => {
  const {
    loadingRange: loading,
    errorRange: error,
    dataRange: data,
  } = useDeskOverviewInsightsContext();

  return (
    <>
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<LoadingBox className="OverviewGraphsRow__line" />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={
            <ChartCard
              title="Reservations"
              className="OverviewGraphsRow__line"
              message={tooltips.insights.desks.reservations}
              direction="right"
            >
              <CenteredBox>{error?.message}</CenteredBox>
            </ChartCard>
          }
        >
          <ChartCard
            title="Reservations"
            className="OverviewGraphsRow__line"
            message={tooltips.insights.desks.reservations}
            direction="right"
          >
            <LineChart chartData={data} />
          </ChartCard>
        </HandleRequestState>
      </HandleLoadingState>
    </>
  );
};
