import React from "react";
import { Icon } from "../../shared/Icon/Icon";

export const HelpCenter = () => {
  return (
    <a
      href="https://support.gogetcorp.com/hc/en-us"
      target="_blank"
      rel="noopener noreferrer"
      className="HelpCenter"
    >
      <span>Help center</span>

      <Icon icon="help-icon" />
    </a>
  );
};
