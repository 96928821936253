import { toast } from "react-toastify";
import { WorkplaceFields } from "../../api/graphql/workplaces";
import { DisplaySettingsFormik } from "../../components/shared/Forms/DisplaySettingsForm/lib/useDisplaySettingsForm";
import { WayfinderDisplaySettingsFormik } from "../../components/shared/Forms/WayFinderForm/lib/useWayfinderDisplaySettingsForm";

export const selectFloorForWorkspace = (
  item: WorkplaceFields,
  formik: DisplaySettingsFormik | WayfinderDisplaySettingsFormik,
  fieldName: string
) => {
  if (item.__typename !== "Floor") {
    return;
  }

  if (!item.map || !item.map.url) {
    return toast.warn("This floor has no map added.");
  }

  formik.setFieldValue(fieldName, {
    __typename: "Floor",
    id: item.id,
  });
  formik.setFieldTouched(fieldName, true);
  return;
};
