import React from "react";
import { useBookingsContext } from "../../../Context/BookingsContext";
import { UserInfoRowItem } from "../../../shared/UserModal/UserInfoRowItem";
import { seating } from "../capabilitiesOptions";

import "./Seatings.scss";

export const Seatings = () => {
  const { seats, handleSeats } = useBookingsContext();

  const handleSeatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSeats(e.target.value);
  };

  return (
    <div className="Seatings">
      <UserInfoRowItem icon="users-group" content="Seatings" />
      {seating.map((seat) => (
        <div key={seat}>
          <input
            key={seat}
            type="radio"
            value={seat}
            name={seat}
            checked={seats === seat}
            onChange={handleSeatingChange}
          />
          {seat}
        </div>
      ))}
    </div>
  );
};
