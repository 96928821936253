// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "auth.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FieldMask } from "./google/protobuf/field_mask";
/**
 * @generated from protobuf message AuthDeviceByIDRequest
 */
export interface AuthDeviceByIDRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string device_id = 2;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message AuthDeviceByIDResponse
 */
export interface AuthDeviceByIDResponse {
    /**
     * @generated from protobuf field: string auth_token = 1;
     */
    authToken: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message DeleteAuthAccountRequest
 */
export interface DeleteAuthAccountRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf message AuthVerifyEmailRequest
 */
export interface AuthVerifyEmailRequest {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: string api_key = 2;
     */
    apiKey: string;
    /**
     * @generated from protobuf field: string customer_id = 3;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ResetPasswordLinkRequest
 */
export interface ResetPasswordLinkRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message ResetPasswordResponse
 */
export interface ResetPasswordResponse {
    /**
     * @generated from protobuf field: string link = 1;
     */
    link: string;
}
/**
 * @generated from protobuf message VerifyEmailLinkRequest
 */
export interface VerifyEmailLinkRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message VerifyEmailLinkResponse
 */
export interface VerifyEmailLinkResponse {
    /**
     * @generated from protobuf field: string link = 1;
     */
    link: string;
}
/**
 * @generated from protobuf message UpdateAuthAccountRequest
 */
export interface UpdateAuthAccountRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask update_mask = 3;
     */
    updateMask?: FieldMask;
    /**
     * @generated from protobuf field: AuthAccount account = 4;
     */
    account?: AuthAccount;
    /**
     * @generated from protobuf field: string new_password = 5;
     */
    newPassword: string;
    /**
     * @generated from protobuf field: string role = 6;
     */
    role: string;
}
/**
 * @generated from protobuf message GetAccountByEmailRequest
 */
export interface GetAccountByEmailRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message GetAccountByIdRequest
 */
export interface GetAccountByIdRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string customer_id = 2;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CreateTenantRequest
 */
export interface CreateTenantRequest {
    /**
     * @generated from protobuf field: string company_name = 1;
     */
    companyName: string;
}
/**
 * @generated from protobuf message Tenant
 */
export interface Tenant {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
}
/**
 * @generated from protobuf message GetTenantRequest
 */
export interface GetTenantRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message DeleteTenantRequest
 */
export interface DeleteTenantRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message AuthAccount
 */
export interface AuthAccount {
    /**
     * @generated from protobuf field: string display_name = 1;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string phone_number = 3;
     */
    phoneNumber: string;
    /**
     * @generated from protobuf field: string photo_url = 4;
     */
    photoUrl: string;
    /**
     * @generated from protobuf field: string first_name = 5;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 6;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string customer_id = 7;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string id = 8;
     */
    id: string;
    /**
     * @generated from protobuf field: bool disabled = 9;
     */
    disabled: boolean;
    /**
     * @generated from protobuf field: bool email_verified = 10;
     */
    emailVerified: boolean; // Read-only
    /**
     * @generated from protobuf field: bytes claims = 11;
     */
    claims: Uint8Array;
    /**
     * @generated from protobuf field: int64 TokensValidAfterMillis = 12 [json_name = "TokensValidAfterMillis"];
     */
    tokensValidAfterMillis: number;
}
/**
 * @generated from protobuf message AuthCreateAccountRequest
 */
export interface AuthCreateAccountRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
    /**
     * @generated from protobuf field: string display_name = 3;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string first_name = 4;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 5;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string phone_number = 6;
     */
    phoneNumber: string;
    /**
     * @generated from protobuf field: string photo_url = 7;
     */
    photoUrl: string;
    /**
     * @generated from protobuf field: string customer_id = 8;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string role = 9;
     */
    role: string;
}
/**
 * @generated from protobuf message CreateAdminAccountRequest
 */
export interface CreateAdminAccountRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
    /**
     * @generated from protobuf field: string display_name = 3;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string first_name = 4;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 5;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string phone_number = 6;
     */
    phoneNumber: string;
    /**
     * @generated from protobuf field: string photo_url = 7;
     */
    photoUrl: string;
    /**
     * @generated from protobuf field: string customer_id = 8;
     */
    customerId: string;
}
/**
 * @generated from protobuf message CreateContactAccountRequest
 */
export interface CreateContactAccountRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
    /**
     * @generated from protobuf field: string first_name = 3;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 4;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string customer_id = 5;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string code = 6;
     */
    code: string; // Verification code for contact invite
    /**
     * @generated from protobuf field: string id = 7;
     */
    id: string;
    /**
     * @generated from protobuf field: bool is_active = 8;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: string role = 9;
     */
    role: string;
    /**
     * key-value map with custom account properties
     *
     * @generated from protobuf field: map<string, string> claims = 10;
     */
    claims: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message CreateSubscriptionClaimsRequest
 */
export interface CreateSubscriptionClaimsRequest {
    /**
     * @generated from protobuf field: string customer_id = 1;
     */
    customerId: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: string chargebee_customer_id = 3;
     */
    chargebeeCustomerId: string;
    /**
     * @generated from protobuf field: string subscription_id = 4;
     */
    subscriptionId: string;
    /**
     * @generated from protobuf field: uint32 desk_quantity = 5;
     */
    deskQuantity: number;
    /**
     * @generated from protobuf field: uint32 rooms_quantity = 6;
     */
    roomsQuantity: number;
    /**
     * @generated from protobuf field: uint32 users_quantity = 7;
     */
    usersQuantity: number;
}
/**
 * @generated from protobuf message VerifyTokenRequest
 */
export interface VerifyTokenRequest {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message VerifyTokenResponse
 */
export interface VerifyTokenResponse {
    /**
     * @generated from protobuf field: int64 auth_time = 1;
     */
    authTime: number;
    /**
     * @generated from protobuf field: string issuer = 2;
     */
    issuer: string;
    /**
     * @generated from protobuf field: string audience = 3;
     */
    audience: string;
    /**
     * @generated from protobuf field: int64 expires = 4;
     */
    expires: number;
    /**
     * @generated from protobuf field: int64 issued_at = 5;
     */
    issuedAt: number;
    /**
     * @generated from protobuf field: string subject = 6;
     */
    subject: string;
    /**
     * @generated from protobuf field: string uid = 7;
     */
    uid: string;
    /**
     * @generated from protobuf field: bytes claims = 8;
     */
    claims: Uint8Array;
}
/**
 * @generated from protobuf message CustomTokenRequest
 */
export interface CustomTokenRequest {
    /**
     * @generated from protobuf field: string uuid = 1;
     */
    uuid: string;
    /**
     * @generated from protobuf field: string role = 2;
     */
    role: string;
}
/**
 * @generated from protobuf message Token
 */
export interface Token {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message VerifyDeviceTokenRequest
 */
export interface VerifyDeviceTokenRequest {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: string deviceID = 2;
     */
    deviceID: string;
}
/**
 * @generated from protobuf message UpdatePrimaryEmailRequest
 */
export interface UpdatePrimaryEmailRequest {
    /**
     * @generated from protobuf field: string customerID = 1;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string accountID = 2;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string newEmail = 3;
     */
    newEmail: string;
}
/**
 * @generated from protobuf message ExchangeSocialAuthTokenRequest
 */
export interface ExchangeSocialAuthTokenRequest {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message ExchangeSocialAuthTokenResponse
 */
export interface ExchangeSocialAuthTokenResponse {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: string accountID = 2;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 3;
     */
    customerID: string;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
}
/**
 * @generated from protobuf message SetAccountClaimsRequest
 */
export interface SetAccountClaimsRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
    /**
     * @generated from protobuf field: map<string, string> claims = 3;
     */
    claims: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message CheckBasicAuthMethodRequest
 */
export interface CheckBasicAuthMethodRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message DisableMFAAccountRequest
 */
export interface DisableMFAAccountRequest {
    /**
     * @generated from protobuf field: string accountID = 1;
     */
    accountID: string;
    /**
     * @generated from protobuf field: string customerID = 2;
     */
    customerID: string;
}
/**
 * @generated from protobuf message DisableMFAAccountResponse
 */
export interface DisableMFAAccountResponse {
    /**
     * @generated from protobuf field: bool disabled = 1;
     */
    disabled: boolean;
}
/**
 * @generated from protobuf message ValidateCredentialsRequest
 */
export interface ValidateCredentialsRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
}
/**
 * @generated from protobuf message ValidateCredentialsResponse
 */
export interface ValidateCredentialsResponse {
    /**
     * @generated from protobuf field: bool isValid = 1;
     */
    isValid: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class AuthDeviceByIDRequest$Type extends MessageType<AuthDeviceByIDRequest> {
    constructor() {
        super("AuthDeviceByIDRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthDeviceByIDRequest>): AuthDeviceByIDRequest {
        const message = { customerId: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthDeviceByIDRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthDeviceByIDRequest): AuthDeviceByIDRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string device_id */ 2:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthDeviceByIDRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string device_id = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthDeviceByIDRequest
 */
export const AuthDeviceByIDRequest = new AuthDeviceByIDRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthDeviceByIDResponse$Type extends MessageType<AuthDeviceByIDResponse> {
    constructor() {
        super("AuthDeviceByIDResponse", [
            { no: 1, name: "auth_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthDeviceByIDResponse>): AuthDeviceByIDResponse {
        const message = { authToken: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthDeviceByIDResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthDeviceByIDResponse): AuthDeviceByIDResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string auth_token */ 1:
                    message.authToken = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthDeviceByIDResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string auth_token = 1; */
        if (message.authToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.authToken);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthDeviceByIDResponse
 */
export const AuthDeviceByIDResponse = new AuthDeviceByIDResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAuthAccountRequest$Type extends MessageType<DeleteAuthAccountRequest> {
    constructor() {
        super("DeleteAuthAccountRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteAuthAccountRequest>): DeleteAuthAccountRequest {
        const message = { customerId: "", id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAuthAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAuthAccountRequest): DeleteAuthAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAuthAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteAuthAccountRequest
 */
export const DeleteAuthAccountRequest = new DeleteAuthAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthVerifyEmailRequest$Type extends MessageType<AuthVerifyEmailRequest> {
    constructor() {
        super("AuthVerifyEmailRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthVerifyEmailRequest>): AuthVerifyEmailRequest {
        const message = { token: "", apiKey: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthVerifyEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthVerifyEmailRequest): AuthVerifyEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* string api_key */ 2:
                    message.apiKey = reader.string();
                    break;
                case /* string customer_id */ 3:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthVerifyEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* string api_key = 2; */
        if (message.apiKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.apiKey);
        /* string customer_id = 3; */
        if (message.customerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthVerifyEmailRequest
 */
export const AuthVerifyEmailRequest = new AuthVerifyEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetPasswordLinkRequest$Type extends MessageType<ResetPasswordLinkRequest> {
    constructor() {
        super("ResetPasswordLinkRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetPasswordLinkRequest>): ResetPasswordLinkRequest {
        const message = { email: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResetPasswordLinkRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetPasswordLinkRequest): ResetPasswordLinkRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetPasswordLinkRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ResetPasswordLinkRequest
 */
export const ResetPasswordLinkRequest = new ResetPasswordLinkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetPasswordResponse$Type extends MessageType<ResetPasswordResponse> {
    constructor() {
        super("ResetPasswordResponse", [
            { no: 1, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetPasswordResponse>): ResetPasswordResponse {
        const message = { link: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResetPasswordResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetPasswordResponse): ResetPasswordResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string link */ 1:
                    message.link = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetPasswordResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string link = 1; */
        if (message.link !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.link);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ResetPasswordResponse
 */
export const ResetPasswordResponse = new ResetPasswordResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyEmailLinkRequest$Type extends MessageType<VerifyEmailLinkRequest> {
    constructor() {
        super("VerifyEmailLinkRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyEmailLinkRequest>): VerifyEmailLinkRequest {
        const message = { email: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VerifyEmailLinkRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyEmailLinkRequest): VerifyEmailLinkRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyEmailLinkRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyEmailLinkRequest
 */
export const VerifyEmailLinkRequest = new VerifyEmailLinkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyEmailLinkResponse$Type extends MessageType<VerifyEmailLinkResponse> {
    constructor() {
        super("VerifyEmailLinkResponse", [
            { no: 1, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyEmailLinkResponse>): VerifyEmailLinkResponse {
        const message = { link: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VerifyEmailLinkResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyEmailLinkResponse): VerifyEmailLinkResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string link */ 1:
                    message.link = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyEmailLinkResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string link = 1; */
        if (message.link !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.link);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyEmailLinkResponse
 */
export const VerifyEmailLinkResponse = new VerifyEmailLinkResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAuthAccountRequest$Type extends MessageType<UpdateAuthAccountRequest> {
    constructor() {
        super("UpdateAuthAccountRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "update_mask", kind: "message", T: () => FieldMask },
            { no: 4, name: "account", kind: "message", T: () => AuthAccount },
            { no: 5, name: "new_password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateAuthAccountRequest>): UpdateAuthAccountRequest {
        const message = { id: "", customerId: "", newPassword: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateAuthAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAuthAccountRequest): UpdateAuthAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                case /* google.protobuf.FieldMask update_mask */ 3:
                    message.updateMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.updateMask);
                    break;
                case /* AuthAccount account */ 4:
                    message.account = AuthAccount.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* string new_password */ 5:
                    message.newPassword = reader.string();
                    break;
                case /* string role */ 6:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAuthAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        /* google.protobuf.FieldMask update_mask = 3; */
        if (message.updateMask)
            FieldMask.internalBinaryWrite(message.updateMask, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* AuthAccount account = 4; */
        if (message.account)
            AuthAccount.internalBinaryWrite(message.account, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string new_password = 5; */
        if (message.newPassword !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.newPassword);
        /* string role = 6; */
        if (message.role !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdateAuthAccountRequest
 */
export const UpdateAuthAccountRequest = new UpdateAuthAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountByEmailRequest$Type extends MessageType<GetAccountByEmailRequest> {
    constructor() {
        super("GetAccountByEmailRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountByEmailRequest>): GetAccountByEmailRequest {
        const message = { email: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountByEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountByEmailRequest): GetAccountByEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountByEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetAccountByEmailRequest
 */
export const GetAccountByEmailRequest = new GetAccountByEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountByIdRequest$Type extends MessageType<GetAccountByIdRequest> {
    constructor() {
        super("GetAccountByIdRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountByIdRequest>): GetAccountByIdRequest {
        const message = { id: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountByIdRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountByIdRequest): GetAccountByIdRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string customer_id */ 2:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountByIdRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string customer_id = 2; */
        if (message.customerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetAccountByIdRequest
 */
export const GetAccountByIdRequest = new GetAccountByIdRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTenantRequest$Type extends MessageType<CreateTenantRequest> {
    constructor() {
        super("CreateTenantRequest", [
            { no: 1, name: "company_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateTenantRequest>): CreateTenantRequest {
        const message = { companyName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTenantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTenantRequest): CreateTenantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string company_name */ 1:
                    message.companyName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateTenantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string company_name = 1; */
        if (message.companyName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.companyName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateTenantRequest
 */
export const CreateTenantRequest = new CreateTenantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tenant$Type extends MessageType<Tenant> {
    constructor() {
        super("Tenant", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Tenant>): Tenant {
        const message = { id: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Tenant>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tenant): Tenant {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tenant, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Tenant
 */
export const Tenant = new Tenant$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTenantRequest$Type extends MessageType<GetTenantRequest> {
    constructor() {
        super("GetTenantRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTenantRequest>): GetTenantRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTenantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTenantRequest): GetTenantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTenantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetTenantRequest
 */
export const GetTenantRequest = new GetTenantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTenantRequest$Type extends MessageType<DeleteTenantRequest> {
    constructor() {
        super("DeleteTenantRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteTenantRequest>): DeleteTenantRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteTenantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTenantRequest): DeleteTenantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteTenantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeleteTenantRequest
 */
export const DeleteTenantRequest = new DeleteTenantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthAccount$Type extends MessageType<AuthAccount> {
    constructor() {
        super("AuthAccount", [
            { no: 1, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "photo_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "email_verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "claims", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 12, name: "TokensValidAfterMillis", kind: "scalar", jsonName: "TokensValidAfterMillis", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AuthAccount>): AuthAccount {
        const message = { displayName: "", email: "", phoneNumber: "", photoUrl: "", firstName: "", lastName: "", customerId: "", id: "", disabled: false, emailVerified: false, claims: new Uint8Array(0), tokensValidAfterMillis: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthAccount): AuthAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string display_name */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string phone_number */ 3:
                    message.phoneNumber = reader.string();
                    break;
                case /* string photo_url */ 4:
                    message.photoUrl = reader.string();
                    break;
                case /* string first_name */ 5:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 6:
                    message.lastName = reader.string();
                    break;
                case /* string customer_id */ 7:
                    message.customerId = reader.string();
                    break;
                case /* string id */ 8:
                    message.id = reader.string();
                    break;
                case /* bool disabled */ 9:
                    message.disabled = reader.bool();
                    break;
                case /* bool email_verified */ 10:
                    message.emailVerified = reader.bool();
                    break;
                case /* bytes claims */ 11:
                    message.claims = reader.bytes();
                    break;
                case /* int64 TokensValidAfterMillis = 12 [json_name = "TokensValidAfterMillis"];*/ 12:
                    message.tokensValidAfterMillis = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string display_name = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string phone_number = 3; */
        if (message.phoneNumber !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.phoneNumber);
        /* string photo_url = 4; */
        if (message.photoUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.photoUrl);
        /* string first_name = 5; */
        if (message.firstName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 6; */
        if (message.lastName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.lastName);
        /* string customer_id = 7; */
        if (message.customerId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.customerId);
        /* string id = 8; */
        if (message.id !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.id);
        /* bool disabled = 9; */
        if (message.disabled !== false)
            writer.tag(9, WireType.Varint).bool(message.disabled);
        /* bool email_verified = 10; */
        if (message.emailVerified !== false)
            writer.tag(10, WireType.Varint).bool(message.emailVerified);
        /* bytes claims = 11; */
        if (message.claims.length)
            writer.tag(11, WireType.LengthDelimited).bytes(message.claims);
        /* int64 TokensValidAfterMillis = 12 [json_name = "TokensValidAfterMillis"]; */
        if (message.tokensValidAfterMillis !== 0)
            writer.tag(12, WireType.Varint).int64(message.tokensValidAfterMillis);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthAccount
 */
export const AuthAccount = new AuthAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthCreateAccountRequest$Type extends MessageType<AuthCreateAccountRequest> {
    constructor() {
        super("AuthCreateAccountRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "photo_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthCreateAccountRequest>): AuthCreateAccountRequest {
        const message = { email: "", password: "", displayName: "", firstName: "", lastName: "", phoneNumber: "", photoUrl: "", customerId: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthCreateAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthCreateAccountRequest): AuthCreateAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                case /* string display_name */ 3:
                    message.displayName = reader.string();
                    break;
                case /* string first_name */ 4:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 5:
                    message.lastName = reader.string();
                    break;
                case /* string phone_number */ 6:
                    message.phoneNumber = reader.string();
                    break;
                case /* string photo_url */ 7:
                    message.photoUrl = reader.string();
                    break;
                case /* string customer_id */ 8:
                    message.customerId = reader.string();
                    break;
                case /* string role */ 9:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthCreateAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        /* string display_name = 3; */
        if (message.displayName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.displayName);
        /* string first_name = 4; */
        if (message.firstName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 5; */
        if (message.lastName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastName);
        /* string phone_number = 6; */
        if (message.phoneNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.phoneNumber);
        /* string photo_url = 7; */
        if (message.photoUrl !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.photoUrl);
        /* string customer_id = 8; */
        if (message.customerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerId);
        /* string role = 9; */
        if (message.role !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthCreateAccountRequest
 */
export const AuthCreateAccountRequest = new AuthCreateAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAdminAccountRequest$Type extends MessageType<CreateAdminAccountRequest> {
    constructor() {
        super("CreateAdminAccountRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "photo_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAdminAccountRequest>): CreateAdminAccountRequest {
        const message = { email: "", password: "", displayName: "", firstName: "", lastName: "", phoneNumber: "", photoUrl: "", customerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAdminAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAdminAccountRequest): CreateAdminAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                case /* string display_name */ 3:
                    message.displayName = reader.string();
                    break;
                case /* string first_name */ 4:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 5:
                    message.lastName = reader.string();
                    break;
                case /* string phone_number */ 6:
                    message.phoneNumber = reader.string();
                    break;
                case /* string photo_url */ 7:
                    message.photoUrl = reader.string();
                    break;
                case /* string customer_id */ 8:
                    message.customerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAdminAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        /* string display_name = 3; */
        if (message.displayName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.displayName);
        /* string first_name = 4; */
        if (message.firstName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 5; */
        if (message.lastName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastName);
        /* string phone_number = 6; */
        if (message.phoneNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.phoneNumber);
        /* string photo_url = 7; */
        if (message.photoUrl !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.photoUrl);
        /* string customer_id = 8; */
        if (message.customerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.customerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateAdminAccountRequest
 */
export const CreateAdminAccountRequest = new CreateAdminAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateContactAccountRequest$Type extends MessageType<CreateContactAccountRequest> {
    constructor() {
        super("CreateContactAccountRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "claims", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<CreateContactAccountRequest>): CreateContactAccountRequest {
        const message = { email: "", password: "", firstName: "", lastName: "", customerId: "", code: "", id: "", isActive: false, role: "", claims: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateContactAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateContactAccountRequest): CreateContactAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                case /* string first_name */ 3:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 4:
                    message.lastName = reader.string();
                    break;
                case /* string customer_id */ 5:
                    message.customerId = reader.string();
                    break;
                case /* string code */ 6:
                    message.code = reader.string();
                    break;
                case /* string id */ 7:
                    message.id = reader.string();
                    break;
                case /* bool is_active */ 8:
                    message.isActive = reader.bool();
                    break;
                case /* string role */ 9:
                    message.role = reader.string();
                    break;
                case /* map<string, string> claims */ 10:
                    this.binaryReadMap10(message.claims, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap10(map: CreateContactAccountRequest["claims"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateContactAccountRequest["claims"] | undefined, val: CreateContactAccountRequest["claims"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field CreateContactAccountRequest.claims");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: CreateContactAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        /* string first_name = 3; */
        if (message.firstName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 4; */
        if (message.lastName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastName);
        /* string customer_id = 5; */
        if (message.customerId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.customerId);
        /* string code = 6; */
        if (message.code !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.code);
        /* string id = 7; */
        if (message.id !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.id);
        /* bool is_active = 8; */
        if (message.isActive !== false)
            writer.tag(8, WireType.Varint).bool(message.isActive);
        /* string role = 9; */
        if (message.role !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.role);
        /* map<string, string> claims = 10; */
        for (let k of Object.keys(message.claims))
            writer.tag(10, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.claims[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateContactAccountRequest
 */
export const CreateContactAccountRequest = new CreateContactAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSubscriptionClaimsRequest$Type extends MessageType<CreateSubscriptionClaimsRequest> {
    constructor() {
        super("CreateSubscriptionClaimsRequest", [
            { no: 1, name: "customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chargebee_customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "subscription_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "desk_quantity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "rooms_quantity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "users_quantity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<CreateSubscriptionClaimsRequest>): CreateSubscriptionClaimsRequest {
        const message = { customerId: "", email: "", chargebeeCustomerId: "", subscriptionId: "", deskQuantity: 0, roomsQuantity: 0, usersQuantity: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateSubscriptionClaimsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSubscriptionClaimsRequest): CreateSubscriptionClaimsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customer_id */ 1:
                    message.customerId = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string chargebee_customer_id */ 3:
                    message.chargebeeCustomerId = reader.string();
                    break;
                case /* string subscription_id */ 4:
                    message.subscriptionId = reader.string();
                    break;
                case /* uint32 desk_quantity */ 5:
                    message.deskQuantity = reader.uint32();
                    break;
                case /* uint32 rooms_quantity */ 6:
                    message.roomsQuantity = reader.uint32();
                    break;
                case /* uint32 users_quantity */ 7:
                    message.usersQuantity = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateSubscriptionClaimsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customer_id = 1; */
        if (message.customerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerId);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string chargebee_customer_id = 3; */
        if (message.chargebeeCustomerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.chargebeeCustomerId);
        /* string subscription_id = 4; */
        if (message.subscriptionId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.subscriptionId);
        /* uint32 desk_quantity = 5; */
        if (message.deskQuantity !== 0)
            writer.tag(5, WireType.Varint).uint32(message.deskQuantity);
        /* uint32 rooms_quantity = 6; */
        if (message.roomsQuantity !== 0)
            writer.tag(6, WireType.Varint).uint32(message.roomsQuantity);
        /* uint32 users_quantity = 7; */
        if (message.usersQuantity !== 0)
            writer.tag(7, WireType.Varint).uint32(message.usersQuantity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateSubscriptionClaimsRequest
 */
export const CreateSubscriptionClaimsRequest = new CreateSubscriptionClaimsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyTokenRequest$Type extends MessageType<VerifyTokenRequest> {
    constructor() {
        super("VerifyTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyTokenRequest>): VerifyTokenRequest {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VerifyTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyTokenRequest): VerifyTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyTokenRequest
 */
export const VerifyTokenRequest = new VerifyTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyTokenResponse$Type extends MessageType<VerifyTokenResponse> {
    constructor() {
        super("VerifyTokenResponse", [
            { no: 1, name: "auth_time", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "issuer", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "audience", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expires", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "issued_at", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "claims", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyTokenResponse>): VerifyTokenResponse {
        const message = { authTime: 0, issuer: "", audience: "", expires: 0, issuedAt: 0, subject: "", uid: "", claims: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VerifyTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyTokenResponse): VerifyTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 auth_time */ 1:
                    message.authTime = reader.int64().toNumber();
                    break;
                case /* string issuer */ 2:
                    message.issuer = reader.string();
                    break;
                case /* string audience */ 3:
                    message.audience = reader.string();
                    break;
                case /* int64 expires */ 4:
                    message.expires = reader.int64().toNumber();
                    break;
                case /* int64 issued_at */ 5:
                    message.issuedAt = reader.int64().toNumber();
                    break;
                case /* string subject */ 6:
                    message.subject = reader.string();
                    break;
                case /* string uid */ 7:
                    message.uid = reader.string();
                    break;
                case /* bytes claims */ 8:
                    message.claims = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 auth_time = 1; */
        if (message.authTime !== 0)
            writer.tag(1, WireType.Varint).int64(message.authTime);
        /* string issuer = 2; */
        if (message.issuer !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.issuer);
        /* string audience = 3; */
        if (message.audience !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.audience);
        /* int64 expires = 4; */
        if (message.expires !== 0)
            writer.tag(4, WireType.Varint).int64(message.expires);
        /* int64 issued_at = 5; */
        if (message.issuedAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.issuedAt);
        /* string subject = 6; */
        if (message.subject !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.subject);
        /* string uid = 7; */
        if (message.uid !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.uid);
        /* bytes claims = 8; */
        if (message.claims.length)
            writer.tag(8, WireType.LengthDelimited).bytes(message.claims);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyTokenResponse
 */
export const VerifyTokenResponse = new VerifyTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomTokenRequest$Type extends MessageType<CustomTokenRequest> {
    constructor() {
        super("CustomTokenRequest", [
            { no: 1, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CustomTokenRequest>): CustomTokenRequest {
        const message = { uuid: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CustomTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CustomTokenRequest): CustomTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uuid */ 1:
                    message.uuid = reader.string();
                    break;
                case /* string role */ 2:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CustomTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uuid = 1; */
        if (message.uuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uuid);
        /* string role = 2; */
        if (message.role !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomTokenRequest
 */
export const CustomTokenRequest = new CustomTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Token$Type extends MessageType<Token> {
    constructor() {
        super("Token", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Token>): Token {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Token>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Token): Token {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Token, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Token
 */
export const Token = new Token$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyDeviceTokenRequest$Type extends MessageType<VerifyDeviceTokenRequest> {
    constructor() {
        super("VerifyDeviceTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyDeviceTokenRequest>): VerifyDeviceTokenRequest {
        const message = { token: "", deviceID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VerifyDeviceTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyDeviceTokenRequest): VerifyDeviceTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* string deviceID */ 2:
                    message.deviceID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyDeviceTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* string deviceID = 2; */
        if (message.deviceID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VerifyDeviceTokenRequest
 */
export const VerifyDeviceTokenRequest = new VerifyDeviceTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePrimaryEmailRequest$Type extends MessageType<UpdatePrimaryEmailRequest> {
    constructor() {
        super("UpdatePrimaryEmailRequest", [
            { no: 1, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "newEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdatePrimaryEmailRequest>): UpdatePrimaryEmailRequest {
        const message = { customerID: "", accountID: "", newEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePrimaryEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePrimaryEmailRequest): UpdatePrimaryEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customerID */ 1:
                    message.customerID = reader.string();
                    break;
                case /* string accountID */ 2:
                    message.accountID = reader.string();
                    break;
                case /* string newEmail */ 3:
                    message.newEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePrimaryEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customerID = 1; */
        if (message.customerID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customerID);
        /* string accountID = 2; */
        if (message.accountID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountID);
        /* string newEmail = 3; */
        if (message.newEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.newEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UpdatePrimaryEmailRequest
 */
export const UpdatePrimaryEmailRequest = new UpdatePrimaryEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExchangeSocialAuthTokenRequest$Type extends MessageType<ExchangeSocialAuthTokenRequest> {
    constructor() {
        super("ExchangeSocialAuthTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExchangeSocialAuthTokenRequest>): ExchangeSocialAuthTokenRequest {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExchangeSocialAuthTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExchangeSocialAuthTokenRequest): ExchangeSocialAuthTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExchangeSocialAuthTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExchangeSocialAuthTokenRequest
 */
export const ExchangeSocialAuthTokenRequest = new ExchangeSocialAuthTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExchangeSocialAuthTokenResponse$Type extends MessageType<ExchangeSocialAuthTokenResponse> {
    constructor() {
        super("ExchangeSocialAuthTokenResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExchangeSocialAuthTokenResponse>): ExchangeSocialAuthTokenResponse {
        const message = { token: "", accountID: "", customerID: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExchangeSocialAuthTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExchangeSocialAuthTokenResponse): ExchangeSocialAuthTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* string accountID */ 2:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 3:
                    message.customerID = reader.string();
                    break;
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExchangeSocialAuthTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* string accountID = 2; */
        if (message.accountID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 3; */
        if (message.customerID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.customerID);
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ExchangeSocialAuthTokenResponse
 */
export const ExchangeSocialAuthTokenResponse = new ExchangeSocialAuthTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAccountClaimsRequest$Type extends MessageType<SetAccountClaimsRequest> {
    constructor() {
        super("SetAccountClaimsRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "claims", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<SetAccountClaimsRequest>): SetAccountClaimsRequest {
        const message = { accountID: "", customerID: "", claims: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetAccountClaimsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetAccountClaimsRequest): SetAccountClaimsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                case /* map<string, string> claims */ 3:
                    this.binaryReadMap3(message.claims, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: SetAccountClaimsRequest["claims"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SetAccountClaimsRequest["claims"] | undefined, val: SetAccountClaimsRequest["claims"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field SetAccountClaimsRequest.claims");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: SetAccountClaimsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        /* map<string, string> claims = 3; */
        for (let k of Object.keys(message.claims))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.claims[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SetAccountClaimsRequest
 */
export const SetAccountClaimsRequest = new SetAccountClaimsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckBasicAuthMethodRequest$Type extends MessageType<CheckBasicAuthMethodRequest> {
    constructor() {
        super("CheckBasicAuthMethodRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckBasicAuthMethodRequest>): CheckBasicAuthMethodRequest {
        const message = { email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckBasicAuthMethodRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckBasicAuthMethodRequest): CheckBasicAuthMethodRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckBasicAuthMethodRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckBasicAuthMethodRequest
 */
export const CheckBasicAuthMethodRequest = new CheckBasicAuthMethodRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableMFAAccountRequest$Type extends MessageType<DisableMFAAccountRequest> {
    constructor() {
        super("DisableMFAAccountRequest", [
            { no: 1, name: "accountID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "customerID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DisableMFAAccountRequest>): DisableMFAAccountRequest {
        const message = { accountID: "", customerID: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisableMFAAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableMFAAccountRequest): DisableMFAAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accountID */ 1:
                    message.accountID = reader.string();
                    break;
                case /* string customerID */ 2:
                    message.customerID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableMFAAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accountID = 1; */
        if (message.accountID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accountID);
        /* string customerID = 2; */
        if (message.customerID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.customerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisableMFAAccountRequest
 */
export const DisableMFAAccountRequest = new DisableMFAAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableMFAAccountResponse$Type extends MessageType<DisableMFAAccountResponse> {
    constructor() {
        super("DisableMFAAccountResponse", [
            { no: 1, name: "disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DisableMFAAccountResponse>): DisableMFAAccountResponse {
        const message = { disabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DisableMFAAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableMFAAccountResponse): DisableMFAAccountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool disabled */ 1:
                    message.disabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableMFAAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool disabled = 1; */
        if (message.disabled !== false)
            writer.tag(1, WireType.Varint).bool(message.disabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DisableMFAAccountResponse
 */
export const DisableMFAAccountResponse = new DisableMFAAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateCredentialsRequest$Type extends MessageType<ValidateCredentialsRequest> {
    constructor() {
        super("ValidateCredentialsRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateCredentialsRequest>): ValidateCredentialsRequest {
        const message = { email: "", password: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ValidateCredentialsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateCredentialsRequest): ValidateCredentialsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateCredentialsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ValidateCredentialsRequest
 */
export const ValidateCredentialsRequest = new ValidateCredentialsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateCredentialsResponse$Type extends MessageType<ValidateCredentialsResponse> {
    constructor() {
        super("ValidateCredentialsResponse", [
            { no: 1, name: "isValid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateCredentialsResponse>): ValidateCredentialsResponse {
        const message = { isValid: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ValidateCredentialsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateCredentialsResponse): ValidateCredentialsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isValid */ 1:
                    message.isValid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateCredentialsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isValid = 1; */
        if (message.isValid !== false)
            writer.tag(1, WireType.Varint).bool(message.isValid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ValidateCredentialsResponse
 */
export const ValidateCredentialsResponse = new ValidateCredentialsResponse$Type();
/**
 * @generated ServiceType for protobuf service Auth
 */
export const Auth = new ServiceType("Auth", [
    { name: "CreateTenant", options: {}, I: CreateTenantRequest, O: Tenant },
    { name: "GetTenant", options: {}, I: GetTenantRequest, O: Tenant },
    { name: "DeleteTenant", options: {}, I: DeleteTenantRequest, O: Empty },
    { name: "CreateAccount", options: {}, I: AuthCreateAccountRequest, O: AuthAccount },
    { name: "CreateAdminAccount", options: {}, I: CreateAdminAccountRequest, O: AuthAccount },
    { name: "CreateContactAccount", options: {}, I: CreateContactAccountRequest, O: AuthAccount },
    { name: "CreateSubscriptionClaims", options: {}, I: CreateSubscriptionClaimsRequest, O: Empty },
    { name: "VerifyToken", options: {}, I: VerifyTokenRequest, O: VerifyTokenResponse },
    { name: "GenerateCustomToken", options: {}, I: CustomTokenRequest, O: Token },
    { name: "GetAccountByEmail", options: {}, I: GetAccountByEmailRequest, O: AuthAccount },
    { name: "GetAccountById", options: {}, I: GetAccountByIdRequest, O: AuthAccount },
    { name: "UpdateAuthAccount", options: {}, I: UpdateAuthAccountRequest, O: AuthAccount },
    { name: "GenerateVerifyEmailLink", options: {}, I: VerifyEmailLinkRequest, O: VerifyEmailLinkResponse },
    { name: "GenerateResetPasswordLink", options: {}, I: ResetPasswordLinkRequest, O: ResetPasswordResponse },
    { name: "VerifyEmail", options: {}, I: AuthVerifyEmailRequest, O: Empty },
    { name: "DeleteAccount", options: {}, I: DeleteAuthAccountRequest, O: Empty },
    { name: "VerifyDeviceToken", options: {}, I: VerifyDeviceTokenRequest, O: VerifyTokenResponse },
    { name: "AuthDeviceByID", options: {}, I: AuthDeviceByIDRequest, O: AuthDeviceByIDResponse },
    { name: "UpdatePrimaryEmail", options: {}, I: UpdatePrimaryEmailRequest, O: AuthAccount },
    { name: "ExchangeSocialAuthToken", options: {}, I: ExchangeSocialAuthTokenRequest, O: ExchangeSocialAuthTokenResponse },
    { name: "SetAccountClaims", options: {}, I: SetAccountClaimsRequest, O: Empty },
    { name: "CheckBasicAuthMethod", options: {}, I: CheckBasicAuthMethodRequest, O: Empty },
    { name: "DisableMFAForAccount", options: {}, I: DisableMFAAccountRequest, O: DisableMFAAccountResponse },
    { name: "ValidateCredentials", options: {}, I: ValidateCredentialsRequest, O: ValidateCredentialsResponse }
]);
