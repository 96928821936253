import React from "react";
import { Form } from "reactstrap";

import { DeviceFormik } from "./lib/useDeviceFormik";
import { tooltips } from "../../../../lib/utils/tooltips";

import { FormSection } from "../../FormSection/FormSection";
import { ExistingDeviceSelect } from "./ExistingDeviceSelect/ExistingDeviceSelect";
import { LabelWithInformationBox } from "../../LabelWithInformationBox/LabelWithInformationBox";
import { DeviceFormFields } from "./DeviceFormFields";

import { WorkplaceTypes } from "../../../Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

interface Props {
  formik: DeviceFormik;
  addNewDevice?: boolean;
  parentType?: WorkplaceTypes;
}

/*
  In devices page is the case that we don't need to show the form in two switches,
  therefore the section has conditional rendering
*/
export const DeviceForm = (props: Props) => {
  const { formik, addNewDevice, parentType } = props;

  return (
    <Form className="DeviceForm">
      {(parentType === "Room" ||
        parentType === undefined ||
        parentType === "Device") && (
        <FormSection
          title="Add new Room Device"
          switchName="add-new-room-device"
          onChange={(value) => {
            if (value) {
              formik.setFieldValue("typeOfWorkspace", "room");
              formik.setFieldTouched("type", true);

              formik.setFieldValue("type", "new");
              formik.setFieldTouched("type", true);
            }
          }}
          isActive={
            formik.values.typeOfWorkspace === "room" &&
            formik.values.type === "new"
          }
        >
          <DeviceFormFields formik={formik} image="pairing-screen" />

          <LabelWithInformationBox
            title="How do I install the app?"
            direction="left"
            message={tooltips.devices.downloadRdx}
            className="justify-content-center pt-2"
          />
        </FormSection>
      )}

      {(parentType === "Wayfinder" ||
        parentType === undefined ||
        parentType === "Device") && (
        <FormSection
          title="Add new Wayfinder Device"
          switchName="add-new-wayfinder-device"
          onChange={(value) => {
            if (value) {
              formik.setFieldValue("typeOfWorkspace", "wayFinder");
              formik.setFieldTouched("type", true);

              formik.setFieldValue("type", "new");
              formik.setFieldTouched("type", true);
            }
          }}
          isActive={
            formik.values.typeOfWorkspace === "wayFinder" &&
            formik.values.type === "new"
          }
        >
          <DeviceFormFields formik={formik} image="wayfinder_paringCode_ex" />

          <LabelWithInformationBox
            title="How do I install the app?"
            direction="left"
            message={tooltips.devices.downloadRfx}
            className="justify-content-center pt-2"
          />
        </FormSection>
      )}

      {!addNewDevice && (
        <FormSection
          title="Add an existing device"
          switchName="add-existing-device"
          onChange={(value) => {
            if (value) {
              formik.setFieldValue("type", "existing");
              formik.setFieldTouched("type", true);
            }

            if (!!parentType) {
              formik.setFieldValue(
                "typeOfWorkspace",
                parentType === "Room" ? "room" : "wayFinder"
              );
              formik.setFieldTouched("typeOfWorkspace", true);
            }
          }}
          isActive={formik.values.type === "existing"}
        >
          <ExistingDeviceSelect
            name="existingDeviceId"
            onChange={(value) => {
              formik.setFieldTouched("existingDeviceId", true);
              formik.setFieldValue("existingDeviceId", value);
            }}
            parentType={parentType}
            errorMessage={formik.errors.existingDeviceId || ""}
            invalid={
              (!!formik.errors.existingDeviceId &&
                formik.touched.existingDeviceId) ||
              false
            }
          />
        </FormSection>
      )}
    </Form>
  );
};
