import React from "react";
import cs from "classnames";
import { toast, ToastContainer } from "react-toastify";

import logo from "../../../assets/images/logo.png";
import { WorkspacePlatformMessage } from "./WorkspacePlatformMessage/WorkspacePlatformMessage";

import "./AuthenticationPage.scss";

// import { Beta } from "../Beta/Beta";

interface Props {
  formTitle?: string;
  fullWidth?: boolean;
  information?: string;
  hideFormTitle?: boolean;
  children: React.ReactNode;
  isDefault?: boolean;
  formContentClassname?: string;
}

export const AuthenticationPage = ({
  information,
  children,
  formTitle,
  fullWidth,
  hideFormTitle,
  isDefault,
  formContentClassname,
}: Props) => {
  /*
    There is a case with the public routes, e.g. /set-contact-password. Where the user can view it even if it's authenticated.
    This was breaking the layout because the sidebar & header was still showing up. For this the most stable version was to add
    a class AuthenticationPage--full which covers the whole layout with fixed position.
  */

  const classes = cs("AuthenticationPage", {
    "AuthenticationPage--full": fullWidth,
  });

  return (
    <div className={classes}>
      <ToastContainer
        enableMultiContainer
        position={toast.POSITION.TOP_RIGHT}
      />
      <div
        className={cs("AuthenticationPage__wrapper", {
          "mt-5": formTitle === "Account activated!",
        })}
      >
        <div className="AuthenticationPage__wrapper__content">
          <div className="AuthenticationPage__background">
            <WorkspacePlatformMessage isDefault={isDefault} />
          </div>
          <div className="AuthenticationPage__form">
            <div className="AuthenticationPage__form__logo">
              <img src={logo} alt="Goget Logo" />
              {/* <Beta /> */}
            </div>
            <div
              className={`AuthenticationPage__form__content ${formContentClassname}`}
            >
              {formTitle && !hideFormTitle && (
                <h3 className="AuthenticationPage__form-title">{formTitle}</h3>
              )}
              {children}
            </div>
            {information && (
              <span className="AuthenticationPage__info">{information}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
