import { RoomSelectFields } from "../../../../api/graphql/rooms/rooms";

export const structureInitialValues = (
  data: RoomSelectFields[] | undefined,
  values: string[]
) => {
  if (values.length === 0 || data === undefined) {
    return [];
  }

  const rooms = data.filter((item) => values.includes(item.id));

  return rooms.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};
