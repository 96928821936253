import { FinishedUnaryCall } from "@protobuf-ts/runtime-rpc";
import { useEffect, useMemo, useReducer, useRef } from "react";

import { transformObjectToString } from "../helpers/transformObjectToString";

interface State<R> {
  data?: R;
  error?: Error;
  loading: boolean;
}

type Cache<T> = { [name: string]: T };

type Action<R> =
  | { type: "loading" }
  | { type: "fetched"; payload: R }
  | { type: "error"; payload: Error };

interface Props<V extends Object, R extends Object> {
  variables: V;
  method: (variables: V) => Promise<FinishedUnaryCall<V, R>>;
}

interface Response<R> extends State<R> {
  refetch: () => void;
}

function useGrpcQuery<V extends Object, R extends Object>({
  variables,
  method,
}: Props<V, R>): Response<R> {
  const cache = useRef<Cache<R>>({});
  const unMounted = useRef<boolean>(false);
  const nextRequest = useRef<boolean>(false);
  const name = useMemo(() => {
    const variablesName = transformObjectToString(variables);

    return `${method.name}:${variablesName}`;
  }, [variables]);

  const initialState: State<R> = {
    loading: false,
    error: undefined,
    data: undefined,
  };

  // Keep state logic separated

  const fetchReducer = (state: State<R>, action: Action<R>): State<R> => {
    switch (action.type) {
      case "loading":
        return { ...initialState, loading: true };
      case "fetched":
        return { ...initialState, data: action.payload };
      case "error":
        return { ...initialState, error: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    return () => {
      unMounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (!name) return;

    const fetchData = async () => {
      dispatch({ type: "loading" });

      if (cache.current[name]) {
        dispatch({ type: "fetched", payload: cache.current[name] });
        return;
      }

      try {
        const { response } = await method(variables);

        cache.current[name] = response;

        if (unMounted.current) {
          return;
        }

        if (!nextRequest.current) {
          dispatch({ type: "fetched", payload: response });
          nextRequest.current = false;
        }
      } catch (error: any) {
        if (unMounted.current) return;

        if (!nextRequest.current) {
          dispatch({ type: "error", payload: error as Error });
        }
      }
    };

    void fetchData();

    return () => {
      if (state.loading) {
        nextRequest.current = true;
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return {
    ...state,
    refetch: () => {
      cache.current = {};

      // TO DO: This fetchData function is repeated from on the second useEffect too, refactor to have it only once.
      const fetchData = async () => {
        dispatch({ type: "loading" });

        if (cache.current[name]) {
          dispatch({ type: "fetched", payload: cache.current[name] });
          return;
        }

        try {
          const { response } = await method(variables);

          cache.current[name] = response;

          if (unMounted.current) {
            return;
          }

          if (!nextRequest.current) {
            dispatch({ type: "fetched", payload: response });
            nextRequest.current = false;
          }
        } catch (error: any) {
          if (unMounted.current) return;

          if (!nextRequest.current) {
            dispatch({ type: "error", payload: error as Error });
          }
        }
      };

      void fetchData();
    },
  };
}

export default useGrpcQuery;
