import React from "react";
import { useCalendarContext } from "../../../../../../lib/context/Calendar/CalendarContext";
import { CenteredBox } from "../../../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../../../shared/LoadingBox/LoadingBox";
import { BookingsMapDeleteRoomForm } from "./BookingsMapDeleteRoomForm";

interface Props {
  id: string;
  toggleModal: () => void;
  refetch: () => void;
}

export const BookingsMapDeleteRoom = ({ id, toggleModal, refetch }: Props) => {
  const {
    loadingPersonalCalendar: loading,
    errorPersonalCalendar: error,
    personalCalendar,
  } = useCalendarContext();

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={
        <LoadingBox count={5} minHeight={45} className="mb-1" />
      }
    >
      <HandleRequestState
        state={!!error}
        placeholder={
          <CenteredBox>Couldn't fetch personal integration</CenteredBox>
        }
      >
        <BookingsMapDeleteRoomForm
          id={id}
          toggleModal={toggleModal}
          refetch={refetch}
          personalCalendar={personalCalendar}
        />
      </HandleRequestState>
    </HandleLoadingState>
  );
};
