import { WorkplaceTypes } from "../AddWorkplaceButton/AddWorkplaceButton";

export const getPossibleTypes = (type?: WorkplaceTypes) => {
  if (!type) {
    return [
      {
        type: "Company",
        label: "Company",
        icon: "company_icon_18x18",
      },
      {
        type: "Site",
        label: "Site",
        icon: "site_icon_18x18",
      },
      {
        type: "Building",
        label: "Building",
        icon: "buildings-icon",
      },
      {
        type: "Floor",
        label: "Floor",
        icon: "floors-icon",
      },
      {
        type: "Zone",
        label: "Zone",
        icon: "zone_icon_18x18",
      },
      {
        type: "Room",
        label: "Room",
        icon: "rooms-icon",
      },
      {
        type: "Desk",
        label: "Desk",
        icon: "desk_icon_18x18",
      },
      {
        type: "Wayfinder",
        label: "Wayfinder",
        icon: "wayFinder_icon_18x18",
      },
    ];
  }

  if (type === "Company") {
    return [
      {
        type: "Site",
        label: "Site",
        icon: "site_icon_18x18",
      },
      {
        type: "Building",
        label: "Building",
        icon: "buildings-icon",
      },
      {
        type: "Floor",
        label: "Floor",
        icon: "floors-icon",
      },
      {
        type: "Zone",
        label: "Zone",
        icon: "zone_icon_18x18",
      },
      {
        type: "Room",
        label: "Room",
        icon: "rooms-icon",
      },
      {
        type: "Desk",
        label: "Desk",
        icon: "desk_icon_18x18",
      },
      {
        type: "Wayfinder",
        label: "Wayfinder",
        icon: "wayFinder_icon_18x18",
      },
    ];
  }

  if (type === "Site") {
    return [
      {
        type: "Building",
        label: "Building",
        icon: "buildings-icon",
      },
      {
        type: "Floor",
        label: "Floor",
        icon: "floors-icon",
      },
      {
        type: "Zone",
        label: "Zone",
        icon: "zone_icon_18x18",
      },
      {
        type: "Room",
        label: "Room",
        icon: "rooms-icon",
      },
      {
        type: "Desk",
        label: "Desk",
        icon: "desk_icon_18x18",
      },
      {
        type: "Wayfinder",
        label: "Wayfinder",
        icon: "wayFinder_icon_18x18",
      },
    ];
  }

  if (type === "Building") {
    return [
      {
        type: "Floor",
        label: "Floor",
        icon: "floors-icon",
      },
      {
        type: "Zone",
        label: "Zone",
        icon: "zone_icon_18x18",
      },
      {
        type: "Room",
        label: "Room",
        icon: "rooms-icon",
      },
      {
        type: "Desk",
        label: "Desk",
        icon: "desk_icon_18x18",
      },
      {
        type: "Wayfinder",
        label: "Wayfinder",
        icon: "wayFinder_icon_18x18",
      },
    ];
  }

  if (type === "Floor") {
    return [
      {
        type: "Zone",
        label: "Zone",
        icon: "zone_icon_18x18",
      },
      {
        type: "Room",
        label: "Room",
        icon: "rooms-icon",
      },
      {
        type: "Desk",
        label: "Desk",
        icon: "desk_icon_18x18",
      },
      {
        type: "Wayfinder",
        label: "Wayfinder",
        icon: "wayFinder_icon_18x18",
      },
    ];
  }

  if (type === "Zone") {
    return [
      {
        type: "Room",
        label: "Room",
        icon: "rooms-icon",
      },
      {
        type: "Desk",
        label: "Desk",
        icon: "desk_icon_18x18",
      },
      {
        type: "Wayfinder",
        label: "Wayfinder",
        icon: "wayFinder_icon_18x18",
      },
    ];
  }

  return [
    {
      type: "Device",
      label: "Device",
      icon: "device_icon_18x18",
    },
  ];
};
