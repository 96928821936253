import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { usePartnerData } from "../lib/usePartnerData";
import { useChannelPartnerFormik } from "./lib/useChannelPartnerFormik";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { AddManagedPartner } from "./ManagedPartnerContent/AddManagedPartner";
import { ManagedPartner } from "./ManagedPartnerContent/ManagedPartner";

export const ManagedPartnerMain = () => {
  const { loading, error, data, createChannelPartner } = usePartnerData();
  const [hasPartner, setHasPartner] = useState<boolean>(!!data?.email);

  const formik = useChannelPartnerFormik({
    onSubmit: async (values) => {
      try {
        await createChannelPartner(values.email);
        setHasPartner(true);
        toast.success("Partner added successfuly!");
      } catch (error: any) {
        toast.error(
          error?.message || "Partner couldn't be added, please try again!"
        );
        console.error(error);
      }
    },
  });

  useEffect(() => {
    if (data?.email) {
      return setHasPartner(true);
    }

    setHasPartner(false);
  }, [data]);

  return (
    <HandleRequestState
      state={loading}
      placeholder={
        <>
          <LoadingBox minHeight={450} className="BillingInfo--loading" />
        </>
      }
    >
      <HandleRequestState
        state={!!error}
        placeholder={<p>Error loading subscription information</p>}
      >
        <div className="BillingInfo default-card">
          {hasPartner ? (
            <ManagedPartner
              email={data?.email ? data?.email : formik.values.email}
            />
          ) : (
            <AddManagedPartner
              connectPartner={formik.handleSubmit}
              formik={formik}
            />
          )}
        </div>
      </HandleRequestState>
    </HandleRequestState>
  );
};
