import React from "react";
import { ProvidersButtons } from "./ProvidersButtons";

import "./ProvidersLogin.scss";

export const ProvidersLogin = () => {
  return (
    <div className="ProvidersLogin mt-3">
      <div className="flex-a-center mb-3">
        <div className="ProvidersLogin__line"></div>
        <div className="ProvidersLogin__text">Or</div>
        <div className="ProvidersLogin__line"></div>
      </div>

      <ProvidersButtons />
    </div>
  );
};
