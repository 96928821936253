import React from "react";
import { WorkplaceFields } from "../../../../../../api/graphql/workplaces";
import { trimText } from "../../../../../../lib/helpers/trimText";

interface Props {
  workspace: WorkplaceFields;
  handleWorkspace: (
    item: WorkplaceFields,
    type: "desks" | "rooms",
    methodType: "add" | "delete"
  ) => void;
  onClick?: (item: WorkplaceFields) => void;
}

export const WayFinderFormWorkspacesItem = ({
  workspace,
  handleWorkspace,
  onClick,
}: Props) => {
  return (
    <span className="WayFinderFormWorkspacesItem mb-1">
      {trimText(workspace.name, 20)}
      <span
        data-role="remove"
        role="button"
        onClick={() => {
          if (onClick) {
            return onClick(workspace);
          }

          return handleWorkspace(
            workspace,
            workspace.__typename === "Desk" ? "desks" : "rooms",
            "delete"
          );
        }}
      ></span>
    </span>
  );
};
