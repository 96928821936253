import React, { PropsWithChildren } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";

import "./ReAuthModal.scss";

type ReAuthType = "User" | "Admin" | "Directory";

const text: { [key in ReAuthType]: string } = {
  Admin:
    "While Goget has a system in place to automatically refresh the renew token, you may be receiving this warning because your company's Microsoft 365 or Google Workspace system is configured to revoke the service account (SA) session in response to certain events, such as a change of the master password. To resolve this issue, please re-authenticate your service account integration by following the provided instructions. Your company's calendar provider will typically prompt you to sign in with the SA again, after which your integration should resume normal functionality.",
  User: "You may be receiving this warning because your company's Microsoft 365 or Google Workspace system is configured to revoke your user session at regular intervals, such as every 90 days, or in response to certain events, such as a change in location. To resolve this issue, please re-authenticate your calendar integration by following the provided instructions. Your company's calendar provider will typically prompt you to sign in again, after which your linked personal integration should resume normal functionality.",
  Directory:
    "Auto-renewal of renew-token failed. Directory integration re-authentication is required.",
};

export const ReAuthModal = ({
  closeModal,
  handleReAuthButton,
  type,
  isEws,
}: PropsWithChildren<{
  closeModal: () => void;
  handleReAuthButton: () => void;
  type: ReAuthType;
  isEws?: boolean;
}>) => {
  return (
    <Modal className="ReAuthModal" isOpen={true}>
      <Icon className="ReAuthModal__header" icon="alert-exclamation" />
      <h6>
        {isEws
          ? "Verify EWS credentials required"
          : `${type} re-authentication required`}
      </h6>
      <span className="ReAuthModal__header--subTitle">
        -Why am I receiving this warning?
      </span>

      <ModalBody>
        <span>
          {isEws
            ? "You may be receiving this warning because your company's Exchange Web Services (EWS) system revoke the service account (SA) session in response to certain events, such as a change of the master password, or wrong password. To resolve this issue, please re-authenticate your service account integration by verifying your EWS connection credentials."
            : text[type]}
        </span>
        <br></br>
        <br></br>

        <span>
          If you have any further questions, we recommend reaching out to your
          system administrator, as this process falls outside of Goget's domain.
        </span>
      </ModalBody>

      <ModalFooter>
        <Button
          size="small"
          color="outline-secondary"
          title="Close"
          onClick={closeModal}
        />
        <Button
          size="small"
          color="primary"
          title="Re-authenticate"
          onClick={handleReAuthButton}
        />
      </ModalFooter>
    </Modal>
  );
};
