import React from "react";
import { PageHeader } from "../../../components/shared/PageHeader/PageHeader";
import { AddTeamButton } from "../../../components/Teams/AddTeamButton/AddTeamButton";
import { TeamList } from "../../../components/Teams/TeamList/TeamList";

export const Teams = () => {
  return (
    <div className="default-page mt-4">
      <PageHeader title="Teams" message="teams">
        <AddTeamButton adjustable size="medium" />
      </PageHeader>

      <TeamList />
    </div>
  );
};
