import React from "react";

import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import { WorkplaceExpandedDevicesMain } from "./WorkplaceExpandedDevicesMain";
import { WorkplaceExpandedDevicesRfx } from "./WorkplaceExpandedDevicesRfx";

export type DeviceType = "main" | "rfx";

interface Props {
  parentId?: string;
  onClose: () => void;
  data: WorkplaceFields[];
  deviceType?: DeviceType;
}

const components: {
  [key in DeviceType]: React.FunctionComponent<Props>;
} = {
  main: WorkplaceExpandedDevicesMain,
  rfx: WorkplaceExpandedDevicesRfx,
};

export const WorkplaceExpandedDevices = ({
  parentId,
  onClose,
  data,
  deviceType,
}: Props) => {
  const Component = components[deviceType ? deviceType : "main"];

  const componentProps: Props = {
    parentId,
    onClose,
    data,
    deviceType,
  };

  return <Component {...componentProps} />;
};
