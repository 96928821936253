import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import useLocalStorageState from "use-local-storage-state";
import cs from "classnames";

import { useDefineBounds } from "../lib/useDefineBounds";
import { useMarker } from "../lib/useMarkerData";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useShapeHandlers } from "../lib/useShapeHandlers";

import { drawMarkers } from "../../../lib/helpers/leaflet";
import { getImageSize } from "../../../api/external-api/react-native-map-view/utils/overlay.utils";
import { EditControl } from "react-leaflet-draw";
import {
  MapContainer,
  ImageOverlay,
  MapConsumer,
  FeatureGroup,
} from "react-leaflet";
import { FloorSettingsButton } from "../FloorSettings/FloorSettingsButton";
import { floorPlanHelpers } from "../lib/floorPlanHelpers";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { EmptyEntity } from "../../shared/EmptyEntity/EmptyEntity";
import { FloorFields } from "../../../api/graphql/floors/floors";
import { FloorChildrenDefaultList } from "../FloorChildrenList/FloorChildrenDefaultList";
import { FloorMarkersSettings } from "../FloorMarkersSettings/FloorMarkersSettings";
import { FloorMapActionsGroup } from "../FloorMapActionsGroup/FloorMapActionsGroup";
import { Size } from "./FloorsPlan";

import "leaflet-draw/dist/leaflet.draw.css";
import "./FloorContent.scss";

interface Props {
  data: FloorFields;
}

export const FloorPlan = ({ data }: Props) => {
  const [disableZoom, setDisableZoom] = useState(false);
  const [activeEditDraw, setActiveEditDraw] = useState(false);
  const [timeOutEdit, setTimeOutEdit] = useState(false);
  const [_, setShow] = useState(false);
  const [mapImageSize, setMapImageSize] = useState<Size | null>(null);
  const [activeRoomIdDraw, setActiveRoomIdDraw] = useState<string | undefined>(
    undefined
  );

  const editableLayersRef = useRef<L.FeatureGroup>(new L.FeatureGroup());

  const { handleCreateShape, handleEditShape, handleDeleteShape } =
    useShapeHandlers();
  const { user } = useAuthContext();

  const { validateInputNumber } = floorPlanHelpers();
  const [markersScaleFactor, setMarkersScaleFactor] = useLocalStorageState(
    `markers-scale-factor-${data?.id}`,
    {
      defaultValue: 1,
    }
  );

  const {
    markers,
    openMarkerPopup,
    openShapePopup,
    shapes: { _circles: circles, _polygonal: polygonal },
  } = useMarker(data, setDisableZoom, disableZoom, {
    scaleFactor: markersScaleFactor,
    showRoomMarkers: data?.map?.showRoomMarkers || false,
    activeDraw: activeEditDraw,
  });

  const { overlayBounds, mapBounds, mapMaxBounds } =
    useDefineBounds(mapImageSize);

  useEffect(() => {
    setMapImageSize(null);

    getImageSize(data?.map?.url || "").then(setMapImageSize);
  }, [data?.map?.url]);

  useEffect(() => {
    if (disableZoom === true) {
      setDisableZoom(false);
    }

    if (activeEditDraw) {
      setActiveEditDraw(false);
    }

    if (activeRoomIdDraw) {
      setActiveRoomIdDraw(undefined);
    }
  }, [data?.id]);

  useEffect(() => {
    if (!data?.map?.url) {
      return;
    }

    [...circles, ...polygonal].forEach((layer) => {
      if (layer && layer.addTo && editableLayersRef.current) {
        layer.addTo(editableLayersRef?.current);
      }
    });
  }, [circles, polygonal, editableLayersRef.current]);

  const handleShapeClick = () => {
    if (activeEditDraw) {
      return;
    }

    //automatically focus edit button when toolbar is shown:
    const editButton = document.querySelector(".leaflet-draw-edit-edit");

    if (editButton) {
      (editButton as HTMLElement).click();
    }

    setActiveRoomIdDraw(undefined);

    setActiveEditDraw(true);
  };

  const handleAddRoomButtonClick = (id?: string) => {
    setActiveEditDraw(false);

    if (sessionStorage.getItem("activeRoomIdDraw")?.length) {
      sessionStorage.removeItem("activeRoomIdDraw");
    }

    sessionStorage.setItem("activeRoomIdDraw", id || "");
    setActiveRoomIdDraw(id);
  };

  const classes = cs("FloorContent__plan", {
    "FloorContent__plan--hide": !activeEditDraw && !activeRoomIdDraw,
    "FloorContent__plan--hideDraw": activeEditDraw,
    "FloorContent__plan--hideSection": activeRoomIdDraw,
  });

  return (
    <div className="FloorContent">
      <div className={classes}>
        {data?.map === null ? (
          <EmptyEntity
            type="FloorMap"
            typeOfFloorMap="floor"
            floorId={data?.id}
          />
        ) : (
          <>
            <MapContainer
              id="map-container"
              crs={L.CRS.Simple}
              center={[0, 0]}
              zoom={0}
              maxZoom={3}
              minZoom={-3}
              zoomSnap={0.15}
              zoomDelta={0.5}
              doubleClickZoom={false}
              scrollWheelZoom={true}
              touchZoom={true}
            >
              <FloorMapActionsGroup>
                <FloorSettingsButton map={data?.map} />
                <FloorMarkersSettings
                  scaleFactor={markersScaleFactor}
                  onScaleFactorChange={setMarkersScaleFactor}
                />
              </FloorMapActionsGroup>
              {data?.map?.url !== undefined &&
              !!overlayBounds &&
              !!mapImageSize ? (
                <>
                  <MapConsumer>
                    {(map) => {
                      if (mapBounds && mapMaxBounds && disableZoom === false) {
                        // map.setMaxBounds(mapMaxBounds).fitBounds(mapBounds);
                        setTimeout(() => {
                          setShow(true);
                        }, 500);
                      }

                      drawMarkers({
                        map,
                        markers,
                        layers: [...circles, ...polygonal],
                        handleShapeClick,
                        config: {
                          mapSize: {
                            width: mapImageSize?.width / 2,
                            height: mapImageSize?.height / 2,
                          },
                        },
                        options: {
                          snapEnabled: data?.map?.showGridline || false,
                          showTooltips: data?.map?.showTooltips || false,
                          showRoomMarkers: data?.map?.showRoomMarkers || false,
                        },
                      });

                      return null;
                    }}
                  </MapConsumer>
                  <FeatureGroup ref={editableLayersRef}>
                    <EditControl
                      position="topright"
                      draw={{
                        circlemarker: false,
                        marker: false,
                        polyline: false,
                      }}
                      // onMounted={handleShapesOnMount}
                      onCreated={async (e) => {
                        await handleCreateShape({
                          drawEvent: e,
                          setActiveRoomIdDraw,
                          tenantId: user?.customerid || "",
                        });

                        if (
                          editableLayersRef.current &&
                          editableLayersRef.current?.removeLayer
                        )
                          editableLayersRef.current.removeLayer(e.layer);
                      }}
                      onEdited={async (e) => {
                        if (timeOutEdit) return;

                        setTimeOutEdit(true);
                        await handleEditShape({
                          editEvent: e,
                          tenantId: user?.customerid || "",
                        });

                        setActiveEditDraw(false);

                        setActiveRoomIdDraw(undefined);

                        setTimeout(() => {
                          setTimeOutEdit(false);
                        }, 7000);
                      }}
                      onEditStop={() => {
                        setActiveEditDraw(false);
                        setActiveRoomIdDraw(undefined);
                      }}
                      onDeleted={(e) => {
                        handleDeleteShape({
                          editEvent: e,
                          tenantId: user?.customerid || "",
                        });
                      }}
                      onDeleteStop={(e) => {
                        setActiveEditDraw(false);
                        setActiveRoomIdDraw(undefined);
                      }}
                      onEditStart={() => {
                        if (!activeEditDraw) {
                          setActiveEditDraw(true);
                        }
                      }}
                      onDeleteStart={() => {
                        if (!activeEditDraw) {
                          setActiveEditDraw(true);
                        }
                      }}
                    />
                  </FeatureGroup>
                  <ImageOverlay
                    url={data.map.url}
                    opacity={
                      data.map.opacity && validateInputNumber(data.map.opacity)
                    }
                    bounds={overlayBounds}
                  />
                </>
              ) : (
                <LoadingBox />
              )}
            </MapContainer>
          </>
        )}
      </div>
      <div className="FloorContent__children">
        <FloorChildrenDefaultList
          onClick={openMarkerPopup}
          onClickShape={openShapePopup}
          data={data}
          handleAddRoomButtonClick={handleAddRoomButtonClick}
        />
      </div>
    </div>
  );
};
