import { useApolloClient } from "@apollo/client";

import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../workplaces";

import { SITE_FIELDS_FRAGMENT } from "../sites/sites";
import { FLOOR_FIELDS_FRAGMENT } from "../floors/floors";
import { COMPANY_FIELDS_FRAGMENT } from "../companies/companies";
import { BUILDING_FIELDS_FRAGMENT } from "../buildings/buildings";

import {
  AddZoneResponse,
  AddZoneVariables,
  ADD_ZONE_TO_TENANT,
  UpdateZoneResponse,
  ZoneRequestVariables,
  UPDATE_ZONE,
  ADD_ZONE_TO_COMPANY,
  ADD_ZONE_TO_SITE,
  ADD_ZONE_TO_BUILDING,
  ZONE_FIELDS_FRAGMENT,
  ADD_ZONE_TO_FLOOR,
} from "./zones";
import { WorkplaceTypes } from "../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";

export const useZonesRequests = () => {
  const client = useApolloClient();

  const addZoneToTenant = async (variables: ZoneRequestVariables) => {
    return await client.mutate<AddZoneResponse, AddZoneVariables>({
      mutation: ADD_ZONE_TO_TENANT,
      variables,
      update: (cache, newData) => {
        const previousQuery = cache.readQuery<
          GetWorkplacesResponse,
          WorkplaceRequestVariables
        >({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
        });

        const tenantResults = previousQuery?.queryTenant[0];
        const newZone = newData.data?.addZone.zone[0];

        cache.writeQuery({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
          data: {
            queryTenant: [
              {
                ...tenantResults,
                zones: [...(tenantResults?.zones || []), newZone],
              },
            ],
          },
        });
      },
    });
  };

  const addZoneToCompany = async (variables: ZoneRequestVariables) => {
    return await client.mutate<AddZoneResponse, AddZoneVariables>({
      mutation: ADD_ZONE_TO_COMPANY,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            zones: [...previousData.zones, newData.data?.addZone.zone[0]],
          },
        });
      },
    });
  };

  const addZoneToSite = async (variables: ZoneRequestVariables) => {
    return await client.mutate<AddZoneResponse, ZoneRequestVariables>({
      mutation: ADD_ZONE_TO_SITE,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Site:${variables.id}`,
          fragmentName: "SiteFields",
          fragment: SITE_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            zones: [...previousData.zones, newData.data?.addZone.zone[0]],
          },
        });
      },
    });
  };

  const addZoneToBuilding = async (variables: ZoneRequestVariables) => {
    return await client.mutate<AddZoneResponse, ZoneRequestVariables>({
      mutation: ADD_ZONE_TO_BUILDING,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Building:${variables.id}`,
          fragmentName: "BuildingFields",
          fragment: BUILDING_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            zones: [...previousData.zones, newData.data?.addZone.zone[0]],
          },
        });
      },
    });
  };

  const addZoneToFloor = async (variables: ZoneRequestVariables) => {
    return await client.mutate<AddZoneResponse, ZoneRequestVariables>({
      mutation: ADD_ZONE_TO_FLOOR,
      variables,
      update: (cache, newData) => {
        const previousData = client.readFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Floor:${variables.id}`,
          fragmentName: "FloorFields",
          fragment: FLOOR_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            zones: [...previousData.zones, newData.data?.addZone.zone[0]],
          },
        });
      },
    });
  };

  const addZone = async (
    variables: ZoneRequestVariables,
    parentType?: WorkplaceTypes
  ) => {
    if (parentType === "Company") {
      return await addZoneToCompany(variables);
    }

    if (parentType === "Site") {
      return await addZoneToSite(variables);
    }

    if (parentType === "Building") {
      return await addZoneToBuilding(variables);
    }

    if (parentType === "Floor") {
      return addZoneToFloor(variables);
    }

    return await addZoneToTenant(variables);
  };

  const updateZone = async (variables: ZoneRequestVariables) => {
    return await client.mutate<UpdateZoneResponse, ZoneRequestVariables>({
      mutation: UPDATE_ZONE,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Zone:${variables.id}`,
          fragmentName: "ZoneFields",
          fragment: ZONE_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateZone.zone[0],
          },
        });
      },
    });
  };

  return { addZone, updateZone };
};
