import { WorkplaceFields } from "../../../api/graphql/workplaces";

type WorkplacesListType =
  | "companies"
  | "sites"
  | "buildings"
  | "floors"
  | "zones"
  | "rooms"
  | "desks"
  | "devices";

export type HierarchyListType = { [key in WorkplacesListType]: string[] };

const possibleTypes: Array<WorkplacesListType> = [
  "companies",
  "sites",
  "buildings",
  "floors",
  "zones",
  "rooms",
  "desks",
  "devices",
];

const defaultList: HierarchyListType = {
  companies: [],
  sites: [],
  buildings: [],
  floors: [],
  zones: [],
  rooms: [],
  desks: [],
  devices: [],
};

const flatListChildren = (
  workplace: WorkplaceFields,
  list: HierarchyListType = defaultList
) => {
  let result = list;

  for (const typeItem of possibleTypes) {
    const type = workplace[typeItem];

    if (type === undefined || type.length === 0) {
      continue;
    }

    const ids = type.map((item) => item.id);

    result = {
      ...result,
      [typeItem]: [...result[typeItem], ...ids],
    };

    for (const subItem of type) {
      const childResult = flatListChildren(subItem, result);

      result = childResult;
    }
  }

  return result;
};

export const getHierarchyItems = (data: WorkplaceFields) => {
  const children = flatListChildren(data);

  return {
    hasChildren: Object.values(children).some((item) => item.length > 0),
  };
};
