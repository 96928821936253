import gql from "graphql-tag";
import { DESK_FIELDS_FRAGMENT } from "../desks/desks";
import {
  CustomLanguageType,
  Marker,
  ROOM_FIELDS_FRAGMENT,
} from "../rooms/rooms";
import {
  WorkplaceDefaultFields,
  WorkplaceFields,
  WorkplaceFormFields,
  WorkplaceTenantFields,
} from "../workplaces";

export const defaultDisplaySettings = {
  showOrganizer: true,
  showMeetingTitle: true,
  allowAdhocBookings: false,
  mapView: false,
  maximizedMapView: false,
  scalingFactor: "1",
  floor: null,
  energySaveMode: false,
  showOccupancyCards: true,
  switcherTempo: 5,
};

export interface WayfinderSelectFields {
  id: string;
  name: string;
  devices: [
    {
      id: string;
      name: string;
    }
  ];
}

export interface GetWayfinderSelectResponse {
  queryWayfinder: WayfinderSelectFields[];
}

export interface WayFinderCoreSettings extends WorkplaceDefaultFields {
  rooms: WorkplaceFields[];
  desks: WorkplaceFields[];
}

export interface WayfinderFormFields extends WorkplaceFormFields {
  desks: WorkplaceFields[];
  rooms: WorkplaceFields[];
  licensed: boolean;
  distanceUnit: DistanceUnitType;
  marker: Marker;
}

export interface FormikWayFinderDisplaySettings {
  main: {
    adminPin: string;
    backgroundImageUrl: string;
    logoImageUrl: string;
  };
  display: {
    showOrganizer: boolean;
    showMeetingTitle: boolean;
    allowAdhocBookings: boolean;
    mapView: boolean;
    maximizedMapView: boolean;
    scalingFactor: string;
    floor: {
      id: string;
    } | null;
    energySaveMode: boolean;
    energySaveModeStart: string;
    energySaveModeEnd: string;
    showOccupancyCards: boolean;
    switcherTempo: number;
    customerId: string;
    customLanguage?: CustomLanguageType;
    workingWeekDays: number[];
  };
}

export interface WayFinderDisplaySettings {
  adminPin?: string;
  backgroundImageUrl?: string;
  logoImageUrl?: string;

  showOrganizer: boolean;
  showMeetingTitle: boolean;
  allowAdhocBookings: boolean;
  mapView: boolean;
  maximizedMapView: boolean;
  scalingFactor: string;
  floor: {
    id: string;
  } | null;
  energySaveMode: boolean;
  energySaveModeStart?: string;
  energySaveModeEnd?: string;
  showOccupancyCards: boolean;
  switcherTempo: number;
  customerId?: string;
  customLanguage?: CustomLanguageType;
  workingWeekDays?: number[];
}

export interface AddWorkplaceReference {
  id: string;
}

export type DistanceUnitType = "METRE_M" | "YARD_YD" | "MINUTE_MIN";

export interface WayfinderRequestVariables extends WorkplaceTenantFields {
  customerId?: string;
  name: string;
  tags: string[];
  description: string;
  desks: AddWorkplaceReference[];
  rooms: AddWorkplaceReference[];
  displaySettings?: WayFinderDisplaySettings;
  distanceUnit?: DistanceUnitType;
  workingWeekDays?: number[];
  marker: Marker;
  remove?: {
    tags: string[];
    desks: AddWorkplaceReference[];
    rooms: AddWorkplaceReference[];
  };
}

export interface WayFinderFields extends WorkplaceFields {
  tags: string[];
  description: string;
  licensed: boolean;
  rooms?: WorkplaceFields[];
  desks?: WorkplaceFields[];
  marker: Marker;
  displaySettings: WayFinderDisplaySettings | null;
  devices: WorkplaceFields[];
  distanceUnit: DistanceUnitType;
}

export interface AddWayfinderResponse {
  addWayfinder: {
    wayfinder: WayFinderFields[];
  };
}

export interface UpdateWayfinderResponse {
  updateWayfinder: {
    wayfinder: WayFinderFields[];
  };
}

export interface UpdateWayfinderDisplaySettingsVariables {
  id: string;
  displaySettings: WayFinderDisplaySettings;
}

export interface UpdateWayfinderLicenseVariables extends WorkplaceTenantFields {
  licensed: boolean;
}

export interface UpdateWayfinderDistanceUnitVariables
  extends WorkplaceTenantFields {
  distanceUnit: string;
}

export interface CreateUpdateWayfinderRoomDirectionVariables {
  roomDeleteID: string[];
  directions: {
    distance: number;
    direction: number;
    room: { id: string };
    wayFinder: { id: string };
  }[];
}

export interface AddRoomDirectionToWayfinderVariables {
  roomDirectionDeleteID: string[];
  directions: {
    distance: number;
    direction: number;
    room: { id: string; name: string };
    wayFinder: { id: string; name: string };
  }[];
}

export interface AddRoomDirectionToWayfinderResponse {
  addRoomDirection: {
    roomDirection: {
      distance: number;
      direction: number;
      room: { id: string; name: string };
      wayFinder: { id: string };
    }[];
  };
}

export interface UpdateWayfinderMarkerVariables extends WorkplaceTenantFields {
  marker: Marker;
}

export const WAY_FINDER_DISPLAY_SETTINGS_FRAGMENT = gql`
  fragment WayfinderDisplaySettings on Wayfinder {
    displaySettings {
      id

      adminPin
      backgroundImageUrl
      logoImageUrl

      showOrganizer
      showMeetingTitle
      allowAdhocBookings
      mapView
      maximizedMapView
      scalingFactor
      floor {
        id
      }
      energySaveMode
      energySaveModeStart
      energySaveModeEnd
      showOccupancyCards
      switcherTempo
      customerId
      workingWeekDays
      customLanguage {
        id
        value
        isGlobal
        name
      }
    }
  }
`;

export const WAY_FINDER_FIELDS_FRAGMENT = gql`
  fragment WayfinderFields on Wayfinder {
    id
    name
    tags
    description
    tenantId
    licensed
    distanceUnit
    roomDirection {
      id
      room {
        id
        name
      }
      wayFinder {
        id
        name
      }
      distance
      direction
    }
    marker {
      latitude
      longitude
    }
    devices {
      id
      name
      tags
      description
      deviceID
      isOnline
      hardwareInfo {
        model
      }
      softwareInfo {
        version
      }
    }
    rooms {
      ...RoomFields
    }
    desks {
      ...DeskFields
    }
    ...WayfinderDisplaySettings
  }
  ${ROOM_FIELDS_FRAGMENT}
  ${DESK_FIELDS_FRAGMENT}
  ${WAY_FINDER_DISPLAY_SETTINGS_FRAGMENT}
`;

export const ADD_WAY_FINDER_TO_TENANT = gql`
  mutation addWayfinderToTenantMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $marker: MarkerRef
    $displaySettings: WayfinderDisplaySettingsRef
    $distanceUnit: String
  ) {
    addWayfinder(
      input: [
        {
          name: $name
          tags: $tags
          tenant: { id: $id }
          description: $description
          tenantId: $tenantId
          marker: $marker
          displaySettings: $displaySettings
          distanceUnit: $distanceUnit
        }
      ]
    ) {
      wayfinder {
        ...WayfinderFields
      }
    }
  }
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_WAY_FINDER_TO_COMPANY = gql`
  mutation addWayfinderToCompanyMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $marker: MarkerRef
    $tenantId: String!
    $displaySettings: WayfinderDisplaySettingsRef
    $distanceUnit: String
  ) {
    addWayfinder(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          displaySettings: $displaySettings
          marker: $marker
          distanceUnit: $distanceUnit
          company: { id: $id }
        }
      ]
    ) {
      wayfinder {
        ...WayfinderFields
      }
    }
  }
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_WAY_FINDER_TO_BUILDING = gql`
  mutation addWayfinderToBuildingMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $marker: MarkerRef
    $displaySettings: WayfinderDisplaySettingsRef
    $distanceUnit: String
  ) {
    addWayfinder(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          marker: $marker
          displaySettings: $displaySettings
          distanceUnit: $distanceUnit
          building: { id: $id }
        }
      ]
    ) {
      wayfinder {
        ...WayfinderFields
      }
    }
  }
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_WAY_FINDER_TO_SITE = gql`
  mutation addWayfinderToSiteMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $marker: MarkerRef
    $tenantId: String!
    $displaySettings: WayfinderDisplaySettingsRef
    $distanceUnit: String
  ) {
    addWayfinder(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          marker: $marker
          displaySettings: $displaySettings
          distanceUnit: $distanceUnit
          site: { id: $id }
        }
      ]
    ) {
      wayfinder {
        ...WayfinderFields
      }
    }
  }
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_WAY_FINDER_TO_FLOOR = gql`
  mutation addWayfinderToFloorMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $marker: MarkerRef
    $tenantId: String!
    $displaySettings: WayfinderDisplaySettingsRef
    $distanceUnit: String
  ) {
    addWayfinder(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          marker: $marker
          tenantId: $tenantId
          displaySettings: $displaySettings
          distanceUnit: $distanceUnit
          floor: { id: $id }
        }
      ]
    ) {
      wayfinder {
        ...WayfinderFields
      }
    }
  }
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const ADD_WAY_FINDER_TO_ZONE = gql`
  mutation addWayfinderToZoneMutation(
    $id: ID
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $marker: MarkerRef
    $displaySettings: WayfinderDisplaySettingsRef
    $distanceUnit: String
  ) {
    addWayfinder(
      input: [
        {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          marker: $marker
          displaySettings: $displaySettings
          distanceUnit: $distanceUnit
          zone: { id: $id }
        }
      ]
    ) {
      wayfinder {
        ...WayfinderFields
      }
    }
  }
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const UPDATE_WAY_FINDER = gql`
  mutation updateWayfinderSettings(
    $id: ID!
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $marker: MarkerRef
    $rooms: [RoomRef]
    $desks: [DeskRef]
    $remove: WayfinderPatch
  ) {
    updateWayfinder(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          tags: $tags
          description: $description
          tenantId: $tenantId
          marker: $marker
          rooms: $rooms
          desks: $desks
        }
        remove: $remove
      }
    ) {
      wayfinder {
        ...WayfinderFields
      }
    }
  }
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

export const UPDATE_WAY_FINDER_DISPLAY_SETTINGS = gql`
  mutation updateWayfinderDisplaySettings(
    $id: ID!
    $displaySettings: WayfinderDisplaySettingsRef
  ) {
    updateWayfinder(
      input: {
        filter: { id: [$id] }
        set: { displaySettings: $displaySettings }
      }
    ) {
      wayfinder {
        id
        tenantId
        devices {
          id
          deviceID
          tenantId
        }
        ...WayfinderDisplaySettings
      }
    }
  }
  ${WAY_FINDER_DISPLAY_SETTINGS_FRAGMENT}
`;

export const UPDATE_WAY_FINDER_LICENSE = gql`
  mutation updateWayfinderSettings(
    $id: ID!
    $tenantId: String!
    $licensed: Boolean
  ) {
    updateWayfinder(
      input: {
        filter: { id: [$id] }
        set: { tenantId: $tenantId, licensed: $licensed }
      }
    ) {
      wayfinder {
        id
        tenantId
        licensed
      }
    }
  }
`;

export const GET_WAY_FINDERS_SELECT = gql`
  query getWayfindersSelectQuery($id: String) {
    queryWayfinder(filter: { tenantId: { eq: $id } }) {
      id
      name
      devices {
        id
        name
      }
    }
  }
`;

export const UPDATE_WAYFINDER_DISTANCE_UNIT = gql`
  mutation updateDistanceUnits($id: ID!, $distanceUnit: String) {
    updateWayfinder(
      input: { filter: { id: [$id] }, set: { distanceUnit: $distanceUnit } }
    ) {
      wayfinder {
        id
        distanceUnit
      }
    }
  }
`;

export const CREATE_UPDATE_WAYFINDER_ROOM_DIRECTIONS = gql`
  mutation createUpdateWayfinderRoomDirections(
    $roomDeleteID: [ID!]
    $directions: [AddRoomDirectionInput!]!
  ) {
    deleteRoomDirection(filter: { id: $roomDeleteID }) {
      numUids
    }
    addRoomDirection(input: $directions) {
      numUids
      roomDirection {
        id
      }
    }
  }
`;

export const ADD_ROOM_DIRECTION_TO_WAYFINDER = gql`
  mutation addRoomDirectionToWayfinder(
    $roomDirectionDeleteID: [ID!]
    $directions: [AddRoomDirectionInput!]!
  ) {
    deleteRoomDirection(filter: { id: $roomDirectionDeleteID }) {
      numUids
    }
    addRoomDirection(input: $directions) {
      roomDirection {
        id
        wayFinder {
          id
          name
        }
        room {
          id
          name
        }
        direction
        distance
      }
    }
  }
`;

export const ADD_MARKER_TO_WAYFINDER = gql`
  mutation addMarkerToWayfinder($id: ID!, $marker: MarkerRef) {
    updateWayfinder(
      input: { filter: { id: [$id] }, set: { marker: $marker } }
    ) {
      wayfinder {
        id
        marker {
          latitude
          longitude
        }
      }
    }
  }
`;
