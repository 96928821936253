import React from "react";
import { Button, ThemeButtonProps } from "../Button/Button";
import { Icon } from "../Icon/Icon";

import "./DoubleButton.scss";

interface Props extends ThemeButtonProps {
  secondButtonClick: React.MouseEventHandler<HTMLDivElement>;
}

export const DoubleButton = ({
  title,
  onClick,
  disabled,
  icon,
  secondButtonClick,
}: Props) => {
  return (
    <div className="DoubleButton">
      <Button
        color="primary"
        size="small"
        title={title || "Save"}
        onClick={onClick}
        disabled={disabled}
        className="d-block ml-auto"
      />
      <div
        role="button"
        onClick={secondButtonClick}
        className="DoubleButton__secondButton"
      >
        <Icon icon={icon || "polygon-revert"} />
      </div>
    </div>
  );
};
