import React from "react";
import { BrowserRouter, NavLink } from "react-router-dom";
import { trimText } from "../../../lib/helpers/trimText";
import { WORKPLACES_ROOT_PATH } from "../../../lib/routes/routes";
import { Icon } from "../../shared/Icon/Icon";
import { MarkerStatus } from "./MarkerStatus";
import { MarkerStatuses } from "../lib/useMarkerData";

import "./Popup.scss";

interface Props {
  id: string;
  type: string;
  user: string;
  onClick?: () => void;
  isBlocked?: boolean;
  location?: string;
  name?: string;
}

export const Popup = ({ name, location, isBlocked, id, type, user }: Props) => {
  const hasUser = !!user?.length;

  const defineStatus = () => {
    if (isBlocked) {
      return MarkerStatuses.BLOCKED;
    }

    return MarkerStatuses.ASSIGNED;
  };

  return (
    <div className="Popup">
      <div className="Popup__head">
        <span className="Popup__name">{trimText(name || "", 25)}</span>
        {location && <span className="Popup__location">{location}</span>}
      </div>
      <div className="Popup__content">
        {(hasUser || isBlocked) && <MarkerStatus status={defineStatus()} />}

        {hasUser && (
          <div className="Popup__user flex-a-center">
            <Icon icon="single-user-thin" />
            <span className="pl-min">{user} (Assignee)</span>
          </div>
        )}

        <BrowserRouter>
          <NavLink
            to={`${WORKPLACES_ROOT_PATH}/${type.toLowerCase()}/${id}`}
            className="Popup__settings flex-a-center"
          >
            <Icon icon="settings" />
            <span>Open settings</span>
          </NavLink>
        </BrowserRouter>

        {/* <Button
          onClick={() => onClick && onClick()}
          title="Remove from map"
          size="small"
          color="primary"
        /> */}
      </div>
    </div>
  );
};
