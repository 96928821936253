import React from "react";
import { useWorkplacesClient } from "../../api/grpc/workplaces/useWorkplacesClient";
import { ListTagsEntity } from "../../api/grpc/workplaces/workplaces";
import { Icon } from "../shared/Icon/Icon";

import "./DownloadQRCode.scss";

interface Props {
  name: string;
  id: string;
  type: ListTagsEntity;
}

export const DownloadQRCode = ({ name, type, id }: Props) => {
  const { downloadQRCode } = useWorkplacesClient();

  const handleDownloadImage = async (id: string, type: ListTagsEntity) => {
    const { response } = await downloadQRCode({
      entityID: id,
      entityType: type,
    });

    let blobImage = new Blob([new Uint8Array(response.base64)], {
      type: "image/png" || "image/jpg",
    });

    let csvURL = window.URL.createObjectURL(blobImage);
    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", name);

    document.body.appendChild(tempLink);
    tempLink.click();

    document.body.removeChild(tempLink);
    tempLink.remove();
  };

  return (
    <div
      onClick={() => handleDownloadImage(id, type)}
      className="DownloadQRCode"
    >
      <Icon icon="cloud-download" />
    </div>
  );
};
