import React from "react";
import { useCustomerPermissions } from "../../../components/Billings/lib/useCustomerPermissions";
import { ErrorMessage } from "../../../components/shared/ErrorMessage/ErrorMessage";
import { RestrictedPage } from "../../RestrictedPage/RestrictedPage";
import { Floors } from "./Floors";

export const FloorsMap = () => {
  const { loading, error, isBussinesOrPro } = useCustomerPermissions();

  if (loading || isBussinesOrPro) {
    return <Floors />;
  }

  if (error) {
    return (
      <ErrorMessage
        message="
       Error fetching user plan, please try reloading the page!
    "
      />
    );
  }

  return <RestrictedPage title="Manage floor plan with map" />;
};
