import React, { PropsWithChildren } from "react";
import cs from "classnames";

const statusText: { [key in number]: string } = {
  1: "New",
  2: "Removed",
  3: "Updated",
  4: "Unknown",
};

export const DirectoryIntegrationStatus = ({
  status,
}: PropsWithChildren<{ status: number }>) => {
  const classes = cs("Status", {
    "Status--active": status === 1,
    "Status--inactive": status === 2,
  });

  return <span className={classes}>{statusText[status]}</span>;
};
