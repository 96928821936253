import React from "react";

import {
  DesksMeetingBehaviorResponse,
  SankeyChartResponse,
} from "../../../../../api/grpc/insights/insights";

export interface BehaviourInsights {
  loadingMeetings: boolean;
  errorMeetings?: Error;
  dataMeetings?: DesksMeetingBehaviorResponse;
  loadingSankey: boolean;
  errorSankey?: Error;
  dataSankey?: SankeyChartResponse;
}

const values: BehaviourInsights = {
  loadingMeetings: false,
  errorMeetings: undefined,
  dataMeetings: undefined,
  loadingSankey: false,
  errorSankey: undefined,
  dataSankey: undefined,
};

export const BehaviourInsightsContext = React.createContext(values);

export const useDeskBehaviourInsightsContextContext = () => {
  const context = React.useContext(BehaviourInsightsContext);

  if (!context) {
    throw new Error(
      "useDeskBehaviourInsightsContextContext must be used inside of BehaviourInsightsContext"
    );
  }

  return context;
};
