import { useFormik, FormikHelpers } from "formik";
import { rest } from "lodash";
import * as Yup from "yup";

export interface ChannelPartnerFields {
  email: string;
}
const ChannelPartnerSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email!")
    .required("Email is required"),
});

interface UseChannelPartnerFormOptions {
  initialValues?: ChannelPartnerFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: ChannelPartnerFields,
    formikHelpers: FormikHelpers<ChannelPartnerFields>
  ) => Promise<any>;
}

export type ChannelPartnerFormik = ReturnType<typeof useChannelPartnerFormik>;

export const useChannelPartnerFormik = (opts: UseChannelPartnerFormOptions) => {
  return useFormik<ChannelPartnerFields>({
    initialValues: {
      email: "",
    },
    ...rest,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ChannelPartnerSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
