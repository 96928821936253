export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_O365_CLIENT as string,
    redirectUri: process.env.REACT_APP_O365_REDIRECT,
  },
  scopes: ["User.Read"],
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
