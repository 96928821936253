import React, { PropsWithChildren, useEffect, useRef } from "react";
import { tooltips } from "../../../../../lib/utils/tooltips";
import { Icon } from "../../../Icon/Icon";
import { LabelWithInformationBox } from "../../../LabelWithInformationBox/LabelWithInformationBox";

export const SwitcherTempo = ({
  switcherTempo,
  onChange,
}: PropsWithChildren<{
  switcherTempo: number;
  onChange: (
    key: string,
    value: string | string[] | number | boolean | Date | null
  ) => void;
}>) => {
  const ref = useRef(null);
  const sliderValue = useRef<any>(null);

  let thumbSize = 15;

  const handleSliderValuePosition = (input: any) => {
    const multiplier = input.value / input.max;
    const thumbOffset = thumbSize * multiplier;
    const priceInputOffset = (thumbSize - sliderValue.current.clientWidth) / 2;
    sliderValue.current.style.left =
      input.clientWidth * multiplier -
      thumbOffset +
      (priceInputOffset + handleMultiplyValue()) +
      "px";
  };

  useEffect(() => {
    handleSliderValuePosition(ref.current);
  }, [switcherTempo]);

  const handleBackgroundColor = () => {
    switch (switcherTempo) {
      case 5:
        return 1;

      case 10:
        return 2;

      case 30:
        return 3.2;

      default:
        return 3;
    }
  };

  const handleMultiplyValue = () => {
    switch (switcherTempo) {
      case 5:
        return 12;

      case 10:
        return 17;

      case 15:
        return 22;

      case 20:
        return 27;

      case 25:
        return 32;

      default:
        return 37;
    }
  };

  return (
    <>
      <LabelWithInformationBox
        title="Switcher tempo"
        message={tooltips.wayfinders.displaySettings.switchTemp}
        className="pt-3"
      />
      <div className="flex-a-center WayFinderFrom__slider position-relative">
        <Icon icon="rabbit-icon" />

        <input
          type="range"
          value={switcherTempo}
          ref={ref}
          min={5}
          max={30}
          step={5}
          onChange={(e) => {
            onChange("display.switcherTempo", e.target.valueAsNumber);
          }}
          className="mr-2 ml-2"
          style={{
            background: `linear-gradient(90deg,#2cd0af ${
              +switcherTempo * handleBackgroundColor()
            }%,rgb(241, 241, 241) ${
              +switcherTempo * handleBackgroundColor()
            }%)`,
          }}
        />
        <Icon icon="turtle-icon" />

        <div className="pricing-slider-value" ref={sliderValue}>
          {switcherTempo}s
        </div>
      </div>
    </>
  );
};
