import React from "react";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";

import { EditWorkplaceLoader } from "./EditWorkplaceLoader/EditWorkplaceLoader";
import { EditRoom } from "../../../../components/Workplaces/EditWorkplace/EditRoom/EditRoom";
import { EditSite } from "../../../../components/Workplaces/EditWorkplace/EditSite/EditSite";
import { EditDesk } from "../../../../components/Workplaces/EditWorkplace/EditDesk/EditDesk";
import { EditZone } from "../../../../components/Workplaces/EditWorkplace/EditZone/EditZone";
import { EditFloor } from "../../../../components/Workplaces/EditWorkplace/EditFloor/EditFloor";
import { EditCompany } from "../../../../components/Workplaces/EditWorkplace/EditCompany/EditCompany";
import { EditBuilding } from "../../../../components/Workplaces/EditWorkplace/EditBuilding/EditBuilding";
import { EditWayFinder } from "../../../../components/Workplaces/EditWorkplace/EditWayFinder/EditWayFinder";
import { HandleLoadingState } from "../../../../components/shared/HandleLoadingState/HandleLoadingState";
import { WorkplaceTypes } from "../../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { capitalizeString } from "../../../../lib/helpers/capitalizeString";

import "./EditWorkplace.scss";

export interface EditWorkplaceProps {
  id: string;
}

interface ModalProps {
  type: WorkplaceTypes;
  component: React.FunctionComponent<EditWorkplaceProps>;
}

export const editWorkplaceComponent: ModalProps[] = [
  {
    type: "Company",
    component: EditCompany,
  },
  {
    type: "Site",
    component: EditSite,
  },
  {
    type: "Building",
    component: EditBuilding,
  },
  {
    type: "Floor",
    component: EditFloor,
  },
  {
    type: "Zone",
    component: EditZone,
  },
  {
    type: "Room",
    component: EditRoom,
  },
  {
    type: "Desk",
    component: EditDesk,
  },
  {
    type: "Wayfinder",
    component: EditWayFinder,
  },
];

export const EditWorkplace = () => {
  const { query } = useRouter();
  const { loading } = useCustomerContext();

  // Capitalize the first letter in order to match the typename from GraphQL schema
  const type = capitalizeString(query.type);

  const EditWorkplace =
    editWorkplaceComponent.find((item) => item.type === type)?.component ||
    null;

  return EditWorkplace ? (
    <div className="w-100 h-100">
      <HandleLoadingState
        loading={loading}
        fullWidth={true}
        loadingPlaceholder={<EditWorkplaceLoader />}
      >
        <EditWorkplace id={query.id} />
      </HandleLoadingState>
    </div>
  ) : (
    <></>
  );
};
