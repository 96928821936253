import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCalendarRequests } from "../../../../api/grpc/calendarprovider/useCalendarRequests";
import { useConfirmation } from "../../../../lib/context/Confirmation/ConfirmationContext";
import { useValidPersonalToken } from "../../../../lib/context/ValidateToken/ValidateTokenContext";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { tooltips } from "../../../../lib/utils/tooltips";

import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { CalendarCredentialsStatus } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { CalendarModalWizard } from "../../../Calendars/CalendarModalWizard/CalendarModalWizard";

interface Props {
  toggleModal: () => void;
}

export const PersonalCalendarInfo = ({ toggleModal }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { deleteProvider } = useCalendarRequests();
  const confirmation = useConfirmation();
  const { statusOfToken, setStatusOfToken, setOpenReAuthModal, reAuthButton } =
    useValidPersonalToken();
  const { personalCalendar, setPersonalCalendar } = useCalendarContext();

  const calendarIcon = (type: string) => {
    if (type.toLowerCase().includes("gsuite")) {
      return "google_wp";
    }

    if (type.includes("O365")) {
      return "microsoft-365";
    }

    return "exchange_ews";
  };

  const handleCalendarDelete = async (id: string) => {
    await confirmation.confirm({
      title: "Are you sure that you want to delete this calendar?",
    });

    try {
      await deleteProvider(id);
      setPersonalCalendar([]);
      setStatusOfToken(
        CalendarCredentialsStatus.CalendarCredentialsStatusUnknown
      );

      toast.success("Calendar removed successfully!");
    } catch (error: any) {
      toast.error(error?.message || "Failed deleting Calendar!");
    }
  };

  return (
    <>
      <CalendarModalWizard
        isOpen={isOpen}
        toggle={() => setIsOpen(true)}
        type="reAuth"
      />
      {personalCalendar.length > 0 ? (
        <>
          <div className="Calendar__info">
            <Icon
              className="calendar"
              icon={calendarIcon(personalCalendar[0].iD)}
            />
            <span className="text-break">{personalCalendar[0].name}</span>
          </div>

          <div className="flex-a-center flex-wrap gap-1">
            <Button
              color="primary"
              title="Unlink"
              size="small"
              onClick={() => handleCalendarDelete(personalCalendar[0].iD)}
            />

            {statusOfToken ===
              CalendarCredentialsStatus.CalendarCredentialsStatusInvalid && (
              <>
                <Button
                  color="outline-red"
                  title="Re-authenticate"
                  size="small"
                  onClick={reAuthButton}
                />

                <button
                  type="button"
                  className="underline"
                  onClick={() => {
                    setOpenReAuthModal(true);
                  }}
                >
                  Why do I need to reauthenticate?
                </button>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <LabelWithInformationBox
            title="Personal calendar"
            message={tooltips.personalCalendar.linkPersonalCalendar}
            direction="right"
            className="mb-3"
          />
          <Button
            color="primary"
            title="Link"
            size="small"
            onClick={toggleModal}
          />
        </>
      )}
    </>
  );
};
