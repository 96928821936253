import React, { useState } from "react";
import { format } from "date-fns";

import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { useInsightsContext } from "../../InsightsContext";

import {
  GeneralStatsByRoomsResponse,
  GeneralStatsResponse,
  HistoryEventsRequest,
  RoomHistoryEventsResponse,
  TimeRangeEventsResponse,
} from "../../../../../api/grpc/insights/insights";
import {
  TimeRangeRequest,
  useInsightsRequests,
} from "../../../../../api/grpc/insights/useInsightsRequests";
import { RoomInsights, RoomInsightsContext } from "./RoomInsightsContext";

interface Props {
  children: React.ReactNode;
}

export interface OrganizerFilter {
  label: string;
  value: string;
}

export const RoomInsightsContextProvider = ({ children }: Props) => {
  const [organizers, setOrganizers] = useState<OrganizerFilter[] | undefined>(
    undefined
  );
  const [offset, setOffset] = useState(0);

  const {
    getGeneralRoomStats,
    getAllRoomsOfLocation,
    getRoomsTimeRangeEvents,
    getRoomReservationsHistory,
  } = useInsightsRequests();

  const { timeZone } = useTimeZoneContext();

  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const { loading, error, data } = useGrpcQuery<
    TimeRangeRequest,
    GeneralStatsResponse
  >({
    method: getGeneralRoomStats,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingRooms,
    error: errorRooms,
    data: dataRooms,
  } = useGrpcQuery<TimeRangeRequest, GeneralStatsByRoomsResponse>({
    method: getAllRoomsOfLocation,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingRange,
    error: errorRange,
    data: dataRange,
  } = useGrpcQuery<TimeRangeRequest, TimeRangeEventsResponse>({
    method: getRoomsTimeRangeEvents,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingRoomHistory,
    error: errorRoomHistory,
    data: dataRoomHistory,
  } = useGrpcQuery<HistoryEventsRequest, RoomHistoryEventsResponse>({
    method: getRoomReservationsHistory,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
      customerID: "",
      limit: 250,
      offset,
      organizers: organizers?.map((item) => item.value) || [],
    },
  });

  const context: RoomInsights = {
    generalRoomStats: data,
    errorGeneralStats: error,
    loadingGeneralStats: loading,
    loadingRooms,
    errorRooms,
    rooms: dataRooms,
    loadingRange,
    errorRange,
    roomsTimeRange: dataRange,
    loadingRoomHistory,
    errorRoomHistory,
    dataRoomHistory,
    organizers,
    setOrganizers,
    offset,
    setOffset,
  };

  return (
    <RoomInsightsContext.Provider value={context}>
      {children}
    </RoomInsightsContext.Provider>
  );
};
