import React from "react";
import cs from "classnames";
import { useSidebarContext } from "../../../lib/context/Sidebar/SidebarContext";
import { useRouter } from "../../../lib/hooks/useRouter";

import "./SidebarOverlay.scss";

export const SidebarOverlay = () => {
  const { location } = useRouter();

  const { hideMobileMenus, visibileMainMenu, visiblePageSidebar } =
    useSidebarContext();

  const classes = cs("SidebarOverlay", {
    "SidebarOverlay--visible":
      visiblePageSidebar ||
      visibileMainMenu ||
      ["/manage/map", "/users/sync", "/bookings/reserve/map"].includes(
        location.pathname
      ),
    "SidebarOverlay--showBackground": location.pathname === "/manage/map",
  });

  return <span className={classes} onClick={hideMobileMenus}></span>;
};
