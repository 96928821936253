import React from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { BusyTimeSlots } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Icon } from "../../../shared/Icon/Icon";
import { formatDateName } from "../../../../api/external-api/react-native-map-view/utils/date.utils";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";

interface Props {
  attendeeImage: string;
  attendeeName: string;
  reservation: BusyTimeSlots[];
}

export const BookingsUserPopup = ({
  attendeeImage,
  attendeeName,
  reservation,
}: Props) => {
  const dateName = formatDateName(new Date(reservation[0].startTime));
  const { timeZone } = useTimeZoneContext();

  return (
    <div className="BookingsUserPopup">
      {attendeeImage ? (
        <img src={attendeeImage} alt="User" />
      ) : (
        <span className="BookingsUserPopup__creds">
          {attendeeName[0].toUpperCase()}
        </span>
      )}

      <h6>{attendeeName}</h6>
      <Icon icon="calendar-icon" />

      <span className="BookingsUserPopup__date">{dateName}</span>
      <span className="BookingsUserPopup__time">
        {reservation[0].isAllDay === true
          ? "All day"
          : dateToHoursAndMinutes(
              new Date(reservation[0].startTime),
              timeZone
            ) +
            " - " +
            dateToHoursAndMinutes(new Date(reservation[0].endTime), timeZone)}
      </span>
    </div>
  );
};
