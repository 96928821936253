import React from "react";
import { getRoute } from "../../../lib/routes";

import { Redirect, Switch } from "react-router-dom";
import { Error404 } from "../../../components/Error404/Error404";

import { MainRouteProps } from "../../../lib/routes/routes";

import { UserRoute } from "../../../lib/UserRoute";

interface Props {
  baseRoute: string;
}

export const NestedRoute = (props: Props) => {
  const route = getRoute(props.baseRoute);

  if (!route) {
    return <Error404 />;
  }

  if (!route.menu) {
    return <></>;
  }

  const menu = route.menu as Array<MainRouteProps>;
  const firstChild = route.menu ? route.menu[0] : null;

  return (
    <Switch>
      {menu.map((p: MainRouteProps) => {
        return (
          <UserRoute
            role={p.role}
            key={`${p.label}-auth`}
            path={`${route.to}${p.to}`}
            exact={p.exact ? true : undefined}
            component={p.component || undefined}
          />
        );
      })}
      {firstChild && (
        <Redirect from={route.to} to={`${route.to}${firstChild.to}`} />
      )}
    </Switch>
  );
};
