import gql from "graphql-tag";

import { ZONE_FIELDS_FRAGMENT } from "./zones/zones";
import { SITE_FIELDS_FRAGMENT } from "./sites/sites";
import { CheckInTime, DESK_FIELDS_FRAGMENT, User } from "./desks/desks";
import { FLOOR_FIELDS_FRAGMENT, Map } from "./floors/floors";
import {
  Amenity,
  DisplaySettingsFields,
  Marker,
  RoomDirection,
  ROOM_FIELDS_FRAGMENT,
  CircleShape,
  Polygonal,
} from "./rooms/rooms";
import { COMPANY_FIELDS_FRAGMENT } from "./companies/companies";
import { BUILDING_FIELDS_FRAGMENT } from "./buildings/buildings";
import { CalendarType } from "../grpc/calendarprovider/calendarprovider";
import {
  DeviceFormType,
  DeviceWorkspaceType,
} from "../../components/shared/Forms/DeviceForm/lib/useDeviceFormik";
import { WorkplaceTypes } from "../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { WAY_FINDER_FIELDS_FRAGMENT } from "./wayfinder/wayfinder";
import { HardwareInfo, SoftwareInfo } from "./devices/devices";

export interface WorkplaceFormFields {
  name: string;
  description: string;
  tags?: string[];
}

export interface CompanyFormFields extends WorkplaceFormFields {}
export interface SiteFormFields extends WorkplaceFormFields {}
export interface BuildingFormFields extends WorkplaceFormFields {}
export interface FloorFormFields extends WorkplaceFormFields {
  map?: Map;
}
export interface ZoneFormFields extends WorkplaceFormFields {}
export interface DeskFormFields extends WorkplaceFormFields {
  checkInTime: CheckInTime;
  showCheckInTime: boolean;
  isBlocked?: boolean;
  isBooked?: boolean;
  qrCodeRequired: boolean;
  marker?: Marker;
  licensed?: boolean;
  account?: User | null;
  locationID?: string;
  startIndex?: number;
  desksNumber?: number;
  workingHrsActivated: boolean;
  workingHrsStart?: string;
  workingHrsEnd?: string;
  workingWeekDays?: number[];
}

export interface RoomFormFields extends WorkplaceFormFields {
  customerId: string;
  licensed: boolean;
  calendarId: string;
  pairingCode?: string;
  numberOfSeats: number;
  calendarProviderActive: boolean;
  brokenAmenitiesReporting: boolean;
  isBooked?: boolean;
  isBlocked: boolean;
  marker: Marker;
  circleShape: CircleShape;
  polygonal: Polygonal[];
  roomDirection: RoomDirection[];
  checkInTime: CheckInTime;
  showCheckInTime: boolean;
  resourceEmail: string;
  reportingEmail: string;
  calendarType?: CalendarType;
  calendarProviderId: string;
  roomAmenities: Amenity[];
  displaySettings?: DisplaySettingsFields;
}

export interface DeviceFormFields extends WorkplaceFormFields {
  id?: string;
  roomId?: string;
  wayFinderId?: string;
  code?: string;
  type: DeviceFormType;
  typeOfWorkspace: DeviceWorkspaceType;
  isOnline: boolean;
  existingDeviceId?: string;
  unassignedSince?: number;
  warnedUnassigned?: boolean;
  softwareInfo?: SoftwareInfo | null;
  hardwareInfo?: HardwareInfo | null;
  deviceId?: string;
  autoUpdate: boolean;
}

export interface EditDeviceFormFields {
  name: string;
  roomId?: string;
  wayFinderId?: string;
  description: string;
  autoUpdate: boolean;
}

export interface WorkplaceTenantFields {
  id: string;
  tenantId: string;
}

export interface GetWorkplaceRequest {
  id: string;
}

export interface DeleteWorkplaceRequest {
  id: string;
  tenantId: string;
}

export interface DeleteWorkplaceResponse {
  msg: string;
}

export interface WorkplaceRequestVariables {
  id?: string;
  tenantId: string;
}

export interface WorkplaceAllRequestVariables
  extends WorkplaceRequestVariables {
  licensed?: boolean;
}

export interface WorkplaceDefaultFields {
  __typename: WorkplaceTypes;
  id: string;
  name: string;
  description: string;
  tags: string[];
}

// @TODO: move checkintime,showchekintime,isbooked to a
// specific interface called DeskFields

export interface WorkplaceFields extends WorkplaceDefaultFields {
  tenantId?: string;
  deviceID?: string;
  licensed?: boolean;
  calendarId?: string;
  room?: WorkplaceFields[];
  calendarProviderId?: string;
  devices?: WorkplaceFields[];
  companies?: WorkplaceFields[];
  sites?: WorkplaceFields[];
  buildings?: WorkplaceFields[];
  floors?: WorkplaceFields[];
  zones?: WorkplaceFields[];
  rooms?: WorkplaceFields[];
  desks?: WorkplaceFields[];
  wayfinders?: WorkplaceFields[];
  checkInTime?: CheckInTime;
  showCheckInTime?: boolean;
  isBlocked?: boolean;
  workingHrsActivated?: boolean;
  requireHeadcount?: boolean;
  oneTimePinProtection?: boolean;
  language?: string;
  workingHrsStart?: string;
  workingHrsEnd?: string;
  qrCodeRequired?: boolean;
  isBooked?: boolean;
  marker?: Marker;
  circleShape?: CircleShape;
  polygonal: Polygonal[];
  map?: Map;
  account?: User;
  roomDirection?: RoomDirection[];
  unassignedSince?: number;
  workingWeekDays?: number[];
  warnedUnassigned?: boolean;
  hardwareInfo?: HardwareInfo;
  softwareInfo?: SoftwareInfo;
  isOnline?: boolean;
}

export interface GetWorkplacesResponse {
  queryTenant: [
    {
      id: string;
      tenantId: string;
      companies?: WorkplaceFields[];
      sites?: WorkplaceFields[];
      buildings?: WorkplaceFields[];
      floors?: WorkplaceFields[];
      zones?: WorkplaceFields[];
      rooms?: WorkplaceFields[];
      desks?: WorkplaceFields[];
      wayfinders?: WorkplaceFields[];
    }
  ];
}

export interface GetAllWorkplacesResponse {
  queryCompany: WorkplaceFields[];
  querySite: WorkplaceFields[];
  queryBuilding: WorkplaceFields[];
  queryFloor: WorkplaceFields[];
  queryZone: WorkplaceFields[];
  queryRoom: WorkplaceFields[];
  queryDesk: WorkplaceFields[];
  queryWayfinder: WorkplaceFields[];
}

export interface AddTenantVariables {
  id: string;
  email: string;
  name: string;
}

export interface AddTenantResponse {
  addTenant: {
    tenant: [
      {
        id: string;
        tenantId: string;
      }
    ];
  };
}

export const ADD_TENANT = gql`
  mutation addTenantMutation($id: String!, $email: String!, $name: String!) {
    addTenant(
      input: [{ tenantId: $id, tenantMail: $email, tenantDisplayName: $name }]
    ) {
      tenant {
        id
        tenantId
      }
    }
  }
`;

export const GET_ROOT_WORKPLACES = gql`
  query getRootWorkplaces($tenantId: String!) {
    queryTenant(filter: { tenantId: { eq: $tenantId } }) {
      id
      tenantId
      companies(order: { asc: name }) {
        ...CompanyFields
      }
      sites(order: { asc: name }) {
        ...SiteFields
      }
      buildings(order: { asc: name }) {
        ...BuildingFields
      }
      floors(order: { asc: name }) {
        ...FloorFields
      }
      zones(order: { asc: name }) {
        ...ZoneFields
      }
      rooms(order: { asc: name }) {
        ...RoomFields
      }
      desks(order: { asc: name }) {
        ...DeskFields
      }
      wayfinders(order: { asc: name }) {
        ...WayfinderFields
      }
    }
  }
  ${COMPANY_FIELDS_FRAGMENT}
  ${SITE_FIELDS_FRAGMENT}
  ${BUILDING_FIELDS_FRAGMENT}
  ${FLOOR_FIELDS_FRAGMENT}
  ${ZONE_FIELDS_FRAGMENT}
  ${ROOM_FIELDS_FRAGMENT}
  ${DESK_FIELDS_FRAGMENT}
  ${WAY_FINDER_FIELDS_FRAGMENT}
`;

// export const GET_ALL_WORKPLACES = gql`
//   query getAllWorkplaces($tenantId: String!, $licensed: Boolean) {
//     queryWorkplace(
//       filter: { tenantId: { eq: $tenantId }, licensed: $licensed }
//     ) {
//       id
//       tenantId
//       name
//       tags
//       description
//       licensed
//       __typename
//     }
//   }
// `;

export const GET_ALL_WORKPLACES = gql`
  query listAllWorkplaces($tenantId: String!, $licensed: Boolean) {
    queryCompany(filter: { tenantId: { eq: $tenantId }, licensed: $licensed }) {
      id
      name
      description
      __typename
      tags
    }
    querySite(filter: { tenantId: { eq: $tenantId }, licensed: $licensed }) {
      id
      name
      description
      __typename
      tags
    }
    queryBuilding(
      filter: { tenantId: { eq: $tenantId }, licensed: $licensed }
    ) {
      id
      name
      description
      __typename
      tags
    }

    queryFloor(filter: { tenantId: { eq: $tenantId }, licensed: $licensed }) {
      id
      name
      description
      __typename
      tags
    }
    queryZone(filter: { tenantId: { eq: $tenantId }, licensed: $licensed }) {
      id
      name
      description
      __typename
      tags
    }
    queryRoom(filter: { tenantId: { eq: $tenantId }, licensed: $licensed }) {
      id
      name
      tags
      description
      licensed
      calendarId
      calendarProviderId
      devices {
        id
        name
      }
      __typename
    }

    queryDesk(filter: { tenantId: { eq: $tenantId }, licensed: $licensed }) {
      id
      name
      licensed
      tags
      description
      devices {
        id
        name
      }
      __typename
    }

    queryWayfinder(
      filter: { tenantId: { eq: $tenantId }, licensed: $licensed }
    ) {
      id
      name
      licensed
      tags
      description
      __typename
      devices {
        id
        name
      }
    }
  }
`;
