import React, { PropsWithChildren, useState } from "react";
import { toast } from "react-toastify";
import { Form, Modal } from "reactstrap";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";
import { LoginMethod } from "../../../../api/grpc/account/account";
import { CalendarType } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { GetAutoSyncResponse } from "../../../../api/grpc/contacts/contacts";
import { tooltips } from "../../../../lib/utils/tooltips";
import { getCalendarTypeSlug } from "../../../Calendars/lib/helpers";
import { ModalFooterContent } from "../../../Modals/ModalFooterContent/ModalFooterContent";
import { ModalHeaderContent } from "../../../Modals/ModalHeaderContent/ModalHeaderContent";
import { Switch } from "../../../shared/Switch/Switch";
import { IntegrationsUsersSelector } from "../shared/IntegrationsUsersSelector";
import {
  definedLoginValue,
  LoginMethodObject,
} from "../shared/lib/useSelectLoginMethod";

import "./AutoSyncModal.scss";

const rows: Array<{ id: number; title: string }> = [
  {
    id: 1,
    title: "Add ALL users in your directory",
  },
  {
    id: 2,
    title: "Automatically update or inactivate existing users",
  },
  {
    id: 3,
    title: "Abort adding new users if there are no available licenses",
  },
];

export const AutoSyncModal = ({
  isOpen,
  toggleModal,
  refetch,
  data,
}: PropsWithChildren<{
  isOpen: boolean;
  toggleModal: () => void;
  refetch: () => void;
  data?: GetAutoSyncResponse;
}>) => {
  const { loading, error, isBasicOrBussines } = useCustomerPermissions();

  const { query } = useRouter();
  const { createDirectoryAutoSync, deleteDirectoryAutoSync } = useContacts();
  const [autoSync, setAutoSync] = useState(() => data?.enabled);
  const [loginMethod, setLoginMethod] = useState<LoginMethodObject>({
    label: definedLoginValue[data?.loginMethod || 1],
    value: data?.loginMethod || 1,
  });
  const [loadingChange, setLoadingChange] = useState(false);

  const isGoogleIntegration =
    query.directoryType === getCalendarTypeSlug(CalendarType.GSUITE_SYNC);

  const handleSyncToggle = async () => {
    try {
      if (error) {
        toast.error(error?.message ?? "Error fetching user's plan!");
        return;
      }

      setLoadingChange(true);

      let loginMethodChanged =
        data?.loginMethod !== LoginMethod.ALL &&
        loginMethod.value !== data?.loginMethod;

      if (data?.enabled && !loginMethodChanged) {
        await deleteDirectoryAutoSync({
          providerID: query.id,
        });

        toast.success("Auto-sync switched to OFF.");

        toggleModal();
        return refetch();
      }

      await createDirectoryAutoSync({
        providerID: query.id,
        loginMethod: loginMethod.value,
      });

      toast.success(
        loginMethodChanged
          ? "Sign-in method changed successfully."
          : "Auto-sync switched to ON."
      );
      toggleModal();

      return refetch();
    } catch (error: any) {
      toast.error(error?.message);
      return;
    } finally {
      setLoadingChange(false);
    }
  };

  return (
    <Modal
      title="Auto-sync settings"
      isOpen={isOpen}
      onToggle={toggleModal}
      className="WorkHours AutoSyncModal"
    >
      <ModalHeaderContent title="Sync users" handleToggle={toggleModal} />

      <span className="WorkHours__info">Activating auto-sync will:</span>

      <ul className="WorkHours__info AutoSyncModal__list">
        {rows.map((row) => (
          <li key={row.id} className="WorkHours__info">
            {row.title}
          </li>
        ))}
      </ul>

      <Form>
        <Switch
          title="Enable auto-sync of users"
          name="autoSync"
          value={autoSync}
          message={tooltips.directorySync.enableAutoSync}
          onChange={() => setAutoSync((prev) => !prev)}
          className="p-modal"
        />

        <IntegrationsUsersSelector
          className="p-modal"
          isGoogleIntegration={isGoogleIntegration}
          loginMethod={loginMethod}
          setLoginMethod={setLoginMethod}
          isBasicOrBussines={isBasicOrBussines}
          disabled={autoSync === false}
        />
      </Form>

      <ModalFooterContent
        toggleModal={toggleModal}
        handleSave={handleSyncToggle}
        disabled={
          (autoSync === data?.enabled &&
            (loginMethod.value === data?.loginMethod ||
              data?.loginMethod === LoginMethod.ALL)) ||
          loading ||
          loadingChange
        }
      />
    </Modal>
  );
};
