import React, { PropsWithChildren } from "react";
import useImage from "../../../../lib/hooks/useImage";

import { FormGroup } from "reactstrap";
import { tooltips } from "../../../../lib/utils/tooltips";
import { Input } from "../../Input/Input";
import { LabelWithInformationBox } from "../../LabelWithInformationBox/LabelWithInformationBox";
import { DeviceFormik } from "./lib/useDeviceFormik";
import { LoadingBox } from "../../LoadingBox/LoadingBox";

export const DeviceFormFields = ({
  formik,
  image,
}: PropsWithChildren<{
  formik: DeviceFormik;
  image: "pairing-screen" | "wayfinder_paringCode_ex";
}>) => {
  const { loading, image: pairingImage } = useImage(image);

  return (
    <>
      <FormGroup>
        <LabelWithInformationBox
          for="name"
          title="Name"
          direction="left"
          message={tooltips.devices.name}
        />
        <Input
          type="text"
          id="name"
          name="name"
          value={formik.values.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.name}
          errorMessage={formik.errors.name}
          invalid={!!formik.errors.name && formik.touched.name}
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="description"
          title="Description"
          direction="left"
          message={tooltips.devices.description}
        />
        <Input
          type="text"
          id="description"
          name="description"
          onBlur={formik.handleBlur}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="code"
          direction="left"
          title="Pairing Code"
          message={tooltips.devices.pairingCode}
        />
        <Input
          type="text"
          id="code"
          name="code"
          onBlur={formik.handleBlur}
          onChange={(e) => {
            e.preventDefault();

            e.target.value = e.target.value.toUpperCase();

            formik.handleChange(e);
          }}
          value={formik.values.code}
          isTouched={formik.touched.code}
          errorMessage={formik.errors.code}
          invalid={!!formik.errors.code && formik.touched.code}
        />
      </FormGroup>
      <div className="form-group-image">
        {loading ? (
          <LoadingBox minHeight={155} />
        ) : (
          <img src={pairingImage} alt="Pairing code screen" />
        )}
      </div>
    </>
  );
};
