import React, { PropsWithChildren } from "react";
import cs from "classnames";

import { IconDefinition } from "../Icon/Icon.generated";
import { Icon } from "../Icon/Icon";
import { LabelWithInformationBox } from "../LabelWithInformationBox/LabelWithInformationBox";

import "./FormItem.scss";

export const FormItem = ({
  children,
  title,
  message,
  icon,
  className,
  status,
  statusClassName,
}: PropsWithChildren<{
  children: React.ReactNode;
  title: string;
  message?: string;
  icon?: IconDefinition;
  className?: string;
  status?: string;
  statusClassName?: string;
}>) => {
  const classes = cs("FormItem", className);

  return (
    <div className={classes}>
      <div className="flex-a-center FormItem__header">
        {icon && <Icon className="FormItem__header--main" icon={icon} />}
        <LabelWithInformationBox title={title} message={message} />
        {status && <span className={statusClassName}>{status}</span>}
      </div>

      <div>{children}</div>
    </div>
  );
};
