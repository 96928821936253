import React, { PropsWithChildren } from "react";
import { useDirectoryContext } from "../../Context/DirectoryContext";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { trimText } from "../../../../lib/helpers/trimText";

import { CheckboxButton } from "../../../shared/CheckboxButton/CheckboxButton";
import {
  StructureTable,
  StructureTableColumn,
} from "../../../shared/StructureTable/StructureTable";
import { DirectoryIntegrationStatus } from "../DirectoryIntegrationStatus/DirectoryIntegrationStatus";
import { IntegrationGroupMember } from "../../../../api/grpc/contacts/contacts";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { getCalendarTypeSlug } from "../../../Calendars/lib/helpers";
import { CalendarType } from "../../../../api/grpc/calendarprovider/calendarprovider";

export const DirectoryIntegrationsTable = ({
  data,
}: PropsWithChildren<{
  data: IntegrationGroupMember[];
}>) => {
  const { selectedUsers, setSelectedUsers, selectAllUsers, setSelectAllUsers } =
    useDirectoryContext();
  const { query } = useRouter();

  const isGoogleIntegration =
    query.directoryType === getCalendarTypeSlug(CalendarType.GSUITE_SYNC);

  const handleUsersSelect = (user: IntegrationGroupMember) => {
    let userExists = !!selectedUsers.find((item) => item.id === user.id);

    if (userExists) {
      if (selectAllUsers) {
        setSelectAllUsers(false);
      }

      return setSelectedUsers(
        selectedUsers.filter((item) => item.id !== user.id)
      );
    }

    return setSelectedUsers([...selectedUsers, user]);
  };

  let columns: StructureTableColumn[] = [
    {
      key: "actions",
      header: "",
    },
    {
      key: "status",
      header: "Status",
    },
    {
      key: "emailId",
      header: "Email",
    },
    {
      key: "name",
      header: "Name",
    },
    {
      key: "groups",
      header: "Groups",
    },
  ];

  if (isGoogleIntegration) {
    columns = [
      ...columns,
      {
        key: "orgUnit",
        header: "Organizational unit",
      },
    ];
  }

  const rows = data.map((item) => {
    return {
      actions: (
        <CheckboxButton
          id={item.id}
          name={item.id}
          onChange={() => {
            return handleUsersSelect(item);
          }}
          checked={!!selectedUsers.find((user) => user.id === item.id)}
          className="mb-3 IntegrationCheck"
          hideLabel
        />
      ),
      status: <DirectoryIntegrationStatus status={item.status} />,
      emailId: <h4>{trimText(item.email || "-", 35)}</h4>,
      name: <h4>{trimText(item.name)}</h4>,
      groups: !!item.groups.length ? (
        <div>
          {item.groups.map((group) => (
            <span key={group.id}>{group.name}</span>
          ))}
        </div>
      ) : (
        <h4>-</h4>
      ),
      orgUnit: <h4>{trimText(item.orgUnitPath || "-", 22)}</h4>,
    };
  });

  return (
    <div className="PeopleTable">
      <StructureTable
        small
        columns={columns}
        rows={rows}
        noResultsPlaceholder={
          <CenteredBox className="min-vh-25">
            No new, updated, or removed users found for this directory.
          </CenteredBox>
        }
      />
    </div>
  );
};
