import React from "react";
import { toast } from "react-toastify";
import { usePartnerData } from "../../lib/usePartnerData";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import gogetImage from "../../../../assets/images/gogetDevice.png";
import floorPlanImage from "../../../../assets/images/floor-plan.png";
import { openCustomerPortal } from "../../../Subscription/lib/openCustomerPortal";
import { createHostedPage } from "../../../Subscription/lib/createChargebeeHostedPage";

import { BillingInnerCard } from "../../shared/BillingInnerCard/BillingInnerCard";
import { BillingData } from "../Help/BillingHelp";

type ProductType = "goget-one-v1-USD" | "floor-plan-v1-USD";

export interface ProductsData extends BillingData {
  image: JSX.Element;
  secondDescription: string;
}

export const BillingProducts = () => {
  const { user, tokenInterceptor } = useAuthContext();
  const { data: partnerData } = usePartnerData();

  const openCheckout = async (type: ProductType) => {
    try {
      const customerPortal = await openCustomerPortal(
        user?.customerid || "",
        tokenInterceptor
      );

      return customerPortal?.chargebeeInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            const initHostedPage = async () => {
              try {
                const response = await createHostedPage(
                  user?.customerid || "",
                  tokenInterceptor,
                  type,
                  ""
                );
                resolve(response);
              } catch (error: any) {
                reject(error);
                toast.error(error.message);
              }
            };
            initHostedPage();
          });
        },
      });
    } catch (error: any) {
      toast.error(
        "Failed to fetch your data to open widget, please reload and try again!"
      );
      console.log(error);
    }
  };

  const data: ProductsData[] = [
    {
      title: "Meeting room hardware",
      content: "GOGET One.",
      secondDescription:
        "Designer 10” display with PoE, Wifi and signature LED-status lights.",
      hasButton: true,
      buttonTitle: "Buy",
      onClick: () => openCheckout("goget-one-v1-USD"),
      image: <img src={gogetImage} alt="GOGET" />,
    },
    {
      title: "Floor plan maps",
      content: "Let GOGET create visual maps for your workspace.",
      secondDescription: " Price per floor, max 2 revisions",
      hasButton: true,
      buttonTitle: "Buy",
      onClick: () => openCheckout("floor-plan-v1-USD"),
      image: <img src={floorPlanImage} alt="Floor plan" />,
    },
  ];
  return (
    <div>
      {data.map((item: ProductsData) => (
        <BillingInnerCard
          key={item.title}
          title={item.title}
          content={item.content}
          hasButton={item.hasButton}
          buttonTitle={item.buttonTitle}
          onClick={item.onClick}
          imageSrc={item.image}
          disabled={!!partnerData?.email}
          secondDescription={item.secondDescription}
          className="Products"
        />
      ))}
    </div>
  );
};
