import React from "react";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { ContactSalesModal } from "../../../ContactSales/ContactSalesModal";
import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { BillingInnerCard } from "../../shared/BillingInnerCard/BillingInnerCard";

interface Props {
  email: string;
}

export const ManagedPartner = ({ email }: Props) => {
  const { isOpen, toggleModal } = useToggleModal();

  return (
    <>
      <InfoCard
        title="Channel Partner"
        hasButton
        buttonTitle="Contact sales"
        onClick={toggleModal}
      >
        <BillingInnerCard title="Partner ID" content={email} />
        {isOpen && <ContactSalesModal isOpen={isOpen} toggle={toggleModal} />}
      </InfoCard>
    </>
  );
};
