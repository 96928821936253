import { useMutation } from "@apollo/client";
import {
  UpdateDefaultLocationPathResponse,
  UpdateDefaultLocationPathVariables,
  UPDATE_DEFAULT_LOCATION_PATH,
} from "../../../api/graphql/bookings/bookings";

export const useUpdateDefaultLocation = () =>
  useMutation<
    UpdateDefaultLocationPathResponse,
    UpdateDefaultLocationPathVariables
  >(UPDATE_DEFAULT_LOCATION_PATH);
