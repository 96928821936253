import React from "react";

export const EmptyRoomDirections = () => {
  return (
    <div className="FloorEmptyMap">
      <h4 className="heading-empty">
        It seems that there isn’t any room added!
      </h4>
    </div>
  );
};
