import React from "react";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { tooltips } from "../../../../lib/utils/tooltips";
import { CalendarsList } from "../../../../components/Calendars/CalendarsList/CalendarsList";
import { PageHeader } from "../../../../components/shared/PageHeader/PageHeader";
import { EmptyEntity } from "../../../../components/shared/EmptyEntity/EmptyEntity";

import "./CalendarsMain.scss";

export const CalendarsMain = () => {
  const { providers } = useCalendarContext();

  return (
    <div className="CalendarsMain w-100">
      <PageHeader
        title="Calendar integrations"
        message={tooltips.calendars.connections}
      />
      {providers.length > 0 ? (
        <CalendarsList items={providers} />
      ) : (
        <EmptyEntity largeView largeIcon type="Calendar" />
      )}
    </div>
  );
};
