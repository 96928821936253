import React, { useEffect, useState } from "react";

import { useLicensesContext } from "../../../lib/context/Customer/CustomerContext";

import { Switch } from "../../shared/Switch/Switch";
import { EntityType } from "../../../api/grpc/subscription/subscription";

interface Props {
  id: string;
  value: boolean;
  type: EntityType;
  refetch?: () => void;
}

export const LicenseSwitch = (props: Props) => {
  const { value, id, type, refetch } = props;
  const [switchValue, setSwitchValue] = useState(() => value);
  const [loading, setLoading] = useState(false);
  const { disabled, handleLicenseUpdate } = useLicensesContext();

  // TO DO: Remove this switchValue that is used for Contacts
  useEffect(() => {
    setSwitchValue(value);
  }, [value]);

  return (
    <Switch
      name="licensed"
      value={switchValue}
      disabled={disabled}
      spinner={loading}
      onChange={async (value) => {
        try {
          setLoading(true);
          const response = await handleLicenseUpdate({
            id,
            type,
            value,
          });

          if (refetch && response) {
            refetch();
          }
        } catch (error: any) {
          console.error(error.toString());
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
