import React, { PropsWithChildren } from "react";
import { People } from "../../../../api/grpc/contacts/contacts";
import { HistoryPeopleSelect } from "./HistoryPeopleSelect";
import { OrganizerFilter } from "../../Context/Room/Overview/RoomInsightsContextProvider";

export const HistoryOrganizers = ({
  loading,
  error,
  data,
  organizers,
  setOrganizers,
  handleClose,
}: PropsWithChildren<{
  loading: boolean;
  error?: Error;
  data?: People;
  organizers?: OrganizerFilter[];
  setOrganizers: React.Dispatch<
    React.SetStateAction<OrganizerFilter[] | undefined>
  >;
  handleClose: (() => void) | undefined;
}>) => {
  return (
    <HistoryPeopleSelect
      loading={loading}
      error={error}
      data={data}
      organizers={organizers}
      setOrganizers={setOrganizers}
      handleClose={handleClose}
    />
  );
};
