import React from "react";
import { tooltips } from "../../../../lib/utils/tooltips";
import { ContactSalesModal } from "../../../ContactSales/ContactSalesModal";
import { Icon } from "../../../shared/Icon/Icon";
import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { BillingInnerCard } from "../../shared/BillingInnerCard/BillingInnerCard";
import { PaymentCardContent } from "./PaymentCardContent";

interface Props {
  hasCard: boolean;
  toggleModal: () => void;
  isOpen: boolean;
  loading: boolean;
  image: string;
  cardNumber?: string;
  openPortal: () => void;
  disabled: boolean;
}

export const PaymentCard = ({
  hasCard,
  toggleModal,
  isOpen,
  loading,
  image,
  cardNumber,
  openPortal,
  disabled,
}: Props) => {
  return (
    <>
      {hasCard ? (
        <InfoCard
          title="Credit Card Payment"
          hasButton
          disabled={disabled}
          onClick={openPortal}
        >
          <BillingInnerCard
            title="Primary card"
            content={
              <PaymentCardContent
                loading={loading}
                image={image}
                cardNumber={cardNumber}
              />
            }
          />
        </InfoCard>
      ) : (
        <InfoCard
          title="Credit Card Payment"
          hasButton
          buttonTitle="Add"
          disabled={disabled}
          onClick={openPortal}
          message={tooltips.organizationSettings.payment.creditCard}
        >
          <BillingInnerCard title="Primary card" content="No card on file" />
        </InfoCard>
      )}

      <InfoCard
        title="Invoice/Bank Wire Payment"
        hasButton
        buttonTitle="Apply"
        onClick={toggleModal}
        disabled={disabled}
        message={tooltips.organizationSettings.payment.invoice}
      >
        <BillingInnerCard
          content="Apply for payment by international bank wire transfer (only Business/Pro plan)"
          imageSrc={<Icon icon="billing_icon_18x18" />}
        />
        {isOpen && <ContactSalesModal isOpen={isOpen} toggle={toggleModal} />}
      </InfoCard>
    </>
  );
};
