import React, { Dispatch, SetStateAction, useState } from "react";
import { Switch } from "../../../../shared/Switch/Switch";
import { OrganizationSettingsModal } from "../../shared/OrganizationSettingsModal";
import { CustomerSettingsVariables } from "../../InsightsCard/Insights/Insights";
import { tooltips } from "../../../../../lib/utils/tooltips";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  handleDeskSimultaneous: ({
    disableDeskSimultaneous,
  }: CustomerSettingsVariables) => void;
  disableDeskSimultaneous?: boolean;
  error?: Error;
}

export const BookingsSettingsModal = ({
  isOpen,
  setIsOpen,
  loading,
  handleDeskSimultaneous,
  disableDeskSimultaneous,
  error,
}: Props) => {
  const [disableDeskSimultaneousToChange, setDisableDeskSimultaneousToChange] =
    useState<boolean | undefined>(undefined);

  return (
    <OrganizationSettingsModal
      title="Bookings"
      formTitle="Manage global settings for user bookings"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      error={error}
      toggleModal={() => {
        setIsOpen(false);
        return;
      }}
      handleSave={() => {
        handleDeskSimultaneous({
          disableDeskSimultaneous: disableDeskSimultaneousToChange,
        });
        setIsOpen(false);
        return;
      }}
    >
      <Switch
        name="disableDeskSimultaneous"
        value={
          disableDeskSimultaneousToChange !== undefined
            ? disableDeskSimultaneousToChange
            : disableDeskSimultaneous
        }
        onChange={() => {
          setDisableDeskSimultaneousToChange(
            disableDeskSimultaneousToChange !== undefined
              ? !disableDeskSimultaneousToChange
              : !disableDeskSimultaneous
          );
        }}
        title="Don't allow simultaneous reservations of desks"
        //   message={tooltips.account.enableDataCollection}
        message={
          tooltips.organizationSettings.generalSettings.disableSimultaneous
        }
        className="mb-3 WorkingHoursForm__selectors--deskController"
      />
    </OrganizationSettingsModal>
  );
};
