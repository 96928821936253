import React, { useMemo } from "react";
import Select from "react-select";
import { FormGroup } from "reactstrap";

import { useDeskTemplateContext } from "../../../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";
import { useRoomTemplateContext } from "../../../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";
import { useTemplateContext } from "../../../../../lib/context/Templates/Templates/TemplatesContext";

import { selectStyles } from "../../../../../lib/utils/selectStyles";
import { MainResources } from "../../../../../lib/types/main.types";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { LabelWithInformationBox } from "../../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";

export const SelectProfileStep = () => {
  const { deskTemplates, loadingDeskTemplate } = useDeskTemplateContext();
  const { roomTemplates, loadingRoomTemplate } = useRoomTemplateContext();
  const {
    activeProfile,
    setActiveProfile,
    pickedWorkspaces,
    setPickedWorkspaces,
  } = useTemplateContext();

  const options = useMemo(() => {
    if (!deskTemplates || !roomTemplates) {
      return [];
    }

    if (pickedWorkspaces.data.length) {
      const type =
        pickedWorkspaces.data[0].__typename === "Desk" ? "desk" : "room";

      if (type === "desk") {
        return deskTemplates.map((desk) => ({
          value: desk.id,
          label: desk.name,
          type: "desk",
        }));
      }

      return roomTemplates.map((room) => ({
        value: room.id,
        label: room.name,
        type: "room",
      }));
    }

    const desksWithType = deskTemplates.map((desk) => {
      return {
        ...desk,
        type: "desk",
      };
    });

    const roomsWithType = roomTemplates.map((room) => {
      return {
        ...room,
        type: "room",
      };
    });

    return [...desksWithType, ...roomsWithType].map((option) => ({
      value: option.id,
      label: option.name,
      type: option.type,
    }));
  }, [deskTemplates, roomTemplates, pickedWorkspaces]);

  return (
    <FormGroup className="w-50-large mb-5">
      <LabelWithInformationBox
        title="Select template to transfer settings from"
        for="profile"
      />

      <HandleLoadingState
        loading={loadingDeskTemplate || loadingRoomTemplate}
        loadingPlaceholder={<LoadingBox minHeight={50} />}
      >
        <Select
          name="profile"
          theme={selectStyles}
          options={options}
          isSearchable
          isClearable
          noOptionsMessage={() => "No templates found"}
          onChange={(e) => {
            if (!e) {
              if (activeProfile) {
                setActiveProfile(undefined);
              }

              if (pickedWorkspaces && !pickedWorkspaces.redirect) {
                setPickedWorkspaces({ data: [] });
              }

              return;
            }

            setActiveProfile({
              label: e.label,
              value: e.value,
              type: e.type as MainResources,
            });
          }}
          value={options
            .filter((option) => option.value === activeProfile?.value)
            .map((opt) => ({
              value: opt.value,
              label: opt.label,
              type: opt.type,
            }))}
        />
      </HandleLoadingState>
    </FormGroup>
  );
};
