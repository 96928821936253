import React from "react";
import cs from "classnames";
import { StepProps } from "../../CalendarsWizardForm";

import "./CalendarsWizardHeaderItem.scss";

interface Props {
  number: number;
  item: StepProps;
  isActive: boolean;
  onClick: () => void;
  isCompleted: boolean;
}

export const CalendarsWizardHeaderItem = (props: Props) => {
  const classes = cs("CalendarsWizardHeaderItem", {
    "CalendarsWizardHeaderItem--active": props.isActive,
    "CalendarsWizardHeaderItem--completed": props.isCompleted,
  });

  return (
    <div className={classes} onClick={props.onClick}>
      <h5>Step {props.number}</h5>
      <p>{props.item.title}</p>
      <span className="indicator"></span>
    </div>
  );
};
