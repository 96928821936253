import React from "react";
import { toast } from "react-toastify";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useDirectoryContext } from "../../Context/DirectoryContext";
import { Button } from "../../../shared/Button/Button";
import { IntegrationsUsersModal } from "./IntegrationsUsersModal";

export const SyncUsersButton = () => {
  const { isOpen, toggleModal } = useToggleModal();

  const { selectedUsers } = useDirectoryContext();

  const handleSyncButton = () => {
    if (!!selectedUsers.length) {
      return toggleModal();
    }

    toast.error("No users selected!");
    return;
  };

  return (
    <>
      <div className="adjustable-button">
        <Button
          title="Sync users"
          size="medium"
          color="primary"
          className="adjustable-title"
          onClick={handleSyncButton}
        />
      </div>

      {isOpen && (
        <IntegrationsUsersModal isOpen={isOpen} toggleModal={toggleModal} />
      )}
    </>
  );
};
