import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  CALENDAR_STRINGS as f,
  FROM_STRINGS as t,
} from "../../../lib/utils/constants";
import { WizardFormFields } from "../CalendarsWizardForm/CalendarsWizardForm";

const EWSSchema = Yup.object().shape({
  name: Yup.string().min(2, f.accountName).required(t.required),
  domain: Yup.string().required(t.required),
  password: Yup.string().required(t.required),
  svcAccount: Yup.string().required(t.required),
});

interface UseEWSFormOptions {
  initialValues?: WizardFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: WizardFormFields,
    formikHelpers: FormikHelpers<WizardFormFields>
  ) => Promise<any>;
}

export type EWSFormik = ReturnType<typeof useEWSFormik>;

export const useEWSFormik = (opts: UseEWSFormOptions) => {
  return useFormik<WizardFormFields>({
    initialValues: {
      name: "",
      domain: "",
      password: "",
      svcAccount: "",
      impersonationEnabled: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: EWSSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
