import React, { useEffect, useState } from "react";
import { useDeskOverviewInsightsContext } from "../../Context/Desk/Overview/OverviewContext";
import { useInsightsContext } from "../../Context/InsightsContext";

import { DeskHistoryEventsResponse } from "../../../../api/grpc/insights/insights";
import { DeskHistoryTable } from "./DeskHistoryTable";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { Button } from "../../../shared/Button/Button";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HistoryFilters } from "../../shared/Filters/HistoryFilters";

export const DeskHistory = () => {
  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const {
    loadingDeskHistory,
    errorDeskHistory,
    dataDeskHistory,
    setOffset,
    organizers,
    setOrganizers,
  } = useDeskOverviewInsightsContext();

  const [deskHistoryData, setDeskHistoryData] = useState<
    DeskHistoryEventsResponse | undefined
  >(undefined);

  useEffect(() => {
    setDeskHistoryData(undefined);
    setOffset(0);
  }, [organizers, startTime, endTime, locationId]);

  useEffect(() => {
    if (dataDeskHistory?.results?.length) {
      setDeskHistoryData((prevData) => ({
        totalRecords: dataDeskHistory.totalRecords,
        results: [...(prevData?.results || []), ...dataDeskHistory.results],
      }));
    }
  }, [dataDeskHistory]);

  return (
    <div className="RoomHistory">
      <HistoryFilters
        organizers={organizers}
        setOrganizers={setOrganizers}
        disbaled={loadingDeskHistory || !!errorDeskHistory}
      />

      <HandleLoadingState
        loading={loadingDeskHistory && !!!deskHistoryData?.results.length}
        loadingPlaceholder={<LoadingBox count={5} minHeight={60} />}
      >
        <HandleRequestState
          state={!!errorDeskHistory}
          placeholder={
            <CenteredBox>
              {errorDeskHistory?.message || "Error fetching room history"}
            </CenteredBox>
          }
        >
          {deskHistoryData && (
            <>
              <DeskHistoryTable dataDeskHistory={deskHistoryData} />
              {/* loader for new requests */}
              {loadingDeskHistory && <LoadingBox count={5} minHeight={65} />}

              {deskHistoryData.results.length <
                deskHistoryData.totalRecords && (
                <div className="text-center mt-4">
                  <Button
                    title="More items"
                    size="small"
                    color="primary"
                    onClick={() => setOffset((prev) => prev + 250)}
                  />
                </div>
              )}
            </>
          )}
          {!!!dataDeskHistory?.results.length && (
            <CenteredBox>
              <span>There are no desk reservations.</span>
            </CenteredBox>
          )}
        </HandleRequestState>
      </HandleLoadingState>
    </div>
  );
};
