import React from "react";
import { tooltips } from "../../../lib/utils/tooltips";

import { CenteredInformationBox } from "../../shared/CenteredInformationBox/CenteredInformationBox";

export const CalendarsEWSList = () => {
  return (
    <div className="CalendarsEWSList">
      <CenteredInformationBox
        message="Calendars are assigned to meeting rooms in the Workspaces section"
        infoMessage={tooltips.calendars.ews.view}
      />
    </div>
  );
};
