import React from "react";
import {
  GetAutoSyncRequest,
  GetAutoSyncResponse,
} from "../../../../api/grpc/contacts/contacts";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { tooltips } from "../../../../lib/utils/tooltips";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { InformationBox } from "../../../shared/InformationBox/InformationBox";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { AutoSyncModal } from "./AutoSyncModal";

export const DirectoryIntegrationsAutoSync = () => {
  const { isOpen, toggleModal } = useToggleModal();
  const { query } = useRouter();

  const { getDirectoryAutoSync } = useContacts();
  const { loading, error, data, refetch } = useGrpcQuery<
    Omit<GetAutoSyncRequest, "customerID">,
    GetAutoSyncResponse
  >({
    method: getDirectoryAutoSync,
    variables: {
      providerID: query.id,
    },
  });

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<LoadingBox minHeight={30} className="w-5" />}
    >
      <HandleRequestState
        state={!!error}
        placeholder={"Error fetching auto-sync"}
      >
        <div className="flex-a-center DirectoryIntegrationsHeaderButtons__auto">
          <div className="cursor-pointer" onClick={toggleModal}>
            <span>Auto-sync: </span>
            <span
              className={`DirectoryIntegrationsHeaderButtons__auto--${
                data?.enabled ? "on" : "off"
              }`}
            >
              {data?.enabled ? "ON" : "OFF"}
            </span>
          </div>
          <InformationBox
            message={tooltips.directorySync.autoSync}
            direction="left"
          />
        </div>

        {isOpen && (
          <AutoSyncModal
            data={data}
            refetch={refetch}
            isOpen={isOpen}
            toggleModal={toggleModal}
          />
        )}
      </HandleRequestState>
    </HandleLoadingState>
  );
};
