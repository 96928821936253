import { createContext, Dispatch, SetStateAction, useContext } from "react";
import {
  IntegrationGroupMember,
  SyncStatus,
} from "../../../api/grpc/contacts/contacts";
import {
  DirectorySyncIntegration,
  ListDirectoryProviderResponse,
} from "../../../api/grpc/calendarprovider/calendarprovider";

export interface IDirectoryContext {
  data: ListDirectoryProviderResponse | undefined;
  loading: boolean;
  error: Error | undefined;
  refetch: () => void;
  brokenIntegrations: DirectorySyncIntegration[];
  setBrokenIntegrations: React.Dispatch<
    React.SetStateAction<DirectorySyncIntegration[]>
  >;
  selectedUsers: IntegrationGroupMember[];
  setSelectedUsers: Dispatch<SetStateAction<IntegrationGroupMember[]>>;
  selectAllUsers: boolean;
  setSelectAllUsers: Dispatch<SetStateAction<boolean>>;
  filterStatus: SyncStatus[];
  setFilterStatus: Dispatch<SetStateAction<SyncStatus[]>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  dynamicStylesDirectory: React.CSSProperties;
  handleFixDirectoryButton: (id: string) => Promise<void>;
}

export const DirectoryContext = createContext<IDirectoryContext>({
  selectedUsers: [],
  setSelectedUsers: () => {},
  selectAllUsers: false,
  setSelectAllUsers: () => {},
  filterStatus: [],
  setFilterStatus: () => {},
  searchValue: "",
  setSearchValue: () => {},
  data: undefined,
  loading: false,
  error: undefined,
  refetch: () => {},
  brokenIntegrations: [],
  setBrokenIntegrations: () => {},
  handleFixDirectoryButton: async () => {},
  dynamicStylesDirectory: {},
});

export const useDirectoryContext = () => useContext(DirectoryContext);

export const { Provider } = DirectoryContext;
