import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { FROM_STRINGS } from "../../../../../../lib/utils/constants";

export interface DatePriceSchemaFormFields {
  itemPrice?: number;
  userItemPrice?: number;
}

const DatePriceSchema = Yup.object().shape({
  itemPrice: Yup.number()
    .min(0, "Negative value not allowed.")
    .required(FROM_STRINGS.required),
  userItemPrice: Yup.number()
    .min(0, "Negative value not allowed.")
    .required(FROM_STRINGS.required),
});

interface DatePriceFormOptions {
  initialValues?: DatePriceSchemaFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: DatePriceSchemaFormFields,
    formikHelpers: FormikHelpers<DatePriceSchemaFormFields>
  ) => Promise<any>;
}

export type DatePriceFormik = ReturnType<typeof useDatePriceFormik>;

export const useDatePriceFormik = (opts: DatePriceFormOptions) => {
  return useFormik<DatePriceSchemaFormFields>({
    initialValues: {
      itemPrice: undefined,
      userItemPrice: undefined,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: DatePriceSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
