import { useFormik, FormikHelpers } from "formik";
import { rest } from "lodash";
import * as Yup from "yup";

import { TeamFormFields } from "../../../../Teams/interface/teams/teams";

const TeamSchema = Yup.object().shape({
  name: Yup.string().required("Please enter the name of the team!"),
  member: Yup.string().required("Please add at least one member!"),
});

interface UseTeamFormOptions {
  initialValues?: TeamFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: TeamFormFields,
    formikHelpers: FormikHelpers<TeamFormFields>
  ) => Promise<any>;
}

export type TeamFormik = ReturnType<typeof useTeamFormik>;

export const useTeamFormik = (opts: UseTeamFormOptions) => {
  return useFormik<TeamFormFields>({
    initialValues: {
      name: "",
      id: "",
      member: "",
      location: "",
      company: "",
    },
    ...rest,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: TeamSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
