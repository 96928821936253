import { useApolloClient } from "@apollo/client";

import {
  GetWorkplacesResponse,
  GET_ROOT_WORKPLACES,
  WorkplaceRequestVariables,
} from "../workplaces";
import {
  AddCompanyResponse,
  AddCompanyVariables,
  ADD_COMPANY,
  CompanyRequestVariables,
  COMPANY_FIELDS_FRAGMENT,
  UpdateCompanyResponse,
  UPDATE_COMPANY,
} from "./companies";

export const useCompaniesRequests = () => {
  const client = useApolloClient();

  const addCompany = async (variables: AddCompanyVariables) => {
    return await client.mutate<AddCompanyResponse, AddCompanyVariables>({
      mutation: ADD_COMPANY,
      variables,
      update: (cache, newData) => {
        const previousQuery = cache.readQuery<
          GetWorkplacesResponse,
          WorkplaceRequestVariables
        >({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
        });

        const tenantResults = previousQuery?.queryTenant[0];
        const newCompany = newData.data?.addCompany.company[0];

        cache.writeQuery({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: variables.tenantId,
          },
          data: {
            queryTenant: [
              {
                ...tenantResults,
                companies: [...(tenantResults?.companies || []), newCompany],
              },
            ],
          },
        });
      },
    });
  };

  const updateCompany = async (variables: CompanyRequestVariables) => {
    return await client.mutate<UpdateCompanyResponse, CompanyRequestVariables>({
      mutation: UPDATE_COMPANY,
      variables,
      update: (cache, newData) => {
        client.writeFragment({
          id: `Company:${variables.id}`,
          fragmentName: "CompanyFields",
          fragment: COMPANY_FIELDS_FRAGMENT,
          data: {
            ...newData.data?.updateCompany.company[0],
          },
        });
      },
    });
  };

  return { addCompany, updateCompany };
};
