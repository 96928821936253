export const GENERAL_STRINGS = {
  slogan: "Easy meetings by GOGET",
  contactUs: "Contact Us",
  logInHeader: "Log in into GOGET",
  logIn: "Log In",
  name: "Name",
  logOut: "Log out",
  register: "Register",
  addPayment: "Add new Payment Method",
};

export const SUCCESS_STRINGS = {
  reservationDone: "Reservation done successfully.",
  reservationDeleted: "Reservation deleted successfully.",
  reservationUpdated: "Reservation updated successfully.",
  customLanguageUpdated: "Custom language updated successfully",
  checkOutDone: "Check-out finished successfully",
  calendarAdded: "Calendar added successfully",
  deviceDisconnected: "Device disconnected successfully",
  copiedText: "Copied to clipboard",
};

export const INFO_STRINGS = {
  externalRoom:
    "External Booking - Please allow up to 30 seconds for room confirmation.",
};

export const ERROR_STRINGS = {
  resources: "It seems like we have problems with resources, please try again!",
  emptyResourceTree:
    "Currently there isn't any resources, start by adding a company!",
  reservationFail: "Reservation failed, please reaload and try again!",
};

export const FROM_STRINGS = {
  email: "Email",
  submit: "Submit",
  message: "Your message",
  invalidEmail: "Invalid email",
  required: "This field is required",
  validUserName: "Please enter a valid user name",
};

export const RESOURCE_STRINGS = {
  name: "Name",
  parentID: "Parent ID",
  submit: "Submit",
  description: "Description",
  invalidName: "Invalid Name",
  required: "This field is required",
  validName: "Please enter a valid building name",
  numberOfSeats: "Please enter a valid number of seats",
  equipmentName: "Equipment name",
  equipmentLocation: "Equipment location",
  equipmentID: "Equipment ID",
  cateringType: "Select Catering type",
  cateringName: "Catering name",
  cateringCost: "Catering cost",
  bookable: "Bookable",
  reserved: "Reserved",
  seatLicenseKey: "Assign license",
  validTag: "Please enter a valid tag",
  pairingCode: "Device ID",
  code: "Code",
  organizationID: "Organization ID",
  calendarProviderID: "Calendar Provider ID",
};

export const REGISTER_STRINGS = {
  firstName: "First name",
  lastName: "Last name",
  middleName: "Middle Name",
  email: "Company email",
  phone: "Phone",
  company: "Company",
  country: "Country",
  state: "State",
  city: "City",
  postalcode: "Postalcode",
  line1: "Line 1",
  line2: "Line 2",
  submit: "Create Account",
  invalidName: "Invalid Name",
  newsletter: "Get updates & offers",
  required: "This field is required",
  validName: "Please enter a valid name",
  validEmail: "Please enter a valid email",
  privacyTerms: "I agree with the privacy policy",
};

export const ACCOUNT_STRINGS = {
  code: "Code",
  password: "Password",
  passwordConfirm: "Password confirm",
  newPassword: "New password",
  newPasswordConfirm: "New password confirm",
  activateAccount: "Activate Account",
  validPassword: "Password doesn't match or is too short!",
  changePassword: "Change password",
  resetPassword: "Reset password",
};

export const CALENDAR_STRINGS = {
  accountName: "Account Name",
};

export const allTimezones: Record<string, string> = {
  "Pacific/Midway": "Midway Island, Samoa",
  "Pacific/Honolulu": "Hawaii",
  "America/Juneau": "Alaska",
  "America/Boise": "Mountain Time",
  "America/Dawson": "Dawson, Yukon",
  "America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
  "America/Phoenix": "Arizona",
  "America/Chicago": "Central Time",
  "America/Regina": "Saskatchewan",
  "America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
  "America/Belize": "Central America",
  "America/Detroit": "Eastern Time",
  "America/Bogota": "Bogota, Lima, Quito",
  "America/Caracas": "Caracas, La Paz",
  "America/Santiago": "Santiago",
  "America/St_Johns": "Newfoundland and Labrador",
  "America/Sao_Paulo": "Brasilia",
  "America/Tijuana": "Tijuana",
  "America/Montevideo": "Montevideo",
  "America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
  "America/Godthab": "Greenland",
  "America/Los_Angeles": "Pacific Time",
  "Atlantic/Azores": "Azores",
  "Atlantic/Cape_Verde": "Cape Verde Islands",
  GMT: "UTC",
  "Europe/London": "Edinburgh, London",
  "Europe/Dublin": "Dublin",
  "Europe/Lisbon": "Lisbon",
  "Africa/Casablanca": "Casablanca, Monrovia",
  "Atlantic/Canary": "Canary Islands",
  "Europe/Belgrade": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  "Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
  "Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
  "Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  "Africa/Algiers": "West Central Africa",
  "Europe/Bucharest": "Bucharest",
  "Africa/Cairo": "Cairo",
  "Europe/Helsinki": "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  "Europe/Athens": "Athens",
  "Asia/Jerusalem": "Jerusalem",
  "Africa/Harare": "Harare, Pretoria",
  "Europe/Moscow": "Istanbul, Minsk, Moscow, St. Petersburg, Volgograd",
  "Asia/Kuwait": "Kuwait, Riyadh",
  "Africa/Nairobi": "Nairobi",
  "Asia/Baghdad": "Baghdad",
  "Asia/Tehran": "Tehran",
  "Asia/Dubai": "Abu Dhabi, Muscat",
  "Asia/Baku": "Baku, Tbilisi, Yerevan",
  "Asia/Kabul": "Kabul",
  "Asia/Yekaterinburg": "Ekaterinburg",
  "Asia/Karachi": "Islamabad, Karachi, Tashkent",
  "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
  "Asia/Kathmandu": "Kathmandu",
  "Asia/Dhaka": "Astana, Dhaka",
  "Asia/Colombo": "Sri Jayawardenepura",
  "Asia/Almaty": "Almaty, Novosibirsk",
  "Asia/Rangoon": "Yangon Rangoon",
  "Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
  "Asia/Krasnoyarsk": "Krasnoyarsk",
  "Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR, Urumqi",
  "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
  "Asia/Taipei": "Taipei",
  "Australia/Perth": "Perth",
  "Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
  "Asia/Seoul": "Seoul",
  "Asia/Tokyo": "Osaka, Sapporo, Tokyo",
  "Asia/Yakutsk": "Yakutsk",
  "Australia/Darwin": "Darwin",
  "Australia/Adelaide": "Adelaide",
  "Australia/Sydney": "Canberra, Melbourne, Sydney",
  "Australia/Brisbane": "Brisbane",
  "Australia/Hobart": "Hobart",
  "Asia/Vladivostok": "Vladivostok",
  "Pacific/Guam": "Guam, Port Moresby",
  "Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
  "Asia/Kamchatka": "Kamchatka, Marshall Islands",
  "Pacific/Fiji": "Fiji Islands",
  "Pacific/Auckland": "Auckland, Wellington",
  "Pacific/Tongatapu": "Nuku'alofa",
};
