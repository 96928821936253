import React, { PropsWithChildren, useMemo, useState } from "react";
import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";

import {
  ListTagsEntity,
  ListTagsRequest,
  Tags,
} from "../../../api/grpc/workplaces/workplaces";
import { HandleLoadingState } from "../HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../HandleRequestState/HandleRequestState";
import { SearchBox } from "../SearchBox/SearchBox";
import { InputDescription } from "../InputDescription/InputDescription";
import { CenteredLoader } from "../CenteredLoader/CenteredLoader";
import { TagListContent } from "./TagListContent";

import "./TagList.scss";

export const TagList = ({
  id,
  activeTagsFilter,
  setActiveTagsFilter,
  closeModal,
}: PropsWithChildren<{
  id: string;
  activeTagsFilter: string[];
  setActiveTagsFilter: React.Dispatch<React.SetStateAction<string[]>>;
  closeModal: () => void;
}>) => {
  const { listTags } = useWorkplacesClient();

  const { data, loading, error } = useGrpcQuery<ListTagsRequest, Tags>({
    method: listTags,
    variables: {
      locationId: id,
      limit: 50,
      entityType: ListTagsEntity.TYPE_LOCATION,
      customerId: "",
    },
  });

  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce<string>(searchValue, 500);

  const definedTags = useMemo(() => {
    if (!data) {
      return [];
    }

    if (!!!debouncedValue.length) {
      return data.tags;
    }

    return data.tags.filter((item) =>
      item.toLowerCase().includes(debouncedValue.toLowerCase())
    );
  }, [debouncedValue, data]);

  return (
    <div className="TagList TagFilter">
      <h6>All tags</h6>

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<CenteredLoader />}
      >
        <HandleRequestState
          state={!!error}
          placeholder={
            <InputDescription
              title="Error"
              description={error?.message ?? "Error fetching tags."}
            />
          }
        >
          <SearchBox
            searchValue={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            clearSearchValue={() => setSearchValue("")}
            className="mb-2"
          />
          {!!debouncedValue.length && !!!definedTags.length ? (
            <div>No tag found</div>
          ) : (
            <TagListContent
              tags={definedTags}
              setActiveTagsFilter={setActiveTagsFilter}
              activeTagsFilter={activeTagsFilter}
              closeModal={closeModal}
            />
          )}
        </HandleRequestState>
      </HandleLoadingState>
    </div>
  );
};
