import { format } from "date-fns";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useBookingsContext } from "../../Context/BookingsContext";
import { useBookingsMapContext } from "../../BookingsMap/Context/BookingsMapContext";
import { useFormatReservationDate as formatReservationDate } from "../../lib/datePickerHelper";
import { utcToZonedTime } from "date-fns-tz";

import { MatchingDesk } from "../../../../api/grpc/desk/desk";
import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import {
  attachHoursToDate,
  dateToHoursAndMinutes,
} from "../../lib/dateInputConvert";
import { PersonCredentials } from "../PersonCredentials/PersonCredentials";
import { UserModalDesks } from "./UserModalDesks";
import { defineLocationPath } from "../../helpers/defineLocationPath";

interface Props {
  name?: string;
  imageUrl?: string;
  email?: string;
  desks: MatchingDesk[];
  personAccountId?: string;
  handleModalClose: () => void;
}

export const UserInfo = ({
  name,
  imageUrl,
  email,
  desks,
  personAccountId,
  handleModalClose,
}: Props) => {
  const { findNearbyDesks } = useDeskBookingRequest();
  const { timeZone } = useTimeZoneContext();
  const { allDay } = useBookingsMapContext();
  const [pagePagination, setPagePagination] = useState(1);

  const {
    handleMatchedDesks,
    day,
    reservDeskVariables: { startTime, endTime },
    matchedDesks,
    activeTagsFilter,
  } = useBookingsContext();

  const handleNearbyDesks = async () => {
    try {
      let start = attachHoursToDate(
        format(day, "yyyy-MM-dd"),
        startTime,
        timeZone
      );
      let end = attachHoursToDate(
        format(day, "yyyy-MM-dd"),
        endTime,
        timeZone,
        allDay
      );

      let availableDesks: MatchingDesk[];
      let pageFromResponse: number;

      const { response } = await findNearbyDesks({
        customerID: "",
        accountID: personAccountId || "",
        startTime: start,
        endTime: end,
        locationID: desks[0].locationID,
        tagsFilter: activeTagsFilter,
        timeZone: timeZone,
        page: pagePagination,
      });

      pageFromResponse = response.nextPage;
      availableDesks = response.desks;

      if (response.desks.length <= 0 && response.nextPage > 0) {
        do {
          const { response } = await findNearbyDesks({
            customerID: "",
            accountID: personAccountId || "",
            startTime: start,
            endTime: end,
            locationID: desks[0].locationID,
            tagsFilter: activeTagsFilter,
            timeZone: timeZone,
            page: pageFromResponse,
          });

          availableDesks = response.desks;
          pageFromResponse = response.nextPage;
        } while (availableDesks.length <= 0 && pageFromResponse > 0);
      }

      if (matchedDesks) {
        handleMatchedDesks([...matchedDesks, ...(availableDesks || [])]);
      } else {
        handleMatchedDesks(availableDesks);
      }

      setPagePagination(pageFromResponse);

      handleModalClose();
    } catch (error: any) {
      toast.error(
        error?.message || "Error fetching desks, please reload and try again!"
      );
      console.log(error);
    }
  };

  return (
    <div className="UserInfo w-full">
      <PersonCredentials
        className="UserInfo__creds"
        imageUrl={imageUrl}
        name={name}
      />
      <h4>{name}</h4>
      <div className="flex-a-center">
        <Icon icon="email-icon" />
        <h6>{email}</h6>
      </div>

      {desks.length <= 0 ? (
        <div className="UserInfo__empty">Reservations list empty</div>
      ) : (
        desks.map((desk) => (
          <UserModalDesks
            key={desk.deskID}
            name={desk.deskName}
            reservationStartTime={dateToHoursAndMinutes(
              new Date(desk.event[0].startTime),
              timeZone
            )}
            reservationEndTime={dateToHoursAndMinutes(
              new Date(desk.event[0].endTime),
              timeZone
            )}
            createdTime={formatReservationDate(
              utcToZonedTime(new Date(desk.event[0].startTime), timeZone)
            )}
            locationPath={defineLocationPath(desk.locationPath)}
            allDay={desk.event[0].allDay}
          />
        ))
      )}

      <Button
        title="Find nearby desk"
        disabled={desks.length <= 0}
        color="business"
        size="medium"
        onClick={() => handleNearbyDesks()}
      />
    </div>
  );
};
