import {
  CALENDARS_ROOT_PATH,
  BOOKINGS_ROOT_PATH,
} from "../../../../lib/routes/routes";
import { config } from "./o365-config";

export const redirectToO365 = async (
  type: string,
  openEnvironment: boolean,
  calendarId?: string
) => {
  let redirectUrl: string;

  switch (type) {
    case "personal":
    case "reAuth":
      redirectUrl = `${config.redirectUri}${BOOKINGS_ROOT_PATH}/?complete=0365`;
      break;
    default:
      redirectUrl = `${
        config.redirectUri
      }${CALENDARS_ROOT_PATH}/?complete=0365${
        openEnvironment ? "_custom" : ""
      }`;
      break;
  }

  const uri = encodeURI(
    `https://login.microsoftonline.${
      openEnvironment ? "us" : "com"
    }/organizations/oauth2/v2.0/authorize?client_id=${
      config.appId
    }&response_type=code&redirect_uri=${redirectUrl}&response_mode=fragment&scope=${
      config.scopes
    }&prompt=select_account`
  );

  if (type === "admin") {
    localStorage.setItem("calendarId", calendarId || "");
  }

  if (type === "reAuth") {
    localStorage.setItem("initiateO365ReAuth", "1");
  }

  window.location.replace(uri);
};
