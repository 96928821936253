import { useMemo } from "react";
import { ItemStats } from "../../../../api/grpc/insights/insights";

export const useSortInsightsData = (data?: ItemStats[]) => {
  return useMemo(() => {
    if (!data) {
      return [];
    }

    return [...data].sort((item1, item2) => {
      return item1.value < item2.value ? 1 : item1.value > item2.value ? -1 : 0;
    });
  }, [data]);
};
