import React from "react";
import { FormFeedback, Input as RSInput, InputProps } from "reactstrap";

interface Props extends InputProps {
  isTouched?: boolean;
  errorMessage?: string;
}

export const Input = ({ isTouched, errorMessage, ...rest }: Props) => {
  return (
    <>
      <RSInput {...rest} />
      {isTouched && errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
    </>
  );
};
