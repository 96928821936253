import React, { useRef, useState } from "react";
import cs from "classnames";

import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { Button } from "../../../shared/Button/Button";
import { DropdownList } from "../../../shared/DropdownList/DropdownList";
import { InvitePeopleModal } from "../InvitePeopleModal/InvitePeopleModal";

import "./InvitePeopleButton.scss";

export type PeopleModalType = "single-user" | "multiple-user" | "add-directory";

interface Props {
  noMargin?: boolean;
  className?: string;
  adjustable?: boolean;
}

const options = [
  {
    icon: "add-user-icon",
    label: "Add a user",
    type: "single-user",
  },
  // {
  //   disabled: true,
  //   icon: "add-multiple-users-icon",
  //   label: "Add multiple users",
  //   type: "multiple-user",
  // },
  {
    icon: "directory-icon",
    label: "Add from directory",
    type: "add-directory",
  },
];

export const InvitePeopleButton = (props: Props) => {
  const ref = useRef(null);
  const { history } = useRouter();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [modalType, setModalType] = useState<PeopleModalType | undefined>(
    undefined
  );

  const classes = cs(
    "InvitePeopleButton",
    {
      "adjustable-button": props.adjustable,
      "adjustable-button--icon": props.adjustable,
      "InvitePeopleButton--no-margin": props.noMargin,
    },
    props.className
  );

  useOutsideClickDetection(ref, () => {
    setShowDropdown(false);
  });

  const handleListClick = (value: PeopleModalType) => {
    if (value === "add-directory") {
      return history.push("/users/sync");
    }
    setModalType(value);
    setShowDropdown(false);
  };

  return (
    <div ref={ref} className={classes}>
      <Button
        size="medium"
        title="Invite"
        color="primary"
        icon="add-user-icon"
        onClick={() => setShowDropdown((prev) => !prev)}
      />

      {showDropdown && (
        <DropdownList
          extended
          color="gray"
          options={options}
          onClick={(value) => handleListClick(value as PeopleModalType)}
        />
      )}

      {modalType && (
        <InvitePeopleModal
          type={modalType}
          onComplete={() => setModalType(undefined)}
        />
      )}
    </div>
  );
};
