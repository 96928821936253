import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import { Icon } from "../../../shared/Icon/Icon";

import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useSidebarContext } from "../../../../lib/context/Sidebar/SidebarContext";
import { useTemplateContext } from "../../../../lib/context/Templates/Templates/TemplatesContext";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { MainRouteProps } from "../../../../lib/routes/routes";

import "./SidebarSubMenuList.scss";

interface Props {
  parentID: string;
  parentPath: string;
  items: MainRouteProps[];
}

export const SidebarSubMenuList = (props: Props) => {
  const { user, tokenInterceptor } = useAuthContext();
  const { toggleSubMenu, activeMenuItem } = useSidebarContext();
  const {
    activeProfile,
    setActiveProfile,
    setPickedWorkspaces,
    pickedWorkspaces,
  } = useTemplateContext();
  const { pathname } = useRouter();

  const classes = classNames("SidebarSubMenuList pl-0", {
    "d-block": activeMenuItem === props.parentID,
  });

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: MainRouteProps
  ) => {
    if (item.id === "settings-profiles") {
      if (activeProfile) {
        setActiveProfile(undefined);
      }

      if (pickedWorkspaces.data.length) {
        setPickedWorkspaces({
          data: [],
          redirect: undefined,
        });
      }
    }

    if (item.onClick && user) {
      event.preventDefault();

      item.onClick(user.claims.subscriptionId, tokenInterceptor);
      return;
    }

    toggleSubMenu();
  };

  return (
    <ul className={classes} data-parent-id={props.parentID}>
      {props.items.map((item) => {
        return (
          <li className="SidebarSubMenu__item w-100 mb-3" key={item.id}>
            {item.disabled ? (
              <div className="d-flex align-items-center px-4 disabled">
                {item.icon && <Icon icon={item.icon} />}
                <h4 className="SidebarSubMenu__title">{item.label}</h4>
              </div>
            ) : (
              <NavLink
                className={`d-flex align-items-center px-4 ${
                  pathname.includes("map") ? item.label : ""
                }`}
                to={`${props.parentPath}${item.to}`}
                onClick={(e) => handleClick(e, item)}
              >
                {item.icon && <Icon icon={item.icon} />}
                <h4 className="SidebarSubMenu__title">{item.label}</h4>
              </NavLink>
            )}
          </li>
        );
      })}
    </ul>
  );
};
