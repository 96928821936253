import React from "react";
import ContentLoader from "react-content-loader";

interface Props {
  width?: number;
}

export const DefaultWorkplacesLoader = (props: Props) => (
  <ContentLoader
    speed={2}
    width={props.width || 1200}
    height={750}
    viewBox={`0 0 ${props.width} 750`}
    backgroundColor="#fff"
    foregroundColor="#f3f3f3"
  >
    <rect x="0" y="0" rx="10" ry="10" width={props.width || 1200} height="98" />

    <rect
      x="0"
      y="130"
      rx="10"
      ry="10"
      width={props.width || 1200}
      height="98"
    />

    <rect
      x="0"
      y="260"
      rx="10"
      ry="10"
      width={props.width || 1200}
      height="98"
    />

    <rect
      x="0"
      y="390"
      rx="10"
      ry="10"
      width={props.width || 1200}
      height="98"
    />

    <rect
      x="0"
      y="520"
      rx="10"
      ry="10"
      width={props.width || 1200}
      height="98"
    />

    <rect
      x="0"
      y="650"
      rx="10"
      ry="10"
      width={props.width || 1200}
      height="98"
    />
  </ContentLoader>
);
