import { parseISO, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import {
  useFormatReservationDate,
  getDaysRangeArray,
} from "./datePickerHelper";

export const defineDatesPicker = (timeZone: string) => {
  const dateOptionsPicker = getDaysRangeArray(
    parseISO(format(utcToZonedTime(new Date(), timeZone), "yyyy-MM-dd")),
    8
  ).map((day) => ({
    id: day.getDate().toString(),
    date: day,
  }));

  const datePickOptions =
    dateOptionsPicker.map((item: any) => ({
      value: format(item.date, "yyyy-MM-dd"),
      label: useFormatReservationDate(item.date),
    })) || [];

  return { datePickOptions };
};
