import React, { PropsWithChildren } from "react";
import { CustomerSettingsVariables } from "../../../../Organization/OrganizationSettings/InsightsCard/Insights/Insights";
import { WorkspaceCostModal } from "../../../../Organization/OrganizationSettings/WorkspaceCost/WorkspaceCostModal";
import { Button } from "../../../../shared/Button/Button";

export const MeetingSpaceCostContent = ({
  closeModal,
  value,
  currency,
  loading,
  error,
  handleCostPerHr,
  isOpen,
  setIsOpen,
}: PropsWithChildren<{
  closeModal: () => void;
  value: number;
  currency?: string;
  loading: boolean;
  error?: Error;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCostPerHr: ({
    meetingSpaceCostPerHr,
    currency,
  }: CustomerSettingsVariables) => void;
}>) => {
  return (
    <div className="MeetingSpaceCostContent">
      <h3>Meeting space cost</h3>

      <div className="MeetingSpaceCostContent__infos">
        <span className="MeetingSpaceCostContent__current">Current value</span>
        <span className="MeetingSpaceCostContent__value">{value}</span>
        <span className="MeetingSpaceCostContent__exchange">
          {currency}/work hour
        </span>
      </div>

      <div className="MeetingSpaceCostContent__buttons flex-a-center">
        <Button
          title="Cancel"
          onClick={closeModal}
          size="small"
          color="outline-primary"
        />
        <Button
          title="Edit"
          onClick={() => setIsOpen(true)}
          size="small"
          color="primary"
        />
      </div>

      {isOpen && (
        <WorkspaceCostModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          cost={value}
          currency={currency}
          handleCostPerHr={handleCostPerHr}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};
