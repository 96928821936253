import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouter } from "../../lib/hooks/useRouter";
import { SubRouteProps } from "../../lib/routes/routes";
import { PeopleContextProvider } from "../../views/Users/People/lib/PeopleContextProvider";

import { Contacts } from "./Contacts/Contacts";
import { PeopleHeader } from "./PeopleHeader/PeopleHeader";

export const People = () => {
  const { match } = useRouter();

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Contacts",
      to: match.url,
      id: "contacts-page",
      component: Contacts,
    },
  ];

  return (
    <div className="default-page">
      <PeopleContextProvider>
        <PeopleHeader routes={routes} />
        <Switch>
          {routes.map((item) => (
            <Route
              key={item.id}
              path={item.to}
              component={item.component}
              exact={item.exact ? true : undefined}
            />
          ))}
          <Redirect to={routes[0].to} />
        </Switch>
      </PeopleContextProvider>
    </div>
  );
};
