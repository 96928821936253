import React from "react";
import { IconDefinition } from "../../../../shared/Icon/Icon.generated";
import { Icon } from "../../../../shared/Icon/Icon";

interface ModalRowProps {
  icon: IconDefinition;
  title: string;
  utc: string;
  startTime?: string;
  endTime?: string;
  strikeThroughEndTime?: boolean;
  showExtendTime?: string;
  allDay?: boolean;
  everyDay?: boolean;
  children?: React.ReactNode;
}

export const ModalRow = ({
  icon,
  title,
  startTime,
  utc,
  endTime,
  strikeThroughEndTime = false,
  showExtendTime,
  allDay,
  everyDay,
  children,
}: ModalRowProps) => {
  return (
    <div className="ModalRow flex-a-center flex-column py-2">
      <Icon icon={icon} />
      <span className="text-center">{everyDay ? "Every day" : title}</span>

      {endTime && (
        <div className="text-center">
          <span>{startTime} - </span>
          <span
            className={
              strikeThroughEndTime ? "text-decoration-line-through" : ""
            }
          >
            {endTime}
          </span>
          {showExtendTime && (
            <span className="error-message px-1">{showExtendTime}</span>
          )}
          {allDay && <span className="error-message px-1">All day</span>}
          <span> {utc}</span>
        </div>
      )}

      {children && children}
    </div>
  );
};
