import React, { useCallback, useMemo } from "react";
import spacetime from "spacetime";
import soft from "timezone-soft";
import { useCheckUserPermissions } from "./useCheckUserPermissions";

import { allTimezones } from "../utils/constants";
import { useAuthContext } from "../context/Auth/AuthContext";
import { RolePermissions } from "../../api/grpc/account/account";

export type ICustomTimezone = {
  [key: string]: string;
};

export type ILabelStyle = "original" | "altName" | "abbrev";

export type IDisplayValue = "GMT" | "UTC";

export type ITimezoneOption = {
  value: string;
  label: string;
  abbrev?: string;
  altName?: string;
  offset?: number;
};

export type ITimezone = ITimezoneOption | string;

export type TimezoneSelectOptions = {
  labelStyle?: ILabelStyle;
  displayValue?: IDisplayValue;
  timezones?: ICustomTimezone;
  maxAbbrLength?: number;
};

export function useTimezones({
  timezones = allTimezones,
  labelStyle = "original",
  displayValue = "GMT",
  maxAbbrLength = 4,
}: TimezoneSelectOptions): {
  parseTimezone: (zone: ITimezone) => ITimezoneOption;
  options: ITimezoneOption[];
  userTimezone: string;
} {
  const { user } = useAuthContext();
  const { checkUserPermission } = useCheckUserPermissions();

  const options = React.useMemo(() => {
    return (
      Object.entries(timezones)
        .map((zone) => {
          try {
            const now = spacetime.now(zone[0]);
            const tz = now.timezone();
            const tzStrings = soft(zone[0]);

            let label = "";

            const standardAbbr = tzStrings?.[0]?.standard?.abbr ?? "";
            const dstAbbr = tzStrings?.[0]?.daylight?.abbr ?? standardAbbr;

            let abbr = now.isDST() ? dstAbbr : standardAbbr;

            const standardAltName = tzStrings?.[0]?.standard?.name ?? "";
            const dstAltName =
              tzStrings?.[0]?.daylight?.name ?? standardAltName;

            let altName = now.isDST() ? dstAltName : standardAltName;

            const min = tz.current.offset * 60;
            const hr =
              `${(min / 60) ^ 0}:` +
              (min % 60 === 0 ? "00" : Math.abs(min % 60));
            const prefix = `(${displayValue}${
              hr.includes("-") ? hr : `+${hr}`
            }) ${zone[1]}`;

            switch (labelStyle) {
              case "original":
                label = prefix;
                break;
              case "altName":
                label = `${prefix} ${altName ? `(${altName})` : ""}`;
                break;
              case "abbrev":
                label = `${prefix} (${abbr.substring(0, maxAbbrLength)})`;
                break;
              default:
                label = `${prefix}`;
            }

            return {
              value: tz.name,
              label: label,
              offset: tz.current.offset,
              abbrev: abbr,
              altName: altName,
            };
          } catch {
            return null;
          }
        })
        .filter(Boolean)
        // @ts-ignore
        .sort((a: ITimezoneOption, b: ITimezoneOption) => a.offset - b.offset)
    );
  }, [labelStyle, timezones, displayValue, maxAbbrLength]);

  const findFuzzyTz = useCallback(
    (zone: string): ITimezoneOption | undefined => {
      let currentTime = spacetime.now("GMT");
      try {
        currentTime = spacetime.now(zone);
      } catch (err) {
        return;
      }
      return (
        options
          .filter(
            // @ts-ignore
            (tz: ITimezoneOption) =>
              tz.offset === currentTime.timezone().current.offset
          )
          // @ts-ignore
          .map((tz: ITimezoneOption) => {
            let score = 0;
            if (
              currentTime.timezones[tz.value.toLowerCase()] &&
              !!currentTime.timezones[tz.value.toLowerCase()].dst ===
                currentTime.timezone().hasDst
            ) {
              if (
                tz.value
                  .toLowerCase()
                  .indexOf(
                    currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)
                  ) !== -1
              ) {
                score += 8;
              }
              if (
                tz.label
                  .toLowerCase()
                  .indexOf(
                    currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)
                  ) !== -1
              ) {
                score += 4;
              }
              if (
                tz.value
                  .toLowerCase()
                  .indexOf(
                    currentTime.tz.substring(0, currentTime.tz.indexOf("/"))
                  )
              ) {
                score += 2;
              }
              score += 1;
            } else if (tz.value === "GMT") {
              score += 1;
            }
            return { tz, score };
          })
          .sort((a, b) => b.score - a.score)
          .map(({ tz }) => tz)[0]
      );
    },
    [options]
  );

  const parseTimezone = useCallback(
    (zone: ITimezone) => {
      if (typeof zone === "object" && zone.value && zone.label) return zone;
      if (typeof zone === "string") {
        return (
          // @ts-ignore
          options.find((tz) => tz.value === zone) ||
          (zone.indexOf("/") !== -1 && findFuzzyTz(zone))
        );
      } else if (zone.value && !zone.label) {
        // @ts-ignore
        return options.find((tz) => tz.value === zone.value);
      }
    },
    [options, findFuzzyTz]
  );

  const userTimezone = useMemo<string>(() => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (
      !checkUserPermission(RolePermissions.MODALS_TIMEZONE_SELECTOR_VIEW_EDIT)
    ) {
      return browserTimezone;
    }

    const timezone = parseTimezone(user?.timeZone || browserTimezone);

    if (timezone) {
      return timezone.value;
    }

    return user?.timeZone ?? browserTimezone;
  }, [user, parseTimezone]);

  // @ts-ignore
  return { options, parseTimezone, userTimezone };
}
