import L from "leaflet";
import wayfinderMarker from "../../../assets/icons/wayfinder-marker.svg";

import manageDeskMarker from "../../../assets/icons/manage-desk-icon.svg";
import manageRoomMarker from "../../../assets/icons/manage-room-icon.svg";

import deskMarker from "../../../assets/icons/free-desk-marker.svg";
import busyDeskMarker from "../../../assets/icons/busy-desk-marker.svg";
import blockedDeskMarker from "../../../assets/icons/blocked-desk.svg";

import roomMarker from "../../../assets/icons/free-room-marker.svg";
import busyRoomMarker from "../../../assets/icons/busy-room.svg";
import blockedRoomMarker from "../../../assets/icons/blocked-room.svg";

import { createLeafletIcon } from "../../../lib/helpers/leaflet";
import { FloorEntity } from "../FloorPlan/FloorsPlan";

const wayfinderLeafletIcon = createLeafletIcon({
  svgUrl: wayfinderMarker,
  size: [78, 78],
  anchor: [78 / 2, 78],
  type: "wayfinder",
});

const manageDeskLeafletIcon = createLeafletIcon({
  svgUrl: manageDeskMarker,
  size: [50, 50],
  type: "desk",
});

const manageRoomLeafletIcon = createLeafletIcon({
  svgUrl: manageRoomMarker,
  size: [60, 60],
  type: "room",
});

const freeDeskLeafletIcon = createLeafletIcon({
  svgUrl: deskMarker,
  size: [50, 50],
  type: "desk",
});

const busyDeskLeafletIcon = createLeafletIcon({
  svgUrl: busyDeskMarker,
  size: [50, 50],
  type: "desk",
});

const freeRoomLeafletIcon = createLeafletIcon({
  svgUrl: roomMarker,
  size: [60, 60],
  type: "room",
});

const busyRoomLeafletIcon = createLeafletIcon({
  svgUrl: busyRoomMarker,
  size: [60, 60],
  type: "room",
});

const blockedRoomLeafletIcon = createLeafletIcon({
  svgUrl: blockedRoomMarker,
  size: [60, 60],
  type: "room",
});

const blockedDeskLeafletIcon = createLeafletIcon({
  svgUrl: blockedDeskMarker,
  size: [50, 50],
  type: "desk",
});

type ImageDimension = {
  width: number;
  height: number;
  mapContainerDimension: number;
};

export const floorPlanHelpers = () => {
  const calculateWidth = ({
    width,
    height,
    mapContainerDimension,
  }: ImageDimension) => {
    if (height > width || width > mapContainerDimension) {
      return mapContainerDimension / 2;
    }

    return mapContainerDimension;
  };

  const calculateHeight = ({
    height,
    width,
    mapContainerDimension,
  }: ImageDimension) => {
    if (width > height || height > mapContainerDimension) {
      let divider = width - height >= 300 ? 1.2 : 1;

      return mapContainerDimension / divider;
    }

    return mapContainerDimension;
  };

  const validateInputNumber = (value: number) => {
    if (value === 100) {
      return 1;
    }

    return Number(0 + "." + value);
  };

  const getFloorEntityLeafletIcon = (
    floorEntity: FloorEntity,
    type: "floor" | "bookings"
  ) => {
    if (!floorEntity) {
      return;
    }

    if (floorEntity.type === "Wayfinder") {
      return wayfinderLeafletIcon;
    }

    if (floorEntity.type === "Desk") {
      if (type === "bookings") {
        if (floorEntity.isAssigned || floorEntity.isBlocked) {
          return blockedDeskLeafletIcon;
        }

        return floorEntity.isBooked ? busyDeskLeafletIcon : freeDeskLeafletIcon;
      }

      return floorEntity.user || floorEntity.isBlocked
        ? blockedDeskLeafletIcon
        : manageDeskLeafletIcon;
    }

    if (floorEntity?.isBlocked) {
      return blockedRoomLeafletIcon;
    }

    if (type === "floor") {
      return manageRoomLeafletIcon;
    }

    return floorEntity.isBooked ? busyRoomLeafletIcon : freeRoomLeafletIcon;
  };

  const getScaledIcon = (
    icon: L.Icon,
    scaleFactor: number,
    isWayfinder?: boolean
  ): L.Icon => {
    if (!Array.isArray(icon.options.iconSize)) {
      throw new Error(
        "Scaling can only be applied to icons that have a set size represented as a tuple"
      );
    }

    const heightAnchor = isWayfinder
      ? icon.options.iconSize[0] * scaleFactor
      : (icon.options.iconSize[0] * scaleFactor) / 2;

    return L.icon({
      ...icon.options,
      iconSize: [
        icon.options.iconSize[0] * scaleFactor,
        icon.options.iconSize[0] * scaleFactor,
      ],
      iconAnchor: [(icon.options.iconSize[0] * scaleFactor) / 2, heightAnchor],
    });
  };

  return {
    calculateWidth,
    calculateHeight,
    validateInputNumber,
    getFloorEntityLeafletIcon,
    getScaledIcon,
  };
};
