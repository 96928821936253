import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useRouter } from "../../lib/hooks/useRouter";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useSetPasswordFormik } from "../../components/SetPassword/lib/useSetPasswordFormik";

import { CenteredLoader } from "../../components/shared/CenteredLoader/CenteredLoader";
import { SetPasswordForm } from "../../components/SetPassword/SetPasswordForm/SetPasswordForm";
import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";
import { HandleRequestState } from "../../components/shared/HandleRequestState/HandleRequestState";
import { AccountMessage } from "../../components/shared/AuthenticationPage/AccountMessage/AccountMessage";

export const SetPassword = () => {
  const { query, history } = useRouter();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { verifyPasswordResetCode, confirmPasswordReset } = useAuthContext();

  useEffect(() => {
    handleVerifyCode();
  }, []);

  const handleVerifyCode = async () => {
    if (!query.token || !query.tenantId) {
      setLoading(false);
      setShowError(true);
      return;
    }

    try {
      const email = await verifyPasswordResetCode(query.token, query.tenantId);

      setEmail(email);
    } catch (error: any) {
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const formik = useSetPasswordFormik({
    initialValues: {
      emailPassword: email,
      confirmPassword: "",
      newResetPassword: "",
    },
    onSubmit: async (values) => {
      try {
        setSubmitting(true);

        await confirmPasswordReset(query.token, values.newResetPassword);

        toast.success("Password updated successfully!");

        setTimeout(() => {
          history.push("/login");
        }, 2000);
      } catch (error: any) {
        setSubmitting(false);
        console.error(error.message);
        toast.error(
          "Password couldn't be updated, please reload and try again!"
        );
      }
    },
  });

  return (
    <AuthenticationPage isDefault fullWidth formTitle="Set Password">
      <HandleRequestState state={loading} placeholder={<CenteredLoader />}>
        <HandleRequestState
          state={showError}
          placeholder={
            <AccountMessage message="Your request to reset your password has expired or the link has already been used" />
          }
        >
          <SetPasswordForm loading={submitting} formik={formik} />
        </HandleRequestState>
      </HandleRequestState>
    </AuthenticationPage>
  );
};
