import { createContext, useContext } from "react";
import { ModalResponseType } from "../../../components/Account/EnableMFA/Modal/ModalResponse/ModalResponse";

export type MfaModalKeys =
  | "authentication"
  | "mfaPicker"
  | "QRCode"
  | "secondaryEmail"
  | ModalResponseType;

export type MfaContextValues = {
  mfaModal: MfaModalKeys | undefined;
  setMfaModal: React.Dispatch<React.SetStateAction<MfaModalKeys | undefined>>;
};

export const MfaContext = createContext<MfaContextValues>({
  mfaModal: undefined,
  setMfaModal: () => undefined,
});

export const useMfaContext = () => {
  const context = useContext(MfaContext);

  if (!context) {
    throw new Error("useMfaContext must be used within MfaContext");
  }

  return context;
};
