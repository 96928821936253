import React from "react";

import { useRouter } from "../../../../lib/hooks/useRouter";

import { tooltips } from "../../../../lib/utils/tooltips";
import { WORKPLACES_ROOT_PATH } from "../../../../lib/routes/routes";
import { WorkplacesTreeLevel } from "./WorkplacesTreeLevel/WorkplacesTreeLevel";
import { WorkplacesTreeLoader } from "./WorkplacesTreeLoader/WorkplacesTreeLoader";
import { PageSidebar } from "../../../../components/shared/PageSidebar/PageSidebar";
import { ApolloErrorGuard } from "../../../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../../../components/shared/HandleLoadingState/HandleLoadingState";
import { PageSidebarHeader } from "../../../../components/shared/PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { AddWorkplaceButton } from "../../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { ApolloError } from "@apollo/client";

interface Props {
  showOnDesktop?: boolean;
  loading?: boolean;
  error?: ApolloError;
  rootWorkplaces?: any;
}

export const WorkplacesTree = ({
  showOnDesktop,
  loading,
  error,
  rootWorkplaces,
}: Props) => {
  const { history, pathname } = useRouter();

  return (
    <PageSidebar
      className="PageSidebar--default PageSidebar--mainWorkpalces"
      showOnDesktop={showOnDesktop}
    >
      <PageSidebarHeader
        title="Workspaces"
        message={tooltips.workplaces.tree}
        onTitleClick={() => {
          if (pathname === WORKPLACES_ROOT_PATH) {
            return;
          }

          history.push(WORKPLACES_ROOT_PATH);
        }}
      >
        <AddWorkplaceButton disabled={loading || !!error} small />
      </PageSidebarHeader>

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<WorkplacesTreeLoader />}
      >
        <ApolloErrorGuard
          error={error}
          errorComponent={<p>Data couldn't be loaded.</p>}
        >
          <div className="default-scroll-y">
            {rootWorkplaces.map((item: any) => {
              if (typeof item !== "object") {
                return;
              }

              return item?.map((workplace: WorkplaceFields) => {
                return (
                  <WorkplacesTreeLevel
                    item={workplace}
                    toggleParent={() => {}}
                    key={`${workplace.id}-tree-root`}
                    mainTreeLevel
                    pathTo={WORKPLACES_ROOT_PATH}
                  />
                );
              });
            })}
          </div>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </PageSidebar>
  );
};
