import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useDesksRequests } from "./desks/useDesksRequests";
import { useRoomsRequests } from "./rooms/useRoomsRequests";
import { useSitesRequests } from "./sites/useSitesRequests";
import { useZonesRequests } from "./zones/useZonesRequests";
import { useFloorsRequests } from "./floors/useFloorsRequests";
import { useDevicesRequests } from "./devices/useDevicesRequests";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useCompaniesRequests } from "./companies/useCompaniesRequests";
import { useBuildingsRequests } from "./buildings/useBuildingsRequests";
import { useWorkplacesClient } from "../grpc/workplaces/useWorkplacesClient";
import { useConfirmation } from "../../lib/context/Confirmation/ConfirmationContext";
import { useWayfinderRequests } from "./wayfinder/useWayfinderRequests";
import { useBookingsRequests } from "./bookings/useBookingsRequests";

import {
  ADD_TENANT,
  AddTenantResponse,
  AddTenantVariables,
  GetWorkplacesResponse,
  WorkplaceRequestVariables,
  GET_ROOT_WORKPLACES,
} from "./workplaces";
import { WORKPLACES_ROOT_PATH } from "../../lib/routes/routes";
import { EntityType } from "../grpc/subscription/subscription";
import { ConfirmationModalOptions } from "../../lib/context/Confirmation/ConfirmationContextProvider";
import { getHierarchyItems } from "../../components/Workplaces/helpers/getHierarchyItems";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
  getWorkplaceId,
} from "../../components/Workplaces/helpers/getWorkplaceFragment";

export interface LicenseUpdateRequest {
  id: string;
  value: boolean;
  type: EntityType;
}

export const useWorkplaceRequests = () => {
  const history = useHistory();
  const client = useApolloClient();
  const { user } = useAuthContext();
  const confirmation = useConfirmation();
  const { deleteWorkplace: deleteWorkplaceRequest } = useWorkplacesClient();
  const { defaultLocation, deleteDefaultLocation } = useBookingsRequests();

  const getRootWorkplaces = useLazyQuery<
    GetWorkplacesResponse,
    WorkplaceRequestVariables
  >(GET_ROOT_WORKPLACES);

  const roomRequests = useRoomsRequests();
  const deskRequests = useDesksRequests();
  const siteRequests = useSitesRequests();
  const zonesRequests = useZonesRequests();
  const floorRequests = useFloorsRequests();
  const deviceRequests = useDevicesRequests();
  const companyRequests = useCompaniesRequests();
  const buildingRequests = useBuildingsRequests();
  const wayfinderRequests = useWayfinderRequests();

  const addTenant = async () => {
    return await client.mutate<AddTenantResponse, AddTenantVariables>({
      mutation: ADD_TENANT,
      variables: {
        name: user?.name || "",
        email: user?.email || "",
        id: user?.customerid || "",
      },
      update: (cache, newData) => {
        const newTenant = newData.data?.addTenant.tenant[0];

        const tenantData = {
          id: newTenant?.id || "",
          tenantId: newTenant?.tenantId || "",
          companies: [],
          sites: [],
          buildings: [],
          floors: [],
          zones: [],
          rooms: [],
          desks: [],
        };

        cache.writeQuery<GetWorkplacesResponse, WorkplaceRequestVariables>({
          query: GET_ROOT_WORKPLACES,
          variables: {
            tenantId: user?.customerid || "",
          },
          data: {
            queryTenant: [tenantData],
          },
        });
      },
    });
  };

  const updateGraphQLLicense = async ({
    id,
    type,
    value,
  }: LicenseUpdateRequest) => {
    if (type === EntityType.ROOM) {
      return await roomRequests.updateRoomLicense({
        id,
        licensed: value,
        tenantId: user?.customerid || "",
      });
    }

    if (type === EntityType.WAYFINDER) {
      return await wayfinderRequests.updateWayfinderLicense({
        id,
        licensed: value,
        tenantId: user?.customerid || "",
      });
    }

    return await deskRequests.updateDeskLicense({
      id,
      licensed: value,
      tenantId: user?.customerid || "",
    });
  };

  const deleteWorkplace = async (
    id: string,
    workspaceName: string,
    redirect?: boolean
  ) => {
    if (!id) {
      return;
    }

    let workspaceItem = client.readFragment({
      id: getWorkplaceId(workspaceName, id),
      fragmentName: getWorkplaceFragmentName(workspaceName),
      fragment: getWorkplaceFragment(workspaceName),
    });

    const { hasChildren } = getHierarchyItems(workspaceItem);

    let modalOptions: ConfirmationModalOptions = {
      title: "Are you sure you want to delete this workspace?",
    };

    if (hasChildren) {
      modalOptions = {
        ...modalOptions,
        keywordDelete: "DELETE",
        description:
          "You're about to delete this and all related sub-items. Deleted items can't be restored.",
      };
    }

    await confirmation.confirm(modalOptions);

    try {
      await deleteWorkplaceRequest(id);

      if (
        id === defaultLocation.data?.queryDefaultLocationPath[0]?.referenceId
      ) {
        //we check if the deleted workspace is default location, if so we delete it as default location too
        await deleteDefaultLocation({
          accountId: user?.claims.user_id || "",
        });
      }

      client.cache.evict({
        id: getWorkplaceId(workspaceName, id),
      });

      if (redirect) {
        history.replace(WORKPLACES_ROOT_PATH);
      }

      toast.success("Workspace deleted!");
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.message || "Workspace couldn't be deleted, please try again!"
      );
    }
  };

  return {
    addTenant,
    deleteWorkplace,
    updateGraphQLLicense,
    getRootWorkplaces,
    ...zonesRequests,
    ...companyRequests,
    ...siteRequests,
    ...buildingRequests,
    ...floorRequests,
    ...roomRequests,
    ...deskRequests,
    ...deviceRequests,
    ...wayfinderRequests,
  };
};
