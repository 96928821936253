import React from "react";

interface Props {
  title: string;
  value: string;
}

export const DebugInfoItem = ({ title, value }: Props) => {
  return (
    <div className="DebugInfoItem">
      <span className="DebugInfoItem__title">{title + ": "}</span>
      <span className="DebugInfoItem__value">{value}</span>
    </div>
  );
};
