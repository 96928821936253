import {
  OnLevelSearchRequestLicenseStatus,
  WorkplaceLocationType,
  WorkplaceResourceType,
} from "../../../api/grpc/workplaces/workplaces";
import {
  FilterOption,
  PossibleWorkplaceFilters,
  WorkplaceLocation,
} from "../../../lib/types/main.types";

interface AllWorkplaceFiltersObj {
  name: PossibleWorkplaceFilters;
  options: Array<FilterOption>;
}

const possibleTypes: {
  [key in WorkplaceLocation]: Array<FilterOption>;
} = {
  Company: [
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeSite,
      name: "site",
      title: "Site",
    },
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeBuilding,
      name: "building",
      title: "Building",
    },
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeFloor,
      name: "floor",
      title: "Floor",
    },
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeZone,
      name: "zone",
      title: "Zone",
    },
  ],
  Site: [
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeBuilding,
      name: "building",
      title: "Building",
    },
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeFloor,
      name: "floor",
      title: "Floor",
    },
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeZone,
      name: "zone",
      title: "Zone",
    },
  ],
  Building: [
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeFloor,
      name: "floor",
      title: "Floor",
    },
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeZone,
      name: "zone",
      title: "Zone",
    },
  ],
  Floor: [
    {
      value: WorkplaceLocationType.WorkplaceLocationTypeZone,
      name: "zone",
      title: "Zone",
    },
  ],
  Zone: [],
};

export const definedWorkplaceFilters = (
  type?: WorkplaceLocation
): Array<AllWorkplaceFiltersObj> => {
  const commonFilters: Array<AllWorkplaceFiltersObj> = [
    {
      name: "resources",
      options: [
        {
          value: WorkplaceResourceType.WorkplaceResourceTypeRoom,
          name: "room",
          title: "Room",
        },
        {
          value: WorkplaceResourceType.WorkplaceResourceTypeDesk,
          name: "desk",
          title: "Desk",
        },
        {
          value: WorkplaceResourceType.WorkplaceResourceTypeWayfinder,
          name: "wayfinder",
          title: "Wayfinder",
        },
      ],
    },
    {
      name: "license",
      options: [
        {
          value:
            OnLevelSearchRequestLicenseStatus.OnLevelSearchRequestLicenseStatusActive,
          name: "licensed",
          title: "Yes",
        },
        {
          value:
            OnLevelSearchRequestLicenseStatus.OnLevelSearchRequestLicenseStatusNotActive,
          name: "unlicensed",
          title: "No",
        },
      ],
    },
  ];

  if (type === "Zone") {
    return commonFilters;
  }

  return [
    {
      name: "location",
      options: !!type
        ? possibleTypes[type as WorkplaceLocation]
        : [
            {
              value: WorkplaceLocationType.WorkplaceLocationTypeCompany,
              name: "company",
              title: "Company",
            },
            {
              value: WorkplaceLocationType.WorkplaceLocationTypeSite,
              name: "site",
              title: "Site",
            },
            {
              value: WorkplaceLocationType.WorkplaceLocationTypeBuilding,
              name: "building",
              title: "Building",
            },
            {
              value: WorkplaceLocationType.WorkplaceLocationTypeFloor,
              name: "floor",
              title: "Floor",
            },
            {
              value: WorkplaceLocationType.WorkplaceLocationTypeZone,
              name: "zone",
              title: "Zone",
            },
          ],
    },
    ...commonFilters,
  ];
};
