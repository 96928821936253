import React, { PropsWithChildren, useMemo } from "react";

import { ResponsivePie } from "@nivo/pie";

export const RoomMeetingBehavior = ({
  adHoc,
  planned,
}: PropsWithChildren<{ adHoc: number; planned: number }>) => {
  const adHocAsPercentage = useMemo(() => {
    let sum = adHoc + planned;

    return Math.round((100 / sum) * adHoc);
  }, [adHoc, planned]);

  const data = [
    {
      id: "Ad-hoc",
      label: "Ad-hoc",
      value: adHocAsPercentage,
      color: "#00EC97",
    },
    {
      id: "Planned",
      label: "Planned",
      value: 100 - adHocAsPercentage,
      color: "#156C79",
    },
  ];

  return (
    <>
      <ResponsivePie
        data={data}
        colors={{ datum: "data.color" }}
        margin={{ top: 10, right: 20, bottom: 70, left: 20 }}
        arcLabel={(d) => `${d.value}%`}
        arcLabelsTextColor="#fff"
        innerRadius={0.65}
        padAngle={0.7}
        borderWidth={1}
        enableArcLabels={false}
        borderColor={{
          from: "color",
        }}
        enableArcLinkLabels={false}
        theme={{
          tooltip: {
            container: {
              borderRadius: 12,
            },
            chip: {
              borderRadius: 50,
            },
          },
        }}
        legends={[
          {
            itemsSpacing: 75,
            itemWidth: 20,
            itemHeight: 15,
            itemOpacity: 1,
            symbolSize: 15,
            itemTextColor: "#000",
            justify: false,
            anchor: "bottom",
            direction: "row",
            translateX: -25,
            translateY: 35,
            itemDirection: "left-to-right",
            symbolShape: "circle",
            data: data.map((cur, index) => ({
              id: cur.id,
              label: cur.id,

              color: cur.color,
            })),
          },
        ]}
      />

      <div className="SharedPieChart__value RoomMeetingBehavior__value">
        {adHocAsPercentage}%
      </div>
    </>
  );
};
