import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import { format } from "date-fns";
import { isString } from "lodash";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useDeskFormik } from "../../../shared/Forms/DeskForm/lib/useDeskFormik";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useDesksRequests } from "../../../../api/graphql/desks/useDesksRequests";
import { useGetContacts } from "../../../People/lib/useGetContacts";
import { useDefineLicense } from "../../../../lib/hooks/useDefineLicense";

import { SubRouteProps } from "../../../../lib/routes/routes";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";

// import { EditViewsTab } from "../EditViewsTab/EditViewsTab";
import { EditSettings } from "../EditSettings/EditSettings";
import { DeskForm } from "../../../shared/Forms/DeskForm/DeskForm";
// import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import {
  DeskRequestVariables,
  DESK_FIELDS_FRAGMENT,
} from "../../../../api/graphql/desks/desks";
// import { EntityType } from "../../../../api/grpc/subscription/subscription";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";
import { tooltips } from "../../../../lib/utils/tooltips";
import { DownloadQRCode } from "../../../DownloadQRCode/DownloadQRCode";
import { ListTagsEntity } from "../../../../api/grpc/workplaces/workplaces";
import { EntityType } from "../../../../api/grpc/subscription/subscription";

export const EditDesk = (props: EditWorkplaceProps) => {
  const { id } = props;
  const { match } = useRouter();
  const client = useApolloClient();
  const { customer } = useCustomerContext();
  const { updateDesk } = useWorkplaceRequests();
  const { removeUserFromDesk } = useDesksRequests();

  const {
    loading,
    error,
    data,
    refetch: refetchContacts,
  } = useGetContacts({
    page: 1,
    pageSize: 0,
  });
  const [deskData, setDeskData] = useState<WorkplaceFields | null>(null);

  // TO DO: Move on how the data are loaded to a hook useWorkplaceData
  const updateData = useCallback(() => {
    setDeskData(
      client.readFragment({
        id: `Desk:${id}`,
        fragmentName: "DeskFields",
        fragment: DESK_FIELDS_FRAGMENT,
      })
    );
  }, [id]);

  const { defineLicenseNumber, refetch: refetchLicenseNumber } =
    useDefineLicense({
      type: EntityType.DESK,
      adjustLeft: true,
      isLicensed: deskData?.licensed,
    });

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useDeskFormik({
    initialValues: {
      name: deskData?.name || "",
      description: deskData?.description || "",
      tags: deskData?.tags || [],
      isBooked: deskData?.isBooked,
      marker: {
        latitude: deskData?.marker?.latitude || 0,
        longitude: deskData?.marker?.longitude || 0,
      },
      showCheckInTime: deskData?.showCheckInTime || false,
      checkInTime: deskData?.checkInTime || 1,
      isBlocked: deskData?.isBlocked || false,
      qrCodeRequired: deskData?.qrCodeRequired || false,
      workingHrsActivated: deskData?.workingHrsActivated || false,
      workingHrsStart: deskData?.workingHrsStart || "",
      workingHrsEnd: deskData?.workingHrsEnd || "",
      workingWeekDays: deskData?.workingWeekDays,
      licensed: !!deskData?.licensed,
      account:
        deskData?.account === null
          ? null
          : {
              accountID:
                deskData?.account?.accountID + " " + deskData?.account?.email ||
                "",
              tenantID: customer.tenantId,
              displayName: deskData?.account?.displayName || "",
              email: deskData?.account?.email || "",
            },
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const removedTags =
          deskData?.tags.filter((item) => !values.tags?.includes(item)) || [];

        const emailAndIdOfAccount = values?.account?.accountID.split(" ") || [];
        const idOfAccount = emailAndIdOfAccount[0];
        const emailOfAccount = emailAndIdOfAccount[1];

        const variables: DeskRequestVariables = {
          id: props.id,
          name: values.name,
          tags: values.tags || [],
          showCheckInTime: values.showCheckInTime || false,
          checkInTime: values.checkInTime || 1,
          qrCodeRequired: values.qrCodeRequired || false,
          description: values.description,
          isBooked: values.isBooked,
          isBlocked: values.isBlocked,
          workingHrsActivated: values.workingHrsActivated || false,
          workingHrsStart: !!values.workingHrsStart
            ? isString(values.workingHrsStart) &&
              values.workingHrsStart.length <= 9
              ? values.workingHrsStart
              : format(new Date(values.workingHrsStart || ""), "HH:mm:ss")
            : "",
          workingHrsEnd: !!values.workingHrsEnd
            ? isString(values.workingHrsEnd) && values.workingHrsEnd.length <= 9
              ? values.workingHrsEnd
              : format(new Date(values.workingHrsEnd || ""), "HH:mm:ss")
            : "",
          marker: {
            latitude: values?.marker?.latitude || 0,
            longitude: values?.marker?.longitude || 0,
          },
          account: values.account?.accountID
            ? {
                accountID: idOfAccount,
                displayName: values.account.displayName,
                email: emailOfAccount,
                tenantID: customer.tenantId,
              }
            : null,
          workingWeekDays: values.workingWeekDays,
          tenantId: customer.tenantId,
          remove: {
            tags: removedTags,
          },
        };

        await updateDesk(variables);

        if (
          deskData?.account !== null &&
          !variables.account?.accountID?.length
        ) {
          await removeUserFromDesk({
            id: variables.id,
            userId: deskData?.account?.accountID || "",
            email: deskData?.account?.email || "",
          });
        }

        toast.success("Desk edited successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Desk couldn't be updated, please reload and try again!"
        );
        console.error(error.message);
      }
    },
  });

  // const rows: WorkplaceListData[] = [
  //   {
  //     data: deskData?.devices,
  //     type: "Device",
  //   },
  // ];

  useEffect(() => {
    refetchContacts();
  }, [id]);

  const routes: SubRouteProps[] = [
    // {
    //   exact: true,
    //   label: "View",
    //   to: match.url,
    //   id: "view-page-desk",
    //   render: () => <EditViewsTab id={props.id} type="Desk" rows={rows} />,
    // },
    {
      exact: true,
      label: "Settings",
      id: "settings-page-desk",
      to: `${match.url}`,
      render: () => (
        <EditSettings
          title="General settings"
          // hasChanges={formik.dirty}
          onSubmit={formik.handleSubmit}
        >
          <DeskForm
            id={id}
            edit
            formik={formik}
            loading={loading}
            error={error}
            data={data}
            downloadQRicon={
              deskData ? (
                <DownloadQRCode
                  name={deskData?.name}
                  type={ListTagsEntity.TYPE_DESK}
                  id={deskData?.id}
                />
              ) : (
                <></>
              )
            }
            displayEditWorkHours={deskData?.workingHrsActivated}
            defineLicenseNumber={defineLicenseNumber}
            refetchLicenseNumber={refetchLicenseNumber}
          />
        </EditSettings>
      ),
    },
  ];

  return (
    <EditWorkplaceWrapper
      type="Desk"
      id={props.id}
      routes={routes}
      data={deskData || undefined}
      message={tooltips.workplaces.desk}
    />
  );
};
