import React from "react";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";

interface Props {
  image: string;
  loading: boolean;
  cardNumber?: string;
}

export const PaymentCardContent = ({ image, loading, cardNumber }: Props) => {
  return (
    <div>
      {loading && <LoadingBox minHeight={45} className="LoadingBox--image" />}
      <>
        <img src={image} alt="Payment Card" />
        <span>Ending {cardNumber?.slice(cardNumber.length - 4)}</span>
      </>
    </div>
  );
};
