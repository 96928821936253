import React, { PropsWithChildren } from "react";
import { usePaginationHelper } from "../../../../lib/hooks/usePaginationHelper";
import { useInsightsContext } from "../../Context/InsightsContext";

import { GeneralStatsByDesksResponse } from "../../../../api/grpc/insights/insights";
import { PaginationContext } from "../../../../lib/context/Pagination/PaginationContext";
import { trimText } from "../../../../lib/helpers/trimText";
import { Pagination } from "../../../shared/Pagination/Pagination";
import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";

const columns: StructureTableColumn[] = [
  {
    key: "desk_name",
    header: "Desk name",
  },
  {
    key: "desk_reservations",
    header: "Reservations",
  },
  {
    key: "avg_daily_reservations",
    header: "Avg. daily reservations",
  },
  {
    key: "avg_reservations_duration",
    header: "Avg. reservation duration (min)",
  },
  {
    key: "occupancy",
    header: "Occupancy",
  },
  {
    key: "no_shows",
    header: "No-shows",
  },
];

export const InsightsExpandedDesks = ({
  data,
  showPagination,
}: PropsWithChildren<{
  data: GeneralStatsByDesksResponse;
  showPagination: boolean;
}>) => {
  const { page, pageSize, pagination } = usePaginationHelper(data.data.length);
  const { setInsightsLocation } = useInsightsContext();

  const indexOfLastDesk = page * pageSize;
  const indexOfFirstDesk = indexOfLastDesk - pageSize;
  const currentDeskData = data.data.slice(indexOfFirstDesk, indexOfLastDesk);

  const rows: StructureTableRows[] = currentDeskData.map((item) => {
    return {
      desk_name: (
        <h6
          className="mb-0"
          title={item.name}
          onClick={() => {
            setInsightsLocation({
              locationId: item.id,
              locationName: item.name,
              locationType: "Desk",
            });
          }}
        >
          {trimText(item.name, 27)}
        </h6>
      ),
      desk_reservations: <h6>{item.totalMeetings}</h6>,
      avg_daily_reservations: (
        <h6>{item.avgMeetingTime > 0 ? Math.round(item.avgMeetings) : 0}</h6>
      ),
      avg_reservations_duration: (
        <h6>{item.avgMeetingTime > 0 ? Math.round(item.avgMeetingTime) : 0}</h6>
      ),
      occupancy: (
        <h6>{item.occupancy > 0 ? Math.round(item.occupancy) + "%" : 0}</h6>
      ),
      no_shows: <h6>{item.noShows > 0 ? Math.round(item.noShows) : 0}</h6>,
    };
  });

  return (
    <PaginationContext.Provider value={pagination}>
      <StructureTable
        rows={rows}
        columns={columns}
        className="StructureTable--rooms InsightsExpandedRooms mt-5"
      />
      {showPagination && <Pagination />}
    </PaginationContext.Provider>
  );
};
