import { useMutation } from "@apollo/client";
import {
  DeleteDefaultLocationPathResponse,
  DeleteDefaultLocationPathVariables,
  DELETE_DEFAULT_LOCATION_PATH,
} from "../../../api/graphql/bookings/bookings";

export const useDeleteLocation = () =>
  useMutation<
    DeleteDefaultLocationPathResponse,
    DeleteDefaultLocationPathVariables
  >(DELETE_DEFAULT_LOCATION_PATH);
