import React from "react";
import Select from "react-select";
import { LoginMethod } from "../../../api/grpc/account/account";

import { selectStyles } from "../../../lib/utils/selectStyles";
import { definedLoginValue } from "../../DirectorySync/DirectoryIntegrations/shared/lib/useSelectLoginMethod";

interface Props {
  value: LoginMethod;
  fieldName: string;
  disable: boolean;
  onChange: (field: string, value: number) => void;
  isBasicOrBussines?: boolean;
}

const options = [
  { value: LoginMethod.EMAIL, label: definedLoginValue[LoginMethod.EMAIL] },
  { value: LoginMethod.GSUITE, label: definedLoginValue[LoginMethod.GSUITE] },
  { value: LoginMethod.O365, label: definedLoginValue[LoginMethod.O365] },
];

const basicOptions = [{ value: LoginMethod.EMAIL, label: "Email + Password" }];

export const SignInMethodSelect = ({
  fieldName,
  value,
  onChange,
  disable,
  isBasicOrBussines,
}: Props) => {
  return (
    <Select
      name={fieldName}
      options={isBasicOrBussines ? basicOptions : options}
      theme={selectStyles}
      isClearable={false}
      isDisabled={disable}
      value={{
        value: value,
        label: options.find((option) => option.value === value)?.label,
      }}
      onChange={(e) => {
        if (!e || e.value === undefined) {
          return;
        }

        onChange(fieldName, e.value);
      }}
    />
  );
};
