import React, { PropsWithChildren } from "react";
import { ResourceFilter } from "../../../shared/ResourceFilter/ResourceFilter";
import { useDirectoryContext } from "../../Context/DirectoryContext";
import { DirectoryIntegrationsFilters } from "../shared/DirectoryIntegrationsFilters";
import { SelectAllIntegrations } from "./SelectAllIntegrations";

export const IntegrationsHeader = ({
  handleSelectAll,
  numberOfAllUsers,
  disableFilters,
}: PropsWithChildren<{
  handleSelectAll: (isSelect: boolean) => void;
  numberOfAllUsers: number;
  disableFilters?: boolean;
}>) => {
  const { filterStatus, searchValue, setSearchValue } = useDirectoryContext();

  return (
    <div className="d-flex justify-content-between flex-wrap gap-1 mt-3">
      <SelectAllIntegrations
        handleSelectAll={handleSelectAll}
        numberOfAllUsers={numberOfAllUsers}
      />

      <ResourceFilter
        searchValue={searchValue}
        onChangeSearch={(e) => setSearchValue(e.target.value)}
        clearSearchValue={() => setSearchValue("")}
        className="ml-auto"
        disabledFilters={disableFilters && !!!filterStatus.length}
        numberOfFilters={!!filterStatus.length ? 1 : undefined}
      >
        <DirectoryIntegrationsFilters />
      </ResourceFilter>
    </div>
  );
};
