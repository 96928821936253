import React from "react";
import { Button } from "reactstrap";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { AuthenticationPage } from "../AuthenticationPage/AuthenticationPage";

import "./NoPermission.scss";

export const NoPermission = () => {
  const { signOut } = useAuthContext();

  return (
    <AuthenticationPage formTitle="Access Denied">
      <p className="mt-4 mb-2 default-paragraph">
        Looks like your account can't login. The reason could be one of these:
      </p>
      <ul className="mt-2 mb-2 default-list">
        <li>You haven't verified your email yet</li>
        <li>You're a workspace app user</li>
      </ul>
      <p className="mt-3 mb-4 default-paragraph">
        If none of the above is your case, please contact your administrator.
      </p>

      <Button
        outline
        color="primary"
        className="mt-auto"
        onClick={() => signOut()}
      >
        Go to login page
      </Button>
    </AuthenticationPage>
  );
};
