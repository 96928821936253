import React, { PropsWithChildren, useEffect, useState } from "react";
import { trimText } from "../../../lib/helpers/trimText";
import { Button } from "../Button/Button";

export const TagListContent = ({
  tags,
  activeTagsFilter,
  closeModal,
  setActiveTagsFilter,
}: PropsWithChildren<{
  tags: string[];
  activeTagsFilter: string[];
  setActiveTagsFilter: React.Dispatch<React.SetStateAction<string[]>>;
  closeModal: () => void;
}>) => {
  const [tempTags, setTempTags] = useState<string[]>(() => activeTagsFilter);

  const handleTempTags = (tag: string) => {
    if (tempTags.includes(tag)) {
      setTempTags(tempTags.filter((item) => item !== tag));
      return;
    }
    setTempTags([...tempTags, tag]);
  };

  useEffect(() => {
    //this is to handle when users click Clear all to clear the temporary tags too:
    if (!!!activeTagsFilter.length && !!tempTags.length) {
      return setTempTags([]);
    }
  }, [activeTagsFilter]);

  return (
    <div className="TagListContent">
      <div className="main-flex TagListContent__buttons">
        <Button
          title="Done"
          color="primary"
          className="child-element--clear"
          onClick={() => {
            setActiveTagsFilter(tempTags);

            return closeModal();
          }}
        />

        <Button
          title="Clear tags"
          color="outline-secondary"
          className="child-element--clear"
          disabled={!!!tempTags.length}
          onClick={() => {
            setActiveTagsFilter([]);
            setTempTags([]);
            return;
          }}
        />
      </div>

      <div className="TagListContent__tags">
        {tags.map((tag) => (
          <div
            className={`TagFilter__item ${
              tempTags.includes(tag) ? "active" : " "
            }`}
            onClick={() => handleTempTags(tag)}
            key={tag}
          >
            {trimText(tag, 20)}
          </div>
        ))}
      </div>
    </div>
  );
};
