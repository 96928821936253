import React from "react";
import { NavLink } from "react-router-dom";
import { GENERAL_STRINGS as t } from "../../../../lib/utils/constants";

export const LogInButton = () => {
  return (
    <NavLink to={"/login"} className="btn btn-primary">
      {t.logIn}
    </NavLink>
  );
};
