import React from "react";
import { BookingTypes } from "../../../../../Bookings/BookingsSearch/BookingsSearch";
import { WayFinderFormWorkspacesItem } from "./WayFinderFormWorkspacesItem";
import { WorkplaceFields } from "../../../../../../api/graphql/workplaces";

import "./WayFinderFormWorkspaces.scss";

interface Props {
  type: BookingTypes | "workspaces";
  data: WorkplaceFields[];
  hideCounter?: boolean;
  handleWorkspace: (
    item: WorkplaceFields,
    type: "desks" | "rooms",
    methodType: "add" | "delete"
  ) => void;
  onClick?: (item: WorkplaceFields) => void;
}

export const WayFinderFormWorkspaces = ({
  type,
  data,
  hideCounter,
  handleWorkspace,
  onClick,
}: Props) => {
  return (
    <div className="WayFinderFormWorkspaces">
      <label className="label__main mt-3">Added {type}</label>
      {!hideCounter && (
        <span className="WayFinderFormWorkspaces__counter">
          ({data.length} of max {type === "room" ? "24" : "42"})
        </span>
      )}

      <div className="WayFinderFormWorkspaces__list d-flex flex-wrap">
        {data.map((item) => (
          <WayFinderFormWorkspacesItem
            key={item.id}
            workspace={item}
            handleWorkspace={handleWorkspace}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
};
