import React, { PropsWithChildren } from "react";
import { ResponsivePie } from "@nivo/pie";

import "./SharedPieChart.scss";

export const SharedPieChart = ({
  data,
  value,
}: PropsWithChildren<{
  data: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
  value: number;
}>) => {
  return (
    <div className="SharedPieChart">
      <ResponsivePie
        data={data}
        colors={{ datum: "data.color" }}
        margin={{ top: 1, right: 0, bottom: 1, left: 0 }}
        innerRadius={0.65}
        padAngle={0.7}
        borderWidth={1}
        enableArcLabels={false}
        borderColor={{
          from: "color",
        }}
        enableArcLinkLabels={false}
        theme={{
          tooltip: {
            container: {
              borderRadius: 12,
            },
            chip: {
              borderRadius: 50,
            },
          },
        }}
      />

      <div className="SharedPieChart__value">{value}%</div>
    </div>
  );
};
