import React, { PropsWithChildren } from "react";
import { Icon } from "../../shared/Icon/Icon";

export const ModalHeaderContent = ({
  title,
  handleToggle,
}: PropsWithChildren<{
  title: string;
  handleToggle: () => void;
}>) => {
  return (
    <div className="modal-header">
      <h5 className="modal-title">{title}</h5>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={handleToggle}
      >
        <Icon icon="close-icon" />
      </button>
    </div>
  );
};
