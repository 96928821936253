import React from "react";
import Select from "react-select";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { selectStyles } from "../../../../lib/utils/selectStyles";
import { defineDatesPicker } from "../../../Bookings/lib/defineDatesPicker";

export type InputType = { label: string; value: string };

interface Props {
  onChange: (value: InputType | null) => void;
  value?: InputType;
}

export const DaySelectorInput = ({ onChange, value }: Props) => {
  const { timeZone } = useTimeZoneContext();

  const { datePickOptions } = defineDatesPicker(timeZone);

  return (
    <Select
      name="day"
      options={datePickOptions}
      theme={selectStyles}
      minMenuHeight={55}
      isSearchable={false}
      className="SearchWorkplaceFrom__select"
      onChange={onChange}
      value={value}
    />
  );
};
