// @generated by protobuf-ts 2.1.0 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "subscription.proto" (syntax proto3)
// tslint:disable
import { ChargeBeeHostedService } from "./subscription";
import type { HostedPage } from "./subscription";
import type { CreateHostedPageRequest } from "./subscription";
import type { PortalSession } from "./subscription";
import type { PortalSessionRequest } from "./subscription";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { SubscriptionService } from "./subscription";
import type { ListCustomerSubscriptionResponse } from "./subscription";
import type { ListCustomerSubscriptionRequest } from "./subscription";
import type { GetSubscriptionCustomerRequest } from "./subscription";
import type { DelinkChannelPartnerRequest } from "./subscription";
import type { GetChannelPartnerRequest } from "./subscription";
import type { CreateChannelPartnerRequest } from "./subscription";
import type { GetPaymentCardRequest } from "./subscription";
import type { PaymentCardResponse } from "./subscription";
import type { CreatePaymentCardRequest } from "./subscription";
import type { BulkLicensingResponse } from "./subscription";
import type { BulkLicensingRequest } from "./subscription";
import type { LicenseFreeSlotsResponse } from "./subscription";
import type { LicenseFreeSlotsRequest } from "./subscription";
import type { InvoicePDFResponse } from "./subscription";
import type { InvoiceRequest } from "./subscription";
import type { SubscriptionInfoResponse } from "./subscription";
import type { ListLicenseResponse } from "./subscription";
import type { ListLicenseRequest } from "./subscription";
import type { EntityLicenceResponse } from "./subscription";
import type { EntityLicenceRequest } from "./subscription";
import type { CheckSubscriptionResponse } from "./subscription";
import type { CheckSubscriptionRequest } from "./subscription";
import type { TrialRequest } from "./subscription";
import type { DeleteCBCustomerRequest } from "./subscription";
import type { Empty } from "./google/protobuf/empty";
import type { DeleteSubscriptionRequest } from "./subscription";
import type { UpdateSubscriptionRequest } from "./subscription";
import type { ListSubscriptionsResponse } from "./subscription";
import type { ListSubscriptionsRequest } from "./subscription";
import type { CBCustomer } from "./subscription";
import type { UpdateChargebeeCustomerRequest } from "./subscription";
import type { GetCustomerSubscriptionRequest } from "./subscription";
import type { GetSubscriptionRequest } from "./subscription";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Subscription } from "./subscription";
import type { CreateSubscriptionRequest } from "./subscription";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * All operations related to Chargebee Subscriptions
 *
 * @generated from protobuf service SubscriptionService
 */
export interface ISubscriptionServiceClient {
    /**
     * Create a ChargeBee subscription for existing customer
     *
     * @generated from protobuf rpc: CreateSubscription(CreateSubscriptionRequest) returns (Subscription);
     */
    createSubscription(input: CreateSubscriptionRequest, options?: RpcOptions): UnaryCall<CreateSubscriptionRequest, Subscription>;
    /**
     * Retrieve a subscription given a subscriptionId and customerId.
     *
     * @generated from protobuf rpc: GetSubscription(GetSubscriptionRequest) returns (Subscription);
     */
    getSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, Subscription>;
    /**
     * GetChargebeeSubscription Retrieve a subscription given a subscriptionId and customerId from chargebee portal
     *
     * @generated from protobuf rpc: GetChargebeeSubscription(GetSubscriptionRequest) returns (Subscription);
     */
    getChargebeeSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, Subscription>;
    /**
     * Retrieve a subscription given a customerId.
     *
     * @generated from protobuf rpc: GetCustomerSubscription(GetCustomerSubscriptionRequest) returns (Subscription);
     */
    getCustomerSubscription(input: GetCustomerSubscriptionRequest, options?: RpcOptions): UnaryCall<GetCustomerSubscriptionRequest, Subscription>;
    /**
     * Retrieve a subscription given a customerId.
     *
     * @generated from protobuf rpc: UpdateChargebeeCustomer(UpdateChargebeeCustomerRequest) returns (CBCustomer);
     */
    updateChargebeeCustomer(input: UpdateChargebeeCustomerRequest, options?: RpcOptions): UnaryCall<UpdateChargebeeCustomerRequest, CBCustomer>;
    /**
     * List all subscriptions for a given customerId.
     *
     * @generated from protobuf rpc: ListSubscriptions(ListSubscriptionsRequest) returns (ListSubscriptionsResponse);
     */
    listSubscriptions(input: ListSubscriptionsRequest, options?: RpcOptions): UnaryCall<ListSubscriptionsRequest, ListSubscriptionsResponse>;
    /**
     * Updates a subscription based on field mask given a customerId.
     *
     * @generated from protobuf rpc: UpdateSubscription(UpdateSubscriptionRequest) returns (Subscription);
     */
    updateSubscription(input: UpdateSubscriptionRequest, options?: RpcOptions): UnaryCall<UpdateSubscriptionRequest, Subscription>;
    /**
     * Deletes a subscription given a customerId and subscriptionId.
     *
     * @generated from protobuf rpc: DeleteSubscription(DeleteSubscriptionRequest) returns (google.protobuf.Empty);
     */
    deleteSubscription(input: DeleteSubscriptionRequest, options?: RpcOptions): UnaryCall<DeleteSubscriptionRequest, Empty>;
    /**
     * Delete Chargebee Customer given customerId.
     *
     * @generated from protobuf rpc: DeleteCustomer(DeleteCBCustomerRequest) returns (google.protobuf.Empty);
     */
    deleteCustomer(input: DeleteCBCustomerRequest, options?: RpcOptions): UnaryCall<DeleteCBCustomerRequest, Empty>;
    /**
     * Create a trial subscription lasting 14 days.
     * Takes in customerID and billing details.
     *
     * @generated from protobuf rpc: CreateTrial(TrialRequest) returns (Subscription);
     */
    createTrial(input: TrialRequest, options?: RpcOptions): UnaryCall<TrialRequest, Subscription>;
    /**
     * Check if a customer has an active subscription subscribed to one of the
     * plans. Only checks for the Basic plan currently. Returns true if
     * subscription exists, otherwise false.
     *
     * @generated from protobuf rpc: CheckSubscriptionExists(CheckSubscriptionRequest) returns (CheckSubscriptionResponse);
     */
    checkSubscriptionExists(input: CheckSubscriptionRequest, options?: RpcOptions): UnaryCall<CheckSubscriptionRequest, CheckSubscriptionResponse>;
    /**
     * Licence room create new licence record for room
     *
     * @generated from protobuf rpc: LicenceEntity(EntityLicenceRequest) returns (EntityLicenceResponse);
     */
    licenceEntity(input: EntityLicenceRequest, options?: RpcOptions): UnaryCall<EntityLicenceRequest, EntityLicenceResponse>;
    /**
     * Unlicence room create new licence record for room
     *
     * @generated from protobuf rpc: UnlicenceEntity(EntityLicenceRequest) returns (EntityLicenceResponse);
     */
    unlicenceEntity(input: EntityLicenceRequest, options?: RpcOptions): UnaryCall<EntityLicenceRequest, EntityLicenceResponse>;
    /**
     * List of licence statistic
     *
     * @generated from protobuf rpc: LicencesList(ListLicenseRequest) returns (ListLicenseResponse);
     */
    licencesList(input: ListLicenseRequest, options?: RpcOptions): UnaryCall<ListLicenseRequest, ListLicenseResponse>;
    /**
     * List of licence statistic
     *
     * @generated from protobuf rpc: GetSubscriptionStats(CheckSubscriptionRequest) returns (SubscriptionInfoResponse);
     */
    getSubscriptionStats(input: CheckSubscriptionRequest, options?: RpcOptions): UnaryCall<CheckSubscriptionRequest, SubscriptionInfoResponse>;
    /**
     * List of licence statistic
     *
     * @generated from protobuf rpc: GetInvoicePDF(InvoiceRequest) returns (InvoicePDFResponse);
     */
    getInvoicePDF(input: InvoiceRequest, options?: RpcOptions): UnaryCall<InvoiceRequest, InvoicePDFResponse>;
    /**
     * List of available license slots for entity licensing
     *
     * @generated from protobuf rpc: LicenseFreeSlots(LicenseFreeSlotsRequest) returns (LicenseFreeSlotsResponse);
     */
    licenseFreeSlots(input: LicenseFreeSlotsRequest, options?: RpcOptions): UnaryCall<LicenseFreeSlotsRequest, LicenseFreeSlotsResponse>;
    /**
     * BulkLicensing create or update license status for many entities
     *
     * @generated from protobuf rpc: BulkLicensing(BulkLicensingRequest) returns (BulkLicensingResponse);
     */
    bulkLicensing(input: BulkLicensingRequest, options?: RpcOptions): UnaryCall<BulkLicensingRequest, BulkLicensingResponse>;
    /**
     * CreatePaymentCard create new payment card resource
     *
     * @generated from protobuf rpc: CreatePaymentCard(CreatePaymentCardRequest) returns (PaymentCardResponse);
     */
    createPaymentCard(input: CreatePaymentCardRequest, options?: RpcOptions): UnaryCall<CreatePaymentCardRequest, PaymentCardResponse>;
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: UpdatePaymentCard(CreatePaymentCardRequest) returns (PaymentCardResponse);
     */
    updatePaymentCard(input: CreatePaymentCardRequest, options?: RpcOptions): UnaryCall<CreatePaymentCardRequest, PaymentCardResponse>;
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: GetPaymentCard(GetPaymentCardRequest) returns (PaymentCardResponse);
     */
    getPaymentCard(input: GetPaymentCardRequest, options?: RpcOptions): UnaryCall<GetPaymentCardRequest, PaymentCardResponse>;
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: CreateChannelPartner(CreateChannelPartnerRequest) returns (CBCustomer);
     */
    createChannelPartner(input: CreateChannelPartnerRequest, options?: RpcOptions): UnaryCall<CreateChannelPartnerRequest, CBCustomer>;
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: GetChannelPartner(GetChannelPartnerRequest) returns (CBCustomer);
     */
    getChannelPartner(input: GetChannelPartnerRequest, options?: RpcOptions): UnaryCall<GetChannelPartnerRequest, CBCustomer>;
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: DelinkChannelPartner(DelinkChannelPartnerRequest) returns (google.protobuf.Empty);
     */
    delinkChannelPartner(input: DelinkChannelPartnerRequest, options?: RpcOptions): UnaryCall<DelinkChannelPartnerRequest, Empty>;
    /**
     * GetSubscriptionCustomer return customer info from chargebee service
     *
     * @generated from protobuf rpc: GetSubscriptionCustomer(GetSubscriptionCustomerRequest) returns (CBCustomer);
     */
    getSubscriptionCustomer(input: GetSubscriptionCustomerRequest, options?: RpcOptions): UnaryCall<GetSubscriptionCustomerRequest, CBCustomer>;
    /**
     * @generated from protobuf rpc: ListCustomerSubscription(ListCustomerSubscriptionRequest) returns (ListCustomerSubscriptionResponse);
     */
    listCustomerSubscription(input: ListCustomerSubscriptionRequest, options?: RpcOptions): UnaryCall<ListCustomerSubscriptionRequest, ListCustomerSubscriptionResponse>;
}
/**
 * All operations related to Chargebee Subscriptions
 *
 * @generated from protobuf service SubscriptionService
 */
export class SubscriptionServiceClient implements ISubscriptionServiceClient, ServiceInfo {
    typeName = SubscriptionService.typeName;
    methods = SubscriptionService.methods;
    options = SubscriptionService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Create a ChargeBee subscription for existing customer
     *
     * @generated from protobuf rpc: CreateSubscription(CreateSubscriptionRequest) returns (Subscription);
     */
    createSubscription(input: CreateSubscriptionRequest, options?: RpcOptions): UnaryCall<CreateSubscriptionRequest, Subscription> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateSubscriptionRequest, Subscription>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve a subscription given a subscriptionId and customerId.
     *
     * @generated from protobuf rpc: GetSubscription(GetSubscriptionRequest) returns (Subscription);
     */
    getSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, Subscription> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSubscriptionRequest, Subscription>("unary", this._transport, method, opt, input);
    }
    /**
     * GetChargebeeSubscription Retrieve a subscription given a subscriptionId and customerId from chargebee portal
     *
     * @generated from protobuf rpc: GetChargebeeSubscription(GetSubscriptionRequest) returns (Subscription);
     */
    getChargebeeSubscription(input: GetSubscriptionRequest, options?: RpcOptions): UnaryCall<GetSubscriptionRequest, Subscription> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSubscriptionRequest, Subscription>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve a subscription given a customerId.
     *
     * @generated from protobuf rpc: GetCustomerSubscription(GetCustomerSubscriptionRequest) returns (Subscription);
     */
    getCustomerSubscription(input: GetCustomerSubscriptionRequest, options?: RpcOptions): UnaryCall<GetCustomerSubscriptionRequest, Subscription> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCustomerSubscriptionRequest, Subscription>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve a subscription given a customerId.
     *
     * @generated from protobuf rpc: UpdateChargebeeCustomer(UpdateChargebeeCustomerRequest) returns (CBCustomer);
     */
    updateChargebeeCustomer(input: UpdateChargebeeCustomerRequest, options?: RpcOptions): UnaryCall<UpdateChargebeeCustomerRequest, CBCustomer> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateChargebeeCustomerRequest, CBCustomer>("unary", this._transport, method, opt, input);
    }
    /**
     * List all subscriptions for a given customerId.
     *
     * @generated from protobuf rpc: ListSubscriptions(ListSubscriptionsRequest) returns (ListSubscriptionsResponse);
     */
    listSubscriptions(input: ListSubscriptionsRequest, options?: RpcOptions): UnaryCall<ListSubscriptionsRequest, ListSubscriptionsResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListSubscriptionsRequest, ListSubscriptionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Updates a subscription based on field mask given a customerId.
     *
     * @generated from protobuf rpc: UpdateSubscription(UpdateSubscriptionRequest) returns (Subscription);
     */
    updateSubscription(input: UpdateSubscriptionRequest, options?: RpcOptions): UnaryCall<UpdateSubscriptionRequest, Subscription> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSubscriptionRequest, Subscription>("unary", this._transport, method, opt, input);
    }
    /**
     * Deletes a subscription given a customerId and subscriptionId.
     *
     * @generated from protobuf rpc: DeleteSubscription(DeleteSubscriptionRequest) returns (google.protobuf.Empty);
     */
    deleteSubscription(input: DeleteSubscriptionRequest, options?: RpcOptions): UnaryCall<DeleteSubscriptionRequest, Empty> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteSubscriptionRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Delete Chargebee Customer given customerId.
     *
     * @generated from protobuf rpc: DeleteCustomer(DeleteCBCustomerRequest) returns (google.protobuf.Empty);
     */
    deleteCustomer(input: DeleteCBCustomerRequest, options?: RpcOptions): UnaryCall<DeleteCBCustomerRequest, Empty> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteCBCustomerRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * Create a trial subscription lasting 14 days.
     * Takes in customerID and billing details.
     *
     * @generated from protobuf rpc: CreateTrial(TrialRequest) returns (Subscription);
     */
    createTrial(input: TrialRequest, options?: RpcOptions): UnaryCall<TrialRequest, Subscription> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<TrialRequest, Subscription>("unary", this._transport, method, opt, input);
    }
    /**
     * Check if a customer has an active subscription subscribed to one of the
     * plans. Only checks for the Basic plan currently. Returns true if
     * subscription exists, otherwise false.
     *
     * @generated from protobuf rpc: CheckSubscriptionExists(CheckSubscriptionRequest) returns (CheckSubscriptionResponse);
     */
    checkSubscriptionExists(input: CheckSubscriptionRequest, options?: RpcOptions): UnaryCall<CheckSubscriptionRequest, CheckSubscriptionResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckSubscriptionRequest, CheckSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Licence room create new licence record for room
     *
     * @generated from protobuf rpc: LicenceEntity(EntityLicenceRequest) returns (EntityLicenceResponse);
     */
    licenceEntity(input: EntityLicenceRequest, options?: RpcOptions): UnaryCall<EntityLicenceRequest, EntityLicenceResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<EntityLicenceRequest, EntityLicenceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Unlicence room create new licence record for room
     *
     * @generated from protobuf rpc: UnlicenceEntity(EntityLicenceRequest) returns (EntityLicenceResponse);
     */
    unlicenceEntity(input: EntityLicenceRequest, options?: RpcOptions): UnaryCall<EntityLicenceRequest, EntityLicenceResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<EntityLicenceRequest, EntityLicenceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List of licence statistic
     *
     * @generated from protobuf rpc: LicencesList(ListLicenseRequest) returns (ListLicenseResponse);
     */
    licencesList(input: ListLicenseRequest, options?: RpcOptions): UnaryCall<ListLicenseRequest, ListLicenseResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListLicenseRequest, ListLicenseResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List of licence statistic
     *
     * @generated from protobuf rpc: GetSubscriptionStats(CheckSubscriptionRequest) returns (SubscriptionInfoResponse);
     */
    getSubscriptionStats(input: CheckSubscriptionRequest, options?: RpcOptions): UnaryCall<CheckSubscriptionRequest, SubscriptionInfoResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckSubscriptionRequest, SubscriptionInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List of licence statistic
     *
     * @generated from protobuf rpc: GetInvoicePDF(InvoiceRequest) returns (InvoicePDFResponse);
     */
    getInvoicePDF(input: InvoiceRequest, options?: RpcOptions): UnaryCall<InvoiceRequest, InvoicePDFResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<InvoiceRequest, InvoicePDFResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List of available license slots for entity licensing
     *
     * @generated from protobuf rpc: LicenseFreeSlots(LicenseFreeSlotsRequest) returns (LicenseFreeSlotsResponse);
     */
    licenseFreeSlots(input: LicenseFreeSlotsRequest, options?: RpcOptions): UnaryCall<LicenseFreeSlotsRequest, LicenseFreeSlotsResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<LicenseFreeSlotsRequest, LicenseFreeSlotsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * BulkLicensing create or update license status for many entities
     *
     * @generated from protobuf rpc: BulkLicensing(BulkLicensingRequest) returns (BulkLicensingResponse);
     */
    bulkLicensing(input: BulkLicensingRequest, options?: RpcOptions): UnaryCall<BulkLicensingRequest, BulkLicensingResponse> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<BulkLicensingRequest, BulkLicensingResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreatePaymentCard create new payment card resource
     *
     * @generated from protobuf rpc: CreatePaymentCard(CreatePaymentCardRequest) returns (PaymentCardResponse);
     */
    createPaymentCard(input: CreatePaymentCardRequest, options?: RpcOptions): UnaryCall<CreatePaymentCardRequest, PaymentCardResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreatePaymentCardRequest, PaymentCardResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: UpdatePaymentCard(CreatePaymentCardRequest) returns (PaymentCardResponse);
     */
    updatePaymentCard(input: CreatePaymentCardRequest, options?: RpcOptions): UnaryCall<CreatePaymentCardRequest, PaymentCardResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreatePaymentCardRequest, PaymentCardResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: GetPaymentCard(GetPaymentCardRequest) returns (PaymentCardResponse);
     */
    getPaymentCard(input: GetPaymentCardRequest, options?: RpcOptions): UnaryCall<GetPaymentCardRequest, PaymentCardResponse> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPaymentCardRequest, PaymentCardResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: CreateChannelPartner(CreateChannelPartnerRequest) returns (CBCustomer);
     */
    createChannelPartner(input: CreateChannelPartnerRequest, options?: RpcOptions): UnaryCall<CreateChannelPartnerRequest, CBCustomer> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateChannelPartnerRequest, CBCustomer>("unary", this._transport, method, opt, input);
    }
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: GetChannelPartner(GetChannelPartnerRequest) returns (CBCustomer);
     */
    getChannelPartner(input: GetChannelPartnerRequest, options?: RpcOptions): UnaryCall<GetChannelPartnerRequest, CBCustomer> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetChannelPartnerRequest, CBCustomer>("unary", this._transport, method, opt, input);
    }
    /**
     * CreatePaymentCard update payment card resource
     *
     * @generated from protobuf rpc: DelinkChannelPartner(DelinkChannelPartnerRequest) returns (google.protobuf.Empty);
     */
    delinkChannelPartner(input: DelinkChannelPartnerRequest, options?: RpcOptions): UnaryCall<DelinkChannelPartnerRequest, Empty> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<DelinkChannelPartnerRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * GetSubscriptionCustomer return customer info from chargebee service
     *
     * @generated from protobuf rpc: GetSubscriptionCustomer(GetSubscriptionCustomerRequest) returns (CBCustomer);
     */
    getSubscriptionCustomer(input: GetSubscriptionCustomerRequest, options?: RpcOptions): UnaryCall<GetSubscriptionCustomerRequest, CBCustomer> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSubscriptionCustomerRequest, CBCustomer>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListCustomerSubscription(ListCustomerSubscriptionRequest) returns (ListCustomerSubscriptionResponse);
     */
    listCustomerSubscription(input: ListCustomerSubscriptionRequest, options?: RpcOptions): UnaryCall<ListCustomerSubscriptionRequest, ListCustomerSubscriptionResponse> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCustomerSubscriptionRequest, ListCustomerSubscriptionResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service ChargeBeeHostedService
 */
export interface IChargeBeeHostedServiceClient {
    /**
     * Create a portal session where the customer can manage subscriptions,
     * payment methods and billing history. Takes in a customerId and returns a
     * portal session object containing the uniquely generated url for the portal
     * session.
     *
     * @generated from protobuf rpc: CreatePortalSession(PortalSessionRequest) returns (PortalSession);
     */
    createPortalSession(input: PortalSessionRequest, options?: RpcOptions): UnaryCall<PortalSessionRequest, PortalSession>;
    /**
     * CreateHostedPage create chargebee hosted page object for manage existing subscription.
     * In response will be returned chargebee hosted page object
     *
     * @generated from protobuf rpc: CreateHostedPage(CreateHostedPageRequest) returns (HostedPage);
     */
    createHostedPage(input: CreateHostedPageRequest, options?: RpcOptions): UnaryCall<CreateHostedPageRequest, HostedPage>;
}
/**
 * @generated from protobuf service ChargeBeeHostedService
 */
export class ChargeBeeHostedServiceClient implements IChargeBeeHostedServiceClient, ServiceInfo {
    typeName = ChargeBeeHostedService.typeName;
    methods = ChargeBeeHostedService.methods;
    options = ChargeBeeHostedService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Create a portal session where the customer can manage subscriptions,
     * payment methods and billing history. Takes in a customerId and returns a
     * portal session object containing the uniquely generated url for the portal
     * session.
     *
     * @generated from protobuf rpc: CreatePortalSession(PortalSessionRequest) returns (PortalSession);
     */
    createPortalSession(input: PortalSessionRequest, options?: RpcOptions): UnaryCall<PortalSessionRequest, PortalSession> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<PortalSessionRequest, PortalSession>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateHostedPage create chargebee hosted page object for manage existing subscription.
     * In response will be returned chargebee hosted page object
     *
     * @generated from protobuf rpc: CreateHostedPage(CreateHostedPageRequest) returns (HostedPage);
     */
    createHostedPage(input: CreateHostedPageRequest, options?: RpcOptions): UnaryCall<CreateHostedPageRequest, HostedPage> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateHostedPageRequest, HostedPage>("unary", this._transport, method, opt, input);
    }
}
