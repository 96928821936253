import React from "react";
import { LoginMethod, Role } from "../../../api/grpc/account/account";
import { capitalizeString } from "../../../lib/helpers/capitalizeString";
import { Icon, IconDefinition } from "../../shared/Icon/Icon";

interface Props {
  isNotActive: boolean;
  role: Role;
  icon?: IconDefinition;
  loginMethod: LoginMethod;
}

export const PeopleRole = ({ isNotActive, role, icon, loginMethod }: Props) => {
  return (
    <div className="flex-a-center PeopleRole">
      <h4 className={isNotActive ? "inactive" : ""}>
        {capitalizeString(Role[role])}
      </h4>

      {icon && (
        <div className="PeopleRole__info">
          <div className="PeopleRole__info--text">
            {loginMethod === LoginMethod.GSUITE
              ? "SSO - Google sign in only"
              : "SSO - Microsoft sign in only"}
          </div>
          <Icon icon={icon} />
        </div>
      )}
    </div>
  );
};
