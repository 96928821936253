import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormGroup } from "reactstrap";
import { toast } from "react-toastify";

import { CustomerSettingsVariables } from "../InsightsCard/Insights/Insights";
import { Input } from "../../../shared/Input/Input";
import { WorkspaceCostInfo } from "./WorkspaceCostInfo/WorkspaceCostInfo";
import { OrganizationSettingsModal } from "../shared/OrganizationSettingsModal";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  cost?: number;
  currency?: string;
  handleCostPerHr: ({
    meetingSpaceCostPerHr,
    currency,
  }: CustomerSettingsVariables) => void;
  loading: boolean;
  error?: Error;
}

export const WorkspaceCostModal = ({
  isOpen,
  setIsOpen,
  cost,
  currency,
  handleCostPerHr,
  error,
  loading,
}: Props) => {
  const [costToChange, setCostToChange] = useState<number | undefined>(0);
  const [currencyToChange, setCurrencyToChange] = useState<string | undefined>(
    ""
  );

  const updateData = useCallback(() => {
    setCostToChange(cost);
    setCurrencyToChange(currency);
  }, [cost, currency]);

  useEffect(() => {
    updateData();
  }, [cost, currency]);

  return (
    <OrganizationSettingsModal
      title="Input workspace cost per work hour"
      modalClassName="WorkspaceCostModal"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      error={error}
      secondDescription={
        <>
          <span className="WorkHours__info pb-4">
            The following calculation example can help you.
          </span>

          <WorkspaceCostInfo />
        </>
      }
      handleSave={() => {
        if (costToChange && costToChange <= 0) {
          return toast.error("Invalid cost value.");
        }
        handleCostPerHr({
          meetingSpaceCostPerHr: costToChange,
          currency: currencyToChange,
        });
        setIsOpen(false);
        return;
      }}
      toggleModal={() => {
        setCostToChange(cost);
        setCurrencyToChange(currency);
        setIsOpen(false);
        return;
      }}
    >
      <FormGroup className="WorkingHoursForm__value w-100">
        <label className="label__main">
          Value - workspace cost per work hour
        </label>

        <Input
          type="number"
          name="cost"
          id="cost"
          value={costToChange}
          onChange={(e) => {
            setCostToChange(+e.target.value || undefined);
          }}
        />
      </FormGroup>

      <FormGroup className="WorkingHoursForm__currency position-relative w-100">
        <label className="label__main">Currency</label>

        <Input
          type="select"
          name="currency"
          id="currency"
          value={currencyToChange}
          onChange={(e) => setCurrencyToChange(e.target.value)}
        >
          <option>USD</option>
          {/* <option>EUR</option> */}
        </Input>

        <span className="WorkingHoursForm__currency--arrow"></span>
      </FormGroup>
    </OrganizationSettingsModal>
  );
};
