import { useQuery } from "@apollo/client";
import {
  GetWayfinderSelectResponse,
  GET_WAY_FINDERS_SELECT,
} from "../../api/graphql/wayfinder/wayfinder";
import { GetWorkplaceRequest } from "../../api/graphql/workplaces";

import { useAuthContext } from "../context/Auth/AuthContext";

export const useWayfindersQuery = () => {
  const { user } = useAuthContext();

  const { loading, error, data, refetch } = useQuery<
    GetWayfinderSelectResponse,
    GetWorkplaceRequest
  >(GET_WAY_FINDERS_SELECT, {
    variables: {
      id: user?.customerid || "",
    },
  });

  return { loading, error, data: data?.queryWayfinder || [], refetch };
};
