import React, { useState } from "react";
import {
  IPickedWorkspaces,
  ITemplatesContext,
  TemplateOption,
  TemplatesContext,
} from "./TemplatesContext";

interface Props {
  children: React.ReactNode;
}

export const TemplatesContextProvider = ({ children }: Props) => {
  const [activeProfile, setActiveProfile] = useState<
    TemplateOption | undefined
  >(undefined);
  const [pickedWorkspaces, setPickedWorkspaces] = useState<IPickedWorkspaces>({
    data: [],
    redirect: false,
  });

  const context: ITemplatesContext = {
    activeProfile,
    setActiveProfile,
    pickedWorkspaces,
    setPickedWorkspaces,
  };

  return (
    <TemplatesContext.Provider value={context}>
      {children}
    </TemplatesContext.Provider>
  );
};
