import React from "react";
import { NavLink } from "react-router-dom";

import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";

import { trimText } from "../../../../lib/helpers/trimText";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { WORKPLACES_ROOT_PATH } from "../../../../lib/routes/routes";
import { getWorkplaceName } from "../../helpers/getWorkplaceInformation";

import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../../shared/StructureTable/StructureTable";
import { LicenseSwitch } from "../../LicenseSwitch/LicenseSwitch";
import { DeleteButton } from "../../../shared/DeleteButton/DeleteButton";
import { EntityType } from "../../../../api/grpc/subscription/subscription";
import { WorkplacePrefixIcon } from "../WorkplacePrefixIcon/WorkplacePrefixIcon";
import { AddDeviceButton } from "../../AddWorkplace/AddWorkplaceButton/AddDeviceButton/AddDeviceButton";

interface Props {
  onClose: () => void;
  data: WorkplaceFields[];
}

export const WorkplaceExpandedWayFinder = (props: Props) => {
  const { data, onClose } = props;
  const { deleteWorkplace } = useWorkplaceRequests();

  const workplaceName = getWorkplaceName("Wayfinder", data.length);

  const columns: StructureTableColumn[] = [
    {
      key: "workplace_icon",
      header: workplaceName,
      onClick: () => onClose(),
    },
    {
      key: "workplace_name",
      header: "Name",
    },
    {
      key: "workplace_description",
      header: "Description",
    },
    {
      key: "workplace_device",
      header: "Device",
    },
    {
      key: "workplace_license",
      header: "License",
    },
    {
      key: "workplace_delete",
      header: "",
    },
  ];

  const rows: StructureTableRows[] = data.map((item) => {
    const devices =
      item.devices && item.devices.length > 0
        ? item.devices.map((item) => item.name)
        : [];

    return {
      workplace_icon: <WorkplacePrefixIcon icon="wayfinders-icon" />,
      workplace_name: (
        <NavLink to={`${WORKPLACES_ROOT_PATH}/wayfinder/${item.id}`}>
          <h6 className="mb-0" title={item.name}>
            {item.name}
          </h6>
        </NavLink>
      ),
      workplace_description: (
        <h6 className="mb-0" title={item.description || "-"}>
          {trimText(item.description || "-")}
        </h6>
      ),
      workplace_device:
        devices.length > 0 ? (
          <>
            {devices.map((item, index) => {
              return <span key={`${item}-${index}`}>{trimText(item)}</span>;
            })}
          </>
        ) : (
          <AddDeviceButton
            outline
            size="small"
            lineHeight="low"
            color="secondary"
            title="Set Device"
            parentId={item.id}
            parentType="Wayfinder"
          />
        ),
      workplace_license: (
        <>
          <LicenseSwitch
            id={item.id}
            type={EntityType.WAYFINDER}
            value={!!item.licensed}
          />
        </>
      ),
      workplace_delete: (
        <DeleteButton
          onClick={() => deleteWorkplace(item.id, item.__typename)}
        />
      ),
    };
  });

  return (
    <StructureTable rows={rows} columns={columns} noResultsPlaceholder={"--"} />
  );
};
