import gql from "graphql-tag";
import { DeviceWorkspaceType } from "../../../components/shared/Forms/DeviceForm/lib/useDeviceFormik";

import { ROOM_FIELDS_FRAGMENT } from "../rooms/rooms";
import { WAY_FINDER_FIELDS_FRAGMENT } from "../wayfinder/wayfinder";
import {
  WorkplaceFields,
  WorkplaceTenantFields,
  WorkplaceDefaultFields,
} from "../workplaces";

export interface HardwareInfo {
  brand: string;
  model: string;
  serialNumber: string;
  resultion: string;
  dpi: string;
  ip: string;
  mac: string;
  lastPing: number;
  firmwareVersion: string;
}

export interface SoftwareInfo {
  androidOS: string;
  app: string;
  version: string;
}

export interface DeviceFields {
  id: string;
  name: string;
  description: string;
  deviceID: string;
  room?: WorkplaceDefaultFields | undefined;
  wayFinder?: WorkplaceDefaultFields | undefined;
  typeOfWorkspace: DeviceWorkspaceType;
  isOnline: boolean;
  unassignedSince?: number;
  warnedUnassigned?: boolean;
  softwareInfo: SoftwareInfo | null;
  hardwareInfo: HardwareInfo | null;
  autoUpdate: boolean;
}

export interface GetDevicesRequestFields {
  and: Array<
    | {
        tenantId: {
          eq: string;
        };
        isOnline?: boolean;
        typeOfWorkspace?: {
          eq: string;
        };
      }
    | {
        or: Array<{
          has: string;
        }>;
      }
    | {
        and: Array<{
          not: {
            has: string;
          };
        }>;
      }
  >;
}

export interface GetAllDevicesRequest {
  filter: GetDevicesRequestFields;
}

export interface UpdateDeviceVariables extends WorkplaceTenantFields {
  id: string;
  name: string;
  description: string;
  unassignedSince?: number;
  warnedUnassigned?: boolean;
  tenantId: string;
  autoUpdate: boolean;
  remove?: {
    room?: {
      id: string;
    };
    wayFinder?: {
      id: string;
    };
  };
}

export interface UpdateDeviceRoomVariables extends WorkplaceTenantFields {
  roomId: string;
  oldRoomId?: string;
  unassignedSince?: number;
  warnedUnassigned?: boolean;
}

export interface UpdateDeviceWayfinderVariables extends WorkplaceTenantFields {
  wayFinderId: string;
  oldWayfinderId?: string;
  unassignedSince?: number;
  warnedUnassigned?: boolean;
}

export interface GetDevicesResponse {
  queryDevice: DeviceFields[];
}

export interface AddDeviceVariables extends WorkplaceTenantFields {
  room?: {
    id: string;
  };
  wayFinder?: {
    id: string;
  };
  name: string;
  description: string;
  tenantId: string;
  deviceId: string;
  typeOfWorkspace: DeviceWorkspaceType;
  isOnline: boolean;
  unassignedSince?: number;
  warnedUnassigned?: boolean;
  autoUpdate: boolean;
}

export interface DisconnectDeviceVariables {
  id: string;
  roomId?: string;
  wayFinderId?: string;
  unassignedSince?: number;
  warnedUnassigned?: boolean;
}

export interface AddDeviceResponse {
  addDevice: {
    device: WorkplaceFields[];
  };
}

export interface UpdateDeviceResponse {
  device: WorkplaceFields[];
}

export const DEVICE_SOFTWARE_INFO_FRAGMENT = gql`
  fragment SoftwareInfoFields on DeviceSoftware {
    androidOS
    app
    version
  }
`;

export const DEVICE_HARDWARE_INFO_FRAGMENT = gql`
  fragment HardwareInfoFields on DeviceHardware {
    brand
    model
    serialNumber
    resultion
    dpi
    ip
    mac
    lastPing
    firmwareVersion
  }
`;

export const DEVICE_FIELDS_FRAGMENTS = gql`
  fragment DeviceFields on Device {
    id
    name
    description
    tenantId
    deviceID
    tags
    isOnline
    typeOfWorkspace
    unassignedSince
    warnedUnassigned
    autoUpdate
    softwareInfo {
      ...SoftwareInfoFields
    }
    hardwareInfo {
      ...HardwareInfoFields
    }
    room {
      ...RoomFields
    }
    wayFinder {
      ...WayfinderFields
    }
  }
  ${ROOM_FIELDS_FRAGMENT}
  ${WAY_FINDER_FIELDS_FRAGMENT}
  ${DEVICE_SOFTWARE_INFO_FRAGMENT}
  ${DEVICE_HARDWARE_INFO_FRAGMENT}
`;

export const ADD_DEVICE = gql`
  mutation addDeviceMutation(
    $name: String
    $description: String
    $tags: [String]
    $tenantId: String!
    $deviceId: String!
    $typeOfWorkspace: String
    $isOnline: Boolean
    $unassignedSince: Int
    $warnedUnassigned: Boolean
    $room: RoomRef
    $wayFinder: WayfinderRef
    $autoUpdate: Boolean
  ) {
    addDevice(
      input: [
        {
          name: $name
          tags: $tags
          room: $room
          wayFinder: $wayFinder
          description: $description
          tenantId: $tenantId
          deviceID: $deviceId
          typeOfWorkspace: $typeOfWorkspace
          isOnline: $isOnline
          unassignedSince: $unassignedSince
          warnedUnassigned: $warnedUnassigned
          autoUpdate: $autoUpdate
        }
      ]
    ) {
      device {
        ...DeviceFields
      }
    }
  }
  ${DEVICE_FIELDS_FRAGMENTS}
`;

export const UNPAIR_DEVICE = gql`
  mutation unpairDevice($id: ID!, $tenantId: String) {
    deleteDevice(filter: { id: [$id], tenantId: { eq: $tenantId } }) {
      device {
        id
        name
        description
        tenantId
        deviceID
      }
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation updateDeviceMutation(
    $id: ID!
    $name: String
    $description: String
    $tenantId: String!
    $unassignedSince: Int
    $warnedUnassigned: Boolean
    $autoUpdate: Boolean
    $remove: DevicePatch
  ) {
    updateDevice(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          description: $description
          tenantId: $tenantId
          unassignedSince: $unassignedSince
          warnedUnassigned: $warnedUnassigned
          autoUpdate: $autoUpdate
        }
        remove: $remove
      }
    ) {
      device {
        ...DeviceFields
      }
    }
  }
  ${DEVICE_FIELDS_FRAGMENTS}
`;

export const UPDATE_EXISTING_DEVICE_ROOM = gql`
  mutation updateDeviceRoomMutation(
    $id: ID!
    $unassignedSince: Int
    $warnedUnassigned: Boolean
    $roomId: ID
    $tenantId: String!
  ) {
    updateDevice(
      input: {
        filter: { id: [$id] }
        set: {
          tenantId: $tenantId
          unassignedSince: $unassignedSince
          warnedUnassigned: $warnedUnassigned
          room: { id: $roomId }
        }
      }
    ) {
      device {
        ...DeviceFields
      }
    }
  }
  ${DEVICE_FIELDS_FRAGMENTS}
`;

export const UPDATE_EXISTING_DEVICE_WAY_FINDER = gql`
  mutation updateDeviceWayfinderMutation(
    $id: ID!
    $wayFinderId: ID
    $unassignedSince: Int
    $warnedUnassigned: Boolean
    $tenantId: String!
  ) {
    updateDevice(
      input: {
        filter: { id: [$id] }
        set: {
          tenantId: $tenantId
          unassignedSince: $unassignedSince
          warnedUnassigned: $warnedUnassigned
          wayFinder: { id: $wayFinderId }
        }
      }
    ) {
      device {
        ...DeviceFields
      }
    }
  }
  ${DEVICE_FIELDS_FRAGMENTS}
`;

export const GET_DEVICES = gql`
  query getDevicesQuery($filter: DeviceFilter) {
    queryDevice(filter: $filter) {
      ...DeviceFields
    }
  }
  ${DEVICE_FIELDS_FRAGMENTS}
`;

// export const GET_DEVICES = gql`
//   query getDevicesQuery($tenantId: String!, $deviceName: String) {
//     queryDevice(
//       filter: { tenantId: { eq: $tenantId }, name: { allofterms: $deviceName } }
//     ) {
//       ...DeviceFields
//     }
//   }
//   ${DEVICE_FIELDS_FRAGMENTS}
// `;

export const DISCONNECT_DEVICE = gql`
  mutation disconnectDevice(
    $id: ID!
    $unassignedSince: Int
    $warnedUnassigned: Boolean
    $roomId: ID
  ) {
    updateDevice(
      input: {
        filter: { id: [$id] }
        set: {
          unassignedSince: $unassignedSince
          warnedUnassigned: $warnedUnassigned
        }
        remove: { room: { id: $roomId } }
      }
    ) {
      device {
        id
        name
        unassignedSince
        warnedUnassigned
        description
        tenantId
        deviceID
      }
    }
  }
`;

export const DISCONNECT_DEVICE_WAY_FINDER = gql`
  mutation disconnectDevice(
    $id: ID!
    $unassignedSince: Int
    $warnedUnassigned: Boolean
    $wayFinderId: ID
  ) {
    updateDevice(
      input: {
        filter: { id: [$id] }
        set: {
          unassignedSince: $unassignedSince
          warnedUnassigned: $warnedUnassigned
        }
        remove: { wayFinder: { id: $wayFinderId } }
      }
    ) {
      device {
        id
        name
        unassignedSince
        warnedUnassigned
        description
        tenantId
        deviceID
      }
    }
  }
`;
