import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";
import { Icon } from "../../../shared/Icon/Icon";
import { LineDivider } from "../../../shared/LineDivider/LineDivider";
import { Button } from "../../../shared/Button/Button";

import myReservation from "../../../../assets/images/MyReservation.png";

export const GoToMyReservationModal = ({
  isOpen,
  toggleModal,
}: ExtendedProps) => {
  const { history } = useRouter();

  return (
    <Modal isOpen={isOpen} onToggle={toggleModal} className="BookingsModal">
      <ModalHeader>
        <span onClick={toggleModal} className="btn-close">
          <Icon icon="close-icon" />
        </span>
      </ModalHeader>

      <h5 className="mt-4 mb-3 text-uppercase">Go to "my reservations"</h5>

      <LineDivider />
      <div className="text-center w-90 mt-1">
        <p className="mb-0 text-pro text-center max-w-none-important text-16">
          It looks like you're trying to edit a room reservation you created.
        </p>
        <p className="mb-0 text-pro text-center max-w-none-important text-16">
          To make changes, please go to "My Reservations."
        </p>
      </div>

      <img src={myReservation} alt="My Reservations" className="w-80" />

      <ModalFooter className="modal-footer-row p-0 w-90 gap-2 mt-3">
        <Button
          size="small"
          color="outline-secondary"
          title={"Back"}
          onClick={toggleModal}
          className="m-0 max-w-none flex-1"
        />

        <Button
          size="small"
          color="business"
          title="Go there"
          onClick={() => history.push("/bookings/reservations")}
          className="m-0 max-w-none flex-1"
        />
      </ModalFooter>
    </Modal>
  );
};
