import React, { useMemo, useRef, useState } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useDebounce } from "../../../lib/hooks/useDebounce";
import { useQuery } from "@apollo/client";

import { tooltips } from "../../../lib/utils/tooltips";

import { ApolloErrorGuard } from "../../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../../components/shared/HandleLoadingState/HandleLoadingState";

import { DevicesList } from "../../../components/Devices/DevicesList/DevicesList";
import { ResourceFilter } from "../../../components/shared/ResourceFilter/ResourceFilter";
import { DefaultWorkplacesLoader } from "../../../components/Workplaces/DefaultWorkplaces/DefaultWorkplacesLoader/DefaultWorkplacesLoader";
import { PageHeader } from "../../../components/shared/PageHeader/PageHeader";
import { AddDeviceButton } from "../../../components/Workplaces/AddWorkplace/AddWorkplaceButton/AddDeviceButton/AddDeviceButton";
import { DeviceFilters } from "../../../components/Devices/DevicesList/DeviceFilters/DeviceFilters";
import { CenteredBox } from "../../../components/shared/CenteredBox/CenteredBox";
import {
  GetAllDevicesRequest,
  GetDevicesResponse,
  GET_DEVICES,
} from "../../../api/graphql/devices/devices";

import "./Devices.scss";

export interface IDeviceFilters {
  assigned: "assigned" | "unassigned" | undefined;
  app: "room" | "wayFinder" | undefined;
  status: "online" | "offline" | undefined;
  autoUpdate: "auto" | "manual" | undefined;
}

export const Devices = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce<string>(searchValue, 500);
  const [deviceFilters, setDeviceFilters] = useState<IDeviceFilters>({
    assigned: undefined,
    app: undefined,
    status: undefined,
    autoUpdate: undefined,
  });

  const { user } = useAuthContext();

  const deviceVariables = {
    and: [
      {
        tenantId: {
          eq: user?.customerid || "",
        },
        isOnline: !!deviceFilters.status?.length
          ? deviceFilters.status === "online"
            ? true
            : false
          : undefined,
        autoUpdate: !!deviceFilters.autoUpdate?.length
          ? deviceFilters.autoUpdate === "auto"
            ? true
            : false
          : undefined,
        typeOfWorkspace:
          deviceFilters.app === undefined
            ? undefined
            : {
                eq: deviceFilters.app,
              },
      },
    ],
  };

  const { loading, error, data, refetch } = useQuery<
    GetDevicesResponse,
    GetAllDevicesRequest
  >(GET_DEVICES, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter:
        deviceFilters.assigned !== undefined
          ? {
              and: [
                ...deviceVariables.and,
                deviceFilters.assigned === "assigned"
                  ? {
                      or: [
                        {
                          has: "room",
                        },
                        {
                          has: "wayFinder",
                        },
                      ],
                    }
                  : {
                      and: [
                        {
                          not: { has: "room" },
                        },
                        {
                          not: { has: "wayFinder" },
                        },
                      ],
                    },
              ],
            }
          : deviceVariables,
    },
  });

  let devicesData = useMemo(() => {
    if (!data) {
      return [];
    }

    if (!!!debouncedValue.length) {
      return data?.queryDevice;
    }

    return data.queryDevice.filter((device) =>
      device.name.toLowerCase().includes(debouncedValue.toLowerCase())
    );
  }, [debouncedValue, data]);

  const activeFilters: string[] = useMemo(() => {
    return Object.values(deviceFilters).filter(
      (filter) => filter !== undefined
    );
  }, [deviceFilters]);

  return (
    <div className="Devices default-page">
      <PageHeader title="Devices" message={tooltips.devices.heading}>
        <AddDeviceButton
          adjustable
          size="medium"
          icon="plus-sign"
          className="AddDeviceButton__icon"
          refetch={refetch}
        />
      </PageHeader>
      <div ref={ref} className="Devices__content">
        <ResourceFilter
          searchValue={searchValue}
          onChangeSearch={(e) => setSearchValue(e.target.value)}
          numberOfFilters={
            !!activeFilters.length ? activeFilters.length : undefined
          }
          disabledFilters={
            !!!data?.queryDevice.length && !!!activeFilters.length
          }
          clearSearchValue={() => setSearchValue("")}
        >
          <DeviceFilters
            filters={deviceFilters}
            onChange={(updatedFilters) => {
              setDeviceFilters(updatedFilters);
            }}
          />
        </ResourceFilter>

        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <DefaultWorkplacesLoader width={ref.current?.offsetWidth || 1200} />
          }
        >
          <ApolloErrorGuard
            error={error}
            errorComponent={<p>Error fetching data...</p>}
          >
            {(!!debouncedValue.length || !!activeFilters.length) &&
            !!!devicesData.length ? (
              <CenteredBox>No device found</CenteredBox>
            ) : (
              <DevicesList
                data={devicesData}
                onListChange={() => {
                  refetch();
                }}
              />
            )}
          </ApolloErrorGuard>
        </HandleLoadingState>
      </div>
    </div>
  );
};
