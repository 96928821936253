import React, { useEffect } from "react";
import {
  format,
  differenceInMinutes,
  parseISO,
  setHours,
  setMinutes,
  differenceInDays,
  startOfDay,
} from "date-fns";
import DatePicker from "react-datepicker";

import { useFormatReservationDate } from "../../../lib/datePickerHelper";
import { useBookingsMapContext } from "../../Context/BookingsMapContext";
import { utcToZonedTime } from "date-fns-tz";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { DaySelectorInput } from "../../../../shared/Forms/DaySelectorInput/DaySelectorInput";
import { timePickerProps } from "../../../helpers/timePickerProps";

import "react-datepicker/dist/react-datepicker.css";

export const SelectTimeModal = () => {
  const { time, handleTime, handleDay, day, dateAndTime } =
    useBookingsMapContext();
  const { timeZone } = useTimeZoneContext();

  const filterPassedTime = (time: Date) => {
    if (
      differenceInDays(
        startOfDay(parseISO(day)),
        startOfDay(utcToZonedTime(new Date(), timeZone))
      ) > 0
    ) {
      return true;
    }
    const currentDate = utcToZonedTime(new Date(), timeZone);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    handleDay(format(utcToZonedTime(new Date(day), timeZone), "yyyy-MM-dd"));

    if (
      new Date(dateAndTime) > new Date() ||
      differenceInMinutes(new Date(dateAndTime), new Date()) === -0
    ) {
      return;
    }

    handleTime(utcToZonedTime(new Date(), timeZone));
  }, [timeZone]);

  return (
    <div className="SelectTimeModal">
      <h6>Select time for map view</h6>
      <DaySelectorInput
        onChange={(value) => {
          if (!value) {
            return;
          }
          handleDay(value.value);
        }}
        value={{
          label: useFormatReservationDate(parseISO(day)),
          value: day,
        }}
      />

      <DatePicker
        {...timePickerProps}
        selected={time}
        onChange={(date) => {
          if (!date) {
            return;
          }
          handleTime(date);
        }}
        className="SearchWorkplaceForm__timePicker"
        filterTime={filterPassedTime}
        injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
      />
    </div>
  );
};
