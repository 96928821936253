import React from "react";
import { toast } from "react-toastify";
import { compareDesc, parseISO } from "date-fns";

import { useSubscriptionRequests } from "../../../api/grpc/subscription/useSubscriptionRequests";

import {
  AnalyticsBillingStat,
  AnalyticsInvoices,
} from "../../../api/grpc/analytics/analytics";
import { Currencies } from "../../../lib/helpers/getCurrencySymbol";
import { tooltips } from "../../../lib/utils/tooltips";
import {
  HomeCardList,
  HomeDetailsCard,
} from "../../shared/HomeDetailsCard/HomeDetailsCard";
import { totalBill } from "../../../lib/helpers/totalBill";

interface Props {
  data?: AnalyticsBillingStat;
  status?: string;
}

export const BillingBox = ({ data, status }: Props) => {
  const { getInvoicePDF } = useSubscriptionRequests();

  if (!data) {
    return (
      <HomeDetailsCard
        list={[]}
        bottomDiff
        link="/settings/billing/subscriptions"
        title="Billing Status"
        listPlaceholder="No records found!"
      />
    );
  }

  const invoices: HomeCardList[] = mapInvoices(data.invoices);

  const handleRowClick = async (invoice: string) => {
    try {
      const {
        response: { invoiceUrl },
      } = await getInvoicePDF(invoice);

      window.open(invoiceUrl, "_blank", "noopener");
    } catch (error: any) {
      console.error(error.toString());
      toast.error(
        "Invoice couldn't be downloaded, please reload and try again!"
      );
    }
  };

  return (
    <HomeDetailsCard
      total={
        status === "in_trial"
          ? "In trial"
          : totalBill(data.currency as Currencies, data.chargeAmount)
      }
      list={invoices}
      title="Billing Status"
      link="/settings/billing/subscriptions"
      onRowClick={handleRowClick}
      listPlaceholder="No invoices found!"
      tooltip={tooltips.dashboard.billing}
      subTitle={`Upcoming charges (${data.upcomingCharge})`}
    />
  );
};

export const mapInvoices = (invoices: AnalyticsInvoices[]) =>
  [...invoices]
    .sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)))
    .map((item) => {
      return {
        rowValue: item.invoiceID,
        label: (
          <h5>
            {item.planName}
            <br />
            {totalBill(item.currency as Currencies, item.chargeAmount)}{" "}
            {item.date}
          </h5>
        ),
        value: <span>Download</span>,
      };
    });
