import React, { PropsWithChildren } from "react";
import { DeskInsightsOverviewResponse } from "../../../../api/grpc/insights/insights";
import { tooltips } from "../../../../lib/utils/tooltips";
import { ChartCard } from "../../shared/ChartCard/ChartCard";
import { NoShowsPieChart } from "../../shared/Charts/PieChart/NoShowsPieChart";
import { calculatePercentage } from "../../shared/lib/calculatePercentageOfTwoNumber";
import { MeetingsAverageRow } from "../../shared/MeetingsAverageRow/MeetingsAverageRow";

export const DeskOverview = ({
  data,
}: PropsWithChildren<{ data?: DeskInsightsOverviewResponse }>) => {
  return (
    <div className="WorkspaceValueInsights__statistics WorkspaceValueInsights__statistics--desks WorkspaceValueInsights__statistics--main">
      <ChartCard className="d-flex justify-content-between ChartCard__adjustable">
        <MeetingsAverageRow
          title="Active"
          value={data?.activeDesks || 0}
          description="Total nr of desks in service"
          message={tooltips.insights.desks.active}
          cardDescription={
            data?.isAllDesksIsActive
              ? "All desks are active"
              : "There are inactive desks"
          }
          cardIcon={data?.isAllDesksIsActive ? "active-green" : "inactive-red"}
        />

        <div className="divider"></div>

        <MeetingsAverageRow
          title="Assigned | Blocked"
          value={data?.assignedDesks + " | " + data?.blockedDesks}
          description="Desks with assignee | Blocked"
          className="flex-align-start"
        />

        <div className="divider"></div>

        <MeetingsAverageRow
          title="Unused"
          value={data?.unusedDesks || 0}
          description="Desks were never reserved"
          className="flex-align-start"
        />
      </ChartCard>

      <ChartCard className="d-flex justify-content-between ChartCard__adjustable">
        <MeetingsAverageRow
          title="Reservations"
          value={data?.totalReservations || 0}
          description="Total nr of desk reservations"
          percentage={
            data?.prevPeriodIncluded
              ? calculatePercentage(
                  data?.prevPeriodTotalReservations || 0,
                  data?.totalReservations || 0
                )
              : undefined
          }
        />

        <div className="divider"></div>

        <MeetingsAverageRow
          title="Avg. Daily"
          value={Math.round(data?.avgDaily || 0)}
          description="Avg daily desk reservations"
          percentage={
            data?.prevPeriodIncluded
              ? calculatePercentage(
                  data?.prevPeriodAvgDaily || 0,
                  data?.avgDaily || 0
                )
              : undefined
          }
        />

        <div className="divider"></div>

        <MeetingsAverageRow
          title="Avg. Duration"
          value={Math.round(data?.avgDuration || 0)}
          description="Minutes per desk reservation"
          percentage={
            data?.prevPeriodIncluded
              ? calculatePercentage(
                  data?.prevPeriodAvgDuration || 0,
                  data?.avgDuration || 0
                )
              : undefined
          }
        />
      </ChartCard>
      <ChartCard className="flex-a-center justify-content-between">
        <MeetingsAverageRow
          title="No-Shows"
          titleDescription="Check-in expired"
          message={tooltips.insights.desks.noShows}
          value={Math.round(data?.noShowsMinutes || 0)}
          description="Hours accumulated"
          className="align-self-start"
        />

        <NoShowsPieChart
          value={data?.noShowsPercentage || 0}
          type="reservations"
        />
      </ChartCard>
    </div>
  );
};
