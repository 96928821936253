import { AccountFields } from "./useAccountFormik";

const excludedFields = ["currentPassword", "confirmPassword"];

export const getUpdatedFields = (
  initialValues: AccountFields,
  newValues: AccountFields,
  includePassword: boolean
) => {
  return Object.keys(initialValues).filter((item) => {
    const key = item as keyof AccountFields;

    // currentPassword & confirmPassword don't exist on Account service, so ignore these as fields.
    if (excludedFields.includes(item)) {
      return false;
    }

    // Only include the password field, if it was updated on Firebase first.
    if (key === "newPassword") {
      return includePassword;
    }

    return initialValues[key] !== newValues[key];
  });
};
