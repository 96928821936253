import React from "react";

import { CalendarType } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { tooltips } from "../../../../lib/utils/tooltips";
import { EWSProviderInput } from "../EWSProviderInput/EWSProviderInput";
import { RoomFormik } from "../../../shared/Forms/RoomForm/lib/useRoomFormik";
import { CalendarEmailSelect } from "../../CalendarEmailSelect/CalendarEmailSelect";
import { Providers } from "../../../../lib/context/Calendar/CalendarContextProvider";

interface Props {
  formik: RoomFormik;
  providers: Providers[];
  roomId?: string;
}

export const CalendarEmail = (props: Props) => {
  const { formik, providers, roomId } = props;

  const isEWSProvider = providers.find(
    (item) =>
      item.iD === formik.values.calendarProviderId &&
      item.calType === CalendarType.EWS
  );

  const activeProvider = providers.find(
    (item) => item.iD === formik.values.calendarProviderId
  );

  return (
    <>
      {!!isEWSProvider ? (
        <EWSProviderInput
          onChange={formik.handleChange}
          value={formik.values.calendarId}
          error={formik.errors.calendarId}
          calendarProviderId={formik.values.calendarProviderId}
          invalid={!!formik.errors.calendarId && formik.touched.calendarId}
        />
      ) : (
        <CalendarEmailSelect
          formik={formik}
          provider={formik.values.calendarProviderId}
          message={tooltips.rooms.settings.calendarEmail}
          roomId={roomId}
          activeProvider={activeProvider}
        />
      )}
    </>
  );
};
