import React, { Dispatch, SetStateAction, useState } from "react";
import cs from "classnames";

import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { getWorkplaceIcon } from "../../../Workplaces/helpers/getWorkplaceInformation";
import { getWorkplaceChildren } from "../../../Workplaces/helpers/getWorkplaceChildren";
import { NavLink } from "react-router-dom";
import { Icon } from "../../../shared/Icon/Icon";
import { FloorChildrenType } from "../../../Floors/FloorChildrenList/FloorChildrenExpandedList";
import { InsightsLocation } from "../../Context/InsightsContext";

interface Props {
  item: WorkplaceFields;
  toggleParent: (value: boolean) => void;
  onItemClick: Dispatch<SetStateAction<InsightsLocation>>;
  toggleSidebar: () => void;
  filterWorkplace: FloorChildrenType;
}

export const InsightsWorkplacesTreeLevel = ({
  item,
  toggleParent,
  onItemClick,
  filterWorkplace,
  toggleSidebar,
}: Props) => {
  const { pathname } = useRouter();

  const isActive = pathname.includes(item.id);

  const [toggle, setToggle] = useState(() => isActive);

  const icon = getWorkplaceIcon(item.__typename);
  const workplaceChildren = getWorkplaceChildren(item);
  const hasChildren = workplaceChildren.some((item) => item && item.length > 0);

  const classes = cs("WorkplacesTreeLevel", {
    "WorkplacesTreeLevel--open": toggle,
    "WorkplacesTreeLevel--active": isActive,
    "WorkplacesTreeLevel--space": !hasChildren,
  });

  const subLevelClasses = cs("WorkplacesTreeLevel__sub-level", {
    "WorkplacesTreeLevel__sub-level--open": toggle,
  });

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  return (
    <>
      <div className={classes}>
        <div className="WorkplacesTreeLevel__wrapper">
          {hasChildren && (
            <span
              onClick={handleToggle}
              className="WorkplacesTreeLevel__toggle"
            ></span>
          )}
          <NavLink
            className="WorkplacesTreeLevel__link"
            onClick={() => {
              onItemClick({
                locationId: item.id,
                locationName: item.name,
                locationType: item.__typename,
              });
              toggleSidebar();
            }}
            to="#"
          >
            <div className="WorkplacesTreeLevel__icon">
              <Icon icon={icon} />
            </div>
            <h3 className="WorkplacesTreeLevel__name">{item.name}</h3>
          </NavLink>
        </div>
      </div>
      {hasChildren && (
        <div className={subLevelClasses}>
          {workplaceChildren.map((child) => {
            return child
              ?.filter(
                (i: WorkplaceFields) =>
                  i.__typename !== filterWorkplace &&
                  i.__typename !== "Wayfinder"
              )
              .map((workplace: WorkplaceFields) => (
                <InsightsWorkplacesTreeLevel
                  item={workplace}
                  key={`${workplace.id}-tree-level`}
                  toggleParent={(value) => {
                    setToggle(value);
                    toggleParent(value);
                  }}
                  onItemClick={onItemClick}
                  filterWorkplace={filterWorkplace}
                  toggleSidebar={toggleSidebar}
                />
              ));
          })}
        </div>
      )}
    </>
  );
};
