import React, { useState } from "react";
import { toast } from "react-toastify";
import ModalSidebar from "../../Modals/ModalSidebar/ModalSidebar";
import { useTeamFormik } from "../../shared/Forms/TeamForm/lib/useTeamFormik";
import { TeamForm } from "../../shared/Forms/TeamForm/TeamForm";

interface Props {
  parentId?: string;
  isOpen: boolean;
  toggle: () => void;
}

export const AddTeamModal = (props: Props) => {
  const { isOpen, toggle } = props;
  const [loading, setLoading] = useState(false);

  const formik = useTeamFormik({
    onSubmit: async (values) => {
      try {
        console.log(values);
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Team couldn't be added, please reload and try again!"
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add New Team"
      onSubmit={formik.handleSubmit}
    >
      <TeamForm formik={formik} />
    </ModalSidebar>
  );
};
