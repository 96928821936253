import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useFloorFormik } from "../../../shared/Forms/FloorForm/lib/useFloorFormik";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";

import { SubRouteProps } from "../../../../lib/routes/routes";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import { FloorForm } from "../../../shared/Forms/FloorForm/FloorForm";
import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import {
  FloorRequestVariables,
  FLOOR_FIELDS_FRAGMENT,
} from "../../../../api/graphql/floors/floors";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { EditSettings } from "../EditSettings/EditSettings";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";
import { tooltips } from "../../../../lib/utils/tooltips";
import { EditViewsTabWorkspaces } from "../EditViewsTabWorkspaces/EditViewsTabWorkspaces";

export const EditFloor = (props: EditWorkplaceProps) => {
  const { id } = props;
  const router = useRouter();
  const client = useApolloClient();
  const { customer } = useCustomerContext();
  const { updateFloor } = useWorkplaceRequests();
  const [floorData, setFloorData] = useState<WorkplaceFields | null>(null);

  // TO DO: Move on how the data are loaded to a hook useWorkplaceData
  const updateData = useCallback(() => {
    setFloorData(
      client.readFragment({
        id: `Floor:${id}`,
        fragmentName: "FloorFields",
        fragment: FLOOR_FIELDS_FRAGMENT,
      })
    );
  }, [id]);

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useFloorFormik({
    initialValues: {
      name: floorData?.name || "",
      description: floorData?.description || "",
      tags: floorData?.tags || [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const removedTags =
          floorData?.tags.filter((item) => !values.tags.includes(item)) || [];

        const variables: FloorRequestVariables = {
          id: props.id,
          name: values.name,
          tags: values.tags,
          description: values.description,
          tenantId: customer.tenantId,
          map: null,
          remove: {
            tags: removedTags,
          },
        };

        await updateFloor(variables);

        toast.success("Floor edited successfully!");
      } catch (error: any) {
        toast.error(error?.message ?? "Error updating the floor!");
        console.error(error);
      }
    },
  });

  const rows: WorkplaceListData[] = [
    {
      data: floorData?.zones,
      type: "Zone",
    },
    {
      data: floorData?.rooms,
      type: "Room",
    },
    {
      data: floorData?.desks,
      type: "Desk",
    },
    {
      data: floorData?.wayfinders,
      type: "Wayfinder",
    },
  ];

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "View",
      to: router.match.url,
      id: "view-page-floor",
      render: () => <EditViewsTabWorkspaces type="Floor" id={id} rows={rows} />,
    },
    {
      exact: true,
      label: "Settings",
      id: "settings-page-floor",
      to: `${router.match.url}/settings`,
      render: () => (
        <EditSettings
          title="General settings"
          // hasChanges={formik.dirty}
          onSubmit={formik.handleSubmit}
        >
          <FloorForm formik={formik} />
        </EditSettings>
      ),
    },
  ];

  return (
    <>
      <EditWorkplaceWrapper
        type="Floor"
        id={props.id}
        routes={routes}
        data={floorData || undefined}
        message={tooltips.workplaces.floor}
      />
    </>
  );
};
