import React, { PropsWithChildren, useState } from "react";
import {
  AllReservationContext,
  IAllReservationContext,
} from "./AllReservationContext";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { FavoriteWorkplace } from "../../../../../api/grpc/account/account";

export const AllReservationContextProvider = ({
  children,
}: PropsWithChildren<{
  children: React.ReactNode;
}>) => {
  const { user } = useAuthContext();

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [eventEndTime, setEventEndTime] = useState<Date | undefined>(undefined);

  const [openWorkspacePicker, setOpenWorkspacePicker] = useState(false);
  const [pickedWorkspaces, setPickedWorkspaces] = useState<FavoriteWorkplace[]>(
    user?.favoriteWorkplaces || []
  );

  const context: IAllReservationContext = {
    startDate,
    setStartDate,
    openWorkspacePicker,
    setOpenWorkspacePicker,
    pickedWorkspaces,
    setPickedWorkspaces,
    eventEndTime,
    setEventEndTime,
  };

  return (
    <AllReservationContext.Provider value={context}>
      {children}
    </AllReservationContext.Provider>
  );
};
