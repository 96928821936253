import { toast } from "react-toastify";
import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { Event } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as RoomEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import {
  DeleteMeetingRequest,
  UpdateMeetingRequest,
} from "../../../../api/grpc/booking/booking";
import { INFO_STRINGS, SUCCESS_STRINGS } from "../../../../lib/utils/constants";

export const useReservationHelpers = () => {
  const { deleteDeskBooking, updateDeskBooking } = useDeskBookingRequest();
  const {
    updateRoomBookings,
    deleteRoomBooking,
    updateMeetingByCoordinator,
    deleteMeetingByCoordinator,
  } = useBookingRequest();
  const { pathname } = useRouter();

  const isAllReservation = pathname.includes("all-reservations");

  const deleteDeskReservation = async ({
    deskID,
    eventID,
    accountID,
    refetch,
  }: {
    deskID: string;
    eventID: string;
    accountID: string;
    refetch: () => void;
  }) => {
    try {
      await deleteDeskBooking({
        customerID: "",
        accountID: accountID,
        deskID: deskID,
        eventID: eventID,
      });

      toast.success(SUCCESS_STRINGS.reservationDeleted);

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Deletion of reservation failed, please reload and try again!"
      );
    }
  };

  const updateDeskReservation = async (
    deskID: string,
    eventID: string,
    refetch: () => void,
    event: Event
  ) => {
    try {
      await updateDeskBooking({
        customerID: "",
        accountID: "",
        deskID: deskID,
        eventID: eventID,
        event: event,
      });

      toast.success(SUCCESS_STRINGS.reservationUpdated);

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Update of reservation failed, please reload and try again!"
      );
    }
  };

  const updateRoomBooking = async (
    calendarId: string,
    calendarproviderId: string,
    meeting: RoomEvent,
    refetch: () => void,
    mode2Organizer?: boolean
  ) => {
    try {
      const variables: UpdateMeetingRequest = {
        calendarId: calendarId,
        calendarproviderId: calendarproviderId,
        customerId: "",
        meeting: meeting,
        activateCheckOut: false,
      };

      let externalRoom;

      if (mode2Organizer || !isAllReservation) {
        const { response } = await updateRoomBookings(variables);
        externalRoom = response.isExternalBooking;
      } else {
        const { response } = await updateMeetingByCoordinator(variables);
        externalRoom = response.isExternalBooking;
      }

      if (externalRoom) {
        toast.info(INFO_STRINGS.externalRoom);
      } else {
        toast.success(SUCCESS_STRINGS.reservationUpdated);
      }

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Update of reservation failed, please reload and try again!"
      );
    }
  };

  const deleteRoomReservation = async (
    calendarId: string,
    calendarproviderId: string,
    eventId: any,
    refetch: () => void,
    systemDelete: boolean,
    organizerEmail: string,
    mode2Organizer?: boolean
  ) => {
    try {
      const variables: DeleteMeetingRequest = {
        customerId: "",
        calendarId: calendarId,
        calendarproviderId: calendarproviderId,
        metaData: eventId,
        systemDelete: systemDelete,
        organizerEmail: organizerEmail,
      };

      let externalRoom;

      if (mode2Organizer || !isAllReservation) {
        const { response } = await deleteRoomBooking(variables);
        externalRoom = response.isExternalBooking;
      } else {
        const { response } = await deleteMeetingByCoordinator(variables);
        externalRoom = response.isExternalBooking;
      }

      if (externalRoom) {
        toast.info(INFO_STRINGS.externalRoom);
      } else {
        toast.success(SUCCESS_STRINGS.reservationDeleted);
      }

      refetch();
    } catch (error: any) {
      toast.error(
        error?.message ||
          "Deletion of reservation failed, please reload and try again!"
      );
    }
  };

  return {
    deleteDeskReservation,
    updateDeskReservation,
    updateRoomBooking,
    deleteRoomReservation,
  };
};
