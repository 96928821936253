import React, { Dispatch, SetStateAction } from "react";

export type dateRangeType = "7" | "30" | "custom" | null;

export interface IDateRangeContext {
  initialDateRange: dateRangeType;
  confirmedRange: dateRangeType;
  setInitialDateRange: Dispatch<SetStateAction<dateRangeType>>;
  setConfirmedRange: Dispatch<SetStateAction<dateRangeType>>;
}

const values: IDateRangeContext = {
  initialDateRange: null,
  confirmedRange: "7",
  setConfirmedRange: () => {},
  setInitialDateRange: () => {},
};

export const DateRangeContext = React.createContext(values);

export const useDateRangeContext = () => {
  const context = React.useContext(DateRangeContext);

  if (!context) {
    throw new Error(
      "useDateRangeContext must be used inside of DateRangeContext"
    );
  }

  return context;
};
