import React, { useState } from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useSidebarContext } from "../../../../lib/context/Sidebar/SidebarContext";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useBookingsMapContext } from "../Context/BookingsMapContext";
import { tooltips } from "../../../../lib/utils/tooltips";

import { FloorMapResponse } from "../../../../api/grpc/workplaces/workplaces";
import { FloorPickerButton } from "../../../Floors/FloorPicker/FloorPickerButton";
import { Button } from "../../../shared/Button/Button";
import { PageHeader } from "../../../shared/PageHeader/PageHeader";
import { formatTitleDate } from "../helpers/formatDateTitleName";
import { BookingsMapModal } from "./BookingsMapModal/BookingsMapModal";

export type BookingsMapModalTypes = "people" | "time";

interface Props {
  title: string;
  data?: FloorMapResponse;
}

export const BookingsMapHeader = ({ title, data }: Props) => {
  const { togglePageSidebar } = useSidebarContext();
  const { isOpen, toggleModal } = useToggleModal();
  const [modalType, setModalType] = useState<BookingsMapModalTypes>("time");
  const { dateAndTime, searchedAttendees } = useBookingsMapContext();
  const { timeZone } = useTimeZoneContext();
  const handleOpenModal = (type: BookingsMapModalTypes) => () => {
    setModalType(type);
    toggleModal();
  };

  return (
    <div className="BookingsMapHeader">
      <PageHeader
        title={title}
        message={tooltips.bookings.map.floorHeading}
        className="BookingsMap"
      >
        <div className="FloorsHeader__buttons adjustable-button">
          {searchedAttendees.length > 0 && (
            <h6 className="FloorsHeader__buttons--counter">
              +{searchedAttendees.length}
            </h6>
          )}
          <Button
            title="Spotlight"
            color="primary"
            size="medium"
            icon="spotlights-icon"
            onClick={handleOpenModal("people")}
            className="SpotlightButton"
            subTitle={
              searchedAttendees.length > 0
                ? "+" + searchedAttendees.length
                : undefined
            }
          />

          <Button
            title={formatTitleDate(dateAndTime, timeZone)}
            color="primary"
            size="medium"
            icon="clock-icon"
            onClick={handleOpenModal("time")}
            className="TimeButton"
          />

          <FloorPickerButton
            className="FloorPickerButton"
            adjustable
            size="medium"
            onClick={togglePageSidebar}
            color="third"
          />
        </div>
      </PageHeader>

      {isOpen && (
        <BookingsMapModal
          isOpen={isOpen}
          type={modalType}
          toggleModal={toggleModal}
          data={data}
        />
      )}
    </div>
  );
};
