import React from "react";
import { Icon } from "../../../../shared/Icon/Icon";

interface Props {
  tags?: string[];
}

export const PopUpTags = ({ tags }: Props) => {
  return (
    <div className="PopUpTags">
      {tags &&
        tags.map((tag, index) => (
          <div className="PopUpTags__tag flex-a-center">
            <Icon icon="tag-reserve" />
            <span key={`${index}-${tag}`} className="pl-min">
              {tag}
            </span>
          </div>
        ))}
    </div>
  );
};
