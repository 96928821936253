import React, { useState } from "react";
import { toast } from "react-toastify";
import { FormFeedback, FormGroup } from "reactstrap";
import { useLoginContext } from "../../../../lib/context/LoginContext/LoginContext";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useTotpInputChange } from "../../../../lib/hooks/useTotpInputChange";

import { Button } from "../../../shared/Button/Button";
import { TotpCodeInput } from "../../../shared/Forms/TotpCodeInput/TotpCodeInput";

import "../VerifyMfa.scss";

export const VerifyMfa = () => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const { loginWithTotp, setSsoLoading } = useAuthContext();
  const { errorMfa, setMfaStep } = useLoginContext();
  const { handleTotpInputChange, totpCode } = useTotpInputChange();

  const handleLogin = async () => {
    if (errorMfa === undefined) {
      return;
    }

    try {
      setLoading(true);
      setSsoLoading(true);
      await loginWithTotp(errorMfa, totpCode.join(""));

      setTimeout(() => {
        setSsoLoading(false);
      }, 1500);
    } catch (error: any) {
      setSsoLoading(false);
      if (error?.code === "auth/invalid-verification-code") {
        setErrorMessage("Wrong code");
        return;
      }

      // setSsoLoading(false);
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="VerifyMfa">
      <div className="VerifyMfa__info">
        <span>
          To continue, enter the 6-digit verification code generated by your
        </span>
        <span className="VerifyMfa__info--app">authenticator app.</span>
      </div>
      <FormGroup className="VerifyMfa__totp">
        <TotpCodeInput
          totpCode={totpCode}
          handleTotpInputChange={handleTotpInputChange}
        />
        {errorMessage !== undefined && (
          <FormFeedback className="invalid-feedback">
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>

      <div className="VerifyMfa__buttons flex-a-center">
        <Button
          color="outline-primary"
          size="small"
          title="Back"
          onClick={() => setMfaStep(undefined)}
        />

        <Button
          color="primary"
          size="small"
          title="Continue"
          onClick={handleLogin}
          disabled={totpCode.join("").length < 6 || loading}
          loadSpinner={loading}
          loadTitle="Loggin in"
        />
      </div>

      <div className="VerifyMfa__singleButton">
        <Button
          color="link"
          title="Having problems?"
          onClick={() => setMfaStep("options")}
        />
      </div>
    </div>
  );
};
