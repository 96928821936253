import React, { PropsWithChildren, useState } from "react";
import cs from "classnames";

import { Button } from "../Button/Button";

export const AdjustableButtons = ({
  children,
}: PropsWithChildren<{
  children: React.ReactNode;
}>) => {
  const [hamburgerButton, setHamburgerButton] = useState(false);

  const classes = cs("InsightsHeaderButtons__large flex-a-center", {
    "InsightsHeaderButtons__large--active": hamburgerButton,
  });

  return (
    <>
      <div className={classes}>{children}</div>

      <div className="InsightsHeaderButtons__small adjustable-button">
        <Button
          color="primary"
          size="medium"
          onClick={() => setHamburgerButton((prev) => !prev)}
        />
      </div>
    </>
  );
};
