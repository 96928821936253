import React, { useState, PropsWithChildren, useEffect, useMemo } from "react";

import { useRouter } from "../../hooks/useRouter";

import { Provider, ISidebarContext } from "./SidebarContext";

import { getSidebarMenuFromURL } from "./helpers/getSidebarMenuFromURL";

export const SidebarContextProvider = (props: PropsWithChildren<{}>) => {
  const router = useRouter();

  const [activeMenuItem, setActiveMenuItem] = useState<string>("");
  const [isSubMenuActive, setVisibleSubMenu] = useState<boolean>(false);
  const [visibileMainMenu, setVisibileMainMenu] = useState<boolean>(false);
  const [visiblePageSidebar, setVisiblePageSidebar] = useState<boolean>(false);

  const activePage = useMemo(() => {
    return getSidebarMenuFromURL(router.location.pathname);
  }, [router.location.pathname]);

  useEffect(() => {
    setActiveMenuItem(activePage.id || "");

    hideMobileMenus();
  }, [activePage]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleSubMenu = () => {
    setVisibleSubMenu((prev) => !prev);
  };

  const hideMobileMenus = () => {
    // if (window.innerWidth > 1400) {
    //   return;
    // }

    setVisibleSubMenu(false);
    setVisibileMainMenu(false);
    setVisiblePageSidebar(false);
  };

  const toggleMainMenu = () => {
    if (visiblePageSidebar) {
      setVisiblePageSidebar(false);
    }

    if (isSubMenuActive) {
      setVisibleSubMenu((prev) => !prev);
      return;
    }

    setVisibileMainMenu((prev) => !prev);
  };

  const togglePageSidebar = () => {
    if (visibileMainMenu) {
      setVisibleSubMenu(false);
      setVisibileMainMenu(false);
    }

    setVisiblePageSidebar((prev) => !prev);
  };

  const openPageSidebar = () => {
    setVisiblePageSidebar(true);
  };

  const setSubMenuList = (s: string) => {
    setActiveMenuItem(s);
  };

  const closeSidebar = () => {
    if (!isSubMenuActive) {
      return;
    }

    setActiveMenuItem(activePage.id || "");
    setVisibleSubMenu(false);
  };

  const handleSidebarClick = (id: string, hasChildren: boolean) => {
    if (id === activeMenuItem) {
      setVisibleSubMenu(hasChildren ? !isSubMenuActive : false);
      return;
    }

    setActiveMenuItem(id);
    setVisibleSubMenu(hasChildren);
  };

  const context: ISidebarContext = {
    activeMenuItem,
    isSubMenuActive,
    visibileMainMenu,
    visiblePageSidebar,
    toggleSubMenu,
    toggleMainMenu,
    hideMobileMenus,
    togglePageSidebar,
    openPageSidebar,
    handleSidebarClick,
    closeSidebar,
    setActiveMenuItem: setSubMenuList,
  };

  return <Provider value={context}>{props.children}</Provider>;
};
