import React, { PropsWithChildren } from "react";
import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";

export const AddReservation = ({
  handleClick,
}: PropsWithChildren<{
  handleClick: () => void;
}>) => {
  return (
    <div className="AddReservation mt-4 mb-2">
      <div className="divider mr-4"></div>

      <Icon onClick={handleClick} icon="add-green-icon" />

      <div className="divider ml-4"></div>
    </div>
  );
};
