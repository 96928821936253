import React from "react";

import "./HandleLoadingState.scss";

interface Props {
  loading?: boolean;
  fullWidth?: boolean;
  loadingPlaceholder: JSX.Element;
  children: React.ReactNode;
}

export const HandleLoadingState = (props: Props) => {
  if (props.loading) {
    return (
      <div className={`loader ${props.fullWidth ? "full-loader" : ""}`}>
        {props.loadingPlaceholder}
      </div>
    );
  }
  return <>{props.children}</>;
};
