import React, { useRef, useState } from "react";
import { DistanceUnitType } from "../../../../../api/graphql/wayfinder/wayfinder";
import { useOutsideClickDetection } from "../../../../../lib/hooks/useOutsideClickDetection";
import { directionUnitTypes } from "../lib/getDirectionUnit";
import { WayFinderFormik } from "../lib/useWayFinderFormik";

interface Props {
  formik: WayFinderFormik;
}

export const DistanceUnit = ({ formik }: Props) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  useOutsideClickDetection(ref, () => {
    setOpen(false);
  });

  return (
    <>
      <div className="flex-a-center position-relative WayFinderDirectionTable">
        <div>Distance</div>
        <div
          className="WayFinderDirectionTable__button ml-2"
          onClick={() => setOpen((prev) => !prev)}
        >
          Unit
        </div>
      </div>
      {open && (
        <div
          ref={ref}
          className="position-absolute WayFinderDirectionTable__options WayFinderDirectionTable__unit"
        >
          {Object.entries(directionUnitTypes).map(
            ({ "0": key, "1": value }) => {
              return (
                <span
                  key={key}
                  onClick={() => {
                    formik.setFieldValue(
                      "distanceUnit",
                      key as DistanceUnitType
                    );
                    formik.setFieldTouched("distanceUnit", true);
                    setOpen(false);
                    return;
                  }}
                >
                  {value}
                </span>
              );
            }
          )}
        </div>
      )}
    </>
  );
};
