import React from "react";
import { format } from "date-fns";

import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { useInsightsContext } from "../../InsightsContext";

import {
  DesksTotalOccupancyResponse,
  HeatMapResponse,
} from "../../../../../api/grpc/insights/insights";
import {
  TimeRangeRequest,
  useDeskInsightsRequests,
} from "../../../../../api/grpc/insights/useDeskInsightsRequests";
import {
  UtilizationInsights,
  UtilizationInsightsContext,
} from "./UtilizationContext";

interface Props {
  children: React.ReactNode;
}

export const UtilizationContextProvider = ({ children }: Props) => {
  const { getDeskTotalOccupancy, getDeskHeatMapResevations } =
    useDeskInsightsRequests();

  const { timeZone } = useTimeZoneContext();
  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const {
    loading: loadingOccupancy,
    error: errorOccupancy,
    data: dataOccupancy,
  } = useGrpcQuery<TimeRangeRequest, DesksTotalOccupancyResponse>({
    method: getDeskTotalOccupancy,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingHeatmap,
    error: errorHeatmap,
    data: dataHeatmap,
  } = useGrpcQuery<TimeRangeRequest, HeatMapResponse>({
    method: getDeskHeatMapResevations,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const context: UtilizationInsights = {
    loadingOccupancy,
    errorOccupancy,
    dataOccupancy,
    loadingHeatmap,
    errorHeatmap,
    dataHeatmap,
  };

  return (
    <UtilizationInsightsContext.Provider value={context}>
      {children}
    </UtilizationInsightsContext.Provider>
  );
};
