import React from "react";
import { toast } from "react-toastify";

import { useRouter } from "../../../lib/hooks/useRouter";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useAccountRequests } from "../../../api/grpc/account/useAccountRequests";
import { useConfirmation } from "../../../lib/context/Confirmation/ConfirmationContext";

import "./CancelAccount.scss";

export const CancelAccount = () => {
  const { history } = useRouter();
  const { signOut } = useAuthContext();
  const confirmation = useConfirmation();
  const { disableAccount } = useAccountRequests();

  const handleClick = async () => {
    await confirmation.confirm({
      keywordDelete: "DISABLE",
      title: "Are you sure you want to disable your account?",
      description:
        "This action will disable your account, to reactive you must contact sales@gogetcorp.com",
    });

    try {
      await disableAccount();

      await signOut();

      history.push("/login");
      toast.success("Your account has been deactivated!");
    } catch (error: any) {
      console.error(error);
      toast.info("Account hasn't been deactivated");
    }
  };

  return (
    <button type="button" onClick={handleClick} className="CancelAccount">
      <span>Cancel my account</span>
    </button>
  );
};
