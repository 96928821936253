import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouter } from "../../../lib/hooks/useRouter";

import { SubRouteProps } from "../../../lib/routes/routes";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { ResourceNavigation } from "../../shared/ResourceNavigation/ResourceNavigation";
import { InsightsHeaderButtons } from "../shared/InsightsHeaderButtons/InsightsHeaderButtons";
import { RoomValueInsights } from "./Value/RoomValueInsights";
import { RoomUtilization } from "./Utilization/RoomUtilization";
import { RoomRelease } from "./RoomRelease/RoomRelease";
import { InsightsContextProvider } from "../Context/InsightsContextProvider";
import { DateRangeContextProvider } from "../Context/DateRangeContext/DateRangeContextProvider";
import { InsightsForInfo } from "../shared/InsightsForInfo/InsightsForInfo";
import { tooltips } from "../../../lib/utils/tooltips";
import { RoomInsightsContextProvider } from "../Context/Room/Overview/RoomInsightsContextProvider";
import { UtilizationContextProvider } from "../Context/Room/Utilization/UtilizationContextProvider";
import { TimeZoneLabel } from "../shared/TimeZoneLabel/TimeZoneLabel";
import { BehaviourContextProvider } from "../Context/Room/Behaviour/BehaviourContextProvider";
import { RoomHistory } from "./RoomHistory/RoomHistory";
import { PageDescription } from "../../shared/PageDescription/PageDescription";

import "../shared/WorkplaceInsights.scss";

export const RoomInsights = () => {
  const { match, pathname } = useRouter();

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "Overview",
      to: match.url,
      id: "room-value-insights-page",
      component: RoomValueInsights,
    },
    {
      exact: true,
      label: "Utilization",
      to: `${match.url}/utilization`,
      id: "room-utilization-page",
      component: RoomUtilization,
    },
    {
      exact: true,
      label: "Behaviour",
      to: `${match.url}/behaviour`,
      id: "room-efficiency-page",
      component: RoomRelease,
    },
    {
      exact: true,
      label: "History",
      to: `${match.url}/history`,
      id: "room-history-page",
      component: RoomHistory,
    },
  ];

  return (
    <DateRangeContextProvider>
      <InsightsContextProvider>
        <RoomInsightsContextProvider>
          <UtilizationContextProvider>
            <BehaviourContextProvider>
              <div className="WorkplaceInsights default-page">
                <div className="PeopleHeader position-relative">
                  <PageHeader
                    title="Room Insights Dashboard"
                    hideBorder
                    message={tooltips.insights.rooms.heading}
                  >
                    <TimeZoneLabel />
                  </PageHeader>

                  <ResourceNavigation routes={routes}>
                    <InsightsHeaderButtons pathname={pathname} />
                  </ResourceNavigation>
                </div>

                {pathname.includes("history") && (
                  <PageDescription description='For privacy reasons, we will only display the organizers of events who have a registered profile in the GOGET directory. To view additional organizer details, please add more users in the "People" section.' />
                )}

                <div className="position-relative">
                  <InsightsForInfo
                    title={
                      pathname.includes("history") ? "History for" : undefined
                    }
                  />

                  <Switch>
                    {routes.map((item) => (
                      <Route
                        key={item.id}
                        path={item.to}
                        component={item.component}
                        exact={item.exact ? true : undefined}
                      />
                    ))}
                    <Redirect to={routes[0].to} />
                  </Switch>
                </div>
              </div>
            </BehaviourContextProvider>
          </UtilizationContextProvider>
        </RoomInsightsContextProvider>
      </InsightsContextProvider>
    </DateRangeContextProvider>
  );
};
