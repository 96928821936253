import React, { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useRoomTemplateContext } from "../../../../lib/context/Templates/RoomTemplate/RoomTemplateContext";
import { useScrollToTop } from "../../../../lib/hooks/useScrollToTop";

import { PageHeader } from "../../../shared/PageHeader/PageHeader";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { EditRoomProfileForm } from "./Form/EditRoomProfileForm";
import { tooltips } from "../../../../lib/utils/tooltips";

interface Props {
  id: string;
}

export const EditRoomProfile = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<Props>) => {
  useScrollToTop();
  const { roomTemplates } = useRoomTemplateContext();
  const [loading, setLoading] = useState(true);

  const roomData = useMemo(() => {
    const roomTemplate = roomTemplates.find((room) => room.id === id);

    setLoading(false);

    return roomTemplate;
  }, [id, roomTemplates]);

  return (
    <div className="TemplateManage">
      <PageHeader
        title="Edit room template"
        hideBorder
        message={tooltips.templates.editTemplate}
      />

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<LoadingBox count={3} minHeight={70} />}
      >
        <HandleRequestState
          state={!roomData && loading === false}
          placeholder={<CenteredBox>No room template found!</CenteredBox>}
        >
          {roomData && <EditRoomProfileForm roomData={roomData} />}
        </HandleRequestState>
      </HandleLoadingState>
    </div>
  );
};
