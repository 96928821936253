import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";
import { Button } from "reactstrap";

import "./EditSettings.scss";

interface Props {
  title?: string;
  hasChanges?: boolean;
  onSubmit: () => void;
  submitting?: boolean;
  disableButton?: boolean;
  splitSections?: boolean;
  children: React.ReactNode;
  flexibleSections?: boolean;
  className?: string;
}

export const EditSettings = (props: Props) => {
  const location = useLocation();
  const {
    title,
    hasChanges,
    children,
    disableButton,
    submitting,
    splitSections,
    flexibleSections,
    onSubmit,
    className,
  } = props;

  useEffect(() => {
    scrollToAnchor();
  }, []);

  const scrollToAnchor = () => {
    if (!location.hash) {
      return;
    }

    const anchorElement = document.querySelector(location.hash);

    if (!anchorElement) {
      return;
    }

    window.scrollTo({
      top: anchorElement.getBoundingClientRect().top - 150,
      behavior: "smooth",
    });
  };

  const classes = cs("EditSettings", className, {
    "EditSettings--split": splitSections,
    "EditSettings--flexible": flexibleSections,
  });

  return (
    <div className={classes}>
      {title && <h3>{title}</h3>}

      {children}

      <Button
        size="medium"
        color="primary"
        onClick={onSubmit}
        className="px-4 py-2"
        disabled={
          disableButton ||
          submitting ||
          (hasChanges !== undefined ? !hasChanges : false)
        }
      >
        {submitting ? "Saving..." : "Save"}
      </Button>
    </div>
  );
};
