import { set } from "date-fns";
import { isString } from "lodash";
import { CustomerSettings } from "../../../../../api/grpc/customer/customer";

export const dateHelper = (
  timeValue: string,
  type: "startTime" | "endTime",
  globalWorkingHours?: CustomerSettings
) => {
  return !!timeValue
    ? set(new Date(), {
        hours:
          isString(timeValue) && timeValue.length <= 9
            ? +timeValue.split(":")[0]
            : new Date(timeValue).getHours(),
        minutes:
          isString(timeValue) && timeValue.length <= 9
            ? +timeValue.split(":")[1]
            : new Date(timeValue).getMinutes(),
        seconds: 0,
      })
    : set(new Date(), {
        hours: globalWorkingHours && +globalWorkingHours[type].split(":")[0],
        minutes: globalWorkingHours && +globalWorkingHours[type].split(":")[1],
        seconds: globalWorkingHours && +globalWorkingHours[type].split(":")[2],
      });
};

export const dateFormat = (timeValue: string) => {
  return set(new Date(), {
    hours: +timeValue.split(":")[0],
    minutes: +timeValue.split(":")[1],
    seconds: 0,
  });
};
