import { useState } from "react";

export const useAllTags = () => {
  const [activeTagsFilter, setActiveTagsFilter] = useState<string[]>([]);

  const handleActiveTagsFilter = (tag: string) => {
    if (activeTagsFilter.includes(tag)) {
      setActiveTagsFilter(activeTagsFilter.filter((item) => item !== tag));
      return;
    }
    setActiveTagsFilter([...activeTagsFilter, tag]);
  };

  return {
    activeTagsFilter,
    handleActiveTagsFilter,
    setActiveTagsFilter,
  };
};
