import { RpcInterceptor } from "@protobuf-ts/runtime-rpc";

import { transport } from "../../../api/grpc/grpcTransport";
import { ChargeBeeHostedServiceClient } from "../../../api/grpc/subscription/subscription.client";

declare global {
  interface Window {
    Chargebee: any;
    google: any;
  }
}

export const openCustomerPortal = async (
  chargeBeeId: string,
  interceptor: RpcInterceptor
) => {
  const hostedServiceClient = new ChargeBeeHostedServiceClient(transport);

  try {
    const chargebeeInstance = window.Chargebee.getInstance();

    await chargebeeInstance.setPortalSession(async () => {
      const { response } = await hostedServiceClient.createPortalSession(
        {
          customerId: chargeBeeId,
        },
        {
          interceptors: [interceptor],
        }
      );

      return response;
    });

    let cbPortal = chargebeeInstance.createChargebeePortal();

    return { cbPortal, chargebeeInstance };
  } catch (error: any) {
    console.error(error.message);
  }

  return;
};
