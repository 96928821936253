import React, { useState } from "react";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

import { useAuthContext } from "../../lib/context/Auth/AuthContext";

import { ResetPasswordForm } from "./ResetPasswordForm";

import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { resetPassword, isAuthenticated } = useAuthContext();

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await resetPassword(email);

      toast.success("Password reset email was successfully sent!");
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setEmail("");
      setLoading(false);
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <AuthenticationPage formTitle="Reset Password" isDefault>
      <ResetPasswordForm
        email={email}
        loading={loading}
        handleSubmit={handleSubmit}
        handleChange={(value: string) => setEmail(value)}
      />
    </AuthenticationPage>
  );
};
