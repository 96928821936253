import React, { useState } from "react";
import { PeopleContext } from "./PeopleContext";

import { useGetContacts } from "../../../../components/People/lib/useGetContacts";
import { useDebounce } from "../../../../lib/hooks/useDebounce";
import { IContactFilters } from "../../../../components/People/ContactsFilters/ContactsFilters";
import { NullableBoolean } from "../../../../api/grpc/contacts/contacts";

interface Props {
  children: React.ReactNode;
}

export interface PeopleFilter {
  license: boolean;
  hasDesk: boolean;
  role: boolean;
  status: boolean;
}

export const PeopleContextProvider = (props: Props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);

  const debouncedValue = useDebounce<string>(searchValue, 500);
  const [contactsFilters, setContactsFilters] = useState<IContactFilters>({
    licensed: NullableBoolean.NULL,
    role: [],
    status: [],
    desks: NullableBoolean.NULL,
  });

  const { loading, error, data, refetch } = useGetContacts({
    page: page,
    pageSize: pageSize,
    name: debouncedValue,
    role: contactsFilters.role,
    status: contactsFilters.status,
    licensed: contactsFilters.licensed,
    desks: contactsFilters.desks,
  });

  const context: PeopleContext = {
    error,
    loading,
    searchValue: searchValue,
    debouncedValue: debouncedValue,
    setSearchValue: setSearchValue,
    contactsFilters: contactsFilters,
    setContactsFilters: setContactsFilters,
    updateUsersList: () => refetch(),
    deleteLoader,
    setDeleteLoader,
    refetchUsers: () => {
      refetch();
    },
    data: data?.contacts,
    pagination: {
      key: "",
      page,
      pageSize,
      total: data?.pagesCount || 1,
      setPage: (page) => setPage(page),
      setPageSize: (pageSize) => {
        setPage(1);
        setPageSize(pageSize);
      },
      setToNextPage: () => {
        setPage((prev) => prev + 1);
      },
      setToPreviousPage: () => {
        setPage((prev) => prev - 1);
      },
    },
  };

  return (
    <PeopleContext.Provider value={context}>
      {props.children}
    </PeopleContext.Provider>
  );
};
