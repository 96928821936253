import React, { PropsWithChildren, useRef } from "react";

import "./TotpCodeInput.scss";

export const TotpCodeInput = ({
  totpCode,
  handleTotpInputChange,
}: PropsWithChildren<{
  totpCode: string[];
  handleTotpInputChange: (index: number, value: string) => void;
}>) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));

  return (
    <div className="TotpCodeInput flex-a-center">
      <div className="TotpCodeInput__group d-flex flex-nowrap">
        {totpCode.slice(0, 3).map((code, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            value={code}
            onChange={(e) => {
              handleTotpInputChange(index, e.target.value);

              if (
                e.target.value === "" &&
                index > 0 &&
                inputRefs.current[index - 1]
              ) {
                inputRefs.current[index - 1]?.focus();
              }

              if (
                e.target.value !== "" &&
                index < totpCode.length - 1 &&
                inputRefs.current[index + 1]
              ) {
                inputRefs.current[index + 1]?.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const pastedData = e.clipboardData.getData("text");
              const pastedArray = pastedData.slice(0, 6).split("");

              pastedArray.forEach((char, i) => {
                handleTotpInputChange(i, char);
              });
            }}
            maxLength={1}
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="off"
            className={`TotpCodeInput__group--index-${index + 1}`}
          />
        ))}
      </div>
      <div className="TotpCodeInput__divider"></div>
      <div className="TotpCodeInput__group d-flex flex-nowrap">
        {totpCode.slice(3).map((code, index) => (
          <input
            key={index + 3}
            ref={(el) => (inputRefs.current[index + 3] = el)}
            type="text"
            value={code}
            onChange={(e) => {
              handleTotpInputChange(index + 3, e.target.value);

              if (e.target.value === "" && inputRefs.current[index + 2]) {
                inputRefs.current[index + 2]?.focus();
              }

              if (
                e.target.value !== "" &&
                index + 3 < totpCode.length &&
                inputRefs.current[index + 4]
              ) {
                inputRefs.current[index + 4]?.focus();
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const pastedData = e.clipboardData.getData("text");
              const pastedArray = pastedData.slice(0, 6).split("");

              pastedArray.forEach((char, i) => {
                handleTotpInputChange(i, char);
              });
            }}
            maxLength={1}
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="off"
            className={`TotpCodeInput__group--index-${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};
