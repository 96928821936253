import React from "react";
import { tooltips } from "../../../lib/utils/tooltips";
import { InfoCard } from "../../shared/InfoCard/InfoCard";
import { BillingHelp } from "./Help/BillingHelp";
import { BillingProducts } from "./Products/BillingProducts";
import { SubscriptionsInfo } from "./SubscriptionsInfo/SubscriptionsInfo";

export const Subscriptions = () => {
  return (
    <>
      <SubscriptionsInfo />
      <div className="Products main-grid">
        <InfoCard
          title="Products & Add-ons"
          message={tooltips.organizationSettings.subscription.products}
        >
          <BillingProducts />
        </InfoCard>
        <InfoCard title="Need Help?">
          <BillingHelp />
        </InfoCard>
      </div>
    </>
  );
};
