import React, { useMemo } from "react";
import {
  ListPeopleFilter,
  ListPeopleRequest,
  People,
  Person,
} from "../../../../../api/grpc/contacts/contacts";
import { useContacts } from "../../../../../api/grpc/contacts/useContacts";
import { useCalendarContext } from "../../../../../lib/context/Calendar/CalendarContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import Select from "react-select";

interface Props {
  onChange: (values: Person[]) => void;
  attendees: Person[];
  isAddPeople?: boolean;
}

export const FindPeopleSelector = ({
  onChange,
  attendees,
  isAddPeople,
}: Props) => {
  const { listPeople } = useContacts();
  const { personalCalendar } = useCalendarContext();

  const { loading, error, data } = useGrpcQuery<ListPeopleRequest, People>({
    method: listPeople,
    variables: {
      personalCalendarID: isAddPeople ? personalCalendar[0]?.iD || "" : "",
      customerID: "",
      query: "",
      accountID: "",
      filter: ListPeopleFilter.ALL,
    },
  });

  const options = useMemo(() => {
    return data?.people.map((item) => ({
      value: item,
      label: item.displayName,
    }));
  }, [data]);

  const selectedValue = useMemo(() => {
    return attendees.map((attendee) => ({
      value: attendee,
      label: attendee.displayName,
    }));
  }, [attendees]);

  return (
    <>
      <h6 className="pb-2">{isAddPeople ? "Add" : "Find"} people</h6>

      {loading ? (
        <LoadingBox minHeight={50} />
      ) : error ? (
        <div>Error fetching people, please try again.</div>
      ) : (
        <>
          <Select
            className="FindPeople FindAllPeople no-min-height"
            isClearable
            isMulti
            options={options}
            value={selectedValue}
            classNamePrefix={selectedValue.length >= 2 ? "MultipleVal" : ""}
            onChange={(e) => {
              if (!e) {
                return;
              }

              const values = e.map((item) => item.value);
              onChange(values);
            }}
          />
        </>
      )}
    </>
  );
};
