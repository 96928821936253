import React, { useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useDeskTemplateContext } from "../../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";
import { useScrollToTop } from "../../../../lib/hooks/useScrollToTop";

import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { PageHeader } from "../../../shared/PageHeader/PageHeader";
import { EditDeskProfileForm } from "./Form/EditDeskProfileForm";
import { tooltips } from "../../../../lib/utils/tooltips";

interface Props {
  id: string;
}

export const EditDeskProfile = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<Props>) => {
  useScrollToTop();
  const { deskTemplates } = useDeskTemplateContext();
  const [loading, setLoading] = useState(true);

  const deskData = useMemo(() => {
    const deskTemplate = deskTemplates.find((desk) => desk.id === id);

    setLoading(false);

    return deskTemplate;
  }, [id, deskTemplates]);

  return (
    <div className="TemplateManage">
      <PageHeader
        title="Edit desk template"
        hideBorder
        message={tooltips.templates.editTemplate}
      />

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<LoadingBox count={3} minHeight={70} />}
      >
        <HandleRequestState
          state={!deskData && loading === false}
          placeholder={<CenteredBox>No desk template found!</CenteredBox>}
        >
          {deskData && <EditDeskProfileForm deskData={deskData} />}
        </HandleRequestState>
      </HandleLoadingState>
    </div>
  );
};
