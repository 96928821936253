import React from "react";

import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { useAnalytics } from "../../../api/grpc/analytics/useAnalytics";

import { tooltips } from "../../../lib/utils/tooltips";
import {
  CalendarsResponse,
  CustomerRequest,
} from "../../../api/grpc/analytics/analytics";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";

import { HomeCard } from "../../shared/HomeCard/HomeCard";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { CALENDARS_ROOT_PATH } from "../../../lib/routes/routes";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";

export const CalendarsBox = () => {
  const { getCalendars } = useAnalytics();
  const { timeZone } = useTimeZoneContext();

  const { loading, error, data } = useGrpcQuery<
    Omit<CustomerRequest, "customerID">,
    CalendarsResponse
  >({
    method: getCalendars,
    variables: {
      timeZone,
    },
  });

  return (
    <HandleRequestState state={loading} placeholder={<LoadingBox />}>
      <HandleRequestState
        state={!!error}
        placeholder={
          <HomeCard
            disabled
            title="Calendars"
            icon="dashboard-calendar-icon"
            list={[
              { title: "Calendar integrations" },
              {
                title: "Assigned rooms",
                progress: data?.assigned || 0,
              },
            ]}
          />
        }
      >
        <HomeCard
          title="Calendars"
          link={CALENDARS_ROOT_PATH}
          icon="dashboard-calendar-icon"
          tooltip={tooltips.dashboard.calendars}
          list={[
            { title: "Calendar integrations", value: data?.connections || 0 },
            {
              title: "Assigned rooms",
              value: data?.total || 0,
              progress: data?.assigned || 0,
            },
          ]}
        />
      </HandleRequestState>
    </HandleRequestState>
  );
};
