import { useMemo } from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { transport } from "../grpcTransport";
import {
  ListCustomerLanguagesRequest,
  RetrieveCustomerGlobalCustomLanguagesRequest,
  UpdateCustomerGlobalCustomLanguagesRequest,
} from "./workplaces";

import { WorkplacesClient } from "./workplaces.client";

export const useCustomLanguageClient = () => {
  const { user, tokenInterceptor } = useAuthContext();
  const workplacesClient = useMemo(() => new WorkplacesClient(transport), []);

  const listCustomLanguages = async ({
    appType,
  }: Omit<ListCustomerLanguagesRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.listCustomerLanguages(
      {
        customerId: user.customerid,
        appType,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getCustomLanguageById = async ({
    id,
  }: Omit<RetrieveCustomerGlobalCustomLanguagesRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.retrieveCustomerGlobalCustomLanguages(
      {
        customerId: user.customerid,
        id,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const updateCustomLanguage = async ({
    id,
    name,
    languageData,
  }: Omit<UpdateCustomerGlobalCustomLanguagesRequest, "customerId">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return await workplacesClient.updateCustomerGlobalCustomLanguages(
      {
        customerId: user.customerid,
        id,
        name,
        languageData,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    listCustomLanguages,
    getCustomLanguageById,
    updateCustomLanguage,
  };
};
