import React, { useState } from "react";
import { toast } from "react-toastify";

import { useLoginFormik } from "./lib/useLoginFormik";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useLoginContext } from "../../lib/context/LoginContext/LoginContext";

import { LoginForm } from "../../components/Login/LoginForm/LoginForm";
import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";
import { ProvidersLogin } from "../../components/Login/ProvidersLogin/ProvidersLogin";
import { Spinner } from "reactstrap";
import { StartVerifyMfa } from "../../components/Login/StartVerifyMfa/StartVerifyMfa";

export const Login = () => {
  const [loading, setLoading] = useState(false);

  const { getTenant, login, ssoLoading, setRecoveryEmail } = useAuthContext();
  const { mfaStep, setMfaStep, setErrorMfa } = useLoginContext();

  const formik = useLoginFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const {
          response: { id, recoveryEmail },
        } = await getTenant(values.username);

        setRecoveryEmail(recoveryEmail);

        await login(values.username, values.password, id);
        localStorage.setItem("initiateLogin", "1");
      } catch (error: any) {
        if (error?.code === "auth/multi-factor-auth-required") {
          setLoading(false);
          setErrorMfa(error);

          return setMfaStep("verifyCode");
        }

        if (error?.code === "auth/wrong-password") {
          setLoading(false);
          toast.error(
            "The password is invalid or the user does not have a password."
          );

          return;
        }
        setLoading(false);
        toast.error(error.message);
      }
    },
  });

  return (
    <>
      {mfaStep !== undefined ? (
        <StartVerifyMfa primaryEmail={formik.values.username} />
      ) : (
        <AuthenticationPage
          isDefault={true}
          formTitle={ssoLoading ? "Signing in..." : "Login"}
          formContentClassname={
            ssoLoading
              ? "flex-column-reverse align-items-center justify-content-center"
              : ""
          }
        >
          <Spinner color="success" className={!ssoLoading ? "d-none" : ""} />

          <div className={ssoLoading ? "d-none" : ""}>
            <LoginForm formik={formik} loading={loading} />

            <ProvidersLogin />
          </div>
        </AuthenticationPage>
      )}
    </>
  );
};
