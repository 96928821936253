import React from "react";
import { DeskUtilizationDensity } from "./DeskUtilizationDensity/DeskUtilizationDensity";
import { DeskUtilizationOccupancy } from "./DeskUtilizationOccupancy/DeskUtilizationOccupancy";

export const DeskUtilization = () => {
  return (
    <div className="RoomValueInsights WorkspaceValueInsights">
      <DeskUtilizationOccupancy />

      <DeskUtilizationDensity />
    </div>
  );
};
