import React, { PropsWithChildren, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useCalendarContext } from "../../../lib/context/Calendar/CalendarContext";
import { useEWSFormik } from "../lib/useEWSFormik";

import { redirectToO365 } from "../CalendarForms/O365ConnectButton/o365-popup";
import { config } from "../CalendarForms/GSuiteConnectButton/GSuiteConnectButton";
import { ReAuthModal } from "../../Modals/ReAuthModal/ReAuthModal";
import { Button } from "../../shared/Button/Button";
import { CalendarType } from "../../../api/grpc/calendarprovider/calendarprovider";
import { Icon } from "../../shared/Icon/Icon";
import { EWSServiceForm } from "../CalendarForms/EWSServiceForm";
import { Providers } from "../../../lib/context/Calendar/CalendarContextProvider";

import "./CalendarError.scss";

export const CalendarError = ({
  message,
  calendar,
}: PropsWithChildren<{
  message: string;
  calendar: Providers;
}>) => {
  const [openReAuthModal, setOpenReAuthModal] = useState(false);
  const [openEWSForm, setOpenEWSForm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { refetchCalendars } = useCalendarContext();
  const { user } = useAuthContext();

  const { reAuthenticateIntegration, updateEWSProvider } =
    useCalendarRequests();
  const handleGoogleReAuth = async (token: string) => {
    if (!user) {
      return;
    }

    try {
      await reAuthenticateIntegration(calendar.iD, {
        oneofKind: "gsuite",
        gsuite: {
          oAuthToken: token,
          calendarType: CalendarType.GSUITE_WEB,
        },
      });

      await refetchCalendars();

      if (openReAuthModal) {
        setOpenReAuthModal(false);
      }

      toast.success(
        <div className="SuccessToast">
          <div className="flex-a-center">
            <Icon icon="check-icon" />
            <span className="ml-2">
              Re-authentication finished successfully!
            </span>
          </div>
        </div>,
        {
          position: toast.POSITION.TOP_LEFT,
        }
      );

      return;
    } catch (error: any) {
      toast.error(error?.message ?? "Calendar couldn't be reauthenticated!");
    }
  };

  const formik = useEWSFormik({
    initialValues: {
      name: calendar.name,
      domain: calendar.hostUrl,
      svcAccount: calendar.email,
      password: "",
      impersonationEnabled: calendar?.settings?.impersonationEnabled || false,
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        if (!user || !values) {
          return;
        }

        await updateEWSProvider({
          customerID: user?.customerid || "",
          iD: calendar.iD,
          ...values,
        });

        await refetchCalendars();

        formikHelpers.resetForm();
        setOpenEWSForm(false);

        if (openReAuthModal) {
          setOpenReAuthModal(false);
        }

        toast.success(
          <div className="SuccessToast">
            <div className="flex-a-center">
              <Icon icon="check-icon" />
              <span className="ml-2">
                Re-authentication finished successfully!
              </span>
            </div>
          </div>,
          {
            position: toast.POSITION.TOP_LEFT,
          }
        );
        return;
      } catch (error: any) {
        toast.error(error?.message ?? "Calendar couldn't be re-authenticated!");
      }
    },
  });

  const handleFixNowButton = () => {
    if (calendar.iD.toLowerCase().startsWith("o365")) {
      return redirectToO365("admin", false, calendar.iD);
    }

    if (calendar.iD.toLowerCase().startsWith("gsuite")) {
      return window.google.accounts.oauth2
        .initCodeClient({
          client_id: config.clientId,
          scope: config.scope,
          callback: async (tokenResponse: any) => {
            if (tokenResponse && tokenResponse.code) {
              await handleGoogleReAuth(tokenResponse.code);
            }
          },
        })
        .requestCode();
    }

    return setOpenEWSForm(true);
  };

  return (
    <>
      {openReAuthModal && (
        <ReAuthModal
          closeModal={() => setOpenReAuthModal(false)}
          handleReAuthButton={handleFixNowButton}
          type="Admin"
          isEws={calendar.iD.toLowerCase().startsWith("ews")}
        />
      )}

      <Modal
        isOpen={openEWSForm}
        className="ReAuthEWS"
        toggle={() => setOpenEWSForm((prev) => !prev)}
      >
        <EWSServiceForm formik={formik} isVerify email={calendar.email} />

        <div className="d-flex justify-content-center gap-1 pt-3 mt-4 border-top">
          <Button
            size="small"
            color="outline-secondary"
            title="Close"
            onClick={() => setOpenEWSForm(false)}
          />
          <Button
            size="small"
            title="Verify"
            color="primary"
            disabled={
              !formik.dirty ||
              (formik.dirty && !!Object.keys(formik.errors).length)
            }
            onClick={formik.submitForm}
          />
        </div>
      </Modal>

      <div className="CalendarError d-flex mb-3">
        <div className="d-flex gap-1">
          <Icon icon="red-error-icon" />

          <div className="d-flex flex-column CalendarError__content">
            <span>
              {calendar.iD.toLowerCase().startsWith("ews")
                ? "A problem with your calendar provider integration has occured. Based on the error codes in the response from your calendar provider, it seems like critical settings have been changed. Examples of such changes include, but are not limited to: wrong credentials (changed or wrong password), room calendar permissions, calendar sharing, calendar removal and/or actions triggered by security policies."
                : "Auto-renewal of renew-token failed. Admin calendar integration re-authentication is required."}
            </span>
            <span className="CalendarError__content--provider">
              <span>Message from calendar provider:</span>
              <span className="CalendarError__content--provider--message">
                {isExpanded ? message : `${message.slice(0, 50)}...`}
              </span>
            </span>
            <span
              className="CalendarError__content--expand pt-2"
              onClick={() => setIsExpanded((prev) => !prev)}
            >
              {isExpanded ? "Hide" : "Expand"}
            </span>
          </div>
        </div>

        <div className="d-flex CalendarError__buttons">
          <Button
            color="outline-red"
            title={
              calendar.iD.toLowerCase().startsWith("ews")
                ? "Verify credentials"
                : "Re-authenticate"
            }
            size="small"
            onClick={handleFixNowButton}
          />
          <Button
            color="simple"
            title="Read more"
            size="small"
            onClick={() => setOpenReAuthModal(true)}
          />
        </div>
      </div>
    </>
  );
};
