import React from "react";
import {
  CBCustomer,
  Subscription,
} from "../../../../api/grpc/subscription/subscription";
import { Currencies } from "../../../../lib/helpers/getCurrencySymbol";
import { totalBill } from "../../../../lib/helpers/totalBill";
import { NoAddons } from "./NoAddons";
import { SubscriptionItemsCount } from "./SubscriptionItemsCount";

import "./SubscriptionRow.scss";

export interface DetailsData {
  title: string;
  content: string | JSX.Element;
  className?: string;
}

interface Props {
  subscription?: Subscription;
  partnerData?: CBCustomer;
}

export const SubscriptionItems = ({ subscription, partnerData }: Props) => {
  let definedStatus = [
    "cancelled",
    "active",
    "in_trial",
    "non_renewing",
  ].includes(subscription?.status.toLowerCase() || "");

  const details: DetailsData[] = [
    {
      title: "Subscription items",
      content: !!subscription?.addons.length ? (
        <SubscriptionItemsCount data={subscription.addons} />
      ) : (
        <NoAddons />
      ),
    },
    {
      title: "Billing status",
      content: !!partnerData?.email
        ? "Managed by Channel Partner"
        : subscription?.status?.toLowerCase() === "in_trial"
        ? `Free trial expires (${subscription?.billingStatus?.upcomingBillingAt})`
        : `Upcoming charges (${
            subscription?.billingStatus?.upcomingBillingAt
          }) ${totalBill(
            subscription?.billingStatus?.currency as Currencies,
            subscription?.billingStatus?.price || 0
          )}`,
      className: "span-block",
    },
  ];

  return (
    <div className="SubscriptionRow">
      <div className="SubscriptionRow__rows">
        {details.map((item: DetailsData) => (
          <div
            key={item.title}
            className={`SubscriptionRow__row ${definedStatus ? "" : "m-w-25"}`}
          >
            <h5>{item.title}</h5>
            <span
              className={`SubscriptionRow__row--content ${
                definedStatus
                  ? item.className
                  : `${item.className} ${item.className}--dual`
              }`}
            >
              {item.content}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
