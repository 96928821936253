import React from "react";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Label, Form, FormGroup, FormFeedback, Row, Col } from "reactstrap";

import { countries } from "./lib/countries";
import { Input } from "../shared/Input/Input";
import { RegisterFormik } from "./lib/useRegisterFormik";
import { selectStyles } from "../../lib/utils/selectStyles";
import { REGISTER_STRINGS as t } from "../../lib/utils/constants";
import { PasswordInput } from "../shared/PasswordInput/PasswordInput";
import { SubmitButton } from "../shared/SubmitButton/SubmitButton";
import { Icon } from "../shared/Icon/Icon";

interface RegisterFormProps {
  loading: boolean;
  formik: RegisterFormik;
  recaptchaRef: any;
}

const interestedOptions = [
  {
    label: "Meeting Room Booking System",
    value: "Meeting Room Booking System",
  },
  { label: "Desk Booking", value: "Desk Booking" },
  {
    label: "Lobby Overview & Wayfinding",
    value: "Lobby Overview & Wayfinding",
  },
  { label: "Workplace Analytics", value: "Workplace Analytics" },
  { label: "Mobile App Booking", value: "Mobile App Booking" },
  { label: "Visitor Management", value: "Visitor Management" },
  { label: "API & Integrations", value: "API & Integrations" },
];

export const RegisterForm = (props: RegisterFormProps) => {
  const { formik, recaptchaRef, loading } = props;

  const handleFormikChange = (
    key: string,
    value: string | string[] | undefined
  ) => {
    formik.setFieldTouched(key);
    formik.setFieldValue(key, value);
  };

  return (
    <>
      <div className="AuthenticationPage__formInfo flex-a-center">
        <Icon icon="green-flag" />
        <span>
          Only for admins. If you're a user, contact your organization's admin.
        </span>
      </div>
      <Form className="Contact__form w-100" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="firstName">{t.firstName}</Label>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                placeholder={t.firstName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                isTouched={formik.touched.firstName}
                errorMessage={formik.errors.firstName}
                invalid={!!formik.errors.firstName && formik.touched.firstName}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="lastName">{t.lastName}</Label>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                placeholder={t.lastName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                isTouched={formik.touched.lastName}
                errorMessage={formik.errors.lastName}
                invalid={!!formik.errors.lastName && formik.touched.lastName}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="workEmail">{t.email}</Label>
              <Input
                type="email"
                id="workEmail"
                name="workEmail"
                placeholder={t.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.workEmail}
                isTouched={formik.touched.workEmail}
                errorMessage={formik.errors.workEmail}
                invalid={!!formik.errors.workEmail && formik.touched.workEmail}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="accountPassword">Password</Label>
              <PasswordInput
                id="accountPassword"
                name="accountPassword"
                placeholder="Password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.accountPassword}
                invalid={
                  !!formik.errors.accountPassword &&
                  formik.touched.accountPassword
                }
                isTouched={formik.touched.accountPassword}
                errorMessage={formik.errors.accountPassword}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="companyName">Company name</Label>
              <Input
                type="text"
                id="companyName"
                name="companyName"
                data-cy="companyName"
                placeholder="Company name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.companyName}
                isTouched={formik.touched.companyName}
                errorMessage={formik.errors.companyName}
                invalid={
                  !!formik.errors.companyName && formik.touched.companyName
                }
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="country">{t.country}</Label>
              <Select
                isClearable
                id="country"
                name="country"
                isSearchable={true}
                theme={selectStyles}
                options={countries}
                onChange={(e) => {
                  if (!e) {
                    return;
                  }

                  handleFormikChange("country", e.value);
                }}
                className={formik.errors.country ? "is-invalid" : ""}
                invalid={!!formik.errors.country && formik.touched.country}
              />
              {!!formik.errors.country && formik.touched.country && (
                <FormFeedback className="invalid-feedback">
                  {formik.errors.country}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="interest">I'm interested in:</Label>
              <Select
                isMulti
                isClearable
                id="interest"
                name="interest"
                isSearchable={true}
                theme={selectStyles}
                options={interestedOptions}
                onChange={(e) => {
                  if (!e) {
                    return;
                  }

                  const values = e.map((item) => item.value);

                  handleFormikChange("interest", values);
                }}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="delivery">When do you need it?</Label>
              <Select
                isClearable
                id="delivery"
                name="delivery"
                isSearchable={true}
                theme={selectStyles}
                options={[
                  { label: "Now", value: "Now" },
                  { label: "Within 1 month", value: "Within 1 month" },
                  { label: "Within 3 months", value: "Within 3 months" },
                  { label: "Within 6 months", value: "Within 6 months" },
                  { label: "Within 1 year", value: "Within 1 year" },
                ]}
                onChange={(e) => {
                  if (!e) {
                    return;
                  }

                  handleFormikChange("delivery", e.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="deskNumber">Number of desks:</Label>
              <Select
                isClearable
                id="deskNumber"
                name="deskNumber"
                isSearchable={true}
                theme={selectStyles}
                options={[
                  { label: "1-10", value: "1-10" },
                  { label: "11-50", value: "11-50" },
                  { label: "51-100", value: "51-100" },
                  { label: "101-500", value: "101-500" },
                  { label: "501-1000", value: "501-1000" },
                  { label: "1001-5000", value: "1001-5000" },
                  { label: "5000+", value: "5000+" },
                ]}
                onChange={(e) => {
                  if (!e) {
                    return;
                  }

                  handleFormikChange("deskNumber", e.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label for="numberOfRooms">Number of rooms:</Label>
              <Select
                isClearable
                id="numberOfRooms"
                name="numberOfRooms"
                isSearchable={true}
                theme={selectStyles}
                options={[
                  { label: "1-5", value: "1-5" },
                  { label: "6-10", value: "6-10" },
                  { label: "11-20", value: "11-20" },
                  { label: "21-50", value: "21-50" },
                  { label: "51-100", value: "51-100" },
                  { label: "101-500", value: "101-500" },
                  { label: "500+", value: "500+" },
                  { label: "none", value: "none" },
                ]}
                onChange={(e) => {
                  if (!e) {
                    return;
                  }

                  handleFormikChange("numberOfRooms", e.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Input
                type="checkbox"
                id="privacyTerms"
                name="privacyTerms"
                data-cy="privacyTerms"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.privacyTerms}
                invalid={
                  !!formik.errors.privacyTerms && formik.touched.privacyTerms
                }
              />
              <Label for="privacyTerms" className="checkbox_label">
                I agree with the
                <a
                  target="_blank"
                  className="ml-1"
                  rel="noopener noreferrer"
                  href="https://gogetcorp.com/privacy/"
                >
                  privacy policy
                </a>
                <span className="checkbox_box"></span>
              </Label>
              {formik.touched.privacyTerms && (
                <FormFeedback>{formik.errors.privacyTerms}</FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Input
                type="checkbox"
                id="newsletter"
                name="newsletter"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.newsletter}
                invalid={
                  !!formik.errors.newsletter && formik.touched.newsletter
                }
              />
              <Label for="newsletter" className="checkbox_label">
                {t.newsletter}
                <span className="checkbox_box"></span>
              </Label>
            </FormGroup>
          </Col>
          <Col xs="12">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
              // sitekey="6Lc05c4UAAAAAHOXA390-IgP62d7iSFXP2EgqHUV"
              onErrored={() => handleFormikChange("recaptcha", "")}
              onExpired={() => handleFormikChange("recaptcha", "")}
              onChange={(token) => handleFormikChange("recaptcha", token || "")}
            />
            {!!formik.errors.recaptcha && formik.touched.recaptcha && (
              <FormFeedback className="invalid-feedback">
                {formik.errors.recaptcha}
              </FormFeedback>
            )}
          </Col>

          <Col xs="12">
            <div className="AuthenticationPage__buttons">
              <SubmitButton
                size="large"
                title={t.submit}
                loading={loading}
                loadingTitle="Creating your account..."
              />

              <NavLink
                to="/login"
                className="btn btn-outline-primary btn-large"
              >
                Back
              </NavLink>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};
