import { useMemo } from "react";

import { usePaginationContext } from "../../../../../lib/context/Pagination/PaginationContext";

const range = (start: number, end: number) => {
  let length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePaginationList = (siblingCount: number = 1) => {
  const { page, total } = usePaginationContext();

  const paginationRange = useMemo(() => {
    const firstPageIndex = 1;

    /*
    	Case 1: No dots on both sides, when the difference between first and last page is less/equal than 3
    */
    if (total - firstPageIndex <= 3) {
      return range(firstPageIndex, total);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, total);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < total - 2;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      // Ensure that the last item is not duplicated if it's the same as the total
      if (leftRange[leftRange.length - 1] === total) {
        leftRange = range(1, leftItemCount - 1);
      }

      // Otherwise, include the left range, a "DOTS" marker, and the final page
      return [...leftRange, "DOTS", total];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(total - rightItemCount + 1, total);

      // Ensure that the first item is not duplicated if it's equal with 1
      if (rightRange[0] === firstPageIndex) {
        rightRange = range(total - rightItemCount + 2, total);
      }

      // Otherwise, include the left range, a "DOTS" marker, and the final page
      return [firstPageIndex, "DOTS", ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */

    let middleRange = range(leftSiblingIndex, rightSiblingIndex);

    // Ensure that the first item is not duplicated if it's equal with 1
    if (middleRange[0] === firstPageIndex) {
      middleRange = range(leftSiblingIndex + 1, rightSiblingIndex);
    }

    return [firstPageIndex, "DOTS", ...middleRange, "DOTS", total];
  }, [page]);

  return paginationRange;
};
