import React from "react";
import { useRouter } from "../../../lib/hooks/useRouter";

import WfxDevice1 from "../../../assets/images/wfx-wall-device.png";
import { BillingInnerCard } from "../../Billings/shared/BillingInnerCard/BillingInnerCard";
import { TEMPLATE_ROOT_PATH } from "../../../lib/routes/routes";

export const ManageProfiles = () => {
  const { history } = useRouter();

  return (
    <>
      <div className="ManageProfiles">
        <h5 className="pt-2 pb-2">Transfer settings templates</h5>

        <div className="ManageProfiles__content">
          <BillingInnerCard
            buttonTitle="Transfer"
            hasButton
            imageSrc={<img alt="Goget" src={WfxDevice1} />}
            secondDescription="Use a template to transfer settings in bulk to all types of
          workspaces, incl rooms & desks"
            onClick={() => history.push(`${TEMPLATE_ROOT_PATH}/transfer`)}
          />
        </div>
      </div>
    </>
  );
};
