import { addMinutes } from "date-fns";
import { createContext, useContext } from "react";
import { MatchingDesk } from "../../../api/grpc/desk/desk";
import {
  AmenitiesFilter,
  AvailableRoom,
} from "../../../api/grpc/workplaces/workplaces";

export interface IBookingsContext {
  day: Date;
  matchedDesks: MatchingDesk[] | null;
  handleMatchedDesks: (desks: MatchingDesk[] | null) => void;
  matchedRooms: AvailableRoom[] | null;
  handleMatchedRooms: (rooms: AvailableRoom[] | null) => void;
  location: {
    locationId: string;
    locationName: string;
  };
  reservDeskVariables: {
    title: string;
    startTime: Date;
    endTime: Date;
  };
  handleDayChange: (day: Date) => void;
  handleLocationId: (locationId: string, locationName: string) => void;
  handleReserveDeskVariables: (startTime: Date, endTime: Date) => void;
  activeTagsFilter: string[];
  handleActiveTagsFilter: (tag: string) => void;
  emptyActiveTags: () => void;
  equipment: AmenitiesFilter;
  seats: string;
  handleSeats: (seat: string) => void;
  handleEquipmentChange: (key: keyof AmenitiesFilter) => void;
  attendees: string[];
  handleAttendees: (attendee: string[]) => void;
  subject: string;
  handleSubject: (subject: string) => void;
  handleModalClose: () => void;
  locationDefaultName: string;
  handleLocationDefaultName: (location: string) => void;
  isOnlineMeeting: boolean;
  handleIsOnlineMeeting: () => void;
  makePrivate: boolean;
  handleMakePrivate: () => void;
}

export const BookingsContext = createContext<IBookingsContext>({
  day: new Date(),
  matchedDesks: [],
  handleMatchedDesks: () => {},
  matchedRooms: [],
  handleMatchedRooms: () => {},
  location: {
    locationId: "",
    locationName: "",
  },
  reservDeskVariables: {
    title: "",
    startTime: new Date(),
    endTime: addMinutes(new Date(), 15),
  },
  handleDayChange: () => {},
  handleLocationId: () => {},
  handleReserveDeskVariables: () => {},
  activeTagsFilter: [],
  handleActiveTagsFilter: () => {},
  emptyActiveTags: () => {},
  handleEquipmentChange: () => {},
  equipment: {
    id: "",
    accessibility: false,
    audio: false,
    casting: false,
    chargingPort: false,
    climate: false,
    desk: false,
    presentationTech: false,
    videoConference: false,
    faluty: false,
  },
  seats: "1-5",
  handleSeats: () => {},
  attendees: [],
  handleAttendees: () => {},
  subject: "",
  handleSubject: () => {},
  handleModalClose: () => {},
  locationDefaultName: "All locations",
  handleLocationDefaultName: () => {},
  isOnlineMeeting: false,
  handleIsOnlineMeeting: () => {},
  makePrivate: false,
  handleMakePrivate: () => {},
});

export const useBookingsContext = () => useContext(BookingsContext);

export const { Provider } = BookingsContext;
