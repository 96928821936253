import React from "react";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";

import { ModalMFA } from "../ModalMFA";
import { ModalMFANotification } from "../ModalMFANotification/ModalMFANotification";

export const MfaDisabled = () => {
  const { setMfaModal } = useMfaContext();

  return (
    <ModalMFA
      title="Disable two-factor authentication"
      closeButton="Close"
      handleBackButton={() => {
        setMfaModal(undefined);
      }}
      handleNextButton={() => {}}
      className="ModalResponse"
    >
      <ModalMFANotification
        icon="check-icon"
        title="Two-factor authentication disabled!"
        className="ModalResponse__content--disable"
      />
    </ModalMFA>
  );
};
