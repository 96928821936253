import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { useRouter } from "../../../../lib/hooks/useRouter";

import { CalendarsEWSList } from "../../../../components/Calendars/CalendarsEWSList/CalendarsEWSList";
import { ResourceNavigation } from "../../../../components/shared/ResourceNavigation/ResourceNavigation";
import { CalendarsEWSSettings } from "../../../../components/Calendars/CalendarsEWSSettings/CalendarsEWSSettings";

interface Props {
  shouldRefetchAllCalendars?: boolean;
}

export const CalendarEWSSingle = ({ shouldRefetchAllCalendars }: Props) => {
  const { match, query } = useRouter();

  const routes = [
    {
      exact: true,
      to: match.url,
      label: "Calendars",
      id: "calendars-list",
      render: () => <CalendarsEWSList />,
    },
    {
      exact: true,
      label: "Settings",
      id: "settings-calendar-page",
      to: `${match.url}/settings`,
      render: () => (
        <CalendarsEWSSettings
          id={query.id}
          shouldRefetchAllCalendars={shouldRefetchAllCalendars}
        />
      ),
    },
  ];

  return (
    <>
      <ResourceNavigation routes={routes} />
      <Switch>
        {routes.map((item) => (
          <Route
            key={item.id}
            path={item.to}
            render={item.render}
            exact={item.exact ? true : undefined}
          />
        ))}
        <Redirect to={routes[0].to} />
      </Switch>
    </>
  );
};
