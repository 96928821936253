import { format, isToday, isTomorrow } from "date-fns";

export const formatDateName = (date: Date | number) => {
  if (isToday(date)) {
    return `Today`;
  }

  if (isTomorrow(date)) {
    return `Tomorrow`;
  }

  return format(date, "MMM dd");
};
