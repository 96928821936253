import React from "react";
import { WorkplaceFields } from "../../../api/graphql/workplaces";

import { WorkplaceTypes } from "../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { WorkplaceExpandedRooms } from "./WorkplaceExpandedRooms/WorkplaceExpandedRooms";
import { WorkplaceExpandedSites } from "./WorkplaceExpandedSites/WorkplaceExpandedSites";
import { WorkplaceExpandedDesks } from "./WorkplaceExpandedDesks/WorkplaceExpandedDesks";
import { WorkplaceExpandedZones } from "./WorkplaceExpandedZones/WorkplaceExpandedZones";
import { WorkplaceExpandedFloors } from "./WorkplaceExpandedFloors/WorkplaceExpandedFloors";
import {
  DeviceType,
  WorkplaceExpandedDevices,
} from "./WorkplaceExpandedDevices/WorkplaceExpandedDevices";
import { WorkplaceExpandedBuildings } from "./WorkplaceExpandedBuildings/WorkplaceExpandedBuildings";
import { WorkplaceExpandedCompanies } from "./WorkplaceExpandedCompanies/WorkplaceExpandedCompanies";
import { WorkplaceExpandedWayFinder } from "./WorkplaceExpandedWayFinder/WorkplaceExpandedWayFinder";

interface ExpandedProps {
  data: WorkplaceFields[];
  onClose: () => void;
  parentId?: string;
  deviceType?: DeviceType;
}

interface Props extends ExpandedProps {
  type: WorkplaceTypes;
}

const components: {
  [key in WorkplaceTypes]: React.FunctionComponent<ExpandedProps>;
} = {
  Company: WorkplaceExpandedCompanies,
  Site: WorkplaceExpandedSites,
  Building: WorkplaceExpandedBuildings,
  Floor: WorkplaceExpandedFloors,
  Zone: WorkplaceExpandedZones,
  Room: WorkplaceExpandedRooms,
  Desk: WorkplaceExpandedDesks,
  Device: WorkplaceExpandedDevices,
  Wayfinder: WorkplaceExpandedWayFinder,
};

export const WorkplaceExpandedList = ({
  parentId,
  type,
  data,
  onClose,
  deviceType,
}: Props) => {
  const Component = components[type];

  let componentProps: ExpandedProps = {
    data: data,
    onClose: onClose,
  };

  if (type === "Device") {
    componentProps.parentId = parentId;
    componentProps.deviceType = deviceType;
  }

  return <Component {...componentProps} />;
};
