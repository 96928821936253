import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { FormGroup, Modal } from "reactstrap";

import { useTimezones } from "../../../../lib/hooks/useTimezones";
import { tooltips } from "../../../../lib/utils/tooltips";

import { ModalHeaderContent } from "../../../Modals/ModalHeaderContent/ModalHeaderContent";
import { ModalFooterContent } from "../../../Modals/ModalFooterContent/ModalFooterContent";
import { Input } from "../../../shared/Input/Input";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";

import "./TimeZoneSettingsModal.scss";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  timeZone: string;
  onSave: (timeZone: string) => void;
}

export const TimeZoneSettingsModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  timeZone,
  onSave,
}) => {
  const { options } = useTimezones({
    displayValue: "UTC",
  });
  const [internalTimeZone, setInternalTimeZone] = useState(timeZone);

  // sync props.timeZone with internalTimeZone
  useEffect(() => {
    setInternalTimeZone(timeZone);
  }, [timeZone]);

  const handleSave = () => {
    onSave(internalTimeZone);
    setIsOpen(false);
  };

  return (
    <Modal
      title="Time Zone"
      isOpen={isOpen}
      onTogge={() => setIsOpen((prev) => !prev)}
      className="TimeZoneSettingsModal WorkHours"
    >
      <ModalHeaderContent
        title="Time Zone"
        handleToggle={() => setIsOpen(false)}
      />
      <span className="WorkHours__info pb-4">
        Main time zone setting for your organization.
      </span>
      <div className="WorkHours__info">
        <FormGroup>
          <LabelWithInformationBox
            title="Display events and insights in"
            for="timeZone"
            message={
              tooltips.organizationSettings.generalSettings.timeZoneModal
            }
          />
          <Input
            type="select"
            name="timeZone"
            id="timeZone"
            value={internalTimeZone}
            onChange={(e) => setInternalTimeZone(e.target.value)}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      </div>
      <ModalFooterContent
        toggleModal={() => setIsOpen(false)}
        handleSave={handleSave}
      />
    </Modal>
  );
};
