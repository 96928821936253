import React from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useCheckUserPermissions } from "../../../../lib/hooks/useCheckUserPermissions";
import { dateToHoursAndMinutes } from "../../../Bookings/lib/dateInputConvert";

import { ReservationListActions } from "../../../Bookings/Reservations/ReservationList/ReservationListActions";
import { Event as RoomEvent } from "../../../../api/grpc/desk/ggevent/ggevent";
import { Event as DeskEvent } from "../../../../api/grpc/booking/ggevent/ggevent";
import { BookingTypes } from "../../../Bookings/BookingsSearch/BookingsSearch";
import { RolePermissions } from "../../../../api/grpc/account/account";
import { ReservationEventStatus } from "./ReservationEventStatus";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import { RoomSettings } from "../RoomsBookings/RoomsBookings";
import { RESOURCE_STRINGS } from "../../../../lib/utils/constants";

interface Props {
  event: RoomEvent | DeskEvent;
  type: BookingTypes;
  workplaceID: string;
  workspaceName: string;
  locationPath: DeskLocationItem[];
  qrCodeRequired?: boolean;
  refetchDesk?: () => void;
  refetchRoom?: () => void;
  checkInTime?: number;
  settings?: RoomSettings;
  mode2Organizer?: boolean;
  showAttendees?: boolean;
}

export const ReservationEvent = ({
  event,
  type,
  workplaceID,
  workspaceName,
  locationPath,
  qrCodeRequired,
  refetchDesk,
  refetchRoom,
  checkInTime,
  settings,
  mode2Organizer,
  showAttendees,
}: Props) => {
  const { user } = useAuthContext();
  const { checkUserPermission } = useCheckUserPermissions();

  const { timeZone } = useTimeZoneContext();

  //rooms can have organizer stored in attendees
  const organizerFromAttendee = event?.attendees.find(
    (attendee) => attendee.mode1Organizer
  );

  //for desk we no need to check for mode1Organizer
  const eventOrganizer =
    event.organizer?.email === user?.email &&
    (event.organizer?.mode1Organizer || type === "desk");

  const isAuthedUsersEvent =
    eventOrganizer ||
    organizerFromAttendee?.email === user?.email ||
    mode2Organizer;

  const memberView = checkUserPermission(
    RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
  );

  const defineEventOrganizerName = () => {
    if (isAuthedUsersEvent) {
      if (organizerFromAttendee) {
        return organizerFromAttendee.displayName + " (You)";
      }

      return event.organizer?.displayName + " (You)";
    }

    if (type === "desk") {
      if (event?.organizer?.allowToFind || !memberView) {
        return event?.organizer?.displayName;
      }

      return RESOURCE_STRINGS.reserved;
    }

    //room settings:
    if (!settings?.showMeetingOrganizer || event.isPrivate) {
      return RESOURCE_STRINGS.reserved;
    }

    if (organizerFromAttendee) {
      return organizerFromAttendee.displayName || RESOURCE_STRINGS.reserved;
    }

    return event?.organizer?.displayName || RESOURCE_STRINGS.reserved;
  };

  const showTitleEvent = () => {
    if (event.title.length <= 0 || type !== "room") {
      return false;
    }

    if (isAuthedUsersEvent) {
      return true;
    }

    if (!settings?.showMeetingTitle || event.isPrivate) {
      return false;
    }

    return true;
  };

  return (
    <div className="ReservationEvent mt-4 default-box">
      <div className="flex-a-center">
        <span>
          {event.allDay === true
            ? "All day"
            : dateToHoursAndMinutes(new Date(event.startTime), timeZone) +
              " - " +
              dateToHoursAndMinutes(new Date(event.endTime), timeZone)}
        </span>

        <ReservationEventStatus
          type={type}
          isCheckOut={event.isCheckOut}
          allDay={event.allDay}
          startTime={event.startTime}
          endTime={event.endTime}
          status={event.locations[0]?.status}
        />
      </div>
      <h6 className="ReservationEvent__organizer">
        {defineEventOrganizerName()}
      </h6>

      {showTitleEvent() && (
        <span className="text-secondary d-block overflow-text ReservationEvent__title">
          {event.title}
        </span>
      )}

      {event && (
        <ReservationListActions
          type={type}
          workplaceId={workplaceID}
          event={event}
          startTime={event.startTime}
          endTime={event.endTime}
          name={workspaceName}
          locationPath={locationPath}
          checkInStatus={event.eventCheckInStatus}
          qrCodeRequired={qrCodeRequired}
          isAuthedUsersEvent={isAuthedUsersEvent}
          allowDeleteOption={true}
          // isAttendee={
          //   event.organizer?.mode1Organizer !== true &&
          //   organizerFromAttendee?.email !== user?.email
          // }
          isAttendee={false}
          refetchDesk={refetchDesk}
          refetchRoom={refetchRoom}
          checkInTime={checkInTime}
          organizerName={defineEventOrganizerName()}
          hideMeetingTitle={!showTitleEvent()}
          mode2Organizer={mode2Organizer}
          showAttendees={showAttendees}
        />
      )}
    </div>
  );
};
