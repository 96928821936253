import React from "react";
import { HandleLoadingState } from "../../../HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../LoadingBox/LoadingBox";
import Select from "react-select";
import { selectStyles } from "../../../../../lib/utils/selectStyles";
import { Icon } from "../../../Icon/Icon";
import { InputDescription } from "../../../InputDescription/InputDescription";

interface Props {
  loading: boolean;
  error: any;
  toggleModal: () => void;
  shouldDisplayEditButton?: boolean;
  options: {
    id: string;
    value: string;
    label: string;
    isGlobal: boolean;
  }[];
  selectedValue:
    | {
        label: string;
        value: string;
      }
    | {
        label: string;
        value: string;
      }[];
  onChange: (e: { label: string; value: string } | null) => void;
  isGlobalLanguage: boolean;
}

export const SelectLanguage = ({
  loading,
  error,
  toggleModal,
  shouldDisplayEditButton,
  options,
  selectedValue,
  onChange,
  isGlobalLanguage,
}: Props) => {
  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<LoadingBox minHeight={45} />}
    >
      <HandleRequestState
        state={!!error}
        placeholder={<p>{error?.message ?? "Error fetching languages"}</p>}
      >
        <div className="flex-a-center">
          <Select
            name="display.customLanguage"
            id="display.customLanguage"
            className="display-customLanguage"
            isSearchable
            options={options}
            theme={selectStyles}
            isMulti={false}
            isClearable={false}
            value={selectedValue}
            onChange={(e) => {
              if (!e) {
                return;
              }

              onChange(e);
            }}
          />

          {isGlobalLanguage && (
            <div className="flex-a-center gap-1">
              <div
                tabIndex={shouldDisplayEditButton ? 0 : -1}
                className={`flex-a-center ml-3 cursor-pointer ${
                  shouldDisplayEditButton ? "" : "disabled"
                }`}
                onClick={() => {
                  if (!shouldDisplayEditButton) {
                    return;
                  }

                  return toggleModal();
                }}
              >
                <Icon icon="edit-icon" className="mr-2" />
                <span className="label__main pt-1">Edit</span>
              </div>

              {!shouldDisplayEditButton && (
                <InputDescription
                  title="Press Save button to unlock edit."
                  className="custom-font mt-0"
                  adjustable
                />
              )}
            </div>
          )}
        </div>
      </HandleRequestState>
    </HandleLoadingState>
  );
};
