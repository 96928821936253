import React, { useMemo } from "react";
import Select from "react-select";
import { FormFeedback } from "reactstrap";

import { HandleRequestState } from "../../../HandleRequestState/HandleRequestState";
import { selectStyles } from "../../../../../lib/utils/selectStyles";
import { ContactsListResponse } from "../../../../../api/grpc/contacts/contacts";

interface Props {
  invalid: boolean;
  errorMessage: string;
  onChange: (value: string, label: string) => void;
  value: { value: string; label: string };
  isDisabled: boolean;
  loading: boolean;
  error?: Error;
  data?: ContactsListResponse;
}

export const UserListSelect = ({
  invalid,
  errorMessage,
  onChange,
  value,
  isDisabled,
  loading,
  error,
  data,
}: Props) => {
  // we need to call pageSize: 0 to fetch all users from database
  // by default it returns 10 users per request
  const options = useMemo(() => {
    return data?.contacts
      .filter((contact) => contact.desks.length <= 0)
      .map((item) => {
        return {
          value: item.id + " " + item.email + " " + item.role,
          label: item.firstName + " " + item.lastName,
        };
      });
  }, [data]);

  const userSelectedId = value.value.split(" ")[0];

  return (
    <HandleRequestState state={loading} placeholder={<p>Loading...</p>}>
      <HandleRequestState
        state={!!error}
        placeholder={<p>Couldn't load existing users, please try again!</p>}
      >
        <Select
          name="account"
          isClearable
          isSearchable
          options={options}
          theme={selectStyles}
          isDisabled={isDisabled}
          value={userSelectedId !== "undefined" ? value : null}
          onChange={(e) => {
            if (e === null) {
              onChange("", "");
              return;
            }
            onChange(e.value, e.label);
          }}
        />
        {invalid && (
          <FormFeedback className="invalid-feedback">
            {errorMessage}
          </FormFeedback>
        )}
      </HandleRequestState>
    </HandleRequestState>
  );
};
