import React from "react";
import {
  EntityType,
  LicenseFreeSlotsRequest,
  LicenseFreeSlotsResponse,
} from "../../api/grpc/subscription/subscription";
import { useSubscriptionRequests } from "../../api/grpc/subscription/useSubscriptionRequests";
import { InputDescription } from "../../components/shared/InputDescription/InputDescription";
import { LoadingBox } from "../../components/shared/LoadingBox/LoadingBox";
import useGrpcQuery from "./useGrpcQuery";

export const useDefineLicense = ({
  type,
  adjustLeft,
  isLicensed,
}: {
  type: EntityType;
  adjustLeft?: boolean;
  isLicensed?: boolean;
}) => {
  const { licenseFreeSlots } = useSubscriptionRequests();

  const { loading, error, data, refetch } = useGrpcQuery<
    Omit<LicenseFreeSlotsRequest, "customerID" | "subscriptionID">,
    LicenseFreeSlotsResponse
  >({
    method: licenseFreeSlots,
    variables: {
      type: type,
    },
  });

  const defineLicenseNumber = () => {
    if (loading) {
      return <LoadingBox minHeight={30} />;
    }

    if (error) {
      return (
        <InputDescription
          color="danger"
          title="Couldn't fetch licenses number!"
          className="InputDescription--switch"
        />
      );
    }

    const notAvailable = !data?.quantity || data.quantity <= 0;

    if (isLicensed && notAvailable) {
      return <></>;
    }

    return (
      <InputDescription
        color={notAvailable ? "danger" : "theme"}
        title={notAvailable ? "No licenses left!" : `${data?.quantity} left`}
        className={`InputDescription--switch ${
          adjustLeft ? "InputDescription--switch--left" : ""
        }`}
      />
    );
  };

  return {
    defineLicenseNumber,
    refetch,
  };
};
