import React, { useState } from "react";
import cs from "classnames";
import { toast } from "react-toastify";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { getWorkplaceIcon } from "../../../Workplaces/helpers/getWorkplaceInformation";
import { getWorkplaceChildren } from "../../../Workplaces/helpers/getWorkplaceChildren";

import { WorkplaceFields } from "../../../../api/graphql/workplaces";
import { NavLink } from "react-router-dom";
import { Icon } from "../../../shared/Icon/Icon";
import { isValidWorkplace } from "../../../Bookings/helpers/isValidWorkspace";
import {
  FavoriteWorkplace,
  FavoriteWorkplaceEnum,
} from "../../../../api/grpc/account/account";

interface Props {
  item: WorkplaceFields;
  toggleParent: (value: boolean) => void;
  pickedWorkspaces: FavoriteWorkplace[];
  innerWorkspaces: FavoriteWorkplace[];
  setPickedWorkspaces: React.Dispatch<
    React.SetStateAction<FavoriteWorkplace[]>
  >;
  setInnerWorkspaces: React.Dispatch<React.SetStateAction<FavoriteWorkplace[]>>;
}

export const AllReservationTreeLevel = (props: Props) => {
  const {
    item,
    toggleParent,
    pickedWorkspaces,
    setPickedWorkspaces,
    innerWorkspaces,
    setInnerWorkspaces,
  } = props;

  const { pathname } = useRouter();
  const isActive = pathname.includes(item.id);

  const [toggle, setToggle] = useState(() => isActive);

  const icon = getWorkplaceIcon(item.__typename);
  const workplaceChildren = getWorkplaceChildren(item);
  const hasChildren = workplaceChildren.some((item) => item && item.length > 0);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const classes = cs("WorkplacesTreeLevel", {
    "WorkplacesTreeLevel--open": toggle,
    "WorkplacesTreeLevel--active": isActive,
    "WorkplacesTreeLevel--space": !hasChildren,
  });

  const subLevelClasses = cs("WorkplacesTreeLevel__sub-level", {
    "WorkplacesTreeLevel__sub-level--open": toggle,
  });

  const handleWorkspaceClick = () => {
    if (!["Desk", "Room"].includes(item.__typename)) {
      return;
    }

    let typeOfMethod: "add" | "delete" = innerWorkspaces.some(
      (workspace) => workspace.id === item.id
    )
      ? "delete"
      : "add";

    if (typeOfMethod === "add" && innerWorkspaces.length >= 50) {
      return toast.warn(
        "You have reached the maximum number allowed for calendars"
      );
    }

    setInnerWorkspaces((prev) => {
      if (typeOfMethod === "add") {
        return [
          ...prev,
          {
            id: item.id,
            type:
              item.__typename === "Desk"
                ? FavoriteWorkplaceEnum.FavoriteWorkplace_DESK
                : FavoriteWorkplaceEnum.FavoriteWorkplace_ROOM,
          },
        ];
      }

      return prev.filter((workplaceItem) => workplaceItem.id !== item.id);
    });
  };

  return (
    <>
      <div className={classes}>
        <div className="WorkplacesTreeLevel__wrapper">
          {hasChildren && (
            <span
              onClick={handleToggle}
              className="WorkplacesTreeLevel__toggle"
            ></span>
          )}
          <NavLink
            className="WorkplacesTreeLevel__link"
            onClick={handleWorkspaceClick}
            to={"#"}
          >
            <div className="WorkplacesTreeLevel__icon">
              <Icon icon={icon} />
            </div>
            <h3 className="WorkplacesTreeLevel__name">{item.name}</h3>
          </NavLink>
          {item.__typename === "Desk" || item.__typename === "Room" ? (
            <>
              <div
                className="WorkplacesTreeLevel__status"
                onClick={handleWorkspaceClick}
              >
                <Icon
                  icon={
                    innerWorkspaces.some(
                      (formikItem) => formikItem.id === item.id
                    )
                      ? "checkbox-full"
                      : "checkbox-empty"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {hasChildren && (
        <div className={subLevelClasses}>
          {workplaceChildren.map((type) => {
            return type?.map((workplace) => {
              if (!isValidWorkplace(workplace)) {
                return null;
              }

              return (
                <AllReservationTreeLevel
                  item={workplace}
                  key={`${workplace.id}-tree-level`}
                  toggleParent={(value) => {
                    setToggle(value);
                    toggleParent(value);
                  }}
                  pickedWorkspaces={pickedWorkspaces}
                  setPickedWorkspaces={setPickedWorkspaces}
                  innerWorkspaces={innerWorkspaces}
                  setInnerWorkspaces={setInnerWorkspaces}
                />
              );
            });
          })}
        </div>
      )}
    </>
  );
};
