import React from "react";
import cs from "classnames";

import { Button, ThemeButtonProps } from "../../../../shared/Button/Button";

import { AddWorkplaceModal } from "../../AddWorkplaceModal/AddWorkplaceModal";
import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";

interface Props extends ThemeButtonProps {
  parentId?: string;
  adjustable?: boolean;
  parentType?: "Room" | "Wayfinder";
  numberOfDeviceAdded?: number;
  refetch?: () => void;
}

export const AddDeviceButton = (props: Props) => {
  const {
    outline,
    size,
    title,
    parentId,
    parentType,
    lineHeight,
    color,
    className,
    numberOfDeviceAdded,
    icon,
  } = props;
  const { isOpen, toggleModal } = useToggleModal();

  const classes = cs(
    "AddDeviceButton",
    {
      "adjustable-button": props.adjustable,
    },
    className
  );

  return (
    <div className={classes}>
      <Button
        size={size}
        color={color}
        outline={!!outline}
        onClick={toggleModal}
        lineHeight={lineHeight}
        title={title ? title : "Add device"}
        icon={icon}
      />

      {isOpen && (
        <AddWorkplaceModal
          type="Device"
          isOpen={isOpen}
          parentId={parentId}
          toggle={toggleModal}
          parentType={parentType}
          numberOfDeviceAdded={numberOfDeviceAdded}
          refetch={props.refetch}
        />
      )}
    </div>
  );
};
