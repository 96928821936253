import { DisplaySettingsFields } from "./rooms";

export const displaySettingsObj = (params: DisplaySettingsFields) => {
  return {
    adminPin: params.adminPin,
    backgroundImageUrl: params.backgroundImageUrl,
    logoImageUrl: params.logoImageUrl,
    customerId: params.customerId,
    showOrganizer: params.showOrganizer,
    showMeetingTitle: params.showMeetingTitle,
    showAttendees: params.showAttendees,
    showRoomCapacity: params.showRoomCapacity,
    showRoomAmeneties: params.showRoomAmeneties,
    showCheckinRequired: params.showCheckinRequired,
    checkinReminder: params.checkinReminder,
    pendingTimeBeforeMeeting: params.pendingTimeBeforeMeeting,
    pendingTimeAfterMeeting: params.pendingTimeAfterMeeting,
    soonFree: params.soonFree,
    enableScreenLock: params.enableScreenLock,
    screenLock: params.screenLock,
    energySaveMode: params.energySaveMode,
    energySaveModeStart: params.energySaveModeStart,
    energySaveModeEnd: params.energySaveModeEnd,
    qrCodeRequired: params.qrCodeRequired,
    contentBoardActivated: params.contentBoardActivated,
    contentBoardURL: params.contentBoardURL,
    customLanguage: !params?.customLanguage?.id
      ? null
      : {
          id: params.customLanguage?.id || "",
          value: params.customLanguage?.value || "",
          name: params.customLanguage?.name || "",
          isGlobal: params.customLanguage?.isGlobal || false,
        },
    bwFilter: params.bwFilter,
    onScreenReservation: params.onScreenReservation,
    showMeetingTitleInput: params.showMeetingTitleInput,
    requireMeetingTitleInput: params.requireMeetingTitleInput,
    showYourNameInput: params.showYourNameInput,
    requireYourNameInput: params.requireYourNameInput,
    workingHrsActivated: params.workingHrsActivated,
    requireHeadcount: params.requireHeadcount,
    oneTimePinProtection: params.oneTimePinProtection,
    workingWeekDays: params.workingWeekDays,
    workingHrsStart: params.workingHrsStart,
    workingHrsEnd: params.workingHrsEnd,
    deleteMeeting: params.deleteMeeting,
    endMeetingEarly: params.endMeetingEarly,
    extendMeeting: params.extendMeeting,
    nearbyRooms: params.nearbyRooms,
    nearbyEnabled: params.nearbyEnabled,
    mapView: params.mapView,
    floor: !params?.floor?.id
      ? null
      : {
          id: params.floor?.id || "",
        },
  } as DisplaySettingsFields;
};
