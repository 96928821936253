import React from "react";
import { Button } from "../Button/Button";

import "./LineTextDivider.scss";

interface Props {
  text?: string;
  handleClick?: () => void;
}

export const LineTextDivider = ({ text, handleClick }: Props) => {
  return (
    <div className="LineTextDivider">
      <div className="divider"></div>

      {text ? (
        <span>{text}</span>
      ) : (
        <Button onClick={handleClick} icon="plus-icon" />
      )}

      <div className="divider"></div>
    </div>
  );
};
