import React, { PropsWithChildren } from "react";
import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";

export const InsightsLocationInfo = ({
  icon,
  name,
}: PropsWithChildren<{
  icon: IconDefinition;
  name: string;
}>) => {
  return (
    <div className="InsightsLocationInfo flex-a-center">
      <Icon icon={icon} />
      <span>{name}</span>
    </div>
  );
};
