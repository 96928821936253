import React from "react";

import {
  WorkplaceList,
  WorkplaceListData,
} from "../../WorkplaceList/WorkplaceList";
import { EmptyWorkplaces } from "../../EmptyWorkplaces/EmptyWorkplaces";
import { WorkplaceTypes } from "../../AddWorkplace/AddWorkplaceButton/AddWorkplaceButton";
import { DeviceType } from "../../WorkplaceExpandedList/WorkplaceExpandedDevices/WorkplaceExpandedDevices";

interface Props {
  id: string;
  type: WorkplaceTypes;
  rows: WorkplaceListData[];
  deviceType?: DeviceType;
  hasData?: boolean;
}

export const EditViewsTab = (props: Props) => {
  const { id, type, rows, deviceType, hasData } = props;

  return (
    <>
      {!hasData ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <EmptyWorkplaces parentId={id} type={type} />
        </div>
      ) : (
        <>
          <WorkplaceList parentId={id} rows={rows} deviceType={deviceType} />
        </>
      )}
    </>
  );
};
