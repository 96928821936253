import React, { PropsWithChildren, useMemo } from "react";
import { HourlyDistribution } from "../../../../api/grpc/insights/insights";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import {
  PieChart as CustomPieChart,
  StaticLegendData,
} from "../../shared/Charts/PieChart/PieChart";
import { RoomDataIdType } from "../../shared/types/insightsTypes";

let attachColors: {
  [key in RoomDataIdType]: string;
} = {
  "0-15 min": "#00EC97",
  "15-30 min": "#2CD0AF",
  "30-60 min": "#156C79",
  "60+ min": "#000",
};

const staticLegendData: StaticLegendData[] = [
  {
    id: "0-15 min",
    color: attachColors["0-15 min"],
  },
  {
    id: "15-30 min",
    color: attachColors["15-30 min"],
  },
  {
    id: "30-60 min",
    color: attachColors["30-60 min"],
  },
  {
    id: "60+ min",
    color: attachColors["60+ min"],
  },
];

export const RoomMeetingsDistributions = ({
  data,
}: PropsWithChildren<{ data: HourlyDistribution[] }>) => {
  const dataObject = useMemo(() => {
    const filteredData = data.filter((item) => item.value > 0);

    return filteredData.map((item) => {
      return {
        id: item.id,
        label: item.label,
        value: Math.round(item.value),
        color: attachColors[item.id as RoomDataIdType],
      };
    });
  }, [data]);

  return (
    <>
      {dataObject.length <= 0 ? (
        <CenteredBox>Empty data</CenteredBox>
      ) : (
        <CustomPieChart data={dataObject} staticLegendData={staticLegendData} />
      )}
    </>
  );
};
