import React, { PropsWithChildren } from "react";

import { defineLeastMostPaddingChart } from "../../../../shared/lib/defineLeastMostPaddingChart";
import { ResponsiveBar } from "@nivo/bar";

export interface UtilizationData {
  id: string;
  name: string;
  value: number;
}

export const UtilizationOccupancyByDayOfWeek = ({
  data,
}: PropsWithChildren<{
  data: UtilizationData[];
}>) => {
  const dataByDays = data.map((item) => {
    return {
      day: item.name.slice(0, 3),
      id: item.id,
      value: Math.round(item.value),
    };
  });

  return (
    <>
      <ResponsiveBar
        data={dataByDays.reverse()}
        colors="#2CD0AF"
        indexBy="day"
        layout="horizontal"
        margin={{ top: 0, right: 40, bottom: 50, left: 45 }}
        padding={defineLeastMostPaddingChart(data.length)}
        maxValue={100}
        borderRadius={5}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          legendPosition: "middle",
        }}
        isInteractive={false}
        theme={{
          fontFamily: "Sofia Pro",
          fontSize: 13,
          grid: {
            line: {
              stroke: "#F1F5F5",
              strokeWidth: 9,
            },
          },
        }}
        role="application"
      />

      <div
        className={`UtilizationOccupancyByDayOfWeek__values UtilizationOccupancyByDayOfWeek__values--${data.length}`}
      >
        {data.map((item) => {
          return <div key={item.id}>{Math.round(item.value)}%</div>;
        })}
      </div>
    </>
  );
};
