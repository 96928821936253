import React from "react";

export const ManagedPartnerInfo = () => {
  return (
    <>
      <span>Connect your account to a GOGET Channel Partner. This will:</span>
      <ul>
        <li>Disable your access to plans & subscriptions</li>
        <li>Allow the partner to edit your plans & subscriptions</li>
        <li>Send all invoices to your partner</li>
        <li>Payment will be done by your partner</li>
      </ul>
    </>
  );
};
