import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

import { ZoneFormFields } from "../../../../../api/graphql/workplaces";
import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";

const ZoneSchema = Yup.object().shape({
  name: Yup.string().min(2, f.validName).required(f.required),
});

interface UseZoneFormOptions {
  initialValues?: ZoneFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ZoneFormFields>
  ) => Promise<any>;
}

export type ZoneFormik = ReturnType<typeof useZoneFormik>;

export const useZoneFormik = (opts: UseZoneFormOptions) => {
  return useFormik<ZoneFormFields>({
    initialValues: {
      name: "",
      description: "",
      tags: [],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ZoneSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
