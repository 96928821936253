import React from "react";
import { useDirectoryContext } from "../../../components/DirectorySync/Context/DirectoryContext";
import { DirectoryIntegrationPicker } from "../../../components/DirectorySync/DirectoryIntegrationPicker/DirectoryIntegrationPicker";
import { DirectoryRotues } from "./DirectoryRotues/DirectoryRotues";

import "./DirectorySync.scss";

export const DirectorySync = () => {
  const { loading, error, data, refetch } = useDirectoryContext();

  const getDirectoryById = (id: string) => {
    return data?.integrations.find((integration) => integration.iD === id);
  };

  return (
    <div className="default-page DirectorySync">
      <DirectoryIntegrationPicker
        loading={loading}
        error={error}
        data={data}
        refetch={refetch}
      />

      <DirectoryRotues refetch={refetch} getDirectoryById={getDirectoryById} />
    </div>
  );
};
