import React from "react";
import { FilterItem } from "../../Devices/DevicesList/DeviceFilters/DeviceFilters";
import { Button } from "../../shared/Button/Button";
import { CheckboxButton } from "../../shared/CheckboxButton/CheckboxButton";
import { ResourceFilterHeader } from "../../shared/ResourceFilter/ResourceFilterHeader";

interface Props {
  handleAssignedChange: (e: boolean | undefined) => void;
  assignedFilter?: boolean;
}

const deviceFilters: FilterItem[] = [
  {
    name: "Assigned room",
    options: [
      {
        value: "true",
        name: "assigned",
        title: "Yes",
      },
      {
        value: "false",
        name: "unassigned",
        title: "No",
      },
    ],
  },
];

export const CalendarsFilters = ({
  handleAssignedChange,
  assignedFilter,
}: Props) => {
  return (
    <div className="child-element">
      <div className="main-flex mb-2">
        <ResourceFilterHeader />

        <Button
          title="Clear"
          color="outline-secondary"
          className="child-element--clear"
          disabled={assignedFilter === undefined}
          onClick={() => {
            handleAssignedChange(undefined);
          }}
        />
      </div>

      {deviceFilters.map((filterItem, index) => {
        return (
          <div key={index}>
            <span className="child-element--head">{filterItem.name}</span>

            {filterItem.options.map((item, index) => (
              <CheckboxButton
                key={index}
                id={item.value}
                name={item.title}
                checked={
                  assignedFilter !== undefined &&
                  item.value === String(assignedFilter)
                }
                onChange={(e) => {
                  const value = e.target.value === "true" ? true : false;
                  handleAssignedChange(
                    value === assignedFilter ? undefined : value
                  );
                }}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};
