import React from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { CompleteSignupFormik } from "../../../components/CompleteSignup/lib/useCompleteSignupFormik";
import { Input } from "../../../components/shared/Input/Input";

interface Props {
  formik: CompleteSignupFormik;
  className?: string;
  customError?: boolean;
}

export const PrivacyTerms = ({ formik, className, customError }: Props) => {
  return (
    <>
      <FormGroup className={className ?? ""}>
        <Input
          type="checkbox"
          id="privacyTerms"
          name="privacyTerms"
          data-cy="privacyTerms"
          checked={formik.values.privacyTerms}
          invalid={!!formik.errors.privacyTerms && formik.touched.privacyTerms}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Label for="privacyTerms" className="checkbox_label">
          I agree with the
          <a
            target="_blank"
            className="ml-1"
            rel="noopener noreferrer"
            href="https://gogetcorp.com/privacy/"
          >
            privacy policy
          </a>
          <span className="checkbox_box"></span>
        </Label>
        {(!!formik.errors.privacyTerms && formik.touched.privacyTerms) ||
        customError ? (
          <FormFeedback>
            {formik.errors.privacyTerms ??
              "Please make sure you agree with our privacy policy terms"}
          </FormFeedback>
        ) : (
          <></>
        )}
      </FormGroup>
    </>
  );
};
