import React, { ReactNode, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import {
  ConfirmationContext,
  ConfirmationContextType,
} from "./ConfirmationContext";
import { ConfirmationModal } from "../../../components/Modals/ConfirmationModal/ConfirmationModal";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  children: ReactNode;
}

export interface ConfirmationModalState {
  title?: string;
  isOpen: boolean;
  description?: string;
  onCancel: () => void;
  onConfirm: () => void;
  keywordDelete?: string;
}

export interface ConfirmationModalOptions {
  title?: string;
  description?: string;
  keywordDelete?: string;
}

const defaultText = "Are you sure you want to perform this action?";

export const ConfirmationProvider = (props: Props) => {
  const [state, setState] = useState<ConfirmationModalState>({
    isOpen: false,
    title: defaultText,
    onCancel: () => {},
    onConfirm: () => {},
  });

  const confirm = ({
    title,
    description,
    keywordDelete,
  }: ConfirmationModalOptions) => {
    const mainTitle = title || defaultText;

    return new Promise((resolve, reject) => {
      setState((prev) => ({
        title: mainTitle,
        isOpen: !prev.isOpen,
        description: description || "",
        keywordDelete,
        onConfirm() {
          setState((prev) => ({
            ...prev,
            isOpen: false,
          }));
          resolve(true);
        },
        onCancel() {
          setState((prev) => ({
            ...prev,
            isOpen: false,
          }));
          reject("Action cancelled!");
        },
      }));
    });
  };

  const context: ConfirmationContextType = {
    isOpen: state.isOpen,
    confirm: confirm,
  };

  return (
    <ConfirmationContext.Provider value={context}>
      <ConfirmationModal {...state} />
      <ToastContainer
        enableMultiContainer
        position={toast.POSITION.TOP_RIGHT}
      />
      {props.children}
    </ConfirmationContext.Provider>
  );
};
