import React, { CSSProperties } from "react";
import Skeleton from "react-loading-skeleton";

interface Props {
  className?: string;
  minHeight?: number;
  maxWidth?: number;
  style?: CSSProperties;
  count?: number;
}

export const LoadingBox = (props: Props) => {
  const { className, minHeight, maxWidth, style, count } = props;
  return (
    <Skeleton
      height="100%"
      count={count}
      className={`LoadingBox ${className}`}
      style={{
        minHeight: minHeight ? `${minHeight}px` : "275px",
        maxWidth: maxWidth ? `${maxWidth}px` : "100%",
        borderRadius: "10px",
        ...style,
      }}
    />
  );
};
