import React from "react";

import { Map } from "../../../api/graphql/floors/floors";
import { Popup } from "../../shared/Popup/Popup";
import { FloorMapControlButton } from "../FloorMapControlButton/FloorMapControlButton";
import { FloorSettingsPopupForm } from "./FloorSettingsPopupForm/FloorSettingsPopupForm";

interface Props {
  map: Map;
}

export const FloorSettingsButton = ({ map }: Props) => {
  return (
    <Popup
      trigger={() => <FloorMapControlButton icon="settings-main" />}
      position="bottom left"
      title="Map Settings"
    >
      <FloorSettingsPopupForm map={map} />
    </Popup>
  );
};
