import { WorkplaceFields } from "../../../api/graphql/workplaces";

export const getWorkplaceChildren = (workplace: WorkplaceFields) => {
  if (workplace.__typename === "Company") {
    return [
      workplace.sites,
      workplace.buildings,
      workplace.floors,
      workplace.zones,
      workplace.rooms,
      workplace.desks,
      workplace.wayfinders,
    ];
  }

  if (workplace.__typename === "Site") {
    return [
      workplace.buildings,
      workplace.floors,
      workplace.zones,
      workplace.rooms,
      workplace.desks,
      workplace.wayfinders,
    ];
  }

  if (workplace.__typename === "Building") {
    return [
      workplace.floors,
      workplace.zones,
      workplace.rooms,
      workplace.desks,
      workplace.wayfinders,
    ];
  }

  if (workplace.__typename === "Floor") {
    return [
      workplace.zones,
      workplace.rooms,
      workplace.desks,
      workplace.wayfinders,
    ];
  }
  if (workplace.__typename === "Zone") {
    return [workplace.rooms, workplace.desks, workplace.wayfinders];
  }

  return [];
};
