//this function convert keys into more readable heading for users
//example: "nearbyRooms" -> "Nearby Rooms"

export const formatKeyName = (name: string) => {
  if (name.includes("_")) {
    name = name.split("_").join(" ");
  }
  let formatedName = name.replace(/([a-z])([A-Z])/g, "$1 $2");
  formatedName = formatedName.charAt(0).toUpperCase() + formatedName.slice(1);

  return formatedName;
};
