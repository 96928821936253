import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCustomerRequests } from "../../api/grpc/customer/useCustomerRequests";
import { useContactSalesFormik } from "../Billings/lib/useContactSalesFormik";
import ModalSidebar from "../Modals/ModalSidebar/ModalSidebar";
import { ContactSalesForm } from "./ContactSalesForm";

interface Props {
  parentId?: string;
  isOpen: boolean;
  toggle: () => void;
}

export const ContactSalesModal = (props: Props) => {
  const { isOpen, toggle } = props;
  const [loading, setLoading] = useState(false);
  const { contactSales } = useCustomerRequests();

  const formik = useContactSalesFormik({
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const { name, email, message } = values;
        await contactSales({ name, email, message });
        toast.success(
          "Thank you for contacting us, one of our team member will contact you shortly!"
        );
        toggle();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error.message);
        console.log(error.message);
      }
    },
  });

  return (
    <ModalSidebar
      title="Contact Sales Support"
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      className="ModalSidebar--contact ModalSidebar--middle"
      submitButtonLabel="Send email"
      onSubmit={formik.handleSubmit}
    >
      <ContactSalesForm
        formik={formik}
        bodyLabelText="What can we help you with?"
      />
    </ModalSidebar>
  );
};
