import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

import { useRouter } from "../../../../lib/hooks/useRouter";
import { useWorkplaceRequests } from "../../../../api/graphql/useWorkplaceRequests";
import { useCustomerContext } from "../../../../lib/context/Customer/CustomerContext";
import { useBuildingFormik } from "../../../shared/Forms/BuildingForm/lib/useBuildingFormik";

import {
  BuildingRequestVariables,
  BUILDING_FIELDS_FRAGMENT,
} from "../../../../api/graphql/buildings/buildings";
import { SubRouteProps } from "../../../../lib/routes/routes";
import { WorkplaceFields } from "../../../../api/graphql/workplaces";

import { WorkplaceListData } from "../../WorkplaceList/WorkplaceList";
import { BuildingForm } from "../../../shared/Forms/BuildingForm/BuildingForm";
import { EditWorkplaceWrapper } from "../EditWorkplaceWrapper/EditWorkplaceWrapper";
import { EditSettings } from "../EditSettings/EditSettings";
import { EditWorkplaceProps } from "../../../../views/Manage/Workplaces/EditWorkplace/EditWorkplace";
import { tooltips } from "../../../../lib/utils/tooltips";
import { EditViewsTabWorkspaces } from "../EditViewsTabWorkspaces/EditViewsTabWorkspaces";

export const EditBuilding = (props: EditWorkplaceProps) => {
  const { id } = props;
  const router = useRouter();
  const client = useApolloClient();
  const { customer } = useCustomerContext();
  const { updateBuilding } = useWorkplaceRequests();

  const [buildingData, setBuildingData] = useState<WorkplaceFields | null>(
    null
  );

  // TO DO: Move on how the data are loaded to a hook useWorkplaceData
  const updateData = useCallback(() => {
    setBuildingData(
      client.readFragment({
        id: `Building:${id}`,
        fragmentName: "BuildingFields",
        fragment: BUILDING_FIELDS_FRAGMENT,
      })
    );
  }, [id]);

  useEffect(() => {
    updateData();
  }, [id, customer, updateData]);

  const formik = useBuildingFormik({
    initialValues: {
      name: buildingData?.name || "",
      description: buildingData?.description || "",
      tags: buildingData?.tags || [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const removedTags =
          buildingData?.tags.filter((item) => !values.tags.includes(item)) ||
          [];

        const variables: BuildingRequestVariables = {
          id,
          name: values.name,
          tags: values.tags,
          description: values.description,
          tenantId: customer.tenantId,
          remove: {
            tags: removedTags,
          },
        };

        await updateBuilding(variables);

        toast.success("Building edited successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Building couldn't be updated, please reload and try again"
        );
        console.error(error.message);
      }
    },
  });

  const rows: WorkplaceListData[] = [
    {
      data: buildingData?.floors,
      type: "Floor",
    },
    {
      data: buildingData?.zones,
      type: "Zone",
    },
    {
      data: buildingData?.rooms,
      type: "Room",
    },
    {
      data: buildingData?.desks,
      type: "Desk",
    },
    {
      data: buildingData?.wayfinders,
      type: "Wayfinder",
    },
  ];

  const routes: SubRouteProps[] = [
    {
      exact: true,
      label: "View",
      to: router.match.url,
      id: "view-page-building",
      render: () => (
        <EditViewsTabWorkspaces type="Building" id={id} rows={rows} />
      ),
    },
    {
      exact: true,
      label: "Settings",
      id: "settings-page-building",
      to: `${router.match.url}/settings`,
      render: () => (
        <EditSettings
          title="General settings"
          // hasChanges={formik.dirty}
          onSubmit={formik.handleSubmit}
        >
          <BuildingForm formik={formik} />
        </EditSettings>
      ),
    },
  ];

  return (
    <EditWorkplaceWrapper
      id={id}
      type="Building"
      routes={routes}
      data={buildingData || undefined}
      message={tooltips.workplaces.building}
    />
  );
};
