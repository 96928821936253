import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { App } from "./App";
import { AuthContextProvider } from "./lib/context/Auth/AuthContextProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./components/Login/authConfig";

import "./lib/scss/main.scss";
import "reactjs-popup/dist/index.css";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </BrowserRouter>
  </MsalProvider>,
  document.getElementById("root")
);
