import React, { useState } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

import { useCustomerContext } from "../../../lib/context/Customer/CustomerContext";
import { useDevicesRequests } from "../../../api/graphql/devices/useDevicesRequests";
import { useConfirmation } from "../../../lib/context/Confirmation/ConfirmationContext";

import {
  StructureTable,
  StructureTableColumn,
  StructureTableRows,
} from "../../shared/StructureTable/StructureTable";
import { trimText } from "../../../lib/helpers/trimText";
import { WORKPLACES_ROOT_PATH } from "../../../lib/routes/routes";

import { Icon } from "../../shared/Icon/Icon";
import { DeviceFormFields } from "../../../api/graphql/workplaces";
import { DeviceFields } from "../../../api/graphql/devices/devices";
import { DeleteButton } from "../../shared/DeleteButton/DeleteButton";
import { EditDevice } from "../../Workplaces/EditWorkplace/EditDevice/EditDevice";
import { EmptyWorkplaces } from "../../Workplaces/EmptyWorkplaces/EmptyWorkplaces";
import { WorkplacePrefixIcon } from "../../Workplaces/WorkplaceExpandedList/WorkplacePrefixIcon/WorkplacePrefixIcon";
import { getDeviceAssignedName } from "../lib/getDeviceAssignedName";
import { DeviceApp } from "../DeviceApp/DeviceApp";
import { SUCCESS_STRINGS } from "../../../lib/utils/constants";

interface Props {
  data: DeviceFields[];
  onListChange?: () => void;
}

const columns: StructureTableColumn[] = [
  {
    key: "device_icon",
    header: "",
  },
  {
    key: "device_name",
    header: "Name",
  },
  {
    key: "device_connection",
    header: "Status",
  },
  {
    key: "device_app",
    header: "App",
  },
  {
    key: "device_app_version",
    header: "App version",
  },
  {
    key: "device_hardware",
    header: "Hardware",
  },
  {
    key: "device_description",
    header: "Description",
  },
  {
    key: "device_assign",
    header: "Assigned status",
  },
  {
    key: "device_delete",
    header: "",
  },
];

export const DevicesList = (props: Props) => {
  const { data, onListChange } = props;
  const confirmation = useConfirmation();
  const { customer } = useCustomerContext();
  const { disconnectDevice, unpairDevice, disconnectDeviceWayfinder } =
    useDevicesRequests();
  const [selectedDevice, setSelectedDevice] = useState<
    DeviceFormFields | undefined
  >(undefined);

  const handleDeleteClick = async (
    deviceId: string,
    workspaceId: string | null,
    attachedWorkspaceType: "room" | "wayFinder"
  ) => {
    await confirmation.confirm({
      keywordDelete: "DELETE",
      title: "Are you sure you want to delete this device?",
      description:
        "By deleting this device, it will affect the rooms or wayfinders that it has been paired to!",
    });

    try {
      if (attachedWorkspaceType === "room" && workspaceId) {
        await disconnectDevice({
          id: deviceId,
          roomId: workspaceId,
          unassignedSince: Math.floor(Date.now() / 1000),
          warnedUnassigned: true,
        });

        toast.success(SUCCESS_STRINGS.deviceDisconnected);
      }

      if (attachedWorkspaceType === "wayFinder" && workspaceId) {
        await disconnectDeviceWayfinder({
          id: deviceId,
          wayFinderId: workspaceId,
          unassignedSince: Math.floor(Date.now() / 1000),
          warnedUnassigned: true,
        });

        toast.success(SUCCESS_STRINGS.deviceDisconnected);
      }

      await unpairDevice({
        id: deviceId,
        tenantId: customer.tenantId,
      });

      if (onListChange) {
        onListChange();
      }

      toast.success("Device deleted successfully!");
    } catch (error: any) {
      console.error(error.message);
      toast.error(
        error?.message ||
          "Device couldn't be deleted, please reload and try again!"
      );
    }
  };

  const rows: StructureTableRows[] = data.map((item, index) => {
    return {
      device_icon: (
        <WorkplacePrefixIcon
          icon={
            item.typeOfWorkspace === "wayFinder"
              ? "wayFinder_icon_48x48"
              : "device_icon_48x48"
          }
        />
      ),
      device_name: (
        <h3 className="link mb-0" onClick={() => handleRowClick(index)}>
          {trimText(item.name, 15)}
        </h3>
      ),
      device_connection: (
        <div className="d-flex justify-content-center align-items-center">
          {/* we check if it's null because inONline is a new field
            and exsisting devices have set isOnline=null
          */}
          {item.isOnline || item.isOnline === null ? (
            <Icon icon="approve-icon" />
          ) : (
            <div className="offline-device">
              <Icon icon="alert-triangle" />
              <span>Offline</span>
            </div>
          )}
        </div>
      ),
      device_app: (
        <DeviceApp
          typeOfWorkspace={item.typeOfWorkspace}
          name={
            item.typeOfWorkspace === "wayFinder"
              ? "Wayfinder X"
              : "Room Display X"
          }
        />
      ),
      device_app_version: !!item.softwareInfo ? (
        <h4 className="mb-0 m-auto text-center">
          {trimText(item.softwareInfo.version, 20)}
        </h4>
      ) : (
        <h4 className="mb-0 m-auto">-</h4>
      ),
      device_hardware: !!item.hardwareInfo ? (
        <h4 className="mb-0  m-auto text-center">
          {trimText(item.hardwareInfo.model, 12)}
        </h4>
      ) : (
        <h4 className="mb-0 m-auto">-</h4>
      ),
      device_description: <span>{trimText(item.description || "-", 30)}</span>,
      device_assign: (
        <div
          className={`Devices__${
            item.typeOfWorkspace === "wayFinder" ? "wayfinder" : "room"
          }`}
        >
          {!!item.room || !!item.wayFinder ? (
            <>
              <h4 className="mb-0">Assigned</h4>

              <NavLink
                title={item[getDeviceAssignedName(item.room)]?.name}
                to={`${WORKPLACES_ROOT_PATH}/${item[
                  getDeviceAssignedName(item.room)
                ]?.__typename.toLowerCase()}/${
                  item[getDeviceAssignedName(item.room)]?.id
                }`}
              >
                ({trimText(item[getDeviceAssignedName(item.room)]?.name || "")})
              </NavLink>
            </>
          ) : (
            <h4 className="mb-0">Unassigned</h4>
          )}
        </div>
      ),
      device_delete: (
        <div className="ml-auto">
          <DeleteButton
            onClick={() =>
              handleDeleteClick(
                item.id,
                item[getDeviceAssignedName(item.room)]?.id || null,
                getDeviceAssignedName(item.room)
              )
            }
          />
        </div>
      ),
    };
  });

  const handleRowClick = (index: number) => {
    const deviceData = data[index];

    setSelectedDevice(() => {
      return {
        id: deviceData.id,
        name: deviceData.name,
        description: deviceData.description,
        type: "existing",
        typeOfWorkspace: deviceData.typeOfWorkspace,
        isOnline: deviceData.isOnline,
        unassignedSince: deviceData.unassignedSince,
        warnedUnassigned: deviceData.warnedUnassigned,
        roomId: deviceData.room?.id || "",
        wayFinderId: deviceData.wayFinder?.id || "",
        deviceId: deviceData.deviceID,
        tags: [],
        softwareInfo: deviceData.softwareInfo,
        hardwareInfo: deviceData.hardwareInfo,
        autoUpdate: deviceData.autoUpdate,
      };
    });
  };

  return (
    <>
      <StructureTable
        rows={rows}
        columns={columns}
        noResultsPlaceholder={<EmptyWorkplaces type="Device" />}
      />
      {selectedDevice !== undefined && (
        <EditDevice
          isOpen={selectedDevice !== undefined}
          data={selectedDevice}
          toggle={() => setSelectedDevice(undefined)}
        />
      )}
    </>
  );
};
