import React from "react";

import { Route, Switch } from "react-router";

// Hooks
import { useScrollToTop } from "./lib/hooks/useScrollToTop";
import { useAuthContext } from "./lib/context/Auth/AuthContext";

import { routes } from "./lib/routes";
import { AppRoute } from "./lib/AppRoute";
import { AppWrapper } from "./AppWrapper";
import { Error404 } from "./components/Error404/Error404";
import { GoGetLoader } from "./components/shared/GoGetLoader/GoGetLoader";
import { HandleLoadingState } from "./components/shared/HandleLoadingState/HandleLoadingState";

export const App = () => {
  useScrollToTop();
  const { loading } = useAuthContext();

  return (
    <HandleLoadingState loading={loading} loadingPlaceholder={<GoGetLoader />}>
      <AppWrapper>
        <Switch>
          {routes
            .filter((item) => !item.disabled)
            .map((p) => {
              return (
                <AppRoute
                  path={p.to}
                  routeType={p.type}
                  key={`${p.to}-auth`}
                  exact={p.exact ? true : undefined}
                  component={p.component || undefined}
                  permissions={p.permissions}
                />
              );
            })}
          <Route component={Error404} />
        </Switch>
      </AppWrapper>
    </HandleLoadingState>
  );
};
