import React, { PropsWithChildren } from "react";
import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import cs from "classnames";

export const RecuperationItem = ({
  icon,
  title,
  className,
}: PropsWithChildren<{
  icon: IconDefinition;
  title: string;
  className?: string;
}>) => {
  const classNames = cs("RecuperationItem flex-center", className);

  return (
    <div className={classNames}>
      <Icon icon={icon} />
      <span>{title}</span>
    </div>
  );
};
