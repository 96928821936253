import React, { useState } from "react";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";
import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import { useAccountRequests } from "../../../../../api/grpc/account/useAccountRequests";

import { ModalMFA } from "../ModalMFA";
import { ModalMFANotification } from "../ModalMFANotification/ModalMFANotification";
import { MFAType } from "../../../../../api/grpc/account/account";

export const MfaConfirm = () => {
  const { unEnrollUserForTotp, updateUser, user } = useAuthContext();
  const { setMfaModal } = useMfaContext();
  const [loading, setLoading] = useState(false);
  const { timeZone } = useTimeZoneContext();
  const { updateAccount, setRecoveryEmail } = useAccountRequests();

  const handleClick = async () => {
    try {
      if (!user) {
        return;
      }

      setLoading(true);
      await unEnrollUserForTotp();

      const {
        response: { mfaType },
      } = await updateAccount(
        {
          displayName: user.displayName,
          email: user.email.toLowerCase(),
          firstName: user.firstName,
          lastName: user.lastName,
          newPassword: "",
          phoneNumber: "",
          photoUrl: user.picture,
          googleAccountConnected: user.googleAccountConnected,
          o365AccountConnected: user.o365AccountConnected,
          googleAccountLinkedEmail: user.googleAccountLinkedEmail,
          o365AccountLinkedEmail: user.o365AccountLinkedEmail,
          timeZone,
          mfaType: MFAType.MFA_TYPE_UNKNOWN,
          recoveryEmail: "",
        },
        ["mfaType", "recoveryEmail"]
      );

      if (!!user.recoveryEmail) {
        await setRecoveryEmail("");
      }

      updateUser({
        mfaType: mfaType,
        recoveryEmail: "",
      });

      setMfaModal("disabled");
    } catch (error: any) {
      if (
        ["auth/requires-recent-login", "auth/user-token-expired"].includes(
          error.code
        )
      ) {
        setMfaModal("authentication");
        return;
      }

      setMfaModal("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalMFA
      title="Disable two-factor authentication"
      nextButton="Confirm"
      closeButton="Close"
      handleBackButton={() => setMfaModal(undefined)}
      handleNextButton={handleClick}
      disabledNext={loading}
      disabledBack={loading}
      className="ModalResponse"
    >
      <ModalMFANotification icon="question-mark" title="Are you sure?" />
    </ModalMFA>
  );
};
