import React from "react";
import { LabelWithInformationBox } from "../../../../LabelWithInformationBox/LabelWithInformationBox";
import { RadioButton } from "../../../../RadioButton/RadioButton";

interface Props {
  scalingVal: string;
  onChange: (key: string, value: string) => void;
}

const radioOptions = [
  {
    title: "1x",
    value: "1",
  },
  {
    title: "0.85x",
    value: "0.85",
  },
  {
    title: "0.7x",
    value: "0.7",
  },
  {
    title: "0.5x",
    value: "0.5",
  },
];

const MarkerScaleOption = ({ scalingVal, onChange }: Props) => {
  return (
    <div className="MarkerScaleOption flex-a-center flex-wrap pt-4">
      <LabelWithInformationBox
        className="pr-3"
        title="Map marker scaling factor"
      />

      <div className="flex-a-center flex-wrap">
        {radioOptions.map((option) => (
          <RadioButton
            key={option.value}
            title={option.title}
            name="scalingFactor"
            checked={option.value === scalingVal}
            onChange={(value) => {
              onChange("display.scalingFactor", value.target.value);
            }}
            value={option.value}
            className="pr-4"
          />
        ))}
      </div>
    </div>
  );
};

export default MarkerScaleOption;
