import {
  ListDeskBookingRequest,
  ListDesksResponse,
} from "../../../../api/grpc/desk/desk";
import { useDeskBookingRequest } from "../../../../api/grpc/desk/useDeskBookingRequest";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";

export const useDeskReservations = () => {
  const { listDeskBooking } = useDeskBookingRequest();

  const {
    loading: loadingDesk,
    error: errorDesk,
    data: deskData,
    refetch: refetchDesk,
  } = useGrpcQuery<ListDeskBookingRequest, ListDesksResponse>({
    method: listDeskBooking,
    variables: {
      accountID: "",
      customerID: "",
    },
  });

  return {
    loadingDesk,
    errorDesk,
    deskData,
    refetchDesk,
  };
};
