import React from "react";
import { Button } from "../../shared/Button/Button";
import { UploadImage } from "../../shared/UploadImage/UploadImage";

import "./FloorEmptyMap.scss";

interface Props {
  floorId?: string;
  typeOfFloorMap?: "bookings" | "floor";
}

export const FloorEmptyMap = ({ floorId, typeOfFloorMap }: Props) => {
  return (
    <div className="FloorEmptyMap">
      <h4 className="heading-empty">
        It seems that there isn’t any map added!
      </h4>
      {typeOfFloorMap === "floor" && (
        <UploadImage
          title="Upload your floor plan"
          floorId={floorId}
          buttonText={<Button title="Add map" size="medium" color="primary" />}
          onUpload={() => {}}
        />
      )}
    </div>
  );
};
