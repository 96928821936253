import React, { useState } from "react";
import { DirectorySyncIntegration } from "../../../api/grpc/calendarprovider/calendarprovider";
import { ReAuthModal } from "../../Modals/ReAuthModal/ReAuthModal";
import { useDirectoryContext } from "../Context/DirectoryContext";
import { IntegrationBanner } from "../../Calendars/shared/IntegrationBanner/IntegrationBanner";
import { NavLink } from "react-router-dom";
import { getCalendarTypeSlug } from "../../Calendars/lib/helpers";

export const DirectoryIntegrationBanner = ({
  directory,
}: {
  directory: DirectorySyncIntegration;
}) => {
  const [openReAuthModal, setOpenReAuthModal] = useState(false);
  const {
    handleFixDirectoryButton,
    brokenIntegrations,
    setBrokenIntegrations,
  } = useDirectoryContext();

  const handleCloseBanner = () => {
    setBrokenIntegrations((prev) => prev.filter((p) => p.iD !== directory.iD));
  };

  if (!brokenIntegrations.some((provider) => provider.iD === directory.iD))
    return null;

  return (
    <>
      {openReAuthModal && (
        <ReAuthModal
          closeModal={() => setOpenReAuthModal(false)}
          handleReAuthButton={() => handleFixDirectoryButton(directory.iD)}
          type="Directory"
        />
      )}

      <IntegrationBanner
        header={
          <span className="ml-2">
            Directory integration
            <NavLink
              to={`/users/sync/${getCalendarTypeSlug(
                directory.integrationType
              )}/${directory.iD}`}
              className="px-1"
            >
              {directory.name}
            </NavLink>
            re-authentication required
          </span>
        }
        reAuthButton={() => handleFixDirectoryButton(directory.iD)}
        setOpenReAuthModal={() => {
          setOpenReAuthModal(true);
        }}
        handleCloseButton={() => handleCloseBanner()}
        classes="TokenToast TokenToast__admins TokenToast__show"
      />
    </>
  );
};
