import React, { useState } from "react";
import { isString } from "lodash";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { useDeskFormik } from "../../../shared/Forms/DeskForm/lib/useDeskFormik";
import { useTemplateFormik } from "../../lib/useTemplateFormik";
import { useTemplatesClient } from "../../../../api/grpc/workplaces/useTemplatesClient";
import { useDeskTemplateContext } from "../../../../lib/context/Templates/DeskTemplate/DeskTemplateContext";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useScrollToTop } from "../../../../lib/hooks/useScrollToTop";

import { TEMPLATE_ROOT_PATH } from "../../../../lib/routes/routes";
import { tooltips } from "../../../../lib/utils/tooltips";

import { PageHeader } from "../../../shared/PageHeader/PageHeader";
import { Button } from "../../../shared/Button/Button";
import { DeskProfileForm } from "./Form/DeskProfileForm";
import { CancelProfileButton } from "../../shared/CancelProfileButton";

import "./AddDeskProfile.scss";

export const AddDeskProfile = () => {
  useScrollToTop();
  const [loading, setLoading] = useState(false);

  const { createDeskTemplate } = useTemplatesClient();
  const { refetchDeskTemplates } = useDeskTemplateContext();
  const { history } = useRouter();
  const { user } = useAuthContext();

  const templateFormik = useTemplateFormik({
    onSubmit: async () => {},
  });

  const formik = useDeskFormik({
    onSubmit: async () => {},
  });

  const handleCreateDeskProfile = async () => {
    try {
      if (
        Object.keys(templateFormik.errors).length ||
        templateFormik.values.profileName.length <= 0
      ) {
        toast.error(
          "Field validation failed. Make sure you write data as validation rules require."
        );
        return;
      }

      setLoading(true);
      const {
        checkInTime,
        description,
        isBlocked,
        qrCodeRequired,
        showCheckInTime,
        tags,
        workingHrsActivated,
        workingHrsEnd,
        workingHrsStart,
        account,
      } = formik.values;

      const emailAndIdOfAccount = account?.accountID.split(" ") || [];
      const idOfAccount = emailAndIdOfAccount[0];
      const emailOfAccount = emailAndIdOfAccount[1];

      const {
        response: { id },
      } = await createDeskTemplate({
        description: templateFormik.values.profileDescription,
        name: templateFormik.values.profileName,
        deskSettings: {
          checkInTime,
          description,
          isBlocked: isBlocked || false,
          qrCodeRequired,
          showCheckInTime,
          tags: tags || [],
          workingHrsActivated,
          workingHrsStart: !!workingHrsStart
            ? isString(workingHrsStart) && workingHrsStart.length <= 9
              ? workingHrsStart
              : format(new Date(workingHrsStart || ""), "HH:mm:ss")
            : "",
          workingHrsEnd: !!workingHrsEnd
            ? isString(workingHrsEnd) && workingHrsEnd.length <= 9
              ? workingHrsEnd
              : format(new Date(workingHrsEnd || ""), "HH:mm:ss")
            : "",
          account: account?.accountID
            ? {
                accountID: idOfAccount,
                displayName: account.displayName,
                email: emailOfAccount,
                tenantID: user?.customerid || "",
              }
            : undefined,
        },
      });

      toast.success("Desk template added successfully.");

      await refetchDeskTemplates();

      // history.push(TEMPLATE_ROOT_PATH);
      history.push(`${TEMPLATE_ROOT_PATH}/edit-desk/${id}`);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="TemplateManage">
      <PageHeader
        title="Add desk template"
        hideBorder
        message={tooltips.templates.addDeskTemplate}
      />
      <div className="flex-a-center justify-content-end gap-1 mb-2">
        <CancelProfileButton />

        <Button
          color="primary"
          size="small"
          title="Save"
          onClick={handleCreateDeskProfile}
          disabled={loading}
        />
      </div>

      <DeskProfileForm formik={formik} templateFormik={templateFormik} />
    </div>
  );
};
