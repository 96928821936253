import React from "react";
import cs from "classnames";
import { Icon } from "../../../shared/Icon/Icon";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";

interface Props {
  content: string | JSX.Element;
  className?: string;
  icon?: IconDefinition;
}

export const UserInfoRowItem = ({ icon, className, content }: Props) => {
  return (
    <div className={cs("flex-a-center UserInfoRowItem", className)}>
      <Icon icon={icon} />
      <span>{content}</span>
    </div>
  );
};
