import React, { PropsWithChildren } from "react";
import { ItemStats } from "../../../../../../api/grpc/insights/insights";
import { ResponsiveBarChart } from "../../../../Room/Utilization/UtilizationOccupancy/UtilizationOccupancyMostLeast/ResponsiveBarChart";

export const MostAndLeastPopularDesk = ({
  data,
  type,
}: PropsWithChildren<{ data: ItemStats[]; type: "most" | "least" }>) => {
  let colorOfData = type === "most" ? "#29C0A2" : "#E24A1A";

  let definedData: {
    id: string;
    name: string;
    value: number;
    color: string;
  }[] = data.map((item) => {
    return {
      id: item.id,
      value: Math.round(item.value),
      name: item.name + "-innerGoGetIDSplitter-" + item.id,
      color: colorOfData,
    };
  });

  return <ResponsiveBarChart data={definedData} dataLength={data.length} />;
};
