import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { usePartnerData } from "../lib/usePartnerData";
import { useCardPayment } from "../../../api/grpc/customer/useCardPayment";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import useImage from "../../../lib/hooks/useImage";
import { useToggleModal } from "../../../lib/hooks/useToggleModal";
import { useCustomerDetails } from "../lib/useCustomerDetails";
import { useOpenBillingInfo } from "../lib/useOpenBillingInfo";
import { openCustomerPortal } from "../../Subscription/lib/openCustomerPortal";

import { PaymentCardResponse } from "../../../api/grpc/subscription/subscription";
import { HandleRequestState } from "../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { PaymentCard } from "./PaymentCard/PaymentCard";
import { AddInfoModal } from "../shared/AddInfoModal";

import "./PaymentMethod.scss";

export const PaymentMethod = () => {
  const { getPaymentCard } = useCardPayment();
  const { user, tokenInterceptor, chargebeeProperties } = useAuthContext();
  const { data: partnerData } = usePartnerData();
  const { isOpen, toggleModal } = useToggleModal();
  const [openBillingInfo, setOpenBillingInfo] = useState(false);

  const {
    loading: customerLoading,
    error: customerError,
    refetch: refetchCustomer,
    hasRequiredFieldsAdded,
  } = useCustomerDetails();

  const { loading, error, data, refetch } = useGrpcQuery<
    {},
    PaymentCardResponse
  >({
    method: getPaymentCard,
    variables: {},
  });
  const { loading: loadingImage, image } = useImage(data?.brand || "");

  const [hasCard, setHasCard] = useState<boolean>(!!data?.brand);

  const { handleBillingModal } = useOpenBillingInfo(() => {
    refetchCustomer();
    setOpenBillingInfo(false);
  });

  useEffect(() => {
    if (data?.brand) {
      return setHasCard(true);
    }

    setHasCard(false);
  }, [data]);

  const handleOpenWidget = async () => {
    if (!hasRequiredFieldsAdded) {
      return setOpenBillingInfo(true);
    }
    try {
      const customerPortal = await openCustomerPortal(
        user?.customerid || "",
        tokenInterceptor
      );

      return customerPortal?.cbPortal.open(
        {
          paymentSourceAdd: () => refetch(),

          paymentSourceRemove: () => setHasCard(false),

          paymentSourceUpdate: () => refetch(),
        },
        chargebeeProperties("PAYMENT_SOURCES")
      );
    } catch (error: any) {
      toast.error(
        "Failed to fetch your data to open widget, please reload and try again!"
      );
    }
  };

  return (
    <HandleRequestState
      state={loading || customerLoading}
      placeholder={
        <>
          <div className="PaymentMethod--loading main-grid">
            <LoadingBox
              minHeight={195}
              className="main-grid"
              style={{ marginBottom: 20 }}
            />
            <LoadingBox minHeight={195} />
          </div>
        </>
      }
    >
      <HandleRequestState
        state={!!error || !!customerError}
        placeholder={<p>Error loading subscription information</p>}
      >
        <div className="PaymentMethod main-grid">
          <PaymentCard
            hasCard={hasCard}
            isOpen={isOpen}
            toggleModal={toggleModal}
            loading={loadingImage}
            image={image}
            cardNumber={data?.maskedNumber}
            openPortal={handleOpenWidget}
            disabled={!!partnerData?.email}
          />
        </div>

        {openBillingInfo && (
          <AddInfoModal
            handleBillingModal={handleBillingModal}
            toggleModal={() => setOpenBillingInfo(false)}
          />
        )}
      </HandleRequestState>
    </HandleRequestState>
  );
};
