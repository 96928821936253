import React from "react";
import { Button } from "reactstrap";

import "./CalendarsWizardFooter.scss";

interface Props {
  activeStep: number;
  createButton?: boolean;
  disabledNext?: boolean;
  activeCalendar?: string;
  handlePrevClick: () => void;
  handleNextClick?: () => void;
  handleSubmitClick?: () => void;
  nextButtonTitle?: string;
}

export const CalendarsWizardFooter = (props: Props) => {
  return (
    <div className="CalendarsWizardFooter pt-3 mt-4 border-top">
      {props.activeStep !== 1 && (
        <Button
          size="small"
          color="secondary"
          onClick={props.handlePrevClick}
          disabled={props.activeStep === 1}
          className="CalendarsWizardFooter__button CalendarsWizardFooter__button--prev"
        >
          Previous
        </Button>
      )}

      <NextButton
        createButton={props.createButton}
        disabledNext={props.disabledNext}
        activeCalendar={props.activeCalendar}
        handleNextClick={props.handleNextClick}
        handleSubmitClick={props.handleSubmitClick}
        nextButtonTitle={props.nextButtonTitle}
      />
    </div>
  );
};

interface NextButtonProps {
  createButton?: boolean;
  disabledNext?: boolean;
  activeCalendar?: string;
  handleNextClick?: () => void;
  handleSubmitClick?: () => void;
  nextButtonTitle?: string;
}

const NextButton = (props: NextButtonProps) => {
  if (props.activeCalendar === "gsuite" || props.activeCalendar === "o365") {
    return <></>;
  }

  if (props.createButton) {
    return (
      <Button
        size="small"
        color="primary"
        disabled={props.disabledNext}
        onClick={props.handleSubmitClick}
        className="CalendarsWizardFooter__button CalendarsWizardFooter__button--next ml-auto"
      >
        {props.nextButtonTitle ?? "Create"}
      </Button>
    );
  }

  return (
    <Button
      size="small"
      color="primary"
      disabled={props.disabledNext}
      onClick={props.handleNextClick}
      className="CalendarsWizardFooter__button CalendarsWizardFooter__button--next ml-auto"
    >
      Next
    </Button>
  );
};
