import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { differenceInMinutes } from "date-fns";

import { timeFormat } from "../../helpers/displayTimeFormat";
import { TimeSelector } from "../TimeSelector/TimeSelector";
import { Icon } from "../../../shared/Icon/Icon";
import { timePickerProps } from "../../helpers/timePickerProps";

interface Props {
  onChange: (date: Date | null) => void;
  endTime: Date;
  startTime: Date;
  filterPassedTime: (time: Date) => boolean;
  handleDurationChange: (hour: number, minute: number) => void;
  disabled?: boolean;
}

export const EndTimeInputs = ({
  onChange,
  endTime,
  startTime,
  filterPassedTime,
  handleDurationChange,
  disabled,
}: Props) => {
  const [selectedHour, setSelectedHour] = useState<number | null>(null);
  const [selectedMinute, setSelectedMinute] = useState<number | null>(15);

  const updateForFieldFromTime = (start: Date, end: Date) => {
    const duration = differenceInMinutes(end, start);
    const hour = Math.floor(duration / 60);
    const minute = duration % 60;
    setSelectedHour(hour);
    setSelectedMinute(minute);
  };

  useEffect(() => {
    updateForFieldFromTime(startTime, endTime);
  }, [startTime, endTime]);

  return (
    <>
      <TimeSelector
        selectedHour={selectedHour}
        selectedMinute={selectedMinute}
        onDurationChange={(hour: number, minute: number) => {
          setSelectedHour(hour);
          setSelectedMinute(minute);

          handleDurationChange(hour, minute);
        }}
      />

      <Icon icon="time-divider" className="mx-2" />

      <div className="flex-1">
        <label>Until</label>
        <DatePicker
          {...timePickerProps}
          selected={endTime}
          onChange={onChange}
          filterTime={filterPassedTime}
          disabled={disabled}
        />
      </div>
    </>
  );
};
