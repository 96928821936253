import React from "react";

import { useDeskBehaviourInsightsContextContext } from "../../../Context/Desk/Behaviour/BehaviourContext";
import { useSortInsightsData } from "../../../shared/lib/useSortInsightsData";
import { useInsightsContext } from "../../../Context/InsightsContext";

import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { RealizedMeetingsFunnel } from "../../../Room/RoomRelease/RealizedMeetingsFunnel/RealizedMeetingsFunnel";
import { RoomMeetingBehavior } from "../../../Room/RoomRelease/RoomReleaseBehaviour/RoomMeetingBehavior/RoomMeetingBehavior";
import { ChartCard } from "../../../shared/ChartCard/ChartCard";
import { MostAndLeastPopularDesk } from "../../Utilization/DeskUtilizationOccupancy/MostAndLeastPopularDesk/MostAndLeastPopularDesk";
import { tooltips } from "../../../../../lib/utils/tooltips";

export const DeskReleaseBehaviour = () => {
  const {
    insightsLocation: { locationType },
  } = useInsightsContext();

  const {
    loadingMeetings: loading,
    errorMeetings: error,
    dataMeetings: data,
  } = useDeskBehaviourInsightsContextContext();

  const mostAndLeastNoShowsSortedData = useSortInsightsData(
    data?.desksByNoShows
  );

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={
        <div className="mt-3 WorkspaceValueInsights__statistics">
          <LoadingBox minHeight={170} />
          <LoadingBox minHeight={170} />
          <LoadingBox minHeight={170} />
          <LoadingBox minHeight={170} />
        </div>
      }
    >
      <HandleRequestState
        state={!!error}
        placeholder={<CenteredBox>{error?.message}</CenteredBox>}
      >
        <div className="UtilizationOccupancy WorkspaceValueInsights__statistics">
          <ChartCard
            title="Reservation Behavior"
            message={tooltips.insights.desks.reservationBehavior}
            adjustInfoLeft
            className="ChartCard__occupancy"
          >
            {!!data?.adHocEvents || !!data?.plannedEvents ? (
              <RoomMeetingBehavior
                adHoc={data?.adHocEvents}
                planned={data?.plannedEvents}
              />
            ) : (
              <CenteredBox className="d-block">No reservations</CenteredBox>
            )}
          </ChartCard>

          {locationType !== "Desk" && (
            <ChartCard
              title="Least No-shows"
              message={tooltips.insights.desks.leastNoShows}
              className="ChartCard__occupancy"
            >
              {!!data?.desksByNoShows.length ? (
                <MostAndLeastPopularDesk
                  data={mostAndLeastNoShowsSortedData.slice(
                    Math.max(mostAndLeastNoShowsSortedData.length - 6, 0)
                  )}
                  type="most"
                />
              ) : (
                <CenteredBox className="CenteredBox__disableHeight">
                  Empty data
                </CenteredBox>
              )}
            </ChartCard>
          )}

          {locationType !== "Desk" && (
            <ChartCard
              title="Most No-shows"
              message={tooltips.insights.desks.mostNoShows}
              adjustInfoLeft
              className="ChartCard__occupancy"
            >
              {!!data?.desksByNoShows.length ? (
                <MostAndLeastPopularDesk
                  data={mostAndLeastNoShowsSortedData.slice(0, 6)}
                  type="least"
                />
              ) : (
                <CenteredBox className="CenteredBox__disableHeight">
                  Empty data
                </CenteredBox>
              )}
            </ChartCard>
          )}

          <ChartCard
            title="Realized Reservations Funnel"
            message={tooltips.insights.desks.realizedReservationsFunnel}
            className="ChartCard__occupancy"
          >
            {!!data?.funnel.length ? (
              <RealizedMeetingsFunnel data={data.funnel} />
            ) : (
              <CenteredBox className="CenteredBox__disableHeight">
                Empty data
              </CenteredBox>
            )}
          </ChartCard>
        </div>
      </HandleRequestState>
    </HandleLoadingState>
  );
};
