import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormGroup } from "reactstrap";
import { Input } from "../../../../shared/Input/Input";
import { CustomerSettingsVariables } from "../../InsightsCard/Insights/Insights";
import { OrganizationSettingsModal } from "../../shared/OrganizationSettingsModal";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  email?: string;
  handleErrorReportingEmail: ({
    errorReportingEmail,
  }: CustomerSettingsVariables) => void;
  loading: boolean;
  error?: Error;
}

export const ServiceEmailModal = ({
  isOpen,
  setIsOpen,
  email,
  handleErrorReportingEmail,
  error,
  loading,
}: Props) => {
  const [emailToChange, setEmailToChange] = useState<string | undefined>("");

  const updateData = useCallback(() => {
    setEmailToChange(email);
  }, [email]);

  useEffect(() => {
    updateData();
  }, [email]);

  return (
    <OrganizationSettingsModal
      title="Service emails"
      formTitle="Service emails are used for sending service messages"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      error={error}
      disabled={
        !/\S+@\S+\.\S+/.test(emailToChange ? emailToChange : "") ||
        email === emailToChange
      }
      toggleModal={() => {
        setEmailToChange(email);
        setIsOpen(false);
        return;
      }}
      handleSave={() => {
        handleErrorReportingEmail({
          errorReportingEmail: emailToChange,
        });
        setIsOpen(false);
        return;
      }}
    >
      <FormGroup className="w-100">
        <label className="label__main">Reporting email</label>

        <Input
          type="email"
          name="errorReportingEmail"
          id="errorReportingEmail"
          value={emailToChange}
          errorMessage="Please add a valid email"
          invalid={!/\S+@\S+\.\S+/.test(emailToChange ? emailToChange : "")}
          onChange={(e) => setEmailToChange(e.target.value)}
        />
      </FormGroup>
    </OrganizationSettingsModal>
  );
};
