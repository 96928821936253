import { toast } from "react-toastify";
import { useCustomerRequests } from "../../../api/grpc/customer/useCustomerRequests";
import { useGlobalWorkingHours } from "../../../lib/hooks/useGlobalWorkingHours";
import { CustomerSettingsVariables } from "../../Organization/OrganizationSettings/InsightsCard/Insights/Insights";
import { defineBooleanValue } from "../../../lib/helpers/defineBooleanValue";

export const useWorkingHoursData = () => {
  const { loading, error, customerSettings, refetch } = useGlobalWorkingHours();
  const { updateCustomerSettings } = useCustomerRequests();

  const handleWorkingHours = async ({
    startTime,
    endTime,
    errorReportingEmail,
    meetingSpaceCostPerHr,
    currency,
    workingWeekDays,
    collectInsights,
    ssoEnable,
    disableDeskSimultaneous,
    showEventOrganizer,
  }: CustomerSettingsVariables) => {
    try {
      if (!!customerSettings) {
        await updateCustomerSettings({
          startTime: startTime || customerSettings.startTime,
          endTime: endTime || customerSettings.endTime,
          errorReportingEmail:
            errorReportingEmail || customerSettings.errorReportingEmail,
          meetingSpaceCostPerHr:
            meetingSpaceCostPerHr || customerSettings.meetingSpaceCostPerHr,
          currency: currency || customerSettings?.currency,
          workingWeekDays: workingWeekDays || customerSettings.workingWeekDays,
          collectInsights: defineBooleanValue(
            collectInsights,
            customerSettings.collectInsigths
          ),
          ssoEnable: defineBooleanValue(ssoEnable, customerSettings.ssoEnable),
          disableDeskSimultaneous: defineBooleanValue(
            disableDeskSimultaneous,
            customerSettings.disableDeskSimultaneous
          ),
          showEventOrganizer: defineBooleanValue(
            showEventOrganizer,
            customerSettings.showEventOrganizer
          ),
        });

        toast.success("Customer settings updated successfuly");
        refetch();
        return;
      }
    } catch (error: any) {
      toast.error(
        error.message
          ? error.message
          : "Cannot set working hours, please try again"
      );
    }
  };

  return { loading, error, customerSettings, handleWorkingHours, refetch };
};
