import React from "react";

import { ApplyProfilesSteps } from "../../../components/SettingsProfiles/ManageProfiles/ApplyProfiles/ApplyProfilesSteps";
import { PageHeader } from "../../../components/shared/PageHeader/PageHeader";
import { tooltips } from "../../../lib/utils/tooltips";

export const TransferProfiles = () => {
  return (
    <>
      <PageHeader
        title="Transfer settings template"
        message={tooltips.templates.transferSettings}
      />

      <ApplyProfilesSteps />
    </>
  );
};
