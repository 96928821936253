import React, { useMemo } from "react";

import { useBehaviourInsightsContext } from "../../../Context/Room/Behaviour/BehaviourContext";

import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../../shared/LoadingBox/LoadingBox";
import { ChartCard } from "../../../shared/ChartCard/ChartCard";
import { SankeyChart } from "../../../shared/Charts/SankeyChart/SankeyChart";
import { tooltips } from "../../../../../lib/utils/tooltips";

export const RoomReleaseSankey = () => {
  const {
    loadingSankey: loading,
    errorSankey: error,
    dataSankey: data,
  } = useBehaviourInsightsContext();

  const checkAllValuesAreZero = useMemo(() => {
    // this is a case when all objects from links array have property value to 0
    // for some reasons nivo library takes them as NaN and doesn't display correct chart
    // so we have to check if this is the case then display a message instead of chart

    if (!data) {
      return;
    }

    return data.links.every((item) => item.value === 0);
  }, [data]);

  return (
    <HandleLoadingState
      loading={loading}
      loadingPlaceholder={<LoadingBox className="mt-4" minHeight={400} />}
    >
      <ChartCard
        title="Path exploration"
        message={tooltips.insights.rooms.pathExploration}
        direction="right"
        className={`mt-4 RoomReleaseSankey ${
          data && data.nodes.length > 7 && !checkAllValuesAreZero
            ? "RoomReleaseSankey__max"
            : ""
        }`}
      >
        <HandleRequestState
          state={!!error}
          placeholder={<CenteredBox>{error?.message}</CenteredBox>}
        >
          {data && !!data.links.length && !!data.nodes.length ? (
            checkAllValuesAreZero ? (
              <CenteredBox>
                Can't display data since they all have 0 values
              </CenteredBox>
            ) : (
              <SankeyChart links={data.links} nodes={data.nodes} type="Room" />
            )
          ) : (
            <CenteredBox>Empty data</CenteredBox>
          )}
        </HandleRequestState>
      </ChartCard>
    </HandleLoadingState>
  );
};
