import React, { useEffect } from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useWorkplaceRequests } from "../../../api/graphql/useWorkplaceRequests";
import { PageSidebar } from "../PageSidebar/PageSidebar";
import { PageSidebarHeader } from "../PageSidebar/PageSidebarHeader/PageSidebarHeader";
import { Icon } from "../Icon/Icon";
import { HandleLoadingState } from "../HandleLoadingState/HandleLoadingState";
import { WorkplacesTreeLoader } from "../../../views/Manage/Workplaces/WorkplacesTree/WorkplacesTreeLoader/WorkplacesTreeLoader";
import { ApolloErrorGuard } from "../ApolloErrorGuard/ApolloErrorGuard";
import { WorkplaceFields } from "../../../api/graphql/workplaces";

interface Props {
  message: string;
  closePicker: () => void;
  description: string;
  children: (data: {
    rootWorkplaces: WorkplaceFields[][];
    loading: boolean;
    error: any;
  }) => React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
}

export const WorkspacePicker = ({
  message,
  description,
  closePicker,
  children,
  footer,
  title,
}: Props) => {
  const { user } = useAuthContext();
  const { getRootWorkplaces } = useWorkplaceRequests();
  const [request, { loading, error, data }] = getRootWorkplaces;

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item): item is WorkplaceFields[] => Array.isArray(item)
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  return (
    <PageSidebar
      className={`PageSidebar--default PageSidebar--toggled PageSidebar--Custom PageSidebar--Wayfinder`}
      showOnInitialization={false}
      showOnDesktop={false}
      hideToggler
    >
      <PageSidebarHeader
        title={title || "Workspace picker"}
        message={message}
        direction="left"
      />

      <div onClick={closePicker} className="PageSidebar--Wayfinder--closer">
        <Icon icon="close-icon" />
      </div>

      <div
        className="
      PageSidebar--Wayfinder--desc
      "
      >
        {description}
      </div>

      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<WorkplacesTreeLoader />}
      >
        <ApolloErrorGuard
          error={error}
          errorComponent={<p>Data couldn't be loaded.</p>}
        >
          <div className="PageSidebar--Wayfinder--items">
            {children({ rootWorkplaces, loading, error })}
          </div>
        </ApolloErrorGuard>
      </HandleLoadingState>
      {footer && <div>{footer}</div>}
    </PageSidebar>
  );
};
