import React, { useEffect } from "react";

import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../../../lib/context/Auth/AuthContext";
import { useSelectedFloor } from "../../../../../lib/hooks/useSelectedFloor";
import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";

import { WorkplaceFields } from "../../../../../api/graphql/workplaces";
import { selectFloorForWorkspace } from "../../../../../lib/helpers/selectFloorForWorkspace";
import { tooltips } from "../../../../../lib/utils/tooltips";
import { FilteredWorkplacesTree } from "../../../../../views/Manage/Workplaces/FilteredWorkplaces/FilteredWorkplacesTree";
import { Button } from "../../../Button/Button";
import { FormSection } from "../../../FormSection/FormSection";
import { FormSubSection } from "../../../FormSubSection/FormSubSection";
import { Switch } from "../../../Switch/Switch";
import { WayfinderDisplaySettingsFormik } from "../lib/useWayfinderDisplaySettingsForm";
import { SwitcherTempo } from "../SwitcherTempo/SwitcherTempo";
import MarkerScaleOption from "../shared/MarkerScale/MarkerScaleOption";

interface Props {
  formik: WayfinderDisplaySettingsFormik;
  onChange: (
    key: string,
    value: string | string[] | number | boolean | Date | null
  ) => void;
}

export const WayFinderDisplaySettings = ({
  formik,
  formik: {
    values: { display },
  },
  onChange,
}: Props) => {
  const { toggleModal, isOpen } = useToggleModal();
  const { user } = useAuthContext();
  const { getRootWorkplaces } = useWorkplaceRequests();
  const [request, { loading, error, data }] = getRootWorkplaces;

  const selectedFloorName = useSelectedFloor(display.floor);

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  const handleWorkspacePickerItemClick = (item: WorkplaceFields) => {
    return selectFloorForWorkspace(item, formik, "display.floor");
  };

  return (
    <FormSection title="Display settings" toggleable>
      {isOpen && (
        <>
          <FilteredWorkplacesTree
            showOnDesktop={false}
            loading={loading}
            error={error}
            rootWorkplaces={rootWorkplaces}
            showOnInitialization={false}
            isPickerOnly
            isWayfinder
            className="PageSidebar--toggled PageSidebar--Custom"
            onTitleClick={toggleModal}
            onItemClick={handleWorkspacePickerItemClick}
            pathTo="#"
            hasCloseButton
            handleClearFloorMap={() => {
              if (display.floor) {
                return onChange("display.floor", null);
              }
            }}
          />
          <div onClick={toggleModal} className="background__shadow"></div>
        </>
      )}
      <FormSubSection>
        <Switch
          name="display.showOrganizer"
          value={display.showOrganizer}
          title="Show meeting organizer"
          message={tooltips.rooms.display.showOrganizer}
          onChange={(value) => {
            onChange("display.showOrganizer", value);
          }}
        />
        <Switch
          name="display.showMeetingTitle"
          value={display.showMeetingTitle}
          title="Show meeting title"
          message={tooltips.rooms.display.showTitle}
          onChange={(value) => {
            onChange("display.showMeetingTitle", value);
          }}
        />
        <Switch
          name="display.allowAdhocBookings"
          value={display.allowAdhocBookings}
          title="Allow ad-hoc bookings"
          message={tooltips.wayfinders.displaySettings.adHoc}
          onChange={(value) => {
            onChange("display.allowAdhocBookings", value);
          }}
        />
        <Switch
          name="display.mapView"
          value={display.mapView}
          title="Map view"
          message={tooltips.rooms.reservation.mapView}
          onChange={(value) => {
            onChange("display.mapView", value);
          }}
          className="pb-2"
        >
          <label className="label__main pb-3">
            Select map to replace grid view for available wayfinders
          </label>
          <Button
            icon="floor-map-2"
            title="Select floor map"
            color={!display?.floor?.id ? "outline-primary" : "primary"}
            size="small"
            className="WayFinderFrom__workspace--opener mb-2"
            onClick={toggleModal}
          />
          {selectedFloorName}

          <Switch
            name="display.maximizedMapView"
            value={display.maximizedMapView}
            title="Maximize map view"
            message={tooltips.rooms.display.maximizedMapView}
            onChange={(value) => {
              onChange("display.maximizedMapView", value);
            }}
            className="mt-3"
          />

          <MarkerScaleOption
            scalingVal={display.scalingFactor}
            onChange={onChange}
          />
        </Switch>

        <Switch
          name="display.energySaveMode"
          value={display.energySaveMode}
          title="Energy save mode"
          message={tooltips.rooms.display.energySave}
          onChange={(value) => {
            onChange("display.energySaveMode", value);
          }}
          className="pt-2"
        />
        <Switch
          name="display.showOccupancyCards"
          value={display.showOccupancyCards}
          title="Show occupancy cards"
          message={tooltips.wayfinders.displaySettings.occupancy}
          onChange={(value) => {
            onChange("display.showOccupancyCards", value);
          }}
        />

        <SwitcherTempo
          switcherTempo={display.switcherTempo}
          onChange={onChange}
        />
      </FormSubSection>
    </FormSection>
  );
};
