import React from "react";
import Select from "react-select";
import { FormFeedback, FormGroup } from "reactstrap";

import { tooltips } from "../../../../lib/utils/tooltips";
import { selectStyles } from "../../../../lib/utils/selectStyles";
import { Providers } from "../../../../lib/context/Calendar/CalendarContextProvider";
import { CalendarType } from "../../../../api/grpc/calendarprovider/calendarprovider";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";

interface Props {
  value: string;
  error?: string;
  providers: Providers[];
  onRemove: () => void;
  onChange: (value: string, type: CalendarType) => void;
  direction?: LabelDirectionType;
}

export const CalendarProvider = (props: Props) => {
  const { value, error, providers, onChange, onRemove, direction } = props;

  const selectValue =
    value && providers
      ? providers
          .filter((item) => item.iD === value)
          .map((item) => ({ label: item.name, value: item.iD }))
      : [];

  const options =
    providers.map((item) => ({ label: item.name, value: item.iD })) || [];

  return (
    <FormGroup>
      <LabelWithInformationBox
        title="Calendar source"
        for="calendarProviderId"
        message={tooltips.rooms.settings.calendarSource}
        direction={direction}
      />
      <Select
        isClearable
        name="calendarId"
        options={options}
        menuPlacement="top"
        value={selectValue}
        theme={selectStyles}
        onChange={(e) => {
          if (e === null) {
            onRemove();
            return;
          }

          const provider = providers.find((item) => item.iD === e.value);

          // Typescript doing its thing
          if (!provider) {
            return;
          }

          onChange(provider.iD, provider.calType);
        }}
      />
      {!!error && (
        <FormFeedback className="invalid-feedback">{error}</FormFeedback>
      )}
    </FormGroup>
  );
};
