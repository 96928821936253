import { useMemo } from "react";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../grpcTransport";
import { AnalyticsClient } from "./analytics.client";
import { CustomerRequest } from "./analytics";

export const useAnalytics = () => {
  const { user, tokenInterceptor } = useAuthContext();

  const analyticsClient = useMemo(() => new AnalyticsClient(transport), []);

  const getCalendars = async (params: Omit<CustomerRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return analyticsClient.calendars(
      {
        customerID: user.customerid,
        ...params,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getWorkplaces = async (params: Omit<CustomerRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return analyticsClient.workplaces(
      {
        customerID: user.customerid,
        ...params,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getDevices = async (params: Omit<CustomerRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return analyticsClient.devices(
      {
        customerID: user.customerid,
        ...params,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getInsights = async (params: Omit<CustomerRequest, "customerID">) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return analyticsClient.insights(
      {
        customerID: user.customerid,
        ...params,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  const getSubscription = async (
    params: Omit<CustomerRequest, "customerID">
  ) => {
    if (!user) {
      throw new Error("User not found!");
    }

    return analyticsClient.subscription(
      {
        customerID: user.customerid,
        ...params,
      },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    getDevices,
    getInsights,
    getCalendars,
    getWorkplaces,
    getSubscription,
  };
};
