import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { Event } from "../../../../api/grpc/booking/ggevent/ggevent";
import { ROOM_FIELDS_FRAGMENT } from "../../../../api/graphql/rooms/rooms";
import { UpdateMeetingRequest } from "../../../../api/grpc/booking/booking";
import { INFO_STRINGS, SUCCESS_STRINGS } from "../../../../lib/utils/constants";

export const useCheckOutRoom = () => {
  const client = useApolloClient();
  const { personalCalendar } = useCalendarContext();
  const { updateRoomBookings, updateMeetingByCoordinator } =
    useBookingRequest();
  const { timeZone } = useTimeZoneContext();
  const { pathname } = useRouter();

  const isAllReservation = pathname.includes("all-reservations");

  const checkOutRoom = async (
    id: string,
    event: Event,
    isAuthedUsersEvent?: boolean
  ) => {
    const workplaceData = client.readFragment({
      id: `Room:${id}`,
      fragmentName: "RoomFields",
      fragment: ROOM_FIELDS_FRAGMENT,
    });

    let eventToBeChanged: Event = {
      ...event,
      endTime: new Date().toISOString(),
      eventTimeZone: timeZone,
    };

    try {
      const owner = personalCalendar.length > 0 && isAuthedUsersEvent;

      const calendarId = owner ? "" : workplaceData?.calendarId;

      const calendarproviderId = owner
        ? personalCalendar[0].iD
        : workplaceData?.calendarProviderId;

      const variables: UpdateMeetingRequest = {
        customerId: "",
        meeting: eventToBeChanged,
        calendarId,
        calendarproviderId,
        activateCheckOut: false,
      };

      let externalRoom;

      if (event.mode2Organizer || !isAllReservation) {
        const { response } = await updateRoomBookings(variables);
        externalRoom = response.isExternalBooking;
      } else {
        const { response } = await updateMeetingByCoordinator(variables);
        externalRoom = response.isExternalBooking;
      }

      if (externalRoom) {
        toast.info(INFO_STRINGS.externalRoom);
      } else {
        toast.success(SUCCESS_STRINGS.reservationUpdated);
      }
    } catch (error: any) {
      return toast.error(error?.message ?? "Couldn't check out room");
    }
  };

  return { checkOutRoom };
};
