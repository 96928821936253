import { useMemo } from "react";
import { useBookingsRequests } from "../../../api/graphql/bookings/useBookingsRequests";
import { useRootIdQuery } from "../../../lib/hooks/useRootIdQuery";
import { useBookingsContext } from "../Context/BookingsContext";

export const useLocationDefaultInfo = () => {
  const { defaultLocation } = useBookingsRequests();
  const { rootId } = useRootIdQuery();

  const {
    location: { locationId },
  } = useBookingsContext();

  const _locationId = useMemo(() => {
    //return the id from location that users chooses in search desk or room form (temp location)
    if (!!locationId && locationId !== "refreshedId" && locationId !== "root") {
      return locationId;
    }

    //if we click Workspaces from sidebar, we set locationId to root so we try to search in root level:
    if (
      locationId === "root" ||
      defaultLocation?.data?.queryDefaultLocationPath.length === 0
    ) {
      return rootId;
    }

    return defaultLocation?.data?.queryDefaultLocationPath[0]?.referenceId;
  }, [defaultLocation, locationId]);

  return { _locationId };
};
