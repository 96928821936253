import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { REGISTER_STRINGS as f } from "../../../lib/utils/constants";

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[A-Za-z\s\u00C0-\u024F]+$/,
      "Only letters and spaces are allowed in the first name!"
    )
    .required("First name field is required!"),
  lastName: Yup.string()
    .matches(
      /^[A-Za-z\s\u00C0-\u024F]+$/,
      "Only letters and spaces are allowed in the last name!"
    )
    .required("Last name field is required!"),
  workEmail: Yup.string()
    .email(f.validEmail)
    .required("Email field is required!"),
  accountPassword: Yup.string()
    .min(8, "Password must be at least 8 characters long!")
    .required("Password field is required!"),
  companyName: Yup.string()
    .min(4, "Company name must be at least 4 characters long!")
    .required("Company Name is required!"),
  country: Yup.string().required("Please select your country!"),
  privacyTerms: Yup.boolean().oneOf(
    [true],
    "Please make sure you agree with our privacy policy terms"
  ),
  recaptcha: Yup.string().required("Please check the reCAPTCHA box!"),
});

export interface RegisterFields {
  firstName: string;
  lastName: string;
  workEmail: string;
  accountPassword: string;
  companyName: string;
  country: string;
  delivery: string;
  interest: string[];
  deskNumber: string;
  numberOfRooms: string;
  newsletter: boolean;
  privacyTerms: boolean;
  recaptcha: string;
}

interface UseRegisterFormOptions {
  onSubmit: (
    values: RegisterFields,
    formikHelpers: FormikHelpers<RegisterFields>
  ) => Promise<any>;
}

export type RegisterFormik = ReturnType<typeof useRegisterFormik>;

export const useRegisterFormik = (opts: UseRegisterFormOptions) => {
  return useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      workEmail: "",
      accountPassword: "",
      companyName: "",
      country: "",
      privacyTerms: false,
      newsletter: false,
      interest: [""],
      delivery: "",
      deskNumber: "",
      numberOfRooms: "",
      recaptcha: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    ...opts,
  });
};
