import React from "react";
import { GraphQLError } from "graphql";
import { ApolloError } from "@apollo/client";

interface Props {
  error?: ApolloError | ReadonlyArray<GraphQLError>;
  errorComponent?: JSX.Element;
  children: React.ReactNode;
}

export const ApolloErrorGuard = (props: Props) => {
  if (!props.error) {
    return <>{props.children}</>;
  }

  if (props.errorComponent) {
    return <>{props.errorComponent}</>;
  }

  return <></>;
};
