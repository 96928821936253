import React from "react";
import { Person } from "../../../api/grpc/contacts/contacts";

interface Props {
  attendee: Person;
  showReservationInfo: boolean;
}

export const UserLeafletMarker = ({ attendee, showReservationInfo }: Props) => {
  return (
    <div className="UserLeafletMarker">
      <svg
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_4197_572)">
          <path
            d="M4 19C4 8.50659 12.5066 0 23 0V0C33.4934 0 42 8.50659 42 19V19C42 29.4934 33.4934 38 23 38H5C4.44772 38 4 37.5523 4 37V19Z"
            fill="#0D99FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_4197_572"
            x="0"
            y="0"
            width="46"
            height="46"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4197_572"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4197_572"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <div className="UserLeafletMarker__icon">
        {!!attendee.avatar?.url.length ? (
          <img src={attendee.avatar.url} alt="User" />
        ) : (
          <span>{attendee.displayName[0].toUpperCase()}</span>
        )}
      </div>
    </div>
  );
};
