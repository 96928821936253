import React from "react";
import cs from "classnames";

import { Subscription } from "../../../../api/grpc/subscription/subscription";
import {
  StatusInformationKeys,
  SubscriptionStatus,
} from "../../../Subscription/SubscriptionStatus/SubscriptionStatus";
import { DetailsData } from "./SubscriptionItems";

import "./SubscriptionRow.scss";

interface Props {
  data?: Subscription;
  hasManagedPartner: boolean;
}

export const SubscriptionPlan = ({ data, hasManagedPartner }: Props) => {
  let definedStatus = [
    "cancelled",
    "active",
    "in_trial",
    "non_renewing",
  ].includes(data?.status.toLowerCase() || "");

  const details: DetailsData[] = [
    {
      title: "Current plan",
      content: (
        <>
          {data?.name}
          <SubscriptionStatus
            status={data?.status.toLowerCase() as StatusInformationKeys}
          />
        </>
      ),
    },
    {
      title: "Period",
      content: data?.name.includes("1-Year") ? "Yearly" : "3 Years",
    },
    ...(definedStatus
      ? [
          {
            title:
              data?.status.toLowerCase() === "cancelled" ||
              data?.status.toLowerCase() === "non_renewing"
                ? "Expires"
                : "Renews",
            content:
              data?.status.toLowerCase() === "cancelled" ||
              data?.status.toLowerCase() === "non_renewing"
                ? data.cancelDate
                : data?.nextRenewal || "",
          },
        ]
      : []),
  ];

  const classes = cs("SubscriptionRow__rows overflow-hidden");

  return (
    <div className="SubscriptionRow">
      <div className={classes}>
        {details.map((item: DetailsData) => (
          <div
            key={item.title}
            className={`${definedStatus ? "" : "m-w-25"} SubscriptionRow__row ${
              item.title
            }`}
          >
            <h5>{item.title}</h5>
            <span className="SubscriptionRow__row--content">
              {item.content}
            </span>
          </div>
        ))}
        {!hasManagedPartner && (
          <a
            href="https://gogetcorp.com/pricing/"
            className={`SubscriptionRow__plans ${
              definedStatus ? "" : "SubscriptionRow__plans--adjust"
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Compare plans
          </a>
        )}
      </div>
    </div>
  );
};
