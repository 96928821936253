import React from "react";
import { Button } from "reactstrap";

import { Icon } from "../../shared/Icon/Icon";

import "./ContactPasswordProcessed.scss";

export const ContactPasswordProcessed = () => {
  return (
    <div className="ContactPasswordProcessed">
      <Icon icon="check-icon" />
      <h4>Done!</h4>
      <h5>
        Please head over to the GOGET Workspaces app and sign in with your new
        password.
      </h5>
      <Button color="primary" outline>
        It is recommended that you close this browser window
      </Button>
    </div>
  );
};
