import React, { useMemo } from "react";
import { FormGroup, Label } from "reactstrap";
import cs from "classnames";

import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";
import { useListLanguags } from "../lib/useListLanguages";

import { tooltips } from "../../../../../lib/utils/tooltips";

import { Input } from "../../../Input/Input";
import { FormSection } from "../../../FormSection/FormSection";
import { DisplaySettingsSection } from "./ReservationSettings";
import { LabelWithInformationBox } from "../../../LabelWithInformationBox/LabelWithInformationBox";
import { CustomLanguageModal } from "../../../../Modals/CustomLanguageModal/CustomLanguageModal";
import { CustomLanguageAppType } from "../../../../../api/grpc/workplaces/workplaces";
import { SelectLanguage } from "../shared/SelectLanguage";

export const MainSettings = (props: DisplaySettingsSection) => {
  const {
    formik,
    formik: { values, errors, touched, handleBlur, handleChange },
    isBussinesOrPro,
    roomId,
    currentCustomLanguageId,
    isProfile,
    className,
  } = props;

  const { main, display } = values;
  const { rooms } = tooltips;

  const { isOpen, toggleModal } = useToggleModal();

  const { loading, error, data, refetch } = useListLanguags(
    CustomLanguageAppType.RDX
  );

  const definedLanguages = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.data
      .filter(
        (language) =>
          !["Bulgarian", "Hungarian", "Lithuanian", "Portuguese"].includes(
            language.name
          )
      )
      .map((lng) => {
        return {
          id: lng.id,
          value: !!lng.value ? lng.value : lng.id,
          label: lng.name,
          isGlobal: lng.isGlobal,
        };
      });
  }, [data]);

  const selectedValue = useMemo(() => {
    // newly created rooms has customlanguage as null and it means RDX will have
    // english lang (same for rfx)
    if (!display.customLanguage) {
      const englishDefaultLanguage = definedLanguages.find(
        (language) => language.value === "en"
      );

      if (!englishDefaultLanguage) {
        return {
          label: "English",
          value: "en",
        };
      }

      formik.setFieldValue("display.customLanguage", englishDefaultLanguage);

      return {
        label: englishDefaultLanguage.label,
        value: englishDefaultLanguage.value,
      };
    }
    return definedLanguages
      .filter((language) => language.id === display.customLanguage?.id)
      .map(({ label, value }) => ({
        label: label,
        value: value,
      }));
  }, [definedLanguages, display.customLanguage]);

  const shouldDisplayEditLanguageButton = useMemo(() => {
    //we only show edit button if user has pressed Save settings when changing custom langauge
    //to improve the flow
    if (isProfile) {
      return true;
    }

    return (
      formik.values.display.customLanguage?.id === currentCustomLanguageId &&
      formik.values.display.customLanguage?.isGlobal
    );
  }, [formik, currentCustomLanguageId]);

  const defaultAdminPinInput =
    !!main.adminPin.length && main.adminPin.length < 4;

  const classes = cs("RoomForm__main", className);

  return (
    <FormSection title="Main settings" toggleable className={classes}>
      <FormGroup>
        <LabelWithInformationBox
          title="Admin PIN (4 digits 0-9)"
          for="main.adminPin"
          message={rooms.main.adminPin}
        />
        <Input
          id="main.adminPin"
          name="main.adminPin"
          value={main.adminPin}
          onBlur={props.formik.handleBlur}
          isTouched={touched.main?.adminPin || defaultAdminPinInput}
          errorMessage={
            defaultAdminPinInput
              ? "Pin number must be exactly 4 digits long!"
              : errors.main?.adminPin
          }
          onChange={props.formik.handleChange}
          invalid={
            (!!errors.main?.adminPin && touched.main?.adminPin) ||
            defaultAdminPinInput
          }
        />
      </FormGroup>

      <FormGroup className="RoomForm__main--group flex-a-center">
        <FormGroup className="RoomForm__main--backgroundImage mb-0">
          <LabelWithInformationBox
            title="Background image"
            for="main.backgroundImageUrl"
            message={rooms.main.background}
            className="mt-2"
          />
          <Input
            type="text"
            id="main.backgroundImageUrl"
            name="main.backgroundImageUrl"
            value={
              isBussinesOrPro
                ? main.backgroundImageUrl
                : "Upgrade your plan to access this feature!"
            }
            onBlur={props.formik.handleBlur}
            disabled={!isBussinesOrPro}
            invalid={
              !!errors.main?.backgroundImageUrl &&
              touched.main?.backgroundImageUrl
            }
            onChange={props.formik.handleChange}
            isTouched={touched.main?.backgroundImageUrl}
            errorMessage={errors.main?.backgroundImageUrl}
          />
        </FormGroup>
        <FormGroup className="position-relative RoomForm__main--bwFilter">
          <Input
            type="checkbox"
            id="display.bwFilter"
            name="display.bwFilter"
            checked={isBussinesOrPro ? display.bwFilter ?? false : false}
            disabled={!isBussinesOrPro}
            onChange={() => {
              //bwFilter is a new field, so existing rooms have it as null
              //so with ?? we check if it's null set it to true:

              formik.setFieldValue(
                "display.bwFilter",
                !display.bwFilter ?? true
              );
              formik.setFieldTouched("display.bwFilter", true);
              return;
            }}
          />
          <Label for="display.bwFilter" className="checkbox_label">
            <span>Override B/W filter</span>
            <span className="d-block">(May reduce contrast)</span>
            <span className="checkbox_box"></span>
          </Label>
        </FormGroup>
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="logoImageUrl"
          title="Logo image"
          message={rooms.main.logo}
        />
        <Input
          type="text"
          id="logoImageUrl"
          value={
            isBussinesOrPro
              ? values.main.logoImageUrl
              : "Upgrade your plan to access this feature!"
          }
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={!isBussinesOrPro}
          name="main.logoImageUrl"
          isTouched={touched.main?.logoImageUrl}
          errorMessage={errors.main?.logoImageUrl}
          invalid={!!errors.main?.logoImageUrl && touched.main?.logoImageUrl}
        />
      </FormGroup>

      <FormGroup className="position-relative">
        <LabelWithInformationBox
          for="display.customLanguage"
          title="Language"
          message={rooms.display.language}
        />
        <SelectLanguage
          loading={loading}
          error={error}
          toggleModal={toggleModal}
          options={definedLanguages}
          selectedValue={selectedValue}
          shouldDisplayEditButton={shouldDisplayEditLanguageButton}
          isGlobalLanguage={
            formik.values.display.customLanguage?.isGlobal || false
          }
          onChange={(e) => {
            formik.setFieldValue("display.customLanguage", e);
            formik.setFieldTouched("display.customLanguage", true);

            return;
          }}
        />

        {isOpen && (
          <CustomLanguageModal
            isOpen={isOpen}
            workspaceId={roomId}
            toggleModal={toggleModal}
            customLanguageId={formik.values.display.customLanguage?.id || ""}
            type="room"
            refetchLanguages={refetch}
          />
        )}
      </FormGroup>
    </FormSection>
  );
};
