import React, { FC } from "react";

import { InfoCard } from "../../../shared/InfoCard/InfoCard";
import { Icon } from "../../../shared/Icon/Icon";

import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { tooltips } from "../../../../lib/utils/tooltips";

export const TimeZoneCard: FC = () => {
  const { openTimeZoneSettings } = useTimeZoneContext();

  return (
    <>
      <InfoCard
        title="Time Zone"
        message={tooltips.organizationSettings.generalSettings.timeZone}
        direction="right"
        adjustInfoRight
      >
        <div
          className="OrganizationSettigs__cardContent OrganizationSettigs__cardContent_clickable OrganizationSettigs__timeZone"
          onClick={openTimeZoneSettings}
        >
          <Icon icon="earth" />
          <h3>Set time zone</h3>
        </div>
      </InfoCard>
    </>
  );
};
