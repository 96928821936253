import React from "react";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";

export const ReservationListLoading = () => {
  return (
    <div>
      <LoadingBox className="mt-main" maxWidth={250} minHeight={45} />
      <LoadingBox
        className="mt-main d-block"
        maxWidth={550}
        minHeight={45}
        count={3}
      />
    </div>
  );
};
