import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { RoomFormFields } from "../../../../../api/graphql/workplaces";
import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";

const RoomSchema = Yup.object().shape({
  name: Yup.string().min(2, f.validName).required(f.required),
  numberOfSeats: Yup.number().min(1, f.numberOfSeats).required(f.required),
});

interface UseRoomFormOptions {
  enableReinitialize?: boolean;
  initialValues?: RoomFormFields;
  onSubmit: (
    values: RoomFormFields,
    formikHelpers: FormikHelpers<RoomFormFields>
  ) => Promise<any>;
}

export type RoomFormik = ReturnType<typeof useRoomFormik>;

export const useRoomFormik = (opts: UseRoomFormOptions) => {
  return useFormik<RoomFormFields>({
    initialValues: {
      customerId: "",
      name: "",
      description: "",
      tags: [],
      numberOfSeats: 1,
      showCheckInTime: false,
      checkInTime: 1,
      calendarId: "",
      isBooked: true,
      isBlocked: false,
      calendarProviderActive: false,
      marker: { latitude: 0, longitude: 0 },
      circleShape: { latitude: 0, longitude: 0, radius: 0 },
      polygonal: [],
      roomDirection: [
        {
          distance: 0,
          direction: 0,
        },
      ],
      licensed: false,
      pairingCode: "",
      resourceEmail: "",
      reportingEmail: "",
      roomAmenities: [],
      brokenAmenitiesReporting: false,
      calendarProviderId: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RoomSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
