import React from "react";
import { Form, FormGroup } from "reactstrap";

import { BuildingFormik } from "./lib/useBuildingFormik";

import { Input } from "../../Input/Input";
import { TagInput } from "../../TagInput/TagInput";
import { tooltips } from "../../../../lib/utils/tooltips";
import {
  LabelDirectionType,
  LabelWithInformationBox,
} from "../../LabelWithInformationBox/LabelWithInformationBox";

interface Props {
  formik: BuildingFormik;
  informationBoxDirection?: LabelDirectionType;
}

export const BuildingForm = (props: Props) => {
  const { formik, informationBoxDirection } = props;

  return (
    <Form className="BuildingForm">
      <FormGroup>
        <LabelWithInformationBox
          for="name"
          title="Name"
          message={tooltips.workplaces.name}
          direction={informationBoxDirection}
        />
        <Input
          id="name"
          type="text"
          name="name"
          value={formik.values.name}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isTouched={formik.touched.name}
          errorMessage={formik.errors.name}
          invalid={!!formik.errors.name && formik.touched.name}
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="description"
          title="Description"
          message={tooltips.workplaces.description}
          direction={informationBoxDirection}
        />
        <Input
          type="text"
          id="description"
          name="description"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </FormGroup>
      <TagInput
        value={formik.values.tags || []}
        direction={informationBoxDirection}
        onChange={(tags) => {
          formik.setFieldValue("tags", tags);
          formik.setFieldTouched("tags");
        }}
      />
    </Form>
  );
};
