import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { LoginMethod } from "../../../../api/grpc/account/account";

import { Role } from "../../../../api/grpc/contacts/account/account";
import { Status as StatusType } from "../../../../api/grpc/contacts/account/account";

const EditUserSchema = Yup.object().shape({
  firstName: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Last name is required"),
});

export interface EditUserFields {
  id: string;
  role: Role;
  team: string;
  emailID: string;
  lastName: string;
  firstName: string;
  licensed: boolean;
  status: StatusType;
  desk: string[];
  loginMethod: LoginMethod;
}

interface UseEditUserFormOptions {
  initialValues: EditUserFields;
  onSubmit: (
    values: EditUserFields,
    formikHelpers: FormikHelpers<EditUserFields>
  ) => Promise<void>;
}

export type EditUserFormik = ReturnType<typeof useEditUserFormik>;

export const useEditUserFormik = (opts: UseEditUserFormOptions) => {
  return useFormik({
    ...opts,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: EditUserSchema,
  });
};
