import React from "react";
import cs from "classnames";
import { Button as ReactstrapButton, ButtonProps, Spinner } from "reactstrap";

import { Icon } from "../Icon/Icon";
import { IconDefinition } from "../Icon/Icon.generated";

import "./Button.scss";

export type ButtonSize = "large" | "small" | "medium" | undefined;

export type LineHeight = "low" | "normal" | undefined;

export type ButtonColor =
  | "primary"
  | "secondary"
  | "third"
  | "business"
  | "pro"
  | "outline-secondary"
  | "outline-primary"
  | "outline-red"
  | "underline"
  | "simple"
  | "link"
  | undefined;

export interface ThemeButtonProps extends ButtonProps {
  icon?: IconDefinition;
  title?: string;
  subTitle?: string;
  size?: ButtonSize;
  className?: string;
  color?: ButtonColor;
  lineHeight?: LineHeight;
  loadSpinner?: boolean;
  loadTitle?: string;
}

export const Button = (props: ThemeButtonProps) => {
  const {
    title,
    subTitle,
    className,
    color,
    icon,
    lineHeight,
    loadSpinner,
    loadTitle,
    ...rest
  } = props;

  return (
    <ReactstrapButton
      {...rest}
      color={color || "primary"}
      className={cs(
        "Button",
        {
          "Button--icon": !!icon,
        },
        {
          "Button--low": lineHeight === "low",
        },
        className
      )}
    >
      {icon && <Icon icon={icon} />}
      {loadSpinner && <Spinner size="sm" className="mr-2" />}
      <span>{loadSpinner ? loadTitle : title}</span>
      {subTitle && <span className="Button--subtitle">{subTitle}</span>}
    </ReactstrapButton>
  );
};
