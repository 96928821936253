import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

import { SiteFormFields } from "../../../../../api/graphql/workplaces";
import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";

const SiteSchema = Yup.object().shape({
  name: Yup.string().min(2, f.validName).required(f.required),
});

interface UseSiteFormOptions {
  initialValues?: SiteFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<SiteFormFields>
  ) => Promise<any>;
}

export type SiteFormik = ReturnType<typeof useSiteFormik>;

export const useSiteFormik = (opts: UseSiteFormOptions) => {
  return useFormik<SiteFormFields>({
    initialValues: {
      name: "",
      description: "",
      tags: [],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SiteSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
