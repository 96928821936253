import React from "react";
import { Icon } from "../../../shared/Icon/Icon";

export const NoAddons = () => {
  return (
    <div className="NoAddons flex-a-center">
      <Icon icon="warning-triangle" />
      <span>No addons!</span>
    </div>
  );
};
