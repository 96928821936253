import { useMemo } from "react";
import { useSubscriptionRequests } from "../../../api/grpc/subscription/useSubscriptionRequests";
import useGrpcQuery from "../../../lib/hooks/useGrpcQuery";
import { CBCustomer } from "../../../api/grpc/subscription/subscription";

export const useCustomerDetails = () => {
  const { getCustomerDetails } = useSubscriptionRequests();

  const { loading, error, data, refetch } = useGrpcQuery<{}, CBCustomer>({
    method: getCustomerDetails,
    variables: {},
  });

  const hasRequiredFieldsAdded = useMemo(() => {
    if (!data?.billingInfo) {
      return false;
    }

    const { city, country, line1, zip } = data.billingInfo;

    return !!city && !!country && !!line1 && !!zip;
  }, [data]);

  return {
    loading,
    error,
    data,
    refetch,
    hasRequiredFieldsAdded,
  };
};
