import React from "react";
import { SyncStatus } from "../../../../api/grpc/contacts/contacts";
import { Button } from "../../../shared/Button/Button";
import { CheckboxButton } from "../../../shared/CheckboxButton/CheckboxButton";
import { ResourceFilterHeader } from "../../../shared/ResourceFilter/ResourceFilterHeader";
import { useDirectoryContext } from "../../Context/DirectoryContext";

const integrationFilters = [
  {
    name: "status",
    options: [
      {
        value: SyncStatus.NEW,
        name: "new",
        title: "New",
      },
      {
        value: SyncStatus.UPDATED,
        name: "updated",
        title: "Updated",
      },
      {
        value: SyncStatus.REMOVED,
        name: "removed",
        title: "Removed",
      },
    ],
  },
];

export const DirectoryIntegrationsFilters = () => {
  const { filterStatus, setFilterStatus } = useDirectoryContext();

  return (
    <div className="child-element">
      <div className="main-flex mb-2 FilterHeader">
        <ResourceFilterHeader />

        <div className="flex-a-center gap-1">
          <Button
            title="Clear all"
            color="outline-secondary"
            className="child-element--clear"
            disabled={false}
            onClick={() => {
              setFilterStatus([]);
            }}
          />
        </div>
      </div>

      <div className="d-flex gap-3 FilterContent">
        {integrationFilters.map((filterItem, index) => {
          return (
            <div key={index}>
              <span className="child-element--head text-nowrap flex">
                User status
              </span>

              {filterItem.options.map((item, index) => {
                const isStatusChecked = filterStatus.includes(item.value);

                return (
                  <CheckboxButton
                    key={index}
                    id={item.name}
                    name={item.title}
                    checked={isStatusChecked}
                    onChange={() => {
                      if (isStatusChecked) {
                        return setFilterStatus(
                          filterStatus.filter((status) => status !== item.value)
                        );
                      }

                      return setFilterStatus([...filterStatus, item.value]);
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
