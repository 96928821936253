import { useState } from "react";
import { ChangedRoomsDirectionsVariables } from "../../../../Workplaces/EditWorkplace/EditWayFinder/EditWayFinder";

export const useChangedRoomDirections = () => {
  const [changedRooms, setChangedRooms] = useState<
    ChangedRoomsDirectionsVariables[]
  >([]);

  const handleChangedRooms = ({
    id,
    roomDirectionId,
    distance,
    direction,
    name,
  }: ChangedRoomsDirectionsVariables) => {
    setChangedRooms([
      ...changedRooms,
      {
        id,
        roomDirectionId,
        direction,
        distance,
        name,
      },
    ]);
  };

  const clearChangedRooms = () => setChangedRooms([]);

  return { changedRooms, handleChangedRooms, clearChangedRooms };
};
