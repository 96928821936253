import React, { PropsWithChildren } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import cs from "classnames";

import "./ModalMFA.scss";

export const ModalMFA = ({
  title,
  keyPoints,
  nextButton,
  closeButton,
  handleNextButton,
  handleBackButton,
  disabledBack,
  disabledNext,
  children,
  className,
}: PropsWithChildren<{
  title: string;
  keyPoints?: string[] | JSX.Element;
  closeButton: string;
  nextButton?: string;
  disabledBack?: boolean;
  disabledNext?: boolean;
  handleNextButton: () => void;
  handleBackButton: () => void;
  children: React.ReactNode;
  className?: string;
}>) => {
  const classNames = cs("ModalMFA", className);

  return (
    <Modal isOpen={true} className={classNames}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        {keyPoints && (
          <>
            {Array.isArray(keyPoints) ? (
              <div className="mb-4">
                {keyPoints.map((point, index) => (
                  <div
                    className="d-flex ModalMFA__key"
                    key={`${index}-${point}`}
                  >
                    <Icon icon="green-flag" />
                    <span>{point}</span>
                  </div>
                ))}
              </div>
            ) : (
              keyPoints
            )}
          </>
        )}

        {children}
      </ModalBody>

      <ModalFooter>
        <Button
          title={closeButton}
          color="outline-secondary"
          size="small"
          onClick={handleBackButton}
          disabled={disabledBack}
        />
        {nextButton && (
          <Button
            title={nextButton}
            color="primary"
            size="small"
            onClick={handleNextButton}
            disabled={disabledNext}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
