import React, { PropsWithChildren, useState } from "react";
import { useDirectoryContext } from "../../Context/DirectoryContext";

import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import { ReAuthModal } from "../../../Modals/ReAuthModal/ReAuthModal";

export const DirectoryIntegrationError = ({
  id,
  message,
}: PropsWithChildren<{ id: string; message: string }>) => {
  const [openReAuthModal, setOpenReAuthModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { handleFixDirectoryButton } = useDirectoryContext();

  // const isO365 = id.toLowerCase().startsWith("o365");

  // const redirectURL = isO365
  //   ? (process.env.REACT_APP_O365_DIRECTORY as string)
  //   : (process.env.REACT_APP_GCP_DIRECTORY as string);

  // const handleFixNowButton = async () => {
  // const { response } = await generateDirectoryIntegrationAuthLink({
  //   redirectURL,
  //   integrationType: isO365
  //     ? CalendarType.O365_SYNC
  //     : CalendarType.GSUITE_SYNC,
  // });
  // if (!response.authLink.length) {
  //   toast.error("Failed to generate the authentication link");
  //   return;
  // }
  // localStorage.setItem("directoryReAuth", id);
  // window.location.href = response.authLink;
  // return;
  //   if (calendar.iD.toLowerCase().startsWith("o365")) {
  // return redirectToO365("directory", false);
  //   }
  //   if (calendar.iD.toLowerCase().startsWith("gsuite")) {
  //     return window.google.accounts.oauth2
  //       .initCodeClient({
  //         client_id: config.clientId,
  //         scope: config.scope,
  //         callback: async (tokenResponse: any) => {
  //           if (tokenResponse && tokenResponse.code) {
  //             await handleGoogleReAuth(tokenResponse.code);
  //           }
  //         },
  //       })
  //       .requestCode();
  //   }
  // };
  return (
    <>
      {openReAuthModal && (
        <ReAuthModal
          closeModal={() => setOpenReAuthModal(false)}
          handleReAuthButton={() => handleFixDirectoryButton(id)}
          type="Directory"
        />
      )}
      <div className="CalendarError d-flex mt-3">
        <div className="d-flex gap-1">
          <Icon icon="red-error-icon" />

          <div className="d-flex flex-column CalendarError__content">
            <span>
              Auto-renewal of renew-token failed. Directory integration
              re-authentication is required.
            </span>
            <span className="CalendarError__content--provider">
              <span>Message from directory provider:</span>
              <span className="CalendarError__content--provider--message">
                {isExpanded ? message : `${message.slice(0, 50)}...`}
              </span>
            </span>
            <span
              className="CalendarError__content--expand pt-2"
              onClick={() => setIsExpanded((prev) => !prev)}
            >
              {isExpanded ? "Hide" : "Expand"}
            </span>
          </div>
        </div>

        <div className="d-flex CalendarError__buttons">
          <Button
            color="outline-red"
            title="Re-authenticate"
            size="small"
            onClick={() => handleFixDirectoryButton(id)}
          />
          <Button
            color="simple"
            title="Read more"
            size="small"
            onClick={() => setOpenReAuthModal(true)}
          />
        </div>
      </div>
    </>
  );
};
