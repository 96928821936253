import React, { PropsWithChildren } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";

import "./AddInfoModal.scss";

export const AddInfoModal = ({
  handleBillingModal,
  toggleModal,
}: PropsWithChildren<{
  handleBillingModal: () => Promise<any>;
  toggleModal: () => void;
}>) => {
  return (
    <Modal className="AddInfoModal" isOpen={true}>
      <ModalHeader>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggleModal}
        >
          <Icon icon="close-icon" />
        </button>
      </ModalHeader>
      <Icon className="AddInfoModal__header" icon="alert-triangle" />
      <h6>Add billing info</h6>

      <ModalBody>
        <span>
          Complete billing information before managing your subscription or
          payment method.
        </span>
      </ModalBody>

      <ModalFooter>
        <Button
          size="small"
          color="primary"
          title="Edit"
          onClick={() => handleBillingModal()}
        />
      </ModalFooter>
    </Modal>
  );
};
