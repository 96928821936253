import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouter } from "../../../lib/hooks/useRouter";
import { FloorMain } from "./FloorMain/FloorMain";
import { FloorSingle } from "./FloorSingle/FloorSingle";

export const FloorRoutes = () => {
  const { match } = useRouter();

  const floorsRoutes = [
    {
      to: match.url,
      exact: true,
      label: "FloorsMain",
      component: FloorMain,
      id: "floors-main-tab",
    },
    {
      to: `${match.url}/floor/:id`,
      label: "FloorSingle",
      component: FloorSingle,
      id: "floor-single",
    },
  ];

  return (
    <Switch>
      {floorsRoutes.map((item) => (
        <Route
          key={item.id}
          path={item.to}
          component={item.component}
          exact={item.exact ? true : undefined}
        />
      ))}
      <Redirect to={floorsRoutes[0].to} />
    </Switch>
  );
};
