import React, { useState } from "react";
import { WorkplaceFields } from "../../../api/graphql/workplaces";
import cs from "classnames";
import { getWorkplaceIcon } from "../../Workplaces/helpers/getWorkplaceInformation";
import { getWorkplaceChildren } from "../../Workplaces/helpers/getWorkplaceChildren";
import { useRouter } from "../../../lib/hooks/useRouter";
import { NavLink } from "react-router-dom";
import { Icon } from "../../shared/Icon/Icon";
import { WayFinderFormik } from "../../shared/Forms/WayFinderForm/lib/useWayFinderFormik";
import { toast } from "react-toastify";

interface Props {
  item: WorkplaceFields;
  toggleParent: (value: boolean) => void;
  handleWorkspace: (
    item: WorkplaceFields,
    type: "desks" | "rooms",
    methodType: "add" | "delete"
  ) => void;
  formik: WayFinderFormik;
  maxNumberOfRooms: boolean;
  maxNumberOfDesks: boolean;
}

export const WayFinderTreeLevel = (props: Props) => {
  const {
    item,
    toggleParent,
    handleWorkspace,
    formik,
    maxNumberOfDesks,
    maxNumberOfRooms,
  } = props;

  const { pathname } = useRouter();
  const isActive = pathname.includes(item.id);

  const [toggle, setToggle] = useState(() => isActive);

  const icon = getWorkplaceIcon(item.__typename);
  const workplaceChildren = getWorkplaceChildren(item);
  const hasChildren = workplaceChildren.some((item) => item && item.length > 0);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  const classes = cs("WorkplacesTreeLevel", {
    "WorkplacesTreeLevel--open": toggle,
    "WorkplacesTreeLevel--active": isActive,
    "WorkplacesTreeLevel--space": !hasChildren,
  });

  const subLevelClasses = cs("WorkplacesTreeLevel__sub-level", {
    "WorkplacesTreeLevel__sub-level--open": toggle,
  });

  const handleWorkspaceClick = () => {
    let typeOfMethod: "add" | "delete" = formik.values[
      item.__typename === "Desk" ? "desks" : "rooms"
    ].some((formikItem) => formikItem.id === item.id)
      ? "delete"
      : "add";

    if (
      typeOfMethod === "add" &&
      item.__typename === "Desk" &&
      maxNumberOfDesks
    ) {
      return toast.warn(
        "You have reached the maximum number allowed for desks"
      );
    }

    if (
      typeOfMethod === "add" &&
      item.__typename === "Room" &&
      maxNumberOfRooms
    ) {
      return toast.warn(
        "You have reached the maximum number allowed for rooms"
      );
    }

    handleWorkspace(
      item,
      item.__typename === "Desk" ? "desks" : "rooms",
      typeOfMethod
    );
  };

  return (
    <>
      <div className={classes}>
        <div className="WorkplacesTreeLevel__wrapper">
          {hasChildren && (
            <span
              onClick={handleToggle}
              className="WorkplacesTreeLevel__toggle"
            ></span>
          )}
          <NavLink
            className="WorkplacesTreeLevel__link"
            onClick={handleWorkspaceClick}
            to={"#"}
          >
            <div className="WorkplacesTreeLevel__icon">
              <Icon icon={icon} />
            </div>
            <h3 className="WorkplacesTreeLevel__name">{item.name}</h3>
          </NavLink>
          {item.__typename === "Desk" || item.__typename === "Room" ? (
            <>
              <div
                className="WorkplacesTreeLevel__status"
                onClick={handleWorkspaceClick}
              >
                <Icon
                  icon={
                    formik.values[
                      item.__typename === "Desk" ? "desks" : "rooms"
                    ].some((formikItem) => formikItem.id === item.id)
                      ? "checkbox-full"
                      : "checkbox-empty"
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {hasChildren && (
        <div className={subLevelClasses}>
          {workplaceChildren.map((type) => {
            return type?.map((workplace) => (
              <WayFinderTreeLevel
                item={workplace}
                key={`${workplace.id}-tree-level`}
                handleWorkspace={handleWorkspace}
                formik={formik}
                toggleParent={(value) => {
                  setToggle(value);
                  toggleParent(value);
                }}
                maxNumberOfRooms={maxNumberOfRooms}
                maxNumberOfDesks={maxNumberOfDesks}
              />
            ));
          })}
        </div>
      )}
    </>
  );
};
