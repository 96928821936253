import React from "react";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";

import { ModalMFA } from "../ModalMFA";
import { ModalMFANotification } from "../ModalMFANotification/ModalMFANotification";

export const MfaEnabled = () => {
  const { setMfaModal } = useMfaContext();

  return (
    <ModalMFA
      title="Confirmation"
      keyPoints={[
        "You can continue on to add a secondary email for recovery purposes.",
      ]}
      nextButton="Add secondary email"
      closeButton="Close"
      handleBackButton={() => {
        setMfaModal(undefined);
      }}
      handleNextButton={() => {
        setMfaModal("secondaryEmail");
      }}
      className="ModalResponse"
    >
      <ModalMFANotification
        icon="check-icon"
        title="Two-factor authentication enabled!"
      />
    </ModalMFA>
  );
};
