import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  title?: string;
  message: string;
}

export const AccountMessage = (props: Props) => {
  return (
    <>
      {props.title && <h3 className="mt-4">{props.title}</h3>}
      <p className="my-4 default-paragraph">{props.message}</p>
      <NavLink
        to="/login"
        className="btn btn-large btn-outline-primary mt-auto"
      >
        Return to home page
      </NavLink>
    </>
  );
};
