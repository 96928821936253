import React from "react";
import cs from "classnames";

import { useSidebarContext } from "../../../lib/context/Sidebar/SidebarContext";

import "./SidebarDifferential.scss";

export const SidebarDifferential = () => {
  const { visibileMainMenu, isSubMenuActive } = useSidebarContext();

  const classes = cs("SidebarDifferential", {
    "SidebarDifferential--hidden_menu": !visibileMainMenu,
    "SidebarDifferential--active_submenu": isSubMenuActive,
  });

  return <span className={classes}></span>;
};
