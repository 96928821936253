import React, { useMemo } from "react";
import { HourlyDistribution } from "../../../../../api/grpc/insights/insights";
import { CenteredBox } from "../../../../shared/CenteredBox/CenteredBox";
import {
  PieChart as CustomPieChart,
  StaticLegendData,
} from "../../../shared/Charts/PieChart/PieChart";
import { DeskDataIdType } from "../../../shared/types/insightsTypes";

let attachColors: {
  [key in DeskDataIdType]: string;
} = {
  "0-2 h": "#00EC97",
  "2-4 h": "#2CD0AF",
  "4-8 h": "#156C79",
  "8+ h": "#000",
};

const staticLegendData: StaticLegendData[] = [
  {
    id: "0-2 h",
    color: attachColors["0-2 h"],
  },
  {
    id: "2-4 h",
    color: attachColors["2-4 h"],
  },
  {
    id: "4-8h",
    color: attachColors["4-8 h"],
  },
  {
    id: "8+ h",
    color: attachColors["8+ h"],
  },
];

interface Props {
  data: HourlyDistribution[];
}

export const DeskReservationsDistribution = ({ data }: Props) => {
  const dataObject = useMemo(() => {
    const filteredData = data.filter((item) => item.value > 0);

    return filteredData.map((item) => {
      return {
        id: item.id,
        label: item.label,
        value: Math.round(item.value),
        color: attachColors[item.id as DeskDataIdType],
      };
    });
  }, [data]);

  return (
    <>
      {data.length <= 0 ? (
        <CenteredBox>Empty data</CenteredBox>
      ) : (
        <CustomPieChart data={dataObject} staticLegendData={staticLegendData} />
      )}
    </>
  );
};
