import { createContext, useContext } from "react";

export interface ISidebarContext {
  isSubMenuActive: boolean;
  activeMenuItem: string;
  visibileMainMenu: boolean;
  visiblePageSidebar: boolean;
  closeSidebar: () => void;
  toggleSubMenu: () => void;
  toggleMainMenu: () => void;
  hideMobileMenus: () => void;
  togglePageSidebar: () => void;
  openPageSidebar: () => void;
  setActiveMenuItem: (s: string) => void;
  handleSidebarClick: (s: string, c: boolean) => void;
}

export const SidebarContext = createContext<ISidebarContext>({
  activeMenuItem: "",
  isSubMenuActive: false,
  visibileMainMenu: true,
  visiblePageSidebar: false,
  closeSidebar: () => {},
  toggleSubMenu: () => {},
  toggleMainMenu: () => {},
  hideMobileMenus: () => {},
  togglePageSidebar: () => {},
  openPageSidebar: () => {},
  setActiveMenuItem: () => {},
  handleSidebarClick: () => {},
});

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error("useSidebarContext must be used within SidebarContext");
  }

  return context;
};

export const { Provider } = SidebarContext;
