import React from "react";

interface Props {
  address?: string;
  city?: string;
  zip?: string;
  country?: string;
}

export const BillingInfoCustomerAddress = ({
  address,
  city,
  zip,
  country,
}: Props) => {
  return (
    <div className="BillingInfoCustomerAddress">
      <span>{address}</span>
      <span>
        {zip} {city}
      </span>
      <span>{country}</span>
    </div>
  );
};
