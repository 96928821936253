import React from "react";

import logo from "../../../assets/images/logo.png";

import "./GoGetLoader.scss";

export const GoGetLoader = () => {
  return (
    <div className="GoGetLoader">
      <img src={logo} alt="Goget Logo" />
    </div>
  );
};
