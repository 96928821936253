import React, { useState } from "react";
import { toast } from "react-toastify";

import { useZoneFormik } from "../../../../shared/Forms/ZoneForm/lib/useZoneFormik";
import { useCustomerContext } from "../../../../../lib/context/Customer/CustomerContext";
import { useWorkplaceRequests } from "../../../../../api/graphql/useWorkplaceRequests";

import { WorkplaceModalProps } from "../AddWorkplaceModal";

import { ZoneForm } from "../../../../shared/Forms/ZoneForm/ZoneForm";
import ModalSidebar from "../../../../Modals/ModalSidebar/ModalSidebar";
import { AddZoneVariables } from "../../../../../api/graphql/zones/zones";

export const AddZoneModal = (props: WorkplaceModalProps) => {
  const { isOpen, toggle, parentId, parentType } = props;
  const { addZone } = useWorkplaceRequests();
  const { retrieveTenant } = useCustomerContext();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useZoneFormik({
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const { id, tenantId } = await retrieveTenant();

        const variables: AddZoneVariables = {
          id: parentId || id,
          tenantId,
          name: values.name,
          tags: values.tags,
          description: values.description,
        };

        await addZone(variables, parentType);

        toggle();
        toast.success("Zone added successfully!");
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Zone couldn't be added, please reload and try again!"
        );
        setLoading(false);
        console.error(error.message);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Add new Zone"
      onSubmit={formik.handleSubmit}
    >
      <ZoneForm formik={formik} informationBoxDirection="left" />
    </ModalSidebar>
  );
};
