import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import { useContacts } from "../../../api/grpc/contacts/useContacts";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import { useConfirmation } from "../../../lib/context/Confirmation/ConfirmationContext";
import { usePeopleContext } from "../../../views/Users/People/lib/PeopleContext";

import { Status } from "../../../api/grpc/contacts/account/account";
import { EditUser } from "../../People/EditUser/EditUser";
import { DropdownList } from "../DropdownList/DropdownList";
import { Contact } from "../../../api/grpc/contacts/contacts";
import { Icon } from "../Icon/Icon";

import "./PeopleActions.scss";

export type PeopleActionsType =
  | "edit-contact"
  | "resend-invitation"
  | "remove-contact";

const options = [
  {
    icon: "edit-contact-icon",
    label: "Edit contact",
    type: "edit-contact",
  },
  {
    icon: "invite-contact-icon",
    label: "Resend invitation",
    type: "resend-invitation",
  },
  {
    icon: "remove-contact-icon",
    label: "Remove contact",
    type: "remove-contact",
  },
];

interface Props {
  contact: Contact;
}

export const PeopleActions = ({ contact }: Props) => {
  const ref = useRef(null);
  const [selectedContact, setSelectedContact] = useState<Contact | undefined>();
  const confirmation = useConfirmation();
  const { resendInvite, deleteContact } = useContacts();
  const { updateUsersList, setDeleteLoader } = usePeopleContext();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useOutsideClickDetection(ref, () => {
    setShowDropdown(false);
  });

  const handleSidebarClick = async (value: PeopleActionsType) => {
    setShowDropdown(false);

    if (value === "edit-contact") {
      setSelectedContact(contact);
      return;
    }

    if (value === "remove-contact") {
      await confirmation.confirm({
        title: "Are you sure you want to delete this contact?",
      });

      try {
        setDeleteLoader(true);
        await deleteContact(contact.id);
        updateUsersList();

        toast.success("Contact removed successfully!");
      } catch (error: any) {
        console.error(error.toString());
        toast.error(
          error?.message || "Something went wrong, please reload and try again!"
        );
      } finally {
        setDeleteLoader(false);
      }

      return;
    }

    if (value === "resend-invitation") {
      try {
        await resendInvite(contact.id);

        toast.success("Invitation sent successfully!");
      } catch (error: any) {
        console.error(error.toString());
        toast.error(
          error?.message || "Something went wrong, please reload and try again!"
        );
      }

      return;
    }
  };

  const filteredOptions =
    contact.status === Status.PENDING
      ? options
      : options.filter((option) => option.type !== "resend-invitation");

  return (
    <div ref={ref} className="PeopleActions">
      <Icon
        icon="actions-icon"
        onClick={() => setShowDropdown((prev) => !prev)}
      />

      {showDropdown && (
        <DropdownList
          extended
          color="gray"
          options={filteredOptions}
          onClick={(value) => handleSidebarClick(value as PeopleActionsType)}
        />
      )}

      {selectedContact && (
        <EditUser
          contact={selectedContact}
          onToggle={() => setSelectedContact(undefined)}
        />
      )}
    </div>
  );
};
