import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

import { FloorFormFields } from "../../../../../api/graphql/workplaces";
import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";

const FloorSchema = Yup.object().shape({
  name: Yup.string().min(2, f.validName).required(f.required),
});

interface UseFloorFormOptions {
  initialValues?: FloorFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FloorFormFields>
  ) => Promise<any>;
}

export type FloorFormik = ReturnType<typeof useFloorFormik>;

export const useFloorFormik = (opts: UseFloorFormOptions) => {
  return useFormik<FloorFormFields>({
    initialValues: {
      name: "",
      description: "",
      tags: [],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: FloorSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
