import React, { PropsWithChildren, useState } from "react";
import { MfaContext, MfaContextValues, MfaModalKeys } from "./MfaContext";

export const MfaContextProvider = ({
  children,
}: PropsWithChildren<{
  children: React.ReactNode;
}>) => {
  const [mfaModal, setMfaModal] = useState<MfaModalKeys | undefined>(undefined);

  const context: MfaContextValues = {
    mfaModal,
    setMfaModal,
  };

  return <MfaContext.Provider value={context}>{children}</MfaContext.Provider>;
};
