import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { EditDeviceFormFields } from "../../../../../api/graphql/workplaces";

const DeviceSchema = Yup.object().shape({
  name: Yup.string().required("Please enter the name of the device!"),
});

interface UseDeviceFormOptions {
  initialValues?: EditDeviceFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: EditDeviceFormFields,
    formikHelpers: FormikHelpers<EditDeviceFormFields>
  ) => Promise<any>;
}

export type EditDeviceFormik = ReturnType<typeof useEditDeviceFormik>;

export const useEditDeviceFormik = (opts: UseDeviceFormOptions) => {
  return useFormik<EditDeviceFormFields>({
    initialValues: {
      name: "",
      roomId: "",
      description: "",
      autoUpdate: true,
    },
    ...opts,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: DeviceSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
