import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  FormikWayFinderDisplaySettings,
  WayFinderDisplaySettings,
} from "../../../../../api/graphql/wayfinder/wayfinder";

const DisplaySchema = Yup.object().shape({
  main: Yup.object().shape({
    adminPin: Yup.string()
      .matches(/^[0-9]+$/, "Pin number must be only digits!")
      .min(4, "Pin number must be exactly 4 digits long!")
      .max(4, "Pin number must be exactly 4 digits long!"),
    backgroundImageUrl: Yup.string()
      .matches(/^https:\/\/(.*)$/, {
        message:
          "Please make sure that the link is from a secure source, starting with an https://",
        excludeEmptyString: true,
      })
      .matches(/^(.*).(png|jpg|jpeg)$/, {
        message:
          "Please make sure that image is from a supported format(.jpg, .jpeg or .png)",
        excludeEmptyString: true,
      }),
    logoImageUrl: Yup.string()
      .matches(/^https:\/\/(.*)$/, {
        message:
          "Please make sure that the link is from a secure source, starting with an https://",
        excludeEmptyString: true,
      })
      .matches(/^(.*).(png|jpg|jpeg)$/, {
        message:
          "Please make sure that image is from a supported format(.jpg, .jpeg or .png)",
        excludeEmptyString: true,
      }),
  }),
});

interface UseWayformDisplaySettingsFormOptions {
  enableReinitialize?: boolean;
  onSubmit: (
    values: FormikWayFinderDisplaySettings,
    formikHelpers: FormikHelpers<FormikWayFinderDisplaySettings>
  ) => Promise<any>;
}

export type WayfinderDisplaySettingsFormik = ReturnType<
  typeof useWayfinderDisplaySettingsFormik
>;

export const useWayfinderDisplaySettingsFormik = (
  data: WayFinderDisplaySettings | null | undefined,
  opts: UseWayformDisplaySettingsFormOptions
) => {
  return useFormik<FormikWayFinderDisplaySettings>({
    initialValues: {
      main: {
        adminPin: data?.adminPin ? data.adminPin.toString() : "",
        backgroundImageUrl: data?.backgroundImageUrl || "",
        logoImageUrl: data?.logoImageUrl || "",
      },
      display: {
        customerId: data?.customerId || "",
        showOrganizer: data?.showOrganizer || false,
        showMeetingTitle: data?.showMeetingTitle || false,
        energySaveMode: data?.energySaveMode || false,
        energySaveModeStart: data?.energySaveModeStart || "",
        energySaveModeEnd: data?.energySaveModeEnd || "",
        allowAdhocBookings: data?.allowAdhocBookings || false,
        mapView: data?.mapView || false,
        maximizedMapView: data?.maximizedMapView || false,
        scalingFactor: data?.scalingFactor || "1",
        floor: data?.floor || null,
        showOccupancyCards: data?.showOccupancyCards || false,
        switcherTempo: data?.switcherTempo || 5,
        customLanguage: data?.customLanguage || undefined,
        workingWeekDays: data?.workingWeekDays || [1, 2, 3, 4, 5],
      },
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: DisplaySchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
