import { useApolloClient, useLazyQuery } from "@apollo/client";

import { useAuthContext } from "../../../lib/context/Auth/AuthContext";

import { transport } from "../../grpc/grpcTransport";
import { PairingClient } from "../../grpc/pairing/pairing.client";
import { DeleteWorkplaceRequest, DeleteWorkplaceResponse } from "../workplaces";
import { RoomFields, ROOM_FIELDS_FRAGMENT } from "../rooms/rooms";
import {
  AddDeviceResponse,
  AddDeviceVariables,
  ADD_DEVICE,
  DisconnectDeviceVariables,
  UpdateDeviceRoomVariables,
  DISCONNECT_DEVICE,
  UpdateDeviceVariables,
  UpdateDeviceResponse,
  UPDATE_DEVICE,
  UPDATE_EXISTING_DEVICE_ROOM,
  GetDevicesResponse,
  GET_DEVICES,
  UNPAIR_DEVICE,
  UpdateDeviceWayfinderVariables,
  UPDATE_EXISTING_DEVICE_WAY_FINDER,
  DISCONNECT_DEVICE_WAY_FINDER,
  DEVICE_FIELDS_FRAGMENTS,
  GetAllDevicesRequest,
} from "./devices";
import {
  WayFinderFields,
  WAY_FINDER_FIELDS_FRAGMENT,
} from "../wayfinder/wayfinder";
import { PairingDeviceType } from "../../grpc/pairing/pairing";

export const useDevicesRequests = () => {
  const client = useApolloClient();
  const { tokenInterceptor } = useAuthContext();
  const pairingClient = new PairingClient(transport);
  const [requestDevices, requestState] = useLazyQuery<
    GetDevicesResponse,
    GetAllDevicesRequest
  >(GET_DEVICES, {
    fetchPolicy: "cache-and-network",
  });

  const addDevice = async (variables: AddDeviceVariables) => {
    return await client.mutate<AddDeviceResponse, AddDeviceVariables>({
      mutation: ADD_DEVICE,
      variables,
    });
  };

  const updateDevice = async (variables: UpdateDeviceVariables) => {
    return await client.mutate<UpdateDeviceResponse, UpdateDeviceVariables>({
      mutation: UPDATE_DEVICE,
      variables,
    });
  };

  const unpairDevice = async (variables: DeleteWorkplaceRequest) => {
    return await client.mutate<DeleteWorkplaceResponse, DeleteWorkplaceRequest>(
      {
        mutation: UNPAIR_DEVICE,
        variables,
      }
    );
  };

  const updateDeviceWayfinder = async (
    variables: UpdateDeviceWayfinderVariables
  ) => {
    const { oldWayfinderId, ...rest } = variables;
    return await client.mutate<
      UpdateDeviceResponse,
      UpdateDeviceWayfinderVariables
    >({
      mutation: UPDATE_EXISTING_DEVICE_WAY_FINDER,
      variables: rest,
      update: () => {
        if (!oldWayfinderId) {
          return;
        }

        const previousData = client.readFragment<WayFinderFields>({
          id: `Wayfinder:${oldWayfinderId}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Wayfinder:${oldWayfinderId}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            devices:
              previousData?.devices?.filter(
                (item) => item.id !== variables.id
              ) || [],
          },
        });

        const devicePreviousData = client.readFragment({
          id: `Device:${variables.id}`,
          fragmentName: "DeviceFields",
          fragment: DEVICE_FIELDS_FRAGMENTS,
        });

        client.writeFragment({
          id: `Device:${variables.id}`,
          fragmentName: "DeviceFields",
          fragment: DEVICE_FIELDS_FRAGMENTS,
          data: {
            ...devicePreviousData,
            unassignedSince: variables?.unassignedSince,
            warnedUnassigned: variables?.warnedUnassigned,
          },
        });
      },
    });
  };

  const updateDeviceRoom = async (variables: UpdateDeviceRoomVariables) => {
    const { oldRoomId, ...rest } = variables;

    return await client.mutate<UpdateDeviceResponse, UpdateDeviceRoomVariables>(
      {
        mutation: UPDATE_EXISTING_DEVICE_ROOM,
        variables: rest,
        update: () => {
          if (!oldRoomId) {
            return;
          }

          const previousData = client.readFragment<RoomFields>({
            id: `Room:${oldRoomId}`,
            fragmentName: "RoomFields",
            fragment: ROOM_FIELDS_FRAGMENT,
          });

          client.writeFragment({
            id: `Room:${oldRoomId}`,
            fragmentName: "RoomFields",
            fragment: ROOM_FIELDS_FRAGMENT,
            data: {
              ...previousData,
              devices:
                previousData?.devices?.filter(
                  (item) => item.id !== variables.id
                ) || [],
            },
          });

          const devicePreviousData = client.readFragment({
            id: `Device:${variables.id}`,
            fragmentName: "DeviceFields",
            fragment: DEVICE_FIELDS_FRAGMENTS,
          });

          client.writeFragment({
            id: `Device:${variables.id}`,
            fragmentName: "DeviceFields",
            fragment: DEVICE_FIELDS_FRAGMENTS,
            data: {
              ...devicePreviousData,
              unassignedSince: variables?.unassignedSince,
              warnedUnassigned: variables?.warnedUnassigned,
            },
          });
        },
      }
    );
  };

  const disconnectDevice = async (variables: DisconnectDeviceVariables) => {
    return await client.mutate<any, DisconnectDeviceVariables>({
      mutation: DISCONNECT_DEVICE,
      variables,

      update: () => {
        const previousData = client.readFragment<RoomFields>({
          id: `Room:${variables.roomId}`,
          fragmentName: "RoomFields",
          fragment: ROOM_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Room:${variables.roomId}`,
          fragmentName: "RoomFields",
          fragment: ROOM_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            devices:
              previousData?.devices?.filter(
                (item) => item.id !== variables.id
              ) || [],
          },
        });

        const devicePreviousData = client.readFragment({
          id: `Device:${variables.id}`,
          fragmentName: "DeviceFields",
          fragment: DEVICE_FIELDS_FRAGMENTS,
        });

        client.writeFragment({
          id: `Device:${variables.id}`,
          fragmentName: "DeviceFields",
          fragment: DEVICE_FIELDS_FRAGMENTS,
          data: {
            ...devicePreviousData,
            unassignedSince: variables?.unassignedSince,
            warnedUnassigned: variables?.warnedUnassigned,
          },
        });
      },
    });
  };

  const disconnectDeviceWayfinder = async (
    variables: DisconnectDeviceVariables
  ) => {
    return await client.mutate<any, DisconnectDeviceVariables>({
      mutation: DISCONNECT_DEVICE_WAY_FINDER,
      variables,

      update: () => {
        const previousData = client.readFragment<WayFinderFields>({
          id: `Wayfinder:${variables.wayFinderId}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
        });

        client.writeFragment({
          id: `Wayfinder:${variables.wayFinderId}`,
          fragmentName: "WayfinderFields",
          fragment: WAY_FINDER_FIELDS_FRAGMENT,
          data: {
            ...previousData,
            devices:
              previousData?.devices?.filter(
                (item) => item.id !== variables.id
              ) || [],
          },
        });

        const devicePreviousData = client.readFragment({
          id: `Device:${variables.id}`,
          fragmentName: "DeviceFields",
          fragment: DEVICE_FIELDS_FRAGMENTS,
        });

        client.writeFragment({
          id: `Device:${variables.id}`,
          fragmentName: "DeviceFields",
          fragment: DEVICE_FIELDS_FRAGMENTS,
          data: {
            ...devicePreviousData,
            unassignedSince: variables?.unassignedSince,
            warnedUnassigned: variables?.warnedUnassigned,
          },
        });
      },
    });
  };

  const pairDevice = async (
    code: string,
    customerId: string,
    deviceType: PairingDeviceType
  ) => {
    return await pairingClient.pair(
      { code, customerId, deviceType },
      {
        interceptors: [tokenInterceptor],
      }
    );
  };

  return {
    addDevice,
    getDevices: {
      state: requestState,
      request: requestDevices,
    },
    pairDevice,
    updateDevice,
    unpairDevice,
    disconnectDevice,
    disconnectDeviceWayfinder,
    updateDeviceRoom,
    updateDeviceWayfinder,
  };
};
