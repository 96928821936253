import { RpcInterceptor } from "@protobuf-ts/runtime-rpc";

import { transport } from "../../../api/grpc/grpcTransport";
import { ChargeBeeHostedServiceClient } from "../../../api/grpc/subscription/subscription.client";

export const createHostedPage = async (
  chargeBeeId: string,
  interceptor: RpcInterceptor,
  itemPriceID: string,
  redirectURL: string
) => {
  const hostedServiceClient = new ChargeBeeHostedServiceClient(transport);

  try {
    const { response } = await hostedServiceClient.createHostedPage(
      {
        customerID: chargeBeeId,
        itemPriceID: itemPriceID,
        redirectURL: redirectURL,
      },
      {
        interceptors: [interceptor],
      }
    );
    return response;
  } catch (error: any) {
    console.error(error.message);
  }

  return;
};
