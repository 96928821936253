import React from "react";
import { SettingsProfilesContent } from "../../../components/SettingsProfiles/SettingsProfilesContent";
import { PageHeader } from "../../../components/shared/PageHeader/PageHeader";
import { tooltips } from "../../../lib/utils/tooltips";

import "./SettingsProfiles.scss";

export const SettingsProfiles = () => {
  return (
    <div className="SettingsProfiles">
      <PageHeader
        title="Settings templates"
        message={tooltips.templates.settingsTemplates}
      />

      <SettingsProfilesContent />
    </div>
  );
};
