import React, { useEffect, useState } from "react";
import _ from "lodash";
import { parse } from "query-string";
import { toast } from "react-toastify";

import { useRouter } from "../../hooks/useRouter";
import { useCalendarRequests } from "../../../api/grpc/calendarprovider/useCalendarRequests";
import { useAuthContext } from "../Auth/AuthContext";
import { useValidPersonalToken } from "../ValidateToken/ValidateTokenContext";

import { CalendarContext, Provider } from "./CalendarContext";
import {
  Calendar,
  CalendarType,
  EWSProvider,
  GGCalendarProvider,
  CalendarProviderDeviceType,
  PersonalCalendar,
  GGCalendarProviderStatus,
} from "../../../api/grpc/calendarprovider/calendarprovider";
import { BOOKINGS_ROOT_PATH, CALENDARS_ROOT_PATH } from "../../routes/routes";
import { Icon } from "../../../components/shared/Icon/Icon";
import { SUCCESS_STRINGS } from "../../utils/constants";

export interface ProvidersList {
  [key: string]: Calendar[];
}

export interface Providers extends GGCalendarProvider {
  settings?: EWSProvider;
}

export const CalendarContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [loading, setLoading] = useState(false);
  const [loadingPersonalCalendar, setLoadingPersonalCalendar] = useState(false);
  const [errorPersonalCalendar, setErrorPersonalCalendar] = useState(false);
  const [error, setError] = useState(false);
  const { updateUserAvatarAfterPersonalLinked } = useAuthContext();

  const { location, history } = useRouter();
  const [openEnvironment, setOpenEnvironment] = useState(false);

  const {
    retrieveProviderList,
    createO365Provider,
    linkPersonal0365Calendar,
    listPersonalCalendars,
    reAuthenticateIntegration,
  } = useCalendarRequests();

  const { openReAuthModal, setOpenReAuthModal } = useValidPersonalToken();

  const [providers, setProviders] = useState<Providers[]>([]);
  const [brokenProviders, setBrokenProviders] = useState<Providers[]>([]);
  const [personalCalendar, setPersonalCalendar] = useState<PersonalCalendar[]>(
    []
  );
  const [providersList, setProvidersList] = useState<ProvidersList>({});

  const hash = parse(location.hash);
  const params = parse(location.search);

  const isPersonalCalendarIntegration =
    !location.pathname.includes(CALENDARS_ROOT_PATH);

  useEffect(() => {
    //we clean local storage items if user opens microsoft constent page and doesn't complete the auth proccess
    if (!params.complete) {
      if (localStorage.getItem("initiateO365ReAuth")) {
        localStorage.removeItem("initiateO365ReAuth");
      }

      if (localStorage.getItem("calendarId")) {
        localStorage.removeItem("calendarId");
      }
    }

    loadPersonalCalendar();
    loadCalendarProviders();
    finishO365Integration();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (personalCalendar.length <= 0) {
      return;
    }

    const finishReAuth = async () => {
      if (!params.complete && !params.complete?.includes("0365")) {
        return;
      }

      const code =
        hash.code && typeof hash.code === "object" ? hash.code[0] : hash.code;

      if (!code || localStorage.getItem("initiateO365ReAuth") !== "1") {
        return;
      }

      try {
        await reAuthenticateIntegration(personalCalendar[0].iD, {
          oneofKind: "o365",
          o365: {
            oAuthToken: code,
            calendarType: CalendarType.O365_WEB,
          },
        });

        const personalCalendarRes = await listPersonalCalendars();
        setPersonalCalendar(personalCalendarRes.response.calendars);

        if (openReAuthModal) {
          setOpenReAuthModal(false);
        }

        await loadCalendarProviders();

        toast.success(
          <div className="SuccessToast">
            <div className="flex-a-center">
              <Icon icon="check-icon" />
              <span className="ml-2">
                Re-authentication finished successfully!
              </span>
            </div>
          </div>,
          {
            position: toast.POSITION.TOP_LEFT,
          }
        );

        return;
      } catch (error: any) {
        toast.error(
          error?.message ||
            "Couldn't re-authenticate. Please contact your administrator."
        );
      } finally {
        localStorage.removeItem("initiateO365ReAuth");

        setTimeout(() => {
          history.push(BOOKINGS_ROOT_PATH);
        }, 2000);
      }
    };

    finishReAuth();
  }, [personalCalendar]);

  const loadCalendarProviders = async () => {
    try {
      setError(false);
      setLoading(true);
      const res = await retrieveProviderList();

      setProviders(res.response.providers);
      setBrokenProviders(
        res.response.providers.filter(
          (provider) => provider.status === GGCalendarProviderStatus.FAILED
        )
      );
    } catch (error: any) {
      setError(true);
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const loadPersonalCalendar = async () => {
    try {
      setErrorPersonalCalendar(false);
      setLoadingPersonalCalendar(true);

      const personalCalendarRes = await listPersonalCalendars();

      setPersonalCalendar(personalCalendarRes.response.calendars);
    } catch (error: any) {
      setErrorPersonalCalendar(true);
      console.error(error.message);
    } finally {
      setLoadingPersonalCalendar(false);
    }
  };

  const finishO365Integration = async () => {
    if (!params.complete && !params.complete?.includes("0365")) {
      return;
    }

    const code =
      hash.code && typeof hash.code === "object" ? hash.code[0] : hash.code;

    if (!code || localStorage.getItem("initiateO365ReAuth") === "1") {
      return;
    }

    if (localStorage.getItem("calendarId")) {
      try {
        await reAuthenticateIntegration(
          localStorage.getItem("calendarId") as string,
          {
            oneofKind: "o365",
            o365: {
              oAuthToken: code,
              calendarType: CalendarType.O365,
            },
          }
        );

        await loadCalendarProviders();

        toast.success(
          <div className="SuccessToast">
            <div className="flex-a-center">
              <Icon icon="check-icon" />
              <span className="ml-2">
                Re-authentication finished successfully!
              </span>
            </div>
          </div>,
          {
            position: toast.POSITION.TOP_LEFT,
          }
        );

        return;
      } catch (error: any) {
        console.error(error?.message);
        toast.error(error?.message ?? "Calendar couldn't be re-authenticated!");
        return;
      } finally {
        localStorage.removeItem("calendarId");

        return setTimeout(() => {
          history.push(CALENDARS_ROOT_PATH);
        }, 2000);
      }
    }

    toast.info("Finishing adding integration...");

    try {
      const result = isPersonalCalendarIntegration
        ? await linkPersonal0365Calendar("armin@roomdev.se", {
            oAuthToken: code,
            calendarType: CalendarType.O365,
          })
        : await createO365Provider(
            "armin@roomdev.se",
            {
              oAuthToken: code,
              calendarType: CalendarType.O365,
            },
            params.complete.includes("custom")
              ? {
                  azureUrl: "https://portal.azure.us",
                  graphUrl: "https://graph.microsoft.us",
                  name: "Microsoft 365 GCC High",
                }
              : undefined
          );

      if (!isPersonalCalendarIntegration) {
        addProvider({
          calType: CalendarType.O365,
          iD: result.response.iD,
          name: result.response.name,
          deviceType: CalendarProviderDeviceType.WEB,
          token: "",
          email: "",
          status: GGCalendarProviderStatus.ACTIVE,
          apiError: "",
          hostUrl: "",
        });
      }

      if (isPersonalCalendarIntegration) {
        setPersonalCalendar([
          {
            iD: result.response.iD,
            name: result.response.name,
            email: "",
            hostUrl: "",
          },
        ]);
        await updateUserAvatarAfterPersonalLinked();
      }

      toast.success(SUCCESS_STRINGS.calendarAdded);
    } catch (error: any) {
      console.error(error.message);
      toast.error(error?.message ?? "Calendar couldn't be added!");
    } finally {
      setTimeout(() => {
        history.push(
          isPersonalCalendarIntegration
            ? BOOKINGS_ROOT_PATH
            : CALENDARS_ROOT_PATH
        );
      }, 2000);
    }
  };

  const getProviderByID = (id: string) => {
    return providers.find((item) => item.iD === id);
  };

  const getProvidersByType = (type: CalendarType) => {
    return providers.filter((item) => item.calType === type);
  };

  const deleteProvider = (id: string) => {
    setProviders((prev) => {
      return prev.filter((item) => item.iD !== id);
    });
  };

  const updateProvidersList = (key: string, value: Calendar[]) => {
    setProvidersList((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const updateProviderSettings = (
    id: string,
    settings: EWSProvider,
    includeName?: boolean
  ) => {
    setProviders((prev) => {
      const newValue = prev.map((item) => {
        if (item.iD === id) {
          item.settings = settings;
        }

        if (item.iD === id && includeName) {
          item.name = settings.name;
        }

        return item;
      });

      return newValue;
    });
  };

  const addProvider = (provider: Providers) => {
    setProviders((prev) => {
      const newValue = [...prev, provider];

      return _.orderBy(newValue, ["calType"], ["asc"]);
    });
  };

  const context: CalendarContext = {
    error,
    loading,
    providers,
    brokenProviders,
    setBrokenProviders,
    providersList,
    addProvider,
    deleteProvider,
    getProviderByID,
    getProvidersByType,
    updateProvidersList,
    updateProviderSettings,
    openEnvironment,
    setOpenEnvironment,
    personalCalendar,
    setPersonalCalendar,
    errorPersonalCalendar,
    loadingPersonalCalendar,
    refetchCalendars: loadCalendarProviders,
    refetchPersonalCalendar: loadPersonalCalendar,
    dynamicStyles: {
      "--providers-count": brokenProviders.length,
    } as React.CSSProperties,
  };

  return <Provider value={context}>{props.children}</Provider>;
};
