import React, { PropsWithChildren } from "react";
import { Subscription } from "../../../api/grpc/subscription/subscription";
import { Icon } from "../../shared/Icon/Icon";
import { PageHeader } from "../../shared/PageHeader/PageHeader";
import { CustomerSubscriptionsTable } from "./CustomerSubscriptionsTable/CustomerSubscriptionsTable";
import { CalculatorProRate } from "./CalculatorProRate/CalculatorProRate";
import { CalculatorProContextProvider } from "./CalculatorProRate/Context/CalculatorProContextProvider";

import "./CustomerSubscriptions.scss";

export const CustomerSubscriptions = ({
  subscriptions,
}: PropsWithChildren<{
  subscriptions: Subscription[];
}>) => {
  return (
    <div className="CustomerSubscriptions default-page">
      <PageHeader
        title="Customer Subscriptions"
        className="flex-column align-items-start"
      >
        <div className="CustomerSubscriptions__info d-flex">
          <Icon icon="child-subs-icon" />
          <div>
            <span>
              This section provides you, as a channel partner, with information
              on your customers' existing subscriptions. It includes a tool for
              calculating pro-rating when adding items to a subscription and a
              link to our partner help guide.
            </span>
            <span>
              For GOGET, all items (rooms, desks, etc.) expire on the same date,
              so it's crucial for customers to renew within this date.
              Subscriptions with the status 'Active' will automatically renew,
              while 'Non-renewing' subscriptions will cancel on the expiry date.
            </span>
            <span>
              Pro-rating is used to calculate the amount a customer should pay
              when adding items (rooms, desks, etc.) to an existing
              subscription. Essentially, this means that customers will only pay
              for the number of days until the expiry date.
            </span>
          </div>
        </div>
      </PageHeader>

      <div className="d-flex CustomerSubscriptions__content">
        <CustomerSubscriptionsTable subscriptions={subscriptions} />
        <CalculatorProContextProvider>
          <CalculatorProRate subscriptions={subscriptions} />
        </CalculatorProContextProvider>
      </div>
    </div>
  );
};
