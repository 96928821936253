import React, { PropsWithChildren } from "react";
import { ResponsiveFunnel } from "@nivo/funnel";

import { UtilizationData } from "../../Utilization/UtilizationOccupancy/UtilizationOccupancyByDayOfWeek/UtilizationOccupancyByDayOfWeek";

type DataFunnelType = "Released" | "Booked" | "Reclaimed";

let attachColors: {
  [key in DataFunnelType]: string;
} = {
  Released: "#2CD0AF",
  Booked: "#156C79",
  Reclaimed: "#00EC97",
};

export const RealizedMeetingsFunnel = ({
  data,
}: PropsWithChildren<{ data: UtilizationData[] }>) => {
  let definedData = data.map((item) => {
    return {
      id: item.id,
      label: item.name,
      value: item.value,
      color: attachColors[item.id as DataFunnelType],
    };
  });

  return (
    <>
      <ResponsiveFunnel
        data={definedData}
        margin={{ top: 10, right: 20, bottom: 85, left: 20 }}
        direction="horizontal"
        colors={({ color }) => color}
        borderWidth={14}
        borderColor={{ from: "color", modifiers: [] }}
        borderOpacity={0.5}
        labelColor={{
          from: "color",
          modifiers: [["darker", 3]],
        }}
        theme={{
          grid: {
            line: {
              display: "none",
            },
          },
        }}
        enableBeforeSeparators={false}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={3}
        enableAfterSeparators={false}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={11}
        currentBorderWidth={22}
        shapeBlending={0.6}
      />

      <div className="RealizedMeetingsFunnel flex-a-center">
        {definedData.map(({ id }) => (
          <div key={id} className="flex-a-center">
            <div
              className={`RealizedMeetingsFunnel__legend RealizedMeetingsFunnel__legend--${id}`}
            ></div>
            <div className="RealizedMeetingsFunnel__name">{id}</div>
          </div>
        ))}
      </div>
    </>
  );
};
