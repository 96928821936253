import React, { useState } from "react";

import { SingleUserModal } from "../SingleUserModal/SingleUserModal";
import { PeopleModalType } from "../InvitePeopleButton/InvitePeopleButton";

interface Props {
  type: PeopleModalType;
  onComplete: () => void;
}

export const InvitePeopleModal = ({ type, onComplete }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);

    setTimeout(() => onComplete(), 200);
  };

  return <SingleUserModal isOpen={isOpen} toggle={handleToggle} />;
};
