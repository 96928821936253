import { useCustomLanguageClient } from "../../../../../api/grpc/workplaces/useCustomLanguageClient";
import {
  CustomLanguageAppType,
  ListCustomerLanguagesRequest,
  ListCustomerLanguagesResponse,
} from "../../../../../api/grpc/workplaces/workplaces";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";

export const useListLanguags = (appType: CustomLanguageAppType) => {
  const { listCustomLanguages } = useCustomLanguageClient();

  const { loading, error, data, refetch } = useGrpcQuery<
    Omit<ListCustomerLanguagesRequest, "customerId">,
    ListCustomerLanguagesResponse
  >({
    method: listCustomLanguages,
    variables: {
      appType,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
