import React from "react";
import { Form } from "reactstrap";
import { WayfinderDisplaySettingsFormik } from "../lib/useWayfinderDisplaySettingsForm";
import { WayFinderDisplaySettings } from "./WayFinderDisplaySettings";
import { WayFinderMainSettings } from "./WayFinderMainSettings";

interface Props {
  formik: WayfinderDisplaySettingsFormik;
  isBussinesOrPro?: boolean;
  wayfinderId: string;
  currentCustomLanguageId?: string;
}

export const WayFinderDisplaySettingsMain = ({
  formik,
  isBussinesOrPro,
  wayfinderId,
  currentCustomLanguageId,
}: Props) => {
  const handleFormikChange = (
    field: string,
    value: string | string[] | boolean | number | Date | null
  ) => {
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field);
  };

  return (
    <Form>
      <WayFinderMainSettings
        formik={formik}
        isBussinesOrPro={isBussinesOrPro}
        onChange={(key, value) => handleFormikChange(key, value)}
        wayfinderId={wayfinderId}
        currentCustomLanguageId={currentCustomLanguageId}
      />
      <WayFinderDisplaySettings
        formik={formik}
        onChange={(key, value) => handleFormikChange(key, value)}
      />
    </Form>
  );
};
