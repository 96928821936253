export const defineLeastMostPaddingChart = (value: number) => {
  if (value === 1) {
    return 0.91;
  }
  if (value === 2) {
    return 0.87;
  }
  if (value === 3) {
    return 0.85;
  }
  if (value === 4) {
    return 0.8;
  }
  if (value === 5) {
    return 0.75;
  }

  if (value === 6) {
    return 0.72;
  }
  return 0.7;
};
