import React, { useMemo } from "react";

import { useToggleModal } from "../../../../../lib/hooks/useToggleModal";
import { useListLanguags } from "../../DisplaySettingsForm/lib/useListLanguages";

import { FormGroup } from "reactstrap";
import { CustomLanguageAppType } from "../../../../../api/grpc/workplaces/workplaces";
import { tooltips } from "../../../../../lib/utils/tooltips";
import { CustomLanguageModal } from "../../../../Modals/CustomLanguageModal/CustomLanguageModal";
import { FormSection } from "../../../FormSection/FormSection";
import { Input } from "../../../Input/Input";
import { LabelWithInformationBox } from "../../../LabelWithInformationBox/LabelWithInformationBox";
import { WayfinderDisplaySettingsFormik } from "../lib/useWayfinderDisplaySettingsForm";
import { SelectLanguage } from "../../DisplaySettingsForm/shared/SelectLanguage";

interface Props {
  formik: WayfinderDisplaySettingsFormik;
  onChange: (
    key: string,
    value: string | string[] | number | boolean | Date | null
  ) => void;
  isBussinesOrPro?: boolean;
  wayfinderId: string;
  currentCustomLanguageId?: string;
}

export const WayFinderMainSettings = ({
  formik,
  onChange,
  isBussinesOrPro,
  wayfinderId,
  currentCustomLanguageId,
}: Props) => {
  const { isOpen, toggleModal } = useToggleModal();

  const { loading, error, data, refetch } = useListLanguags(
    CustomLanguageAppType.RFX
  );

  const definedLanguages = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.data.map((lng) => {
      return {
        id: lng.id,
        value: !!lng.value ? lng.value : lng.id,
        label: lng.name,
        isGlobal: lng.isGlobal,
      };
    });
  }, [data]);

  const selectedValue = useMemo(() => {
    if (!formik.values.display.customLanguage) {
      const englishDefaultLanguage = definedLanguages.find(
        (language) => language.value === "en"
      );

      if (!englishDefaultLanguage) {
        return {
          label: "English",
          value: "en",
        };
      }

      formik.setFieldValue("display.customLanguage", englishDefaultLanguage);

      return {
        label: englishDefaultLanguage.label,
        value: englishDefaultLanguage.value,
      };
    }
    return definedLanguages
      .filter(
        (language) => language.id === formik.values.display.customLanguage?.id
      )
      .map(({ label, value }) => ({
        label: label,
        value: value,
      }));
  }, [definedLanguages, formik.values.display.customLanguage]);

  const shouldDisplayEditLanguageButton = useMemo(() => {
    //we only show edit button if user has pressed Save settings when changing custom langauge
    //to improve the flow
    return (
      formik.values.display.customLanguage?.id === currentCustomLanguageId &&
      formik.values.display.customLanguage?.isGlobal
    );
  }, [formik, currentCustomLanguageId]);

  return (
    <FormSection title="Main settings" toggleable>
      <FormGroup>
        <LabelWithInformationBox
          title="Admin PIN (4 digits 0-9)"
          for="main.adminPin"
          message={tooltips.rooms.main.adminPin}
        />
        <Input
          id="main.adminPin"
          name="main.adminPin"
          value={formik.values.main.adminPin}
          onBlur={formik.handleBlur}
          isTouched={formik.touched.main?.adminPin}
          errorMessage={formik.errors.main?.adminPin}
          onChange={formik.handleChange}
          invalid={
            !!formik.errors.main?.adminPin && formik.touched.main?.adminPin
          }
        />
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          title="Background image"
          for="main.backgroundImageUrl"
          message={tooltips.wayfinders.main.background}
        />
        <Input
          type="text"
          id="main.backgroundImageUrl"
          name="main.backgroundImageUrl"
          value={
            isBussinesOrPro
              ? formik.values.main.backgroundImageUrl
              : "Upgrade your plan to access this feature!"
          }
          disabled={!isBussinesOrPro}
          onBlur={formik.handleBlur}
          isTouched={formik.touched.main?.backgroundImageUrl}
          onChange={formik.handleChange}
          invalid={
            !!formik.errors.main?.backgroundImageUrl &&
            formik.touched.main?.backgroundImageUrl
          }
          errorMessage={formik.errors.main?.backgroundImageUrl}
        />
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="logoImageUrl"
          title="Logo image"
          message={tooltips.rooms.main.logo}
        />
        <Input
          type="text"
          id="main.logoImageUrl"
          value={
            isBussinesOrPro
              ? formik.values.main.logoImageUrl
              : "Upgrade your plan to access this feature!"
          }
          onBlur={formik.handleBlur}
          disabled={!isBussinesOrPro}
          isTouched={formik.touched.main?.logoImageUrl}
          errorMessage={formik.errors.main?.logoImageUrl}
          onChange={formik.handleChange}
          invalid={
            !!formik.errors.main?.logoImageUrl &&
            formik.touched.main?.logoImageUrl
          }
        />
      </FormGroup>

      <FormGroup className="RoomForm__main position-relative">
        <LabelWithInformationBox
          for="display.language"
          title="Language"
          message={tooltips.wayfinders.displaySettings.language}
        />
        <SelectLanguage
          loading={loading}
          error={error}
          toggleModal={toggleModal}
          options={definedLanguages}
          selectedValue={selectedValue}
          shouldDisplayEditButton={shouldDisplayEditLanguageButton}
          isGlobalLanguage={
            formik.values.display.customLanguage?.isGlobal || false
          }
          onChange={(e) => {
            formik.setFieldValue("display.customLanguage", e);
            formik.setFieldTouched("display.customLanguage", true);

            return;
          }}
        />
        {isOpen && (
          <CustomLanguageModal
            isOpen={isOpen}
            workspaceId={wayfinderId}
            toggleModal={toggleModal}
            customLanguageId={formik.values.display.customLanguage?.id || ""}
            type="wayfinder"
            refetchLanguages={refetch}
          />
        )}
      </FormGroup>
    </FormSection>
  );
};
