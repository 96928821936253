import { timeFormat } from "./displayTimeFormat";

export const timePickerProps = {
  showTimeSelect: true,
  showTimeSelectOnly: true,
  timeIntervals: 15,
  timeCaption: "Time",
  dateFormat: timeFormat,
  timeFormat: timeFormat,
};
