import { createContext, useContext } from "react";

export type TimeZoneContextValues = {
  timeZone: string;
  timeZoneFormatted: string; //
  openTimeZoneSettings: () => void;
};

export const TimeZoneContext = createContext<TimeZoneContextValues>({
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  timeZoneFormatted: "",
  openTimeZoneSettings: () => {},
});

export const useTimeZoneContext = () => {
  const context = useContext(TimeZoneContext);

  if (!context) {
    throw new Error("useTimeZoneContext must be used within TimeZoneContext");
  }

  return context;
};
