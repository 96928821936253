import { useBookingRequest } from "../../../../api/grpc/booking/useBookingRequest";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import useGrpcQuery from "../../../../lib/hooks/useGrpcQuery";

import {
  Interval,
  ListPersonalMeetingsRequest,
  ListPersonalMeetingsResponse,
} from "../../../../api/grpc/booking/booking";

export const useRoomReservations = () => {
  const { listBookings } = useBookingRequest();
  const { timeZone } = useTimeZoneContext();

  const {
    loading: loadingRoom,
    error: errorRoom,
    data: roomData,
    refetch: refetchRoom,
  } = useGrpcQuery<ListPersonalMeetingsRequest, ListPersonalMeetingsResponse>({
    method: listBookings,
    variables: {
      customerId: "",
      week: 1,
      interval: Interval.SEVENDAYS,
      limit: 100,
      timeZone: timeZone,
    },
  });

  return {
    loadingRoom,
    errorRoom,
    roomData,
    refetchRoom,
  };
};
