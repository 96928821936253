import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { DeskFormFields } from "../../../../../api/graphql/workplaces";
import { RESOURCE_STRINGS as f } from "../../../../../lib/utils/constants";

const DeskSchema = Yup.object().shape({
  name: Yup.string().min(2, f.validName).required(f.required),
});

interface UseDeskFormOptions {
  initialValues?: DeskFormFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: DeskFormFields,
    formikHelpers: FormikHelpers<DeskFormFields>
  ) => Promise<any>;
}

export type DeskFormik = ReturnType<typeof useDeskFormik>;

export const useDeskFormik = (opts: UseDeskFormOptions) => {
  return useFormik<DeskFormFields>({
    initialValues: {
      name: "",
      tags: [],
      description: "",
      showCheckInTime: false,
      isBooked: false,
      isBlocked: false,
      marker: { latitude: 0, longitude: 0 },
      checkInTime: 1,
      qrCodeRequired: false,
      licensed: false,
      workingHrsActivated: false,
      workingHrsStart: "",
      workingHrsEnd: "",
      account: {
        accountID: "",
        displayName: "",
        email: "",
      },
      desksNumber: 0,
      locationID: "",
      startIndex: 0,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: DeskSchema,
    ...opts,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
