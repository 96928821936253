import React from "react";
import { useMfaContext } from "../../../../../lib/context/MFA/MfaContext";
import { ModalMFA } from "../ModalMFA";
import { ModalMFANotification } from "../ModalMFANotification/ModalMFANotification";

export const MfaError = () => {
  const { setMfaModal } = useMfaContext();

  return (
    <ModalMFA
      title="A problem occured"
      keyPoints={["Please close and try again"]}
      closeButton="Close"
      handleBackButton={() => setMfaModal(undefined)}
      handleNextButton={() => {}}
      className="ModalResponse"
    >
      <ModalMFANotification
        icon="error-filled"
        title="We couldn't complete the request"
      />
    </ModalMFA>
  );
};
