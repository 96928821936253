import React from "react";
import { AddCalendarConnections } from "../../Calendars/AddCalendarConnections/AddCalendarConnections";
import { FloorEmptyMap } from "../../Floors/FloorEmptyMap/FloorEmptyMap";
import { EmptyRoomDirections } from "../Forms/WayFinderForm/shared/EmptyRoomDirections/EmptyRoomDirections";

export type EntityType = "Calendar" | "FloorMap" | "RoomDirection";

interface ExpandedProps {
  floorId?: string;
  largeView?: boolean;
  largeIcon?: boolean;
  typeOfFloorMap?: "bookings" | "floor";
}

interface Props extends ExpandedProps {
  type: EntityType;
}

const components: {
  [key in EntityType]: React.FunctionComponent<ExpandedProps>;
} = {
  Calendar: AddCalendarConnections,
  FloorMap: FloorEmptyMap,
  RoomDirection: EmptyRoomDirections,
};

export const EmptyEntity = ({
  type,
  floorId,
  largeView,
  largeIcon,
  typeOfFloorMap,
}: Props) => {
  const Component = components[type];

  let componentProps: ExpandedProps = {
    floorId: floorId,
    largeView: largeView,
    largeIcon: largeIcon,
    typeOfFloorMap: typeOfFloorMap,
  };

  return <Component {...componentProps} />;
};
