import React from "react";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";

import { LocationItem } from "../../../../api/grpc/workplaces/workplaces";
import { LocationPath } from "../LocationPath/LocationPath";
import { UserInfoRowItem } from "./UserInfoRowItem";
import { ModalRow } from "../../BookingsMap/BookingsMapModal/shared/ModalRow";

interface Props {
  name: string;
  reservationStartTime: string;
  reservationEndTime: string;
  createdTime: string;
  locationPath: LocationItem[];
  allDay: boolean;
}

export const UserModalDesks = ({
  name,
  reservationStartTime,
  reservationEndTime,
  createdTime,
  locationPath,
  allDay,
}: Props) => {
  const { timeZoneFormatted } = useTimeZoneContext();
  const utc = timeZoneFormatted.split(" ")[0];
  const definedUtc = utc.replace(":00", "");

  return (
    <div className="flex-center flex-col UserInfo__desk BookingsModal__body my-3">
      <ModalRow icon="desk_icon_18x18" title={name} utc={definedUtc} />

      <div className="d-flex flex-column align-items-center w-full pb-2 bg-white">
        <ModalRow
          icon="calendar-bookings"
          title={createdTime}
          utc={definedUtc}
        />

        <UserInfoRowItem
          content={
            allDay
              ? "All day"
              : `${reservationStartTime} - ${reservationEndTime} ${definedUtc}`
          }
        />
      </div>

      <UserInfoRowItem
        icon="location-reserve"
        className="flex-column mt-1"
        content={<LocationPath location={locationPath} />}
      />
    </div>
  );
};
