import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  LoginMethod,
  Role,
} from "../../../../../api/grpc/contacts/account/account";

const SingleUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email!")
    .required("Email is required"),
  role: Yup.string().required("Please select the role of the user!"),
});

export interface SingleUserFields {
  email: string;
  role: Role;
  team: string;
  desk: string[];
  loginMethod: LoginMethod;
}

interface UseSingleUserFormOptions {
  initialValues?: SingleUserFields;
  onSubmit: (
    values: SingleUserFields,
    formikHelpers: FormikHelpers<SingleUserFields>
  ) => Promise<void>;
}

export type SingleUserFormik = ReturnType<typeof useSingleUserFormik>;

export const useSingleUserFormik = (opts: UseSingleUserFormOptions) => {
  return useFormik({
    initialValues: {
      email: "",
      role: Role.MEMBER,
      team: "",
      desk: [] as string[],
      loginMethod: LoginMethod.EMAIL,
    },
    ...opts,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: SingleUserSchema,
  });
};
