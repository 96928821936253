import { addMinutes, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useFormik, FormikHelpers } from "formik";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { SearchWorkplaceFields } from "../../../views/Bookings/types";
// import { datePickOptions } from "../form/SearchWorkplaceForm";

interface UseSearchRoomsFormOptions {
  initialValues?: SearchWorkplaceFields;
  enableReinitialize?: boolean;
  onSubmit: (
    values: SearchWorkplaceFields,
    formikHelpers: FormikHelpers<SearchWorkplaceFields>
  ) => Promise<any>;
}

export type SearchRoomsFormik = ReturnType<typeof useSearchRoomsFormik>;

export const useSearchRoomsFormik = (opts: UseSearchRoomsFormOptions) => {
  const { timeZone } = useTimeZoneContext();

  return useFormik<SearchWorkplaceFields>({
    initialValues: {
      day: {
        label: `Today, ${format(
          utcToZonedTime(new Date(), timeZone),
          "MMM dd"
        )}`,
        value: format(utcToZonedTime(new Date(), timeZone), "yyyy-MM-dd"),
      },
      from: utcToZonedTime(new Date(), timeZone), //new Date(),
      until: utcToZonedTime(addMinutes(new Date(), 15), timeZone),
      tags: [],
      minNrSeats: 0,
      maxNrSeats: 0,
      equipment: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, fromikHelpers) => {
      await opts.onSubmit(values, fromikHelpers);
    },
  });
};
