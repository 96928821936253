import { useMemo, useState } from "react";
import { OnLevelSearchRequestLicenseStatus } from "../../../api/grpc/workplaces/workplaces";
import { IWorkplacesFilters } from "../WorkplaceList/WorkplacesDefaultList/WorkplacesFilters";

export const useWorkplacesFilters = () => {
  const [workplacesFilters, setWorkplacesFilters] =
    useState<IWorkplacesFilters>({
      license:
        OnLevelSearchRequestLicenseStatus.OnLevelSearchRequestLicenseStatusNone,
      location: [],
      resources: [],
    });

  const activeFilters = useMemo(() => {
    let counter = 0;

    if (
      workplacesFilters.license !==
      OnLevelSearchRequestLicenseStatus.OnLevelSearchRequestLicenseStatusNone
    ) {
      counter++;
    }

    if (workplacesFilters.location.length) {
      counter++;
    }

    if (workplacesFilters.resources.length) {
      counter++;
    }

    return counter;
  }, [workplacesFilters]);

  return {
    workplacesFilters,
    setWorkplacesFilters,
    activeFilters,
  };
};
