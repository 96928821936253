import React, { useState } from "react";
import { toast } from "react-toastify";

import { useSingleUserFormik } from "./lib/useSingleUserFormik";
import { useContacts } from "../../../../api/grpc/contacts/useContacts";
import { usePeopleContext } from "../../../../views/Users/People/lib/PeopleContext";

import { SingleUserForm } from "../SingleUserForm/SingleUserForm";
import ModalSidebar from "../../../Modals/ModalSidebar/ModalSidebar";
import { ModalMessage } from "../../../Modals/ModalMessage/ModalMessage";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const SingleUserModal = ({ isOpen, toggle }: Props) => {
  const { inviteContact } = useContacts();
  const { refetchUsers } = usePeopleContext();
  const [loading, setLoading] = useState(false);

  const formik = useSingleUserFormik({
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await inviteContact({
          email: values.email.toLowerCase(),
          role: values.role,
          teamID: 0,
          deskID: values.desk,
          loginMethod: values.loginMethod,
        });

        refetchUsers();

        toast.success("User invited successfully!");
        toggle();
      } catch (error: any) {
        console.error(error.toString());

        toast.error(
          error?.message
            ? error.message
            : "User couldn't be invited, please reload and try again!"
        );
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <ModalSidebar
      isOpen={isOpen}
      loading={loading}
      onToggle={toggle}
      title="Invite new contact"
      onSubmit={formik.handleSubmit}
      submitButtonLabel="Send invite"
    >
      <SingleUserForm formik={formik} />
      <ModalMessage message="By pressing Send invite, we will send an invitation email to the user with a link to complete the account registration." />
    </ModalSidebar>
  );
};
