import React from "react";
import { useToggleModal } from "../../../../lib/hooks/useToggleModal";
import { useAllReservationContext } from "../../shared/context/AllReservationContext/AllReservationContext";

import { MainResources } from "../../../../lib/types/main.types";
import { ReservationCardHeader } from "./ReservationCardHeader";
import { AddReservation } from "./AddReservation";
import { ReservationEvent } from "./ReservationEvent";
import { DeskLocationItem } from "../../../../api/grpc/desk/desk";
import {
  hasEnoughGapBetweenEvents,
  isEnoughTimeLeftInDay,
} from "../../../../lib/helpers/dateHelpers";
import { BookingsButtonsModal } from "../../../Bookings/BookingsMap/BookingsMapModal/BookingsButtonsModal";
import { RoomEquipment } from "../../../../api/grpc/booking/booking";
import { RoomSettings } from "../RoomsBookings/RoomsBookings";
import { Event } from "../../../../api/grpc/booking/ggevent/ggevent";

interface Props {
  type: MainResources;
  name: string;
  tags: string;
  events: Event[];
  locationPath: DeskLocationItem[];
  workplaceID: string;
  refetch?: () => void;
  equipment?: RoomEquipment;
  roomNumberOfSeats?: number;
  qrCodeRequired?: boolean;
  checkInTime?: number;
  settings?: RoomSettings;
  showAttendees?: boolean;
}

export const ReservationCard = ({
  type,
  name,
  tags,
  locationPath,
  events,
  workplaceID,
  refetch,
  roomNumberOfSeats,
  qrCodeRequired,
  equipment,
  checkInTime,
  settings,
  showAttendees,
}: Props) => {
  const { startDate, setEventEndTime } = useAllReservationContext();

  const { isOpen, toggleModal } = useToggleModal();
  const hasEvents = events.length > 0;

  return (
    <div className="ReservationCard default-box">
      {isOpen && (
        <BookingsButtonsModal
          isOpen={isOpen}
          id={workplaceID}
          busySlots={[]}
          toggleModal={toggleModal}
          type="add"
          typeOfWorkplace={type}
          refetch={() => {
            if (refetch) {
              refetch();
            }
          }}
        />
      )}

      <ReservationCardHeader
        workplaceID={workplaceID}
        type={type}
        name={name}
        tags={tags}
        locationPath={locationPath}
        roomNumberOfSeats={roomNumberOfSeats}
        equipment={equipment}
      />

      {hasEvents &&
        hasEnoughGapBetweenEvents(
          new Date(),
          new Date(events[0]?.startTime)
        ) && (
          <AddReservation
            handleClick={() => {
              setEventEndTime(undefined);
              toggleModal();
            }}
          />
        )}

      {hasEvents ? (
        events
          .sort(
            (el1, el2) =>
              +new Date(el1?.startTime ?? "") - +new Date(el2?.endTime ?? "")
          )
          .map((event, index) => (
            <div key={`${index}-${event?.workplaceID}`}>
              {event && (
                <ReservationEvent
                  type={type}
                  workspaceName={name}
                  event={event}
                  workplaceID={workplaceID}
                  locationPath={locationPath}
                  qrCodeRequired={qrCodeRequired}
                  refetchDesk={refetch}
                  refetchRoom={refetch}
                  checkInTime={checkInTime}
                  settings={settings}
                  mode2Organizer={event.mode2Organizer}
                  showAttendees={showAttendees}
                />
              )}

              {index < events.length - 1 &&
                event &&
                events[index + 1] &&
                hasEnoughGapBetweenEvents(
                  new Date(event.endTime),
                  new Date(events[index + 1]!.startTime)
                ) && (
                  <AddReservation
                    handleClick={() => {
                      setEventEndTime(new Date(event.endTime));
                      toggleModal();
                    }}
                  />
                )}
            </div>
          ))
      ) : (
        <h6 className="text-center">No bookings found</h6>
      )}

      {hasEvents &&
        events[events.length - 1] &&
        isEnoughTimeLeftInDay({
          endTime: new Date(events[events.length - 1]!.endTime),
          startTime: startDate,
        }) && (
          <AddReservation
            handleClick={() => {
              setEventEndTime(new Date(events[events.length - 1]!.endTime));
              toggleModal();
            }}
          />
        )}

      {!hasEvents &&
        isEnoughTimeLeftInDay({
          endTime: startDate || new Date(),
          startTime: startDate,
        }) && (
          <AddReservation
            handleClick={() => {
              setEventEndTime(undefined);
              toggleModal();
            }}
          />
        )}
    </div>
  );
};
