import { useAuthContext } from "../context/Auth/AuthContext";
import { RolePermissions } from "../../api/grpc/account/account";

export const useCheckUserPermissions = () => {
  const { user } = useAuthContext();

  const checkUserPermission = (
    permission: RolePermissions | RolePermissions[]
  ) => {
    if (!user) {
      return false;
    }

    return Array.isArray(permission)
      ? permission.some((p) => user?.permissions?.includes(p))
      : user?.permissions?.includes(permission);
  };

  return { checkUserPermission };
};
