import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../lib/context/Auth/AuthContext";
import { useRouter } from "../../lib/hooks/useRouter";
import { useAccountRequests } from "../../api/grpc/account/useAccountRequests";

import { Form, FormGroup, Label, Button as ButtonStrap } from "reactstrap";

import { AccountMessage } from "../../components/shared/AuthenticationPage/AccountMessage/AccountMessage";
import { AuthenticationPage } from "../../components/shared/AuthenticationPage/AuthenticationPage";
import { Button } from "../../components/shared/Button/Button";
import { HandleRequestState } from "../../components/shared/HandleRequestState/HandleRequestState";
import { Input } from "../../components/shared/Input/Input";

export const ConfirmEmailChange = () => {
  const { query, history } = useRouter();
  const [error, setError] = useState<string | undefined>(undefined);
  const { signOut } = useAuthContext();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { verifyEmailChange } = useAccountRequests();

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await verifyEmailChange({
        customerID: query.customer_id,
        token: query.token,
      });

      setSuccess(true);

      setTimeout(async () => {
        await signOut();

        history.push("/login");
      }, 3000);
    } catch (error: any) {
      toast.error(
        error.message ? error.message : "Couldn't confirm email change!"
      );
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <AuthenticationPage
      fullWidth
      isDefault
      formTitle={
        success ? "Email changed successfully!" : "Confirm your new email"
      }
    >
      <HandleRequestState
        state={!!error}
        placeholder={
          <AccountMessage message="Your request to change email has expired or the link has already been used!" />
        }
      >
        {success ? (
          <>
            <span>You can now start using your new email.</span>

            <ButtonStrap className="mt-auto" color="primary" outline>
              It is recommended that you close this browser window
            </ButtonStrap>
          </>
        ) : (
          <Form>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                readOnly
                type="email"
                id="email"
                name="email"
                value={query.email}
              />
            </FormGroup>

            <Button
              title="Confirm"
              size="medium"
              color="primary"
              onClick={() => handleConfirm()}
              className="mt-auto"
              disabled={loading}
            />
          </Form>
        )}
      </HandleRequestState>
    </AuthenticationPage>
  );
};
