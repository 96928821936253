import React from "react";
import { StepProps } from "../CalendarsWizardForm";
import { CalendarsWizardHeaderItem } from "./CalendarsWizardHeaderItem/CalendarsWizardHeaderItem";

import "./CalendarsWizardHeader.scss";

interface Props {
  activeStep: number;
  handleClick: (step: number) => void;
  isDirectoryUser?: boolean;
  type?: string;
}

export const CalendarsWizardHeader = (props: Props) => {
  const steps = [
    {
      id: "calendar-wizard-1",
      title: props.isDirectoryUser
        ? "Select directory"
        : "Select calendar provider",
    },
    {
      id: "calendar-wizard-2",
      title:
        props.type === "personal" ? "Add account" : "Configure service account",
    },
  ];

  return (
    <div className="CalendarsWizardHeader">
      {steps.map((item: StepProps, key: number) => {
        const itemIndex = ++key;

        return (
          <CalendarsWizardHeaderItem
            item={item}
            number={itemIndex}
            key={`${item.id}-header`}
            isActive={props.activeStep === itemIndex}
            isCompleted={itemIndex < props.activeStep}
            onClick={() => props.handleClick(itemIndex)}
          />
        );
      })}
    </div>
  );
};
