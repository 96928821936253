import { AmenitiesFilter } from "../../../../api/grpc/workplaces/workplaces";

export const seating = ["1-5", "6-10", "11-20", "20+", "Any"];

export const equipment = [
  "accessibility",
  "casting",
  "climate",
  "desk",
  "audio",
  "charging port",
  "presentation tech",
  "video conference",
];

export type KeysEnum<T, D> = { [P in keyof Required<T>]: D };

export type EquipmentType = Omit<AmenitiesFilter, "faluty" | "id">;

export const equipments: KeysEnum<EquipmentType, string> = {
  accessibility: "Accessibility",
  audio: "Audio",
  casting: "Casting",
  chargingPort: "Charging Port",
  climate: "Climate",
  desk: "Desk",
  presentationTech: "Presentation Tech",
  videoConference: "Video Conference",
};
