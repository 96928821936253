import { ChangedRoomsDirectionsVariables } from "../EditWorkplace/EditWayFinder/EditWayFinder";

export const findLastObjectsWithSameId = (
  objects: ChangedRoomsDirectionsVariables[]
): ChangedRoomsDirectionsVariables[] => {
  const lastObjects: ChangedRoomsDirectionsVariables[] = [];
  const idToLastIndex: Record<string, number> = {};

  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    idToLastIndex[obj.id] = i;
  }

  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    if (i === idToLastIndex[obj.id]) {
      lastObjects.push(obj);
    }
  }

  return lastObjects;
};
