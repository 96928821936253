import React from "react";
import { format } from "date-fns";

import { useTimeZoneContext } from "../../../../../lib/context/TimeZone/TimeZoneContext";
import useGrpcQuery from "../../../../../lib/hooks/useGrpcQuery";
import { useInsightsContext } from "../../InsightsContext";

import {
  DesksMeetingBehaviorResponse,
  SankeyChartResponse,
} from "../../../../../api/grpc/insights/insights";
import {
  TimeRangeRequest,
  useDeskInsightsRequests,
} from "../../../../../api/grpc/insights/useDeskInsightsRequests";
import {
  BehaviourInsights,
  BehaviourInsightsContext,
} from "./BehaviourContext";

interface Props {
  children: React.ReactNode;
}

export const BehaviourContextProvider = ({ children }: Props) => {
  const { getDesksMeetingBehaviour, getDesksSankeyChart } =
    useDeskInsightsRequests();

  const { timeZone } = useTimeZoneContext();
  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const {
    loading: loadingMeetings,
    error: errorMeetings,
    data: dataMeetings,
  } = useGrpcQuery<TimeRangeRequest, DesksMeetingBehaviorResponse>({
    method: getDesksMeetingBehaviour,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const {
    loading: loadingSankey,
    error: errorSankey,
    data: dataSankey,
  } = useGrpcQuery<TimeRangeRequest, SankeyChartResponse>({
    method: getDesksSankeyChart,
    variables: {
      locationID: locationId,
      startDate: format(startTime, "yyyy-MM-dd"),
      endDate: format(endTime, "yyyy-MM-dd"),
      timeZone,
    },
  });

  const context: BehaviourInsights = {
    loadingMeetings,
    errorMeetings,
    dataMeetings,
    loadingSankey,
    errorSankey,
    dataSankey,
  };

  return (
    <BehaviourInsightsContext.Provider value={context}>
      {children}
    </BehaviourInsightsContext.Provider>
  );
};
