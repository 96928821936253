import React, { useState } from "react";

import { useInsightsContext } from "../../Context/InsightsContext";
import { useDeskOverviewInsightsContext } from "../../Context/Desk/Overview/OverviewContext";
import { useDebounce } from "../../../../lib/hooks/useDebounce";

import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { InsightsAllDesks } from "../AllDesks/InsightsAllDesks";
import { DeskOverview } from "./DeskOverview";
import { DeskOverviewCharts } from "./DeskOverviewCharts";

export const DeskValueInsights = () => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce<string>(searchValue, 500);

  const {
    insightsLocation: { locationType },
  } = useInsightsContext();

  const {
    loadingOverview: loading,
    errorOverview: error,
    dataOverview: data,
  } = useDeskOverviewInsightsContext();

  return (
    <>
      <div className="WorkspaceValueInsights DeskValueInsights">
        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <div className="mt-3 WorkspaceValueInsights__statistics WorkspaceValueInsights__statistics--main">
              <LoadingBox minHeight={170} />
              <LoadingBox minHeight={170} />
              <LoadingBox minHeight={170} />
            </div>
          }
        >
          <HandleRequestState
            state={!!error}
            placeholder={<CenteredBox>{error?.message}</CenteredBox>}
          >
            {!!!debouncedValue.length && <DeskOverview data={data} />}
          </HandleRequestState>
        </HandleLoadingState>

        {!!!debouncedValue.length && (
          <DeskOverviewCharts
            loading={loading}
            error={error}
            data={data?.reservationLengthDistribution || []}
          />
        )}
      </div>

      {locationType !== "Desk" && (
        <InsightsAllDesks
          searchValue={searchValue}
          debouncedValue={debouncedValue}
          setSearchValue={setSearchValue}
        />
      )}
    </>
  );
};
