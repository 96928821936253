import React from "react";

import { usePaginationList } from "./lib/usePaginationList";
import { usePaginationContext } from "../../../../lib/context/Pagination/PaginationContext";

import { Icon } from "../../Icon/Icon";

import "./PaginationList.scss";

export const PaginationList = () => {
  const paginationList = usePaginationList();
  const { page, setPage, setToPreviousPage, setToNextPage, key, total } =
    usePaginationContext();

  return (
    <ol className="PaginationList">
      {page > 1 && (
        <li
          onClick={() => setToPreviousPage()}
          className="PaginationList__item PaginationList__item--arrow PaginationList__item--previous"
        >
          <Icon icon="chevron" />
        </li>
      )}

      {paginationList.map((item, index) => {
        if (item === "DOTS") {
          return (
            <li
              key={`${key}-${item}-${index}`}
              className="PaginationList__item PaginationList__item--dots"
            >
              <span>...</span>
            </li>
          );
        }

        const isActive = page === item;

        return (
          <li
            key={`${key}-${item}-${index}`}
            className={`PaginationList__item ${isActive ? "active" : ""}`}
            onClick={() => {
              if (isActive) {
                return;
              }

              setPage(item as number);
            }}
          >
            <span>{item}</span>
          </li>
        );
      })}

      {page < total && (
        <li
          onClick={() => setToNextPage()}
          className="PaginationList__item PaginationList__item--arrow PaginationList__item--next"
        >
          <Icon icon="chevron" />
        </li>
      )}
    </ol>
  );
};
