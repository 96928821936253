import React from "react";
import {
  GeneralStatsByRoomsResponse,
  GeneralStatsResponse,
  RoomHistoryEventsResponse,
  TimeRangeEventsResponse,
} from "../../../../../api/grpc/insights/insights";
import { OrganizerFilter } from "./RoomInsightsContextProvider";

export interface RoomInsights {
  generalRoomStats?: GeneralStatsResponse;
  errorGeneralStats?: Error;
  loadingGeneralStats: boolean;
  errorRooms?: Error;
  loadingRooms: boolean;
  rooms?: GeneralStatsByRoomsResponse;
  errorRange?: Error;
  loadingRange: boolean;
  roomsTimeRange?: TimeRangeEventsResponse;
  loadingRoomHistory: boolean;
  errorRoomHistory?: Error;
  dataRoomHistory?: RoomHistoryEventsResponse;
  organizers?: OrganizerFilter[];
  setOrganizers: React.Dispatch<
    React.SetStateAction<OrganizerFilter[] | undefined>
  >;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
}

const values: RoomInsights = {
  generalRoomStats: undefined,
  loadingGeneralStats: false,
  errorGeneralStats: undefined,
  loadingRooms: false,
  errorRooms: undefined,
  rooms: undefined,
  loadingRange: false,
  errorRange: undefined,
  roomsTimeRange: undefined,
  loadingRoomHistory: false,
  errorRoomHistory: undefined,
  dataRoomHistory: undefined,
  organizers: [],
  setOrganizers: () => {},
  offset: 0,
  setOffset: () => {},
};

export const RoomInsightsContext = React.createContext(values);

export const useRoomInsightsContext = () => {
  const context = React.useContext(RoomInsightsContext);

  if (!context) {
    throw new Error(
      "useRoomInsightsContext must be used inside of RoomInsightsContext"
    );
  }

  return context;
};
