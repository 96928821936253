import React from "react";
import { usePaginationHelper } from "../../../../../lib/hooks/usePaginationHelper";

import { trimText } from "../../../../../lib/helpers/trimText";
import { PaginationContext } from "../../../../../lib/context/Pagination/PaginationContext";
import { ChangedRoomsDirectionsVariables } from "../../../../Workplaces/EditWorkplace/EditWayFinder/EditWayFinder";
import { Pagination } from "../../../Pagination/Pagination";
import { WayFinderFormik } from "../lib/useWayFinderFormik";
import { DistanceValue } from "../shared/DistanceValue/DistanceValue";
import { WayFinderDirection } from "../WayFinderDirection/WayFinderDirection";
import { DistanceUnit } from "./DistanceUnit";
import { RoomDirection } from "../../../../../api/graphql/rooms/rooms";

import "./WayFinderDirections.scss";

interface Props {
  roomDirections: RoomDirection[];
  handleChangedRooms: ({
    id,
    roomDirectionId,
    direction,
    distance,
  }: ChangedRoomsDirectionsVariables) => void;
  formik: WayFinderFormik;
  changedRooms: ChangedRoomsDirectionsVariables[];
}

export const WayFinderDirections = ({
  roomDirections,
  handleChangedRooms,
  formik,
  changedRooms,
}: Props) => {
  const { page, pageSize, pagination } = usePaginationHelper(
    roomDirections.length
  );

  const indexOfLastItem = page * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentRoomDirectionsData = roomDirections?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <PaginationContext.Provider value={pagination}>
      <div className="mb-4 WayFinderDirections card">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>Room name</th>
                <th>
                  <DistanceUnit formik={formik} />
                </th>
                <th>Direction</th>
              </tr>
            </thead>
            <tbody>
              {currentRoomDirectionsData.map(
                ({ direction, distance, id, room }) =>
                  id &&
                  room && (
                    <tr key={id}>
                      <td className="text-break">{trimText(room.name, 15)}</td>
                      <td>
                        <DistanceValue
                          id={room.id || ""}
                          value={distance}
                          roomDirectionId={id}
                          directionValue={direction}
                          distanceValue={distance}
                          handleChangedRooms={handleChangedRooms}
                          unitValue={formik.values.distanceUnit}
                          roomName={room.name}
                        />
                      </td>
                      <td className="main-flex position-relative">
                        <WayFinderDirection
                          id={room.id}
                          directionValue={direction}
                          distanceValue={distance}
                          roomDirectionId={id}
                          handleChangedRooms={handleChangedRooms}
                          roomName={room.name}
                        />
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </PaginationContext.Provider>
  );
};
