import React, { PropsWithChildren, useMemo } from "react";
import { HourlyDistribution } from "../../../../api/grpc/insights/insights";
import { tooltips } from "../../../../lib/utils/tooltips";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { RoomMeetingsDistributions } from "../../Room/Value/RoomMeetingsDistributions";
import { RoomsLineChart } from "../../Room/Value/RoomsLineChart";
import { ChartCard } from "../ChartCard/ChartCard";

import "./OverviewGraphsRow.scss";

export const OverviewGraphsRow = ({
  loading,
  data,
  error,
}: PropsWithChildren<{
  loading: boolean;
  data: HourlyDistribution[];
  error?: Error;
}>) => {
  const checkAllValuesAreZero = useMemo(() => {
    if (!data) {
      return;
    }

    return data.every((item) => item.value === 0);
  }, [data]);

  return (
    <div className="OverviewGraphsRow">
      <RoomsLineChart />

      {loading ? (
        <LoadingBox className="OverviewGraphsRow__pie" />
      ) : (
        <ChartCard
          title="Meeting Length Distribution (%)"
          message={tooltips.insights.rooms.lengthDistribution}
          className="OverviewGraphsRow__pie"
          adjustInfoLeft
        >
          {error ? (
            <CenteredBox>{error?.message}</CenteredBox>
          ) : checkAllValuesAreZero ? (
            <CenteredBox>
              Can't display data since they all have 0 values
            </CenteredBox>
          ) : (
            <RoomMeetingsDistributions data={data} />
          )}
        </ChartCard>
      )}
    </div>
  );
};
